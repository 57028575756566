import React, { useEffect, useRef, useState } from 'react';
import './miscellaneous-logs.css';
import { AttendanceService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AttendanceModel, AttendanceUpdateRequest } from '@gtpl/shared-models/hrms';
import Highlighter from 'react-highlight-words';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';

export function MiscellaneousLogs() {
  const attendanceService = new AttendanceService;
  const [attendanceData, setAttendanceData] = useState<AttendanceModel[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [month, setMonth] = useState<number>();
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [selectedReason, setSelectedReason] = useState<string>()
  const [selectedPresentStatus, setSelectedPresentStatus] = useState<string>()
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getLogsData();
  }, []);

  function handleMonth(value: number) {
    setMonth(value);
  }

  const getLogsData = () => {
    attendanceService.getLessWorkingEmployees().then(res => {
      setAttendanceData([]);
      if (!res.status) {
        AlertMessages.getErrorMessage(res.internalMessage);
        return false;
      } else {
        setAttendanceData(res.data);
      }
    }).catch(err => {
      setAttendanceData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateAttendanceByHR = (rowData) => {
    if (!selectedPresentStatus && !selectedReason) return AlertMessages.getErrorMessage('Please select both reason and present status')
    else {
      rowData.date = moment(rowData.date).format('YYYY-MM-DD')
      const req = new AttendanceUpdateRequest(rowData.employeeCode, rowData.employeeName, rowData.date, null, null, selectedPresentStatus, null, selectedReason, localStorage.getItem("username"));
      attendanceService.updateAttendanceByHR(req).then(res => {
        if (res.status) {
          getLogsData();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
    }
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };



  const onReset = () => {
    setMonth(0);
    setAttendanceData([]);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      width: 60,
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      ...getColumnSearchProps('employeeCode'),
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      ...getColumnSearchProps('employeeName'),
      // sorter: (a, b) => a.employeeName.length - b.employeeName.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '100px',
      render: (text, record) => {
        return <span>
          {record.date ? moment(record.date).format('YYYY-MM-DD') : '-'}
        </span>
      },
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Shift In TIme',
      dataIndex: 'shift_in_time',
      ...getColumnSearchProps('in_time'),
      render: (text, record) => {
        return <span>
          {record.shift_out_time ? moment(record.shift_out_time).format('HH:mm:ss') : '-'}
        </span>
      },
    },
    {
      title: 'In TIme',
      dataIndex: 'inTime',
      ...getColumnSearchProps('inTime'),
      render: (text, record) => {
        return <span>
          {record.inTime ? moment(record.inTime).format('HH:mm:ss') : '-'}
        </span>
      },
    },
    {
      title: 'Shift Out Time',
      dataIndex: 'shift_out_time',
      ...getColumnSearchProps('out_time'),
      render: (text, record) => {
        return <span>
          {record.shift_out_time ? moment(record.shift_out_time).format('HH:mm:ss') : '-'}
        </span>
      },
    },
    {
      title: 'Out Time',
      dataIndex: 'outTime',
      ...getColumnSearchProps('outTime'),
      render: (text, record) => {
        return <span>
          {record.outTime ? moment(record.outTime).format('HH:mm:ss') : '-'}
        </span>
      },
    },
    {
      title: 'Working Hours',
      dataIndex: 'totalHours',
      ...getColumnSearchProps('totalHours'),
      render: (text, record) => {
        return <span>
          {record.totalHours ? record.totalHours : '-'}
        </span>
      },
    },
    {
      title: 'Attendance Status',
      dataIndex: 'presentStatus',
      render: (text, data, index) => {
        return <span>
          <Form>
            <Form.Item name="presentStatus"
              rules={[
                {
                  required: true,
                  message: "Select a valid status"
                }
              ]}>
              {<Select
                showSearch
                placeholder="Select Status"
                onChange={(value: any) => { setSelectedPresentStatus(value); }}
              >
                <Option key={1} value={'P'}>Present</Option>
                <Option key={2} value={'A'}>Absent</Option>
                <Option key={3} value={'HD'}>Half Day</Option>
              </Select>}
            </Form.Item>
          </Form>
        </span>
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: () => {
        return <span>
          <Form>
            <Form.Item name="reason"
              rules={[
                {
                  required: true,
                  message: "Select a valid reason"
                }
              ]}>
              {<Select
                showSearch
                placeholder="Select Reason"
                onChange={(value: any) => { setSelectedReason(value) }}
              >
                <Option key={1} value={'Early Exit'}>Early Exit</Option>
                <Option key={2} value={'Late'}>Late</Option>
                <Option key={3} value={'Health Issue'}>Health Issue</Option>
              </Select>}
            </Form.Item>
          </Form>
        </span>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (text, rowData) => (
        <span>
          <Button type="primary" shape="round" size="small"
            onClick={() => updateAttendanceByHR(rowData)}
          >
            Submit
          </Button>
        </span>
      ),
    }
  ];

  const data = [
    { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
    { title: 'Employee Code', dataIndex: 'employeeCode' },
    { title: 'Employee Name', dataIndex: 'employeeName' },
    { title: "Date", dataIndex: "date", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Shift", dataIndex: "shift" },
    { title: "In Time", dataIndex: "inTime", render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss") },
    { title: 'Out Time', dataIndex: 'outTime', render: (value) => value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : '-' },
    { title: "Working Hours", dataIndex: "totalHours" }

  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Criteria-not-matched')
      .addColumns(data)
      .addDataSource(attendanceData, { str2num: true })
      .saveAs('criteria-not-matched.xlsx');
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Criteria not Matching Logs</span>} extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Table columns={columns} bordered dataSource={attendanceData} size="small" scroll={{ x: true }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }} />
    </Card>
  );
}

export default MiscellaneousLogs;
