import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col,FormProps } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { HlPercentageDto } from '@gtpl/shared-models/masters';
import { HLpercentagesService } from '@gtpl/shared-services/masters';

import './hl-percentages-form.css';

/* eslint-disable-next-line */
export interface HlPercentagesFormProps {
  hlpercentagesData: HlPercentageDto;
  updateHLpercentages: (product: HlPercentageDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}


export function HlPercentagesForm(
  props: HlPercentagesFormProps
) {
  const [disable, setDisable] = useState<boolean>(false)
  const [form] = Form.useForm();
  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const hlpercentagedataservice = new HLpercentagesService

  const createHlpercentages = (hlpercentagesData: HlPercentageDto) => {
    hlpercentagesData.createdUser=localStorage.getItem('username')
    setDisable(true)
    if ( Number(form.getFieldValue('minGrade') <= Number(form.getFieldValue('maxGrade'))) ){
    hlpercentagedataservice.createHlPercentage(hlpercentagesData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('HL percentage Created Successfully');
        history.push("hl-percentage-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
    else {
     AlertMessages.getInfoMessage('Max grade should be greater than Min grade');
    }
  }
  

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: HlPercentageDto) => {
    setDisable(false)
    console.log(values);
    if (props.isUpdate) {
      console.log(values);
      props.updateHLpercentages(values);
    } else {
      setDisable(false)
      console.log('hello');
      createHlpercentages(values);
    }

  };

  // function minMaxValidator(c:any) {
  //   const { minGrade, maxGrade } = c.value;
  
  //    if (!minGrade || !maxGrade) {
  //      return null;
  //    }
  
  //    if (maxGrade >= minGrade) {
  //      return null;
  //    }
  
  //    return { minMaxValidator: { message: 'Minimum value cannot be more than the maximum value.'}}
  // }


  return (
    <Card title={<span style={{ color: 'white' }}>HL Percentages</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/hl-percentage-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
      <Form form={form} initialValues={props.hlpercentagesData} name="control-hooks" onFinish={saveData} 
        layout="vertical" 
      >
        <Form.Item name="hlPercentageId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="minGrade"
              label="Min Grade"
              rules={[
                {
                  required: true, message: 'Min Grade is required'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Enter valid Min Grade`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="maxGrade"
              label="Max Grade"
            
              
              rules={[
                {
                  required: true, message: 'Max Grade is required'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Enter valid Max Grade`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="hlPercentage"
              label="HL Percentage"
              rules={[
                {
                  required: true, message: 'HL Percentage is required'
                },
                {
                  pattern:  /((\d+)((\.\d{1,2})?))$/,
                  message: `Enter valid HL Percentage`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>


        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
          </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
        </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default HlPercentagesForm;


