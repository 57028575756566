import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Select, Card, Row, Col} from 'antd';
import {HatcheriesDto} from '@gtpl/shared-models/masters';
import {HatcheryService} from '@gtpl/shared-services/masters';

import {Link, useHistory} from 'react-router-dom';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';

import './hatcheries-form.css';

/* eslint-disable-next-line */

export interface HatcheriesFormProps {
  hatcheryData: HatcheriesDto;
  updateHatchery: (hatcheryData: HatcheriesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function HatcheriesForm(
  props: HatcheriesFormProps
) {
    const [form] = Form.useForm();
    const service = new HatcheryService;
    const [disable, setDisable] = useState<boolean>(false)
    let history = useHistory();
    
    const createHatchery = (hatcheryData: HatcheriesDto) => {
      hatcheryData.createdUser=localStorage.getItem('username')
      setDisable(true)
      hatcheryData.isActive= true;
      service.createHatchery(hatcheryData).then(res => {
        setDisable(false)
        if(res.status){
          AlertMessages.getSuccessMessage(res.internalMessage);
          history.push('./hatcheries-view');
          onReset();
        } else {
          if(res.intlCode){
            AlertMessages.getErrorMessage(res.internalMessage);
          }else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      })
    }
  /**
   * 
   * @param values //Dto values
   */
  const saveData = (values: HatcheriesDto) => {
    setDisable(false)
    if(props.isUpdate){
      props.updateHatchery(values);
    }else {
      setDisable(false)
      createHatchery(values);
    }
  };

  /**
   * To reset form fields
   */
  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card title={<span style={{color:'white'}} >Hatchery</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra= {props.isUpdate==true? '':<Link to = '/hatcheries-view'><span style = {{color:'white'}} ><Button className='panel_button'>View</Button></span></Link>}>
      <Form layout="vertical" form={form} initialValues={props.hatcheryData} name="control-hooks" onFinish={saveData}>
        <Form.Item name="hatcheriesId" style={{display:'none'}}>
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:'none'}} name="createdUser" >
          <Input hidden/>
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
            <Form.Item
             name="hatcheriesName"
             label="Hatchery Name"
             
             rules={[
               {
                    required: true,message:'Hatchery Name is required'
                    
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0. ]*$/,
                    message: `Should contain only alphabets .`
                  }
             ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate==false) && 
            <Button htmlType="button" style={{margin: '0 14px'}} onClick={onReset}>
              Reset
            </Button>
            } 
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default HatcheriesForm;
