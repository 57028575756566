import './invoice-grid.css';
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CrrencySymbols, PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest } from "@gtpl/shared-models/sale-management";
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { Link, Redirect } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Invoice } from "@gtpl/pages/logistics/logistics-components/invoice"
import { EmployeeRolesEnum, UiConfig } from '@gtpl/shared-models/common-models';
import { render } from 'react-dom';
import moment from 'moment';

export interface InvoiceGridProps { }
/* eslint-disable-next-line */

export function InvoiceGrid(props: InvoiceGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const numbers = [1, 12, 123, 1234, 12345, 123456, 1234567, 12345678, 123456789].map(String);


  const [drawerVisible, setDrawerVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState<PlantInvoiceDetailsModel[]>([]);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<any>(undefined);

  const invoiceDataService = new SaleOrderService;
  const [saleOrderIdState, setsaleOrderIdState] = useState<any>(null);
  console.log(saleOrderIdState)

  useEffect(() => {
    getAllInvoices();
  }, []);


  const setUpdatevalue = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.saleOrderId)

  }

  const setsaleOrderId = (rowdata) => {

    setsaleOrderIdState(rowdata)

  }
  /**
   * 
   */
  const getAllInvoices = () => {
    const reqObj = new PlantInvoiceDetailsRequest;
    invoiceDataService.getAllPlantInvoiceDetails().then(res => {
      console.log('invoice data')
      console.log(res.data);
      if (res.status) {
        for (const destinationData of res.data) {
          const destinationPlace = `${destinationData.country}, ${destinationData.destinationName}`;
          destinationData.country = destinationPlace
        }
        setInvoiceData(res.data);
      } else {
        if (res.intlCode) {
          setInvoiceData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setInvoiceData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param productData 
   */

  /**
   * 
   * @param variantData 
   */

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData: PlantInvoiceDetailsModel) => {
    setDrawerVisible(true);
    console.log(viewData);
    setSelectedInvoiceData(viewData);
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '75px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      fixed: 'left',
      // responsive: ['lg'],
      sorter: (a, b) => a.invoiceDate.localeCompare(b.invoiceDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: PlantInvoiceDetailsModel, index) => {
        return moment(record.invoiceDate).format('YYYY-MMMM-DD');
      }
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      fixed: 'left',
      // responsive: ['lg'],
      sorter: (a, b) => a.invoiceId - b.invoiceId,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoiceNumber')
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrderId',
      // responsive: ['lg'],
      fixed: 'left',
      sorter: (a, b) => a.saleOrderId - b.saleOrderId,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('custPoNo'),
      render: (text, rowData) => { return rowData.custPoNo }
    },
    {
      title: 'Customer Name',
      dataIndex: 'clientName',
      // responsive: ['lg'],
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('clientName')
    },
    {
      title: 'End Customer Name',
      dataIndex: 'endCustomerName',
      // responsive: ['lg'],
      sorter: (a, b) => a.endCustomerName.localeCompare(b.endCustomerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerName')
    },
    {
      title: 'Country',
      dataIndex: 'country',
      // responsive: ['lg'],
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('country'),
      render: (text, data, index) => {
        return <span>{text}</span>
      }

    },

    {
      title: 'Unit',
      dataIndex: 'unitName',
      // responsive: ['lg'],
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unitName')
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('totalAmount'),
      render: (text, rowData) => { return CrrencySymbols.find(item => item.id == rowData.currency)?.symbol + ' ' + rowData.totalAmount },
    },
    {
      title: 'Exchange Rate(₹)',
      dataIndex: 'exchangeRate',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.exchangeRate - b.exchangeRate,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('exchangeRate'),
      render: (text, rowData) => { return Number(text) },


    },
    {
      title: 'FOB Value in Rs',
      dataIndex: 'fobValue',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.totalInrAmount - b.totalInrAmount,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('total_INR_amount'),
      render: (text, rowData) => { return (rowData.totalAmount > 0) ? Number((rowData.totalAmount - rowData.freightCharges - rowData.insurance) * (rowData.exchangeRate)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (Status, rowData) => (
        <>
          {Status}

        </>
      ),
      filters: [
        {
          text: 'OPEN',
          value: "OPEN",
        },
        {
          text: 'CLOSE',
          value: "CLOSE",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },

    {
      title: `Action`,
      dataIndex: 'action',
      fixed: 'right',
      render: (text, rowData) => (
        <span>

          {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.OPERATIONS_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.OPERATIONS_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE)) &&
            (object) ? <Redirect
            to={{
              pathname: "/invoice-form",
              state: object
            }}
          /> : null}<EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              setUpdatevalue(rowData);

            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          {(saleOrderIdState) ? <Redirect to={`/invoice-detail-view/${saleOrderIdState.saleOrderId}`}
          /> : null}
          <EyeOutlined type="view"
            onClick={() => {

              // if (rowData.isActive) {
              setsaleOrderId(rowData);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Invoice</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

      extra={<Link to='/invoice-form' ><span style={{ color: 'white' }} >
        {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.OPERATIONS_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD)) &&
          <Button className='panel_button' >Create </Button>}
      </span></Link>}
    >

      {/* <Row gutter={40}>
        <Col>
          <Card title={'Total Invoices: ' + invoiceData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
           <Card title={'Active: ' + invoiceData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + invoiceData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
      </Row> */}
      <Row>
        <Table
          rowKey={record => record.invoiceId}
          columns={columnsSkelton}
          dataSource={invoiceData
            //   .filter(JSON.parse(localStorage.getItem('unit_id')))}
            // pagination={{
            //   onChange(current) {
            //     setPage(current);
            //   }
            // }
          }
          onChange={onChange}
          scroll={{ x: true }}
          bordered
        />
      </Row>
    </Card>
  );
}



export default InvoiceGrid;