import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Input } from 'antd';
import './warehouse-dashboard.css';

export interface ProductionPercentageProps { }

export function ProductionPercentage(props: ProductionPercentageProps) {

  const [prodData, setProdData] = useState<any[]>([]);
  const service = new WarehouseDashboardService;

  useEffect(() => {
    getPOsProductionAbove70Perc();
  }, [])


  const getPOsProductionAbove70Perc = () => {
    service.getPOsProductionAbove70Perc({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setProdData(res.data)
        //AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        //AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }

  return (
    <div>
      <Card style={{ padding: '23px', textAlign: 'left', width: 232, height: 63, backgroundColor: '#2e445a', marginBottom: '3px', color: 'white',borderRadius:'10px',marginLeft:'-49px' }} bordered={false} ><b style={{marginLeft:'-18px'}}>Production Above 70% : {" " + prodData[0]?.count ? prodData[0]?.count : "0"}</b></Card>
    </div>
  )
}
export default ProductionPercentage;