import { Card, Col, Row, Tooltip, message } from 'antd';
import React, { useState } from 'react';
import excel from '../../../assets-dashboard/src/lib/excel.png';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

interface TopFiveDownTimeCausesProps {
    assetsData: any
}

const TopFiveDownTimeCausesComponent = (props: TopFiveDownTimeCausesProps) => {
    const { assetsData } = props;
    const [hoveredCard, setHoveredCard] = useState(null);

    const downTimeData = assetsData.flatMap(asset => asset?.top5AssetsDownTimeDetails || []);

    const handleExport: React.MouseEventHandler<HTMLElement> = async (e) => {
        e.preventDefault();

        if (downTimeData.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date().toISOString().slice(0, 10).split("-").join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { header: 'Asset Code', key: 'assetCode', width: 20 },
            { header: 'Down Time Started At', key: 'downTimeStartedAt', width: 30 },
            { header: 'Reason', key: 'reason', width: 20 }
        ];

        worksheet.columns = columns;

        // Add header row with styling
        const headerRow = worksheet.getRow(1);
        headerRow.font = { bold: true };
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        downTimeData.forEach(dtm => {
            worksheet.addRow({
                assetCode: dtm.assetCode,
                downTimeStartedAt: moment(dtm.downTimeStartedAt).format("YYYY-MM-DD"),
                reason: dtm.downTimeReason
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Top Five Down Time Assets Info-${currentDate}.xlsx`);
    };

    return (
        <div>
            <Card
                title="Top 5 Down Time Assets"
                style={{
                    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px',
                    height: "400px",
                    textAlign: 'center'
                }}
                headStyle={{ backgroundColor: '#114232', color: 'white' }}
                extra={
                    <Tooltip title="Export As Excel">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                padding: '5px',
                                height: '35px',
                                width: '35px'
                            }}
                        >
                            <img src={excel} style={{ width: 40, height: 40 }} alt="Export to Excel" 
                             onClick={handleExport} 
                            />
                        </div>
                    </Tooltip>
                }
            >
                <Card style={{ backgroundColor: '#AFD198', height: '50px' }}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Asset Code</h1>
                        </Col>
                        <Col span={8}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Down Time </h1>
                            <br/>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "5px" }}> Started At</h1>
                        </Col>
                        <Col span={8}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Reason</h1>
                        </Col>
                    </Row>
                </Card>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {downTimeData.map((dtm, index) => (
                        <Card
                            key={index}
                            style={{
                                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                                height: '80px',
                                backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white'
                            }}
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <Row gutter={16}>
                                <Col span={8}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{dtm.assetCode}</h1>
                                </Col>
                                <Col span={8}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{moment(dtm.downTimeStartedAt).format("YYYY-MM-DD")}</h1>
                                </Col>
                                <Col span={8}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{dtm.downTimeReason}</h1>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default TopFiveDownTimeCausesComponent;
