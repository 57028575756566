import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Card, Descriptions, Table } from "antd";
import {AssetService} from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import{AssetsDto}  from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {AssetByCode} from '../../../../../../shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';

import moment from 'moment';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import { AssetById } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-id.dto';



export interface AssetsDetailedViewProps {
  assetsCode:any;
}

export const AssetsDetailedView = (
  props: AssetsDetailedViewProps
) => {
  const {assetsCode} = props;
  const [page,setPage] = useState<number>(1);
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [data,setData] = useState<any[]>([]);
  const [itemdata, setItemData] = useState<any>(null);
  const [assetprops,setAssetprops] = useState<boolean>(true)
  let location = useLocation();
  const { state }: any = useLocation();

 // let { id } = state ? state : { id: '' };
  let { loc } = state ? state : { loc: '' };
  const service = new AssetService();
  const [historyData,setHistoryData] = useState<any[]>([]);
  let {code} = useParams<any>()

  useEffect(() => {
    if(assetsCode){
      getDetailedView(assetsCode)
      setAssetprops(false)
     } 
    //else{
    // setAssetprops(true)
    // getDetailedView(id);
    // }
    
  }, [assetsCode])

  useEffect(()=>{
    if(code) {
      console.log(code)
      getDetailedView(code)
    }
  },[code])

  // useEffect(() => {
  //   if(assetsCode){
  //     getDetailedView(assetsCode)
  //     setAssetprops(false)
  //   } else{
  //   setAssetprops(true)
  //   getDetailedView(code);
  //   }
  // }, [code])

  const getDetailedView = (assetId) => {
    const req = new AssetByCode(assetId)
    service.getByAssetCode(req).then(res => {
      assetHistoryInformation(req)
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        // assetInformation(res.data[0].assetId)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)

    })
  }

  // const assetInformation = (id) => {
  //   const req = new AssetById(id,null,null,null) 
  //     service.assetInformation(req).then(res => {
  //       if(res.status) {
  //         setData(res.data)
  //        // AlertMessages.getSuccessMessage('Data Retrieved Successfully')
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     })
  //  }

   const assetHistoryInformation = (req) => {
    service.assetHistory(req).then(res => {
      if(res.status){
        setHistoryData(res.data)
      } else{
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const columns: ColumnsType<any> = [
    {
        title: "S.No",
        key: "sno",
        responsive: ["sm"],
        render: (text,object,index) => (page - 1) * 10 + (index + 1)
      },   {
        title: 'Date',
        dataIndex: 'date',
        render:(text,record) => {
          return (
            <>
            {record.date ? moment(record.date).format('YYYY-MM-DD'): null}
            </>
          )
        }
    
    
      },
  
     {
       title: 'Asset Code',
      dataIndex: 'assetCode',
     },
    
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
     
    },
    
    {
      title: 'Owner of Asset',
      dataIndex: 'ownerOfAsset',
  
    }, 
    
 
    {
      title: 'Check In',
      dataIndex: 'checkIn',
      render:(text,record) => {
        return (
          <>
          {record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD'): '-'}
          </>
        )
      }
  
    },
    {
      title: 'Check Out',
      dataIndex: 'checkOut',
      render:(text,record) => {
        return (
          <>
          {record.checkOut ? moment(record.checkOut).format('YYYY-MM-DD'): '-'}
          </>
        )
      }
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
  
    },
    {
      title: 'Employee Phone No',
      dataIndex: 'employeePhNo',
  
    },
    {
      title: 'Maintenance Type',
      dataIndex: 'maintainanceType',
  
    },
    {
      title: 'Cost of Repair',
      dataIndex: 'costOfRepair',
    }
    
  ];

  const column1 : ColumnProps<any>[] =[
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Transaction Date',
      dataIndex:'transactionDate',
      width:130,
      align:"left",
      render: (text,record) => {
        return (
          <>
          {record.transactionDate ? moment(record.transactionDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      }
    },
    {
      title:'Transaction Type',
      dataIndex:'transactionType',
      width:130,
      align:"left",
    },
    { 
      title:'Assigned to',
      dataIndex:'employeeName',
      width:130,
      align:"left",
      render:(text,record) => {
        return(
          <>
          {record.transactionType == 'INUSE' ? record.employeeName : '-'}
          </>
        )
      }
    },
    {
      title:'Department',
      dataIndex:'department',
      width:130,
      align:"left",
      render:(text,record) => {
        return(
          <>
          {record.transactionType == 'INUSE' ? record.department : '-'}
          </>
        )
      }
    },
    {
      title:'Service Name',
      dataIndex:'serviceName',
      width:130,
      align:"left",
      render: (text,record) => {return record.serviceName ? record.serviceName : '-'}
    },
    {
      title:'Location',
      dataIndex:'location',
      width:130,
      align:"left",
      render: (text,record) => {return record.location ? record.location : '-'} 
    },
    {
      title:'Approved by',
      dataIndex:'Approved',
      width:130,
      align:"left",
      render: (text,record) => {return record.Approved ? record.Approved : '-'}
    },
    
    {
      title:'Maintenance Cost',
      dataIndex:'maintenanceCost',
      width:130,
      align:"left",
      render: (text,record) => {return record.transactionType == 'UNDERMAINTENANCE' ? record.maintenanceCost : '-'} 
      
    }
  ]



  return (
    <Card title={<span style={{ color: 'white' }}> Detailed View - <span style={{color:'black'}}>{itemdata?.assetname}</span></span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

      <Descriptions>
        {/* <Descriptions.Item label="Asset Category">{previewdata?.assetCategory} </Descriptions.Item> */}
        <Descriptions.Item label="Plant">{itemdata?.plantName?itemdata.plantName:''}</Descriptions.Item>
        <Descriptions.Item label="Current Location"> {itemdata?.location_name} </Descriptions.Item>
        <Descriptions.Item label="Asset Mode"> {previewdata?.assetMode} </Descriptions.Item>
        <Descriptions.Item label="Asset Type"> {previewdata?.assetType} </Descriptions.Item>
        <Descriptions.Item label="Asset Category"> {itemdata?.assetCategory}</Descriptions.Item>
        <Descriptions.Item label="Asset Name"> {itemdata?.assetname}</Descriptions.Item>
        <Descriptions.Item label="Asset Code"> {previewdata?.assetsCode} </Descriptions.Item>
        <Descriptions.Item label="Asset Serial Number"> {previewdata?.assetSerialNo}</Descriptions.Item>
        <Descriptions.Item label="Invoice">{previewdata?.invoice}  </Descriptions.Item>
        <Descriptions.Item label="Model"> {previewdata?.model} </Descriptions.Item>
        <Descriptions.Item label="No of Units"> {previewdata?.noOfUnits} </Descriptions.Item>
        <Descriptions.Item label="Purchased Date"> {moment(previewdata?.dateOfPurchased).format('YYYY-MM-DD')} </Descriptions.Item>
        <Descriptions.Item label="Asset Cost"> {previewdata?.assetCost} </Descriptions.Item>
        <Descriptions.Item label="Asset Supplier"> {previewdata?.assetSupplier} </Descriptions.Item>
        <Descriptions.Item label="AMC Availability"> {previewdata?.amc} </Descriptions.Item>
        <Descriptions.Item label="Warranty  Availability"> {previewdata?.warrenty} </Descriptions.Item>
        <Descriptions.Item label="Warranty Valid Upto">{previewdata?.warrentyExpiredDate ? moment(previewdata?.warrentyExpiredDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        <Descriptions.Item label="Insurance"> {previewdata?.insurance} </Descriptions.Item>
        <Descriptions.Item label="Insurance Valid Upto">{previewdata?.insuranceDate ? moment(previewdata?.insuranceDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        <Descriptions.Item label="Expiry Date">{previewdata?.expiredDate ? moment(previewdata?.expiredDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        {/* <Descriptions.Item label="Manufacturer">{previewdata?.manufacturer} </Descriptions.Item> */}
        {/* <Descriptions.Item label="Year"> {previewdata?.year} </Descriptions.Item> */}
        {/* <Descriptions.Item label="Units Of Measurment"> {previewdata?.unitsOfMeasurements} </Descriptions.Item> */}
      </Descriptions>
      <br></br>
      
        {
          historyData.length > 0 ? <>
          <Card title={<span style={{ color: 'white' }}>Asset History</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}></Card>
      <Table
         columns={column1}
         dataSource={historyData} 
        scroll={{ x: 600,y:400 }}
        bordered />
          </> : ' '
        }
        <br/>
      { assetprops ? 
        <Link to={loc}> <Button type="primary" style={{ float: 'right' }}> Back </Button></Link> : (<></>)
        
        }
    </Card>

  );

}

export default AssetsDetailedView;