import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import './asset-transfer-print.css';
import { LogIdRequest, LogInfoData } from '@gtpl/shared-models/production-management'
import { ProdlogService } from '@gtpl/shared-services/production'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FactoriesInput } from '@gtpl/shared-models/sale-management'
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { TransferMaterialService } from '@gtpl/shared-services/procurement';
import { AssetTransfersService } from '@gtpl/shared-services/asset-management';
import { TransferIdRequest } from '@gtpl/shared-models/asset-management';
/* eslint-disable-next-line */
export interface AssetTransferPrintProps {
  assetTransferId: number;
  printOrder: () => void;
  printType: string;
}


export function AssetTransferPrint(
  props: AssetTransferPrintProps
) {

  useEffect(() => {
    getData(props.assetTransferId);
  }, [])

  const [transferInfo, setTransferInfo] = useState<any>([]);
  const assetTransfersService = new AssetTransfersService();

  const getData = (assetTransferId) => {
    const reqObj = new LogIdRequest(assetTransferId);
    const req = new TransferIdRequest();
    req.assetTransferId = assetTransferId;
    req.createdUser = localStorage.getItem('createdUser');
    assetTransfersService.getTransferDetails(req).then(res => {
      if (res.status) {
        setTransferInfo(res.data[0]);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };



  if (transferInfo) {
    return (
        <div id='printme' className='transferPrint'>
          <h1 style={{ textAlign: 'center', fontSize: '28px', marginTop: '12px' }}> {props.printType}</h1>
          {/* <h1 style={{ textAlign: 'center', fontSize: '20px', marginTop: '4px' }}>
            {props.printType}
          </h1> */}
          <table className={'ta-b no-bt-bb'} style={{ width: '100%' }}>
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'} colSpan={2}>
                <h4>From Plant: </h4>
                <b>{transferInfo.fromPlant}</b>
              </td>
              <td className={'ta-b'} colSpan={2}>
                <h4>To Plant: </h4>
                <b>{transferInfo.toPlant}</b>
              </td>
            </tr>
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC NUMBER</b>
              </td>
              <td className={'ta-b'}>{transferInfo.dcNumber}</td>
              <td className={'ta-b'}>
                <b>MRN NUMBER</b>
              </td>
              <td className={'ta-b'}>{transferInfo.mrnNumber}</td>
            </tr>
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC DATE</b>
              </td>
              <td className={'ta-b'}>{moment(transferInfo.transferDate).format("YYYY/MM/DD LTS")}</td>
              <td className={'ta-b'}>
                <b>RECEIVED DATE</b>
              </td>
              <td className={'ta-b'}>{(transferInfo.receivedDate)?moment(transferInfo.receivedDate).format("YYYY/MM/DD LTS"):transferInfo.status}</td>
            </tr>
           
          </table>
          <br />
          <br />

        <table className={'ta-b'} style={{ width: '100%' }}>
          <tr className={'ta-b'}>
            {/* <th className={'ta-b'}>S.No</th> */}
            <th className={'ta-b'}>ASSET CATEGORY</th>
            <th className={'ta-b'}>ASSET NAME</th>
            <th className={'ta-b'}>TRANSFER QTY</th>
            <th className={'ta-b'}>RECEIVED QTY</th>
            <th className={'ta-b'}>COST OF ASSET</th>
          </tr>
          <tr>
            {/* <td className={'ta-b'} style={{ textAlign: 'center' }}></td> */}
            <td className={'ta-b'}>{transferInfo.categoryName}</td>
            <td className={'ta-b'}>{transferInfo.itemName}</td>
            <td className={'ta-b'} style={{ textAlign: 'right' }}>{transferInfo.transferedQuantity}</td>
            <td className={'ta-b'} style={{ textAlign: 'right' }}>{transferInfo.receivedQuantity}</td>
            <td className={'ta-b'} style={{ textAlign: 'right' }}>{transferInfo.assetCost}</td>
          </tr>

        </table>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>{transferInfo.createdUser}</p>
              <h4>ISSUED BY</h4>
            </div>
            <div style={{ padding: '10px' }}>
              <p>{transferInfo.receivedUser}</p>
              <h4>RECEIVED BY</h4>
            </div>
          </div>
          <Button onClick={props.printOrder} className='noprint' style={{ display: 'block', margin: 'auto', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}>Print</span></Button>
        </div>
    );
  }
  else {
    return (<Empty />);
  }
}

export default AssetTransferPrint;