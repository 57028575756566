import './pages-asset-management-asset-management-components-dc-form.css';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select,Col,Row  } from 'antd';
import {Link} from 'react-router-dom';
import {AssetsLocationDto} from '../../../../../../shared-models/asset-management/src/lib/asset-location/asset-location.dto';
import { AssetLocationService } from "../../../../../../shared-services/asset-management/src/lib/asset-location";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {UnitcodeService} from '@gtpl/shared-services/masters';


const {Option} = Select;

/* eslint-disable-next-line */
export interface locationFormProps {
  Data: AssetsLocationDto;
  updateData: (Data: AssetsLocationDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function locationForm(
  props: locationFormProps
) {

  const [form] = Form.useForm();
  const service = new AssetLocationService();
  const plantsService = new UnitcodeService();
  const [plantName,setPlantName]= useState<any>([]);

  useEffect(()=>{
    getAllPlants();
  
  },[])

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if(res.status){
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const onFinish = (values) => {
    service.create(values).then(res => {
      if(res.status) {
        AlertMessages.getSuccessMessage('Created Successfully')
        form.resetFields()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(error => {
      AlertMessages.getErrorMessage(error.message)
    })
  }

  const onReset = () => {
    form.resetFields(); 
  }
  
  return (
    <Card
      title={<span style={{ color: "white" }}>Asset Location</span>}
      style={{ textAlign: "center" }}
      headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      extra={
        props.isUpdate == true ? (
          ""
        ) : (
           <Link to="/Asset-location">
          <span style={{ color: "white" }}>
            <Button>View </Button>{" "}
          </span>
           </Link>
        )
      }
    >
      <Form form={form} onFinish={onFinish}>
        <Row gutter={20}>
            <Col span={5}>
          <Form.Item label='Plant' name='plant' 
            rules={[{required:true}]}>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder='plant'
            showSearch
            allowClear>
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="locationName"
              label="Location Name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type='primary' htmlType='submit'>Submit</Button>
            {[] && <Button onClick={onReset}>Reset</Button>}
          </Col>
        </Row>
      </Form>
    </Card>
  );
}


export default locationForm;