import { ShiftDto, ShiftRequest } from "@gtpl/shared-models/hrms";
import { ShiftService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Divider, Drawer, Input, Popconfirm, Switch, Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from "moment";
import { Link } from "react-router-dom";
import ShiftForm from "../../../shifts/src/lib/pages-hrms-hrms-components-shifts";
import Table, { ColumnProps } from 'antd/lib/table';
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export interface ShiftGridProps { }

export function ShiftGrid(props: ShiftGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [shiftData, setShiftData] = useState<ShiftDto[]>([]);
  const [selectedShiftData, setSelectedShiftData] = useState<any>(undefined);
  const [allData, setAllData] = useState<ShiftDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const shiftService = new ShiftService()

  useEffect(() => {
    getAllShiftActions();
  }, []);

  const getAllShiftActions = () => {
    shiftService.getAllShiftActions(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        console.log(res.data);
        setShiftData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setShiftData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setShiftData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param shiftData 
     */
  const deleteShift = (shiftData: ShiftRequest) => {
    shiftData.isActive = shiftData.isActive ? false : true;
    shiftService.activateOrDeactivateShift(shiftData).then(res => {
      console.log(res);
      if (res.status) {
        getAllShiftActions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateShift = (Data: ShiftDto) => {
    Data.updatedUser= JSON.parse(localStorage.getItem('username'))
    shiftService.updateShift(Data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllShiftActions();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  /**
 * 
 * @param pagination 
 * @param filters 
 * @param sorter 
 * @param extra 
 */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const openFormWithData = (rowData: ShiftDto) => {
    setDrawerVisible(true);
    rowData.inTime = moment(rowData.inTime, 'HH:mm:ss')
    rowData.outTime = moment(rowData.outTime, 'HH:mm:ss')
    setSelectedShiftData(rowData);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      // responsive: ['lg'],
      sorter: (a, b) => a.shift.localeCompare(b.shift),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shift')
    },
    {
      title: 'In Time',
      dataIndex: 'inTime',
      sorter: (a, b) => a.inTime?.localeCompare(b.inTime),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.inTime ? moment(record.inTime).format('HH:mm') : '-'}
        </span>
      },
    },
    {
      title: 'Out Time',
      dataIndex: 'outTime',
      sorter: (a, b) => a.outTime?.localeCompare(b.outTime),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.outTime ? moment(record.outTime).format('HH:mm') : '-'}
        </span>
      },
    },
    {
      title: 'Day Change',
      dataIndex: 'dayChange',
      render: (text, record) => { return record.dayChange ? 'Yes' : 'No' }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.isActive === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Exit Action');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteShift(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate exit-action ?'
                : 'Are you sure to Activate exit-action ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }

  ];

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Shift</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/shift-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        <Table
          rowKey={record => record.id}
          columns={columnsSkelton}
          dataSource={shiftData}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <ShiftForm
              shiftData={selectedShiftData}
              updateShift={updateShift}
              isUpdate={true}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
      </Card>
    </>
  );
}
export default ShiftGrid;