import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {FoodTypesDto}  from '@gtpl/shared-models/masters';

import {FoodTypeService} from '@gtpl/shared-services/masters';
import './type-of-food-grid.css';

import { Link } from 'react-router-dom';

import {TypeOfFoodForm} from '@gtpl/pages/master/master-components/type-of-food-form';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface TypeOfFoodGridProps {}

export function TypeOfFoodGrid(props:TypeOfFoodGridProps) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const foodTypeService = new FoodTypeService();
  const [selectedFoodTypeData, setSelectedFoodTypeData] = useState<any>(undefined);
  const [foodTypeData, setFoodTypeData] = useState<FoodTypesDto[]>([]);

  const openFormWithData=(viewData: FoodTypesDto)=>{
    setDrawerVisible(true);
    setSelectedFoodTypeData(viewData);
  }
  
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    getAllFoodTypes();
  }, []);
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getAllFoodTypes= () => {
    foodTypeService.getAllFoodTypes(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setFoodTypeData(res.data);
      } else {
        if (res.intlCode) {
          setFoodTypeData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setFoodTypeData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteFoodType = (foodTypeData:FoodTypesDto) => {
    foodTypeData.isActive=foodTypeData.isActive?false:true;
    foodTypeService.activateDeactivateFoodType(foodTypeData).then(res => { console.log(res);
      if (res.status) {
        getAllFoodTypes();
        // AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateFoodType = (foodTypeData: FoodTypesDto) => {
    foodTypeData.updatedUser= JSON.parse(localStorage.getItem('username'))
    foodTypeService.updateFoodType(foodTypeData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllFoodTypes();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
       render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Food Type',
      dataIndex:'foodType',
      sorter: (a, b) => a.foodType.localeCompare(b.foodType),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('foodType')
    },
    {
      title: 'Food Type Code',
      dataIndex:'foodTypeCode',
      sorter: (a, b) => a.foodTypeCode.localeCompare(b.foodTypeCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('foodTypeCode')
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      // responsive: ['lg'],
      responsive: ['sm'],
      sorter: (a, b) => a.hsnCode - b.hsnCode,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('hsnCode')
    },
    {
      title: 'Remarks',
      dataIndex:'remarks',
      sorter: (a, b) => String(a.remarks).localeCompare(String(b.remarks)),
      responsive:['sm'],
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('remarks')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        rowData.foodType.trim()=='NA' || rowData.foodType.trim()=='N/A' ? <span> </span>:
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Food Type');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteFoodType(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Food Type ?'
                :  'Are you sure to Activate Food Type ?'
            }
          >  
             <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>  
        </span>
      )
    }
  ];



  return (
    <Card title={<span style={{color:'white'}}>Food Types</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/typeofFood-form' ><Button className='panel_button' >Create </Button></Link>}>


     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Food Types: ' + foodTypeData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + foodTypeData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + foodTypeData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
      </Col>
    </Row><br></br>
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={foodTypeData}/> */}
      
        <Table
          rowKey={record => record.foodTypeId}
          columns={columnsSkelton}
          dataSource={foodTypeData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <TypeOfFoodForm key={Date.now()}
                updateDetails={updateFoodType}
                isUpdate={true}
                // saveItem={saveVariant}
                foodTypeData={selectedFoodTypeData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}

export default TypeOfFoodGrid;
