import React, { useEffect, useState } from 'react';

import './plant-capacity-form.css';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber, Table } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService} from '@gtpl/shared-services/masters'
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantCapacityDto, PlantRequest} from '@gtpl/shared-models/planning'
import { PlantCapacityService } from '@gtpl/shared-services/planning';


const { Option } = Select;


/* eslint-disable-next-line */
export interface PlantCapacityFormProps {
  plantCapacity: PlantCapacityDto;
  isUpdate: boolean;
  closeForm: () => void;
  updatePlantCapacity: (plantCapacities: PlantCapacityDto) => void;
}

export function PlantCapacityForm(
  props: PlantCapacityFormProps
) {
  useEffect(()=>{
    getPlants();

  },[])
  const unitService = new UnitcodeService();

  const [form] = Form.useForm()
  const [plants,setPlants] = useState<PlantsDropDown[]>([])
  const [showTable, setShowTable] = useState<boolean>(false);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [tableData,setTableData] = useState<PlantCapacityDto[]>([
    {
      operation:'De Haeding',
      plantCode:null,
      machineCapacity:0,
      peopleCapacity:null,
      createdUser:'',
    },
    {
      operation:'Grading',
      plantCode:null,
      machineCapacity:null,
      peopleCapacity:0,
      createdUser:''
    },
    {
      operation:'Value Addition',
      plantCode:null,
      machineCapacity:0,
      peopleCapacity:null,
      createdUser:''
    },
    {
      operation:'Soaking',
      plantCode:null,
      machineCapacity:null,
      peopleCapacity:0,
      createdUser:''
    },
    {
      operation:'Freezing',
      plantCode:null,
      machineCapacity:null,
      peopleCapacity:0,
      createdUser:''
    },
  ])

  let createdUser = "";

  const onSearch = () => {

  }
  const saveData = (values) => {
    setBtnDisable(true);
    if (props.isUpdate) {
      console.log(values);
      updatePlantCapacity(tableData);
    } else {
      let effFlag:boolean[] = []
      tableData.forEach(element => {
        console.log(element)
        console.log(element.machineCapacity)
        console.log(element.peopleCapacity)
        if(element.machineCapacity>=0  && element.peopleCapacity>=0 ){
          effFlag.push(true)
          console.log(effFlag)
        }else{
          effFlag.push(false)
          console.log(effFlag)

        }

      });
      if(effFlag.includes(false)){
      }else{
        console.log(tableData);
        createPlantCapacity(tableData);
      }

    }
  }

  const plantCapacityService = new PlantCapacityService()

  const createPlantCapacity = (tableData: PlantCapacityDto[]) => {
    console.log(tableData)

    plantCapacityService.createPlantCapacity(tableData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Plant Capacity Created Successfully');
        setBtnDisable(false)
        // history.push("/resource-op-efficiency-view");
        // onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updatePlantCapacity = (tableData: PlantCapacityDto[]) => {
    console.log(tableData)

    plantCapacityService.updatePlantCapacity(tableData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Plant Capacity Updated Successfully');
        setBtnDisable(false)
        // history.push("/resource-op-efficiency-view");
        // onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const storeMachineCapacity = (val,index,data) => {
    const dynTableData = [...tableData];
    dynTableData[index].machineCapacity = val
    dynTableData[index].plantCode = form.getFieldValue('plantCode')
    dynTableData[index].createdUser = JSON.parse(localStorage.getItem('username'));
    setTableData(dynTableData);

  }
  const storePeopleCapacity = (val,index,data) => {
    const dynTableData = [...tableData];
    dynTableData[index].peopleCapacity = val
    dynTableData[index].plantCode = form.getFieldValue('plantCode')
    dynTableData[index].createdUser = JSON.parse(localStorage.getItem('username'));
    setTableData(dynTableData);
  }
  const getCapacityData = (val) => {
    // setTableData([]);
    plantCapacityService.getCapacityByPlant(new PlantRequest(val)).then(res => {
      if (res.status) {
        setTableData(res.data)
        setShowTable(true)
        setBtnChange(true);
        props.isUpdate=true;
      } else {
        setBtnChange(false);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getPlants = () => {
    unitService.getAllPlants().then((res) => {
      if (res.status) {
        setPlants(res.data);
      }
      else {
        setPlants([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlants([]);
      });
  }
  const columns = [
    
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      colspan:5
    },
    {
      title: 'Machine Capacity',
      dataIndex: 'machineCapacity',
      key: 'machineCapacity',
      render: (text, data, index) => {
        return <span><InputNumber min={0} defaultValue={text} onChange={e => storeMachineCapacity(e, index,data)} /></span>
        // return <span>{data.brandId.children}</span>
      }
    },
    {
      title: 'People Capacity',
      dataIndex: 'peopleCapacity',
      render: (text, data, index) => {
        return <span><InputNumber min={0} defaultValue={text} onChange={e => storePeopleCapacity(e, index,data)} /></span>
        // return <span>{data.brandId.children}</span>
      }
    },

  ]
  return (
    <Card title={<span style={{ color: 'white' }}>Plant Capacity</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/resource-op-efficiency-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}  name="control-hooks" onFinish={saveData}
        layout="vertical"
      >
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        {/* <Form.Item style={{ display: "none" }} name="unitId" initialValue={unitId}>
          <Input hidden />
        </Form.Item> */}

        <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}} >
            <Form.Item
              name="plantCode"
              label="Plant"
              rules={[
                {
                  required: true,
                  message: 'Plant Required',

                },
                
              ]}>
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Plant"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={getCapacityData}
              >
               {plants.map(skudropData => {
                      return <Option  key={skudropData.plantId} value={skudropData.plantId}>{skudropData.plantName}</Option>
                    })}
              </Select>
            </Form.Item>
          </Col>
         
        </Row>
        
        {
          showTable ?
        <Card>
        <Table  dataSource={tableData} columns={columns}  pagination={false} />
        </Card>
        :<></>
        }
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary"  htmlType='submit'>
            {btnChange?'Update':'Create'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default PlantCapacityForm;
