import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Switch, Input, Button, Tag, Row, Col, Drawer, Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link } from 'react-router-dom';

import { RecruitmentDto } from '@gtpl/shared-models/masters';
import { RecruitmentService } from '@gtpl/shared-services/masters';
import { RecruitmentForm } from '@gtpl/pages/hrms/hrms-components/recruitment-mode-form';

import './recruitment-mode.css';

/* eslint-disable-next-line */
export interface RecruitmentGridProps { }

export function RecruitmentGrid(
  props: RecruitmentGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState<RecruitmentDto[]>([]);
  const [selectedRecruitment, setSelectedRecruitment] = useState<any>(undefined);

  const recruitmentService = new RecruitmentService();

  useEffect(() => {
    getAllRecruitment();
  }, []);

  /**
   * 
   */
  const getAllRecruitment = () => {
    recruitmentService.getAllRecruitment().then(res => {
      if (res.status) {
        setRecruitmentData(res.data);
      } else {
        if (res.intlCode) {
          setRecruitmentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRecruitmentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param recruitmentData 
   */
  const deleteRecruitment = (recruitmentData: RecruitmentDto) => {
    recruitmentData.isActive = recruitmentData.isActive ? false : true;
    recruitmentService.activateOrDeactivateRecruitment(recruitmentData).then(res => {
      console.log(res);
      if (res.status) {
        getAllRecruitment();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
  const updateRecruitment = (RecruitmentData: RecruitmentDto) => {
    recruitmentService.updateRecruitment(RecruitmentData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllRecruitment();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData: RecruitmentDto) => {
    setDrawerVisible(true);
    setSelectedRecruitment(viewData);
    console.log(selectedRecruitment)
    console.log('selectedQualifiction')
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Recruitment Mode',
      dataIndex: 'recruitmentMode',
      // responsive: ['lg'],
      sorter: (a, b) => a.recruitmentMode.length - b.recruitmentMode.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('recruitmentMode')
    },


    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {

        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'> 
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Recruitment');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
        </Tooltip>
          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteRecruitment(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate recruitment ?'
                : 'Are you sure to Activate recruitment ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');

  }

  return (
    <Card title={<span style={{ color: 'white' }}>Recruitment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/recruitment-mode-form' ><Button className='panel_button' >Create </Button></Link>}

    >
      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total Recruitment: ' + recruitmentData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + recruitmentData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + recruitmentData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.recruitmentId}
        columns={columnsSkelton}
        dataSource={recruitmentData}
        scroll={{ x: true }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <RecruitmentForm key={Date.now()}
            updateRecruitment={updateRecruitment}
            isUpdate={true}
            recruitmentData={selectedRecruitment}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default RecruitmentGrid;
