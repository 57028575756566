import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import {  Link } from "react-router-dom";
import React,{useState} from 'react';
// import NMenu from './menu';
export interface SelfServicePortalProps {}

export function SelfServicePortal( props: SelfServicePortalProps) 
{

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };


  return (

    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
   <Row gutter={[24, 24]}>
   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
      <Form.Item
        name="username"
        label="User Name"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
        <Link to='/menu' >Login </Link>
        </Button>
      </Form.Item>
      </Col>
      </Row>
    </Form>
  
  );
};
// }
export default SelfServicePortal;




 