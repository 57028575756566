import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Row, Col, Select } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import { ItemSubCategoriesDto, ItemCategoriesDto } from '@gtpl/shared-models/masters';
import { ItemSubCategoryService, ItemCategoryService } from '@gtpl/shared-services/masters';
 
import './item-sub-categories-form.css';

const { TextArea } = Input;
const { Option } = Select;
/* eslint-disable-next-line */
export interface ItemSubCategoriesFormProps {
  subCategoryData: ItemSubCategoriesDto;
  updateData: (subCategory: ItemSubCategoriesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ItemSubCategoriesForm(
  props: ItemSubCategoriesFormProps
) {
  const [form] = Form.useForm();
  const service = new ItemSubCategoryService;
  const [disable, setDisable] = useState<boolean>(false)
 
  let history = useHistory();

  const [itemCategoryData, setItemCategoryData] = useState<ItemCategoriesDto[]>([]);
  const itemCategoryService = new ItemCategoryService();
  const [selectedItemCategory, setSelectedItemCategory] = useState<number>(null);

  useEffect(() => {
    getAllItemCategories();
  }, []);

  const getAllItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then(res => {
      if (res.status) {
        setItemCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const save = (Data: ItemSubCategoriesDto) => {
    Data.createdUser=localStorage.getItem('username')
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
      if (res.data) {
        AlertMessages.getSuccessMessage('Success');
        history.push("/item-sub-category-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: ItemSubCategoriesDto) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateData(values);
    } else {
      setDisable(false)
      save(values);
    }
  }

  const handleItemCategory = (value, item) => {
    setSelectedItemCategory(value);
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card title={<span style={{ color: 'white' }}>Asset Sub Categories</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/item-sub-category-view' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.subCategoryData} name="control-hooks" onFinish={saveData}>
        <Form.Item name="itemSubCategoryId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={props.subCategoryData}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemCategoryId"
              label="Asset Category"
              rules={[
                {
                  required: true,
                  message: 'Asset Category is required'
                },
              ]}>
              <Select
                placeholder="Select Asset Category"
                onSelect={handleItemCategory}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {itemCategoryData?.map(dropData => {
                  return <Option key={dropData.itemCategoryId} value={dropData.itemCategoryId}>{dropData.itemCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemSubCategory"
              label="Asset Sub Category Name"
              rules={[
                {
                  required: true, message: 'Sub Category Name is required'

                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                },
                {
                  max:30,
                  message:'Asset Sub Category Name cannot be greater than 30'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemSubCategoryCode"
              label="Asset Sub Category Code"
              rules={[
                {
                  required: true, message: 'Sub Category Code is required'

                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                  message: `Invalid Asset Sub Category Code`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
             {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
          </Button>
          }
        </Col>
      </Form>
    </Card>

  );
}

export default ItemSubCategoriesForm;
