import { Card, Col, Collapse, Form, Radio, Row, Select, Statistic, } from "antd";
import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import CardComponent from "libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/dashboard-components/card-component";
import './packing-dashboard.css';
import React, { useEffect, useState } from "react";
import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody, HighColumn, } from "@gtpl/shared-models/common-models";
import { PurchaseOrderService } from "@gtpl/shared-services/procurement";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faBox, faBoxOpen, faBullseye } from '@fortawesome/free-solid-svg-icons';
import Highcolumn from "libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/column";
import { LineSeries } from "libs/shared-models/common-models/src/lib/dashboards/line-series";
import { ReportTypeENum, StockInResDto } from '@gtpl/shared-models/analytics-data';
import { PoGrnGraph } from 'libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/pack-Po-Grn-Graph';
import moment from "moment";

/* eslint-disable-next-line */
export interface PackingDashboardsProps { }

export function PackingDashboards(
  props: PackingDashboardsProps
) {

  const [poLifeCycleStatus, setPoLifeCycleStatus] = useState<any[]>([]);
  const [grnLifeCycleStatusCount, setGrnLifeCycleStatusCount] = useState<any[]>([]);
  const [poDeadStockDetail, setPoDeadStockDetail] = useState<any[]>([]);
  const [poSubCategoryDetail, setPoSubCategoryDetail] = useState<any[]>([]);
  const [soWiseQty, setSoWiseQty] = useState<any[]>([]);
  const [dummyWiseQty, setDummyWiseQty] = useState<any[]>([]);
  const [fastMovingProduct, setFastMovingProduct] = useState<any[]>([]);
  const purchaseOrderService = new PurchaseOrderService()
  const monthsNamesArray = new Set<string>();
  const grnCnt: CardHeaderBody[] = [];
  const grnQty: CardHeaderBody[] = [];
  const monthPlanned = new Map<number, number>();
  const [value, setValue] = useState(1);
  const { Option } = Select;
  const [graphType, setGraphType] = useState(ReportTypeENum.DAYWISE)
  let xAxisValues = []



  useEffect(() => {
    getPoLifeStatus()
    getPoDeadStock()
    getPoSubCategory()
    getSoWiseQty()
    getDummyWiseQty()
    fastMovingProd()
  }, [])

  const getPoLifeStatus = () => {
    purchaseOrderService.getPoLifeStatus({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setPoLifeCycleStatus(res.data);
      } else {
        setPoLifeCycleStatus([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoLifeCycleStatus([]);
    })
  }
  const getPoDeadStock = () => {
    purchaseOrderService.getPoDeadStock({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setPoDeadStockDetail(res.data);
      } else {
        setPoDeadStockDetail([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoDeadStockDetail([]);
    })
  }
  const getPoSubCategory = () => {
    purchaseOrderService.getPoSubCategory({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setPoSubCategoryDetail(res.data);
      } else {
        setPoSubCategoryDetail([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoSubCategoryDetail([]);
    })
  }
  const getSoWiseQty = () => {
    purchaseOrderService.getSoWiseQty({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setSoWiseQty(res.data);
      } else {
        setSoWiseQty([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoWiseQty([]);
    })
  }
  const getDummyWiseQty = () => {
    purchaseOrderService.getDummyWiseQty({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setDummyWiseQty(res.data);
      } else {
        setDummyWiseQty([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDummyWiseQty([]);
    })
  }
  const fastMovingProd = () => {
    purchaseOrderService.fastMovingProd({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setFastMovingProduct(res.data);
      } else {
        setFastMovingProduct([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setFastMovingProduct([]);
    })
  }
  // poLifeCycleStatus
  const cardBodyForPoLifeCycleStatusCount: CardBodyDetails[] = [];
  let LifeCycleStatusCount = 0;
  let kakinadaCount = 0;
  let unitOneCount = 0;
  let unitTwoCount = 0;
  for (const productInfo of poLifeCycleStatus) {

    cardBodyForPoLifeCycleStatusCount.push(new CardBodyDetails(productInfo.lifeCycleStatus, Number(productInfo.kakinada).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit1).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit2).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), String(Number(productInfo.kakinada) + Number(productInfo.unit1) + Number(productInfo.unit2))));
    kakinadaCount = kakinadaCount + Number(productInfo.kakinada)
    unitOneCount = unitOneCount + Number(productInfo.unit1);
    unitTwoCount = unitTwoCount + Number(productInfo.unit2);
  }
  LifeCycleStatusCount = Number(kakinadaCount + unitOneCount + unitTwoCount);

  const poLifeCycleStatusCountBodyStatus = new CardHeaderBody(null);
  const poLifeCycleStatusCountBodyCount = new CardHeaderBody(null);
  const poLifeCycleStatusCountBodyCardHeader = new CardHeader('Po Life Cycle Status Count', [poLifeCycleStatusCountBodyStatus, poLifeCycleStatusCountBodyCount], 'Packing','Total Count',Number(LifeCycleStatusCount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));

  const poLifeCycleStatusCountSoCard = new CardDetail(poLifeCycleStatusCountBodyCardHeader, cardBodyForPoLifeCycleStatusCount, ['Status', 'Kakinada', 'Unit1', 'Unit2', 'Total'],false);
  // fast moving product(top5selling product)
  const cardBodyForTopFiveSo: CardBodyDetails[] = [];
  let maxiInvDate = '';
  let maxiActEndDate = '';
  for (const productInfo of fastMovingProduct) {
    cardBodyForTopFiveSo.push(new CardBodyDetails(productInfo.itemName, productInfo.cnt,));
  }
  const topFiveSoBodyCount = new CardHeaderBody(null);
  const topFiveSoBodyQty = new CardHeaderBody(null);
  const topFiveSoBodyCardHeader = new CardHeader('Fast Moving Items', [topFiveSoBodyCount, topFiveSoBodyQty]);

  const FastMovingProd = new CardDetail(topFiveSoBodyCardHeader, cardBodyForTopFiveSo, ['Items', 'count',],false);
  //Sub Category Stock
  const cardBodyForSubCategoryStockPro: CardBodyDetails[] = [];
  let subCategoryCount = 0;
  let subCategoryQty = 0;
  for (const productInfo of poSubCategoryDetail) {
    cardBodyForSubCategoryStockPro.push(new CardBodyDetails(productInfo.subCat, Number(productInfo.qty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),Number(productInfo.phyQty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })));
    subCategoryCount += Number(productInfo.phyQty);
    subCategoryQty += Number(productInfo.qty);
  }
  const SubCategoryProBodyCount = new CardHeaderBody(null);
  const SubCategoryProBodyQty = new CardHeaderBody(null);
  const SubCategoryProBodyCardHeader = new CardHeader('Sub Category Stock', [SubCategoryProBodyCount , SubCategoryProBodyQty],'Packing','Total Count',Number(subCategoryQty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));

  const SubCategoryProCard = new CardDetail(SubCategoryProBodyCardHeader, cardBodyForSubCategoryStockPro, ['Sub Category ', 'Sub Category cnt', 'Stock Qty ' ],false);

  //Dead  Stock
  const cardBodyForDeadStockPro: CardBodyDetails[] = [];
  let deadStockCount = 0;
  let deadStockQty = 0;
  for (const productInfo of poDeadStockDetail) {
    const createdDate = productInfo.createdAt ? moment(productInfo.date).format('YYYY-MM-DD') : '';
    cardBodyForDeadStockPro.push(new CardBodyDetails(productInfo.itemName,createdDate, Number(productInfo.days).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.qty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })));
    deadStockCount += Number(productInfo.days);
    deadStockQty += Number(productInfo.qty);
  }
  const DeadStockProBodyCount = new CardHeaderBody(null);
  const DeadStockProBodyQty = new CardHeaderBody(null);
  const DeadStockProBodyCardHeader = new CardHeader('Dead Stock Items', [DeadStockProBodyCount, DeadStockProBodyQty],'po','Total Qty',Number(deadStockQty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));

  const DeadStockProCard = new CardDetail(DeadStockProBodyCardHeader, cardBodyForDeadStockPro, ['Items','GRN Date','Age','Qty'],false);

  return (
    <>
      <div style={{ backgroundColor: 'white', fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro' }}>
        <Row gutter={24} style={{ backgroundColor: "#272953" }}>
          <Col className="cardComp" xs={24} sm={24} md={8} xl={9} style={{ height: '33%' }}>
            <CardComponent cardData={poLifeCycleStatusCountSoCard} />
          </Col>

          <Col span={12} xs={24} sm={24} md={8} xl={5}>
            <Card className="statistics" >
              <Statistic
                title="Available Qty against So"
                //style={{color: '#eee'}}
                valueStyle={{color: '#eee',marginTop: '21px',marginLeft:'38px'}}
                value={soWiseQty[0]?.soQty}
                prefix={<FontAwesomeIcon icon={faBox} />}
              />
            </Card>
            <Card className="statistics" >
              
              <Statistic
                title="Available Qty against Dummy"
                valueStyle={{color: '#eee',marginTop: '-12px',marginLeft:'38px'}}  
                value={dummyWiseQty[0]?.dummyQty}
                prefix={<FontAwesomeIcon icon={faBoxOpen} />}
              />
            </Card>
          </Col>
          <Col className="cardComp" xs={24} sm={24} md={8} xl={10} style={{ height: '33%' }}>
            <CardComponent cardData={FastMovingProd} />
          </Col>


        </Row>
        <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
          <Col className="cardComp" xs={24} sm={24} md={8} xl={12} style={{ height: '33%' }}>
            <CardComponent cardData={SubCategoryProCard} />
          </Col>
          <Col className="cardComp" xs={24} sm={24} md={8} xl={12} style={{ height: '100%', marginTop: '-4px' }}>
           
            <Row gutter={24}>
              <Col className="cardComp" xs={24} sm={24} md={8} xl={24} style={{ height: '33%' }}>
                <CardComponent cardData={DeadStockProCard} />
              </Col>
              <Col className="cardComp" xs={24} sm={24} md={8} xl={24} style={{ height: '33%' }}>
                <Card>
                  <PoGrnGraph />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default PackingDashboards;
