import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

import { Link, Redirect } from 'react-router-dom';

import TypesForm from '../../../types-form/src/lib/types-form';
import { TypesDto } from '@gtpl/shared-models/masters';
import { TypesService } from '@gtpl/shared-services/masters';
import './types-grid.css';

/* eslint-disable-next-line */
export interface TypesGridProps {

}

export function TypesGrid(
  props: TypesGridProps
) {
  const [typesData, setTypesData] = useState<TypesDto[]>([]);
  const [page, setPage] = React.useState(1);
  const typesService = new TypesService();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTypeData, setSelectedTypeData] = useState<any>(undefined);
  const searchInput = useRef(null);

  useEffect(() => {
    getAllTypes()
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  const getAllTypes = () => {
    typesService.getAllTypes().then(res => {
      if (res.status) {
        setTypesData(res.data);
      } else {
        if (res.intlCode) {
          setTypesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTypesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteNotifyParty = (dto: TypesDto) => {
    dto.isActive = dto.isActive ? false : true;
    typesService.activatedeActivateTypes(dto).then(res => {
      console.log(res);
      if (res.status) {
        getAllTypes();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateTypes = (dto: TypesDto) => {
    typesService.updateTypes(dto).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllTypes();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const openFormWithData = (viewData: TypesDto) => {
    setDrawerVisible(true);
    setSelectedTypeData(viewData);

  }
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const columnsSkelton : ColumnProps<any>[] =[
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Item Sub Category',
      dataIndex:'itemSubCategory',
      sorter: (a, b) => a.itemSubCategory.localeCompare(b.itemSubCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategory')
    },
    {
      title:'Type',
      dataIndex:'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('type')
    },
    
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Notify party');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteNotifyParty(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate  ?'
                :  'Are you sure to Activate  ?'
            }
          >  
             <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>  
        </span>
      )
    }
  ]
  return (
    <Card title={<span style={{ color: 'white' }}>Types</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/types-form' ><Button className='panel_button' >Create </Button></Link>}
  >
    <br></br>
    <Row gutter={40} >
      <Col>
        <Card title={'Total Types: ' + typesData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
      </Col>
      <Col>
        <Card title={'Active: ' + typesData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
      </Col>
      <Col>
        <Card title={'In-Active: ' + typesData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
      </Col>
    </Row>
    <br></br>
    <Table
      rowKey={record => record.notifyPartyId}
      columns={columnsSkelton}
      dataSource={typesData}
      scroll={{ x: true }}
      pagination={{
        onChange(current) {
          setPage(current);
        }
      }}
      onChange={onChange}
      bordered />
    <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
      onClose={closeDrawer} visible={drawerVisible} closable={true}>
      <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
        <TypesForm key={Date.now()}
          updateDetails={updateTypes}
          isUpdate={true}
          typesData={selectedTypeData}
          closeForm={closeDrawer} />
      </Card>
    </Drawer>
  </Card>
  );
}

export default TypesGrid;
