import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FoodTypesDto } from '@gtpl/shared-models/masters';

import { FoodTypeService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from "react-router-dom";
import './type-of-food-form.css';

/* eslint-disable-next-line */
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
export interface TypeOfFoodFormProps {
  foodTypeData: FoodTypesDto;
  updateDetails: (foodType: FoodTypesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function TypeOfFoodForm(props: TypeOfFoodFormProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const service = new FoodTypeService();
  const [disable,setDisable]=useState<boolean>(false)

  const save = (foodTypeData: FoodTypesDto) => {
    setDisable(true)
    service.createFoodType(foodTypeData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Food Type Created Successfully');
        history.push("/typeofFood-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const saveData = (values: FoodTypesDto) => {
    setDisable(false)
    console.log(values);
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      setDisable(false)
      save(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };

  return (

    <Card title={<span style={{ color: 'white' }}>Food Type</span>} style={{ textAlign: 'center' }} extra={props.isUpdate == true ? "" : <Link to='/typeofFood-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout="vertical" onFinish={saveData} initialValues={props.foodTypeData} >
        <Form.Item name="foodTypeId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser">
          <Input hidden />
        </Form.Item>
        <Row gutter={10}>
          <Col >
            <Form.Item
              name="foodType"
              label="Food Type"
              rules={[
                {
                  required: true,
                  message: 'Food Type is required'
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>
          <Col >
            <Form.Item
              name="foodTypeCode"
              label="Food Type Code"
              rules={[
                {
                  required: true,
                  message: 'Food Type Code is required'
                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                  message: `Invalid Food Type Code`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>

          <Col >
            <Form.Item
              name="hsnCode"
              label="HSN Code"
              rules={[
                {
                  required: true,
                  message: 'HSN Code is required'
                },
                {
                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                  message: `Should contain only numbers.`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>


          <Col >
            <Form.Item name="remarks" label="Remarks"
            rules={[
                
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Don't Allow and spaces`
                }
              ]}>
              <TextArea rows={1} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
          </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
          </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default TypeOfFoodForm;
