import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { SgsTuvDto } from "@gtpl/shared-models/sale-management";
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import moment from 'moment';
import { InspectionStatusEnum } from '@gtpl/shared-models/common-models';
export interface SgsTuvFormProps {
  saleOrderId: number;
  handleCancel: () => void
}

export function SgsTuvForm(props: SgsTuvFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  let createdUser = "";
  const sgsTuvService = new SaleOrderService();
  const {Option} = Select
  const updateSgsTuvData = (data: SgsTuvDto) => {
    data.saleOrderId = props.saleOrderId;
    data.shipmentDate = moment(data.shipmentDate).format('YYYY-MM-DD');
    data.initiationDate = moment(data.initiationDate).format('YYYY-MM-DD');
    data.plannedDate = moment(data.plannedDate).format('YYYY-MM-DD');
    data.actualDate = moment(data.actualDate).format('YYYY-MM-DD');
    data.expectedResultDate = moment(data.expectedResultDate).format('YYYY-MM-DD');
    sgsTuvService.updateSGSTUVData(data).then(res => {
      if (res.status) {
        props.handleCancel();
        AlertMessages.getSuccessMessage('SGS TUV Updated Successfully');
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset = () => {
    form.resetFields();
  }

  return (


    <Card title={<span style={{ color: 'white' }}>SGS-TUV</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <Form form={form} name="control-hooks" layout="vertical" onFinish={updateSgsTuvData}>
        <Row gutter={24}>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="shipmentDate"
              label="Shipment Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />

            </Form.Item>
          </Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="initiationDate"
              label="Initiation Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="plannedDate"
              label="Planned Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="actualDate"
              label="Actual Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="expectedResultDate"
              label="Expected Result Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />
            </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
               name = 'inspectionStatus'
               label = 'Inspection Status'
               rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
               ]}
              >
               <Select 
               showSearch
               // style={{ width: 200 }}
               placeholder="Select"
               optionFilterProp="children"
               allowClear
               >
                 <Option key={0} value={null}>Select Status</Option>
                 <Option key={1} selected value={InspectionStatusEnum.OPEN}>{InspectionStatusEnum.OPEN}</Option>
                 <Option key={2} value={InspectionStatusEnum.INITIATED}>{InspectionStatusEnum.INITIATED}</Option>
                 <Option key={3} value={InspectionStatusEnum.PASS}>{InspectionStatusEnum.PASS}</Option>
                 <Option key={3} value={InspectionStatusEnum.FAIL}>{InspectionStatusEnum.FAIL}</Option>
               </Select>
              </Form.Item>
            
          </Col>
          <Col style={{paddingTop: '30px' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>

      </Form>
    </Card>
  );
}

export default SgsTuvForm;
