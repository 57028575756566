import { ItemForBrandReq, ItemsDropDownDto, ItemSubCategoryDropDownDto, SubCatReqForCategoryId } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Col, Form, Input, InputNumber, Radio, Row, Select, Table } from 'antd'
import { FormInstance } from 'rc-field-form';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons'
import { ClassValidator } from '@gtpl/shared-utils/class-validators';

const { Option } = Select

interface AddTrimFormProps {
  form: FormInstance<any>
  trimsDetailFormForm: FormInstance<any>
  setTrimsData: (value: any) => void
  isUpdate?: boolean
  initialValues: any
}

export default function AddTrimForm(props: AddTrimFormProps) {

  const [childform] = Form.useForm()
  const subCategoryService = new ItemSubCategoryService();
  const [itemSubCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const { form, isUpdate, initialValues } = props
  const [itemData, setItemData] = useState<ItemsDropDownDto[]>([]);
  const itemService = new ItemsService();
  const optionsWithDisabled = [
    { label: 'Pouch', value: 'Pouch' },
    { label: 'Carton', value: 'Carton' },
    { label: 'Ring', value: 'Ring' },
    { lable: 'Skewer', value: 'Skewer' }

  ];
  const [trimDetails, setTrimDetails] = useState<any[]>(initialValues)
  const [itemSubCategory, setItemSubCategory] = useState<any>()
  const [itemName, setItemName] = useState<any>()
  const [indexVal, setIndexVal] = useState<number>()
  let createdUser = "";
  const classValidator = new ClassValidator();
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  props.form = childform


  React.useEffect(() => {
    getAllItemSubCategories();
  }, [props.initialValues])

  React.useEffect(() => {
    setTrimDetails(props.initialValues)
  }, [props.initialValues])

  const getAllItemSubCategories = () => {
    const itemCategoryId = 6
    const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getItemData = (value?: any) => {
    if (value) { setItemSubCategory(value.children) }
    setItemData([])
    const itemSubCategoryId = childform.getFieldValue('itemSubCategoryId')
    const req = new ItemForBrandReq()
    req.itemSubCategoryId = itemSubCategoryId
    itemService.getItemsForSubCategory(req).then(res => {
      if (res.status) {
        setItemData(res.data)
      } else {
        setItemData([])
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    })

  }

  const addTrim = () => {
    if (isUpdate && indexVal != undefined) {
      console.log('update')
      childform.validateFields().then((res) => {
        if (trimDetails.length > 0) {
          const findDuplicate = trimDetails.find((i) => i.itemId == res.itemId && i.itemSubCategoryId == res.itemSubCategoryId && i.againstTo == res.againstTo)
          if (findDuplicate) {
            return AlertMessages.getInfoMessage('Same Combination can not be added twice')
          }
        }
        res.subCategory = itemSubCategoryData.find(i => i.itemSubCategoryId == res.itemSubCategoryId).itemSubCategory
        res.itemName = itemData.find(i => i.itemId == res.itemId).itemName
        res['subcategoryId'] = res['itemSubCategoryId'];
        res.createdUser = localStorage.getItem("createdUser")
        delete res['itemSubCategoryId'];
        // setTrimDetails((prevData: any) => { return [...prevData,prevData[indexVal] = res] })
        const newData = trimDetails
        newData[indexVal] = res
        setTrimDetails(preev => [...newData])
        props.setTrimsData([...newData])
        childform.resetFields()
        setIndexVal(undefined)

      })
    } else {
      childform.validateFields().then((res) => {
        if (trimDetails.length > 0) {
          const findDuplicate = trimDetails.find((i) => i.itemId == res.itemId && i.itemSubCategoryId == res.itemSubCategoryId && i.againstTo == res.againstTo)
          if (findDuplicate) {
            return AlertMessages.getInfoMessage('Same Combination can not be added twice')
          }
        }
        res.subCategory = itemSubCategory
        res.itemName = itemName
        res['subcategoryId'] = res['itemSubCategoryId'];
        delete res['itemSubCategoryId'];
        setTrimDetails((prevData: any) => { console.log(prevData); return [...prevData, res] })
        props.setTrimsData([...trimDetails, res])
        childform.resetFields()

      })
    }
  }

  const deleteTrim = (index) => {
    console.log(index)
    trimDetails.splice(index, 1)
    setTrimDetails([...trimDetails]);
    props.setTrimsData([...trimDetails])
    childform.resetFields()
  }

  const openFormWithData = (values, index) => {
    console.log(values)
    childform.setFieldsValue({
      trimItemId: values.trimItemId,
      itemSubCategoryId: values.subcategoryId,
      itemId: values.itemId,
      againstTo: values.againstTo,
      noOfTrims: values.noOfTrims
    })
    getAllItemSubCategories()
    getItemData()
    setIndexVal(index)
  }


  const columns = [
    {
      title: 'Sub Category',
      dataIndex: 'subCategory',
      width: '150px'
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width: '550px'
    },
    {
      title: 'Against To',
      dataIndex: 'againstTo'

    },
    {
      title: 'No of Trims',
      dataIndex: 'noOfTrims',
      width: '120px'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData: any, index) => {
        return isUpdate ? <EditOutlined className={'editSamplTypeIcon'} type="edit"
          onClick={() => { openFormWithData(rowData, index) }}
          style={{ color: '#1890ff', fontSize: '14px' }}
        />
          : <Button size='small' shape='round' type='primary' onClick={() => { deleteTrim(index) }} >Delete</Button>
      }
    }
  ]


  return (
    <Card title={<span style={{ color: 'white' }}>Add Trims</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={childform} layout={'vertical'} initialValues={props.initialValues}>
        <Row gutter={24}>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="trimItemId" >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="itemSubCategoryId"
              label="Item Sub Category"
              rules={[
                {
                  required: true,
                  message: 'Item Sub Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Item Sub Category `,
                },
              ]}

            >
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onSelect={(itemSubCategoryId, value) => { getItemData(value) }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Sub Category</Option> */}
                {itemSubCategoryData.map((subCategory) => {
                  return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item
              name="itemId"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: 'Item Name is required',
                },

              ]}>
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Item"
                optionFilterProp="children"
                onSelect={(itemId, value) => { setItemName(value.children) }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={0}>Select Item</Option> */}
                {itemData.map((item) => {
                  return <Option key={item.itemId} value={item.itemId}>{item.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='againstTo' label='Against To' rules={[
              {
                required: true,
                message: 'Input is required',
              },

            ]}>
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Against to"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  optionsWithDisabled.map((el) => {
                    return <Option key={el.value} value={el.value}>{el.value}</Option>
                  })
                }

              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='noOfTrims' label='No of Trims' rules={[
              {
                required: true,
                message: 'Input required',
              },
              ...classValidator.onlyNumeric()
            ]}>
              <InputNumber min={1} max={6} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={2} style={{ paddingTop: '30px' }} >
            <Button onClick={addTrim} type='primary' >{isUpdate ? 'Update' : 'Add'}
            </Button>
          </Col>
        </Row>
      </Form>
      {(trimDetails?.length) ? <Row gutter={24}>
        <Table
          dataSource={trimDetails}
          columns={columns}
          pagination={false}
          scroll={{ y: 1600 }}
        />
      </Row> : <>
      </>}
    </Card>
  )
}
