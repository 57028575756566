import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Col } from 'antd';
import CardComponent from 'libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/dashboard-components/card-component';
import React, { useEffect, useState } from 'react';
import './warehouse-dashboard.css';
import { WhCardComponent } from './wh-card-component';

export interface WhAvailabilityProps { }

export function WhAvailability() {
    const [stockAvailabilityData, setStockAvailabilityData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getWarehouseCapacity();
    }, [])

    const getWarehouseCapacity = () => {
        service.getWarehouseCapacity({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockAvailabilityData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const whAvalilityBodyCard: CardBodyDetails[] = [];

    let totalCount = 0;

    for (const dataInfo of stockAvailabilityData) {
        console.log(dataInfo.total)
        whAvalilityBodyCard.push(new CardBodyDetails(dataInfo.unitName, Number(dataInfo.assignedCount).toLocaleString("en-IN"), Number(dataInfo.occupiedCount ? dataInfo.occupiedCount : '0').toLocaleString("en-IN"), Number(dataInfo.notOccupiedCount).toLocaleString("en-IN"),Number(dataInfo.total).toLocaleString("en-IN")));
        totalCount += Number(dataInfo.assignedCount)
    }
    const totalCoundBody = new CardHeaderBody('Total Count', totalCount.toString());
    const totalCoundBodyTwo = new CardHeaderBody('', status);

    const whCardHeader = new CardHeader('Racks Availability', [totalCoundBody, totalCoundBodyTwo]);

    const cardDetails = new CardDetail(whCardHeader, whAvalilityBodyCard, ['Unit', ' Assigned', 'Reserved', 'Available','Capacity']);

    return (
            <WhCardComponent cardData={cardDetails} />
    )
}

export default WhAvailability;