import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SubJobRevisionCodesRequest, SubJobSoIdRequest } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import { CodeListMainService } from '@gtpl/shared-services/planning';
import { PlusSquareOutlined, MinusSquareOutlined  } from '@ant-design/icons';
import { ErrorResponse } from '@gtpl/shared-models/common-models';
import { PlantIdRequest } from '@gtpl/shared-models/masters';

export interface SubJobRevisionCodelistProps {
    // isUpdate: boolean
    id: number[];
    updatedTraceCodes: (updatedTraceCodes: SubJobRevisionCodesRequest[]) => void
}

export function SubJobRevisionCodelist(props: SubJobRevisionCodelistProps) {
    const [formRef] = Form.useForm();
    const [page, setPage] = React.useState(1);
    const [codeListData, setCodeListData] = useState<any[]>([])
    const [unitData, setUnitData] = useState<any[]>([])
   // const [totalCartons , setTotalCartons] = useState<Number>(0)
    const service = new SaleOrderService();
    const { Option } = Select;
    const codeListService = new CodeListMainService();
   

    useEffect(() => {
        getSubJobRevisionCodeListData();
        getUnitsForCodeList();
    }, [])

    const getSubJobRevisionCodeListData = () => {
        service.getSubJobRevisionCodeListData(new SubJobSoIdRequest(props.id)).then((res) => {
            if (res.data) {
                setCodeListData(res.data)
                // let tCart = 0;
                // res.data.forEach(el=>{tCart += Number(el.cartons)})
                // setTotalCartons(tCart);
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }
 
    //console.log(Number(totalCartons))

    const editCartons = (e, index, row) => {
        console.log(e)
        console.log(row)
        console.log(e.cartons)
        console.log(index)
        row.cartons = e;
    }

    const editUnit = (e, index, row) => {
        console.log(e)
        console.log(row)
        console.log(index)
        row.unitName = e
        row.unitId = e
        const req = {unitId:row.unitId,mainSoItemId:row.mainSoItemId}
        service.getEditedSoItemData(req).then((res) => {
            if (res.status) {
                row.saleOrderItemId = res.data.soItemId
                // setCodeListData(res.data)
                // let tCart = 0;
                // res.data.forEach(el=>{tCart += Number(el.cartons)})
                // setTotalCartons(tCart);
                // AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });

    }

    const saveData = () => {
        formRef.validateFields().then(values => {
            const response = [];
           // let newTotalCart = 0;
            for (const codeData of codeListData) {
                response.push(codeData)
            }
            // codeListData.forEach(el=>{
            //     newTotalCart += el.cartons
            // })
            // console.log(Number(newTotalCart))
            // if(newTotalCart != totalCartons) return AlertMessages.getInfoMessage('Saved cases did not matched the previous cases')
            console.log(response)
            props.updatedTraceCodes(response)
        })
    }

    const getUnitsForCodeList = () => {
        codeListService.getUnitsForCodeList().then((res) => {
            if (res.data) {
                setUnitData(res.data)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const addRow = (rowData,index)=>{
          const newData = [...codeListData]
        //   codeListData[index+1].codeListMainItemId = null
        let obj = {...codeListData[index],codelistmainItemId:null}
          newData.push(obj)
        //   newData[newData.length-1].codelistmainItemId = null
          console.log(newData)
          setCodeListData(newData)

    }

    const Columns: ColumnProps<any>[] = [
        {
            title: '#Sno',
            key: 'sno',
            //width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Traceability Code',
            dataIndex: 'traceabilityCode',
        },
        {
            title: 'Cases',
            dataIndex: 'cartons',
            render: (text, data, index) => {
                return <span>
                    <Form.Item name={data.codeListMainItemsId} >
                        <InputNumber defaultValue={text} onChange={e => editCartons(e, index, data)} />
                    </Form.Item>
                </span>
            }
        },
        {
            title: 'SO Item',
            dataIndex: 'soItem',
        },
        {
            title: 'Unit',
            dataIndex: 'unitName',
            render: (text, data, index) => {
                console.log(data)
                return <span>
                    <Form.Item name={data.codeListMainItemsId}
                        rules={[
                            {
                                required: false,
                                message: "Select Unit"
                            }
                        ]}>
                        {<Select
                            showSearch
                            placeholder="Select Unit"
                            defaultValue={text}
                            onChange={e => editUnit(e, index, data)}
                        >
                            {unitData?.map(dropData => {
                                return <Option value={dropData.unitId}>{dropData.unitName}</Option>
                            })}
                        </Select>}
                    </Form.Item>
                </span>
            }
        },
        {
            title :'Action',
            dataIndex:'action',
            render: (value, rowData, index) => {
                 return <span>
                            <Button color='blue' size='small' icon={<PlusSquareOutlined  />} onClick={() => { addRow(rowData, index); }} />
                        </span>
            }

        }
    ];

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Sub Job Revision Code list</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <Form form={formRef} >
                    <Table
                        rowKey={record => record.codelistmainItemId}
                        columns={Columns}
                        dataSource={codeListData}
                        pagination={{
                            onChange(current, pageSize) {
                                setPage(current);
                            }
                        }}
                        size='small'
                        bordered />
                </Form>
                <Row justify='end'>
                    <Col span={4} >
                        <Button type="primary" onClick={() => saveData()}>Save</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default SubJobRevisionCodelist;