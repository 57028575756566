import { Table, Card, Input, Button, Row, Col, Form, Select, DatePicker } from 'antd';import React, { useState, useRef, useEffect } from "react";
import {  SearchOutlined, } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { ColumnProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import { AttendanceService } from '@gtpl/shared-services/hrms';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { AttendanceModel, EmployeeAttDateRequest } from '@gtpl/shared-models/hrms';



/* eslint-disable-next-line */
export interface AttandanceProps {}

export const Attandance = (props: AttandanceProps) => {

  const service = new HrmsEmpDetService();
  const attendanceService = new AttendanceService();
  const [empData, setEmpData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any[]>([]);


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [form] = Form.useForm();
  const { RangePicker, MonthPicker } = DatePicker;

  useEffect(()=>{
    form.setFieldsValue({
      fromDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1,'months')),moment(moment().format("YYYY-MM-DD"))] 
    })
    setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1,'months')))
    setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
  }, [])

  const EstimatedETDDate = (value) => {
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
      console.log(fromDate)
      console.log(toDate)
    }
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const onReset = () => {
    form.resetFields();
    setSelectedEstimatedFromDate(undefined);
    setSelectedEstimatedToDate(undefined);
    setReportData([]);
  }

  useEffect(() => {
  //  getAllActiveEmployees();

     getAllEmpDateAtten();
  }, []);


  const getAllEmpDateAtten = (onReset?: boolean) => {
    setDisable(true);
    setLoading(true);
    let employeeCode = form.getFieldValue('employeeCode');
    let startDate = onReset ? undefined : selectedEstimatedFromDate;
    let endDate = onReset ? undefined : selectedEstimatedToDate;
    const req = new EmployeeAttDateRequest(employeeCode, startDate, endDate);
    attendanceService.getAllEmpDateAtten(req).then(res => {
      setDisable(false)
      if (res.status) {
        setReportData(res.data)
        setLoading(false);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setReportData([]);
        } else {
          setLoading(false);
        }
      }
    }).catch(err => {
      setReportData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  // const getAllActiveEmployees = () => {
  //   service.getAllActiveEmployees().then((res) => {
  //     if (res.status) {
  //       console.log(res.data)
  //       setEmpData(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //       setEmpData([]);
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setEmpData([]);
  //   })
  // }


  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      width: '150px',
      fixed: 'left',
      align: 'center',
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '150px',
      sorter: (a, b) => a.date?.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.date ? moment(record.date).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'Day',
      dataIndex: 'day',
      width: '150px',
      render:(text,record)=>{return moment(record.date).format('dddd')},
      sorter: (a, b) => a.day?.localeCompare(b.targetday),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Day Status',
      dataIndex: 'dayStatus',
      width: '150px',
      filters: [
        {
          text: 'Working Day',
          value: 'workingDay',
        },

        {
          text: 'Holiday',
          value: 'holiday',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.dayStatus === value;
      },
    },

    {
      title: 'Attendence Status',
      dataIndex: 'presentStatus',
      width: '150px',
      filters: [
        { text: 'PRESENT', value: 'P'},
        { text: 'WORKFROMHOME', value: 'WFH'},
        { text: 'ABSENT', value: 'A' },
        { text: 'LEAVE', value: 'L' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.presentStatus === value;
      },
      sorter: (a, b) => a.presentStatus?.localeCompare(b.presentStatus),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Leave Status',
      dataIndex: 'leaveStatus',
      width: '150px',
      sorter: (a, b) => a.leaveStatus?.localeCompare(b.leaveStatus),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Shift Group',
      dataIndex: 'shiftGroup',
      width: '150px',
      sorter: (a, b) => a.shiftGroup?.localeCompare(b.shiftGroup),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Work Station',
      dataIndex: 'workStation',
      width: '150px',
      sorter: (a, b) => a.workStation?.localeCompare(b.workStation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Shift time',
      dataIndex: 'shiftTime',
      width: '150px',
      sorter: (a, b) => a.shiftTime?.localeCompare(b.shiftTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'In Time',
      dataIndex: 'inTime',
      width: '150px',
      render: (text, record) => { return moment(record.inTime).format('HH:mm') },
      sorter: (a, b) => a.inTime?.localeCompare(b.inTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Out time',
      dataIndex: 'outTime',
      width: '150px',
      render: (text, record) => { return moment(record.outTime).format('HH:mm') },
      sorter: (a, b) => a.outTime?.localeCompare(b.outTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      width: '150px',
      render:(text,record)=>{ return moment.utc(moment(record.outTime).diff(moment(record.inTime))).format("HH:mm")},
      sorter: (a, b) => a.workingHours?.localeCompare(b.workingHours),
      sortDirections: ['descend', 'ascend'],
    },
      
  ];
  return (
    <div>  
       <Card title={<span style={{color:'white'}}>Attandance</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/menu' ><span style={{color:'white'}} ><Button className='panel_button' >Menu</Button> </span></Link>}   >
     <br></br>  
     <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="fromDate"
              label="ETD"
              rules={[
                {
                  required: false,
                  message: " ETD Date"
                }, 
              ]}>
                <RangePicker onChange={EstimatedETDDate} />
            </Form.Item>
          </Col> 
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getAllEmpDateAtten()}>
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          </Row> 
          </Card>
      <Table
        rowKey={record => record.Id}
        columns={sampleTypeColumns}
         dataSource={reportData}
        pagination={{ onChange(current) { setPage(current); } }}
        scroll={{ y: 500 }}
        size="small"
        bordered
       
      />

</div>
  
  );  
}

export default Attandance;
