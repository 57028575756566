import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CountriesForm} from '@gtpl/pages/master/master-components/countries-form';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
// import { Countries } from '@gtpl/shared-models/masters';
import { FormattedMessage } from 'react-intl';
import {CountryService} from '@gtpl/shared-services/masters';
import {CountryDto,CountryDtoDefault} from '@gtpl/shared-models/masters';
//import './item-type-grid.css';
//import GetCumulatives, { CumulativeModel,CumulativeTypes } from './get-cumulatives';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface ItemTypeGridProps {}

import './countries-grid.css';
import { Link } from 'react-router-dom';


/* eslint-disable-next-line */
export interface CountriesGridProps {}

// const data:Countries[] = [
//   {
//     CountryId : 1,
//     CountryName: 'Shrimp',
//     isActive:true,    
//   },
//   {
//     CountryId: 2,
//     CountryName: 'General',
//     isActive:true,

//   },
//   {
//     CountryId: 3,
//     CountryName: 'Fish',
//     isActive:false,
//   }
// ];

export function CountriesGrid() {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [defaultData, setDefaultData] = useState<Countries>(new Countries(null,null,null));
  const [countryData, setCountryData] = useState<CountryDto[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(CountryDtoDefault);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new CountryService;

  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      // responsive: ['lg'],
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('countryName')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        rowData.countryName.trim().toLowerCase()=="india"?<span></span>:
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Country');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteCountry(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Country ?'
                :  'Are you sure to Activate Country ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {getAllCountries();}, [])
  const getAllCountries= () => {
    const value = new UserRequestDto(JSON.parse(localStorage.getItem('username')))
    service.getAllCountries(value).then(res => {
      if (res.status) {setCountryData(res.data);
      } else {
        if (res.intlCode) {
          setCountryData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);  
      setCountryData([]);
    })
  }


  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(CountryViewData: CountryDto)=>{
    setDrawerVisible(true);
    setSelectedCountry(CountryViewData);
  }

   
    const saveCountry = (countryData: CountryDto) => {
      countryData.countryId=0;
      countryData.isActive=true;
      service.createCountry(countryData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Country Created Successfully');
          getAllCountries();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
    /**
     * 
     * @param variantData 
     */
    const updateCountry = (countryData: CountryDto) => {
      countryData.updatedUser = JSON.parse(localStorage.getItem('username'))
      service.updateCountry(countryData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllCountries();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    /**
     * 
     * @param VariantViewData 
     */
    const deleteCountry = (countryViewData:CountryDto) => {
      countryViewData.isActive=countryViewData.isActive?false:true;
      service.ActivatedeActivateCountry(countryViewData).then(res => { console.log(res);
        if (res.status) {
          getAllCountries();
          AlertMessages.getSuccessMessage('Success'); 
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  return (
    <Card title={<span style={{color:'white'}}>Countries</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/Countries-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
     <Row gutter={40}>
      <Col>
          <Card title={'Total Countries: ' + countryData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + countryData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + countryData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
        <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
          rowKey={record => record.itemName}
          columns={columnsSkelton}
          dataSource={countryData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll = {{x:true}}
          onChange={onChange}
          size='small'
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <CountriesForm key={Date.now()}
                updateItem={updateCountry}
                isUpdate={true}
                // saveItem={saveVariant}
                countryData={selectedCountry}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}

export default CountriesGrid;

