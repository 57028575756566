import { ServiceStatusEnum } from "@gtpl/shared-models/common-models";
import { ApiProperty } from "@nestjs/swagger";

export class MachineInwordReq {
   id:number;
   machinedescription: string;
   barcode:string;
   transferDate: any;
  transferStartDate?: any;
  transferEndDate?: any;
}