import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {VendorsDto} from '@gtpl/shared-models/masters';
import {VendorsService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined, CheckOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {VendorsForm} from '@gtpl/pages/master/master-components/vendors-form';
import { Link, Redirect, useLocation } from 'react-router-dom';



import './vendors-grid.css';
import { GlobalStatus } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface VendorsGridProps {}

export function VendorsGrid(
  props: VendorsGridProps
) {
  
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [vendorsData, setVendorsData] = useState<VendorsDto[]>([]);
  const [selectedVendorsData, setSelectedVendorsData] = useState<any>(undefined);
  const [vendorview, setVendorView]=useState(false);
  const [vendorIds, setVendorIds]=useState();
  const vendorsService=new VendorsService();

  useEffect(() => {
    getAllVendors();
  }, []);

  /**
   * 
   */
  const getAllVendors= () => {
    vendorsService.getAll().then(res => {
      if (res.status) {
        setVendorsData(res.data);
      } else {
        if (res.intlCode) {
            setVendorsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setVendorsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param unitcodeData 
   */
  const deleteVendor = (vendorsData:VendorsDto) => {
    vendorsData.isActive=vendorsData.isActive?false:true;
    vendorsService.activatedeActivate(vendorsData).then(res => { console.log(res);
      if (res.status) {
        getAllVendors();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   

    const updateVendor = (vendorData: VendorsDto) => {
      vendorsService.update(vendorData).then(res => { console.log(res);
        if (res.status) {
          getAllVendors();
          setDrawerVisible(false);
          AlertMessages.getSuccessMessage('Updated Successfully');
          
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
    const getColumnSearchProps = (dataIndex:string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={ searchInput }
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex]
         .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {    setTimeout(() => searchInput.current.select());   }
      },
      render: text =>
        text ?(
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) :text
        )
        : null
       
    });
  
    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
  
      //drawer related
      const closeDrawer=()=>{
        setDrawerVisible(false);
      }
    
      //TO open the form for updation
      const openFormWithData=(viewData: VendorsDto)=>{
        viewData.createdUser = localStorage.getItem('createdUser');
        setDrawerVisible(true);
        setSelectedVendorsData(viewData);
      }
  
  

      const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        
        // {
        //   dataIndex:"vendorCode",
        //   title:"VendorCode",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('vendorCode')
        // },
        {
          dataIndex:"vendorName",
          title:"Vendor Name",
          // responsive: ['lg'],
          sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('vendorName')
        },
        {
          dataIndex:"priceNeeded",
          title:"Price Needed",
          // responsive: ['lg'],
          filters: [
            {
              text: 'YES',
              value: GlobalStatus.YES,
            },
            {
              text: 'NO',
              value: GlobalStatus.NO,
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => 
          {
            // === is not work
            return record.priceNeeded === value;
          },
          
        },
        
        // {
        //   dataIndex:"website",
        //   title:"Website",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('website')
        // },
        {
          dataIndex:"contactPerson",
          title:"Contact Person",
          // responsive: ['lg'],
          sorter: (a, b) => a.contactPerson.localeCompare(b.contactPerson),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('contactPerson')
        },
        {
          dataIndex:"contactNumber",
          title:"Contact Number",
          // responsive: ['lg'],
          sorter: (a, b) => a.contactNumber.localeCompare(b.contactNumber),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('contactNumber')
        },
        // {
        //   dataIndex:"street",
        //   title:"Street",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('street')
        // },
        // {
        //   dataIndex:"apartment",
        //   title:"Apartment",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('apartment')
        // },
        // {
        //   dataIndex:"city",
        //   title:"City",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('city')
        // },
        // {
        //   dataIndex:"postalCode",
        //   title:"PostalCode",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('postalCode')
        // },
        {
          dataIndex:"countryName",
          title:"Country",
          // responsive: ['lg'],
          sorter: (a, b) => a.countryName.localeCompare(b.countryName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('countryName')
        },
        {
          dataIndex:"currencyName",
          title:"Currency",
          // responsive: ['lg'],
          sorter: (a, b) => a.currencyName.localeCompare(b.currencyName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('currencyName'),
        },
        // {
        //   dataIndex:"privateNote",
        //   title:"Private Note",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('privateNote')
        // },
        // {
        //   dataIndex:"publicNote",
        //   title:"PublicNote",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('publicNote')
        // },
        // {
        //   dataIndex:"bankAccNo",
        //   title:"BankAccNo",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('bankAccNo')
        // },
        // {
        //   dataIndex:"bankIfsc",
        //   title:"BankIfsc",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('bankIfsc')
        // },
        // {
        //   dataIndex:"bankName",
        //   title:"BankName",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('bankName')
        // },
        // {
        //   dataIndex:"bankBranch",
        //   title:"BankBranch",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('bankBranch')
        // },

        // {
        //   dataIndex:"emailId",
        //   title:"EmailId",
        //   // responsive: ['lg'],
        //   sorter: (a, b) => a.deptName.length - b.deptName.length,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('emailId')
        // },

  
       
        {
          title: 'Status',
          dataIndex: 'isActive',
          render: (isActive, rowData) => (
            <>
              {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
              
            </>
          ),
          filters: [
            {
              text: 'Active',
              value: true,
            },
            {
              text: 'InActive',
              value: false,
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => 
          {
            // === is not work
            return record.isActive === value;
          },
          
        },
        {
          title:`Action`,
          dataIndex: 'action',
          render: (text, rowData) => (
            <span>   
                 
                <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                  onClick={() => {
                    if (rowData.isActive) {
                      console.log(rowData);
                      openFormWithData(rowData);
                    } else {
                      AlertMessages.getErrorMessage('You Cannot Edit Deactivated Vendor');
                    }
                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                />

              <Divider type="vertical" />
              {
                    (vendorIds)?<Redirect
                to={{
                  pathname: "/vendorsPriceListForm",
                  state:vendorIds
                }} 
              />:''
            }  

              <CheckOutlined className={'editSamplTypeIcon'}  type="edit" 
                  onClick={() => {
                    if (rowData.isActive) {
                      setVendorIds(rowData.vendorId)
                    } else {
                      AlertMessages.getErrorMessage('You Cannot perform this Operation');
                    }
                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}/>
              
              
              <Divider type="vertical" />
                <Popconfirm onConfirm={e =>{deleteVendor(rowData);}}
                title={
                  rowData.isActive
                    ? 'Are you sure to Deactivate vendor ?'
                    :  'Are you sure to Activate vendor ?'
                }
              >
                <Switch  size="default"
                    className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                    checkedChildren={<RightSquareOutlined type="check" />}
                    unCheckedChildren={<RightSquareOutlined type="close" />}
                    checked={rowData.isActive}
                  />
                
              </Popconfirm>
            </span>
          )
        }
      ];
    
      /**
       * 
       * @param pagination 
       * @param filters 
       * @param sorter 
       * @param extra 
       */
      const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
      const OpenFormTocreateRecord = () => {
       console.log('redirect here');
       
      }
    


  return (
    <>
    <Card title={<span style={{color:'white'}}>Vendors</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/vendors-form' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Vendors : ' + vendorsData.length} style={{textAlign: 'left', width: 210, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + vendorsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + vendorsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={vendorsData}
          scroll={{ x:500 }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <VendorsForm key={Date.now()}
              updateDetails={updateVendor}
                isUpdate={true}
                vendorsData={selectedVendorsData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>

     </>
  );
}

export default VendorsGrid;
