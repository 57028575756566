
import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {UomsForm} from '@gtpl/pages/master/master-components/uoms-form';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ItemVariant } from '@gtpl/shared-models/masters';
import { FormattedMessage } from 'react-intl';
import {UomsService} from '@gtpl/shared-services/masters';
import {UomsDto} from '@gtpl/shared-models/masters';
import './uoms-grid.css'
import { Link } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


export interface UomsGridProps {}

export function UomsGrid() {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [uomsData, setUomsData] = useState<UomsDto[]>([]);
  const [selectedUoms, setSelectedUoms] = useState<any>(undefined);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
 
  const service = new UomsService;

  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'UOMs Name',
      dataIndex: 'uomName',
      // responsive: ['lg'],
      sorter: (a, b) => a.uomName.localeCompare(b.uomName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('uomName')
    },
    {
      title: 'UOMs Type',
      dataIndex: 'uomType',
      // responsive: ['lg'],
      sorter: (a, b) => a.uomType.length - b.uomType.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('uomType')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated UOM');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteUoms(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate UOM ?'
                :  'Are you sure to Activate UOM ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {getAllUoms();}, [])
  // const getAllUoms= (new UserRequestDto(JSON.parse(localStorage.getItem('username')))) => {
  //   service.getAllUoms().then(res => {
  //     if (res.status) {setUomsData(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         setUomsData([]);
  //           AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //        AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setUomsData([]);
  //   })
  // }
  const getAllUoms= () => {
    service.getAllUoms(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      console.log(res);
      if (res.status) {
        setUomsData(res.data);
      } else {
        if (res.intlCode) {
          setUomsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setUomsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(UomsViewData: UomsDto)=>{
    setDrawerVisible(true);
    setSelectedUoms(UomsViewData);
  }

   
    const saveUoms = (uomsData: UomsDto) => {
      uomsData.uomId=0;
      uomsData.isActive=true;
      service.createUoms(uomsData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('UOMs Created Successfully');
          getAllUoms();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
    /**
     * 
     * @param variantData 
     */
    const updateUoms = (uomsData: UomsDto) => {
      uomsData.updatedUser= JSON.parse(localStorage.getItem('username'))
      service.updateUoms(uomsData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllUoms();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    /**
     * 
     * @param uomsViewData 
     */
    const deleteUoms = (uomsViewData:UomsDto) => {
      uomsViewData.isActive= uomsViewData.isActive?false:true;
      service.ActivatedeActivateUoms(uomsViewData).then(res => { console.log(res);
        if (res.status) {
          getAllUoms();
          AlertMessages.getSuccessMessage('Success'); 
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  
  return (
    <Card title={<span style={{color:'white'}}>UOMs</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/UOMs-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
     <br></br>
     <Row gutter={40}>
      
          <Card title={'Total UOMs: ' + uomsData.length} style={{textAlign: 'left', width: 190, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          
           <Card title={'Active: ' + uomsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 190, height: 41,backgroundColor:'#52c41a'}}></Card>
          
           <Card title={'In-Active: ' + uomsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 190, height: 41,backgroundColor:'#f5222d'}}></Card>
          
          </Row><br></br>
      <Card >

      
        <Table
          rowKey={record => record.uomId}
          columns={columnsSkelton}
          dataSource={uomsData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll = {{x:true}}
          onChange={onChange}
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <UomsForm key={Date.now()}
                updateItem={updateUoms}
                isUpdate={true}
                // saveItem={saveVariant}
                uomsData={selectedUoms}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}

export default UomsGrid;




