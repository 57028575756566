import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker, Upload, Typography, UploadProps } from 'antd';
import './container-register.css';
import { Redirect,useLocation,useHistory } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService, DocumentsListService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput, DocumentType } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo, DocumentsListRequest } from '@gtpl/shared-models/logistics';
import Link from 'antd/lib/typography/Link';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService} from '@gtpl/shared-services/masters';


/* eslint-disable-next-line */
export interface FillSealInfoProps { }
const { Option } = Select;
export function FillSealInfo(
  props: FillSealInfoProps
) {

  let location = useLocation();
  const containerStatusInfo: ContainerStatusInfo | any = location.state;
  const [containerRegisterForm] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [filelist, setfilelist] = useState([]);
  const [fieldsData, setFieldsData] = useState<DocumentsListRequest>(undefined);

  const [saleOrderData, setSaleOrderData] = useState<any[]>([]);
  const [tab, setTab] = useState<string>(undefined);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);

  const SoService = new SaleOrderService();
  const containerRService = new ContainerRegisterService();
  const employeeService = new HrmsEmpDetService();

  const history = useHistory();

  useEffect(() => {
    getSaleOrderData();
    getLogisticsPersonsData();
    if (containerStatusInfo) {
      containerRegisterForm.setFieldsValue({
          saleOrderId: containerStatusInfo.saleOrderId,
          containerStatus: ContainerStatusEnum.LOADED_AND_SEALED,
          containerId:containerStatusInfo.containerId,
          containerNo:containerStatusInfo.containerNo,
          scac:containerStatusInfo.scac
      });
  } else {
      containerRegisterForm.setFieldsValue({
          containerStatus: ContainerStatusEnum.LOADED_AND_SEALED
      });
  }
}, [containerStatusInfo]);

  // get sale order drop down details
  const getSaleOrderData = () => {
    SoService.getSoPoBrandDropDownList().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getLogisticsPersonsData = () => {
    employeeService.getActiveEmployeesByDept({employeeDept:"logistics"}).then((res) => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setEmployeeData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeData([]);
    });
  }
  const appendInputData = (val, label) => {
    console.log(val);
    console.log(label);
      setFieldsData({ ...fieldsData, [label]: val });
  }
  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setfilelist([]);
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(xls|pdf|jpg|png|jpeg|doc|zip)$/)) {
        AlertMessages.getErrorMessage("Only xls,pdf,jpg,png,doc,jpeg,zip files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
          console.log(file);
          setfilelist([...filelist, file]);
          return false;
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };
  const onReset = () => {
    containerRegisterForm.resetFields();
    if (containerStatusInfo) {
      containerRegisterForm.setFieldsValue({
          saleOrderId: containerStatusInfo.saleOrderId,
          containerStatus: ContainerStatusEnum.LOADED_AND_SEALED,
          containerId:containerStatusInfo.containerId,
          containerNo:containerStatusInfo.containerNo,
          scac:containerStatusInfo.scac
      });
  } else {
      containerRegisterForm.setFieldsValue({
          containerStatus: ContainerStatusEnum.LOADED_AND_SEALED
      });
  }
  }
  const documentsListService = new DocumentsListService();

  const saveData = (values) => {
    console.log(values);
    values.createdUser=localStorage.getItem('createdUser');
    values.updatedUser=localStorage.getItem('createdUser');
    containerRService.fillSealInfo(values).then(res => {
      console.log(res);
        if (!res.status) {
          console.log("hi");
          throw new Error(res.internalMessage);
        }else{
          console.log(values.saleOrderId);
          console.log(fieldsData);
            if(fieldsData != undefined){
              fieldsData.saleOrderId = values.saleOrderId;
              fieldsData.documentCategory = "Fill Seal";
              fieldsData.documentType = DocumentType.PRELOADING;
              fieldsData.file = values.file===undefined?"":values.file.fileList;
              documentsListService.createDocumentsList(fieldsData).then((res) => {
                console.log(res);
                if(res.status){
                  console.log(filelist);
                  if (filelist.length > 0) {
                    const formData = new FormData();
                    formData.append('documentCategory', `${res.data[0].documentCategory}`);
                    formData.append('saleOrderId', `${res.data[0].saleOrderId}`);
                    res.data.forEach((value) => {
                      formData.append('documentsListId', `${value.documentsListId}`);
                    })
        
                      filelist?.forEach((file: any) => {
                      formData.append('file', file);
                    });
                      console.log(formData);
                      documentsListService.documentFileUpload(formData).then((res) => {
                        console.log(res);
                        // if(res.status){
                        // }
                      })
                  }
                  AlertMessages.getSuccessMessage(res.internalMessage);
                } else {
                if (res.intlCode) {
                  AlertMessages.getErrorMessage(res.internalMessage);
                } else {
              console.log(res);

                  AlertMessages.getErrorMessage(res.internalMessage);
                }
              }
            }).catch((err) => {
              AlertMessages.getErrorMessage(err.message);
            });
          }
              goToContainerGrid();
            AlertMessages.getSuccessMessage(res.internalMessage);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
    })
}
const goToContainerGrid = () => {
  setTab("Loading");
}


  return (
    <>
    {tab ? <Redirect to={{pathname:"/container-register-grid", state:tab}}/>:null}

    <Card title={<span style={{ color: 'white' }}>Fill Seal Info</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link onClick={e => goToContainerGrid()} ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form layout="vertical" form={containerRegisterForm} name="control-hooks" onFinish={saveData} >
        <Row gutter={24}>
        <Form.Item name="containerId">
                        <Input hidden />
          </Form.Item>
        <Form.Item name="scac">
                        <Input hidden />
          </Form.Item>
          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  >
            <Form.Item
              name="loadingDate"
              label="Loading Date"
              rules={[{ required: true, message: 'Missing Loading Date' },
              ]} initialValue={moment()}>

              <DatePicker style={{ width: '100%' }} />

            </Form.Item>
          </Col>
          

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Container Number" name="containerNo" rules={[{ required: true, message: 'Missing Container Number' }]} >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }}>
            <Form.Item name="saleOrderId" label="SO Number" >
              <Select
                placeholder="Select SaleOrder"
                allowClear
                disabled
              >
                <Option value={''}>Please Select</Option>
                {saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{`${dropData.poNumber}-${dropData.brandName}-${dropData.variantCode}`}</Option>

                  // return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
         

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Line Seal" name="lineSeal" rules={[{ required: true, message: 'Missing Line Seal' }]}>
              <Input />
            </Form.Item>

          </Col>



        </Row>

        <Row gutter={24}>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item label="E-Seal" name="eSeal" rules={[{ required: false, message: 'Missing E-Seal' }]}>
              <Input />
            </Form.Item>
          </Col> */}


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Vehicle Number" name="vehicleNumber"
            rules={[
              {
                // pattern: /^[^-\s][Z0-9_\s-]*$/,
                // pattern: /^[^-\s][a-zA-Z_\s-]*$/,
                required: true ,
                message: `vehicle number is missing`,
                
              }
            ]}>
              <Input />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Driver Name" name="driverName" rules={[
                      {
                        // pattern: /^[^-\s][Z0-9_\s-]*$/,
                        pattern: /^[^-\s][a-zA-Z_\s-]*$/,
                        
                        message: `Don't Allow numbers and Spaces`,
                        
                      }
                    ]}>
              <Input />
            </Form.Item>

          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item  label="Driver Phone Number" name="driverPhoneNumber"  rules={[
                      
                        { required: false, message: ' Valid Mobile No is required' , max:12},
                      {
                        pattern: /^[^-\s][Z0-9_\s-]*$/,
                        message: `Don't Allow letters and Spaces`,
                        
                      }
                    ]}>
              <Input />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Assigned By" name="assignedBy">
              <Input />
            </Form.Item>

          </Col>
           {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item name="assignedBy" label="Assigned By"
                rules={[
                  {
                    required: true, message: 'Missing Assigned ',
                  },
                ]}
              >
                 <Select
                  placeholder="Select Logistics Assigned"
                  // onChange={handleSaleOrder}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employeeData.map(dropData => {
                  return <Option value={dropData.employeeName}>{`${dropData.employeeName}`}</Option>
                })}
                </Select>
              </Form.Item>
            </Col> */}

        </Row>

        <Row gutter={24}>
          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>
                <Form.Item
                  name="containerStatus"
                  label="Container Status"
                  rules={[
                    {
                      // required: true,message: 'Missed Container Status'
                    },
                  ]}>
                    <Select
                    placeholder="Select Container Status"
                    disabled
                    >
                    <Option key={0} value={null}>Select Container Status</Option>
                    {ContainerStatusInput.map((container) => {
                      return <Option key={container.id} value={container.id}>{container.status}</Option>
                    })}
                    </Select>
                </Form.Item>
          </Col>

          {/* <Col span={6}>
            <Form.Item name="file" label="Upload Fill Seal Images" rules={[{ required: false, message: 'Please Upload File' },]}>
                <Upload {...uploadFieldProps} style={{  width:'100%' }} onChange={e => appendInputData(e.file.name, 'file')}>
                    <Button style={{ color: "white", backgroundColor: "#806767", width:'100%' }} icon={<UploadOutlined />} >Upload Document</Button><Typography.Text  type="secondary">
                     (Supported formats xls,pdf,jpg,png,doc,jpeg)
                </Typography.Text>
                </Upload>
            </Form.Item>
          </Col> */}
          <Col style={{ paddingTop: '30px' }}>

            <Button type="primary" htmlType="submit" >
              Submit
              </Button>
              {(isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
          </Col>
        </Row>
      </Form>
    </Card>
    </>
  );
}

export default FillSealInfo;
