import { Form, Input, Button, Space, Card, Select, Col, Row, Table, Empty, InputNumber, Tooltip, Divider, Modal, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { CategoryEnum, CategoryItemReq, FoodTypesDropDownData, FoodTypesDto, GetValueAdditionDataBySkuCodesRequest, GradesDropDownData, ItemsDto, PackingMethodDto, ProductDto, ProductsDropDownData, SizesDropDownDto, SizesDto, SkuDropDownData, SkuDto, TypeOfValueAddition4Dto, UomsDropDownDto, UomsDto, ValAddFourDropDownData, ValAddOneDropDownData, ValAddThreeDropDownData, ValAddTwoDropDownData } from '@gtpl/shared-models/masters';
import { GradeServices, ProductService, FoodTypeService, SkuService, PackingMethodService, UomsService, TypeofValueAddition4Service, SizesService, ItemsService } from '@gtpl/shared-services/masters';
import { SizesForm } from '@gtpl/pages/master/master-components/sizes-form';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { CodeListMainService, CodeListService } from '@gtpl/shared-services/planning';

import './so-design-form.css';


import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CategoriesInput, CrrencySymbols, PackTypeCategory, SaleOrderItemsInfo, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { Route } from 'react-router-dom';
import { JobTypesEnum, PackingCategoryEnum, SoStatusEnum, UomEnum, UserRequestDto } from '@gtpl/shared-models/common-models';
import { CodeListMainIdRequest } from '@gtpl/shared-models/planning';
import CodeListAllocationForm from './code-list-allocation-form';

const { Option } = Select;

export interface ItemDetailsFormProps {
  addDataToItemDetails: (itemDetalFormdata: any) => void;
  mastesrBrandData: (masterBrand: number) => void;
  brandsData: any[]
  category: string
  initialData: SaleOrderItemsInfo[]
  soStatus: string
  packCategory: string
  currency: number
  updateCurrency: number
  jobType:string
  isCasesUpdate:(isCasesUpdate:boolean) => void
  isCodelistAllocated:(isCodelistAllocated:boolean) => void
  codeListData:(codeListData:any) => void
}


const ItemDetailsForm = (props: ItemDetailsFormProps) => {
  let unitOfWeights = UnitsOfWeightInput;
  let categories = CategoriesInput;


  const [form] = Form.useForm();
  const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [traceCodes, setTraceCodes] = useState<any[]>([]);
  const [productsdata, setPruseEffectoductdata] = useState<ProductsDropDownData[]>([]);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [soItemId, setSoItemId] = useState<number>(0);
  const [codeListSoId, setCodeListSoId] = useState<number>();
  const [subJobCases, setSubJobCases] = useState<number>();
  const [priceLabel, setPriceLabel] = useState<string>('');
  const [fieldUpdateKey, setFieldUpdateKey] = useState<number>(1);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [codeListBtn, setCodeListBtn] = useState(true);
  const [isCodeListModalVisible, setIsCodeListModalVisible] = useState(false);
  const [productsBtnDisable, setProductsBtnDisable] = useState<boolean>(false);
  const [subPoDisable, setsubPoDisable] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [foodTypeData, setFoodType] = useState<FoodTypesDto[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [updateTableData, setUpdateTableData] = useState<any[]>([]);
  const [fieldsData, setFieldsData] = useState<any>({
    palletCapacity: 0,
    product: '',
    foodType: '',
    soakingTime: 120,
    innerBags: 0,
    innerBagSizeId: undefined,
    shrimpWeight: 0,
    lbnetWeight: 0,
    kgnetWeight: 0
  });

  const [updateFieldsdata, setupdateFieldsdata] = useState<any>({
    product: '',
    foodType: '',
    soakingTime: 120,
    innerBags: 0,
    shrimpWeight: 0,
    lbnetWeight: 0
  });
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [updatedefaultFormData, setUpdateDefaultFormData] = useState(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [codeListAllocated, setCodeListAllocated] = useState<boolean>(false);
  const [callItemState, setCallItemState] = useState<boolean>(false);
  const [masterBrandId, setMasterBrandId] = useState<number>(0);
  const [category, setCategory] = useState<string>('');

  const [isInnerBagsHidden, setIsInnerBagsHidden] = useState<boolean>(true);
  const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [isInnerBagsizeHidden, setIsInnerBagsizeHidden] = useState<boolean>(true);
  const [isShrimpWeightHidden, setIsShrimpWeightHidden] = useState<boolean>(true);
  const [packingStyles, setPackingStyke] = useState<ProductsDropDownData[]>([]);
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [packingMethods, setPackingMethods] = useState<PackingMethodDto[]>([]);

  const [weighingStyle, setWeighingStyle] = useState<UomsDto[]>([]);
  const [freezeStyles, setFreezeStyles] = useState<TypeOfValueAddition4Dto[]>([]);
  const [updateDisableField, setUpdateDisableField] = useState<boolean>(false);
  const productService = new ProductService();
  const codeListMainService = new CodeListMainService()
  const foodTypeService = new FoodTypeService();
  const skuService = new SkuService();
  const pmService = new PackingMethodService();
  const uomsService = new UomsService();
  const freezeStleService = new TypeofValueAddition4Service();
  const sizeService = new SizesService();
  const itemService = new ItemsService();

  let history = useHistory();

  useEffect(()=>{
    if(props.jobType == JobTypesEnum.SUB_JOB){
      console.log(props.initialData)
      console.log(props.jobType)
      props.isCasesUpdate(false)
      setsubPoDisable(true)
      setProductsBtnDisable(true)
    }else{
      setProductsBtnDisable(false)
    }
  },[props.jobType])

  useEffect(() => {
    if (fieldsData) {
      console.log(fieldsData);

    }
    // console.log(minGrade);
  }, [fieldsData])

  const codeListData = (traceCodesData) => {
    console.log(traceCodesData)
    props.codeListData(traceCodesData)
    // setFieldsData({ ...fieldsData, traceCodesData: traceCodesData})
    props.isCodelistAllocated(true)
    setIsCodeListModalVisible(false)
  }




  useEffect(() => {
    console.log(props.brandsData)
    console.log(props.currency)

    if (defaultFormData) {
      console.log(defaultFormData);
      const selectedUom = unitOfWeights.find(item => item.value == defaultFormData.uomId);
      if (!isUpdate && defaultFormData.category.children == PackTypeCategory.SKEWERS) {
        console.log('haaaaaa')
        setIsInnerBagsHidden(false)
        setIsInnerBagsizeHidden(false)
      }else if((!isUpdate && defaultFormData.category.children == PackTypeCategory.RING) || (!isUpdate && defaultFormData.category.children == PackTypeCategory.PLATTER)){
        setIsInnerBagsHidden(true)
        setIsInnerBagsizeHidden(false)
      }else{
        setIsInnerBagsHidden(true)
        setIsInnerBagsizeHidden(true)
      }

      form.setFieldsValue({
        category: !isUpdate ? defaultFormData.category.children : defaultFormData.category,
        Brand: !isUpdate ? defaultFormData.brandId.children : defaultFormData.brandId,
        packStyleId: !isUpdate ? defaultFormData.uomId.packMethodName : defaultFormData.packStyleId,
        // Grade: defaultFormData.Grade,
        // maxGrade: defaultFormData.maxGrade,
        itemId: !isUpdate ? defaultFormData.itemId : defaultFormData.itemId,
        // foodTypeId: defaultFormData.foodTypeId.children,
        // freezeStyle: defaultFormData.freezeStyle.children,
        varientCode: !isUpdate ? defaultFormData.varientId.children : defaultFormData.varientId,
        glazzing: { 'minGlaze': defaultFormData.minGlaze, 'maxGlaze': !isUpdate ? defaultFormData.MaxGlaze : defaultFormData.MaxGlaze },
        grade: { 'minGrade': defaultFormData.minGrade, 'maxGrade': defaultFormData.maxGrade },
        packStyle: { 'pouches': defaultFormData.pouches, 'pouchWeight': defaultFormData.pouchWeight },
        WeighingType: !isUpdate ? defaultFormData.uomId.children : selectedUom.name,
        netwgtcnt: !isUpdate ? defaultFormData.styleWeighment.children : defaultFormData.styleWeighment,
        innerBags: defaultFormData.innerBags,
        shrimpWeight: defaultFormData.shrimpWeight,
        sockingStyleId: !isUpdate ? defaultFormData.soakingStyle : defaultFormData.soakingStyle,
        soakingTime: defaultFormData.soakingTime,
        Cost: defaultFormData.unitPrice,
        cases: defaultFormData.jobCases,
        caseWeight: !isUpdate ? defaultFormData.caseWeight : defaultFormData.caseWeight,
        netWeight: defaultFormData.netWeight,
        netAmount: defaultFormData.netAmount,
        innerBagSizeId: !isUpdate ? defaultFormData.innerBagSizeId?.children : defaultFormData.innerBagSizeId

      });
      setFieldsData(defaultFormData);
      // const test=form.getFieldsValue();
      // form.setFieldsValue({...test, glazzing['minGlaze']: defaultFormData.minGlaze,
      // glazzing['maxGlaze']: defaultFormData.maxGlaze})
    }


  }, [defaultFormData]);

  // useEffect(() => {
  //   if(callItemState){
  //     saveItemSize();
  //   }
  // }, [fieldsData])

  const showCodeListModal = () => {
    setIsCodeListModalVisible(true);

  };
  const allocateCodeList = (rowData: any) => {
    console.log(rowData)
    setSubJobCases(rowData.cases)
    setSoItemId(rowData.saleOrderItemId)
    // setSoData(rowData)
    setIsCodeListModalVisible(true)

  }

  const showModal = () => {
    setIsModalVisible(true);
    if(form.getFieldValue('category')?.catId){
      getSizes(form.getFieldValue('category')?.catId);
    }

  };

  const getTracebilityCodes = (soId) => {
    const soreq = new CodeListMainIdRequest();
    soreq.saleOrderId = soId
    console.log(soreq)
    codeListMainService.geTracebilityCodeForSoId(soreq).then((res) => {
      if (res.status) {
        console.log(res.data)
        setTraceCodes(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setTraceCodes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setTraceCodes([]);
    });
  }


  const handleOk = () => {
    setIsModalVisible(false);
    if(form.getFieldValue('category')?.catId){
      getSizes(form.getFieldValue('category')?.catId);
    }
    // getSizes();

  };
  const handleCodeListModalOk = () => {
    setIsCodeListModalVisible(false);
    // getSizes();

  };
  const handleCodeListModalcancel = () => {
    setIsCodeListModalVisible(false);
    // getSizes();

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // getSizes();

  };
  const onFinish = values => {
  };
  // useEffect(() => {
  //   // setFieldsData(...fieldsData, soakingTime: 120) 
  // }, [props.brandsData]);

  useEffect(() => {
    if (props.initialData) {
      console.log(props.initialData)
      setCodeListSoId((props.initialData)[0].saleOrderId)
      getTracebilityCodes((props.initialData)[0].saleOrderId)
      const skewerexist = props.initialData.filter(item => (item.category == PackTypeCategory.SKEWERS))
      if(skewerexist.length > 0){
        setCategory(PackTypeCategory.SKEWERS)
      }

      console.log(props.initialData);
      // props.currency = props.initialDa
      // if (props.soStatus == SoStatusEnum.IN_PROGRESS) {
      //   setUpdateDisableField(true);
      // }
      setIsUpdate(true);
      if (props.initialData.length > 0) {
        if (props.packCategory == PackTypeCategory.SKEWERS) {
          setIsInnerBagsHidden(false)
          setIsInnerBagsizeHidden(false)
        }
        setUpdateTableData(props.initialData);
        props.addDataToItemDetails(props.initialData);

        setShowTable(true)
      }
    }
    // if (props.category) {
    //   setShowTable(false)
    //   setUpdateTableData([]);
    //   if (isUpdate) {
    //   }

    //   const category = props.category
    //   if (category == PackTypeCategory.SKEWERS) {
    //     setIsInnerBagsHidden(false)
    //     setIsInnerBagsizeHidden(false)

    //   }
    //   else if (category == PackTypeCategory.RING) {
    //     setIsShrimpWeightHidden(false)
    //     // form.setFieldsValue({})
    //   }
    //   else {
    //     setIsInnerBagsHidden(true)
    //     setIsInnerBagsizeHidden(true)
    //     form.setFieldsValue({ innerBag: 0 })
    //     setIsShrimpWeightHidden(true)

    //   }
    // }
    if (updatedefaultFormData) {

    }
    // setFieldsData(...fieldsData, soakingTime: 120) 
    getItemsData();
    getFoodTypesData();
    getSkuCodes();
    getPackingMethods();
    getUomsdata();
    getFreezeStyles();
    // getSizes()

  }, []);

  useEffect(() => {
    console.log('hiiiiiiiiii')
    // setFieldsData(...fieldsData, soakingTime: 120) 
    calculatWeightPrice()
  }, [fieldsData.pouches, fieldsData.pouchWeight, fieldsData.cases, fieldsData.unitPrice, fieldsData.uomId, fieldsData.shrimpWeight, fieldsData.category]);



  const getSizes = (subCatId) => {
    const req = new CategoryItemReq();
    req.itemSubCategoryId = subCatId;
    req.itemId = undefined;
    req.categoryId = 6;
    sizeService.getAllActiveSizesDropDownForCategory(req).then((res) => {

      if (res.status) {
        // console.log(res.data);
        setSizes(res.data);
      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }

  // const saveItemSize = () => {
  //   console.log(fieldsData)
  //   let sizeDto
  //   if(isUpdate){
  //     sizeDto = new ItemsDto(undefined,undefined,6,PackingCategoryEnum.SKEWER,undefined,PackingCategoryEnum.SKEWER,
  //       masterBrandId,fieldsData.varientId,fieldsData.minGrade,fieldsData.maxGrade,fieldsData.pouches,fieldsData.pouchWeight,fieldsData.innerBagSizeId,undefined,undefined,true,undefined,'Admin',undefined,undefined,undefined,fieldsData.uomId)

  //   }else{
  //     sizeDto = new ItemsDto(undefined,undefined,6,PackingCategoryEnum.SKEWER,undefined,PackingCategoryEnum.SKEWER,
  //     fieldsData.brandId.masterBrandId,fieldsData.varientId.value,fieldsData.minGrade,fieldsData.maxGrade,fieldsData.pouches,fieldsData.pouchWeight,fieldsData.innerBagSizeId.value,undefined,undefined,true,undefined,'Admin',undefined,undefined,undefined,fieldsData.uomId.value)

  //   }
  //   console.log(sizeDto)
  //   itemService.createItem(sizeDto).then((res) => {

  //     if (res.status) {
  //       // console.log(res.data);
  //       // setSizes(res.data);
  //     AlertMessages.getSuccessMessage('New size added to item');
  //     setCallItemState(false)


  //     } else {
  //       setCallItemState(false)

  //       // setSizes([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setCallItemState(false)

  //     // setSizes([]);
  //   });
  // }




  const getUomsdata = () => {
    uomsService.getAllUoms().then((res) => {
      if (res.status) {
        setWeighingStyle(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setWeighingStyle([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWeighingStyle([]);
    });
  }

  const getFreezeStyles = () => {
    freezeStleService.getAllActiveTypeOfValueAddition4().then((res) => {
      if (res.status) {
        setFreezeStyles(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setFreezeStyles([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFreezeStyles([]);
    });
  }


  const getItemsData = () => {
    productService.getAllActiveProducts().then((res) => {
      if (res.status) {
        setItemsdata(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setItemsdata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsdata([]);
    });
  }

  const soakingStyleOnchange = (selectedValue: string) => {

  }

  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkucode(res.data);
      }
      else {
        setSkucode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });
  }

  const getPackingMethods = () => {
    pmService.getAllPackingMethod(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then((res) => {
      if (res.status) {
        setPackingMethods(res.data);
      }
      else {
        setPackingMethods([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPackingMethods([]);
      });
  }

  const getFoodTypesData = () => {
    foodTypeService.getAllActiveFoodTypes().then((res) => {
      if (res.status) {
        setFoodType(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setFoodType([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFoodType([]);
    });
  }

  const onReset = () => {
    setBtnChange(false);
    form.resetFields();
    setDefaultFormData(undefined)

  };

  const onAddProduct = () => {
    setSubJobCases(form.getFieldValue('cases'))
    console.log(form.getFieldValue('cases'))


    let grade = form.getFieldValue('grade');
    // const selMaxGrade = form.getFieldValue('grade');
    if (grade) {

      if (grade.maxGrade <= grade.minGrade) {
        // console.log(selMinGrade);
        form.setFields([{ value: '', name: ['grade', 'maxGrade'], errors: ['min < max'] }])
        // form.setFields([{name:['cases'],errors:['min < max']}])
        // form.setFields([{value:'',name:['cases'],errors:['min < max']}])
      }
    }

    let glaze = form.getFieldValue('glazzing');
    const selMaxGlaze = form.getFieldValue('glazzing');
    if (glaze) {

      if (glaze.maxGlaze < glaze.minGlaze) {
        console.log(glaze);
        form.setFields([{ value: '', name: ['glazzing', 'maxGlaze'], errors: ['min < max'] }])
        // form.setFields([{name:['cases'],errors:['min < max']}])
        // form.setFields([{value:'',name:['cases'],errors:['min < max']}])
      }
    }
    form.validateFields().then(res => {


      console.log(updateTableData)
      console.log(fieldsData);
      let duplicateData;
      let tableName;
      if (isUpdate) {
        tableName = updateTableData;
      } else {
        tableName = tableData;
      }

      if (!isUpdate) {
        console.log(props.category)
        console.log(tableName);
        console.log(fieldsData);

        if (props.category == PackTypeCategory.SKEWERS) {
          duplicateData = tableName.find(item => (item.MaxGlaze == fieldsData.MaxGlaze && item.brandId.children == fieldsData.brandId.children && item.minGrade == fieldsData.minGrade && item.maxGrade == fieldsData.maxGrade && item.itemId == fieldsData.itemId && item.varientId.children == fieldsData.varientId.children && item.pouches == fieldsData.pouches && item.pouchWeight == fieldsData.pouchWeight && item.uomId.children == fieldsData.uomId.children && item.styleWeighment.children == fieldsData.styleWeighment.children && item.minGlaze == fieldsData.minGlaze && item.soakingTime == fieldsData.soakingTime && item.soakingStyle == fieldsData.soakingStyle && Number(item.unitPrice) == Number(fieldsData.unitPrice) && item.cases == fieldsData.cases && item.shrimpWeight == fieldsData.shrimpWeight && item.innerBags == fieldsData.innerBags && item.innerBagSizeId.children == fieldsData.innerBagSizeId.children && item.category?.children == fieldsData.category.children));
        } else {
          console.log(tableName[0])
          console.log(tableName[0]?.category)
          console.log(fieldsData.category)
          console.log(fieldsData.category.children)
          duplicateData = tableName.find(item => (item.MaxGlaze == fieldsData.MaxGlaze && item.brandId.children == fieldsData.brandId.children && item.minGrade == fieldsData.minGrade && item.maxGrade == fieldsData.maxGrade && item.itemId == fieldsData.itemId && item.varientId.children == fieldsData.varientId.children && item.pouches == fieldsData.pouches && item.pouchWeight == fieldsData.pouchWeight && item.uomId.children == fieldsData.uomId.children && item.styleWeighment.children == fieldsData.styleWeighment.children && item.minGlaze == fieldsData.minGlaze && item.soakingTime == fieldsData.soakingTime && item.soakingStyle == fieldsData.soakingStyle && Number(item.unitPrice) == Number(fieldsData.unitPrice) && item.cases == fieldsData.cases && item.shrimpWeight == fieldsData.shrimpWeight && item.category?.children == fieldsData.category.children));
        }
        console.log(duplicateData)
      } else {
        if (props.category == PackTypeCategory.SKEWERS) {
          alert('skewer')
          console.log(tableName)
console.log(fieldsData)
          duplicateData = tableName.find(item => (item.MaxGlaze == fieldsData.MaxGlaze && item.brandId == fieldsData.brandId && item.minGrade == fieldsData.minGrade && item.maxGrade == fieldsData.maxGrade && item.itemId == fieldsData.itemId && item.varientId == fieldsData.varientId && item.pouches == fieldsData.pouches && item.pouchWeight == fieldsData.pouchWeight && item.uomId == fieldsData.uomId && item.styleWeighment == fieldsData.styleWeighment && item.minGlaze == fieldsData.minGlaze && item.soakingTime == fieldsData.soakingTime && item.soakingStyle == fieldsData.soakingStyle && Number(item.unitPrice) == Number(fieldsData.unitPrice) && item.cases == fieldsData.cases && item.shrimpWeight == fieldsData.shrimpWeight && item.innerBags == fieldsData.innerBags && item.innerBagSizeId == fieldsData.innerBagSizeId && item.catgory == fieldsData.category));
        } else {
          duplicateData = tableName.find(item => (item.MaxGlaze == fieldsData.MaxGlaze && item.brandId == fieldsData.brandId && item.minGrade == fieldsData.minGrade && item.maxGrade == fieldsData.maxGrade && item.itemId == fieldsData.itemId && item.varientId == fieldsData.varientId && item.pouches == fieldsData.pouches && item.pouchWeight == fieldsData.pouchWeight && item.uomId == fieldsData.uomId && item.styleWeighment == fieldsData.styleWeighment && item.minGlaze == fieldsData.minGlaze && item.soakingTime == fieldsData.soakingTime && item.soakingStyle == fieldsData.soakingStyle && Number(item.unitPrice) == Number(fieldsData.unitPrice) && item.cases == fieldsData.cases && item.shrimpWeight == fieldsData.shrimpWeight && item.catgory == fieldsData.category));
        }
      }

      // console.log(duplicateData);
      if (duplicateData) {
        AlertMessages.getErrorMessage('Product already exist in items');
      } else {

        if (btnChange == false) {
          if (isUpdate) {
            // form.submit();
            // form.validateFields().then(res => {
            setShowTable(true);
            const dynTableData = [...updateTableData, fieldsData]
            console.log(dynTableData)
            setUpdateTableData(dynTableData);
            props.addDataToItemDetails(dynTableData);
            // })
          } else {
            console.log(props.category);
            // form.validateFields().then(res => {
            setShowTable(true);
            let dynTableData = [...tableData, fieldsData]
            console.log(dynTableData)
            setTableData(dynTableData);
            props.addDataToItemDetails(dynTableData);
            // })
          }
        } else {
          // let dynTableData;
          if (isUpdate) {
            const updateDynTableData = [...updateTableData]
            console.log(updateDynTableData)
            console.log(fieldsData)
            updateDynTableData[indexVal] = fieldsData
            updateDynTableData[indexVal].jobCases = form.getFieldValue('cases')
            updateDynTableData[indexVal].cases = form.getFieldValue('cases')
            console.log(updateDynTableData)
            setShowTable(true);
            setUpdateTableData(updateDynTableData);
            props.addDataToItemDetails(updateDynTableData);
          } else {
            console.log(props.category);

            console.log(tableData);
            console.log(fieldsData);
            const dynTableData = [...tableData]
            dynTableData[indexVal] = fieldsData
            console.log(dynTableData)
            setShowTable(true);
            setTableData(dynTableData);
            // setFieldUpdateKey(prevVal => prevVal+1)
            props.addDataToItemDetails(dynTableData);
          }


        }
        onReset();
        setFieldsData({
          palletCapacity:0,
          product: '',
          foodType: '',
          soakingTime: 120,
          innerBags: 0,
          shrimpWeight: 0,
          lbnetWeight: 0,
          kgnetWeight: 0

        })
      }
    })



  }


  const setEditForm = (rowData: any, index) => {
    setProductsBtnDisable(true)
    console.log(rowData);
    if (!isUpdate?rowData.category.children:rowData.category != PackTypeCategory.SKEWERS) {
      setIsInnerBagsHidden(true)
      setIsInnerBagsizeHidden(true)
    } else if((!isUpdate?rowData.category.children:rowData.category != PackTypeCategory.RING) || (!isUpdate?rowData.category.children:rowData.category != PackTypeCategory.PLATTER)){
      setIsInnerBagsHidden(false)
      setIsInnerBagsizeHidden(true)
    }else{
      setIsInnerBagsHidden(false)
      setIsInnerBagsizeHidden(false)
    }
    // alert('test');
    // if(isUpdate){
    //   setUpdateDefaultFormData(rowData)
    // }else{
    setDefaultFormData(rowData);
    // setupdateFieldsdata(rowData);
    // }
    setIndexVal(index);
    setBtnChange(true);

  }

  const getFormGridValues = () => {

  }

  const poNWNCOnchange = (selectedValue: string) => {

  }

  const deleteData = (index) => {
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }

  const saveCategory = (val, row) => {
    // props.categoryValue(val)
    // console.log(row)
  }


  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text, data, index) => {
        return <span>{data.category.children}</span>
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      render: (text, data, index) => {
        return <span>{data.brandId.children}</span>
      }
    },
    // {
    //   title: 'Grade',
    //   dataIndex: 'minGrade',
    //   key: 'minGrade',
    //   render: (text, data, index) => {
    //     return <span>{`${text} - ${data.maxGrade} `}</span>
    //   }
    // },
    {
      title: 'Variant',
      dataIndex: 'varientId',
      render: (text, data, index) => {
        const selproductCode = itemsdata.find(item => item.productId == data.itemId);
        console.log(selproductCode)
        return <span className={'stylesInfo'}>{`${data.varientId.children}`}</span>
      }
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'itemId',
    //   render: (text, data, index) => {
    //     console.log(text, data, index)
    //     return <span>{data.itemId.children}</span>
    //   }
    // },
    // {
    //   title: 'Food Type',
    //   dataIndex: 'foodTypeId',
    //   render: (text, data, index) => {
    //     console.log(text, data, index)
    //     return <span>{data.foodTypeId.children}</span>
    //   }
    // },


    {
      title: 'Pack Style',
      dataIndex: 'pouchWeight',
      key: 'pouchWeight',
      render: (text, data, index) => {
        return <span>{`${data.pouches} * ${Number(parseFloat(text).toFixed(3))}  ${data.uomId.children}`}</span>
      },
    },

    {

      title: 'Skewers',
      dataIndex: 'innerBags',
      key: 'innerBags',

    },
    {
      title: 'Variety',
      dataIndex: 'innerBagSizeId',
      key: 'innerBagSizeId',
      render: (text, data, index) => {
        return <span>{`${text ? text.children : '-'}`}</span>
      }
    },

    {
      title: 'Net Case Weight',
      dataIndex: 'caseWeight',
      key: 'caseWeight',
      render: (text, data, index) => {
        let outputWeight
        if (data.uomId.children == UomEnum.LB || data.uomId.children == UomEnum.OZ) {
          outputWeight = UomEnum.LB;
        }
        if (data.uomId.children == UomEnum.KG || data.uomId.children == UomEnum.Gms) {
          outputWeight = UomEnum.KG;
        }
        // else {
        //   outputWeight = data.wighmentType.children
        // }
        return <span>{`${Number(parseFloat(text).toFixed(3))} ${outputWeight}`}</span>
      }
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      key: 'cases',
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (text, data, index) => {
        let outputNetWeight;
        if (data.uomId.children == UomEnum.LB || data.uomId.children == UomEnum.OZ) {
          outputNetWeight = UomEnum.LB;
        }
        if (data.uomId.children == UomEnum.KG || data.uomId.children == UomEnum.Gms) {
          outputNetWeight = UomEnum.KG;
        }
        return <span>{`${Number(parseFloat(text).toFixed(3))} ${outputNetWeight}`}</span>
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'netAmount',
      render: (text, data, index) => {
        return <span>{`${Number(parseFloat(text).toFixed(2))}`}</span>
      }
    },

    {
      title: 'Style Weighment',
      dataIndex: 'styleWeighment',
      key: 'styleWeighment',
      render: (text, data, index) => {
        return <span>{`${data.styleWeighment.children}`}</span>
      }
    },
    // {
    //   title: 'Glazing',
    //   dataIndex: 'MaxGlaze',
    //   key: 'MaxGlaze',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     return <span>{`${data.minGlaze} - ${text}`}</span>
    //   }
    // },
    // {
    //   title: 'Soaking Time',
    //   dataIndex: 'soakingTime',
    //   key: 'soakingTime',
    //   render: (text, data, index) => {
    //     return <span>{`${text}min`}</span>
    //   }
    // },
    // {
    //   title: 'Soaking Style',
    //   dataIndex: 'soakingStyle',
    //   key: 'soakingStyle',
    //   render: (text, data, index) => {
    //     // alert();
    //     // console.log(data)
    //     return <span>{`${data.soakingStyle}`}</span>
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                // if (rowData) {
                setEditForm(rowData, index);
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>

          <Divider type="vertical" />
          <Tooltip placement="top" title='delete'>
            <Popconfirm title='Sure to delete?' onConfirm={e =>{deleteData(index);}}>
            <MinusCircleOutlined 

              style={{ color: '#1890ff', fontSize: '14px' }} />
            </Popconfirm>
          </Tooltip>

        </span>)
    }

  ]


  const updateColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text, data, index) => {
        return <span>{data?.category}</span>
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brandId',
      render: (text, data, index) => {
        const selBrand = props.brandsData.find(item => item.brandId == data.brandId);

        return <span>{selBrand?.brandName}</span>
      }
    },
    // {
    //   title: 'Grade',
    //   dataIndex: 'minGrade',
    //   key: 'minGrade',
    //   render: (text, data, index) => {
    //     return <span>{`${text} - ${data.maxGrade} `}</span>
    //   }
    // },
    {
      title: 'Variant',
      dataIndex: 'varientId',
      render: (text, data, index) => {
        const selproductCode = itemsdata.find(item => item.productId == data.itemId);
        const selVarientCode = skucodes.find(item => item.skuCodeId == text);
        return <span className={'stylesInfo'}>{`${selVarientCode?.variantCode}`}</span>
      }
    },
    // // {
    // //   title: 'Product',
    // //   dataIndex: 'itemId',
    // //   render: (text, data, index) => {
    // //     console.log(text, data, index)
    // //     return <span>{data.itemId.children}</span>
    // //   }
    // // },
    // // {
    // //   title: 'Food Type',
    // //   dataIndex: 'foodTypeId',
    // //   render: (text, data, index) => {
    // //     console.log(text, data, index)
    // //     return <span>{data.foodTypeId.children}</span>
    // //   }
    // // },


    {
      title: 'Pack Style',
      dataIndex: 'pouchWeight',
      key: 'pouchWeight',
      render: (text, data, index) => {
        const selUom = unitOfWeights.find(item => item.value == data.uomId);
        return <span>{`${data.pouches} * ${Number(parseFloat(text).toFixed(3))}  ${selUom.name}`}</span>
      }
    },
    {

      title: 'Skewers',
      dataIndex: 'innerBags',
      key: 'innerBags',

    },
    {
      title: 'Variety',
      dataIndex: 'innerBagSizeId',
      key: 'innerBagSizeId',
      render: (text, data, index) => {
        const selSize = sizes.find(item => item.sizeId == data.innerBagSizeId);
        return <span>{`${selSize?.sizeName?selSize.sizeName:'-'}`}</span>
      }

    },
    {
      title: 'Net Case Weight',
      dataIndex: 'caseWeight',
      key: 'caseWeight',
      render: (text, data, index) => {
        const selUom = unitOfWeights.find(item => item.value == data.uomId);

        let outputWeight
        if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
          outputWeight = UomEnum.LB;
        }
        if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
          outputWeight = UomEnum.KG;
        }
        // else {
        //   outputWeight = data.wighmentType.children
        // }
        return <span>{`${Number(parseFloat(text).toFixed(3))} ${outputWeight}`}</span>
      }
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      key: 'cases',
      render: (text, data, index) => {
        console.log(data)
        return <span>{data.jobType == JobTypesEnum.MAIN_JOB?text:data.jobCases}</span>
      }
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (text, data, index) => {
        let outputNetWeight;
        const selUom = unitOfWeights.find(item => item.value == data.uomId);

        if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
          outputNetWeight = UomEnum.LB;
        }
        if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
          outputNetWeight = UomEnum.KG;
        }
        return <span>{`${Number(parseFloat(text).toFixed(3))} ${outputNetWeight}`}</span>
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'netAmount',
      render: (text, data, index) => {
        return <span>{`${Number(parseFloat(text).toFixed(2))}`}</span>
      }
    },

    {
      title: 'Style Weighment',
      dataIndex: 'styleWeighment',
      key: 'styleWeighment',

    },
    // {
    //   title: 'Code List Info',
    //   dataIndex: 'codeListQuantity',
    //   key: 'codeListQuantity',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     const selTraceCodes = traceCodes.find(item => item.tracebilityCodeId == data.codeListId);
    //     return <span>{`${selTraceCodes.tracebilityCode}(${text})`}</span>
    //   }
    // },
    // {
    //   title: 'Code List Info',
    //   dataIndex: 'codeListQuantity',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     // return <span>{`${Number(parseFloat(text).toFixed(2))}`}</span>
    //   }
    // },
    // {
    //   title: 'Glazing',
    //   dataIndex: 'MaxGlaze',
    //   key: 'MaxGlaze',
    //   render: (text, data, index) => {
    //     return <span>{`${data.minGlaze} - ${text}`}</span>
    //   }
    // },
    // {
    //   title: 'Soaking Time',
    //   dataIndex: 'soakingTime',
    //   key: 'soakingTime',
    //   render: (text, data, index) => {
    //     return <span>{`${text}min`}</span>
    //   }
    // },
    // {
    //   title: 'Soaking Style',
    //   dataIndex: 'soakingStyle',
    //   key: 'soakingStyle',

    // },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                // if (rowData) {
                setEditForm(rowData, index);
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          {(isUpdate && props.jobType != 'Clone') ? <></> :
            <><Divider type="vertical" />
              <Tooltip placement="top" title='delete'>
                <MinusCircleOutlined onClick={() => {
                  if (rowData) {
                    deleteData(index)
                    // openFormWithData(rowData);
                  }
                }}
                  style={{ color: '#1890ff', fontSize: '14px' }} />
              </Tooltip>
            </>
          }
        </span>)
    },
    {
      title: 'Code List',
      dataIndex: 'codeList',
      align: 'center',
      width: 100,
      render: (text, data, index) => {
          console.log(data)
          return <>
          {(isUpdate && props.jobType == JobTypesEnum.SUB_JOB)?<><span>
              <Button disabled={codeListBtn} type="primary" onClick={() => {
              allocateCodeList(data);
              }} 
              style={{ backgroundColor: '#40a9ff' }}>Allocate Code List</Button></span></>:<></>}
          </>
          // return <span>{isUpdate ? 1 :<Button type="primary" onClick={() => {
          //     allocateCodeList(data);
          //     }} 
          //     style={{ backgroundColor: '#40a9ff' }}>Allocat Code List</Button>
          //     {/* <Tooltip title='Allocate'><RotateRightOutlined className={'editSamplTypeIcon'} type="allocate"
          //         onClick={() => {

          //           // if (rowData.isActive) {
          //           allocateCodeList(data);
          //           // } else {
          //           //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
          //           // }
          //         }}
          //         style={{ color: '#1890ff', fontSize: '14px' }}
          //       /></Tooltip> */}}
              
          // </span>
          
      }

  },

  ]


  const calculatWeightPrice = () => {
    let selectedCategory
    if (isUpdate) {
      selectedCategory = fieldsData.category
    } else {
      console.log(fieldsData)
      if (fieldsData.category) {
        selectedCategory = fieldsData.category.value
      }
      // }else{
      //   AlertMessages.getErrorMessage('please select Category')
      // }
    }
    if (selectedCategory) {
      console.log('selcat')
      console.log(selectedCategory)

      const unitprice = fieldsData.unitPrice
      const cases = fieldsData.cases;
      const uom = fieldsData.uomId
      const shrimpWeight = fieldsData.shrimpWeight
      const pouches = fieldsData.pouches
      let pouchWeight;
      pouchWeight = fieldsData.pouchWeight
      let selUom

      if (selectedCategory == PackTypeCategory.POUCH || selectedCategory == PackTypeCategory.SKEWERS) {
        if (uom) {
          if (isUpdate) {
            selUom = uom
          } else {
            selUom = uom.value
          }
          if (selUom == 1 || selUom == 4) {
            setPriceLabel('Per KG')
          }
          if (selUom == 2 || selUom == 3) {
            setPriceLabel('Per LB')
          }
        }
      } else if (selectedCategory == PackTypeCategory.RING || selectedCategory == PackTypeCategory.PLATTER) {
        if (uom) {
          setPriceLabel('Per Unit')
        }
      }

      console.log(cases)
      console.log(unitprice)
      console.log(pouches)
      console.log(pouchWeight)
      console.log(uom)
      console.log(shrimpWeight)

      if (cases && unitprice >= 0 && pouches && pouchWeight && uom && shrimpWeight) {

        let selUomId
        if (isUpdate) {
          selUomId = fieldsData.uomId
        } else {
          selUomId = fieldsData.uomId.value
        }
        if (selUomId == 3) {
          pouchWeight = (fieldsData.pouchWeight) * 0.0625
        }
        if (selUomId == 4) {
          pouchWeight = (fieldsData.pouchWeight) * 0.001
        }

        const caseWeight = pouches * pouchWeight;
        const netWeight = caseWeight * cases;
        let netAmount
        if (selectedCategory == PackTypeCategory.POUCH || selectedCategory == PackTypeCategory.SKEWERS) {
          netAmount = netWeight * unitprice;
        } else if (selectedCategory == PackTypeCategory.RING || selectedCategory == PackTypeCategory.PLATTER) {
          netAmount = pouches * cases * unitprice;
        }
        //netweight conversion for total
        let lbnetWeight;
        let kgnetWeight;

        if (selUomId == 1 || selUomId == 4) {
          lbnetWeight = (netWeight) * 2.20462
        } else {
          lbnetWeight = netWeight;
        }

        if (selUomId == 2 || selUomId == 3) {
          kgnetWeight = (netWeight) * 0.453592
        } else {
          kgnetWeight = netWeight;
        }
        // else if (uom.value == 2) {
        //   lbnetWeight = netWeight;

        // } else if (uom.value == 3) {
        //   lbnetWeight = (netWeight) * 0.0625
        // } else {
        //   lbnetWeight = (netWeight) * 0.00220462
        // }
        console.log(lbnetWeight);


        form.setFieldsValue({ caseWeight: String(caseWeight.toFixed(3)), netWeight: String(netWeight.toFixed(3)), netAmount: String(netAmount.toFixed(2)), lbnetWeight: String(lbnetWeight) });
        setFieldsData({ ...fieldsData, caseWeight: caseWeight, netWeight: netWeight, lbnetWeight: lbnetWeight, kgnetWeight: kgnetWeight, netAmount: netAmount })
        // setFieldsData({ ...finalData, [label]: val });

      } else {
        form.setFieldsValue({ caseWeight: String(0), netWeight: String(0), netAmount: String(0), lbnetWeight: String(0) });
        // let finalData = { ...fieldsData, caseWeight: 0, netWeight: 0, netAmount: 0  }
        setFieldsData({ ...fieldsData, caseWeight: 0, netWeight: 0, netAmount: 0, lbnetWeight: 0 })
        // setFieldsData({ ...finalData, [label]: val });
      }

    }


    // let totAmount = 0;

  }

  const appendData = (val, rowObj) => {
    console.log(val);
    setProductsBtnDisable(false)

    console.log(rowObj);
    let row = rowObj
    if (isUpdate) {
      row = rowObj.value
    }

    console.log(rowObj);
    let { label } = rowObj;
    if (isUpdate && label == 'brandId') {
      console.log(val);
      console.log(rowObj);
      setFieldsData({ ...fieldsData, masterBrandId: rowObj?.masterBrandId})
      // setMasterBrandId(rowObj.masterBrandId);
      // props.mastesrBrandData(rowObj.masterBrandId)
    }
    if (isUpdate) {
      label = rowObj.label;
    }


    // if(label == 'innerBagSizeId'){
    //   console.log('testrrrrr')
    //   setCallItemState(true)
    // }


    // if(label == 'wighmentType'){
    //   calculatWeightPrice();
    // }
    if (label == 'varientId') {
      console.log(val)
      let object: any = ''
      setFieldsData({ ...fieldsData, [label]: row })
      skuService.getValueAdditionDataBySkuCode(new GetValueAdditionDataBySkuCodesRequest(val)).then((res) => {
        if (res.status) {
          console.log(res.status);
          const valuAdditions = res.data;
          form.setFieldsValue({ itemId: valuAdditions.productId, sockingStyleId: valuAdditions.soakingStyle, soakingTime: valuAdditions.soakingTime, glazzing: { 'minGlaze': valuAdditions.minglaze, 'maxGlaze': valuAdditions.maxglaze }, grade: { 'minGrade': Number(valuAdditions.minGrade), 'maxGrade': Number(valuAdditions.maxGrade) } })
          object = {
            ...fieldsData, [label]: row, valueAdditionOneId: valuAdditions.valueAdditionOneId,
            valueAdditionTwoId: valuAdditions.valueAdditionTwoId,
            valueAdditionThreeId: valuAdditions.valueAdditionThreeId,
            valueAdditionFourId: valuAdditions.valueAdditionFourId,
            valueAdditionFiveId: valuAdditions.valueAdditionFiveId,
            lossOrGainPercentage: valuAdditions.lossOrGainPercentage,
            requiredCount: valuAdditions.requiredCount,
            foodTypeId: valuAdditions.foodTypeId,
            itemId: valuAdditions.productId,
            soakingStyle: valuAdditions.soakingStyle,
            soakingTime: valuAdditions.soakingTime,
            // grade: { 'minGrade': valuAdditions.minGrade, 'maxGrade': valuAdditions.maxGrade },
            minGrade: valuAdditions.minGrade,
            maxGrade: valuAdditions.maxGrade,
            minGlaze: valuAdditions.minglaze,
            MaxGlaze: valuAdditions.maxglaze
          }
          setFieldsData({ ...object })
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
    } else {
      console.log(fieldsData)
      if (label == 'category') {
        // setFieldsData({ ...fieldsData, [label]: row });
        // alert();
        console.log(val);
        props.category = val;
        setCategory(val)
        // if (props.category) {
        // setShowTable(false)
        // setUpdateTableData([]);
        // if (isUpdate) {
        // }

        const category = props.category
        let packstyleValues
        let pouchWeightValue
        if (form.getFieldValue('packStyle')) {
          packstyleValues = form.getFieldValue('packStyle');
          pouchWeightValue = packstyleValues.pouchWeight
        }
        if (val == PackTypeCategory.SKEWERS) {
          if(form.getFieldValue('netwgtcnt') && (form.getFieldValue('netwgtcnt') == 'FWFC' || form.getFieldValue('netwgtcnt') == 'FWNC')){
            console.log(row)
            console.log(form.getFieldsValue())
            console.log(form.getFieldValue('glazzing'))
            const minGlaze = form.getFieldValue('glazzing').minGlaze
            const maxGlaze = form.getFieldValue('glazzing').maxGlaze
            const avg = (minGlaze+maxGlaze)/2
        
            const pouchWt = pouchWeightValue?Number(pouchWeightValue):null
            const finalntwt = Number(pouchWt-(avg/100*pouchWt)).toFixed(2)
            let newwtobject
            form.setFieldsValue({shrimpWeight:Number(finalntwt)})
            newwtobject = {
              ...fieldsData, [label]: row, shrimpWeight:Number(finalntwt),
        
            }
            console.log(newwtobject)
            setFieldsData({ ...newwtobject })
          }else{
            console.log('yesssssss')
              setIsInnerBagsHidden(false)
              setIsInnerBagsizeHidden(false)
            form.setFieldsValue({ shrimpWeight: pouchWeightValue?Number(pouchWeightValue):null });
            const object = {
              ...fieldsData, [label]: row, shrimpWeight: pouchWeightValue
            }
            setFieldsData({ ...object });
          }
        }
        else if (val == PackTypeCategory.RING) {
          console.log('ring')
          setIsInnerBagsHidden(true)
          setIsInnerBagsizeHidden(false)
          form.setFieldsValue({ innerBags: 0 })
          form.setFieldsValue({ innerBagSizeId: null })
          form.setFieldsValue({ shrimpWeight: null });
          const object = {
            ...fieldsData, [label]: row, shrimpWeight: null, innerBags: 0, innerBagSizeId: undefined
          }
          setFieldsData({ ...object });
        }
        else {
          console.log('pouch,platter')
          setIsInnerBagsHidden(true)
          if(val == PackTypeCategory.PLATTER){
            setIsInnerBagsizeHidden(false)
          }else{
            setIsInnerBagsizeHidden(true)
          }
          if(form.getFieldValue('netwgtcnt') && (form.getFieldValue('netwgtcnt') == 'FWFC' || form.getFieldValue('netwgtcnt') == 'FWNC')){
            console.log(row)
            console.log(form.getFieldsValue())
            console.log(form.getFieldValue('glazzing'))
            const minGlaze = form.getFieldValue('glazzing').minGlaze
            const maxGlaze = form.getFieldValue('glazzing').maxGlaze
            const avg = (minGlaze+maxGlaze)/2
        
            const pouchWt = pouchWeightValue?Number(pouchWeightValue):null
            const finalntwt = Number(pouchWt-(avg/100*pouchWt)).toFixed(2)
            let appendObj
            form.setFieldsValue({shrimpWeight:(val == PackTypeCategory.POUCH?(finalntwt?Number(finalntwt):null):null)})
            appendObj = {
              ...fieldsData, [label]: row, shrimpWeight:val == PackTypeCategory.POUCH ? finalntwt:null,
        
            }
            console.log(appendObj)
            setFieldsData({ ...appendObj })
          }else{
            form.setFieldsValue({ shrimpWeight: (val == PackTypeCategory.POUCH?(pouchWeightValue?Number(pouchWeightValue):null):null), innerBags: 0, innerBagSizeId: undefined });
            console.log(form.getFieldsValue());
            const object = {
              ...fieldsData, [label]: row, shrimpWeight: val == PackTypeCategory.POUCH ? pouchWeightValue:null, innerBags: 0, innerBagSizeId: undefined
            }
            console.log(object)
            setFieldsData({ ...object });
            // setFieldUpdateKey(prevState => prevState+1)
          }

        }
        props.category = val;
        console.log(rowObj)
        getSizes(rowObj.catid);


        // }
      } else {

        if (label != 'soakingTime' && form.getFieldValue('soakingTime') == 120) {
          if (label == 'uomId') {
            const itemFeilds = form.getFieldsValue();
            console.log(itemFeilds);
            console.log(fieldsData);
            if(((isUpdate?fieldsData.category:fieldsData.category.value) != PackTypeCategory.RING) && ((isUpdate?fieldsData.category:fieldsData.category.value) != PackTypeCategory.PLATTER)){
              form.setFieldsValue({shrimpWeight:Number(itemFeilds.packStyle.pouchWeight)})
            }else{
              form.setFieldsValue({shrimpWeight:null})
            }
            const object = {
              ...fieldsData, pouches: itemFeilds.packStyle.pouches, pouchWeight: itemFeilds.packStyle.pouchWeight, shrimpWeight: itemFeilds.packStyle.pouchWeight, soakingTime: 120, uomId: row,packStyleId:rowObj.packMethodId,palletCapacity:rowObj.palletCapacity
            }
            setFieldsData({ ...object });
          }else if(isUpdate && label == 'brandId'){
            let newobject
            newobject = {
              ...fieldsData, [label]: row, masterBrandId: rowObj?.masterBrandId,

            }
            setFieldsData({ ...newobject })
          }
          else if(label == 'styleWeighment'){
            console.log(row)
            const itemFeildsValues = form.getFieldsValue();
            console.log(itemFeildsValues)
            if(val == 'FWFC' || val == 'FWNC'){
              console.log(row)
              console.log(form.getFieldsValue())
              console.log(form.getFieldValue('glazzing'))
              const minGlaze = form.getFieldValue('glazzing').minGlaze
              const maxGlaze = form.getFieldValue('glazzing').maxGlaze
              const avg = (minGlaze+maxGlaze)/2
          
              const pouchWt = itemFeildsValues.packStyle.pouchWeight
              const finalntwt = Number(pouchWt-(avg/100*pouchWt)).toFixed(2)
              let newwtobject
              form.setFieldsValue({shrimpWeight:Number(finalntwt)})
              newwtobject = {
                ...fieldsData, [label]: row, shrimpWeight:Number(finalntwt),
          
              }
              console.log(newwtobject)
              setFieldsData({ ...newwtobject })
          
            }else{
              let shrmpWtObj
              form.setFieldsValue({shrimpWeight:Number(itemFeildsValues.packStyle.pouchWeight)})
              shrmpWtObj = {
                ...fieldsData, [label]: row, shrimpWeight:Number(itemFeildsValues.packStyle.pouchWeight),
          
              }
              console.log(shrmpWtObj)
            setFieldsData({ ...shrmpWtObj })


            }
        
            
          }
          else {
            let newobject
            newobject = {
              ...fieldsData, [label]: row, soakingTime: 120,

            }
            setFieldsData({ ...newobject })
            console.log('soak')
            // setFieldsData({...fieldsData,soakingTime:120})
          }

        } else {
          console.log('else')


          setFieldsData({ ...fieldsData, [label]: row });
        }
      }



    }



  }



  const appendInputData = (val, label) => {
    let prevCases;
    if(props.jobType == JobTypesEnum.SUB_JOB){
      prevCases = props.initialData[indexVal].cases
    }
    setProductsBtnDisable(false)
    console.log(val);
    console.log(label);
    console.log(fieldsData);
    console.log(props.category);

    // if()
    if (props.category != PackTypeCategory.RING) {
      // if (label == 'packStyleId') {
      //   const itemFeilds = form.getFieldsValue();
      //   console.log(itemFeilds);
      //   const object = {
      //     ...fieldsData, pouches: itemFeilds.packStyle.pouches, pouchWeight: itemFeilds.packStyle.pouchWeight, shrimpWeight: itemFeilds.packStyle.pouchWeight
      //   }
      //   setFieldsData({ ...object });
      // } else {
        setFieldsData({ ...fieldsData, [label]: val });
        if(label == 'cases'){
          console.log(prevCases)
        console.log(val)
        console.log(props.jobType)
        if(props.jobType == JobTypesEnum.SUB_JOB && prevCases > val){
          props.isCasesUpdate(true)
          setCodeListBtn(false)
          // setSubJobCases(val)
        }else{
          props.isCasesUpdate(false)
          setCodeListBtn(true)
          // setSubJobCases(val)
        }
          const object = {
            ...fieldsData, [label]: val, jobCases: val
          }
          setFieldsData({ ...object});

        }
        if (label == 'pouchWeight') {
          form.setFieldsValue({ shrimpWeight: val });
          const object = {
            ...fieldsData, [label]: val, shrimpWeight: val
          }
          // setFieldsData({ ...fieldsData, [label]: val });
          setFieldsData({ ...object });

        }
      // }
    } else {
      setFieldsData({ ...fieldsData, [label]: val });
      if(label == 'cases'){
        console.log(prevCases)
        console.log(val)
        console.log(props.jobType)
        if(props.jobType == JobTypesEnum.SUB_JOB && prevCases > val){
          props.isCasesUpdate(true)
          setCodeListBtn(false)
        }else{
          props.isCasesUpdate(false)
          setCodeListBtn(true)
        }
        const object = {
          ...fieldsData, [label]: val, jobCases: val
        }
        setFieldsData({ ...object});

      }

    }

    // if (label == 'maxGlaze') {
    // }
    // if (label == 'maxGrade') {
    //   const maxGrade = val;
    //   console.log(fieldsData);
    //   const minGrade = fieldsData.minGrade;
    //   // console.log(minGrade)
    //   // console.log(maxGrade)
    //   if (Number(maxGrade) < Number(minGrade)) {
    //     AlertMessages.getErrorMessage('Max Grade must be greater than minGrade');
    //     // form.setFieldsValue({grade: { 'minGrade':0,'maxGrade':0}})
    //     // return false;
    //   }
    // }
    // setFieldsData({ ...fieldsData, [label]: val });


  }
  const renderFooterData = (data: any[], currency) => {
    let totalwight = 0;
    let totalkgwight = 0;
    let totalamount = 0;
    let pouchesnumber = 0;
    let cartonsnumber = 0;
    console.log(data);
    let currencySymbolData;
    let currencySymbol;
    console.log(currency);

    if (currency) {
      currencySymbolData = CrrencySymbols.find(item => item.id == currency);
      console.log(currencySymbolData);
      currencySymbol = currencySymbolData.symbol;
    }
    if (isUpdate) {
      if (props.updateCurrency) {
        console.log(props.updateCurrency);
        currencySymbolData = CrrencySymbols.find(item => item.id == props.updateCurrency);
        console.log(currencySymbolData);
        currencySymbol = currencySymbolData.symbol;
      }
    }
    console.log(currencySymbol);
    // let uom = data[0].wighmentType.children;
    data.forEach(tableData => {
      let lbnetWeight
      let kgnetWeight
      if (isUpdate) {

        const uom = tableData.uomId

        if (uom == 1 || uom == 4) {
          lbnetWeight = (tableData.netWeight) * 2.20462
        } else {
          lbnetWeight = tableData.netWeight;

        }

        if (uom == 2 || uom == 3) {
          kgnetWeight = (tableData.netWeight) * 0.453592
        } else {
          kgnetWeight = tableData.netWeight;

        }
        // if (uom == 1) {
        //   lbnetWeight = (tableData.netWeight) * 2.20462
        // } else if (uom == 2) {
        //   lbnetWeight = tableData.netWeight;

        // } else if (uom == 3) {
        //   lbnetWeight = (tableData.netWeight) * 0.0625
        // } else {
        //   lbnetWeight = (tableData.netWeight) * 0.00220462
        // }
      } else {
        lbnetWeight = tableData.lbnetWeight
        kgnetWeight = tableData.kgnetWeight
      }
      totalwight += Number(lbnetWeight) ? Number(lbnetWeight) : 0;
      totalkgwight += Number(kgnetWeight) ? Number(kgnetWeight) : 0;
      totalamount += Number(tableData.netAmount) ? Number(tableData.netAmount) : 0;
      pouchesnumber += (Number(tableData.pouches) && Number(tableData.cases)) ? (Number(tableData.pouches) * Number(tableData.cases)) : 0;
      cartonsnumber += Number(tableData.cases) ? Number(tableData.cases) : 0;
    });
    return (<><span><b>Total Weight(In LB) : {Math.round(totalwight)}&nbsp;&nbsp;&nbsp;Total Weight(In KG) : {Math.round(totalkgwight)}&nbsp;&nbsp;&nbsp;Total Amount : {currencySymbol} {Math.round(totalamount)}  &nbsp;&nbsp;&nbsp;Total Pouches/Rings/Platters : {pouchesnumber}&nbsp;&nbsp;&nbsp;Total Cases : {cartonsnumber}</b></span></>)
  }


  const packMethodeChange = (val,optiondata) => {
    console.log(optiondata)
    const selectedPackMethode = packingMethods.find((value) => value.packingMethodId === val);
    console.log(selectedPackMethode)
    form.setFieldsValue({ 'packStyle': { 'pouches': selectedPackMethode.noOfPouches, 'pouchWeight': selectedPackMethode.pouchesPerRow }, WeighingType: selectedPackMethode.uomId,palletCapacity:optiondata.palletCapacity });
    // appendInputData(val, 'packStyleId');
    // appendInputData(selectedPackMethode.noOfPouches, 'pouches');
    // appendInputData(selectedPackMethode.pouchesPerRow, 'pouchWeight');
    const unitName = unitOfWeights.find((val) => val.value === selectedPackMethode.uomId).name;
    const rowData = {
      children: unitName,
      className: "stylesInfo",
      key: selectedPackMethode.uomId,
      label: "uomId",
      value: selectedPackMethode.uomId,
      packMethodId:selectedPackMethode.packingMethodId,
      packMethodName:selectedPackMethode.packingMethodName,
      palletCapacity:optiondata.palletCapacity,
    }
    appendData(selectedPackMethode.uomId, rowData);
  }
  return (
    <Form onFinish={onFinish} autoComplete="off" layout="vertical" form={form}>
      <>
        <Card title={<span style={{ color: 'white' }}>Product Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <div >

            <Row gutter={24}>
              <Form.Item
                name={['packStyle', 'pouches']}
                rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing Pouches/can not allow decimals' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'pouches')} />
              </Form.Item>
              <Form.Item name="barcode" style={{ display: "none" }} >
                <Input hidden />
              </Form.Item>

              <Form.Item
                name={['packStyle', 'pouchWeight']}
                rules={[{ required: true, message: 'Missing Pouch Weight' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'pouchWeight')} />
              </Form.Item>
              <Col span={4}>
                <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Missing category' }]}>
                  <Select

                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Category"
                    allowClear
                    onChange={appendData}
                  disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}
                  >
                    {categories.map(catdropData => {
                      return <Option label={'category'} catid={catdropData.id} value={catdropData.value}>{catdropData.name}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={6}>

                <Form.Item label="Brand"
                  name={'Brand'}
                  rules={[{ required: true, message: 'Missing Brand name' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Brand"
                    onChange={appendData}
                    allowClear
                    disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}

                  >
                    {props.brandsData.map(branddropData => {
                      return <Option label={'brandId'} masterBrandId={branddropData.masterBrandId} key={branddropData.brandId} value={branddropData.brandId}>{branddropData.brandName}</Option>
                    })}
                  </Select>
                </Form.Item>



              </Col>

              {/* <Col span={4}> */}



              <Form.Item label="Grade" required hidden>
                <Input.Group compact>
                  <Form.Item
                    name={['grade', 'minGrade']}
                    noStyle
                    rules={[{ required: true, message: 'Missing Min Grade' }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, 'minGrade')} style={{ width: "50%" }} placeholder="Min" min={0} disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false} />
                  </Form.Item>


                  <Form.Item required
                    name={['grade', 'maxGrade']}
                    noStyle
                    rules={[{ required: true, message: 'Missing Max Grade/Max grade should greater than Min grade' }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, 'maxGrade')} style={{ width: "50%" }} placeholder="Max" min={0} disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>



              {/* </Col> */}
              {/* <Col span={5}> */}

              <Form.Item label="Product" hidden
                name={'itemId'}
                rules={[{ required: true, message: 'Missing Product name' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  key={Date.now()}
                  placeholder="Select Product"
                  onChange={appendData}
                  allowClear
                  disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}
                >
                  {itemsdata.map(dropData => {
                    return <Option key={dropData.productId} label='itemId' value={dropData.productId}>{dropData.productName}</Option>
                  })}
                </Select>
              </Form.Item>


              {/* </Col> */}
              {/* <Col span={6}>
                <Form.Item label="Food Type"
                  name={'foodTypeId'}
                  rules={[{ required: true, message: 'Missing Food Type name' }]}
                >
                  <Select
                    placeholder="Select Food Type"
                    onChange={appendData}
                    allowClear
                  >
                    {foodTypeData.map(foodTypedropData => {
                      return <Option label='foodTypeId' key={foodTypedropData.foodTypeId} value={foodTypedropData.foodTypeId}>{foodTypedropData.foodType}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col> */}
              <Col span={5} >
                <Form.Item label="Product"
                  name={'varientCode'}
                  rules={[{ required: true, message: 'Missing Varient Code name' }]}
                >
                  <Select
                    //className={'stylesInfo'}//for font style
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Variant Code"
                    onChange={appendData}
                    allowClear
                    disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}
                    dropdownMatchSelectWidth={false}
                  >
                    {skucodes.map(skudropData => {
                      return <Option className={'stylesInfo'} label={'varientId'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>

                {/* <Form.Item label="Pack Style"
                  // {...field}
                  name={'Pack style'}
                // rules={[{ required: true, message: 'Missing Pack style name' }]}
                >
                  <Input.Group compact>
                    <InputNumber onChange={e => appendInputData(e, 'pouches')} min={1} placeholder="Pouches" style={{ width: '50%' }} />
                    <InputNumber onChange={e => appendInputData(e, 'pouchWeight')} min={1} placeholder="Weight" style={{ width: '50%' }} />
                  </Input.Group>
                </Form.Item> */}
                <Form.Item label="Pack Style" required name={'packStyleId'}>
                  <Select
                    //className={'stylesInfo'}//for font style
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Packing Method"
                    onSelect={packMethodeChange}
                    allowClear
                    disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}
                  >
                    {packingMethods.map(skudropData => {
                      return <Option className={'stylesInfo'} label={'packStyleId'} key={skudropData.packingMethodId} palletCapacity = {skudropData.palletCapacity} value={skudropData.packingMethodId}>{skudropData.packingMethodName}</Option>
                    })}
                  </Select>
                </Form.Item>


              </Col>
              <Col span={3} style={{ display: 'none' }}>

                <Form.Item label="UOM"
                  name={'WeighingType'}
                  rules={[{ required: true, message: 'Missing UOM' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select UOM"
                    onChange={appendData}
                    allowClear
                    disabled={updateDisableField}
                  >
                    {unitOfWeights.map(weighdropData => {
                      return <Option label={'uomId'} key={weighdropData.value} value={weighdropData.value}>{weighdropData.name}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={4}>
                <Form.Item name="netwgtcnt" label="Style Weighment" rules={[{ required: true, message: 'Missing Style Weighment' }]}>
                  <Select
                    placeholder="Weighment"
                    onChange={appendData}
                    // onChange={poNWNCOnchange}
                    allowClear
                    disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false}
                  >
                    <Option label="styleWeighment" value="NWNC">NWNC</Option>
                    <Option label="styleWeighment" value="FWNC">FWNC</Option>
                    <Option label="styleWeighment" value="FWFC">FWFC</Option>
                  </Select>
                </Form.Item>
              </Col>

            </Row>

            <Row gutter={24}>


              {/* <Col span={6}>

                <Form.Item label="Freeze Style"
                  name={'freezeStyle'}
                  rules={[{ required: true, message: 'Missing Freeze Stle name' }]}
                >
                  <Select
                    placeholder="Select Freeze Stle"
                    onChange={appendData}
                    allowClear
                  >
                    {freezeStyles.map(freezeStyleData => {
                      return <Option label='freezeStyle' key={freezeStyleData.valueAdditionFourId} value={freezeStyleData.valueAdditionFourId}>{freezeStyleData.name}</Option>
                    })}
                  </Select>
                </Form.Item>


              </Col> */}



              {/* </Row>
            <Row gutter={24}> */}



              {/* <Col span={4}> */}

              <Form.Item label="Glazing" required hidden>
                <Input.Group compact>
                  <Form.Item
                    name={['glazzing', 'minGlaze']}
                    noStyle
                    rules={[{ required: true, message: 'Missed glazing/Enter a correct value', pattern: /^[0-9]*$/ }]}

                  >
                    <InputNumber onChange={e => appendInputData(e, 'minGlaze')} style={{ width: "35%" }} placeholder="Min" min={1} disabled={updateDisableField} />
                  </Form.Item>

                  <Form.Item name={['glazzing', 'middle']} noStyle>
                    <Input style={{ width: "30%" }}
                      placeholder="~"
                      disabled

                    />
                  </Form.Item>
                  <Form.Item
                    name={['glazzing', 'maxGlaze']}
                    noStyle
                    rules={[{ required: true, message: 'Missed max glaze/Max glaze greater than Min glaze/Enter a correct value', pattern: /^[0-9]*$/ }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, ('MaxGlaze'))} style={{ width: "35%" }} placeholder="Max" min={1} disabled={updateDisableField} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>


              {/* </Col> */}
              {/* <Col span={3}> */}
              <Form.Item name="soakingTime" hidden label="Soaking Time" rules={[{ required: true }]} initialValue={120}>
                <InputNumber onChange={e => appendInputData(e, 'soakingTime')} style={{ width: '100%' }} min={1} disabled={updateDisableField} />
              </Form.Item>
              {/* </Col> */}

              {/* <Col span={4}> */}
              <Form.Item name="sockingStyleId" hidden label="Soaking Style" rules={[{ required: true, message: 'Missing Soaking Style' }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Soaking Style"
                  onChange={appendData}
                  // onChange={soakingStyleOnchange}
                  allowClear
                  disabled={updateDisableField}
                >
                  <Option label={'soakingStyle'} value="STPP+SALT">STPP+SALT</Option>
                  <Option label={'soakingStyle'} value="Only SALT">Only SALT</Option>
                  <Option label={'soakingStyle'} value="NP + SALT">NP + SALT</Option>
                  <Option label={'soakingStyle'} value="CP + SALT">CP + SALT</Option>
                </Select>
              </Form.Item>
              {/* </Col> */}

              <Col span={4}>

                <Form.Item label={priceLabel ? `Unit Price (${priceLabel})` : "Unit Price"}
                  name={'Cost'}
                  rules={[{ required: true, message: 'Missing Cost ' }]}
                >
                  <InputNumber disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false} placeholder="Unit Price" onChange={e => appendInputData(e, 'unitPrice')} style={{ width: '100%' }} min={0} />
                </Form.Item>

              </Col>
              <Col span={4}>

                <Form.Item label="Net Shrimp Weight"
                  name={'shrimpWeight'}
                  rules={[{ required: true, message: 'Missing Weight' }]}
                >
                  <Input placeholder="Net Shrimp Weight" onChange={e => appendInputData(e.target.value, 'shrimpWeight')} disabled={props.jobType == JobTypesEnum.SUB_JOB?true:false} />

                </Form.Item>

              </Col>


              <Col span={4}>

                <Form.Item label="No. Of Cases"
                  name={'cases'}
                  rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing cases/can not allow decimals' }]}
                >
                  <InputNumber placeholder="Cases" onChange={e => appendInputData(e, 'cases')} style={{ width: '100%' }} min={props.jobType==JobTypesEnum.MAIN_JOB ? 1 : 0} disabled={props.jobType==JobTypesEnum.MAIN_JOB ? updateDisableField: false} />
                </Form.Item>

              </Col>
              <Col span={4}>

                <Form.Item label="Net Case Weight"
                  name={'caseWeight'}
                  rules={[{ message: 'Missing Net case Weight' }]}
                >
                  <Input readOnly placeholder="Net Case Weight" onChange={e => appendInputData(e.target.value, 'caseWeight')} disabled />
                </Form.Item>


              </Col>
              <Col span={4}>

                <Form.Item label="Net Weight"
                  name={'netWeight'}
                  rules={[{ message: 'Missing netWeight' }]}
                >
                  <Input readOnly placeholder="Net weight" onChange={e => appendInputData(e.target.value, 'netWeight')} disabled />
                </Form.Item>

              </Col>

              <Col span={4}>
                <Form.Item label="Net Amount"
                  name={'netAmount'}
                  rules={[{ message: 'Missing netAmount' }]}
                >
                  <Input readOnly placeholder="Net Amount" onChange={e => appendInputData(e.target.value, 'netAmount')} disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>


              <Col span={4}>

                <Form.Item label="Skewers"
                  name={'innerBags'}
                  hidden={isInnerBagsHidden}
                  rules={[{ required: !isInnerBagsHidden, message: 'Missing Skewers' }]}
                >
                  <InputNumber style = {{width:'100%'}} placeholder="Skewers" onChange={e => appendInputData(e,'innerBags')} min={0}/>

                </Form.Item>

              </Col>


              <Col span={4}>

                <Form.Item label="Variety"
                  name={'innerBagSizeId'}
                  hidden={isInnerBagsizeHidden}
                  rules={[{ required: !isInnerBagsizeHidden, message: 'Missing Skewer size' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Variety"
                    onChange={appendData}
                    // onClear={clearCustomerDependentData}
                    allowClear
                  >
                    {sizes.filter(item => item.itemSubCategoryId==form.getFieldValue('category')?.id)?.map(dropData => {
                      return <Option label="innerBagSizeId" key={dropData.sizeId} value={dropData.sizeId}>{dropData.sizeName}</Option>
                    })}
                  </Select>

                </Form.Item>

              </Col>

              <Col span={4}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Button type="primary" hidden={isInnerBagsizeHidden} onClick={showModal} style={{ backgroundColor: '#40a9ff' }}>+New Size</Button>
                <Modal title="Add Size" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                  <SizesForm sizesData={undefined}
                    isUpdate={false}
                    closeForm={() => { }}
                    updateSize={(undefined => { })}
                  />
                </Modal>
              </Col>
              <Col span= {4}>
              {/* <Button type="primary" onClick={showCodeListModal} style={{ backgroundColor: '#40a9ff' }}>Allocat Code List</Button> */}
                <Modal width={'50%'} title="Allocate Code list" visible={isCodeListModalVisible} onOk={handleCodeListModalOk} onCancel={handleCodeListModalcancel}>
                  <CodeListAllocationForm soId = {codeListSoId} codeListData={codeListData} soItemId = {soItemId} operation={'SUB JOB CREATE'} subJobCases = {subJobCases}
                  />
                </Modal>
              {/* <Form.Item label="Code List"
                  name={'codeListId'}
                  // hidden={isInnerBagsizeHidden}
                  // rules={[{ required: !isInnerBagsizeHidden, message: 'Missing Skewer size' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Code"
                    onChange={appendData}
                    allowClear
                  >
                    {traceCodes.map(dropData => {
                      return <Option label="codeListId" key={dropData.tracebilityCodeId} value={dropData.tracebilityCodeId}>{dropData.tracebilityCode}</Option>
                    })}
                  </Select>

                </Form.Item> */}

              </Col>
              {/* <Col span= {3}>
              <Form.Item label="Qunatity"
                  name={'codeListQuantity'}
                  rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing cases/can not allow decimals' }]}
                >
                  <InputNumber placeholder="Cases" onChange={e => appendInputData(e, 'codeListQuantity')} style={{ width: '100%' }} min={props.jobType==JobTypesEnum.MAIN_JOB ? 1 : 0} disabled={props.jobType==JobTypesEnum.MAIN_JOB ? updateDisableField: false} />
                </Form.Item>
              </Col> */}

              <Col span={3}>
                <Form.Item >

                  <br />
                  <Button disabled={productsBtnDisable} style={{ backgroundColor: "#67b32d", color: "white" }} onClick={onAddProduct} block >
                    {btnChange ? 'UPDATE' : 'ADD'}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item >
                  <br />

                  <Button style={{ backgroundColor: "#f19c9b", color: "white" }}

                    onClick={onReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>


            </Row>

            <Row justify="end">


              {/* <Col span={6}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col> */}
            </Row>
          </div>
          <br />
          { 
            showTable ?
              <Table
                scroll={{ x: 1000 }}
                dataSource={isUpdate ? updateTableData : tableData}
                columns={isUpdate ? (updateTableData.filter(item => item.category == PackTypeCategory.SKEWERS).length>0) ? updateColumns : updateColumns.filter(col => (col.dataIndex != 'innerBags' && col.dataIndex != 'innerBagSizeId')) : (tableData.filter(item => item.category.children == PackTypeCategory.SKEWERS).length>0) ? columns : ((tableData.filter(item => item.category.children == PackTypeCategory.RING).length>0) || (tableData.filter(item => item.category.children == PackTypeCategory.PLATTER).length>0))? columns.filter(col => (col.dataIndex != 'innerBags')): columns.filter(col => (col.dataIndex != 'innerBags' && col.dataIndex != 'innerBagSizeId'))}
                footer={() => renderFooterData(isUpdate ? updateTableData : tableData, props.currency)} pagination={false} /> :
              <></>
          }
        </Card>
        <br />
      </>
    </Form>
  );
};

export default ItemDetailsForm;
//so form bckup