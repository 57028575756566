import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CertificationsService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CertificationsForm} from '@gtpl/pages/master/master-components/certifications-form';
import { CertificatesDto } from '@gtpl/shared-models/masters';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { ColumnProps } from 'antd/lib/table';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
// import './certifications-grid.css'; 

/* eslint-disable-next-line */
export interface CertificationsGridProps {}

export function CertificationsGrid(props: CertificationsGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const columns = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [certificationData, setCertificationData] = useState<CertificatesDto[]>([]);
  const [slectedCertificationData, setSelectedCertificationData] = useState<any>(undefined);
  const [allData, setAllData] = useState<CertificatesDto[]>([]);
  const certificationDataService=new CertificationsService();
  useEffect(() => {
    getAllCertifications();
  }, []);

  /**
   * 
   */
  const getAllCertifications= () => {
    console.log(localStorage.getItem('createdUser'))
    certificationDataService.getAllCertifications(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setCertificationData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
            setCertificationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCertificationData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param certificationData 
   */
  const deleteCertification = (certificationData:CertificatesDto) => {
    certificationData.isActive=certificationData.isActive?false:true;
    certificationDataService.activatedeActivateCertification(certificationData).then(res => { console.log(res);
      if (res.status) {
        getAllCertifications();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    /**
     * 
     * @param variantData 
     */
    const updateCertification = (certificationData: CertificatesDto) => {
      certificationData.updatedUser =JSON.parse( localStorage.getItem('username'))
      console.log(certificationData) 
      certificationDataService.updateCertification(certificationData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllCertifications();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
   /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    //TO open the form for updation
    const openFormWithData=(viewData: CertificatesDto)=>{
      setDrawerVisible(true);
      setSelectedCertificationData(viewData);
    }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      // hideInSearch: true,
      // hideInForm: true,
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },

    {
      title: 'Certification Name',
      dataIndex: 'certificateName',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.certificateName.localeCompare(b.certificateName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('certificateName')
    },
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   responsive: ['sm'],
    //   // sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    //   // sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('remarks')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      // hideInSearch: true,
      // hideInForm: true,
      render: (isActive, rowData) => (
        <>
          {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
    },
    {
      title:`Action`,
      dataIndex: 'action',
      // hideInSearch: true,
      // hideInForm: true,
      render: (text, rowData) => (
        rowData.certificateName.trim().toLowerCase()=="vizag"?<span></span>:
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Certification');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteCertification(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Certification ?'
                :  'Are you sure to Activate Certification ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){ 
      case "true":
        return true;
      case "false":
        return false; 
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Certification</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/certificates-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40} style={{ marginLeft: '1%' }}>
          <Card title={'Total Certifications: ' + certificationData.length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#bfbfbf'}}></Card>   
          <Card title={'Active: ' + certificationData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a',marginLeft:'1%'}}></Card>
          <Card title={'In-Active: ' + certificationData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d',marginLeft:'1%'}}></Card>       
          </Row>
          <br></br><br/>
          {/* <ConfigProvider locale={enUSIntl}>
            <ProTable
              columns={columnsSkelton}
              rowKey={record => record.certificationId}
              request={(params, sorter, filter) => {
                return Promise.resolve({
                  data: certificationData,
                  success: true,
                });
              }}
              scroll = {{x:true}}
              dataSource = {certificationData}
              dateFormatter = 'string'
              search = {false}
              // onSubmit = {(params) => {
              //   if(Object.keys(params).length){
              //     const filteredData = certificationData.filter(record => record.isActive === getBoolean(params.isActive));
              //     setCertificationData(filteredData);
              //   }
              // }}
              // onReset = {()=>{
              //   setCertificationData(allData);
                
              // }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
            />
          </ConfigProvider> */}
      <Card >
      
        <Table
          rowKey={record => record.certificationId}
          columns={columnsSkelton}
          dataSource={certificationData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          
          scroll = {{x:true}}
          onChange={onChange}
          size='small'
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '60%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <CertificationsForm key={Date.now()}
                updateCertification={updateCertification}
                isUpdate={true}
                certificationData={slectedCertificationData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default CertificationsGrid;
