import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { DesignationActionDto, ExitActionDto } from "@gtpl/shared-models/hrms";
import { DesignationActionService, ExitActionService } from "@gtpl/shared-services/hrms";
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import './exit-action-form.css';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { ExitReasonsEnum } from '@gtpl/shared-models/common-models';
// import { EmployeeCode, EmployeeName } from '@gtpl/shared-models/hrms-employee-details-form'

const { TextArea } = Input;
const { Option } = Select;
export interface ExitActionFormProps {
  exitActionData: ExitActionDto;
  updateExitAction: (exitAction: ExitActionDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ExitActionForm(props: ExitActionFormProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const exitActionService = new ExitActionService();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [rolesData, setRolesData] = useState<RolesDto[]>([]);
  const empService = new HrmsEmpDetService();
  const dateFormat = "YYYY-MM-DD";

  const rolesDataService = new RolesService();
  const hrmsEmpDetService = new HrmsEmpDetService();
  const [exitData, setExitData] = useState<ExitActionDto[]>([]);
 


  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  useEffect(() => {
    console.log(props.exitActionData)
    // getAllEmployeeDetails();
    getAllActiveRoles();
    // getEmployeeDetails();
    getAllActiveEmployees();
  }, []);


  const getAllActiveRoles = () => {
    rolesDataService.getAllActiveRoles().then(res => {
      if (res.status) {
        setRolesData(res.data);
      } else {
        if (res.intlCode) {
          setRolesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRolesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  
  useEffect(() => {
    getAllExitActions();
  },[]);

  /**
   * 
   */
  const getAllExitActions = () => {
    exitActionService.getAllExitActions().then(res => {
      if(res.status){
        setExitData(res.data);
      }else {
        if(res.intlCode){
          setExitData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setExitData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  } 

  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSelectedEmployeeData = (req) => {
    const request = new HrmsEmployeeRequest(req);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeName: res.data.employeeName+ res.data.employeeLastName,
          employeeId: res.data.employeeId,
          employeeCode: res.data?.employeeCode,
          fromDesignation:res.data?.designation,
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const createExitAction = (exitAction: ExitActionDto) => {
    exitActionService.createExitAction(exitAction).then(res => {
      if (res.status) {
        history.push("/exit-action-view");
        onReset();
        AlertMessages.getSuccessMessage('Exit Action Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset = () => {
    console.log('')
    form.resetFields();
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const saveData = (values: ExitActionDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateExitAction(values);
    } else {
      console.log(values);
      createExitAction(values);
    }
  }
  const onFocus = () => {
    console.log('focus');
  }
  const onBlur = () => {
    console.log('blur');
  }

  if (props?.exitActionData && props.exitActionData.exitDate)
  props.exitActionData.exitDate = moment(props.exitActionData.exitDate);

  return (
    <Card title={<span style={{ color: 'white' }}>Exit Action</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/exit-action-view' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout='vertical' form={form} onFinish={saveData} initialValues={props.exitActionData}>
        <FormItem
          name="exitActionId" style={{ display: 'none' }}>
          <Input hidden />
        </FormItem>
        <FormItem style={{ display: 'none' }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </FormItem>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="exitDate"
              label="Exit Date "
              rules={[
                {
                  required: true,
                  message: "Date is required"
                },

              ]}>
              <DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
              name="employeeId"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "Employee Code is required"
                },
              ]}>
              <Select
                placeholder="Select Employee Code"
                onChange={getSelectedEmployeeData}
              >
                <Option key={0} value={null}> Select Employee Code</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="exitReason"
              label="Exit Reason"
              rules={[
                {
                  required: true,
                  message: "Exit Reason is required."
                },
              ]}>
              <Select
                placeholder="Select Exit Reason"
              >
                {Object.keys(ExitReasonsEnum).map((reason) => {
                  return <Option value={ExitReasonsEnum[reason]}>{ExitReasonsEnum[reason]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
              name="approvedBy"
              label="Approved By"
              rules={[
                {
                  required: true,
                  message: "Approved By is required"
                },
              ]}>
              <Select
                placeholder="Select Approved By"
              >
                <Option key={0} value={null}> Select Approved By</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{`${employee.employeeName} ${employee.employeeLastName}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Don't cross the limit`,
                  max:100,
                }
              ]}
            >
              <TextArea rows={2} maxLength={150} />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>

      </Form>
    </Card>

  );
};


export default ExitActionForm;
