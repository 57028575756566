import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {GradeForm} from '@gtpl/pages/master/master-components/grades-form';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import {GradesDto} from '@gtpl/shared-models/masters';
import {GradeServices} from '@gtpl/shared-services/masters';
import './grades-grid.css'
import { Link } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
// import GetCumulatives, { CumulativeModel,CumulativeTypes } from './get-cumulatives';

/* eslint-disable-next-line */
export interface GradeGridProps {}

export function GradeGrid() {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [defaultData, setDefaultData] = useState<ItemVariant>(new ItemVariant(null,null,null));
  const [gradeData, setGradeData] = useState<GradesDto[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<any>(undefined);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new GradeServices;

  useEffect(() => {
    getAllGrades();
  }, []);

  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Grade Name',
      dataIndex: 'gradeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.gradeName.localeCompare(b.gradeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('gradeName')
    },
    {
      title: 'Minimum Grade',
      dataIndex: 'gradeMinCount',
      // responsive: ['lg'],
     sorter: (a, b) => a.gradeMinCount - b.gradeMinCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('gradeMinCount')
    },
    {
      title: 'Maximum Grade',
      dataIndex: 'gradeMaxCount',
      // responsive: ['lg'],
      sorter: (a, b) => a.gradeMaxCount - b.gradeMaxCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('gradeMaxCount')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm 
            onConfirm={e =>{deleteGrade(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Grade ?'
                :  'Are you sure to Activate Grade ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  
  const getAllGrades= () => {
    service.getAllGrades(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {setGradeData(res.data);
      } else {
        if (res.intlCode) {
          setGradeData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setGradeData([]);
    })
  }


  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(GradeViewData: GradesDto)=>{
    setDrawerVisible(true);
    setSelectedGrade(GradeViewData);
  }

    /**
     * 
     * @param variantData 
     */
    const updateGrade = (gradeData: GradesDto) => {
      gradeData.updatedUser= JSON.parse(localStorage.getItem('username'))
      service.updateGrade(gradeData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllGrades();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    /**
     * 
     * @param gradesDto 
     */
    const deleteGrade = (gradesDto:GradesDto) => {
      gradesDto.isActive=gradesDto.isActive?false:true;
      service.activateDeactivateGrades(gradesDto).then(res => { console.log(res);
        if (res.status) {
          getAllGrades();
          AlertMessages.getSuccessMessage('Success'); 
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  return (
  
<Card title={<span style={{color:'white'}}>Grades</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/Grades-form' ><Button className='panel_button' >Create </Button></Link>}>


     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Grades: ' + gradeData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + gradeData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active:' + gradeData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
      <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
          rowKey={record => record.gradeId}
          columns={columnsSkelton}
          dataSource={gradeData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <GradeForm key={Date.now()}
                updateGrade={updateGrade}
                isUpdate={true}
                // saveItem={saveVariant}
                gradesData={selectedGrade}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}

export default GradeGrid;
