import React from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {ItemDamageReasonsService} from '@gtpl/shared-services/masters'
import {DamageReasonsDto} from '@gtpl/shared-models/masters'

import './item-damage-reasons-form.css';
import TextArea from 'antd/lib/input/TextArea';


/* eslint-disable-next-line */
export interface ItemDamageReasonsFormProps {
  itemdamagereasonsData: DamageReasonsDto;
  updateDetails:(itemdamagereasons:DamageReasonsDto)=>void;
 isUpdate:boolean;
closeForm: () => void;

}

export function ItemDamageReasonsForm(props: ItemDamageReasonsFormProps) {
     
  const [form] = Form.useForm();
  const service = new ItemDamageReasonsService
  let history = useHistory();

  const save = (Data: DamageReasonsDto) => {
    service.createItemDamageReason(Data).then(res => {
   if (res.status) {
     AlertMessages.getSuccessMessage('Details Created Successfully');
         
     history.push("/item-damage-reasons-view")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
   AlertMessages.getErrorMessage(err.message);
 })
}

const saveData = (values: DamageReasonsDto) => {
  // console.log(values);
  if(props.isUpdate){
    props.updateDetails(values);
  }else{
    save(values);
  }

};
const onReset = () => {
  form.resetFields();
};


  return (

    <Card title={<span style={{color:'white'}}>Asset Damage Reasons</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/item-damage-reasons-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form} initialValues={props.itemdamagereasonsData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="damageReasonId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.itemdamagereasonsData}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="damageReason"
                      label="Damage Reasons"
                      rules={[
                        {
                          required: true,
                        },
                        { 
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                          message: `Should contain only alphabets.`,
                        }
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
           
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="remarks"
                    label="Remarks"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    >
                      <TextArea rows={1} />
                    
                  </Form.Item>
            </Col>
          
            
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
  );
}


   
export default ItemDamageReasonsForm;
