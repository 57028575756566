import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { TemperatureLogService } from "@gtpl/shared-services/warehouse-management";
import { DayWiseTemperatureModel, DayWiseTempRequest } from "@gtpl/shared-models/warehouse-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { Card, Col, Row } from "antd";

export interface GradingDayWiseTempProps { }

const GradingDayWiseTemp = (props: GradingDayWiseTempProps) => {
    const tempLogService = new TemperatureLogService()
    const [tempData, setTempData] = useState<DayWiseTemperatureModel[]>([]);
    const [tempCount, setTempCount] = useState<any[]>([]);

    useEffect(() => {
        getDayWiseTemeperaturesForDept();
        getCountOfTemperature();
    }, [])

    const getDayWiseTemeperaturesForDept = () => {
        const req = new DayWiseTempRequest;
        const deptCode = "WH"
        req.deptCode = deptCode
        req.startDate = moment(moment().subtract(30, 'days')).format("YYYY-MM-DD")
        req.endDate = moment().format('YYYY-MM-DD')
        tempLogService.getDayWiseTemeperaturesForDept(req).then((res) => {
            if (res.status) {
                setTempData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    };

    const getCountOfTemperature = () => {
        tempLogService.getCountOfTemperature().then((res) => {
            if (res.status) {
                setTempCount(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf("day").subtract(idx, "days").format('DD-MMMM'));

    const config = {
        chart: {
            type: 'spline',
        },
        title: {
            text: 'Last 30 Days Average Temperature | Temp >-18°C of last 30 days : ' + tempCount[0]?.countOfTemp,
        },
        xAxis: {
            title: { text: 'Days' },
            categories: lastThirtyDays,
            accessibility: {
                description: 'Months of the year'
            }
        },
        yAxis: {
            title: {
                text: 'Temperature'
            },
            labels: {
                formatter: function () {
                    return this.value + '°';
                }
            }, plotLines: [{
                color: 'red',
                value: -18,
                width: 1,
                label: {
                    text: '-18°C',
                    rotation: 360
                }
            }],

        },
        tooltip: {
            crosshairs: true,
            shared: true
        },
        plotOptions: {
            spline: {
                marker: {
                    radius: 4,
                    lineColor: '#666666',
                    lineWidth: 1
                }
            }
        },
        series: [{
            name: 'Highest Temperature',
            marker: { symbol: 'circle' },
            data: tempData.map(val => val.highestTemperature),
            color: '#e7b05d'
        }, {
            name: 'Lowest Temperature',
            marker: {
                symbol: 'cirle'
            },
            data: tempData.map(val => val.lowestTemperature),
            color: '#74b5f1'
        },
        ]
    }
    // -18°C
    return (
        <div>
            <Row justify="space-around">
                {/* <Col>
                    <Card bordered={false} style={{ padding: '17px', width: 350, background: '#74b5f1', height: 41, textAlign: 'left', marginTop: '64px', marginLeft: '-87px' }}>{('Temp >-18°C of last 30 days : ' + tempCount[0]?.countOfTemp)}</Card>
                </Col> */}
                <Col xs={24} sm={24} md={6} xl={24} style={{margin:'1%'}}>
                    <HighchartsReact highcharts={Highcharts} options={config} />
                </Col>
                {/* <Col xs={24} sm={24} md={6} xl={4} style={{margin:'1%'}}>
                    <Card title={'Current Temperature'} style={{height:'273px'}}>
                        <CurrentTemp />
                    </Card>
                </Col> */}


            </Row>

        </div>
    )
}

export default GradingDayWiseTemp;