import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card,Form, Switch, Input, Button, Tag, Row, Col, Drawer, Tooltip} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';
import { FgStatusEnum } from '@gtpl/shared-models/common-models';
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmployeeDetailsForm } from '@gtpl/pages/master/master-components/hrms-employee-details-form';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import {SuggestionsDto} from '@gtpl/shared-models/hrms';
import {SuggestionsService} from '@gtpl/shared-services/hrms';
import {Suggestion} from '@gtpl/pages/hrms/hrms-components/suggestion';
import {DepartmentService} from '@gtpl/shared-services/masters';
import {DepartmentDto} from '@gtpl/shared-models/masters';

import './suggestion-grid.css';

/* eslint-disable-next-line */
export interface SuggestionGridProps {}

export function SuggestionGrid(
  props: SuggestionGridProps
) {

  const hrmsEmpDetService = new HrmsEmpDetService();
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [suggestionsData, setSuggestionsData] = useState<SuggestionsDto[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>(undefined);
  const [filtersData, setFiltersData] =useState<HrmsEmployeeDetailsDto[]>([]);
  const suggestionsService = new SuggestionsService();
  const deptService = new DepartmentService();
  const [employeeDetails, setEmployeeDetails] = useState<string[]>([]);
  const [employeesData, setEmployeesData]= useState<HrmsEmployeeDetailsDto[]>([]);
  

  const searchData = () => {
    
   
    const employeeName = form.getFieldValue('employeeName');
   
    console.log(employeeName);
    let selectedData = filtersData;
    if(employeeName){
      selectedData = selectedData.filter(item => item.employeeName === employeeName)
    }
   
    setEmployeeData(selectedData);
  }

  const getFiltersDropdownData = (filtersData) => {
    let etdData:any[] =[];
    let employeesData:any[] =[];
    filtersData.forEach(element => {
      if(element.employeeName){
        employeesData.push(element.employeeName)
      }
      
    });
    setEmployeeDetails(employeesData)
  }
  useEffect(()=> {
    getFiltersDropdownData(filtersData);
  },[filtersData])

  useEffect(()=> {
    console.log(selectedSuggestion);
  },[selectedSuggestion])

  
  
  useEffect(() => {
    getAllSuggestions();
    getAllActiveEmployeesForLeaves();

    
  },[]);

  /**
   * 
   */
  const getAllSuggestions = () => {
    suggestionsService.getAllSuggestions().then(res => {
      if(res.status){
        setSuggestionsData(res.data);
      }else {
        if(res.intlCode){
          setSuggestionsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSuggestionsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

    
    const getAllActiveEmployeesForLeaves = () => {
      hrmsEmpDetService.getAllActiveEmployeesForLeaves().then(res => {
        if (res.status) {
          setEmployeeData(res.data);
         
        } else {
          if (res.intlCode) {
            setEmployeeData(res.data);
          
            console.log(res.data)
            // AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            //  AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setEmployeeData([]);
        
        
        // AlertMessages.getErrorMessage(err.message);
      })
    }
  
  

  /**
   * 
   * @param suggestionsData 
   */
   const deleteSuggestion = (suggestionsData:SuggestionsDto) => {
    suggestionsData.isActive=suggestionsData.isActive?false:true;
    suggestionsService.activateOrDeactivateSuggestion(suggestionsData).then(res => { console.log(res);
      if (res.status) {
        getAllSuggestions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
   const updateSuggestion = (QualificationsData: SuggestionsDto) => {
    suggestionsService.updateSuggestion(QualificationsData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllSuggestions();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
   * used for column filter
   * @param dataIndex column data index
   */
    const getColumnSearchProps = (dataIndex:string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={ searchInput }
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex]
         .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {    setTimeout(() => searchInput.current.select());   }
      },
      render: text =>
        text ?(
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) :text
        )
        : null
       
    });

    /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    //TO open the form for updation
    const openFormWithData=(viewData: SuggestionsDto)=>{
      setDrawerVisible(true);
      console.log(viewData)
      setSelectedSuggestion(viewData);
      console.log(selectedSuggestion)
      console.log('selectedSuggestion')
    }

    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'Employee Code',
        dataIndex: 'employeeCode',
        // responsive: ['lg'],
        sorter: (a, b) => a.employeeCode?.length - b.employeeCode?.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeCode')
      },
      {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        // responsive: ['lg'],
        sorter: (a, b) => a.employeeName?.length - b.employeeName?.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeName')
      },
      {
        title: 'Department',
        dataIndex: 'departmentName',
        // responsive: ['lg'],
        sorter: (a, b) => a.departmentName?.length - b.departmentName?.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('departmentName')
      },
      {
        title: 'Suggestion Type',
        dataIndex: 'suggestionType',
        // responsive: ['lg'],
        sorter: (a, b) => a.suggestionType.length - b.suggestionType.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('suggestionType')
      },
      {
        title: 'Suggestion',
        dataIndex: 'suggestion',
        // responsive: ['lg'],
        sorter: (a, b) => a.suggestion.length - b.suggestion.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('suggestion')
      },
      
      // {
      //   title: 'Remarks',
      //   dataIndex: 'remarks',
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.remarks.length - b.remarks.length,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('remarks')
      // },
      
     
//       {
//         title: ' Suggestion Status',
//         dataIndex: 'status',
//         align:'center',
//         render: (status, rowData) => {
//           console.log(rowData)
// return(<>
//             {status == FgStatusEnum.OPEN?<Tag icon={<CheckCircleOutlined />} color="#87d068">{FgStatusEnum.OPEN}</Tag>: status == FgStatusEnum.CLOSE? <Tag icon={<CloseCircleOutlined />} color="#f50">{FgStatusEnum.CLOSE}</Tag>: <Tag icon={<CloseCircleOutlined />} color="#f79707">{FgStatusEnum.IN_PROGRESS}</Tag>}
            
//           </>
// )},
          
        
//         filters: [
//           {
//             text: FgStatusEnum.OPEN,
//             value: FgStatusEnum.OPEN,
//           },
//           {
//             text: FgStatusEnum.CLOSE,
//             value: FgStatusEnum.CLOSE,
//           },
//           {
//             text: FgStatusEnum.IN_PROGRESS,
//             value: FgStatusEnum.IN_PROGRESS,
//           },
//         ],
//         filterMultiple: false,
//         onFilter: (value, record) => 
//         {
//           console.log(record)
          
//           return record.status === value;
//         },
        
//       },
      {
        title: 'Suggestion Status',
        dataIndex: 'status',
       
        filters: [
          {
            text: FgStatusEnum.OPEN,
            value: FgStatusEnum.OPEN,
          },
          {
            text: FgStatusEnum.IN_PROGRESS,
            value: FgStatusEnum.IN_PROGRESS,
          },
          {
            text: FgStatusEnum.CLOSE,
            value: FgStatusEnum.CLOSE,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          
          return record.status === value;
        },
        
      },
      {
        title:`Action`,
        dataIndex: 'isActive',
        render: (text, rowData) => (
          <span>  
            <Tooltip placement="top" title='Edit'>        
              <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                onClick={() => {
                  if (rowData.isActive) {
                    openFormWithData(rowData);
                  } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated Suggestion');
                  }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            </Tooltip>
            <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteSuggestion(rowData);}}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate suggestion ?'
                  :  'Are you sure to Activate suggestion ?'
              }
            >
              <Switch  size="default"
                  className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                  checkedChildren={<RightSquareOutlined type="check" />}
                  unCheckedChildren={<RightSquareOutlined type="close" />}
                  checked={rowData.isActive}
                />
              
            </Popconfirm>
          </span>
        )
      }
    ];
  
    /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
    const OpenFormTocreateRecord = () => {
     console.log('redirect here');
     
    }
  return (
    <Card title={<span style={{color:'white'}}>Suggestions</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/suggestion' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Suggestion: ' + suggestionsData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + suggestionsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + suggestionsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.qualificationId}
          columns={columnsSkelton}
          dataSource={suggestionsData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <Suggestion key={Date.now()}
                updateSuggestion={updateSuggestion}
                isUpdate={true}
                suggestionsData={selectedSuggestion}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default SuggestionGrid;
