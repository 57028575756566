import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
// import {ExpensesService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ExpensesForm} from '@gtpl/pages/hrms/hrms-components/expenses';
// import { ExpensesDto } from '@gtpl/shared-models/masters';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { ExpensesService } from '@gtpl/shared-services/hrms';
import { ExpensesDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';

export interface ExpensesGridProps {}

export function ExpensesGrid(props: ExpensesGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [ExpensesData, setExpensesData] = useState<ExpensesDto[]>([]);
  const [slectedExpensesData, setSelectedExpensesData] = useState<ExpensesDto>();
  const [expensesData, setExpensesData] = useState<ExpensesDto[]>([]);
  const expensesDataService=new ExpensesService();
  useEffect(() => {
    getAllExpenses();
   
  }, []);

  
  const getAllExpenses= () => {
    expensesDataService.getAllExpenses().then(res => {
      if (res.status) {
        setExpensesData(res.data);
        
      } else {
        setExpensesData([]);
        if (res.intlCode) {
            setExpensesData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setExpensesData([]);
      setExpensesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
 
  const deleteExpenses = (ExpensesData:ExpensesDto) => {
    ExpensesData.isActive=ExpensesData.isActive?false:true;
    expensesDataService.activateOrDeactivateExpenses(ExpensesData).then(res => { console.log(res);
      if (res.status) {
        getAllExpenses();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    
    const updateExpenses = (ExpensesData: ExpensesDto) => {
      expensesDataService.updateExpenses(ExpensesData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllExpenses();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
   
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    // TO open the form for updation
    const openFormWithData=(viewData: ExpensesDto)=>{
      console.log('test',viewData)
      setDrawerVisible(true);
      setSelectedExpensesData(viewData);
      console.log('----',slectedExpensesData)
    }


  const columnsSkelton: ProColumns[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      hideInSearch: true,
      hideInForm: true,
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
	{
      title: 'Expenses Against Plant',
      dataIndex: 'expensesAgainst',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.expensesAgainst.localeCompare(b.expensesAgainst),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('expensesAgainst')
     
    },
    // {
    //   title: 'Expenses Type',
    //   dataIndex: 'expensesType',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.expensesType?.localeCompare.length(b.expensesType.length),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('expensesType')
    // },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.refrenceNo.length - b.refrenceNo.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('paymentMode')
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('amount')
    },
    {
      title: 'Corpus Approval',
      dataIndex: 'corpusApproval',
      hideInSearch: true,
      hideInForm: true,
      align:'center',
      render: (isActive, rowData) => (
        <>
          {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Approved</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">Rejected</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Approved',
          value: true,
        },
        {
          text: 'Rejected',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      valueEnum: {
        true: { text: 'Approved', status: 'Success' },
        false: { text: 'Rejected', status: 'Error' }
      }      
    
    },
    {
      title: 'Ref NO',
      dataIndex: 'refrenceNo',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.refrenceNo.length - b.refrenceNo.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('refrenceNo')
    },
	{
      title: 'Approved By',
      dataIndex: 'approvedBy',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.approvedBy.length - b.approvedBy.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('approvedBy')
    },
   
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      hideInSearch: true,
      hideInForm: true,
      responsive: ['sm'],
      // sorter: (a, b) => a.remarks.localeCompare(b.remarks),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('remarks')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      hideInSearch:true ,
      hideInForm: true,
      align:'center',
      render: (isActive, rowData) => (
        <>
          {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      valueEnum: {
        true: { text: 'Active', status: 'Success' },
        false: { text: 'InActive', status: 'Error' }
      }      

    },
    {
      title:`Action`,
      dataIndex: 'action',
      hideInSearch: true,
      hideInForm: true,
      render: (text, rowData) => (
        <span>   
           <Tooltip placement="top" title='Edit'>       
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Certification');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm 
            onConfirm={e =>{deleteExpenses(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Expenses ?'
                :  'Are you sure to Activate Expenses  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Expenses</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/expenses-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Expenses: ' + expensesData.length} style={{textAlign: 'left', width: 215, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + expensesData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + expensesData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <ConfigProvider locale={enUSIntl}>
            <ProTable
              columns={columnsSkelton}
              rowKey={record => record.expensesId}
              request={(params, sorter, filter) => {
                return Promise.resolve({
                  data: expensesData,
                  success: true,
                });
              }}
              scroll = {{x:true}}
              dataSource = {expensesData}
              dateFormatter = 'string'
              search = {false}
              onSubmit = {(params) => {
                if(Object.keys(params).length){
                  const filteredData = expensesData.filter(record => record.isActive === getBoolean(params.isActive));
                  setExpensesData(filteredData);
                }
              }}
              onReset = {()=>{
                setExpensesData(expensesData);
              }}
            />
          </ConfigProvider>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <ExpensesForm key={Date.now()}
                updateExpenses={updateExpenses}
                isUpdate={true}
                expensesData={slectedExpensesData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default ExpensesGrid;
