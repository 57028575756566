import React, {useState, useEffect, useRef} from 'react';
import {Divider, Table, Popconfirm, Card, Switch, Input, Button, Tag, Row, Col, Drawer} from 'antd';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {useIntl} from 'react-intl';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

//import './pages-master-master-components-hatcheries-grid.css';
//import { FormProvider } from 'antd/lib/form/context';
import {HatcheriesForm} from '@gtpl/pages/master/master-components/hatcheries-form';
import {HatcheryService} from '@gtpl/shared-services/masters';
import {HatcheriesDto} from '@gtpl/shared-models/masters';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


/* eslint-disable-next-line */
export interface HatcheriesGridProps {}

export function HatcheriesGrid(
  props: HatcheriesGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  
  const [hatcheryData, setHatcheryData] = useState<HatcheriesDto[]>([]);
  const [selectedHatchery, setSelectedHatchery] = useState<any>(undefined);
  const service = new HatcheryService;

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  /**
   * @param selectedKeys
   * @param confirm
   * @param dataIndex
   */
  function handleSearch(selectedKeys, confirm, dataIndex){
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
 
  const columnSkeleton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 + (index+1)
    },
    {
      title: 'Hatchery Name',
      dataIndex: 'hatcheriesName',
      sorter: (a, b) => a.hatcheriesName.localeCompare(b.hatcheriesName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('hatcheriesName')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined/>} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined/>} color="#f50"> In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) =>
      {
        return record.isActive === value;
      },
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Hatchery');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteHatchery(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Hatchery ?'
                :  'Are you sure to Activate Hatchery ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  useEffect(() => {getAllHatcheries();}, [])

  const getAllHatcheries = () => {
    service.getAllHatcheries(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if(res.status) {setHatcheryData(res.data);
      } else {
        if(res.intlCode) {
          setHatcheryData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setHatcheryData([]);
    })
  }

   //drawer related
   const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(HatcheryViewData: HatcheriesDto)=>{
    setDrawerVisible(true);
    setSelectedHatchery(HatcheryViewData);
    console.log(HatcheryViewData);
  }
  /**
   * 
   * @param varaiantData
   */
  const updateHatchery =(hatcheryData: HatcheriesDto) => {
    hatcheryData.updatedUser=localStorage.getItem('username')
    service.updateHatchery(hatcheryData).then(res => {console.log(res);
    if(res.status){
      AlertMessages.getSuccessMessage('Updated Successfully');
      getAllHatcheries();
      setDrawerVisible(false);
    }else {
      if(res.intlCode){
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
     * 
     * @param VariantViewData 
     */
   const deleteHatchery = (hatcheryViewData:HatcheriesDto) => {
    hatcheryViewData.isActive=hatcheryViewData.isActive?false:true;
    service.activateordeactivatehatchery(hatcheryViewData).then(res => { console.log(res);
      if (res.status) {
        getAllHatcheries();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  return (
    <Card title={<span style={{color:'white'}}>Hatcheries</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/hatcheries-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Hatcheries: ' + hatcheryData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + hatcheryData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + hatcheryData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
      <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
          rowKey={record => record.hatcheryId}
          columns={columnSkeleton}
          dataSource={hatcheryData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <HatcheriesForm key={Date.now()}
                updateHatchery={updateHatchery}
                isUpdate={true}
                // saveItem={saveVariant}
                hatcheryData={selectedHatchery}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}


export default HatcheriesGrid;
