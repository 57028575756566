import React, { useState } from 'react';
import {Button, Card, Col, Form, Input, Row} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {ExpensesCategoryDto} from '@gtpl/shared-models/hrms';
import { ExpensesCategoryService } from '@gtpl/shared-services/hrms';
import './expenses-category.css';

/* eslint-disable-next-line */
export interface ExpensesCategoryProps {
  expensesCategoryData: ExpensesCategoryDto;
  updateExpensesCategory: (expensesCategory: ExpensesCategoryDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ExpensesCategory(
  props: ExpensesCategoryProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const expensesCategoryService = new ExpensesCategoryService()
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset=()=>{
    console.log('');
    form.resetFields();
     }

     const createExpensesCategory = (Data: ExpensesCategoryDto) => {
      setDisable(true)
      expensesCategoryService.createExpensesCategory(Data).then(res => {
        setDisable(false)
        if(res.status) {
          AlertMessages.getSuccessMessage('Expenses Category created successfully');
          history.push('/expenses-category-view');
          onReset();
        } else {
          if(res.intlCode){
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
   
    const saveData = (values: ExpensesCategoryDto) => {
      setDisable(false)
      if(props.isUpdate){
        console.log(values);
        props.updateExpensesCategory(values);
      }else {
        setDisable(false)
        console.log(values);
        createExpensesCategory(values);
      }
    };
  return (
   
      <Card title={<span style={{color:'white'}}>Expenses Category</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/expenses-category-view' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.expensesCategoryData} >
        <Form.Item 
        name ="expensesCategoryId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="expensesCategory"
                label="Expenses Category"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Expenses Category name."
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/_@ ]*$/,
                    message: `Should contain only alphabets and numbers.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
   </Card>
  );
}

export default ExpensesCategory;
