import React, { useEffect, useRef, useState } from 'react';
import {  Button, Card, Drawer, Form, Row, Col, Alert, Select, DatePicker, Anchor,Spin, Input,Table, Tag  } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import {RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import './so-design-grid.css';
import {SaleOrderTypeService,CustomersService,EndCustomerService} from '@gtpl/shared-services/masters';
import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import {SoViewDto,SoViewDtoDtoDefault,SODto} from '@gtpl/shared-models/sale-management';
import { SaleOrderTypeDto,CustomerDto,EndCustomerDto, } from '@gtpl/shared-models/masters';
import { BomParameter } from '@gtpl/pages/sale-management/sale-pages/bom-parameter';
import { render } from 'react-dom';
const { Option } = Select;
import { Link, Redirect, Route } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface SoDesignGridProps {}

export function SoDesignGrid(props: SoDesignGridProps) {
  const [form] = Form.useForm();
  const [showSO, setShowSO] = useState<Boolean>(false);
  const service = new SaleOrderService;
  const saleOrderService = new SaleOrderTypeService;
  const customerService = new CustomersService;
  const endCustomerService = new EndCustomerService;
  const [saleOrderViewData, setSaleOrderViewData] = useState<SoViewDto[]>([]);
  const [saleOrdersData, setSaleOrdersData] = useState<SODto[]>([]);
  const [customersData, setCustomersData] = useState<CustomerDto[]>([]);
  const [endCustomerData, setEndCustomerData] = useState<EndCustomerDto[]>([]);
  const [statusData, setStatusData] = useState<SaleOrderTypeDto[]>([]);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<number>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<number>(null);
  const [selectedEndCustomer, setSelectedEndCustomer] = useState<number>(null);
  const [selectedStatus, setSelectedStatus] = useState<number>(null);
  const [selectedExpectedDate, setSelectedExpectedDate] =  useState('');
  const [selectedSoView, setSelectedSoView] = useState<any>(SoViewDtoDtoDefault);
  const [redirectToBom, setRedirectToBom] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const [page, setPage] = React.useState(1);
const columns = useState('');
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const handleChange=(value) =>{
    console.log(value.format('DD-MM-YYYY'));
    setSelectedExpectedDate(value.format('DD-MM-YYYY'))
  }
  function getDifferenceInDays(expectedEndDate, actualEndDate) {
    const diffInMs = Math.abs(actualEndDate - expectedEndDate);
    return diffInMs / (1000 * 60 * 60 * 24);
  }
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }
  const viewSO= (soViewDetailsData: SoViewDto, viewOnly: boolean = true) => {
    console.log(soViewDetailsData);
    setShowSO(true);
    getSoViewDetails(selectedCustomer, selectedEndCustomer, selectedSaleOrder,selectedStatus, selectedExpectedDate);
    setSelectedSoView(soViewDetailsData);
}
useEffect(() => {
  getAllCustomers();getAllSaleOrders();getAllEndCustomers();getSoViewDetails(selectedCustomer, selectedEndCustomer, selectedSaleOrder,selectedStatus, selectedExpectedDate);
}, []);
const getSoViewDetails=async (selectedCustomer, selectedEndCustomer, selectedSaleOrder,selectedStatus, selectedExpectedDate)=>{
  await service.getSoViewDetails(selectedCustomer, selectedEndCustomer, selectedSaleOrder,selectedStatus, selectedExpectedDate ).then(res => { console.log(selectedSaleOrder);
    console.log(res.data);
    if (res.status) {
      setSaleOrderViewData(res.data);
  } else {
    if (res.intlCode) {
      setSaleOrderViewData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
    } else {
     AlertMessages.getErrorMessage(res.internalMessage);
    }
  }
}).catch(err => {
  AlertMessages.getErrorMessage(err.message);
  setSaleOrderViewData([]);
})
}
const getAllSaleOrders = () => {
  service.getAllSaleOrders().then(res => {
    console.log(res.data);

    if (res.status) {
      setSaleOrdersData(res.data);
    } else {
      if (res.intlCode) {
        setSaleOrdersData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setSaleOrdersData([]);
  })
}
const getAllEndCustomers = () => {
  endCustomerService.getAll().then(res => {
    console.log(res.data);

    if (res.status) {
      setEndCustomerData(res.data);
    } else {
      if (res.intlCode) {
        setEndCustomerData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setCustomersData([]);
  })
}
const getAllCustomers = () => {
  customerService.getAllCustomers(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
    console.log(res.data);

    if (res.status) {
      setCustomersData(res.data);
    } else {
      if (res.intlCode) {
        setCustomersData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setCustomersData([]);
  })
}
const handleSaleOrder = (value) => {
  console.log(`selected ${value}`);
  setSelectedSaleOrder(value);
}
const handleCustomer = (value)=>{
  console.log(`selected ${value}`);
  setSelectedCustomer(value);
}
const handleEndCustomer = (value)=>{
  console.log(`selected ${value}`);
  setSelectedEndCustomer(value);
}
const handleStatus = (value)=>{
  console.log(`selected ${value}`);
  setSelectedStatus(value);
}


  const Data=[
    {
      key: '1',
      saleOrderNumber: 'WALMART/RPDTO',
      operations: 'RM stock',
      status: ['completed'],
      expectedEndDate: '16-03-2021',
      actualEndDate: '15-03-2021',
      overdue: '0',
    },
    {
      key: '2',
      saleOrderNumber: 'WALMART/RPDTO',
      operations: 'FG stock',
      status: ['In-Progress'],
      expectedEndDate: '16-03-2021',
      actualEndDate: '15-03-2021',
      overdue: '0',
    },
    {
      key: '3',
      saleOrderNumber: 'WALMART/RPDTO',
      operations: 'Process',
      status: ['open'],
      expectedEndDate: '16-03-2021',
      actualEndDate: '15-03-2021',
      overdue: '0',
    }
  ]
  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    
    },
    {
      title: 'Sale Order',
      dataIndex: 'saleOrderNumber', 
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      // responsive: ['lg'],
      ...getColumnSearchProps('saleOrderNumber')
    },
    {
      title: 'Operations',
      dataIndex: 'operations', 
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      // responsive: ['lg'],
      ...getColumnSearchProps('operations')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      key:'status',
      // responsive: ['lg'],
      // render: status => (
      //   <>
      //     {status.map(tag => {
      //       let color = tag.length >5 ? 'green' : 'geekblue';
      //       if (tag === 'In-Progress') {
      //         color = 'orange';
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // )
    },
   
    {
      title: 'Expected End Date',
      dataIndex: 'expectedEndDate', 
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      // responsive: ['lg'],
      ...getColumnSearchProps('expectedEndDate')
    },

    {
      title: 'Actual End Date',
      dataIndex: 'actualEndDate', 
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      // responsive: ['lg'],
      ...getColumnSearchProps('actualEndDate')
    },

    {
      title: 'Overdue Period',
      dataIndex: 'overdue', 
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      // render:(value)=>(
      //   getDifferenceInDays(expectedEndDate,actualEndDate)
      // ),
      // responsive: ['lg'],
      ...getColumnSearchProps('overdue')
    },
    {
      title:`Action`,
      dataIndex: 'action',
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      render: (text, rowData) => (
        <span>
        {(redirectToBom)?<Redirect
          to={{
            pathname: "/saleorder-bom",
            state: rowData.saleOrderId
          }}
        />:''}<SolutionOutlined className={'editSamplTypeIcon'} type="edit"
          onClick={() => {
            setRedirectToBom(true);
          }}
          style={{ color: '#1890ff', fontSize: '14px' }}
        />




      </span>)
    }
  ];
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }
  function onReset(){
    form.resetFields();
  }
  

  return (
    <>
    <Card title={<span style={{color:'white'}}>Sale Order View</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
    <Form form={form} layout={'inline'} style={{ padding: '0px' }}  onFinish={viewSO}>
    <Row gutter={[32, 32]}>
             
              <Col>
              <Form.Item name="customerName"
                  label="Customer Name"
                  rules={[
                    {
                      required: false,
                      message: "Select Customer Name"
                    },
                  ]}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Customer Name"
                optionFilterProp="children"
                onChange={handleCustomer}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select All Customer</Option>
                {customersData.map((customer)=>{
                  return <Option key={customer.custId} value={customer.custId}>{customer.clientName}</Option>
                })}
              </Select>
              </Form.Item>
               
            </Col>
            <Col>
              <Form.Item name="endCustomerName"
                  label="End Customer"
                  rules={[
                    {
                      required: false,
                      message: "Select End Customer"
                    },
                  ]}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select End Customer"
                optionFilterProp="children"
                onChange={handleEndCustomer}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select End Customer</Option>
                {endCustomerData.map((endCustomer)=>{
                  return <Option key={endCustomer.endCustomerId} value={endCustomer.endCustomerId}>{endCustomer.endCustomerName}</Option>
                })}
              </Select>
              </Form.Item>
            </Col>
              <Col>
              <Form.Item name="saleOrderNumber"
                  label="Sale Order"
                  rules={[
                    {
                      required: false,
                      message: "Select Sale Order"
                    },
                  ]}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Sale Order"
                optionFilterProp="children"
                onChange={handleSaleOrder}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Sale Order</Option>
                {saleOrdersData.map((saleOrder)=>{
                  return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.saleOrderNumber}</Option>
                })}
              </Select>
              </Form.Item>
            </Col>
              {/* <Col>
              <Form.Item name="status"
                  label="Status"
                  rules={[
                    {
                      required: false,
                      message: "Select Status"
                    },
                  ]}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Status"
                optionFilterProp="children"
                onChange={handleStatus}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select All Status</Option>
                {statusData.map((status)=>{
                  return <Option key={status.statusId} value={status.statusId}>{status.statusName}</Option>
                })}
              </Select>
              </Form.Item>
            </Col> */}
              <Col>
                <Form.Item name="expectedDate"
                  label="Expected Date"
                  rules={[
                    {
                      required: false,
                      message: "ExpectedDate"
                    },
                  ]}>
                  <DatePicker onChange={handleChange} picker="date" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Get Details
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>

                </Form.Item>
              </Col>
            </Row>
    </Form></Card>
    <Card>
    <Table
    rowKey={record => record.Id}
    columns={sampleTypeColumns}
    dataSource={saleOrderViewData}
     pagination={{
    onChange(current) {
      setPage(current);
    }
  }}
  onChange={onChange} 
    bordered
  /></Card></>
  );
}

export default SoDesignGrid;
