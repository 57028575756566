import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { DesignationActionDto } from "@gtpl/shared-models/hrms";
import { DesignationActionService } from "@gtpl/shared-services/hrms";
import { DepartmentDto, DeptRequest, HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { DepartmentService, HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { DesignationDto } from '@gtpl/shared-models/hrms';
import { DesignationService } from '@gtpl/shared-services/hrms';
import './designation-action-form.css';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
// import { EmployeeCode, EmployeeName } from '@gtpl/shared-models/hrms-employee-details-form'

const { TextArea } = Input;
const { Option } = Select;
export interface DesignationActionFormProps {
  designationActionData: DesignationActionDto;
  updateDesignationAction: (designationAction: DesignationActionDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function DesignationActionForm(props: DesignationActionFormProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const designationActionService = new DesignationActionService();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [rolesData, setRolesData] = useState<RolesDto[]>([]);
  const empService = new HrmsEmpDetService();
  const dateFormat = "YYYY-MM-DD";

  const rolesDataService = new RolesService();
  const hrmsEmpDetService = new HrmsEmpDetService();
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const designationService = new DesignationService();
  const fromDesignation = form.getFieldValue('fromDesignation');
  const [toDesignationData, setToDesignationData] = useState<DesignationDto[]>([]);
  const [departmentsData, setDepartmentsData] = useState<DepartmentDto[]>([]);
  const departmentService = new DepartmentService();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  useEffect(() => {
    console.log(props.designationActionData)
    // getAllEmployeeDetails();
    getAllActiveRoles();
    // getEmployeeDetails();
    getAllActiveEmployees();

  }, []);


  const getAllActiveRoles = () => {
    rolesDataService.getAllActiveRoles().then(res => {
      if (res.status) {
        setRolesData(res.data);
      } else {
        if (res.intlCode) {
          setRolesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRolesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }



  useEffect(() => {
    getAllDesignation();
    getAllActiveDepartment();
  }, []);

  /**
   * 
   */
  const getAllDesignation = () => {
    designationService.getAllDesignation().then(res => {
      if (res.status) {
        setDesignationData(res.data);
      } else {
        if (res.intlCode) {
          setDesignationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSelectedEmployeeData = (req) => {
    const request = new HrmsEmployeeRequest(req);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      console.log(res)
      if (res.status) {
        const req = new DeptRequest(Number(res.data?.departmentId))
        departmentService.getDeptDataById(req).then(response=>{
          if(response.data){
            const deptName = response.data.deptName;
            form.setFieldsValue({
              department: deptName
            })
          }
        })
        form.setFieldsValue({
          employeeName: res.data.employeeName + '  '  + res.data.employeeLastName,
          employeeId: res.data.employeeId,
          employeeCode: res.data?.employeeCode,
          fromDesignation: res.data?.designation,

          // fromDepartmentId:res.data?.departmentId,
          // approvedBy: res.data.employeeName + '  '  + res.data.employeeLastName,
        });
        //getToDesignationData(res.data?.designation)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const createDesignationAction = (designationAction: DesignationActionDto) => {
    designationActionService.createDesignationAction(designationAction).then(res => {
      if (res.status) {
        history.push("/designationaction-view");
        onReset();
        AlertMessages.getSuccessMessage('Designation Action Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllActiveDepartment = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentsData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset = () => {
    console.log('')
    form.resetFields();
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const saveData = (values: DesignationActionDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateDesignationAction(values);
    } else {
      console.log(values);
      createDesignationAction(values);
    }
  }
  const onFocus = () => {
    console.log('focus');
  }
  const onBlur = () => {
    console.log('blur');
  }

  if (props?.designationActionData && props.designationActionData.designationDate)
    props.designationActionData.designationDate = moment(props.designationActionData.designationDate);

  // const getToDesignationData = (value) => {
  //   //setToDesignationData(designationData.filter(i => i.designation != value))
  //   setToDesignationData(designationData.filter(i => i.designation = value))
  // }


  return (


    <Card title={<span style={{ color: 'white' }}>Designation Action</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/designationaction-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} name="control-hooks" layout="vertical" onFinish={saveData} initialValues={props.designationActionData}>

        <Form.Item name="designationActionId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="employeeId" >
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="designationDate"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Date is required"
                },

              ]}>
              <DatePicker
                style={{ width: '100%' }}
                format={dateFormat}
                showToday={true}
                disabledDate={(current) => {
                  return moment().add(-15, 'days') >= current ||
                    moment().add(15, 'month') <= current;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "Employee Code is required"
                },
              ]}>
              <Select
                placeholder="Select Employee Code"
                onChange={getSelectedEmployeeData}
              >
                <Option key={0} value={null}> Select Employee Code</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                <Form.Item
                  name="department"
                  label="From Department"

                  rules={[
                    {
                      required: true,
                      message: 'Department is required'
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    // onFocus={personalDetailsAffix}
                    // onChange={onChange}
                    onSearch={onSearch}
                    disabled
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {departmentsData?.map(dropData => {
                      return <Option key={dropData.deptId} value={dropData.deptName}>{dropData.deptName}</Option>;
                    })}
                  </Select>
                  
                </Form.Item>

              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                <Form.Item
                  name="toDepartment"
                  label="To Department"

                  rules={[
                    {
                      required: true,
                      message: 'Department is required'
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    // onFocus={personalDetailsAffix}
                    // onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {departmentsData?.map(dropData => {
                      return <Option key={dropData.deptId} value={dropData.deptName}>{dropData.deptName}</Option>;
                    })}
                  </Select>
                </Form.Item>

              </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="fromDesignation"
              label="From Designation"
              rules={[
                {
                  required: true,
                  message: "To Designation is required"
                },
              ]}>
              <Select
                placeholder="Select designation"
                // onChange={}
                disabled
                // onSelect={getToDesignationData}
                allowClear
                style={{ width: '100%' }}
              >
                {designationData.map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}</Select>
                
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="toDesignation"
              label="To Designation"
              rules={[
                {
                  required: true,
                  message: "To Designation is required"
                },
              ]}>
              <Select
                placeholder="Select designation"
                // onChange={}
                allowClear
                style={{ width: '100%' }}

              >
                {designationData?.map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="approvedBy"
              label="Approved By"
              rules={[
                {
                  required: true,
                  message: "Approved By is required"
                },
              ]}
            >
              <Select
                // onChange={getSelectedEmployeeData}
                placeholder="Select Approved By"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData?.employeeId} value={employeeData?.employeeName+' '+employeeData?.employeeLastName}>{employeeData?.employeeName +' '+employeeData?.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
};


export default DesignationActionForm;
