import { AssetService } from "@gtpl/shared-services/asset-management"
import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import { Col, Row } from "antd"
import Form, { useForm } from "antd/lib/form/Form"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { title } from "process"
import React, { useEffect, useState } from "react"

export interface AssetsAgeGraphProps { }

export function AssetsAgeGraph() {
  const form = useForm

  useEffect(() => {
    getAgeWiseAssets();
  }, [])
  const [assetsData, setAssetsData] = useState<any[]>([]);
  console.log(assetsData)
  const getAgeWiseAssets = () => {
    service.getAgeWiseAssets().then(res => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        if (res.intlCode) {
          setAssetsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setAssetsData([]);
    })

  }
  const plantname = assetsData.map(i => { return i.plantName });
  const last3months = assetsData.map(i => { return Number(i.last3months) });
  const threeTo6months = assetsData.map(i => { return Number(i.threeTo6months) });
  const sixTo12months = assetsData.map(i => { return Number(i.sixTo12months) });
  const yearbefore = assetsData.map(i => { return Number(i.yearbefore) });
  const purchasedButNotAssigned = assetsData.map(i => { return Number(i.purchasedButNotAssigned) });
  console.log(plantname);
  console.log(last3months);
  console.log(threeTo6months);
  console.log(sixTo12months);
  console.log(yearbefore);
  console.log(purchasedButNotAssigned);
  const service = new AssetService();

  const config = {
    colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
      type: 'column',
      backgroundColor: '#272953'
    },

    title: {
      text: 'Age Wise In-Stock Assets',
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.75rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      }
    },

    xAxis: {
      categories: plantname,
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      // tickAmount: 16,
      // tickPixelInterval: 100,
      title: {
        text: 'Number of In Stock Assets'
      },
      enabled: true,
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.15rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      },
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' + 'No.of Assets: ' + this.y + '<br/>'

      }
    },

    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },

    series: [
      // {
      //   name: 'Purchased But Not Assigned',
      //   data: purchasedButNotAssigned,
      //   // stack: 'male'
      // },
      {
        name: 'Last3 M',
        data: last3months,
        // stack: 'male'
      },
      {
        name: '3to6 M',
        data: threeTo6months,
        // stack: 'male'
      },
      {
        name: '6to12 M',
        data: sixTo12months,
        // stack: 'male'
      },
      {
        name: 'Above 1Yr',
        data: yearbefore,
        // stack: 'male'
      },
    ]
  }
  return (
    <Form layout="vertical" name="control-hooks">
      <Row gutter={24}>

        {/* {containerData.length ? */}
        <Col span={24}>
          <div><HighchartsReact highcharts={Highcharts} options={config} /></div>
        </Col>
        {/* : <Empty/> */}
        {/* } */}
      </Row>
    </Form>

  )
}
