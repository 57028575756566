import React, { useEffect, useState } from 'react';
import { RackDto, RmRackDto, ZoneDropDownDto, ZoneDto } from '@gtpl/shared-models/masters';
import { OccupancyEnum, RackPositionEnum } from "@gtpl/shared-models/masters";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  RmRackService,ZoneService } from '@gtpl/shared-services/masters'
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';

import './rm-racks-form.css';

/* eslint-disable-next-line */
export interface RmRacksFormProps {
  rackData: RmRackDto;
  updateRack: (rack: RmRackDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export function RmRacksForm(
  props: RmRacksFormProps
) {
  const [form] = Form.useForm();
  const rackService = new RmRackService;
  const zoneService = new ZoneService();
  const [selectedZoneName, setSelectedZoneName] = useState<number>(null);
  const [zoneData, setZoneData] = useState<ZoneDropDownDto[]>([]);
  const [display, setDisplay] = useState<string>('none');

  useEffect(() => { 
    if(!props.isUpdate){
      form.setFieldsValue({reservedForDummy:0})
    }
    getAll();
   }, [])
  let history = useHistory();

  const getAll = () => {
    zoneService.getAllZoneDropDown().then(res => {
      console.log(res.data);
      if (res.status) {
        setZoneData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }



  const createRack = (rackDto: RmRackDto) => {
    rackDto.plantId=Number(localStorage.getItem('unit_id'));
    rackService.createRmRack(rackDto).then(res => {
      console.log(res.data);
      if (res.status) {
        AlertMessages.getSuccessMessage('Rack Created Successfully');
        history.push("/rm-rack");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleZoneName = (value) => {
    setSelectedZoneName(value);
    if (value == "") {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
  }
  const onReset = () => {
    console.log('');
    form.resetFields();

  }
  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const saveData = (values: RmRackDto) => {
    if (props.isUpdate) {
      console.log(values)
      props.updateRack(values);
    } else {

      console.log(values)
      createRack(values);
    }

  };
  return (<Card title={<span style={{ color: 'white' }}>Rm Rack</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate ? '' : <Link to='/rm-rack' ><Button className='panel_button' >View </Button></Link>}
  >
    <Form layout="vertical" form={form} initialValues={props.rackData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="rmRackId" style={{ display: "none" }} >
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>

      
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
          <Form.Item
            name="zoneId"
            label="Zone Name"
            rules={[
              {
                required: true,
                message: 'Zone Name is required'
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }

            ]}>
            <Select
              showSearch
              //style={{ width: 210 }}
              placeholder="Select Zone"
              optionFilterProp="children"
              onSelect={handleZoneName}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Zone</Option>
              {zoneData.map((zoneData) => {
                return <Option key={zoneData.zoneId} value={zoneData.zoneId}>{zoneData.zoneName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
          <Form.Item
            name="rmRackCode"
            label="Rack Code"
            rules={[
              {
                required: true,
                message: 'Rack Code Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
          <Form.Item
            name="rmRackName"
            label="Rack Name"
            rules={[
              {
                required: true,
                message: 'Rack Name Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
          <Form.Item
            name="reservedForDummy"
            label="Rack Type"
            rules={[
              {
                required: true,
                message: 'Rack Type Required',

              },
            ]}>
            <Select
                  placeholder="Select Rack Type"
                  allowClear
                  defaultValue={0}
                >
                  <Option value="null">Select Rack Type</Option>
                  <Option value={1}>Dummy</Option>
                  <Option value={0}>Sale Order</Option>
                </Select>
          </Form.Item>
        </Col>
        

      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" htmlType="submit" >
            Submit
         </Button>
          {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
       </Button>
          }


        </Col>
      </Row>
    </Form>
  </Card>
  );
}


export default RmRacksForm;