import { AssetService } from "@gtpl/shared-services/asset-management"
import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import { Col, Row } from "antd"
import Form, { useForm } from "antd/lib/form/Form"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { title } from "process"
import React, { useEffect, useState } from "react";
import drilldown from "highcharts/modules/drilldown.js";
import exporting from "highcharts/modules/exporting.js";
import exportData from "highcharts/modules/export-data.js";


drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);

export interface AssetRentedGraphProps{}
function Table(props) {
  console.log(props.data);
  if (!props.data) {
    return null;
  } else {
    return (
      <table>
        <thead>{props.data.name}</thead>
      </table>
    );
  }
}

export const AssetRentedGraph = () =>{
    const form = useForm

    useEffect(() =>{
     getRentedAssetsDetails();
    },[])
    const[assetsData,setAssetsData]= useState<any[]>([]);
    const [tableData, setTableData] = useState();

    console.log(assetsData)
    const getRentedAssetsDetails =()=>{
        service.getRentedAssetsDetails().then(res => {
          if (res.status) {
            setAssetsData(res.data);
          } else {
              if (res.intlCode) {
                setAssetsData([]);
                  AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
              }
          }
      }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setAssetsData([]);
      })
    
      }
      const status = assetsData.map(i =>{return i.status});
      const AssetCost = assetsData.map(i =>{return Number(i.cost)});
      const AssetCount = assetsData.map(i =>{return Number(i.assets)});
      console.log(status);
      console.log(AssetCost);
      console.log(AssetCount);
    const service = new AssetService();
    
    const config = {
      colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
      chart: {
        type: 'bar',
        backgroundColor: '#272953',
        events: {
          drilldown(e) {
            setTableData(e.seriesOptions);
            console.log(e);
          }
        }
      },
  
      title: {
        text: 'Rented Assets',
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.75rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        }
      },
  
      xAxis: {
        categories: status,
        labels: {
          style: {
            color: 'var(--text-color,#eee)'
          }
        }
      },
  
      yAxis: {
        allowDecimals: false,
        min: 0,
        // tickAmount: 16,
        // tickPixelInterval: 100,
        formatter: function() {
          return Highcharts.numberFormat(this.value, 2);
      },
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.15rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        },
        labels: {
          style: {
            color: 'var(--text-color,#eee)'
          }
        }
      },
  
      tooltip: {
        // formatter: function () {
        //   return '<b>' + this.x + '</b><br/>'  + 'No.of Assets: ' + this.y  + '<br/>'
  
        // }
      },
  
      plotOptions: {
        bar: {
          stacking: 'normal',
          borderRadius: '5%',
          dataLabels: {
            enabled: true
          }
        }
      },
      
  
      series: [
        {
        name: 'Asset Count',
        data:AssetCount,
        stack: 'type-a'
      }, 
      {
        name: 'Asset Cost',
        data:AssetCost,
        stack: 'type-b'
      },
    ]
    }
return(
      <div>
        <HighchartsReact highcharts={Highcharts} options={config} />
        <Table data={tableData} />
      </div>
      
)
}
