import { CodeListDto } from "@gtpl/shared-models/planning";
import React, { useState } from "react";

export interface CodeListPrintProps {
    //     codeListData: CodeListDto;
    //   isUpdate: boolean;
    //   closeForm: () => void;
}

export function CodeListPrint(props: CodeListPrintProps) {
    const [codeListData, setcodeListData] = useState<any[]>([]);
    return (
        <div>
            <br />
            <html>
                <body id='print' className="codesInfo">
                    <br></br>
                    <div>
                        <table className={'table-b'} style={{ width: '100%' }} id="table-to-xls">
                            <tr><td className={'table-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '20px', lineHeight: '10px', paddingTop: '20px' }}><h1>SANDHYA AQUA EXPORTS PVT. LTD,UNIT-II (CHE/FFE/02728)</h1></td></tr>
                            <tr><td className={'table-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '20px', lineHeight: '10px', paddingTop: '20px' }}><h1>CODE LIST</h1></td></tr>
                            <tr><td className={'table-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '16px', lineHeight: '10px', paddingTop: '20px' }}><h1>COOK & PEEL</h1></td></tr>
                        </table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <table>
                                <td className={'table-b'} colSpan={3} style={{ fontSize: '15px' }}>
                                    <b></b><br />
                                    <b>COUNTRY:</b> <br />
                                    <b>BUYER  :</b><br />
                                    <b>PO NO  :</b><br />
                                    <b>LOT NO :</b><br />
                                    <b></b><br />
                                </td>
                            </table>
                        </div>
                        <div>
                            <table style={{ marginRight: '5rem' }}>
                                <thead>
                                    <tr>
                                        <th className={'ta-b'} rowSpan={2} colSpan={2} style={{ textAlign: 'center', width: '350px' }}>SPECIFICATION(WALMART)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={'ta-b'} colSpan={1} style={{width:'100px'}}><b>GLAZE</b></td>
                                        <td className={'ta-b'} colSpan={1}> </td>
                                    </tr>
                                    <tr>
                                        <td className={'ta-b'}  colSpan={1} ><b>TREATMENT</b></td>
                                        <td className={'ta-b'}  colSpan={1} > </td>
                                    </tr>
                                    <tr>
                                        <td className={'ta-b'} colSpan={1}><b>CUTTING</b></td>
                                        <td className={'ta-b'}  colSpan={1} > </td>
                                    </tr>
                                    <tr>
                                        <td className={'ta-b'} colSpan={1}><b>BAP</b></td>
                                        <td className={'ta-b'}  colSpan={1} > </td>
                                    </tr>
                                    <tr>
                                        <td className={'ta-b'} colSpan={1}><b>OTHER</b></td>
                                        <td className={'ta-b'}  colSpan={1} > </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <table>
                                <tr >
                                    <td style={{ fontSize: '15px' }}>
                                        <b>VARIETY: </b><br />
                                        <b>PACKING: </b><br />
                                        <b>BRAND  : </b><br />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ marginRight: '11rem' }}>
                            <table>
                                <tr>
                                    <td colSpan={3} style={{ fontSize: '15px' }}>
                                        <b></b><br /><br />
                                        <b>DATE: </b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div> <br />

                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th className={'ta-b'} rowSpan={2} style={{ textAlign: 'center', width: '200px' }}>CODE</th>
                                    <th className={'ta-b'} rowSpan={2} style={{ textAlign: 'center', width: '200px' }}>TRACIBILITY</th>
                                    <th className={'ta-b'} style={{ textAlign: 'center', width: '200px' }}>Production Date</th>
                                    <th className={'ta-b'} style={{ textAlign: 'center', width: '200px' }}>Best Before Date</th>
                                    <th className={'ta-b'} rowSpan={2} style={{ textAlign: 'center', width: '200px' }}>26/30</th>
                                </tr>
                                <tr>
                                    <th className={'ta-b'} >MM/ DD/ YYYY</th>
                                    <th className={'ta-b'} >MM/ DD/ YYYY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[].map(item => {
                                    return <tr key={item.code}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                })}
                                <tr>
                                    <th className={'ta-b'} colSpan={4} style={{ textAlign: 'center' }}>TOTAL MASTER CARTONS</th>
                                    <th className={'ta-b'} ></th>
                                </tr>
                            </tbody><br />
                            <tr>
                                <td colSpan={4}><b>ISSUED BY:</b><br /></td>
                                <td colSpan={4}><b>VERIFIED BY:</b></td>
                            </tr>
                        </table></div>
                </body>
            </html>
        </div>
    )
};
export default CodeListPrint;