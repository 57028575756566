import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from "react-router-dom";
import { Button, Card, Checkbox, Col, Collapse, Descriptions, Drawer, Form, Input, Modal, Row, Select, Table, Tabs } from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import Highlighter from 'react-highlight-words';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import Scanner from '../../../asset-classification-form/src/lib/Scanner';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import { AssetsDto } from '@gtpl/shared-models/asset-management';

const { Option } = Select;
const { TabPane } = Tabs;

/* eslint-disable-next-line */
export interface DecommisionedAssetsProps { }

export function DecommisionedAssets(
  props: DecommisionedAssetsProps
) {

  const [page, setPage] = useState<number>(1);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const employeeService = new EmployeeService();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedData, setSelectedData] = useState<any>([]);
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const service = new AssetService();
  const [assetsData, setAssetsData] = useState<any>([]);
  const [code, setCode] = useState(0);
  const assetsService = new AssetService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [assetsCompleteData, setAssetsCompleteData] = useState<any>([]);
  const [assetCodeData, setAssetCodeData] = useState<AssetDto[]>([]);
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [itemdata, setItemData] = useState<any>(null);
  const [modal, setModal] = useState('');
  const [newModal, setNewModal] = useState<boolean>(false);


  const role = JSON.parse(localStorage.getItem('role'))
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);

  let diff = 0;
  let date;
  let todate;

  useEffect(() => {
    employeeDetails();
    getDepartments();
    getExpiredAssets();
    getDecommissionedAssets();
    getAllPlants();
    getItemCategory();
    getAssetCode();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }  
    getLocations()
  }, [])

  const getAssetCode = () => {
    const req = new AssetByStatus()
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getallAssets(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  
  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getExpiredAssets = () => {
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    service.getExpiredAssets(req).then(res => {
      if (res.status) {
        setAssetsData(res.data)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Expired assets retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')

        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getDecommissionedAssets = () => {
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (form.getFieldValue('plant') !== undefined) {
      req.plant = form.getFieldValue('plant')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeName') !== undefined) {
      req.employeeId = form.getFieldValue('employeeName')
    }
    service.getDecommissioneddAssetsReport(req).then(res => {
      if (res.status) {
        setAssetsCompleteData(res.data)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Retired assets retrieved successfully')
        } else {
          // AlertMessages.getErrorMessage('No records found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const AssetsDetailedView = (assetId) => {
    const req = new AssetByCode(assetId)
    assetsService.getByAssetCode(req).then(res => {
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        setNewModal(true)
        // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)

    })
  }


  const handleAssetIdScan = (value) => {
    setShowQrScan(true)
    form.setFieldsValue({
      assetCode: value
    })

  }
  const handleBack = () => {
    setNewModal(false)
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      key: "sno",
      width: '30px',
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      align: 'left',
      width: 80,
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },

    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      align: 'left',
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location_name'),
      render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Mode',
      dataIndex: 'asset_mode',
      align: 'left',
      sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_mode'),
      render: (text, record) => {
        return (
          <>
            {record.asset_mode ? record.asset_mode : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      align: 'left',
      sorter: (a, b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_type'),
      render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Category',
      dataIndex: 'asset_category',
      align: 'left',
      sorter: (a, b) => a.asset_category.localeCompare(b.asset_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_category')

    },

    // filters: [
    //   {
    //     text: 'MOVABLE',
    //     value: 'movable',
    //   },

    //   {
    //     text: 'IMMOVABLE',
    //     value: 'immovable',
    //   },
    // ],
    // filterMultiple: false,
    // onFilter: (value, record) => {
    //   // === is not work
    //   return record.asset_type === value;
    // },



    {
      title: 'Asset Name',
      dataIndex: 'assets_name',
      align: 'left',

      ...getColumnSearchProps('assets_name'),
      sorter: (a, b) => a.assets_name.localeCompare(b.assets_name),
      sortDirections: ['descend', 'ascend']

    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: 'left',
      ...getColumnSearchProps('assetsCode'),
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return <>
          <Button type='link' onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
        </>
      }
    },
    {
      title: "Invoice Number",
      dataIndex: "asset_invoice_no",
      align: "left",
      sorter: (a, b) => a.asset_invoice_no?.localeCompare(b.asset_invoice_no),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_invoice_no'),
      // width:120,
      render: (text, record) => {
        return (
          <>
            {record.asset_invoice_no ? record.asset_invoice_no : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Serial Number",
      dataIndex: "asset_serialno",
      align: "left",
      sorter: (a, b) => a.asset_serialno?.localeCompare(b.asset_serialno),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_serialno'),
      render: (text, record) => {
        return (
          <>
            {record.asset_serialno ? record.asset_serialno : '-'}
          </>
        )
      },
    },

    {
      title: 'Asset Cost',
      dataIndex: 'asset_cost',
      align: 'right',
      // sorter: (a, b) => a.asset_cost?.localeCompare(b.asset_cost),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_cost'),
      render: (text, record) => {
        return (
          <>
            {record.asset_cost ? Number(Number(record.asset_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
    },

    {
      title: 'Expiry Date',
      dataIndex: 'expired_date',
      align: 'right',
      sorter: (a, b) => a.expired_date?.localeCompare(b.expired_date),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('expired_date'),
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Asset Age',
      dataIndex: 'aging',
      align: 'right',
      ...getColumnSearchProps('aging'),
      // render: (text, record) => {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //   date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD'));
      //   diff = (todate - date) / 864e5;
      //   return (
      //     <>
      //       {record.date_of_purchased ? diff : null}
      //     </>
      //   )
      //},
      sorter: (a, b) => a.aging - b.aging,
      sortDirections: ['descend', 'ascend'],
      //   sorter: (a, b) => moment(a.date_of_purchased).days() - moment(b.date_of_purchased).days(),
      // sortDirections: [ 'ascend','descend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'left',

      render: (text, rowData) => (
        <span>
          <Button type='primary' shape='round' onClick={() => clickHandler(rowData)}>Retire</Button>
        </span>
      )
    }

  ];
  const columns1: ColumnsType<any> = [
    {
      title: "S.No",
      key: "sno",
      width: '30px',
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Plant',
      dataIndex: 'plant',
      align: 'left',
      sorter: (a, b) => a.plant.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('plant')

    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      align: 'left',
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location_name'),
      render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('location_name')

    },
    {
      title: 'Asset Mode',
      dataIndex: 'asset_mode',
      align: 'left',
      sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_mode'),
      render: (text, record) => {
        return (
          <>
            {record.asset_mode ? record.asset_mode : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      align: 'left',
      sorter: (a, b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_type'),
      render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
      // filters: [
      //   {
      //     text: 'MOVABLE',
      //     value: 'movable',
      //   },

      //   {
      //     text: 'IMMOVABLE',
      //     value: 'immovable',
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.asset_type === value;
      // },

    },
    {
      title: 'Asset Category',
      dataIndex: 'asset_category',
      align: 'left',
      sorter: (a, b) => a.asset_category.localeCompare(b.asset_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_category')


    },
    {
      title: 'Asset Name',
      dataIndex: 'assets_name',
      align: 'left',
      sorter: (a, b) => a.assets_name.localeCompare(b.assets_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assets_name')


    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: 'left',
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return (<>
          <Button type='link' onClick={() => AssetsDetailedView(record.assetsCode)}>
            {record.assetsCode}</Button>
        </>)
      },
    },
    {
      title: "Invoice Number",
      dataIndex: "asset_invoice_no",
      align: "left",
      sorter: (a, b) => a.asset_invoice_no?.localeCompare(b.asset_invoice_no),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_invoice_no'),
      render: (text, record) => {
        return (
          <>
            {record.asset_invoice_no ? record.asset_invoice_no : '-'}
          </>
        )
      },
    },
    {
      title: "Serial Number",
      dataIndex: "asset_serialno",
      align: "left",
      sorter: (a, b) => a.asset_serialno?.localeCompare(b.asset_serialno),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_serialno'),
      render: (text, record) => {
        return (
          <>
            {record.asset_serialno ? record.asset_serialno : '-'}
          </>
        )
      },


    },
    {
      title: 'Asset Cost',
      dataIndex: 'asset_cost',
      align: 'right',
      sorter: (a, b) => a.asset_cost-b.asset_cost,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_cost'),
      render: (text, record) => {
        return (
          <>
            {record.asset_cost ? record.asset_cost : '-'}
          </>
        )
      }
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expired_date',
      align: 'right',
      sorter: (a, b) => a.expired_date?.localeCompare(b.expired_date),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('expired_date'),
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    // {
    //   title: 'status',
    //   dataIndex: 'status',
    //   align: 'right',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.status ? record.status : '-'}
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Retired Date',
      dataIndex: 'updated_at',
      align: 'right',
      sorter: (a, b) => a.updated_at?.localeCompare(b.updated_at),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('updated_at'),
      render: (text, record) => {
        return (
          <>
            {record.updated_at ? moment(record.updated_at).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Approved By',
      dataIndex: 'decommission_approved_by',
      align: 'left',
      sorter: (a, b) => a.decommission_approved_by?.localeCompare(b.decommission_approved_by),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('decommission_approved_by'),
      render: (text, record) => {
        return (
          <>
            {record.decommission_approved_by ? record.decommission_approved_by : '-'}
          </>
        )
      }
    },
    {
      title: 'Department',
      dataIndex: 'dept_name',
      align: 'left',
      ...getColumnSearchProps('dept_name'),
      render: (text, record) => {
        return (
          <>
            {record.dept_name ? record.dept_name : '-'}
          </>
        )
      }
    },
    {
      title: 'Asset Age',
      dataIndex: 'aging',
      align: 'right',

      ...getColumnSearchProps('aging'),
      // render: (text, record) => {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //   date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD'));
      //   diff = (todate - date) / 864e5;
      //   return (
      //     <>
      //       {record.date_of_purchased ? diff : null}
      //     </>
      //   )
      // },
      // sorter: (a, b) => a.aging?.localeCompare(b.aging),
      // sortDirections: ['descend', 'ascend'],

    },


  ];

  const clickHandler = (rowData) => {
    setDrawerVisible(true)
    setSelectedData(rowData)
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
    form.resetFields();
  }

  const finishHandler = (values) => {
    service.assetDecommission(values).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated successfully')
        getExpiredAssets();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
    form.resetFields();
    setDrawerVisible(false);
    getExpiredAssets();
  }

  const onReset = () => {
    form.resetFields();
    getDecommissionedAssets();
    getExpiredAssets();

  }

  const onStatusSearch = () => {
    getDecommissionedAssets();
    getExpiredAssets();
  }

  return (
    <>
      {(code) ? <Redirect to={{ pathname: '/assetdetailedview', state: { id: code, loc: '/asset-decommission' } }} /> : null}

      <Card title={<span style={{ color: 'white' }}>Asset Retire Details</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>  <br></br>

        <Form form={form}
          layout='vertical'>
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

              <Form.Item name="assetCode"
                label="Asset Code">
                <Select
                  suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} />}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetCodeData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                  })

                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Asset Category' name='assetCategory' >
                <Select
                  defaultValue={'All'}
                  showSearch
                  //onChange={getItems}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {itemCategory.map((items) => {
                    return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
            <Col span={4}>
              <Form.Item label='Plant' name='plantId' >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                  showSearch
                  allowClear>
                  {/* <Option key={'all'} value={''}>All</Option> */}
                  {plantName.map((plant) => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Location' name='location'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={'All'}
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {locationData.map((location) => {
                    return (
                      <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item label='Department' name='department'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Employee Name - Code' name='employeeName'>
              <Select
                optionFilterProp="children"
                //  filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
            <Col style={{ marginTop: '2%' }}>
              <Button type='primary' onClick={onStatusSearch}>Submit</Button>
            </Col>
            <Col style={{ marginTop: '2%' }}>
              <Button onClick={onReset}>Reset</Button>
            </Col>
          </Row>
        </Form>
        <br />

        <Tabs>
          <TabPane key='1' tab={<span><b>{`About To Retire : ${assetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={assetsData} scroll={{ x: 1700 }} />
            <Drawer
              bodyStyle={{ paddingBottom: 80 }} title='Retire Approval' width={window.innerWidth > 768 ? '65%' : '60%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}

            >
              <Descriptions>
                <DescriptionsItem label='Asset Name'>{selectedData.assets_name}</DescriptionsItem>
                <DescriptionsItem label='Asset Code'>{selectedData.assetsCode}</DescriptionsItem>
              </Descriptions>

              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>

                <Form form={form} onFinish={finishHandler}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                      <Form.Item name='assetsCode' label='Asset Code' style={{ display: 'none' }} initialValue={selectedData.assetsCode}
                      >
                        <Input hidden />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                      <Form.Item name='assetsName' label='Asset Name' style={{ display: 'none' }} initialValue={selectedData.assets_name}
                      >
                        <Input hidden />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24,24]}>
                    <Col span={11}>
                      <Form.Item name='decommissionApprovedBy' label='Approved by'
                        rules={[{
                          required: true,
                          message: 'Approved by is required'
                        }]}
                      >
                        <Select

                          showSearch
                          optionFilterProp="children"

                          allowClear
                        >
                          {employeeData.map((employee) => {
                            return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        name={'agree'}
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("To proceed you need to agree"),
                          },
                        ]}
                      >
                        <Checkbox>
                          Retire assets cannot be retrieved.
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Button type="primary" htmlType="submit" >
                        Submit
                      </Button>
                      {[] &&
                        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={closeDrawer}>
                          Cancel
                        </Button>
                      }
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Drawer>
          </TabPane>
          <TabPane key='2' tab={<span><b>{`Retired Assets : ${assetsCompleteData.length}`}</b></span>}>
            <Table columns={columns1} dataSource={assetsCompleteData} scroll={{ x: 1700 }} />
            <Drawer
              bodyStyle={{ paddingBottom: 80 }} title='Retired' width={window.innerWidth > 768 ? '65%' : '60%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}

            >
              <Descriptions>
                <DescriptionsItem label='Asset Name'>{selectedData.assets_name}</DescriptionsItem>
                <DescriptionsItem label='Asset Code'>{selectedData.assetsCode}</DescriptionsItem>
              </Descriptions>

              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>

                <Form form={form} onFinish={finishHandler}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                      <Form.Item name='assetsCode' label='Asset Code' style={{ display: 'none' }} initialValue={selectedData.assetsCode}
                      >
                        <Input hidden />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                      <Form.Item name='assetsName' label='Asset Name' style={{ display: 'none' }} initialValue={selectedData.assets_name}
                      >
                        <Input hidden />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24,24]}>
                    <Col span={11}>
                      <Form.Item name='decommissionApprovedBy' label='Approved by'
                        rules={[{
                          required: true,
                          message: 'Approved by is required'
                        }]}
                      >
                        <Select

                          showSearch
                          optionFilterProp="children"

                          allowClear
                        >
                          {employeeData.map((employee) => {
                            return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        name={'agree'}
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("To proceed you need to agree"),
                          },
                        ]}
                      >
                        <Checkbox>
                          Retired Assets assets cannot be retrieved.
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Button type="primary" htmlType="submit" >
                        Submit
                      </Button>
                      {[] &&
                        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={closeDrawer}>
                          Cancel
                        </Button>
                      }
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Drawer>
          </TabPane>
        </Tabs>
        <Modal
        className='model'
        key={'newModal' + Date.now()}
        width={'95%'}
        onCancel={handleBack}
        style={{ top: 30, alignContent: 'right' }}
        visible={newModal}
        footer={[
          //  <Button key="back" onClick={() =>  handleBack()}>
          //    Back
          //  </Button>,
        ]}
        title={<React.Fragment>
        </React.Fragment>}
      >
        <Card title={<span style={{ color: 'white' }}> Detailed View</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

          <Descriptions>
            
            <Descriptions.Item label="Plant"> {itemdata?.plant} </Descriptions.Item>
            <Descriptions.Item label="Current Location"> {itemdata?.location_name} </Descriptions.Item>
            {/* <Descriptions.Item label="Asset Mode"> {previewdata?.assetMode} </Descriptions.Item> */}
            {/* <Descriptions.Item label="Asset Type"> {previewdata?.assetType} </Descriptions.Item> */}
            <Descriptions.Item label="Asset Category"> {itemdata?.assetCategory}</Descriptions.Item>
            <Descriptions.Item label="Asset Name"> {itemdata?.assetname}</Descriptions.Item>
            {/* <Descriptions.Item label="Asset Serial Number"> {previewdata?.assetSerialNo}</Descriptions.Item> */}
            {/* <Descriptions.Item label="Asset Code"> {previewdata?.assetsCode} </Descriptions.Item> */}
            {/* <Descriptions.Item label="Invoice">{previewdata?.invoice}  </Descriptions.Item> */}
            {/* <Descriptions.Item label="Model"> {previewdata?.model} </Descriptions.Item> */}
            {/* <Descriptions.Item label="No.Of Units"> {previewdata?.noOfUnits} </Descriptions.Item> */}
            {/* <Descriptions.Item label="Date Of Purchased"> {moment(previewdata?.dateOfPurchased).format('YYYY-MM-DD HH:mm:ss')} </Descriptions.Item>
            <Descriptions.Item label="Expiry Date"> {moment(previewdata?.expiredDate).format('YYYY-MM-DD HH:mm:ss')} </Descriptions.Item>
            <Descriptions.Item label="Asset Cost"> {previewdata?.assetCost} </Descriptions.Item>
            <Descriptions.Item label="Asset Supplier"> {previewdata?.assetSupplier} </Descriptions.Item>
            <Descriptions.Item label="AMC Availability"> {previewdata?.amc} </Descriptions.Item>
            <Descriptions.Item label="Warranty  Availability"> {previewdata?.warrenty} </Descriptions.Item>
            <Descriptions.Item label="Warranty Valid Upto">{previewdata?.warrentyExpiredDate ? moment(previewdata?.warrentyExpiredDate).format('YYYY-MM-DD') : '-'}  </Descriptions.Item>
            <Descriptions.Item label="Insurance"> {previewdata?.insurance} </Descriptions.Item>
            <Descriptions.Item label="Insurance Valid Upto">{previewdata?.insuranceDate ? moment(previewdata?.insuranceDate).format('YYYY-MM-DD') : '-'}  </Descriptions.Item>
            <Descriptions.Item label="Manufacturer">{previewdata?.manufacturer} </Descriptions.Item>
            <Descriptions.Item label="Year"> {previewdata?.year} </Descriptions.Item>
            <Descriptions.Item label="Units Of Measurment"> {previewdata?.unitsOfMeasurements} </Descriptions.Item> */}
          </Descriptions>
        </Card>
      </Modal>
        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
        // visible={modal}
        visible={showQrSacn} 
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            modal == 'assetId' ? <Scanner handleScan={handleAssetIdScan} /> : null
            //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal>
      </Card>
    </>
  );
}

export default DecommisionedAssets;
