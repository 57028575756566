import React from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';

/* eslint-disable-next-line */
export interface CommonPackingListProps {}

export function CommonPackingList(props: CommonPackingListProps) {
  return (
    // <Card
    //   title={<span style={{ color: 'white' }}>COMMON PACKING LIST</span>}
    //   style={{ textAlign: 'center' }}
    //   headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    //   extra={<Button>Print</Button>}
    // >
      <html>
        <head></head>
        <body>
          <h1 style={{ textAlign: 'center', fontSize: '32px' }}>
            PACKING LIST<Button onClick={() => window.print()} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}>Print</span></Button>
          </h1>

          <table className={'ta-b'} style={{ width: '100%' }}>
            <tr>
              <td className={'ta-b'} rowSpan={2}>
                <h4>Exporter:</h4>
                <b>SANDHYA AQUA EXPORTS PVT. LIMITED</b>
                <br />
                MIG-25A, LAWSONS BAY COLONY,
                <br />
                VISAKHAPATNAM - 530 017
                <br />
                ANDHRA PRADESH, INDIA
                <br />
                GSTIN : 37AAJCS2286C1ZA
              </td>
              <td className={'ta-b'}>
                Invoice No.
                <h4>SAE-A677 / 20-21</h4>
                Date
                <h4>08th Mar, 2021</h4>
              </td>
              <td className={'ta-b'}></td>
              <td className={'ta-b'}>
                Exporters Ref
                <h4>IE Code 2605001024</h4>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={'ta-b'}>
                Buyer's Order No.
                <h4>PO NO. AZ9327</h4>
                Date
                <h4> 23rd Jan, 2021</h4>
              </td>
            </tr>

            <tr>
              <td className={'ta-b'}>
                <h4>Processed and Packed at</h4>
                <b>SANDHYA AQUA EXPORTS PVT. LIMITED</b>
                <br />
                KURUMADDALI VILLAGE, PAMARRU MANDAL,
                <br />
                521157 KRISHNA DIST, ANDHRA PRADESH, INDIA
                <br />
                FDA REGISTRATION NO. 16652389826
                <br />
                FEI NUMBER: 2000038402
              </td>
              <td colSpan={3} className={'ta-b'}>
                <h4>
                  <u> NOTIFY PARTY</u>
                </h4>
                <b>ROD INTERNATIONAL CHB SERVICES LLC</b>
                <br />
                10330 PIONEER BLVD, SUITE 200
                <br />
                SANTA FE SPRINGS, CA 90670, USA.
                <br />
                TEL: (562)622-0715, FAX: (562)622-074
              </td>
            </tr>

            <tr>
              <td rowSpan={2} className={'ta-b'}>
                <h4>Consignee</h4>
                <b>AZ GEMS INC</b>
                <br />
                405 MISSOURI CT.
                <br />
                REDLANDS, <br />
                CA 92373
                <br />
                U S A<br />
                <b>FDA REGISTRATION NO:16502951940</b>
              </td>
              <td colSpan={3} className={'ta-b'}>
                <h4>
                  <u>IMPORTER ON RECORD</u>
                </h4>
                <b>AZ GEMS INC</b>
                <br />
                405 MISSOURI CT.
                <br />
                REDLANDS, CA 92373, USA
                <br />
                <b>FDA REGISTRATION NO:16502951940</b>
              </td>
            </tr>
            <tr>
              <td className={'ta-b'}>
                Country of Origin of Goods
                <h4>
                  <b>INDIA</b>
                </h4>
              </td>
              <td colSpan={2} className={'ta-b'}>
                Country of Final Destination
                <h4>
                  <b>USA</b>{' '}
                </h4>
              </td>
            </tr>

            <tr>
              <td className={'ta-b'}>
                <h4>Pre Carriage By</h4>
                <h4>Road</h4>
              </td>
              <td className={'ta-b'}>
                <h4>Place of Receipt of Pre-carrier</h4>
              </td>
              <td colSpan={2} className={'ta-b'}>
                Terms of Delivery and Payment
                <h4>
                  <b>PAYMENT 90 DAYS FROM BL DATE</b>
                </h4>
              </td>
            </tr>

            <tr>
              <td className={'ta-b'}>
                <h4>Vessel / Flight No. </h4>
                <h4>
                  <b>TABEA V-MNIV3N</b>
                </h4>
              </td>
              <td className={'ta-b'}>
                Port of Loading
                <h4>
                  <b>VISAKHAPATNAM, INDIA</b>{' '}
                </h4>
              </td>

              <td colSpan={2} rowSpan={2} className={'ta-b'}>
                Container No.&nbsp;<b>AMCU9285015</b>
                <br />
                Liner Seal No.&nbsp;<b>P4246474</b>
                <br />
                Bill of Lading No.&nbsp;<b>16502951940</b>
              </td>
            </tr>
            <tr>
              <td className={'ta-b'}>
                Port of Discharge
                <h4>HOUSTON, USA </h4>
              </td>
              <td className={'ta-b'}>
                Final Destination
                <h4>HOUSTON, USA</h4>
              </td>
            </tr>

            <tr>
              <td colSpan={1} className={'ta-b'}>
                Marks and Nos / No and Kind of Pkgs Description of Goods
                <br />
                <br />
                &nbsp;&nbsp; <h4>HSN CODE SIZE No. OF CARTONS</h4>
                <br />
                <br />
                20 X 1.00 LB 0306.17.20 RAW FROZEN PEELED AND DEVEINED <br />
                20.00 LB TAIL OFF VANNAMEI SHRIMPS, IQF <br />
                9.080 KGS LATIN NAME : LITOPENAEUS VANNAMEI <br />
                16/20 150
                <br />
                <br />
                26/30 425
                <br />
                <br />
                31/40 250
                <br />
                <br />
                51/60 350
                <br />
                <br />
                71/90 50
                <br />
                <br />
                100/200 175
                <br />
                20 X 1.00 LB 0306.17.20 RAW FROZEN PEELED AND DEVEINED <br />
                20.00 LB BROKEN VANNAMEI SHRIMPS, IQF <br />
                9.080 KGS LATIN NAME : LITOPENAEUS VANNAMEI <br />
                40/60 150
                <br />
                60/80 100
              </td>
              <td>
                <h4>
                  Quantity
                  <br />
                  IN LBS
                  <br />
                  NET WEIGHT
                  <br />
                </h4>
                <br />
                <br />
                <br />
                3,000.000
                <br />
                0.000
                <br />
                8,500.000
                <br />
                0.000
                <br />
                5,000.000
                <br />
                0.000
                <br />
                7,000.000
                <br />
                0.000
                <br />
                1,000.000
                <br />
                0.000
                <br />
                3,500.000
                <br />
                0.000
                <br />
                0.000
                <br />
                0.000
                <br />
                0.000
                <br />
                0.000
                <br />
                3,000.000
                <br />
                0.000
                <br />
                2,000.000
              </td>
              <td className={'ta-b'}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </td>
              <td className={'ta-b'}></td>
            </tr>

            <tr>
              <td colSpan={1} className={'ta-b'}>
                Total <h4>1650</h4>
              </td>
              <td className={'ta-b'}>
                <h4>33,000.000</h4>
              </td>
              <td className={'ta-b'}></td>
              <td className={'ta-b'}></td>
            </tr>
            <tr className={'ta-b'}>
              <td colSpan={2}>
                <tr>
                  <td colSpan={1}>
                    <br />
                    <tr>TOTAL NET WT. :</tr>
                    <tr>TOTAL GROSS WT. :</tr>
                  </td>
                  <td colSpan={1}>
                    <div style={{ marginLeft: '20px' }}>
                      <tr>
                        <b>IN LBS</b>
                      </tr>
                      <tr>#REF!</tr>
                      <tr>#REF!</tr>
                    </div>
                  </td>
                  <td colSpan={1}>
                    <div style={{ marginLeft: '20px' }}>
                      <tr>
                        <b>IN KGS</b>
                      </tr>
                      <tr>#REF!</tr>
                      <tr>#REF!</tr>
                    </div>
                  </td>
                </tr>
              </td>
              <td colSpan={2} className={'ta-b'}>
                <h4>TOTAL NO. OF MASTER CARTONS : 1650</h4>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={'ta-b'}>
                Declaration :<br />
                We declare that this invoice shows the actual price of the goods
                <br />
                described and that all particulars are true and correct
                <br />
              </td>
              <td colSpan={2} className={'ta-b'}>
                Signature and Date For &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                <h4>SANDHYA AQUA EXPORTS PVT. LTD</h4>
                <br />
                <h4>AUTHORISED SIGNATORY</h4>
              </td>
            </tr>
          </table>
        </body>
      </html>
    // </Card>
  );
}

export default CommonPackingList;
