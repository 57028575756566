import React, { useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import './insurance-form.css';
import { InsuranceDto } from '@gtpl/shared-models/masters';
import {InsuranceService} from '@gtpl/shared-services/masters'
/* eslint-disable-next-line */
export interface InsuranceFormProps {
  insuranceData: InsuranceDto;
  updateInsurance: (insurance: InsuranceDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function InsuranceForm(
  props: InsuranceFormProps
) {

  const { Option } = Select;
  const [disable, setDisable] = useState<boolean>(false)
  const [setUse,setSelectedUser] = useState<InsuranceDto[]>([]);
  const [form] = Form.useForm();
  let history = useHistory();
  const insuranceService = new InsuranceService();
  const [display, setDisplay] = useState<string>('none');
 
  const onReset = () => {
    form.resetFields();
  };

  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }

  const saveData = (values: InsuranceDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateInsurance(values);
    } else {
      setDisable(false)
      console.log(values);
      createInsurance(values);
    }
  };

  const createInsurance = (insuranceDto: InsuranceDto) => {
    setDisable(true)
    insuranceService.createInsurance(insuranceDto)
      .then((res) => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage('Insurance Created Successfully');
          history.push('/insurance-view');
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  };
  return (
    <Card
      title={<span style={{ color: 'white' }}> Insurance Details</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={
        props.isUpdate == true ? (
          ''
        ) : (
          <Link to="/insurance-view">
            <Button className="panel_button">View </Button>
          </Link>
        )
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={props.insuranceData}
        name="control-hooks"
        
        onFinish={saveData}
      >
        <Form.Item name="insuranceId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>
        <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}} style={{margin:'1%'}}>
            <Form.Item
              name="companyName"
              label="Company Name"
              
              rules={[
                {
                  required: true,
                  message: 'Company Name is required',
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}} style={{margin:'1%'}}>
            <Form.Item
              name="emailId"
              label="Email"
              rules={[
                { required: true, message: 'Email is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}} style={{margin:'1%'}}>
          
            <Form.Item
              name="mobileNumber"
              label="Mobile No"
              rules={[
                { required: true, message: ' Valid Mobile No is required' , max:12},
                {
                  pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/, 
                  message: `Don't Allow letters and Spaces`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(!props.isUpdate) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
      </Form>
    </Card>
  );
}

export default InsuranceForm;
