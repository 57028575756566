import { BomTrackerPackingItemsDto } from "@gtpl/shared-models/sale-management";
import { StoreConsumptionService } from "@gtpl/shared-services/procurement";
import { Button, Card, Col, Input, Row, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Redirect } from "react-router-dom";
import { SaleOrderService } from "@gtpl/shared-services/sale-management";
import { GlobalStatus } from "@gtpl/shared-models/common-models";

export interface BomItemsGridProps {
    saleOrderId: number;
}

function BomItemsGrid(
    props: BomItemsGridProps
) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [bomSoData, setBomSoData] = useState<BomTrackerPackingItemsDto[]>([]);
    const [renderToPoForm, setRenderToPoForm] = useState<boolean>(false);
    const [poForm, setPoForm] = useState(0);
    const service = new SaleOrderService();
    useEffect(() => {
        getStoreItems();
    }, [])

    const renderToPo = (saleOrderId) => {
        setRenderToPoForm(true);
        setPoForm(saleOrderId);
    }

    const getStoreItems = () => {
        service.getBomItemsForSoId({ saleOrderId: props.saleOrderId }).then(res => {
            if (res.status) {
                setBomSoData(res.data);
            } else {
                setBomSoData([]);
            }
        }).catch(err => {
            setBomSoData([]);
        })
    }


    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });

    const BomColumns: ColumnProps<any>[] = [
        {
            title: 'Item',
            dataIndex: 'item',
            width: 150,
            fixed: 'left',
            align: 'left',
            sorter: (a, b) => a.item.localeCompare(b.item),
            ...getColumnSearchProps('item'),

        },
        {
            title: 'Packing Category',
            dataIndex: 'packingCategory',
            width: 150,
            align: 'left',

            // sorter: (a, b) => a.packingCategory - b.packingCategory,
            // ...getColumnSearchProps('packingCategory'),

        },
        {
            title: 'BOM Quantity',
            dataIndex: 'quantity',
            width: 150,
            align: 'right',

            // sorter: (a, b) => a.quantity - b.quantity,
            // ...getColumnSearchProps('quantity'),
            render: (text, record) => {
                return <span>{`${Math.round(record.quantity)}`}</span>
            }

        },
        {
            title: 'Existing Stock',
            dataIndex: 'existingQuantity',
            width: 150,
            align: 'right',

            // sorter: (a, b) => a.existingQuantity - b.existingQuantity,
            // ...getColumnSearchProps('existingQuantity'),
            render: (text, record) => {
                return <span>{`${Math.round(record.existingQuantity)}`}</span>
            }
        },
        {
            title: 'Assigned Stock',
            dataIndex: 'AssignedQuantity',
            width: 150,
            align: 'right',

            // sorter: (a, b) => a.AssignedQuantity - b.AssignedQuantity,
            // ...getColumnSearchProps('AssignedQuantity'),
            render: (text, record) => {
                return <span>{`${Math.round(record.AssignedQuantity)}`}</span>
            }
        },
        {
            title: 'To be Order',
            dataIndex: 'poQuantity',
            width: 150,
            align: 'right',
            // sorter: (a, b) => a.poQuantity - b.poQuantity,
            // ...getColumnSearchProps('poQuantity'),
            render: (text, record) => {
                return <span>{`${(Number(record.quantity) - Number(record.poQuantity) - Number(record.AssignedQuantity)) > 0 ? Math.round(Number(record.quantity) - Number(record.poQuantity) - Number(record.AssignedQuantity)) : 0}`}</span>
            }
        },
        {
            title: 'PO Quantity',
            dataIndex: 'poQuantity',
            width: 150,
            align: 'right',
            // sorter: (a, b) => a.poQuantity - b.poQuantity,
            // ...getColumnSearchProps('poQuantity'),

        },
        {
            title: 'GRN Quantity',
            dataIndex: 'grnQuantity',
            width: 150,
            // render: (text, rowData,index) => { rowData.status === 'GRN' ? `${Number(rowData.grnQuantity)}` : 0 },
            align: 'right',
            // sorter: (a, b) => a.grnQuantity - b.grnQuantity,
            // ...getColumnSearchProps('grnQuantity'),

        },

        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
            align: 'left',

            render: (status, rowData) => (
                <>
                    {status == "OPEN" ? <a><Tag onClick={() => renderToPo(props.saleOrderId)} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> </a> : status == "PO" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>PURCHASE ORDER</Tag> :
                        status == "GRN" ? <Tag style={{ backgroundColor: '#e4f467', color: "black", fontWeight: 'bold' }}>GRN</Tag>
                            : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : (status == "ASSIGNED") ? <Tag style={{ backgroundColor: '#e4f467', color: "black", fontWeight: 'bold' }}>ASSIGNED</Tag> : ''}

                </>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
            filters: [
                {
                    text: 'OPEN',
                    value: 'OPEN',
                },
                {
                    text: 'ASSIGNED',
                    value: 'ASSIGNED',
                },
                {
                    text: 'PURCHASE ORDER',
                    value: 'PO',
                },
                {
                    text: 'GRN',
                    value: 'GRN',
                },
                {
                    text: 'CLOSED',
                    value: 'CLOSED',
                },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                // === is not work
                return record.status === value;
            },
        },
        {
            title: 'Revised',
            dataIndex: 'isQtyRevised',
            fixed: 'right',
            align: 'left',
            render: (isQtyRevised, rowData) => (
                <>
                    {isQtyRevised === GlobalStatus.YES ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{isQtyRevised}</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">{isQtyRevised}</Tag>}

                </>
            ),
            filters: [
                {
                    text: GlobalStatus.YES,
                    value: GlobalStatus.YES,
                },
                {
                    text: GlobalStatus.NO,
                    value: GlobalStatus.NO,
                },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                // === is not work
                return record.isQtyRevised === value;
            },

        },
        {
            title: 'Item Status',
            dataIndex: 'isActive',
            fixed: 'right',
            align: 'left',
            render: (isActive, rowData) => (
                <>
                    {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

                </>
            ),
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'InActive',
                    value: false,
                },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                // === is not work
                return record.isActive == value;
            },

        },


    ];
    return (
        <>
            {(poForm) ? <Redirect to={{ pathname: "/purchase-order-form", state: { id: poForm, type: 'BOM' } }} /> : null}
            <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>BOM DETAILS</span>}></Card>
            <Card>
                <Row >
                    <Col style={{ marginLeft: '5%' }}>
                        <Card title={'TOTAL: ' + (bomSoData.filter(rec => rec.saleOrderId == props.saleOrderId)).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
                    </Col>
                    <Col style={{ marginLeft: '2%' }}>
                        <Card title={'OPEN :' + (bomSoData.filter(rec => rec.saleOrderId == props.saleOrderId)).filter(el => el.status == "OPEN").length} style={{ textAlign: 'left', width: 130, height: 41, backgroundColor: '#f6e552' }}></Card>
                    </Col>
                    <Col style={{ marginLeft: '2%' }}>
                        <Card title={'PURCHASE ORDER :' + (bomSoData.filter(rec => rec.saleOrderId == props.saleOrderId)).filter(el => el.status == "PO").length} style={{ textAlign: 'left', width: 220, height: 41, backgroundColor: '#f2a637' }}></Card>
                    </Col>
                    <Col style={{ marginLeft: '2%' }}>
                        <Card title={'GRN :' + (bomSoData.filter(rec => rec.saleOrderId == props.saleOrderId)).filter(el => el.status == "GRN").length} style={{ textAlign: 'left', width: 130, height: 41, backgroundColor: '#e4f467' }}></Card>
                    </Col>
                    <Col style={{ marginLeft: '2%' }}>
                        <Card title={'CLOSED :' + (bomSoData.filter(rec => rec.saleOrderId == props.saleOrderId)).filter(el => el.status == "CLOSED").length} style={{ textAlign: 'left', width: 140, height: 41, backgroundColor: '#52c41a' }}></Card>
                    </Col>
                </Row>
            </Card>
            {bomSoData ?
                <Table
                    key={Date.now()}
                    className="components-table-nested"
                    columns={BomColumns}
                    dataSource={bomSoData}
                    scroll={{ x: true }}
                    size="small"
                />
                : ""
            }
        </>
    );
}
export default BomItemsGrid;