import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { ProductDto } from '@gtpl/shared-models/masters';
import { ProductService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";

import './products-form.css';


const { TextArea } = Input;

/* eslint-disable-next-line */
export interface ProductsFormProps {
  productData: ProductDto;
  updatePoduct: (product: ProductDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ProductsForm(props: ProductsFormProps) {
  const [form] = Form.useForm();
  const [disable,setDisable]=useState<boolean>(false)
  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const productDataService = new ProductService()

  const createProduct = (productData: ProductDto) => {
    setDisable(true)
    productDataService.createProduct(productData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Product Created Successfully');
        history.push("/Products-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: ProductDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updatePoduct(values);
    } else {
      setDisable(false)
      console.log('hello');

      createProduct(values);
    }

  };

  return (


    <Card title={<span style={{ color: 'white' }}>Products</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/Products-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} initialValues={props.productData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="productId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="productCode"
              label="Product Code"
              rules={[
                {
                  required: true,
                  message: 'Product Code Required',
                },
                {
                  pattern: /^[^-\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                      message: `Invalid Product Code`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="productName"
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: 'Product Name Required',

                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="hsnCode"
              label="HSN Code"
              rules={[
                {
                  required: true,
                  message: 'HSN Code Required',

                },
                {
                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                  message: `Should contain only numbers.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="remarks" label="Remarks"
            rules={[

              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }
            ]}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
          </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ProductsForm;
