
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Col } from 'antd';
import React, { useState, useEffect} from 'react';

export interface CurrentDayStockTransferProps { }

export function CurrentDayStockTransfer(props: CurrentDayStockTransferProps) {
    const [stockTransferData, setStockTransferData] = useState<number>(0);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getTodayStockTransfers();
    }, [])

    const getTodayStockTransfers = () => {
        service.getTodayStockTransfers({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                console.log(res.data);
                setStockTransferData(res.data)
               // AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
               // AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    return (
        <div>
            <Col  xs={24} sm={24} md={6} xl={6}>
            <Card  style={{ padding: '26px', textAlign: 'left', width: 232, height: 63, backgroundColor: '#2e445a', marginBottom: '10px', color: 'white',borderRadius:'10px',marginLeft:'-77px' }}><b style={{marginLeft:'-18px'}}>Today Stock Transfers : {" " + stockTransferData[0]?.jobCartonsCnt ? stockTransferData[0]?.jobCartonsCnt : "0"}</b></Card>
            </Col>
        </div>
    )
}
export default CurrentDayStockTransfer;