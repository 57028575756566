import React, { useEffect, useRef, useState } from 'react';
import './documents-approval-form.css';
import { Button, Card,Drawer,Form,Row,Col,Alert,Select, Anchor,Spin, Radio,Tabs, Popconfirm, Switch, Divider, Input} from 'antd';
import Table, { ColumnProps, ColumnsType } from 'antd/lib/table';
import { DocumentationData } from '../data';
import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import { DocumentsEnum, DocumentsUploadData } from '@gtpl/shared-models/logistics';
import { AlertMessages} from '../../../../../../shared-utils/alert-messages/src';
import {RightSquareOutlined,SearchOutlined  } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface DocumentsApprovalFormProps {
  formData:[];
  updateStatus:(approvalData: any,val:string) => void;
  submitStatus:(submitStatus:any)=>void;

}

export function DocumentsApprovalForm(
  props: DocumentsApprovalFormProps
) {
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [documentationData, setDocumentationData] = useState<DocumentsUploadData[]>([])
  const [defaultTabPane, setDefaultTabPane] = useState<string>("1")
  const saleOrderService = new SaleOrderService;
  const [form] = Form.useForm();

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select(),100);   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
        // searchText
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  

  // /**
  //  * 
  //  * @param pagination 
  //  * @param filters 
  //  * @param sorter 
  //  * @param extra 
  //  */
  // const onChange=(pagination, filters, sorter, extra)=> {
  //   console.log('params', pagination, filters, sorter, extra);
  // }

  useEffect(() => {
    getAllSaleOrdersDocumentsData();
  //   if (Number(localStorage.getItem('unit_id')) != 5) {
  //     form.setFieldsValue({ unitcodeId: Number(localStorage.getItem('unit_id')) })
  // }
  }, [])

  const getAllSaleOrdersDocumentsData= async () => {
    // const plantId = Number(localStorage.getItem('unit_id'));
    // console.log(plantId)
    saleOrderService.getAllSoDocumentsInfo({unitcodeId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) 
      
      {
        console.log(res.data);
        setDocumentationData(res.data);
      } else {
        if (res.intlCode) {
          setDocumentationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDocumentationData([]);
    })
  }
  const submitApproval= (record:DocumentsUploadData,key:string) => {
    console.log(record);
    console.log(key);
    if(key == "approverOneStatusUploaded"){
      record.approverOneStatusUploaded=record.approverOneStatusUploaded?false:true;
    }
    else if(key == "approverTwoStatusUploaded"){
      record.approverTwoStatusUploaded=record.approverTwoStatusUploaded?false:true;

    }
    saleOrderService.approveDocument(record).then(res => {
      console.log(res.internalMessage);
      if (res.status) 
      {
        console.log(res.data);
        // setDocumentationData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllSaleOrdersDocumentsData();
      } else {
        if (res.intlCode) {
          setDocumentationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
            getAllSaleOrdersDocumentsData();
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDocumentationData([]);
    })
  }
  const updateDocumentStatus= (documentsApprovalId:number,saleOrderId:number,documentName:DocumentsEnum, status:boolean,key) => {
    console.log(documentsApprovalId);
    console.log(saleOrderId);
    console.log(documentName);
    console.log(status);
    saleOrderService.updateDocumentInfo({documentsApprovalId,saleOrderId,documentName,status}).then(res => {
      if (res.status) 
      {
        console.log(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
        // setDocumentationData(res.data);
        getAllSaleOrdersDocumentsData();
      } else {
        if (res.intlCode) {
          setDocumentationData([]);

            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDocumentationData([]);
    })
    if(key==="approverOneStatusUploaded"){
      setDefaultTabPane("2");
    }else if(key==="approverTwoStatusUploaded"){
      setDefaultTabPane("3");
    }
  }
  /**
   * Open & Completed columns
   */
  const StatusColumns:ColumnProps<any>[] = [
    {
      title:'SNo',
      dataIndex:'saleId',
      width: '180',
      fixed: 'left',
      align:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
      
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrderNumber',
      key: 'saleOrderNumber',
      // align:'left',
      width: 150,
      sorter: (a, b) => a.saleOrderId- b.saleOrderId,
      sortDirections: ['descend', 'ascend'], 
      ...getColumnSearchProps('saleOrderNumber'),
    },
    
    
    {
      title: 'BL Draft',
      key: 'bLDraftUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.bLDraftUploaded===true?'Yes':'No'}</>)

    },  
   
    {
      title: 'SIMP',
      key: 'sIMPUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.sIMPUploaded===true?'Yes':'No'}</>)
    }, 
    {
      title: 'Certificate Of Origin',
      key: 'certificateOfOriginUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.certificateOfOriginUploaded===true?'Yes':'No'}</>)
    },    
    {
      title: 'Declaration Of Shrimp',
      key: 'declarationOfShrimpUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.declarationOfShrimpUploaded===true?'Yes':'No'}</>)
    },   
    {
      title: 'OBL',
      key: 'oblUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.oblUploaded===true?'Yes':'No'}</>)

    },  
    {
      title: 'ISF Worksheet',
      key: 'iSFWorksheetUploaded',
      align:'center',
      width:'150',
      render: (text, rowData) => (<>{rowData.iSFWorksheetUploaded===true?'Yes':'No'}</>)
    },  
    
   
    
    {
      title: 'Approval One?',
      key: 'Id',
      align:'center',
      width:'150',
      fixed:'right',
      render: (text, rowData) => (<>{rowData.approverOneStatusUploaded===true?'Yes':'No'}</>)
    },
    {
      title: 'Approval Two?',
      key: 'Id',
      align:'center',
      width:'150',
      fixed:'right',
      render: (text, rowData) => (<>{rowData.approverTwoStatusUploaded===true?'Yes':'No'}</>)
    },
    
  ];
  const columns = (key:string) => {
    console.log(key);

    /**
     * Approval One & Two Columns
     */
  const ApprovalColumns:ColumnProps<any>[] = [
    {
      title:'SNo',
      dataIndex:'saleId',
      width: '150',
      fixed: 'left',
      align:'center',
      render: (text, object, index) => (page-1) * 10 +(index+1)
      
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrderNumber',
      key: 'saleOrderNumber',
      // align:'left',
      width: 150,
      sorter: (a, b) => a.saleOrderId- b.saleOrderId,
      sortDirections: ['descend', 'ascend'], 
      ...getColumnSearchProps('saleOrderNumber'),

    },
    
   
    {
      title: 'BL Draft',
      key: 'bLDraftUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.BL_DRAFT,e.target.value,key)}
          value={rowData.bLDraftUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
    {
      title: 'SIMP',
      key: 'sIMPUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.SIMP,e.target.value,key)}
          value={rowData.sIMPUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
    {
      title: 'Certificate Of Origin',
      key: 'certificateOfOriginUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.CERTIFICATE_OF_ORIGIN,e.target.value,key)}
          value={rowData.certificateOfOriginUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
    {
      title: 'Declaration Of Shrimp',
      key: 'declarationOfShrimpUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.DECLARATION_OF_SHRIMP,e.target.value,key)}
          value={rowData.declarationOfShrimpUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
     
    {
      title: 'OBL',
      key: 'oblUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.OBL,e.target.value,key)}
          value={rowData.oblUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
    {
      title: 'ISF Worksheet',
      key: 'iSFWorksheetUploaded',
      align:'center',
      width:'150',
      render: (text, rowData,index) => ( 
        <Radio.Group
          options={options}
          onChange={(e)=>updateDocumentStatus(rowData.documentsApprovalId,rowData.saleOrderId,DocumentsEnum.ISF_Worksheet,e.target.value,key)}
          value={rowData.iSFWorksheetUploaded}
          optionType="button"
          buttonStyle="solid"
          style={{ display: 'contents'}}
          size='middle'   />
      ),
    },  
    
  ]
  const ApproverOne:ColumnProps<any>[] = [
    {
      title:`Approve`,
      dataIndex: 'Approve',
      fixed:'right',
      render: (text, rowData) => (
        <span>         
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{submitApproval(rowData,key);}}
            title={
              rowData.approverOneStatusUploaded
                ? 'Are you sure to DisApprove One Documents ?'
                :  'Are you sure to Approve One Documents ?'
            }
          >
            <Switch  size="default"
                className={ rowData.approverOneStatusUploaded ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.approverOneStatusUploaded}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  const ApproverTwo:ColumnProps<any>[] = [
    {
      title:`Approve`,
      dataIndex: 'Approve',
      fixed:'right',
      render: (text, rowData) => (
        <span>         
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{submitApproval(rowData,key);}}
            title={
              rowData.approverTwoStatusUploaded
                ? 'Are you sure to DisApprove Two Documents ?'
                :  'Are you sure to Approve Two Documents ?'
            }
          >
            <Switch  size="default"
                className={ rowData.approverTwoStatusUploaded ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.approverTwoStatusUploaded}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  if(key === "approverOneStatusUploaded"){
    console.log('approverOneStatusUploaded')

    return [...ApprovalColumns,...ApproverOne];
  }
  else if(key === "approverTwoStatusUploaded"){
    console.log('approverTwoStatusUploaded')
    return [...ApprovalColumns,...ApproverTwo];
  }
  else{
    return ApprovalColumns;
  }
}

  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    // setDocumentationData(DocumentationData);
    console.log(documentationData);
  }, [])
  function callback(key) {
    console.log(key);
  }
  
  return (
    <>
    <Card title={<span style={{color:'white'}}>Documentation Approval</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
     <br></br>
      <div className="card-container">
      <Tabs type={'card'} tabPosition={'top'} onChange={callback} defaultActiveKey={defaultTabPane}>
      {
      <TabPane key="1" tab={<span style={{ color: "#f5222d" }}>{'Open: ' + (documentationData.filter(rec=> rec.approverOneStatusUploaded== false || rec.approverTwoStatusUploaded== false).length)}</span>}>
        <Table
          key={defaultTabPane}
          rowKey={record => record.containerId}
          columns={StatusColumns}
          dataSource={documentationData.filter(rec=> rec.approverOneStatusUploaded== false || rec.approverTwoStatusUploaded== false)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          size="large"
          scroll={{ x:true }}
          bordered/>
      </TabPane>
      }
       {
      <TabPane key="2" tab={<span style={{ color: "#08979c" }}>{'Approval One Pendings: ' + (documentationData.filter(el => el.approverOneStatusUploaded== false).length)}</span>}>
        <Table
          key={defaultTabPane}
          rowKey={record => record.containerId}
          columns={documentationData.length==0?null:columns("approverOneStatusUploaded")}
          dataSource={documentationData.filter(rec=> rec.approverOneStatusUploaded== false)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          size="large"
          scroll={{ x:true}}
          bordered/>
      </TabPane>
      }
       {
      <TabPane key="3" tab={<span style={{ color: "#faad14" }}>{'Approval Two Pendings: ' + (documentationData.filter(el => el.approverTwoStatusUploaded== false && el.approverOneStatusUploaded == true).length)}</span>}>
      <Table
          key={defaultTabPane}
          rowKey={record => record.documentsApprovalId}
          columns={documentationData.length==0?null:columns("approverTwoStatusUploaded")}
          dataSource={documentationData.filter(rec=> rec.approverOneStatusUploaded== true && rec.approverTwoStatusUploaded == false)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          size="large"
          scroll={{ x:true }}
          bordered/>
      </TabPane>
      }
      {
      <TabPane key="4" tab={<span style={{ color: "#52c41a" }}>{'Completed: ' + (documentationData.filter(el => el.approverTwoStatusUploaded== true).length)}</span>}>
        <Table
          key={defaultTabPane}
          rowKey={record => record.containerId}
          columns={StatusColumns}
          dataSource={documentationData.filter(rec=> rec.approverOneStatusUploaded== true) && documentationData.filter(rec=> rec.approverTwoStatusUploaded== true)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          size="large"
          scroll={{ x:true }}
          bordered/>
      </TabPane>
      }
    </Tabs>
      </div>
    </Card>
  </>
  );
}

export default DocumentsApprovalForm;
