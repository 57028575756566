import { ContractorDto, ContractRequest } from "@gtpl/shared-models/hrms";
import { ContractorService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Input, Button, Tag, Divider, Popconfirm, Tooltip, Card, ConfigProvider, Drawer, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from "moment";
import Table, { ColumnProps } from "antd/lib/table";
import enUSIntl from 'antd/lib/locale/en_US';
import { Link } from "react-router-dom";
import ContractorForm from "../../../human-resource-agent-form/src/lib/pages-hrms-hrms-components-human-resource-agent-form";

export interface ContractorGridProps {}
export function ContractorGrid(props:ContractorGridProps){
    const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [contractorData, setContractorData] = useState<ContractorDto[]>([]);
  const [selectedContractorData, setSelectedContractorData] = useState<any>(undefined);
  const [allData, setAllData] = useState<ContractorDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const contractorService = new ContractorService();

  useEffect(() => {
    getAllContractorActions();
  }, []);

  const getAllContractorActions = () => {
    contractorService.getAllContractorActions().then(res => {
      if (res.status) {
        for (const contract of res.data) {
          contract.fromDate = moment(contract.fromDate).format('YYYY-MM-DD');
          contract.toDate = moment(contract.fromDate).format('YYYY-MM-DD');
        }
        setContractorData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setContractorData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setContractorData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
   * 
   * @param contractorData 
   */
    const deleteContractor = (contractorData: ContractRequest) => {
        contractorData.isActive = contractorData.isActive ? false : true;
        contractorService.activateOrDeactivateContractor(contractorData).then(res => {
          console.log(res);
          if (res.status) {
            getAllContractorActions();
            AlertMessages.getSuccessMessage('Success');
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => { 
          AlertMessages.getErrorMessage(err.message);
        })
      }

      const updateContractor = (Data: ContractorDto) => {
        contractorService.updateContractor(Data).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage('Updated Successfully');
            getAllContractorActions();
            setDrawerVisible(false);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }

      const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
          text ? (
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : text
            )
            : null
    
      });

      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };
    
      const closeDrawer = () => {
        setDrawerVisible(false);
      }

       /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  const openFormWithData = (viewData: ContractorDto) => {
    setDrawerVisible(true);
    console.log(viewData)
    viewData.fromDate=moment(viewData.fromDate).format('YYYY-MM-DD');
    viewData.toDate=moment(viewData.toDate).format('YYYY-MM-DD');
    setSelectedContractorData(viewData);
  }
  const columnsSkelton: ColumnProps<any>[] = [
{
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        {
          title: 'Contractor Name',
          dataIndex: 'contractorName',
          // responsive: ['lg'],
          sorter: (a, b) => a.contractorName.localeCompare(b.contractorName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('contractorName')
        },
        
        {
          title: 'Max ManPower',
          dataIndex: 'noOfEmployees',
          // responsive: ['lg'],
          sorter: (a, b) => a.noOfEmployees.localeCompare(b.contractorName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('contractorName')
        },
        {
          title: 'From Date',
          dataIndex: 'fromDate',
          sorter: (a, b) => a.fromDate.localeCompare(b.fromDate),
          sortDirections: ['descend', 'ascend'],
        
        },
        {
          title: 'To Date',
          dataIndex: 'toDate',
          sorter: (a, b) => a.toDate.localeCompare(b.toDate),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Contract Period',
          dataIndex: 'contractPeriod',
          responsive: ['lg'],
          sorter: (a, b) => a.contractPeriod?.localeCompare(b.contractPeriod),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('contractPeriod'),
         
        },
        // {
        //     title: 'Contract Project Id',
        //     dataIndex: 'contractorProId',
        //     responsive: ['lg'],
        //     sorter: (a, b) => a.contractorProId?.localeCompare(b.contractorProId),
        //     sortDirections: ['descend', 'ascend'],
        //     ...getColumnSearchProps('contractorProId'),
           
        //   },
        {
          title: 'Status',
          dataIndex: 'isActive',
          render: (isActive, rowData) => (
            <>
              {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
            </>
          ),
          filters: [
            {
              text: 'Active',
              value: true,
            },
            {
              text: 'InActive',
              value: false,
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => 
          {
            return record.isActive === value;
          },
          
        },
        {
            title:`Action`,
            dataIndex: 'action',
            align:'center',
            width: '190px', 
            render: (text, rowData) => (
              <span> 
                <Tooltip placement="top" title='Edit'>         
                  <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                    onClick={() => {
                      if (rowData.isActive) {
                        openFormWithData(rowData);
                      } else {
                        AlertMessages.getErrorMessage('You Cannot Edit Deactivated Exit Action');
                      }
                    }}
                    style={{ color: '#1890ff', fontSize: '14px' }}
                  />
                </Tooltip>
                <Divider type="vertical" />
                  <Popconfirm onConfirm={e =>{deleteContractor(rowData);}}
                  title={
                    rowData.isActive
                      ? 'Are you sure to Deactivate exit-action ?'
                      :  'Are you sure to Activate exit-action ?'
                  }
                >
                  <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
                  
                </Popconfirm>
              </span>
            )
          }
       
      ];
      return (
        
        <>

          <Card title={<span style={{ color: 'white' }}>Human Resource Agent</span>}

          // <Card title={<span style={{ color: 'white' }}>Contractor Details</span>}

            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/contract-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
            <br></br>
            <Table
          rowKey={record => record.Contractorid}
          columns={columnsSkelton}
          dataSource={contractorData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
            <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                <ContractorForm
                  contractorData={selectedContractorData}
                  updateContractor={updateContractor}
                  isUpdate={true}
                  closeForm={closeDrawer}/>
                  
              </Card>
            </Drawer>
          </Card>
        </>
      );
}
