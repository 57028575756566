import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Form,
  Input,
  Button,
  Col,
  Row,
  Select,
  DatePicker,
  Descriptions,
  Modal,
  Alert,
  Divider,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AssetService } from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AssetsLocationDto } from 'libs/shared-models/asset-management/src/lib/asset-location/asset-location.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemsDto } from '@gtpl/shared-models/masters';
import { ItemsService } from 'libs/shared-services/masters/src/lib/items-service';
import TextArea from 'antd/lib/input/TextArea';
import { DepartmentService, ReasonsService, UnitcodeService, VendorsService } from '@gtpl/shared-services/masters';
import { VendorsDto } from '@gtpl/shared-models/masters';
import { ItemSubCategoryService } from 'libs/shared-services/masters/src/lib/item-sub-category-service';
import { ItemBySubcategory } from "libs/shared-models/masters/src/lib/items/item-by-subcategory";
import moment from "moment";
import { ServiceStatusEnum } from "@gtpl/shared-models/common-models";
import FormItem from "antd/lib/form/FormItem";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import FileUploadForm from "./fileupload";
import { AssetAssignmentService } from 'libs/shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment";
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { Option } = Select;
const Rangepicker = DatePicker;

/* eslint-disable-next-line */
export interface AssetFormProps {
  assetData: AssetDto;
  updateAsset: (assetData: AssetDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export enum ServiceFrequency {
  Weekly = 'Weekly',
  FortWeekly = 'Fortnightly',
  Monthly = 'Monthly',
  QuarterYearly = 'Quarterly',
  HalfYearly = 'Half yearly',
  Yearly = 'Yearly',
}
export enum RentedFrequency {
  Days = 'Days',
  Month = 'Months',
  Year = 'Years',
}
export function AssetForm(props: AssetFormProps) {
  const [form] = Form.useForm();
  const service = new AssetService();
  const [disable, setDisable] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [insuranceData, setInsuranceData] = useState<boolean>(false);
  const [AmcData, setAmcData] = useState<boolean>(false);
  const [Data, setData] = useState<boolean>(false);
  const [fos, setFos] = useState<boolean>(false);
  const [depart, setDepart] = useState<any[]>([]);
  const departmentService = new DepartmentService();
  const [vendorsData, setVendorsData] = useState<VendorsDto[]>([]);
  const [amcnoData, setAmcNoData] = useState<boolean>(true);
  const [manufacturerData, setManufacturerData] = useState<boolean>(undefined);
  const [dealerData, setDealerData] = useState<boolean>(false);
  const [location, setLocation] = useState<AssetsLocationDto[]>([]);
  const [item, setItem] = useState<ItemsDto[]>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [plantName, setPlantName] = useState<any>([]);
  const [rented, setRented] = useState<boolean>(false);
  const [owned, setOwned] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [frequencyOfRented, setFrequencyOfRented] = useState<RentedFrequency>(RentedFrequency.Days);
  const [selectedItemName, setSelectedItemName] = useState<string>()
  const [frequencyOfService, setFrequencyOfService] = useState<ServiceFrequency | any>(ServiceFrequency.Weekly)
  const [update, setUpdate] = useState<boolean>(false);
  const [softasset, setSoftasset] = useState<boolean>(false)
  const [asset, setAsset] = useState<boolean>(true)
  const [hostingInfo, setHostingInfo] = useState<boolean>(true)
  const [isdisabled, setIsdisabled] = useState<boolean>(false)
  const role = JSON.parse(localStorage.getItem('role'))
  const amcReasonService = new ReasonsService();
  const [amcReasons, setAmcReasons] = useState<any>([]);
  const [server, setServer] = useState<boolean>(false);
  const [physical, setPhysical] = useState<boolean>(false);
  const [vm, setVm] = useState<boolean>(false);
  const [networkAsset, setNetworkAsset] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState<boolean>(false)


  const weekdaysArr = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  let history = useHistory();
  const employeeService = new EmployeeService();
  const locationService = new AssetLocationService();
  const itemService = new ItemsService();
  const vendorsService = new VendorsService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const plantsService = new UnitcodeService();
  const assignmentService = new AssetAssignmentService()
  const { MonthPicker } = DatePicker;

  useEffect(() => {
    if (props?.isUpdate) {
      setUpdate(true)
      setIsdisabled(true)
    }
    if (props?.assetData) {
      // console.log(props?.assetData)
      if (props.assetData.amc == 'YES') {
        setAmcData(true);
        setAmcNoData(false);
        if (props.assetData.amcProvider == 'supplier') {
          setDealerData(true);
        } else {
          setManufacturerData(true);
        }
      }
      setFrequencyOfService(props.assetData.frequencyOfService)
      if (props.assetData.serviceType == 'planned') {
        setFos(true);
      } else {
        setFos(false);
      }
      if (props.assetData.warrenty == 'yes') {
        setData(true)
      } else {
        setData(false)
      }
      if (props.assetData.insurance == 'yes') {
        setInsuranceData(true)
      } else {
        setInsuranceData(false)
      }
      // if(props.assetData.amc == 'no') {
      //   setAmcNoData(true);
      // }
      getItems(props?.assetData?.assetCategory);
    }
    if (form.getFieldValue('amc') == 'YES') {
      if (form.getFieldValue('amcProvider') == 'supplier') {
        console.log(form.getFieldValue('amcProvider'), '------------------------------------')
        setDealerData(true)
      }
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plant: Number(localStorage.getItem('unit_id')) })
    }
    getLocations(Number(localStorage.getItem('unit_id')));
    employeeDetails();
    getAllVendors();
    getItemCategory();
    getAllPlants();
    getDepartments();
    getAmcReasons();
  }, []);

  console.log(update, '-------updae')

  const getAmcReasons = () => {
    amcReasonService.getReasonsDropDownInfo().then(res => {
      if (res.status) {
        setAmcReasons(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAllPlants = () => {
    plantsService.getAllPlants().then((res) => {
      if (res.status) {
        console.log(res, '---------response');
        setPlantName(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const getAllVendors = () => {
    vendorsService
      .getAll()
      .then((res) => {
        if (res.status) {
          setVendorsData(res.data);
        } else {
          if (res.intlCode) {
            setVendorsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setVendorsData([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const getItems = (id) => {
    if (id == 128) {
      setSoftasset(true)
      setAsset(false)
      setServer(false)
      setPhysical(false)
      setVm(false)

    } else if (id == 116) {
      setSoftasset(false)
      setServer(true)
      setAsset(false)
    } else if (id == 111) {
      setSoftasset(false)
      setServer(false)
      setAsset(false)
      setPhysical(false)
      setVm(false)
      setNetworkAsset(true)
    }
    else {
      setSoftasset(false)
      setServer(false)
      setAsset(true)
    }
    const req = new ItemBySubcategory(id);
    itemService.getAllGeneralItems(req).then((res) => {
      if (res.status) {
        setItem(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then((res) => {
      if (res.status) {
        console.log(res.data, '------itemdata')
        setItemCategory(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const getLocations = (value) => {
    const req = new LocationByPlant(value);
    locationService.getLocationByPlant(req).then((res) => {
      if (res.status) {
        setLocation(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      // console.log(props?.assetData)
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const insuranceDisplay = (value) => {
    if (value == 'yes') {
      setInsuranceData(true);
    } else {
      setInsuranceData(false);
    }
  };

  const servicesData = (value) => {
    if (value == 'planned') {
      setFos(true);
    } else {
      setFos(false);
    }
  };

  const TypeDisplay = (value) => {
    if (value == 'YES') {
      setAmcData(true);
    } else {
      setAmcData(false);
    }
    if (value == 'NO') {
      setAmcNoData(true);
    } else {
      setAmcNoData(false);
    }
  };
  const Display = (value) => {
    if (value == 'yes') {
      setData(true);
    } else {
      setData(false);
    }
  };
  const StatusData = (value) => {
    if (value == 'yes') {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const ontouch = (value) => {
    if (value == 'RentedAsset') {
      setRented(true);
    } else {
      setRented(false);
    }
    if (value == 'OwnedAsset') {
      setOwned(true);
    } else {
      setOwned(false);
    }
  };
  const onManufacturer = (value) => {
    if (value == 'Manufacturer') {
      setManufacturerData(true);
    } else {
      setManufacturerData(false);
    }
    if (value == 'supplier') {
      setDealerData(true);
    } else {
      setDealerData(false);
    }
  };

  const onServerHostingType = (val) => {
    if (val === 'Physical') {
      setPhysical(true)
      setAsset(true)
      setVm(false)
    } else if (val === 'VM') {
      setVm(true)
      setAsset(false)
      setPhysical(false)
    }
  }

  const onDevice = (value) => {
    if (value) {
      setDeviceType(true)
    } else {
      setDeviceType(false)
    }
  }

  const createAsset = (assetData: AssetDto) => {
    setDisable(true);
    assetData.isActive = true;
    assetData.transactionDate = moment(Date.now()).format('YYYY-MM-DD');
    assetData.month = assetData.month ? String(moment(assetData.month).month() + 1) : '0'
    assetData.itemName = selectedItemName
    service.createAssets(assetData).then(res => {
      setDisable(false)
      if (res.status) {
        item.forEach(e => {
          if (res.data.assetsName == e.itemId && e.assetType == 'immovable') {
            const request = new AssetAssignmentDto(null, null, null, 913, null, null, true, null, null, res.data.assetsCode)
            assignmentService.createCheckIn(request).then(res => {
              if (res.status) {
                console.log(res.data)
              }
            })
          }

        });

        AlertMessages.getSuccessMessage('Created Sucessfully')
        history.push('./assets-grid')
        onReset();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    }).catch(error => {
      setDisable(false)
      AlertMessages.getErrorMessage(error.message)
    })
  }
  // const onStatusSearch = (value) => {
  //   const req = new AssetByStatus(value);
  //   service.getByStatus(req).then(res => {
  //     if(res.status) {
  //       setAssetsData(res.data)
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }

  // const onFinish = (values) => {
  //   service.createAssets(values).then(res=>{
  //     if(res.status){
  //       AlertMessages.getSuccessMessage('Created Sucessfully')
  //     }else{
  //       AlertMessages.getErrorMessage(res.internalMessage)

  //     }
  //   }).catch(error=>{
  //     AlertMessages.getErrorMessage(error.message)
  //   })
  // }
  const onFinish = (values: AssetDto) => {
    setDisable(false);
    if (props.isUpdate) {
      console.log(props.isUpdate);
      props.updateAsset(values);
    } else {
      setDisable(false);
      createAsset(values);
    }
  };
  const onReset = () => {
    form.resetFields();
  };


  const onPlantChange = (value) => {
    getLocations(value);
  };

  function renderDates(value: RentedFrequency) {
    let days: number[]
    let name: string;
    let label: string
    switch (value) {
      case RentedFrequency.Days:
        days = Array.from(Array(moment().daysInMonth()), (_, index) => index + 1)
        name = 'assetRentPeriod'
        label = 'Days'
        break;
      case RentedFrequency.Month:
        days = Array.from(Array(12), (_, index) => index + 1)
        name = 'assetRentPeriod'
        label = 'Months'
        break;
      case RentedFrequency.Year:
        days = Array.from(Array(9), (_, index) => index + 1)
        name = "assetRentPeriod"
        label = 'Years'
    }
    console.log(days)
    return <Form.Item name={name} label={label}>
      <Select>
        {days?.map((i) => <Option value={i}>{i}</Option>)}
      </Select>
    </Form.Item>
  }

  const getItemName = (value) => {
    console.log(value, '-------onchangeitemname')
  }

  const onHostInfo = (value) => {
    if (value == 'YES') {
      setHostingInfo(true)
    } else if (value == 'NO') {
      setHostingInfo(false)
    }
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}> Asset Details</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={
        props.isUpdate == true ? (
          ''
        ) : (
          <Link to="/assets-grid">
            <span style={{ color: 'white' }}>
              <Button>View </Button>{' '}
            </span>
          </Link>
        )
      }
    >
      <Form
        form={form}
        initialValues={props.assetData}
        name="control-hooks"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item name="assetId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="noOfUnits"
          style={{ display: 'none' }}
          initialValue={1}
        >
          <Input hidden defaultValue={1} />
        </Form.Item>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 5, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          xl={{ span: 5, offset: 1 }}
          style={{ margin: '1%' }}
        >
          <Form.Item
            name="assetMode"
            label="Asset Mode"
            initialValue={'OwnedAsset'}
            rules={[
              {
                required: true,
                message: 'Asset Mode is required',
              },
            ]}
          >
            <Select onChange={ontouch} defaultValue={'OwnedAsset'} allowClear disabled={isdisabled}>
              <Option key={1} value={'OwnedAsset'}>
                Owned Asset
              </Option>
              <Option key={2} value={'RentedAsset'}>
                Rented Asset
              </Option>
            </Select>
          </Form.Item>
        </Col>
        {rented ? (
          <>
            <Row gutter={24}>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetCategory"
                  label="Asset Category"
                  rules={[
                    {
                      required: true,
                      message: 'Asset Category is required',
                    },
                  ]}
                >
                  <Select
                    placeholder="asset category"
                    showSearch
                    onChange={getItems}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().startsWith(input.toLowerCase())
                    }
                    allowClear
                    disabled={isdisabled}
                  >
                    {itemCategory.map((items) => {
                      return (
                        <Option
                          key={items.itemSubCategoryId}
                          value={items.itemSubCategoryId}
                        >
                          {items.itemSubCategory}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {
                update ? (<>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="assetType"
                      label="Asset Type"
                    >
                      <Select
                        placeholder="Asset type"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                        allowClear
                      >
                        {/* <Option key='movable' value='movable'>Movable</Option>
                    <Option key='immovable' value='immovable'>Immovable</Option> */}
                        {Object.values(AssetTypeEnum).map((i) => {
                          return (
                            <Option key={i} value={i}>
                              {i}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </>) : (<></>)
              }
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetsName"
                  label="Asset Name"
                  rules={[
                    {
                      required: true,
                      message: 'Asset Name is required',
                    },
                  ]}
                >
                  <Select
                    placeholder="asset name"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().startsWith(input.toLowerCase())
                    }
                    allowClear
                    disabled={isdisabled}
                  >
                    {item.map((items) => {
                      return (
                        <Option key={items.itemId} value={items.itemId}>
                          {items.itemName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetSerialNo"
                  label="Asset Serial No."
                  rules={[
                    {
                      required: true,
                      message: 'Asset Serial No is required',
                    },
                  ]}
                >
                  <Input placeholder="Serial no" disabled={isdisabled} />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetCost"
                  label="Asset Cost"
                  rules={[
                    {
                      required: true,
                      message: 'Asset Cost is required',
                    },
                    {
                      pattern: /^[0-9.]*$/,
                      message: `Letters , Spaces and Special charecters are not allowed`,
                    },
                  ]}
                >
                  <Input placeholder="Enter Asset Cost" max={15} disabled={isdisabled} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetSupplier"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: 'Asset Supplier is required',
                    },
                  ]}
                >
                  <Select
                    placeholder="Asset supplier"
                    showSearch
                    optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    filterOption={(input, option) =>
                      option.children.toLowerCase().startsWith(input.toLowerCase())
                    }
                    allowClear
                    disabled={isdisabled}
                  >
                    {vendorsData.map((vendors) => {
                      return (
                        <Option
                          key={vendors.vendorId}
                          value={vendors.vendorId}
                        >
                          {vendors.vendorName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="dateOfPurchased"
                  label=" Rent Commencement Date"
                  initialValue={moment(moment().format('YYYY-MM-DD HH:mm:ss'))}
                  rules={[
                    {
                      required: true,
                      message: 'Date of Purchase is required',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={isdisabled}
                    disabledDate={(current) => {
                      return moment().add(-1, 'days') >= current
                    }}
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD"
                    defaultValue={moment(
                      moment().format('YYYY-MM-DD HH:mm:ss')
                    )}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="plant"
                  label="Plant"
                  rules={[
                    {
                      required: true,
                      message: 'Plant is required',
                    },
                  ]}
                >
                  <Select
                    onChange={onPlantChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().startsWith(input.toLowerCase())
                    }
                    showSearch
                    allowClear
                    disabled={isdisabled}
                  >
                    {plantName.map((plant) => {
                      return (
                        <Option key={plant.plantId} value={plant.plantId}>
                          {plant.plantName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="assetRentTenureType"
                  label="Asset Rent Tenure"
                  initialValue={RentedFrequency.Days}
                  rules={[
                    {
                      required: true,
                      message: 'Asset Rent Tenure is required',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(value: any) => {
                      setFrequencyOfRented(value);
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().startsWith(input.toLowerCase())
                    }
                  >
                    {Object.values(RentedFrequency).map((i) => {
                      return (
                        <Option key={i} value={i}>
                          {i}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                {renderDates(frequencyOfRented)}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="rentalCostType"
                  label="Rent Cost Period"
                >
                  <Select placeholder='Select Rent Period' allowClear>
                    <Option key={'1'} value={'perDay'}>Per Day</Option>
                    <Option key={'2'} value={'perMonth'}>Per Month</Option>
                    <Option key={'3'} value={'totalRent'}>Total Rent</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="rentalCost"
                  label="Rental Cost"
                  rules={[
                    {
                      required: true,
                      message: 'Rental Cost is required',
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Letters , Spaces and Special charecters are not allowed`,
                    },
                  ]}
                >
                  <Input placeholder="Enter Retal Cost" max={15} />
                </Form.Item>
              </Col>

            </Row>
          </>
        ) : (
          <></>)}
        {owned ? (<>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
              <Form.Item
                name="assetCategory"
                label="Asset Category"
                rules={[
                  {
                    required: true,
                    message: 'Asset Category is required',
                  },
                ]}
              >
                <Select
                  placeholder="Asset category"
                  showSearch
                  onChange={getItems}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().startsWith(input.toLowerCase())
                  }
                  allowClear
                  disabled={isdisabled}
                >
                  {itemCategory.map((items) => {
                    return (
                      <Option
                        key={items.itemSubCategoryId}
                        value={items.itemSubCategoryId}
                      >
                        {items.itemSubCategory}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {
            server ? (<>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="hostType"
                    label="Hosting Type"
                    rules={[
                      {
                        required: true,
                        message: 'Hosting Type is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Hosting Type"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                      onChange={onServerHostingType}
                    // disabled={isdisabled}
                    >
                      <Option key='physical' value='Physical'>Physical</Option>
                      <Option key='vm' value='VM'>VM</Option>
                    </Select>
                  </Form.Item>

                </Col>
              </Row>
            </>) : (<></>)
          }
          {
            vm ? (<>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='assetsName' label='Server Name'
                    rules={[
                      {
                        required: true,
                        message: 'Server Name is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Server Name"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                      onChange={getItemName}
                      disabled={isdisabled}
                    >
                      {item.map((items) => {
                        return (
                          <Option key={items.itemId} value={items.itemId}>
                            {items.itemName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='state' label='State'
                    rules={[
                      {
                        required: true,
                        message: 'State is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                    >
                      <Option key='on' value='poweredOn'>Powered ON</Option>
                      <Option key='off' value='poweredOff'>Powered OFF</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='ownerOfAsset' label='Managed By'
                    rules={[
                      {
                        required: true,
                        message: 'Managed By is required',
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      showSearch
                      allowClear

                    >
                      {employeeData.map((employee) => {
                        return (
                          <Option
                            key={employee.employeeId}
                            value={employee.employeeId}
                          >
                            {employee.employeeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='host' label='Host'
                    rules={[
                      {
                        required: true,
                        message: 'Host is required',
                      },
                    ]}
                  >
                    <Input placeholder='Host' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='provisionedSpace' label='Provisioned Space'
                    rules={[
                      {
                        required: true,
                        message: 'Provisioned Space is required',
                      },
                    ]}
                  >
                    <Input placeholder='Provisioned Space' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='usedSpace' label='Used Space'
                    rules={[
                      {
                        required: true,
                        message: 'Used Space is required',
                      },
                    ]}
                  >
                    <Input placeholder='Used Space' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='version' label='Guest OS'
                    rules={[
                      {
                        required: true,
                        message: 'Guest OS is required',
                      },
                    ]}
                  >
                    <Input placeholder='Guest OS' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='compatibility' label='Compatibility'
                    rules={[
                      {
                        required: true,
                        message: 'Compatibility is required',
                      },
                    ]}
                  >
                    <Input placeholder='Compatibility' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='memorySize' label='Memory Size'
                    rules={[
                      {
                        required: true,
                        message: 'Memory Size is required',
                      },
                    ]}
                  >
                    <Input placeholder='MemorySize' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='cpu' label={`CPU'S`}
                    rules={[
                      {
                        required: true,
                        message: 'CPU is required',
                      },
                    ]}
                  >
                    <Input placeholder='CPU' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='nic' label={`NIC'S`}
                    rules={[
                      {
                        required: true,
                        message: 'NIC is required',
                      },
                    ]}
                  >
                    <Input placeholder='NIC' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='hostAddress' label='IP Address'
                    rules={[
                      {
                        required: true,
                        message: 'IP Address is required',
                      },
                    ]}
                  >
                    <Input placeholder='IP Address' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='vmWareVersionToolStatus' label='VM Ware Version Tool Status'
                    rules={[
                      {
                        required: true,
                        message: 'VM Ware Version Tool Status is required',
                      },
                    ]}
                  >
                    <Input placeholder='VM Ware Version Tool Status' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='domainUrl' label='DNS Name'
                    rules={[
                      {
                        required: true,
                        message: 'DNS Name is required',
                      },
                    ]}
                  >
                    <Input placeholder='DNS Name' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='alarmActions' label='Alarm Actions'
                    rules={[
                      {
                        required: true,
                        message: 'Alarm Actions is required',
                      },
                    ]}
                  >
                    <Input placeholder='Alarm Actions' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='assetSupplier' label='Supplier'
                    rules={[
                      {
                        required: true,
                        message: 'Supplier is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="asset supplier"
                      showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                      disabled={isdisabled}
                    >
                      {vendorsData.map((vendors) => {
                        return (
                          <Option
                            key={vendors.vendorName}
                            value={vendors.vendorName}
                          >
                            {vendors.vendorName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='dateOfPurchased' label='Created Date'
                    initialValue={moment(moment().format('YYYY-MM-DD HH:mm:ss'))}
                    rules={[
                      {
                        required: true,
                        message: 'Created Date is required',
                      },
                    ]}

                  >
                    <DatePicker
                  disabled={isdisabled}
                  disabledDate={(current) => {
                  return moment().add(-1, 'days')  >= current }}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  defaultValue={moment(
                    moment().format('YYYY-MM-DD HH:mm:ss')
                  )}
                />
                  </Form.Item>
                </Col> */}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='expiredDate' label='OS Expiry Date'
                    rules={[
                      {
                        required: true,
                        message: 'OS Expiry Date is required',
                      },
                    ]}
                  >
                    <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                      disabledDate={(current) => {
                        return (
                          current && current <= moment(form.getFieldValue('dateOfPurchased'))
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='userName' label='User Name'
                    rules={[
                      {
                        required: true,
                        message: 'User Name is required',
                      },
                    ]}
                  >
                    <Input placeholder='User Name' />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name='password' label='Password'
                    rules={[
                      {
                        required: true,
                        message: 'Password is required',
                      },
                    ]}
                  >
                    <Input placeholder='Password' />
                  </Form.Item>
                </Col>

              </Row>
            </>) : (<></>)
          }
          {
            asset ? (<>
              <Row>
                {
                  update ? (<>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      xl={{ span: 5, offset: 1 }}
                      style={{ margin: '1%' }}
                    >
                      <Form.Item
                        name="assetType"
                        label="Asset Type"
                      >
                        <Select
                          placeholder="asset type"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().startsWith(input.toLowerCase())
                          }
                          allowClear
                        >
                          {/* <Option key='movable' value='movable'>Movable</Option>
                    <Option key='immovable' value='immovable'>Immovable</Option> */}
                          {Object.values(AssetTypeEnum).map((i) => {
                            return (
                              <Option key={i} value={i}>
                                {i}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>) : (<></>)
                }
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetsName"
                    label="Asset Name"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Name is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Asset name"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                      onChange={getItemName}
                      disabled={isdisabled}
                    >
                      {item.map((items) => {
                        return (
                          <Option key={items.itemId} value={items.itemId}>
                            {items.itemName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetSerialNo"
                    label="Asset Serial No"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Serial No is required',
                      },
                    ]}
                  >
                    <Input placeholder="Serial no" disabled={isdisabled} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="invoice" label="Invoice No."
                    rules={[{
                      max: 15,
                      message: 'Invoice No does not exceed 15 characters'
                    }]}
                  >
                    <Input placeholder="Enter Invoice No" max={15} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="model" label="Model" rules={[{}]}>
                    <Input placeholder="Enter Model" max={15} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetCost"
                    label="Asset Cost"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Cost is required',
                      },
                      {
                        pattern: /^[0-9.]*$/,
                        message: `Letters , Spaces and Special charecters are not allowed`,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Asset Cost" max={15} disabled={isdisabled} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="dateOfPurchased"
                    label="Date of Purchase"
                    initialValue={moment(moment().format('YYYY-MM-DD HH:mm:ss'))}
                    rules={[
                      {
                        required: true,
                        message: 'Date of Purchase is required',
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={isdisabled}
                      // disabledDate={(current) => {
                      //   return moment().add(-1, 'days') >= current
                      // }}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      defaultValue={moment(
                        moment().format('YYYY-MM-DD HH:mm:ss')
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetSupplier"
                    label="Asset Supplier"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Supplier is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="asset supplier"
                      showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                      disabled={isdisabled}
                    >
                      {vendorsData.map((vendors) => {
                        return (
                          <Option
                            key={vendors.vendorName}
                            value={vendors.vendorName}
                          >
                            {vendors.vendorName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="expiredDate" label="Expiry Date">
                    <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                      disabledDate={(current) => {
                        return (
                          current && current <= moment(form.getFieldValue('dateOfPurchased'))
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="ownerOfAsset"
                    label="Asset Owned By"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Owned By is required',
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      showSearch
                      allowClear

                    >
                      {employeeData.map((employee) => {
                        return (
                          <Option
                            key={employee.employeeId}
                            value={employee.employeeId}
                          >
                            {employee.employeeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetcondition"
                    label="Asset Condition"
                    initialValue={'Good'}
                    rules={[
                      {
                        required: true,
                        message: 'Asset Condition is required',
                      },
                    ]}
                  >
                    {
                      <Select showSearch allowClear>
                        <Option key={1} value={'Good'}>
                          Good
                        </Option>
                        <Option key={2} value={'PartiallyDamaged'}>
                          Partially Damaged
                        </Option>
                        <Option key={2} value={'Damaged'}>
                          Damaged
                        </Option>
                      </Select>
                    }
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="plant"
                    label="Plant"
                    rules={[
                      {
                        required: true,
                        message: 'Plant is required',
                      },
                    ]}
                    initialValue={role == 'SUPERADMIN' ? '' : Number(localStorage.getItem('unit_id'))}
                  >
                    <Select
                      onChange={onPlantChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      showSearch
                      allowClear
                      // disabled={isdisabled}
                      disabled={Number(localStorage.getItem('unit_id')) != 5 || props.isUpdate ? true : false}
                      defaultValue={role == 'SUPERADMIN' ? '' : Number(localStorage.getItem('unit_id'))}
                    >
                      {plantName.map((plant) => {
                        return (
                          <Option key={plant.plantId} value={plant.plantId}>
                            {plant.plantName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="currentLocation" label="Current Location"
                    rules={[
                      {
                        required: true,
                        message: 'location is required'
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      disabled={isdisabled}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                    >
                      {location.map((locations) => {
                        return (
                          <Option
                            key={locations.locationId}
                            value={locations.locationId}
                          >
                            {locations.locationName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item label='Department' name='department'
                  //  rules={[
                  //   {
                  //     required:true,
                  //     message:'location is required'
                  //   }
                  // ]}
                  >
                    <Select
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      defaultValue={'All'}
                      showSearch
                      allowClear>
                      <Option key={'all'} value={''}>All</Option>
                      {depart.map((e) => {
                        return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {
                  physical ? (<>
                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='state' label='State'
                          rules={[
                            {
                              required: true,
                              message: 'State is required',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                          >
                            <Option key='on' value='poweredOn'>Powered ON</Option>
                            <Option key='off' value='poweredOff'>Powered OFF</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='host' label='Host'
                          rules={[
                            {
                              required: true,
                              message: 'Host is required',
                            },
                          ]}
                        >
                          <Input placeholder='Host' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='provisionedSpace' label='Provisioned Space'
                          rules={[
                            {
                              required: true,
                              message: 'Provisioned Space is required',
                            },
                          ]}
                        >
                          <Input placeholder='Provisioned Space' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='usedSpace' label='Used Space'
                          rules={[
                            {
                              required: true,
                              message: 'Used Space is required',
                            },
                          ]}
                        >
                          <Input placeholder='Used Space' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='version' label='Guest OS'
                          rules={[
                            {
                              required: true,
                              message: 'Guest OS is required',
                            },
                          ]}
                        >
                          <Input placeholder='Guest OS' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='compatibility' label='Compatibility'
                          rules={[
                            {
                              required: true,
                              message: 'Compatibility is required',
                            },
                          ]}
                        >
                          <Input placeholder='Compatibility' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='memorySize' label='Memory Size'
                          rules={[
                            {
                              required: true,
                              message: 'Memory Size is required',
                            },
                          ]}
                        >
                          <Input placeholder='MemorySize' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='cpu' label={`CPU'S`}
                          rules={[
                            {
                              required: true,
                              message: 'CPU is required',
                            },
                          ]}
                        >
                          <Input placeholder='CPU' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='nic' label={`NIC'S`}
                          rules={[
                            {
                              required: true,
                              message: 'NIC is required',
                            },
                          ]}
                        >
                          <Input placeholder='NIC' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='hostAddress' label='IP Address'
                          rules={[
                            {
                              required: true,
                              message: 'IP Address is required',
                            },
                          ]}
                        >
                          <Input placeholder='IP Address' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='vmWareVersionToolStatus' label='VM Ware Version Tool Status'
                          rules={[
                            {
                              required: true,
                              message: 'VM Ware Version Tool Status is required',
                            },
                          ]}
                        >
                          <Input placeholder='VM Ware Version Tool Status' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='domainUrl' label='DNS Name'
                          rules={[
                            {
                              required: true,
                              message: 'DNS Name is required',
                            },
                          ]}
                        >
                          <Input placeholder='DNS Name' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='alarmActions' label='Alarm Actions'
                          rules={[
                            {
                              required: true,
                              message: 'Alarm Actions is required',
                            },
                          ]}
                        >
                          <Input placeholder='Alarm Actions' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='userName' label='User Name'
                          rules={[
                            {
                              required: true,
                              message: 'User Name is required',
                            },
                          ]}
                        >
                          <Input placeholder='User Name' />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item name='password' label='Password'
                          rules={[
                            {
                              required: true,
                              message: 'Password is required',
                            },
                          ]}
                        >
                          <Input placeholder='Password' />
                        </Form.Item>
                      </Col>

                    </Row>
                  </>) : (<></>)
                }
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="remarks" label="Remarks ">
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </Row>

              {/* <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>AMC Details</h1><br /> */}

              {/* <Divider />
        <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Warrenty Details</h1><br /> */}
              <Card
                title={<span style={{ color: 'white' }}> Warranty Details</span>}
                style={{ textAlign: 'center' }}
                headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
              >
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="warrenty"
                      label="Warranty Available"
                      initialValue={'no'}
                      rules={[
                        {
                          required: true,
                          message: 'Warranty Available is required',
                        },
                      ]}
                    >
                      {
                        <Select defaultValue={'no'} onChange={Display} allowClear>
                          <Option key={1} value={'yes'}>
                            Yes
                          </Option>
                          <Option key={2} value={'no'}>
                            No
                          </Option>
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                  {Data ? (
                    <>
                      {/* <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      xl={{ span: 5, offset: 1 }}
                      style={{ margin: '1%' }}
                    >
                      <Form.Item
                        name="warrentyExpiredDate"
                        label="Warrenty Expired Date"
                        rules={[{
                          required: true,
                           message: "Input is required",
                        }]}
                      >    <DatePicker
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD" />warrentyExpiredDate
              
                      </Form.Item>
                    </Col> */}
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="warrentyExpiredDate"
                          label="Warranty Valid upto "
                          rules={[
                            {
                              required: true,
                              message: 'Warranty expired date is required',
                            },
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                          //enable only current data
                          // disabledDate={(current) => {     
                          //   return (
                          //     current && current <= moment(form.getFieldValue('dateOfPurchased')) || current >= moment(form.getFieldValue('expiredDate'))
                          //   );
                          // }}

                          //Disable Past Dates
                          // disabledDate={(current) => {
                          //   return moment().add(-1, 'days') >= current
                          // }}

                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card>
              {/* <Divider/>
            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Insurance Details</h1><br /> */}
              <Card
                title={<span style={{ color: 'white' }}> Insurance Details</span>}
                style={{ textAlign: 'center' }}
                headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
              >
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="insurance"
                      label="Insurance Available"
                      initialValue={'no'}
                      rules={[
                        {
                          required: true,
                          message: 'Insurance Available is required',
                        },
                      ]}
                    >
                      {
                        <Select onChange={insuranceDisplay} allowClear>
                          <Option key={1} value={'yes'}>
                            Yes
                          </Option>
                          <Option key={2} value={'no'}>
                            No
                          </Option>
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                  {insuranceData ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="insuranceDate"
                          label="Insurance Valid upto "
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                            disabledDate={(current) => {
                              return (
                                current && current <= moment(form.getFieldValue('dateOfPurchased')) || current >= moment(form.getFieldValue('expiredDate'))
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="insuranceRefNum"
                          label="Insurance Reference No "
                          rules={[
                            {
                              required: true,
                              message: 'Insurance Reference No is required',
                            },
                          ]}
                        >
                          <Input placeholder="Insurance reference no" />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="insuranceProvidedBy"
                          label="Insurance Company"
                          rules={[
                            {
                              required: true,
                              message: 'Insurance Company is required',
                            },
                            {
                              pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                              message: `Should contain only alphabets.`,
                            },
                          ]}
                        >
                          <Input placeholder="Insurance company" />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card>
              <Card
                title={<span style={{ color: 'white' }}> AMC Details</span>}
                style={{ textAlign: 'center' }}
                headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
              >
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="amc"
                      label="AMC Availability"
                      initialValue={'NO'}
                      rules={[
                        {
                          required: true,
                          message: 'AMC Availability is required',
                        },
                      ]}
                    >
                      {
                        <Select onChange={TypeDisplay} defaultValue={'NO'} allowClear>
                          <Option key={1} value={'YES'}>
                            YES
                          </Option>
                          <Option key={2} value={'NO'}>
                            NO
                          </Option>
                          <Option key={3} value={'N/A'}>
                            N/A
                          </Option>
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                  {AmcData ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="amcProvider"
                          label="AMC Provided By"
                          initialValue={'supplier'}
                          rules={[
                            {
                              required: true,
                              message: 'AMC Provided By is required',
                            },
                            {
                              pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                              message: `Should contain only alphabets.`,
                            }
                          ]}
                        >
                          {
                            <Select
                              showSearch
                              onChange={onManufacturer}
                              defaultValue={'supplier'}
                              allowClear
                            >
                              <Option key={1} value={'Manufacturer'}>
                                Manufacturer
                              </Option>
                              <Option key={2} value={'supplier'}>
                                Supplier
                              </Option>
                            </Select>
                          }
                        </Form.Item>
                      </Col>
                      {manufacturerData ? (
                        <>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 5, offset: 1 }}
                            lg={{ span: 5, offset: 1 }}
                            xl={{ span: 5, offset: 1 }}
                            style={{ margin: '1%' }}
                          >
                            <Form.Item
                              name="amcname"
                              label="Manufacturer Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Manufacturer Name is required',
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`,
                                }
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <>
                          {dealerData ? (
                            <>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 5, offset: 1 }}
                                lg={{ span: 5, offset: 1 }}
                                xl={{ span: 5, offset: 1 }}
                                style={{ margin: '1%' }}
                              >
                                <Form.Item
                                  name="amcname"
                                  label="Vendor Name"
                                  initialValue={form.getFieldValue('assetSupplier')}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Vendor Name is required',
                                    },
                                    {
                                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                      message: `Should contain only alphabets.`,
                                    }
                                  ]}
                                >
                                  <Input
                                    defaultValue={form.getFieldValue('assetSupplier')}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {amcnoData ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="amcReason"
                          label="Reason"
                          rules={[
                            {
                              required: true,
                              message: 'Reason is required',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            disabled={isdisabled}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                          >
                            {amcReasons.map((reason) => {
                              return (
                                <Option
                                  key={reason.reasonId}
                                  value={reason.reasonName}
                                >
                                  {reason.reasonName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card>
              <Card
                title={<span style={{ color: 'white' }}> Service Details</span>}
                style={{ textAlign: 'center' }}
                headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
              >
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="servicestaus"
                      label="Is Service Required "
                      initialValue={'yes'}
                      rules={[
                        {
                          message: 'Is Service Required is required',
                          required: true,
                        },
                      ]}
                    >
                      {
                        <Select onChange={StatusData} defaultValue={'yes'} allowClear>
                          <Option key={1} value={'yes'}>
                            Yes
                          </Option>
                          <Option key={2} value={'no'}>
                            No
                          </Option>
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                  {status ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="serviceType"
                          label="Service Type"
                          rules={[
                            {
                              required: true,
                              message: 'Service Type is required',
                            },
                          ]}
                        >
                          <Select
                            onChange={servicesData}
                            allowClear
                            placeholder="Select Service Type"
                          >
                            <Option key={1} value={'planned'}>
                              {' '}
                              Planned
                            </Option>
                            <Option key={2} value={'unPlanned'}>
                              UnPlanned
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      {fos ? (
                        <>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 6 }}
                            lg={{ span: 6 }}
                            xl={{ span: 4 }}
                            style={{ margin: '1%' }}
                          >
                            <Form.Item
                              name="frequencyOfService"
                              label="Frequency of Service"
                              initialValue={ServiceFrequency.Weekly}
                              rules={[
                                {
                                  required: true,
                                  message: 'Input is required',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Enter or Service Interval"
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                onChange={(value: any) => {
                                  setFrequencyOfService(value);
                                }}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().startsWith(input.toLowerCase())
                                }
                              >
                                {Object.values(ServiceFrequency).map((i) => {
                                  return (
                                    <Option key={i} value={i}>
                                      {i}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          {(frequencyOfService == ServiceFrequency.Weekly ||
                            frequencyOfService == ServiceFrequency.FortWeekly) && (
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 6 }}
                                lg={{ span: 6 }}
                                xl={{ span: 4 }}
                                style={{ margin: '1%' }}
                              >
                                <Form.Item
                                  name="day"
                                  label="Day"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Input is required',
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Day"
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().startsWith(input.toLowerCase())
                                    }
                                  >
                                    {weekdaysArr.map((e, index) => {
                                      return (
                                        <Option key={e} value={index}>
                                          {e}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                          {frequencyOfService != ServiceFrequency.Weekly &&
                            frequencyOfService != ServiceFrequency.FortWeekly &&
                            frequencyOfService != ServiceFrequency.Monthly && (
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 6 }}
                                lg={{ span: 6 }}
                                xl={{ span: 4 }}
                                style={{ margin: '1%' }}
                              >
                                <Form.Item
                                  name="month"
                                  label="Month"
                                  initialValue={moment()}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Input is required',
                                    },
                                  ]}
                                >
                                  <MonthPicker
                                    format="MM-YYYY"
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          {frequencyOfService != ServiceFrequency.Weekly &&
                            frequencyOfService != ServiceFrequency.FortWeekly && (
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 6 }}
                                lg={{ span: 6 }}
                                xl={{ span: 4 }}
                                style={{ margin: '1%' }}
                              >
                                <Form.Item
                                  name="day"
                                  label="Day"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Input is required',
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Day"
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().startsWith(input.toLowerCase())
                                    }
                                  >
                                    {[...Array(moment().daysInMonth()).keys()].map(
                                      (i) => {
                                        return (
                                          <Option key={i + 1} value={i + 1}>
                                            {i + 1}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                </Row>
              </Card>
            </>) : (<></>)
          }
          {
            softasset ? (<>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetsName"
                    label="Software Name"
                    rules={[
                      {
                        required: true,
                        message: 'Software Name is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="software name"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                    >
                      {item.map((items) => {
                        return (
                          <Option key={items.itemId} value={items.itemId}>
                            {items.itemName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetSerialNo"
                    label="Product Key"
                    rules={[
                      {
                        required: true,
                        message: 'Product key is required',
                      },
                    ]}
                  >
                    <Input placeholder="Product Key" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="softAssetManufacturer" label="Manufacturer"
                  // rules={[{
                  //   max:15,
                  //   message:'Invoice No does not exceed 15 characters'
                  // }]}
                  >
                    <Input placeholder="Manufacturer" max={15} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="model" label="Version" rules={[{}]}>
                    <Input placeholder="Version" max={15} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="ownerOfAsset"
                    label="Licensed to"
                    rules={[
                      {
                        required: true,
                        message: 'Licensed to is required',
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      showSearch
                      allowClear
                    >
                      {employeeData.map((employee) => {
                        return (
                          <Option
                            key={employee.employeeId}
                            value={employee.employeeId}
                          >
                            {employee.employeeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetSupplier"
                    label="Supplier"
                    rules={[
                      {
                        required: true,
                        message: 'Asset Supplier is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Asset supplier"
                      showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().startsWith(input.toLowerCase())
                      }
                      allowClear
                    >
                      {vendorsData.map((vendors) => {
                        return (
                          <Option
                            key={vendors.vendorId}
                            value={vendors.vendorName}
                          >
                            {vendors.vendorName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="invoice" label="Order No"
                    rules={[{
                      max: 20,
                      message: 'Order No does not exceed 20 numbers'
                    },
                      // {
                      //   pattern: /^[0-9]*$/,
                      //   message: `Letters , Spaces and Special charecters are not aloowed`,
                      // },
                    ]}
                  >
                    <Input placeholder="Enter Order No" max={15} />
                  </Form.Item>
                </Col>


                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="assetCost"
                    label="Purchase Cost"
                    rules={[
                      {
                        required: true,
                        message: 'Purchase Cost is required',
                      },
                      // {
                      //   pattern: /^[0-9]*$/,
                      //   message: `Letters , Spaces and Special charecters are not allowed`,
                      // },
                    ]}
                  >
                    <Input placeholder="Enter Purchase Cost" max={15} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item
                    name="dateOfPurchased"
                    label=" Purchase Date"
                    initialValue={moment(moment().format('YYYY-MM-DD HH:mm:ss'))}
                    rules={[
                      {
                        required: true,
                        message: 'Date of Purchase is required',
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={isdisabled}
                      disabledDate={(current) => {
                        return moment().add(-1, 'days') >= current
                      }}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      defaultValue={moment(
                        moment().format('YYYY-MM-DD HH:mm:ss')
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="expiredDate" label="Expiry Date">
                    <DatePicker
                      disabledDate={(current) => {
                        return (
                          current && current <= moment(form.getFieldValue('dateOfPurchased'))
                        );
                      }}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="licenseNumber" label="License Number"
                    rules={[
                      {
                        required: true,
                        message: 'License Number is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  style={{ margin: '1%' }}
                >
                  <Form.Item name="hostingInfo" label="Hosting Information"
                    initialValue={'Yes'}
                    rules={[
                      {
                        required: true,
                        message: 'Hosting Information is required',
                      },
                    ]}
                  >
                    <Select defaultValue={'Yes'} onChange={onHostInfo}>
                      <Option key='Yes' value='Yes'>Yes</Option>
                      <Option key='No' value='No'>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {hostingInfo ? (<>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="hostingType" label="Hosting Type"
                      rules={[
                        {
                          required: true,
                          message: 'Hosting Type is required',
                        },
                      ]}
                    >
                      <Select>
                        <Option key='cloud' value='Cloud'>Cloud</Option>
                        <Option key='internal' value='Internal'>Internal</Option>
                        <Option key='stack' value='Stack'>Stack</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="hostAddress" label="Host Address"
                      rules={[
                        {
                          required: true,
                          message: 'Host Address is required',
                          min: 5,
                          max: 15,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="location" label="Location"
                      rules={[
                        {
                          required: true,
                          message: 'Location is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="domainUrl" label="Domain Url"
                      rules={[
                        {
                          required: true,
                          message: 'Domain Url is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="userName" label="User Name"
                      rules={[
                        {
                          required: true,
                          message: 'User Name is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item name="password" label="Password"
                      rules={[
                        {
                          required: true,
                          message: 'Password is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </>) : (<></>)}
              </Row>
            </>) : (<></>)
          }


        </>) : (<></>)}
        {networkAsset ? (<>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
              <Form.Item
                name="hostType"
                label="Device Type"
                rules={[
                  {
                    required: true,
                    message: 'Device Type is required',
                  },
                ]}
              >
                <Select
                  placeholder="Device Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().startsWith(input.toLowerCase())
                  }
                  allowClear
                  onChange={onDevice}
                // disabled={isdisabled}
                >
                  <Option key='Switch' value='Switch'>Switch</Option>
                  <Option key='Meraki ' value='Meraki '>Meraki </Option>
                  <Option key='Aeronet ' value='Aeronet '>Aeronet </Option>
                  <Option key='Firewall ' value='Firewall '>Firewall </Option>
                  <Option key='WLC ' value='WLC '>WLC </Option>
                  <Option key='ISP ' value='ISP '>ISP </Option>
                  <Option key='Rourter ' value='Rourter '>Rourter </Option>
                </Select>
              </Form.Item>

            </Col>
          </Row></>) : (<></>)}
        {deviceType ? (<><Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item name='hostAddress' label='IP Address'
              rules={[
                {
                  required: true,
                  message: 'IP Address is required',
                },
              ]}
            >
              <Input placeholder='IP Address' />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item name='host' label='MAC Address'
              rules={[
                {
                  required: true,
                  message: 'MAC is required',
                },
              ]}
            >
              <Input placeholder='MAC' />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item
              name="plant"
              label="Plant"
              rules={[
                {
                  required: true,
                  message: 'Plant is required',
                },
              ]}
            >
              <Select
                onChange={onPlantChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
                showSearch
                allowClear
                disabled={isdisabled}
              >
                {plantName.map((plant) => {
                  return (
                    <Option key={plant.plantId} value={plant.plantId}>
                      {plant.plantName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item label='Department' name='department'
            //  rules={[
            //   {
            //     required:true,
            //     message:'location is required'
            //   }
            // ]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item
              name="assetSerialNo"
              label="Asset Serial No"
              rules={[
                {
                  required: true,
                  message: 'Asset Serial No is required',
                },
              ]}
            >
              <Input placeholder="Serial no" disabled={isdisabled} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item name="model" label="Model" rules={[{}]}>
              <Input placeholder="Enter Model" max={15} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item
              name="dateOfPurchased"
              label="Date of Purchase"
              initialValue={moment(moment().format('YYYY-MM-DD HH:mm:ss'))}
              rules={[
                {
                  required: true,
                  message: 'Date of Purchase is required',
                },
              ]}
            >
              <DatePicker
                disabled={isdisabled}
                disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current
                }}
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                defaultValue={moment(
                  moment().format('YYYY-MM-DD HH:mm:ss')
                )}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item name="expiredDate" label="Expiry Date">
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                disabledDate={(current) => {
                  return (
                    current && current <= moment(form.getFieldValue('dateOfPurchased'))
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item name="noOfUnits" label="No Of Ports">
              <Input placeholder="Enter Ports" />
            </Form.Item>
          </Col>
        </Row>
          <Card
            title={<span style={{ color: 'white' }}> Warranty Details</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
          >
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="warrenty"
                  label="Warranty Available"
                  initialValue={'no'}
                  rules={[
                    {
                      required: true,
                      message: 'Warranty Available is required',
                    },
                  ]}
                >
                  {
                    <Select defaultValue={'no'} onChange={Display} allowClear>
                      <Option key={1} value={'yes'}>
                        Yes
                      </Option>
                      <Option key={2} value={'no'}>
                        No
                      </Option>
                    </Select>
                  }
                </Form.Item>
              </Col>
              {Data ? (
                <>
                  {/* <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      xl={{ span: 5, offset: 1 }}
                      style={{ margin: '1%' }}
                    >
                      <Form.Item
                        name="warrentyExpiredDate"
                        label="Warrenty Expired Date"
                        rules={[{
                          required: true,
                           message: "Input is required",
                        }]}
                      >    <DatePicker
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD" />warrentyExpiredDate
              
                      </Form.Item>
                    </Col> */}
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="warrentyExpiredDate"
                      label="Warranty Valid upto "
                      rules={[
                        {
                          required: true,
                          message: 'Warranty expired date is required',
                        },
                      ]}
                    >
                      <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                        disabledDate={(current) => {
                          return (
                            current && current <= moment(form.getFieldValue('dateOfPurchased')) || current >= moment(form.getFieldValue('expiredDate'))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Card>
          <Card
            title={<span style={{ color: 'white' }}> AMC Details</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
          >
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 5, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                style={{ margin: '1%' }}
              >
                <Form.Item
                  name="amc"
                  label="AMC Availability"
                  initialValue={'NO'}
                  rules={[
                    {
                      required: true,
                      message: 'AMC Availability is required',
                    },
                  ]}
                >
                  {
                    <Select onChange={TypeDisplay} defaultValue={'NO'} allowClear>
                      <Option key={1} value={'YES'}>
                        Yes
                      </Option>
                      <Option key={2} value={'NO'}>
                        No
                      </Option>
                      <Option key={3} value={'N/A'}>
                        N/A
                      </Option>
                    </Select>
                  }
                </Form.Item>
              </Col>
              {AmcData ? (
                <>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="amcProvider"
                      label="AMC Provided By"
                      initialValue={'supplier'}
                      rules={[
                        {
                          required: true,
                          message: 'AMC Provided By is required',
                        },
                        {
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                          message: `Should contain only alphabets.`,
                        }
                      ]}
                    >
                      {
                        <Select
                          showSearch
                          onChange={onManufacturer}
                          defaultValue={'supplier'}
                          allowClear
                        >
                          <Option key={1} value={'Manufacturer'}>
                            Manufacturer
                          </Option>
                          <Option key={2} value={'supplier'}>
                            Supplier
                          </Option>
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                  {manufacturerData ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        style={{ margin: '1%' }}
                      >
                        <Form.Item
                          name="amcname"
                          label="Manufacturer Name"
                          rules={[
                            {
                              required: true,
                              message: 'Manufacturer Name is required',
                            },
                            {
                              pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                              message: `Should contain only alphabets.`,
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <>
                      {dealerData ? (
                        <>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 5, offset: 1 }}
                            lg={{ span: 5, offset: 1 }}
                            xl={{ span: 5, offset: 1 }}
                            style={{ margin: '1%' }}
                          >
                            <Form.Item
                              name="amcname"
                              label="Vendor Name"
                              initialValue={form.getFieldValue('assetSupplier')}
                              rules={[
                                {
                                  required: true,
                                  message: 'Vendor Name is required',
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`,
                                }
                              ]}
                            >
                              <Input
                                defaultValue={form.getFieldValue('assetSupplier')}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {amcnoData ? (
                <>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    style={{ margin: '1%' }}
                  >
                    <Form.Item
                      name="amcReason"
                      label="Reason"
                      rules={[
                        {
                          required: true,
                          message: 'Reason is required',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        disabled={isdisabled}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                      >
                        {amcReasons.map((reason) => {
                          return (
                            <Option
                              key={reason.reasonId}
                              value={reason.reasonName}
                            >
                              {reason.reasonName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Card>
        </>) : (<></>)}

        <Card>
          <FileUploadForm
            maxCount={3}
            layoutType={'inline'}
            formRef={undefined}
            initialValues={[]}
          />
        </Card>
        <Row gutter={24}>
          <Col style={{ marginLeft: "80%" }}>
            <Button type="primary" disabled={disable} htmlType="submit">
              Submit
            </Button>
            {/* </Col>
            <Col style={{marginLeft:"85%"}}> */}
            {/* {(props.isUpdate == false) && */}
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
            {/* } */}
          </Col>

        </Row>
      </Form>

    </Card>

  );

}

export default AssetForm;
