import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { InterviewDto, JobDescriptionReq, NotificationDto } from "@gtpl/shared-models/hrms";
import { InterviewService } from '@gtpl/shared-services/hrms';
import { PlantsDropDown} from '@gtpl/shared-models/masters';
import { UnitcodeService} from '@gtpl/shared-services/masters';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import { ProfileRequest } from '@gtpl/shared-models/hrms';
import {ProfilesService} from '@gtpl/shared-services/hrms'
import { ProfilesDto } from '@gtpl/shared-models/hrms';
import './interview-form.css';
import { InterviewType, NotificationStatus, StEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { request } from 'http';


const { TextArea } = Input;
const { Option } = Select;

export interface InterviewFormProps {
  data: InterviewDto;
  updateInterview: (data: InterviewDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function InterviewForm(props: InterviewFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [interview, setInterview] = useState<InterviewDto[]>([]);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [profilesData, setprofilesData] = useState<ProfilesDto[]>([]);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [idData, setIdData] = useState<NotificationDto[]>([]);
  const [current, setCurrent] = useState<any>();
  const [willingData , setWillingData] = useState<boolean>(false)
  const unitsService = new UnitcodeService();
  const interviewService = new InterviewService();
  const empService = new HrmsEmpDetService();
  const service=new ProfilesService();
  const hrmsEmpDetService = new HrmsEmpDetService();

  
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(()=> {
    if(profilesData){
      console.log(profilesData)
    }
  },[profilesData])
  

  useEffect(() => {
     getAllActiveEmployees();
    getAllInterview();
    getAllPlants();
    getAllProfiles();
    getIDJob();
   

  }, [])
  const onReset = () => {
    form.resetFields();
  }
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }

  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getIDJob = () =>{
    interviewService.getIDJob().then((res) =>{
      if(res.status){
      setIdData(res.data);
    }else {
      if (res.intlCode) {
        setIdData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        setIdData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setIdData([]);
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setIdData([]);
  })
}
  const getAllPlants = () => {
    unitsService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const getAllProfiles = () => {
    service.getAllProfiles().then((res) => {
      if (res.status) {
        console.log(res.data)
        setprofilesData(res.data);
        // form.setFieldsValue({referredBy:res.data})
      } else {
        if (res.intlCode) {
          setprofilesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setprofilesData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setprofilesData([]);
    })
  }

  const willingDetails = () => {
    setCurrent(0)
  }

 
  const create = (interviewtData: InterviewDto) => {
    interviewService.createinterview(interviewtData)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Interview mode Created Successfully');
          history.push('/interview-view');
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  
  // const getSelectedProfileData = (req) => {
  //   const profileId = form.getFieldValue('profileId')
  //   const request = new ProfileRequest(req);
  //   service.getAllActiveProfiles(request).then(res => {
  //     if (res.status) {
       
  //       form.setFieldsValue({
        


  //       });
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const getSelectedEmployeeData = (req) => {
    const request = new HrmsEmployeeRequest(req);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form. setFieldsValue ({
         
          interviewerMobileNo: res.data?.mobileNumber,
          
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
 

  const getSelectedProfileData = (val,row) => {
    console.log(row)
    let profileName = form.getFieldValue("candidateName");
    const profile = profilesData.find(item => item.candidateName == profileName )
   let profileId = profile.profileId

    console.log(profileId)
    console.log(profilesData);
    const refById = profilesData.find(item => item.profileId == profileId).referredBy
console.log(refById)
    const data = employeeData.find(rec => rec.employeeId == Number(refById)).employeeName
    console.log(data)
    form.setFieldsValue({referredBy:data})
    // const request = new ProfileRequest(req);
    // service.getAllActiveProfiles(request).then(res => {
    //   if (res.status) {
    //     form.setFieldsValue({
    //       referredBy:res.data?. referredBy
      
    //     });
    //   } else {
    //     if (res.intlCode) {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     } else {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     }
    //   }
    // }).catch(err => {
    //   AlertMessages.getErrorMessage(err.message);
    // })
  }

  const saveData = (values: InterviewDto) => {
    console.log("test");
    if (props.isUpdate) {
      console.log(values);
      props.updateInterview(values);
    } else {
      console.log(values);
      create(values);
    }
  };
  const getAllInterview = () => {
    interviewService.getAllInterview().then(res => {
      if (res.status) {
        setInterview([]);
      }
      else {
        if (res.intlCode) {
          setInterview([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }

    }).catch(err => {
      setInterview([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  if (props?.data && props.data.interviewDate) {
    let interviewDate: any = moment(props.data.interviewDate);
    props.data.interviewDate = interviewDate;
  }

  const getSelectedNotificationID = (req) => {
    console.log(req,'req')
    console.log(idData)
   const notificationId= idData.find(i => i.jobDescription == req).notificationId
   form.setFieldsValue({notificationId : notificationId})
  }

  const willingDisplay = (value) => {
    if(value == StEnum.Selected){
        setWillingData(true)
    }else{
      setWillingData(false)
    }
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Interview</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/interview-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} 
            name="control-hooks"
             layout="vertical" 
             initialValues={props.data} onFinish={saveData}>

        <Form.Item name="interviewId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="interviewDate"
              label="Interview Date"
              initialValue={ moment(moment().format("YYYY-MM-DD")) }
              rules={[
                {
                  required: true,
                  message: "Select the date again"
                },
              ]}
            >
              < DatePicker
                           style={{ width: '100%' }} format="YYYY-MM-DD"
                          defaultValue={ moment(moment().format("YYYY-MM-DD")) }
                           showToday={true} 
                        />

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="jobDescription"
              label="Job Description"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Select
                placeholder="Select Job Description"
                onChange={getSelectedNotificationID}
              >
                {idData.map((id) => {
                  return <Option key={id.notificationId} value={id.jobDescription}>{id.jobDescription}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="notificationId"
              label="Notification Id"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
         
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="interviewType"
              label="Interview Type "
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Interview Type "
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {/* {
                  Object.entries(InterviewType).map((value) => {
                    return <Option value={value[1]} >{value[1]} </Option>
                  })
                } */}
                <Option key={1} value={InterviewType.HrInterview} >{InterviewType.HrInterview}</Option>
                <Option key={2} value={InterviewType.Masters} >{InterviewType.Masters}</Option>
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="interviewer"
              label="Interviewer"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
                <Select
                placeholder="Select Interviewer"
                onChange={getSelectedEmployeeData}
              >
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName +' '+employee.employeeLastName}</Option>
                })}
              </Select> 
           
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="interviewerMobileNo"
              label="Interviewer.Mob.No"
              rules={[
                { required: true, message: ' Valid Mobile No is required' , max:10},
                {
                  pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                  message: `Don't Allow letters and Spaces`
                }
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="plant"
              label="Plant"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
               <Select
                  showSearch
                  placeholder="Select Plant "
                  optionFilterProp="children"

                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* <Option value={undefined}>please select Plant</Option>; */}
                  {plantData.map(dropData => {
                    return <Option key={dropData?.plantId}value={dropData?.plantName}>{dropData?.plantName}</Option>;
                  })}
                </Select>
            </Form.Item>
          </Col>
         
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="candidateName"
              label="Candidate Name"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
               <Select
                placeholder="Select Candidate Name"
                onChange={getSelectedProfileData}
              >
                {profilesData.map((profiles) => {
                  return <Option key={profiles.profileId} value={profiles.candidateName}>{profiles.candidateName}</Option>
                })}
              </Select> 
         
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="refereedBy"
              label="Referred By"
              rules={[
                { required: true, message: ' Valid name is required' , max:15},
                {
                  pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                  message: `Should contain only alphabets and first letter should be capital.`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="status"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          message:"It should not be blank"
                        },
                        ]}
                        initialValue={StEnum.Open}
                      >
                        <Select
                      showSearch
                      placeholder="Select Status"
                      defaultValue={StEnum.Open}
                      allowClear
                      onChange={willingDisplay}
                     >
                      <Option key={StEnum.Open} value={StEnum.Open} >{StEnum.Open}</Option>
                      <Option key={StEnum.Selected} value={StEnum.Selected} >{StEnum.Selected}</Option>
                      <Option key={StEnum.Pending} value={StEnum.Pending} >{StEnum.Pending}</Option>
                      <Option key={StEnum.Rejected} value={StEnum.Rejected} >{StEnum.Rejected}</Option>  
        </Select>  
        </Form.Item>
        </Col> 

        {willingData ? <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="willing"
                  label="Willing"
                  rules={[
                    {
                      required: true,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Willing"
                  >
                    <Option key={1} value={'Yes'}>Yes</Option>
                    <Option key={2} value={'No'}>No</Option>
                  

                  </Select>}
                </Form.Item>
              </Col> : <></>}
         
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item  name="remarks" label="Remarks"
            rules={[
              {
                //required: true,
                message:"It should not cross the limit", max:100
              }, 
          ]}>
                <TextArea rows={2} />
              </Form.Item>
            </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default InterviewForm;


