import { ShiftDto } from "@gtpl/shared-models/hrms";
import { ShiftService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const format = 'HH:mm';
const { Option } = Select;
export interface ShiftFormProps {
  shiftData: ShiftDto;
  updateShift: (shift: ShiftDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ShiftForm(props: ShiftFormProps) {

  const [form] = Form.useForm();
  let history = useHistory();
  const shiftservice = new ShiftService;
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endTime, setEndTime] = useState<string>('00:00');


  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const createShift = (shift: ShiftDto) => {
    shiftservice.createShift(shift).then(res => {
      if (res.status) {
        history.push("/shift-view");
        onReset();
        AlertMessages.getSuccessMessage('Shift Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('')
    form.resetFields();
  }

  const saveData = (values: ShiftDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateShift(values);
    } else {
      console.log(values);
      createShift(values);
    }
  }

  const onStartTimeChange = (time, timeString: string) => {
    // console.log(time.hour() + ':' + time.minutes(), timeString);
    setStartTime(timeString);
  };

  const onEndTimeChange = (time, timeString: string) => {
    setEndTime(timeString);
  };

  return (
    <Card title={<span style={{ color: 'white' }}>Shift</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/shift-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form form={form} name="control-hooks" layout="vertical" onFinish={saveData} initialValues={props.shiftData} >

        <Form.Item name="id" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="shift"
              label="Shift"
              rules={[
                {
                  required: true,
                  message: "Shift is required", max: 16
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="inTime"
              label="In Time"
            >
              <TimePicker
                key={startTime}
                defaultValue={moment(props.shiftData ? props.shiftData.inTime : startTime, format)}
                format={format}
                onChange={onStartTimeChange}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="outTime"
              label="Out Time"
            >
              <TimePicker key={endTime} defaultValue={moment(props.shiftData ? props.shiftData.outTime : endTime, format)} format={format} onChange={onEndTimeChange} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="dayChange"
              label="Day Change"
              rules={[
                {
                  required: false,
                  message: "This field is mandatory"
                },
              ]}>
              {<Select
                showSearch
                placeholder="Select Day Change"
              >
                <Option key={1} value={'1'}>Yes</Option>
                <Option key={2} value={'0'}>No </Option>
              </Select>}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
export default ShiftForm;