
import { DeptDateHourRequest, TemperaturesModel } from "@gtpl/shared-models/warehouse-management";
import { TemperatureLogService } from "@gtpl/shared-services/warehouse-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Thermometer from "react-thermometer-component";
import './current-temp.css';


export interface CurrentTempProps {} 

export function CurrentTemp(props: CurrentTempProps) {
    const currentTempService = new TemperatureLogService()
    const [tempData, setTempData] = useState<TemperaturesModel[]>([]);
    
    useEffect(() => {
        getCurrentTemperature(moment().format("YYYY-MM-DD"), Number(moment().format('hh')));
        callEveryHour();

    }, []);
    if(tempData[0]?.temperature > -18){
        document.documentElement.style.setProperty('--thermo-color','#ff0303');
    }

    const styles = {
        dial: {
            display: "inline-block",
            width: `150px`,
            height: `auto`,
            background:'white',
            border: "0.5px solid #fff",
            padding: "2px"
        },
        title: {
            fontSize: "1em",
            color: "#000",
            marginTop: "15px"
        }
    };

    function callEveryHour() {
        setInterval(getCurrentTemperature, 1000 * 60 * 60);
    }

    const getCurrentTemperature = (date?: string, hour?: number) => {
        const req = new DeptDateHourRequest();
        req.date = date;
        req.hour = hour;
        currentTempService.getCurrentTemperature(req).then((res) => {
            if (res.status) {
                setTempData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    };

    return (
        <div style={styles.dial}>
            <Thermometer
                theme="light"
                value={tempData[0]?.temperature}
                max="100"
                steps="1"
                format="°C"
                size="normal"
                height="180"
            />
        </div>
    );
};

export default CurrentTemp;

