import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin, Input } from 'antd';
import documentsFontSizeStyle from './documents-prints-style.module.css'
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  UnitsOfWeightInput,
} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { GlobalStatus, UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import stampImage from "./stamp.png";
import signature from "./signature.png"


// import documentsStyle from './documents.module.css'
/* eslint-disable-next-line */
export interface PlantPackingListProps {
  saleOrderId: number;
}

export function PlantPackingList(props: PlantPackingListProps) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;
  const [remarksData, setRemarksData] = useState<string>(undefined);
  const { TextArea } = Input;
  const defaultDeclaration = 'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct'

  let totalCases = 0;
  let totalLbWeight = 0;
  let totalGrossWeight = 0;
  let totalAmount = 0;
  let lbPouchWeight;
  let kgPouchWeight;
  let totalAm = 0 ;


  const getData = (saleOrderId) => {
    const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(saleOrderId)
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };
  const handleRemarks = (value) => {
    console.log(value);
    console.log(value.target.defaultValue);
    setRemarksData(value);
  }
  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print()
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  let exporterId = 1;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };
  let hasNumber = /\d/;
  let normalOrder = hasNumber.test(plantDetails?.buyerPoNo);
  let lotRefString = '';
  let lotNo = '';
  let RefNo = '';
  if (normalOrder) {
    if (plantDetails?.lotCode) {
      lotNo = 'LOT NO. ' + plantDetails?.lotCode + ', '
    }
    if (plantDetails?.buyerPoNo) {
      RefNo = 'REF NO. ' + plantDetails?.buyerPoNo
    }
    lotRefString = lotNo + RefNo
  }
  // return 'test';

  return (
    <>
    <span style={{alignContent:'left', backgroundColor:'red'}}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="PlantPackingList"
              sheet="PlantPackingList"
              buttonText="Get Excel" />
          </span>
      {plantDetails ?

        <html>
          <head></head>
          <br />
          <body id="printme" className={"stylesInfo"}>

            <br></br>
            <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
              <tr><td className={'ta-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '16px', lineHeight: '12px', paddingTop: '20px' }}><h1>PACKING LIST</h1></td></tr>
              <tr>
                <td className={'ta-b'} colSpan={3} rowSpan={2}>
                  <b>Exporter:</b> <br />
                  <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                  {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                  <br />
                  {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                  <br />
                  {exporterData?.state + ', ' + exporterData?.country}
                  <br /><b>GSTIN:</b>
                  {exporterData?.GSTNumber}
                  <br />

                </td>
                <td className={'ta-b'} colSpan={3}>
                  <b>Invoice No. </b>
                  {plantDetails?.invoiceNumber}<br />
                  <b>Date:</b>
                  {moment(plantDetails?.invoiceDate).format('DD-MMM-YYYY')}
                </td>

                <td className={'ta-b'} colSpan={2}>
                  <b>Exporter's Ref<br />
                    IE Code </b> {exporterData?.ieCode}
                </td>
              </tr>
              <tr>
                <td colSpan={5} className={'ta-b'}>
                  <b>Buyer's Order No. </b>
                  PO NO. {plantDetails.custPoNo}
                  <h4><b>Date:</b>{plantDetails?.actualEdl ? (moment(plantDetails?.actualEdl).format('DD-MMM-YYYY')) : null}
                  </h4>
                </td>
              </tr>

              <tr>
                <td className={'ta-b'} colSpan={3} rowSpan={plantDetails?.notifyPartyThree ? 2 : 1} >
                  <b>PROCESSED & PACKED AT </b><br />
                  <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                  {plantDetails?.invoicedPlant?.villageName + ", " + plantDetails?.invoicedPlant?.mandalName}<br />
                  {plantDetails?.invoicedPlant?.postalCode + ", " + plantDetails?.invoicedPlant?.distirctName + ", " + plantDetails?.invoicedPlant?.stateName + ", " + plantDetails?.invoicedPlant?.countryName}<br />
                  <b>FDA REGISTRATION NUMBER :</b>{plantDetails?.invoicedPlant?.fdaRegNumber}
                  <br />
                  {plantDetails?.invoicedPlant?.feiNumber ?
                    <><b>FEI NUMBER:</b>{plantDetails?.invoicedPlant?.feiNumber}</> : <></>}
                </td>
                {
                  (plantDetails.notifyPartyOne) ?
                <td colSpan={plantDetails?.notifyPartyOne ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start' }}>
                  <u>
                    Notify party 1</u>
                  <br />
                  <pre>
                    <b>{plantDetails?.notifyPartyOne?.split("\n")[0]}</b><br />
                    {plantDetails?.notifyPartyOne?.split("\n")[1]}<br />
                    {plantDetails?.notifyPartyOne?.split("\n")[2]}<br />
                    {plantDetails?.notifyPartyOne?.split("\n")[3]}</pre><br />
                  {/* {(plantDetails?.notifyPartyOne) ? plantDetails?.notifyPartyOne : ''} */}

                </td> : <></>
                } 
                {
                  (plantDetails.notifyPartyTwo) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start', width: '50%' }}>
                      <u>
                        Notify party 2</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyTwo?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[3]}</pre><br /><br />

                    </td> : <></>
                }
              </tr>
              <tr >
                {
                  (plantDetails.notifyPartyThree) ?
                    <td colSpan={plantDetails.notifyPartyFour ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start' }}>
                      <u>
                        Notify party 3</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyThree?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyThree?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }
                {
                  (plantDetails.notifyPartyFour) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start' }}>
                      <u>
                        Notify party 4</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyFour?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyFour?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }

              </tr>


              <tr >
                <td colSpan={3} rowSpan={2} className={'ta-b'}>
                  <b>Consignee:</b><br />
                  {
                    plantDetails?.country === "CANADA" ?
                    <>
                    <b>AZ GEMS CANADA INC:</b>
                    </> : <b>AZ GEMS INC:</b>
                  }<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[3]}<br />
                  <b>FDA REGISTRATION NO. </b>{unitsData?.consigneefdaRegNumber}
                </td>

                <td colSpan={5} className={'ta-b'} >
                  <b>
                    Importer on record:
                  </b><br />
                  {
                    plantDetails?.country === "CANADA" ?
                    <>
                    <b>AZ GEMS CANADA INC:</b>
                    </> : <b>AZ GEMS INC:</b>
                  }<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
                  <br /><b>FDA REGISTRATION NO.</b>{unitsData?.consigneefdaRegNumber}
                </td>
              </tr>

              <tr>
                <td colSpan={3} className={'ta-b'}>
                  <b > Country of Origin of Goods:</b><br />
                  INDIA
                </td>
                <td colSpan={5} className={'ta-b'}>
                  <b> Country of Final Destination:</b><br />
                  {plantDetails?.country}{' '}

                </td>

              </tr>


              <tr>
                <td className={'ta-b'} colSpan={2}>
                  <b>Pre Carriage By:</b>
                  <h4>Road</h4>
                </td>
                <td className={'ta-b'} colSpan={1}>
                  <b>Place of Receipt of Pre-carrier:</b>
                  {(plantDetails?.placeOfReceipt) ? plantDetails?.placeOfReceipt : " "}
                </td>
                <td colSpan={5} className={'ta-b'}>
                  <b>Terms of Delivery and Payment:</b><br />

                  {plantDetails?.saleOrderItems[0]?.paymentTerms}

                </td>
              </tr>

              <tr>

                <td className={'ta-b'} colSpan={2}>
                  <b>Vessel / Flight No. </b>
                  {/* {(plantDetails?.vesselName)?.toUpperCase()} */}
                </td>
                <td className={'ta-b'} colSpan={1}>
                  <b> Port of Loading: </b><br />

                  {plantDetails?.portofloading ? (plantDetails?.portofloading)?.toUpperCase() + ', INDIA' : ''}

                </td>

                <td colSpan={5} rowSpan={2} className={'ta-b'}>
                  <b> Container No.&nbsp;</b>
                  {/* {plantDetails?.saleOrderItems[0]?.containerNo} */}
                  <br />
                  <b> Liner Seal No.&nbsp;</b>
                  {/* {plantDetails?.saleOrderItems[0]?.linearSelaNo} */}
                  <br />
                  <b> Bill of Lading No.&nbsp;</b>
                  {/* {plantDetails?.saleOrderItems[0]?.billOfLadingno}<br/><br/><br/> */}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={2}>
                  <b>Port of Discharge:</b><br />
                  {(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() + "," : '') + (plantDetails?.country ? plantDetails?.country : '')}
                </td>
                <td className={'ta-b'} colSpan={1}><b>
                  Final Destination:</b><br />
                  {(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() + "," : '') + (plantDetails?.country ? plantDetails?.country : '')}
                </td>
              </tr>

              <tr>
                <td colSpan={1} style={{ textAlign: 'center', fontSize: 10 }}>
                  Marks & Nos / No & Kind of Pkgs
                </td>
                <td colSpan={1} style={{ textAlign: 'center' }}>
                </td>
                <td style={{ textAlign: 'center' }} colSpan={2}>
                  Description of Goods
                </td>

                <td style={{ textAlign: 'center' }}>
                </td>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Quantity
                  <br />
                  IN KGS <br /> NET WEIGHT
                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>

                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>

                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                </th>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                  HSN Code
                </th>
                <th colSpan={2} style={{ textAlign: 'left' }}>
                  Size
                </th>
                <th style={{ textAlign: 'left' }}>
                  No. Of Cartons
                </th>
              </tr>

              {
                plantDetails?.saleOrderItems?.map(item => {
                  totalCases += item.invoicedCases;
                  const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                  let finalNetWeight;
                  let unitPrice;
                  if (plantDetails.isInvoiced == GlobalStatus.YES) {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      finalNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                      unitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                    } else {
                      finalNetWeight = Number(item.invoicedQty).toFixed(3);
                      unitPrice = Number(item.unitPrice).toFixed(5);
                    }
                  }
                  else {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      finalNetWeight = (Number(item.netWeight) * 0.454).toFixed(3);
                      unitPrice = (Number(item.unitPrice) / 0.454).toFixed(5);
                    } else {
                      finalNetWeight = Number(item.netWeight).toFixed(3);
                      unitPrice = Number(item.unitPrice).toFixed(5);
                    }
                  }
                  totalLbWeight += Number(finalNetWeight);
                  totalGrossWeight += (Number(item.grossWeightPerKg) * Number(item.invoicedCases));
                  // totalAmount += Number(unitPrice) * Number(finalNetWeight);
                  totalAmount = item.netAmount ;
                  totalAm += Number(item.netAmount);
                  let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" || "Sea Tiger" ? "PENAEUS MONODON" : "";
                  // let shrimp = item.itemName === "Vanname"?"SHRIMP":"";
                  let shrimp = "SHRIMPS";
                  let outputWeight = '';
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    outputWeight = UomEnum.LB;
                  } else if (uom.name == UomEnum.KG || uom.name == UomEnum.Gms) {
                    outputWeight = UomEnum.KG;
                  }
                  lbPouchWeight = Number(item.pouchWeight)
                  kgPouchWeight = Number(item.pouchWeight)
                  if (uom.name == UomEnum.OZ) {
                    lbPouchWeight = lbPouchWeight * 0.0625
                  }
                  if (uom.name == UomEnum.Gms) {
                    kgPouchWeight = kgPouchWeight * 0.001
                  }
                  return (
                    <><tr style={{ border: '0px' }}>
                      <td style={{ textAlign: 'center' }}>{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}<br />
                        <span style={{ textAlign: 'center' }}>{Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</span><br /><span style={{ textAlign: 'center' }}>{(uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? <>{(Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'}</> : <></>}</span></td>
                      <td style={{ textAlign: 'center' }}>{item.hsnCode}</td>
                      <td colSpan={2} style={{ textAlign: 'left', alignContent: 'center' }}>
                        {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                          (!(([
                            'HLSO', 'HOSO', 'EZPeel', 'N/A'
                          ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                        ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')}<br />{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}<br />
                        {'LATIN NAME : ' + latinName.toLocaleUpperCase('en-US') + ' '}<br />
                        {' BRAND : ' + item.displayBrandName?.toLocaleUpperCase('en-US')}<br />
                        {lotRefString}<br />
                        {plantDetails?.finNumber ? ('FPO#/FIN# ' + (plantDetails?.finNumber)) : ''}
                        {/* {' BRAND : ' + item.brandName.toUpperCase()} */}
                      </td>
                      <td style={{ textAlign: 'center' }} ></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      {/* <td className={'ta-b'} style={{textAlign:'center', borderTop:'0px', borderBottom:'0px'}}></td> */}
                    </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'left' }}>{item.minGrade + '/' + item.maxGrade}</td>
                        <td style={{ textAlign: 'center' }} ></td>
                        <td style={{ textAlign: 'right' }}>{item.invoicedCases}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(finalNetWeight) ? Number(finalNetWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}></td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}></td>
                      </tr>
                    </>
                  )
                }
                )
              }
              <tr>
                <td className={'ta-b'} colSpan={4} style={{ textAlign: 'center', borderTop: '0px' }}>
                  <b> Total</b>
                </td>
                <td colSpan={1} className={'ta-b'} style={{ textAlign: 'right' }}><b>
                  {totalCases}</b>
                </td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}><b>
                  {(totalLbWeight) ? Number(totalLbWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</b>
                </td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              </tr>

              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              {/* </table>
          <table style={{width:'100%'}} id="table-to-xls"> */}

              <tr>
                <td style={{ width: '20%' }} className={'ta-b'}></td>
                <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  <h4>IN LBS</h4>
                </td>
                <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  <h4>IN KGS</h4>
                </td>
                <td style={{ width: '30%' }}></td>
                <td className={'ta-b'} colSpan={4} style={{ width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}></td>
              </tr>
              <tr>
                <td style={{ width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  {' '}
                  <b>TOTAL NET WEIGHT:{' '}</b>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight / 0.454).toFixed(3)}</h4> */}
                  <h4>{(Math.round(totalLbWeight / 0.454)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight).toFixed(3)}</h4> */}
                  <h4>{(Number(totalLbWeight)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                  <b>TOTAL NO. OF MASTER CARTONS:</b>
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}>
                  <h4>{totalCases}</h4>
                </td>


              </tr>
              <tr>
                <td style={{ width: '20%', borderTop: '0px' }} className={'ta-b'}>
                  {' '}
                  <b> TOTAL GROSS WEIGHT:</b>
                </td>
                <td style={{ textAlign: 'right', width: "20%", borderTop: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                  <h4>{Math.round((totalGrossWeight / 0.454) ? (totalGrossWeight / 0.454) : 0 ).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px' }} className={'ta-b'}>
                <h4>{(Math.round(totalGrossWeight ? totalGrossWeight : 0 )).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                  {/* <b>TOTAL AMOUNT (IN {plantDetails?.saleOrderItems[0]?.currencyDetails}):</b> */}
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}  >
                  {/* <h4>{Number(totalAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4> */}
                </td>
              </tr>
              <tr>
                <td colSpan={4} id='inputText'><b>Declaration :</b>
                  <TextArea style={{ outline: 'none', border: 'none' }} rows={3} contentEditable={true} defaultValue={defaultDeclaration}
                    onChange={(evt) => handleRemarks(evt)}
                  ></TextArea>
                  {plantDetails?.remarks?.length > 0 ? <>
                    <h4><b>REMARKS : {(plantDetails?.remarks) ? plantDetails?.remarks : ''}</b></h4>
                  </> : ''}
                </td>
                <td colSpan={4} className={'ta-b'} style={{ textAlign: 'left' }}>
                  <div className='stamp-and-print'>

                    <b>Signature & Date For:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h4>{exporterData?.name}</h4><br />
                    <h4>AUTHORISED SIGNATORY</h4>
                  </div>
                </td>
              </tr>
            </table>
          </body>
        </html>


        : <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height: '100' }}>PLANT PACKING LIST</span>}
          // extra={<> <Button href="/#/document-register" style={{color:'black'}}>Back</Button></>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}><span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span></Card>}
    </>

  );
}

export default PlantPackingList;