import { AttendanceMonthRequest, MonthAttnDataModel } from "@gtpl/shared-models/hrms";
import { Input, Button, Space, Form, Row, Col, Select, Card } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { useState, useRef, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AttendanceService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { DepartmentDto, PlantsDropDown } from "@gtpl/shared-models/masters";
import { DepartmentService, UnitcodeService } from '@gtpl/shared-services/masters';

/* eslint-disable-next-line */

export const MonthwiseReportGrid = () => {


  const attendanceService = new AttendanceService;
  const [attendanceData, setAttendanceData] = useState<MonthAttnDataModel[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [month, setMonth] = useState<number>();
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [filtersData, setFiltersData] = useState<MonthAttnDataModel[]>([]);
  const [departmentData, setDepartmentData] = useState<DepartmentDto[]>([]);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const departmentService = new DepartmentService();
  const unitsService = new UnitcodeService();
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getAllDepartments();
    getAllPlants();
  }, []);

  function handleMonth(value: number) {
    setMonth(value);
  }

  const getAttendanceData = (month: number) => {
    const deptId = form.getFieldValue('department')
    const plantId = form.getFieldValue('unitName')
    const empAttnReq = new AttendanceMonthRequest(month, deptId, plantId);
    attendanceService.getMonthWiseAttendanceReport(empAttnReq).then(res => {
      setAttendanceData([]);
      if (res.status) {
        setAttendanceData(res.data);
        setFiltersData(res.data);
      } else {
        setAttendanceData([]);
        setFiltersData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
        return false;
      }
    }).catch(err => {
      setAttendanceData([]);
      setFiltersData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    getFiltersDropdownData(filtersData);
  }, [filtersData])

  const getFiltersDropdownData = (filtersData) => {
    let employeesData: any[] = [];
    filtersData.forEach(element => {

      if (element.department) {
        employeesData.push(element.department)
      }
      if (element.unitName) {
        employeesData.push(element.unitName)
      }

    });
    //setEmployeeDetails(employeesData);
  }
  const searchData = () => {

    const employeeName = form.getFieldValue('employeeName');
    const department = form.getFieldValue('department');
    const unitName = form.getFieldValue('unitName')

    let selectedData = filtersData;
    if (employeeName) {
      selectedData = filtersData.filter(item => item.employeeName === employeeName)
    }
    if (department) {
      selectedData = selectedData.filter(item => item.department === department)
    }
    if (unitName) {
      selectedData = selectedData.filter(item => item.unitName === unitName)
    }
    setAttendanceData(selectedData);
  }

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const getAllDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getDayHeaders = (data: MonthAttnDataModel[]) => {
    const dayHeaders = new Set<number>();
    data.forEach(rec => rec.dayStatus.forEach(date => {
      dayHeaders.add(date.day);
    }))
    return Array.from(dayHeaders);
  };

  const getEmpDayWiseAttendanceMap = (data: MonthAttnDataModel[]) => {
    const empDayWiseAttnMap = new Map<string, Map<number, string>>();
    data.forEach(rec => {
      if (!empDayWiseAttnMap.has(rec.employeeCode)) {
        empDayWiseAttnMap.set(rec.employeeCode, new Map<number, string>());
      }
      rec.dayStatus.forEach(date => {
        empDayWiseAttnMap.get(rec.employeeCode).set(date.day, date.presentStatus);
      })
    });
    return empDayWiseAttnMap;
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const onReset = () => {
    form.resetFields();
    setAttendanceData([]);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  let excelTitles: IExcelColumn[] = [];
  const renderReport = (data: MonthAttnDataModel[]) => {
    const dayHeaders = getDayHeaders(data);
    const dateWiseEmpAttendanceMap = getEmpDayWiseAttendanceMap(data);
    excelTitles = [
      { title: "Employee Code", dataIndex: "employeeCode" },
      { title: "Employee Name", dataIndex: "employeeName" },
      { title: "Department", dataIndex: "department" },
      { title: "Contractor Name", dataIndex: "contractorName" },
      { title: "Unit", dataIndex: "unitName" },
      { title: "Shift Code", dataIndex: "shift" },
    ];

    const columns: ColumnProps<any>[] = [
      {
        title: 'S.No',
        width: 55,
        fixed: 'left',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)

      },
      {
        title: 'Employee Code',
        dataIndex: 'employeeCode',
        fixed: 'left',
        width: 105,
        ...getColumnSearchProps('employeeCode'),
        sorter: (a, b) => a.employeeCode.length - b.employeeCode.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        width: 105,
        sorter: (a, b) => a.employeeName.length - b.employeeName.length,
        sortDirections: ['descend', 'ascend'],
        fixed: 'left',
        ...getColumnSearchProps('employeeName'),
      },

      {
        title: 'Department',
        dataIndex: 'department',
        width: 130,
        ...getColumnSearchProps('departmentId'),
        sorter: (a, b) => a.departmentId.length - b.departmentId.length,
        sortDirections: ['descend', 'ascend'],
        //  ...getColumnSearchProps('department'),
        fixed: 'left'
      },
      {
        title: 'Unit',
        dataIndex: 'unitName',
        width: 100,
        // sorter: (a, b) => a.unitName.length - b.unitName.length,
        // sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('unitName'),
        fixed: 'left'
      },
      {
        title: 'Contractor Name',
        dataIndex: 'contractorName',
        width: 100,
        ...getColumnSearchProps('contractorName'),
        fixed: 'left'
      },
      {
        title: 'Shift',
        dataIndex: 'shift',
        width: 100,
        ...getColumnSearchProps('shift'),
        sorter: (a, b) => a.shift.length - b.shift.length,
        sortDirections: ['descend', 'ascend'],
        fixed: 'left'
      }
    ];

    dayHeaders.forEach(day => {
      columns.push(
        {
          title: day,
          key: day,
          width: 60,
          align: 'center',
          render: (value, record: any, index) => {
            const empCode = record.employeeCode;
            return dateWiseEmpAttendanceMap.get(empCode).get(day) ? dateWiseEmpAttendanceMap.get(empCode).get(day) : '-';
          }
        }
      );
      excelTitles.push({
        title: day.toString(), dataIndex: "", render: (value: any, record: any) => {
          const empCode = record.employeeCode;
          return dateWiseEmpAttendanceMap.get(empCode).get(day);
        }
      });
    });

    columns.push(
      {
        title: 'Present Days',
        dataIndex: 'presentCount',
        fixed: 'right',
        width: 70,
        // sorter: (a, b) => a.presentCount.length - b.presentCount.length,
        // sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Half Days',
        dataIndex: 'workFromHomeCount',
        fixed: 'right',
        width: 70,
        // sorter: (a, b) => a.absentCount.length - b.absentCount.length,
        // sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Total OT Hours',
        dataIndex: 'otHours',
        fixed: 'right',
        width: 70,
        render: (text, record) => {
          return <span>
            {record.otHours ? record.otHours : 0}
          </span>
        }
        // sorter: (a, b) => a.otHours.length - b.otHours.length,
        // sortDirections: ['descend', 'ascend'],
        // fixed: 'right'
      },
      {
        title: 'Total OT Shifts',
        dataIndex: 'otShifts',
        fixed: 'right',
        width: 70,
        render: (text, record) => {
          return <span>
            {record.otShifts ? record.otShifts : 0}
          </span>
        }
        // sorter: (a, b) => a.otShifts.length - b.otShifts.length,
        // sortDirections: ['descend', 'ascend'],
        // fixed: 'right'
      },
      {
        title: 'Absent Days',
        dataIndex: 'absentCount',
        fixed: 'right',
        width: 70,
        // sorter: (a, b) => a.absentCount.length - b.absentCount.length,
        // sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Leave Days',
        dataIndex: 'leaveCount',
        fixed: 'right',
        width: 70,
        // sorter: (a, b) => a.absentCount.length - b.absentCount.length,
        // sortDirections: ['descend', 'ascend']
      }
    );

    excelTitles.push(
      { title: "Present Days", dataIndex: "presentCount" },
      { title: "Total OT Hours", dataIndex: "otHours" },
      { title: "Total OT Shifts", dataIndex: "otShifts" },
      { title: "Absent Days", dataIndex: "absentCount" },
      { title: "Half Days", dataIndex: "workFromHomeCount" },
      { title: "Leave Days", dataIndex: "leaveCount" },
    );

    return <Table columns={columns} bordered dataSource={data} size="small" scroll={{ x: 1500, y: 500 }}
      pagination={{
        onChange(current) {
          setPage(current);
        }
      }}
      onChange={onChange}
    />
  }

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('id')
      .addColumns(excelTitles)
      .addDataSource(attendanceData, { str2num: true })
      .saveAs('Monthwise-Attendance-Report.xlsx');
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Monthwise Attendance Report</span>} extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form form={form} layout="horizontal">
        <Row gutter={22}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item label="Month" name='month' required={true}>
              <Select
                showSearch
                placeholder="Select Month"
                onChange={handleMonth}
              >
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">March</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="department"
              label="Department"
              rules={[
                {
                  required: false,
                  message: "Enter Your Department"
                }
              ]}>
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Department"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {departmentData.map((departmentData) => {
                  return <Option key={departmentData.deptId} value={departmentData.deptId}>{departmentData.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="unitName"
              label="Unit"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              >
                {plantData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col >
          <br />
          <br />
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 3 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={() => getAttendanceData(month)}>
                Submit
              </Button>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 1 }}>
            <Form.Item>
              <Button type="primary" htmlType="reset" onClick={() => onReset()}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div>
          {renderReport(attendanceData)}
        </div>
      </Form>
    </Card>
  );
}
export default MonthwiseReportGrid;
