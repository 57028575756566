import { WorkCalenderDto } from "@gtpl/shared-models/hrms";
import { WorkCalenderService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Divider, Drawer, Input, Popconfirm, Switch, Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Table, { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { Link } from "react-router-dom";
import TeamCalender from "../../../team-calender-form/src/lib/pages-hrms-hrms-components-team-calender-form";
import WorkCalender from "../../../work-calender-form/src/lib/pages-hrms-hrms-components-work-calender-form";

export interface WorkCalenderGridProps {}

export function WorkCalenderGrid(
  props: WorkCalenderGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [workcalenderData, setWorkCalenderData] = useState<WorkCalenderDto[]>([]);
  const [selectedWorkCalender, setSelectedWorkCalender] = useState<any>(undefined);

  const workcalenderService = new WorkCalenderService();

  useEffect(() => {
    getAllWorkCalender();
  },[]);

  
  const getAllWorkCalender = () => {
    workcalenderService.getAllWorkCalender().then(res => {
      if(res.status){
        setWorkCalenderData(res.data);
      }else {
        if(res.intlCode){
          setWorkCalenderData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setWorkCalenderData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
   * 
   * @param WorkCalenderData 
   */
    const deleteWorkCalender = (workcalenderData:WorkCalenderDto) => {
      workcalenderData.isActive=workcalenderData.isActive?false:true;
      workcalenderService.activateOrDeactivateWorkCalender(workcalenderData).then(res => { console.log(res);
        if (res.status) {
          getAllWorkCalender();
          AlertMessages.getSuccessMessage('Success');
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

    /**
     * 
     * @param variantData 
     */
   const updateWorkCalender = (workcalenderData: WorkCalenderDto) => {
    workcalenderService.updateWorkCalender(workcalenderData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllWorkCalender();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  
    /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(viewData: WorkCalenderDto)=>{
    setDrawerVisible(true);
    setSelectedWorkCalender(viewData);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },

    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeCode?.length - b.employeeCode?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeCode')
    },
    {
      title: 'Date',
      dataIndex: 'date',
      // responsive: ['lg'],
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('date')
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      // responsive: ['lg'],
      sorter: (a, b) => a.shift?.length - b.shift?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shift')
    },
    {
      title: 'Shift Code',
      dataIndex: 'shiftCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.shiftCode?.length - b.shiftCode?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shiftCode')
    },
    {
      title: 'Work Station Team',
      dataIndex: 'workStationTeam',
      // responsive: ['lg'],
      sorter: (a, b) => a.workStationTeam?.length - b.workStationTeam?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workStationTeam')
    },
    {
      title: 'In Time',
      dataIndex: 'inTime',
      render: (text, record) => { return moment(record.inTime).format('YYYY-MM-DD') },
      sorter: (a, b) => a.inTime.localeCompare(b.inTime),
      sortDirections: ['descend', 'ascend'],
    
    },
    {
      title: 'Out Time',
      dataIndex: 'outTime',
      render: (text, record) => { return moment(record.outTime).format('YYYY-MM-DD') },
      sorter: (a, b) => a.outTime.localeCompare(b.outTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Attendance Status',
      dataIndex: 'attendanceStatus',
      // responsive: ['lg'],
      sorter: (a, b) => a.attendanceStatus?.length - b.attendanceStatus?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('attendanceStatus')
    },
    {
      title: 'Leave Code',
      dataIndex: 'leaveCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.leaveCode?.length - b.leaveCode?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('leaveCode')
    },
    {
      title: 'Holiday',
      dataIndex: 'holiday',
      // responsive: ['lg'],
      sorter: (a, b) => a.holiday?.length - b.holiday?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('holiday')
    },
    {
      title: 'Department',
      dataIndex: 'department',
      // responsive: ['lg'],
      sorter: (a, b) => a.department?.length - b.department?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('department')
    },
    {
      title: 'Contractor',
      dataIndex: 'contractor',
      // responsive: ['lg'],
      sorter: (a, b) => a.contractor?.length - b.contractor?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('contractor')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>  
             <Tooltip placement="top" title='Edit'>     
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Team Calender');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteWorkCalender(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Team Calender ?'
                :  'Are you sure to Activate Team Calender ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  
  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   
  }
  
  return (
    <Card title={<span style={{color:'white'}}>Work Calender</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/workcalender' ><Button className='panel_button' >Create </Button></Link>}>
          <Table
          rowKey={record => record.id}
          columns={columnsSkelton}
          dataSource={workcalenderData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <WorkCalender key={Date.now()}
                updateWorkCalender={updateWorkCalender}
                isUpdate={true}
                workcalenderData={selectedWorkCalender}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}
export default WorkCalenderGrid; 