import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { RecruitmentActivitiesService } from '@gtpl/shared-services/hrms'
import './activities-form.css';
import { RecruitmentActivitiesDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';




const { TextArea } = Input;

export interface ActivitiesFormProps {
  isUpdate: boolean;
  closeForm: () => void;
  updateRecruitmentActivity: (data: RecruitmentActivitiesDto) => void;
  data: RecruitmentActivitiesDto
}

export function ActivitiesForm(props: ActivitiesFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const service = new RecruitmentActivitiesService
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const saveData  = (values: RecruitmentActivitiesDto) => {
    if(props.isUpdate){
      console.log(values)
      props.updateRecruitmentActivity(values);
    }
    else{
      console.log(values)
      create(values);
    }
  };

  const onReset = () => {
    form.resetFields();
  }

  const create = (data: RecruitmentActivitiesDto) => {
    service.createRecruitmentActivity(data).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Recruitment Activity created sucessfully');
        history.push('/activities-view');
        onReset();
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  if (props?.data && props.data.activityDate) {
    let activityDate: any = moment(props.data.activityDate);
    props.data.activityDate = activityDate;
  }


  return (
    <Card title={<span style={{ color: 'white' }}>Activities</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/activities-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}

        name="control-hooks"
        initialValues={props.data}
        onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="recruitmentActivityId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="activityDate"
              label="Date"
              initialValue={ moment(moment().format("YYYY-MM-DD")) }
              rules={[
                {
                  required: true,
                  message:"Select the date again"
                },
                ]}
            >
              < DatePicker
                           style={{ width: '100%' }} format="YYYY-MM-DD"
                          defaultValue={ moment(moment().format("YYYY-MM-DD")) }
                           showToday={true} 
                        />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="candidateName"
              label="Candidate Name"
              rules={[{ required: true },
              {

                pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                message: `Should contain only alphabets and first letter should be capital.`
              }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="activity"
              label="Activity"
              rules={[{ required: true },
              {

                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                message: `Should contain only alphabets.`
              }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item  name="remarks" label="Remarks"
            rules={[
              {
                //required: true,
                message:"It should not cross the limit", max:100
              }, 
          ]}>
                <TextArea rows={2} />
              </Form.Item>
            </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ActivitiesForm;
