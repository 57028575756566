import React, { useEffect, useRef, useState } from 'react';
import { Excel } from 'antd-table-saveas-excel';
import {Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip} from 'antd';
import {BarcodeOutlined,SearchOutlined,UndoOutlined} from '@ant-design/icons';
import {EmployeeService} from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {AssetLocationMappingDto} from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import {AssetAssignmentService} from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import moment from 'moment';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import Highlighter from 'react-highlight-words';
import{AssetsDto}  from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { ColumnProps } from 'antd/es/table';

const {TabPane} = Tabs;
const {Option} = Select;

/* eslint-disable-next-line */
export interface AssetHistoryReportProps {}

export function AssetHistoryReport(
  props: AssetHistoryReportProps
) {

  const [page,setPage] = useState<number>(1);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<AssetLocationMappingDto[]>([]);
  const [checkInData,setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData,setAssetCodeData] = useState<AssetDto[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [selectedData,setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService(); 
  const assignmentService = new AssetAssignmentService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [itemdata, setItemData] = useState<any>(null);
  const [assetView,setAssetView] = useState<boolean>(false);
  const [historyData,setHistoryData] = useState<any[]>([]);



  useEffect(()=>{
    getAssetCode();
  },[])

  const getAssetCode = () => {
    assetsService.getallAssets().then((res) => {
      if(res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getDetailedView = () => {
    const assetId = form.getFieldValue('assetCode');
    const req = new AssetByCode(assetId)
    assetsService.getByAssetCode(req).then(res => {
      assetHistoryInformation(req)
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        setAssetView(true);
       // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)

      }
    // }).catch(err => {
      // AlertMessages.getErrorMessage(err.message)

    })
  }

  const assetHistoryInformation = (req) => {
    assetsService.assetHistory(req).then(res => {
      if(res.status){
        setHistoryData(res.data)
      } else{
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })   
    getDetailedView()
 
  }

  const resetHandler = () => {
    form.resetFields();
    setHistoryData([])
    setAssetView(false);
  }

  const finishHandler = (value) => {
   getDetailedView();
    
  }

  const column : ColumnProps<any>[] =[
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Transaction Date',
      dataIndex:'transactionDate',
      width:130,
      align:"left",
      render: (text,record) => {
        return (
          <>
          {record.transactionDate ? moment(record.transactionDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      }
    },
    {
      title:'Transaction Type',
      dataIndex:'transactionType',
      width:130,
      align:"left",
    },
    { 
      title:'Assigned to',
      dataIndex:'employeeName',
      width:130,
      align:"left",
      render:(text,record) => {
        return(
          <>
          {record.transactionType == 'INUSE' ? record.employeeName : '-'}
          </>
        )
      }
    },
    {
      title:'Department',
      dataIndex:'department',
      width:130,
      align:"left",
      render:(text,record) => {
        return(
          <>
          {record.transactionType == 'INUSE' ? record.department : '-'}
          </>
        )
      }
    },
    {
      title:'Service Name',
      dataIndex:'serviceName',
      width:130,
      align:"left",
      render: (text,record) => {return record.serviceName ? record.serviceName : '-'}
    },
    {
      title:'Location',
      dataIndex:'location',
      width:130,
      align:"left",
      render: (text,record) => {return record.location ? record.location : '-'} 
    },
    {
      title:'Approved by',
      dataIndex:'Approved',
      width:130,
      align:"left",
      render: (text,record) => {return record.Approved ? record.Approved : '-'}
    },
    
    {
      title:'Maintenance Cost',
      dataIndex:'maintenanceCost',
      width:130,
      align:"left",
      render: (text,record) => {return record.transactionType == 'UNDERMAINTENANCE' ? record.maintenanceCost : '-'} 
      
    }
  ]

  const exportedData = [];
  const excelData = historyData
  let i = 1;
  const data = [
     { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    // { title: 'Asset Category', dataIndex: 'itemSubCategoryName' },
    // { title: 'Asset Name', dataIndex: 'itemName' },
    // { title: 'Asset Serial Number', dataIndex: 'assetSerialNo',render: (text, record) => { return record.assetSerialNo ? record.assetSerialNo: '-'}  },
    //  { title: 'Asset Code', dataIndex: 'assetsCode' },
    // {title: 'Invoice Number', dataIndex: 'invoice',render: (text, record) => { return record.invoice ? record.invoice: '-'} },
    // {title: 'Model', dataIndex: 'model',render: (text, record) => { return record.model ? record.model: '-'} },
    // { title: 'Asset Cost', dataIndex: 'assetCost',render: (text, record) => { return record.assetCost ? record.assetCost: '-'}  },
    // { title: 'Purchase Date', dataIndex: 'dateOfPurchased', render: (text, record) => { return record.dateOfPurchased ? moment(record.daetOfPurchased).format('YYYY-MM-DD'): '-'} },
    // { title: 'Asset Supplier', dataIndex: 'assetSupplier',render: (text, record) => { return record.assetSupplier ? record.assetSupplier: '-'}  },
    // { title: 'Asset Owner', dataIndex: 'employeeName' },
    // { title: 'Asset Condition', dataIndex: 'assetcondition' },
    // { title: 'Curretn Location', dataIndex: 'currentLocation' },
    // { title: 'AMC Availability', dataIndex: 'amc' },
    // { title: 'Manufacturer/Vendor Name', dataIndex: 'amcname',render: (text, record) => { return record.amcname ? record.amcname: '-'} },
    // // { title: 'Manufacturer/Vendor Number', dataIndex: 'amcno',render: (text, record) => { return record.amcno ? record.amcno: '-'}  },
    // { title: 'Warranty Availability', dataIndex: 'warrenty' },
    // { title: 'Warranty Valid Upto', dataIndex: 'warrentyExpiredDate', render: (text, record) => { return record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD'): '-'} },
    // { title: 'Insurance Availability', dataIndex: 'insurance' },
    // { title: 'Insurance Valid upto', dataIndex: 'insuranceDate', render: (text, record) => { return record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD'): '-'}  },
    // {title: 'Asset Status', dataIndex: 'status'},
    // {title: 'Asset Age (In Days)' , dataIndex: 'assetAge'},
    {title: 'Transaction Date', dataIndex: 'transactionDate' , render: (text, record) => { return record.transactionDate ? moment(record.transactionDate).format('YYYY-MM-DD'): '-'} },
    {title: 'Transaction Type', dataIndex: 'transactionType',render: (text,record) => {return record.transactionType ? record.transactionType : '-'} },
    {title: 'Assigned to', dataIndex: 'employeeName',render: (text,record) => {return record.transactionType == 'INUSE' ? record.employeeName : '-'}  },
    {title: 'Department', dataIndex: 'department',render: (text,record) => {return record.transactionType == 'INUSE' ? record.department : '-'}  },
    {title: 'Service Name', dataIndex: 'serviceName',render: (text,record) => {return record.serviceName ? record.serviceName : '-'} },
    {title: 'Location', dataIndex: 'location',render: (text,record) => {return record.location ? record.location : '-'} },
    {title: 'Approved by', dataIndex: 'Approved',render: (text,record) => {return record.Approved ? record.Approved : '-'} },
    {title: 'Maintenance Cost', dataIndex: 'maintenanceCost',render: (text,record) => {return record.transactionType == 'UNDERMAINTENANCE' ? record.maintenanceCost : '-'}  },

  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('History-Report')
      .addColumns(data)
      .addDataSource(historyData, { str2num: true })
      .saveAs('HistoryReport.xlsx');
  }

  
  return (
    
    <Card title={<span style={{ color: 'white' }}> History Report</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} 
    extra={historyData.length > 0 ? (
      <>
        <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
      </>
    ) : (<></>)}
    >
       <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[24,24]}>
            <Col  xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5}}
            lg={{ span: 5}}
            xl={{ span: 6}}>
              <Form.Item name="assetCode" label="Asset Code">
              <Select 
            suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }}/>}
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetCodeData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
            })

            }
          </Select>
              </Form.Item>
            </Col>
            <Col  style={{ marginTop: "2.5%" }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
              </Col>
              <Col style={{marginTop:"2.5%"}}>
            <Button 
              icon={<UndoOutlined/>}
              onClick={ resetHandler}>
              Reset
            </Button>
            </Col>
          </Row>
        </Form>
        { assetView ?
       ( <Card>
      <Descriptions>
        {/* <Descriptions.Item label="Asset Category">{previewdata?.assetCategory} </Descriptions.Item> */}
        
        <Descriptions.Item label="Plant"> {itemdata?.plant} </Descriptions.Item>
        <Descriptions.Item label="Current Location"> {itemdata?.location_name} </Descriptions.Item>        
        <Descriptions.Item label="Asset Mode"> {previewdata?.assetMode} </Descriptions.Item>
        <Descriptions.Item label="Asset Type"> {previewdata?.assetType} </Descriptions.Item>
        <Descriptions.Item label="Asset Category"> {itemdata?.assetCategory}</Descriptions.Item>
        <Descriptions.Item label="Asset Name"> {itemdata?.assetname}</Descriptions.Item>
        <Descriptions.Item label="Asset Serial Number"> {previewdata?.assetSerialNo}</Descriptions.Item>
        <Descriptions.Item label="Asset Code"> {previewdata?.assetsCode} </Descriptions.Item>
        <Descriptions.Item label="Invoice">{previewdata?.invoice}  </Descriptions.Item>
        <Descriptions.Item label="Model"> {previewdata?.model} </Descriptions.Item>
        <Descriptions.Item label="No.Of Units"> {previewdata?.noOfUnits} </Descriptions.Item>
        <Descriptions.Item label="Purchased Date"> {moment(previewdata?.dateOfPurchased).format('YYYY-MM-DD')} </Descriptions.Item>
        <Descriptions.Item label="Asset Cost"> {previewdata?.assetCost} </Descriptions.Item>
        <Descriptions.Item label="Asset Supplier"> {previewdata?.assetSupplier} </Descriptions.Item>
        <Descriptions.Item label="AMC Availability"> {previewdata?.amc} </Descriptions.Item>
        <Descriptions.Item label="Warranty  Availability"> {previewdata?.warrenty} </Descriptions.Item>
        <Descriptions.Item label="Warranty Valid Upto">{previewdata?.warrentyExpiredDate ? moment(previewdata?.warrentyExpiredDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        <Descriptions.Item label="Insurance"> {previewdata?.insurance} </Descriptions.Item>
        <Descriptions.Item label="Insurance Valid Upto">{previewdata?.insuranceDate ? moment(previewdata?.insuranceDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        <Descriptions.Item label="Expired Date">{previewdata?.expiredDate ? moment(previewdata?.expiredDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
        <Descriptions.Item label="Asset Condition">{previewdata?.assetcondition} </Descriptions.Item>
        <Descriptions.Item label="Status"> {previewdata?.status} </Descriptions.Item>
        <Descriptions.Item label="Owner"> {itemdata?.employee_name} </Descriptions.Item>
      </Descriptions>
      {
        historyData.length > 0 ? <>
            <Table columns={column} dataSource={historyData} scroll={{ x: 600,y:400 }}
/>
        </> : ' '
      }
    </Card>
    ):(
        <>
        </>
    )
}
    </Card>
  );
}

export default AssetHistoryReport;



