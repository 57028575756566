
import React, { useEffect, useState, useRef } from 'react';
import { ColumnProps } from "antd/lib/table";
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal, Form, Select, Typography, DatePicker, Alert } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, BarcodeOutlined, EyeOutlined, EditOutlined, SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import 'jspdf-autotable';
import { AssetsDetailedView } from './Detailed-view';
import moment from 'moment';
import { AssetDto, purchasedatefilterreq } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetService } from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';

import { Excel } from 'antd-table-saveas-excel';
import Highlighter from 'react-highlight-words';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import {UnitcodeService,ItemSubCategoryService} from '@gtpl/shared-services/masters';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

export interface InsuranceReportProps {
    updateState?: (Key: number) => void;
  }
export function InsuranceReport(
    props: InsuranceReportProps
  ) {
const { Option } = Select;
const { RangePicker } = DatePicker;
const [pageSize, setPageSize] = useState<number>(null);
const [page, setPage] = useState<number>(1);
const [assetsData, setAssetsData] = useState<AssetDto[]>([]);
const [dropData, setDropData] = useState<any>([]);
const { Text } = Typography;
const service = new AssetService();
const [form] = Form.useForm();
const itemSubCategoryService = new ItemSubCategoryService();

const [plantName,setPlantName]= useState<any>([]);
const [itemCategory,setItemCategory] = useState([]);
const plantsService = new UnitcodeService();
const role = JSON.parse(localStorage.getItem('role'))
const locationService = new AssetLocationService();
const [locationData,setLocationData] = useState<any[]>([]);
const [length,setLength]= useState('');
const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
let diff = 0;
let date;
let todate;
useEffect(()=>{
  getAllAssets();
  getAllPlants();
  getAssetsDropDown();
  getItemCategory();
  if (Number(localStorage.getItem('unit_id')) != 5) {
    form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
  }
  getLocations();
},[])

const getAllAssets = () => {
  const req = new AssetByStatus();
  if(form.getFieldValue('assets_code') != undefined){
    req.assetCode = form.getFieldValue('assets_code')
  } 
  if(form.getFieldValue('assetType') !== undefined){
    req.assetType = form.getFieldValue('assetType')
  }
  if(form.getFieldValue('assetCategory') !== undefined){
    req.assetCategory = form.getFieldValue('assetCategory')
  }
  if(form.getFieldValue('plantId') != undefined){
    req.plantId = form.getFieldValue('plantId')
  }
  if(form.getFieldValue('location') != undefined){
    req.location = form.getFieldValue('location')
  }
    service.getInsuranceAssets(req).then(res => {
      if (res.status) {
        setAssetsData(res.data)
        setLength(res.data.length)
        AlertMessages.getSuccessMessage('Assets retrieved successfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAssetsDropDown = () => {
    service.getInsuranceDropDownAssets().then(res => {
      if (res.status) {
        setDropData(res.data)
        // AlertMessages.getSuccessMessage('Assets retrieved successfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if(res.status){
        setPlantName(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getLocations = () => {
    const req = new LocationByPlant();
  req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if(res.status){
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });
    const onReset = () => {
    form.resetFields();
    getAllAssets();
  }
const columns: ColumnProps<any>[] = [
  {
    title: "S.No",
    key: "sno",
    width: 60,
    align: "left",
    fixed: "left",
    responsive: ["sm"],
    render: (text, object, index) => (page - 1) * pageSize + (index + 1)
  },
   {
    title: "Plant",
    dataIndex: "plantName",
    align: "left",
    fixed: "left",  
    sorter: (a, b) => a.plantName.localeCompare(b.plantName),
    sortDirections: ['descend', 'ascend'],

    // width:120,
    render: (text, record) => {
      return (
        <>
          {record.plantName ? record.plantName : '-'}
        </>
      )
    },
    // ...getColumnSearchProps('plant')
  },

  {
    title: "Location",
    dataIndex: "location_name",
    align: "left",
    fixed: "left",
    // width:120,
     sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('location_name'),
    render: (text, record) => {
      return (
        <>
          {record.location_name ? record.location_name : '-'}
        </>
      )
    },
    // ...getColumnSearchProps('currentLocation')
  },
  {
    title: "Asset Type",
    dataIndex: "asset_type",
    align: "left",
    // width:120,
    fixed: "left",
    sorter: (a, b) => a.asset_type.localeCompare(b.asset_type),
    sortDirections: ['descend', 'ascend'],
    // sorter: (a, b) => a.item_sub_category.localeCompare(b.item_sub_category),
    // sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return (
        <>
          {record.asset_type ? record.asset_type : '-'}
        </>
      )
    }, filters: [
      {
        text: 'MOVABLE',
        value: 'movable',
      },

      {
        text: 'FIXED',
        value: 'FIXED',
      },
      {
        text: 'SOFTASSETS',
        value: 'softassets',
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => {
      // === is not work
      return record.asset_type === value;
    },

    ...getColumnSearchProps('asset_type')
  },

  {
    title: "Asset Category",
    dataIndex: "item_sub_category",
    align: "left",
    // width:120,
    fixed: "left",
    sorter: (a, b) => a.item_sub_category.localeCompare(b.item_sub_category),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('item_sub_category')
  },
  {
    title: "Asset Name",
    dataIndex: "item_name",
    align: "left",
    fixed: "left",
    sorter: (a, b) => a.item_name.localeCompare(b.item_name),
    sortDirections: ['descend', 'ascend'],
     ...getColumnSearchProps('item_name')
  },
  {
    title: "Asset Code",
    dataIndex: "assets_code",
    align: "left",
    sorter: (a, b) => a.assets_code?.localeCompare(b.assets_code),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('assets_code')
  },
    {
    title: "Invoice Number",
    dataIndex: "asset_invoice_no",
    align: "left",
    // width:120,
    sorter: (a, b) => a.asset_invoice_no?.localeCompare(b.asset_invoice_no),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('asset_invoice_no'), 
    render: (text, record) => {
      return (
        <>
          {record.asset_invoice_no ? record.asset_invoice_no : '-'}
        </>
      )
    },
    // ...getColumnSearchProps('currentLocation')
  },
  {
    title: "Serial Number",
    dataIndex: "asset_serialno",
    align: "left",
    sorter: (a, b) => a.asset_serialno?.localeCompare(b.asset_serialno),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('asset_serialno'),
    // width:120,
    render: (text, record) => {
      return (
        <>
          {record.asset_serialno ? record.asset_serialno : '-'}
        </>
      )
    },
    // ...getColumnSearchProps('assetSerialNo')
  },

  {
    title: "Purchase Date",
    dataIndex: "date_of_purchased",
    align: "right",
    sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return (
        <>
          {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
        </>
      )
    }
  },
  {
    title: "AMC",
    dataIndex: "amc_available",
    align: "left",
    // width:120,
    sorter: (a, b) => a.amc_available?.localeCompare(b.amc_available),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return (
        <>
          {record.amc_available ? record.amc_available : 'No'}
        </>
      )
    }
  },
 ,
  {
    title: "Assigned To",
    dataIndex: "employee_name",
    align: "left",
    sorter: (a, b) => a.employee_name?.localeCompare(b.employee_name),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('employee_name')

  },
  {
    title: "Insurance",
    dataIndex: "insurance_date",
    align: "right",
    //  width:70,
 // width:120,
 sorter: (a, b) => a.insurance_date?.localeCompare(b.insurance_date),
 sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('insurance_date'),
    // ...getColumnSearchProps('insurance_date'),
    render: (text, record) => {
      return (
        <>
          {record.insurance_date ? moment(record.insurance_date).format('YYYY-MM-DD') : '-'}
        </>
      )
    }
  },
  {
    title: "Insurance Reference No",
    dataIndex: "insurance_reference_number",
    align: "right",
    sorter: (a, b) => a.insurance_reference_number?.localeCompare(b.insurance_reference_number),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('insurance_reference_number'),
    render: (text, record) => {
      return (
        <>
          {record.insurance_reference_number ? record.insurance_reference_number : 'N/A'}
        </>
      )
    }
  }, {
    title: "Insurance Company",
    dataIndex: "insurance_provided_by",
    align: "left",
    ...getColumnSearchProps('insurance_provided_by'),
    sorter: (a, b) => a.insurance_provided_by?.localeCompare(b.insurance_provided_by),
        sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return (
        <>
          {record.insurance_provided_by ? record.insurance_provided_by : 'N/A'}
        </>
      )
    }
  },
  
  {
    title: "Status",
    dataIndex: "status",
    align: "left",
    ...getColumnSearchProps('status'),
    sorter: (a, b) => a.status?.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
  },
  // {
  //   title: "Aging",
  //   dataIndex: "age",
  //   render:(text,record,index) => {        
  //       todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
  //       date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
  //       diff = (todate - date) / 864e5;
  //       if(diff < 0) {
  //         diff = -diff;
  //       }
  //     return(
  //       <>
  //       {record.transactionDate ?  diff : '-'}
  //       </>
  //     )

  //   }
  // },
  {
    title: "Expiry Date",
    dataIndex: "expired_date",
    align: "right",
    sorter: (a, b) => a.expired_date?.localeCompare(b.expired_date),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('expired_date'),
    // ...getColumnSearchProps('expired_date'),
    // width:120,
    render: (text, record) => {
      return (
        <>
          {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
        </>
      )
    }
  },
  {
    title: "Cost of Asset",
    dataIndex: "asset_cost",
    align: "right",
    // width:120,
    ...getColumnSearchProps('asset_cost'),
    sorter: (a, b) => a.asset_cost - b.asset_cost,
      sortDirections: ['descend','ascend'],
     render: (text, record) => {
      return (
        <>
          {record.asset_cost ? Number(Number(record.asset_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
        </>
      )
    }
  },
  {
    title: ' Insurance Age',
    key: 'insurance_date',
    align: "right",
    sorter: (a, b) => (Math.floor((new Date(a.insurance_date).getTime() - new Date(b.insurance_date).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.insurance_date).getTime() - new Date(b.insurance_date).getTime()) / (1000 * 60 * 60 * 24)) + 1),
    sortDirections: ['descend', 'ascend'],
    // ...getColumnSearchProps('aging'),
    render(text, record) {
      const obj: any = {
        children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.insurance_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
        props: {
          style: {
            background: Math.floor((new Date(record.insurance_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
            color: Math.floor((new Date(record.insurance_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
          }
        },
      };
      return obj;
    }
   
  },
]
const exportExcel = () => {
  const excel = new Excel();
  excel
    .addSheet('Insurance-report')
    .addColumns(data)
    .addDataSource(assetsData, { str2num: true })
    .saveAs('InsuranceReport.xlsx');
}




const exportedData = [];
const excelData = assetsData
let i = 1;
const data = [
  { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
  { title: 'Plant', dataIndex: 'plantName',render: (text, record) => { return record.plantName ? record.plantName: '-'} },
  { title: 'Location', dataIndex: 'location_name',render: (text, record) => { return record.location_name ? record.location_name: '-'} },
  { title: 'Asset Type', dataIndex: 'asset_type',render: (text, record) => { return record.asset_type ? record.asset_type: '-'} },
  { title: 'Asset Category', dataIndex: 'item_sub_category',render: (text, record) => { return record.item_sub_category ? record.item_sub_category: '-'} },
  { title: 'Asset Name', dataIndex: 'item_name',render: (text, record) => { return record.item_name ? record.item_name: '-'} },
  { title: 'Asset Code', dataIndex: 'assets_code',render: (text, record) => { return record.assets_code ? record.assets_code: '-'} },
    {title: 'Invoice Number', dataIndex: 'asset_invoice_no',render: (text, record) => { return record.asset_invoice_no ? record.asset_invoice_no: '-'} },
  { title: 'Asset Serial Number', dataIndex: 'asset_serialno',render: (text, record) => { return record.asset_serialno ? record.asset_serialno: '-'}  },
  {title: 'Model', dataIndex: 'model',render: (text, record) => { return record.model ? record.model: '-'} },
  { title: 'Cost of Asset', dataIndex: 'asset_cost',render: (text, record) => { return record.asset_cost ? record.asset_cost: '-'}  },
  { title: 'Purchase Date', dataIndex: 'date_of_purchased', render: (text, record) => { return record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD'): '-'} },
    {title: 'AMC', dataIndex: 'amc_available',render: (text, record) => { return record.amc_available ? record.amc_available: '-'} },
    { title: 'Assigned To', dataIndex: 'employee_name',render:(text,record) => {return record.employee_name  ? record.employee_name:'-'} },
    { title: 'Insurance', dataIndex: 'insurance_date',render: (text, record) => { return record.insurance_date ? record.insurance_date: '-'} },
    { title: 'Insurance Reference No', dataIndex: 'insurance_reference_number',render:(text,record) => {return record.insurance_reference_number  ? record.insurance_reference_number:'-'} },
    { title: 'Insurance Company', dataIndex: 'insurance_provided_by',render: (text, record) => { return record.insurance_provided_by ? record.insurance_provided_by: '-'} },
    { title: 'Asset Owner', dataIndex: 'employeeName',render: (text, record) => { return record.employeeName ? record.employeeName: '-'} },
  { title: 'Status', dataIndex: 'status',render: (text, record) => { return record.status ? record.status: '-'} },
 
];
return (
    <>
      
      <Card
        title={<span style={{ color: "white" }}> Insurance Report</span>}
        style={{ textAlign: "center" }}
        headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
        // extra={<>
        // <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>   </> }
        extra={assetsData.length > 0 ? (
          <>
            <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
          </>
        ) : (<></>)}
        >
            <Form form={form}
        layout='vertical'>
        <Row gutter={[24,24]}>
        <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5}}
            xl={{ span: 5}}>
          <Form.Item name="assets_code" label='Asset Code'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropData.map((assets) => {
              return <Option key={assets.assets_code} value={assets.assets_code}>{`${assets.item_name} -- ${assets.assets_code}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
               defaultValue={'All'}
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Plant' name='plantId'
          initialValue={Number(localStorage.getItem('unit_id')) }>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
            showSearch
            allowClear>
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'All'}
            showSearch
            allowClear>
            <Option key={'all'} value={''}>All</Option>
            {locationData.map((location) => {
              return <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType'>
          <Select 
              placeholder='assetType'
              showSearch
              defaultValue={'All'}
              //onChange={onStatusSearch}
              allowClear
            >
              <Option key='all' value=''>All</Option>
              {Object.values(AssetTypeEnum).map((i) => {
                return (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          </Col>
          <Col style={{ marginTop: "2.3%" }} >
              <Button type='primary' onClick={getAllAssets}>Submit</Button>
            </Col>
            <Col style={{ marginTop: "2.3%" }}>
              <Button onClick={onReset}>Reset</Button>
            </Col></Row>
            <Col span={3} style={{marginLeft:'87%',paddingBottom:'1px'}}>
            <Button >Total no of Records:{length}
            </Button>
            </Col>
        </Form>
        {assetsData.length >0?(<>
          <Table
          columns={columns}
          dataSource={assetsData}
          scroll={{ x: 2000, y: 500 }}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
        //   onChange={}
          bordered
          summary={(pageData) => {
            let totalCost = 0;

            pageData.forEach(({ asset_cost }) => {
              if (Number(asset_cost)) {
                totalCost += Number(asset_cost)
              }
            })

            return (
              <>
                <Table.Summary.Row className="tableFooter">
                  <Table.Summary.Cell index={10} colSpan={16}><Text>Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={10} colSpan={1}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                </Table.Summary.Row>
              </>
            )
          }}

        /> </>):(
          <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
        )}
        
       
        </Card>
        </>
)
 }
export default InsuranceReport;