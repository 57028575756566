import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { TrimsMappingService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import './trims-mapping-grid.css';
import { TrimsItemMappingDto } from '@gtpl/shared-models/masters';
 export interface TrimsItemsMappingGrid{
    trimId:number;
 }

 export function TrimsItemsMappingGrid(props:TrimsItemsMappingGrid){
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [trimsitemdata,setTtrimsitemdata] = useState<TrimsItemMappingDto[]>([]);
  const trimsmappingservice = new TrimsMappingService;

  useEffect(() =>{
    getitemsdata();
  },[])


    const getitemsdata = () =>{
        trimsmappingservice.getalltrimsitemmappingdetailsByTrimsId({trimId: props.trimId}).then(res =>{
          if(res.status){
            setTtrimsitemdata(res.data);
          }else{
            setTtrimsitemdata([]);
          }
        }).catch(err =>{
            setTtrimsitemdata([]);
        })
    
      }
      const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {    setTimeout(() => searchInput.current.select());   }
        },
        render: text =>
          text ?(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) :text
          )
          : null
         
      });
    
  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }

      const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        
        {
          title: 'Item Name',
          dataIndex: 'itemName',
        },
        // {
        //   title: 'Trim Name',
        //   dataIndex: 'trimName',
        // },
        {
            title: 'No.of.Trims',
            dataIndex: 'noOfTrims',
          },
          {
            title:'Against To',
            dataIndex:'againstTo'
          }
      ];
      return(
        <>
        <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 ,textAlign:'center'}} title={<span>Trims Item Mapping Details</span>}></Card>
      <Table
          key={props.trimId}
          className="components-table-nested"
          columns={columnsSkelton}
          dataSource={trimsitemdata}
          pagination={false}
      />
        </>
      );


 }

