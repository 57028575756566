import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
    FactoriesInput,
    PlantInvoiceDetailsModel,
    PlantInvoiceDetailsRequest,
    UnitsOfWeightInput,

} from '@gtpl/shared-models/sale-management';
const toWords = new ToWords({
    localeCode: 'en-US',
});
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PrinterOutlined } from '@ant-design/icons'
let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import Item from 'antd/lib/list/Item';
import { ToWords } from 'to-words';

export interface BillOfLadingProps {
    saleOrderId: number;
}

export function BillOfLading(
    props: BillOfLadingProps
) {
    let location = useLocation();
    const salOrderService = new SaleOrderService();
    const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
    const soId = location.state;

    console.log(location.state);
    let totalCases = 0;
    let totalLbWeight = 0;
    let totalGrossWeight = 0;
    let totalAmount = 0;

    const printOrder = () => {
        const printableElements = document.getElementById('printme').innerHTML;
        const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
        const oldPage = document.body.innerHTML;
        document.body.innerHTML = orderHTML;
        // document.body.innerHTML = oldPage
        window.print();

    }

    const getData = (saleOrderId) => {
        // const reqObj = new PlantInvoiceDetailsRequest(1);
        salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(props.saleOrderId)).then((res) => {
            console.log(res.data);
            if (res.status) {
                console.log(res.data);
                setPlantDetails(res.data);
            } else {
                if (res.intlCode) {
                    setPlantDetails(undefined);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setPlantDetails(undefined);
            });
    };

    useEffect(() => {
        if (props.saleOrderId) {
            getData(props.saleOrderId);
        }
        // window.print()
    }, [props.saleOrderId])


    let exporterDetails = ExporterDataInput;
    let exporterId = 1;
    const exporterData = exporterDetails.find(
        (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
    );

    let unitInput = FactoriesInput;
    const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
    const tableData = () => {
        const tableDataArray = [];
        for (const data of plantDetails?.saleOrderItems) {
            const tableDataArray = data;
        }
    };

    return (
        <><span style={{ alignContent: 'left', backgroundColor: 'red' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="BLDraft"
                sheet="BLDraft"
                buttonText="Get Excel" />
        </span><div>
                <html>

                    <body id="printme">
                        <h1 style={{ textAlign: 'center', fontSize: '32px' }}>BILL OF LADING</h1>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
                            <tr>
                                <td className={'ta-b'}>

                                </td>
                                <td className={'ta-b'} rowSpan={3} style={{ textAlign: 'center', fontSize: '32px' }}>
                                    &nbsp; &nbsp; DRAFT <br />
                                    BILL OF LADING
                                </td>
                                <td className={'ta-b'} rowSpan={3}>
                                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }}>
                                        <tr>
                                            <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                                VOYAGE NUMBER
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                                0FD2ME 1MA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                                BILL OF LADDING NUMBER
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                                {plantDetails?.blNO}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'}>
                                    SHIPPER
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'}>
                                    {(exporterData?.name) ? exporterData?.name : ''}<br />
                                    {(exporterData?.addressOne) ? exporterData?.addressOne : ''}, <br />
                                    {(exporterData?.addressTwo) ? exporterData?.addressTwo : ''}, <br />
                                    {exporterData?.state + ',' + exporterData?.country}
                                </td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }}>
                            <tr>
                                <td className={'ta-b'}>
                                    COSIGNEE
                                </td>
                                <td className={'ta-b'}>
                                    EXPORT REFERENCES
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'}>
                                    {plantDetails?.saleOrderItems[0].customeraddress}
                                    {/* TO THE ORDER OF <br/>
    SANDHYA AQUA EXPORTS PVT LTD.<br/>
    MIG-25A, LAWSONS BAY COLONY,<br/>
    VISAKHAPATNAM - 530017,<br/>
    ANDHRA PRADESH, INDIA. */}
                                </td>
                                <td className={'ta-b'} rowSpan={3}>
                                    <hr style={{ width: '100%' }} /><br />
                                    (logo)<br />
                                    <u style={{ fontWeight: 'bold' }}>CARRIER:</u>&nbsp;CMA CGM Societe Anonymous au Capital de 234 988 330 Euros<br />
                                    Head Office: 4 quai d'Arenc - 13002 Marseille France<br />
                                    &nbsp;Tel: (33) 4 88 91 90 00 - Fax: (33) 4 88 91 90 95<br />
                                    &nbsp;&nbsp;562 024 422 R.C.S. Marseille

                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'}>
                                    NOTIFY PARTY, Carrier not to be responsible for failure to notify
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'}>
                                    {plantDetails?.saleOrderItems[0].deliveryaddress}
                                </td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed', borderBottom: '0px' }} id="table-to-xls">
                            <tr>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    PRE CARRIAGE BY*
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    PLACE OF RECEIPT*
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    FREIGHT TO BE PAID AT*
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    NUMBER OF ORIGINAL BILLS OF LADING
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    Road
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>

                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    VISAKHAPATNAM
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    THREE (3)
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    VESSEL
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    PORT OF LOADING
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    PORT OF DISCHARGE
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    FINAL PLACE OF DELIVERY*
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    {plantDetails?.vesselName}
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    VISAKHAPATNAM, INDIA
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    LOS ANGELES
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    {plantDetails?.saleOrderItems[0].destinationDetails}
                                </td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
                            <tr>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    MARKS AND NOS<br />
                                    <hr style={{ width: '100%' }} />
                                    CONTAINER AND SEALS
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    NO AND KIND<br />
                                    <hr style={{ width: '100%' }} />
                                    OF PACKAGES
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }} colSpan={2}>
                                    DESCRIPTION OF PACKAGES AND GOODS AS STATED BY SHIPPER<br />
                                    &nbsp;SHIPPER'S LOAD STOW AND COUNT  SAID TO CONTAIN
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    GROSS WEIGHT<br />
                                    &nbsp;&nbsp;CARGO
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    RATE
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    MEASUREMENT
                                </td>
                            </tr>
                            {plantDetails?.saleOrderItems.map(item => {
                                console.log(item);
                                totalCases += item.cases;
                                const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                                let finalNetWeight;
                                if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                                    finalNetWeight = item.netWeight;
                                } else {
                                    finalNetWeight = item.netWeight * 2.20462;
                                }
                                totalLbWeight += Number(finalNetWeight);
                                totalGrossWeight += Number(item.grossWeight);
                                totalAmount += Number(item.unitPrice) * Number(finalNetWeight);
                                return (
                                    <tr>
                                        <td className={'ta-b'} style={{ textAlign: 'center' }} colSpan={2}>{item.pouches + ' * ' + Number(item.pouchWeight) + ' ' + uom.name}</td>
                                        <td className={'ta-b'} colSpan={2}>{item.foodTypeName + '  ' + item.valueAdditionTwoIdName + '  ' + item.valueAdditionThreeIdName + '  ' + item.itemName + ',' + item.valueAdditionFourIdName}</td>
                                        <td style={{ textAlign: 'right' }} className={'ta-b'}>{Number(finalNetWeight)}</td>
                                        <td style={{ textAlign: 'right' }} className={'ta-b'}>{(item.unitPrice)}</td>
                                        <td style={{ textAlign: 'right' }} className={'ta-b'}>{(item.unitPrice * finalNetWeight).toFixed(2)}</td>
                                    </tr>
                                );
                            }
                            )}
                            <tr>
                                <td colSpan={4} className={'ta-b'} style={{ textAlign: 'center' }}>
                                    Total
                                </td>
                                <td style={{ textAlign: 'right' }} className={'ta-b'}>
                                    <h4>{totalLbWeight}</h4>
                                </td>
                                <td className={'ta-b'}></td>
                                <td style={{ textAlign: 'right' }} className={'ta-b'}>{totalAmount.toFixed()}</td>
                            </tr>

                            <tr>
                                <td colSpan={7} className={'ta-b'}>
                                    <b>Amount chargeable </b><br /> (in words):  <b> U.S {(totalAmount) ? (toWords.convert(totalAmount, { currency: true }).toUpperCase()) : ""}</b>
                                </td>
                                {/* <td className={'ta-b'}>
      <h4>Total INR</h4>
    </td>
    <td style={{ textAlign: 'right' }} className={'ta-b'}>
      <h4>{plantDetails?.total_INR_amount}</h4>
    </td> */}
                            </tr>

                            <tr>
                                <td className={'ta-b'} colSpan={2}></td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    <h4>IN LBS</h4>
                                </td>
                                <td className={'ta-b'} style={{ textAlign: 'center' }}>
                                    <h4>IN KGS</h4>
                                </td>
                                <td className={'ta-b'} colSpan={3}></td>
                            </tr>
                            <tr>
                                <td colSpan={2} className={'ta-b'}>
                                    {' '}
                                    TOTAL NET WEIGHT:{' '}
                                </td>
                                <td style={{ textAlign: 'right' }} className={'ta-b'}>
                                    {/* <h4>{totalLbWeight}</h4> */}
                                    {/* {plantDetails?.saleOrderItems?.map(item => {return (<h4>{Number(item.netWeight).toFixed(3)}</h4>)})} */}
                                    {plantDetails?.saleOrderItems?.map(item => { return (<h4>{(Math.trunc(item.netWeight)).toFixed(3)}</h4>) })}
                                </td>
                                <td style={{ textAlign: 'right' }} className={'ta-b'}>
                                    {/* <h4>{(totalLbWeight).toFixed(3)}</h4> */}
                                    <h4>{(Math.trunc(totalLbWeight)).toFixed(3)}</h4>
                                </td>
                                <td className={'ta-b'} colSpan={2}>

                                </td>
                                <td className={'ta-b'} colSpan={1} style={{ textAlign: 'right' }}>
                                    <h4></h4>
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={2} className={'ta-b'}>
                                    {' '}
                                    TOTAL GROSS WEIGHT:
                                </td>
                                <td style={{ textAlign: 'right', width: "20%", borderTop: '0px' }} className={'ta-b'}>
                                    {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                                    <h4>{((Math.round((plantDetails?.saleOrderItems[0]?.grossWeightPerKg) * (plantDetails?.saleOrderItems[0]?.invoicedCases))) / 0.454).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                                </td>
                                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px' }} className={'ta-b'}>
                                    <h4>{Math.round((plantDetails?.saleOrderItems[0]?.grossWeightPerKg) * (plantDetails?.saleOrderItems[0]?.invoicedCases)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} </h4>
                                </td>
                                <td className={'ta-b'} colSpan={2}>
                                    TOTAL AMOUNT (IN {plantDetails?.saleOrderItems[0].currencyDetails}):
                                </td>
                                <td style={{ textAlign: 'right' }} colSpan={1}>
                                    <h4>{totalAmount.toFixed()}</h4>
                                </td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
                            <tr>
                                <td style={{ textAlign: 'center', border: '1px' }}> ADDITIONAL CLAUSES</td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed', fontSize: '10px' }} id="table-to-xls">
                            <tr>
                                <td className={'ta-b'}>
                                    4. Cargo at port is at merchant risk, expenses and responsibility<br />
                                    5. FCL<br />
                                    77. THC at destination payable by Merchant as per line/port tariff<br />
                                    91. Ground rent/storages/power sypply/monitoring costs at port of discharge for Merchant's account according to port rates<br />
                                    92. Reefer container can only be operated by electrical power. During land transportation the Carrier will not be liable in any respect whatsoever for consequences, due to non refrigeration.<br />
                                    191. Unless the value of cargo is declared on the face of this bill of lading or waybill in the conditions set for on the reverse, limitation of liability in respect of loss or damage to goods shall not exceed US$ 500 per package, or customory freight unit if goods are not shipped in package.<br />
                                    194. For the purpose of the present carriage, clause 14(2) shall exclude the application of the York/Antiwerp rules, 2004.<br />
                                    214. U.S. demurrage and detention conditions are billed per CMA-CGM (America's) U.S. tariff or service
                                </td>
                                <td className={'ta-b'}>
                                    contracts filed with the FMC<br />
                                    216. Mis-declaration of cargo weight endangers crew, port workers and vessels' safety. Your cargo may be weighed at any place and time of carriage and any mis-declaration will expose you to claims for all
                                    losses, expenses or damages whatsoever resulting thereof and be subject to freight surcharge.<br />
                                    225. The shipper acknowledges that the Carrier may carry the goods identified in this bill of lading on the deck of any vessel and in taking remittance of this bill of lading the Merchant (including the shipper, the consignee and the holder of the bill of lading, as the case may be) confirms his express acceptance of all <br />
                                    the terms and conditions of this bill of lading and expressly confirms his unconditional and irrevocable consent to the possible carriage of the goods on the deck of any vessel.<br />
                                    239. This Bill of Lading has been generated electronically. Bills of Lading bearing a CMA CGM stamp and/or manual signature shall be considered as forged and will be treated as null.<br />
                                    274. The Merchant is responsible for returning any empty container, with interior clean, free of any dangerous goods placards, labels or markings, at the designated place, and within 60 days following to the date of release, failing which the container shall be construed as lost.The Merchant shall be liable to <br />
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} colSpan={2} style={{ fontSize: '13px' }}>
                                    RECEIVED by the carrier from the shipper in apparent good order and condition (unless otherwise noted herein) the total number or quantity of Containers or other packages or units
                                    indicated above stated by the shipper to comprise the cargo specified above for transportation subject to all the terms hereof (including the terms on page one) from the place of receipt or the
                                    port of loading, whichever is applicable, to the port of discharge or the place of delivery, whichever is applicable. Delivery of the Goods will only be made on payment of all Freight and
                                    charges. On presentation of this document (duly endorsed) to the Carrier, by or on behalf of the holder, the rights and liabilities arising in accordance with the terms hereof shall (without
                                    prejudice to any rule of common law or statutes rendering them binding upon the shipper, holder and carrier) become binding in all respects between the Carrier and Holder as though the
                                    contract contained herein or evidenced hereby had been made between them.<br />
                                    <b>All claims and actions arising between the Carrier and the Merchant in relation with the contract of Carriage evidenced by this Bill of Lading shall exclusively be brought before the Tribunal de Commerce de
                                        Marseille and no other Court shall have jurisdiction with regards to any such claim or action. Notwithstanding the above, the Carrier is also entitled to bring the claim or action before the Court of the place
                                        where the defendant has his registered office.</b><br />
                                    In witness whereof three (3) original Bills of Lading, unless otherwise stated above, have been issued, one of which being accomplished, the others to be void.
                                    <br /><b>(OTHER TERMS AND CONDITIONS OF THE CONTRACT ON PAGE ONE)</b>
                                </td>
                            </tr>
                        </table>
                        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
                            <tr>
                                <td className={'ta-b'}>PLACE AND DATE OF ISSUE</td>
                                <td className={'ta-b'}>MUMBAI {plantDetails?.invoiceDate}</td>
                                <td className={'ta-b'} rowSpan={3}>SIGNED FOR THE CARRIER CMA CGM S.A.<br />
                                    BY CMA CGM Agencies (India) Pvt. Ltd.<br />
                                    as agents for the carrier CMA CGM S. A.<br />
                                    <hr style={{ width: '70%' }} />
                                </td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} colSpan={2}>&nbsp;SIGNED FOR THE SHIPPER</td>
                            </tr>
                            <tr>
                                <td className={'ta-b'} colSpan={2}>&nbsp; *APPLICABLE ONLY WHEN THIS DOCUMENT IS USED AS A COMBINED TRANSPORT BILL OF LADING</td>
                            </tr>
                        </table>
                    </body>
                </html>
            </div></>
    )

}

export default BillOfLading;