import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Drawer, Form, Row, Col, Alert, Select, Anchor, Spin, Radio, Tabs, Popconfirm, Switch, Divider, Input, Modal, Empty } from 'antd';
import Table, { ColumnProps, ColumnsType } from 'antd/lib/table';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { DocumentsEnum, DocumentsUploadData } from '@gtpl/shared-models/logistics';
import { AlertMessages } from '../../../../../../shared-utils/alert-messages/src';
import { RightSquareOutlined, SearchOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import './so-inspection-grid.css';
import { GlobalStatus } from '@gtpl/shared-models/common-models';
import { SoAllInspectionsDto } from '@gtpl/shared-models/sale-management';
import SgsTuvForm from './sgs-tuv-form';
import moment from 'moment';

/* eslint-disable-next-line */
export interface SoInspectionGridProps { }

export function SoInspectionGrid(props: SoInspectionGridProps) {
  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
  ];
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [defaultTabPane, setDefaultTabPane] = useState<string>("1")
  const saleOrderService = new SaleOrderService;
  const [inspectionData, setInspectionData] = useState<SoAllInspectionsDto[]>([]);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select(), 100); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
          // searchText
        ) : text
      )
        : null


  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    getAllSoInspectionData();
  }, [])

  const getAllSoInspectionData = async () => {
    saleOrderService.getAllSoInspections().then(res => {
      if (res.status) {
        console.log(res.data);
        setInspectionData(res.data);
      } else {
        if (res.intlCode) {
          setInspectionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setInspectionData([]);
    })
  }

  const updateSoInspection = (soInspectionId: number, saleOrderId: number, inspectionName: string, isChecked: GlobalStatus, key) => {
    console.log(soInspectionId);
    console.log(saleOrderId);
    console.log(inspectionName);
    console.log(isChecked);
    saleOrderService.updateSoInspectionInfo({ soInspectionId, saleOrderId, inspectionName, isChecked }).then(res => {
      if (res.status) {
        console.log(res.data);
        getAllSoInspectionData();
        AlertMessages.getSuccessMessage(res.internalMessage);
        // setDocumentationData(res.data);

      } else {
        if (res.intlCode) {
          setInspectionData([]);

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setInspectionData([]);
    })
    if (key === "inspectionPending") {
      setDefaultTabPane("1");
    } else if (key === "inspectionCompleted") {
      setDefaultTabPane("2");
    }
    // else if(key==="loadInspection"){
    //   setDefaultTabPane("3");
    // }
  }

  const columns = (key: string) => {
    console.log(key);

    const StatusColumns: ColumnProps<any>[] = [
      {
        title: 'SNo',
        dataIndex: 'saleId',
        // width: '180',
        // fixed: 'left',
        align: 'left',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)

      },
      {
        title: 'Customer PO',
        dataIndex: 'poNumber',
        key: 'saleOrderNumber',
        align: 'left',
        // width: 180,
        // render:(value, record: SoAllInspectionsDto, index)=>{
        //   return (record.saleOrderInfo.saleOrderNumber) ? (record.saleOrderInfo.saleOrderNumber): '-'
        // },
        sorter: (a, b) => a.saleOrderId - b.saleOrderId,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('saleOrderNumber'),
      },
      {
        title: 'SGS TUV',
        key: 'isSgsTuv',
        dataIndex: 'isSgsTuv',
        align: 'center',
        // width:'160',
        render: (text, rowData, index) => (
          (rowData.isSgsTuv) ?
            <CheckOutlined style={{ color: 'green', fontSize: '24px', }} />
            : <CloseOutlined style={{ color: 'red', fontSize: '24px', }} onClick={() => openModel(rowData.saleOrderId)} />
        ),
      },
      {
        title: 'SGS Inspection',
        key: 'sgsInspectionStatus',
        dataIndex: 'sgsInspectionStatus',
        align: 'center',
        // width:'160',
        render: (text, rowData, index) => (
          ((rowData.sgsInspectionStatus != "-") && rowData.isSgsTuv) ?
            <Radio.Group
              options={options}
              onChange={(e) => updateSoInspection(rowData.soInspectionId, rowData.saleOrderId, 'SGS Inspection', e.target.value, key)}
              value={rowData.sgsInspectionStatus != "" ? rowData.sgsInspectionStatus : "-"}
              optionType="button"
              buttonStyle="solid"
              style={{ display: 'contents' }}
              size='middle' />
            : '-'
        ),
      },
      {
        title: 'TUV Inspection',
        key: 'tuvInspectionStatus',
        dataIndex: 'tuvInspectionStatus',
        align: 'center',
        // width:'160',
        render: (text, rowData, index) => (
          (rowData.tuvInspectionStatus != "-" && rowData.isSgsTuv) ?
            <Radio.Group
              options={options}
              onChange={(e) => updateSoInspection(rowData.soInspectionId, rowData.saleOrderId, 'TUV Inspection', e.target.value, key)}
              value={rowData.tuvInspectionStatus != "" ? rowData.tuvInspectionStatus : "-"}
              optionType="button"
              buttonStyle="solid"
              style={{ display: 'contents' }}
              size='middle' />
            : '-'
        ),
      },
      {
        title: 'Surveyer Load Inspection',
        key: 'loadInspectionStatus',
        dataIndex: 'loadInspectionStatus',
        align: 'center',
        // width:'160',
        render: (text, rowData, index) => (
          (rowData.loadInspectionStatus != "-" && rowData.isSgsTuv) ?
            <Radio.Group
              options={options}
              onChange={(e) => updateSoInspection(rowData.soInspectionId, rowData.saleOrderId, 'Load Inspection', e.target.value, key)}
              value={rowData.loadInspectionStatus != "" ? rowData.loadInspectionStatus : "-"}
              optionType="button"
              buttonStyle="solid"
              style={{ display: 'contents' }}
              size='middle' />
            : '-'
        ),
      },
      // {
      //   title: 'Check',
      //   key: 'isChecked',
      //   dataIndex: 'isChecked',
      //   align:'center',
      //   width:'160',
      //   render: (text, rowData,index) => ( 
      //     <Radio.Group
      //       options={options}
      //       onChange={(e)=>updateSoInspection(rowData.soInspectionId,rowData.saleOrderId,rowData.inspectionName,e.target.value,key)}
      //       value={rowData.isChecked}
      //       optionType="button"
      //       buttonStyle="solid"
      //       style={{ display: 'contents'}}
      //       size='middle'   />
      //   ),
      // },  
      // {
      //   title: 'Is Checked?',
      //   key: 'isChecked',
      //   dataIndex: 'isChecked',
      //   align:'center',
      //   width:'160',
      //   render: (text, rowData) => (<>{rowData.isChecked===GlobalStatus.YES?'Yes':'No'}</>)
      // },  
    ];
    // const StatusColumns1:ColumnProps<any>[] = [

    //   {
    //     title: 'TUV Inspection',
    //     key: 'inspectionName',
    //     dataIndex: 'inspectionName',
    //     align:'center',
    //     width:'160',
    //     render: (text, rowData,index) => ( 
    //       (rowData.inspectionName== 'TUV Inspection')?
    //       <Radio.Group
    //         options={options}
    //         onChange={(e)=>updateSoInspection(rowData.soInspectionId,rowData.saleOrderId,rowData.inspectionName,e.target.value,key)}
    //         value={(rowData.inspectionName== 'TUV Inspection')?(rowData.isChecked):'-'}
    //         optionType="button"
    //         buttonStyle="solid"
    //         style={{ display: 'contents'}}
    //         size='middle'   />:'-'
    //     ),
    //   },  
    //   // {
    //   //   title: 'Check',
    //   //   key: 'isChecked',
    //   //   dataIndex: 'isChecked',
    //   //   align:'center',
    //   //   width:'160',
    //   //   render: (text, rowData,index) => ( 
    //   //     <Radio.Group
    //   //       options={options}
    //   //       onChange={(e)=>updateSoInspection(rowData.soInspectionId,rowData.saleOrderId,rowData.inspectionName,e.target.value,key)}
    //   //       value={rowData.isChecked}
    //   //       optionType="button"
    //   //       buttonStyle="solid"
    //   //       style={{ display: 'contents'}}
    //   //       size='middle'   />
    //   //   ),
    //   // },  
    //   // {
    //   //   title: 'Is Checked?',
    //   //   key: 'isChecked',
    //   //   dataIndex: 'isChecked',
    //   //   align:'center',
    //   //   width:'160',
    //   //   render: (text, rowData) => (<>{rowData.isChecked===GlobalStatus.YES?'Yes':'No'}</>)
    //   // },  
    // ];
    if (key === "inspectionPending") {
      console.log('inspectionPending')

      return [...StatusColumns];
    }
    else if (key === "inspectionCompleted") {
      console.log('inspectionCompleted')
      return [...StatusColumns];
    }
    // else if(key === "loadInspection"){
    //   console.log('loadInspection')
    //   return [...StatusColumns];
    // }
    else {
      return StatusColumns;
    }
  }

  const openModel = (saleOrderId) => {
    setIsModalVisible(true);
    setSelectedSaleOrder(saleOrderId);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedSaleOrder(undefined);
    getAllSoInspectionData();
  }

  function callback(key) {
    console.log(key);
  }

  const sgsTuvColumns: ColumnProps<any>[] = [
    {
      title: 'SNo',
      dataIndex: 'saleId',
      // width: '180',
      // fixed: 'left',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },
    {
      title: 'Shipment Date',
      dataIndex: 'shipmentDate',
      key: 'shipmentDate',
      render: (value) => {
        return moment(value).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Inititaion Date',
      dataIndex: 'initiationDate',
      key: 'initiationDate',
      render: (value) => {
        return moment(value).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Planned Date',
      dataIndex: 'plannedDate',
      key: 'plannedDate',
      render: (value) => {
        return moment(value).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Actual Date',
      dataIndex: 'actualDate',
      key: 'actualDate',
      render: (value) => {
        return moment(value).format('DD-MM-YYYY');
      }
    },

    {
      title: 'Expected Result Date',
      dataIndex: 'expectedResultDate',
      key: 'expectedResultDate',
      render: (value) => {
        return moment(value).format('DD-MM-YYYY');
      }
    },

  ]

  const SgsTuvTable = (record) => {
    return (record.isSgsTuv) ? <>
       <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: 'white', height: 40, margin: 1 ,textAlign:'center'}} title={<span></span>}></Card>
     <Table
      rowKey={record => record.soInspectionId}
      columns={sgsTuvColumns}
      dataSource={[record]}
      className="components-table-nested"
      // pagination={false}
      // scroll={{ x: true }}
      bordered /> </> : <Empty />
  }


  return (
    <><Tabs type={'card'} tabPosition={'top'} onChange={callback} defaultActiveKey={defaultTabPane}>
      <TabPane key="1" tab={<span style={{ color: "#fa8c16" }}>{'Inspection Pending: '
        + (inspectionData.filter(rec => rec.tuvInspectionStatus === "NO" || rec.sgsInspectionStatus === "NO" || rec.loadInspectionStatus === "NO")).length}</span>}>
        <Table
          rowKey={record => record.soInspectionId}
          columns={inspectionData.length == 0 ? null : columns("inspectionPending")}
          dataSource={inspectionData.filter(rec => rec.tuvInspectionStatus === "NO" || rec.sgsInspectionStatus === "NO" || rec.loadInspectionStatus === "NO")}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          expandable={{
            expandedRowRender: record => {
              return SgsTuvTable(record)
            },
            rowExpandable: record => record.isSgsTuv,
          }}
          expandIconColumnIndex={6}
          // size="large"
          scroll={{ x: true }}
          bordered />
      </TabPane>
      <TabPane key="2" tab={<span style={{ color: "#1890ff" }}>{'Inspection Completed: '
        + (inspectionData.filter(rec => (rec.tuvInspectionStatus === "YES" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "YES") || (rec.tuvInspectionStatus === "YES" && rec.loadInspectionStatus === "YES" && rec.sgsInspectionStatus == "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "YES") || (rec.tuvInspectionStatus == "YES" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "YES" && rec.sgsInspectionStatus === "-" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "-" && rec.loadInspectionStatus === "YES"))).length}</span>}>
        <Table
          rowKey={record => record.soInspectionId}
          columns={inspectionData.length == 0 ? null : columns("inspectionCompleted")}
          dataSource={inspectionData.filter(rec => (rec.tuvInspectionStatus === "YES" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "YES") || (rec.tuvInspectionStatus === "YES" && rec.loadInspectionStatus === "YES" && rec.sgsInspectionStatus == "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "YES") || (rec.tuvInspectionStatus == "YES" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "YES" && rec.sgsInspectionStatus === "-" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "YES" && rec.loadInspectionStatus === "-") || (rec.tuvInspectionStatus == "-" && rec.sgsInspectionStatus === "-" && rec.loadInspectionStatus === "YES"))}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          expandable={{
            expandedRowRender: record => {
              return SgsTuvTable(record)
            },
            rowExpandable: record => record.isSgsTuv,
          }}
          expandIconColumnIndex={6}
          // size="large"
          scroll={{ x: true }}
          bordered
        />
      </TabPane>
      {/* <TabPane key="3" tab={<span style={{ color: "#f5222d" }}>{'Load Inspection: ' + (inspectionData.filter(rec=> rec.inspectionName== 'Load Inspection').length)}</span>}>
  <Table
  rowKey={record => record.soInspectionId}
  columns={StatusColumns}
  dataSource={inspectionData.filter(rec=> rec.inspectionName== 'Load Inspection')}
  pagination={{
    onChange(current) {
      setPage(current);
    }
  }}
  // size="large"
  scroll={{ x:true }}
  bordered/>
   </TabPane> */}
    </Tabs>
      <Modal width={'80%'} style={{ top: 30, alignContent: 'right' }} title={<React.Fragment>
      </React.Fragment>} visible={isModalVisible} onCancel={handleCancel} footer={[

      ]}>
        <SgsTuvForm saleOrderId={selectedSaleOrder} handleCancel={handleCancel} />
      </Modal>
    </>
  );
}

export default SoInspectionGrid;
