import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {TransferActionForm} from '@gtpl/pages/hrms/hrms-components/transfer-action-form';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { TransferActionDto } from '@gtpl/shared-models/hrms';
import { TransferActionService } from '@gtpl/shared-services/hrms';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';

export interface TransferActionGridProps {}

export function TransferActionGrid(props: TransferActionGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [transferActionData, setTransferActionData] = useState<TransferActionDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedtransferActionsData, setSelectedTransferActionData] = useState<any>(undefined);
  const transferActionService = new TransferActionService();
  const [allData, setAllData] = useState<TransferActionDto[]>([]);


  useEffect(() => {
    getAllTransferActions();
    // transferActionData.forEach((suggestion) => {
    //   deptService.getDeptDataById(suggestion.departmentId).then(res => {
    //     res.data.map((details)=> {
    //       suggestion.departmentName = details.deptName;
    //     })
    //   })
    // })
  },[]);

  /**
   * 
   */
  const getAllTransferActions = () => {
    transferActionService.getAllTransferActions().then(res => {
      if(res.status){
        setTransferActionData(res.data);
        setAllData(res.data);
      }else {
        setAllData([]);
        if(res.intlCode){
          setTransferActionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTransferActionData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    }) 

    
  } 

  /**
   * 
   * @param transferActionData 
   */
   const deleteTransferAction = (transferActionData:TransferActionDto) => {
    transferActionData.isActive=transferActionData.isActive?false:true;
    transferActionService.activateOrDeactivateTransferAction(transferActionData).then(res => { console.log(res);
      if (res.status) {
        getAllTransferActions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
   const updateTransferAction = (Data: TransferActionDto) => {
    transferActionService.updateTransferAction(Data).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllTransferActions();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

 
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',

      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Date',
      dataIndex: 'transferDate',
      render: (text, record) => { return moment(record.transferDate).format('YYYY-MM-DD') },
      sorter: (a, b) => a.transferDate?.localeCompare(b.transferDate),
      sortDirections: ['descend', 'ascend'],
    },
	{
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      width: '150px',

      sorter: (a, b) => a.employeeCode.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeCode')
    },

    {
      title: 'Employee Name',
      dataIndex: 'employeeName',

      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
	{
      title: 'Designation',
      dataIndex: 'designation',

      sorter: (a, b) => a.designation?.localeCompare(b.designation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('designation')
    },
    {
      title: 'Approved By',
      dataIndex: 'approvedBy',

      sorter: (a, b) => a.approvedBy?.localeCompare(b.approvedBy),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('approvedBy')
    },
    {
      title: 'From Plant',
      dataIndex: 'fromPlant',
  
      sorter: (a, b) => a.fromPlant?.localeCompare(b.fromPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromPlant')
    },
    {
      title: 'To Plant',
      dataIndex: 'toPlant',

      sorter: (a, b) => a.toPlant?.localeCompare(b.toPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toPlant')
    },
    //  {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
    //     </>
    //   ),
    //   filters: true,
    //   onFilter: true,
    //   valueEnum: {
    //     true: { text: 'Active', status: 'Success' },
    //     false: { text: 'InActive', status: 'Error' }
    //   }      
    // },
    {
      title:`Action`,
      dataIndex: 'action',

      render: (text, rowData) => (
        <span> 
            <Tooltip placement="top" title='Edit'>       
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
             onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Transfer Action');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          {/* <Divider type="vertical" />
            <Popconfirm    onConfirm={e =>{deleteTransferAction(rowData);}  }      
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate TransferAction ?'
                :  'Are you sure to Activate TransferAction  ?'
            }
          >
            
          </Popconfirm> */}
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }

  const openFormWithData=(viewData: TransferActionDto)=>{
    setDrawerVisible(true);
    setSelectedTransferActionData(viewData);
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>TransferAction</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/transfer-action-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <br></br>
     <Row gutter={40}>
      </Row>
          <br></br>
          <Table
          rowKey={record => record.transferActionsId}
          columns={columnsSkelton}
          dataSource={transferActionData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <TransferActionForm key={Date.now()} 
                Data={selectedtransferActionsData} 
                updateTransferAction={updateTransferAction}             
                isUpdate={true}             
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default TransferActionGrid;




