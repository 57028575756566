import React, { useEffect, useState } from 'react';

import './resource-operation-efficiency-form.css';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber, Table } from 'antd';
import { Link, useHistory } from "react-router-dom";
import {SkuService} from '@gtpl/shared-services/masters';
import { SkuDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ResourceOperationEfficiencyDto, VariantEfficiencyRequest} from '@gtpl/shared-models/planning'
import { ResourceOpEfficiencyService } from '@gtpl/shared-services/planning';



const {Option} = Select


/* eslint-disable-next-line */
export interface ResourceOperationEfficiencyFormProps {
  resourceOpEfficiency: ResourceOperationEfficiencyDto;
  isUpdate: boolean;
  closeForm: () => void;
  updateResourceOpEfficiency: (opEfficiency: ResourceOperationEfficiencyDto) => void;
}

export function ResourceOperationEfficiencyForm(
  props: ResourceOperationEfficiencyFormProps
) {
  const skuService = new SkuService();
  const [form] = Form.useForm()
  const [skucodes,setSkuCode] = useState<SkuDto[]>([])
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [tableData,setTableData] = useState<ResourceOperationEfficiencyDto[]>([
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:0,
      maxCount:20,
      efficiency:null,
      createdUser:'',
    },
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:21,
      maxCount:40,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:41,
      maxCount:60,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:61,
      maxCount:80,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:81,
      maxCount:100,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'De Haeding',
      variantCode:null,
      minCount:101,
      maxCount:300,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:0,
      maxCount:20,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:21,
      maxCount:40,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:41,
      maxCount:60,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:61,
      maxCount:80,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:81,
      maxCount:100,
      efficiency:null,
      createdUser:''
    },
    {
      operation:'Value Addition',
      variantCode:null,
      minCount:101,
      maxCount:300,
      efficiency:null,
      createdUser:''
    }
  ])
  const [showTable, setShowTable] = useState<boolean>(false);

  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  // let unitId = "";
  // if (!props.isUpdate) {
  //   unitId = localStorage.getItem("unit_id");
  // }

  useEffect(()=>{
    getSkuCodes();

  },[])

  useEffect(()=>{
    console.log(tableData);

  },[tableData])

  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkuCode(res.data);
      }
      else {
        setSkuCode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkuCode([]);
      });
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values) => {
    console.log(props.isUpdate)
    setBtnDisable(true)
    if (props.isUpdate) {
      console.log(values);
      updateResourceOpEfficiency(tableData);
      // props.updatePoduct(values);
    } else {
      let effFlag:boolean[] = []
      tableData.forEach(element => {
        console.log(element)
        if(element.efficiency > 0){
          effFlag.push(true)
          console.log(effFlag)
        }else{
          effFlag.push(false)
          console.log(effFlag)

        }

      });
      if(effFlag.includes(false)){
      }else{
        console.log(tableData);
        createResourceOpEfficiency(tableData);
      }

    }

  };

  const resourceOpEffService = new ResourceOpEfficiencyService()

  const createResourceOpEfficiency = (tableData: ResourceOperationEfficiencyDto[]) => {

    resourceOpEffService.createResourceOperationEfficiency(tableData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Resource Operation Efficiency Created Successfully');
        setBtnDisable(false)

        // history.push("/resource-op-efficiency-view");
        // onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateResourceOpEfficiency = (tableData: ResourceOperationEfficiencyDto[]) => {

    resourceOpEffService.updateResourceOperationEfficiency(tableData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Resource Operation Efficiency Updated Successfully');
        setBtnDisable(false)
        // history.push("/resource-op-efficiency-view");
        // onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBtnDisable(false)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getEfficiencyData = (val) => {
    console.log(val)
    // setTableData([]);
    resourceOpEffService.getEfficiencyByVariant(new VariantEfficiencyRequest(val)).then(res => {
      if (res.status) {
        setTableData(res.data)
        setShowTable(true)
        setBtnChange(true);
        props.isUpdate=true;
      } else {
        setBtnChange(false);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const onSearch = () => {

  }

  const storeEfficiency = (e, index,data) => {
    console.log(e)
    console.log(index)
    console.log(data)
    const dynTableData = [...tableData];
    dynTableData[index].efficiency = e
    dynTableData[index].variantCode = form.getFieldValue('variantCode')
    dynTableData[index].createdUser = JSON.parse(localStorage.getItem('username'));
    setTableData(dynTableData);
  }

  const columns = [
    
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      colspan:5
    },
    {
      title: 'Count Range',
      dataIndex: 'minCount',
      key: 'minCount',
      render: (text, data, index) => {
        return <span>{`${text} - ${data.maxCount} `}</span>
      }
    },
    {
      title: 'Efficiency',
      dataIndex: 'efficiency',
      render: (text, data, index) => {
        return <span><InputNumber min={0} defaultValue={text} onChange={e => storeEfficiency(e, index,data)} /></span>
        // return <span>{data.brandId.children}</span>
    }
    },

  ]
  
  return (
    <Card title={<span style={{ color: 'white' }}>Resource Operation Efficicency</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/resource-op-efficiency-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}  name="control-hooks" onFinish={saveData}
        layout="vertical"
      >
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        {/* <Form.Item style={{ display: "none" }} name="unitId" initialValue={unitId}>
          <Input hidden />
        </Form.Item> */}

        <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}} >
            <Form.Item
              name="variantCode"
              label="Variant Code"
              rules={[
                {
                  required: true,
                  message: 'Variant Code Required',

                },
                
              ]}>
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Variant Code"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={getEfficiencyData}
              >
               {skucodes.map(skudropData => {
                      return <Option label={'varientId'} key={skudropData.variantCode} value={skudropData.variantCode}>{skudropData.variantCode}</Option>
                    })}
              </Select>
            </Form.Item>
          </Col>
         
        </Row>
        
        {
          showTable ?
        <Card>
        <Table  dataSource={tableData} columns={columns}  pagination={false} />
        </Card>
        :<></>
        }
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary"  htmlType='submit'>
              {btnChange?'Update':'Create'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ResourceOperationEfficiencyForm;
