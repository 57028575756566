import React, { useEffect, useRef, useState } from 'react';
import './container-loading-report.css';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Descriptions, Form, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Column from 'antd/lib/table/Column';
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
import { SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import {ContainerLogBookService} from '@gtpl/shared-services/logistics'
import { LoadingReportResponse, RowDataResponse } from '@gtpl/shared-models/logistics';
import moment from 'moment';
import { SaleOrderIdRequest } from '@gtpl/shared-models/warehouse-management';
import { useForm } from 'antd/lib/form/Form';


const saleService = new SaleOrderService;
const containerLogBookService = new ContainerLogBookService;

/* eslint-disable-next-line */

const varientListService = new SaleOrderService();

export interface ContainerLoadingReportProps { }

export function ContainerLoadingReport(
    props: ContainerLoadingReportProps
) {
    const { Option } = Select
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [soDropDown, setSoDropDown] = useState<any[]>([]);
    const [responseData, setResponseData] = useState<LoadingReportResponse>(undefined);
    const [reportData, setReportData] = useState<RowDataResponse[]>([]);
    const[soDropdownVisible, setsoDropdownVisible] = useState<boolean>(false);
    const service = new ContainerLogBookService();
    const saleService = new SaleOrderService;
    const [form] = useForm();


    /**
    * used for column filter
    * @param dataIndex column data index
    */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {

        getSoNoDropdown();
        //getContainerLoadingReport();

    }, []);

    const getSoNoDropdown = () => {
        const req = new PlantRequest
        req.plantId = Number(localStorage.getItem('unit_id'))
        service.getSaleOrderDropdown(req).then((res) => {
            if (res.status) {
                setSoDropDown(res.data)
            } else {
                setSoDropDown([]);
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    // const getContainerLoadingReport = () => {
    //     const req = new SaleOrderIdRequest(form.getFieldValue('saleorderId'))

    //     containerLogBookService.getContainerLoadingReport(req).then(res => {
    //         if (res.status) {
    //             setcontainerLoadingReportData(res.data);
    //         } else {
    //             if (res.intlCode) {
    //                 setcontainerLoadingReportData([]);
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             } else {
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             }
    //         }
    //     }).catch(err => {
    //         AlertMessages.getErrorMessage(err.message);
    //         setcontainerLoadingReportData([]);
    //     })
    // }
    const getVarientlist = (reqData) => {
        console.log(reqData);
        const col = [...commonColumns];
        const uniqueColumnSet = new Set<string>();
        service.getContainerLoadingReport({saleOrderId: reqData}).then((res) => {
        if (res.status) {
            console.log(res.data.logBookItemsInfo);
            setResponseData(res.data);
            res.data?.logBookItemsInfo?.forEach(element => {
                console.log(element);
                (element.productInfo).forEach(val => {
                    console.log(val);
                    if (!uniqueColumnSet.has(val.product)) {
                    const dynCol = {
                        title: val.product,
                        dataIndex: val.product,
                        // align: 'right',
                        // render: (value) => (<span style={{ minWidth:60, float: 'right' }}>{(value)}</span>)
                    }
                    col.push(dynCol);
                    }
                    element[val.product] = Number(val.cartons).toLocaleString(undefined, { maximumFractionDigits: 0 });

                    uniqueColumnSet.add(val.product);
                })
                setColumns(col);
                setKeyUpdate(preVal => preVal + 1);
            })
            const tabelData = [...res.data.logBookItemsInfo];
            console.log(tabelData);
            setReportData(tabelData);
            setsoDropdownVisible(true);
        } else {
            setReportData([])
            AlertMessages.getErrorMessage(res.internalMessage);
        }
        }).catch((err) => {
        console.log("error coming"+err)
        AlertMessages.getErrorMessage(err.message);
      });
    }

    const commonColumns: any[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },

        {
            title: 'Row',
            dataIndex: 'row',
            width:'90px',
            sorter: (a, b) => a.row - b.row,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('reason')
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width:'90px',
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('reason')
        },

    ];
    /**
        * 
        * @param pagination 
        * @param filters 
        * @param sorter 
        * @param extra 
        */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const [columns, setColumns] = useState(commonColumns);
    const [keyUpdate, setKeyUpdate] = useState<number>(0);

    const onFocus = () => {
        console.log('focus');
    }
    const onSearch = (val) => {
        console.log('search:', val);
    }
    const onBlur = () => {
        console.log('blur');
    }
    /**
        * 
        * @param variantData 
        */
    /**
      * 
      * @param VariantViewData 
      */
    return (
        <div>
            <Card title={<span style={{ color: 'black' }}>Loading Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
                <div>
                <Form form={form} >
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                            <Form.Item
                                name="saleorderId"
                                label="PO Number"
                                rules={[
                                    { required: true},
                                  ]}
                            >
                                <Select
                                    showSearch
                                    //style={{ width: 200 }}
                                    placeholder="Select PO Number"
                                    optionFilterProp="children"
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={getVarientlist}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={0} value={null}>Select PO Number</Option>
                                    {soDropDown?.map((value) => {
                                        return <Option key={value.saleOrderId} value={value.saleOrderId}>{value.poNumber}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        
                    </Col>
                </Form>
                </div>
        {soDropdownVisible ?
        <Card>
            <div>
                <Descriptions column={2}>
                    <Descriptions.Item label={"Loading Start Time"}>{responseData.loadingStartTime}</Descriptions.Item>
                    <Descriptions.Item label={"Loading End Time"}>{responseData.loadingEndTime}</Descriptions.Item>
                    <Descriptions.Item label={"Loading Date"}>{moment(responseData.loadingDate).format("YYYY-MM-DD")}</Descriptions.Item>
                </Descriptions>
            </div>
            <Table
                rowKey={record => record.row}
                columns={columns}
                dataSource={reportData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                scroll={{ x: true }}
                onChange={onChange}
                size='small'
                bordered />
            <div>
                <Descriptions column={2}>
                    <Descriptions.Item label={"Container No"}>{responseData.containerNo}</Descriptions.Item>
                    <Descriptions.Item label={""}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Vehicle No"}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Q.C.Supervisor"}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Cex Seal"}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Loading Supervisor Signature"}>{}</Descriptions.Item>
                    <Descriptions.Item label={"DMS"}>{}</Descriptions.Item>
                    <Descriptions.Item label={""}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Loading Survey"}>{}</Descriptions.Item>
                    <Descriptions.Item label={"Incharge"}>{}</Descriptions.Item>
                </Descriptions>
            </div>
            </Card>:" "}
         </Card>
        </div>
    );
}

export default ContainerLoadingReport;



