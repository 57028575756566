import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col  } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {EmploymentStatusDto} from "@gtpl/shared-models/hrms"
import './employment-status-form.css';
import {EmploymentStatService} from '@gtpl/shared-services/hrms'

 

const { TextArea } = Input;

export interface EmploymentStatusFormProps {
  EmpStatusData :EmploymentStatusDto;
  update : (EmpStatus:EmploymentStatusDto)=> void;
   isUpdate:boolean;
  closeForm: () => void;
}

export function EmploymentStatusForm(props: EmploymentStatusFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  const employmentStatService = new EmploymentStatService()
  const create = (empStatusTypeData:EmploymentStatusDto)=>{
    setDisable(true)
    employmentStatService.create(empStatusTypeData).then(res=>{
      setDisable(false)
      if(res.status){
        AlertMessages.getSuccessMessage('Employment Status created succesfully');
        history.push("./employment-status-form");
        onReset();
      }
      else {
        if (res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage);
          
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset=()=>{
    form.resetFields();
  }
  const saveData = (values :EmploymentStatusDto )=>{
    setDisable(false)
    if (props.isUpdate){
      console.log(values)
      props.update(values);
    }
    else {
      setDisable(false)
      console.log(values)
      create(values);
    }
  };

  return (
  

    <Card title={<span style={{color:'white'}}>Employment Status</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/employment-status-form' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form}
          
           name="control-hooks"
            
          layout="vertical" onFinish={saveData}
          initialValues={props.EmpStatusData}
          >
    
          <Form.Item name="employmentStatusId" style={{display:"none"}}   >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name='employmentStatus'
                      label="Employment Status"
                      rules={[
                        {
                        required:true,message:'Status type is required',
                        max:100
                        },
                        {
          
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                          message: `Should contain only alphabets.`
                        }
                        ]}>
                      
                        <Input />
                        {/* <Select>
                          <Select.Option value="Full Time Contract"> Full Time Contract </Select.Option>
                          <Select.Option value="Full time internship"> Full time internship </Select.Option>
                          <Select.Option value="Full time perminant"> Full time permanent </Select.Option>
                          <Select.Option value="Part time contract"> Part time contract </Select.Option>
                          <Select.Option value="Part time internship"> Part time internship </Select.Option>
                          <Select.Option value="Part time permanent"> Part time permanent </Select.Option>
                        </Select>                        
                      
                        </Select>                         
                        <Input />
                       */}
                    </Form.Item>  
            </Col>           
            </Row>
            <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary"disabled={disable} htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true ) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
            }
          </Col>
            </Row>
          </Form>
        </Card>
      );
}

export default EmploymentStatusForm;
