import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, UploadProps, Typography, Upload } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LoadingOutlined } from '@ant-design/icons';

import './spec-sheet-form.css';
import form from 'antd/lib/form';
import { CustomerDto, MasterBrandsDropDownDto, SkuDto, SpecSheetDto } from '@gtpl/shared-models/masters';
import {CustomersService, MasterBrandService, SkuService, SpecSheetService} from '@gtpl/shared-services/masters';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import appSettings from 'apps/services/config';
import moment from 'moment';


/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

/* eslint-disable-next-line */
export interface SpecSheetFormProps {
  specsheetData: SpecSheetDto;
  updateItem: (specsheetData: SpecSheetDto, filelist:any) => void;
  isUpdate: boolean;
  closeForm: () => void;
  // uploadFileList:(uploadFiles:any[])=>void
  // uploadedFile:string;


}

export function SpecSheetForm(props: SpecSheetFormProps) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [selectedCustomer, setselectedCustomer] = useState<number>(null);
  const [selectedbrand, setselectedbrand] = useState<number>(null);
  const [selectedvarient, setselectedvarient] = useState<number>(null);
  const [customerData, setcustomerData] = useState<CustomerDto[]>();
  const [brandData, setbrandData] = useState<MasterBrandsDropDownDto[]>();
  const [varientData, setvarientData] = useState<SkuDto[]>();
  const [loading, setLoading] = useState<boolean>(false);
  // const [filelist, setFilelist] = useState([]);
  const [filelist, setFilelist] = useState<any>(props.isUpdate?[{
    name: props.specsheetData.fileName,
    status: 'done',
    url: appSettings.file_upload_path+props.specsheetData.filePath,
  }]:[]);

  const customerservice = new CustomersService;
  const masterbrandservice = new MasterBrandService;
  const skucodeservice = new SkuService;
  const specsheetservice = new SpecSheetService;
 
useEffect(()=>{
  getAllActiveCustomers();
  getAllMastersDropdown();
  getAllSkucodes();
},[])

const getAllActiveCustomers= () =>{
  customerservice.getAllActiveCustomers().then(res => {
    if (res.status) {
      setcustomerData(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })

}

const getAllMastersDropdown= () =>{
  masterbrandservice.getAllMasterBrandsDropDown().then(res =>{
    if(res.status){
      setbrandData(res.data);
    }else {
      if(res.intlCode){
        AlertMessages.getErrorMessage(res.internalMessage);
      }else{
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}
const getAllSkucodes= () =>{
  skucodeservice.getActiveSkuCodes().then(res =>{
    if(res.status){
      setvarientData(res.data);
    }else {
      if(res.intlCode){
        AlertMessages.getErrorMessage(res.internalMessage);
      }else{
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })

}

  let history =  useHistory()
  const saveSpecSheet = (specsheetData: SpecSheetDto) => {
    console.log(form.getFieldsValue());
    form.validateFields().then(req => 
    {
      const formData = new FormData();
      console.log(req);
      req.fileName = req.file?.fileList[0]?.name;
      formData.append('file', req.file?.fileList[0]);
      console.log(formData);
      console.log(req);
      specsheetservice.createSpecSheet(req).then(res => 
      {
        if (res.status) {
          if (filelist.length > 0) {
            const formData = new FormData();
            filelist.forEach((file: any) => {
              formData.append('file', file);
            });
            formData.append('specSheetId', `${res.data.specSheetId}`)
            specsheetservice.specSheetFileUpload(formData).then(fileres => {
              res.data.filePath = fileres.data;
              history.push("/specsheet-view");
            })
          }
        // if(props.isUpdate){
        //  form.setFieldsValue('fileUpload')== form.getFieldValue('fileUpload')
        //  setFilelist([]);
        // }
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }
  

  const saveData = (values: SpecSheetDto) => {
    console.log(values,'dto in form')
    setDisable(false)
    if (props.isUpdate) {
      // form.setFieldsValue('fileUpload') == form.getFieldValue('fileUpload')
     setFilelist([]);
      props.updateItem(values,filelist);
    } else {
      setDisable(false)
      saveSpecSheet(values);
    }
  };
 
  const handlecustomer = (value) => {
    setselectedCustomer(value);
  }
 
  const handlevarient = (value) => {
    setselectedvarient(value);
  }
  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  const onReset = () => {
    form.resetFields();
    setFilelist([]);
  };
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }


  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setFilelist([]);
      // props.uploadFileList([])

    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(csv|xlsx|xls|pdf|zip)$/)) {
        AlertMessages.getErrorMessage("Only xlsx,csv,xls,pdf files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You cannot upload more than One file at a time");
          return true;
        } else {
          setFilelist([...filelist, file]);
          // props.uploadFileList([...filelist, file])
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Spec Sheet</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='specsheet-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
        <Form form={form} initialValues={props.specsheetData} name="control-hooks"
          onFinish={saveData}
          layout="vertical"         
        >
          <Form.Item name="specSheetId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{margin:'1%'}}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: 'Customer  is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Select the Customer`
                  }
                ]}

              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select Customer"
                  optionFilterProp="children"
                  onChange={handlecustomer}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Customer</Option>
                  {customerData?.map((value) => {
                    return <Option key={value.custId} value={value.custId}>{value.clientName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }} style={{margin:'1%'}} >
              <Form.Item
                name="brandId"
                label="Brand"
                rules={[
                  {
                    required:true,
                    message: 'Brand  is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Select the Brand`
                  }
                ]}

              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select Brand"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Brand</Option>
                  {brandData?.map((value) => {
                    return <Option key={value.masterBrandId} value={value.masterBrandId}>{value.masterBrandName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{margin:'1%'}}>
              <Form.Item
                name="skuCodeId"
                label="Product Sku"
                rules={[
                  {
                    required: true ,
                    message: 'Product  is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Select the Product`
                  }
                ]}

              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select Product"
                  optionFilterProp="children"
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                >
                  <Option key={0} value={null}>Select Product</Option>
                  {varientData?.map((value) => {
                    return <Option key={value.skuCodeId} value={value.skuCodeId}>{value.variantCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
             
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
          <Form.Item name = "fileUpload" 
           rules={[
            {
              required: true ,
              message: 'File is Required'
            }
          ]}
          >
      <Upload {...uploadFieldProps} style={{padding:'7px'}}>
        <br/><span style={{color: "red"}}>*<Button style={{ color: "black", backgroundColor: "#7ec1ff" }} icon={<UploadOutlined />}>Upload File</Button></span><Typography.Text  type="secondary">
        (Supported formats xlsx,csv,xls,pdf,zip)
                </Typography.Text>
      </Upload>
      </Form.Item>

          </Col>
          </Row>
          <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
        </Form>
      </Card>
    </>
  )
}

export default SpecSheetForm;
