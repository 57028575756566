import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import { DohountRequest } from "@gtpl/shared-models/common-models";
import './dashboard.css'




export interface donutProps {
  doNutData: DohountRequest;
}

const Highdonut = (props: donutProps) => {
  const { doNutData: cardData } = props;
  
  const data = cardData.portionData.map(portion => [portion.title, Number(portion.value)]);

  const config = {
    colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
  '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
  subtitle: {
    style: {
      color: 'var(--text-color,#eee)',
      textTransform: 'uppercase'
    }
  },
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45
      },
      backgroundColor: '#272953'
    },
    title: {
      text: cardData.headerTitle,
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.75rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      }
    },
    legend: {
      style: {
        color: 'var(--text-color,#eee)',
        textTransform: 'uppercase'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: 100,
        dataLabels: {
          enabled: true
        },
        showInLegend: true,
      },
      tooltip: {
        valueSuffix: '%'
      },
    },
    series: [{
      color: 'white',
      name: cardData.name,
      data: data,
    }]
  };

  return (
    <div>

      <HighchartsReact highcharts={Highcharts} options={config} />

    </div>
  )
}

export default Highdonut;