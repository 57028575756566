import { Card, Col, Row } from "antd";
import './attendance-dashboard.css'

import React, { } from "react";
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface cardProps {
    cardData: CardDetail
}

export const AttendanceCardComponet = (props: cardProps) => {
    const { cardData } = props;

    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <tr style={{ textAlign: 'center', marginRight: '5px', lineHeight: ' 1.8em' }}>
                <td >{bodyProps.cardBodyTitle}</td>
                <td >{bodyProps.cardBodyParam1}</td>
                <td >{bodyProps.cardBodyParam2}</td>
                <td >{bodyProps.cardBodyParam3}</td>
            </tr>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid black', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    return (
        <>
            <Card className="attnCard" title={<span style={{ marginBottom: '10px' }} className="title">{cardData.carHeader.cardHeaderTitle}</span>} bordered={false} style={{ color: 'black', height: '100%' }}>
                <Row style={{ width: '100%', height: '20px' }}>
                    <Col span={7}>
                        {cardData.carHeader.cardHederData[0].title}
                    </Col>
                    <Col span={7} >
                        {cardData.carHeader.cardHederData[1].title}
                    </Col>
                    <Col span={7} >
                        {cardData.carHeader.cardHederData[2].title}
                    </Col>
                </Row>
                <Row>
                    <Col span={7} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[0].value}
                    </Col>
                    <Col span={7} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[1].value}
                    </Col>
                    <Col span={7} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[2].value}
                    </Col>
                </Row>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '90%', margin: '1.5em', fontSize: '0.85em', border: '0' }}>
                    {constructTableHeader(cardData.tableHeaders)}
                    <tbody>
                        {constructLocationDetail(cardData.cardBody)}
                    </tbody>
                </table>
            </Card>
        </>
    );
}
export default AttendanceCardComponet;