import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin, Input } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { CrrencySymbols, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO } from '@gtpl/shared-models/sale-management';
import { FactoriesInput, PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest, UnitsOfWeightInput, } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { GlobalStatus, UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PrinterOutlined } from '@ant-design/icons'
let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { ToWords } from 'to-words';
import stampImage from "./stamp.png";
import signature from "./signature.png"


// const { ToWords } = require('to-words');
const toWords = new ToWords({
  localeCode: 'en-US',
});
/* eslint-disable-next-line */
export interface CommonInvoiceProps {
  saleOrderId: number;
}

export function CommonInvoice(props: CommonInvoiceProps) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;
  const [remarksData, setRemarksData] = useState<string>(undefined);
  const { TextArea } = Input;
  const defaultDeclaration = 'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct'

  console.log(location.state);
  let totalCases = 0;
  let netWeightInKgs = 0;
  let totalAmount = 0;
  let itemsTotalAmount = 0;
  let totalLbWeight = 0;
  let totalGrossWeight = 0;
  let FOBInRs = 0;
  let FOBInUSD = 0;
  let lbPouchWeight;
  let kgPouchWeight;

  let netWeightCalc;
  let totalAm = 0;


  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    // document.body.innerHTML = oldPage
    window.print();

  }

  const getData = (saleOrderId) => {
    // const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(props.saleOrderId)).then((res) => {
      console.log(res);
      if (res.status) {
        console.log(res);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  useEffect(() => {
    // setRemarksData("text");
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print()
  }, [props.saleOrderId])


  let exporterDetails = ExporterDataInput;
  let exporterId = 1;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };
  // return 'test';
  // const print = () => {
  //   var content = document.getElementById("divcontents");
  //   var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   pri.document.close();
  //   pri.focus();
  //   pri.print();
  // }
  const handleRemarks = (value) => {
    setRemarksData(value);
  }
  let hasNumber = /\d/;
  let normalOrder = hasNumber.test(plantDetails?.buyerPoNo);
  let lotRefString = '';
  let lotNo = '';
  let RefNo = '';
  if (normalOrder) {
    if (plantDetails?.lotCode) {
      lotNo = 'LOT NO. ' + plantDetails?.lotCode + ', '
    }
    if (plantDetails?.buyerPoNo) {
      RefNo = 'REF NO. ' + plantDetails?.buyerPoNo
    }
    lotRefString = lotNo + RefNo
  }


  return (
    <div >
      <span style={{ alignContent: 'left', backgroundColor: 'red' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="CommercialInvoice"
          sheet="CommercialInvoice"
          buttonText="Get Excel" />
      </span>
      <br />
      {plantDetails ?
        <html>

          <head></head>
          <br />
          <body id='printme' className={"stylesInfo"}>


            <br></br>
            <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls" >
              <tr><td className={'ta-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '16px', lineHeight: '10px', paddingTop: '20px' }}><h1>INVOICE</h1></td></tr>
              <tr>
                <td className={'ta-b'} colSpan={3} rowSpan={2}>
                  <b>Exporter:</b> <br />
                  <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                  {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                  <br />
                  {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                  <br />
                  {exporterData?.state + ', ' + exporterData?.country}
                  <br />

                </td>
                <td className={'ta-b'} colSpan={3}>
                  <b>Invoice No. </b>
                  {plantDetails?.invoiceNumber}<br />
                  <b>Date: </b>
                  {moment(plantDetails?.invoiceDate).format('DD-MMM-YYYY')}
                </td>

                <td className={'ta-b'} colSpan={2}>
                  <b>Exporter's Ref<br />
                    IE Code </b> {exporterData?.ieCode}
                </td>
              </tr>
              <tr>
                <td colSpan={5} className={'ta-b'}>
                  <b>Buyer's Order No. </b>
                  PO NO. {plantDetails.custPoNo}
                  {/* <h4><b>Date: </b>{moment(plantDetails?.actualEdl).format('Do MMM, YYYY')}
                  </h4> */}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={3} rowSpan={plantDetails?.notifyPartyThree ? 2 : 1} style={{ textAlign: 'start' }}>
                  PROCESSED & PACKED AT <br />
                  <h4 style={{ display: 'inline' }}><b>{(exporterData?.name) ? exporterData?.name : ''}</b></h4><br />
                  {plantDetails?.invoicedPlant?.villageName + ", " + plantDetails?.invoicedPlant?.mandalName}<br />
                  {plantDetails?.invoicedPlant?.postalCode + ", " + plantDetails?.invoicedPlant?.distirctName + ", " + plantDetails?.invoicedPlant?.stateName + ", " + plantDetails?.invoicedPlant?.countryName}<br />
                  <b>FDA REGISTRATION NUMBER :</b>{plantDetails?.invoicedPlant?.fdaRegNumber}
                  <br />
                  {plantDetails?.invoicedPlant?.feiNumber ?
                    <><b>FEI NUMBER:</b>{plantDetails?.invoicedPlant?.feiNumber}</> : <></>}
                </td>
                {
                  (plantDetails.notifyPartyOne) ?
                    <td colSpan={plantDetails?.notifyPartyOne ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start', fontFamily: 'Arial Narrow' }}>
                      <u>
                        Notify party 1</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyOne?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyOne?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyOne?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyOne?.split("\n")[3]}</pre><br />
                      {/* {(plantDetails?.notifyPartyOne) ? plantDetails?.notifyPartyOne : ''} */}

                    </td> : <></>
                }
                {
                  (plantDetails.notifyPartyTwo) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start', width: '50%', fontFamily: 'Arial Narrow' }}>
                      <u>
                        Notify party 2</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyTwo?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[3]}</pre><br /><br />

                    </td> : <></>
                }
              </tr>
              <tr >
                {
                  (plantDetails.notifyPartyThree) ?
                    <td colSpan={plantDetails.notifyPartyFour ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start', fontFamily: 'Arial Narrow' }}>
                      <u>
                        Notify party 3</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyThree?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyThree?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }
                {
                  (plantDetails.notifyPartyFour) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start', fontFamily: 'Arial Narrow' }}>
                      <u>
                        Notify party 4</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyFour?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyFour?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }
              </tr>
              <tr >
                <td colSpan={3} rowSpan={2} className={'ta-b'}>
                  Consignee:<br />
                  {
                    plantDetails?.country === "CANADA" ?
                      <>
                        <b>AZ GEMS CANADA INC:</b>
                      </> : <b>AZ GEMS INC:</b>
                  }
                  <br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","}
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","}
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[3]}<br />
                  FDA REGISTRATION NO. {unitsData?.consigneefdaRegNumber}
                </td>
                <td colSpan={5} className={'ta-b'} >
                  <b>
                    Importer on record:
                  </b><br />
                  {
                    plantDetails?.country === "CANADA" ? <>
                      <b>AZ GEMS CANADA INC:</b>
                    </> : <b>AZ GEMS INC</b>
                  }
                  <br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
                  <br /><b>FDA REGISTRATION NO.{unitsData?.consigneefdaRegNumber}</b>
                </td>
              </tr>

              <tr>
                <td colSpan={3} className={'ta-b'}>
                  Country of Origin of Goods:<br />
                  <b > INDIA</b>
                </td>
                <td colSpan={5} className={'ta-b'}>
                  <b> Country of Final Destination:</b><br />
                  {plantDetails?.country}{' '}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={2}>
                  Pre Carriage By:
                  <h4>Road</h4>
                </td>
                <td className={'ta-b'} colSpan={1}>
                  Place of Receipt of Pre-carrier:
                  <b>{plantDetails?.placeOfReceipt != undefined ? plantDetails.placeOfReceipt : null}</b>
                </td>
                <td colSpan={5} className={'ta-b'}>
                  Terms of Delivery and Payment:<br />
                  <span style={{ textAlign: 'center' }}><b>{plantDetails?.saleOrderItems[0]?.paymentTerms}</b></span>
                </td>
              </tr>

              <tr>

                <td className={'ta-b'} colSpan={2}>
                  Vessel / Flight No.

                  <b>{/* {(plantDetails?.vesselName)?.toUpperCase()} */}</b>

                </td>
                <td className={'ta-b'} colSpan={1}>
                  Port of Loading: <br />

                  <b>{plantDetails?.portofloading ? (plantDetails?.portofloading)?.toUpperCase() + ', INDIA' : ''}</b>


                </td>

                <td colSpan={5} rowSpan={2} className={'ta-b'}>
                  Container No.&nbsp;
                  <b>{/* {plantDetails?.saleOrderItems[0]?.containerNo} */}</b>
                  <br />
                  Liner Seal No.&nbsp;
                  <b> {/* {plantDetails?.saleOrderItems[0]?.linearSelaNo} */}</b>
                  <br />
                  Bill of Lading No.&nbsp;
                  <b>{/* {plantDetails?.saleOrderItems[0]?.billOfLadingno}<br/><br/><br/> */}</b>
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={2}>
                  Port of Discharge:<br />
                  <b>{(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() : '') + "," + (plantDetails?.country ? plantDetails?.country : '')}</b>
                </td>
                <td className={'ta-b'} colSpan={1}>
                  Final Destination:<br />
                  <b>{(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() : '') + "," + (plantDetails?.country ? plantDetails?.country : '')}</b>
                </td>
              </tr>
              <tr>
                <td colSpan={1} style={{ textAlign: 'center', fontSize: 10 }}>
                  Marks & Nos / No & Kind of Pkgs
                </td>
                <td colSpan={1} style={{ textAlign: 'center' }}>
                </td>
                <td style={{ textAlign: 'center', paddingLeft: '55px' }} colSpan={2}>
                  Description of Goods
                </td>

                <td style={{ textAlign: 'center' }}>
                </td>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Quantity
                  <br />
                  {
                    plantDetails?.country === 'CANADA' ? <>
                      IN KGs
                    </> : <>IN LBs</>
                  } <br /> NET WEIGHT
                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Rate
                  <br />
                  {
                    plantDetails?.country === 'CANADA' ? <>
                      PER KGs
                    </> : <>PER LBs</>
                  } <br />
                  <br />({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  <br />{plantDetails?.shipmentTerms}
                  <br />{plantDetails?.saleOrderItems[0].destinationDetails}
                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Amount
                  <br /> ({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  <br /> {plantDetails?.shipmentTerms}
                  <br /> {plantDetails?.saleOrderItems[0].destinationDetails}
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                </th>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                  HSN Code
                </th>
                <th style={{ textAlign: 'left' }} colSpan={2}>
                  Size
                </th>
                <th style={{ textAlign: 'left', width: '30%' }}>
                  No. Of Cartons
                </th>
              </tr>

              {plantDetails?.saleOrderItems?.map(item => {
                const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                let finaItemslNetWeight;
                let itemUnitPrice;
                if (plantDetails.isInvoiced == GlobalStatus.YES) {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    finaItemslNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                    itemUnitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                  } else {
                    finaItemslNetWeight = Number(item.invoicedQty).toFixed(3);
                    itemUnitPrice = Number(item.revisedUnitPrice).toFixed(5);
                  }
                }
                itemsTotalAmount += Number(itemUnitPrice) * Number(finaItemslNetWeight);

              })}

              {
                plantDetails?.saleOrderItems?.map(item => {
                  netWeightInKgs += Number(item.netWeight) * 0.454;
                  console.log('netWeightInKgs', netWeightInKgs)

                  let freightCharges = plantDetails?.freightCharges === undefined ? 0 : plantDetails?.freightCharges;
                  let exchangeRate = plantDetails?.exchangeRate === undefined ? 0 : plantDetails?.exchangeRate;
                  totalCases += item.invoicedCases;
                  const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                  let finalNetWeight;
                  let unitPrice;
                  let invUnitPrice;
                  if (plantDetails.isInvoiced == GlobalStatus.YES) {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      if (plantDetails?.country === 'CANADA') {
                        finalNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                        unitPrice = Number(item.revisedUnitPrice).toFixed(5);
                      } else {
                        finalNetWeight = (Number(item.invoicedQty)).toFixed(3);
                        unitPrice = Number(item.revisedUnitPrice).toFixed(5);
                      }
                    } else {
                      finalNetWeight = Number((item.invoicedQty)).toFixed(3);
                      unitPrice = (Number(item.unitPrice)).toFixed(5);
                    }
                  }

                  else {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      finalNetWeight = (Number(item.netWeight) / 0.454).toFixed(3);
                      unitPrice = (Number(item.revisedUnitPrice) * 0.454).toFixed(5);
                    } else {
                      finalNetWeight = Number(item.netWeight).toFixed(3);
                      unitPrice = Number(item.revisedUnitPrice).toFixed(5);
                    }
                  }

                  if (plantDetails?.country === 'CANADA') {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      invUnitPrice = (Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice) * 0.454).toFixed(2)
                    } else {
                      invUnitPrice = Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice).toFixed(5)
                    }
                  } else if (plantDetails?.country === 'USA') {
                    if (uom.name == UomEnum.KG) {
                      invUnitPrice = (Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice) / 0.454).toFixed(5)
                    } else {
                      invUnitPrice = Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice).toFixed(5)
                    }
                  }

                  console.log(invUnitPrice)
                  totalLbWeight += Number(finalNetWeight);
                  totalGrossWeight += (Number(item.grossWeightPerKg) * Number(item.invoicedCases));
                  // totalAmount += Number((Number(unitPrice) * Number(finalNetWeight)).toFixed(2)); Number(finalNetWeight * Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice))
                  totalAmount = item.invoiceAmount;
                  totalAm += Number(Number(invUnitPrice) * Number(finalNetWeight));
                  // totalAmount += (Number(plantDetails?.totalAmount))
                  FOBInRs = totalAmount - freightCharges;
                  FOBInUSD = (totalAmount - freightCharges) * exchangeRate;


                  let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" || "Sea Tiger" ? "PENAEUS MONODON" : "";
                  // let shrimp = item.itemName.toUpperCase() === "VANNAME"?"SHRIMPS":"";
                  let shrimp = "SHRIMPS";
                  let outputWeight = '';
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    outputWeight = UomEnum.LB;
                  } else if (uom.name == UomEnum.KG || uom.name == UomEnum.Gms) {
                    outputWeight = UomEnum.KG;
                  }

                  lbPouchWeight = Number(item.pouchWeight)
                  kgPouchWeight = Number(item.pouchWeight)
                  if (uom.name == UomEnum.OZ) {
                    lbPouchWeight = lbPouchWeight * 0.0625
                  }
                  if (uom.name == UomEnum.Gms) {
                    kgPouchWeight = kgPouchWeight * 0.001
                  }


                  return (
                    <><tr style={{ border: '0px' }}>
                      <td style={{ textAlign: 'center' }}>{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}<br />
                        <span style={{ textAlign: 'center' }}>{Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</span><br /><span style={{ textAlign: 'center' }}>{(uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? <>{(Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'}</> : <></>}</span></td>
                      <td style={{ textAlign: 'center' }}>{item.hsnCode}</td>
                      <td colSpan={2} style={{ textAlign: 'left' }}>
                        {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                          (!(([
                            'HLSO', 'HOSO', 'EZPeel', 'N/A'
                          ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                        ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')}<br />{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}<br />
                        {'LATIN NAME : ' + latinName.toUpperCase()}<br />
                        {' BRAND : ' + item.displayBrandName?.toUpperCase()}<br />
                        {lotRefString}<br />
                        {plantDetails?.finNumber ? ('FPO#/FIN# ' + (plantDetails?.finNumber)) : ''}
                      </td>
                      <td style={{ textAlign: 'center' }} ></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      {/* <td className={'ta-b'} style={{textAlign:'center', borderTop:'0px', borderBottom:'0px'}}></td> */}
                    </tr><tr>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'left' }} colSpan={2}>{item.minGrade + '/' + item.maxGrade}</td>
                        <td style={{ textAlign: 'right' }}>{item.invoicedCases}</td>
                        {console.log('------333------', finalNetWeight)}
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(finalNetWeight) ? Number(finalNetWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(invUnitPrice)}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{Number(Number(invUnitPrice) * Number(finalNetWeight))?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr></>
                  )
                }
                )
              }

              <tr>
                <td className={'ta-b'} colSpan={4} style={{ textAlign: 'center' }}>
                  Total
                </td>
                <td colSpan={1} className={'ta-b'} style={{ textAlign: 'right' }}><b>
                  {totalCases}</b>
                </td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}><b>
                  {(totalLbWeight) ? Number(totalLbWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</b>
                </td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              </tr>

              <tr>
                <td colSpan={5} >
                  Amount chargeable <br /> (in words):  <b> U.S {(totalAm) ? (toWords.convert(totalAm, { currency: true }).toUpperCase()) : ""}</b>
                </td>
                <td colSpan={2} style={{ textAlign: 'left', borderBottom: '0px' }}>
                  <b>Total USD</b>
                </td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}><b>
                  {/* {Number(totalAm).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                  {Number(totalAm).toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails).locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </b></td>
              </tr>
              <br></br>
              <br></br>
              <br></br>
              <br></br>


              <tr>
                <td style={{ width: '20%' }} className={'ta-b'}></td>
                {
                  plantDetails.country === 'CANADA' ? <>
                    <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                      <h4><b>IN LBS</b></h4>
                    </td>
                    <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                      <h4><b>IN KGS</b></h4>
                    </td>
                  </> : <>
                    <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                      <h4><b>IN LBS</b></h4>
                    </td>
                    <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                      <h4><b>IN KGS</b></h4>
                    </td>
                  </>
                }

                <td style={{ width: '30%' }}></td>
                <td className={'ta-b'} colSpan={4} style={{ width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}></td>
              </tr>
              <tr>
                <td style={{ width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  {' '}
                  <b>TOTAL NET WT:{' '}</b>
                </td>
                {
                  plantDetails.country === 'CANADA' ? <>
                    <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                      <h4>{(Number((totalLbWeight / 0.454))).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                    </td>
                    <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                      {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                      <h4>{(Math.trunc(totalLbWeight)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                    </td>

                  </> :
                    <>
                      <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                        {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                        <h4>{(Math.trunc(totalLbWeight)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                      </td>
                      <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                        <h4>{(Number((totalLbWeight * 0.454))).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                      </td>
                    </>

                }

                <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                  TOTAL NO. OF MASTER CARTONS:
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}>
                  <h4><b>{totalCases}</b></h4>
                </td>


              </tr>
              <tr>
                <td style={{ width: '20%', borderTop: '0px' }} className={'ta-b'}>
                  {' '}
                  <b> TOTAL GROSS WT:</b>
                </td>
                <td style={{ textAlign: 'right', width: "20%", borderTop: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                  <h4>{Math.round((totalGrossWeight / 0.454) ? (totalGrossWeight / 0.454) : 0).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px' }} className={'ta-b'}>
                  <h4>{(Math.round(totalGrossWeight ? totalGrossWeight : 0)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                  TOTAL AMOUNT (IN {plantDetails?.saleOrderItems[0]?.currencyDetails}):
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}  >
                  <h4>
                    <b>{Number(totalAm).toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails).locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                    {/* {Number(totalAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                  </h4>
                </td>
              </tr>
              {/* </table>
            <table style={{width:'100%',tableLayout:'fixed'}}> */}

              <tr>
                <td colSpan={4} id='inputText'><b>Declaration :</b>
                  <TextArea style={{ outline: 'none', border: 'none' }} rows={3} contentEditable={true} defaultValue={defaultDeclaration}
                    onChange={(evt) => handleRemarks(evt)}
                  ></TextArea>
                </td>
                <td colSpan={4} className={'ta-b'}>
                  {/* <div className='stamp-and-print'> */}
                  <b>Signature and Date For :</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <h4>SANDHYA AQUA EXPORTS PVT. LTD</h4>
                  <br />
                  <h4>AUTHORISED SIGNATORY</h4>
                  {/* </div> */}
                </td>
              </tr>
            </table>

          </body>
        </html>
        : <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height: '100' }}>Commercial Invoice</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}><span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span></Card>}

    </div>
  );
}

export default CommonInvoice;