import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, Modal, Popconfirm, Row, Switch } from 'antd';
import { SearchOutlined, CheckOutlined, CloseOutlined, RightSquareOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link, useHistory, useParams } from 'react-router-dom';
import './pages-asset-management-asset-management-components-location-audit-grid.css';
import Table, { ColumnProps } from 'antd/lib/table';
import TextArea from 'antd/lib/input/TextArea';
import { LocationAuditService } from 'libs/shared-services/asset-management/src/lib/location-audit';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LocationAuditDto } from 'libs/shared-models/asset-management/src/lib/open-assets/location-audit.dto';
import { LocationById } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-id.dto';


/* eslint-disable-next-line */
export interface LocationAssetListProps {
    locationId: any
}

export function LocationAssetListGrid(
    props: LocationAssetListProps
) {

    const { locationId } = props;
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pageSize, setPageSize] = useState<number>(null);
    const [page, setPage] = React.useState(1);
    const [openModal, setOpenModel] = useState<boolean>(false);
    const [assetsdata, setAssetsData] = useState<any[]>([]);
    const history = useHistory();
    const [length,setLength]= useState('');

    const service = new LocationAuditService();

    useEffect(() => {
        if(locationId){
        getAssetsByLocation(locationId)
        }

    }, [locationId])

    const getAssetsByLocation = (locationId) => {
        const req = new LocationById(locationId)
        service.getAssetsByLocation(req).then(res => {
            if (res.status) {
                setAssetsData(res.data)
                setLength(res.data.length)
                AlertMessages.getSuccessMessage(res.internalMessage)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }



    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });


    const columns: ColumnProps<any>[] = [
        {
            title: 'S.No',
            key: 'sno',
            width: '50px',
            fixed: 'left',
            render: (text, object, index) => (page - 1) * pageSize + (index + 1)
        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            ...getColumnSearchProps('location_name'),
        },
        {
            title: 'Asset Mode',
            dataIndex: 'asset_mode',
            ...getColumnSearchProps('asset_mode'),

        },
        {
            title: 'Asset Type',
            dataIndex: 'asset_type',
            ...getColumnSearchProps('asset_type'),

        },
        {
            title: 'Asset Category',
            dataIndex: 'item_sub_category',
            ...getColumnSearchProps('item_sub_category'),
        },
        {
            title: 'Asset Code',
            dataIndex: 'assets_code',
            ...getColumnSearchProps('assets_code'),
        },

        {
            title: 'Asset Name',
            dataIndex: 'item_name',
            ...getColumnSearchProps('item_name'),
        },

    ]

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Assets</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
            >
                <Col span={3} style={{ marginLeft: '85%', paddingBottom: '1px' }}>
                    <Button >Total no of Records:{length}
                    </Button>
                </Col>
                <Table columns={columns} dataSource={assetsdata}
                />
            </Card>

        </>
    );
}

export default LocationAssetListGrid;
