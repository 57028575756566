import { Card, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SalePoDetailsInfo } from '@gtpl/shared-models/sale-management';
import { JobTypesEnum } from '@gtpl/shared-models/common-models';

const { Option } = Select;
const { TextArea } = Input;

export interface IProcessDetailsFormProps {
    itemDetailsData: any[];
    form: FormInstance<any>
    initialData:SalePoDetailsInfo
    jobType:string
}

const ProcessDetailsForm = (props: IProcessDetailsFormProps) => {

    const form = props.form;
    const [subPoDisable, setsubPoDisable] = useState<boolean>(false);

    useEffect(()=>{
        if(props.jobType == JobTypesEnum.SUB_JOB){
          console.log(props.jobType)
          setsubPoDisable(true)
        }
      },[props.jobType])



    useEffect(() => {
        getProcessDetailsData();
        if(props.initialData){
            console.log(props.initialData)
            props.form.setFieldsValue({
                branddesignType:props.initialData.brandDesignType,
                branddesign:props.initialData.isBrandApproved,
                Addnote:props.initialData.additionalNote,
            })

        }
    }, []);

    const getProcessDetailsData = () => {
    }

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Packing Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <Form layout={"vertical"} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>

                            <Form.Item name="branddesignType" label="Brand Design Type" rules={[{ required: true,message: 'Missing brand design' }]}>
                                <Select
                                    placeholder="Select Brand Design Type"
                                    allowClear
                                    disabled={subPoDisable}
                                >
                                    <Option value="Old">Old</Option>
                                    <Option value="New">New</Option>

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item name="branddesign" label="Is Brand Approved?" rules={[{ required: true,message: 'Missing brand approval' }]}>
                                <Select
                                    placeholder="Select Approval Status"
                                    allowClear
                                    disabled={subPoDisable}
                                >
                                    <Option value="yes">YES</Option>
                                    <Option value="no">NO</Option>

                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item name="Addnote" label="Additional Note">
                                <TextArea disabled={subPoDisable} showCount  />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card></>)
}
export default ProcessDetailsForm