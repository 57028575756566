import { NotifyPartyDto } from '@gtpl/shared-models/masters';
import { NotifyPartyService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';


import './notify-party-form.css';

/* eslint-disable-next-line */
export interface NotifyPartyFormProps {
  notifyPartyData: NotifyPartyDto;
  updateNotifyParty: (notifyParty: NotifyPartyDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function NotifyPartyForm(
  props: NotifyPartyFormProps
) {


  const [form] = Form.useForm();
  let history = useHistory();
  const service = new NotifyPartyService;
  const [disable, setDisable] = useState<boolean>(false)

  const createNotifyParty = (Data: NotifyPartyDto) => {
    setDisable(true)
    console.log(Data,'create')
    service.createNotifyParty(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');
        history.push("/notify-party-grid")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: NotifyPartyDto) => {
    setDisable(false)
     console.log(values);
    if (props.isUpdate) {
      props.updateNotifyParty(values);
    } else {
      setDisable(false)
      createNotifyParty(values);
       console.log('test')
    }

  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <Card title={<span style={{ color: 'white' }}>Notify party</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/notify-party-grid' ><span style={{ color: 'white' }} >  {(props.isUpdate === false) &&
        <Button className='panel_button' >View </Button>
      }  </span></Link>} >
      <Form form={form} initialValues={props.notifyPartyData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="notifyPartyId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={props.notifyPartyData}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="notifyPartyName"
              label="Notify Party"
              rules={[
                {
                  required: true,
                  message: 'Notify Party required'
                },
              ]}
            >
              <TextArea size='large' aria-multiline />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default NotifyPartyForm;
