import { HighChartReq } from "@gtpl/shared-models/common-models";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import './dashboard.css';


export interface HighChartProps {
  highChartData: HighChartReq;
}
const Highchartbar = (props: HighChartProps) => {

  const { highChartData } = props
  const title = highChartData.title
  const category = highChartData.category



  const config = {
    colors: ['#058DC7', '#ECF0F1', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
      type: 'column',
      backgroundColor: '#272953',
     
    },

    title: {
      text: title,
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.75rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      }
      
    },

    xAxis: {
      categories: category,
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: highChartData.subTitle,
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.15rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        }
      },
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }

    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' +
          this.series.name + ': ' + this.y + '<br/>' +
          'Total: ' + this.point.stackTotal;
      }
    },

    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },

    series: highChartData.chartDetails,

  }
  return (

    <HighchartsReact highcharts={Highcharts} options={config} />

  )

}
export default Highchartbar;