import { DesignationDto, ShiftDto, TeamDto, WorkCalenderDto } from "@gtpl/shared-models/hrms";
import { EmployeeCodeRequest, HrmsEmployeeDetailsDto } from "@gtpl/shared-models/masters";
import { WorkCalenderService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { EmployeeService } from '@gtpl/shared-services/masters';

const format = 'HH:mm';

export interface WorkCalenderProps {
  workcalenderData: WorkCalenderDto;
  updateWorkCalender: (workcalender: WorkCalenderDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export function WorkCalender(
  props: WorkCalenderProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const workcalenderService = new WorkCalenderService()
  const [disable, setDisable] = useState<boolean>(false)
  const dateFormat = "YYYY-MM-DD";
  const [shiftData, setShiftData] = useState<ShiftDto[]>([]);
  const { Option } = Select;
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endTime, setEndTime] = useState<string>('00:00');
  const empService = new EmployeeService();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [teamData, setTeamData] = useState<TeamDto[]>([]);
  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }

  const createWorkCalender = (Data: WorkCalenderDto) => {
    setDisable(true)
    workcalenderService.createWorkCalender(Data).then(res => {
      setDisable(false)
      if(res.status) {
        AlertMessages.getSuccessMessage('Work Calender created successfully');
        history.push('/workcalender-grid');
        onReset();
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }
  const getEmployeeDetails = () => {
    const employeeCode = form.getFieldValue('employeeCode')
    const request = new EmployeeCodeRequest(employeeCode);
    empService.getActiveEmployeesByEmpCode(request).then(res => {
      if (res.status) {
        console.log(res.data.employeeName);
        form.setFieldsValue({
          employeeName: res.data.employeeName,
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const saveData = (values: WorkCalenderDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values);
      props.updateWorkCalender(values);
    }else {
      setDisable(false)
      console.log(values);
      createWorkCalender(values);
    }
  };

  const getNoOfDays = (dateType: string) => {
    const fromDate = form.getFieldValue('fromDate');
    const toDate = form.getFieldValue('toDate');
    if (moment(fromDate).format('dddd') === 'Sunday' || moment(toDate).format('dddd') === 'Sunday') {
      AlertMessages.getErrorMessage('Selected day should not be a sunday');
      if (dateType == 'fromDate') {
        setFromDate(moment());
        form.setFieldsValue({
          fromDate: moment()
        })
      }
      if (dateType == 'toDate') {
        form.setFieldsValue({
          toDate: moment()
        })
      }
    }
    const d1 = moment(form.getFieldValue('fromDate'), 'YYYY-MM-DD');
    const d2 = moment(form.getFieldValue('toDate'), 'YYYY-MM-DD');
    // Calc all days used including last day ( the +1 )
    const days = d2.diff(d1, 'days') + 1;

    console.log('Days:', days);

    // how many full weekends occured in this time span
    const weekends = Math.floor(days / 7);

    console.log('Full Weekends:', weekends);

    // Subtract all the weekend days
    let businessDays = days - (weekends * 1);

    // Special case for weeks less than 7
    if (weekends === 0) {
      const cur = d1.clone();
      for (let i = 0; i < days; i++) {
        if (cur.day() === 0) {
          businessDays--;
        }
        cur.add(1, 'days')
      }
    } else {
      // If the first day is a sunday we need to account for it
      if (d1.day() === 0) {
        console.log('Extra weekend day (Sunday)');
        businessDays--;
      }
    } 
} 

const onStartTimeChange = (time, timeString: string) => {
  // console.log(time.hour() + ':' + time.minutes(), timeString);
  setStartTime(timeString);
};

const onEndTimeChange = (time, timeString: string) => {
  setEndTime(timeString);
};

return (
  <Card title={<span style={{color:'white'}}>Work Calender</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/workcalender-grid' ><Button className='panel_button' >View </Button></Link>}
  >
  <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.workcalenderData} >
      <Form.Item 
      name ="id" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
      <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
        <Input hidden/>
      </Form.Item>
    <Row gutter={24}>
    <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message:"Enter valid Employee Code"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Code"
                optionFilterProp="children" 
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={getEmployeeDetails}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeCode}>{employeeData.employeeCode}</Option>
                })}
              </Select>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
      <Form.Item name="date" label="Date" initialValue={moment()}>
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              onChange={(selected) => {setFromDate(selected);getNoOfDays('date')}}
              showToday={true} />
          </Form.Item>
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="shift"
              label="Shift"
              rules={[
                {
                  required: true,
                  message:"Enter valid Shift"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Select
                                    placeholder="Select Shift Group"
                                    // onChange={}
                                    allowClear
                                    style={{ width: '100%' }}
                                  >
                                    {shiftData.map(dropData => {
                                      return <Option value={dropData.shift}>{dropData.shift}</Option>;
                                    })}</Select>
        </Form.Item>
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="shiftCode"
              label="Shift Code"
              rules={[
                {
                  required: true,
                  message:"Enter valid Shift Code"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Select
                                    placeholder="Select Shift Code"
                                    // onChange={}
                                    allowClear
                                    style={{ width: '100%' }}
                                  >
                                    {teamData.map(dropData => {
                                      return <Option value={dropData.shiftCode}>{dropData.shiftCode}</Option>;
                                    })}</Select>
        </Form.Item>
      </Col>     
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="workStationTeam"
              label="WorkStation Team"
              rules={[
                {
                  required: true,
                  message:"Enter valid WorkStation Team"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input/>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="inTime"
            label="In Time"
          >
             <TimePicker key={startTime} defaultValue={moment(startTime, format)} format={format} onChange={onStartTimeChange}/>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
        <Form.Item
            name="outTime"
            label="Out Time"
          >
             <TimePicker key={startTime} defaultValue={moment(startTime, format)} format={format} onChange={onEndTimeChange}/>
          </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="attendanceStatus"
              label="Attendance Status"
              rules={[
                {
                  required: true,
                  message:"Enter valid Attendance Status"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input/>
        </Form.Item>
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="leaveCode"
              label="Leave Code"
              rules={[
                {
                  required: true,
                  message:"Enter valid Leave Code"
                },
                {
        
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Should contain only letters and numbers.`
                }
              ]}>
              <Input/>
        </Form.Item>
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="department"
              label="Department"
              rules={[
                {
                  required: true,
                  message:"Enter valid Department"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Select
                    placeholder="Select designation"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {designationData.map(dropData => {
                      return <Option value={dropData.designation}>{dropData.designation}</Option>;
                    })}</Select>
        </Form.Item>
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
              name="contractor"
              label="Contractor"
              rules={[
                {
                  required: true,
                  message:"Enter valid Contractor"
                },
                {
        
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input/>
        </Form.Item>
      </Col>
    </Row>
    <Col span={24} style={{ textAlign: 'right' }}>
        <Button type="primary"disabled={disable} htmlType="submit" >
          Submit
        </Button>
        {(props.isUpdate!==true) &&
        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
          Reset
        </Button>
        }
      </Col>

  </Form>
</Card>
);


}
export default WorkCalender;