import { Input, Button, Space, Tag, Card, InputNumber } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { CaretRightOutlined, SearchOutlined, FilterOutlined ,CloseCircleOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { Link } from "react-router-dom";
import Table, { ColumnProps } from "antd/lib/table";

/* eslint-disable-next-line */
export interface otDetailsProps {}

export function otDetails(
  props: otDetailsProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
 
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      width: '150px',
      fixed: 'left',
      align: 'center',
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      width: '150px',
      fixed: 'left',
      align: 'center',
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      
    },
    {
      title: 'In Time',
      dataIndex: 'inTime',
      width: '150px',
      render: (text, record) => { return moment(record.inTime).format('HH:mm') },
      sorter: (a, b) => a.inTime?.localeCompare(b.inTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Out time',
      dataIndex: 'outTime',
      width: '150px',
      render: (text, record) => { return moment(record.outTime).format('HH:mm') },
      sorter: (a, b) => a.outTime?.localeCompare(b.outTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      width: '150px',
      render:(text,record)=>{ return moment.utc(moment(record.outTime).diff(moment(record.inTime))).format("HH:mm")},
      sorter: (a, b) => a.workingHours?.localeCompare(b.workingHours),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'OT hours',
      dataIndex: 'otHours',
      width: '150px',
      render:(_text,record)=>{ return moment.utc(moment(record.outTime).diff(moment(record.inTime))).format("HH:mm")},
      // {return <InputNumber  min={0} max={10} />},
      sorter: (a, b) => a.otHours?.localeCompare(b.otHours),
      sortDirections: ['descend', 'ascend'],
      
    },     
    
  ];
  
  return (
    <Card title={<span style={{color:'white'}}>OT-Details</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/menu' ><span style={{color:'white'}} ><Button className='panel_button' >Menu</Button> </span></Link>}   >    
      <Table
        rowKey={record => record.Id}
        columns={columnsSkelton        }
        pagination={{ onChange(current) { setPage(current); } }}
        scroll={{ y: 500 }}
        size="small"
        bordered
       
      >
      </Table>
    </Card>
  );  
}

export default otDetails;
