import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import React, { useEffect, useState } from 'react'
import LifeCyclCard from './life-cycle-card';

export interface WhLifeCycleProps { }

export function WhLifeCycle(props: WhLifeCycleProps) {
    const [lifeCycleData, setLifeCycleData] = useState<any[]>([])

    const service = new WarehouseDashboardService;

    useEffect(() => {
        getWarehouseLifeCycle();
    }, [])

    const getWarehouseLifeCycle = () => {
        service.getWarehouseLifeCycle({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setLifeCycleData(res.data)
            } else {

            }
        })
    }
    const cardBodyForSoLifeCycleStatusCount: CardBodyDetails[] = [];
    let LifeCycleStatusCount = 0;
    let kakinadaCount = 0;
    let unitOneCount = 0;
    let unitTwoCount = 0;
    for (const productInfo of lifeCycleData) {
        cardBodyForSoLifeCycleStatusCount.push(new CardBodyDetails(productInfo.lifeCycleStatus, Number(productInfo.kkd).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unitOne).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unitTwo).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),productInfo.total ));
        kakinadaCount = kakinadaCount + Number(productInfo.kkd)
        unitOneCount = unitOneCount + Number(productInfo.unitOne);
        unitTwoCount = unitTwoCount + Number(productInfo.unitTwo);
    }
    LifeCycleStatusCount = Number(kakinadaCount + unitOneCount + unitTwoCount);

    const soLifeCycleStatusCountBodyStatus = new CardHeaderBody('', status);
    const soLifeCycleStatusCountBodyCount = new CardHeaderBody('Total Count', Number(LifeCycleStatusCount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
    const soLifeCycleStatusCountBodyCardHeader = new CardHeader('Warehouse Life Cycle Status Count', [soLifeCycleStatusCountBodyStatus, soLifeCycleStatusCountBodyCount]);

    const cardDetails = new CardDetail(soLifeCycleStatusCountBodyCardHeader, cardBodyForSoLifeCycleStatusCount, ['Status', 'Kakinada', 'Unit1', 'Unit2','Total']);
    return (
        <>
            <LifeCyclCard cardData={cardDetails} />
        </>
    )
}

export default WhLifeCycle;