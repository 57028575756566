import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Button, Card, Descriptions, Divider, Modal, Popover, Row, Table } from "antd";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { LocationAuditService } from "libs/shared-services/asset-management/src/lib/location-audit";

const localizer = momentLocalizer(moment);
moment.locale('en-GB');

export function LocationAuditCalender() {

  const [calenderData, setCalenderData] = useState<any[]>([])
  const [datesData, setDatesData] = useState<any[]>([])
  const history = useHistory()
  const service = new LocationAuditService()
  const [events, setEvents] = useState([]);



  useEffect(() => {
    getDataForLocationAuditCalender();
  }, [])

  const getDataForLocationAuditCalender = () => {
    service.getLocationAuditDetails().then(res => {
      if(res.status) {
        setCalenderData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    } )
    
  }

  function content(index) {
    return (
      <>
        <Descriptions column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item label="Location Name"  >
            {calenderData[index]?.location_name}
          </Descriptions.Item>
          <Descriptions.Item label="Total Assets"  >
            {calenderData[index]?.assets_count}
          </Descriptions.Item>
          <Descriptions.Item label="Working Assets"  >
            {calenderData[index]?.working}
          </Descriptions.Item>
          <Descriptions.Item label="Not Working Assets"  >
            {calenderData[index]?.not_working}
          </Descriptions.Item>
        </Descriptions>
      </>
    )
  }


  const updateworkingStatus = (rowData) => {
    console.log(rowData,'------rowdata')
    history.push(`/location-audit-grid/${rowData.location_id}/${rowData.audit_id}`)

  }


  function renderPopoverTitle(values) {
    // let title = (values.status == ServiceStatusEnum.OPEN) ? 'Start' : 'Complete';
    const buttons = values.status == 'Open' ? (<Button onClick={() => updateworkingStatus(values)} size='small' type='primary' shape="round">Start</Button>) : (<></>)
    return <Row justify="space-between"><span>Audit Details</span><span>{buttons}</span></Row>

  }

  // Get the first Monday in the month
  const NoCurrentTimeLine = () => null;
  const eventsData = calenderData?.map((i, index) => {

    return {
        title: i.status != 'Completed' ? <Popover
        title={renderPopoverTitle(i)} content={() => content(index)} overlayStyle={{ width: '600px' }} ><span style={{ color: 'white' }}><h1 style={{fontSize:'11px'}}>{i.location_name ? i.location_name : '-'}<br/>{i.status}</h1></span></Popover> : <Popover title="Audit Details" content={() => content(index)} overlayStyle={{ width: '600px' }} ><span style={{ color: 'white' }}><h1 style={{fontSize:'11px'}}>{i.location_name ? i.location_name : '-'}<br/>{i.status}</h1></span>
      </Popover>,
      start: new Date(i.audit_date),
      end: new Date(i.audit_date),
      status: i.status
    }
  })


   console.log(events)
  const formats = {
    // dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd M/D', culture),
    // weekDayFormat: (date, culture, localizer) => localizer.format(date, 'ddd M/D', culture),
    eventTimeRangeFormat: () => null,
    // agendaTimeFormat: () => null,
    // timeGutterFormat: () => '',

  };

  const eventStyleGetter = (event) => {
    console.log(event,'------------------event-----------------')
    let color = '#536eef';
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - 1);
    const auditDate = new Date(event.start)
    if (event.status == 'Completed') {
      color = '#1be158'
    } else if ( auditDate < currDate && event.status != 'Completed'){
      color = '#dd5959'
    }
    else {
      color = '#536eef'
    }
    return {
      style: {
        backgroundColor: color
      },
    }
  };
  
  return (
    <>

      {/* <h1 >🔵 Open  🔴 Skipped date  🟢 Service Completed  🟡 In Progress</h1> */}
      <div className="App">
        <Row justify="end">
          <Link to="location-audit-form">  <h4>Click here to go to location audit form</h4></Link>
        </Row>
        <br />
        <div style={{ height: 500 }}>
          <Calendar
            events={eventsData}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
            defaultView="month"
            color='white'
            formats={formats}
            eventPropGetter={eventStyleGetter}
            components={{ today: NoCurrentTimeLine }}
            popup={false}

          />
        </div>
      </div>
    </>
  )
}
export default LocationAuditCalender;