import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Tabs, Form, DatePicker, Select, Spin, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined,CheckOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, CheckCircleTwoTone ,SearchOutlined, RollbackOutlined, UndoOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Table } from "ant-table-extensions";
import { FormattedMessage } from 'react-intl';
import { DestinationService, EndCustomersService, UnitcodeService } from '@gtpl/shared-services/masters';
import { DestinationDto, EndCustomerDto, EndCustomersDropDownData } from '@gtpl/shared-models/masters';
import moment from 'moment';
import { ContainerStatusEnum, ContainerLoadingStatus, EmployeeRolesEnum, GlobalStatus, UnitRequest } from '@gtpl/shared-models/common-models';
import { ContaierRegisterRequest, ContainerStatusInfo, FilenameDto } from '@gtpl/shared-models/logistics';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
import { CaptureGrossWeightForm } from '@gtpl/pages/logistics/logistics-components/capture-gross-weight-form'
import Link from 'antd/lib/typography/Link';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'
import './container-register-grid.css';
import appSettings from 'apps/services/config';
import { FoodTypesDto, PlantsDropDown, ProductDto } from '@gtpl/shared-models/masters';
import ArrowDownOutlined from '@ant-design/icons/lib/icons/ArrowDownOutlined';
/* eslint-disable-next-line */
export interface ContainerRegisterGridProps { }

export function ContainerRegisterGrid(props: ContainerRegisterGridProps) {

  const [defaultTab, setDefaultTab] = useState<string>('0');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const [updateState, setUpdateState] = useState(false);
  const [coId1, setCoIdState1] = useState<number>(null);
  const [coId2, setCoIdState2] = useState<number>(null);
  const [coId3, setCoIdState3] = useState<number>(null);
  const [coId4, setCoIdState4] = useState<number>(null);
  const [coId5, setCoIdState5] = useState<number>(null);
  const [coId6, setCoIdState6] = useState<number>(null);
  const [coId7, setCoIdState7] = useState<number>(null);
  const [disable, setDisable] = useState<boolean>(false);
  let location = useLocation();
  const { RangePicker } = DatePicker;

  // setDefaultTab("3");
  const [etdFromDate, setEtdFromDate] = useState(undefined);
  const [etdToDate, setEtdToDate] = useState(undefined);
  const [destination, setDestination] = useState(undefined);
  const [saleOrderId, setSaleOrderId] = useState(undefined)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [containerData, setContainerData] = useState<ContainerStatusInfo[]>([]);
  const [filtersData, setFiltersData] = useState<ContainerStatusInfo[]>([]);
  const [destinationData, setDestinationData] = useState<DestinationDto[]>([]);
  const [destinationDetails, setDestinationDetails] = useState<string[]>([]);
  const [etdData, setEtdData] = useState<string[]>([]);
  const destinationservice = new DestinationService();
  const history = useHistory();
  const text = <span>Set Previous Status</span>
  const [saleId, setSaleId] = useState(0);
  const [modelType, setModelType] = useState<string>('');
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);

  
  const [form] = Form.useForm();
  const { Option } = Select;

  const service = new ContainerRegisterService();
  const saleService = new SaleOrderService();  
  const unitService = new UnitcodeService();


  const download = (filePath) => {
    console.log(filePath);
    // : FilenameDto[]
    
    if (filePath) {
      filePath = filePath.split(",");
      for (const res of filePath) {
        if(res){
          console.log(res);
          setTimeout(() => {
            const response = {
              file: appSettings.logistics_doc_upload_path + `${res}`,
            };
  
            window.open(response.file);
  
          }, 100);
        }
      }
    }
    else {
      AlertMessages.getErrorMessage("Please upload file. ");

    }
  }


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Sea ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }


  const searchData = () => {
    const selectedEtdFromDate = moment(form.getFieldValue('etdDateRange')[0]).format("YYYY-MM-DD");
    const selectedEtdToDate = moment(form.getFieldValue('etdDateRange')[1]).format("YYYY-MM-DD");
    const destination = form.getFieldValue('destination');
    const plantName = form.getFieldValue('plantName');
    console.log(selectedEtdToDate, selectedEtdFromDate, destination);
    let selectedData = filtersData;
    if (selectedEtdFromDate && selectedEtdToDate) {
      selectedData = filtersData.filter(item => moment(item.etd).format("YYYY-MM-DD") > selectedEtdFromDate && moment(item.etd).format("YYYY-MM-DD") < selectedEtdToDate)
    }
    if (destination) {
      selectedData = selectedData.filter(item => item.destination === destination)
    }
    if (plantName) {
      selectedData = selectedData.filter(item => item.plant === plantName)
    }
  
    setContainerData(selectedData);
  }

  useEffect(() => {
    getFiltersDropdownData(filtersData);
  }, [filtersData])
   
  const getFiltersDropdownData = (filtersData) => {
    let etdData: any[] = [];
    let destinationData: any[] = [];
    filtersData.forEach(element => {
      if (element.etd > etdFromDate && element.etd < etdToDate) {
        etdData.push(element.etd)
      }
      if (element.destination) {
        destinationData.push(element.destination)
      }
    });
    setDestinationDetails(destinationData)
    setEtdData(etdData)
  }

  
  useEffect(() => {
    let state = location.state != undefined ? location.state : "Ready to Book";
    const locationDefaultState = state;
    if (locationDefaultState != undefined) {
      if (locationDefaultState === "BOOKED") {
        setDefaultTab("3");
      }else if (locationDefaultState === "Containers in Plant") {
        setDefaultTab("4");
      }else if (locationDefaultState === "Ready to Book") {
        setDefaultTab("2");
      }else if (locationDefaultState === "Loading") {
        setDefaultTab("5");
      }else {
        setDefaultTab("2");
      }
    }
    getFinalDestinationData();
    getAllPlants();
    getAllContainerStatus();
  }, [])

  const getAllContainerStatus = () => {
    const unitReq = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getAllContainerStatusInfo(unitReq).then(res => {
      if (res.status) {
        for (const containerData of res.data ){
           const destinationPlace = `${containerData.destination}, ${containerData.country}`;
           containerData.destination = destinationPlace
        }
        setContainerData(res.data);
        setFiltersData(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.message);
        } else {
          AlertMessages.getErrorMessage(res.message);
        }
        setContainerData([]);
        setFiltersData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setContainerData([]);
      setFiltersData([]);
    })
  }
  const getAllPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    });
  }

  const updateToReadyToBook = (values) => {
    service.updateToReadyToBook(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus();
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const updateContainerStatusReadyToBook = (values) => {
    // if(	Number(values.fgQty) < (Number(values.poQty) / 2 ))
    // if(Number(values.percent)>70)
    //  {
      
    saleService.updateContainerStatusReadyToBook(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus();
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
    // else{
    //   return AlertMessages.getInfoMessage('FG Qty should be grater than 70% of PO qty')
    // }
  

  const updateToBooked = (values) => {
    service.updateToBooked(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const updateStatusToLoading = (values) => {
    service.updateStatusToLoading(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateStatusToDispatchedToPlant = (values) => {
    service.updateToDispatchedToPlant(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateToLoading = (values) => {

    service.updateToLoading(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const updateToLoadedAndSealed = (values) => {
    service.updateToLoadedAndSealed(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getFinalDestinationData = () => {
    destinationservice.getAllActiveDestination().then((res) => {
      if (res.status) {
        setDestinationData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setDestinationData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDestinationData([]);
    });
  }

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata)
  }

  const setCoId1 = (rowdata) => {
    setUpdateState(true);
    setCoIdState1(rowdata)
  }
  const captureGrossWeight = (saleOrderId) => {
    console.log(saleOrderId)
    setIsModalVisible(true);
    // setBookSlot(true);
    setSaleOrderId(saleOrderId);
    setModelType('gross-weight-form')
  }
  const setCoId2 = (rowdata) => {
    setUpdateState(true);
    setCoIdState2(rowdata)
  }
  const setCoId3 = (rowdata) => {
    setCoIdState3(rowdata)
  }
  const setCoId4 = (rowdata) => {
    setUpdateState(true);
    setCoIdState4(rowdata)
    service.initiateDispatch(rowdata).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getAllContainerStatus()

        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const setCoId5 = (rowdata) => {
    setUpdateState(true);
    setCoIdState5(rowdata)
  }

  const setCoId6 = (rowdata) => {
    setDisable(true);
    console.log(rowdata)
    if (rowdata.isInvoiced == GlobalStatus.YES) {
      setUpdateState(true);
      setCoIdState6(rowdata)
      service.shipContainer(rowdata).then(res => {
        if (!res.status) {
          throw new Error(res.internalMessage);
        } else {
          setDisable(false);
          getAllContainerStatus()

          AlertMessages.getSuccessMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage('Invoice should be done to ship the container');
    }
  }

  const handleDestination = (value) => {
    console.log(value)
    setDestination(value)
  }

  

  const tableColumns = (key) => {
    const readyToBookColumns: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'saleId',
        width: '60px',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
      
      {
        title: 'Customer PO',
        dataIndex: 'poNo',
        key: 'poNo',
        width: 150,
        // align:'left',
        sorter: (a, b) => a.poNo?.localeCompare(b.poNo),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('poNo'),
      },
      {
        title: 'Buyer PO',
        dataIndex: 'buyerPoNo',
        key: 'buyerPoNo',
        width: 150,
        // align:'left',
        sorter: (a, b) => a.buyerPoNo?.localeCompare(b.buyerPoNo),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('buyerPoNo'),
      },
     
      {
        title: 'ETD',
        dataIndex: 'etd',
        key: 'etd',
        width: 180,
        align: 'left',
        sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
        sortDirections: ['descend', 'ascend'],
        render: (value, record: ContainerStatusInfo, index) => {
              return (record.etd) ? moment(record.etd).format("YYYY-MM-DD") : ' - ';
            },
      },


      {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
        width: 150,
        ...getColumnSearchProps('destination'),
        sorter: (a, b) => a.destination?.localeCompare(b.destination),
        sortDirections: ['descend', 'ascend'],
          render: (text,data,index) => {
            return <span>{text }</span>
          }

      },
      
      {
        title: 'End Customer',
        dataIndex: 'endCustomer',
        key: 'endCustomer',
        width: 150,
        ...getColumnSearchProps('endCustomer'),
        sorter: (a, b) => a.endCustomer?.localeCompare(b.endCustomer), 
        sortDirections: ['descend', 'ascend'],

      },
    ]
    const bookedColumns: ColumnProps<any>[] = [
      {
        title: 'Reference No',
        dataIndex: 'referenceNumber',
        width: 150,
        sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('referenceNumber'),
        render: (value, record: ContainerStatusInfo, index) => {
          return (record.referenceNumber) ? record.referenceNumber : ' - ';
        },
      },
      {
        title: 'SCAC',
        dataIndex: 'scac',
        width: 150,
        sorter: (a, b) => a.scac?.localeCompare(b.scac),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('scac'),
        render: (value, record: ContainerStatusInfo, index) => {
          return (record.scac) ? record.scac : ' - ';
        },
      }
    ]
    const plantColumn: ColumnProps<any>[] = [
      {
        title: 'Prod Unit',
        dataIndex: 'plant',
        width: 150,
        sorter: (a, b) => a.plant?.localeCompare(b.plant),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('plant'),
      },
    ]
    const containerColumn: ColumnProps<any>[] = [
      {
        title: 'Container Number',
        dataIndex: 'containerNo',
        width: 150,
        align: 'left',
        sorter: (a, b) => a.containerNo?.localeCompare(b.containerNo),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('containerNo'),
        render: (value, record: ContainerStatusInfo, index) => {
          return (record.containerNo) ? record.containerNo : ' - ';
        },
      }
    ]

    const fgQtyColumns : ColumnProps<any>[]=[
      {
        title : 'PO Qty',
        dataIndex : 'poQty',
        width: 100,
        render: (value, record, index) => {
          return (record.poQty) ? record.poQty : ' - ';
        },
      },
      {
        title : 'FG Qty',
        dataIndex : 'fgQty',
        width: 100,
        render: (value, record, index) => {
          return (record.fgQty) ? record.fgQty : ' - ';
        },
      },
      {
        title : 'FG%',
        dataIndex : 'fgQty',
        width: 100,
        render: (value, record, index) => {
          return  (record.fgQty) ? ((record.fgQty * 100) / record.poQty ).toFixed(2): ' - ';
        },
        filters: [
          {
            text: 'Above 70%',
            value: true,
          },
          {
            text: 'Below 70%',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          if(value == true){
            return ((record.fgQty * 100) / record.poQty ) > 70;
          }else{
            return ((record.fgQty * 100) / record.poQty ) < 70;
          }
        },
      },
      {
        title: 'Production Status',
        dataIndex: 'productionStatus',
        key: 'productionStatus',
        width: 150,
        ...getColumnSearchProps('productionStatus'),
        sorter: (a, b) => a.productionStatus?.localeCompare(b.productionStatus), 
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Inspection Status',
        dataIndex: 'inspectionStatus',
        key: 'inspectionStatus',
        width: 150,
        ...getColumnSearchProps('inspectionStatus'),
        sorter: (a, b) => a.inspectionStatus?.localeCompare(b.inspectionStatus), 
        sortDirections: ['descend', 'ascend'],

      },
    ]
    
    const actionColumns: ColumnProps<any>[] = [
      {
        title: 'Action',
        dataIndex: 'containerStatus',
        width: 200,
        // responsive: ['lg'],
        render: (containerStatus, rowData: ContainerStatusInfo, record) => (
          <>
            {containerStatus == ContainerStatusEnum.NOT_READY ? <span>{(coId1) ?
              <Redirect to={{ pathname: "/container-booking", state: coId1 }}
              /> : null}<Button style={{ backgroundColor: '#8fec79', color: "black", fontWeight: 'bold' }}
                onClick={() => {  updateContainerStatusReadyToBook(rowData); }}>READY TO BOOK</Button>
            </span> :
              containerStatus == ContainerStatusEnum.READY_TO_BOOK ?
                <span>{(coId1) ?
                  <Redirect to={{ pathname: "/container-booking", state: coId1 }}
                  /> : null}
                  {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.ADMIN)|| ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) || ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.LOGISTICS_HEAD)) 
                  //&&
                  // <><Tooltip placement="top" title={rowData.grossWeight?"Gross Weight Captured":"Need to Capture Gross Weight"}><Button style={{ backgroundColor: '#7ec1ff', color: "black", fontWeight: 'bold' }} onClick={() => { captureGrossWeight(rowData.saleOrderId); } }>{rowData.grossWeight?<CheckCircleTwoTone twoToneColor="#52c41a" />:""}GROSS WEIGHT</Button></Tooltip></>
                }
                  {/* {(rowData.grossWeight) ? <Button style={{ backgroundColor: '#722ed1', color: "black", fontWeight: 'bold' }}
                    onClick={() => { setCoId1(rowData); }}>BOOK A SLOT</Button> : ""} */}

                  <Button style={{ backgroundColor: '#b3e897', color: "black", fontWeight: 'bold' }}
                    onClick={() => { setCoId1(rowData); }}>BOOK A SLOT</Button>

                  {/* <Divider type="vertical" /><Tooltip placement="top" title={text}><Button><RollbackOutlined /></Button></Tooltip> */}
                </span> : 
                containerStatus == ContainerStatusEnum.BOOKED ?
                  <span>{(coId2) ? <Redirect to={{ pathname: "/container-dispatch-to-plant", state: coId2 }}
                  /> : null}
                  <Button style={{ backgroundColor: '#7ec1ff', color: "black", fontWeight: 'bold' }}
                        onClick={() => { setCoId2(rowData); } }>ADD CONTAINER NO.</Button><Divider type="vertical" /><Tooltip placement="top" title={text}>
                          <UndoOutlined style={{fontSize: '20px',marginLeft:'5px'}} onClick={() => { updateToReadyToBook(rowData); } }/>
                          {/* <Button onClick={() => { updateToReadyToBook(rowData); } }><RollbackOutlined /></Button> */}
                          </Tooltip></span> :
                  containerStatus == ContainerStatusEnum.LOADING ?
                    <span>{(coId3) ? <Redirect to={{ pathname: "/assign-so", state: coId3 }}
                    /> : null}{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE)) && <><Tooltip placement="topLeft" title={rowData.loadingStatus == ContainerLoadingStatus.LOADED ?"SO Assigned":" Need to Assign SO"}><Button style={{ backgroundColor: '#f2d18f', color: "black", fontWeight: 'bold' }}
                        onClick={() => { setCoId3(rowData); } }>{rowData.loadingStatus == ContainerLoadingStatus.LOADED ?
                          <CheckCircleTwoTone style={{ color: 'green' }} />
                          : ""}ASSIGN SO</Button></Tooltip></>
                      }
                      {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) ) &&
                        <Button style={{ backgroundColor: '#a2ee7c', color: "black", fontWeight: 'bold' }} onClick={() => { updateStatusToLoading(rowData) }}>CONFIRM</Button>}
                      {rowData.loadingStatus == ContainerLoadingStatus.LOADED ? ((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) ) &&
                        <Button style={{ backgroundColor: '#7ec1ff', color: "black", fontWeight: 'bold' }}
                          onClick={() => { setCoId5(rowData); }}>FILL SEAL INFORMATION</Button> : ''}<span>{(coId5) ? <Redirect to={{ pathname: "/fill-seal-info", state: coId5 }}
                          /> : null}</span>
                      <Divider type="vertical" />
                      <Tooltip placement="top" title={text}>
                      <UndoOutlined style={{fontSize: '20px',marginLeft:'5px'}} onClick={() => { updateStatusToDispatchedToPlant(rowData) }}/>

                        {/* <Button onClick={() => { updateStatusToDispatchedToPlant(rowData) }}><RollbackOutlined /></Button> */}
                        </Tooltip></span> :
                    containerStatus == ContainerStatusEnum.DISPATCHED_TO_PLANT ?
                      <span>
                        {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD)) &&
                          <Button style={{ backgroundColor: '#d4b106', color: "black", fontWeight: 'bold' }}
                            onClick={() => { setCoId4(rowData); }}>INITIATE DISPATCH</Button>}
                        <Divider type="vertical" />
                        {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER)) &&
                          <span>
                          </span>
                        }
                        {((JSON.parse(localStorage.getItem('role')) != EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE)) &&
                        <><Divider type="vertical" /><Tooltip placement="top" title={text}>
                      <UndoOutlined style={{fontSize: '20px',marginLeft:'5px'}}  onClick={() => { updateToBooked(rowData); } }/>

                          {/* <Button onClick={() => { updateToBooked(rowData); } }><RollbackOutlined /></Button> */}
                        </Tooltip></> }
                      </span> :
                      containerStatus == ContainerStatusEnum.LOADED_AND_SEALED ?
                        <span><Popconfirm title={rowData.stockId != null?"Are you sure to ship Sub job also & move stock to bulk ?":"Are you sure？"} okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
                          setCoId6(rowData);
                        }}><Button style={{ backgroundColor: '#72b7f0', color: "black", fontWeight: 'bold' }}>SHIP THE CONTAINER</Button></Popconfirm>
                          <Divider type="vertical" /><Tooltip placement="top" title={text}>
                      <UndoOutlined style={{fontSize: '20px',marginLeft:'5px'}}  onClick={() => { updateToLoading(rowData) }}/>

                            
                            {/* <Button onClick={() => { updateToLoading(rowData) }}><RollbackOutlined /></Button> */}
                            </Tooltip><br /><Tooltip placement="top" title={'Download Seal Info'}><Button style={{ marginLeft: 20 }} onClick={() => { download(rowData.filePath); }}> <ArrowDownOutlined />Download</Button></Tooltip></span> :
                        containerStatus == ContainerStatusEnum.SHIPPED ?
                          <span><Divider type="vertical" /><Tooltip placement="top" title={text}>
                      <UndoOutlined style={{fontSize: '20px',marginLeft:'5px'}}  onClick={() => { updateToLoadedAndSealed(rowData) }}/>

                            {/* <Button onClick={() => { updateToLoadedAndSealed(rowData) }}><RollbackOutlined /></Button> */}
                            </Tooltip></span> : ""}
            {/* <Divider type="vertical" /> */}

            {/* {containerStatus=="NOT_READY"?
  <span>
          {(object) ?<Redirect
            to={{
              pathname: "/container-register-form",
              state: object
            }}
          />:null}<EditOutlined className={'editSamplTypeIcon'} type="edit" name="edit"
            onClick={() => {
              
                setUpdatevalue(rowData);
              
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
        </span>:""} */}

          </>

        ),
      },
    ]


    if (key === "2") {
      return [...readyToBookColumns, ...bookedColumns, ...plantColumn,...fgQtyColumns, ...actionColumns, ];
    }
    else if (key === "3") {
      return [...readyToBookColumns, ...bookedColumns, ...containerColumn, ...plantColumn,...fgQtyColumns, ...actionColumns, ];
    }
    else if (key === "4") {
      return [...readyToBookColumns, ...bookedColumns, ...containerColumn, ...plantColumn,...fgQtyColumns, ...actionColumns, ]
    }
    else {
      return [...readyToBookColumns, ...plantColumn,...fgQtyColumns, ...actionColumns, ];
    }
  }

  const onReset = () => {
    // form.resetFields();
    form.resetFields();
    setSearchText('');
    setContainerData(filtersData);
    // getFiltersDropdownData(filtersData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getAllContainerStatus();
  };
  return (
    <>
      {/* {(saleOrderId)? <Redirect to={{ pathname: "/so-info-view", state: saleOrderId }} /> : null} */}
      

      <Card title={<span style={{ color: 'white' }}>Container Register</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

        <br></br>
        <div className="card-container">

          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            {
              <TabPane
                // tab="Open Orders"
                key="1"
                tab={<span style={{ color: "#f5222d" }}>{'Open: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.NOT_READY).length)}</span>}
              >
                <Table
                  key={defaultTab}
                  rowKey={record => record.saleOrderNumber}
                  columns={tableColumns("1")}
                  dataSource={containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.NOT_READY )}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y:500 }}
                  size="middle"
                  bordered />
                {/* } */}
              </TabPane>
            }
            {
              <TabPane
                // tab="In-Procurement" 
                key="2"
                tab={<span style={{ color: "#52c41a" }}>{'Ready to Book: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.READY_TO_BOOK).length)}</span>}
              >
                <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                
                  <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                  </Form.Item>
                  <Row gutter={[24, 24]}>
                    <Col span={6}>
                      <Form.Item name="etdDateRange" label="ETD From Date  ETD To Date" rules={[{
                        required: false,
                        message: "ETD From Date"
                      },
                      ]}>
                        <RangePicker />
                      </Form.Item>
                    </Col>
                    {/* <Col span={3}>
                      <Form.Item name="etdFromDate" label="ETD From Date"rules={[  {
                        required: false,
                        message: "ETD From Date"
                      },
                      ]}>
                        <DatePicker placeholder={"select from date"}  onChange={EstimatedFromDate} picker="date" />
                      </Form.Item>
                    </Col>
                    
                    <Col span={3} >
                      <Form.Item name="etdToDate"  label="ETD To Date"  rules={[    {
                        required: false,
                        message: "ETD To Date"
                      },
                      ]}>
                        <DatePicker placeholder={"select to date"} onChange={EstimatedToDate} picker="date" />
                      </Form.Item>
                    </Col> */}
                     <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                     <Form.Item
                    name="plantName"
                    label=" Plant"
                    rules={[
                      {
                        required: false, message: 'Select Plant',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Select Plant"
                      // onChange={handlePlant}
                      allowClear
                      style={{ width: '100%' }}
                    >
                      {plantData.map(dropData => {
                        return  <Option value={dropData.plantName}>{dropData.plantName}</Option>
                      })}
                    </Select>

                  </Form.Item>

                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                      <Form.Item
                        name="destination"
                        label="Destination"
                        rules={[
                          {
                            required: false, message: 'Select Destination',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          placeholder="Select Destination"
                          onChange={handleDestination}
                          allowClear
                          style={{ width: '100%' }}
                        >
                          {destinationData.map(dropData => {
                            return <Option value={dropData.destinationName}>{dropData.destinationName}</Option>
                          })}
                        </Select>

                      </Form.Item>
                    </Col>

                    <Col style={{ marginTop: 30 }} span={5}>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        // size="small"
                        block onClick={() => searchData()}
                        style={{ marginRight: 2, width: 100 }}
                      >
                        Search
                      </Button>
                      {/* <Button type='primary' block onClick={()=>searchData()}>Get</Button> */}
                      <Button type="primary" htmlType="submit" onClick={onReset}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>

                <Table
                  key={defaultTab}
                  rowKey={record => record.saleOrderNumber}
                  columns={tableColumns("1")}
                  dataSource={containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.READY_TO_BOOK)}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />

              </TabPane>
            }
            {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER)) &&
              <TabPane
                // tab="Work in Progress"
                key="3"
                tab={<span style={{ color: "#722ed1" }}>{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER)) ?'Booked: ' +  (containerData.filter(el => el.containerStatus == ContainerStatusEnum.BOOKED).length):'Booked: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.BOOKED && el.plantId === Number(localStorage.getItem("unit_id"))).length)}</span>}
              >
                <Table
                  key={defaultTab}
                  rowKey={record => record.containerId}
                  columns={tableColumns("2")}
                  dataSource={((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER))?containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.BOOKED):containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.BOOKED && rec.plantId === Number(localStorage.getItem("unit_id")))}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />
              </TabPane>
            }
            {
              <TabPane
                // tab="Work in Progress"
                key="4"
                tab={<span style={{ color: "#faad14" }}>{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER)  || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD) ) ? 'Containers in Plant: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.DISPATCHED_TO_PLANT).length):'Containers in Plant: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.DISPATCHED_TO_PLANT && el.plantId === Number(localStorage.getItem("unit_id"))).length)}</span>}
              >
                <Table
                  key={defaultTab}
                  rowKey={record => record.containerId}
                  columns={tableColumns("3")}
                  dataSource={((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER)  || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD) )?containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.DISPATCHED_TO_PLANT):containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.DISPATCHED_TO_PLANT && rec.plantId === Number(localStorage.getItem("unit_id")))}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />
              </TabPane>
            }
            {
              <TabPane
              
                // tab="Work in Progress"
                key="5"
                tab={<span style={{ color: "#d4b106" }}>{(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN || JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE)?'Loading: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.LOADING).length):'Loading: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.LOADING && 
                  el.plantId === Number(localStorage.getItem("unit_id"))).length)}</span>}
              >
                <Table
                  key={defaultTab}
                  rowKey={record => record.containerId}
                  columns={tableColumns("3")}
                  dataSource={(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN || JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE)?containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.LOADING) :containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.LOADING && rec.plantId === Number(localStorage.getItem("unit_id")))}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />
              </TabPane>
            }
            {
              <TabPane
                // tab="Work in Progress"
                key="6"
                tab={<span style={{ color: "#eb2f96" }}>{(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN || JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) ?'Loaded and Sealed: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.LOADED_AND_SEALED).length) : 'Loaded and Sealed: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.LOADED_AND_SEALED && el.plantId === Number(localStorage.getItem("unit_id"))).length)}</span>}
              >
                <Table
                  key={defaultTab}
                  rowKey={record => record.containerId}
                  columns={tableColumns("3")}
                  dataSource={(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN || JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE)?containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.LOADED_AND_SEALED ) : containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.LOADED_AND_SEALED && rec.plantId === Number(localStorage.getItem("unit_id")))}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />
              </TabPane>
            }
            {
              <TabPane
                // tab="Work in Progress"
                key="7"
                tab={<span style={{ color: "#08979c" }}>{'Shipped: ' + (containerData.filter(el => el.containerStatus == ContainerStatusEnum.SHIPPED).length)}</span>}
              >

                <Col style={{ paddingLeft: '80%' }}>

                  <Button ><CSVLink filename={"logistics-shipped-data.csv"} data={containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.SHIPPED)} >
                    Get Excel

                  </CSVLink>
                  </Button>
                </Col>
                <Table
                  key={defaultTab}
                  rowKey={record => record.containerId}
                  columns={tableColumns("3")}
                  dataSource={containerData.filter(rec => rec.containerStatus == ContainerStatusEnum.SHIPPED)}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  scroll={{ y: 500 }}
                  size="small"
                  bordered />
              </TabPane>
            }
          </Tabs>
          {isModalVisible ?
            <>
              <Modal
                className='print-docket-modal'
                key={'modal' + Date.now()}
                width={'80%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={isModalVisible}
                title={<React.Fragment>
                </React.Fragment>}
                onCancel={handleCancel}
                footer={[

                ]}
              >

                
                {(modelType == 'gross-weight-form') ?
                  <CaptureGrossWeightForm saleOrderId={saleOrderId} setModalVisible={handleCancel} /> : null}

                  {(modelType == 'so-info-view') ? <SoInfoViewPages saleOrderId={saleId} screen="logistics" /> : ''}
              </Modal></>
            : ""}
        </div>
      </Card>
    </>
  );
}

export default ContainerRegisterGrid;