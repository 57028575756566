import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, TimePicker, Drawer, AutoComplete, Collapse, Descriptions, Switch, Radio, Space, Table, Typography, Modal, Divider } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { SaleOrderService, saleOrder, SaleOrderItemsRequest } from '@gtpl/shared-services/sale-management';
import './code-list-main-form.css';
import { CodeListMainService } from '@gtpl/shared-services/planning';
import { SaleOrderDetailViewInfoDTO, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import moment from 'moment';
import { CodeListMainItemsDto } from 'libs/shared-models/planning/src/lib/code-list-main/code-list-main-items.dto';
import { CodeListMainDto } from '@gtpl/shared-models/planning';
import CodeListItems from './code-list-items';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import TextArea from 'antd/lib/input/TextArea';

/* eslint-disable-next-line */
export interface CodeListMainFormProps {
  isUpdate: boolean;
  closeForm: () => void;
  selectedData: CodeListMainDto;
}

export function CodeListMainForm(
  props: CodeListMainFormProps
) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Option } = Select;
  const codeListMainService = new CodeListMainService()
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [soItemDetails, setSoItemDetails] = useState<SaleOrderDetailViewInfoDTO>(undefined);
  const [packStyle, setPackStyle] = useState<any[]>([])
  let history = useHistory()
  const [codeListItemsFrom] = Form.useForm()
  const [soItemDropdown, setSoItemDropdown] = useState<any[]>([])
  const [codeListItems, setcodeListItems] = useState<CodeListMainItemsDto[]>([])
  const soService = new SaleOrderService();
  const [codeListUpdateData, selectedCodeListUpdateData] = useState<any[]>([])
  const [itemsForm] = Form.useForm()

  let totCartons = 0;


  useEffect(() => {
    getSaleOrdersDropdown();
  }, []);

  useEffect(() => {
    if (props.isUpdate) {
      getSoItemsDropdown();
    }
  }, [])

  // const getCodeListUpdateData = (codeListmainId: number) => {
  //   const req = new CodeListMainId()
  //   req.codeListMainId = codeListmainId
  //   codeListMainService.getCodeListDataForUpdate(req).then((res) => {
  //     if (res.status) {
  //       selectedCodeListUpdateData(res.data)
  //       setcodeListItems(res.data)
  //     } else {
  //       selectedCodeListUpdateData([])
  //       setcodeListItems([])
  //     }
  //   }).catch((err) => {
  //     selectedCodeListUpdateData([])
  //     setcodeListItems([])
  //   })
  // }

  const getSaleOrdersDropdown = () => {
    const req = new PlantRequest()
    req.plantId = Number(localStorage.getItem('unit_id'))
    soService.getAllActiveSaleOrdersForCodeList(req).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getSoItemsDropdown = () => {
    itemsForm.resetFields()
    setcodeListItems([])
    const saleOrderId = form.getFieldValue('saleOrderId')
    const req = new saleOrder(saleOrderId, Number(localStorage.getItem('unit_id')))
    soService.getProductForSoId(req).then((res) => {
      if (res.status) {
        setSoItemDropdown(res.data)
      } else {
        setSoItemDropdown([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoItemDropdown([]);
    })
  }


  const saveData = () => {
    form.validateFields().then((mainForm) => {
      const codeListItemsData = []
      const soItemValidationFlag = new Set<boolean>();
      let totalSoJobCases = 0
      soItemDropdown.forEach((el) => { totalSoJobCases += el.jobCases })
      codeListItems.forEach((el) => {
        totCartons += el.cartons;
        // const soItemData = soItemDropdown.filter((i) => { return i.saleOrderItemId == el.soItemId });
        // soItemValidationFlag.add(true)
        // if (el.cartons != soItemData[0].jobCases) {
        //   soItemValidationFlag.has(true)
        // }
        return codeListItemsData.push(new CodeListMainItemsDto(el.soItemId, el.code, el.tracebilityCodeId, el.cartons, el.productionDate, el.bestBeforeDate, undefined, localStorage.getItem('createdUser'), undefined, localStorage.getItem('createdUser'), 1, el.unitId, el.codeListMainItemsId,mainForm.codeListMainId))
      });

      if (totCartons != totalSoJobCases) return AlertMessages.getInfoMessage('Saleorder not fulfilled,Please add code lists for all the cases')
      // if (totCartons != soItemDetails.totCases) return AlertMessages.getInfoMessage('Total cases should not exceed ordered cases')
      const codeListMainDto = new CodeListMainDto(mainForm.codeListMainId, mainForm.saleOrderId, totCartons, localStorage.getItem('createdUser'), 'admin', localStorage.getItem('createdUser'), true, codeListItems, props.isUpdate ? true : false,mainForm.remarks)
      codeListMainService.createCodeListInfo(codeListMainDto).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Code List  created sucessfully');
          history.push('/code-list-main-grid')
        } else {
          AlertMessages.getErrorMessage("Error while creating Code List");
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }


  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const onReset = () => {
    form.resetFields();
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }



console.log(props.selectedData)
console.log(form.getFieldsValue())

  return (
    <Card title={<span style={{ color: 'white' }}>CodeList Allocation</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={ !props.isUpdate && <Link to='/code-list-main-grid'>{<span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>}</Link>}
    >
      <Form form={form} name="control-hooks"
        layout="vertical"
        onFinish={saveData}
        initialValues={props.selectedData}
      >
        <Row gutter={24} >
          <Form.Item name="codeListMainId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="saleOrderId" label="PO number" rules={[
              {
                required: true,
                message: 'Sale Order is Required'
              },
            ]}>
              <Select
                disabled={props.isUpdate}
                showSearch
                placeholder="Select Sale Order"
                optionFilterProp="children"
                onChange={() => {  getSoItemsDropdown(); }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onClear={onReset}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option key={0} value={null}>Select Sale Order</Option>
                {saleOrderData.map((saleOrder) => {
                  return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="remarks" label="Remarks/Instructions">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          {/* <Descriptions style={{ display: (soItemDetails) ? 'unset' : 'none' }} column={{ xxl: 5, xl: 5, lg: 5, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Country" >
              {soItemDetails?.country}
            </Descriptions.Item>
            <Descriptions.Item label="Buyer" >
              {soItemDetails?.endCustomer}
            </Descriptions.Item>
          
            <Descriptions.Item label="Total Cartons" >
              {soItemDetails?.totCases}
            </Descriptions.Item>
          </Descriptions> */}
        </Row>
        {/* <Divider type='horizontal' /> */}
      </Form>
      <CodeListItems processingUnitId={soItemDropdown[0]?.processingUnitId} isUpdate={props.isUpdate} selectedData={props.selectedData} itemsForm={itemsForm} soItemDropdown={soItemDropdown} setTableData={setcodeListItems} />
      {(codeListItems?.length > 0) && <Row justify='end' gutter={24}>
        <br /><br />
        <Col span={3}>
          <Button type='primary' onClick={saveData}>Submit</Button>
        </Col>
      </Row>}
    </Card >
  );
}

export default CodeListMainForm;
