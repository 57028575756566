import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import {GradesDto} from '@gtpl/shared-models/masters';
import {GradeServices} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ClassValidator } from '@gtpl/shared-utils/class-validators';
import { Link, useHistory } from "react-router-dom";
import './grades-form.css'

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

export interface GradeFormProps {
  gradesData:GradesDto;
  updateGrade: (gradesData:GradesDto) => void;
  isUpdate: boolean;
  // saveItem:(gradesData:GradesDto) => void;
  closeForm: () => void;
}

export function GradeForm(props: GradeFormProps) {
       const [form] = Form.useForm();
       const service = new GradeServices();
       const [disable,setDisable]=useState<boolean>(false)
       let history = useHistory();

       
    const classValidator = new ClassValidator();

    let createdUser="";
    if(!props.isUpdate){
      createdUser= localStorage.getItem("createdUser");
    }

       const saveGrade = (gradesData: GradesDto) => {
         setDisable(true)
        gradesData.gradeId=0;
        if(Number(form.getFieldValue("gradeMinCount")) <= Number(form.getFieldValue("gradeMaxCount"))){
          console.log(form.getFieldValue("gradeMinCount"),'mingarde')
          service.createGrade(gradesData).then(res => {
            setDisable(false)
            if (res.status) {
              AlertMessages.getSuccessMessage('Grade Created Successfully');
              history.push("/Grades-view");
              onReset();
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            setDisable(false)
            AlertMessages.getErrorMessage(err.message);
          })
        }else{
          AlertMessages.getInfoMessage("Max Grade should be greater than or equal's to Min grade")
        }
              }
/**
 * 
 * @param values //Dto values
 */
  const saveData = (values: GradesDto) => {
    setDisable(false)
        // console.log(values);
        if(props.isUpdate){
          props.updateGrade(values);
        }else{
          setDisable(false)
          saveGrade(values);
        }
      
      };

      /**
       * To reset form fields
       */
  const onReset = () => {
    form.resetFields();

  


  };
  return (
    <Card title={<span style={{color:'white'}}>Grades</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true?"":<Link to='/Grades-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >



      
    <Form layout={'vertical'} form={form} initialValues={props.gradesData} name="control-hooks" onFinish={saveData}>
    <Form.Item name="gradeId" style={{display:"none"}} >
      <Input hidden/>
    </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser" initialValue={createdUser} >
      <Input hidden/>
    </Form.Item>
    <Row gutter={20} >
      <Col span={8} >
    <Form.Item
      name="gradeName"
      label="Grade Name"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input/>
    </Form.Item>
    </Col>
    <Col span={8}>
    <Form.Item
      name="gradeMinCount"
      label="Minimum Grade"
      rules={[
        {
          required: true,
        },
        ...classValidator.onlyNumeric()
      ]}
    >
      <Input/>
    </Form.Item>
    </Col>
    <Col span={8}>
    <Form.Item
      name="gradeMaxCount"
      label="Maximum Grade"
      rules={[
        {
          required: true,
        },
        ...classValidator.onlyNumeric()
      ]}
    >
      <Input/>
    </Form.Item>
    </Col>
    </Row>
  
   <Row>
   <Col span={24} style={{ textAlign: 'right' }}>
    
      <Button type="primary" disabled={disable} htmlType="submit" >
        Submit
      </Button>
      {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      
      </Col>
    </Row>
  </Form>
  </Card>
  );
}

export default GradeForm;
