import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {JobNotificationsForm } from '@gtpl/pages/hrms/hrms-components/job-notifications-form';

import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';

import './job-notifications-grid.css';

/* eslint-disable-next-line */
export interface JobNotificationsGridProps {}

export function JobNotificationsGrid(props: JobNotificationsGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [slectedJobNotificationsData, setSelectedJobNotificationsData] = useState<any>(undefined);
     
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const update = ( )=>{

  }

    
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    
  const columnsSkelton: ProColumns[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      hideInSearch: true,
      hideInForm: true,
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
	{
      title: 'Department ',
      dataIndex: 'Department',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.user.localeCompare(b.ClientName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Client Name')
    },
	{
      title: 'Job Description',
      dataIndex: 'Job Descriptionr',
      hideInSearch: true,
      hideInForm: true,
      sorter: (a, b) => a.user.localeCompare(b.JobDescription),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Job Description')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: true,
      onFilter: true,
      valueEnum: {
        true: { text: 'Active', status: 'Success' },
        false: { text: 'InActive', status: 'Error' }
      }      
    },	
	{
      title:`Action`,
      dataIndex: 'action',
      hideInSearch: true,
      hideInForm: true,
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
            />
          
          <Divider type="vertical" />
            <Popconfirm 
            
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate JobNotifications ?'
                :  'Are you sure to Activate JobNotifications  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Job Notifications</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/job-notifications-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
        </Row>
          <br></br>
          <ConfigProvider locale={enUSIntl}>
            <ProTable
              columns={columnsSkelton}
              rowKey={record => record.JobNotificationsId}
              request={(params, sorter, filter) => {
                return Promise.resolve({
                  
                  success: true,
                });
              }}
              scroll = {{x:true}}
              
              dateFormatter = 'string'
              search = {{
                searchText: 'Filter',
              }}
              
            />
          </ConfigProvider>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <JobNotificationsForm key={Date.now()} 
updatejobnotificationType ={update}
jobNotificationData= {slectedJobNotificationsData}
                isUpdate={true}                
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default JobNotificationsGrid;
