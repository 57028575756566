import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Button, Card, Checkbox, Col, Descriptions, Drawer, Form, Input, Row, Select, Table, Typography, DatePicker, Alert } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined, BarcodeOutlined } from '@ant-design/icons';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import Scanner from '../../../asset-classification-form/src/lib/Scanner';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { Excel } from 'antd-table-saveas-excel';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetTypeEnum } from "libs/shared-models/common-models/src/lib/enum/asset-type.enum";
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { AssetsDetailedView} from '../../../aasets-grid/src/lib/Detailed-view';

const { RangePicker } = DatePicker;
const { Option } = Select;


/* eslint-disable-next-line */
export interface DecommisionedAssetsReportProps { }

export function DecommisionedAssetsReport(
  props: DecommisionedAssetsReportProps
) {

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [form] = Form.useForm();
  const service = new AssetService();
  const [assetsData, setAssetsData] = useState<any>([]);
  const [assetCodeData, setAssetCodeData] = useState<any>([]);
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const [code, setCode] = useState(0);
  const { Text } = Typography;
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [length, setLength] = useState(''); const role = JSON.parse(localStorage.getItem('role'));
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const employeeService = new EmployeeService();

  let diff = 0;
  let date;
  let todate;


  var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear, currentMonth, 1)
  const dateformat = 'YYYY-MM-DD';



  useEffect(() => {
    employeeDetails();
    getDepartments();
    getDecommissionedAssets();
    getAllPlants();
    getItemCategory();
    getAssetCode();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations()
  },[])

  const getAssetCode = () => {
    const req = new AssetByStatus()
    req.plantId = Number(localStorage.getItem('unit_id'))
    service.getDecommissioneddAssetsReportDropdown(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
    const getAllPlants = () => {
      plantsService.getAllPlants().then(res => {
        if(res.status){
          setPlantName(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
  
    const getLocations = () => {
      const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
      locationService.getAllAssets(req).then(res => {
        if(res.status){
          setLocationData(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }


  const getDecommissionedAssets = () => {
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('date') !== undefined) {
      req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('date') !== undefined) {
      req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeName') !== undefined) {
      req.employeeId = form.getFieldValue('employeeName')
    }
    service.getDecommissioneddAssetsReport(req).then(res => {
      if (res.status) {
        setAssetsData(res.data)
        setLength(res.data.length)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Retired assets retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')

        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const AssetsDetailedView = (AssetsCode: number) => {
    setCode(AssetsCode)
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });


  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },

    {
      title: 'Plant',
      dataIndex: 'plant',
      align: 'left',
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
      ...getColumnSearchProps('plant')

    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      align: 'left',
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
      ...getColumnSearchProps('location_name')

    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      align: 'left',
      render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
      filters: [
        {
          text: 'MOVABLE',
          value: 'MOVABLE',
        },

        {
          text: 'FIXED',
          value: 'FIXED',
        },
        {
          text: 'SOFTASSET',
          value: 'SOFTASSET',
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.asset_type === value;
      },

    },
    {
      title: 'Asset Category',
      dataIndex: 'asset_category',
      align: 'left',
      sorter: (a, b) => a.asset_category.localeCompare(b.asset_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_category')


    },
    {
      title: 'Asset Name',
      dataIndex: 'assets_name',
      align: 'left',
      sorter: (a, b) => a.assets_name.localeCompare(b.assets_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assets_name')


    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: 'right',
      sorter: (a, b) => a.assetsCode.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      // render: (text, record) => {
      //   return <>
      //     <Button type='link' onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
      //   </>
      // }
    },
    {
      title: 'Purchased Date',
      dataIndex: 'date_of_purchased',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expired_date',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Reitred Date',
      dataIndex: 'updated_at',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            {record.updated_at ? moment(record.updated_at).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }

    },
    {
      title: 'Approved By',
      dataIndex: 'decommission_approved_by',
      align: 'left',
      ...getColumnSearchProps('decommission_approved_by'),
      render: (text, record) => {
        return (
          <>
            {record.decommission_approved_by ? record.decommission_approved_by : '-'}
          </>
        )
      }
    },
    {
      title: 'Department',
      dataIndex: 'dept_name',
      align: 'left',
      ...getColumnSearchProps('dept_name'),
      render: (text, record) => {
        return (
          <>
            {record.dept_name ? record.dept_name : '-'}
          </>
        )
      }
    },
    {
      title: 'Asset Age',
      dataIndex: 'assetAge',
      align: 'right',
      sorter: (a, b) => a.assetAge-b.assetAge,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {record.date_of_purchased ? diff : null}
          </>
        )
      },
    },
    {
      title: 'Asset Cost',
      dataIndex: 'asset_cost',
      align: 'right',
      sorter: (a, b) => a.asset_cost-b.asset_cost,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.asset_cost ? Number(Number(record.asset_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      }
    },
    // {
    //     title:'status',
    //     dataIndex:'status',
    //     align:'right',
    //     render:(text,record) => {
    //       return(
    //         <>
    //         {record.status ? record.status: '-'}
    //         </>
    //       )
    //     }
    //   },



  ];


  const onReset = () => {
    form.resetFields();
    getDecommissionedAssets();

  }

  const onStatusSearch = () => {
    getDecommissionedAssets();
  }

  const exportedData = [];
  const excelData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Plant', dataIndex: 'plant' },
    { title: 'Location', dataIndex: 'location_name', render: (text, record) => { return record.location_name ? record.location_name : '-' } },
    { title: 'Asset Type', dataIndex: 'asset_type', render: (text, record) => { return record.asset_type ? record.asset_type : '-' } },
    { title: 'Department', dataIndex: 'dept_name', render: (text, record) => { return record.dept_name ? record.dept_name : '-' } },
    { title: 'Asset Category', dataIndex: 'asset_category', render: (text, record) => { return record.asset_category ? record.asset_category : '-' } },
    { title: 'Asset Name', dataIndex: 'assets_name', render: (text, record) => { return record.assets_name ? record.assets_name : '-' } },
    { title: 'Asset Code', dataIndex: 'assetsCode', render: (text, record) => { return record.assetsCode ? record.assetsCode : '-' } },

    { title: 'Expiry Date', dataIndex: 'expired_date', render: (text, record) => { return record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD HH:mm:ss') : '-' } },
    { title: 'Retired Date', dataIndex: 'updated_at', render: (text, record) => { return record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD HH:mm:ss') : '-' } },
    { title: 'Approved By', dataIndex: 'decommission_approved_by', render: (text, record) => { return record.decommission_approved_by ? record.decommission_approved_by : '-' } },
    { title: 'Asset Cost', dataIndex: 'asset_cost', render: (text, record) => { return record.asset_cost ? record.asset_cost : '-' } },
    {
      title: 'Asset Age', dataIndex: 'assetAge', render: (text, record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {record.date_of_purchased ? diff : null}
          </>
        )
      },
    },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Retire Assets Report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('RetireAssetsReport.xlsx');
  }




  return (
    <>
      {(code) ? <Redirect to={{ pathname: '/assetdetailedview', state: { id: code, loc: '/decomissioned-assets-report' } }} /> : null}


      <Card title={<span style={{ color: 'white' }}>Retired Assets</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        extra={assetsData.length > 0 ? (
          <>
            <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
          </>
        ) : (<></>)}

      >  <br></br>
        <Form form={form}
          layout='vertical'>
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

              <Form.Item name="assetCode"
                label="Asset Code">
                <Select
                  suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} />}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetCodeData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.assets_name}-- ${assets.assetsCode}`}</Option>
                  })

                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Asset Category' name='assetCategory' >
                <Select
                  defaultValue={'All'}
                  showSearch
                  //onChange={getItems}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {itemCategory.map((items) => {
                    return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Plant' name='plantId' >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                  showSearch
                  allowClear>
                  {/* <Option key={'all'} value={''}>All</Option> */}
                  {plantName.map((plant) => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Location' name='location'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={'All'}
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {locationData.map((location) => {
                    return (
                      <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col >

              <Form.Item label='Retire Date' name='date'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Asset Type' name='assetType'>
                <Select
                  placeholder='assetType'
                  showSearch
                  defaultValue={'All'}
                  //onChange={onStatusSearch}
                  allowClear
                >
                  <Option key='all' value=''>All</Option>
                  {Object.values(AssetTypeEnum).map((i) => {
                    return (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item label='Department' name='department'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Employee Name - Code' name='employeeName'>
              <Select
                optionFilterProp="children"
                //  filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

            <Col style={{ marginTop: '2%' }}>
              <Button type='primary' onClick={onStatusSearch}>Submit</Button>
            </Col>
            <Col style={{ marginTop: '2%' }}>
              <Button onClick={onReset}>Reset</Button>
            </Col>
            <Col span={3} style={{ marginTop: '2%' }}>
              <Button style={{ marginLeft: '530%' }}>Total no of Records:{length}
              </Button>
            </Col>
          </Row>

        </Form>
        <br />
        {assetsData.length > 0 ? (<>
          <Table columns={columns} dataSource={assetsData} scroll={{ x: 1800, y: 500 }}

            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize)
              }
            }}
            bordered
            summary={(pageData) => {
              let totalCost = 0;

              pageData.forEach(({ asset_cost }) => {
                if (Number(asset_cost)) {
                  totalCost += Number(asset_cost)
                }
              })

              return (
                <>
                  <Table.Summary.Row className="tableFooter">
                    <Table.Summary.Cell index={10} colSpan={10}><Text>Total</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={10} colSpan={1}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                  </Table.Summary.Row>
                </>
              )
            }}
          />
        </>) : (
          <Alert
            message="No Data Found"
            type="info"
            showIcon
          />
        )}
      </Card>
    </>
  );
}

export default DecommisionedAssetsReport;
