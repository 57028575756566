import { Card, Checkbox, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { JobTypesEnum, SoStatusEnum } from '@gtpl/shared-models/common-models';

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['SGS Inspection', 'TUV Inspection'];
const defaultCheckedList = [];
export interface ILabInspectionFromProps {
    form: FormInstance<any>
    initialData:any
    loadInspection:(loadInspectionValue:boolean) => void
    soStatus:string
    jobType:string
}
const LabInspectionForm = (props: ILabInspectionFromProps) => {
    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [qaQccheckedList, setQaQccheckedList] = React.useState(false);
    const [updateDisableField, setUpdateDisableField] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [subPoDisable, setsubPoDisable] = useState<boolean>(false);

    const form = props.form;
    useEffect(()=>{
        if(props.jobType == JobTypesEnum.SUB_JOB){
          console.log(props.jobType)
          setsubPoDisable(true)
        }
      },[props.jobType])


    useEffect(() => {
        getLabDetailsData();
        if(props.initialData){
            if(props.soStatus == SoStatusEnum.IN_PROGRESS || props.jobType == JobTypesEnum.SUB_JOB){
                setUpdateDisableField(true);
            }
            if(props.initialData){
                // setQaQccheckedList(props.initialData.loadInspection)
                form.setFieldsValue({snspectionId:props.initialData?.labInspection?.split(","),lotCode:props.initialData.lotCode})
                // console.log(props.initialData.labInspection.split(","));
                setCheckedList(props.initialData?.labInspection?.split(","))
                setQaQccheckedList(props.initialData?.loadInspection)
                props.loadInspection(props.initialData?.loadInspection)
                // setQaQccheckedList(props.initialData.loadInspection)
                // onChange(props.initialData.labInspection)
            }
            console.log(props.initialData);
        }
    }, []);
    useEffect(() => {
        console.log(form.getFieldsValue())
        console.log(qaQccheckedList);
    }, [qaQccheckedList]);

    const getLabDetailsData = () => {
    }
    const LabInspectionSGSOnchange = (selectedValue: string) => {

    }
    const LabInspectionTUVOnchange = (selectedValue: string) => {

    }

    const onChange = list => {
        console.log(list);
        setCheckedList(list);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    const onQaInspectionChange = (e) => {
        console.log(e)
        setQaQccheckedList(e.target.checked);
        props.loadInspection(e.target.checked)
    }

    return (
        <>
            <Form layout={"vertical"} form={form}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Card title={<span style={{ color: 'white' }}>Lab Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                            <Row>
                                <Col>
                                    <Form.Item name="snspectionId" label="Inspection" rules={[{ required: false }]}>
                                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} disabled={updateDisableField}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                <Form.Item name="lotCode" label="Lot Code" rules={[{ required: false }]}>
                                        <Input disabled={subPoDisable}/>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card title={<span style={{ color: 'white' }}>QA/QC Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                            <Row>
                                <Col>
                                    <Form.Item name="qaQcInspection" label="Inspection" valuePropName="checked">
                                            <Checkbox onChange={onQaInspectionChange} disabled={updateDisableField} checked={qaQccheckedList} >
                                                </Checkbox> &nbsp;Surveyer Loading
                                    </Form.Item>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>)
}
export default LabInspectionForm