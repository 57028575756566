import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { BarcodeOutlined } from '@ant-design/icons';
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AssetMaintainanceDto } from "libs/shared-models/asset-management/src/lib/asset-maintainance";
import { AssetMaintenanceService } from 'libs/shared-services/asset-management/src/lib/asset-maintenance';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Scanner } from '../../../asset-classification-form/src/lib/Scanner';
import { AssetsDetailedView } from '../../../aasets-grid/src/lib/Detailed-view';
import { AssetDto } from "libs/shared-models/asset-management/src/lib/open-assets/assets.dto";
import { AssetService } from "libs/shared-services/asset-management/src/lib/assets.service";
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto";
import moment from "moment";
import { ItemDamageReasonsService } from 'libs/shared-services/masters/src/lib/item-damage-reasons-service';
import FormItem from "antd/lib/form/FormItem";
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";

const { Option } = Select;

const { TextArea } = Input;
export interface AssetMaintainanceFormProps {
  maintainanceData: any;
  updateMaintainance: (maintainance: any) => void;
  isUpdate: boolean;
  closeForm: () => void;

}

export function AssetMaintainanceForm(props: AssetMaintainanceFormProps) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const service = new AssetMaintenanceService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const [assetView, setAssetView] = useState<boolean>(false)
  const [repairData, setRepairData] = useState<boolean>(false);
  const [maintainanceData, setMaintainanceData] = useState<boolean>(false);
  const [damagedData, setDamagedData] = useState<boolean>(false);
  const [rentData, setRentData] = useState<boolean>(false);
  const [data, setData] = useState<boolean>(false);
  const [leaseData, setLeaseData] = useState<boolean>(false);
  const [assetsData, setAssetsData] = useState<AssetDto[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [amcData, setAmcData] = useState<boolean>(false)
  const [warrentyData, setWarrentyData] = useState<boolean>(false)
  const [previewdata, setPreviewData] = useState<any>([]);
  let [counter, setCounterData] = useState<number>(0)
  const [warrentyExpDate, setwarrentyExpDate] = useState<any>();
  const [damageReasons, setDamageReasons] = useState<any>([]);
  const damageService = new ItemDamageReasonsService();

  let history = useHistory();
  const assetService = new AssetService();
  const employeeService = new EmployeeService();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }


  useEffect(() => {
    if (props?.maintainanceData) {
      if (props.maintainanceData.maintainanceType) {
        setMaintainanceData(true)
      }
    }
    getAssetCode();
    employeeDetails();
    getAllDamageReasons();
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      warrentyExpiredDate: warrentyExpDate
    })
  }, [warrentyExpDate])

  const onReset = () => {
    form.resetFields();

  }

  const getAllDamageReasons = () => {
    damageService.getAllItemDamageReasons().then(res => {
      if (res.status) {
        setDamageReasons(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetService.getallAssets(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getByCode = (assetId) => {
    const req = new AssetByCode(assetId)
    assetService.getByAssetCode(req).then(res => {
      if (res.status) {
        console.log(res.data)
        setPreviewData(res.data[0]);
        setCounterData(++counter)
        if (res.data[0].warrenty == 'yes') {
          setWarrentyData(true)
          const datee = moment(moment(res.data[0].warrentyExpiredDate).format('YYYY-MM-DD HH:mm:ss'));
          setwarrentyExpDate(datee)
        }
        if (res.data[0].amc !== 'no') {
          setAmcData(true)
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }


  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetsCode: value
    })
    setAssetView(true)
    getByCode(value)
  }

  const createHandler = (values: AssetMaintainanceDto) => {
    console.log(values, '-----values')
    service.createAssetMaintainance(values).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully')
        history.push('./asset-maintainance')
        props.closeForm()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onMaintainanceChange = (value) => {
    if (value == 'Repair') {
      setRepairData(true)
    } else {
      setRepairData(false)
    }
    if (value == 'Maintenance') {
      setMaintainanceData(true)
    } else {
      setMaintainanceData(false)
    }
    if (value == 'Damaged') {
      setDamagedData(true)
    } else {
      setDamagedData(false)
    }
    if (value == 'Rent') {
      setRentData(true)
    } else {
      setRentData(false)
    }
    if (value == 'Lease') {
      setLeaseData(true)
    } else {
      setLeaseData(false)
    }
    if (value == 'PartiallyDamaged') {
      setData(true)
    } else {
      setData(false)
    }
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Asset Maintenance</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/asset-maintainance' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
      <Form form={form} initialValues={props.maintainanceData} name="control-hooks" onFinish={createHandler} layout="vertical">
        <Form.Item name="assetMaintainanceId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        {(props.isUpdate == true) &&
          <Form.Item name="assetId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
        }
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
            <Form.Item
              name="assetsCode"
              label="Asset Code"
              rules={[
                {
                  required: true,
                  message: "Asset Code is required"
                },
              ]}
            >
              {/* <Input  placeholder="asset code"/> */}
              <Select
                placeholder='Enter or scan asset code'
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetsCode') }} />}
                showSearch
                optionFilterProp="children"
                onChange={handleQrScan}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetsData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          {
            assetView ? (
              <AssetsDetailedView key={form.getFieldValue('assetsCode')} assetsCode={form.getFieldValue('assetsCode')} />
            ) : (
              <>
              </>
            )
          }
        </Row>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }} style={{ marginTop: '1%' }}>
            <Form.Item name="maintainanceType" label="Maintenance Type"
              rules={[{
                required: true,
                message: 'Maintenance Type is required'
              }]}
            >
              <Select
                placeholder='Maintenance type'
                showSearch
                onChange={onMaintainanceChange}
              >
                <Option key={'repair'} value={'Repair'}>Repair</Option>
                <Option key={'maintainance'} value={'Maintenance'}>Maintenance/Servicing</Option>
                <Option key={'partiallyDamaged'} value={'PartiallyDamaged'}>Partially Damaged</Option>
                <Option key={'damaged'} value={'Damaged'}>Damaged</Option>
                <Option key={'rent'} value={'Rent'}>Rent</Option>
                <Option key={'lease'} value={'Lease'}>Lease</Option>
              </Select>
            </Form.Item>
          </Col>
          {
            repairData ? (
              <>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
              <Form.Item name='warrenty' label='Warrenty' initialValue={previewdata.warrenty}>
                <Input defaultValue={previewdata.warrenty}/>
              </Form.Item>
            </Col> */}
                {
                  warrentyData ? (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='warrentyExpiredDate' label='Warrenty Expired Date' initialValue={warrentyExpDate}>
                      <DatePicker
                        defaultValue={warrentyExpDate}
                      />
                    </Form.Item>
                  </Col> */}
                      {
                        amcData ? (
                          <>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>                    <Form.Item name='amcname' label='Manufacturer/Vendor Name' initialValue={previewdata.amcname}>
                              <Input defaultValue={previewdata.amcname} />
                            </Form.Item>
                            </Col>
                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='amcno' label='Manufacturer/Dealer Number' initialValue={previewdata.amcno}>
                      <Input defaultValue={previewdata.amcno}/>
                    </Form.Item>
                  </Col> */}
                          </>
                        ) : (
                          <>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                      <Form.Item name='owner' label='Assigned To'>
                      <Select>
                      {employeeData.map((employee) => {
                        return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
                      })}
                       </Select>
                      </Form.Item>
                    </Col> */}
                    </>
                  )
                }

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                  <Form.Item name='costOfRepair' label='Repair Cost'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  // xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5}} 
                  style={{ margin: '1%', width: '55' }}>
                  <Form.Item name='expiredDate' label='Tentative Receive Date'>
                    <DatePicker  disabledDate={(current) => {
                    return (
                      current && current <= moment(form.getFieldValue('dateOfPurchased'))
                    );
                  }} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                  <Form.Item name='remarks' label='Remarks'>
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
              </>
            )
          }
          {
            maintainanceData ? (
              <>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
              <Form.Item name='warrenty' label='Warranty' initialValue={previewdata.warrenty}>
                <Input defaultValue={previewdata.warrenty}/>
              </Form.Item>
            </Col> */}
                {
                  warrentyData ? (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='warrentyExpiredDate' label='Warranty Expired Date' initialValue={warrentyExpDate}>
                      <DatePicker
                        defaultValue={warrentyExpDate}
                      />
                    </Form.Item>
                  </Col> */}
                      {
                        amcData ? (
                          <>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                              <Form.Item name='amcname' label='Manufacturer/Vendor Name' initialValue={previewdata.amcname}>
                                <Input defaultValue={previewdata.amcname} />
                              </Form.Item>
                            </Col>
                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='amcno' label='Manufacturer/Dealer Number' initialValue={previewdata.amcno}>
                      <Input defaultValue={previewdata.amcno}/>
                    </Form.Item>
                  </Col> */}
                          </>
                        ) : (
                          <>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                      <Form.Item name='owner' label='Assigned To'>
                      <Select>
                      {employeeData.map((employee) => {
                        return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
                      })}
                       </Select>
                      </Form.Item>
                    </Col> */}
                    </>
                  )
                }

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                  <Form.Item name='costOfRepair' label='Maintenance Cost'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  //  xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} 
                  style={{ margin: '1%', width: '55' }}>
                  <Form.Item name='expiredDate' label='Tentative Receive Date'>
                    <DatePicker 
                     disabledDate={(current) => {
                      return (
                        current && current <= moment(form.getFieldValue('dateOfPurchased'))
                      );
                    }}/>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                  <Form.Item name='remarks' label='Remarks'>
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
              </>
            )
          }
          {
            damagedData ? (
              <>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
              <Form.Item name='warrenty' label='Warrenty' initialValue={previewdata.warrenty}>
                <Input defaultValue={previewdata.warrenty}/>
              </Form.Item>
            </Col> */}
                {
                  warrentyData ? (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='warrentyExpiredDate' label='Warranty Expired Date' initialValue={warrentyExpDate}>
                      <DatePicker
                        defaultValue={warrentyExpDate}
                      />
                    </Form.Item>
                  </Col> */}
                      {
                        amcData ? (
                          <>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                              <Form.Item name='amcname' label='Manufacturer/Vendor Name' initialValue={previewdata.amcname}>
                                <Input defaultValue={previewdata.amcname} />
                              </Form.Item>
                            </Col>
                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                    <Form.Item name='amcno' label='Manufacturer/Dealer Number' initialValue={previewdata.amcno}>
                      <Input defaultValue={previewdata.amcno}/>
                    </Form.Item>
                  </Col> */}
                          </>
                        ) : (
                          <>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                      <Form.Item name='owner' label='Assigned To'>
                      <Select>
                      {employeeData.map((employee) => {
                        return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
                      })}
                       </Select>
                      </Form.Item>
                    </Col> */}
                    </>
                  )
                }

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                  <Form.Item name='damageReason' label='Damage Reason'>
                    {/* <TextArea rows={1}/> */}

                    <Select>
                      {
                        damageReasons.map((damageReason) => {
                          return <Option key={damageReason.damageReason} value={damageReason.damageReason}>{damageReason.damageReason}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                  <Form.Item name='remarks' label='Remarks'>
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
              </>
            )
          }
          {
            rentData ? (
              <>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
              <Form.Item name='owner' label='Assigned To'>
              <Select>
            {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
            })}
           </Select>
              </Form.Item>
            </Col> */}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} style={{ margin: '1%' }}>
                  <Form.Item name='costOfRepair' label='Rental Cost'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  // xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }}
                  style={{ margin: '1%', width: '55' }}>
                  <Form.Item name='expiredDate' label='End Date'>
                    <DatePicker  disabledDate={(current) => {
                    return (
                      current && current <= moment(form.getFieldValue('dateOfPurchased'))
                    );
                  }}/>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                  <Form.Item name='remarks' label='Remarks'>
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
              </>
            )
          }
          {
            leaseData ? (
              <>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
              <Form.Item name='owner' label='Assigned To'>
              <Select>
            {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
            })}
           </Select>
              </Form.Item>
            </Col> */}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                  <Form.Item name='costOfRepair' label='Lease Value'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  //  xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} 
                  style={{ margin: '1%', width: '55' }}>
                  <Form.Item name='expiredDate' label='No of Days'>
                    <DatePicker  disabledDate={(current) => {
                    return (
                      current && current <= moment(form.getFieldValue('dateOfPurchased'))
                    );
                  }} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                  <Form.Item name='remarks' label='Remarks'>
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
              </>
            )
          }{data ? (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                <FormItem name="canbeReused" label="Can be Reused" initialValue={'yes'}
                  rules={[{
                    required: true
                  }]}>

                  <Select defaultValue={'yes'} showSearch allowClear>
                    <Option key={'1'} value='yes'> Yes </Option>
                    <Option key={'2'} value='no'> No </Option>
                  </Select>
                </FormItem>
              </Col>
            </>
          ) : (<></>)}
          {/* {props.isUpdate ? (
        <>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="status" label='Status' initialValue={'in-progress'}>
            <Select defaultValue={'in-progress'}>
              <Option key={'in-progress'} value={'in-progress'}>In-Progress</Option>
              <Option key={'completed'} value={'completed'}>Completed</Option>
            </Select>
          </Form.Item>
        </Col>
        </>
        ): (<></>)} */}
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">Submit</Button>
            {(props.isUpdate == false) && <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>Reset</Button>}
          </Col>
        </Row>

      </Form>
      <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'assetsCode' ? <Scanner handleScan={handleQrScan} /> : null
        }

      </Modal>
    </Card>
  )

}

export default AssetMaintainanceForm;