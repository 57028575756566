import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Row, Select, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react';
import { TaxesService, UnitcodeService, DestinationService, PaymentTermsService, NotifyPartyService } from '@gtpl/shared-services/masters';
import { MinusCircleOutlined, PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { EmployeeService } from '@gtpl/shared-services/attendance';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { CrrencySymbols, FactoriesInput, PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest, PortOfEntryInput, SaleOrderDetailViewInfoDTO, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { Link, Route, useHistory } from "react-router-dom";
import moment from 'moment';
import { SaleOrderDto } from '@gtpl/shared-models/sale-management';
import { ContainerDetailsModel, ExporterDataInput } from '@gtpl/shared-models/logistics';
import Item from 'antd/lib/list/Item';
import { CountryDto, CountryRequest, CustomerDto, DestinationDto, EndCustomerDto, NotifyPartyDropDownDto, PaymentTermsDropDownDto, PlantsDropDown, TaxDropDownDto, UnitcodeDto } from '@gtpl/shared-models/masters';
import { CategoryEnum, GlobalStatus, InvoiceCategoriesEnum } from '@gtpl/shared-models/common-models';
import { NotifyPartyInfo, NotifyPartyDto } from '@gtpl/shared-models/masters';

const { Option } = Select;
const { TextArea } = Input;

export interface InvoiceDetailsProps {
    isUpdate: boolean
    plantInvoiceData: PlantInvoiceDetailsModel,
    //   intialData: SoInvoiceDetailsInfo;
    form: FormInstance<any>
    invoiceItemsdata: (invoiceItemsTableData: any) => void;
    // getRevisedPrice: (revisedPrice: number) => void;
    // getDispatchedQty: (dispatchedQuantity: number) => void;
    invoiceAmount: (invAmount: number) => void;
    //   soStatus:string

}

const InvoiceDetailsForm = (props: InvoiceDetailsProps) => {
    let portOfEntries = PortOfEntryInput;
    let invoiceCategories = InvoiceCategoriesEnum;
    const [saleOrderDetails, setSaleOrderDetails] = useState<SaleOrderDetailViewInfoDTO>(null);
    const [tableData, setTableData] = useState<any[]>([]);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [feiHiddenState, setFeiHiddenState] = useState<boolean>(false);
    const [isHideTax, setIsHideTax] = useState<boolean>(true);
    const [invoiceCategory, setInvoiceCategory] = useState<string>('');
    const [taxes, setTaxes] = useState<TaxDropDownDto[]>([]);
    const [taxId, setTaxId] = useState<number>(null);
    const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
    const [destinationData, setdestinationData] = useState<DestinationDto[]>([]);
    const [importers, setImporters] = useState<UnitcodeDto[]>([]);
    const [paymentTerms, setPaymentTerms] = useState<PaymentTermsDropDownDto[]>([]);
    let uom;
    const [notifyPartyDropDown, setNotifyPartyDropDown] = useState<NotifyPartyDropDownDto[]>([]);
    const [notifyPartyInfo, setNotifyPartyInfo] = useState<NotifyPartyInfo[]>([]);
    const [notifyPartyData, setNotifyPartyData] = useState<NotifyPartyDto[]>([]);
    const notifyPartyService = new NotifyPartyService();




    const form = props.form;
    const soService = new SaleOrderService();
    let exporters = ExporterDataInput;
    // let importers = FactoriesInput;
    const taxService = new TaxesService;
    const unitService = new UnitcodeService();
    const destinationservice = new DestinationService();
    const paymentTermsService = new PaymentTermsService();




    // useEffect(()=>{
    //     console.log(isHideTax)
    // },[isHideTax])

    useEffect(() => {
        if (props.isUpdate) {
            if (props.plantInvoiceData.isTaxApplicable == GlobalStatus.YES) {
                setIsHideTax(false)
            } else {
                setIsHideTax(true)
            }
            // if(props.plantInvoiceData.)
            props.plantInvoiceData.invoiceDate = moment(props.plantInvoiceData.invoiceDate ? moment(props.plantInvoiceData.invoiceDate) : moment())
            // props.plantInvoiceData.actualEdl = props.plantInvoiceData.actualEdl

            form.setFieldsValue(props.plantInvoiceData)
            getsaleOrderData(props.plantInvoiceData.saleOrderId);

            form.setFieldsValue({ orderDate: props.plantInvoiceData.actualEdl ? moment(props.plantInvoiceData.actualEdl, 'YYYY-MM-DD') : null })
            form.setFieldsValue({ unitId: props.plantInvoiceData.unitId })
            form.setFieldsValue({ totalInrAmount: props.plantInvoiceData.total_INR_amount })
            form.setFieldsValue({ portOfDischarge: props.plantInvoiceData.portOfDischargeId })
            form.setFieldsValue({epcgLicenseDate : props?.plantInvoiceData?.epcgLicenseDate ? moment(props?.plantInvoiceData?.epcgLicenseDate): moment() })
            getRefCode(props.plantInvoiceData.exporterId)
            if (importers.length > 1) {
                getImporterCodes(props.plantInvoiceData.unitData.unitcodeId);
            }
        }
    }, [props.isUpdate])

    useEffect(() => {
        calculateTotalCost();
        if (form.getFieldValue('exchangeRate') > 0) {
            // alert();
            calculateInrPrice();
        }
    }, [tableData])

    // useEffect(() => {
    //     alert('yes')
    //     getInvoiceData();
    // }, [isUpdate])

    useEffect(() => {
        getSoNumbers();
        getTaxes();
        getPlantsData();
        getAllPaymentTerms();
        getFinalDestinationData();
        getAllNotifyParty();
        // console.log(saleOrderDetails);
        if (saleOrderDetails) {
            // let totalCost = 0;
            // tableData.forEach(item => {
            //     totalCost += Number(item.netAmount);

            // })

            // getFinalDestinationData(saleOrderDetails.soInvDetailsInfo.country);
            setShowTable(true)
            setTableData(saleOrderDetails.itemData);
            calculateTotalCost();


            form.setFieldsValue({
                customerName: saleOrderDetails.customer,
                endCustomerName: saleOrderDetails.endCustomer,
                customerId: saleOrderDetails.customerId,
                endCustomerId: saleOrderDetails.endCustomerId,
                payterms: saleOrderDetails.paymentTermsId,
                // paytermsId: saleOrderDetails.paymentTermsId,
                country: saleOrderDetails.country,
                currency: saleOrderDetails.currency,
                destination: saleOrderDetails.finalDestinationId,
                portOfLoading: saleOrderDetails.portOfEntryId,
                unitId: saleOrderDetails.unitId
                // portOfDischarge: saleOrderDetails.portOfEntry,
                // totalAmount:totalCost
            })
            props.invoiceItemsdata(saleOrderDetails.itemData)
            getImporterCodes(saleOrderDetails.unitId)
        }
    }, [saleOrderDetails])

    // To get the Taxes Data
    const getTaxes = () => {
        taxService.getActiveTaxDropDown().then((res) => {
            if (res.status) {
                setTaxes(res.data);
            } else {
                setTaxes([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setTaxes([]);
        });
    }

    const getPlantsData = () => {
        unitService.getAllActiveUnitcode().then((res) => {
            if (res.status) {
                setImporters(res.data);
            } else {
                setImporters([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setImporters([]);
        });
    }




    const calculateTotalCost = () => {
        let totalCost = 0;
        tableData.forEach(item => {
            totalCost += Number(item.netAmount);

        })
        // if(form.getFieldValue('freightCharges')){
        //     totalCost += Number(form.getFieldValue('freightCharges'))
        // }
        form.setFieldsValue({ totalAmount: totalCost })
        props.invoiceAmount(totalCost)
        // calculateInrPrice()

    }


    // To get Invoice data
    // const getInvoiceData = () => {
    //     console.log('alert')
    //     soService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(14)).then((res) => {
    //         if (res.status) {
    //             console.log(res.data)
    //             // setSaleOrderData(res.data);
    //         } else {
    //             // setSaleOrderData([]);
    //         }
    //     }).catch((err) => {
    //         AlertMessages.getErrorMessage(err.message);
    //         // setSaleOrderData([]);
    //     });
    // }

    // To get SO numbers
    const getSoNumbers = () => {
        soService.getSoNumbers().then((res) => {
            if (res.status) {
                setSaleOrderData(res.data);
            } else {
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

    const getAllPaymentTerms = () => {
        paymentTermsService.getAllpaymentTermsDropDown().then((res) => {
            if (res.status) {
                setPaymentTerms(res.data);
            } else {
                // if (res.intlCode) {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // } else {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // }
                setPaymentTerms([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setPaymentTerms([]);
        });
    }

    const getsaleOrderData = (saleOrderId) => {
        setTableData([]);
        soService.getSaleOrderDetailsById(new saleOrder(saleOrderId)).then((res) => {
            if (res.status) {
                form.setFieldsValue({ etaDate: res.data.eta ? (moment(res.data.eta, 'YYYY-MM-DD')) : null })
                // form.setFieldsValue()
                setSaleOrderDetails(res.data);
                getFinalDestinationData()

            } else {
                // if (res.intlCode) {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // } else {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // }
                setSaleOrderDetails(null);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderDetails(null);
        });
        // if(!props.isUpdate){
        //     const invoiceDate = moment(form.getFieldValue("invoiceDate")).format('YYYY-MM-DD');
        //     console.log(saleOrderDetails)
        //     const saleOrderDate = moment(saleOrderDetails.invDate).format('YYYY-MM-DD');
        //       console.log(invoiceDate);
        //       console.log(saleOrderDate);
        //       if(saleOrderDate<invoiceDate){
        //           AlertMessages.getErrorMessage('Invoice date  must be after So Created date');
        //           form.setFieldsValue({invoiceDate:''})
        //       }
        // }
    }

    const getRefCode = (val) => {
        const selectedExporter = exporters.find(item => item.value == val);
        form.setFieldsValue({ ieCode: selectedExporter.ieCode })

    }

    const getImporterCodes = (val) => {
        const selectedImporter = importers.find(item => item.unitcodeId == val);
        if (!selectedImporter?.feiNumber) {
            setFeiHiddenState(true)
        }
        form.setFieldsValue({ fda_reg_no: selectedImporter?.fdaRegNumber, fei_no: selectedImporter?.feiNumber })
    }

    const storeTax = (val, index, row) => {
        const dynTableData = [...tableData];
        dynTableData[index].taxId = row.key;
        setTableData(dynTableData);
        props.invoiceItemsdata(tableData)
        // tableData[index].taxId = val;
        // setTaxId(val.taxId);

    }

    const storeRevisedPrice = (e, index, data) => {
        console.log(data)
        data.unitPrice = e;
        console.log(e)
        tableData[index].dispatchQty = data.dispatchQty;
        tableData[index].unitPrice = data.unitPrice;
        // tableData[index].revisedUnitPrice = data.unitPrice;
        const netAmnt = Number(data.dispatchQty) * Number(e);
        const dynTableData = [...tableData];
        dynTableData[index].invoiceAmnt = Number(netAmnt.toFixed(2));
        setTableData(dynTableData);
        calculateTotalCost()
        props.invoiceItemsdata(tableData)
        console.log(tableData)
        // props.getRevisedPrice(e);
    }
    console.log(tableData)

    const storeInvoicedCases = (e, index, data) => {
        data.invoicedCases = e;
        const dispatchedQty = (data.uomId == 3) ?
            (Number(data.invoicedCases) * Number(data.pouches) * Number(data.pouchWeight) * 0.0625) : ((data.uom == 4) ? (Number(data.invoicedCases) * Number(data.pouches) * Number(data.pouchWeight) * 0.001) : (Number(data.invoicedCases) * Number(data.pouches) * Number(data.pouchWeight)))
        tableData[index].invoicedCases = data.invoicedCases;
        tableData[index].unitPrice = data.unitPrice;
        const netAmnt = Number(data.unitPrice) * dispatchedQty;
        tableData[index].invoicedQty = dispatchedQty;
        const dynTableData = [...tableData];
        dynTableData[index].invoiceAmnt = Number(netAmnt.toFixed(2));
        setTableData(dynTableData);
        // calculateTotalCost()
        props.invoiceItemsdata(tableData)
    }

    const storeDispatchedQty = (e, index, data) => {
        data.dispatchQty = e;
        tableData[index].invoicedQty = data.dispatchQty;
        tableData[index].unitPrice = data.unitPrice;
        const netAmnt = Number(data.unitPrice) * Number(e);
        const dynTableData = [...tableData];
        dynTableData[index].invoiceAmnt = Number(netAmnt.toFixed(2));
        setTableData(dynTableData);
        calculateTotalCost()
        props.invoiceItemsdata(tableData)

        // props.getDispatchedQty(e);
    }

    const getFinalDestinationData = () => {
        // destinationservice.getDestinationsByCountry(new CountryRequest(selectedCountry)).then((res) => {
        destinationservice.getAllActiveDestination().then((res) => {
            if (res.status) {
                setdestinationData(res.data);
            } else {
                // if (res.intlCode) {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // } else {
                //   AlertMessages.getErrorMessage(res.internalMessage);
                // }
                setdestinationData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setdestinationData([]);
        });
    }

    const getAllNotifyParty = () => {
        notifyPartyService.getAllNotifyPartyDropDown().then(res => {
            if (res.status) {
                setNotifyPartyDropDown(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const setNotifyParty = (values, notifyPartyData) => {
        const notifyParty = new NotifyPartyInfo(values, notifyPartyData.children);
        notifyPartyInfo.push(notifyParty);
        setNotifyPartyInfo(notifyPartyInfo);
    }

    const handleGrossWeight = (e, index, data) => {
        data.grossWeight = e;
        tableData[index].grossWeight = data.grossWeight;
    }

    // console.log(tableData)

    const columns = [
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'Brand',
            width: '100px'
            //   editable: true,

        },
        // {
        //     title: 'Grade',
        //     dataIndex: 'minGrade',
        //     key: 'minGrade',
        //     render: (text, data, index) => {
        //         return <span>{`${text} - ${data.maxGrade} `}</span>
        //     }
        // },
        {
            title: 'Product SKU',
            dataIndex: 'varient',
            width: '250px',
            key: 'varient',
            render: (text, data, index) => {
                return <span>{`${data.product} ${text} `}</span>
            }
        },
        // // {
        // //   title: 'Product',
        // //   dataIndex: 'itemId',
        // //   render: (text, data, index) => {
        // //     console.log(text, data, index)
        // //     return <span>{data.itemId.children}</span>
        // //   }
        // // },
        // // {
        // //   title: 'Food Type',
        // //   dataIndex: 'foodTypeId',
        // //   render: (text, data, index) => {
        // //     console.log(text, data, index)
        // //     return <span>{data.foodTypeId.children}</span>
        // //   }
        // // },


        {
            title: 'Pack Style',
            dataIndex: 'packingStyle',
            key: 'packingStyle',
            width: '100px',
            render: (text, data, index) => {
                return <span>{` ${text} ${data.uom}`}</span>
            }
        },
        // {
        //   title: 'Case Weight',
        //   dataIndex: 'caseWeight',
        //   key: 'caseWeight',
        //   render: (text, data, index) => {
        //     let outputWeight
        //     if (data.uomId.children == UomEnum.LB || data.uomId.children == UomEnum.OZ) {
        //       outputWeight = UomEnum.LB;
        //     } 
        //     if (data.uomId.children == UomEnum.KG || data.uomId.children == UomEnum.Gms) {
        //       outputWeight = UomEnum.KG;
        //     } 
        //     // else {
        //     //   outputWeight = data.wighmentType.children
        //     // }
        //     return <span>{`${text.toFixed(3)} ${outputWeight}`}</span>
        //   }
        // },
        {
            title: 'Cases',
            dataIndex: 'cases',
            key: 'cases',
            width: '100px'
        },
        {
            title: 'Net Weight',
            dataIndex: 'netWeight',
            key: 'netWeight',
            width: '100px',
            render: (text, data, index) => {
                return <span>{Number(text) + ' ' + ((data.uomId == 2 || data.uomId == 3) ? 'LB' : 'KG')}</span>
            }
        },
        {
            title: 'Net Amount',
            dataIndex: 'netAmount',
            key: 'netAmount'
        },
        {
            title: 'Dispatched Qty',
            dataIndex: 'invoicedQty',
            key: 'invoicedQty',
            width: '150px',
            //un comment when dispatched qty saving from warehouse
            render: (text, data, index) => {
                if (data.dispatchQty <= 0) {
                    data.dispatchQty = data.netWeight;
                }
                return <span>{(data.dispatchQty > 0 ? (Number(data.dispatchQty)) * Number(data.pouches) * Number(data.pouchWeight) : Number(data.netWeight)) + ' ' + ((data.uomId == 2 || data.uomId == 3) ? 'LB' : 'KG')}</span>
            }
            // render: (text, data, index) => {
            //     // console.log(data.dispatchQty);
            //     if(data.dispatchQty <= 0){
            //         data.dispatchQty=data.netWeight;
            //     }
            //     console.log(data)
            //     return <span><InputNumber min={0} defaultValue={props.isUpdate?text:data.netWeight} onChange={e => storeDispatchedQty(e, index, data)} /></span>
            //     // return <span>{data.brandId.children}</span>
            // }

        },
       
        {
            title: 'Invoiced Cases',
            dataIndex: 'invoicedCases',
            key: 'invoicedCases',
            width: '200px',
            //un comment when dispatched qty saving from warehouse
            // render: (text, data, index) => {
            //     return <span>{Number(text)}</span>
            //   }
            render: (text, data, index) => {
                // console.log(data.dispatchQty);
                // if (data.invoicedCases <= 0) {
                //     data.invoicedCases = data.cases;
                // }
                return <span><InputNumber min={0} defaultValue={Number(text)} onChange={e => storeInvoicedCases(e, index, data)} /></span>
                // return <span>{data.brandId.children}</span>
            }

        },
       
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: '11%',
            render: (text, data, index) => {
                console.log(data)
                return <span>{CrrencySymbols.find(item => item.id == saleOrderDetails.currencyId).symbol + ' '}<InputNumber min={0} defaultValue={data?.revisedUnitPrice ? data?.revisedUnitPrice : data?.unitPrice} onChange={e => storeRevisedPrice(e, index, data)} /> &nbsp;&nbsp; {((data.uomId == 2 || data.uomId == 3) ? 'per LB' : '    per KG')}</span>

                // return <span>{data.brandId.children}</span>
            }
        },
        {
            title: 'Tax',
            dataIndex: 'taxId',
            key: 'taxId',
            width: '15%',
            hidden: isHideTax,
            render: (text, data, index) => {
                return <span><Select
                    showSearch
                    placeholder="Select Tax"
                    allowClear
                    dropdownMatchSelectWidth
                    style={{ width: '110%' }}
                    onChange={(val, row) => { storeTax(val, index, row) }}
                    defaultValue={props.isUpdate ? text : ''}
                >
                    {taxes.map(dropData => {
                        return <Option name="taxId" key={dropData.taxId} value={dropData.taxId}>{dropData.taxName + " - " + dropData.taxPercentage}</Option>
                    })}
                </Select></span>
                // return <span>{data.brandId.children}</span>
            }
        },
        {
            title: 'Invoiced Amount',
            dataIndex: 'invoiceAmnt',
            key: 'invoiceAmnt',
            render: (text, data, index) => {
                return <span>{CrrencySymbols.find(item => item.id == saleOrderDetails.currencyId).symbol + ' '}{data.invoiceAmnt > 0 ? Number(data.invoiceAmnt) : Number(text)}</span>
            }

        },
        {
            title: 'Gross Weight',
            dataIndex: 'grossWeight',
            key: 'grossWeight',
            width: '150px',
            render: (text, data, index) => {
                return <span>
                    <InputNumber defaultValue={props.isUpdate ? text : data.grossWeight} onChange={e => handleGrossWeight(e, index, data)} />&nbsp;{( 'KG per case')}
                </span>
            }
        },

        // {
        //   title: 'Style Weighment',
        //   dataIndex: 'styleWeighment',
        //   key: 'styleWeighment',
        //   render: (text, data, index) => {
        //     return <span>{`${data.styleWeighment.children}`}</span>
        //   }
        // },
        // {
        //   title: 'Glazing',
        //   dataIndex: 'MaxGlaze',
        //   key: 'MaxGlaze',
        //   render: (text, data, index) => {
        //     return <span>{`${data.minGlaze} - ${text}`}</span>
        //   }
        // },
        // {
        //   title: 'Soaking Time',
        //   dataIndex: 'soakingTime',
        //   key: 'soakingTime',
        //   render: (text, data, index) => {
        //     return <span>{`${text}min`}</span>
        //   }
        // },
        // {
        //   title: 'Soaking Style',
        //   dataIndex: 'soakingStyle',
        //   key: 'soakingStyle',
        //   render: (text, data, index) => {
        //     // console.log(data)
        //     return <span>{`${data.soakingStyle.children}`}</span>
        //   }
        // },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     // width: '20%',
        //     render: (text, rowData: any, index) => (
        //         <span>

        //             <Tooltip placement="top" title='dlete'>
        //                 <SaveOutlined onClick={() => {
        //                     if (rowData) {
        //                         // deleteData(index)
        //                         // openFormWithData(rowData);
        //                     }
        //                 }}
        //                     style={{ color: '#1890ff', fontSize: '14px' }} />
        //             </Tooltip>
        //         </span>)
        // }

    ].filter(item => !item.hidden)

    const calculateInrPrice = () => {
        const totCost = Number(form.getFieldValue('totalAmount'));
        const exchRate = Number(form.getFieldValue('exchangeRate'));
        const inrPrice = totCost * exchRate;
        form.setFieldsValue({ totalInrAmount: inrPrice.toFixed(2) });
    }
    const checkInvoiceValidation = () => {
        const invoiceDate = moment(form.getFieldValue("invoiceDate")).format('YYYY-MM-DD');
        const saleOrderDate = moment(saleOrderDetails.invDate).format('YYYY-MM-DD');
        if (invoiceDate < saleOrderDate) {
            AlertMessages.getErrorMessage('Invoice date  must be after So Created date');
            form.setFieldsValue({ invoiceDate: '' })
        }
    }


    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Invoice Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/invoice-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
                <Form layout={"vertical"} form={form} autoComplete="off" >
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="saleOrderId" label="Po Number" rules={[{ required: true, message: 'Missed Sale order' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Po"
                                    onChange={getsaleOrderData}
                                    // onClear={clearCustomerDependentData}
                                    allowClear
                                    dropdownMatchSelectWidth={false}

                                >
                                    {saleOrderData.map(saleOrder => {
                                        return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.poNumber}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="invoiceDate" label="Date" rules={[{ required: true, message: 'Missing Invoice date' }]} initialValue={moment()}>
                                <DatePicker onChange={checkInvoiceValidation}
                                    // onChange={(date) => {if((saleOrderDetails)>date){AlertMessages.getErrorMessage('Invoice date  must be after So Created date');form.setFieldsValue({invoiceDate:''})}}} 
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="exporterId" label="Exporter" rules={[{ required: true, message: 'Missed Exporter' }]}>
                                <Select
                                    placeholder="Select Exporter"
                                    allowClear
                                    onChange={getRefCode}
                                    // disabled={updateDisableField}
                                    dropdownMatchSelectWidth={false}

                                >
                                    {exporters.map(exporter => {
                                        return <Option key={exporter.value} value={exporter.value}>{exporter.addressOne + ',' + exporter.addressTwo + ',' + exporter.state + ',' + exporter.country + ',' + exporter.postalCode}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="ieCode" label="IE Code" rules={[{ required: true, message: 'Missed IE code' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>



                    </Row>

                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="unitId" label="Processed Unit" rules={[{
                                required: true, message:
                                    'Missed unit address'
                            }]}>
                                <Select
                                    placeholder="Select Unit"
                                    allowClear
                                    onChange={getImporterCodes}
                                    dropdownMatchSelectWidth={false}

                                // disabled={updateDisableField}
                                >
                                    {importers.map(importer => {
                                        return <Option key={importer.unitcodeId} value={importer.unitcodeId}>{`${importer.unitcodeName},${importer.villageName},${importer.mandalName},${importer.postalCode},${importer.distirctName},${importer.stateName},${importer.countryName}`}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="fda_reg_no" label="FDA Reg NO." rules={[{ required: true, message: 'Missed FDA Reg No' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        {

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item hidden={feiHiddenState} name="fei_no" label="FEI Number" rules={[{ required: false, message: 'Missed FEI number' }]}>
                                    <Input disabled hidden={feiHiddenState} />
                                </Form.Item>
                            </Col>
                        }



                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="customerName" label="Customer" rules={[{ required: false }]}>

                                <Input disabled />

                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="endCustomerName" label="End Customer" rules={[{ required: false }]}>

                                <Input disabled />

                            </Form.Item>

                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="payterms" label="Payment Terms" rules={[{ required: true, message: 'Missed Payment term' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Payment Terms"
                                    allowClear
                                >
                                    {paymentTerms.map(dropData => {
                                        return <Option value={dropData.paymentTermsId}>{dropData.paymentTermsName}</Option>
                                    })}
                                </Select>

                                {/* <Input disabled /> */}

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="portOfLoading" label="Port Of Loading" rules={[{ required: true, message: 'Missed Port Of Loading' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Port"
                                    allowClear
                                >
                                    {portOfEntries.map(dropData => {
                                        return <Option value={dropData.value}>{dropData.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="portOfDischarge" label="Port Of Discharge" rules={[{ required: true, message: 'Missed Port of discharge' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Port Of Discharge"
                                    // onChange={destinationOnchange}
                                    allowClear
                                // disabled = {customerHidden}
                                >
                                    <Option value={0}>Select Port Of Discharge</Option>
                                    {destinationData.map(dropData => {
                                        return <Option value={dropData.destinationId}>{dropData.destinationName}</Option>
                                    })}
                                </Select>
                                {/* <Input /> */}

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="country" label="Final Country" rules={[{ required: true, message: 'Missed country' }]}>

                                <Input disabled />

                            </Form.Item>
                        </Col>




                    </Row>
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="destination" label="Final Destination" rules={[{ required: true, message: 'Missed Destination' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Destination"
                                    // onChange={destinationOnchange}
                                    allowClear
                                // disabled = {customerHidden}
                                >
                                    {destinationData.map(dropData => {
                                        return <Option value={dropData.destinationId}>{dropData.destinationName}</Option>
                                    })}
                                </Select>

                                {/* <Input disabled /> */}

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="flightNumber" label="Flight Number" >

                                <Input />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Missed currency' }]}>

                                <Input disabled />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="freightCharges" label="Freight Charges(IN $)" >
                                <InputNumber onChange={calculateTotalCost} min={0} style={{ width: '100%' }} />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                            <Form.Item name="exchangeRate" label="Exchange Rate" rules={[{ required: true, message: 'Missed Exchange Rate' }]}>

                                <InputNumber min={0} onChange={calculateInrPrice} style={{ width: '100%' }} />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="totalAmount" label="Total Amount" rules={[{ required: false, message: 'Missed TotalAmount' }]}>

                                <Input disabled />

                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="totalInrAmount" label="Total INR Amount" rules={[{ required: false, message: 'Missed INR Price' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="invoiceCategory" label="Invoice Category" rules={[{ required: true, message: 'Missed Category' }]}>
                                <Select
                                    placeholder="Invoice Category"
                                    allowClear
                                    onChange={(val) => {
                                        setInvoiceCategory(String(val))
                                        if (val == InvoiceCategoriesEnum.IGST) {
                                            setIsHideTax(false)
                                            form.setFieldsValue({ isTaxApplicable: 'yes' })
                                        } else {
                                            setIsHideTax(true)
                                            form.setFieldsValue({ isTaxApplicable: 'no' })
                                        }

                                    }}
                                >
                                    {Object.values(InvoiceCategoriesEnum).map(catDropdown => {
                                        return <Option label={'notifyPartyId'} value={catDropdown}>{catDropdown}</Option>
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item hidden name="isTaxApplicable" label="Is Tax Applicable?">
                                <Select
                                    placeholder="Select Tax Status"
                                    allowClear
                                    onChange={(val) => {
                                        val == 'yes' ? setIsHideTax(false) : setIsHideTax(true)

                                    }}
                                >
                                    <Option value="yes">YES</Option>
                                    <Option value="no">NO</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="saleCommission" label="Sale Commission">
                                <InputNumber min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="insurance" label="Insurance(IN $)">
                                <InputNumber min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="finNumber" label="FPO/FIN Number">
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item

                                label="Notify Party 1"
                                name='notifyPartyOne'

                                rules={[ { pattern: /^(?!\s*$).+/, message: `Enter Notify Party` }]}
                            >
                                <Select
                                    placeholder="Select Notify Party"
                                    allowClear
                                    onChange={setNotifyParty}
                                    aria-multiline
                                >

                                    <Option key={0} value={null}>Select Notify Party</Option>
                                    {notifyPartyDropDown.map(notifyPartydropData => {
                                        return <Option label={'notifyPartyId'} key={notifyPartydropData.notifyPartyId} value={notifyPartydropData.notifyPartyId}>{notifyPartydropData.notifyPartyName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item

                                label="Notify Party 2"
                                name='notifyPartyTwo'

                               // rules={[ { pattern: /^(?!\s*$).+/, message: `Enter Notify Party` }]}
                            >
                                <Select
                                    placeholder="Select Notify Party"
                                    allowClear
                                    onChange={setNotifyParty}
                                    aria-multiline
                                >

                                    <Option key={0} value={null}>Select Notify Party</Option>
                                       {notifyPartyDropDown.map(notifyPartydropData => {
                                        return <Option label={'notifyPartyId'} key={notifyPartydropData.notifyPartyId} value={notifyPartydropData.notifyPartyId}>{notifyPartydropData.notifyPartyName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item

                                label="Notify Party 3"
                                name='notifyPartyThree'

                                //rules={[ { pattern: /^(?!\s*$).+/, message: `Enter Notify Party` }]}
                            >
                                <Select
                                    placeholder="Select Notify Party"
                                    allowClear
                                    onChange={setNotifyParty}
                                    aria-multiline
                                >
                                    <Option key={0} value={null}>Select Notify Party</Option>
                                    {notifyPartyDropDown.map(notifyPartydropData => {
                                        return <Option label={'notifyPartyId'} key={notifyPartydropData.notifyPartyId} value={notifyPartydropData.notifyPartyId}>{notifyPartydropData.notifyPartyName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item

                                label="Notify Party 4"
                                name='notifyPartyFour'

                                //rules={[ { pattern: /^(?!\s*$).+/, message: `Enter Notify Party` }]}
                            >
                                <Select
                                    placeholder="Select Notify Party"
                                    allowClear
                                    onClear={() => { return null }}
                                    onChange={setNotifyParty}
                                    aria-multiline

                                >
                                    <Option key={0} value={null}>Select Notify Party</Option>
                                    {notifyPartyDropDown.map(notifyPartydropData => {
                                        return <Option label={'notifyPartyId'} key={notifyPartydropData.notifyPartyId} value={notifyPartydropData.notifyPartyId}>{notifyPartydropData.notifyPartyName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="invoiceNumber" label="Invoice Number" rules={[{ required: true, message: 'Missing Invoice number' }]}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="orderDate" label="Order Date" rules={[{ required: true, message: 'Missing Invoice date' }]} >
                                <DatePicker
                                    // onChange={checkInvoiceValidation}
                                    // onChange={(date) => {if((saleOrderDetails)>date){AlertMessages.getErrorMessage('Invoice date  must be after So Created date');form.setFieldsValue({invoiceDate:''})}}} 
                                    style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="customerId">
                                <Input hidden />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="placeOfReceipt" label="Place Of Receipt" >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="etaDate" label="Shipment Date(ETA)" rules={[{ required: true, message: 'Missing Shipment date' }]}>
                                <DatePicker
                                    style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="endCustomerId">
                                <Input hidden />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden= {invoiceCategory == InvoiceCategoriesEnum.EPCG ? false : true}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden= {invoiceCategory == InvoiceCategoriesEnum.EPCG ? false : true}>
                                <Form.Item name="epcgLicenseNumber" label="EPCG License No"  rules={[{ required: (invoiceCategory == InvoiceCategoriesEnum.EPCG ? true : false), message: 'Missed Liscence No' }]}>
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden= {invoiceCategory == InvoiceCategoriesEnum.EPCG ? false : true} >
                                    <Form.Item name="epcgLicenseDate" label="License Date" rules={[{ required: (invoiceCategory == InvoiceCategoriesEnum.EPCG ? true : false), message: 'Missing Liscence Date' }]}>
                                        <DatePicker
                                            style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden= {invoiceCategory == InvoiceCategoriesEnum.EPCG ? false : true} >
                                    <Form.Item name="epcgFobValue" label="FOB Avlue In USD" rules={[{ required: (invoiceCategory == InvoiceCategoriesEnum.EPCG ? true : false), message: 'Missing FOB Value' }]}>
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden= {invoiceCategory == InvoiceCategoriesEnum.EPCG ? false : true} >
                                    <Form.Item name="exportObligation" label="Export Obligation" rules={[{ required: (invoiceCategory == InvoiceCategoriesEnum.EPCG ? true : false), message: 'Missing Export Obligation' }]}>
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                     </Row>
                     <Row gutter={24}>
                     <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="remarks" label="Remarks">
                                <TextArea />
                            </Form.Item>
                        </Col>
                     </Row>
                </Form>

                <br />
                {
                    showTable ?
                        <Card title={<span style={{ color: 'white' }}>Invoice Items</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

                            <Table dataSource={tableData} columns={columns} pagination={false}  scroll = {{x:true}}
                            /></Card> :
                        <></>

                }



            </Card></>)
}
export default InvoiceDetailsForm
