import { Card, Col, Row } from "antd";
//import '../dashboard.css'
import React, { useState } from "react";
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
import { Link, Redirect } from "react-router-dom";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface cardProps {
    cardData: CardDetail
}

export const LogisticsCardComponet = (props: cardProps) => {
    const [status,setStatus] = useState('')
    const { cardData } = props;

    const getStatus = (status) => {
        console.log(status)
        setStatus(status)
    }

    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <tr style={{ borderBottom: '1px solid #f0f8ff', lineHeight: ' 1.8em' }}>
                {/* {cardData.carHeader.cardType=='Sales'?<td ><Link to='/sale-otder-report'></Link>:{bodyProps.cardBodyTitle}}</td>:<td >{cardData.carHeader.cardType=='Sales'?<Link to='/sale-otder-report'></Link>:{bodyProps.cardBodyTitle}}</td>} */}
                <td >{cardData.carHeader.cardType=='Sales'?<Link to = '#' onClick={e => getStatus(bodyProps.cardBodyTitle)} >{bodyProps.cardBodyTitle}</Link>:<>{bodyProps.cardBodyTitle}</>}</td>
                <td >{bodyProps.cardBodyParam1}</td>
                <td >{bodyProps.cardBodyParam2}</td>
                <td >{bodyProps.cardBodyParam3}</td>
                <td >{bodyProps.cardBodyParam4}</td>
            </tr>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    return (
        <>
        {(status) ? <Redirect to={{ pathname: "/sale-otder-report", state: status }} /> : null}
            <Card className="logistics-card" title={<span style={{ marginBottom: '10px' }} className="title">{cardData.carHeader.cardHeaderTitle}</span>} bordered={false} style={{ color: 'black', height: '100%' }}>
                <Row style={{ width: '100%', height: '20px' }}>
                    <Col span={8}>
                        {cardData.carHeader.cardHederData[0].title}
                    </Col>
                    <Col span={14} offset={2}>
                        {cardData.carHeader.cardHederData[1].title}
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[0].value}
                    </Col>
                    <Col span={14} offset={2} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[1].value}
                    </Col>
                </Row>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '90%', margin: '1.5em', fontSize: '0.95em', border: '0' }}>
                    {constructTableHeader(cardData.tableHeaders)}
                    <tbody>
                        {constructLocationDetail(cardData.cardBody)}
                    </tbody>
                </table>
            </Card>
        </>
    );
}
export default LogisticsCardComponet;