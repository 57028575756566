import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, DatePicker, Modal, Radio } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { isNull } from 'util';
import TextArea from 'antd/lib/input/TextArea';
import { QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import { DepartmentDto, DownTimeReasonDto, DownTimeTrackingDto, DowntimeTrackingMacineNameResponse, EmployeeDto, EmployeeUnitRequest } from '@gtpl/shared-models/masters';
import { CategoryEnum, DownTimePlannedCategoryenum, DownTimePlannedCategoryenumDropDown, DownTimeTrackingStatusEnum } from '@gtpl/shared-models/common-models';
import { DepartmentService, DowntimeReasonService, DownTimeTrackingService, EmployeeService } from '@gtpl/shared-services/masters';


/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

export interface DowntimeTackFormProps {
  updateDetails: (Downtimetracking: DownTimeTrackingDto) => void;
  downtimeTrackingData: DownTimeTrackingDto;
  isUpdate: boolean;
  closeForm: () => void;
}

export function NewDowntimeTrackingform(props: DowntimeTackFormProps) {
  const [form] = Form.useForm();
  const service = new DownTimeTrackingService;
  const downTimeReasonService = new DowntimeReasonService;
  const downtimeTrackingService = new DownTimeTrackingService;
  const departmentService = new DepartmentService;
  const saleService = new SaleOrderService;
  const empservice = new EmployeeService;
  const [departmentData, setDepartmentData] = useState<DepartmentDto[]>()
  const [selectedReason, setSelectedReason] = useState<number>(null);
  const [downTimeReasonData, setDownTimeReasonData] = useState<DownTimeReasonDto[]>();
  const [disable, setDisable] = useState<boolean>(false);
  const [soDropDown, setSoDropDown] = useState<SaleOrderDropDownDto[]>();
  const [scannedQr, setscannedQr] = useState<any[]>([])
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState<DownTimeTrackingStatusEnum>()
  const [downtimetype, setDowntimetype] = useState('')
  const [statusVissible, setStatusVissible] = useState<boolean>(false);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const [starttimevissible, setStarttimevissible] = useState<boolean>(false);
  const [downtimereason, setDowntimereason] = useState<boolean>(false)
  const [plannedtimevisible, setPlannedtimevisible] = useState<boolean>(false)
  const[endtimeVissible,setEndtimeVissible]=useState<boolean>(false)
  const[resolutionvissible, setResolutionvissible]=useState<boolean>(false)
  const [downTimeTypeVal, setDownTimeTypeVal] = useState<DownTimePlannedCategoryenum>(DownTimePlannedCategoryenum.UN_PLANNED)
  const [assetCategory,setAssetCategory] = useState<any[]>([]);
  const [assetCode,setAssetCode]=useState<any[]>([]);


  useEffect(() => {
    getAllActiveDownTimeReason();
    getDepartmentData();
    getSoNoDropdown()
    getEmpByUnit()
    assetCategorydropdown();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
    }
  }, [])
  let createdUser = "";
  let unitId = 0;
  let history = useHistory();

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    if (props.downtimeTrackingData) {
        props.downtimeTrackingData.endtime=moment();
      form.setFieldsValue(props.downtimeTrackingData)
      if (props.downtimeTrackingData?.employeeId != undefined && props.isUpdate) {
        form.setFieldsValue('employeeId')
      }
    }
  }, [props.downtimeTrackingData])


  const save = (downTimeTrackingData: DownTimeTrackingDto) => {
    setDisable(true)
    downTimeTrackingData.downtimetype = downTimeTypeVal
    console.log(downTimeTrackingData, 'value--')
    service.createDownTimeTracking(downTimeTrackingData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('DownTimeTracking Created Successfully');
        history.push("/downtimetracking-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const statusonchange = () =>{
    if(form.getFieldValue('status') == DownTimeTrackingStatusEnum.CLOSE){
      setEndtimeVissible(true)
      setResolutionvissible(true)
    }else{
      setEndtimeVissible(false);
      setResolutionvissible(false);
    }
  }

  const getDepartmentData = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentData(res.data)
      } else {
        setDepartmentData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getSoNoDropdown = () => {
    const req = new PlantRequest
    req.plantId = Number(localStorage.getItem('unit_id'))
    saleService.getAllActiveSaleOrders(req).then((res) => {
      if (res.status) {
        setSoDropDown(res.data)
      } else {
        setSoDropDown([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getEmpByUnit = () => {
    const req = new EmployeeUnitRequest(Number(localStorage.getItem('unit_id')))
    empservice.getEmployeesByUnitId(req).then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        setEmployeeData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const handleDownTimeTracking = (value) => {
    setSelectedReason(value);
  }


  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onReset = () => {
    form.resetFields();
    setDowntimetype(DownTimePlannedCategoryenum.UN_PLANNED);

    setStatusVissible(false)
    setPlannedtimevisible(false)
  };
  const handleStatus = (value) => {
    setStatus(value);
    setStatusVissible(true);
   
  }
  // const handleDowntimeType = (value) => {
  //   setDowntimetype(value);
  //   setStatusVissible(true);
  //   setPlannedtimevisible(true);

  // }
  const getAllActiveDownTimeReason = () => {
    downTimeReasonService.getAllActiveDownTimeReason().then(res => {
      if (res.status) {
        setDownTimeReasonData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getMachineName = (value) => {
    if (value != undefined) {
      console.log(value, 'testdta')
      downtimeTrackingService.getMachineName({ assetCategoryId: value }).then(res => {
        if (res.data) {
          if (res.data.length > 0) {
            setAssetCode(res.data)
            // form.setFieldsValue({
            //   machineName: res.data[0].assetName,
            //   assetId: res.data[0].assetId
            // })
          } else {
            setAssetCode([])
          }
        } else {
          form.resetFields(['machineCode', 'machineName'])
          AlertMessages.getErrorMessage('No Machine Found for scanned machine code')

        }
      })
    } else {
      AlertMessages.getErrorMessage("Scann Valid QR Code");

    }

  }

 
const machineCodeOnChange = (e) =>{
const assetName = assetCode.filter((el) =>{
return el.assetCode == e
})
  form.setFieldsValue({machineName:assetName[0].assetName,assetId:assetName[0].assetId})
}

  const saveData = (values: DownTimeTrackingDto) => {
    console.log(values, 'dto in form')
    setDisable(false)
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      setDisable(false)
      save(values);
    }
  };

  const handleCategory = (value) => {
    setCategory(value)
  }
  const handleQrScan = (value) => {
    setShowQrScan(false)
    
    const assetName=assetCode.filter((el) =>{return el.assetCode=value})
    console.log(assetName[0].assetName)
    form.setFieldsValue({
      machineCode: value,
      assetId:assetName[0].assetId,
      machineName:assetName[0].assetName
    })

  }

  console.log(status, 'status---')
  const onchange = (event) => {
    console.log(event);
    const { value, checked } = event.target;
    if (value === "Planned" && checked) {
      console.log(value + "*****" + checked);

      setStarttimevissible(true)
      setPlannedtimevisible(true)
      setDowntimereason(true);
      setDownTimeTypeVal(DownTimePlannedCategoryenum.PLANNED)
    } else {
      console.log(value + "*****..." + checked);

      setStarttimevissible(false)
      setPlannedtimevisible(false)
      setDowntimereason(false)
      setDownTimeTypeVal(DownTimePlannedCategoryenum.UN_PLANNED)
    }
  }

  const assetCategorydropdown = () =>{
    downtimeTrackingService.assetCategoryDropDown().then((res) =>{
      if(res.data){
        setAssetCategory(res.data)
      }
      else{
        if (res.intlCode) {
        setAssetCategory([])
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }}
    })
  }

const onAssetCategoryChange = (value) =>{
  getMachineName(value)
}
const onassetQrCodeClick = (e) =>{
  setShowQrScan(true);
   setModal('QRScan')
}

  return (
    <Card size="small" title={<span style={{ color: 'white', fontSize: '20px' }}>DownTime Tracking</span>} style={{ textAlign: 'center' }} extra={props.isUpdate == true ? <Radio.Group name='radioGroup' defaultValue={'Un Planned'} onChange={onchange}>
    <Radio value="Planned" >Planned</Radio>
    <Radio value="Un Planned">Un Planned</Radio>
  </Radio.Group> : <><Radio.Group name='radioGroup' defaultValue={'Un Planned'} onChange={onchange}>
      <Radio value="Planned" >Planned</Radio>
      <Radio value="Un Planned">Un Planned</Radio>
    </Radio.Group><Link to='/downtimetracking-view'><span style={{ color: 'white' }}><Button className='panel_button'>View </Button></span></Link></>} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout={'vertical'} onFinish={saveData} initialValues={props.downtimeTrackingData} >
        <Row gutter={24}>
          <Form.Item
            name="downtimeTrackingId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="assetId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="unitId" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item
                name="assetCategoryId"
                label="Asset Category"
                rules={[
                  {
                    required: true,
                    message: 'Asset Category is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `select  Asset Category`
                  }
                ]}

              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select Asset Category"
                  optionFilterProp="children"
                  onChange={onAssetCategoryChange}
               
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select AssetCategory</Option>
                  {assetCategory?.map((data) => {
                    return <Option key={data.itemSubCategoryId} value={data.itemSubCategoryId}>{data.itemCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> 
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? false : true}>
            <Form.Item
              name="machineCode"
              label="Asset Code"

              rules={[
                {
                  required: form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? true : false,
                  // required:CategoryEnum.MACHINEISSUE || CategoryEnum.MANANDMACHINEISSUE ? true:false,
                  message: 'Machine code required'
                },

              ]}

            >
                <Select
                showSearch
                placeholder="Select orAsset Code "
                onChange={e =>machineCodeOnChange(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<QrcodeOutlined onClick={(e) => {onassetQrCodeClick(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px'}} />}
              >
                <Option key={0} value={null}>Select AssetCode</Option>
                {assetCode.map((value) => {
                  return <Option key={value.assetCode} value={value.assetCode}>{value.assetCode}</Option>
                })}
                
              </Select>
              {/* <Input placeholder='Enter or Scan machine' suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{ fontSize: '28px', marginTop: '3px', marginRight: '-6px' }} />} onChange={(e) => getMachineName(e.target.value)} /> */}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? false : true}>
            <Form.Item
              name="machineName"
              label="Asset Name"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={downTimeTypeVal == DownTimePlannedCategoryenum.PLANNED ? false : true }>
            <FormItem name='startTime' label='Start Time' initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: 'start time is required'
                }
              ]}
            >
              <DatePicker showTime disabled={props.isUpdate ? true : false} />

            </FormItem>
          </Col>

          {plannedtimevisible ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem name='plannedtime' label='Expected Time' initialValue={moment()}
                rules={[
                  {

                    required: true,
                    message: 'Expected time is required'
                  }
                ]}
              >
                <DatePicker showTime />

              </FormItem>
            </Col> : ''
          }
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={downTimeTypeVal == DownTimePlannedCategoryenum.PLANNED || props.isUpdate ? false:true}>
              <Form.Item
                name="reasonId"
                label="DownTime Reason"
                rules={[
                  {
                    required: props.isUpdate || downTimeTypeVal == DownTimePlannedCategoryenum.PLANNED? true : false,
                    message: 'DownTime Reason is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Enter the DownTimeReason`
                  }
                ]}

              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select DownTimeReason"
                  optionFilterProp="children"
                  onChange={handleDownTimeTracking}
                
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Reason</Option>
                  {downTimeReasonData?.map((downTimeReason) => {
                    return <Option key={downTimeReason.downtimereasonId} value={downTimeReason.downtimereasonId}>{downTimeReason.downtimeReason}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> 
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name='departmentId'
              label='Department'
              rules={[
                {
                  required: props.isUpdate ? true : false,
                  message: 'Department  is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the DepartmentReason`
                }
              ]}
            >

              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Department"
                optionFilterProp="children"
                // onChange={handlestatusTracking}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Department</Option>
                {departmentData?.map((value) => {
                  return <Option key={value.deptId} value={value.deptId}>{value.deptName}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: props.isUpdate ? true : false,
                  message: 'Category is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the category`
                }
              ]}
              initialValue={CategoryEnum.MACHINEISSUE}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select category"
                optionFilterProp="children"
                onChange={(value) => handleCategory(value)}
               
                onSearch={onSearch}
                defaultValue={CategoryEnum.MACHINEISSUE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Category</Option>
                {Object.values(CategoryEnum).map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: category == CategoryEnum.SALEORDER ? 'unset' : 'none' }}>
            <Form.Item
              name="saleorderId"
              label="PO Number"
              rules={[
                {
                  // required: props.isUpdate && ? true:false,
                  required: props.isUpdate && form.getFieldValue('category') == CategoryEnum.SALEORDER ? true : false,

                  message: 'Department  is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the DepartmentReason`
                }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select PO Number"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select PO Number</Option> */}
                {soDropDown?.map((value) => {
                  return <Option key={value.saleOrderId} value={value.saleOrderId}>{value.poNumber}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2}} lg={{ span: 2 }} xl={{ span:2}} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE  ? false :true}>
              <span><QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan')}} style={{fontSize:'30px', marginTop:'35px'}}/><b>scan</b></span>
            
             
            </Col> */}

        
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="resources"
              label="No Of Resources"

              rules={[
                {
                  required: props.isUpdate ? true : false,
                  message: 'resources required'
                },
                {
                  pattern: /^[0-9\b]+$/,
                  message: `Should contain only numbers`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="status"
              label="Status"
              initialValue={DownTimeTrackingStatusEnum.OPEN}

            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select status"
                optionFilterProp="children"
                // onChange={handleStatus}
                onChange={statusonchange}
                defaultValue={DownTimeTrackingStatusEnum.OPEN}
                onSearch={onSearch}
                disabled={props.isUpdate ? false : true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Status</Option> */}
                {Object.values(DownTimeTrackingStatusEnum).map((key, value) => {
                  return <Option key={key} value={key}>{key}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
      {endtimeVissible?
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}
          //  style={{ display: props.isUpdate && status == DownTimeTrackingStatusEnum.CLOSE ? 'unset' : 'none' }}
           >
            <FormItem
              name='endtime'
              label='End Time' initialValue={moment()}

              rules={[
                {
                  required:endtimeVissible,
                  message: 'EndTime is required',

                }]}
            >
              <DatePicker disabled showTime />

            </FormItem>
          </Col>:''
          }
         {resolutionvissible?
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} 
          >
            <Form.Item
              name="resolution"
              label="Resolution"
              rules={[
                {
                  required:resolutionvissible,
                  message: 'EndTime is required',

                }]}
            >
              <TextArea rows={1} />
            </Form.Item>
          </Col>:''}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="impact"
              label="Impact"
              rules={[
                {
                  required:status == DownTimeTrackingStatusEnum.CLOSE ? true : false,
                  message: 'Impact is required'
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="remarks"
              label="Remarks"
            >
              <TextArea rows={1} />
            </Form.Item>
          </Col>

          {/* //Practicee
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item
                name="resources"
                label="No Of Resources">
  
                <span><Input style={{padding:'15px'}} /><QrcodeOutlined style={{fontSize:'30px', marginTop:'35px',position:'absolute',top:'0',bottom:'0',padding:'10px'}}/></span>
  
              </Form.Item>
            </Col> */}


        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>

      <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'QRScan' ? <QrScanner handleScan={(value) =>{handleQrScan(value)}} /> : null
        }

      </Modal>

    </Card>
  );

}
export default NewDowntimeTrackingform;