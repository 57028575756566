import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {InterviewForm} from '@gtpl/pages/hrms/hrms-components/schedule-interview-form';

import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { InterviewService } from '@gtpl/shared-services/hrms';
import { InterviewDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';
import { InterviewType, StEnum } from '@gtpl/shared-models/common-models';
import { ColumnProps } from 'antd/lib/table';
export interface InterviewGridProps {}

export function InterviewGrid(props: InterviewGridProps) {
  const interviewService = new InterviewService();
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
const [interviewData,setInterviewData] = useState<InterviewDto[]>([])
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [slectedInterviewData, setSelectedInterviewData] = useState<any>(undefined);
  const [allData, setAllData] = useState<InterviewDto[]>([]);  
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  useEffect(() => {
    getAllInterview();
  }, []);
  const getAllInterview= () => {
    interviewService.getAllInterview().then(res => {
      if (res.status) {
         
        setInterviewData(res.data);
      } else {
        if (res.intlCode) {
          setInterviewData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setInterviewData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }

    
    const deleteInterview = (interviewData:InterviewDto) => {
      interviewData.isActive=interviewData.isActive?false:true;
      interviewService.activateOrDeactivateInterview(interviewData).then(res => { console.log(res);
         if(res.status) {
          getAllInterview();
          AlertMessages.getSuccessMessage('Success');
         }else {
           if (res.intlCode) {
             AlertMessages.getErrorMessage(res.internalMessage);
           } else {
             AlertMessages.getErrorMessage(res.internalMessage);
           }
         }
      }).catch(err =>{
        AlertMessages.getErrorMessage(err.message);
      })
    }

    const openFormWithData=(viewData:InterviewDto)=>{
      setDrawerVisible(true);
      console.log(viewData)
      viewData.interviewDate=moment(viewData.interviewDate, 'YYYY-MM-DD')
      setSelectedInterviewData(viewData);
      console.log(viewData)
      console.log('selectedInterview')
    }
    
    const columnsSkelton: ColumnProps<any>[] = [
      {
      title: 'S No',
      key: 'sno',
      width: '70px',

      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    // {
    //   title: 'Notification Id',
    //   dataIndex: 'notificationId',
      
    //     sorter: (a, b) => a.notificationId?.localeCompare(b.notificationId),
    //     sortDirections: ['descend', 'ascend'],
    //   //  render: (text, record) => {return moment(record.notification_id).format('YYYY-MM-DD')},
    //     // ...getColumnSearchProps('Interview Date')
    // },
	{
      title: 'Interview Date',
      dataIndex: 'interviewDate',
      
        sorter: (a, b) => a.interviewDate.localeCompare(b.InterviewDate),
        sortDirections: ['descend', 'ascend'],
       render: (text, record) => {return moment(record.interviewDate).format('YYYY-MM-DD')},
        // ...getColumnSearchProps('Interview Date')
    },
    {
      title: 'Interview Type',
      dataIndex: 'interviewType',
     
      sorter: (a, b) => a.interviewType?.localeCompare(b.interviewType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('interviewType')
    },
    {
      title: 'Interviewer',
      dataIndex: 'empfullname',
    
      sorter: (a, b) => a.empfullname?.localeCompare(b.empfullname),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('empfullname'),
      
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
   
        sorter: (a, b) => a.plant.localeCompare(b.plant),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('plant')
      },

    // {
    //   title: 'Department ',
    //   dataIndex: 'Department',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.user.localeCompare(b.ClientName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('Client Name')
    // },
	{
      title: 'Job Description', 
      dataIndex: 'jobDescription',
  
      sorter: (a, b) => a.jobDescription.localeCompare(b.jobDescription),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobDescription')
    },	

    // {
    //   title: 'Willing', 
    //   dataIndex: 'willing',
  
    //   sorter: (a, b) => a.willing.localeCompare(b.willing),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('willing')
    // },	
	
   
    {
      title: 'Candidate Name',
      dataIndex: 'candidateName',
 
      sorter: (a, b) => a.candidateName.localeCompare(b.candidateName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('candidateName')
    },
	
    
	
    // {
    //   title: 'Client Interviewer',
    //   dataIndex: 'Client Interviewer',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.user.localeCompare(b.ClientInterviewer),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('Client Interviewer')
    // },
	
    
    {
      title: 'Status',
      dataIndex: 'status',
      align:'center',
render: (status, rowData) => {
        console.log(rowData)
return(<>
          {status == StEnum.Open?<Tag color='yellow'>{StEnum.Open}</Tag>: status == StEnum.Selected? <Tag  color='cyan'>{StEnum.Selected}</Tag>:status == StEnum.Pending? <Tag color='magenta'>{StEnum.Pending}</Tag>: <Tag  color='orange'>{StEnum.Rejected}</Tag>}
          
        </>
)},
        
      
      filters: [
        {
          text: StEnum.Open,
          value: StEnum.Open,
        },
        {
          text: StEnum.Selected,
          value: StEnum.Selected,
        },
        {
          text: StEnum.Pending,
          value: StEnum.Pending,
        },
        {
          text: StEnum.Rejected,
          value: StEnum.Rejected,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        console.log(record)
        
        return record.status == value;
      },
      
    },
    
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',

    //   sorter: (a, b) => a.remarks?.localeCompare(b.remarks),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('remarks')
    // },


//     {
//       title: ' Status',
//       dataIndex: 'status',
//       render: (status, rowData) => {
//         console.log(rowData)
// return(<>
//           {status == StEnum.Open?<Tag icon={<CheckCircleOutlined />} color="#87d068">{StEnum.Open}</Tag>: status == StEnum.Selected? <Tag icon={<CloseCircleOutlined />} color="#f50">{StEnum.Pending}</Tag>: <Tag icon={<CloseCircleOutlined />} color="#f79707">{StEnum.Rejected}</Tag>}
          
//         </>
// )},
        
      
//       filters: [
//         {
//           text: StEnum.Open,
//           value: StEnum.Open,
//         },
//         {
//           text: StEnum.Selected,
//           value: StEnum.Selected,
//         },
//         {
//           text: StEnum.Pending,
//           value: StEnum.Pending,
//         },
//         {
//           text: StEnum.Rejected,
//           value: StEnum.Rejected,
//         },
//       ],
//       filterMultiple: false,
//       onFilter: (value, record) => 
//       {
//         console.log(record)
        
//         return record.status === value;
//       },
      
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'Active',
    //       value: true,
    //     },
    //     {
    //       text: 'InActive',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     return record.isActive === value;
    //   },
    // },
    {
      title:`Action`,
      dataIndex: 'action',

      render: (text, rowData) => (
        <span>  
           <Tooltip placement="top" title='Edit'>        
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={()=>{
                if (rowData.isActive){
                  openFormWithData(rowData);
                }else {
                  AlertMessages.getErrorMessage('You Cannnot Edit Deactivated interview');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          {/* <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteInterview(rowData);}}
            
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Interview ?'
                :  'Are you sure to Activate Interview  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm> */}
        </span>
      )
    }
  ];

  

  const updateInterview = (interviewData:InterviewDto) => {
    interviewService.updateInterview(interviewData).then(res => { console.log(res);
           if(res.status) {
            AlertMessages.getSuccessMessage('Updated Successfully');
            getAllInterview();
            setDrawerVisible(false);
          } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
          }
           
    )

  }

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  
  return (
    <>
    <Card title={<span style={{color:'white'}}>Interview</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/interview-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
     <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
          <Card title={'Total interview: ' + interviewData.length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
           {/* <Card title={'Active: ' + interviewData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
           <Card title={'In-Active: ' + interviewData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card> */}
          </Col>
        </Row>
          <br></br>
          <Table
          rowKey={record => record.interviewId}
          columns={columnsSkelton}
          dataSource={interviewData}
          // scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <InterviewForm key={Date.now()}  
              updateInterview={updateInterview}
              data={slectedInterviewData}
                isUpdate={true}                
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card> 
    </>
  );

            }
export default InterviewGrid;
