import React, { useState, useEffect } from 'react';
import {Button, Card, Col, DatePicker, Descriptions, Divider, Form, FormInstance, Input, Layout, Row, Select, Table, Tooltip, Typography, Upload, UploadProps,
} from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './documents.css';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { GrnData, IndentModel, IndentRequest } from '@gtpl/shared-models/raw-material-procurement';
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import {DocumentsListService} from '@gtpl/shared-services/logistics';
import { ItemsDetails, ProductIdRequest, SaleOrderDropDownDto, SaleOrderItemsDropDownDto, SaleOrderProductIdRequest } from '@gtpl/shared-models/sale-management';
import moment from 'moment';
import { DocumentType, EmployeeRolesEnum, PlantsEnum, SubPlantCodesEnum, UiConfig } from '@gtpl/shared-models/common-models';
import { ColumnProps } from 'antd/lib/table';
import { DocumentsListRequest, DocumentsUploadRequest } from '@gtpl/shared-models/logistics';

export interface DocumentUploadFormProps {
  saleOrderId: number;
  documentCategory: string;
  documentType: DocumentType;
  isUpdate: boolean;
  closeForm: () => void;
}
const { Option } = Select;
export const DocumentUploadForm = (props: DocumentUploadFormProps) => {
  const plantId = Number(localStorage.getItem('unit_id'));
  const userName = localStorage.getItem("createdUser");
  const [form] = Form.useForm();
  const [filelist, setfilelist] = useState([]);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [saleOrderNo, setSaleOrderNo] = useState<string>(undefined);
  const [fieldsData, setFieldsData] = useState<DocumentsListRequest>(undefined);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [indexVal, setIndexVal] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [tableData, setTableData] = useState<DocumentsListRequest[]>([]);
  const saleOrderService = new SaleOrderService();
  const documentsListService = new DocumentsListService();
  let history =  useHistory()

  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }

  useEffect(()=>{
    console.log(props);
    props.documentCategory === null ? setDisabled(false): setDisabled(true);
    getSaleOrderNo(props.saleOrderId);
  },[])
  const getSaleOrderNo = (saleorder) => {
    console.log(saleorder);
    saleOrderService.getSaleOrderInformation({saleOrderId:saleorder}).then((res) => {
      console.log(res.data);
      if (res.status) {
        setSaleOrderNo(res.data.saleOrderNumber);
        form.setFieldsValue({saleOrderId: saleorder});
        form.setFieldsValue({saleOrderNo: res.data.saleOrderNumber});
        form.setFieldsValue({documentCategory: props.documentCategory});
        form.setFieldsValue({documentType: props.documentType});
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderNo(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNo(undefined);
    });
  }
  

  const uploadDocuments = (values: any) => {
    setFieldsData(values);
    form.validateFields().then(res => {
      fieldsData.saleOrderId = res.saleOrderId;
      fieldsData.documentCategory = res.documentCategory;
      fieldsData.file = res.file.fileList;
      fieldsData.documentType = res.documentType;
      documentsListService.createDocumentsList(fieldsData).then((res) => {
        if(res.status){
          console.log(res.data);
          console.log(filelist);
          if (filelist.length > 0) {
            const formData = new FormData();
            formData.append('documentCategory', `${res.data[0].documentCategory}`);
            formData.append('saleOrderId', `${res.data[0].saleOrderId}`);
            res.data.forEach((value) => {
              formData.append('documentsListId', `${value.documentsListId}`);
            })

             filelist.forEach((file: any) => {
              formData.append('file', file);
            });
              console.log(formData);
              documentsListService.documentFileUpload(formData).then((res) => {
                console.log(res);
                if(res.status){
                  saleOrderService.updateDocumentStatus({saleOrderId:fieldsData.saleOrderId,plantId:plantId,documentName:fieldsData.documentCategory}).then((res) => {
                    if(res.status){
                      AlertMessages.getSuccessMessage(res.internalMessage);
                    }
                  })
                }
                else{
                  AlertMessages.getErrorMessage(res.internalMessage);
                }
              })
          }
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
    })

  }
  /**
   * To reset form fields
   */
  const onReset = () => {
    form.resetFields();
  };
  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setfilelist([]);
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(xls|pdf|jpg|png|doc)$/)) {
        AlertMessages.getErrorMessage("Only xls,pdf,jpg,png,doc files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        // if (filelist.length == 1) {
        //   AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
        //   return true;
        // } else {
          console.log(file);
          setfilelist([...filelist, file]);
          return false;
        // }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };
  const columns: ColumnProps<any>[] = [
    {
      title: 'Document Category',
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      align:'left',
    },
    {
      title: 'File Upload',
      dataIndex: 'fileName',
      key: 'fileName',
      align:'left',
    },

  ]
    const appendInputData = (val, label) => {
      console.log(val);
      console.log(label);
        setFieldsData({ ...fieldsData, [label]: val });
    }
  return (
   
    <Card
      title={<span style={{ color: 'white' }}>Upload Documents</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
        <Form layout="vertical" form={form}  name="control-hooks" onFinish={uploadDocuments}>
        <Row gutter={40}>
        <Form.Item name="saleOrderId" style={{ display: 'none' }}>
        <Input hidden />
        </Form.Item>
        <Form.Item name="documentType" style={{ display: 'none' }}>
        <Input hidden />
        </Form.Item>
        <Col span={6}>
            <Form.Item
                    name="saleOrderNo"
                    label="Sale Order No"
                    rules={[
                    {
                        required: true,
                        message: 'Sale Order No Is Required',
                    },
                    ]}
                >
                <Input disabled />
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item
                name="documentCategory"
                label="Document Category"
                rules={[
                {
                    required: true,
                    message: 'Document Category Is Required',
                },
                ]}
            >
                <Input onChange={e => appendInputData(e.target.value, 'documentCategory')} disabled={disabled}/>
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name="file" label="Upload" rules={[{ required: true, message: 'Please Upload File' },]}>
                <Upload {...uploadFieldProps} style={{  width:'100%' }} onChange={e => appendInputData(e.file.name, 'file')}>
                    <Button style={{ color: "white", backgroundColor: "#806767", width:'100%' }} icon={<UploadOutlined />} >Upload Document</Button><Typography.Text  type="secondary">
        (Supported formats xls,pdf,jpg,png,doc)
                </Typography.Text>
                </Upload>
            </Form.Item>
        </Col>
        <Col style={{paddingTop:30}}>
          <Form.Item >
            <br />
            <Form.Item >
                <Button style={{ backgroundColor: "#67b32d", color: "white" }} type="primary" htmlType="submit" >
                {btnChange ? 'UPDATE' : 'Upload'}</Button>
            </Form.Item>
            
          </Form.Item>
        </Col>
        
    </Row>
    <br/>
    <Row>
        <Col span={10}>
            {showTable ?
                <Table key={Date.now()} scroll={{ x: true }} dataSource={tableData} 
                columns={columns} pagination={false} /> :
                <></>
            }
        </Col>
    </Row>
    </Form>
    </Card>
  );
};

export default DocumentUploadForm;
