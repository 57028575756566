import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker, Table, InputNumber, Descriptions } from 'antd';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { ReProcessRequiredQty, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto, SaleOrderItemsDTO, SOItemsDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput, ProductionTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined, RedoOutlined, RotateRightOutlined } from '@ant-design/icons';
import { FGStockData, ReProcessingDtoReq, ReprocessingInputRequest, StockResponseDto } from '@gtpl/shared-models/warehouse-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { HlPercentageRangeRequest } from '@gtpl/shared-models/masters';
import { SoProductionDTO } from '@gtpl/shared-models/sale-management';

/* eslint-disable-next-line */
export interface ReProcessProps {
    SoItemData:SOItemsDto
 }
const { Option } = Select;
export function ReProcess(
    props: ReProcessProps
) {
    
    let location = useLocation();
    const containerStatusInfo: ContainerStatusInfo | any = location.state;
    
    const history = useHistory();

    const [ReProcessForm] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
    const [saleOrderItemsData, setSaleOrderItemsData] = useState<SaleOrderItemsDTO[]>([]);
    const [keyUpdate, setKeyUpdate] = useState<number>(1);
    const [updateStateKey, setUpdateStateKey] = useState<number>(1);
    const [fgData, setFgData] = useState<FGStockData[]>();
    const [requiredBomWeight, setRequiredBomWeight] = useState<number>(0);
    
    const SoService = new SaleOrderService();
    const containerRService = new ContainerRegisterService();
    const saleService = new SaleOrderService();
    const fgService = new FGStockService();
    
    useEffect(() => {
        getSaleOrderData();
        getSaleOrderDetailsById({saleOrderId:props.SoItemData.saleOrderId})
        if (props.SoItemData) {
            ReProcessForm.setFieldsValue({
                saleOrderId: props.SoItemData.saleOrderId,
                saleOrderItemId:props.SoItemData.saleOrderItemId
            });
        } 
        getReProcessingRequiredQuantity();
        // getReProcessStock(); 
        getHlPercentage();
    }, [props.SoItemData]);

    useEffect(()=>{
        if(requiredBomWeight > 0)
        getReProcessStock(); 
    },[requiredBomWeight])

    const getHlPercentage = () =>{
        const hlReq = new HlPercentageRangeRequest(props.SoItemData.maxGrade);

    }

    // get sale order drop down details
    const getSaleOrderData = () => {
        SoService.getSaleOrdersDropDownList().then((res) => {
            if (res.status) {
                setSaleOrderData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

    const getSaleOrderDetailsById=(req) =>{
        SoService.getSaleOrderDetailsById(req).then((res) => {
            if (res.status) {
                setSaleOrderItemsData(res.data.itemData);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderItemsData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderItemsData([]);
        });
    }

    const onReset = () => {
        ReProcessForm.resetFields();
        if (containerStatusInfo) {
            ReProcessForm.setFieldsValue({
                saleOrderId: containerStatusInfo.saleOrder.saleOrderId,
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT,
                containerId: containerStatusInfo.containerId
            });
        } else {
            ReProcessForm.setFieldsValue({
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT
            });
        }
    }

    const getReProcessStock=() =>{
        console.log(props.SoItemData)
        const reProcessReq = new ReProcessingDtoReq();
        reProcessReq.brand = props.SoItemData.brandId;
        reProcessReq.maxGrade = props.SoItemData.maxGrade;
        reProcessReq.minGrade = props.SoItemData.minGrade;
        reProcessReq.product = props.SoItemData.productId;
        reProcessReq.requiredRmQty = requiredBomWeight;
        reProcessReq.variantId = props.SoItemData.convVariantIds;//need to get re processed variants from variant code masters
        fgService.getAllAssignableReProcessingStock(reProcessReq).then((res)=>{
          if (res.status) {
            setFgData(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setFgData(undefined);
          }
        }).catch((error)=>{
          AlertMessages.getErrorMessage(error.message);
          setFgData(undefined);
        })
      }
      const getReProcessingRequiredQuantity = () =>{
        const req = new ReProcessRequiredQty(props.SoItemData.productId, props.SoItemData.saleOrderId, props.SoItemData.saleOrderItemId)
        saleService.getReProcessingRequiredQuantity(req).then((res)=>{
          if (res) {
            setRequiredBomWeight(res);
            history.push('/fh-dashboard-grid')
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setRequiredBomWeight(0);
          }
        }).catch((error)=>{
          AlertMessages.getErrorMessage(error.message);
            setRequiredBomWeight(0);
        })
      }

    const getColumns = () =>{
        const columns = [
            {
              title: 'So No',
              dataIndex: 'soNo',
              key: 'soNo',
              render:(value, record, index)=>{
                //   console.log(record)
                return index+1
              }
            },
            // {
            //     title: 'Soaking Style',
            //     dataIndex: 'soakingStyle',
            //     key: 'soakingStyle',
            //     render:(value)=>{
            //         return value
            //     }
            // },
            // {
            //     title: 'Soaking Time',
            //     dataIndex: 'soakingTime',
            //     key: 'soakingTime',
            //     render:(value)=>{
            //         return value
            //     }
            // },
            // {
            //     title: 'MFG Date',
            //     dataIndex: 'date',
            //     key: 'date',
            //     render:(value)=>{
            //         return moment(value).format('YYYY/MM/DD')
            //     }
            // },
            // {
            //     title: 'Pack Style',
            //     dataIndex: 'packStyle',
            //     key: 'packStyle',
            //     render:(value, record:FGStockData)=>{
            //         return record.pouches+"*"+record.pouchWeight +record.uom;
            //     }
            // },
            {
                title: 'Inventory(In Kgs)',
                dataIndex: 'qtyInKgs',
                key: 'qtyInKgs',
                render:(text)=>{
                    return <div style={{textAlign:'right'}}>{Number(text)}</div>
                }
            },
            {
                title: 'Assignable RM Qty(In Kgs)',
                dataIndex: 'assignableRMWeight',
                key: 'netWeight',
                render:(text)=>{
                    return <div style={{textAlign:'right'}}>{Number(text)}</div>
                }
            },
            // {
            //     title: 'Available QTY',
            //     dataIndex: 'netWeight',
            //     key: 'netWeight',
            //     render:(value, record:FGStockData)=>{
            //         return <div style={{textAlign:'right'}}>{Number(record.netWeight)}</div>
            //     }
            // },
            // {
            //     title: 'Assigned Quantity',
            //     dataIndex: 'assigningQty',
            //     key: 'assigningQty',
            //     render:(value, record:FGStockData)=>{
            //         console.log(record)
            //         if((record.totalCartons>0)){
            //             return <Form.Item name={record.stockId}><InputNumber min={0} max={((props.SoItemData.netWeight<record.netWeight)?props.SoItemData.netWeight:record.netWeight)} onChange={(val)=>checkAssigningQty(val, record, Number(record.pouches)*Number(record.pouchWeight))}/></Form.Item> 
            //         }else{
            //             return '' 
            //         }
                    
            //     }
            // },
            {
                title: 'Action',
                dataIndex: 'assign',
                key: 'assign',
                render: (value, record, index) => (
                <Button type="primary" style={{float:'right'}} onClick={()=>AssignQty(fgData)}><RedoOutlined /> Assign</Button>)
            },
        ];
        return columns;
    }

    const AssignQty = (record:FGStockData[])=>{
        console.log(record);
        const rmStock :FGStockData[] = record;
        let stockIds:number[]=[]
        fgData.forEach(element => {
            // element.saleOrderId = props.SoItemData.saleOrderId
            stockIds.push(element.stockId)
        });


        fgService.AssignReprocessQty(new ReprocessingInputRequest(props.SoItemData.saleOrderId,stockIds,props.SoItemData.saleOrderItemId,fgData[fgData.length-1].assignableRMWeight,'reProcess')).then((res)=>{
            if (res.status) {
                setFgData([]);
                // getReProcessStock();
                history.push('/fh-dashboard-grid')
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
              setFgData([]);
            }
          }).catch((error)=>{
            AlertMessages.getErrorMessage(error.message);
            setFgData([]);
          })
        //   saleService.updateReProcessQty(record).then((res)=>{
        //     getReProcessingRequiredQuantity();
        //     console.log(res);
        //   })
        // const req = new SoProductionDTO();
        // req.soProductionId =0;
        // req.type =ProductionTypeEnum.RE_PROCESSING;
        // // req.lotNumber =record.lotNumber;
        // // req.saleOrderId =record.assignedSoId;
        // // req.saleOrderItemId =record.assignedSoItemId;
        // // req.inputQuantity =record.assigningQty;
        // // req.outputQuantity =record.assigningQty;
        // req.createdUser =localStorage.getItem('createdUser');
        // req.updatedUser =localStorage.getItem('createdUser');
        // saleService.createSoProduction(req).then((res)=>{
        //   getReProcessingRequiredQuantity();
        // })
    }

    
    let record = 0;
    return (
        <Card title={<span style={{ color: 'white' }}>Re Process</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
            <Form layout="vertical" form={ReProcessForm} name="control-hooks">
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="saleOrderId" label="Sale Order" >
                            <Select
                                placeholder="Select SaleOrder"
                                allowClear
                                disabled={true}
                            >
                                <Option value={''}>Please Select</Option>
                                {saleOrderData.map(dropData => {
                                    return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="saleOrderItemId" label="Product" >
                            <Select
                                placeholder="Select Product"
                                allowClear
                                disabled={true}
                            >
                                <Option value={''}>Please Select</Option>
                                {saleOrderItemsData.map(dropData => {
                                    return <Option value={dropData.saleOrderItemId}>{dropData.product}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                    <Descriptions column={3}>
                        <Descriptions.Item label="Brand">
                            {props.SoItemData.brand}
                        </Descriptions.Item>
                        <Descriptions.Item label="Variant">
                            {props.SoItemData.varientId}
                        </Descriptions.Item>
                        <Descriptions.Item label="Grade">
                            {props.SoItemData.minGrade+" - "+props.SoItemData.MaxGlaze}
                        </Descriptions.Item>
                        <Descriptions.Item label="Soaking Style">
                            {props.SoItemData.soakingStyle}
                        </Descriptions.Item>
                        <Descriptions.Item label="Soaking Time">
                            {props.SoItemData.soakingTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="Pack Style">
                            {props.SoItemData.pouches +' * '+Number(props.SoItemData.pouchWeight)+' '+(props.SoItemData.uomId) }
                        </Descriptions.Item>
                        <Descriptions.Item label="Required Cases">
                            {props.SoItemData.cases}
                        </Descriptions.Item>
                        <Descriptions.Item label="Net Case Weight">
                            {Number(props.SoItemData.caseWeight)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Net Weight">
                            {Number(props.SoItemData.netWeight)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Net Drained Weight">
                            {Number(props.SoItemData.shrimpWeight)}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Quantity">
                            {Number(props.SoItemData.pouches)*Number(props.SoItemData.cases)*Number(props.SoItemData.shrimpWeight)}
                        </Descriptions.Item> */}
                        <Descriptions.Item label="Required Quantity">
                            {Math.round(requiredBomWeight)}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Assignable Quantity">
                            {Math.round(fgData?fgData[fgData.length-1].assignableRMWeight:0)}
                        </Descriptions.Item> */}
                    </Descriptions>
                    </Col>
                    
                </Row>
                <Table 
                    className ={'rmProcessing'}
                    key={keyUpdate}
                    // rowKey={(rowRecord: FGStockData) => rowRecord.stockId + record++} 
                    columns={getColumns()} 
                    dataSource={fgData?[fgData[fgData.length-1]]:[]}
                    pagination={false} />
            </Form>
        </Card>
    );
}

export default ReProcess;
