import { RouteService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Card, Col, Divider, Drawer, Input, Popconfirm, Row, Switch, Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Table, { ColumnProps } from "antd/lib/table";
import { RouteForm } from "../../../route-form/src";
import { Link } from "react-router-dom";
import { RouteDto } from "@gtpl/shared-models/hrms";

export interface RouteGridProps{}
export function RouteGrid(props:RouteGridProps){
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [routeData, setRouteData] = useState<RouteDto[]>([]);
  const [selectedRouteData, setSelectedRouteData] = useState<any>(undefined);
  const routeService = new RouteService();

  useEffect(() => {
    getAllRoute();
  }, []);
 
  const getAllRoute= () => {
    routeService.getAllRoute().then(res => {
      if (res.status) {
        setRouteData(res.data);
      } else {
        if (res.intlCode) {
            setRouteData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRouteData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteRoute = (routeData:RouteDto) => {
    routeData.isActive=routeData.isActive?false:true;
    routeService.ActivateorDeactivateRoute(routeData).then(res => { console.log(res);
      if (res.status) {
        getAllRoute();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateRoute = (routeData: RouteDto) => {
    routeService.updateRoute(routeData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllRoute();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    const openFormWithData=(viewData: RouteDto)=>{
      setDrawerVisible(true);
      setSelectedRouteData(viewData);
    }

    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'Route Number',
        dataIndex: 'routeNumber',
        // responsive: ['lg'],
        sorter: (a, b) => a.routeNumber.localeCompare(b.routeNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('routeNumber')
      }
      ,
      {
        title: 'Vehicle Model',
        dataIndex: 'vehicleNumber',
        // responsive: ['lg'],
        sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('vehicleNumber')
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        // responsive: ['lg'],
        sorter: (a, b) => a.distance.localeCompare(b.distance),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('distance')
      },

      {
        title: 'Plant',
        dataIndex: 'plant',
        // responsive: ['lg'],
        sorter: (a, b) => a.plant.localeCompare(b.plant),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('plant')
      },
      {
        title: 'Pickup Point',
        dataIndex: 'pickUp',
        // responsive: ['lg'],
        sorter: (a, b) => a.pickUp.localeCompare(b.pickUp),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('pickUp')
      },
    
      {
        title: 'Status',
        dataIndex: 'isActive',
        align:'center',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
            
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.isActive === value;
        },
        
      },
      {
        title:`Action`,
        dataIndex: 'action',
        render: (text, rowData) => (
          <span>
            <Tooltip placement="top" title='Edit'>          
              <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                onClick={() => {
                  if (rowData.isActive) {
                    openFormWithData(rowData);
                  } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated Route');
                  }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            </Tooltip>
            <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteRoute(rowData);}}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate Route ?'
                  :  'Are you sure to Activate Route ?'
              }
            >
              <Switch  size="default"
                  className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                  checkedChildren={<RightSquareOutlined type="check" />}
                  unCheckedChildren={<RightSquareOutlined type="close" />}
                  checked={rowData.isActive}
                />
              
            </Popconfirm>
          </span>
        )
      }
    ];

    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
    const OpenFormTocreateRecord = () => {
     console.log('redirect here');
    }
    return (
      <Card title={<span style={{color:'white'}}>Route</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/route-form' ><Button className='panel_button' >Create </Button></Link>}
      
      >
       <br></br>
       <Row gutter={40} >
        <Col>
            <Card title={'Total Route: ' + routeData.length} style={{textAlign: 'left', width: 220, height: 41,backgroundColor:'#bfbfbf'}}></Card>
            </Col>
            <Col>
             <Card title={'Active: ' + routeData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
            </Col>
            <Col>
             <Card title={'In-Active: ' + routeData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
            </Col>
            </Row>
            <br></br>
            <Table
            rowKey={record => record.routeId}
            columns={columnsSkelton}
            scroll={{x:true}}
            dataSource={routeData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                <RouteForm key={Date.now()}
                  updateRoute={updateRoute}
                  isUpdate={true}
                  routeData={selectedRouteData}
                  closeForm={closeDrawer} />
              </Card>
            </Drawer>
       </Card>
    );
  
}

export default RouteGrid;