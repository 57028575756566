import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Typography, Row, Select, Table, Tabs, Tooltip, Alert } from 'antd';
import { BarcodeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
// import './pages-asset-management-asset-management-components-mrn-grid.css';
import { ColumnProps } from 'antd/lib/table';
import { Scanner } from '../../../asset-classification-form/src/lib/Scanner';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLocationMappingService } from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import { AssetLocationMappingDto } from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import Highlighter from 'react-highlight-words';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { AssetsDto } from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { Redirect } from "react-router-dom";
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
const { TabPane } = Tabs;
const { Option } = Select;
import moment from 'moment';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { Excel } from 'antd-table-saveas-excel';
export interface AssetsAuditReportProps { viewrow(rowData: any, arg1: boolean): unknown; };
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';


export function AssetsAuditReport(
  props: AssetsAuditReportProps
) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  // const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<AssetLocationMappingDto[]>([]);
  // const [checkInData,setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  // const [selectedData,setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService();
  // const assignmentService = new AssetAssignmentService();
  // const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  // const [itemdata, setItemData] = useState<any>(null);
  // const [code,setCode]=useState(0)
  const locationService = new AssetLocationService();
  const AssignedforRent = new AssetService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  // const [transData,setTransData] = useState<any>([]);
  // const [insurance,setInsurance] = useState<any>([]);
  const [atenure, setAtenure] = useState<any>({});
  const [assetRentTenureType, setAssetRentTenureType] = useState<any>([]);
  const [length, setLength] = useState('');
  const [age, setAge] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);


  let diff = 0;
  let date;
  let todate;
  const { Text } = Typography;

  useEffect(() => {

    getAllPlants();
    getItemCategory();
    getDepartments();
    getByAssetCode();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getAssetCode();
    getLocations();
  }, [])

  const getItemCategory = () => {
    assetsService.getAssignedforRentCatogry().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getRentedAssetCodesDropDown(req).then((res) => {
      console.log(req)
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getByAssetCode = () => {
    const request = new AssetByAssetCode();
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
      request.assetsCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
      request.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
        request.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
      request.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
      request.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
      request.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
      request.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('result') !== undefined) {
      req.result = form.getFieldValue('result')
    }
    if (form.getFieldValue('days_between') !== undefined) {
      req.days_between = form.getFieldValue('days_between')
    }
    AssignedforRent.getAssignedforRent(req).then(res => {
      if (res.status) {

        setAssetsData(res.data)
        setLength(res.data.length)
        setAtenure(res.data.asset_rent_period)
        setAssetRentTenureType(res.data.asset_rent_tenure_type)
        setAge(res.data.days_between)

        console.log(atenure)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
        setAssetsData([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const handleBack = () => {
    setModal(false)
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: 60,
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      width: 130,
      align: 'left',
      ...getColumnSearchProps('plant'),
      sorter: (a, b) => a.plant.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title:'Location',
    //   dataIndex:'locationName',
    //   width:130,
    //   align:'left',
    //   ...getColumnSearchProps('locationName'),
    // },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetName')
    },
    {
      title: "Asset Code",
      dataIndex: "assetCode",
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetCode?.localeCompare(b.assetCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCode'),
      // render:(text,record)=>{
      //   return(<>
      //   <Button type='link'onClick={() => getDetailedView(record.assetsCode)}>
      // //   {record.assetsCode}</Button>
      //   </>)
      // }
    },


    {
      title: 'Asset Category',
      dataIndex: 'assetCategory',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetCategory.localeCompare(b.assetCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCategory')
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetType')
    },

    // {
    //   title:'Asset Condition',
    //   dataIndex:'assetcondition',
    //   width:130,
    //   align:'left',
    // },

    // {
    //   title:'Asset Status',
    //   dataIndex:'STATUS',
    //   width:130,
    //   align:'left',
    //   // sorter:(a,b) => a.STATUS.localeCompare(b.STATUS),
    //   // sortDirections:['descend','ascend'],
    //   // ...getColumnSearchProps('assetCategory')
    // },
    // {
    //   title:'Department',
    //   dataIndex:'department',
    //   width:110,
    //   align:'left',
    //   ...getColumnSearchProps('department')
    // },
    {
      title: "Commencement Date",
      dataIndex: "date_of_purchased",
      align: 'right',
      width: '5',
      sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('date_of_purchased'),
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : ' -'}
          </>
        )
      }

    },
    {
      title: 'Asset Rent Tenure Type',
      dataIndex: 'rental_cost_type',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.rental_cost_type?.localeCompare(b.rental_cost_type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rental_cost_type')
    },
    {
      title: "Total Rent Amount",
      dataIndex: "result",
      align: 'right',
      width: 130,
      sorter: (a, b) => a.result - b.result,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('result'),
      render: (text, record) => {

        return (
          <>
            {record.result ? Number(Number(record.result).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      }

    },
    // {
    //   title: "Rent payment period",
    //   key: "days_between",
    //   dataIndex: "days_between",
    //   width: 130,
    //   align: 'right',

    // },
    {
      title: "Due date",
      dataIndex: "end_date",
      width: 130,
      sorter: (a, b) => a.end_date?.localeCompare(b.end_date),
      sortDirections: ['descend', 'ascend'],
      align: 'right',
      render: (text, record) => {
        return (
          <>
            {record.end_date ? moment(record.end_date).format('YYYY-MM-DD') : ' -'}
          </>
        )
      }
    },
    {
      title: "Rent payment period",
      dataIndex: "end_date",
      width: 130,
      align: 'right',
      sorter: (a, b) => (Math.floor((new Date(a.end_date).getTime() - new Date(b.end_date).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.end_date).getTime() - new Date(b.end_date).getTime()) / (1000 * 60 * 60 * 24)) + 0),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.end_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
          props: {
            style: {
              background: Math.floor((new Date(record.end_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(record.end_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
            }
          },
        };
        return obj;
      }
    },


    // {
    //   title: "Under Rent",
    //   dataIndex: "aging",
    //      align:"left",
    //      render(text, record) {
    //       const x =5 ; const y =-1;const z= x+y;
    //       const obj: any = {

    //         children: (<div style={{ textAlign: 'right' }}>{Math.floor( z)}</div>),
    //         props: {
    //           style: {
    //             background: Math.floor(z) >= 0 ? "#38f438" : '#f4646c',
    //             color: Math.floor(z) >= 0 ? "Black" : 'white'
    //           }
    //         },
    //       };
    //       return obj;
    //     }
    // },


  ]

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })
    getByAssetCode()

  }

  const resetHandler = () => {
    form.resetFields();
    getByAssetCode();
    // getCheckIns();
  }

  const finishHandler = (value) => {
    console.log(value, '------------value')
    getByAssetCode();

  }

  const exportedData = [];
  const execlData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Asset Name', dataIndex: 'assetName', render: (text, record) => { return record.assetName ? record.assetName : '-' } },
    { title: 'Asset Code', dataIndex: 'assetCode', render: (text, record) => { return record.assetCode ? record.assetCode : '-' } },
    { title: 'Plant', dataIndex: 'plant', render: (text, record) => { return record.plant ? record.plant : '-' } },

    { title: 'Asset Category', dataIndex: 'assetCategory', render: (text, record) => { return record.assetCategory ? record.assetCategory : '-' } },
    { title: 'Asset Status', dataIndex: 'STATUS', render: (text, record) => { return record.STATUS ? record.STATUS : '-' } },
    // {title : 'Department', dataIndex:'department',render:(text,record) => {return record.department ? record.department : '-'}},
    { title: 'Commencement Date', dataIndex: 'date_of_purchased', render: (text, record) => { return record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : '-' } },
    { title: 'Asset Rent Tenure', dataIndex: 'asset_rent_tenure_type', render: (text, record) => { return record.asset_rent_tenure_type ? record.asset_rent_tenure_type : '-' } },
    { title: 'Total Rent Amount', dataIndex: 'result', render: (text, record) => { return record.result ? record.result : '-' } },
    { title: 'Rent payment period in days', dataIndex: 'days_between', render: (text, record) => { return record.days_between ? record.days_between : '-' } },

    {title:'Asset Type',dataIndex:'assetType',render:(text,record) => {return record.assetType ? record.assetType : '-'}},
    
    {title:'Due Date',dataIndex:'end_date',render:(text,record) => {return record.end_date ? record.end_date : '-'}},
    // {title:'Location',dataIndex:'location',render:(text,record) => {return record.location ? record.location : '-'}},
    // {title:'Insurance', dataIndex:'insurance',render:(text,record)=>{return record.insurance ? record.insurance : '-'}},
    {
      title: 'Asset Age (In Days)', dataIndex: 'date_of_purchased', render: (text, record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {record.date_of_purchased ? diff : null}
          </>
        )
      }
    },


  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('AssetAudit-report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('assetauditreport.xlsx');
  }
  function handleAgingFilter(values) {

  }



  return (

    <Card title={<span style={{ color: 'white' }}> Rental Asset - Audit Report </span>}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={assetsData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
    >
      <Form layout="vertical" form={form} onFinish={finishHandler}>
        <Row gutter={45}>
          <Col xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}>
            <Form.Item name="assetCode" label="Asset Code">
              <Select
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }} />}
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetCodeData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.concateditemName}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                defaultValue={'All'}
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.item_sub_category}>{items.item_sub_category}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Plant' name='plantId'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                showSearch
                allowClear>
                {/* <Option key={'all'} value={''}>All</Option> */}
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={3}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'all'}
            showSearch
            allowClear>
              <Option key={'all'} value={'all'}>All</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col> */}
          {/* <Col span={3}>
          <Form.Item label='Department' name='department'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'All'}
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All</Option>
            {depart.map((e) => {
              return<Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col> */}
          <Col span={5}>
            <Form.Item label='Rent Amount' name='result'>
              <Select
                placeholder='Amount'
                showSearch
                defaultValue={'All'}
                allowClear
                onChange={handleAgingFilter}
              >
                <Option key='All' value=''>All</Option>
                <Option key='below 300' value='300'>Rs 300 </Option>
                <Option key='301-5000' value='301-5000'>Rs 301-5,000</Option>
                <Option key='5001-50000' value='5001-50000'>Rs 5,001-50,000 </Option>
                <Option key='50001-100000' value='50001-100000'>Rs 50,001-1,00,000 </Option>
                <Option key='100001-200000' value='100001-200000'>Rs 1,00,001-2,00,000</Option>
                <Option key='200001-500000' value='200001-500000'>Rs 2,00,001-5,00,000</Option>
                <Option key='500001-100000' value='500001-1000000'>Rs 5,00,001-10,00,000</Option>
                <Option key='above 1000000' value='above 1000000'>more than 10,00,000</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Re-payment period' name='days_between'>
              <Select
                placeholder='Validity'
                showSearch
                defaultValue={'All'}
                allowClear
                onChange={handleAgingFilter}
              >
                <Option key='All' value=''>All</Option>
                <Option key='below 50' value='50'>Below 50 days</Option>
                <Option key='0-90' value='0-90'>Below 3 months</Option>
                <Option key='0-180' value='0-180'>Below 6 months</Option>
                <Option key='0-365' value='0-365'>Below 1 year</Option>
                <Option key='0-1095' value='0-1095'>Below 3 years</Option>
                <Option key='0-1825' value='0-1825'>Below 5 years</Option>
                <Option key='above 1825' value='above 1825'>Above 5 years</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }} >
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Search </Button>
            <Button style={{ margin: '0 14px' }} icon={<UndoOutlined />} onClick={resetHandler}>Reset</Button>
            <Button style={{ margin: '0 14px' }} >Total no of Records:{length}
            </Button>
          </Col>
        </Row>
      </Form>

      {/* <Tabs  type={'card'} tabPosition={'top'} onChange={(key) => setActiveTab(key)} activeKey={activeTab}> */}


      {assetsData.length > 0 ? (<>
        <Tabs>
          <TabPane key='1' > <Table columns={columns} dataSource={assetsData}

            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize)
              }
            }}
            scroll={{ x: 1000, y: 400 }}
            bordered
            summary={(pageData) => {
              let totalCost = 0;

              pageData.forEach(({ result }) => {
                if (Number(result)) {
                  totalCost += Number(result)
                }
              })

              return (
                <>
                  <Table.Summary.Row className="tableFooter">
                    <Table.Summary.Cell index={10} colSpan={7}><Text>Total</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={10} colSpan={1}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                  </Table.Summary.Row>
                </>
              )
            }}
          />

          </TabPane>

        </Tabs>
      </>) : (
        <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
      )}


    </Card>
  );


}

export default AssetsAuditReport;