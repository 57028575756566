export class VendorRequest {
 
    createdAt?:Date;
    updatedAt?:Date;
    vendorName?:string;

    constructor( 
        createdAt?:Date,
        updatedAt?:Date,
        vendorName?:string,

    ) {
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.vendorName = vendorName;
    
    }
}