import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, ConfigProvider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DesignationActionForm } from '@gtpl/pages/hrms/hrms-components/designation-action-form';
import { Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { DesignationActionDto } from '@gtpl/shared-models/hrms';
import { DesignationActionService } from '@gtpl/shared-services/hrms';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
export interface DesignationActionGridProps {}

export function DesignationActionGrid(props: DesignationActionGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  
  const [designationActionData, setDesignationActionData] = useState<DesignationActionDto[]>([]);
  const [selectedDesignationActionsData, setSelectedDesignationActionData] = useState<any>(undefined);
  const [allData, setAllData] = useState<DesignationActionDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const designationActionService = new DesignationActionService();
  useEffect(() => {
    getAllDesignationActions();
  }, []);

  /**
   * 
   */
  const getAllDesignationActions = () => {
    designationActionService.getAllDesignationActions().then(res => {
      if (res.status) {
        setDesignationActionData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setDesignationActionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationActionData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })


  }

  /**
   * 
   * @param designationActionData 
   */
  const deleteDesignationAction = (designationActionData: DesignationActionDto) => {
    designationActionData.isActive = designationActionData.isActive ? false : true;
    designationActionService.activateOrDeactivateDesignationAction(designationActionData).then(res => {
      console.log(res);
      if (res.status) {
        getAllDesignationActions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
  const updateDesignationAction = (Data: DesignationActionDto) => {
    designationActionService.updateDesignationAction(Data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllDesignationActions();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
        )
        : null

  });


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',

      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (text, record) => { return moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss') },
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Designation Date',
      dataIndex: 'designationDate',
      render: (text, record) => { return moment(record.designationDate).format('YYYY-MM-DD HH:mm:ss') },
      sorter: (a, b) => a.designationDate?.localeCompare(b.designationDate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      responsive: ['sm'],
       sorter: (a, b) => String(a.employeeCode)?.localeCompare(b.employeeCode),
       sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeCode'),
       
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',

      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    {
      title: 'From Department',
      dataIndex: 'department',

      sorter: (a, b) => a.department?.localeCompare(b.department),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('department')
    },
    {
      title: 'To Department',
      dataIndex: 'toDepartment',

      sorter: (a, b) => a.toDepartment?.localeCompare(b.toDepartment),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toDepartment')
    },
    {
      title: 'From Designation',
      dataIndex: 'fromDesignation',

      sorter: (a, b) => a.fromDesignation?.localeCompare(b.fromDesignation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromDesignation')
    },
    {
      title: 'To Designation',
      dataIndex: 'toDesignation',

      sorter: (a, b) => a.toDesignation?.localeCompare(b.toDesignation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toDesignation')
    },
    {
      title: 'Approved By',
      dataIndex: 'approvedBy',
    
      sorter: (a, b) => a.approvedBy?.localeCompare(b.approvedBy),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('approvedBy')
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
    //     </>
    //   ),
    //   filters: true,
    //   onFilter: true,
    //   valueEnum: {
    //     true: { text: 'Active', status: 'Success' },
    //     false: { text: 'InActive', status: 'Error' }
    //   }
    //   },
    // {
    //   title:`Action`,
    //   dataIndex: 'action',

    //   render: (text, rowData) => (
    //     <span> 
    //       <Tooltip placement="top" title='Edit'>         
    //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
    //           onClick={() => {
    //             if (rowData.isActive) {
    //               openFormWithData(rowData);
    //             } else {
    //               AlertMessages.getErrorMessage('You Cannot Edit Deactivated Exit Action');
    //             }
    //           }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />
    //       </Tooltip>
    //       {/* <Divider type="vertical" />
    //         <Popconfirm onConfirm={e =>{deleteDesignationAction(rowData);}}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate exit-action ?'
    //             :  'Are you sure to Activate exit-action ?'
    //         }
    //       >
            
    //       </Popconfirm> */}
    //     </span>
    //   )
    // }
  ];

  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  const openFormWithData = (viewData: DesignationActionDto) => {
    setDrawerVisible(true);
    setSelectedDesignationActionData(viewData); 
  }

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Designation Action</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/designation-action-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        
        <Table
          rowKey={record => record.DesignationActionId}
          columns={columnsSkelton}
          dataSource={designationActionData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <DesignationActionForm key={Date.now()}
              designationActionData={selectedDesignationActionsData}
              updateDesignationAction={updateDesignationAction}
              isUpdate={true}
              closeForm={closeDrawer}/>
          </Card>
        </Drawer>
      </Card>
    </>
  );
}


