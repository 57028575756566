import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { HlPercentageDto } from '@gtpl/shared-models/masters';
import { HLpercentagesService } from '@gtpl/shared-services/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { HlPercentagesForm } from '@gtpl/pages/master/master-components/hl-percentages-form';
import { Link } from 'react-router-dom';

import './hl-percentages-grid.css';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface HlPercentagesGridProps { }

export function HlPercentagesGrid(
  props: HlPercentagesGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');



  const [drawerVisible, setDrawerVisible] = useState(false);
  const [hlpercentagesData, setHlpercentagesData] = useState<HlPercentageDto[]>([]);
  const [selectedHlpercentagesData, setSelectedHlpercentagesData] = useState<any>(undefined);

  const hlpercentageDataService = new HLpercentagesService();

  useEffect(() => {
    getAllHlPercentage();
  }, []);

  const getAllHlPercentage = () => {
    hlpercentageDataService.getAllHlPercentage(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setHlpercentagesData(res.data);
      } else {
        if (res.intlCode) {
          setHlpercentagesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setHlpercentagesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const deleteHlPercentage = (hlpercentagesData: HlPercentageDto) => {
    hlpercentagesData.isActive = hlpercentagesData.isActive ? false : true;
    hlpercentageDataService.activateOrDeactivateHlPercentage(hlpercentagesData).then(res => {
      console.log(res);
      if (res.status) {
        getAllHlPercentage();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateGrade = (hlpercentagesData: HlPercentageDto) => {
  hlpercentagesData.updatedUser=localStorage.getItem('username')
    console.log(hlpercentagesData);
    hlpercentageDataService.updateGrade(hlpercentagesData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllHlPercentage();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData: HlPercentageDto) => {
    setDrawerVisible(true);
    setSelectedHlpercentagesData(viewData);
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Min Grade',
      dataIndex: 'minGrade',
      // responsive: ['lg'],
      sorter: (a, b) => a.minGrade - b.minGrade,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('minGrade'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
    },
    {
      title: 'Max Grade',
      dataIndex: 'maxGrade',
      // responsive: ['lg'],
      sorter: (a, b) => a.maxGrade - b.maxGrade,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('maxGrade'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
    },
    {
      title: 'HL Percentage',
      dataIndex: 'hlPercentage',
      // responsive: ['lg'],
      sorter: (a, b) => a.hlPercentage.localeCompare(b.hlPercentage),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('hlPercentage'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{Number(value)}</div>
      }
    },


    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated HL Percentage');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteHlPercentage(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate HL Percentage ?'
                : 'Are you sure to Activate HL percentage ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>HL Percentages</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/hl-percentages-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >


      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total HL Percentages: ' + hlpercentagesData.length} style={{ textAlign: 'left', width: 300, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + hlpercentagesData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + hlpercentagesData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.hlPercentageId}
        columns={columnsSkelton}
        dataSource={hlpercentagesData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <HlPercentagesForm key={Date.now()}
            updateHLpercentages={updateGrade}
            isUpdate={true}
            hlpercentagesData={selectedHlpercentagesData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default HlPercentagesGrid;

