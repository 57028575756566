import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, InputNumber, DatePicker, Form, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { ApplyForLeavesDto } from '@gtpl/shared-models/hrms';
import { ApplyForLeavesService } from '@gtpl/shared-services/hrms';
import { ApplyForLeavesForm } from '@gtpl/pages/hrms/hrms-components/apply-for-leaves-form'
import moment from 'moment';
import './apply-for-leaves-grid.css';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';

/* eslint-disable-next-line */
export interface ApplyForLeavesGridProps { }

export function ApplyForLeavesGrid(
  props: ApplyForLeavesGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [applyForLeavesData, selectedApplyForLeavesData] = useState<ApplyForLeavesDto[]>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>(undefined);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const applyForLeavesService = new ApplyForLeavesService();
  // const openFormWithData = (viewData : HrmsEmployeeDetailsDto)=>{
  //   setDrawerVisible(true);
  //   setEmployeeData(viewData);
  // }
  const hrmsEmpDetService = new HrmsEmpDetService;

  useEffect(() => {
    getAllAppliedLeaves();
  }, []);

  /**
   * 
   */
  const getAllAppliedLeaves = () => {
    applyForLeavesService.getAllAppliedLeaves().then(res => {
      if (res.status) {
        res.data.forEach((LeaveApproval) => {
          const req = new HrmsEmployeeRequest(LeaveApproval.employeeId);
          hrmsEmpDetService.getActiveEmployeesById(req).then(res => {
            LeaveApproval.employeeName = res.data.employeeName;
          })
          console.log(LeaveApproval.employeeId);
        });
        selectedApplyForLeavesData(res.data);
      } else {
        if (res.intlCode) {
          selectedApplyForLeavesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      selectedApplyForLeavesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param applyForLeavesData 
   */
  const deleteComplaint = (applyForLeavesData: ApplyForLeavesDto) => {
    applyForLeavesData.isActive = applyForLeavesData.isActive ? false : true;
    applyForLeavesService.activateOrDeactivateApplyForLeave(applyForLeavesData).then(res => {
      console.log(res);
      if (res.status) {
        getAllAppliedLeaves();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
  const updateLeave = (appliedLeavesData: ApplyForLeavesDto) => {
    applyForLeavesService.updateLeave(appliedLeavesData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllAppliedLeaves();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param dataIndex 
   */

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const openFormWithData = (viewData: ApplyForLeavesDto) => {
    setDrawerVisible(true);
    viewData.fromDate = moment(viewData.fromDate, 'YYYY-MM-DD')
    viewData.toDate = moment(viewData.toDate, 'YYYY-MM-DD')
    viewData.typeOfLeave = viewData.typeOfLeaveDesc;
    setSelectedLeave(viewData);
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeCode')
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    {
      title: 'Type of leave',
      dataIndex: 'typeOfLeaveDesc',
      // responsive: ['lg'],
      sorter: (a, b) => a.typeOfLeave?.localeCompare(b.typeOfLeaveDesc),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('typeOfLeaveDesc')
    },
    {
      title: 'From Date',
      dataIndex: 'fromDate',
      render: (text, record) => { return moment(record.fromDate).format('YYYY-MM-DD') },
      sorter: (a, b) => a.fromDate.localeCompare(b.fromDate),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'To Date',
      width: 100,
      dataIndex: 'toDate',
      render: (text, record) => { return moment(record.toDate).format('YYYY-MM-DD') },
      sorter: (a, b) => a.toDate.localeCompare(b.toDate),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: "Reason",
      dataIndex: "leaveReason"
    },

    {
      title: 'NO of Days',
      dataIndex: 'noOfDays',
      responsive: ['lg'],
      sorter: (a, b) => a.noOfDays.localeCompare(b.noOfDays),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('noOfDays'),

    },
    {
      title: `Action`,
      dataIndex: 'action',
      // hideInSearch: true,
      // hideInForm: true,
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Notifcation');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
        </span>
      )
    }
  ];


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (

    <Card title={<span style={{ color: 'white' }}>Applied leaves</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/apply-for-leaves' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <br></br>
      <Row gutter={40}></Row>
      <br></br>
      <br></br>
      <Table

        rowKey={record => record.holidayId}
        columns={columnsSkelton}
        dataSource={applyForLeavesData}
        scroll={{ x: true }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />

      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <ApplyForLeavesForm key={Date.now()}
            updateDetails={updateLeave}
            isUpdate={true}
            applyForLeavesData={selectedLeave}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>

    </Card>
  );
}

export default ApplyForLeavesGrid;
