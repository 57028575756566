import React, { useEffect, useState } from 'react';
import {Button, Card, Col, Form, Input, Row, Select, } from 'antd';
import { Link,useHistory} from 'react-router-dom';
import './pages-asset-management-asset-management-components-asset-sale-form.css';
import { ItemsService } from 'libs/shared-services/masters/src/lib/items-service';
import { ItemSubCategoryService } from 'libs/shared-services/masters/src/lib/item-sub-category-service';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemBySubcategory } from "libs/shared-models/masters/src/lib/items/item-by-subcategory";
import { ItemsDto } from '@gtpl/shared-models/masters';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { AssetService } from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { AssetDto, AssetsId } from '@gtpl/shared-models/asset-management';
import {AssetsSaleService} from 'libs/shared-services/asset-management/src/lib/asset-sale';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import {UnitcodeService} from '@gtpl/shared-services/masters';
import { AssetsSaleDto } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-sale.dto';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

/* eslint-disable-next-line */
export interface AssetSaleFormProps {}

export function AssetSaleForm(
  props: AssetSaleFormProps
) {

  const [form] = Form.useForm();
  const [itemCategory, setItemCategory] = useState([]);
  const [item, setItem] = useState<ItemsDto[]>([]);
  const service = new AssetsSaleService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const assetsService = new AssetService();
  const [assetCodeData, setAssetCodeData] = useState<any>([]);
  const [showAssigned,setShowAssigned] = useState<boolean>(false);
  const employeeService = new EmployeeService();
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [profitLossVisible,setprofitLossVisible] = useState<boolean>(false);
  let history = useHistory();



  useEffect(()=>{
    getItemCategory();
    getAssetCode();
    employeeDetails();
    getAllPlants();
  },[])

  useEffect(() => {
    onAssetSaleChange()

  },[profitLossVisible])

  const getItemCategory = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('plant') !== undefined){
      req.plantId = form.getFieldValue('plant')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'))
    }
    service.getassetCategoryDropDownforAssetSale(req).then((res) => {
      if (res.status) {
        console.log(res.data,'------itemdata')
        setItemCategory(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const getAssetCode = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('plant') !== undefined){
      req.plantId = form.getFieldValue('plant')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'))
    }    
    if(form.getFieldValue('assetCategory') !== undefined){
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    service.getassetCodeDropDownforAssetSale(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      // console.log(props?.assetData)
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const getAllPlants = () => {
    plantsService.getAllPlants().then((res) => {
      if (res.status) {
        console.log(res, '---------response');
        setPlantName(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };


  const onFinish = (values) => {
    const req = new AssetsSaleDto(null,values.assetId,values.approvedBy,values.assetSaleCost,AssetStatusEnum.SOLD,values.reason,true);
    service.createAssetSale(req).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage('Created SUccessfully')
        history.push('/asset-sale-grid')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
   
  };

  const getAllAssetsDataById = () => {
    const req = new AssetsId()
    req.assetId = form.getFieldValue('assetId')
    assetsService.getAllAssetsDataById(req).then((res) => {
      if (res.status) {
        let currentamount = 0;
        // setAssetsData(res.data);
        form.setFieldsValue({status:res.data[0].status,assetCost:Number(Number(res.data[0].assetCost).toFixed(0)).toLocaleString('en-IN') })
        currentamount = Number(res.data[0].assetCost);
        console.log(currentamount,'---------aurrentamount')
        for(let i=0;i<res.data[0].yeardiff;i++){
              let amount = (currentamount/100)*5;
              currentamount = currentamount - amount;
            }
        console.log(currentamount,'--------amount')
        form.setFieldsValue({currentAssetCost: Number(Number(currentamount).toFixed(0)).toLocaleString('en-IN')})
        if(res.data[0].status == AssetStatusEnum.RETIRED || res.data[0].status == AssetStatusEnum.DAMAGED){
          console.log(res.data[0].status,'--------status')
          setShowAssigned(false)
        } else {
          setShowAssigned(true)
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onPlantChange = (value) => {
    getItemCategory();
    getAssetCode();
  };

  const onReset = () => {
    form.resetFields();
    setprofitLossVisible(false)
    setShowAssigned(false)
  };

  const onAssetSaleChange = () => {
    console.log('==========')
    const salecost = form.getFieldValue('assetSaleCost')
    console.log(salecost,'--------salecost')
    const currentcost = form.getFieldValue('currentAssetCost')
    console.log(currentcost,'--------currentcost')

    const diff = salecost - currentcost;
    console.log(diff,'-----diff')
    if(diff > 0) {
      form.setFieldsValue({profitLoss: 'Profit'})
    } else if(diff < 0 ) {
      form.setFieldsValue({profitLoss: 'Loss'})

    }
  }


  return (
    <>
    <Card
    title={<span style={{ color: 'white' }}> Asset Sale Form</span>}
    style={{ textAlign: 'center' }}
    headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    extra={
      <Link to="/asset-sale-grid">
        <span style={{ color: 'white' }}>
          <Button>View </Button>{' '}
        </span>
      </Link>

    }
    >
    <Form
    form={form}
    name="control-hooks"
    layout="vertical"
    onFinish={onFinish}
    >
    <Row gutter={24}>
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 5, offset: 1 }}
      lg={{ span: 5, offset: 1 }}
      xl={{ span: 5, offset: 1 }}
      style={{ margin: '1%' }}
    >
      <Form.Item
        name="plant"
        label="Plant"
        rules={[
          {
            required: true,
            message: 'Plant is required',
          },
        ]}
        initialValue={role == 'SUPERADMIN' ? '' : Number(localStorage.getItem('unit_id'))}
      >
        <Select
          onChange={onPlantChange}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
          showSearch
          allowClear
          // disabled={isdisabled}
          disabled={Number(localStorage.getItem('unit_id')) != 5}
          defaultValue={role == 'SUPERADMIN' ? '' : Number(localStorage.getItem('unit_id'))}
        >
          {plantName.map((plant) => {
            return (
              <Option key={plant.plantId} value={plant.plantId}>
                {plant.plantName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 5, offset: 1 }}
      lg={{ span: 5, offset: 1 }}
      xl={{ span: 5, offset: 1 }}
      style={{ margin: '1%' }}
    >
      <Form.Item
        name="assetCategory"
        label="Asset Category"
      >
        <Select
          placeholder="asset category"
          showSearch
          onChange={getAssetCode}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
          allowClear
        >
          {itemCategory.map((items) => {
            return (
              <Option
                key={items.item_sub_category_id}
                value={items.item_sub_category_id}
              >
                {items.item_sub_category}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="assetId"
            label="Asset Code"
            
        rules={[
          {
            required: true,
            message: 'Asset Category is required',
          },
        ]}
            
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder='Asset Code'
              onChange={getAllAssetsDataById}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {assetCodeData.map((assets) => {
                return <Option key={assets.assets_code} value={assets.asset_id}>{`${assets.item_name} -- ${assets.assets_code}`}</Option>
              })

              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="status"
            label="Status"
          >
            <Input disabled/>
          </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="assetCost"
            label="Asset Cost"
          >
            <Input disabled/>
          </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="currentAssetCost"
            label="Current Asset Cost"
          >
            <Input disabled/>
          </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item name="assetSaleCost"
            label="Asset Sale Cost"
            rules={[
              {
                required: true,
                message: 'Asset Sale Cost is required',
              },
            ]}
          >
            <Input/>
          </Form.Item>
          </Col>
          {/* {
            profitLossVisible ? (<>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item name="profitLoss"
                  label="Profit / Loss"
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
            </>) : (<></>)
          } */}
          
          {
            showAssigned ? (<>
              
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>

              <Form.Item name="approvedBy"
                label="Approved By"
                rules={[
                  {
                    required: true,
                    message: 'Asset Sale Approved By is required',
                  },
                ]}
              >
                 <Select
                  optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().startsWith(input.toLowerCase())
                  }
                  showSearch
                  allowClear
                  
                >
                  {employeeData.map((employee) => {
                    return (
                      <Option
                        key={employee.employeeId}
                        value={employee.employeeId}
                      >
                        {employee.employeeName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} style={{ margin: '1%' }}>

              <Form.Item name="reason"
                label="Reason"
                rules={[
                  {
                    required: true,
                    message: 'Reason is required',
                  },
                ]}
              >
                 <TextArea rows={2} />
              </Form.Item>
              </Col>
            </>):(<></>)
          }

    </Row>
    <Row gutter={24}>
          <Col style={{marginLeft:"80%"}}>
            <Button type="primary"  htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
            {/* } */}
          </Col>
      </Row>
    </Form>
    </Card>

    </>
  );
}

export default AssetSaleForm;

