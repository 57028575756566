import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Descriptions, Form, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React,{useState} from 'react';
import { Link } from 'react-router-dom';


export interface NMenuProps {}

export function NMenu( props: NMenuProps) 
{


  return (
    <Card title={<span style={{color:'white'}}>Self Service Portal</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/self-service-portal' ><span style={{color:'white'}} ><Button className='panel_button' >Log Out</Button> </span></Link>}   >
     <br></br>
    <Form
      name="Menu"
      className="Menu"
      initialValues={{
        remember: true,
      }}
    >
   <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 24, offset: 2 }} style={{ margin: '1%' }}>
      <Form.Item>
      <Descriptions column={4}>
          <Descriptions.Item label="Empl Code">
          </Descriptions.Item>
          <Descriptions.Item label="Empl Name">
          </Descriptions.Item>
          <Descriptions.Item label="Designation">
          </Descriptions.Item>
          <Descriptions.Item label="Department">
          </Descriptions.Item>
        </Descriptions> 
        </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
        <Form.Item> 
        
        <Link to='/leave-balance-sheet'><img  src={require("./leaves.png")}/><br/>Leave Balance</Link>
        </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
        <Form.Item>      
        
        <Link to='/attandance'><img src={require("./images.jpg")} style={{width:"179px"}}/> <br/>Attendance</Link>
      </Form.Item> 
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
        <Form.Item>
        
        <Link to='/ot-details'> <img src={require("./ot.jpg")} style={{width:"70px"}}/><br />OT Details</Link>
      </Form.Item>
      </Col>
      </Row>
    </Form>  
    </Card>
  );
};
export default NMenu;
