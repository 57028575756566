import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import {DestinationDto,CountryDto}  from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

import './destination-form.css';
import {  Link,useHistory } from "react-router-dom";
import { DestinationService } from '@gtpl/shared-services/masters';
import {CountryService} from '@gtpl/shared-services/masters';
/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};


export interface DestinationFormProps {
  destinatonData: DestinationDto;
  updateDetails:(destination:DestinationDto)=>void;
 isUpdate:boolean;
closeForm: () => void;
}


export function DestinationForm(props: DestinationFormProps) {
  const [form] = Form.useForm();
  const service = new DestinationService;
  const countryService = new CountryService;
  const [selectedCountry, setSelectedCountry] = useState<number>(null);
  const [disable, setDisable] = useState<boolean>(false)
  const [countryData, setCountryData] = useState<CountryDto[]>([]);
useEffect(()=> {getAllActiveCountries()},[])

let history = useHistory();

  const save = (destinationData: DestinationDto) => {
    setDisable(true)
       service.createDestination(destinationData).then(res => {
        setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Destination Created Successfully');
       history.push("/destination-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveCountries=() =>{
    countryService.getAllActiveCountries().then(res => {
      if(res.status){
        // AlertMessages.getSuccessMessage('Countries Retrived successfully');
        setCountryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleCountry=(value)=>{
    setSelectedCountry(value);
  }

  
  const saveData = (values: DestinationDto) => {
    setDisable(false)
    // console.log(values);
    if(props.isUpdate){
      props.updateDetails(values);
    }else{
      setDisable(false)
      save(values);
    }
  
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }
  return (
    
   
    <Card size="small" title={<span style={{color:'white'}}>Destination</span>} style={{textAlign:'center'}} extra={props.isUpdate==true?"":<Link to='/destination-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
    <br /><br />
    <Form form={form} onFinish={saveData} initialValues={props.destinatonData} >
      <Row gutter={24}>
        <Form.Item 
        name ="destinationId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
          name="countryId"
          label="Country Name"
          rules={[
            {
              required: true,
              message: 'Country name is required'
            },      
            {
              pattern:/^(?!\s*$).+/,
              message: `Enter the Country Name`
            }
          ]}
        >
          <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={handleCountry}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Country</Option>
                {countryData.map((country)=>{
                  return <Option key={country.countryId} value={country.countryId}>{country.countryName}</Option>
                })}
              </Select>
        </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
        <Form.Item
          name="destinationName"
          label="Destination Name"         
          rules={[
            {
              required: true,
              message: 'Destination is required'
            },      
            {
              pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
              message: `Should contain only alphabets`
            }
          ]}>
        
          <Input/>
        </Form.Item>
        </Col>
        </Row>
       
        <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
        <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
          </Col>
          </Row>
       </Form>
          </Card>
  );       
    }
    

   
  

export default DestinationForm;
