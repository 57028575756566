import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col, InputNumber, Modal, Descriptions, Popover, Tag, Table, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './shippment-details-form.css';
import moment from 'moment';
import {ContainerRegisterService} from '@gtpl/shared-services/logistics';
import { ShipmentDetailsDto } from '@gtpl/shared-models/logistics';
import { InsuranceType } from '@gtpl/shared-models/common-models';
import {InsuranceService} from '@gtpl/shared-services/masters'
import { InsuranceDto } from '@gtpl/shared-models/masters';
import { Link, useHistory, useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ShippmentDetailsFormProps {
  containerId:number;
  containerNo:string;
  updateData: ShipmentDetailsDto;
  // setModalVisible: (req: boolean) => void;
}

export function ShippmentDetailsForm(
  props: ShippmentDetailsFormProps
) {

  let history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [insuranceData, setInsuranceData] =useState<InsuranceDto[]>([]);
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetailsDto>(undefined)

  const onReset = () => {
    form.resetFields();
  };
  const containerRegisterService = new ContainerRegisterService;
  const insuranceService = new InsuranceService;
  let location = useLocation();
  const stateData: any = location.state;
  console.log(stateData);
  const containerNo = stateData.containerNo;
  const containerId = stateData.containerId;
  const updateState:ShipmentDetailsDto = stateData.updateState;
  console.log(containerId);
  console.log(containerNo);
  console.log(updateState);
  useEffect(()=> {
    if(updateState != undefined){
      form.setFieldsValue({containerNo:updateState.containerNo});
      form.setFieldsValue({containerId:updateState.containerId});
      form.setFieldsValue({trackingNo:updateState.trackingNo});
      form.setFieldsValue({shippedOnboardDate:updateState.shippedOnboardDate ? moment(updateState.shippedOnboardDate):moment()});
      form.setFieldsValue({vesselName:updateState.vesselName});
      form.setFieldsValue({portOfTranshipment:updateState.portOfTranshipment});
      form.setFieldsValue({dateOfTranshipment:updateState.dateOfTranshipment ? moment(updateState.dateOfTranshipment):moment()});
      form.setFieldsValue({importingVesselName:updateState.importingVesselName});
      form.setFieldsValue({portOfDischarge:updateState.portOfDischarge});
      // form.setFieldsValue({dischargeDate:updateState.dischargeDate!=null?updateState.dischargeDate:moment()});
      // form.setFieldsValue({destinationDate:updateState.destinationDate!=null?updateState.destinationDate:moment()});
      form.setFieldsValue({hoCertificateNo:updateState.hoCertificateNo});
      // form.setFieldsValue({hoIssuedDate:updateState.hoIssuedDate!=null?updateState.hoIssuedDate:moment()});
      form.setFieldsValue({vcNo:updateState.vcNo});
      form.setFieldsValue({documentDispatchStatus:updateState.documentDispatchStatus});
      form.setFieldsValue({couriourNo:updateState.couriourNo});
      form.setFieldsValue({insuranceType:updateState.insuranceType});
      // form.setFieldsValue({documentDispatchDate:updateState.documentDispatchDate!=null?updateState.documentDispatchDate:moment()});
      form.setFieldsValue({insuranceId:updateState.insuranceId});
      form.setFieldsValue({insuranceAmount:updateState.insuranceAmount});
      form.setFieldsValue({referenceNo:updateState.referenceNo});
      // form.setFieldsValue({insuranceDate:updateState.insuranceDate!=null?updateState.insuranceDate:moment()});
      form.setFieldsValue({shippingBillNo:updateState.shippingBillNo});
      form.setFieldsValue({shippingBillDate:updateState.shippingBillDate ? moment(updateState.shippingBillDate) : moment()});
      handleInsuranceType(updateState.insuranceType)
    }
  },[])
  // const getContainerDetails=(containerId) =>{
  //     containerRegisterService.getShipmentDetails({containerId:containerId}).then((res) => {
  //     if(res.status){
  //       console.log(res.data);
  //       setShipmentDetails(res.data);
        
  //     }
  //     else{
  //       setShipmentDetails(undefined);
  //     }
  //     setShipmentDetails(undefined)
  //   })
  // }
  const handleInsuranceType=(value) =>{
    console.log(value)
    insuranceService.getInsuranceByType({insuranceType:value}).then((res) => {
      if(res.status){
        setInsuranceData(res.data)
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else{
        setInsuranceData([])
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setInsuranceData([])
    })
  }
  const saveData = (values) => {
    console.log(values)
    values.dischargeDate = values.dischargeDate ? moment(values.dischargeDate).format("YYYY-MM-DD") : null
    values.insuranceDate =  values.insuranceDate ? moment(values.insuranceDate).format("YYYY-MM-DD") : null
    values.shippedOnboardDate = values.shippedOnboardDate ? moment(values.shippedOnboardDate).format("YYYY-MM-DD") : null
    values.dateOfTranshipment = values.dateOfTranshipment ? moment(values.dateOfTranshipment).format("YYYY-MM-DD") : null
    values.destinationDate = values.destinationDate ? moment(values.destinationDate).format("YYYY-MM-DD") : null
    values.hoIssuedDate = values.hoIssuedDate ? moment(values.hoIssuedDate).format("YYYY-MM-DD") : null
    values.documentDispatchDate = values.documentDispatchDate ? moment(values.documentDispatchDate).format("YYYY-MM-DD") : null
    values.shippingBillDate = values.shippingBillDate ? moment(values.shippingBillDate).format("YYYY-MM-DD") : null
    console.log(values);
    containerRegisterService.updateShipmentDetails(values).then((res) => {
      if(res.status){
        // props.setModalVisible(false);
        AlertMessages.getSuccessMessage(res.internalMessage);
          history.push("/bl-status-grid");
      }
      else{
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }

  
  return (
    <>
    <Card
      title={<span style={{ color: 'white' }}> Shippment Details </span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={
          <Link to="/bl-status-grid">
            <Button className="panel_button">View </Button>
          </Link>
      }
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={saveData}
      >
        <Row>
        <Form.Item
              name="containerId"
              
              initialValue={props.containerId}
            >
              <Input hidden/>
            </Form.Item>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="containerNo"
              label="Container No"
              rules={[
                {
                  required: true,
                  message: 'Container No is required',
                },
              ]}
              initialValue={containerNo}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="trackingNo"
              label="Bill of Lading No"
              rules={[
                {
                  required: true,
                  message: 'Bill of Lading No is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="shippedOnboardDate"
              label="Shipped Onboard Date"
              rules={[
                { required: true, message: 'Shipped Onboard Date is required' },
              ]}
            >
              <DatePicker  name="shippedOnboardDate" style={{ width: '100%' }}  format={'YYYY-MM-DD'} />

            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="vesselName"
              label="Originating Vessel Name"
              rules={[
                { required: true, message: 'Date is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="portOfTranshipment"
              label="Port Of Transhipment"
              rules={[
                { required: false, message: 'Port Of Transhipment is required' },
              ]}
              initialValue="Vizag"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="dateOfTranshipment"
              label="Date Of Transhipment"
              rules={[
                { required: false, message: 'Date Of Transhipment is required' },
              ]}
            >
              <DatePicker  name="dateOfTranshipment" style={{ width: '100%' }}  format={'YYYY-MM-DD'} />

            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="importingVesselName"
              label="Importing Vessel Name"
              rules={[
                { required: false, message: 'Importing Vessel Name is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="portOfDischarge"
              label="Port Of Discharge"
              rules={[
                { required: true, message: 'Port Of Discharge is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="dischargeDate"
              label="Final Port of Discharge Date"
              rules={[
                { required: false, message: 'Final Port of Discharge is required' },
              ]}
            >
              <DatePicker  name="dischargeDate" style={{ width: '100%' }} format={'YYYY-MM-DD'} />
            </Form.Item>
          </Col>
       
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="destinationDate"
              label="Final Destination Date"
              rules={[
                { required: false, message: 'Final Destination Date is required' },
              ]}
            >
              <DatePicker  name="destinationDate" style={{ width: '100%' }} format={'YYYY-MM-DD'} />
            </Form.Item>
          </Col>
          </Row>
        <Row>
          
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="hoCertificateNo"
              label="HO Certificate No"
              
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="hoIssuedDate"
              label="HO Issued Date"
            >
              <DatePicker  name="hoIssuedDate" style={{ width: '100%' }} format={'YYYY-MM-DD'} />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="vcNo"
              label="VC No"
              
            >
              <Input />
            </Form.Item>
          </Col>
       
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="documentDispatchStatus"
              label="Document Dispatch Status"
             
            >
              <Select
                showSearch
                placeholder="Select Dispatch Status"
                allowClear = {true}             
              >
                <Select.Option value="YES"> YES </Select.Option>
                <Select.Option value="NO"> NO </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="couriourNo"
              label="Reference No / Couriour No"
              
            >
              <Input />
            </Form.Item>
          </Col>
          </Row>
        <Row>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="documentDispatchDate"
              label="Document Dispatch Date"
            >
              <DatePicker  name="documentDispatchDate" style={{ width: '100%' }} format={'YYYY-MM-DD'} />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="insuranceType"
              label="Insurance Type"
              
            >
              <Select
                showSearch
                placeholder="Select Insurance Type"
                allowClear = {true}    
                onChange={handleInsuranceType}

              >
                <Select.Option value="INTERNAL"> INTERNAL </Select.Option>
                <Select.Option value="CLIENT"> CLIENT </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="insuranceId"
              label="Insurance"
            >
              <Select
                showSearch
                placeholder="Select Insurance"
                allowClear = {true}    
              >
                {insuranceData.map(dropData => {
                  return <Option value={dropData.insuranceId}>{dropData.companyName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="insuranceAmount"
              label="Insurance Amount (in USD)"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="referenceNo"
              label="Referenec No"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      <Row>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="insuranceDate"
              label="Insurance Date"
            >
             <DatePicker  name="insuranceDate" style={{ width: '100%' }}  format={'YYYY-MM-DD'} />

            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="shippingBillDate"
              label="Shipping Bill Date"
            >
             <DatePicker  name="shippingBillDate" style={{ width: '100%' }}  format={'YYYY-MM-DD'} />

            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{marginLeft:'18px'}}
          >
            <Form.Item
              name="shippingBillNo"
              label="Shipping Bill No"
              rules={[
                { required: true, message: 'Shipping Bill No is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} style={{marginLeft:'18px'}}>
            <Form.Item 
            name="bookingNo"
            label="Booking No"
            rules={[
              { required: false, message: 'Shipping Bill No is required' },
            ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          {/* <>scac code given for temperory as  booking container manually at present Need to remove when the complete process is live</> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{marginLeft:'18px'}} >
                <Form.Item
                name="scac"
                label="SCAC"
                >
                    <Input />
                </Form.Item>
        </Col>
        </Row>
        <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
        </Col>
        </Row>
        </Form>
    </Card>
    </>
  );
}

export default ShippmentDetailsForm;
