import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

export interface GraphProps {}

export function Graph(
  props: GraphProps
) {
const data = [
  {
    name: "January",
    Expected:10 ,
    Selected: 23
  },
  {
    name: "February",
    Expected: 20,
    Selected: 8
  },
  {
    name: "March",
    Expected: 15,
    Selected: 5
  },
  {
    name: "April",
    Expected: 20,
    Selected: 7
  },
  {
    name: "May",
    Expected: 50,
    Selected: 50
  },
  {
    name: "June",
    Expected: 60,
    Selected: 35
  },
  {
    name: "July",
    Expected: 50,
    Selected: 25
  },
  {
    name: "August",
    Expected: 30,
    Selected: 20
  },
  {
    name: "September",
    Expected: 40,
    Selected: 15
  },
  {
    name: "October",
    Expected: 50,
    Selected: 10
  },
  {
    name: "November",
    Expected: 20,
    Selected: 60
  },
  {
    name: "December",
    Expected: 40,
    Selected: 10
  }

]

  return (
    
    <BarChart
      width={950}
      height={370}
      data={data}
      margin={{
        top: 10,
        right: 10,
        left: 40,
        bottom: 10
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Selected" fill="#8884d8" />
      <Bar dataKey="Expected" fill="#82ca9d" />
    </BarChart>
  );
}
export default Graph;