import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { Button, Card, Descriptions, Layout, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import React, { useEffect, useRef, useState } from 'react';

export interface EmployeeDetailViewProps {
    employeeDetails: HrmsEmployeeDetailsDto;
}

export function EmployeeDetailView(
    props: EmployeeDetailViewProps
) {

    useEffect(() => {
        console.log(props.employeeDetails);
    }, [props.employeeDetails]);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            console.log(searchInput.current);
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };

    return (
        <>
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
                <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Employee Detail View</span>}
                    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}
                // extra={<> <Button href="/#/saleorder-tracker" style={{ color: 'black' }}>Employee Grid</Button>
                //     {/* <Button style={{ marginLeft: 20 }} onClick={download}> <ArrowDownOutlined />Download Work Order & Code list</Button> */}
                // </>}
                >
                    <br></br>
                    {props.employeeDetails ? <>
                        <Card title={'Personal Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Employee Type">
                                    {props.employeeDetails.employeeType}
                                </Descriptions.Item>
                                <Descriptions.Item label="Employee Code">
                                    {props.employeeDetails.employeeCode}
                                </Descriptions.Item>
                                <Descriptions.Item label="Employee First Name">
                                    {props.employeeDetails.employeeName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Employee Last Name">
                                    {props.employeeDetails.employeeLastName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Mobile No">
                                    {props.employeeDetails.mobileNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date of Birth">
                                    {props.employeeDetails.dateOfBirth}
                                </Descriptions.Item>
                                <Descriptions.Item label="Gender">
                                    {props.employeeDetails.gender}
                                </Descriptions.Item>
                                <Descriptions.Item label="Designation">
                                    {props.employeeDetails.designation}
                                </Descriptions.Item>
                                <Descriptions.Item label="Marital Status">
                                    {props.employeeDetails.maritualStatus}
                                </Descriptions.Item>
                                <Descriptions.Item label="Email Id">
                                    {props.employeeDetails.emailID}
                                </Descriptions.Item>
                                <Descriptions.Item label="Blood Group">
                                    {props.employeeDetails.bloodGroup}
                                </Descriptions.Item>
                                <Descriptions.Item label="Nationality">
                                    {props.employeeDetails.nationality}
                                </Descriptions.Item>
                                <Descriptions.Item label="Department">
                                    {props.employeeDetails.departmentId}
                                </Descriptions.Item>
                                <Descriptions.Item label="Unit">
                                    {props.employeeDetails.unitId}
                                </Descriptions.Item>
                                <Descriptions.Item label="Shift Group">
                                    {'-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Transportation">
                                    {props.employeeDetails.transportation}
                                </Descriptions.Item>
                                <Descriptions.Item label="Reporting Manager">
                                    {props.employeeDetails.reportingManager}
                                </Descriptions.Item>
                                <Descriptions.Item label="Emergency Contact No">
                                    {props.employeeDetails.emergencyContactNo}
                                </Descriptions.Item>
                                <Descriptions.Item label="Current Address">
                                    {props.employeeDetails.currentAddress}
                                </Descriptions.Item>
                                <Descriptions.Item label="Current State">
                                    {props.employeeDetails.state}
                                </Descriptions.Item>
                                <Descriptions.Item label="Current Pin Code">
                                    {props.employeeDetails.pinCode}
                                </Descriptions.Item>
                                <Descriptions.Item label="Permanent Address">
                                    {props.employeeDetails.permanentAddress}
                                </Descriptions.Item>
                                <Descriptions.Item label="Permanent State">
                                    {props.employeeDetails.permanentState}
                                </Descriptions.Item>
                                <Descriptions.Item label="Permanent Pin Code">
                                    {props.employeeDetails.permanentPinCode}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <br></br>
                        <Card title={'Job Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Employement Type">
                                    {props.employeeDetails.employeeType}
                                </Descriptions.Item>
                                <Descriptions.Item label="Company Email">
                                    {props.employeeDetails.companyEmail}
                                </Descriptions.Item>
                                <Descriptions.Item label="Employee Mode">
                                    {props.employeeDetails.employeeMode}
                                </Descriptions.Item>
                                <Descriptions.Item label="UAN No">
                                    {props.employeeDetails.uanNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="PAN No">
                                    {props.employeeDetails.panNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="ESI No">
                                    {props.employeeDetails.esiNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="Is Audit?">
                                    {props.employeeDetails.isAuditCompleted}
                                </Descriptions.Item>
                                <Descriptions.Item label="Wage Type">
                                    {props.employeeDetails.wageType}
                                </Descriptions.Item>
                                <Descriptions.Item label="Bank Account No">
                                    {props.employeeDetails.bankAccountNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="Bank Name">
                                    {props.employeeDetails.bankName}
                                </Descriptions.Item>
                                <Descriptions.Item label="IFSC Code">
                                    {props.employeeDetails.ifscCode}
                                </Descriptions.Item>
                                <Descriptions.Item label="Skill Set">
                                    {props.employeeDetails.skillSet}
                                </Descriptions.Item>
                                <Descriptions.Item label="Accomodation">
                                    {props.employeeDetails.accommodation}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        {/* <Card title={'Family Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Name">
                                    {props.employeeDetails?.familyDetails[0]?.familyMemName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Relation">
                                    {props.employeeDetails?.familyDetails[0]?.relation}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date of Birth">
                                    {props.employeeDetails?.familyDetails[0]?.famDateOfBirth}
                                </Descriptions.Item>
                                <Descriptions.Item label="Aadhaar No">
                                    {props.employeeDetails?.familyDetails[0]?.famAadhaarNo}
                                </Descriptions.Item>
                                <Descriptions.Item label="Contact No">
                                    {props.employeeDetails?.familyDetails[0]?.contactNo}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Card title={'Education Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Qualification">
                                    {props.employeeDetails?.educationDetails[0]?.empQualification}
                                </Descriptions.Item>
                                <Descriptions.Item label="Specialization">
                                    {props.employeeDetails?.educationDetails[0]?.specialization}
                                </Descriptions.Item>
                                <Descriptions.Item label="Year of PassOut">
                                    {props.employeeDetails?.educationDetails[0]?.dateOfPassOut}
                                </Descriptions.Item>
                                <Descriptions.Item label="Percentage/CGPA">
                                    {props.employeeDetails?.educationDetails[0]?.percentage}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Card title={'ID Proof Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="ID Type">
                                    {props.employeeDetails?.idProofDetails[0]?.idType}
                                </Descriptions.Item>
                                <Descriptions.Item label="ID No">
                                    {props.employeeDetails?.idProofDetails[0]?.idNumber}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Card title={'Experience Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                            <Descriptions
                                title=""
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label="Organisation">
                                    {props.employeeDetails?.experienceDetails[0]?.organisation}
                                </Descriptions.Item>
                                <Descriptions.Item label="Designation">
                                    {props.employeeDetails?.experienceDetails[0]?.designation}
                                </Descriptions.Item>
                                <Descriptions.Item label="From Date">
                                    {props.employeeDetails?.experienceDetails[0]?.fromDate}
                                </Descriptions.Item>
                                <Descriptions.Item label="To Date">
                                    {props.employeeDetails?.experienceDetails[0]?.toDate}
                                </Descriptions.Item>
                                <Descriptions.Item label="Experience(Years.Mon)">
                                    {props.employeeDetails?.experienceDetails[0]?.experience}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card> */}
                        <br></br> </> : ''}
                </Card><br></br>
            </Layout>
        </>
    )
}

export default EmployeeDetailView;