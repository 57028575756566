import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { VendorsDto, CountryDto, CurrencyDto } from '@gtpl/shared-models/masters';
import { VendorsService } from '@gtpl/shared-services/masters'
import { CountryService, CurrencyService } from '@gtpl/shared-services/masters';
const { TextArea } = Input;
const { Option } = Select;

import './vendors-form.css';
import { GlobalStatus } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface VendorsFormProps {

  vendorsData: VendorsDto;
  updateDetails: (vendors: VendorsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function VendorsForm(
  props: VendorsFormProps
) {
  const countryService = new CountryService;
  const currencyService = new CurrencyService;
  const [form] = Form.useForm();
  const service = new VendorsService;
  const [selectedCountry, setSelectedCountry] = useState<number>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(null)
  const [countryData, setCountryData] = useState<CountryDto[]>([]);
  const [currencyData, setCurrencyData] = useState<CurrencyDto[]>([]);
  const [disable,setDisable]=useState<boolean>(false)
  let history = useHistory();

  useEffect(() => { getAllActiveCountries() }, [])

  useEffect(() => { getAllActiveCurrencys() }, [])


  const getAllActiveCountries = () => {
    countryService.getAllActiveCountries().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Countries Retrived successfully');
        setCountryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getAllActiveCurrencys = () => {
    currencyService.getAllActiveCurrencys().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Countries Retrived successfully');
        setCurrencyData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const save = (Data: VendorsDto) => {
    Data.createdUser=localStorage.getItem('username')
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');
        history.push("/vendorsView")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: VendorsDto) => {
    setDisable(false)
      console.log(values);
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      setDisable(false)
      save(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };



  return (
    <Card title={<span style={{ color: 'white' }}>Vendors</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/vendorsView'>{(props.isUpdate === false) && <span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>}</Link>} >


      <Form form={form} initialValues={props.vendorsData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="vendorId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={props.vendorsData}>
          <Input hidden />
        </Form.Item>
        <Row>
             <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="vendorCode"
                      label="Vendor Code"
                      rules={[
                        {
                          required: true,
                          message:'Vendor Code Required'
                        },     
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/\s-]*$/,
                          message: `Invalid Vendor Code`
                          },
                        
                      ]}
                      >
                     <Input  />
                    </Form.Item>
            </Col> 

            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="vendorName"
                      label="Vendor Name"
                      rules={[
                        {
                          required: true,
                          message:"Vendor Name is required"
                        },     
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=~{}:;<>,.?|][a-zA-Z.'"`\s ]*$/,
                          message: `Invalid Vendor Name`
                          },
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="priceNeeded"
                      label="Price Needed"
                      initialValue={GlobalStatus.NO}
                      rules={[
                        {
                          required: true,
                          message:"It should not be blank"
                        },
                        ]}
                  >            
                <Select
                  placeholder="Select Type"
                  defaultValue={GlobalStatus.NO}

                >
                  {/* <Option key={0} value={null}>Select</Option> */}
                  <Option key={1} selected value={GlobalStatus.NO}>{GlobalStatus.NO}</Option>
                  <Option key={2} value={ GlobalStatus. YES}>{GlobalStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>  
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="contactPerson"
                      label="Contact Person"
                      rules={[
                        {
                          required: true,
                          message:"Contact Person is required"
                        },     
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=~{}:;<>,.?|][a-zA-Z.'"`\s ]*$/,
                          message: `Invalid Contact Person`
                        }
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="contactNumber"
                      label="Contact Number"
                      rules={[
                        {
                          required: true,
                          message: `Enter valid Contact Number`,
                          max:10
                        },     
                        {
                          pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                          message: `Should contain only numbers`
                        }
                       
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="emailId"
                      label="Email Id"
                      // rules={[
                      //   {
                      //     required: true,
                      //   },
                      // ]}
                      >
                     <Input/>
                    </Form.Item>
            </Col>

            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="countryId"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message:"Country is required"
                        },     
                        {
                          pattern:/^[^-\s][a-zA-Z0-9_\s-]*$/,
                          message: `Select Country`
                        }
                      ]}>
                      <Select
                showSearch

                placeholder="Select Country"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Country</Option> */}
                {countryData.map((country) => {
                  return <Option key={country.countryId} value={country.countryId}>{country.countryName}</Option>
                })}
              </Select>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="currencyId"
                      label="Currency"
                      rules={[
                        {
                          required: true,
                          message:"Currency is required"
                        },     
                        {
                          pattern:/^(?!\s*$).+/,
                          message: `Select Currency`
                        }
                      ]}>
        <Select
                showSearch

                placeholder="Select Currency"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Currency</Option> */}
                {currencyData.map((currency) => {
                  return <Option key={currency.currencyId} value={currency.currencyId}>{currency.currencyName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="gstNumber"
              label="GST Number"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="website"
              label="Website"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="street"
              label="Street"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="apartment"
              label="Apartment"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="city"
              label="City"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="postalCode"
              label="Postal Code"
            rules={[
              // {
              //   required: true,
              // },
              {
                pattern: /^[0-9.]*$/,
                message: `Letters , Spaces and Special charecters are not allowed`,
              },
              {
                max:6,
                message:'It accept only 6 digits'
              }
            ]}
            
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="bankAccNo"
              label="Bank AccNo"
            rules={[
              // {
              //   required: true,
              // },
              {
                pattern: /^[0-9.]*$/,
                message: `Letters , Spaces and Special charecters are not allowed`,
              },
            ]}>
      
            <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="bankIfsc"
              label="Bank Ifsc"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="bankName"
              label="Bank Name"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: `Numbers and special characters not allowed`,
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="bankBranch"
              label="Bank Branch"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: `Numbers and special characters not allowed`,
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="privateNote"
              label="Private Note"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="publicNote"
              label="Public Note"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>






        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary"disabled={disable} htmlType="submit" >
              Submit
                </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
          </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default VendorsForm;
