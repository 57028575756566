import React, { useEffect, useRef, useState} from 'react';
import {RmRackPositionService} from '@gtpl/shared-services/masters'
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link, Redirect } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import './rm-rack-position-grid.css';
import {  Divider, Table, Popconfirm, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Card } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {RackForm} from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-form';
import { RmRackDto, RmRackModel, RmRackPositionModel, RmRackPostionReq } from '@gtpl/shared-models/masters';
import RmRackPositionForm from '../../../rm-rack-position-form/src/lib/rm-rack-position-form';
/* eslint-disable-next-line */
export interface RmRackPositionGridProps {}

export function RmRackPositionGrid(
  props: RmRackPositionGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [racksData, setRacksData] = useState<RmRackModel[]>([]);
  const [selectedRackData, setSelectedRackData] = useState<any>(undefined);
  const username = localStorage.getItem('username')
  const rackService = new RmRackPositionService();

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  useEffect(() => {
    getAllRmRackPositions();
  }, []);
  
  const getAllRmRackPositions= () => {
    rackService.getAllRmRacksPositions().then(res => {
      if (res.status) {
        setRacksData(res.data);
      } else {
        if (res.intlCode) {
            setRacksData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRacksData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
 
  const deleteRack = (rackData:RmRackModel) => {
    const isActive=rackData.isActive?false:true;
    const updatedUser = localStorage.getItem("createdUser");
    rackService.ActivateorDeactivateRmRack(new RmRackPostionReq(rackData.rmRackPositionId,0,0,updatedUser,rackData.versionFlag,isActive)).then(res => {
      if (res.status) {
        getAllRmRackPositions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    /**
     * 
     * @param variantData 
     */
    const updateRack = (racksData: RmRackPositionModel) => {
      rackService.updateRmRackPosition(racksData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllRmRackPositions();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
  
      //drawer related
      const closeDrawer=()=>{
        setDrawerVisible(false);
      }
    
      //TO open the form for updation
      const openFormWithData=(viewData: RmRackDto)=>{
        setDrawerVisible(true);
        setSelectedRackData(viewData);
        console.log(selectedRackData)
        console.log('selectedRackData')
      }
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'Rack Position Code',
        dataIndex: 'rmRackPositionCode',
        // responsive: ['lg'],
        sorter: (a, b) => a.rmRackPositionCode.localeCompare(b.rmRackPositionCode),
        ...getColumnSearchProps('rmRackPositionCode')
      },
      {
        title: "RF Id's",
        dataIndex: 'assocRfId',
        // responsive: ['lg'],
        sorter: (a, b) => a.assocRfId.localeCompare(b.assocRfId),
        ...getColumnSearchProps('assocRfId')

      },
      {
        title: "Rack Position Type",
        dataIndex: 'rmRackPositionType',
        // responsive: ['lg'],
        sorter: (a, b) => a.rmRackPositionType.localeCompare(b.rmRackPositionType),
        ...getColumnSearchProps('rmRackPositionType')
      },
      {
        title: "Rack Position",
        dataIndex: 'rmRackPosition',
        // responsive: ['lg'],
        sorter: (a, b) => a.rmRackPosition.localeCompare(b.rmRackPosition),
        ...getColumnSearchProps('rmRackPosition')
      },
      {
        title: 'Rack Name',
        dataIndex: 'rmRackMasterCode',
        // responsive: ['lg'],
        sorter: (a, b) => a.rmRackMasterCode.localeCompare(b.rmRackMasterCode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('rmRackMasterCode')
      },
      
      // {
      //   title: 'Remarks',
      //   dataIndex: 'remarks',
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.remarks.length - b.remarks.length,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('productName')
      // },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
            
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.isActive === value;
        },
        
      },
      // {
      //   title:`Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) => (
      //     <span> 
      //       { username == '"ramakrishna"' ?
      //         <>
      //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
      //           onClick={() => {
      //             if (rowData.isActive) {
      //               openFormWithData(rowData);
      //             } else {
      //               AlertMessages.getErrorMessage('You Cannot Edit Deactivated Rack');
      //             }
      //           }}
      //           style={{ color: '#1890ff', fontSize: '14px' }}
      //         />
            
      //       <Divider type="vertical" />
                     
      //         <Popconfirm onConfirm={e =>{deleteRack(rowData);}}
      //         title={
      //           rowData.isActive
      //             ? 'Are you sure to Deactivate Rack ?'
      //             :  'Are you sure to Activate Rack ?'
      //         }
      //       >
      //         <Switch  size="default"
      //             className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
      //             checkedChildren={<RightSquareOutlined type="check" />}
      //             unCheckedChildren={<RightSquareOutlined type="close" />}
      //             checked={rowData.isActive}
      //           />
              
      //       </Popconfirm>
      //       </>:''
      //       } 
      //     </span>
      //   )
      // }
    ];
    /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title={<span style={{color:'white'}}>Rm Rack Position</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/rm-rack-position-form' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={24} >
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

          <Card title={'Total Zones: ' + racksData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + racksData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + racksData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.rackId}
          columns={columnsSkelton}
          dataSource={racksData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{x:true}}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <RmRackPositionForm key={Date.now()}
                updateRack={updateRack}
                isUpdate={true}
                rackData={selectedRackData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default RmRackPositionGrid;
