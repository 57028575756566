
import { ColdStorageCurrentTemp, CurrentTemp } from '@gtpl/pages/dashboard/dashboard-components/temperature-log';
import { Card, Col, Row } from 'antd';
import DayWiseTemp from 'libs/pages/dashboard/dashboard-components/temperature-log/src/lib/day-wise-temp';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import CurrentDayStockTransfer from './current-dat-transfers';
import CurrentDayStockOut from './current-day-stock-out';
import ExpectedStockInAndOut from './expected-in-out';
import LongRunningDummyStock from './long-running-dummy';
import { Practice } from './practice';
import ProductionPercentage from './production-percentage';
import RepackingEfficiency from './repacking-efficiency';
import RecentStockIn from './stock-in-table';
import RecentStockOut from './stock-out-table';
import { CurrentDayStockOutPos } from './stockout-pos';
import TopTenDummyProducts from './top-ten-dummy';
import WhTrendsGraph from './trends-graph';
import { DeptDateHourRequest, TemperaturesModel } from "@gtpl/shared-models/warehouse-management";
import { TemperatureLogService } from "@gtpl/shared-services/warehouse-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import './warehouse-dashboard.css';
import WayRackStock from './wayrack-cartons';
import WhAvailability from './wh-availability';
import WhLifeCycle from './wh-life-cycle';
import ColdStorageDayWiseTemp from 'libs/pages/dashboard/dashboard-components/temperature-log/src/lib/cold-storage-day-wise';
// import {GlobalTimeOut} from '@shared-models/common-models'

/* eslint-disable-next-line */
export interface WhDashboardProps { }


export function WhDashboard(
  props: WhDashboardProps
) {
  const currentTempService = new TemperatureLogService()
  const [tempData, setTempData] = useState<TemperaturesModel[]>([]);

  useEffect(() => {
    getCurrentTemperature(moment().format("YYYY-MM-DD"), Number(moment().format('hh')));
  }, []);
  const getCurrentTemperature = (date?: string, hour?: number) => {
    const req = new DeptDateHourRequest();
    req.date = date;
    req.hour = hour;
    currentTempService.getCurrentTemperature(req).then((res) => {
      if (res.status) {
        setTempData(res.data)
        //AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        //AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };
  return (
    <div >
      <Card style={{ background: '' }}>
        <Row gutter={24}>
          <Col span={12}>
            <Card>
              <WhTrendsGraph />
            </Card>
          </Col>
          <Col span={12}>
            <div className='wh-dasboard-a'>
              <h1 style={{ width: '200px',color: 'black',marginTop: '44px',marginLeft: '65px',}}>Current temp :<h2 style={{ fontSize: '44px', marginTop: '-10px', marginLeft: '126px' }}>{" " + tempData[0]?.temperature ? tempData[0]?.temperature : '0'}°C </h2> </h1>
            </div>
            <CurrentTemp />
            <Card style={{ marginTop: '-33px' }}>
              <DayWiseTemp />
            </Card>
          </Col>
        </Row> <br />
        <Row gutter={24} >
          <Col xs={24} sm={24} md={12} xl={12}>
            <WhLifeCycle />
          </Col>
          <Col xs={24} sm={24} md={12} xl={12}>
            <TopTenDummyProducts />
          </Col>
        </Row><br />
        <Row gutter={24}>
          <Col span={8}>
            <ExpectedStockInAndOut />
          </Col>
          <Col span={5} style={{ paddingLeft: '2px', paddingRight: '12px' }}>
            <Row>
              <ProductionPercentage />
            </Row>
            {/* <Row>
              <CurrentDayStockOutPos />
            </Row> */}
            <Row>
              <CurrentDayStockOut />
            </Row>
          </Col>
          <Col>
            <Row>
              <CurrentDayStockTransfer />
            </Row>
            <Row>
              <WayRackStock />
            </Row>
          </Col>
          <Col style={{width:'380px'}}>
          <WhAvailability/>
          </Col>
        </Row><br />
        {/* +'°C' */}
        <Row gutter={24}>
          <Col span={12}>
            <RecentStockIn />
          </Col>
          <Col span={12} >
            <RecentStockOut />
          </Col>
        </Row> <br />
        <Row gutter={24} style={{ height: '33%' }}>
        </Row><br />
        <Row gutter={24}>
          <Col span={12}>
            <LongRunningDummyStock />
          </Col>
          <Col span={12}>
            <RepackingEfficiency />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default WhDashboard;
