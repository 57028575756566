import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import { Containertrnd, TopFiveDestinationDto } from '@gtpl/shared-models/logistics';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Col, Empty, Form, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import LogisticsBarChart from './logistcs-barchart';
import LogisticsCardComponet from './logistics-card-component';
import './logistics-dashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';



import './logistics-dashboard.css';
import LogisticLifeCycle from './logistics-lifecycle';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import Item from 'antd/lib/list/Item';

/* eslint-disable-next-line */
export interface LogisticsDashboardProps { }

export function LogisticsDashboard(props: LogisticsDashboardProps) {
  const service = new ContainerRegisterService();
  const [topFiveDestination, settopFiveDestination] = useState<TopFiveDestinationDto[]>([]);
  const [totalshippedsaleorders, settotalshippedsaleorders] = useState<any[]>([]);
  const [shipmentsInYearData, setshipmentsInYearData] = useState<any[]>([]);
  const [daywisesaleorderData, setdaywisesaleorderData] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<any>(moment().year());
  const Option = Select;
  //Top Five Destinations
  const cardBodyForTopFiveDestinations: CardBodyDetails[] = [];
  // let maxiInvDate = '';
  // let maxiActEndDate = '';
  for (const productInfo of topFiveDestination) {
    cardBodyForTopFiveDestinations.push(new CardBodyDetails(productInfo.destination, Number(productInfo.count).toString()));
  }
  const topFivedestinationBodyCount = new CardHeaderBody('', status);
  const topFiveDestinationBodyQty = new CardHeaderBody('', status);
  const topFivedestinationBodyCardHeader = new CardHeader('Top Five Destinations for current financial year', [topFivedestinationBodyCount, topFiveDestinationBodyQty]);

  const TopFiveDestinationData = new CardDetail(topFivedestinationBodyCardHeader, cardBodyForTopFiveDestinations, ['Destination', 'Total Shipments',]);

  const gettotalsaleorderShipped = () => {
    const req= new Containertrnd(Number(selectedYear))
    service.gettotalsaleorderShipped(req).then(res => {
      if (res.status) {
        settotalshippedsaleorders(res.data);
      } else {
        if (res.intlCode) {
          settotalshippedsaleorders([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      settotalshippedsaleorders([]);
    })

  }
  const totalsaleordersshiipedinyear = () => {
    const req= new Containertrnd(Number(selectedYear))
    
    service.totalsaleordersshiipedinyear(req).then(res => {
      if (res.status) {
        setshipmentsInYearData(res.data);
      } else {
        if (res.intlCode) {
          setshipmentsInYearData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setshipmentsInYearData([]);
    })

  }

  useEffect(() => {
    getTopFiveDestinations(),
      gettotalsaleorderShipped(),
      totalsaleordersshiipedinyear(),
      gettotalsaleordersdaywise();
    years(0);

  }, [selectedYear]);
  const allmonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  let result = [];

  allmonths.forEach(month => {
    const item = totalshippedsaleorders.find(item => item.MONTH == month);

    if (item) {
      result.push(item);
    } else {
      result.push({ MONTH: month, total: 0 });
    }
  })
 
  const daywisedata = daywisesaleorderData.map(i => { return i.MONTH });
  const id = daywisesaleorderData.map(i => { return i.id })
  console.log(totalshippedsaleorders, 'totalshippedsaleorders')

  const gettotalsaleordersdaywise = () => {
    const req= new Containertrnd(Number(selectedYear))
    service.totalsaleordersShippedinDayWise(req).then(res => {
      if (res.status) {
        setdaywisesaleorderData(res.data);
      }
      else {
        if (res.intlCode) {
          setdaywisesaleorderData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setdaywisesaleorderData([]);
    })
  }

  
    const parsedData = daywisesaleorderData?.map(item => {return {...item, data:JSON.parse(item.data)}});
    const sortedData = parsedData.map(item => {return  {...item,data:item.data.sort(function(a : any, b: any) {
      if (a[0] === b[0]) {
        return 0;
      }else{
        return (a[0] < b[0]) ? -1 : 1;
      }
    })}})
    console.log(sortedData)
   
  const config = {
    chart: {
      type: 'column',
      events: {
        drilldown: function(e) {
          var chart = this,
            drilldowns = chart.userOptions.drilldown.series,
            series = [];
          e.preventDefault();
          Highcharts.each(drilldowns, function(p, i) {
            if (p.id.includes(e.point.name)) {
              chart.addSingleSeriesAsDrilldown(e.point, p);
            }
          });
          chart.applyDrilldown();
        }
      }
    },
    title: {
      align: 'left',
      text: 'No.of saleorders shipped '
    },
    subtitle: {
      align: 'left',
      text: 'Click the columns to view Day wise data'
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'Total Saleorders'
      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:f}'
        }
      }
    },
    

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:f}</b> of total<br/>'
    },
    series: [
      {
        name: "Month",
        colorByPoint: true,
        data: totalshippedsaleorders.map(i => { i.y = Number(i.y); return i; })
      }
    ],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right'
        }
      },
      series:sortedData,
      
    }
  }

  
  // const stringifiedData = JSON.stringify(daywisesaleorderData)
  // console.log('day wise data:',JSON.parse(stringifiedData))

console.log(daywisesaleorderData.map(item => {return {...item, data:JSON.parse(item.data)}}),'daywisesaleorderData')
  const getTopFiveDestinations = () => {
    service.getTopFiveDestinations().then(res => {
      if (res.status) {
        settopFiveDestination(res.data);
      } else {
        if (res.intlCode) {
          settopFiveDestination([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      settopFiveDestination([]);
    })
  }
  const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  }
  const lastTenYears = years(10)


  return (

    <Form layout="vertical" name="control-hooks">

      <Row justify="space-around">
        <Col xs={24} sm={24} md={24} xl={12}>
          <Card><LogisticLifeCycle /></Card>
        </Col>
        <Col xs={24} sm={24} md={24} xl={12}>
          <Card><LogisticsBarChart /></Card>
        </Col>

        <Col className="cardComp" xs={24} sm={24} md={24} xl={12} style={{ height: '100%' }}>
          <LogisticsCardComponet cardData={TopFiveDestinationData} />
        </Col>

        <Col xs={24} sm={24} md={24} xl={12}>
        <Row gutter={24} justify='end' style={{marginRight:'13px'}}>
        <Col xs={24} sm={24} md={24} xl={20}>
        <Card bordered={false} style={{ padding: '6px', width: '680px', background: '#04AEC4', height: '50px', textAlign: 'left', marginTop: '19px', marginLeft: '11px' }}>{<FontAwesomeIcon icon={faTruckFast} fontSize={'20px'} />}<span style={{ fontSize: '15px' }}>Total Shipments in year:{(shipmentsInYearData[0]?.total)}</span></Card>
        </Col>
        <Col xs={24} sm={24} md={24} xl={4} style={{width:'30px',marginTop:'28px',paddingRight:'6px'}}>
                    <Form.Item name="quarterly" label=""
            rules={[
              {
                required: false,
              },
            ]}>
            <Select style={{paddingTop:'14px' ,marginTop:'-14px'}}
              placeholder="Select Year"
              allowClear
              onSelect={(value) => { setSelectedYear(value) }}
              defaultValue={moment().format('YYYY')}
            >
              {lastTenYears.map((value) => {
                return <Option key={value} value={value}>{value}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
     {totalshippedsaleorders.length?
      <div><HighchartsReact highcharts={Highcharts} options={config} /></div>:<Empty/>
     }
  
        </Col>
      </Row>
      
     
     </Form>
   
  );
}

export default LogisticsDashboard;


