import React from 'react';
import {Descriptions, Badge, Layout, Row, Col, Card, Button} from 'antd';
import './documents.css';


/*eslint-disable-next-line*/
export interface TaxInvoiceProps{}

export function TaxInvoice(
    props: TaxInvoiceProps
){
  return (
    //   <Card title={<span style={{color:'white'}}>TAX INVOICE</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra={<Button>Print</Button>}>
          <html>
              <head>              
              </head>
              <body>
                  <table className={'ta-b'} style = {{width:'100%'}}>
                      <tr>
                          <td className={'ta-b'} colSpan={2}>
                                <h2 style={{marginLeft:'37rem'}}>TAX INVOICE<Button onClick={() => window.print()} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}>Print</span></Button></h2>
                                CMA CGM SA, C/O. CCAI * <br/>
                                INDIA BULLS FINANCIAL CENTER <br/>
                                TOWER 3 - 8TH FL SENAPATI <br/>
                                BAPAT MARG, ELPHINSTONE WEST  <br/> 
                                MUMBAI/400013 <br/>
                                INDIA <br/>
                                TEL: +91 22 3988 8999 FAX: +91 22 3345 1990                       
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'} >
                              Payment_info <br/>
                              <hr style={{borderTop: 'dotted 1px'}} />
                              Bill of Lading: 1GC0118325<br/>
                              Customer: 0004124744/001<br/>
                              Cust. Ref: - 
                          </td>
                          <td className={'ta-b'} >
                              <h2>EXPORT INVOICE &nbsp;&nbsp; ORIGINAL*</h2>
                              <h5>INEAP039576</h5>
                              <br/>
                              Date: 14-APR-2021                              
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'} >
                              Payable to:         CMA CGM AGENCIES (INDIA) PVT LTD. <br/>
                                                  6TH FLOOR UNIT NO 706 <br/>
                                                  GRAND PALACE 1ST LANE <br />
                                                  DIWARAKA NAGAR <br/>
                                                  VISAKHAPATNAM/520016 <br/>
                                                  INDIA <br/>
                                                  TEL: 0005015758     FAX: +91 44- 30654560 <br/>

                              POST Place Of       CMA CGM SA, C/O. CCAI <br/>
                              Servicee Provider:  6TH FLOOR UNIT NO 706 <br/>
                                                  GRAND PALACE 1ST LANE <br/>
                                                  DWARAKA NAGAR <br/>
                                                  VISAKHAPATNAM <br/>
                                                  530016 <br/>
                                                  <br/>
                                                  GSTN:     37AABCC9048G1ZK <br/>
                                                  <br/>
                              Contact_info<br/>
                              <h4>Invoice By:</h4>ANOOP ACHARY
                                                         
                          </td>
                          <td className={'ta-b'}>
                              Invoice To:         SANDHYA AQUA EXPORTS PVT LIMITED<br/>
                                                  MIG 25A LAWSONS BAY COLONY <br/>
                                                  VISAKHAPATNAM/530003 <br/>
                                                  INDIA <br/>
                                                  <br/>
                                                  <br/>
                                                  GSTIN:     37AAJCS2286C1ZA <br/>
                                                                 IRN: <br/>
                                                                 <h4>fe2fba56dde62f303327b6ce6a705f533d3</h4>
                                                                 <h4>be497b17c99d77c1f6d8fb3582ff2</h4>
                                                                 <h4>Ack. No: 112110831398530</h4>
                                                                 <h4>Act. Date: 14-APR-2021 11:19:00</h4>



                                                   PAN:
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'} colSpan={2}>
                              Voyage: 25714E          Local Voyage Ref: -       Vessel: CAPE MAYOR   &ensp;  Call Date: 06 APR 2021 <br/>
                              <hr/>              
                              Place of Receipt:    -                                               Dischargee Port:   HALIFAX, NS <br/>
                              Load Port:           VISAKHAPATNAM                                   Place of Delivery: TORONTO, ON <br/>
                              <hr/>
                              Commodity Code          Description                       Package                         Qty <br/>
                              <hr/>
                              160529                  Shrimps and prawns: Other         40RH                             1 <br/>
                              <br/>
                              Container Number(s):  APRU6106090<br/>
                              <hr/>
                              <table className={'ta-b'} style={{width:'100%'}}>
                                  <tr>
                                      <td className={'ta-b'}>
                                          Size/Type &ensp; Charge Description &ensp;&ensp; Tax &nbsp; Based on &ensp; Rate Currency &ensp; Amount 
                                      </td>
                                      <td  className={'ta-b'}>
                                          Amount in USD
                                      </td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          40RH    C   &ensp; BASIC FREIGHT                   &ensp;&ensp;&ensp;&ensp;&ensp;IN      &nbsp; 1 UNI    &ensp;       3,750.00 USD    &ensp;        3,750.00 <br/>
                                          40RH    C   &ensp; Bunker surcharge NOS            &ensp;&ensp;&ensp;&ensp;IN      &nbsp; 1 UNI    &ensp;        967.00 USD     &ensp;         967.00 <br/>
                                          40RH    C   &ensp; Peak Season Adjustment Factor   &ensp;&ensp;IN      &nbsp; 1 UNI    &ensp;         300.00 USD    &ensp;          300.00 
                                      </td>
                                      <td  className={'ta-b'}>
                                          3,750.00 <br/>
                                            967.00 <br/>
                                            300.00 
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style={{borderTop: '2px solid'}}  className={'ta-b'}>   
                                          Rate of Exchange  &ensp;&ensp;&ensp;  Current Charge Totals                                       
                                      </td>
                                      <td rowSpan={2}  className={'ta-b'}></td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          1 USD = 74.688177 INR    &ensp;&ensp;&ensp;   USD  &ensp;  5,017.00 
                                      </td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          GST Tax applied as indicated on charges  &ensp;&ensp;&ensp; Total Excluding Tax 
                                      </td>
                                      <td  className={'ta-b'}>
                                          5,017.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          Service Description &ensp;&nbsp; SAC &ensp; POS State &ensp; Tax &ensp;&nbsp; Taxable Amt. &ensp;  Tax Amount   
                                      </td>
                                      <td  className={'ta-b'} rowSpan={3}>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          <u>Freight charges</u><br/>
                                          AP IGST @ 0% BASIC FREIGHT &ensp;&nbsp; 996521 &ensp;OTHER TERRITORY &ensp; IN &ensp;&nbsp; 3,750.00 &ensp; 0.00   <br/>
                                          AP IGST @ 0% BAF NOS &ensp;&nbsp;&nbsp;&nbsp;&nbsp; 996521  &ensp;  OTHER TERRITORY &ensp; IN &ensp;&nbsp; 967.00 &ensp; 0.00   <br/>
                                          AP IGST @ 0% PEAK SEASON CH  &ensp;  996521 &ensp; OTHER TERRITORY &ensp; IN &ensp;&nbsp; 300.00 &ensp; 0.00   
                                      </td>
                                  </tr>
                                  <tr>
                                      <td className={'ta-b'}>
                                          Total IGST &ensp;&ensp; OTHER TERRITORY &ensp; 0.00USD&ensp;0.00INR
                                      </td>
                                  </tr>
                                  <tr>
                                      <td  className={'ta-b'}>
                                          &ensp;&ensp;&ensp; Total GST TAX
                                      </td>
                                      <td  className={'ta-b'}>
                                             0.00
                                      </td>
                                  </tr> 
                                  <tr>
                                      <td><br/></td>
                                      <td><br/></td>
                                  </tr>                                                                                                                   
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'}>
                            (C)For and on behalf of CMA - CGA <br/>
                              4 QUAI D'ARENC <br/>
                              13002 MARSEILLE-FRANCE <br/>
                              FR72562024422 <br/>
                              &ensp;&ensp;Carrier No: INCMA6661617
                          </td>    
                          <td className={'ta-b'} rowSpan={2}>
                            <h3>Total Amount: &ensp;&ensp; 5.017.00 USD</h3> 
                            Payable by 14-APR-2021
                            <h5>Payment before delivery of Bill Of Landing (Export) or containers (Import).<br/>
                            Our invoices are payable in full to CMA CGM by the indicated due date, without any<br/>
                            deduction, including for payments made in advance. All overdue payments shall bear<br/>
                            interest @ 18% per annum. Subject to Mumbai Jurisdictino. E &amp; O.E.</h5>   
                          </td>                   
                      </tr>
                      <tr>
                          <td className={'ta-b'}>
                            SWIFT Code: HSBCINBB <br/>
                            The Hongkong and Shanghai Banking Corporation Limited <br/>
                            THE HSBC LTD <br/>
                            FOR BRANCH 52 60 MG ROAD <br/>
                            MUMBAI <br/>
                            400 001 INDIA MH <br/>
                            Account Number. 006-099311-861
                          </td>

                      </tr>
                  </table>
                  <p>Agent: CMA CGM Agencies (India) Private Limited (CCAI), Regd. Office Address:219, 2nd Floor, Metro House, Mangaldas Road, Pune - 411001<br/>
                  CIN No – U63012PN2008PTC133890 Tel: +91 20 3045 8390/ Email: mby.genmbox@cma-cgm.com / Website: www.cma-cgm.com <br/>
                  CMA CGM SA, PAN No: AABCC9048G. Invoice issued by CCAI for and on behalf of CMA CGM SA.<br/>
                  *ORIGINAL FOR RECIPIENT</p>

                  <table className={'ta-b'} style = {{width:'100%'}}>
                      <tr>
                          <td className={'ta-b'} colSpan={2}>
                                <h2 style={{marginLeft:'37rem'}}>TAX INVOICE</h2>
                                CMA CGM SA, C/O. CCAI * <br/>
                                INDIA BULLS FINANCIAL CENTER <br/>
                                TOWER 3 - 8TH FL SENAPATI <br/>
                                BAPAT MARG, ELPHINSTONE WEST  <br/> 
                                MUMBAI/400013 <br/>
                                INDIA <br/>
                                TEL: +91 22 3988 8999 FAX: +91 22 3345 1990                       
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'} >
                              Payment_info <br/>
                              <hr style={{borderTop: 'dotted 1px'}} />
                              Bill of Lading: 1GC0118325<br/>
                              Customer: 0004124744/001<br/>
                              Cust. Ref: - 
                          </td>
                          <td className={'ta-b'} >
                              <h2>EXPORT INVOICE          ORIGINAL*</h2>
                              <h5>INEAP039576</h5>
                              <br/>
                              Date: 14-APR-2021                              
                          </td>
                      </tr>
                      <tr>
                      <td className={'ta-b'} colSpan={2}>
                           <table className={'ta-b'} style={{width:'100%'}}>
                               <tr>
                                   <td className={'ta-b'}>Total Including Tax </td>
                                   <td className={'ta-b'}>5,017.00</td>
                               </tr>
                            </table>  
                                                         
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'} colSpan={2}>
                              <table style={{height:'100px', width:'100px'}}>
                                <tr aria-rowspan={6}>
                                    <td>
                                    Electronic Invoice - No Signature/Stamp required. GST Tax is levied on taxable service provided by company. For online payment confirmation,<br/>
                                    please write a message to mby.rtgsconfirmation@cma-cgm.com.<br/>
                                    No Tax is payable on Reverse Charge<br/><br/>
                                    Invoice payment through Online mode available, You can make payment using NEFT/RTGS, Debit/Credit Card and Net banking<br/><br/>
                                    In case of any GST related query, revert within 45 days from the date of Invoice for rectification purpose.<br/><br/>
                                    Making payment through UPI ID or QR Code or Rupay Debit Card, refer our client advisory available on our website<br/>
                                    http://www.cma-cgm.com/static/IN/Attachments/Client%20Advisory%20-<br/>                                    
                                    %20Mandatory%20Implementation%20of%20Electronic%20Modes%20CCAI-347-310120.pdf
                                    </td>
                                </tr>
                                <tr>
                                    <td><br/><br/><br/><br/><br/>
                                    </td>
                                </tr>
                              </table>
                              
                          </td>
                      </tr>
                      <tr>
                          <td className={'ta-b'}>
                            (C)For and on behalf of CMA - CGA <br/>
                              4 QUAI D'ARENC <br/>
                              13002 MARSEILLE-FRANCE <br/>
                              FR72562024422 <br/>
                              &ensp;&ensp;Carrier No: INCMA6661617
                          </td>    
                          <td className={'ta-b'} rowSpan={2}>
                            <h3>Total Amount: &ensp;&ensp; 5.017.00 USD</h3> 
                            Payable by 14-APR-2021
                            <h5>Payment before delivery of Bill Of Landing (Export) or containers (Import).<br/>
                            Our invoices are payable in full to CMA CGM by the indicated due date, without any<br/>
                            deduction, including for payments made in advance. All overdue payments shall bear<br/>
                            interest @ 18% per annum. Subject to Mumbai Jurisdictino. E &amp; O.E.</h5>   
                          </td>                   
                      </tr>
                      <tr>
                          <td className={'ta-b'}>
                            SWIFT Code: HSBCINBB <br/>
                            The Hongkong and Shanghai Banking Corporation Limited <br/>
                            THE HSBC LTD <br/>
                            FOR BRANCH 52 60 MG ROAD <br/>
                            MUMBAI <br/>
                            400 001 INDIA MH <br/>
                            Account Number. 006-099311-861
                          </td>

                      </tr>
                  </table>
                  <p>Agent: CMA CGM Agencies (India) Private Limited (CCAI), Regd. Office Address:219, 2nd Floor, Metro House, Mangaldas Road, Pune - 411001<br/>
                  CIN No – U63012PN2008PTC133890 Tel: +91 20 3045 8390/ Email: mby.genmbox@cma-cgm.com / Website: www.cma-cgm.com <br/>
                  CMA CGM SA, PAN No: AABCC9048G. Invoice issued by CCAI for and on behalf of CMA CGM SA.<br/>
                  *ORIGINAL FOR RECIPIENT</p>
              </body>
          </html>

    //   </Card>
  );
}
export default TaxInvoice;