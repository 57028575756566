import { Button, Card, Col, Row, Select, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { UndoOutlined, SearchOutlined } from '@ant-design/icons';
import { EmployeeService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './user-dashboard.css';
import { EmployeeDto } from 'libs/shared-models/gtpl/src/lib/employees/employee-dto';
const { Option } = Select;

/* eslint-disable-next-line */
export interface UserDashboardProps {
}

export function UserDashboard(
  props: UserDashboardProps
) {
  const empService = new EmployeeService();
  const [filtersData, setFiltersData] = useState<EmployeeDto[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([])
  const [form] = Form.useForm();

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }

  const refreshPage=() => {
    window.location.reload();
  }

  const searchData = () => {
    const employeeName = form.getFieldValue('employeeName');
    const employeeCode = form.getFieldValue('employeeCode');

    let selectedData = filtersData;
    if (employeeName) {
      selectedData = filtersData.filter(item => item.employeeName === employeeName)
    }
    if (employeeCode) {
      selectedData = filtersData.filter(item => item.employeeCode === employeeCode)
    }
    
    setEmployeeData(selectedData);
  }

  const onReset = () => {
    form.resetFields();
    setSearchText('');
    setEmployeeData(filtersData);
  }
  // function handleReset(clearFilters) {
  //   clearFilters();
  //   setSearchText('');
  // };

  const handleEmployeeName = () => {
    const employeeId = form.getFieldValue('employeeName')
    const request = new HrmsEmployeeRequest(employeeId);
    empService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeCode: res.data.employeeCode,
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    getAllActiveEmployees();
  }, []);

  const getAllActiveEmployees = async () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
        setFiltersData(res.data)
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          setFiltersData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      setFiltersData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const img = document.createElement('img');
  img.src = 'data:image/jpeg;base64,' + btoa('your-binary-data');
  document.body.appendChild(img)

  // function hexToBase64(str) {
  //   return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
  // }

  return (

    <div className='dashboard' >

      <Form form={form} layout="vertical">

        <Card title={<span style={{ color: 'black' }}  > Users</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}  >

          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="employeeName"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "name is required"
                  }
                ]}
              >
                <Select
                  showSearch
                  //style={{ width: 210 }}
                  placeholder="Select Employee Name"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  onChange={handleEmployeeName}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employeeData.map((employeeData) => {
                    return <Option key={employeeData.employeeId} value={employeeData.employeeId}>{employeeData.employeeName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="employeeCode"
                label="Employee Code"
                rules={[
                  {
                    required: true,
                    message: "code is required"
                  },
                ]}
              >
                <Input disabled />

              </Form.Item>
            </Col>

            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: false, message: 'Select Designation',
                },
              ]}
            >
              <Select
                placeholder="Select Designation"
                onChange={handleDesignation}
                allowClear
                style={{ width: '100%' }}
                showSearch
              >
                {designationData.filter(emp => emp.designation != '').map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col> */}

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ marginTop: 30 }}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                // size="small"
                block onClick={() => searchData()}
                style={{ marginRight: 2, width: 100 }}
              >
                Search
              </Button>
              {/* <Button type='primary' block onClick={()=>searchData()}>Get</Button> */}
              <Button
                icon={<UndoOutlined />}
                onClick={() => refreshPage()}
                
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row gutter={24}>
            {employeeData.map((i) => {
              let img: any = i.image;
              console.log(img);
              const Base64string = i.image ? i.image['data'] ? btoa(
                String.fromCharCode(... new Uint8Array(i.image['data']))
              ) : '' : '';
              return <Col span={6}>
                <Card
                  hoverable
                  style={{ width: 240, height: 320, textAlign: 'left', padding: 12, margin: 12, borderRadius: 12 }}
                  // cover={<img src={i.image ? i.image['data:image/jpeg;base64,' + hexToBase64('your-binary-data')] : null} />}
                  // cover={<img src={  i.image ? i.image['data'] : ''} />}

                  cover={
                    i.image ? <img id='img' src={`data: image/png; base64, ${Base64string}`} /> : ''}
                // cover={i.image ? <img src={"data:image/jpeg;base64,"+new FileReader().readAsDataURL(img)} /> : ''}
                >
                  <p><b>Name</b>: {i.employeeName}</p>
                  <p><b>Id</b>: {i.employeeCode}</p>
                  <p><b>Designation</b>: {i.designation}</p>
                  <p><b>Department</b>: {i.departmentId}</p>
                  <p><b>Mobile No</b>: {i.mobileNumber}</p>
                  {/* <p><b>Mobile No</b>: <a href="{i.mobileNumber}">{i.mobileNumber}</a></p> */}
                </Card>
              </Col>
            })
            }
          </Row>

        </Card>
      </Form>
    </div >
  )
};


export default UserDashboard;

function setSearchText(arg0: string) {
  throw new Error('Function not implemented.');
}

