import { Form, Input, Button, Space, Card, Select, Col, Row, Layout, Table, Empty, InputNumber, Tooltip, Divider, Descriptions, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useLocation, useParams } from 'react-router-dom';
import { InvoiceDto } from '@gtpl/shared-models/logistics';
import { FactoriesInput, PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest, SaleOrderDetailViewInfoDTO, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import moment from 'moment';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';



import './invoice-detail-view.css';
import { SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';
import { InvoiceCategoriesEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface LogisticsInvoiceDetailViewProps { }

export function LogisticsInvoiceDetailView(
  props: LogisticsInvoiceDetailViewProps
) {
  const [page, setPage] = React.useState(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [invoiceDetailViewData, setinvoiceDetailViewData] = useState<PlantInvoiceDetailsModel>();
  const [indexVal, setIndexVal] = useState(0);
  const [selectedExporter, setSelectedExporter] = useState<any>();
  const [selectedImporter, setSelectedImporter] = useState<any>();
  const invoiceDetailViewDataService = new SaleOrderService;
  let location = useLocation();
  const saleOrderId = location.state;
  const { Text } = Typography;
  const { id } = useParams<any>()
  console.log(id)

  let exporters = ExporterDataInput;
  let importers = FactoriesInput;
  const getRefCode = (val) => {
    const selectedExporter1 = exporters.find(item => item.value == val);
    setSelectedExporter(selectedExporter1);
  }
  const getImporterCodes = (val) => {
    const selectedImporter1 = importers.find(item => item.id == val);
    setSelectedImporter(selectedImporter1);
  }

  useEffect(() => {
    getPlantInvoiceDetails(id);
  }, [id])

  const getPlantInvoiceDetails = (saleOrderId) => {
    const req = new SaleOrderIdRequest(saleOrderId)
    invoiceDetailViewDataService.getPlantInvoiceDetails(req).then(res => {
      console.log(res.data);
      if (res.status) {
        setinvoiceDetailViewData(res.data);
        getRefCode(res.data.exporterId);
        getImporterCodes(res.data.unitId);
        // AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          setinvoiceDetailViewData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setinvoiceDetailViewData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
  const columns = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      key: 'brandName',
      // render: (value, record: PoItemsDetails, index) => {
      //   return (record.itemCategory) ? record.itemCategory.itemCategory : '';
      // },
    },
    {
      title: 'Grade',
      dataIndex: 'minGrade',
      key: 'minGrade',
      render: (value, record, index) => {
        return `${record.minGrade} - ${record.maxGrade}`
      },
    },
    {
      title: 'Product SKU',
      dataIndex: 'varientCode',
      key: 'varientCode',
      // render: (value, record: PoItemsDetails, index) => {
      //   return (record.size) ? record.size.sizeName : '';
      // },
    },

    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      key: 'packStyle',
      render: (value, record, index) => {
        return <div >{(record.pouches) + ' * ' + (record.pouchWeight)}</div>;
      },
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      key: 'cases',
      // render: (value, record: PoItemsDetails, index) => {
      //   return <div style={{ textAlign: 'end' }}>{(record.unitPrice)?Number(record.unitPrice):0}</div>;
      // },
    },
    {
      title:'Gross Weight',
      dataIndex:'grossWeight',
      key:'grossWeight',
      width:'150px',
      render:(value, record, index) =>{
        return <div style={{ textAlign: 'end' }}>{(record.grossWeight) ? Number(record.grossWeight) : 0}</div>;
      }
  },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.netWeight) ? Number(record.netWeight) : 0}</div>;
      },
    },
    {
      title: 'Dispatched Qty',
      dataIndex: 'dispatchedQty',
      key: 'dispatchedQty',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.dispatchedQty) ? Number(record.dispatchedQty) : 0}</div>;
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (value, record, index) => {
        console.log(record)
        return <div style={{ textAlign: 'end' }}>{(record.revisedUnitPrice > 0) ? Number(record.revisedUnitPrice) : record.unitprice}</div>;
      },
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'netAmount',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.revisedUnitPrice > 0) ? (Number(record.revisedUnitPrice) * Number(record.dispatchedQty)) : Number(record.netAmount)}</div>;
      },
    }
  ]



  return (
    <>
      {
        <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
          <Card title='INVOICE Detail View' style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', fontSize: '15px', color: 'white' }} extra={<Link to='/invoice-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} size='small'>
            {invoiceDetailViewData ? <div>
              <Descriptions column={3}>
                <Descriptions.Item label="SO Number">
                  {invoiceDetailViewData?.saleOrderNo}
                </Descriptions.Item>
                <Descriptions.Item label="Date">
                  {invoiceDetailViewData?.invoiceDate ? moment(invoiceDetailViewData?.invoiceDate).format('YYYY/MM/DD') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Exporter">
                  {selectedExporter?.name}
                </Descriptions.Item>

              </Descriptions>
              <Descriptions column={3}>
                <Descriptions.Item label="IE Code">
                  {selectedExporter?.ieCode}
                </Descriptions.Item>

                <Descriptions.Item label="FDA Reg NO.">
                  {selectedImporter?.fdaRegNumber}
                </Descriptions.Item>
                <Descriptions.Item label="FEI Number">
                  {selectedImporter?.feiNumber}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={3}>

                <Descriptions.Item label="Customer">
                  {(invoiceDetailViewData?.clientName)}
                </Descriptions.Item>
                <Descriptions.Item label="End Customer">
                  {invoiceDetailViewData?.endCustomerName}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Terms">
                  {(invoiceDetailViewData?.saleOrderItems[0].paymentTerms)}
                </Descriptions.Item>
              </Descriptions>


              <Descriptions column={3}>

                <Descriptions.Item label="Port of Loading">
                  {(invoiceDetailViewData?.portofloading)}
                </Descriptions.Item>
                <Descriptions.Item label="Port of Discharge">
                  {invoiceDetailViewData?.portOfDischarge}
                </Descriptions.Item>
                <Descriptions.Item label="Final Country">
                  {(invoiceDetailViewData?.country)}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={3}>

                <Descriptions.Item label="Final Destination">
                  {(invoiceDetailViewData?.saleOrderItems[0].destinationDetails)}
                </Descriptions.Item>
                <Descriptions.Item label="Flight Number">
                  {invoiceDetailViewData?.flightNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Currency">
                  {(invoiceDetailViewData?.saleOrderItems[0].currencyDetails)}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={3}>

                <Descriptions.Item label="Freight Charges" style={{ position: 'relative', color: 'red' }}>
                  <Text style={{ color: 'black' }}>{invoiceDetailViewData?.freightCharges ? Number(invoiceDetailViewData?.freightCharges) : ""}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Exchange Rate">
                  {(invoiceDetailViewData?.exchangeRate ? Number(invoiceDetailViewData?.exchangeRate) : "")}
                </Descriptions.Item>
                <Descriptions.Item label="Total Amount">
                  {(invoiceDetailViewData?.totalAmount ? Number(invoiceDetailViewData?.totalAmount) : "")}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={3}>

                <Descriptions.Item label="Total INR Amount">
                  {(invoiceDetailViewData?.total_INR_amount ? Number(invoiceDetailViewData?.total_INR_amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "")}
                </Descriptions.Item>
                <Descriptions.Item label="Processed At">
                  {selectedImporter?.address}
                </Descriptions.Item>
                {invoiceDetailViewData?.invoiceCategory== InvoiceCategoriesEnum.EPCG && <>
                 <Descriptions.Item label="Invoice Category">
                  {invoiceDetailViewData?.invoiceCategory}
                </Descriptions.Item>
                <Descriptions.Item label="EPCG Licence Number">
                {invoiceDetailViewData?.epcgLicenseNumber}
              </Descriptions.Item>
              <Descriptions.Item label="EPCG Licence Date">
              {invoiceDetailViewData?.epcgLicenseDate ? moment(invoiceDetailViewData?.epcgLicenseDate).format('YYYY/MM/DD') : ''}
            </Descriptions.Item>
            <Descriptions.Item label="EPCG FabValue">
                {invoiceDetailViewData?.epcgFobValue}
              </Descriptions.Item>
              <Descriptions.Item label="Export Obligation">
                {invoiceDetailViewData?.exportObligation}
              </Descriptions.Item>
            </>

                }
              </Descriptions>
              <Descriptions column={3}>
                <Descriptions.Item label="Place Of Receipt">
                  {invoiceDetailViewData?.placeOfReceipt}
                </Descriptions.Item>
              </Descriptions>

            </div> : <div></div>}
          </Card><br></br>
          {invoiceDetailViewData?.saleOrderItems.length > 0 ? <Table
            columns={columns}
            dataSource={invoiceDetailViewData ? invoiceDetailViewData.saleOrderItems : []}
            pagination={false}
            scroll={{ y: 500 }}
            size="small"
            bordered
            summary={(pageData) => {
              let totalUnitPrice = 0;
              let totalNetAmount = 0;

              pageData.forEach(({ unitPrice, netAmount }) => {
                totalUnitPrice += Number(unitPrice);
                totalNetAmount += Number(netAmount);

              });

              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={8} ><Text >Total Amount</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={2} ><Text style={{ textAlign: 'right' }}>{totalNetAmount}</Text></Table.Summary.Cell>
                  </Table.Summary.Row>

                </>
              );
            }
            }

          /> : ""
          }

        </Layout>
      }
    </>
  );
}

export default LogisticsInvoiceDetailView;