import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { TypesOfValueAddition2Dto } from '@gtpl/shared-models/masters';
import {TypesOfValueAddition2Service} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './type-of-value-addition2-form.css';
import { Link, useHistory } from "react-router-dom";

const { TextArea } = Input;


/* eslint-disable-next-line */
export interface TypeOfValueAddition2FormProps {
  TypesOfValueAddition2Data:TypesOfValueAddition2Dto;
  updateTypesOfValueAddition2Dto:(product:TypesOfValueAddition2Dto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function TypeOfValueAddition2Form(props: TypeOfValueAddition2FormProps){
  const [form] = Form.useForm();
  let history = useHistory();
  const TypesOfValueAddition2DataService=new TypesOfValueAddition2Service()
  const [disable,setDisable]=useState<boolean>(false)
  const createTypesOfValueAddition2=(productData:TypesOfValueAddition2Dto)=>{
    setDisable(true)
    TypesOfValueAddition2DataService.createTypesOfValueAddition2(productData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Type Of Value Addition 2 Created Successfully');
        history.push("/valueaddition2-view");
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  let createdUser="";
  
    if(!props.isUpdate){
      createdUser= localStorage.getItem("createdUser");
    }
  const onReset=()=>{
    form.resetFields();
  }

  const saveData = (values: TypesOfValueAddition2Dto) => {
    setDisable(false)
    if(props.isUpdate){
      props.updateTypesOfValueAddition2Dto(values);
    }else{
      setDisable(false)
      createTypesOfValueAddition2(values);
    }
  
  };

  return (
    <Card title={<span style={{color:'white'}}>Value Addition 2</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}  extra={props.isUpdate==true?"":<Link to='/valueaddition2-view' > {(props.isUpdate===false) && <span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span>}</Link>}   >
      <Form form={form} initialValues={props.TypesOfValueAddition2Data} name="control-hooks" onFinish={saveData} layout='vertical'>
      <Form.Item name="valueAdditionTwoId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
      <Form.Item style={{display:"none"}} name="createdUser" initialValue={createdUser} >
      <Input />
    </Form.Item>
    <Row>
    <Col style={{margin:'1%'}} xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:5}} xl={{span:5}}>
              <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                    },      
                    { 
                      pattern:/^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input/>
                </Form.Item>
        </Col>
        <Col style={{margin:'1%'}} xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:8}} xl={{span:8}}>
            <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  }, 
                  {
          
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                    message: `Should contain only alphabets.`
                  },    
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
     
        <Col style={{margin:'1%'}} xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:8}} xl={{span:8}}>
        <Form.Item
            name="remarks"
            label="Remarks"
            rules={[
               
            {
              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
              message: `Don't Allow Spaces`
            }
            ]}
            >
            <TextArea rows={1} />
          </Form.Item>
        </Col>
        </Row>
        <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
            }
            </Col>
          </Row>
      </Form>
    </Card>
  );
}

export default TypeOfValueAddition2Form;
