import { Input, Button, Space, Tag, Table, Card } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { CaretRightOutlined, SearchOutlined, FilterOutlined ,CloseCircleOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { ColumnProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import {  EmployeeLeavesService,  } from '@gtpl/shared-services/hrms';
import { EmployeeGetLeavesDto } from "@gtpl/shared-models/hrms";

/* eslint-disable-next-line */
export interface LeaveBalanceProps {}

export const LeaveBalance = (props: LeaveBalanceProps) => {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
const [employee,setEmployee ]=useState<EmployeeGetLeavesDto[]>([])
const service = new EmployeeLeavesService()

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    getLeavesData();
  }, []);
  const getLeavesData= () => {
    service.getLeavesData().then(res => {
      if (res.status) {
         
        setEmployee(res.data);
      } else {
        if (res.intlCode) {
          setEmployee([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployee([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
       {
        title: 'Types of leaves',
        dataIndex: 'typesOfLeaves',
        sorter: (a, b) => a.typesOfLeaves.localeCompare(b.typesOfLeaves),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('typesOfLeaves')
      },
	  // {
    //     title: 'Total leaves avaliable',
    //     dataIndex: 'leavesavaliable',
    //     sorter: (a, b) => a.leavesavaliable.localeCompare(b.leavesavaliable),
    //     sortDirections: ['descend', 'ascend'],
    //     ...getColumnSearchProps('leavesavaliable')
    //   },
	  {
        title: 'Leaves Balance',
        dataIndex: 'leavesLeftOver',
        sorter: (a, b) => a.leavesLeftOver - b.leavesLeftOver,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('leavesLeftOver')
      },
      
  ];
  return (
    <Card title={<span style={{color:'white'}}>Leave Balance</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/menu' ><span style={{color:'white'}} ><Button className='panel_button' >Menu</Button> </span></Link>}   >
     <br></br>
    <>     
      <Table
        rowKey={record => record.Id}
        columns={sampleTypeColumns}
        dataSource={employee}
        pagination={{ onChange(current) { setPage(current); } }}
        scroll={{ y: 500 }}
        size="small"
        bordered
       
      >
      </Table>
      </>
     </Card>
  );  
}

export default LeaveBalance;