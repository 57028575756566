
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space } from 'antd';
import { CountryDto, CurrencyDto, CustomerACcountTypes, CustomerDto, PaymentmodesDto } from '@gtpl/shared-models/masters';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './customers-form.css';
import { CountryService, CurrencyService, CustomersService } from '@gtpl/shared-services/masters';
import { PaymentmodesService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
const countryService = new CountryService();
const currencyService = new CurrencyService();

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};



export interface CustomersFormProps {
  customersData: CustomerDto;
  updateDetails: (customers: CustomerDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function CustomersForm(props: CustomersFormProps) {
  let accountTypes = CustomerACcountTypes;
  let history = useHistory();

  const [form] = Form.useForm();
  const service = new CustomersService;
  const paymentModeService = new PaymentmodesService;
  const [selectedpaymentmode, setSelectedPaymentModes] = useState<number>(null);
  const [paymentModeData, setPaymentModeData] = useState<PaymentmodesDto[]>([]);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(null);
  const [disable, setDisable] = useState<boolean>(false)
  // const [cusAccountTypes, setCusAccountTypes] = useState<any[]>([]);

  useEffect(() => {
    getAllActivePaymentModes()
    getAllActiveCountries()
    getAllActiveCurrencys()
  }, [])

  useEffect(() => {
    console.log(props.customersData)
    // getAllActivePaymentModes()
    // getAllActiveCountries()
  }, [])

  const getAllActiveCountries = () => {
    countryService.getAllActiveCountries().then((res) => {
      if (res.status) {
        setCountries(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setCountries([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCountries([]);
      });
  }

  const getAllActiveCurrencys = () => {
    currencyService.getAllActiveCurrencys().then((res) => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setCurrencies([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCurrencies([]);
      });
  }
  const handleCurrency = (value) => {
    setSelectedCurrency(value);
  }

  const saveCustomer = (customersData: CustomerDto) => {
    setDisable(true)
    service.createCustomers(customersData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Customer Details Created Successfully');
        history.push("/Customers-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActivePaymentModes = () => {
    paymentModeService.getAllActivePaymentmodes().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('PaymentModes Retrived successfully');
        setPaymentModeData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handlePaymentMode = (value) => {
    setSelectedPaymentModes(value);
  }



  const saveData = (values: CustomerDto) => {
    setDisable(false)
    console.log(values);
    if (props.isUpdate) {
      console.log('update')
      props.updateDetails(values);
    } else {
      setDisable(false)
      saveCustomer(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }


 // props.customersData.currency=Number(props.customersData.currency);
  return (
    <>
      <Card size="small" title={<span style={{ color: 'white' }}>Customers Form </span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to="/Customers-view"  ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
        <Form form={form} onFinish={saveData} initialValues={props.customersData} layout="vertical">
          <Form.Item name="custId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="clientName"
                label="Customer Name"
                rules={[
                  {
                    required: true, message: 'Missing Customer Name'
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} hidden>
              <Form.Item
                name="gstNumber"
                label="GST No"
                rules={[
                  {
                    required: false, message: 'Missing GST Number',
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="website"
                label="Website Name"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: /^[a-zA-Z0-9@._-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="clientCode"
                label="Customer Code"
                rules={[
                  {
                    required: true, message: 'Missing customer code',
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                    message: `Invalid client code`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="contactPerson"
                label="Contact Person"
                rules={[
                  {
                    required: true, message: 'Missing Contact Person'
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}
              >
                <Input />
              </Form.Item></Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>

              <Form.Item
                name="phoneNo"
                label="Mobile No"
                rules={[
                  {
                    required: true,
                    message: 'Enter valid mobile number',
                    max:13,
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: `Don't Allow letters and Spaces`
                  }


                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_@.\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>

              <Form.Item
                name="accountType"
                label="Account Type"
                rules={[
                  {
                    required: true,
                    message: 'Account Type Is Required '
                  },
                ]}
              >
                <Select
                  placeholder="Select Account Type"
                  // onChange={getSkuCode}
                  allowClear
                >
                  {accountTypes.map(AccTypeDropData => {
                    return <Option value={AccTypeDropData.value}>{AccTypeDropData.name}</Option>
                  })}
                </Select>
                {/* <Input /> */}
              </Form.Item>


            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="countryId"
                label="Country"
                rules={[
                  {
                    required: true, message: 'Missing country',
                  },
                ]}
              >
                <Select
                  placeholder="Select Country"
                  // onChange={getSkuCode}
                  allowClear
                >
                  {countries.map(dropData => {
                    return <Option value={dropData.countryId}>{dropData.countryName}</Option>
                  })}
                </Select>
                {/* <Input/> */}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true, message: 'Missing currency',
                  },
                ]}
              >
                <Select
                  placeholder="Select Currency"
                  // onChange={getSkuCode}
                  onChange={handleCurrency}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                >
                  {currencies.map(curencyDropData => {
                    return <Option value={curencyDropData.currencyId} >{curencyDropData.currencyName}</Option>
                  })}
                </Select>
                {/* <Input /> */}
              </Form.Item>
            </Col>

            <Col span={0}>
              <Form.Item
                name="paymentTerms"
                // label="Payment Terms"
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue='NA'
              >
                <Input hidden />
              </Form.Item></Col>
            <Col span={0}>
              <Form.Item
                name="ratePerHour"
                // label="Rate Per Hour"
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue='0'
              >
                <Input hidden />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="paymentModeId"
                label="Payment Mode"
                rules={[
                  {
                    required: true, message: 'Missing payment mode',
                  },
                ]}
              >
                <Select
                  showSearch
                  // style={{ width: 200 }}
                  placeholder="Select Payment mode"
                  optionFilterProp="children"
                  onChange={handlePaymentMode}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Payment</Option>
                  {paymentModeData.map((paymentMode) => {
                    return <Option key={paymentMode.paymentModeId} value={paymentMode.paymentModeId}>{paymentMode.paymentMode}</Option>
                  })}
                </Select>
              </Form.Item></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="publicNote"
                label="Public Note"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}

              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="privateNote"
                label="Private Note"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item
                name="shipmentTerms"
                // label="Shipment Terms"
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue='NA'
              >
                <Input hidden />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>

            {/* </Row>

          <Row> */}
            <Form.List name="cusAddressInfo">
              {(fields, { add, remove }) => (
                <>
                  <Card>
                    <Form.Item>
                      {/* <br /> */}
                      {/* <br/> */}
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Address
                      </Button>
                    </Form.Item>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        {/* <Row gutter={24}> */}

                        {/* </Row> */}
                        <Row gutter={24}>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                              {...field}
                              label="Apartment Name"
                              name={[field.name, 'addLineOne']}
                              fieldKey={[field.fieldKey, 'addressLineOne']}
                              rules={[{ required: true, message: 'Missing apartment name' }, {

                                // pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                                message: `Should contain only alphabets.`
                              }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                              {...field}
                              label="Street Name"
                              name={[field.name, 'addLineTwo']}
                              fieldKey={[field.fieldKey, 'addressLineTwo']}
                              rules={[{ required: true, message: 'Missing street name' }, {
                                // pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                                message: `Should contain only alphabets.`
                              }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                              {...field}
                              label="State"
                              name={[field.name, 'addLineThree']}
                              fieldKey={[field.fieldKey, 'addressLineThree']}
                              rules={[{ required: true, message: 'Missing state name' },
                              {
                                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                                message: `Should contain only alphabets.`
                              }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                              {...field}
                              label="Postal Code"
                              name={[field.name, 'addLineFour']}
                              fieldKey={[field.fieldKey, 'addressLineFour']}
                              rules={[{ required: true, message: 'Missing Postal Code' }, {
                                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                                message: `Don't Allow Spaces`

                              }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          {/* <Col span={6}>
                      <Form.Item
                        {...field}
                        label="postalCode"
                        name={[field.name, 'postalCode']}
                        fieldKey={[field.fieldKey, 'postalCode']}
                        rules={[{ required: true, message: 'Missing pincode' }]}
                      >
                        <Input />

                      </Form.Item>
                      </Col> */}
                        </Row>

                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                  </Card>

                </>
              )}
            </Form.List>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" disabled={disable} htmlType="submit" >
                Submit
              </Button>
              {(props.isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );

}



export default CustomersForm;

