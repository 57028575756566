import { EndCustomerRequest, MasterBrandsDropDownDto, PackingMethodDto, SkuDto } from '@gtpl/shared-models/masters';
import { EndCustomersService, MasterBrandService, PackingMethodService, SkuService } from '@gtpl/shared-services/masters';
import { Card, Col, Row, Form, FormInstance, Select, Input } from 'antd'
import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

const { Option } = Select;

interface TrimsDetailformProps {
    form: FormInstance<any>,
    addTrimForm: FormInstance<any>,
    isUpdate?: boolean
    initialValues: any
}

export default function TrimsDetailform(props: TrimsDetailformProps) {
    const { form } = props
    const [brandData, setBrandData] = useState<any[]>([]);
    const [skucodes, setSkucode] = useState<SkuDto[]>([]);
    const [packingMethods, setPackingMethods] = useState<PackingMethodDto[]>([]);
    const [endCustomers, setEndCustomers] = useState<any[]>([])
    const { isUpdate, initialValues } = props
    const brandsService = new MasterBrandService();
    const skuService = new SkuService();
    const pmService = new PackingMethodService();
    const endCustomerService = new EndCustomersService()

    let createdUser = "";

    if (!props.isUpdate) {
        createdUser = localStorage.getItem("createdUser");
    }

    useEffect(() => {
        getSkuCodes();
        getPackingMethods()
        getActiveEndCustomersDropDownData()
        if (isUpdate && initialValues) {
            form.setFieldsValue({
                brandId: initialValues.brandId,
                varientCode: initialValues.variantId,
                packStyleId: initialValues.packStyleId,
                endCustomerId: initialValues.endCustomerId,
                createdUser: initialValues.createdUser
            })
            getAllMasterBrandsDropDown()
        }
    }, [])


    const getActiveEndCustomersDropDownData = () => {
        endCustomerService.getActiveEndCustomersDropDownData().then((res) => {
            if (res.status) {
                setEndCustomers(res.data)
            } else {
                setEndCustomers([])
            }
        }).catch((err) => {
            setEndCustomers([])
        })
    }

    const onFocus = () => {
        //console.log('focus');
    }

    const onSearch = (val) => {
        //console.log('search:', val);
    }
    const onBlur = () => {
        //console.log('blur');
    }

    const getSkuCodes = () => {
        skuService.getActiveSkuCodes().then((res) => {
            if (res.status) {
                setSkucode(res.data);
            }
            else {
                setSkucode([]);
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setSkucode([]);
            });
    }

    const getPackingMethods = () => {
        pmService.getAllPackingMethod().then((res) => {
            if (res.status) {
                setPackingMethods(res.data);
            }
            else {
                setPackingMethods([]);
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setPackingMethods([]);
            });
    }



    const getAllMasterBrandsDropDown = () => {
        const endCustomerId = form.getFieldValue('endCustomerId')
        const req = new EndCustomerRequest(endCustomerId, true)

        if (endCustomerId == 0) {
            brandsService.getAllMasterBrandsDropDown().then((res) => {
                if (res.status) {

                    setBrandData(res.data);
                } else {
                    setBrandData(undefined);
                }
            }).catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setBrandData(undefined);
            });
        } else {
            endCustomerService.getBrandsForEndCustomerId(req).then((res) => {
                if (res.status) {
                    res.data = res.data.map(({
                        brandName: masterBrandName,
                        ...rest
                    }) => ({
                        masterBrandName,
                        ...rest
                    }));
                    setBrandData(res.data);
                } else {
                    setBrandData(undefined);
                }
            }).catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setBrandData(undefined);
            });
        }
    }
    return (
        <Card title={false}>
            <Form form={form} layout={'vertical'} >
                <Row gutter={24}>
                    <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
                        <Input hidden />
                    </Form.Item>
                    <Form.Item style={{ display: "none" }} name="trimId"  >
                        <Input hidden />
                    </Form.Item>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                        <Form.Item label="End Customer" rules={[{ required: true, message: 'Missing Endcustomer' }]}
                            name={'endCustomerId'}>
                            <Select
                                //className={'stylesInfo'}//for font style
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select End Customer"
                                allowClear
                                onChange={() => getAllMasterBrandsDropDown()}
                            >
                                <Option value={0}>All End Customers</Option>;
                                {endCustomers.map(el => {
                                    return <Option className={'stylesInfo'} label={'endCustomerId'} key={el.endCustomerId} value={el.endCustomerId}>{el.endCustomerName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                        <Form.Item
                            label="Brand"
                            name={'brandId'}
                            rules={[{ required: true, message: 'Missing Brand name' }]}
                        >
                            <Select
                                placeholder="Select Brand"
                                allowClear
                                showSearch
                                onFocus={onFocus}
                                onChange={() => props.addTrimForm.resetFields()}
                                onSearch={onSearch}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {brandData?.map(item => {
                                    return <Option value={item.masterBrandId}>{item.masterBrandName}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                        <Form.Item label="Product"
                            name={'varientCode'}
                            rules={[{ required: true, message: 'Missing Varient Code name' }]}
                        >
                            <Select
                                //className={'stylesInfo'}//for font style
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Variant Code"
                                allowClear
                                dropdownMatchSelectWidth={false}
                            >
                                <Option key={'all'} value={0}>All Products</Option>;
                                {skucodes.map(skudropData => {
                                    return <Option className={'stylesInfo'} label={'varientId'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                        <Form.Item label="Pack Style" rules={[{ required: true, message: 'Missing Packstyle' }]}
                            name={'packStyleId'}>
                            <Select
                                //className={'stylesInfo'}//for font style
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Packing Method"
                                allowClear
                            >
                                <Option value={0}>All Pack styles</Option>;
                                {packingMethods.map(skudropData => {
                                    return <Option className={'stylesInfo'} label={'packStyleId'} key={skudropData.packingMethodId} palletCapacity={skudropData.palletCapacity} value={skudropData.packingMethodId}>{skudropData.packingMethodName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Card>
    )
}
