import { PlantIdRequest } from "@gtpl/shared-models/masters"
import { AssetService } from "@gtpl/shared-services/asset-management"
import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import { Col, Row, Table } from "antd"
import Form, { useForm } from "antd/lib/form/Form"
import { ColumnProps } from "antd/lib/table"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto"
import { title } from "process"
import React, { useEffect, useState } from "react"

export interface AssetsMaintainaceProps{}

export function AssetsMaintainance(){
    const form = useForm
    const service = new AssetService();
    const [reportData, setReportData] = useState<any[]>([]);

    useEffect(() =>{
        maintainanceOnInterval();
        // if (Number(localStorage.getItem('unit_id')) != 5) {
        //     form.setFieldsValue({ plant: Number(localStorage.getItem('unit_id')) })
        //   }
    },[])
 

      const maintainanceOnInterval =()=>{
        const req = new AssetByStatus()
        // req.plant=plant
        service.underMaintainanceOnInternal(req).then(res => {
          if (res.status) {
            setReportData(res.data);
          } else {
              if (res.intlCode) {
                setReportData([]);
                  AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
              }
          }
      }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setReportData([]);
      })
    
      }
    
    const Columns :ColumnProps<any>[]=[
       
        {
            title:'Assets Code',
            dataIndex: 'assetsCode',
        },
        {
            title:'Asset Name',
            dataIndex: 'assetName',
        },
        {
            title:'Status',
            dataIndex: 'status',   
        },
        {
            title:'Unit Name',
            dataIndex: 'unitName',
   
        },

    ]

return(

             <Table
            //  rowKey={record => record.saleOrderId}
             columns={Columns}
             dataSource={reportData}
             size='small'
             bordered />

)
}
export default AssetsMaintainance;