import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker } from 'antd';
import './container-register.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';

import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';

/* eslint-disable-next-line */
export interface DispatchToPlantProps { }
const { Option } = Select;
export function DispatchToPlant(
    props: DispatchToPlantProps
) {

    let location = useLocation();
    const containerStatusInfo: ContainerStatusInfo | any = location.state;
    
    const history = useHistory();

    const [containerRegisterForm] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [tab, setTab] = useState<string>(undefined);

    // const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
    const [saleOrderData, setSaleOrderData] = useState<any[]>([]);


    const SoService = new SaleOrderService();
    const containerRService = new ContainerRegisterService();

    useEffect(() => {
        getSaleOrderData();
        if (containerStatusInfo) {
            containerRegisterForm.setFieldsValue({
                saleOrderId: containerStatusInfo.saleOrderId,
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT,
                containerId: containerStatusInfo.containerId
            });
        } else {
            containerRegisterForm.setFieldsValue({
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT
            });
        }
    }, [containerStatusInfo]);

    // get sale order drop down details
    const getSaleOrderData = () => {
        SoService.getSoPoBrandDropDownList().then((res) => {
            if (res.status) {
                setSaleOrderData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

    const onReset = () => {
        containerRegisterForm.resetFields();
        if (containerStatusInfo) {
            containerRegisterForm.setFieldsValue({
                saleOrderId: containerStatusInfo.saleOrderId,
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT,
                containerId: containerStatusInfo.containerId
            });
        } else {
            containerRegisterForm.setFieldsValue({
                containerStatus: ContainerStatusEnum.DISPATCHED_TO_PLANT
            });
        }
    }

    const saveData = (values) => {
        values.createdUser=localStorage.getItem('createdUser');
        values.updatedUser=localStorage.getItem('createdUser');
        containerRService.containerDispatchedToPlant(values).then(res => {
            if (!res.status) {
              throw new Error(res.internalMessage);
            }else{
                history.push({
                    pathname: '/container-register-grid',
                    // search: '?the=search',
                    state: 'BOOKED'
                  })
                // history.push(path:'/container-register-grid',state:"BOOKED");
                // history.push(path:"",state:"")
              AlertMessages.getSuccessMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const goToContainerGrid = () => {
        setTab("BOOKED");
      }

    return (
        <>
        {tab ? <Redirect to={{pathname:"/container-register-grid", state:tab}}/>:null}
        <Card title={<span style={{ color: 'white' }}>Container Dispatched To Plant</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link onClick={e => goToContainerGrid()}><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

            <Form layout="vertical" form={containerRegisterForm} name="control-hooks" onFinish={saveData} >
                <Row gutter={24}>
                    <Form.Item name="containerId">
                        <Input hidden />
                    </Form.Item>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
                        <Form.Item name="saleOrderId" label="SO Number" >
                            <Select
                                placeholder="Select SaleOrder"
                                allowClear
                                disabled={true}
                            >
                                <Option value={''}>Please Select</Option>
                                {saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{`${dropData.poNumber}-${dropData.brandName}-${dropData.variantCode}`}</Option>

                                    // return <Option value={dropData.saleOrderId}>{`${dropData.saleOrderNumber} - ${dropData.poNumber}`}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

                        <Form.Item label="Container Number" name="containerNumber" rules={[{ required: true, message: 'Missing Container Number' }]} >
                            <Input />
                        </Form.Item>
                    </Col>

                  

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>
                        <Form.Item
                            name="containerStatus"
                            label="Container Status"
                            // rules={[
                            //     {
                            //         required: true, message: 'Missed Container Status'
                            //     },
                            // ]}
                            >
                            <Select
                                placeholder="Select Container Status"
                                defaultValue={ContainerStatusEnum.DISPATCHED_TO_PLANT}
                                disabled={true}
                            >
                                <Option key={0} value={null}>Select Container Status</Option>
                                {ContainerStatusInput.map((container) => {
                                    return <Option key={container.status} value={container.status}>{container.status}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col style={{ paddingTop: '30px' }}>
                        <Button type="primary" htmlType="submit" >
                            Submit
                        </Button>
                        {(isUpdate === false) &&
                            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                                Reset
                        </Button>
                        }
                    </Col>

                </Row>
            </Form>
        </Card>
        </>
    );
}

export default DispatchToPlant;
