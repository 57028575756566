import React,{useState, useEffect} from 'react';
import {Form, Input, Button, Card, Row, Col, DatePicker} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import {SkillsDto} from '@gtpl/shared-models/hrms';
import { SkillsService } from '@gtpl/shared-services/hrms';

import './skills.css';

/* eslint-disable-next-line */
export interface SkillsProps {
  skillsData: SkillsDto;
  updateSkill: (skills: SkillsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function Skills(
  props: SkillsProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const skillsService = new SkillsService()
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }

  const createSkill = (Data: SkillsDto) => {
    setDisable(true)
    skillsService.createSkill(Data).then(res => {
      setDisable(false)
      if(res.status) {
        AlertMessages.getSuccessMessage('Skill created successfully');
        history.push('/skills-grid');
        onReset();
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    console.log('');
    form.resetFields();
  }

  const saveData = (values: SkillsDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values);
      props.updateSkill(values);
    }else {
      setDisable(false)
      console.log(values);
      createSkill(values);
    }
  };
  return (
    <Card title={<span style={{color:'white'}}>Skills</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/skills-grid' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.skillsData} >
        <Form.Item 
        name ="skillsId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="skills"
                label="Skill"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Skill",max:16
                  },
                  {
          
                    pattern: /^[ A-Za-z:_@.,;'/#&+-]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
       
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
  </Card>
  );
}

export default Skills;
