import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Typography, Row, Col, TimePicker, DatePicker, FormInstance, Space, Upload, UploadProps } from 'antd';
import './container-log-book-form.css';
import { CategoriesInput, ForkliftJobcodesDropDownDto, ItemsDetails, PackTypeCategory, SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderItemsDTO, SaleOrderItemsInfo, SoNumbersModel, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import moment from 'moment';
import { MinusCircleOutlined } from '@ant-design/icons';
import { format } from 'url';
import { ContainerLogBookDTO } from '@gtpl/shared-models/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import Link from 'antd/lib/typography/Link';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
/* eslint-disable-next-line */
const { Option } = Select;
// 
export interface ContainerLoadingDetailsProps {
  saleOrderData: SoNumbersModel[];
  saleOrderItemDetails:ItemsDetails[]
  form: FormInstance<any>
  saleOrderItem: (saleOrderId: number) => void;
  formData:any;
  isUpdate: boolean;
  uploadFileList:(uploadFiles:any[])=>void
}

export function ContainerLoadingDetails(
  props: ContainerLoadingDetailsProps
) {

  const [brandsData, setBrandsData] = useState<any[]>([])
  const [brandData, setBrandData] = useState<any>(undefined)

  const saleOrderService = new SaleOrderService();
  const [filelist, setfilelist] = useState([]);
  let createdUser = localStorage.getItem('createdUser');
  let unitId = Number(localStorage.getItem('unit_id'));

  useEffect(() => {
    console.log(props.isUpdate)
    if(props.formData){
      props.formData.loadingDate=moment(props.formData.loadingDate, 'YYYY-MM-DD');
      props.formData.loadingStartTime=moment(props.formData.loadingStartTime, 'HH:mm');
      props.formData.loadingEndTime=moment(props.formData.loadingEndTime, 'HH:mm');
    }
    props.form.setFieldsValue({'unitId':unitId});
    // props.brandsData?.brandName?props.form.setFieldsValue({ brandId: props.brandsData.brandName }):null
  }, []);

  const getBrandsForSoId = (reqData) => {
    saleOrderService.getBrandsForSoId({saleOrderId: reqData}).then(res => {
      if (res.status) {
        setBrandsData(res.data);
      } else {
        setBrandsData([])
      }
    }).catch(err => { 
      AlertMessages.getErrorMessage(err.message);
      setBrandsData([]);
    })
  }
  
  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }
  const handleSaleOrder = (value) => {
    console.log(props.form[0]);
    props.form.setFieldsValue({ LogBookInfo: ['', '', '','', '', '', '' ,'', '', ''] });
    props.saleOrderItem(value);
    getBrandsForSoId(value);
  }
  const getItemDetails = (value) => {
    props.form.setFieldsValue({'brandId':undefined});
    setBrandData(undefined)
    console.log(value)
    let masterBrandData = brandsData?.filter(rec => rec.saleOrderItemId === value);
    console.log(masterBrandData);
    setBrandData(masterBrandData);
  }
  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      props.uploadFileList([])
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(zip)$/)) {
        AlertMessages.getErrorMessage("Only xls,pdf,jpg,png,doc,jpeg,zip files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
          console.log(file);
          props.uploadFileList([...filelist, file])
          return false;
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };
  return (

      <Form
        layout="vertical" form={props.form} name="control-hooks" initialValues={props.isUpdate?props.formData:{ LogBookInfo: ['', '', '','', '', '', '' ,'', '', ''] }}  >
        <Form.Item name="containerLogBookId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="unitId"
          initialValue={unitId}
        >
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item name="loadingDate" label = "Loading Date" initialValue={moment()}
            rules={[
              {
                required: true, message: 'Missing Date',
              },
            ]}
            >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item
              name="saleOrderId"
              label="Sale Order"
              rules={[
                {
                  required: true,
                  message: 'Sale Order is required',
                },
              ]}
            >
            <Select
                placeholder="Select Sale Order"
                allowClear
                showSearch
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={handleSaleOrder}
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {props.saleOrderData?.map(saleOrderDropDown => {
                return <Option value={saleOrderDropDown.saleOrderId}>{saleOrderDropDown.poNumber}</Option>
                })}
            </Select>
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item
                name="containerNo"
                label="Container No"
                rules={[
                    {
                    required: true,
                    message: 'Container No is required',
                    },
                ]}
                >
            <Input/>
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item
                name="vehicleNo"
                label="Vehicle No"
                rules={[
                    {
                    required: true,
                    message: 'Vehicle No is required',
                    },
                ]}
                >
            <Input/>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="loadingStartTime" label = "Start Time"
            rules={[
              {
                required: true, message: 'Missing Start Time',
              },
            ]}
            >
              <TimePicker format={"HH:mm"} style={{ width: '100%' }}  onChange={(time) => {
                console.log(time);
                if(props.form.getFieldValue('loadingEndTime')<time){
                  AlertMessages.getErrorMessage('Start Time Should be less than End Time. ');props.form.setFieldsValue({loadingStartTime:null})}
                }} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="loadingEndTime" label = "End Time"
            rules={[
              {
                required: true, message: 'Missing End Time',
              },
            ]}
            >
              <TimePicker format={"HH:mm"} style={{ width: '100%' }} onChange={(time) => {
                console.log(time);
                if(props.form.getFieldValue('loadingStartTime')>time){
                  AlertMessages.getErrorMessage('End Time Should be greater than Start Time. ');props.form.setFieldsValue({loadingEndTime:null})}
                }}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="file" label="Upload Loading Images" rules={[{ required: false, message: 'Please Upload File' },]}>
                <Upload {...uploadFieldProps} style={{  width:'100%' }}>
                    <Button style={{ color: "white", backgroundColor: "#806767", width:'100%' }} icon={<UploadOutlined />} >Upload Document</Button><Typography.Text  type="secondary">
                     (Supported formats zip)
                </Typography.Text>
                </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="LogBookInfo">
          {(fields, { add, remove }) => (
          <>
            <Card>
            <Row gutter={24}>
              <Col span={4}>
              <Form.Item>
                <Button type="dashed" style={{ color: "white", backgroundColor: "#806767"}} onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Row
                </Button>
              </Form.Item>
              </Col>
            </Row>
              {fields.map(field => (
                  <>
                  <Row gutter={24}>
                    <Form.Item
                      name={[field.name, "logbookItemId"]}
                      fieldKey={[field.fieldKey, "logbookItemId"]}
                      style={{ display: 'none' }}>
                      <Input hidden />
                    </Form.Item>
                    <Form.Item
                      style={{ display: 'none' }}
                      name="createdUser"
                      initialValue={createdUser}
                    >
                      <Input hidden />
                    </Form.Item>
                    <Col span={2}>
                      <Form.Item
                        name={[field.name, "row"]}
                        fieldKey={[field.fieldKey, "row"]}
                        label="Row"
                        rules={[
                          {
                            required: true,
                            message: 'Row is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        name={[field.name, "saleOrderItemId"]}
                        fieldKey={[field.fieldKey, "saleOrderItemId"]}
                        label="Product SKU"
                        rules={[
                          {
                            required: true,
                            message: 'Product SKU is required',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Product SKU"
                          allowClear
                          showSearch
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          onChange={getItemDetails}
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {props.saleOrderItemDetails?.map(saleOrderDropDown => {
                            return <Option value={saleOrderDropDown.saleOrderItemId}>{saleOrderDropDown.varient}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[field.name, "brandId"]}
                        fieldKey={[field.fieldKey, "brandId"]}
                        label="Brand"
                        rules={[
                          {
                            required: true,
                            message: 'Brand is required',
                          },
                        ]}
                      >
                        {/* <Input disabled={true}/> */}
                        <Select
                          placeholder="Select Brand"
                          allowClear
                          showSearch
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          // disabled={true}
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {brandData?.map(res => {
                            return <Option value={res.masterBrandId}>{res.brandName}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name={[field.name, "cartons"]}
                        fieldKey={[field.fieldKey, "cartons"]}
                        label="No of Cartons"
                        rules={[
                          {
                            required: true,
                            message: 'Cartons is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  <Col span={2} style={{ marginTop:'26px'}}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Col>
              </Row>
            </>
              ))}
            </Card>
          </>
        )}
      </Form.List>
      </Form>
  );
}

export default ContainerLoadingDetails;
