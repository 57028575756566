import { Button, Card, Col, Row, Tooltip, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import form from 'antd/lib/form';
import Graph from './graph';
import RTable from './table';
export interface RecruitmentDashboardProps {}

export function RecruitmentDashboard(
  props: RecruitmentDashboardProps
) {


  return (
    <div className='dashboard' >

    <Row gutter={24} >
        <Col xs={24} sm={24} md={12} xl={12} style={{ marginTop: '20px' }} >
           <Graph/>
        </Col>
     </Row>
     <Row>   
        <Col xs={24} sm={24} md={12} xl={12} style={{ marginTop: '20px' }} >
            <Card >
                <Card title={<span style={{ color: 'black' }} > Data Of Last 2 Weeks</span>}
                    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#white', border: 0 }}  >
                      <RTable/>
                </Card>
               
            </Card>
        </Col>
       
        
    </Row>

</div >

    
  );
}
export default RecruitmentDashboard;
