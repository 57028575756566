import React from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';

/* eslint-disable-next-line */
export interface PlantInvoiceRTSProps {}

export function PlantInvoiceRTS(
  props: PlantInvoiceRTSProps
) {
  return (
    // <Card title={<span style={{color:'white'}}>PLANT INVOICE</span>}
    // style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Button>Print</Button>}>
    <html>
    <head>
    
    </head>
    <body>
    
    <h1 style={{ textAlign: 'center', fontSize:'32px' }}>INVOICE<Button onClick={() => window.print()} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}>Print</span></Button></h1>
    
    <table className={'ta-b'}>

    <tr>
        <td className={'ta-b'} rowSpan={2}>
        <h4>Exporter:</h4>
        SANDHYA AQUA EXPORTS PVT. LIMITED<br/>
      MIG-25A, LAWSONS BAY COLONY,<br/>
      VISAKHAPATNAM - 530 017<br/>
      ANDHRA PRADESH, INDIA<br/>
      GSTIN : 37AAJCS2286C1ZA
      </td>
        <td className={'ta-b'}>
        Invoice No.
        <h4>SAE-A677 / 20-21</h4>
        Date
      <h4>08th Mar, 2021</h4>
        </td>
        <td className={'ta-b'}></td>
        <td className={'ta-b'}>
      Exporters Ref
      <h4>IE Code 2605001024</h4>
      </td>
      </tr>
      <tr>
      <td colSpan={3} className={'ta-b'}>
      Buyer's Order No.
        <h4>PO NO. AZ9327</h4>
        Date
      <h4> 23rd Jan, 2021</h4>
      </td>
      </tr>

      <tr>
        <td className={'ta-b'}>
        <h4>Processed and Packed at</h4>
        SANDHYA AQUA EXPORTS PVT. LIMITED<br/>
KURUMADDALI VILLAGE, PAMARRU MANDAL,<br/>
521157  KRISHNA DIST, ANDHRA PRADESH, INDIA<br/>
FDA REGISTRATION NO. 16652389826<br/>
FEI NUMBER: 2000038402
      </td>
        <td colSpan={3} className={'ta-b'}>
        <h4> NOTIFY PARTY</h4>
        ROD INTERNATIONAL CHB SERVICES LLC<br/>
10330 PIONEER BLVD, SUITE 200<br/>
SANTA FE SPRINGS, CA 90670, USA.<br/>
TEL: (562)622-0715, FAX: (562)622-074
        </td>
      </tr>

      <tr>
        <td rowSpan={2} className={'ta-b'}>
        <h4>Consignee</h4>
        AZ GEMS INC<br/>
405 MISSOURI CT.<br/>
REDLANDS, <br/>
CA 92373<br/>
U S A<br/>
FDA REGISTRATION NO:16502951940
      </td>
        <td colSpan={3} className={'ta-b'}>
        <h4>IMPORTER ON RECORD</h4>
        AZ GEMS INC<br/>
405 MISSOURI CT.<br/>
REDLANDS,  CA 92373, USA<br/>
FDA REGISTRATION NO. 16502951940
        </td>
      </tr>
      <tr>
      <td className={'ta-b'}>
      Country of Origin of Goods
      <h4>INDIA</h4>
      </td>
      <td colSpan={2} className={'ta-b'}>
      Country of Final Destination
      <h4> USA	</h4>
      </td>
      </tr>

      <tr>
        <td className={'ta-b'}>
        <h4>Pre Carriage By</h4>
        <h4>Road</h4>
      </td>
        <td className={'ta-b'}>
        <h4>Place of Receipt of Pre-carrier</h4>
        </td>
        <td colSpan={2} className={'ta-b'}>
        Terms of Delivery and Payment
        <h4>PAYMENT 90 DAYS FROM BL DATE</h4>
        </td>
      </tr>

      <tr>
        <td className={'ta-b'}>
        <h4>Vessel / Flight No. </h4>
      </td>
        <td className={'ta-b'}>
        Port of Loading
        <h4>VISAKHAPATNAM, INDIA		</h4>
        </td>
       
        <td colSpan={2} rowSpan={2} className={'ta-b'}>
        Container No.<br/>
Liner Seal No.<br/>
Bill of Lading No.
        </td>
      </tr>
      <tr>
        <td className={'ta-b'}>
        Port of Discharge
        <h4>HOUSTON, USA		</h4>
      </td>
        <td className={'ta-b'}>
        Final Destination
        <h4>HOUSTON, USA</h4>
        </td>
        </tr>

        <tr>
        <td colSpan={1} className={'ta-b'}>
        Marks and Nos / No and Kind of Pkgs                       Description of Goods<br/><br/>
        &nbsp;&nbsp; <h4>HSN CODE                    SIZE                        No. OF CARTONS</h4><br/><br/>
        20 X 1.00 LB		0306.17.20	RAW FROZEN PEELED AND DEVEINED		<br/>	
20.00 	LB		TAIL OFF VANNAMEI SHRIMPS, IQF			<br/>
9.080 	KGS		LATIN NAME : LITOPENAEUS  VANNAMEI			<br/>
						
			16/20			150<br/>
						
			26/30			425<br/>
						
			31/40			250<br/>
						
			51/60			350<br/>
						
			71/90			50<br/>
						
			100/200			175<br/>
						
 20 X 1.00 LB		0306.17.20	RAW FROZEN PEELED AND DEVEINED			<br/>
20.00 	LB		BROKEN VANNAMEI SHRIMPS, IQF			<br/>
9.080 	KGS		LATIN NAME : LITOPENAEUS  VANNAMEI		<br/>	
						
			40/60			150<br/>
						
			60/80			100<br/>
        </td>
        <td>
        <h4>Quantity<br/>
IN KGS<br/>
NET WEIGHT<br/></h4><br/><br/><br/>
1,362.000<br/>
0.000<br/>
3,859.000<br/>
0.000<br/>
2,270.000<br/>
0.000<br/>
3,178.000<br/>
0.000<br/>
454.000<br/>
0.000<br/>
1,589.000<br/>
0.000<br/>
0.000<br/>
0.000<br/>
0.000<br/>
0.000<br/>
1,362.000<br/>
0.000<br/>
908.000
        </td>
        <td className={'ta-b'}>
          <h4>
        Rate<br/>
PER KG<br/>
USD<br/>
C N F<br/>
HOUSTON<br/></h4><br/><br/><br/>
10.46256<br/>

8.59031<br/>

7.75330<br/>

7.00441<br/>

6.69604<br/>

6.27753<br/>





6.07930<br/>

5.96916<br/>

        </td>
        <td className={'ta-b'}>
        Amount<br/><br/>
USD<br/>
C N F<br/>
HOUSTON<br/><br/>
14,250.00 <br/>
 0.00 <br/>
 33,150.00 <br/>
 0.00 <br/>
 17,600.00 <br/>
 0.00 <br/>
 22,260.00 <br/>
 0.00 <br/>
 3,040.00 <br/>
 0.00 <br/>
 9,975.00 <br/>
 0.00 <br/>
 0.00 <br/>
 0.00 <br/>
 0.00 <br/>
 0.00 <br/>
 8,280.00 <br/>
 0.00 <br/>
 5,420.00 <br/><br/>

 0.00 <br/>

        </td>
      </tr>

      <tr>
        <td colSpan={1} className={'ta-b'}>
Total <h4>1650</h4>
        </td>
        <td className={'ta-b'}><h4>14,982.000</h4>
        </td>
        <td className={'ta-b'}></td>
        <td className={'ta-b'}></td>
     </tr>

     <tr>
       <td colSpan={2} className={'ta-b'}>
       Amount chargeable<br/>
       (in words) <h4>U.S. DOLLARS ONE HUNDRED THIRTEEN THOUSAND NINE HUNDRED AND SEVENTY FIVE ONLY </h4>
       </td>
       <td className={'ta-b'}>
         <h4>Total USD</h4>
         </td>
       <td className={'ta-b'}>
       <h4>113,975.00 </h4>
       </td>
     </tr>

     <tr>
<td className={'ta-b'}>
  <h4>FREIGHT IN USD 	5,600.00</h4>
</td>
<td className={'ta-b'}>
  <h4>FOB VALUE IN RS. 	7,824,675.00</h4>
</td>
<td className={'ta-b'}>
  <h4>FOB VALUE IN USD	 108,375.00 </h4>
</td>
<td className={'ta-b'}></td>
     </tr>
     <tr>
       <td colSpan={4} className={'ta-b'}>
  <h4>
  EXCHANGE RATE: 1.00 (USD) = 	72.20	(INR)
  </h4></td>
</tr>

<tr>
  <td rowSpan={2} className={'ta-b'}></td>
  <td rowSpan={2} className={'ta-b'}>
  Declaration:<br/>
Supply Meant for Export Under Bond or Letter of Undertaking <br/>
without Payment of Integrated Tax (IGST)<br/>
  </td>
  <td className={'ta-b'}>
  <h4>Total Amount Before Tax :</h4>
CGST @ 0% :<br/>
SGST @ 0% :<br/>
IGST @ 0%:<br/>
<h4>Total GST Amount :</h4>
  </td>
  <td className={'ta-b'}>
  <h4> Currency (USD)</h4>
113,975.00
0.00
0.00
0.00
0.00
  </td>
</tr>
<tr>
  <td className={'ta-b'}>
    <h4>Total Amount After Tax :</h4>
  </td>
  <td className={'ta-b'}>
    <h4>113,975.00</h4>
  </td>
</tr>
<tr>
  <td colSpan={4} className={'ta-b'}>
This Shipment is made under DUTY DRAW BACK SCHEME AS WELL AS UNDER RoDTEP SCHEME.<br/>
I/We, in regard to my/our claim under RoDTEP scheme made in this Shipping Bill or Bill of Export, hereby declare that:<br/>
1. I/ We undertake to abide by the provisions, including conditions, restrictions, exclusions and time-limits as provided under RoDTEP scheme, and relevant notifications, 
    regulations, etc., as amended from time to time.<br/>
2. Any claim made in this shipping bill or bill of export is not with respect to any duties or taxes orlevies which are exempted or credited under any other mechanism outside RoDTEP.<br/>
3. I/We undertake to preserve and make available relevant documents relating to the exported goods for the purposes of audit in the manner and for the time period prescribed in the Customs Audit Regulations, 2018.<br/>
</td>
</tr>
<tr>
  <td className={'ta-b'}></td>
  <td className={'ta-b'}><h4>IN LBS</h4></td>
  <td className={'ta-b'}><h4>IN KGS</h4></td>
  <td className={'ta-b'}></td>
</tr>
<tr>
  <td colSpan={1} className={'ta-b'}>   TOTAL NET WT.: </td>
  <td className={'ta-b'}><h4>33,000.000 </h4></td>
  <td className={'ta-b'}><h4>14,982.000 </h4></td>
  <td className={'ta-b'}>TOTAL NO. OF MASTER CARTONS :<h4>1650</h4></td>
</tr>
<tr>
  <td colSpan={1} className={'ta-b'}>    TOTAL GROSS WT.:</td>
  <td className={'ta-b'}><h4>41,795.000  </h4></td>
  <td className={'ta-b'}><h4>18,975.000 </h4></td>
  <td className={'ta-b'}>TOTAL AMOUNT IN US DOLLARS :<h4>113,975.00</h4></td>
</tr>
<tr>
  <td colSpan={2} className={'ta-b'}>
  Declaration :<br/>
We declare that this invoice shows the actual price of the goods<br/>
described and that all particulars are true and correct<br/>
  </td>
  <td colSpan={2} className={'ta-b'}>
Signature and Date For &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <h4>SANDHYA AQUA EXPORTS PVT. LTD</h4><br/><h4>AUTHORISED SIGNATORY</h4>

  </td>
</tr>

    </table>
    </body>
    </html>
    // </Card>
  );
}

export default PlantInvoiceRTS;
