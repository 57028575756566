import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, ConfigProvider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { NotificationsForm } from '@gtpl/pages/hrms/hrms-components/notifications-form';
import { NotificationStatus } from '@gtpl/shared-models/common-models';
import { Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import './notifications-grid.css';
import { NotificationDto } from '@gtpl/shared-models/hrms';
import { NotificationsService } from '@gtpl/shared-services/hrms';
import moment from 'moment'
import { DepartmentService } from '@gtpl/shared-services/masters';
import { ColumnProps } from 'antd/lib/table';

export interface NotificationsGridProps { }

export function NotificationsGrid(props: NotificationsGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [notificationData, setNotificationData] = useState<NotificationDto[]>([]);
  const [slectedEmploymentStatusData, setSlectedEmploymentStatusData] = useState<any>(undefined);
  let [deptMap, setDeptMap] = useState<Map<number, string>>(new Map());



  const service = new NotificationsService();
  const departmentsDataService = new DepartmentService();

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const openFormWithData = (viewData: NotificationDto) => {
    setDrawerVisible(true);
    viewData.notificationDate = moment(viewData.notificationDate, 'YYYY-MM-DD')
    viewData.plannedClosingDate = moment(viewData.plannedClosingDate, 'YYYY-MM-DD')
    setSlectedEmploymentStatusData(viewData);
  }


  useEffect(() => {
    getAllNotifications();

  }, [])
  const getAllNotifications = () => {
    service.getAllNotifications().then(res => {

      if (res.status) {

        setNotificationData(res.data);
      } else {
        if (res.intlCode) {
          setNotificationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setNotificationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  // const getAllNotifications = () => {
  //   service.getAllNotifications().then(res => {
  //     if(res.status){
  //       const deptIds:string[]=res.data.map(notification=>notification.plant);
  //         departmentsDataService.getDepartmentNameByDeptIds(deptIds).then(res =>{
  //           let deptMapLocal=new Map();
  //           res.data.forEach(element => {
  //             if(!deptMapLocal.has(element.deptName)){
  //               deptMapLocal.set(element.deptName,element.deptName);
  //             }
  //           });
  //           setDeptMap(deptMapLocal);
  //         });
  //       setNotificationData(res.data);
  //     }else {
  //       if(res.intlCode){
  //         setNotificationData([]);
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     setNotificationData([]);
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // } 

  /**
   * 
   * @param notificationData 
   */
  const deleteNotification = (notificationData: NotificationDto) => {
    notificationData.isActive = notificationData.isActive ? false : true;
    service.activateOrDeactivateNotification(notificationData).then(res => {
      console.log(res);
      if (res.status) {
        getAllNotifications();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
  const updateNotification = (data: NotificationDto) => {
    service.updateNotification(data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllNotifications();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      // hideInSearch: true,
      // hideInForm: true,
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.plant.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant')
    },
    {
      title: 'Job Role',
      dataIndex: 'jobRole',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.jobRole.localeCompare(b.jobRole),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobRole')
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.jobDescription.localeCompare(b.jobDescription),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobDescription')
    },
    
    {
      title: 'Notification Date',
      dataIndex: 'notificationDate',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.notificationDate.localeCompare(b.notificationDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('notificationDate'),
      render: (value, record: NotificationDto) => {
        return moment(record.notificationDate).format('DD-MM-YYYY')
      }
    },
    {
      title: 'Planned Closing Date',
      dataIndex: 'plannedClosingDate',
      // hideInSearch: true,
      // hideInForm: true,
      // sorter: (a, b) => a.plannedClosingDate.localeCompare(b.plannedClosingDate),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.plannedClosingDate).unix() - moment(b.plannedClosingDate).unix(),
      // ...getColumnSearchProps('plannedClosingDate'),
      render: (value, record: NotificationDto) => {
        return moment(record.plannedClosingDate).format('DD-MM-YYYY')
      }
    },
    {
      title: 'Aging',
      key: 'plannedClosingDate',
      width: 100,
      align: 'left',
      sorter: (a, b) => (Math.floor((new Date(a.plannedClosingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.plannedClosingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.plannedClosingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
          props: {
            style: {
              background: Math.floor((new Date(record.plannedClosingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(record.plannedClosingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
            }
          },
        };
        return obj;
      }
    },
    {
      title: 'No Of Resources',
      dataIndex: 'resourceRequired',
      // hideInSearch: true,
      // hideInForm: true,
      width: '160px',
      responsive: ['sm'],
      sorter: (a, b) => a.resourceRequired.localeCompare(b.resourceRequired),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('resourceRequired')
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.skills.localeCompare(b.skills),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('skills')
    },
    {
      title: 'Year Of Experience',
      dataIndex: 'yearOfExperience',
      // hideInSearch: true,
      // hideInForm: true,
      width: '160px',
      responsive: ['sm'],
      sorter: (a, b) => a.yearOfExperience.localeCompare(b.yearOfExperience),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('yearOfExperience')
    },

    {
      title: ' Status',
      dataIndex: 'status',
      align: 'center',
      render: (status, rowData) => {
        console.log(rowData)
        return (<>
          {status == NotificationStatus.Open ? <Tag icon={<CheckCircleOutlined />} color="#87d169">{NotificationStatus.Open}</Tag> : status == NotificationStatus.Closed ? <Tag icon={<CloseCircleOutlined />} color="#f50">{NotificationStatus.Closed}</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f79707">{NotificationStatus.Cancelled}</Tag>}

        </>
        )
      },


      filters: [
        {
          text: NotificationStatus.Open,
          value: NotificationStatus.Open,
        },
        // {
        //   text: NotificationStatus.Hold,
        //   value: NotificationStatus.Hold,
        // },
        // {
        //   text: NotificationStatus.Inprogress,
        //   value: NotificationStatus.Inprogress,
        // },
        {
          text: NotificationStatus.Closed,
          value: NotificationStatus.Closed,
        },
        {
          text: NotificationStatus.Cancelled,
          value: NotificationStatus.Cancelled,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        console.log(record)

        return record.status === value;
      },

    },

    {
      title: `Action`,
      dataIndex: 'action',
      // hideInSearch: true,
      // hideInForm: true,
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Notifcation');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          {/* <Divider type="vertical" />
            <Popconfirm  onConfirm={e =>{deleteNotification(rowData);}}
              title={
              rowData.isActive
                ? 'Are you sure to Deactivate Notifications ?'
                :  'Are you sure to Activate Notifications  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm> */}
        </span>
      )
    }
  ];


  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text: string) => {
    switch (text) {
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Notifications</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/notifications-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        <Row gutter={40}>
          <Col>
            <Card title={'Total Notifications: ' + notificationData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          <Col>
            <Card title={'Not Crossed PCD'} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#38f438' }}></Card>
          </Col>
          <Col>
            <Card title={'Crossed PCD'} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f4646c' }}></Card>
          </Col>

        </Row>
        <br></br>
        <Table
          rowKey={record => record.notificationId}
          columns={columnsSkelton}
          dataSource={notificationData}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>

            <NotificationsForm key={Date.now()}
              updatenotificationType={updateNotification}
              data={slectedEmploymentStatusData}
              isUpdate={true}
              closeForm={closeDrawer}
            />

          </Card>
        </Drawer>
      </Card>
    </>
  );
}

export default NotificationsGrid;





