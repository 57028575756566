import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { HsnDto, TypeOfValueAddition4Dto, TypesOfValueAddition2Dto, Type1Dto, ValueAdditionThreeDto, ValueAdditionFiveDto, ProductDto, FoodTypesDto, FoodTypesDropDownData } from '@gtpl/shared-models/masters';
import { HsnService, Type3Service, TypeofValueAddition4Service, TypesOfValueAddition2Service, Type1Service, ProductService, FoodTypeService, TypeofValueAddition5Service } from '@gtpl/shared-services/masters';

import { Link, useHistory } from 'react-router-dom';

import './hsn-codes-form.css';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

/* eslint-disable-next-line */
export interface HsnCodesFormProps {
  hsnData: HsnDto;
  updateHsn: (hsnData: HsnDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function HsnCodesForm(
  props: HsnCodesFormProps
) {



  /* eslint-disable-next-line */


  const [form] = Form.useForm();
  let history = useHistory();
  const [value, setValue] = useState("");
  const service = new HsnService();
  const productService = new ProductService();
  const foodTypeService = new FoodTypeService();
  const [disable,setDisable]=useState<boolean>(false)
  const type1Service = new Type1Service();
  const type2Service = new TypesOfValueAddition2Service();
  const type3Service = new Type3Service();
  const type4Service = new TypeofValueAddition4Service();
  const type5Service = new TypeofValueAddition5Service();
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const [hsnData, setHsnData] = useState<HsnDto[]>([])
  const [productsData, setProductsData] = useState<ProductDto[]>([])
  const [foodData, setFoodData] = useState<FoodTypesDto[]>([])
  const [type1Data, setType1Data] = useState<Type1Dto[]>([])
  const [type2Data, setType2Data] = useState<TypesOfValueAddition2Dto[]>([])
  const [type3Data, setType3Data] = useState<ValueAdditionThreeDto[]>([])
  const [type4Data, setType4Data] = useState<TypeOfValueAddition4Dto[]>([])
  const [type5Data, setType5Data] = useState<ValueAdditionFiveDto[]>([])
  // const [selectedProduct, setSelectedProduct] = useState<number>(null)
  // const [selectedFood, setSelectedFood] = useState<string>(null)
  // const [selectedType1, setSelectedType1] = useState<string>(null)
  // const [selectedType2, setSelectedType2] = useState<string>(null)
  // const [selectedType3, setSelectedType3] = useState<string>(null)
  // const [selectedType4, setSelectedType4] = useState<string>(null);
  // const [selectedType5, setSelectedType5] = useState<string>(null);

  const [code, setCode] = useState('');

  useEffect(() => {
    getAllProducts(); getAllFoods(); getAllTypes1(); getAllTypes2(); getAllTypes3(); getAllTypes4(); getAllTypes5();
  }, [])



  const saveHsnCode = (hsnData: HsnDto) => {
    setDisable(true)
    hsnData.isActive = true;
    service.createHsn(hsnData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Hsn Code Created Successfully');
        history.push("/hsn-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllProducts = () => {   
    productService.getAllProducts(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setProductsData(res.data);
      } else {
        if (res.intlCode) {
          setProductsData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProductsData([]);
    })
  }
  const getAllFoods = () => {
    foodTypeService.getAllActiveFoodTypes().then(res => {
      if (res.status) {
        setFoodData(res.data);
      } else {
        if (res.intlCode) {
          setFoodData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setFoodData([]);
    })
  }
  const getAllTypes1 = () => {
    if (!props.isUpdate) {
      type1Service.getAllActiveValAddOneTypes().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    } else {
      type1Service.getAllTypes1().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    }
  }
  const getAllTypes2 = () => {
    if (!props.isUpdate) {
      type2Service.getAllActiveValAddTwoTypes().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    } else {
      type2Service.getAllTypesOfValueAddition2s().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    }
  }
  const getAllTypes3 = () => {
    if (!props.isUpdate) {
      type3Service.getAllAvtiveValAddThreeTypes().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    } else {
      type3Service.getAllTypesOfValueAddition3s().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    }
  }
  const getAllTypes4 = () => {
    if (!props.isUpdate) {
      type4Service.getAllActiveTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    } else {
      type4Service.getAllTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    }
  }
  const getAllTypes5 = () => {
    if (!props.isUpdate) {
      type5Service.getAllActiveTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    } else {
      type5Service.getAllTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    }
  }
  // const handleProduct = (value) => {
  //   const proarr = value.split('$@');
  //   console.log(proarr)
  //   console.log(`selected ${value}`);
  //   getSkucodeDefaultValue(value ? proarr[1] : '');
  //   setSelectedProduct(proarr[1]);
  // }

  const generateCode = () => {
    const foodTypeId = form.getFieldValue('foodTypeId');
    const valueAdditionOneId = form.getFieldValue('valueAdditionOneId');
    const valueAdditionTwoId = form.getFieldValue('valueAdditionTwoId');
    const valueAdditionThreeId = form.getFieldValue('valueAdditionThreeId');
    const valueAdditionFourId = form.getFieldValue('valueAdditionFourId');
    const valueAdditionFiveId = form.getFieldValue('valueAdditionFiveId');

    const selectedFood = (foodTypeId) ? foodData.find(food => food.foodTypeId.toString() == foodTypeId.toString()).foodTypeCode : '';
    const selectedVal1 = (valueAdditionOneId) ? type1Data.find(val1 => val1.valueAdditionOneId.toString() == valueAdditionOneId.toString()).code : '';
    const selectedVal2 = (valueAdditionTwoId) ? type2Data.find(val1 => val1.valueAdditionTwoId.toString() == valueAdditionTwoId.toString()).code : '';
    const selectedVal3 = (valueAdditionThreeId) ? type3Data.find(val1 => val1.valueAdditionThreeId.toString() == valueAdditionThreeId.toString()).code : '';
    const selectedVal4 = (valueAdditionFourId) ? type4Data.find(val1 => val1.valueAdditionFourId.toString() == valueAdditionFourId.toString()).code : '';
    const selectedVal5 = (valueAdditionFiveId) ? type5Data.find(val1 => val1.valueAdditionFiveId.toString() == valueAdditionFiveId.toString()).code : '';

    getVarcodeDefaultValue(`${foodTypeId ? selectedFood : ''}${valueAdditionOneId ? selectedVal1 : ''}${valueAdditionTwoId ? selectedVal2 : ''}${valueAdditionThreeId ? selectedVal3 : ''}${valueAdditionFourId ? selectedVal4 : ''}${valueAdditionFiveId ? selectedVal5 : ''}`);

  }
  // const handleFood = (value, row?) => {
  //   const selectedFood = foodData.find(food => food.foodTypeId.toString() == value.toString());
  //   getVarcodeDefaultValue(`${value ? selectedFood?.foodTypeCode : ''}${selectedType1 ? selectedType1 : ''}${selectedType2 ? selectedType2 : ''}${selectedType3 ? selectedType3 : ''}${selectedType4 ? selectedType4 : ''}${selectedType5 ? selectedType5 : ''}`);

  //   setSelectedFood(value ? selectedFood?.foodTypeCode : '');
  // }
  // const handleType1 = (value) => {
  //   const selectedVal1 = type1Data.find(val1 => val1.valueAdditionOneId.toString() == value.toString())
  //   getVarcodeDefaultValue(`${selectedFood ? selectedFood : ''}${value ? selectedVal1?.code : ''}${selectedType2 ? selectedType2 : ''}${selectedType3 ? selectedType3 : ''}${selectedType4 ? selectedType4 : ''}${selectedType5 ? selectedType5 : ''}`);
  //   setSelectedType1(value ? selectedVal1?.code : '');
  // }
  // const handleType2 = (value) => {
  //   const selectedVal2 = type2Data.find(val1 => val1.valueAdditionTwoId.toString() == value.toString())
  //   setSelectedType2(value ? selectedVal2?.code : '');
  //   getVarcodeDefaultValue(`${selectedFood ? selectedFood : ''}${selectedType1 ? selectedType1 : ''}${value ? selectedVal2?.code : ''}${selectedType3 ? selectedType3 : ''}${selectedType4 ? selectedType4 : ''}${selectedType5 ? selectedType5 : ''}`);
  // }
  // const handleType3 = (value) => {
  //   const selectedVal3 = type3Data.find(val1 => val1.valueAdditionThreeId.toString() == value.toString())
  //   setSelectedType3(value ? selectedVal3?.code : '');
  //   getVarcodeDefaultValue(`${selectedFood ? selectedFood : ''}${selectedType1 ? selectedType1 : ''}${selectedType2 ? selectedType2 : ''}${value ? selectedVal3?.code : ''}${selectedType4 ? selectedType4 : ''}${selectedType5 ? selectedType5 : ''}`);
  // }
  // const handleType4 = (value) => {
  //   const selectedVal4 = type4Data.find(val1 => val1.valueAdditionFourId.toString() == value.toString())
  //   getVarcodeDefaultValue(`${selectedFood ? selectedFood : ''}${selectedType1 ? selectedType1 : ''}${selectedType2 ? selectedType2 : ''}${selectedType3 ? selectedType3 : ''}${value ? selectedVal4?.code : ''}${selectedType5 ? selectedType5 : ''}`);
  //   setSelectedType4(value ? selectedVal4?.code : '');
  // }
  // const handleType5 = async (value) => {
  //   const selectedVal5 = type5Data.find(val1 => val1.valueAdditionFiveId.toString() == value.toString())
  //   getVarcodeDefaultValue(`${selectedFood ? selectedFood : ''}${selectedType1 ? selectedType1 : ''}${selectedType2 ? selectedType2 : ''}${selectedType3 ? selectedType3 : ''}${selectedType4 ? selectedType4 : ''}${value ? selectedVal5?.code : ''}`);
  //   setSelectedType5(value ? selectedVal5?.code : '');
  // }


  const handlehsnCode = (value) => {
    setCode(value);
  }
  const getSkucodeDefaultValue = (defaultCode: string) => {
    // if(code==''){
    form.setFieldsValue({
      skuCode: defaultCode,
    });
    // }
  }
  const handleVarCode = (value) => {
    setCode(value);
  }
  const getVarcodeDefaultValue = (defaultCode: string) => {
    // if(code==''){
    form.setFieldsValue({
      variantCode: (defaultCode).trim(),
    });
    // }
  }

  const saveData = (values: HsnDto) => {
    setDisable(false)
    // console.log(values);
    if (props.isUpdate) {
      props.updateHsn(values);
    } else {
      setDisable(false)
      saveHsnCode(values);
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>HSN Code</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={props.isUpdate == true ? "" : <Link to='/hsn-view' ><span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>  </Link>}>
      <Form
        layout="vertical"
        form={form}
        initialValues={props.hsnData}
        name="control-hooks"
        onFinish={saveData}
        autoComplete={"off"}
      >
        <Form.Item name="hsnCodeId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>

        <Form.Item style={{ display: 'none' }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={30}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="productId"
              label="Product Type:"
              rules={[
                {
                  required: true,
                  message: "Product Type is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Product Type"
                optionFilterProp="children"
                // onChange={handleProduct}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Food Type</Option>
                {productsData.map((product) => {
                  return <Option value={product.productId}>{`${product.productName}(${product.hsnCode})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="foodTypeId"
              label="Food Type:"
              rules={[
                {
                  required: true,
                  message: "Food Type is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Food Type"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Food Type</Option>
                {foodData.map((food) => {
                  return <Option value={food.foodTypeId}>{`${food.foodType} (${food.foodTypeCode})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="valueAdditionOneId"
              label="Value Addition One:"
              rules={[
                {
                  required: true,
                  message: "Value Addition One is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type1"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type1</Option>
                {type1Data.map((type1) => {
                  return <Option value={type1.valueAdditionOneId}>{`${type1.name}(${type1.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="valueAdditionTwoId"
              label="Value Addition Two:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Two is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type2"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type2</Option>
                {type2Data.map((type2) => {
                  return <Option value={type2.valueAdditionTwoId}>{`${type2.name} (${type2.code})`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="valueAdditionThreeId"
              label="Value Addition Three:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Three is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type3"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type3</Option>
                {type3Data.map((type3) => {
                  return <Option value={type3.valueAdditionThreeId}>{`${type3.name} (${type3.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="valueAdditionFourId"
              label="Value Addition Four:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Four is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type4"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type4</Option>
                {type4Data.map((type4) => {
                  return <Option value={type4.valueAdditionFourId}>{`${type4.name} (${type4.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="valueAdditionFiveId"
              label="Value Addition Five:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Five is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type5"
                optionFilterProp="children"
                onChange={generateCode}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type5</Option>
                {type5Data.map((type5) => {
                  return <Option value={type5.valueAdditionFiveId}>{`${type5.name} (${type5.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="variantCode"
              label="Variant Code:"
              rules={[
                {
                  required: true,
                  message: 'Varient Code is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                  message: `Don't Allow Spaces`
                }]}
            // initialValue={getVarcodeDefaultValue}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={20}>
          <Col span={5}>
            <Form.Item
              name="hsnCode"
              label="HSN Code"
              rules={[
                {
                  required: true,
                  message: 'HSN Code is required'
                },
              ]}
            >
              <Input onChange={handlehsnCode} />
            </Form.Item></Col>

        </Row>
        <Row>
          <Col style={{ textAlign: 'right' }} span={24}>
            <Button type="primary" disabled={disable} htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }


          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default HsnCodesForm;
