import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Space,Tabs, DatePicker, InputNumber, Select, Form, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined,RollbackOutlined ,UndoOutlined } from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import { Redirect } from 'react-router-dom';
import { Table } from "ant-table-extensions";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {ContainerStatusEnum,BlStatusEnum, EmployeeRolesEnum} from '@gtpl/shared-models/common-models';
import {ContainerStatusInfo,BlStatusInfo, ShipmentDetailsDto} from '@gtpl/shared-models/logistics';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { DestinationService } from '@gtpl/shared-services/masters';
import { SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
import './bl-status-grid.css';
import Link from 'antd/lib/typography/Link';
import { DestinationDto } from '@gtpl/shared-models/masters';
import {ShippmentDetailsForm} from '@gtpl/pages/logistics/logistics-components/shippment-details-form'
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'

/* eslint-disable-next-line */
export interface BlStatusGridProps {}

export function BlStatusGrid(props: BlStatusGridProps) {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isNewForm, setisNewForm] = useState<boolean>(true);
  const [object, setObject] = useState(null);
  const [viewOnly, setViewOnly] = useState(true);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const { TabPane } = Tabs;
  const [updateState, setUpdateState] = useState(false);
  const [coId1, setCoIdState1] = useState<number>(null);
  const [coId2, setCoIdState2] = useState<number>(null);
  const [coId3, setCoIdState3] = useState<number>(null);
  const [coId4, setCoIdState4] = useState<number>(null);
  const [coId5, setCoIdState5] = useState<number>(null);
  const [coId6, setCoIdState6] = useState<number>(null);
  const [coId7, setCoIdState7] = useState<number>(null);

  const [containerData, setContainerData] =useState([]);
  const [disbleInputs,setDisableInputs] = useState<boolean>(false)
  const [isModalVisible,setIsModalVisible] = useState<boolean>(false)
  const [containerNo,setContainerNo] = useState<string>(undefined)
  const [containerId,setContainerId] = useState<number>(undefined)
  const [filterData, setFilterData] =useState<BlStatusInfo[]>([]);
  const [destinationData, setDestinationData] =useState<DestinationDto[]>([]);
  const service = new ContainerRegisterService;
  const destinationService = new DestinationService;
  const [destinationDetails, setDestinationDetails] = useState<string[]>([]);
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetailsDto>(undefined)
  const containerRegisterService = new ContainerRegisterService;
  const text = <span>Set Previous Status</span>
  const [actionButton,setActionButton] = useState<string>('')
  
  const [saleId, setSaleId] = useState(0);

  useEffect(()=> {
    if( (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER)){
      setDisableInputs(true);
    }
  },[])

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log(saleOrderId);
    setIsModalVisible(true);
  }


  const searchData = () => {
    
    const destination = form.getFieldValue('destination');
    console.log(destination);
    let selectedData = filterData;
    if(destination){
      selectedData = selectedData.filter(item => item.destination === destination)
    }
    setContainerData(selectedData);
  }

  useEffect(()=> {
    getFiltersDropdownData(filterData);
  },[filterData])




  const getFiltersDropdownData = (filterData) => {
    let etdData:any[] =[];
    let destinationData:any[] =[];
    filterData.forEach(element => {
      if(element.destination){
        destinationData.push(element.destination)
      }
    });
    setDestinationDetails(destinationData)
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  
  

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  useEffect(()=>{
    getAllBlStatus();
    getAllDestinations();
  },[])

  const getAllDestinations = () =>{
    destinationService.getAllActiveDestination().then(res=>{
      if(res.status){
        setDestinationData(res.data);
        console.log(res.data);
      }else {
        if (res.intlCode) {
          setDestinationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDestinationData([]);
    })
  }
  const getAllBlStatus = () =>{
    service.getAllContainerBlStatusInfo().then(res=>{
      if(res.status){
        setContainerData(res.data);
        setFilterData(res.data);
        console.log(res.data);
      }else {
        if (res.intlCode) {
          setContainerData([]);
          setFilterData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setContainerData([]);
      setFilterData([]);
    })
  }
  const notReceived = (values) => {
    service.notReceived(values).then(res => {
        if (!res.status) {
          throw new Error(res.internalMessage);
        }else{
          getAllBlStatus();
          AlertMessages.getSuccessMessage(res.internalMessage);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
    })
}
  const draftReceived = (values) => {
    service.draftReceived(values).then(res => {
        if (!res.status) {
          throw new Error(res.internalMessage);
        }else{
          getAllBlStatus();
          AlertMessages.getSuccessMessage(res.internalMessage);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
    })
}
const oblReceived = (values) => {
  // etdForm.validateFields().then(res => {
  //   console.log(res);
  console.log(values);
  service.oblReceived(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      }else{
        getAllBlStatus();
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
  })
// })
}
const updateTrackingNo = (value, containerNo) => {
    setContainerNo(containerNo);
    containerRegisterService.getShipmentDetails({containerId:value}).then((res) => {
      if(res.status){
        console.log(res.data);
        setShipmentDetails(res.data);
        setContainerId(value);
      }
      else{
        setShipmentDetails(undefined);
        setContainerId(value);
      }
      setShipmentDetails(undefined)
    })
   
}
const receivedAndShared = (values) => {
  service.receivedAndShared(values).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      }else{
        getAllBlStatus();
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
  })
}
const updateBlNo = (e,containerId:number,record:BlStatusInfo) => {
  console.log(e.value);
  console.log(containerId);
  record.blNo = e;
  service.updateBlNo({blNo:e.value,containerId:containerId}).then(res => {
    if (res.status) {
      getAllBlStatus();
      // AlertMessages.getSuccessMessage(res.internalMessage);
    }else{
      getAllBlStatus();
      throw new Error(res.internalMessage);
    }
}).catch(err => {
    AlertMessages.getErrorMessage(err.message);
})
  
}
const updateEtd = (e,containerId:number,type:string) => {
  console.log(e);
  service.updateEtd({etd:e,containerId:containerId,type:type}).then(res => {
      if (res.status) {
        getAllBlStatus();
        AlertMessages.getSuccessMessage(res.internalMessage);
      }else{
        getAllBlStatus();
        throw new Error(res.internalMessage);
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
  })
}

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata)
  }

  const setCoId1 = (rowdata) => {
    setUpdateState(true);
    setCoIdState1(rowdata)
  }
  const setCoId2 = (rowdata) => {
    setUpdateState(true);
    setCoIdState2(rowdata)
  }
  const setCoId3 = (rowdata) => {
    setCoIdState3(rowdata)
  }
  const setCoId4 = (rowdata) => {
    setUpdateState(true);
    setCoIdState4(rowdata)
  }
  const setCoId5 = (rowdata) => {
    setUpdateState(true);
    setCoIdState5(rowdata)
  }
  const setCoId6 = (rowdata) => {
    setUpdateState(true);
    setCoIdState6(rowdata)
  }


  const [form] = Form.useForm();
  const [etdForm] = Form.useForm();
  const { Option } = Select;
  const onFocus = () => {
  }
const tableColumns = (key) => {

  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '60px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNo',
    //   width: 150,
    //   ...getColumnSearchProps('saleOrderNo'),
    //   sorter: (a, b) => a.saleOrder.saleOrderId - b.saleOrder.saleOrderId,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrder.saleOrderId)}>{record.saleOrder.saleOrderNumber}</Link>, 
    // },
    {
      title: 'Customer PO',
      dataIndex: 'customPoNo',
      width: 100, 
      ...getColumnSearchProps('customPoNo'),
      sorter: (a, b) => a.customPoNo.localeCompare(b.customPoNo),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrder.saleOrderId)}>{record.customPoNo}</Link>, 

    },
    {
      title: 'Buyer PO',
      dataIndex: 'buyerPoNumber',
      width:185,
      align:'left',
      ...getColumnSearchProps('buyerPoNumber'),
      render: (text, record) => { return  record.buyerPoNumber },
      // sorter: (a, b) => a.buyerPoNumber.localCompare(b.buyerPoNumber),
      sorter: (a, b) => {
        a = a.buyerPoNumber || '';
        b = b.buyerPoNumber || '';
        return a.localeCompare(b);
    },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Container Number',
      dataIndex: 'containerNo', 
      width: 150,
      sorter: (a, b) => a.containerNo?.localeCompare(b.containerNo),
      sortDirections: ['descend', 'ascend'],  
      ...getColumnSearchProps('containerNo'),
      render: (value, record: ContainerStatusInfo, index) => {
        return (record.containerNo) ? record.containerNo : ' - ';
      },
    },
    {
      title: 'BL Status',
      dataIndex: 'blStatus',  
      width: 150, 
      sorter: (a, b) => a.blStatus.localeCompare(b.blStatus),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('blStatus')
    },
  ]
  const etdColumns: ColumnProps<any>[] = [
    {
      title: 'ETD from Port',
      key: 'etd',
      dataIndex: 'etd',
      width:185,
      // render: (text, record) => { return record.etd != null ? moment(record.etd).format('YYYY-MM-DD') : "-" },
      render: (text, record,index) => ( 
        <Form
        layout="vertical"
        form={etdForm}
        name="control-hooks"
        // onFinish={saveData}
      >
        <Form.Item
              name={"etd"+index}
              rules={[
                {
                  required: true, message: 'Missing ETD',
                },
              ]}
              initialValue={record.etd===null?null:moment(record.etd)}
              style={{ margin: 0 }}
            >
        <DatePicker disabled={disbleInputs} name="etd" onChange={(e)=>updateEtd(e,record.containerId,"etd")} style={{ width: '88%' }} format={'YYYY-MM-DD'} value={record.etd===null?null:moment(record.etd)}/>
        </Form.Item>
        </Form>

      ),
    }, 
  ]
  const inputColumns: ColumnProps<any>[] = [
    {
      title: 'BL Number',
      key: 'blNo',
      width:185,
      render: (text, record,index) => ( 
        <Form
        layout="vertical"
        form={etdForm}
        name="control-hooks"
        // onFinish={saveData}
      >
        <Form.Item
              name={"blNo"+index}
              rules={[
                {
                  required: true, message: 'Missing BL Number',
                },
              ]}
              initialValue={record.blNo===null?"":record.blNo}
              style={{ margin: 0 }}
            >
        <Input type="text" disabled={disbleInputs} onInput={(e)=>updateBlNo(e.target,record.containerId,record)} defaultValue ={record.blNo===null?"":record.blNo} /></Form.Item>
        </Form>
      ),
    }, 
  ]
  const blNoColumns: ColumnProps<any>[] = [
    {
      title: 'BL Number',
      key: 'blNo',
      dataIndex: 'blNo',
      width:185,
      ...getColumnSearchProps('blNo'),
      render: (text, record) => { return record.blNo != null ? record.blNo : "-" },
      sorter: (a, b) => {
        a = a.blNo || '';
        b = b.blNo || '';
        return a.localeCompare(b);
    },
    sortDirections: ['descend', 'ascend'],

    }, 
  ]
  const oblEtdColumns: ColumnProps<any>[] = [
    {
      title: 'ETD',
      dataIndex: 'oblEtd',
      key: 'oblEtd',
      width:185,
      render: (text, record) => { return record.etd != null ? moment(record.etd).format('YYYY-MM-DD') : "-" },
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record,index) => ( 
      //   <DatePicker name="oblEtd" onChange={(e)=>updateEtd(e,record.containerId,"oblEtd")}  style={{ width: '88%' }} format={'YYYY-MM-DD'} value={record.oblEtd===null?null:moment(record.oblEtd)}/>
      // ),
    }, 
  ]
  const dateColumns: ColumnProps<any>[] = [
    {
      title: 'ETD',
      dataIndex: 'etd',
      width:185,
      align:'left',
      render: (text, record) => { return  record.etd===null?"-":moment(record.etd).format('YYYY-MM-DD') },
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'OBL ETD',
      dataIndex: 'oblEtd',
      width:185,
      align:'left',
      render: (text, record) => { return record.oblEtd===null?"-": moment(record.oblEtd).format('YYYY-MM-DD') },
      sorter: (a, b) => moment(a.oblEtd).unix() - moment(b.oblEtd).unix(),
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const etaColumns: ColumnProps<any>[] = [
    {
      title: 'ETD',
      dataIndex: 'etd',
      width:185,
      align:'left',
      render: (text, record) => { return  record.etd===null?"-":moment(record.etd).format('YYYY-MM-DD') },
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      sortDirections: ['descend', 'ascend'],
    }
  ]
  const poColumns: ColumnProps<any>[] = [
    {
      title: 'Customer PO',
      dataIndex: 'customPoNo',
      width:185,
      align:'left',
      ...getColumnSearchProps('customPoNo'),
      render: (text, record) => { return  record.customPoNo },
      // sorter: (a, b) => a.customPoNo.localCompare(b.customPoNo),
      sorter: (a, b) => {
        a = a.customPoNo || '';
        b = b.customPoNo || '';
        return a.localeCompare(b);
    },
      sortDirections: ['descend', 'ascend'],
    },
   
  ]
  const destinationColumns: ColumnProps<any>[] = [
    {
      title: 'Destination',
      dataIndex: 'destinationName',
      width:185,
      align:'left',
      ...getColumnSearchProps('destinationName'),
      render: (text, record) => { return  record.destinationName },
      // sorter: (a, b) => a.destinationName.localCompare(b.destinationName),
      sorter: (a, b) => {
        a = a.destinationName || '';
        b = b.destinationName || '';
        return a.localeCompare(b);
    },
      sortDirections: ['descend', 'ascend'],
    }
  ]
  const actionColumns: ColumnProps<any>[] = [
    
    {
      title: 'Action',
      dataIndex: 'blStatus',
      width: 200,
      // responsive: ['lg'],
      render: (blStatus, rowData, record) => (
        <>
          {blStatus==BlStatusEnum.NOT_RECEIVED?
          <span><Button style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}
        onClick={() => {draftReceived(rowData);}}>SET TO DRAFT</Button>
        </span>:
        blStatus==BlStatusEnum.DRAFT_RECEIVED? 
        <span><Button style={{backgroundColor:'#722ed1',color:"black", fontWeight: 'bold' }}
        onClick={() => {oblReceived(rowData);}}>SET TO OBL</Button>
        <Divider type="vertical" /><Tooltip placement="top" title={text}><Button  onClick={() => {notReceived(rowData);}}><RollbackOutlined /></Button></Tooltip></span>:
        blStatus==BlStatusEnum.OBL_RECEIVED?
        <span>
          { rowData.trackingNo&&
          <Button style={{backgroundColor:'#faad14',color:"black", fontWeight: 'bold' }}
         onClick={() => {receivedAndShared(rowData);}}>SET TO RECEIVED AND SHARED</Button>}
        <Button style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}
         onClick={() => {updateTrackingNo(rowData.containerId, rowData.containerNo); setActionButton('oblReceived')}}>UPDATE TRACK NO</Button>
         <Divider type="vertical" /><Tooltip placement="top" title={text}><Button onClick={() => {draftReceived(rowData);}}><RollbackOutlined /></Button></Tooltip></span>:
        blStatus==BlStatusEnum.RECEIVED_AND_SHARED? <span><Button style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}
        onClick={() => {updateTrackingNo(rowData.containerId, rowData.containerNo); setActionButton('received')}}>UPDATE DETAILS</Button>
      </span>:""}
        <Divider type="vertical" />
   
        
        {/* {containerStatus=="NOT_READY"?
<span>
        {(object) ?<Redirect
          to={{
            pathname: "/container-register-form",
            state: object
          }}
        />:null}<EditOutlined className={'editSamplTypeIcon'} type="edit" name="edit"
          onClick={() => {
            
              setUpdatevalue(rowData);
            
          }}
          style={{ color: '#1890ff', fontSize: '14px' }}
        />
      </span>:""} */}

        </>

      ),
      },
  ]  
  const updateShippingDetails: ColumnProps<any>[] = [
    {
      title: 'Action',
      dataIndex: 'updateShippingDetails',
      width: 200,
      render: (blStatus, rowData, record) => (
        <>
        {/* {rowData.trackingNo === null?  */}
          <Button style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}
          onClick={() => {updateTrackingNo(rowData.containerId, rowData.containerNo);}}>UPDATE TRACK NO</Button>
        {/* :"-"} */}
        </>

      ),
      },
  ]
  
  if(key === "2"){
    return [...columnsSkelton1, ...etdColumns,...inputColumns,...actionColumns];
  }
  else if(key === "3"){
    return [...columnsSkelton1,...oblEtdColumns,...blNoColumns,...actionColumns];
  }
  else if(key == "4"){
    return [...columnsSkelton1,...poColumns,...blNoColumns,...destinationColumns,...etaColumns,...actionColumns];
  }
  else {
    return [...columnsSkelton1,...dateColumns,...actionColumns];
  }
}

const headers =[
  {label:'Sale Order No' , key:'saleOrderNo'},
  {label:'Container No',key:'containerNo'},
  {label:'BL Status',key:'blStatus'},
  {label:'Customer PO No',key:'customPoNo'},
  {labe:'BL Number',key:'blNo'},
  {label:'Destination',key:'destination'},
  {label:'ETA',key:'etd'}

]

const exportedData = [];
containerData.filter(rec=> rec.blStatus== BlStatusEnum.RECEIVED_AND_SHARED).forEach(element => {
    Object.entries(element).map(count => {
        headers.push({label: count[0], key: count[0]});
        element[count[0]] = count[1];
      })
      exportedData.push(element);
     });


     const handleCancel = () => {
      setIsModalVisible(false);
      getAllBlStatus();
    };
 
  
const onReset = () => {
  // form.resetFields();
  form.resetFields();
  setContainerData(filterData);
  // window.location.reload();
};

  return (
    <>
   {containerId > 0 && actionButton == 'oblReceived' ? <Redirect to={{ pathname: "/shippment-details-form", state: { containerId: containerId, containerNo: containerNo, updateState:shipmentDetails}}} />:null}
   {containerId > 0 && actionButton == 'received' ? <Redirect to={{ pathname: "/shippment-details-received-tab", state: { containerId: containerId, containerNo: containerNo, updateState:shipmentDetails}}} />:null}
 
    <Card title={<span style={{color:'white'}}>Bill of Lading</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#1890ff', border: 0 }}>
    
     <br></br>
          <div className="card-container">
          <Tabs type={'card'} tabPosition={'top'}>
          {
             ((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD)) &&
          <TabPane 
          // tab="Open Orders"
           key="1"
           tab={<span style={{ color: "#f5222d" }}>{'Not Received: ' + (containerData.filter(el => el.blStatus== BlStatusEnum.NOT_RECEIVED).length)}</span>}
           >
          <Table
          rowKey={record => record.containerId}
          columns={tableColumns("1")}
          dataSource={containerData.filter(rec=> rec.blStatus== BlStatusEnum.NOT_RECEIVED)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          // scroll={{ y:500 }}
          size="small"
          bordered/>
          {/* } */}
          </TabPane>
          }
          {
            ((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD)) &&
          <TabPane 
          // tab="In-Procurement" 
          key="2" 
          tab={<span style={{ color: "#52c41a" }}>{'Draft Received: ' + (containerData.filter(el => el.blStatus== BlStatusEnum.DRAFT_RECEIVED).length)}</span>}
            >
          <Table
          rowKey={record => record.containerId}
          columns={tableColumns("2")}
          dataSource={containerData.filter(rec=> rec.blStatus== BlStatusEnum.DRAFT_RECEIVED)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          scroll={{ x:true }}
          size="small"
          bordered/>
          </TabPane>
        }
        {
             ((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.DOCUMENTATION_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_AND_WAREHOUSE_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_EXECUTIVE) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.LOGISTICS_HEAD)) &&
          <TabPane 
          // tab="Work in Progress"
           key="3"
           tab={<span style={{ color: "#722ed1" }}>{'OBL Received: ' + (containerData.filter(el => el.blStatus== BlStatusEnum.OBL_RECEIVED).length)}</span>}
           >
          <Table
          rowKey={record => record.containerId}
          columns={tableColumns("3")}
          dataSource={containerData.filter(rec=> rec.blStatus== BlStatusEnum.OBL_RECEIVED)}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          scroll={{ x:true }}
          size="small"
          bordered/>
          </TabPane>
        }
          <TabPane 
          // tab="Work in Progress"
           key="4"
           tab={<span style={{ color: "#faad14" }}>{'Received and Shared: ' + (containerData.filter(el => el.blStatus== BlStatusEnum.RECEIVED_AND_SHARED).length)}</span>}
           >

                <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                    <Row gutter={[24, 24]}>
                    {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                      <Form.Item name="etaFromDate" label="ETA From Date" initialValue={moment()}>
                        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                          showToday={true} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                      <Form.Item name="etaToDate" label="ETA To Date" initialValue={moment()}>
                        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                          showToday={true} />
                      </Form.Item>

                    </Col> */}
                      
                      <Col   xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                      <Form.Item
                        name="destination"
                        label="Destination"
                        rules={[
                          {
                            required: false, message: 'Select Destination',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          placeholder="Select destination"
                          // onChange={handledestination}
                          allowClear
                        >
                          {destinationData.map(destinationDropDown => {
                            return <Option value={destinationDropDown.destinationName}>{destinationDropDown.destinationName}</Option>
                          })}
                        </Select>
                      </Form.Item>
                      </Col>
              
                      <Col style={{marginTop:30}} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                          <Button
                            type="primary"
                            icon={<SearchOutlined />}
                            // size="small"
                            block onClick={()=>searchData()}
                            style={{  marginRight: 2, width: 100 }}
                          >
                              Search
                          </Button>
                          <Button 
                            icon={<UndoOutlined />}
                            onClick={() => onReset()}
                           >
                              Reset
                          </Button>
                     </Col>
                     <Col  style={{marginTop:'30px'}}  xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                
                        <Button 
                        >
                           <CSVLink filename={"bill-of-lading-received&shared-data.csv"} data={exportedData} headers={headers} >
                            Get Excel
                          </CSVLink>
                      </Button>
                  </Col>
                      </Row>
                      </Form>
          <Table
          rowKey={record => record.containerId}
          columns={tableColumns("4")}
          dataSource={containerData.filter(rec=> rec.blStatus== BlStatusEnum.RECEIVED_AND_SHARED)}
         
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          scroll={{ y:500 }}
          size="small"
          bordered/>
          </TabPane>
          </Tabs>
          {isModalVisible ? 
            <>
                <Modal
                  className='so-info-view'
                  key={'modal' + Date.now()}
                  width={'80%'}
                  style={{ top: 30, alignContent: 'center' }}
                  visible={isModalVisible}
                  title={<React.Fragment>
                  </React.Fragment>}
                  onCancel={handleCancel}
                  footer={[
                    
                  ]}

                  >
                     { saleId ? <SoInfoViewPages saleOrderId={saleId} screen="logistics" /> : ''}
              </Modal></>
          :""}
          </div>
     </Card>
     </>
  );
        }

export default BlStatusGrid;
