import React, { useEffect, useRef, useState } from 'react';
import BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeLeavesService } from '@gtpl/shared-services/hrms';

const localizer = momentLocalizer(moment);
moment.locale('en-GB');

export function HolidayCalander(){
    const [eventdata,setEventdata]=useState<any[]>([]);
  const service = new EmployeeLeavesService();
  const eventss = eventdata.map(i =>{
    return {title : i.title,start : moment(i.start) ,end:moment(i.end)}
  })
  console.log(eventss)

  useEffect(() =>{
    getallevncalnderdetails();
},[])


  const getallevncalnderdetails = () =>{
   service.HolidayCalander().then(res => {
        if (res.status) {
            setEventdata(res.data);
        } else {
          if (res.intlCode) {
            setEventdata([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setEventdata([]);
      })

}
return (
    <div className="App">
        <div style={{ height: 700 }}>
            <Calendar
                events={eventss}
                startAccessor="start"
                endAccessor="end"
                defaultDate={moment().toDate()}
                localizer={localizer}
            />
        </div>
    </div>
)


}
export default HolidayCalander;