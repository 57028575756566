import React, { useEffect, useRef, useState } from 'react';
import { Table, Input, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { InterviewService } from '@gtpl/shared-services/hrms';

import { ProdInvResponseModel } from '@gtpl/shared-models/production-management';
import { DtoOfRecruitmentDashboard } from '@gtpl/shared-models/hrms';



export interface RTableProps { }
export function RTable(ProdInvResponseModel: RTableProps) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [recruitmentInfo, setRecruitmentInfo] = useState<any[]>([]);

  const service = new InterviewService();
  const [services, setServices] = useState<DtoOfRecruitmentDashboard[]>([])



  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     * 
     */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => { getRecruitmentData(); }, [])


  const getRecruitmentData = () => {
    service.getRecruitmentData().then((res) => {

      if (res.status) {
        setServices(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setServices([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
          title: '#',
          key: 'sno',
          width: '30px',
          responsive: ['sm'],
          render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',

    },
    {
      title: 'Resource required',
      dataIndex: 'resourceRequired',
    },
    {
      title: 'Total profile Assigned',
      dataIndex: 'totalProfileAssigned',
    },

    {
      title: 'Total Select',
      dataIndex: 'totalSelect',
    },
  ];
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  // const onChange = (pagination, filters, sorter, extra) => {
  //     console.log('params', pagination, filters, sorter, extra);
  // }

  return (
    <Table

      rowKey={record => record.recruitementId}
      columns={columnsSkelton}
      dataSource={services}
      scroll={{ x: true }}
      //  onChange={onChange}
      pagination={false}
      size='small'
      bordered />

  );
}
export default RTable;
