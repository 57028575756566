import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, DatePicker, Alert, } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-code.dto";
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from "moment";
import { AssetsService } from 'apps/services/asset-management/src/app/open-assets/open-assets.service';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { Excel } from 'antd-table-saveas-excel';
import { EmployeeService } from "libs/shared-services/masters/src/lib/employee-details.service";
// import './pages-asset-management-asset-management-components-assets-repair-log.css';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AssetByAssetCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { VariantService } from 'libs/shared-services/masters/src/lib/shared-services-masters'
const { Option } = Select;
import Highlighter from 'react-highlight-words';
import { AssetTypeEnum } from "libs/shared-models/common-models/src/lib/enum/asset-type.enum";

export interface EmployeeInactiveReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const EmployeeInactiveReport = (props: EmployeeInactiveReportProps) => {
  const [page, setPage] = React.useState(1);
  const onSearch = (value: string) => console.log(value);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [assetsData, setAssetsData] = useState<any[]>([])
  const [inActive, setInactive] = useState<any[]>([]);
  const service = new EmployeeService();
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const employeeService = new EmployeeService();
  const assignmentservice = new AssetAssignmentService();
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const inActiveService = new VariantService();
  const [length, setLength] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [pageSize, setPageSize] = useState<number>(null);


  let diff = 0;
  let date;
  let todate;

  useEffect(() => {
    //getAllData();
    getInactiveReportDropDown();
    getAllPlants();
    getItemCategory();
    getDepartments();
    getInactiveReport();
    getEmployeeData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations();
  }, [])

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getEmployeeData = () => {
    employeeService.getAllInactiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)     
      }
    })

  }

  const getInactiveReportDropDown = () => {
    const req = new AssetByAssetCode();
    req.plantId = Number(localStorage.getItem('unit_id'));
    assignmentservice.getInactiveReportDropDown(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getInactiveReport = () => {
    const req = new AssetByAssetCode();
    if (form.getFieldValue('assetsCode') !== undefined) {
      req.assetsCode = form.getFieldValue('assetsCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeName') !== undefined) {
      req.employeeId = form.getFieldValue('employeeName')
    }
    if (form.getFieldValue('aging') !== undefined) {
      req.employeeId = form.getFieldValue('aging')
    }
    assignmentservice.getInactiveReport(req).then(res => {
      if (res.status) {
        setInactive(res.data);
        setLength(res.data.length);
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const searchHandler = () => {

    getInactiveReport();

  }



  const resetHandler = () => {
    form.resetFields();
    getInactiveReport();
  }

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: "20px",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: "Plant",
      dataIndex: "plant",
      width: 30,
      align: "left",
      ...getColumnSearchProps('plant'),
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      }

    },
    {
      title: "Location",
      dataIndex: "location",
      width: 30,
      align: "left",
      ...getColumnSearchProps('location'),
      render: (text, record) => {
        return (
          <>
            {record.location ? record.location : '-'}
          </>
        )
      }

    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      width: 30,
      align: "left",

      render: (text, record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      }
    },
    {
      title: "Asset Category",
      dataIndex: "assetCategory",
      width: 50,
      align: "left",
      sorter: (a, b) => a.assetCategory?.localeCompare(b.assetCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCategory'),
      render: (text, record) => {
        return (
          <>
            {record.assetCategory ? record.assetCategory : '-'}
          </>
        )
      }
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      width: 50,
      align: "left",
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName'),
      render: (text, record) => {
        return (
          <>
            {record.itemName ? record.itemName : '-'}
          </>
        )
      }
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      width: 30,
      align: "left",
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return (
          <>
            {record.assetsCode ? record.assetsCode : '-'}
          </>
        )
      }
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      width: 30,
      align: "left",
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName'),
      render: (text, record) => {
        return (
          <>
            {record.employeeName ? record.employeeName : '-'}
          </>
        )
      }
    },
    {
      title: "Mobile Number",
      dataIndex: "employeePhNum",
      width: 30,
      align: "right",
      render: (text, record) => {
        return (
          <>
            {record.employeePhNum ? record.employeePhNum : '-'}
          </>
        )
      }
    },
    {
      title: "Department",
      dataIndex: "department",
      width: 30,
      align: "right",
      sorter: (a, b) => a.department?.localeCompare(b.department),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('department'),
      render: (text, record) => {
        return (
          <>
            {record.department ? record.department : '-'}
          </>
        )
      }
    },


    {
      title: "Employee Aging",
      dataIndex: "aging",
      width: 30,
      align: "right",
      sorter: (a, b) => a.aging?.localeCompare(b.aging),
      sortDirections: ['descend', 'ascend'],
      //  render:(text,record) => {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'))
      //   date = moment(moment(record.employeeExitDate).format('YYYY-MM-DD'))
      //   diff = (todate - date) / 864e5
      //   return (
      //     <>
      //       {record.employeeExitDate ? diff : '-'}
      //     </>
      //   )
      // }

    },
  ];


  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const exportedData = [];
  const excelData = inActive
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Plant', dataIndex: 'plant' },
    { title: 'Location', dataIndex: 'location' },
    { title: 'Asset Type', dataIndex: 'assetType' },
    { title: 'Asset Category', dataIndex: 'assetCategory' },
    { title: 'Asset Name', dataIndex: 'itemName' },
    { title: 'Asset Code', dataIndex: 'assetsCode' },
    { title: 'Employee name', dataIndex: 'employeeName' },
    { title: 'Mobile Number ', dataIndex: 'employeePhNum', render: (text, record) => { return record.employeePhNum ? record.employeePhNum : '-' } },
    { title: 'Department', dataIndex: 'department', render: (text, record) => { return record.department ? record.department : '-' } },
    { title: ' Employee Aging', dataIndex: 'aging' },
    // {title:'Cost ofAsset',dataIndex:'assetCost'},

  ];
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('CheckIn-report')
      .addColumns(data)
      .addDataSource(inActive, { str2num: true })
      .saveAs('Employee-Inactive Report.xlsx');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Employee-Inactive Report</span>}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={inActive.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
    >
      <br /><br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={45}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetsData.map((assets) => {
                  return <Option key={assets.assets_code} value={assets.asset_id}>{`${assets.assets_name} -- ${assets.assets_code}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                defaultValue={'all'}
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={'all'}>All</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Plant' name='plantId' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'all'}
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Department' name='department'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'all'}
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col span={5}>
            <Form.Item label='Employee Name' name='employeeName'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'all'}
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Aging' name='aging'>
              <Select
                placeholder='Aging'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='0-30' value='0-30'>0-30</Option>
                <Option key='31-60' value='31-60'>31-60</Option>
                <Option key='61-100' value='61-100'>61-100 </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'all'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value='all'>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block
              onClick={() => searchHandler()}
            // style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>

            <Button style={{ margin: '0 14px' }} icon={<UndoOutlined />} onClick={resetHandler}>
              Reset
            </Button>
            <Button style={{ margin: '0 14px' }} >Total no of Records:{length}
            </Button>
          </Col> */}
          <Col span={24} style={{ textAlign: 'right' }} >
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}> Search
            </Button>
            <Button style={{ margin: '0 14px' }} icon={<UndoOutlined />} onClick={resetHandler}>Reset</Button>
            <Button style={{ margin: '0 14px' }} >Total no of Records:{length}
            </Button>
          </Col>
          <br></br>
        </Row>
      </Form>

      {inActive.length > 0 ? (<>
        <Table
          columns={columns}
          scroll={{ x: 1500, y: 500 }}
          dataSource={inActive}
          // // rowClassName={(record) => record.checkOut == null ? 'highlighted-row': ''}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
        />
      </>) : (
        <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
      )}

    </Card>

  );
};
export default EmployeeInactiveReport;
