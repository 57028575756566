import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import {RolesDto} from '@gtpl/shared-models/masters';
import {RolesService} from '@gtpl/shared-services/masters'

import './roles-form.css';

/* eslint-disable-next-line */
export interface RolesFormProps {
roleData:RolesDto;
updateRole:(role:RolesDto)=>void;
isUpdate:boolean;
closeForm: () => void;
}

export function RolesForm(props: RolesFormProps) {

  const [form] = Form.useForm();
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  const roleDataService=new RolesService()

  const createRole=(roleData:RolesDto)=>{
    setDisable(true)
    roleDataService.createRole(roleData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Role Created Successfully');
        history.push("/roles-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    console.log('');
    form.resetFields();
    
  }

  const saveData = (values: RolesDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values)
      props.updateRole(values);
    }else{
      setDisable(false)
      console.log(values)
      createRole(values);
    }
  
  };


  return (


    <Card size="small" title={<span style={{color:'white'}}>Roles</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/roles-view' >{(props.isUpdate===false) && <span style={{color:'white'}} ><Button className='panel_button' > View </Button> </span> }</Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.roleData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="roleId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row gutter={24}>
        
        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
            <Form.Item
                name="roleName"
                label="Role Name"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Role name.",max:18
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/_@ ]*$/,
                    message: `Should contain only alphabets and numbers.`
                  },
                  {
          
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
      </Row>
     
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary"disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      
            
        </Col>
      </Form>
    </Card>
  );
}

   

export default RolesForm;
