import React, { useEffect, useState } from 'react';
import {Button, Card, Col, DatePicker, Form, Input, Row, Select} from 'antd';
import './pages-asset-management-asset-management-components-location-audit-form.css';
import {Link, useHistory} from 'react-router-dom';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetLocationService } from "@gtpl/shared-services/asset-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { LocationById } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-id.dto';
import {LocationAuditService} from 'libs/shared-services/asset-management/src/lib/location-audit';
import {LocationAssetListGrid} from '../../../location-audit-grid/src/lib/location-asset-list';

const {Option}  = Select;

/* eslint-disable-next-line */
export interface LocationAuditFormProps {}

export function LocationAuditForm(
  props: LocationAuditFormProps
) {
  const [form] = Form.useForm();
  const [audittypePlanned,setAudittypePlanned] = useState<boolean>(false)
  const [audittypeUnPlanned,setAudittypeUnPlanned] = useState<boolean>(false)
  const [locationData, setLocationData] = useState<any[]>([]);
  const locationService = new AssetLocationService()
  const plantId = Number(localStorage.getItem('unit_id'));
  const [locationid,setLocationid] = useState<any>()
  const [assetView,setAssetView] = useState<boolean>(false)
  const history = useHistory();


  const service = new LocationAuditService();


  useEffect(() => {
    if(locationid){
    getAssetsCount(locationid)
    }
    setAssetView(false);
  },[locationid])

  useEffect(() => {
    getLocation()
  },[])


  const onauditType = (val) => {
    if(val == 'Planned'){
      setAudittypePlanned(true)
      setAudittypeUnPlanned(false)

    } else if(val == 'UnPlanned'){
      setAudittypeUnPlanned(true)
      setAudittypePlanned(false)

    }
  }

  const getLocation = () => {
    const req = new LocationByPlant(plantId)
    locationService.getLocationByPlant(req).then((res) => {
      if (res.status) {
        setAssetView(false);
        setLocationData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetsCount = (val) => {
    const req = new LocationById(val);
    locationService.getAssetsCountByLocations(req).then(res => {
      if (res.status) {
        // form.setFieldsValue({assetsCount:res.data[0].assetsCount})
        setAssetView(true);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const finishHandler = (values) => {
    console.log(values)
    service.createLocationAudit(values).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage(res.internalMessage)
        form.resetFields()
        setAssetView(false);
        history.push('/location-audit-calender')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
    
  }

  const resetHandler = () => {
    form.resetFields();
    setAssetView(false);
    setAudittypePlanned(false)
    setAudittypeUnPlanned(false)

  }

  return (
    <Card title={<span style={{ color: "white" }}>Location Audit Form</span>} style={{ textAlign: "center" }} 
      headStyle={{ backgroundColor: "#69c0ff", border: 0 }} 
      extra={<><Link to='/location-audit-calender' ><span style={{ color: 'white' }} ><Button className='panel_button' >Calendar </Button> </span></Link></>}
    >
     <Form form={form} name="control-hooks" layout="vertical" onFinish={finishHandler}>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
        <Form.Item label='Location' name = 'locationId'
          rules={[
            {
              required: true,
              message: "Location is required"
            },
          ]}
        >
        <Select
                placeholder='Select Location'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={getAssetsCount}
              >
                {locationData.map((location) => {
                  return <Option key={location.locationId} value={location.locationId}>{`${location.locationName}`}</Option>
                })}
              </Select>
        </Form.Item>
        </Col>
        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Assets Count' name='assetsCount'>
                <Input disabled/>
          </Form.Item>
        </Col> */}
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
        <Form.Item label='Audit Type' name = 'auditType'
          rules={[
            {
              required: true,
              message: "Audit Type is required"
            },
          ]}
          initialValue={'Planned'}
        >
          <Select 
            showSearch
            allowClear
            onChange={onauditType}
            placeholder='Select Audit Type'
            defaultValue={'Planned'}
          >
            <Option key='planned' value='Planned'> Planned</Option>
            <Option key='unPlanned' value='UnPlanned'> UnPlanned</Option>
          </Select>
        </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item label='Audit Date' name = 'auditDate'
              rules={[
                {
                  required: true,
                  message: "Audit Date is required"
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD"/>
            </Form.Item>
        </Col>
        {/*{
          audittypeUnPlanned? (<>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item label='Audit Date' name = 'auditDate'
              rules={[
                {
                  required: true,
                  message: "Audit Date is required"
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD"/>
            </Form.Item>
            </Col>
          </>) : (<></>)
        }
         {
          audittypePlanned? (<>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item label='Start Date' name = 'startDate'
              rules={[
                {
                  required: true,
                  message: "Start Date is required"
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD"/>
            </Form.Item>
            </Col>
            <Col>
            <Form.Item label='Completed Date' name = 'completedDate'
              rules={[
                {
                  required: true,
                  message: "End Date is required"
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD"/>
            </Form.Item>
            </Col>
          </>) : (<></>)
        } */}
         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Status' name = 'status'
          initialValue={'Open'}
            rules={[
              {
                required: true,
                message: "Status is required"
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              defaultValue={'Open'}
              disabled
            >
              <Option key='open' value='Open'>Open</Option>
              <Option key='completed' value='Completed'>Completed</Option>
            </Select> 
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Audit Provider' name = 'auditProvider'
            rules={[
              {
                required: true,
                message: "Audit Provider is required"
              },
            ]}
          >
            <Input placeholder='Enter Audit Provider Name'/>
          </Form.Item>
        </Col>
        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Audit Name' name = 'auditName'
            rules={[
              {
                required: true,
                message: "Audit Name is required"
              },
              {
                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                message: `Should contain only alphabets.`
              },
            ]}
          >
            <Input placeholder='Enter Audit Name'/>
          </Form.Item>
        </Col> */}
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Audit Owner' name = 'auditOwner'
            rules={[
              {
                required: true,
                message: "Audit Owner is required"
              },
              {
                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                message: `Should contain only alphabets.`
              },
            ]}
          >
            <Input placeholder='Enter Audit Owner Name'/>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item label='Phone Number' name = 'phoneNumber'
            rules={[
              {
                required: true,
                 min: 10, max: 12 
              },
              {
                pattern: /^[0-9]*$/,
                message: `Don't Allow letters and Spaces`
              }
            ]}
          >
            <Input placeholder="Enter 10 digit ph no"/>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{marginLeft:'85%'}}>
        <Col>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Col>
        <Col style={{marginLeft:'2%'}}>
          <Button onClick={resetHandler}>
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
    <br />
    {
          assetView ? (
            <LocationAssetListGrid key={form.getFieldValue('locationId')} locationId={form.getFieldValue('locationId')}/>
          ) : (
            <>
            </>
          )
        }

    </Card>

    
  );
}

export default LocationAuditForm;
