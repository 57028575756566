import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemSubCategoryDropDownDto, SubCatReqForCategoryId, TypesDto } from '@gtpl/shared-models/masters';

import { ItemSubCategoryService, TypesService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from "react-router-dom";
import './types-form.css';
const { Option } = Select;

/* eslint-disable-next-line */
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
import './types-form.css';

/* eslint-disable-next-line */
export interface TypesFormProps {
  typesData: TypesDto;
  updateDetails: (dto: TypesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function TypesForm(
  props: TypesFormProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const service = new TypesService();
  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const subCategoryService = new ItemSubCategoryService();
  const [disable,setDisable]=useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  useEffect(() => {
    getAllItemSubCategories()
  },[])

  const save = (dto: TypesDto) => {
    setDisable(true)
    service.createTypes(dto).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Type Created Successfully');
        history.push("/types-grid")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const saveData = (values: TypesDto) => {
    setDisable(false)
    console.log(values);
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      setDisable(false)
      save(values);
    }

  };

  const getAllItemSubCategories = () => {
   
    const itemsRequest = new SubCatReqForCategoryId(6);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const onReset = () => {
    form.resetFields();
  };
  const onFocus = () => {
    //console.log('focus');
  }

  const onSearch = (val) => {
    //console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Types Form</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/types-grid' ><span style={{ color: 'white' }} >  {(props.isUpdate === false) &&
        <Button className='panel_button' >View </Button>
      }  </span></Link>} >
      <Form form={form} initialValues={props.typesData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="typeId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemSubCategoryId"
              label="Item Sub Category"
              rules={[
                {
                  required: true,
                  message: 'Item Sub Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Item Sub Category `,
                },
              ]}

            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Sub Category</Option>
                {subCategoryData.map((subCategory) => {
                  return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,

                  message: 'Type required'

                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default TypesForm;
