import { TrimsDto } from '@gtpl/shared-models/masters';
import React, { useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";

import './trims-form.css';
import { TrimsService } from '@gtpl/shared-services/masters';

/* eslint-disable-next-line */
export interface TrimsFormProps {
  trimsData: TrimsDto;
updateTrims:(trims:TrimsDto)=> void;
isUpdate:boolean;
closeForm: () => void;
}

export function TrimsForm(
  props: TrimsFormProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const service = new TrimsService;
  const [disable,setDisable]=useState<boolean>(false)
  const create = (Data: TrimsDto) => {
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
     AlertMessages.getSuccessMessage('Created Successfully');
         
     history.push("/trimsView")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   AlertMessages.getErrorMessage(err.message);
 })
}

  const saveData = (values: TrimsDto) => {
    setDisable(false)
    // console.log(values);
    if(props.isUpdate){
      props.updateTrims(values);
    }else{
      setDisable(false)
      create(values);
      // console.log('test')
    }
  
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card title={<span style={{color:'white'}}>Trims</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/trimsView' ><span style={{color:'white'}} >  {(props.isUpdate===false) &&
      <Button className='panel_button' >View </Button>
       }  </span></Link>} >


      <Form form={form} initialValues={props.trimsData} name="control-hooks" onFinish={saveData}
      layout="vertical"
      > 

      <Form.Item name="trimsId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
      <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.trimsData}>
      <Input hidden/>
    </Form.Item>
    <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:6,offset:1}} style={{margin:'1%'}}>
              <Form.Item
                  name="trimName"
                  label="Trim Name"
                  rules={[{ required: true }]} >
                 <Input/>
                 
                </Form.Item>
        </Col>
       
      
        
        </Row>
        <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary"disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate!==true) &&
     <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
        Reset
      </Button>
      }
            </Col>
          </Row>
      </Form>
    </Card>
  );
}

export default TrimsForm;
