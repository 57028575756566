import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {PalletCapacityDto, valueAdditionTwoDtoDefault} from '@gtpl/shared-models/masters';
import {PalletCapacityService} from '@gtpl/shared-services/masters';
import {  Link } from "react-router-dom";
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {PalletCapacityForm} from '@gtpl/pages/master/master-components/pallet-capacity-form'

import './pallet-capacity-grid.css';
import { SizeCategoryEnum } from '@gtpl/shared-models/common-models';
import { render } from 'react-dom';

/* eslint-disable-next-line */
export interface PalletCapacityGridProps {}

export function PalletCapacityGrid(
  props: PalletCapacityGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [palletCapacityData, setPalletCapacityData] = useState<PalletCapacityDto[]>([]);
  const [selectedPalletCapacityData, setSelectedPalletCapacityData] = useState<any>(undefined);
  const palletCapacityDataService=new PalletCapacityService();
  const username = localStorage.getItem('username')

  useEffect(() => {
    getAllPalletCapacitys();
  }, []);

  

  /**
   * 
   */
  const getAllPalletCapacitys= () => {
    palletCapacityDataService.getAllPalletCapacitys().then(res => {
      console.log(res);
      if (res.status) {
        setPalletCapacityData(res.data);
      } else {
        if (res.intlCode) {
          setPalletCapacityData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPalletCapacityData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param productData 
   */
  const deletePalletCapacity = (data:PalletCapacityDto) => {
    data.isActive=data.isActive?false:true;
    palletCapacityDataService.activateOrDeactivatePalletCapacity(data).then(res => { console.log(res);
      if (res.status) {
        getAllPalletCapacitys();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    /**
     * 
     * @param variantData 
     */
    const updatePalletCapacity = (palletCapacityData: PalletCapacityDto) => {
      palletCapacityDataService.updatePalletCapacity(palletCapacityData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllPalletCapacitys();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
   /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    //TO open the form for updation
    const openFormWithData=(viewData: PalletCapacityDto)=>{
      console.log(viewData)
      setDrawerVisible(true);
      setSelectedPalletCapacityData(viewData);
    }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Pallet Capacity',
      dataIndex: 'palletCapacity',
      // responsive: ['lg'],
      sorter: (a, b) => a.palletCapacity.localeCompare(b.palletCapacity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('palletCapacity')
    },
    {
      title: 'Packing Method',
      dataIndex: 'packingMethod',
      // responsive: ['lg'],
      sorter: (a, b) => a.packMethod - b.packMethod,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packMethod')
    },
    
    {
      title: 'Size Category',
      dataIndex: 'sizeCategory',
      // responsive: ['lg'],
      sorter: (a, b) => a.sizeCategory - b.sizeCategory,
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('sizeCategory'),
      filters:[
        {text:'Size 1', value:SizeCategoryEnum.Size_One},
        {text:'Size 2', value:SizeCategoryEnum.Size_Two},
        {text:'Size 3', value:SizeCategoryEnum.Size_Three},
      ],
      filterMultiple : false,
      onFilter :( value,record) => {
        return record.sizeCategory === value
      },
    },
    {
      title: 'Unit',
      dataIndex: 'unitName',
      // responsive: ['lg'],
      sorter: (a, b) => a.unitName - b.unitName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unitName')
    },
    // {
    //   title: 'UOMs Name',
    //   dataIndex: 'uomName',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.uomName?.localeCompare(b.uomName),
    //   // render: (uomId, rowData) => (
    //   //   <>
    //   //     {unitOfWeights.find(uom=>uom.value==uomId).name}
    //   //   </>
    //   // ),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('uomName')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    // {
    //   title:`Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>         
    //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
    //           onClick={() => {
    //             if (rowData.isActive) {
    //               openFormWithData(rowData);
    //             } else {
    //               AlertMessages.getErrorMessage('You Cannot Edit Deactivated Packing Method');
    //             }
    //           }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />
          
    //       <Divider type="vertical" />
    //         <Popconfirm onConfirm={e =>{deletePalletCapacity(rowData);}}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate Packing Method ?'
    //             :  'Are you sure to Activate Packing Method ?'
    //         }
    //       >
    //         <Switch  size="default"
    //             className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
    //             checkedChildren={<RightSquareOutlined type="check" />}
    //             unCheckedChildren={<RightSquareOutlined type="close" />}
    //             checked={rowData.isActive}
    //           />
            
    //       </Popconfirm>
    //     </span>
    //   )
    // }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title={<span style={{color:'white'}}>Pallet Capacity</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={(username == '"ramakrishna"') ?<Link to='/pallet-capacity-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>:""} >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Pallet Capacity: ' + palletCapacityData.length} style={{textAlign: 'left', width: 275, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + palletCapacityData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 225, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + palletCapacityData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 225, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.PalletCapacityId }
          columns={columnsSkelton}
          dataSource={palletCapacityData}
          scroll={{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <PalletCapacityForm key={Date.now()}
                updatePalletCapacity={updatePalletCapacity}
                isUpdate={true}
                palletCapacityData={selectedPalletCapacityData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}
export default PalletCapacityGrid;
