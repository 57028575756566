import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { CertificatesDto } from '@gtpl/shared-models/masters';
import {CertificationsService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";


// import './products-form.css';
 

const { TextArea } = Input;


/* eslint-disable-next-line */
export interface CertificationsFormProps {
  certificationData:CertificatesDto;
  updateCertification:(certification:CertificatesDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function CertificationsForm(props: CertificationsFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  const certificationDataService=new CertificationsService()

  const createCertification=(productData:CertificatesDto)=>{
    setDisable(true)
    certificationDataService.createCertification(productData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Certificate Created Successfully');
        history.push("/certificates-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    form.resetFields();
  }

  const saveData = (values: CertificatesDto) => {
    setDisable(false)
    if(props.isUpdate){
      props.updateCertification(values);
    }else{
      setDisable(false)
      createCertification(values);
    }
  
  };

  console.log('screen',screen.width)
  return (
  

    <Card title={<span style={{color:'white'}}>Certification</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/certificates-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form} initialValues={props.certificationData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          >
    
          <Form.Item name="certificateId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{margin:'1%'}}>
                  <Form.Item
                      name="certificateName"
                      label="Certification Name"
                      rules={[
                        {
                          required: true,
                          message:"Enter Certification Name"
                        },
                        {
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                          message: `Should contain only alphabets.`
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col >
            <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{margin:'1%'}}>
            <Form.Item  name="remarks" label="Remarks"
            rules={[
             {
          
              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
              message: `Don't Allow Spaces`
            } 
          ]}>
                <TextArea rows={1} />
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
      );
}

export default CertificationsForm;
