import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { TrimsMappingService } from '@gtpl/shared-services/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import './trims-mapping-grid.css';
import { TrimsItemsMappingGrid } from './trims-item-mapping-grid';
import { TrimsItemMappingDto } from '@gtpl/shared-models/masters';
import { Link, useHistory } from "react-router-dom";
import { TrimsConfigurationForm } from '@gtpl/pages/master/master-components/trims-configuration-form'


/* eslint-disable-next-line */
export interface TrimsMappingGridProps { }

export function TrimsMappingGrid(
  props: TrimsMappingGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [trimsData, setTrimsData] = useState<any[]>([]);
  const [selectedTrimsData, setSelectedTrimsData] = useState<any>(undefined);


  const trimsmappingservice = new TrimsMappingService;

  useEffect(() => {
    getAll();
  }, [])


  const getAll = () => {
    trimsmappingservice.getalltrimsmappingdetails().then(res => {
      if (res.status) {
        setTrimsData(res.data);
      } else {
        if (res.intlCode) {
          setTrimsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTrimsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const openFormWithData = (viewData) => {
    setDrawerVisible(true);
    setSelectedTrimsData(viewData);
    console.log(selectedTrimsData)
    console.log('selectedTrimsData')
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'End Customer',
      dataIndex: 'endCustomerName',
      // responsive: ['lg'],
      sorter: (a, b) => a.endCustomerName - b.endCustomerName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerName'),
      render: (value) => { return value ? value : "All EndCustomers" }

    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      // responsive: ['lg'],
      sorter: (a, b) => a.brandName.length - b.brandName.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('brandName'),
      render: (value) => { return value ? value : "All Brands" }

    },
    {
      title: 'PackStyle',
      dataIndex: 'packmethodName',
      // responsive: ['lg'],
      sorter: (a, b) => a.packmethodName - b.packmethodName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packmethodName'),
      render: (value) => { return value ? value : "All Packstyles" }
    },
    {
      title: 'Product code',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.variantCode - b.variantCode,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),
      render: (value) => { return value ? value : "All Variant Codes" }

    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {

        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Trims');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          {/* <Divider type="vertical" />
          <Popconfirm
            // onConfirm={e =>{deleteTrims(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Trims ?'
                : 'Are you sure to Activate Trims ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm> */}
        </span>
      )
    }
  ];

  /**
   * used for column filter
   * @param dataIndex column data index
   */


  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const renderItems = (record: TrimsItemMappingDto, index, indent, expanded) => {

    return <TrimsItemsMappingGrid trimId={record.trimId} />
  }
  return (

    <Card title={<span style={{ color: 'white' }}>Trims Configuration</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={<Link to='/trims-configuration-form' ><Button className='panel_button' >Create </Button></Link>}
    >
      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total Trims: ' + trimsData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + trimsData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + trimsData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.trimId}
        columns={columnsSkelton}
        dataSource={trimsData}
        scroll={{ x: true }}
        expandedRowRender={renderItems}
        expandIconColumnIndex={7}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '95%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <TrimsConfigurationForm key={Date.now()}
            isUpdate={true}
            selectedData={selectedTrimsData}
            onSucess={setDrawerVisible}
          />
        </Card>
      </Drawer>
    </Card>

  );
}

export default TrimsMappingGrid;
