import React, { useState } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import './recruitment-mode-form.css';
import { RecruitmentService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { RecruitmentDto } from '@gtpl/shared-models/masters';
const { TextArea } = Input;

export interface RecruitmentFormProps {
  recruitmentData: RecruitmentDto;
  updateRecruitment: (recruitmentData: RecruitmentDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export function RecruitmentForm(props: RecruitmentFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";
  const recruitmentService = new RecruitmentService();

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset = () => {
    form.resetFields();
  }
  const createRecruitment = (recruitmentData: RecruitmentDto) => {
    setDisable(true)
    recruitmentService.createRecruitment(recruitmentData)
      .then((res) => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage('Recruitment mode Created Successfully');
          history.push('/recruitment-mode-view');
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  };
  const saveData = (values: RecruitmentDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateRecruitment(values);
    } else {
      setDisable(false)
      console.log(values);
      createRecruitment(values);
    }
  };
  return (
    <Card title={<span style={{ color: 'white' }}>Recruitment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/recruitment-mode-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
      <Form
        layout={'vertical'}
        form={form}
        initialValues={props.recruitmentData}
        name="control-hooks"
      onFinish={saveData}
      >
        
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>
        <Form.Item name="recruitmentId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="recruitmentMode"
              label="Recruitment Mode"
              rules={[
                {
                  required: true,
                  message:"Enter valid Recruitment Mode",
                  max:16
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
                ]} >
                <Input />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary"disabled={disable} htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default RecruitmentForm;