import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Upload, Typography, UploadProps } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { Link } from 'react-router-dom';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { HrmsEmpDetService, UploadDetailesServiceIntigration } from '@gtpl/shared-services/masters';
import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import form from 'antd/lib/form';
import Highlighter from 'react-highlight-words';
import { Excel } from 'antd-table-saveas-excel';
import { UploadDetailesGetResponse, UploadDetailesGetResponseDto, UploadDetailesResponseDto } from '@gtpl/shared-models/hrms';
export interface HrmsUploadEmployeeDetailsProps {
    isUpdate: boolean;
    uploadFileList: (uploadFiles: any[]) => void
}

export function HrmsUploadEmployeeDetails(
    props: HrmsUploadEmployeeDetailsProps
) {
    const [searchText, setSearchText] = useState('');
    const [allData, setAllData] = useState<UploadDetailesGetResponseDto[]>([])
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [disable, setDisable] = useState<boolean>(false);
    const [display, setDisplay] = useState<string>('none');
    const [filelist, setFilelist] = useState([]);
    const service = new HrmsEmpDetService();
    const uploaddetailesservice = new UploadDetailesServiceIntigration();
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    let [empData, setEmpData] = useState([]);
    const uploadFieldProps: UploadProps = {
        multiple: false,
        onRemove: file => {
            setFilelist([]);
            props.uploadFileList([])

        },
        beforeUpload: (file: any) => {
            if (!file.name.match(/\.(csv|xlsx|xls|pdf|zip)$/)) {
                AlertMessages.getErrorMessage("Only xlsx,csv,xls,pdf files are allowed!");
                return true;
            }
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = data => {
                if (filelist.length == 1) {
                    AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
                    return true;
                } else {
                    setFilelist([...filelist, file]);
                    props.uploadFileList([...filelist, file])
                    return false;
                }
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
        fileList: filelist
    };

    const save = (hrmsData: HrmsEmployeeDetailsDto) => {
        console.log(hrmsData)
        console.log('submit clicked')
        service.create(hrmsData).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage('Employee Details Created Successfully');
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const [reportData, setReportData] = useState<any[]>([]);
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        empData = [];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                console.log(resp);
                const excelData = resp.rows;
                // loop each row and push into the empData
                for (let i = 1; i < excelData.length; i++) {
                    const rowData = excelData[i];
                    empData.push(
                        {
                            employeeCode: rowData[0],
                            employeeName: rowData[1],
                            gender: rowData[2],
                            designation: rowData[3],
                            department: rowData[4],
                            priorityOne: rowData[5],
                            priorityTwo: rowData[6],
                            employeeStatus: rowData[7],
                            yearOfExperience: rowData[8],
                            emailId: rowData[9],
                            mobileNumber: rowData[10],

                        }
                    )
                }
            }
            console.log(empData);
            setEmpData([]);
            setEmpData(empData);
        });
        return true;
    }
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    useEffect(() => {
        getUploadDetailes();
    }, []);
    const getUploadDetailes = () => {
        uploaddetailesservice.getUploadDetailes().then(res => {
            if (res.status) {

                setAllData(res.data);
            } else {
                if (res.intlCode) {
                    setAllData([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            setAllData([]);
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            ) : null
    });






    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'Employee Code',
            dataIndex: 'employeeCode',
            responsive: ['sm'],
            sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('employeeCode')
        },

        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            responsive: ['sm'],
            sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('employeeName')
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            sorter: (a, b) => a.gender.localeCompare(b.gender),
            sortDirections: ['descend', 'ascend'],
            responsive: ['sm'],
            filters: [
                { text: 'Male', value: 'M' },
                { text: 'Female', value: 'F' },
                { text: 'Others', value: 'O' },

            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                // === is not work
                return record.gender === value;
            },
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            responsive: ['sm'],
            sorter: (a, b) => a.designation?.localeCompare(b.designation),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('designation')
        },
        {
            title: 'Department',
            dataIndex: 'department',
            responsive: ['sm'],
            sorter: (a, b) => a.department?.localeCompare(b.department),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('department')
        },
        {
            title: 'Priority One',
            dataIndex: 'priorityOne',
            responsive: ['sm'],
            sorter: (a, b) => a.priorityOne?.localeCompare(b.priorityOne),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('priorityOne')
        },
        {
            title: 'Priority Two',
            dataIndex: 'priorityTwo',
            responsive: ['sm'],
            sorter: (a, b) => a.priorityTwo?.localeCompare(b.priorityTwo),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('priorityTwo')
        },
        {
            title: 'Employee Status',
            dataIndex: 'employeeStatus',
            responsive: ['sm'],
            sorter: (a, b) => a.employeeStatus?.localeCompare(b.employeeStatus),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Year of experience',
            dataIndex: 'yearOfExperience',
            responsive: ['sm'],
            sorter: (a, b) => a.yearOfExperience - (b.yearOfExperience),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('yearOfExperience')
        },
        {
            title: 'Company email Id',
            dataIndex: 'emailId',
            responsive: ['sm'],
            sorter: (a, b) => a.emailId?.localeCompare(b.emailId),
            sortDirections: ['descend', 'ascend'],
        },
        // {
        //     title: 'Employee Mode',
        //     dataIndex: 'employeeMode',
        //     responsive: ['sm'],
        //     sorter: (a, b) => a.employeeMode?.localeCompare(b.employeeMode),
        //     sortDirections: ['descend', 'ascend'],
        // },
        {
            title: 'Mobile number',
            dataIndex: 'mobileNumber',
            responsive: ['sm'],
            ...getColumnSearchProps('mobileNumber')
        },

    ];
    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('employeeDetails')
            .addColumns(data)
            .addDataSource(reportData, { str2num: true })
            .saveAs('employee-details.xlsx');
    }
    const data = [
        { title: "Employee Code", dataIndex: "" },
        { title: "Employee Name", dataIndex: "" },
        { title: "Gender(M/F/O)", dataIndex: "" },
        { title: "Designation", dataIndex: "" },
        { title: "Department", dataIndex: "" },
        { title: "Priority One", dataIndex: "" },
        { title: "Priority Two", dataIndex: "" },
        { title: "Employee Status", dataIndex: "" },
        { title: "Year Of Experience", dataIndex: "" },
        { title: "Company Emaid Id", dataIndex: "" },
        // { title: "Employee Mode", dataIndex:""},
        { title: "Mobile Number", dataIndex: "" },
        // { title: "Blood Group", dataIndex:""},
        { title: "Marital Status", dataIndex: "" },
        { title: "Blood Group", dataIndex: "" },
        { title: "Nationality", dataIndex: "" },
        { title: "Unit", dataIndex: "" },
        { title: "Current Address", dataIndex: "" },
        { title: "Permanent Address", dataIndex: "" },
        // { title: "Employee Mode", dataIndex:""},
        { title: "Date of joining", dataIndex: "" },
        { title: "Bank Account Number", dataIndex: "" },
        { title: "IFSC Code", dataIndex: "" },
        { title: "Emergency Contact number", dataIndex: "" },
        { title: "Specilization", dataIndex: "" },
        { title: "Qualification", dataIndex: "" },
        { title: "Percentage", dataIndex: "" },
        { title: "Year of passout", dataIndex: "" },
        { title: "Id Proof", dataIndex: "" },
        { title: "Id Number", dataIndex: "" },
    ];

    return (
        <Card title={<span style={{ color: 'white' }}>Employee Details</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<span style={{ color: 'white' }} ><Button icon={<DownloadOutlined />} onClick={() => { exportExcel(); }}>
                Get Excel
            </Button></span>} >
            <br></br>
            <Row gutter={40}>

                <Col>
                    <Form.Item name="fileUpload" >

                        <Upload {...uploadFieldProps}>
                            <br /><Button style={{ color: "black", backgroundColor: "#7ec1ff" }} icon={<UploadOutlined />}>Upload</Button> </Upload>
                    </Form.Item>

                </Col>
                <Col>
                    {/* <input type="file" onChange={(e) => fileHandler(e)} style={{ "padding": "20px" }} /> */}
                    <Button style={{ marginTop: "22px" }} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
                < Col >
                    <Form.Item >
                        <Button style={{ marginTop: "22px" }} type="primary" htmlType="submit" disabled={disable} onClick={() => setDisplay('block')}> Get Details</Button>
                    </Form.Item>
                </Col >
                {/* <Col>
                        <Button icon={<DownloadOutlined />} style={{ color: "black", backgroundColor: "#7ec1ff",marginTop: "22px",paddingRight:"50px" }} onClick={() => { exportExcel(); }}>
                            Get Excel
                        </Button>                 
                </Col> */}



            </Row>
            <br></br>
            <Table
                key={Date.now()}
                rowKey={record => record.productId}
                columns={columnsSkelton}
                dataSource={allData}
                 scroll={{ x: true }}
                // bordered
            />

        </Card>
    );
}
export default HrmsUploadEmployeeDetails;

