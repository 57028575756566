import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import { DesignationDto } from '@gtpl/shared-models/hrms'
import { DesignationService } from '@gtpl/shared-services/hrms';

import './designation.css';

/* eslint-disable-next-line */
export interface DesignationProps {
  designationData: DesignationDto;
  updateDesignation: (designation: DesignationDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function Designation(
  props: DesignationProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const designationService = new DesignationService()
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const createDesignation = (Data: DesignationDto) => {
    setDisable(true)
    designationService.createDesignation(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Designation created successfully');
        history.push('/designation-grid');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('');
    form.resetFields();
  }

  const saveData = (values: DesignationDto) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateDesignation(values);
    } else {
      setDisable(false)
      console.log(values);
      createDesignation(values);
    }
  };
  console.log(props.designationData);
  return (
    <Card title={<span style={{ color: 'white' }}>designation</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/designation-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} onFinish={saveData} initialValues={props.designationData} >
        <Form.Item
          name="designationId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Enter valid Designation"
                },
                {

                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate !== true) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>

      </Form>
    </Card>
  );
}

export default Designation;
