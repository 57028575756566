import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col,DatePicker,FormInstance  } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import './profiles-form.css';
import { CandidateType } from '@gtpl/shared-models/common-models';
import {QualificationsService} from '@gtpl/shared-services/masters'
import {ProfilesService} from '@gtpl/shared-services/hrms'
import { QualificationsDto } from '@gtpl/shared-models/masters';
import { NotificationDto, ProfilesDto } from '@gtpl/shared-models/hrms';
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
const { TextArea } = Input;
import moment from 'moment';
const { Option } = Select;
export interface ProfilesFormProps {
  profiles:ProfilesDto;
  updateItem: (profiles: ProfilesDto) => void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function ProfilesForm(props: ProfilesFormProps) {
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const qualificationsService=new QualificationsService();
  const service=new ProfilesService();
  const hrmsEmpDetService = new HrmsEmpDetService();
  const empService = new HrmsEmpDetService();
  const profileService = new ProfilesService();
  const [idData, setIdData] = useState<NotificationDto[]>([]);
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  const [qualificationData, setQualificationData] = useState<QualificationsDto[]>([]);
  
  const [isExperience, setIsExperience] = useState<boolean>(false);
  const changeTransferType = (value) => {
    console.log(value)
    if(value === "Experience"){
      setIsExperience(true);
     
    }
    else{
      setIsExperience(false);
     
    }
  }
  
  const [isReference, setIsReference] = useState<boolean>(false);
  const Type = (value) => {
    console.log(value)
    if(value === "Reference"){
      setIsReference(true);
     
    }
    else{
      setIsReference(false);
     
    }
  }

  const getSelectedEmployeeData = (req) => {
    const request = new HrmsEmployeeRequest(req);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form. setFieldsValue ({
          interviewerMobieNo: res.data?.mobileNumber,
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getIDJob = () =>{
    profileService.getIDJob().then((res) =>{
      if(res.status){
      setIdData(res.data);
    }else {
      if (res.intlCode) {
        setIdData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        setIdData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setIdData([]);
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setIdData([]);
  })
}
  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveProfile = (profilesData: ProfilesDto) => {
   
    service.createProfile(profilesData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Profiles Created Successfully');
     history.push("/profiles-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
/**
* 
* @param values //Dto values
*/
const saveData = (values: ProfilesDto) => {
    // console.log(values);
    if(props.isUpdate){
      props.updateItem(values);
    }else{
      saveProfile(values);
    }
  
  };


  const onReset=()=>{
    form.resetFields();
  }
useEffect(() => {
    getAllQualifications();
    getAllActiveEmployees();
    getIDJob();
  }, []);

  /**
   * 
   */
  const getAllQualifications= () => {
    qualificationsService.getAllQualifications().then(res => {
      if (res.status) {
        setQualificationData(res.data);
      } else {
        if (res.intlCode) {
            setQualificationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setQualificationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  return (
      <>
    <Card title={<span style={{color:'white'}}>Candidate Profiles</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/profiles-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
          <Form form={form}
           initialValues={props.profiles} 
           name="control-hooks"
            onFinish={saveData}
          layout="vertical"
          >
          <Form.Item name="profileId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row gutter={24}>            
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 5 }} lg={{ span: 5, offset: 5 }} xl={{ span: 5, offset: 5 }} style={{ margin: '1%' }}>
          <Form.Item
                      name="candidateName"
                      label="Candidate Name"
                      rules={[
                        {
                          required: true,
                          message:"Enter valid candidate name",max:25
                        },
                        {
                          pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                          message: `Should contain only alphabets and first letter should be capital.`
                        }
                        ]}
                      >
                        <Input/>                     
                    </Form.Item>
            </Col> 
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 5 }} lg={{ span: 5, offset: 5 }} xl={{ span: 5, offset: 5 }} style={{ margin: '1%' }}>
          <Form.Item
                      name= "jobDescription"
                      label= "JobDescription"
                      rules={[
                        {
                          required: false,
                          message:"It should not be blank"
                        },
                        {
                          pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                          message: `Should contain only alphabets and first letter should be capital.`
                        }
                        ]}
                      >
                          <Select
                    placeholder="Select Job Description"
                    // onChange={getSelectedNotificationID}
                  >
                    {idData.map((id) => {
                      return <Option key={id.notificationId} value={id.jobDescription}>{id.jobDescription}</Option>
                    })}
                  </Select>                   
                    </Form.Item>
            </Col> 
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="profileDate"
                      label="Profile Date"
                      initialValue={ moment(moment().format("YYYY-MM-DD")) }
                      rules={[
                        {
                          required: true,
                          message:"Select the date again"
                        },
                        ]}
                      >
                        < DatePicker
                           style={{ width: '100%' }} format="YYYY-MM-DD"
                           defaultValue={ moment(moment().format("YYYY-MM-DD")) }
                           showToday={true} 
                        />
                    </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="mobileNumber"
                      label="Mobile Number "
                      rules={[
                        { required: true, message: ' Valid Mobile Number is required' , max:10},
                        {
                          pattern: /^[0-9]*$/,
                          message: `Don't Allow letters and Spaces`
                        }
                      ]}
                      >
                        <Input/>                      
                    </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="qualification"
                      label="Qualification"
                      rules={[
                        {
                          required: true,
                          message:"It should not be blank"
                        },
                        ]}
                      >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select qualifications"
                        allowClear
                      >
                        {qualificationData.map(dropData => {
                          return <Option key={dropData?.qualificationId}value={dropData.qualificationName}>{dropData.qualificationName}</Option>
                        })}
                      </Select>
                    </Form.Item>
            </Col>
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="skills"
                      label="Skills"
                      rules={[
                        {
                          required: true,
                          message:"It should not cross the limit", max:100
                        },
                      
                        ]}
                      
                     >
                        <TextArea rows={1}/>                      
                    </Form.Item>
            </Col>              
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="altMobileNumber"
                      label="Alternative Mobile Number"
                      rules={[
                        { required:false, message: ' Valid Mobile Number is required' , max:10},
                        {
                          pattern: /^[0-9]*$/,
                          message: `Don't Allow letters and Spaces`
                        }
                      ]}
                      >
                        <Input/>
                    </Form.Item>
            </Col> 
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="expectedCTC"
                      label="Expected CTC (In Lacs)"
                      rules={[
                        { required: false, message: ' Valid expected CTC is required' , max:3},
                        {
                          pattern: /^[0-9.]*$/,
                          message: `Don't Allow letters`
                        }
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name="candidateType"
                      label="Fresher/Experienced?"
                      // initialValue={CandidateType.Experience}
                      rules={[
                        {
                          required: true,
                          message:"It should not be blank"
                        },
                        ]}
                  >            
                <Select
                  placeholder="Select Fresher/Experienced?"
             
                  onSelect={changeTransferType}
                  
                >
                  <Option key={2} value= {'Fresher'}>Fresher</Option>
                  <Option key={1} value= {'Experience'}>Experienced</Option>
            
                </Select>                    
              </Form.Item>
            </Col>  
            {/* <Row style={{ display: (isFresher) ? 'block' : 'none' }}> */}
          {isExperience?
           <> <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name='currentCTC'
                label=" Current CTC (In Lacs) "
                rules={[
                  {
                    pattern: /^[0-9.]*$/,
                    message: ` only in numbers `,
                  },
                  { required: false, message: ' Valid current CTC is required' , max:3},
                ]}
              >
                <Input />
              </Form.Item>
            </Col><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name='noticePeriod'
                  label="Notice Period (In Days)"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: ` only in numbers `,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
             

           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name='experience'
                label="Experience (In Years) "
                rules={[
                  {
                    pattern: /^[0-9.]*$/,
                    message: `Should not contain letters.`,
                    
                  },
                  { required: false, message: ' Valid experience  is required',max:3,},
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>   </>:""}        
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                      name='sourceType'
                      label="Source Type "
                      rules={[
                        {
                          required: true,
                          message:"It should not be blank"
                        },
                       
                        ]}
                      
                      >
                      <Select
                      placeholder="Select Source type"
                 
                  onSelect={Type}
                  
                >
                <Option key={1} value= {'Reference'}>Reference</Option>
                <Option key={2} value= {'Direct'}>Direct</Option>
                </Select>                    
                    
                    </Form.Item>
            </Col>
{/* 
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="noticePeriod"
                  label="Notice Period"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Notice Period  ",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Notice Period"
                  >
                    <Option key={1} value={'1 Week'}>1 Week</Option>
                    <Option key={2} value={'10 Days'}>10 Days</Option>
                    <Option key={3} value={'1 Month'}>1 Month</Option>
                    <Option key={4} value={'2 Months'}>2 Months</Option>
                    <Option key={5} value={'3 Months'}>3 Months</Option>
                  </Select>}
                </Form.Item>
              </Col> */}


            {/* {isReference?
          <><Col key={Date.now()} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                  key={Date.now()}
                      name="referredBy"
                      label="Reference By"
                      >
                        {console.log('---------')}
                <Select
                 // key={Date.now()}
                  placeholder="Select Reference By"
                  onChange={getSelectedEmployeeData}
                  defaultValue={Number(props.profiles?.referredBy)}
                >
                {/* <Option key={0} value={null}> Select Reference By</Option> */}
                {/* {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId} >{employee.employeeName}</Option>
                })}
              </Select> 
           
                    </Form.Item>
            </Col> 
            </> 
            :""}  */}
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item  name="remarks" label="Remarks"
            rules={[
              {
                //required: true,
                message:"It should not cross the limit", max:100
              }, 
          ]}>
                <TextArea rows={2} />
              </Form.Item>
            </Col>
           
            </Row> 
            <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true ) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
            }
          </Col>
            </Row>
          </Form>
      </Card>
        </>
      );
          }
export default ProfilesForm; 
