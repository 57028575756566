import React,{useState, useEffect} from 'react';
import {Form, Input, Button, Card, Row, Col, DatePicker, Select} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import {ComplaintsDto} from '@gtpl/shared-models/hrms';
import { ComplaintsService } from '@gtpl/shared-services/hrms';
import {DepartmentService} from '@gtpl/shared-services/masters';
import {DepartmentDto, DeptRequest} from '@gtpl/shared-models/masters';
import './complaints.css';
import { FgStatusEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface ComplaintsProps {
  complaintsData: ComplaintsDto;
  updateComplaint: (complaint: ComplaintsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

const {Option} = Select;

export function Complaints(
  props: ComplaintsProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const complaintsService = new ComplaintsService();
   const [departmentsData, setDepartmentsData] = useState<DepartmentDto[]>([]);
  const [selectedDept, setSelectedDept] = useState<any>(undefined);
  const departmentService = new DepartmentService();
  const [disable, setDisable] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(()=>{
    getAllActiveDepartment();
  },[])


  const getAllActiveDepartment= () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentsData(res.data);
      } else {
        if (res.intlCode) {
            setDepartmentsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getDeptDataById = (value) => {
      const deptReq = new DeptRequest(value);
      departmentService.getDeptDataById(deptReq).then(res => {
        setSelectedDept(res.data.deptName);
      })
  }

  const createComplaint = (Data: ComplaintsDto) => {
    setDisable(true)
    Data.departmentName = selectedDept;
    Data.status = FgStatusEnum.OPEN;
    complaintsService.createComplaint(Data).then(res => {
      setDisable(false)
      if(res.status) {
        AlertMessages.getSuccessMessage('Complaint created successfully');
        history.push("/complaints-view");
        onReset();
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    console.log('');
    form.resetFields();
    
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return  current > fromDate;
  }

  const saveData = (values: ComplaintsDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values);
      props.updateComplaint(values);
    }else {
      setDisable(false)
      console.log(values);
      createComplaint(values);
    }
  };
  return (
    <Card title={<span style={{color:'white'}}>Complaint</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/complaints-view' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.complaintsData} >
        <Form.Item 
        name ="complaintId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="complaint"
                label="Complaint "
                rules={[
                  {
                    required: true,
                    message:"Enter valid Complaint."
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
            <Form.Item
                name="departmentId"
                label="Department"
                rules={[
                  {
                    required: true,
                    message: 'Department is required'
                  },
                ]}>
                <Select
                    placeholder="Select Department"
                    onSelect = {getDeptDataById}
                    >
                    {departmentsData?.map(dropData => {
                     return <Option key={dropData.deptId} value={dropData.deptId}>{dropData.deptName}</Option>
                    })}
                </Select>
              </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item 
              name="complaintRaisedDate" 
              label="Date"
               initialValue={moment()}
               
               rules={[
                 {
                 required:true,
                 message:'Date is required'
                 },
               ]}
               >
                <DatePicker onChange={(val) => { moment(val).format('YYYY-MM-DD') }} style={{ width: '100%' }} 
                //  disabledDate = {disabledDate}
                />
                
              </Form.Item>
        </Col>
        {/* {props.isUpdate == true &&
          <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
           <Form.Item
             name="status"
             label="Status"
             rules={[
               {
                 required: false,
                 message: 'Status is required'
               },
             ]}>
             <Select
                 placeholder="Select Status"
                 allowClear
                 >
                 <Option key={0} value={null}>Select Status</Option>
                 <Option key={1} selected value={FgStatusEnum.OPEN}>{FgStatusEnum.OPEN}</Option>
                 <Option key={2} value={FgStatusEnum.IN_PROGRESS}>{FgStatusEnum.IN_PROGRESS}</Option>
                 <Option key={3} value={FgStatusEnum.CLOSE}>{FgStatusEnum.CLOSE}</Option>
             </Select>
           </Form.Item>
          </Col>
        }  */}

      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
  </Card>

  );
}

export default Complaints;
function disableDateRanges(arg0: { endDate: Date; startDate: Date; }): (date: moment.Moment) => boolean {
  throw new Error('Function not implemented.');
}

