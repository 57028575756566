import React, { useState } from 'react';
import {Form, Input, Button, Card, Row, Col} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import { OverTimeCategoryDto } from '@gtpl/shared-models/hrms';
import {OverTimeCategoryService} from '@gtpl/shared-services/hrms';

import './over-time-category.css';

/* eslint-disable-next-line */
export interface OverTimeCategoryProps {
  data: OverTimeCategoryDto;
  update: (data: OverTimeCategoryDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function OverTimeCategory(
  props: OverTimeCategoryProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const service = new OverTimeCategoryService();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = '';

  if(props.isUpdate){
     createdUser = localStorage.getItem('createdUser');
  } 

  const createOverTimeCategory = (data: OverTimeCategoryDto) => {
    setDisable(true)
    service.create(data).then(res => {
      setDisable(false)
      if(res.status){
        AlertMessages.getSuccessMessage('Over Time Category created successfull');
        history.push('/over-time-categories-grid');
        onReset();
      }else {
        if(res.intlCode){
         AlertMessages.getErrorMessage(res.internalMessage);
        }else{
         AlertMessages.getErrorMessage(res.internalMessage);
        }         
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: OverTimeCategoryDto) => {
    setDisable(false)
    if(props.isUpdate){
      props.update(values);
    }else {
      setDisable(false)
      createOverTimeCategory(values);
    }
  };

  return (
    <Card title={<span style={{color: 'white'}}>Over Time Categories</span>} style= {{textAlign: 'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra = {props.isUpdate==true? '' : <Link to='/over-time-categories-grid'><Button className="panel_button">View</Button></Link>}>
      <Form layout = 'vertical' form = {form} onFinish = {saveData} initialValues={props.data}>
        <Form.Item
         name = "overTimeCategoryId" style={{display: 'none'}}
        >
          <Input hidden/>
        </Form.Item>
        <Form.Item style= {{display: 'none'}} name ="createdUser" initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{span:24}} sm={{span: 24}} md={{span:6}} lg={{span: 6}} xl={{span: 6}}>
            <Form.Item
             name="overTimeCategoryName"
             label="Over Time Category Name"
             rules = {[
               {
                 required: true,
                 message: 'Enter valid Category name',max:16
               },
               {
                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                message: `Should contain only alphabets.` 
               }
             ]}>
               <Input/>
             </Form.Item>
          </Col>
        </Row>
        <Col span={24} style={{textAlign: 'right'}}>
          <Button type="primary"disabled={disable} htmlType="submit">
            Submit
          </Button>
          {(props.isUpdate!==true) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
          }
        </Col>
      </Form>
    </Card>
  );
}

export default OverTimeCategory;
