import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { PackingMethodDto, UomsDto } from '@gtpl/shared-models/masters';
import { PackingMethodService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UomsService } from '@gtpl/shared-services/masters';
import './packing-methods-form.css';
import { UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';


const { TextArea } = Input;
const { Option } = Select;

/* eslint-disable-next-line */
export interface PackingMethodProps {
  packingMehodData: PackingMethodDto;
  updatePackingMethod: (packingMethod: PackingMethodDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function PackingMehodsForm(props: PackingMethodProps) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const [selectedUom, setSelectedUom] = useState<number>(null);
  // const [uomData, setUomData] = useState<UomsDto[]>([]);
  const uomsService = new UomsService();
  // useEffect(() => { getAllActiveUoms() }, []);
  let unitOfWeights = UnitsOfWeightInput;
  let history = useHistory();

  const packingMethodDataService = new PackingMethodService();

  // useEffect(() => {
  //   if (props?.packingMehodData?.uomId) {
  //     setSelectedUom(props.packingMehodData.uomId)
  //   }
  // }, [])


  const createProduct = (packingMehodData: PackingMethodDto) => {
    setDisable(true)
    console.log(packingMehodData)
    packingMethodDataService.createPackingMethod(packingMehodData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Packing Method Created Successfully');
        history.push("/Packingmethods-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  let createdUser = "";
  if (props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset = () => {
    form.resetFields();
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onBlur = () => {
    console.log('blur');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const saveData = (values: PackingMethodDto) => {
    setDisable(false)
    if (props.isUpdate) {
      // values.uomId = form.getFieldValue('uomName');
      props.updatePackingMethod(values);
    } else {
      setDisable(false)
      // values.uomId = form.getFieldValue('uomName');
      createProduct(values);
    }
  };

  // const getAllActiveUoms = () => {
  //   uomsService.getAllActiveUoms().then(res => {
  //     if (res.status) {
  //       // AlertMessages.getSuccessMessage('Umos Retrived successfully');
  //       setUomData(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const handleUom = (value, row) => {
    setSelectedUom(value);
    packingMethodNameSet(row);
  }
  const packingMethodNameSet = (row) => {
    const noOfPouches = form.getFieldValue('noOfPouches') ? form.getFieldValue('noOfPouches') : 0;
    const pouchesPerRow = form.getFieldValue('pouchesPerRow') ? form.getFieldValue('pouchesPerRow') : 0;
    const uomName = row.children ? row.children : 0;
    form.setFieldsValue({
      packingMethodName: `${noOfPouches}X${pouchesPerRow} ${uomName}`,
    });
  }

  return (


    <Card title={<span style={{ color: 'white' }}>Packing Methods</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/Packingmethods-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} initialValues={props.packingMehodData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="packingMethodId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="packingMethodName"
              label="Packing Method Name"
              initialValue={'0*0'}
              rules={[
                {

                  message: "Enter valid packing method name."
                },
                {
                  // pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  // pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'!<>,.?|\s-]*$/,
                  message: `Invalid Packing Method Name`
                }
              ]}>

              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="noOfPouches"
              label="No. Of Pouches"
              rules={[
                {
                  required: true, message: "Enter number of pouches"
                },
                {

                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                  message: `Should contain only numbers.`
                }
              ]}>
              <Input onChange={packingMethodNameSet} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="pouchesPerRow"
              label="Pouches Weight"
              rules={[
                {
                  required: true, message: "Enter a numeric value"
                },
                {

                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                  message: `Should contain only numbers.`
                }
              ]}>
              <Input onChange={packingMethodNameSet} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="palletCapacity"
              label="Pallet Capacity"
              rules={[
                {
                  required: true, message: "Enter a numeric value"
                },
                {

                  pattern: /^[^-\s\\A-Za-z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                  message: `Should contain only numbers.`
                }

              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="uomId"
              label="UOM Name"
              rules={[
                {
                  required: true,
                  message: 'UOMS name is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the UOMS Name`
                }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select UOM'S Name"
               // defaultValue={selectedUom}
               // optionFilterProp="children"
                onChange={handleUom}
                // onFocus={onFocus}

                // onBlur={onBlur}
                // onSearch={onSearch}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
              >
                <Option key={0} value={null}>Select UOM'S Name</Option>
                {unitOfWeights.map((uom) => {
                  return <Option key={uom.value} value={uom.value}>{uom.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  );
}



