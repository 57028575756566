import { Button, Card, Col, DatePicker, Form, FormInstance, Input, Row, Select, Typography, Upload, UploadProps } from 'antd'
import React, { useEffect, useState } from 'react';
import { CountryService, CurrencyService, CustomersService, DeliveryTermsService, DestinationService, EndCustomerService, EndCustomersService, HrmsEmpDetService, PaymentTermsService,UnitcodeService, ShippingTermsService } from '@gtpl/shared-services/masters';
import { EmployeeService } from '@gtpl/shared-services/attendance';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CountriesDropDownData, CountryDto, CountryRequest, CurrencyDto, CusAddressInfo, CustomerDto, CustomersDropDownData, DeliveryTermsDropDownDto, DestinationDropDownData, DestinationDto, EndCustomerDto, EndCustomersDropDownData, HrmsEmployeeDetailsDto, PaymentTermsDropDownDto, PlantsDropDown, ShippingTermsDropDownDto, ShippingTermsDto } from '@gtpl/shared-models/masters';
import { EmployeeDto, RolesList } from '@gtpl/shared-models/gtpl';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CategoriesInput, PortOfEntryInput, ProcessTypeInput,soTypesInput,SoInvoiceDetailsInfo, JobTypes } from '@gtpl/shared-models/sale-management';
import { EmployeeRolesEnum, GlobalStatus, JobTypeEnum, JobTypesEnum, SoProcessTypesEnum, SoStatusEnum, SoTypesEnum, StatusEnum } from '@gtpl/shared-models/common-models';
import { formatMessage } from '@formatjs/intl';


const { Option } = Select;
const { TextArea } = Input;

const onSearch = () => {

}

export interface ICustomerDetailsFormProps {
  intialData: SoInvoiceDetailsInfo;
  brandDetails: (brandDetails: any) => void;
  categoryValue:(categoryValue:string)=>void;
  form:FormInstance<any>
  soStatus:string
  uploadFileList:(uploadFiles:any[])=>void
  currency:(currencyName:string)=>void
  uploadedFile:string;
  soProcessType:any;
  soType:any;
  jobType:string;
  isSubContract:string;
}

const CustomerDetailsForm = (props: ICustomerDetailsFormProps) => {
  let portOfEntries = PortOfEntryInput;
  let categories = CategoriesInput;
  let jobTypes = JobTypes;

  let [initialData, setinitialData] = useState(props.intialData);
  const [customersData, setcustomersData] = useState<CustomerDto[]>([]);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [processingFactoriesData, setProcessingFactoriesData] = useState<PlantsDropDown[]>([]);

  const [selectedCustomer, setSelectedCustomer] = useState<CustomersDropDownData>(undefined)
  const [endcustomersData, setendcustomersData] = useState<EndCustomerDto[]>([]);
  const [countriesData, setcountriesData] = useState<CountryDto[]>([]);
  const [destinationData, setdestinationData] = useState<DestinationDto[]>([]);
  // const [factoriesData, setFactoriesData] = useState<any[]>([{id:1,value:'Unit1'},{id:2,value:'Unit2'},{id:3,value:'Kakinada'}]);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [deliveryTerms, setDeliveryTerms] = useState<DeliveryTermsDropDownDto[]>([]);
  const [shippingTerms, setShippingTerms] = useState<ShippingTermsDropDownDto[]>([]);
  const [paymentTerms, setPaymentTerms] = useState<PaymentTermsDropDownDto[]>([]);
  const [currency, setCurrency] = useState<CurrencyDto[]>([]);
  const [updateDisableField, setUpdateDisableField] = useState<boolean>(false);
  const [isProcessingUnitDisable, setIsProcessingUnitDisable] = useState<boolean>(false);
  const [isManufacturingUnitDisable, setIsManufacturingUnitDisable] = useState<boolean>(false);
  const [customerHidden, setCustomerHidden] = useState<boolean>(false);
  const [subPoDisable, setsubPoDisable] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [soType, setSoType] = useState<string>('');
  const [sotypeDisable, setSotypeDisable] = useState<boolean>(false);
  const [etdValidationState, setEtdValidationState] = useState<boolean>(true);
  const [saletypeDisable, setSaletypeDisable] = useState<boolean>(false);
  const [customerAddress, setCustomerAddress] = useState<CusAddressInfo[]>(undefined);
  const [endCustomerAddress, setEndCustomerAddress] = useState<CusAddressInfo[]>(undefined);
const [filelist, setFilelist] = useState([]);
// let soType


const role = JSON.parse(localStorage.getItem('role'));
const user = JSON.parse(localStorage.getItem('username'));
console.log(user);
useEffect(()=>{
  if(role === EmployeeRolesEnum.ADMIN || role === EmployeeRolesEnum.HO_SALES_MANAGER || role === EmployeeRolesEnum.SR_SALES_MANAGER || role === EmployeeRolesEnum.ASSISTANT_SALES_MANAGER ){
    setSoType('MTO');
  form.setFieldsValue({soProcessType:'MTO'});
    setSotypeDisable(false)
  }else{
    setSoType('MTS');
  form.setFieldsValue({soProcessType:'MTS'});
    setSotypeDisable(true)
  }
  getAllMainPlants();
},[role])
console.log(role)




  const form = props.form;

  const customersService = new CustomersService();
  const currencyService = new CurrencyService();
  const endcustomerservice = new EndCustomersService();
  const countriesservice = new CountryService();
  const destinationservice = new DestinationService();
  // const employeeService = new EmployeeService();HrmsEmpDetService
  const employeeService = new HrmsEmpDetService();
  const deliveryTermsService = new DeliveryTermsService();
  const shippingTermsService = new ShippingTermsService();
  const paymentTermsService = new PaymentTermsService();
  const unitService = new UnitcodeService();

  useEffect(()=>{
    if(!props.intialData){
      hideCustomerDetails(soType)
    }

  },[customersData,endcustomersData,countriesData,deliveryTerms,paymentTerms,shippingTerms,currency])

  useEffect(()=>{
    if(props.jobType == JobTypesEnum.SUB_JOB){
      console.log(props.jobType)
      setsubPoDisable(true)
    }
  },[props.jobType])



  useEffect(() => {
    getCustomersData();
    getPlantsData((props.isSubContract == 'YES')?("yes"):"NO");
    getEndCustomersData();
    getAllFinalCountries();
    getSalesPersonsData();
    getAllDeliveryterms();
    // getFinalDestinationData();
    getAllShippingTerms();
    getAllPaymentTerms();
    getCurrencyData();
    if(soType){
      form.setFieldsValue({soProcessType:soType});
      form.setFieldsValue({soType:SoTypesEnum.DISPATCH});
      
      // hideCustomerDetails(soType)
    }
    form.setFieldsValue({jobType:'Main Job'})
    if(props.intialData){
      console.log(props.intialData)

      if(props.soType == SoTypesEnum.STOCK){
        setEtdValidationState(false);
      }else{
        setEtdValidationState(true);
      }
      if(props.jobType != 'Clone' && props.soStatus != SoStatusEnum.OPEN && props.jobType != JobTypesEnum.SUB_JOB){
        setIsProcessingUnitDisable(true)
      }else{
        setIsProcessingUnitDisable(false)
      }
      if((props.jobType != 'Clone' && props.soStatus != SoStatusEnum.OPEN) || (props.jobType == JobTypesEnum.SUB_JOB) ){
        setIsManufacturingUnitDisable(true)
      }else {
        setIsManufacturingUnitDisable(false)
      }

      console.log(props.intialData)
      console.log(props.soType)
      // setCustomerHidden(true);
      if(props.uploadedFile){
        console.log(props.uploadedFile);
        let fileNameSplit = props.uploadedFile.split('/');
        const fileName = props.uploadedFile.split('/')[2];
        if(!fileName){
           fileNameSplit = props.uploadedFile.split('\\');
        }
        setUploadedFileName(fileNameSplit[2]);
        // console.log(fileName)
        // form.setFieldsValue({fileUpload:props.uploadedFile})  
      }

      if(props.soStatus == SoStatusEnum.IN_PROGRESS){
        setUpdateDisableField(true);
      }
      getAddress(props.intialData.endCustomerId)
      customerOnchange(props.intialData.customerId)
      countryOnchange(props.intialData.country)
      // props.intialData.invoiceDate=moment('2021-03-02').format('YYYY-MM-DD')
      props.intialData.invoiceDate=moment(props.intialData.invoiceDate, 'YYYY-MM-DD');
      if(props.intialData.edaDate){
        props.intialData.edaDate=moment(props.intialData.edaDate, 'YYYY-MM-DD');
      }
      if( props.intialData.edlDate){
        props.intialData.edlDate=moment(props.intialData.actualEdldate, 'YYYY-MM-DD');
      }
      props.form.setFieldsValue(props.intialData)
      form.setFieldsValue({soType:props.soType})
      if(props.jobType == JobTypesEnum.SUB_JOB){
        form.setFieldsValue({jobType:'Sub Job'})
        form.setFieldsValue({processingUnit:undefined})
      }
    }
  }, []);

  

  

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const getCustomersData = () => {
    customersService.getAllActiveCustomers().then((res) => {
      if (res.status) {
        setcustomersData(res.data);
        setcustomersData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setcustomersData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setcustomersData([]);
    });
  }

  const getAllMainPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        // setFactoriesData(res.data);
        setProcessingFactoriesData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setProcessingFactoriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProcessingFactoriesData([]);
    });
  }
  const getPlantsData = (subContractorStatus:string) => {
    form.setFieldsValue({unit:null});
    setFactoriesData([]);
    console.log(subContractorStatus);
    if(subContractorStatus === "yes"){
      console.log("yyyyyyyyyyyyyy")
      unitService.getAllSubContractorPlants().then((res) => {
        if (res.status) {
          // setFactoriesData(res.data);
          setFactoriesData(res.data);
        } else {
          // if (res.intlCode) {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // } else {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // }
          setFactoriesData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setFactoriesData([]);
      });
    }
    else{
      unitService.getAllMainPlants().then((res) => {
        if (res.status) {
          // setFactoriesData(res.data);
          setFactoriesData(res.data);
        } else {
          // if (res.intlCode) {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // } else {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // }
          setFactoriesData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setFactoriesData([]);
      });
    }
  }


  const getCurrencyData = () => {
    currencyService.getAllActiveCurrencys().then((res) => {
      if (res.status) {
        setCurrency(res.data);
        // setCurrency(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setCurrency([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setCurrency([]);
    });
  }
  const getEndCustomersData = () => {
    endcustomerservice.getAllActiveEndCustomers().then((res) => {
      if (res.status) {
        setendcustomersData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setendcustomersData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setendcustomersData([]);
    });
  }
  const getAllFinalCountries = () => {
    countriesservice.getAllActiveCountries().then((res) => {
      if (res.status) {
        setcountriesData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setcountriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setcountriesData([]);
    });
  }
  const getSalesPersonsData = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setEmployeeData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeData([]);
    });
  }
  const getFinalDestinationData = (selectedCountry) => {
    destinationservice.getDestinationsByCountry(new CountryRequest(selectedCountry)).then((res) => {
      if (res.status) {
        setdestinationData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setdestinationData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setdestinationData([]);
    });
  }
  const getAllDeliveryterms = () => {
    deliveryTermsService.getAllDeliveryTermsDropDown().then((res) => {
      if (res.status) {
        setDeliveryTerms(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setDeliveryTerms([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDeliveryTerms([]);
    });
  }

  
  const getAllShippingTerms = () => {
    shippingTermsService.getAllShippingTermsDropDown().then((res) => {
      if (res.status) {
        setShippingTerms(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setShippingTerms([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setShippingTerms([]);
    });
  }
  const getAllPaymentTerms = () => {
    paymentTermsService.getAllpaymentTermsDropDown().then((res) => {
      if (res.status) {
        setPaymentTerms(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPaymentTerms([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPaymentTerms([]);
    });
  }

  // const customerOnchange = (selectedValue: string) => {
  //   const selectedCustomerData = customersData.find((cutomer) => cutomer.customerId == Number(selectedValue));
  //   setSelectedCustomer(selectedCustomerData);
  // }

  const clearCustomerDependentData = () => {
    form.setFieldsValue({ cusAddress: '' });
  }

  const endcustomerOnchange = (selectedValue: string) => {
    const selectedendCustomerData = endcustomersData.find((endcutomer) => endcutomer.endCustomerId == Number(selectedValue));
    form.setFieldsValue({ delAddress: selectedendCustomerData.endCustomerId });
  }
  const clearEndCustomerDependentData = () => {
    form.setFieldsValue({ delAddress: '' });
  }

  const countryOnchange = (selectedValue) => {
    getFinalDestinationData(selectedValue)
    

  }
  const destinationOnchange = (selectedValue: string) => {

  }

  // const formProps = {
  //   initialValues: initialData.saleOrderId ? initialData : undefined
  // }

  // const customerOnchange=(value) =>{
  //   console.log(value.format('YYYY-MM'));
  //   setSelectedMonth(value.format('YYYY-MM'))
  // }
  const customerOnchange = (value) => {
    setCustomerAddress(undefined);
    getCustomerAddress(value);
  }

  const getAddress = (value) => {
    setEndCustomerAddress(undefined);
    getendCustomerAddress(value);
  }

  const saveCategory = (val,row) => {
    props.categoryValue(val)
    // console.log(row)
  }

  const getendCustomerAddress = (value) => {
    endcustomerservice.getEndCustomerDataById({endCustomerId:value,isActive:true}).then((res) => {
      if (res.status) {
        setEndCustomerAddress(res.data.addressInfo);
        props.brandDetails(res.data.brandsInfo);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setEndCustomerAddress(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEndCustomerAddress(undefined);
    });
  }

  const getCustomerAddress = (value) => {
    customersService.getCustomerDataById({custId:value}).then((res) => {
      if (res.status) {
        setCustomerAddress(res.data.cusAddressInfo);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setCustomerAddress(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setCustomerAddress(undefined);
    });
  }

  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setFilelist([]);
      props.uploadFileList([])

    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(csv|xlsx|xls|pdf|zip)$/)) {
        AlertMessages.getErrorMessage("Only xlsx,csv,xls,pdf files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
          return true;
        } else {
          setFilelist([...filelist, file]);
          props.uploadFileList([...filelist, file])
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };

  const storeCurrency = (val,rowData) => {
props.currency(val)
  }

  const hideCustomerDetails =  async (val) => {
    if(customersData.length > 0 && endcustomersData.length > 0 && countriesData.length > 0 && deliveryTerms.length > 0 && paymentTerms.length > 0 && shippingTerms.length > 0 && currency.length > 0){

      if(val == SoProcessTypesEnum.MTO || val == SoProcessTypesEnum.SAMPLES){
        // alert('MTO');
        setCustomerHidden(false)
        form.resetFields();
        form.setFieldsValue({soProcessType:val})
        form.setFieldsValue({soType:SoTypesEnum.DISPATCH})
        setSaletypeDisable(false)
  
      }else{
        // alert('MTS');
        form.setFieldsValue({soType:SoTypesEnum.STOCK})
        setCustomerHidden(true)
        setSaletypeDisable(true)
        await getFinalDestinationData(4)
        // console.log(destinationData)
  
        const customerNA = customersData.find(item => item.clientName == 'Sandhya Aqua');
        // await getCustomerAddress(customerNA.custId);
        console.log(customerAddress);
        // form.setFieldsValue({customerId:customerNA.custId});
  
        const endcustomerNA = endcustomersData.find(item => item.endCustomerName == 'Sandhya Aqua');
        console.log(endcustomerNA);
        await getendCustomerAddress(endcustomerNA.endCustomerId)
  
        // form.setFieldsValue({endCustomerId:endcustomerNA.endCustomerId});
  
        // const invDateNA = '2021-02-01';
        const cusPONA = 'N/A';
        const buyerPONA = 'N/A';
        const countryNA = countriesData.find(item => item.countryName == 'INDIA');
        const delTermsNA = deliveryTerms.find(item => item.deliveryTermsName == 'N/A');
        const payTermsNA = paymentTerms.find(item => item.paymentTermsName == 'N/A');
        const shipTermaNA = shippingTerms.find(item => item.shipmenttermName == 'N/A');
        const currencyNA = currency.find(item => item.currencyName == 'INR');
        form.setFieldsValue({customerId:customerNA.custId});
        form.setFieldsValue({edlDate:moment()});
        form.setFieldsValue({edaDate:moment().add(15, "days")});
        form.setFieldsValue({endCustomerId:endcustomerNA.endCustomerId});
        form.setFieldsValue({poNumber:cusPONA});
        form.setFieldsValue({buyerPoNumber:buyerPONA});
        form.setFieldsValue({cusAddress:'LAWSONS BAY COLONY VISAKHAPATNAM,ANDHRA PRADESH INDIA'});
        form.setFieldsValue({delAddress:'LAWSONS BAY COLONY VISAKHAPATNAM,ANDHRA PRADESH INDIA'});
        form.setFieldsValue({country:countryNA.countryId});
        form.setFieldsValue({destination:23});
        form.setFieldsValue({deliveryTerms:delTermsNA.deliveryTermsId});
        form.setFieldsValue({payTermId:payTermsNA.paymentTermsId});
        form.setFieldsValue({shipmentTerms:shipTermaNA.shiptermId});
        form.setFieldsValue({currency:currencyNA.currencyId});
        console.log(form.getFieldsValue())
        setCustomerHidden(true)
  
      }
    }
  }

  const handleSubContractor = (val) => {

    console.log(val);
    getPlantsData(val);
  }
  const soTypeOnChange =(val) => {
    console.log(val);
    if(val == SoTypesEnum.DISPATCH){
      setEtdValidationState(true);
    }else{
      setEtdValidationState(false);
    }

  }
  

  return (
    <>
    <Card title={<span style={{ color: 'white' }}>Customer Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout={"vertical"} form={form} autoComplete="off" >
      <Row >
{console.log(localStorage.getItem('role'))}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="soProcessType" label="SO Process Type" rules={[{ required: true, message: 'Missing SO Process Type' }]}>
            <Select
                placeholder="Select SO Process Type"
                allowClear
                disabled={props.jobType==JobTypesEnum.MAIN_JOB ? sotypeDisable : subPoDisable}
                onChange={hideCustomerDetails}

              >
                {ProcessTypeInput.map(dropData => {
                  return <Option value={dropData.value}>{dropData.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item name="soType" label="SO Type" rules={[{ required: true, message: 'Missing SO Type' }]}>
            <Select
                placeholder="Select SO Type"
                allowClear
                disabled={props.jobType==JobTypesEnum.MAIN_JOB ? saletypeDisable:subPoDisable}
                onChange={soTypeOnChange}

              >
                {soTypesInput.map(dropData => {
                  return <Option value={dropData.value}>{dropData.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item initialValue={"Main Job"} name="jobType" label="Job Type" rules={[{ required: true, message: 'Missing Job Type' }]}>
            <Select
                showSearch
                defaultValue={props.jobType==JobTypesEnum.MAIN_JOB ?"Main Job":"Sub Job"}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Job Type"
                allowClear
                disabled
                // onChange={saveCategory}
                // disabled={updateDisableField}
              >
                {jobTypes.map(jobTypedropData => {
                  return <Option value={jobTypedropData.value}>{jobTypedropData.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item initialValue={'NO'} name="isSubContract" label="Is Sub Contract" rules={[{ required: true, message: 'Missing sub contract' }]}>
            <Select
                placeholder="Select"
                allowClear
                disabled={subPoDisable}
                onChange={handleSubContractor}
            >
                <Option value="yes">YES</Option>
                <Option value="no">NO</Option>

            </Select>
            </Form.Item>
          </Col>
          
          </Row>
          {console.log(soType)}
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="customerId" label="Customer"  rules={[{ required: true, message: 'Missing customer name' }]}>
              <Select
                showSearch
                placeholder="Select customer"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={customerOnchange}
                onClear={clearCustomerDependentData}
                onSearch={onSearch}

                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden:subPoDisable}
              >
                {customersData.map(dropData => {
                  return <Option value={dropData.custId}>{dropData.clientName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item name="endCustomerId"  label="End Customer Name" rules={[{ required: true, message: 'Missing end customer name' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select end customer"
                onChange={getAddress}
                onClear={clearEndCustomerDependentData}
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden:subPoDisable}
              >
                {/* temperory */}
                 {/* {endCustomers.map(dropData => {
                  return <Option value={dropData.value}>{dropData.name}</Option>
                })} */}
                {endcustomersData.map(dropData => {
                  return <Option value={dropData.endCustomerId}>{dropData.endCustomerName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item name="salePersonId"  label="Sale Person Name" rules={[{ required: true,message: 'Missing sale person' }]}>
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select sale person"
                allowClear
                disabled={subPoDisable}
              >
                {employeeData.map(dropData => {
                  return <Option value={dropData.employeeId}>{`${dropData.employeeName}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item name="invoiceDate"  label="Date" rules={[{ required: true,message: 'Missing Invoice date' }]} initialValue={moment()}>
              <DatePicker disabled={subPoDisable} onChange={() => { }}  style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="poNumber"  label="Customer PO" rules={[{ required: true,message: 'Missing PO' }]}>
              <Input disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden:subPoDisable}/>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="buyerPoNumber"  label="Buyer PO" rules={[{ required: true,message: 'Missing PO' }]}>
              <Input disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}/>
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="cusAddress"  label="Customer Address" rules={[{ required: true,message:'Customer Address Required' }]}>
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select customer address"
                // onChange={customerOnchange}
                // onClear={clearCustomerDependentData}
                allowClear
                dropdownMatchSelectWidth={false}
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}

              >
                {customerAddress===undefined?'': customerAddress.map(dropData => {
                  return <Option value={dropData.addLineOne+ ',' + dropData.addLineTwo+ ',' +dropData.addLineThree+ ',' +dropData.addLineFour}>{dropData.addLineOne+ ',' + dropData.addLineTwo+ ',' +dropData.addLineThree+ ',' +dropData.addLineFour}</Option>
                })}
              </Select>
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="delAddress"  label="Delivery Address" rules={[{ required: true,message: 'Missing Delivery address' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select end customer address"
                // onChange={customerOnchange}
                // onClear={clearCustomerDependentData}
                dropdownMatchSelectWidth={false}
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {endCustomerAddress===undefined?'': endCustomerAddress.map(dropData => {
                  return <Option value={dropData.addLineOne+ ',' + dropData.addLineTwo+ ',' +dropData.addLineThree+ ',' +dropData.addLineFour}>{dropData.addLineOne+ ',' + dropData.addLineTwo+ ',' +dropData.addLineThree+ ',' +dropData.addLineFour}</Option>
                })}
              </Select>
              {/* <Input /> */}
            </Form.Item>
          
            
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="portOfEntry"  label="Port of Entry" rules={[{ required: true,message: 'Missing port of entry ' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Port"
                onChange={destinationOnchange}
                allowClear
                disabled={subPoDisable}
              >
                {portOfEntries.map(dropData => {
                  return <Option value={dropData.value}>{dropData.name}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="country" label="Final Country" rules={[{ required: true,message: 'Missing country' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Country"
                onChange={countryOnchange}
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {countriesData.map(dropData => {
                  return <Option value={dropData.countryId}>{dropData.countryName}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="destination"  label="Final Destination" rules={[{ required: true,message: 'Missing destination' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Destination"
                onChange={destinationOnchange}
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {destinationData.map(dropData => {
                  return <Option value={dropData.destinationId}>{dropData.destinationName}</Option>
                })}
              </Select>
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item  name="edlDate" label="ETD" tooltip="Estimated Time Of Departure" rules={[{ required: etdValidationState,message: 'Missing ETD date' }]}>
              <DatePicker onChange={(date) => {
                if(form.getFieldValue('edaDate')<date){
                  AlertMessages.getErrorMessage('ETD must be less than ETA');form.setFieldsValue({edlDate:null})}
                  if(form.getFieldValue('invoiceDate')>date){
                    AlertMessages.getErrorMessage('ETD must be greater than Invoice date');form.setFieldsValue({edlDate:null})
                  }
                }} 
                style={{ width: '100%' }}
                disabled = {customerHidden} />
            </Form.Item>

          
            
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="edaDate" label="ETA"  tooltip="Estimated Time Of Arrival" rules={[{ required: etdValidationState,message: 'Missing ETA date' }]}>
              <DatePicker  onChange={(date) => {if(form.getFieldValue('edlDate')>date){AlertMessages.getErrorMessage('ETA must be greater than ETD ');form.setFieldsValue({edaDate:null})}}} 
              style={{ width: '100%' }}
              disabled = {customerHidden} 
              />
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="deliveryTerms"  label="Delivery Terms" rules={[{ required: true,message: 'Missing delivery terms' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Delivery terms"
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {deliveryTerms.map(dropData => {
                  return <Option value={dropData.deliveryTermsId}>{dropData.deliveryTermsName}</Option>
                })}
              </Select>
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="payTermId"  label="Payment Terms" rules={[{ required: true ,message: 'Missing payment terms'}]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Payment Terms"
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {paymentTerms.map(dropData => {
                  return <Option value={dropData.paymentTermsId}>{dropData.paymentTermsName}</Option>
                })}
              </Select>
            </Form.Item>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
          <Form.Item name="shipmentTerms"  label="Shipment Terms" rules={[{ required: true,message: 'Missing shipment terms' }]} >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Shipment terms"
                allowClear
                disabled = {props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {shippingTerms.map(dropData => {
                  return <Option value={dropData.shiptermId}>{dropData.shipmenttermName}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>
        </Row>
        <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
        <Form.Item name="processingUnit"   label="Processing Unit" rules={[{ required: true,message: 'Missing Unit' }]} >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Processing Unit"
                allowClear
                disabled={isProcessingUnitDisable}
              >
                {(props.jobType == JobTypesEnum.MAIN_JOB ||props.jobType == 'Clone') ? processingFactoriesData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                }) : processingFactoriesData.filter(item => item.plantId != props.intialData?.processingUnit).map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
        <Form.Item name="unit"   label="Manufacturing Unit" rules={[{ required: true,message: 'Missing Unit' }]} >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                disabled={isManufacturingUnitDisable}
              >
                {factoriesData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }}>
          <Form.Item name="currency"  label="Currency" rules={[{ required: true,message: 'Missing currency' }]} >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Currency"
                allowClear
                onChange={storeCurrency}
                disabled={props.jobType==JobTypesEnum.MAIN_JOB ? customerHidden : subPoDisable}
              >
                {currency.map(currencydropData => {
                  return <Option value={currencydropData.currencyId}>{currencydropData.currencyName}</Option>
                })}
              </Select>
            </Form.Item>
            
          </Col>
          
          <Col span={8}>
          <Form.Item name = "fileUpload" >

      <Upload {...uploadFieldProps}>
        <br/><Button style={{ color: "black", backgroundColor: "#7ec1ff" }} icon={<UploadOutlined />}>Upload Work Order & Code List</Button><p>{props.intialData ? uploadedFileName?`Uploaded File - ${uploadedFileName}`:'':''}</p><Typography.Text  type="secondary">
        (Supported formats xlsx,csv,xls,pdf,zip)
                </Typography.Text>
      </Upload>
      </Form.Item>

          </Col>
          
        </Row>

      </Form>
    </Card></>)
}
export default CustomerDetailsForm
