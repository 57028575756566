import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  DownTimeTrackingDto, EmployeeUnitRequest } from '@gtpl/shared-models/masters';
import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import { DownTimeTrackingForm, NewDowntimeTrackingform } from '@gtpl/pages/master/master-components/down-time-tracking-form'
import './down-time-tracking-grid.css';
import moment from 'moment';
import { DownTimePlannedCategoryenum, DownTimeTrackingStatusEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface DownTimeTrackingGridProps {

}

export function DownTimeTrackingGrid(
  props: DownTimeTrackingGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const columns = useState('');
  // const { formatMessage: fm } = useIntl();

  const downtimetrackingService = new DownTimeTrackingService();
  const [selectedDownTimeTrackingData, setSelectedDownTimeTrackingData] = useState<any>(undefined);
  const [downtimeTrackingData, setDownTimeTrackingData] = useState<DownTimeTrackingDto[]>([]);
  const [selectedDowntimeTracking, setSelectedDowntimetracking] = useState<DownTimeTrackingDto>();
  const [downTimeTypeVal, setDownTimeTypeVal] = useState<DownTimePlannedCategoryenum>(DownTimePlannedCategoryenum.UN_PLANNED)


  /**
   * used for column filter
   * @param dataIndex column data index
   */ 

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      // width:'150px',
      render: (text,rowData) => {return rowData.plant},
            sorter: (a, b) => a.plant?.localeCompare(b.plant),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('plant')
    },
    {
      title: 'Downtime Type',
      dataIndex: 'downtimeType',
      // width:'150px',
      render: (text,rowData) => {return rowData.downtimeType},
            sorter: (a, b) => a.downtimeType?.localeCompare(b.downtimeType),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('downtimeType')
    },
    {
      title: 'Asset Category',
      dataIndex: 'assetCategory',
      // width:'150px',
      render: (text,rowData) => {return rowData.assetCategory},
            sorter: (a, b) => a.assetCategory?.localeCompare(b.assetCategory),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetCategory')
    },
    {
      title: 'Asset Location',
      dataIndex: 'assetLocation',
      // width:'150px',
      render: (text,rowData) => {return rowData.assetCategory},
            sorter: (a, b) => a.assetLocation?.localeCompare(b.assetLocation),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetLocation')
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetCode',
      // width:'150px',
      render: (text,rowData) => {return rowData.assetCode},
            sorter: (a, b) => a.assetCode?.localeCompare(b.assetCode),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetCode')
    },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      width:'150px',
      render: (text,rowData) => {return rowData.assetName},
            sorter: (a, b) => a.assetName?.localeCompare(b.assetName),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetName')
    },
    {
      title: 'DownTime Sla',
      dataIndex: 'assetSla',
      align:'right',
      width:'140px',
      render: (text,rowData) => {return rowData.assetSla},
            sorter: (a, b) => a.assetSla-b.assetSla,

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetSla')
    },
    {
      title: 'Priority',
      dataIndex: 'assetPriority',
      width:'90px',
      render: (text,rowData) => {return rowData.assetPriority},
            sorter: (a, b) => a.assetPriority?.localeCompare(b.assetPriority),

      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetPriority')
    },
    // {
    //   title: 'DownTimeReason',
    //   dataIndex: 'reason',
    //   render: (text,rowData) => {return rowData.reason},
    //         sorter: (a, b) => a.reason?.localeCompare(b.reason),

    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('reason')
    // },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.category.trim().localeCompare(b.category.trim()),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('category')
    // },
    // {
    //   title: 'Impact',
    //   dataIndex: 'impact',
    //   // sorter: (a, b) => a.impact-b.impact,
    //   sorter: (a, b) => a.impact?.localeCompare(b.impact),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('impact')
    // },
   
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      width:'150px',
      sorter: (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix(),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('startTime'),
      render: (value,record) => {
        return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
      }
    },
   
    {
      title: 'Time Duration',
     // dataIndex: 'diffhours', 
     width:'150px',
       render: (value, record) => {
        // const date1 : any= new Date(record?.startTime);
        // const date2 : any = new Date();
        //  const diff : any =new Date(date2-date1);
        //   const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
        //   const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes   
        //   const totalmins = diffMins + (diffHrs * 60)
        //   const totalvalue =  diffHrs + ':'+ diffMins        
           const obj :any = {
             children: (<div style={{ textAlign: 'right' }}>{record.diffhours}</div>),
            props:{
              style:{ background:record.duration <= record.assetSla ? "#38f438": record.duration>record.assetSla && record.duration<=60 ? "#FFFF00":'#FF0000' , color:record.assetSla <= record.assetSla? "black":'black'}
            },
          };
          return obj;
         
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      align:'center',
      // responsive: ['lg'],
      sorter: (a, b) => a.status.trim().localeCompare(b.status.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    },

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>      
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              console.log(rowData,'row data')
              if(rowData.status == DownTimeTrackingStatusEnum.CLOSE) return AlertMessages.getInfoMessage('You cannot edit closed item')
              if (rowData.isActive && (rowData.Status != DownTimeTrackingStatusEnum.CLOSE)) {
                openFormWithData(rowData);
              }else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated DownTimeTracking');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
          </Tooltip> 
          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteDownTimeTracking(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate DownTimeTracking ?'
                : 'Are you sure to Activate DownTimeTracking ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    getAllDownTimeTrackings();
   setInterval(getAllDownTimeTrackings,3*60*1000);
   }, [])

  
  const getAllDownTimeTrackings = () => {
    const req = new EmployeeUnitRequest(Number(localStorage.getItem('unit_id')))
    downtimetrackingService.getAllDownTimeTrackings(req).then(res => {
      if (res.status) {
        setDownTimeTrackingData(res.data);
      } else {
        if (res.intlCode) {
          setDownTimeTrackingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDownTimeTrackingData([]);
    })
  }

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (downTimeTrackingViewData: DownTimeTrackingDto) => {
    // console.log("downTimeTrackingViewData start time"+downTimeTrackingViewData.startTime)
    downTimeTrackingViewData.startTime = moment(downTimeTrackingViewData.startTime);
    downTimeTrackingViewData.machineName!=null? downTimeTrackingViewData.machineName:null;
    // downTimeTrackingViewData.downtimetype? downTimeTrackingViewData.downtimetype:downTimeTrackingViewData.downtimetype
    // console.log("downTimeTrackingViewData start time"+downTimeTrackingViewData.startTime)
    downTimeTrackingViewData.endtime = downTimeTrackingViewData.endtime != null ? moment(downTimeTrackingViewData.endtime): null;
   // console.log(moment(str,downTimeTrackingViewData.startTime))
    setDrawerVisible(true);
    setSelectedDowntimetracking(downTimeTrackingViewData);
  }
 

  /**
      * 
      * @param variantData 
      */
  const updateDownTimeTracking = (downtimetrackingData: DownTimeTrackingDto) => {
    console.log(downtimetrackingData,'update dto')
    downtimetrackingService.updateDownTimeTracking(downtimetrackingData).then(res => {
      // console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllDownTimeTrackings();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
    * 
    * @param VariantViewData 
    */
  const deleteDownTimeTracking = (downtimetrackingViewData: DownTimeTrackingDto) => {
    downtimetrackingViewData.isActive = downtimetrackingViewData.isActive ? false : true;
    downtimetrackingService.activateOrDeactivateDownTimeTracking(downtimetrackingViewData).then(res => {
      // console.log(res);
      if (res.status) {
        getAllDownTimeTrackings();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  return (
    <Card title={<span style={{ color: 'white',fontSize:'20px'}}>DownTime Tracking</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/down-time-tracking-new-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <Row gutter={40}>
        <Col>
          <Card title={'Open: ' + downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.OPEN).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
         <Col>
          <Card title={'WIP: ' + downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.WIP).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Pending: ' + downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.PENDING).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row><br></br>
      <Card >
        {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}

        <Table
          rowKey={record => record.downtimeTrackingId}
          columns={columnsSkelton}
          dataSource={downtimeTrackingData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x: true }}
          onChange={onChange}
          size='small'
          bordered />
      </Card>
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '80%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <NewDowntimeTrackingform 
           key={Date.now()}
            updateDetails={updateDownTimeTracking}
            isUpdate={true}
            downtimeTrackingData={selectedDowntimeTracking}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );

}

export default DownTimeTrackingGrid;

