import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

export enum CumulativeTypes{
    OCCURENCE='OCCURENCE',
    VALUE='VALUE'
}

export class CumulativeModel{
    dataIndex:string;
    lableName:string;
    lablecolor:string;
    cumulativeType:CumulativeTypes;
}


export interface CumulativeProps{
    cumulativeColumns:CumulativeModel[],
    data:any[]
}

function GetCumulatives(props:CumulativeProps) {
    let [cumulatives,setCumulatives]=useState<Map<string,number>>(new Map());

    useEffect(() => {
        setCumulativeData();
    }, [props.cumulativeColumns]);

    const setCumulativeData=()=>{
        const cumulativesClone: Map<string, number>=new Map();
       props.cumulativeColumns.map(cumlativeObj=>{
           if(!cumulativesClone.has(cumlativeObj.dataIndex)){
            cumulativesClone.set(cumlativeObj.dataIndex,0);
           }
       });
       for(const data of props.data){
            for(const cumlativeObj of props.cumulativeColumns){
                let previousValue=cumulativesClone.get(cumlativeObj.dataIndex);
                let incrementValue=0;
                if(data.hasOwnProperty(cumlativeObj.dataIndex)){
                    incrementValue=(cumlativeObj.cumulativeType == CumulativeTypes.OCCURENCE)?1:Number.isNaN(data[cumlativeObj.dataIndex])?Number(data[cumlativeObj.dataIndex]):0;
                }
              cumulativesClone.set(cumlativeObj.dataIndex,(previousValue+incrementValue));    
            }
       }
       setCumulatives(cumulativesClone);
    }

    return (<div>
    <Row justify={props.cumulativeColumns.length>3 ? "space-around" : "start"}>
      {props.cumulativeColumns.map(lableObj=>{
          return <Col span={4} offset={props.cumulativeColumns.length>3 ? 0 :1}>
          <Card title={<h4 style={{ color: 'white' }}>{lableObj.lableName}: {cumulatives.get(lableObj.dataIndex)}</h4>} style={{textAlign: 'left', width: 200, height: 55,backgroundColor:`${lableObj.lablecolor}`}}></Card>
          </Col>
      }
      )}
    </Row>


    </div>)
}

export default GetCumulatives
