import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService } from '@gtpl/shared-services/masters'
import { UnitcodeDto, UnitTypeEnum } from '@gtpl/shared-models/masters'
const { Option } = Select;

import './unit-code-form.css';

/* eslint-disable-next-line */
export interface UnitCodeFormProps {
  unitcodeData: UnitcodeDto;
  updateUnitcode: (department: UnitcodeDto) => void;
  isUpdate: boolean;
  closeForm: () => void;

}

export function UnitCodeForm(props: UnitCodeFormProps) {
  const [form] = Form.useForm();
  let history = useHistory();


  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const [disable, setDisable] = useState<boolean>(false)
  const unitcodeDataService = new UnitcodeService()

  const createUnitcode = (unitcodeData: UnitcodeDto) => {
    unitcodeData.createdUser=localStorage.getItem('username')
    setDisable(true)

    unitcodeDataService.createUnitcode(unitcodeData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Unitcode Created Successfully');
        history.push("/unitcode-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param values //Dto values
   */

  const saveData = (values: UnitcodeDto) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateUnitcode(values);
    } else {
      setDisable(false)
      createUnitcode(values);
    }

  };
  /**
      * To reset form fields
      */
  const onReset = () => {
    console.log('');
    form.resetFields();

  }

  return (
    <Card title={<span style={{ color: 'white' }}>Plants</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/unitcode-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>}
    >
      <Form form={form} initialValues={props.unitcodeData} name="control-hooks" onFinish={saveData} layout="vertical">
        <Form.Item name="unitcodeId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="unitcodeName"
              label="Plant Name"

              rules={[
                {
                  required: true,
                  message: "Plant Name is required"
                },
                // {
                //   pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                //   message: `Should contain only alphabets.`
                // }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="plantCode"
              label="Plant Code"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                  message: `Don't Allow letters and Spaces`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item name="plantType" label=" Plant Type" rules={[{ required: true, message: 'Missing Plant Type' }]} >
              <Select
                placeholder="Select Plant Type"
                allowClear
              >
                <Option key={0} value={null}>Select Plant Type</Option>
                <Option key={1} value={UnitTypeEnum.PLANT}>{UnitTypeEnum.PLANT}</Option>
                <Option key={2} value={UnitTypeEnum.SUB_PLANT}>{UnitTypeEnum.SUB_PLANT}</Option>
                <Option key={3} value={UnitTypeEnum.SUB_CONTRACTOR_PLANT}>{UnitTypeEnum.SUB_CONTRACTOR_PLANT}</Option>


              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="villageName"
              label="Village"
              rules={[
                {
                  required: true,
                  message: "Village Name is required"
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="mandalName"
              label="Mandal"
              rules={[
                {
                  required: true,
                  message: "Mandal Name is required"
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
                name="distirctName"
                label="District"
                rules={[
                  {
                    required: true,
                    message:"Distirct Name is required"
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>



          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="stateName"
              label="State"
              rules={[
                {
                  required: true,
                  message: "State Name is required"
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="countryName"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country Name is required"
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="postalCode"
              label="Postal Code"
              rules={[
                {
                  required: true,
                },
                {
                  max: 6,
                  message: 'Postal Code should not exceed 6 digits'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Letters , Spaces and Special charecters are not aloowed`,
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="approvalNo"
              label="Approval Number"
              rules={[
                {
                  required: true,
                },
                {
                  message: "Approval Number is required"
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="fdaRegNumber"
              label="FDA Reg Number"
              rules={[
                {
                  required: true,
                },
                {
                  message: "FDA Reg Number is required"
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="feiNumber"
              label="FEI Number"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="shortKey"
              label="Short Key"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "ShortKey is required"
                }
              ]}
            >
              <Input
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="bapNo"
              label="BAP No"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "BAP NO is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="ascNo"
              label="ASC No"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "ASC NO is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="walmartId"
              label="Walmart ID"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "Walmart ID is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="fishInapprovalNo"
              label="Fishin Approval No"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "Fishin Approval No is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="krogerNo"
              label="Kroger No"
              // style={{display:"none"}}
              rules={[
                {
                  required: true,
                },
                {
                  message: "Kroger No No is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(!props.isUpdate) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  );
}





export default UnitCodeForm;
