import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { lotCodeDashTableDto } from '@gtpl/shared-models/production-management';
import { LotCodeOpsService, ProductionInventoryService } from '@gtpl/shared-services/production';
import moment from 'moment';
import { ProdInvResponseModel } from '@gtpl/shared-models/production-management';


export interface LotCodeTableProps { }
export function LotCodeDetailsTable(ProdInvResponseModel: LotCodeTableProps) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);


    const service = new ProductionInventoryService();
    const [operationWiseLotsInfo, setOperationWiseLotsInfo] = useState<any[]>([]);

    /**
     * used for column filter
     * @param dataIndex column data index
     */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const columnsSkelton: ColumnProps<any>[] = [
       
        {
            title: 'Operation',
            dataIndex: 'operation',
            

        },
        {
            title: 'Lots',
            dataIndex: 'lots',
            
            // responsive: ['lg'],

        },
        {
            title: 'Ready For Job',
            dataIndex: 'inventory',
            render: (text, data, index) => {
                return <span>{Number(text).toLocaleString('en-IN', undefined)}</span>
              }
        },
        {
            title: 'WIP',
            dataIndex: 'wipQty',
            render: (text, data, index) => {
                return <span>{Number(text).toLocaleString('en-IN', undefined)}</span>
              }
        },
        {
            title: 'Rejected Quantity',
            dataIndex: 'reqQty',
        },
    ];
    /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    useEffect(() => { getOperationWiseLotsInfo(); }, [])

    const getOperationWiseLotsInfo = () => {

        service.getOperationWiseLotsInfo().then(res => {
            if (res.status) {
                setOperationWiseLotsInfo(res.data);
            } else {
                if (res.intlCode) {
                    setOperationWiseLotsInfo([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setOperationWiseLotsInfo([]);
        })
    }



    return (
        <Table

            rowKey={record => record.lotCodeTrackingId}
            columns={columnsSkelton}
            dataSource={operationWiseLotsInfo}

            scroll={{ x: true }}
            // onChange={onChange}
            pagination={false}
            size='small'
            bordered />

    );

}
export default LotCodeDetailsTable;
