import React, { useEffect, useRef, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';



import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';

import { CodeListMainService } from '@gtpl/shared-services/planning';
import { CodeListMainDto } from '@gtpl/shared-models/planning'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CodeListMainForm } from '@gtpl/pages/planning/planning-components/code-list-main-form'
import Highlighter from 'react-highlight-words';



import './code-list-main-grid.css';
import CodeListPrint from './code-list-print';
import CodeListPrintbeforeSubJob from './code-list-print-two';
import { any } from 'prop-types';
import { UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface CodeListMainGridProps { }

export function CodeListMainGrid(
  props: CodeListMainGridProps
) {
  const [codeListData, setCodeListData] = useState<CodeListMainDto[]>([]);
  const [selectedCodeListData, setSelectedCodeListData] = useState<any>(undefined);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [selectedInformation, setSelectedInformation] = useState<CodeListMainDto>();
  const [codeListMainId, setCodeIdListState] = useState<number>(null);
  const [modal, setModal] = useState('')
  const codeListService = new CodeListMainService();

  useEffect(() => {
    getCodeListsData();
  }, []);

  const getCodeListsData = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    codeListService.getAllCodeLists(req).then(res => {
      if (res.status) {
        console.log(res)
        setCodeListData(res.data);
      } else {
        if (res.intlCode) {
          setCodeListData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCodeListData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  const openFormWithData = (viewData: CodeListMainDto) => {
    console.log('open form')
    setDrawerVisible(true);
    setSelectedCodeListData(viewData);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const displayModel = (data: CodeListMainDto, modalName) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
    setModal(modalName)

  };



  const detail = (data: CodeListMainDto) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const setCodeListId = (rowdata) => {
    setCodeIdListState(rowdata.codeListMainId)
  }


  /**
  * 
  * @param productData 
  */
  // const deleteProduct = (codeListData:CodeListMainDto) => {
  //   codeListData.isActive=codeListData.isActive?false:true;
  //   codeListService.activateOrDeactivateProduct(codeListData).then(res => { console.log(res);
  //     if (res.status) {
  //       getCodeListsData();
  //       AlertMessages.getSuccessMessage('Success'); 
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  /**
    * 
    * @param variantData 
    */
  // const updateCodeList = (codeListData: CodeListMainDto) => {
  //   codeListService.updateCodeList(codeListData).then(res => { console.log(res);
  //     if (res.status) {
  //       AlertMessages.getSuccessMessage('Updated Successfully');
  //       getCodeListsData();
  //       setDrawerVisible(false);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'SO Number',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (text, object, index) => {
        return object.jobType ? (text + " (" + object.jobType + ")") : (text)
      }
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      // responsive: ['lg'],
      sorter: (a, b) => a.countryName?.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('countryName')
    },
    {
      title: 'Buyer',
      dataIndex: 'endCustomerName',
      // responsive: ['lg'],
      sorter: (a, b) => a.endCustomerName?.localeCompare(b.endCustomerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerName')
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      // responsive: ['lg'],
      sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('brandName')
    },

    {
      title: 'Total Cartons',
      dataIndex: 'totalCartons',
      // responsive: ['lg'],
      sorter: (a, b) => a.totalCartons - b.totalCartons,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('productCode')
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openFormWithData(rowData);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
          <Divider type='vertical' />
          <Tooltip placement="top" title='Before Subjob'>
            <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                displayModel(rowData, 'printone')
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
         {Number(rowData.count) > 1 ? <> <Divider type='vertical' />
          <Tooltip placement="top" title='After SubJob'>
            <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                displayModel(rowData, 'printtwo')

              }}
              style={{ color: 'green', fontSize: '14px' }}
            />
          </Tooltip> </> :<></>}
          {/* <Divider type='vertical' />
          {(codeListMainId) ? <Redirect
            to={{
              pathname: "/code-list-detailView",
              state: codeListMainId
            }}
          /> : null}
          <EyeOutlined type="view"
            onClick={() => {
              setCodeListId(rowData);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /> */}
        </span>
      )
    }



  ];
  return (
    <>

      <Card title={<span style={{ color: 'white' }}>Code List Allocation</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/code-list-main-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >


        <br></br>
        {/* <Row gutter={40}>
        <Col>
          <Card title={'Total Code Lists: ' + codeListData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + codeListData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + codeListData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row> */}
        <Row justify='end' >
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
          <p style={{paddingTop : '4px'}}> -- Print before subjob data</p>
          <Divider type='vertical' />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            style={{ color: 'green', fontSize: '14px' }}
          />
          <p style={{paddingTop : '4px'}} > -- Print after subjob data</p>
        </Row>
        <br></br>
        <Table
          rowKey={record => record.codeListId}
          columns={columnsSkelton}
          dataSource={codeListData}
          // scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          // onChange={onChange}
          bordered />

        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '90%' : '95%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <CodeListMainForm key={Date.now()}
              isUpdate={true}
              // saveItem={saveVariant}
              selectedData={selectedCodeListData}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
      </Card>
      <Modal key={Date.now()} visible={isModalVisible} onCancel={() => setIsModalVisible(false)} closable width={'80%'} footer={null}>
        {
          modal == 'printone' ?
            <CodeListPrint selectedData={selectedInformation} />
            : <CodeListPrintbeforeSubJob selectedData={selectedInformation} />

        }
      </Modal>

    </>
  );
}

export default CodeListMainGrid;
