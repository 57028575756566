import { GetStoreConsumptionItemsDto } from "@gtpl/shared-models/procurement-management";
import { CrrencySymbols, SOItemsDto, UnitsOfWeightInput } from "@gtpl/shared-models/sale-management";
import { StoreConsumptionService } from "@gtpl/shared-services/procurement";
import { CaretRightOutlined, SearchOutlined, FilterOutlined, EditOutlined, RotateRightOutlined, RedoOutlined } from '@ant-design/icons'
import { saleOrder, SaleOrderService } from "@gtpl/shared-services/sale-management";
import { Button, Card, Input, Modal, Table, Tooltip } from "antd";
import { ColumnProps, ColumnsType } from "antd/lib/table";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import React, { useEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import ReProcess from "./re-processing-rm-stock";
import RePacking from "./repacking-rm-stock";


export interface SaleOrderItemProps {
  saleOrderId: number;
  currencyId: number;
}

function SaleOrderItemDetails(
  props: SaleOrderItemProps
) {

  const service = new SaleOrderService;
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [isReProcess, setIsReProcess] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null);


  useEffect(() => {
    getAllSaleOrderItems();
  }, [])

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    console.log( dataIndex)
    console.log( confirm)
    console.log(selectedKeys)
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  

  const displayModel = (type, data) => {
    console.log(data);
    if (type == 'ReProcess') {
      setIsReProcess(true)
    } else {
      setIsReProcess(false)
    }
    setIsModalVisible(true);
    setSelectedSoItem(data);
  };

  const getAllSaleOrderItems = () => {
    console.log(props.saleOrderId)
    service.getAllSaleOrderItemDetails(new saleOrder(props.saleOrderId)).then(res => {

      if (res.status) {
        for (const saleDetail of res.data){
          const uomData = UnitsOfWeightInput.find(uom => uom.value == Number(saleDetail.uomId));
          const itemDescription = `${saleDetail.varientId} ${saleDetail.pouches} * ${Number(saleDetail.pouchWeight)} ${uomData.name}`;
          saleDetail.varientId=itemDescription
        }
        setSoItemData(res.data);
      } else {
        if (res.intlCode) {
          setSoItemData([]);
        } else {
          setSoItemData([]);
        }
      }
    }).catch(err => {
      setSoItemData([]);
    })
  }

  const Columns: ColumnsType<any> = [
    {
      title: 'Brand',
      dataIndex: 'brand',
      width: 150,
      fixed: 'left',
      sorter: (a, b) => a.brand.localeCompare(b.brand),
      ...getColumnSearchProps('brand'),
    },
   
    {
      title: 'Item Description',
      dataIndex: 'varientId',
     // key: 'varientId',
      sorter: (a, b) => a.varientId - b.varientId,
      ...getColumnSearchProps('varientId'),
      render: (text, data, index) => {
        console.log(text, data, index)
        const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
        return <span>{text}</span>
      }
    },
   
    {
      title: 'Grade',
      dataIndex: 'minGrade',
      //key: 'minGrade',
      sorter: (a, b) => a.minGrade-b.minGrade,
      ...getColumnSearchProps('minGrade'),
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${text}-${data.maxGrade} `}</span>
      }
    },
    {
      title: 'Net Case Weight',
      dataIndex: 'caseWeight',
      key: 'caseWeight',
      sorter: (a, b) => a.caseWeight - b.caseWeight,
      // ...getColumnSearchProps('caseWeight'),
      render: (text, data, index) => {
        const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
        return <span>{`${Number(text)} ${uomData.name}`}</span>
      }
    },

    {
      title: 'Cases',
      dataIndex: 'cases',
      key: 'cases',
      sorter: (a, b) => a.cases - b.cases,
      // ...getColumnSearchProps('cases'),
    },
    {
      title: 'Net Quantity',
      dataIndex: 'netWeight',
      key: 'netWeight',
      sorter: (a, b) => a.netWeight - b.netWeight,
      // ...getColumnSearchProps('netWeight'),
      render: (text, data, index) => {
        console.log(text, data, index)
        const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
        return <span>{`${Number(text)} ${uomData.name}`}</span>
      }
    },
    {
      title: 'Soaking',
      dataIndex: 'soakingStyle',
      key: 'soakingStyle',
      sorter: (a, b) => a.soakingStyle - b.soakingStyle,
      ...getColumnSearchProps('soakingStyle'),
      // render: (text, data, index) => {
      //   console.log(text, data, index)
      //   return <span>{`${text} ${data.uomId}`}</span>
      // }
    },
    {
      title: 'Re-Packing Inventory',
      dataIndex: 'rePackQty',
      key: 'rePackQty',
      // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
      // ...getColumnSearchProps('pouchWeight'),
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${text ? text : 0}`}&nbsp;&nbsp;<Tooltip title='Allocate Repacking Stock'><RotateRightOutlined onClick={() => displayModel('RePack', data)} type='edit' style={{ color: 'blue' }}>Re-Pack</RotateRightOutlined></Tooltip></span>
      }
    },
    {
      title: 'Re-Processing Inventory',
      dataIndex: 'reProcessQty',
      key: 'reProcessQty',
      // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
      // ...getColumnSearchProps('pouchWeight'),
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${text ? text : 0}`}&nbsp;&nbsp;<Tooltip title='Allocate Reprocessing Stock'><RedoOutlined onClick={() => displayModel('ReProcess', data)} type='edit' style={{ color: 'red' }}>Re-Process</RedoOutlined></Tooltip></span>
      }
    },
    {
      title: 'Procurement Quantity',
      dataIndex: 'procurementQuantity',
      key: 'procurementQuantity',
      // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
      // ...getColumnSearchProps('pouchWeight'),
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${text ? text : 0}`}</span>
      }
    }
  ];
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Card
        key={props.saleOrderId + props.currencyId}
         style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>SALE ORDER ITEMS </span>}></Card>


      <Table
        columns={Columns}
        dataSource={soItemData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        // onChange={onChange}
        scroll={{ x: true }}
        size="small"
        bordered
      ></Table>
<Modal
          className='print-docket-modal'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          {(isReProcess) ? <ReProcess SoItemData={selectedSoItem} /> : <RePacking SoItemData={selectedSoItem} />}
        </Modal>

    </>
  );

}
export default SaleOrderItemDetails;