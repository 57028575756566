import React from 'react';

import './documents.css';

/* eslint-disable-next-line */
export interface DocumentsProps {}

export function Documents(
  props: DocumentsProps
) {
  return (
    <div>
      <h1>Welcome to documents!</h1>
    </div>
  );
}

export default Documents;
