
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Col } from 'antd';
import React, { useState, useEffect} from 'react';

export interface WayRackStockProps { }

export function WayRackStock(props: WayRackStockProps) {
    const [stockOutData, setStockOutData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getCartonsInWayRacks();
    }, [])

    const getCartonsInWayRacks = () => {
        service.getCartonsInWayRacks({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockOutData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    return (
        <div>
            <Col  xs={24} sm={24} md={6} xl={6}>
            <Card  style={{padding: '26px' , textAlign: 'left', width: 232, height: 63, backgroundColor: '#2e445a',color:'white',borderRadius:'10px',marginTop:'-6px',marginLeft:'-77px'  }}><b style={{marginLeft:'-18px'}}>Way Rack Cartons :{" " + Number(stockOutData[0]?.totalCartons ? stockOutData[0]?.totalCartons : "0").toLocaleString('en-IN')}</b></Card>
            </Col>
        </div>
    )
}
export default WayRackStock;