import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { DepartmentDto, HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { DepartmentService, HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { UserService } from '@gtpl/shared-services/attendance';
import { CreateUserDto } from '@gtpl/shared-models/gtpl';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';

import './departments-form.css';

/* eslint-disable-next-line */
export interface DepartmentsFormProps {
  departmentData: DepartmentDto;
  updateDepartment: (department: DepartmentDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function DepartmentsForm(props: DepartmentsFormProps) {

  const { Option } = Select;
  const [userData, setUserData] = useState<CreateUserDto[]>([]);
  const [setUse, setSelectedUser] = useState<CreateUserDto[]>([]);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [form] = Form.useForm();
  let history = useHistory();
  const userService = new UserService();
  const [display, setDisplay] = useState<string>('none');
  const [disable, setDisable] = useState<boolean>(false);
  const empService = new HrmsEmpDetService();

  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }

  useEffect(() => {
    // getAllActiveUser();
    getAllActiveEmployees();
  }, []);

  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  // const getAllActiveUser = () => {
  //   userService
  //     .getAllActiveUser()
  //     .then((res) => {
  //       if (res) {
  //         // console.log('users info');
  //         // console.log(res.data);
  //         setUserData(res);
  //       } else {
  //         setUserData([]);
  //       }
  //     })
  //     .catch((err) => {
  //       AlertMessages.getErrorMessage(err.message);
  //       setUserData([]);
  //     });
  // };
  console.log(props.departmentData)

  const departmentDataService = new DepartmentService();

  const createDepartment = (departmentData: DepartmentDto) => {
    setDisable(true)
    departmentData.deptHead ? departmentData.deptHead = departmentData.deptHead : null;
    departmentDataService.createDepartment(departmentData)
      .then((res) => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage('Department Created Successfully');
          history.push('/department-view');
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            console.log(res.internalMessage)
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  const saveData = (values: DepartmentDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateDepartment(values);
    } else {
      setDisable(false)
      console.log(values);
      createDepartment(values);
    }
  };

  const handleUser = (value) => {
    setSelectedUser(value);
    if (value == '') {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  console.log(props.departmentData, 'department data')

  return (
    <Card
      title={<span style={{ color: 'white' }}> Departments</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={
        props.isUpdate == true ? (
          ''
        ) : (
          <Link to="/department-view">
            <Button className="panel_button">View </Button>
          </Link>
        )
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={props.departmentData}
        name="control-hooks"
        onFinish={saveData}
      >
        <Form.Item name="deptId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="deptName"
              label="Department Name"
              rules={[
                {
                  required: true,
                  message: 'Department Name is required',
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="deptHead"
              label="Department Head"
              rules={[
                { required: false, message: 'Department Head is required' },
              ]}
            >
              <Select
                placeholder="Select Department Head"
                showSearch
                optionFilterProp="children"
                onSelect={handleUser}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              // onChange={getSelectedEmployeeData}
              >
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName + ' ' + employee.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit">
            Submit
          </Button>
          {props.isUpdate === false && (
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
          )}
        </Col>
      </Form>
    </Card>
  );
}

export default DepartmentsForm;
