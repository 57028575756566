import React, { useEffect, useState } from "react"

import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import Highdonut from '../../../../../dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/donut';
import Highcolumn from '../../../../../dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/column';
import { LineSeries } from "libs/shared-models/common-models/src/lib/dashboards/line-series";
import { BarChartRequest, CardBodyDetails, CardDetail, CardHeader, CardHeaderBody, DohountRequest, Highlinereq, HighColumn } from "@gtpl/shared-models/common-models";
import CardComponent from "../../../../../../../libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/dashboard-components/card-component";
import './pages-asset-management-asset-management-components-assets-dashboard.css';
import { Card, Col, Modal, Row } from "antd";
import { AssetService } from "libs/shared-services/asset-management/src/lib/assets.service";
import moment from "moment";
import AbstractReport from 'libs/pages/asset-management/asset-management-components/opening-aasets-form/src/lib/pages-asset-management-asset-management-components-opening-aasets-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faRotate, faScrewdriverWrench, faCircleExclamation, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AssetsAgeGraph } from "./asset-age";
import AssetsMaintainance from "./under-maintainance";

import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";
import { AssetsSkippedGraph } from "./asset-skipped-service";
import { AssetsPlantStatusGraph } from "./asset-plant-waisestatus";
import { AssetGrid } from "libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/pages-asset-management-asset-management-components-aasets-grid";
import { AssetStatusEnum } from "libs/shared-models/common-models/src/lib/enum/asset-status.enum";
import { AssetRentedGraph } from "./rented-assets-graph";
import MonthWiseAssetChart from "./month-wise-assets-sale";
import CategoriesWiseAmcInsCount from "./asset-amc-ins-war-serv";



class exportClassObj {
    no_of_assets: string;
    status: string;

}

/* eslint-disable-next-line */
export interface AssetsDashboardProps { }

export function AssetsDashboard(
    props: AssetsDashboardProps
) {
    const [checkInCount, setCheckInCount] = useState<any>({})
    const [checkOutCount, setCheckOutCount] = useState<any>({})
    const [undermaintenance, setUndermaintennace] = useState<any>([])
    const [damaged, setDamaged] = useState<any[]>([])
    const [inStock, setInstock] = useState<any>([])
    const [checkIn, setCheckIn] = useState<any>([])
    const [locations, setLocations] = useState<any>({})
    const [assetsCost, setAssetsCost] = useState<any>([])
    const [assetsCount, setAssetsCount] = useState<any>([])
    const [departmentCount, setDepartmentCount] = useState<any>([])
    const [damagedCost, setDamagedCost] = useState<any>([])
    const [decommissionCost, setDecommissionCost] = useState<any>([])
    const [assetTypeDetail, setAssetTypeDetail] = useState<any[]>([]);
    const [assetAssignDetail, setAssetAssignDetail] = useState<any>([]);
    const [monthWiseCount, setMonthWiseCount] = useState<any>([]);
    const [monthWiseSaleCount, setMonthWiseSaleCount] = useState<any>([]);
    const [warrentyDetails, setWarrentyDetails] = useState<any>([]);
    const [maintainanceDetails, setMaintainaceDetails] = useState<any>([]);
    const [maintainanceVendor, setMaintainaceVendor] = useState<any>([]);
    const [insuranceDetails, setInsuranceDetails] = useState<any>([]);
    const [serviceDetails, setServiceDetails] = useState<any>([]);
    const [plantCount, setPlantCount] = useState<any>([]);
    const [status, setStatus] = useState('')
    const [responsibleDetails, setResponsibleDetails] = useState<any>([]);
    const [expDetails, setExpDetails] = useState<any>([]);
    const [saleDetails,setSaleDetails] = useState<any>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [assetStatus, setAssetStatus] = useState<AssetStatusEnum>(undefined)
    const service = new AssetService();

    useEffect(() => {
        assets();
        assetsDetails();
        assetsAssignmentDetails();
        monthWise();
        monthWiseSaleAssets();
        assetsTopWarrenty();
        assetsTopInsurance();
        departmentWiseDetails();
        categoryWiseDamaged();
        assetsTopService();
        plantWiseAssets()
        maintainanceOnInterval();
        maintainanceOnVendor();
        topResponsible();
        getExpRents();
        getTop5SaleAssets();
        // plantWiseAssets()
    }, [])

    // setInterval(function () {
    //     window.location.reload();
    // }, 300000);

    const assets = () => {
        service.assetsCount().then((res) => {
            if (res.status) {
                setAssetsCount(res.data[0]);
                setCheckInCount(res.data[1])
                setCheckOutCount(res.data[2])
                setUndermaintennace(res.data[3])
                setDamaged(res.data[4])
                setLocations(res.data[5])
                setCheckIn(res.data[6])
                setInstock(res.data[7])
                setAssetsCost(res.data[8])
                setDecommissionCost(res.data[9])
            } else {
                setAssetsCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setAssetsCount([]);
        })
    }

    const plantWiseAssets = () => {
        service.getPlantWiseAssets().then((res) => {
            if (res.status) {
                setPlantCount(res.data);
            } else {
                setPlantCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setPlantCount([]);
        })
    }

    const assetsDetails = () => {
        service.assetsStatusCount().then((res) => {
            if (res.status) {
                setAssetTypeDetail(res.data);
            } else {
                setAssetTypeDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setAssetTypeDetail([]);
        })
    }

    const assetsAssignmentDetails = () => {
        service.assetsCheckInMonthWiseCount().then((res) => {
            if (res.status) {
                setAssetAssignDetail(res.data);
            } else {
                setAssetAssignDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setAssetAssignDetail([]);
        })
    }
    const monthWise = () => {
        service.assetMonthwiseReport().then((res) => {
            if (res.status) {
                setMonthWiseCount(res.data);
            } else {
                setMonthWiseCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setMonthWiseCount([]);
        })
    }
    const monthWiseSaleAssets = () => {
        service.getMonthwiseAssetsSale().then((res) => {
            if (res.status) {
                setMonthWiseSaleCount(res.data);
            } else {
                setMonthWiseSaleCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setMonthWiseSaleCount([]);
        })
    }

    const assetsTopWarrenty = () => {
        service.assetsTopWarrenty().then((res) => {
            if (res.status) {
                setWarrentyDetails(res.data[0]);
            } else {
                setWarrentyDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setWarrentyDetails([]);
        })
    }

    const maintainanceOnInterval = () => {
        const req = new AssetByStatus()
        // req.plant=plant
        service.underMaintainanceOnInternal(req).then(res => {
            if (res.status) {
                setMaintainaceDetails(res.data);
            } else {
                if (res.intlCode) {
                    setMaintainaceDetails([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setMaintainaceDetails([]);
        })

    }


    const maintainanceOnVendor = () => {
        const req = new AssetByStatus()
        // req.plant=plant
        service.underMaintainanceOnVendor(req).then(res => {
            if (res.status) {
                setMaintainaceVendor(res.data);
            } else {
                if (res.intlCode) {
                    setMaintainaceVendor([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setMaintainaceVendor([]);
        })

    }

    const assetsTopInsurance = () => {
        service.assetsTopInsurance().then((res) => {
            if (res.status) {
                setInsuranceDetails(res.data[0]);
            } else {
                setInsuranceDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setInsuranceDetails([]);
        })
    }

    const assetsTopService = () => {
        service.assetsTopServices().then((res) => {
            if (res.status) {
                setServiceDetails(res.data[0]);
            } else {
                setServiceDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setServiceDetails([]);
        })
    }
    const getExpRents = () => {
        service.getExpRents().then((res) => {
            if (res.status) {
                setExpDetails(res.data);
            } else {
                setExpDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setExpDetails([]);
        })
    }
    const getTop5SaleAssets = () => {
        const req = new AssetByStatus()
        req.plantId = Number(localStorage.getItem('unit_id'))
        service.getTop5SaleAssets(req).then((res) => {
            if (res.status) {
                setSaleDetails(res.data);
            } else {
                setSaleDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleDetails([]);
        })
    }
    const topResponsible = () => {
        service.topResponsible().then((res) => {
            if (res.status) {
                setResponsibleDetails(res.data[0]);
            } else {
                setResponsibleDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setResponsibleDetails([]);
        })
    }

    const departmentWiseDetails = () => {
        service.departmentWiseCount().then((res) => {
            if (res.status) {
                setDepartmentCount(res.data[0]);
            } else {
                setDepartmentCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setDepartmentCount([]);
        })
    }

    const categoryWiseDamaged = () => {
        service.assetsDamagedCost().then((res) => {
            if (res.status) {
                setDamagedCost(res.data[0]);
            } else {
                setDamagedCost([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setDamagedCost([]);
        })
    }

    const getStatus = (status) => {
        console.log(status)
        setStatus(status)
    }

    const handleCardClick = (value: AssetStatusEnum) => {
        setAssetStatus(value)
        setModal(true);
    }

    const donutCard: CardHeaderBody[] = [];
    for (const eachDepartment of departmentCount) {
        donutCard.push(new CardHeaderBody(eachDepartment.dept_name, eachDepartment.asset_count));
    }
    const doNutReqdept = new DohountRequest(' Assets By Department', 'Assets Count', donutCard);


    const openSoCardBody1 = new CardHeaderBody(assetTypeDetail[0]?.status, assetTypeDetail[0]?.assets);
    const openSoCardBody2 = new CardHeaderBody(assetTypeDetail[1]?.status, assetTypeDetail[1]?.assets);
    const openSoCardBody3 = new CardHeaderBody(assetTypeDetail[2]?.status, assetTypeDetail[2]?.assets);
    const openSoCardBody4 = new CardHeaderBody(assetTypeDetail[3]?.status, assetTypeDetail[3]?.assets);
    const openSoCardBody5 = new CardHeaderBody(assetTypeDetail[4]?.status, assetTypeDetail[4]?.assets);

    const doNutReq = new DohountRequest(' Assets By Status', 'Assets Count', [openSoCardBody1, openSoCardBody2, openSoCardBody3, openSoCardBody4, openSoCardBody5]);

    // const increment = (function (n) {
    //     return function () {
    //         n += 1;
    //         return n;
    //     }
    // }(0));

    // const finPlantTextMap = new Map<number, string>();
    // for (const eachPlant of plantCount) {
    //     finPlantTextMap.set(Number(increment), eachPlant.plantname)
    // }

    const finMonthTextMap = new Map<number, string>();
    finMonthTextMap.set(1, 'jan');
    finMonthTextMap.set(2, 'feb');
    finMonthTextMap.set(3, 'mar');
    finMonthTextMap.set(4, 'apr');
    finMonthTextMap.set(5, 'may');
    finMonthTextMap.set(6, 'jun');
    finMonthTextMap.set(7, 'july');
    finMonthTextMap.set(8, 'aug');
    finMonthTextMap.set(9, 'sep');
    finMonthTextMap.set(10, 'octo');
    finMonthTextMap.set(11, 'nov');
    finMonthTextMap.set(12, 'decm');

    const cardBodyForDeadStockPro: CardBodyDetails[] = [];
    for (const insuranceInfo of insuranceDetails) {
        cardBodyForDeadStockPro.push(new CardBodyDetails(insuranceInfo.unit, insuranceInfo.assets_code, insuranceInfo.asset_name, insuranceInfo.insurance_date ? moment(insuranceInfo.insurance_date).format('YYYY-MM-DD') : '--', insuranceInfo.aging))
    }

    const CardBody: CardBodyDetails[] = [];
    for (const warrentyInfo of warrentyDetails) {
        CardBody.push(new CardBodyDetails(warrentyInfo.unit, warrentyInfo.assets_code, warrentyInfo.asset_name, warrentyInfo.warrenty_expired_date ? moment(warrentyInfo.warrenty_expired_date).format('YYYY-MM-DD') : '--', warrentyInfo.aging))
    }

    const underMaintainanaceBody: CardBodyDetails[] = [];
    for (const maintainanceInfo of maintainanceDetails) {
        underMaintainanaceBody.push(new CardBodyDetails(maintainanceInfo.unit, maintainanceInfo.assetsCode, maintainanceInfo.assetName, maintainanceInfo.COUNT))
    }

    const underMaintainanaceBodyVendor: CardBodyDetails[] = [];
    for (const maintainanceInfo of maintainanceVendor) {
        underMaintainanaceBodyVendor.push(new CardBodyDetails(maintainanceInfo.assetsCode, maintainanceInfo.assetName, maintainanceInfo.assetSupplier, maintainanceInfo.unit))
    }

    const damagedBody: CardBodyDetails[] = [];
    for (const damagedInfo of damagedCost) {
        damagedBody.push(new CardBodyDetails(damagedInfo.unit, damagedInfo.asset_category, damagedInfo.quantity, Number(damagedInfo.cost_of_assets).toLocaleString('en-IN', undefined)))
    }
    const responsibleBody: CardBodyDetails[] = [];
    for (const responsibleInfo of responsibleDetails) {
        responsibleBody.push(new CardBodyDetails(responsibleInfo.unit, responsibleInfo.department, responsibleInfo.responsible, Number(responsibleInfo.numberOfAssets).toLocaleString('en-IN', undefined)))
    }
    const expBody: CardBodyDetails[] = [];
    for (const expInfo of expDetails) {
        expBody.push(new CardBodyDetails(expInfo.unit, expInfo.assets_code, expInfo.assetName, expInfo.rentDate, expInfo.aging))
    }

    const saleBody: CardBodyDetails[] = [];
    for (const expInfo of saleDetails) {
        saleBody.push(new CardBodyDetails(expInfo.unit, expInfo.assets_code, expInfo.assetName,expInfo.saleDate ? moment(expInfo.saleDate).format('YYYY-MM-DD') : '--' , Number(expInfo.asset_sale_cost).toLocaleString('en-IN', undefined)))
    }

    const serviceBody: CardBodyDetails[] = [];
    for (const serviceInfo of serviceDetails) {
        serviceBody.push(new CardBodyDetails(serviceInfo.unit, serviceInfo.assets_code, serviceInfo.asset_name, serviceInfo.asset_service_date ? moment(serviceInfo.asset_service_date).format('YYYY-MM-DD') : '--', serviceInfo.aging))
    }
    const internal: CardBodyDetails[] = [];

    const monthsNamesArray = new Set<string>();
    const monthAsstes: CardHeaderBody[] = [];
    const assetsSale : CardHeaderBody[] = [];
    const monthCheckIn: CardHeaderBody[] = [];
    const monthCheckOut: CardHeaderBody[] = [];
    for (const [monthNumber, month] of finMonthTextMap) {
        monthsNamesArray.add(month);
    }

    if (monthWiseCount.length > 0) {
        for (const monthsName of monthsNamesArray) {
            const assetsCountVal = monthWiseCount[0][0][monthsName];
            monthAsstes.push(new CardHeaderBody('Assets Count', assetsCountVal));

        }
    }

    // if(monthWiseSaleCount.length>0){
    //     for(const monthsName of monthsNamesArray){
    //         const assetsSaleVal = monthWiseSaleCount[0][0][monthsName];
    //         assetsSale.push(new CardHeaderBody('Assets Count',assetsSaleVal));
          
    //     }
    // }

    if (assetAssignDetail.length > 0) {
        for (const monthsName of monthsNamesArray) {
            const assetsCheckInVal = assetAssignDetail[0][0][monthsName];
            const assetsCheckOutVal = assetAssignDetail[1][0][monthsName];
            monthCheckIn.push(new CardHeaderBody('Check-In', assetsCheckInVal));
            monthCheckOut.push(new CardHeaderBody('Check-Out', assetsCheckOutVal));

        }
    }

    const DeadStockProBodyCount = new CardHeaderBody(null);
    const DeadStockProBodyQty = new CardHeaderBody(null);
    const DeadStockProBodyCardHeader = new CardHeader('Top 5 Asset Insurance Date ', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const BodyCardHeader = new CardHeader('Top 5 Asset Warranty Date ', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const damagedHeader = new CardHeader('Category Wise Damaged Cost', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const serviceHeader = new CardHeader('Top 5 Up-Coming Services', [DeadStockProBodyCount, DeadStockProBodyQty]);
    // const undermaintenanceHeader = new CardHeader('Top 5 under maintainance', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const undermaintenanceHeader = new CardHeader('Top 5 under maintenance', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const undermaintenanceVendor = new CardHeader('Top 5 under maintenance on vendor', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const topFiveResponsibleHeader = new CardHeader('Top 5 Responsible', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const topFiveRentExpHeader = new CardHeader('Top 5 Rent Expired', [DeadStockProBodyCount, DeadStockProBodyQty]);
    const topFiveSaleAssets = new CardHeader('Top 5 Sale Assets',[DeadStockProBodyCount,DeadStockProBodyQty])

    const lineSeries = new LineSeries(monthAsstes);
    const lineseriessale = new LineSeries(assetsSale)
    const lineSeries1 = new LineSeries(monthCheckIn);
    const lineSeriesEnd = new LineSeries(monthCheckOut);

    const highEndreaqest = new HighColumn('Month-Wise Check In & Out', Array.from(monthsNamesArray), 'Total Number Of Assets', [lineSeries1, lineSeriesEnd],)
    const Endreaqest = new HighColumn('Month-Wise Procured Assets', Array.from(monthsNamesArray), 'Assets', [lineSeries])
    const monthwiseSale = new HighColumn('Month-Wise Assets Sale',Array.from(monthsNamesArray),'Total Number of Assets',[lineseriessale])
    const DeadStockProCard = new CardDetail(DeadStockProBodyCardHeader, cardBodyForDeadStockPro, ['Unit', 'Asset Code', 'Asset Name', 'Valid Upto', 'Aging'], false);
    const DeadCard = new CardDetail(BodyCardHeader, CardBody, ['Unit', 'Asset Code', 'Asset Name', 'Valid Upto', 'Aging'], false);
    const damagedAssetCost = new CardDetail(damagedHeader, damagedBody, ['Unit', 'Asset Category', 'Quantity', 'Cost'], false);
    const serviceProCard = new CardDetail(serviceHeader, serviceBody, ['Unit', 'Asset Code', 'Asset Name', 'Service Date', 'Aging'], false);
    const underMaintainanceCard = new CardDetail(undermaintenanceHeader, underMaintainanaceBody, ['Unit', 'Asset Code', 'Asset Name', 'Count'], false)
    const underMaintainanceCardVendor = new CardDetail(undermaintenanceVendor, underMaintainanaceBodyVendor, ['Asset Code', 'Asset Name', 'Asset Supplier', 'Unit'], false)
    const top5Responsible = new CardDetail(topFiveResponsibleHeader, responsibleBody, ['Unit', 'Department Name', 'Responsible Person', 'Count'], false)
    const top5exp = new CardDetail(topFiveRentExpHeader, expBody, ['Unit', 'Asset Code', 'Asset Name', 'Valid Upto', 'Aging'], false)
    const top5Sale = new CardDetail(topFiveSaleAssets,saleBody,['Unit','Asset Code','Asset Name','Sale Date','Sale Cost'],false)

    return (
        <>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className="asset-header" style={{ backgroundColor: '#FFDDAD', color: 'black' }} onClick={() => handleCardClick(AssetStatusEnum.INSTOCK)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>Instock</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faCartPlus} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faCartPlus} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{inStock?.filter((i) => i.asset_type == 'FIXED').length ? inStock?.filter((i) => i.asset_type == 'FIXED')[0].instock_assets : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{inStock?.filter((i) => i.asset_type == 'MOVABLE').length ? inStock?.filter((i) => i.asset_type == 'MOVABLE')[0].instock_assets : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{inStock?.filter((i) => i.asset_type == 'SOFTASSET').length ? inStock?.filter((i) => i.asset_type == 'SOFTASSET')[0].instock_assets : 0}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{inStock?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(inStock?.filter((i) => i.asset_type == 'FIXED')[0].instock_cost) : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{inStock?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(inStock?.filter((i) => i.asset_type == 'MOVABLE')[0].instock_cost) : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{inStock?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(inStock?.filter((i) => i.asset_type == 'SOFTASSET')[0].instock_cost) : 0}</span>
                        </div>

                        {/* Assets : {inStock[0]?.instock_assets}<br />
                        Cost   : ₹ {Number(inStock[0]?.instock_cost).toLocaleString('en-IN')} */}
                    </div >
                    <div className="asset-header" style={{ backgroundColor: '#B1ADAA', color: 'black' }} onClick={() => handleCardClick(AssetStatusEnum.INUSE)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>In-Use</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faRotate} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faRotate} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{checkIn?.filter((i) => i.asset_type == 'FIXED').length ? checkIn?.filter((i) => i.asset_type == 'FIXED')[0].checkin_assets : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{checkIn?.filter((i) => i.asset_type == 'MOVABLE').length ? checkIn?.filter((i) => i.asset_type == 'MOVABLE')[0].checkin_assets : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{checkIn?.filter((i) => i.asset_type == 'SOFTASSET').length ? checkIn?.filter((i) => i.asset_type == 'SOFTASSET')[0].checkin_assets : 0}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{checkIn?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(checkIn?.filter((i) => i.asset_type == 'FIXED')[0].checkin_cost) : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{checkIn?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(checkIn?.filter((i) => i.asset_type == 'MOVABLE')[0].checkin_cost) : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{checkIn?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(checkIn?.filter((i) => i.asset_type == 'SOFTASSET')[0].checkin_cost) : 0}</span>
                        </div>

                        {/* Assets : {checkIn[0]?.checkin_assets}<br />
                        Cost   : ₹ {Number(checkIn[0]?.checkin_cost).toLocaleString('en-IN')} */}
                    </div >
                    <div className="asset-header" style={{ backgroundColor: '#D4AD77', color: 'black' }} onClick={() => handleCardClick(AssetStatusEnum.UNDERMAINTENANCE)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>Maintenance</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faScrewdriverWrench} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faScrewdriverWrench} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{undermaintenance?.filter((i) => i.asset_type == 'FIXED').length ? undermaintenance?.filter((i) => i.asset_type == 'FIXED')[0].undermaintenance_assets : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{undermaintenance?.filter((i) => i.asset_type == 'MOVABLE').length ? undermaintenance?.filter((i) => i.asset_type == 'MOVABLE')[0].undermaintenance_assets : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{undermaintenance?.filter((i) => i.asset_type == 'SOFTASSET').length ? undermaintenance?.filter((i) => i.asset_type == 'SOFTASSET')[0].undermaintenance_assets : 0}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{undermaintenance?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(undermaintenance?.filter((i) => i.asset_type == 'FIXED')[0].undermaintainance_cost) : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{undermaintenance?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(undermaintenance?.filter((i) => i.asset_type == 'MOVABLE')[0].undermaintainance_cost) : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{undermaintenance?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(undermaintenance?.filter((i) => i.asset_type == 'SOFTASSET')[0].undermaintainance_cost) : 0}</span>
                        </div>
                        {/* Assets : {undermaintenance[0]?.undermaintenance_assets} <br />
                        Cost   : ₹ {Number(undermaintenance[0]?.undermaintainance_cost).toLocaleString('en-IN')} */}
                    </div >
                    {/* <div className="asset-header">
                        Total Assets Location <br />
                        {locations[0]?.locations_count}S

                    </div > */}
                </div>
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className="asset-header" style={{ backgroundColor: '#F5AB67', color: 'black' }} onClick={() => handleCardClick(AssetStatusEnum.DAMAGED)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>Damaged</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faCircleExclamation} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faCircleExclamation} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{damaged?.filter((i) => i.asset_type == 'FIXED').length ? damaged?.filter((i) => i.asset_type == 'FIXED')[0].damaged_assets : 0} </span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{damaged?.filter((i) => i.asset_type == 'MOVABLE').length ? damaged?.filter((i) => i.asset_type == 'MOVABLE')[0].damaged_assets : 0} </span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{damaged?.filter((i) => i.asset_type == 'SOFTASSET').length ? damaged?.filter((i) => i.asset_type == 'SOFTASSET')[0].damaged_assets : 0} </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{damaged?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(damaged?.filter((i) => i.asset_type == 'FIXED')[0].asset_cost) : 0} </span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{damaged?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(damaged?.filter((i) => i.asset_type == 'MOVABLE')[0].asset_cost) : 0} </span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{damaged?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(damaged?.filter((i) => i.asset_type == 'SOFTASSET')[0].asset_cost) : 0} </span>
                        </div>
                        {/* Assets : {damaged[0]?.damaged_assets} <br />
                        Cost   : ₹ {Number(damaged[0]?.asset_cost).toLocaleString('en-IN')} */}
                    </div >
                    <div className="asset-header" style={{ backgroundColor: '#ffee65', color: 'black' }} onClick={() => handleCardClick(AssetStatusEnum.RETIRED)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>Retire</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faTrashCan} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faTrashCan} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{decommissionCost?.filter((i) => i.asset_type == 'FIXED').length ? decommissionCost?.filter((i) => i.asset_type == 'FIXED')[0].decommisioned_assets : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{decommissionCost?.filter((i) => i.asset_type == 'MOVABLE').length ? decommissionCost?.filter((i) => i.asset_type == 'MOVABLE')[0].decommisioned_assets : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{decommissionCost?.filter((i) => i.asset_type == 'SOFTASSET').length ? decommissionCost?.filter((i) => i.asset_type == 'SOFTASSET')[0].decommisioned_assets : 0}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{decommissionCost?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(decommissionCost?.filter((i) => i.asset_type == 'FIXED')[0].decommisioned_cost) : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{decommissionCost?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(decommissionCost?.filter((i) => i.asset_type == 'MOVABLE')[0].decommisioned_cost) : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{decommissionCost?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(decommissionCost?.filter((i) => i.asset_type == 'SOFTASSET')[0].decommisioned_cost) : 0}</span>
                        </div>
                        {/* Assets : {decommissionCost[0]?.decommisioned_assets} <br />
                        Cost   : ₹ {Number(decommissionCost[0]?.decommisioned_cost).toLocaleString('en-IN')} */}
                    </div >
                    <div className="asset-header" style={{ backgroundColor: '#F0FFFF', color: 'black' }} onClick={() => handleCardClick(undefined)}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "101%", textAlign: 'center' }}>
                            <b style={{ width: '40%', fontSize: '30px', textAlign: 'left' }}>Total</b>
                            <span style={{ width: '20%', fontSize: '30px', textAlign: 'center' }}><FontAwesomeIcon icon={faPlus} /></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", textAlign: 'center' }}>
                            {/* <h3 style={{ width: '40%', fontSize: '25px', textAlign: 'center' }}><FontAwesomeIcon icon={faPlus} /></h3> */}
                            <h3 style={{ width: '35%', fontSize: '20px', textAlign: 'center' }}>Fixed</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Movable</h3>
                            <h3 style={{ width: '40%', fontSize: '20px', textAlign: 'center' }}>Soft</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Quantity</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>{assetsCost?.filter((i) => i.asset_type == 'FIXED').length ? assetsCost?.filter((i) => i.asset_type == 'FIXED')[0].total_assets : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>{assetsCost?.filter((i) => i.asset_type == 'MOVABLE').length ? assetsCost?.filter((i) => i.asset_type == 'MOVABLE')[0].total_assets : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>{assetsCost?.filter((i) => i.asset_type == 'SOFTASSET').length ? assetsCost?.filter((i) => i.asset_type == 'SOFTASSET')[0].total_assets : 0}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "98%", textAlign: 'center' }}>
                            {/* <span style={{ width: '40%', textAlign: 'center' }}>Cost</span> */}
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{assetsCost?.filter((i) => i.asset_type == 'FIXED').length ? Intl.NumberFormat('en-IN').format(assetsCost?.filter((i) => i.asset_type == 'FIXED')[0].asset_cost) : 0}</span>
                            <span style={{ width: '45%', textAlign: 'center' }}>₹{assetsCost?.filter((i) => i.asset_type == 'MOVABLE').length ? Intl.NumberFormat('en-IN').format(assetsCost?.filter((i) => i.asset_type == 'MOVABLE')[0].asset_cost) : 0}</span>
                            <span style={{ width: '35%', textAlign: 'center' }}>₹{assetsCost?.filter((i) => i.asset_type == 'SOFTASSET').length ? Intl.NumberFormat('en-IN').format(assetsCost?.filter((i) => i.asset_type == 'SOFTASSET')[0].asset_cost) : 0}</span>
                        </div>
                        {/* Assets : {assetsCount[0]?.no_of_assets} <br />
                        Cost   : ₹ {Number(assetsCost[0]?.asset_cost).toLocaleString('en-IN')} */}
                    </div >

                </div>
                <br />
                {/* <Row gutter={24}>
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={12}>
                        <div className="highcolumn-header">
                            <AssetsAgeGraph />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={12}>
                        <div className="highcolumn-header">
                            <Highcolumn columnData={PlantEndreaqest} />
                        </div>
                        <div className="highcolumn-header">
                            <Highcolumn columnData={highEndreaqest} />
                        </div>
                 
                    </Col>
                </Row> */}
                {/* <Row gutter={24}>
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={9}>
                        <div className="donut">
                            <Highdonut doNutData={doNutReq} />
                        </div>
                    </Col>
                </Row> */}
                <Row gutter={24}>
                    {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={12}>
                        <div className="highcolumn-header">
                            <AssetsSkippedGraph />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={12}>
                        <div className="highcolumn-header">
                            <AssetRentedGraph />
                        </div>
                    </Col> */}
                    <Col className="cardComp" xs={24} sm={24} md={24} xl={24}>
                        <div >
                            <CategoriesWiseAmcInsCount />
                        </div>
                    </Col>

                </Row>
                <br />
                <Row gutter={24}>
                    {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={12}>
                        <div className="highcolumn-header">
                            <Highcolumn columnData={Endreaqest} />
                        </div>
                    </Col> */}
                    
                    
               
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={top5Sale} />
                        </div>
                    </Col>
                    
                      {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={16}>
                        <div className="highcolumn-header">
                            <MonthWiseAssetChart/>
                        </div>
                    </Col> */}
                </Row>
                <br />
                <Row gutter={24}>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={serviceProCard} />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={DeadCard} />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={DeadStockProCard} />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={damagedAssetCost} />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={underMaintainanceCard} />
                        </div>
                    </Col>
                    <Col className="cardComp" xs={24} sm={24} md={6} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={top5Responsible} />
                        </div>
                    </Col>
                    {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={serviceProCard} />
                        </div> */}
                    {/* <div className="donut">
                            <Highdonut doNutData={doNutReqdept} />
                        </div> */}
                    {/* </Col> */}
                </Row>
                <br />
                <Row gutter={24}>
                    <Col className="cardComp" xs={24} sm={24} md={8} xl={8}>
                        <div className="highcolumn-header">
                            <CardComponent cardData={top5exp} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} xl={16}>
                        <div className="highcolumn-header">
                            <AbstractReport />
                        </div>
                    </Col>

                </Row>
            </div>
            <Modal
                className='qr-container'
                key={'modal' + Date.now()}
                width={'95%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={modal}
                onCancel={() => { setModal(false) }}
                title={<React.Fragment>
                </React.Fragment>}>
                <AssetGrid status={assetStatus} />
            </Modal>


        </>
    )
}
export default AssetsDashboard;
