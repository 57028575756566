import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { HrmsEmployeeDetailsDto, HrmsEmployeeRequest, PlantIdRequest, PlantsDropDown } from '@gtpl/shared-models/masters'
import { HrmsEmpDetService, UnitcodeService } from '@gtpl/shared-services/masters'
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';
import { DesignationService, TransferActionService } from '@gtpl/shared-services/hrms'
import { DesignationDto, TransferActionDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';


const { TextArea } = Input;
const { Option } = Select;
export interface TransferActionFormProps {
  Data: TransferActionDto;
  updateTransferAction: (transferAction: TransferActionDto) => void;
  isUpdate: boolean;
  closeForm: () => void;

}

export function TransferActionForm(props: TransferActionFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [selectedEmployee, setSelectedEmployee] = useState<HrmsEmployeeDetailsDto>();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [display, setDisplay] = useState<string>('none');
  const [fromPlant, setFromPlant] = useState<string>('');
  const [plantInfo, setPlantInfo] = useState<PlantsDropDown[]>([]);
  const empService = new HrmsEmpDetService();
  const unitService = new UnitcodeService();
  const dropDrownInfo = (key, value) => <Option key={key} value={key}>{value}</Option>
  const transferActionService = new TransferActionService();
  const dateFormat = "YYYY-MM-DD";
  const hrmsEmpDetService = new HrmsEmpDetService();
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const designationService = new DesignationService();
  const [toUnit, setToUnit] = useState<any[]>([])

  useEffect(() => {
    getAllActiveEmployeesForLeaves();
    getAllPlants();
    getAllDesignation();
  }, [])

  const getAllActiveEmployeesForLeaves = () => {
    empService.getAllActiveEmployeesForLeaves().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllDesignation = () => {
    designationService.getAllDesignation().then(res => {
      if (res.status) {
        setDesignationData(res.data);
      } else {
        if (res.intlCode) {
          setDesignationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getPlantDetailsById = (unitId: number) => {
    const plantReq = new PlantIdRequest(unitId);
    unitService.getPlantDetailsById(plantReq).then((res) => {
      if (res.status) {
        form.setFieldsValue({
          fromPlant: res.data.unitcodeName
        })
        getToPlantData(res.data.unitcodeName);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getAllPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantInfo(res.data);
        res.data.map((plant) => {
          if (plant.plantId == Number(localStorage.getItem('unitId'))) {
            setFromPlant(plant.plantName);
            form.setFieldsValue({
              fromPlant: fromPlant
            })
          }
        })
      } else {
        setPlantInfo([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantInfo([]);
    });
  }

  const createTransferAction = (Data: TransferActionDto) => {
    transferActionService.createTransferAction(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Transfer Action done successfully');
        history.push('/transfer-action-view');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: TransferActionDto) => {
    if (props.isUpdate) {
      props.updateTransferAction(values);
    } else {
      createTransferAction(values);
    }
  };

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }


  const onReset = () => {
    form.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  if (props?.Data && props.Data.transferDate)
    props.Data.transferDate = moment(props.Data.transferDate);

  const getSelectedEmployeeData = (req) => {
    const request = new HrmsEmployeeRequest(req);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeName: res.data.employeeName + ' ' + res.data.employeeLastName,
          employeeId: res.data.employeeId,
          employeeCode: res.data?.employeeCode,
          designation: res.data?.designation,
        });
        getPlantDetailsById(res.data.unitId);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getToPlantData = (value) => {
    setToUnit(plantInfo.filter(i => i.plantName != value))
  }

  return (

    <Card title={<span style={{ color: 'white' }}>Transfer Action</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/transfer-action-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={saveData}
        initialValues={props.Data}
      >

        <Form.Item name="transferActionId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="transferDate"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Date is required"
                },

              ]}>
              <DatePicker
                style={{ width: '100%' }}
                format={dateFormat}
                showToday={true}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "Employee Code is required"
                },
              ]}>
              <Select
                placeholder="Select Employee Code"
                onChange={getSelectedEmployeeData}
              >
                <Option key={0} value={null}> Select Employee Code</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Select Designation "
                },
              ]}>

              <Select
                placeholder="Select designation"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {designationData.map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="approvedBy"
              label="Approved By"
              rules={[
                {
                  required: true,
                  message: "approvedBy is required"
                },
              ]}
            >

              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Approved By"
                optionFilterProp="children"
                // onSelect={handleExpensesCategory}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Expenses Category</Option>
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeName}>{employeeData.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="fromPlant"
              label="From Plant"
              rules={[
                {
                  required: true,
                  message: `Please Select Plant!`,
                },
              ]}
            >
              <Select
                placeholder="Select From Plant"
                allowClear
                onSelect={getToPlantData}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >

                {plantInfo.map(dropData => {
                  return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? dropDrownInfo(dropData.plantName, dropData.plantName) : '';
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="toPlant"
              label="To Plant"
              rules={[
                {
                  required: true,
                  message: `Please Select Plant!`,
                },
              ]}
            >
              <Select
                placeholder="Select To Plant"
                allowClear
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option key={'plant'} value={''}>Select To Plant</Option>
                {toUnit?.map(dropData => {
                  return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? dropDrownInfo(dropData.plantName, dropData.plantName) : '';
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&

              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default TransferActionForm;
