import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, DatePicker, Modal } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import { DownTimeTrackingDto, DownTimeReasonDto, DepartmentDto, DowntimeTrackingMacineNameResponse, DowntimeMachineNameRequest, EmployeeUnitRequest, EmployeeDto, EmployeeByUnitDTO } from '@gtpl/shared-models/masters';
import form from 'antd/lib/form';
import { from } from 'rxjs';
import { DepartmentService, DowntimeReasonService, DownTimeTrackingService, EmployeeService } from '@gtpl/shared-services/masters';
import { CategoryEnum, DownTimePlannedCategoryenum, DownTimePlannedCategoryenumDropDown, DownTimeTrackingStatusEnum, StatusEnum } from '@gtpl/shared-models/common-models';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { isNull } from 'util';
import TextArea from 'antd/lib/input/TextArea';
import { QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import { EmpLeaveDTO } from '@gtpl/shared-models/hrms';




/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};


export interface DownTimeTrackingFormProps {
  downtimeTrackingData: DownTimeTrackingDto;
  updateDetails: (Downtimetracking: DownTimeTrackingDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function DownTimeTrackingForm(props: DownTimeTrackingFormProps) {
  const [form] = Form.useForm();
  const service = new DownTimeTrackingService;
  const downTimeReasonService = new DowntimeReasonService;
  const downtimeTrackingService = new DownTimeTrackingService;
  const departmentService = new DepartmentService;
  const saleService = new SaleOrderService;
  const empservice = new EmployeeService;
  const [departmentData, setDepartmentData] = useState<DepartmentDto[]>()
  const [selectedReason, setSelectedReason] = useState<number>(null);
  const [downTimeReasonData, setDownTimeReasonData] = useState<DownTimeReasonDto[]>();
  const [downtimeMacineName, setdowntimeMacineName] = useState<DowntimeTrackingMacineNameResponse>();
  const [disable, setDisable] = useState<boolean>(false);
  const [soDropDown, setSoDropDown] = useState<SaleOrderDropDownDto[]>();
  const [scannedQr, setscannedQr] = useState<any[]>([])
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const [downtimetype, setDowntimetype] = useState('')
  const [statusVissible, setStatusVissible] = useState<boolean>(false);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const [empform] = Form.useForm();
  const[plannedtimeVissible, setplannedtimeVissible]=useState<boolean>(false);



  useEffect(() => {
    getAllActiveDownTimeReason();
    getDepartmentData();
    getSoNoDropdown()
    getEmpByUnit()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
    }
    
  }, [])
  let createdUser = "";
  let unitId=0;
  let history = useHistory();
 
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    if (props.downtimeTrackingData) {
      // props.downtimeTrackingData.startTime=moment(props.downtimeTrackingData.startTime, 'YYYY-MM-DD HH:mm:ss');
      // props.downtimeTrackingData.startTime=moment(props.downtimeTrackingData.startTime).format('YYYY-MM-DD HH:mm:ss');

      // props.downtimeTrackingData.endtime=moment(props.downtimeTrackingData.endtime, 'YYYY-MM-DD HH:mm:ss');

      form.setFieldsValue(props.downtimeTrackingData)
if(props.downtimeTrackingData?.employeeId != undefined && props.isUpdate){
  form.setFieldsValue('employeeId')
}
    }
  }, [props.downtimeTrackingData])


  const save = (downTimeTrackingData: DownTimeTrackingDto) => {
    setDisable(true)
    console.log(downTimeTrackingData,'value--')
    service.createDownTimeTracking(downTimeTrackingData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('DownTimeTracking Created Successfully');
        history.push("/downtimetracking-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getDepartmentData = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentData(res.data)
      } else {
        setDepartmentData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getSoNoDropdown = () => {
    const req = new PlantRequest
    req.plantId = Number(localStorage.getItem('unit_id'))
    saleService.getAllActiveSaleOrders(req).then((res) => {
      if (res.status) {
        setSoDropDown(res.data)
      } else {
        setSoDropDown([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getEmpByUnit = () => {
    const req = new EmployeeUnitRequest(Number(localStorage.getItem('unit_id')))
    empservice.getEmployeesByUnitId(req).then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        setEmployeeData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const handleDownTimeTracking = (value) => {
    setSelectedReason(value);
  }
 
  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  const onReset = () => {
    form.resetFields();
    setDowntimetype(DownTimePlannedCategoryenum.UN_PLANNED);

    setStatusVissible(false)
    setplannedtimeVissible(false)
  };
  const handleStatus = (value) => {
    setStatus(value);
    setStatusVissible(true);
  }
  const handleDowntimeType = (value) => {
    setDowntimetype(value);
    setStatusVissible(true);
    setplannedtimeVissible(true);
    
  }
  const getAllActiveDownTimeReason = () => {
    downTimeReasonService.getAllActiveDownTimeReason().then(res => {
      if (res.status) {
        setDownTimeReasonData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getMachineName = (value) => {
    if (value != undefined) {
      downtimeTrackingService.getMachineName({ assetCode: value }).then(res => {
        if (res.data) {
         if(res.data.length > 0){
          form.setFieldsValue({
            machineName: res.data[0].assetName
          })
        }else{
          form.resetFields(['machineCode'])
          AlertMessages.getErrorMessage('No Machine Found for scanned machine code')
        }
        } else {
          form.resetFields(['machineCode'])
          AlertMessages.getErrorMessage('No Machine Found for scanned machine code')
         
        }
      }).catch(err => {
        // AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage("Scann Valid QR Code");

    }

  }

  const saveData = (values: DownTimeTrackingDto) => {
    console.log(values,'dto in form')
    setDisable(false)
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      setDisable(false)
      save(values);
    }
  };
  const handleEmployeeName = (value) => {
    //setEmployeesData(value)
  }
  const handleCategory = (value) => {
    setCategory(value)
  }
  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      machineCode: value
    })
    getMachineName(value);

  }

  console.log(status,'status---')


  return (
    <Card size="small" title={<span style={{ color: 'white', fontSize:'20px'}}>DownTime Tracking</span>} style={{ textAlign: 'center' }} extra={props.isUpdate == true ? "" : <Link to='/downtimetracking-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout={'vertical'} onFinish={saveData} initialValues={props.downtimeTrackingData} >
        <Row gutter={24}>
          <Form.Item
            name="downtimeTrackingId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
        <Input hidden/>
      </Form.Item>
                  <Form.Item
                    style={{ display: "none" }}
                    name="unitId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <FormItem name='startTime' label='Start Time' initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: 'start time is required'
                }
              ]}
            >
              <DatePicker showTime disabled={props.isUpdate ? true : false}/>

            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="downtimetype"
              label="Downtime Type"
              initialValue={DownTimePlannedCategoryenum.UN_PLANNED}
              rules={[
                {
                  required: true,
                  message: 'downtime type is required',

                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the type`
                }
              ]}

            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select DowntimeType"
                optionFilterProp="children"
                onSelect={(value) => { handleDowntimeType(value) }}
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={DownTimePlannedCategoryenum.UN_PLANNED}
                onSearch={onSearch}
                //disabled={props.isUpdate ? false : true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select DowntimeType</Option>
                {Object.values(DownTimePlannedCategoryenumDropDown).map((value) => {
                  return <Option key={value} value={value}>{name}</Option>
                })} */}
                   {/* <Option value="null">Please select</Option> */}
                  {DownTimePlannedCategoryenumDropDown.map(item => {
                    return <Option value={item.value}>{item.name}</Option>
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: downtimetype == DownTimePlannedCategoryenum.PLANNED ? 'unset' : 'none' }}>
            <FormItem name='plannedtime' label='Expected Time' initialValue={moment()}
              rules={[
                {
                 
                required: form.getFieldValue('downtimetype')==DownTimePlannedCategoryenum.PLANNED?true:false,
                  message: 'Expected time is required'
                }
              ]}
            >
              <DatePicker showTime/>

            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="reasonId"
              label="DownTime Reason"
              rules={[
                {
                  required:true,
                  message: 'DownTime Reason is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the DownTimeReason`
                }
              ]}

            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select DownTimeReason"
                optionFilterProp="children"
                onChange={handleDownTimeTracking}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Reason</Option>
                {downTimeReasonData?.map((downTimeReason) => {
                  return <Option key={downTimeReason.downtimereasonId} value={downTimeReason.downtimereasonId}>{downTimeReason.downtimeReason}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name='departmentId'
              label='Department'
              rules={[
                {
                  required: props.isUpdate?true:false,
                  message: 'Department  is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the DepartmentReason`
                }
              ]}
            >

              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Department"
                optionFilterProp="children"
                // onChange={handlestatusTracking}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Department</Option>
                {departmentData?.map((value) => {
                  return <Option key={value.deptId} value={value.deptId}>{value.deptName}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'Category is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the category`
                }
              ]}
              initialValue={CategoryEnum.MACHINEISSUE}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select category"
                optionFilterProp="children"
                onChange={(value) => handleCategory(value)}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                defaultValue={CategoryEnum.MACHINEISSUE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Category</Option>
                {Object.values(CategoryEnum).map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: category == CategoryEnum.SALEORDER ? 'unset' : 'none' }}>
            <Form.Item
              name="saleorderId"
              label="PO Number"
              rules={[
                {
                  // required: props.isUpdate && ? true:false,
                required:props.isUpdate && form.getFieldValue('category')==CategoryEnum.SALEORDER?true:false,

                  message: 'Department  is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the DepartmentReason`
                }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select PO Number"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select PO Number</Option> */}
                {soDropDown?.map((value) => {
                  return <Option key={value.saleOrderId} value={value.saleOrderId}>{value.poNumber}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2}} lg={{ span: 2 }} xl={{ span:2}} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE  ? false :true}>
            <span><QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan')}} style={{fontSize:'30px', marginTop:'35px'}}/><b>scan</b></span>
          
           
          </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? false :true}>
            <Form.Item
              name="machineCode"
              label="Machine Code"
        
              rules={[
                {
                  required:form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? true:false,
                 // required:CategoryEnum.MACHINEISSUE || CategoryEnum.MANANDMACHINEISSUE ? true:false,
                  message: 'Machine code required'
                },
              
              ]}
              
            >

              <Input placeholder='Enter or Scan machine' suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan')}} style={{ fontSize: '28px', marginTop: '3px', marginRight: '-6px' }}/>} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} hidden={form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? false :true}>
            <Form.Item
              name="machineName"
              label="Machine Name"
              rules={[
                {
                  required:form.getFieldValue('category') == CategoryEnum.MACHINEISSUE || form.getFieldValue('category') == CategoryEnum.MANANDMACHINEISSUE ? true:false,
                  //required:CategoryEnum.MACHINEISSUE || CategoryEnum.MANANDMACHINEISSUE ? true:false,
                  message: 'Machine name required'
                },
              
              ]}

            >

              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: 'Employee Name is Required'
                },
                
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Employee"
                optionFilterProp="children"
                onChange={handleEmployeeName}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Employee</Option>
                {employeeData?.map((employeeName) => {
                  return <Option key={employeeName.employeeId} value={employeeName.employeeId}>{employeeName.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
       

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="impact"
              label="Impact"
              rules={[
                {
                  required: props.isUpdate?true:false,
                  message: 'Impact is required'
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="resources"
              label="No Of Resources"

              rules={[
                {
                  required: props.isUpdate?true:false,
                  message: 'resources required'
                },
                {
                  pattern: /^[0-9\b]+$/,
                  message: `Should contain only numbers`
                }
              ]}>

              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="status"
              label="Status"
              initialValue={DownTimeTrackingStatusEnum.OPEN}

            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select status"
                optionFilterProp="children"
                onChange={handleStatus}
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={DownTimeTrackingStatusEnum.OPEN}
                onSearch={onSearch}
                disabled={props.isUpdate ? false : true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Status</Option> */}
                {Object.values(DownTimeTrackingStatusEnum).map((key,value) => {
                  return <Option key={key} value={key}>{key}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{display:props.isUpdate && status == DownTimeTrackingStatusEnum.CLOSE?'unset':'none'}}>

            <FormItem 
            name='endtime' 
            label='End Time'
          
            rules={[
              {
                required:props.isUpdate && form.getFieldValue('status')==DownTimeTrackingStatusEnum.CLOSE?true:false,
                message: 'EndTime is required',

              }]} 
              initialValue={moment()}>
              <DatePicker showTime />

            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <Form.Item
              name="remarks"
              label="Remarks"
             
            >

              <TextArea rows={1} />
            </Form.Item>
          </Col>

{/* //Practicee
<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="resources"
              label="No Of Resources">

              <span><Input style={{padding:'15px'}} /><QrcodeOutlined style={{fontSize:'30px', marginTop:'35px',position:'absolute',top:'0',bottom:'0',padding:'10px'}}/></span>

            </Form.Item>
          </Col> */}


        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>

      <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
        }

      </Modal>

    </Card>
  );
}

export default DownTimeTrackingForm;
