import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { PaymentmodesDto } from '@gtpl/shared-models/masters';
import {PaymentmodesService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";

//import './payments-modes-form.css';


const { TextArea } = Input;

/* eslint-disable-next-line */
export interface PaymentmodesFormProps {
  paymentmodeData:PaymentmodesDto;
  updatePaymentmode:(paymentmode:PaymentmodesDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function PaymentModesForm(props: PaymentmodesFormProps) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  let history = useHistory();

  const paymentmodesService=new PaymentmodesService()

  const createPaymentmode=(paymentmodeData:PaymentmodesDto)=>{
       paymentmodeData.createdUser=localStorage.getItem('username')
    setDisable(true)
    paymentmodesService.createPaymentmode(paymentmodeData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Payment mode Created Successfully');
        history.push("/Payment-modes-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    form.resetFields();
  }

  const saveData = (values: PaymentmodesDto) => {
    setDisable(false)
    if(props.isUpdate){
      props.updatePaymentmode(values);
    }else{
      setDisable(false)
      createPaymentmode(values);
    }
  
  };

  return (
    <Card title={<span style={{color:'white'}}>Payment Mode</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/Payment-modes-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
      <Form form={form } layout={'vertical'} initialValues={props.paymentmodeData} name="control-hooks" onFinish={saveData}  >   
      <Form.Item name="paymentModeId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser" >
      <Input hidden/>
    </Form.Item>
    <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
              <Form.Item
                  name="paymentMode"
                  label="Payment Mode"
                  rules={[
                    {
                      required: true,
                      message:"Payment Mode Is Required"
                      
                    },
                    {
                      pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/\\_@ ]*$/,
                      message: `Should contain only alphabets and numbers.`
                    }
                  ]}>
                  <Input/>
                </Form.Item>
        </Col>
      </Row>
        <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
            </Col>
          </Row>
      </Form>
    </Card>
  );
}

export default PaymentModesForm;
