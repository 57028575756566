import React, { useState, useEffect } from 'react';
import { DocumentsList } from '@gtpl/shared-models/logistics';
import { DocumentsListService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import card, { CardType } from 'antd/lib/card';
import './documents.css';
import {SearchOutlined , ArrowDownOutlined} from '@ant-design/icons'
import Card from 'antd/lib/card';
import { Button, Col, Descriptions, Row, Table } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ColumnProps } from 'antd/lib/table';
import appSettings from 'apps/services/config';
import { DocumentType } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface DocumentListGridProps {
    saleOrderId: number;
    documentCategory: string;
    documentType: DocumentType;
  closeForm: () => void;

}
export function DocumentListGrid(props: DocumentListGridProps ) {
  const [documentsData, setDocumentsData] = useState<DocumentsList[]>();
  const [saleOrderNo, setSaleOrderNo] = useState<string>(undefined);
  const documentsListService = new DocumentsListService();
  const saleOrderService = new SaleOrderService();

  useEffect(() => {
    getDocumentsList(props);
    getSaleOrderNo(props.saleOrderId);
  }, []);

  const getDocumentsList = (props) => {
    documentsListService.getDocumentsListBySoId({saleOrderId:props.saleOrderId,documentCategory: props.documentCategory, documentType: props.documentType}).then((res) => {
      if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
        setDocumentsData(res.data);
    } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setDocumentsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDocumentsData([]);
    });
  }
  const getSaleOrderNo = (saleorder) => {
    console.log(saleorder);
    saleOrderService.getSaleOrderInformation({saleOrderId:saleorder}).then((res) => {
      console.log(res.data);
      if (res.status) {
        setSaleOrderNo(res.data.saleOrderNumber);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderNo(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNo(undefined);
    });
  }
  const downloadPath = appSettings.logistics_doc_upload_path;
  const download = (filePath) => {
    const fileName = `${filePath}`;
    if(fileName != 'null'){
      setTimeout(() => {
        const response = {
          file: appSettings.logistics_doc_upload_path+`${filePath}`,
        };
        
        window.open(response.file);
       
      }, 100);
    }
    else{
      AlertMessages.getErrorMessage("Please upload file. ");
  
    }
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Document Category',
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      align:'left',
    },
    {
      title: 'File',
      dataIndex: 'fileName',
      key: 'fileName',
      align:'left',
    },
    {
      title: 'Download',
      dataIndex: 'file',
      key: 'file',
      align:'left',
      render: (text, record,index) => ( 
        <Button style={{marginLeft:20}} onClick={() => {download(record.filePath);}}> <ArrowDownOutlined />Download</Button>
      ),
      
    },
  ]
  return (
    <Card title={<span style={{color:'white'}}>Uploaded Documents</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
      <br></br>
        <Descriptions column={3} style={{paddingLeft:"20"}}>
        <Descriptions.Item label="Sale Order No">
          {saleOrderNo}
        </Descriptions.Item>
        </Descriptions>
      <br></br>
          <Table
            rowKey={record => record.deptId}
            columns={columnsSkelton}
            scroll={{x:true}}
            dataSource={documentsData}
            pagination={false}
            bordered />
    </Card>
  );
}

export default DocumentListGrid;
