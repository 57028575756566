import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { CertificationDto, CertificationDtoDefault } from '@gtpl/shared-models/hrms';
import { CertificationService } from '@gtpl/shared-services/hrms';
import { Certification } from '@gtpl/pages/hrms/hrms-components/certification';

import './certification-grid.css';

/* eslint-disable-next-line */
export interface CertificationGridProps {}

export function CertificationGrid(
  props: CertificationGridProps
) {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);

  const [certificationData, setCertificationData] = useState<CertificationDto[]>([]);
  const [selectedCertification, setSelectedCertification] = useState<any>(CertificationDtoDefault);
  const service = new CertificationService;

  useEffect(() => {
    getAllCertifications();
  }, []);

  const getAllCertifications= () => {
    service.getAllCertifications().then(res => {
      if (res.status) {
        setCertificationData(res.data);
      } else {
        if (res.intlCode) {
          setCertificationData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setCertificationData([]);
    })
  }

  const updateCertification = (Data: CertificationDto) => {
    service.updateCertification(Data).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllCertifications();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const deleteCertification = (Data:CertificationDto) => {
    Data.isActive= Data.isActive?false:true;
    service.activateOrDeactivate(Data).then(res => { console.log(res);
      if (res.status) {
        getAllCertifications();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Certification',
      dataIndex: 'certification',
       responsive: ['lg'],
       width: '170px',
      sorter: (a, b) => a.certification.localeCompare(b.certification),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('certification')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      width: '170px',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      width: '170px',
      render: (text, rowData) => (
        <span>  
          <Tooltip placement="top" title='Edit'>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Certification');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteCertification(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Certification ?'
                :  'Are you sure to Activate Certification ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  const openFormWithData=(Data: CertificationDto)=>{
    setDrawerVisible(true);
    setSelectedCertification(Data);
  }

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{color:'white'}}>Certifications</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/certification-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
     <br></br>
     <Row gutter={40}>
     <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
          <Card title={'Total Certifications: ' + certificationData.length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
           <Card title={'Active: ' + certificationData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
           <Card title={'In-Active: ' + certificationData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
      <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
          rowKey={record => record.certificationId}
          columns={columnsSkelton}
          dataSource={certificationData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll = {{x:true}}
          onChange={onChange}
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <Certification key={Date.now()}
                updateData = {updateCertification}
                isUpdate={true}
                Data={selectedCertification}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}

export default CertificationGrid;
