import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Form, Tooltip, DatePicker, Empty, Row, Col, Select } from "antd";
import form from "antd/lib/form";
import moment from "moment";
import { AssetsService } from "apps/services/asset-management/src/app/open-assets/open-assets.service";
import { AssetService } from "libs/shared-services/asset-management/src/lib/assets.service";
//import { ContainerService } ';


export interface MonthWiseAssetProps {

}
export function MonthWiseAssetChart(props: MonthWiseAssetProps) {
    const service = new AssetService();
  const Option = Select
  const [containerData, setContainerData] = useState<any[]>([]);
 

console.log(containerData)

const monthWiseSaleAssets = () => {
    service.getMonthwiseAssetsSale().then((res) => {
        if (res.status) {
            setContainerData(res.data);
        } else {
            setContainerData([]);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setContainerData([]);
    })
}
 
  useEffect(()=>{
    monthWiseSaleAssets();

  },[])

const allmonths = ['January','February','March','April','May','June','July','August','September','October','November','December'];

let result = [];

allmonths.forEach(month => {
  const item = containerData.find(item => item.MONTH == month);
  
  if (item) {
    result.push(item);
  } else {
    result.push({MONTH: month,totalCount:0,totalCost:0});
  }
})
  
const graphDataMonths = result.map(i => {return i.MONTH});
const graphTotalCostData = result.map(i => {return Number(i.totalCost)});
const graphCountData = result.map(i =>{ return Number(i.totalCount)});
// console.log(graphQuantityData)
  const config = {
    colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
        backgroundColor: '#272953',
        type: 'column'
    },

        title: {
        text: 'Month-Wise Assets Sale',
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.75rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        }
      },
  

    // xAxis: {
    //   categories: graphDataMonths
    // },

    // yAxis: {
    //   allowDecimals: false,
    //   min: 0,
    //   // tickAmount: 16,
    //   // tickPixelInterval: 100,
    //   title: {
    //     text: 'Total Number of Assets'
    //   }
    // },

    xAxis: {
      categories: graphDataMonths,
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Total Number of Assets'
      },
      // tickAmount: 16,
      // tickPixelInterval: 100,
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.15rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      },
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },
    


    tooltip: {
      formatter: function () {
        var pointindex = this.point.index;
        var val1= graphCountData[pointindex]
        var val2= graphTotalCostData[pointindex]
        console.log(val2)
        return '<b>' + this.x + '</b><br/>' +
          this.series.name + ': ' +'<b>'+ val1+'</b>'  + '<br/>'+
          'Total Sale Cost :'+'<b>'+val2+'</b>';

      }
    },

    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },


    series: [
      {
      name: 'Assets Count',
      data:graphCountData,
      // stack: 'male'
    }, 
    // {
    //   name: 'Assets Cost',
    //   data:graphTotalCostData,
    //   stack: 'male'
    // },
  ]
  }
 
  // const years = (back) => {
  //   const year = new Date().getFullYear();
  //   return Array.from({ length: back }, (v, i) => year - back + i + 1);
  // }
  // const lastTenYears = years(10)


  return (
    <Form layout="vertical" name="control-hooks">
      <Row gutter={24} justify='center'>
      </Row>
      {containerData.length ?
      <div><HighchartsReact highcharts={Highcharts} options={config} /></div> : <Empty/>
      }
    </Form>

  );
}
export default MonthWiseAssetChart;