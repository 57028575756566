import { PickUpDto, RouteDto } from "@gtpl/shared-models/hrms";
import { UnitcodeDto, VehicleDto } from "@gtpl/shared-models/masters";
import { UserService } from "@gtpl/shared-services/attendance";
import {  UnitcodeService, VehicleService } from "@gtpl/shared-services/masters";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Select, Form, Button, Card, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {PickUpService , RouteService} from "@gtpl/shared-services/hrms";

export interface RouteFormProps {
  routeData: RouteDto;
  updateRoute: (route: RouteDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function RouteForm(props: RouteFormProps) {
  const { Option } = Select;
  const [form] = Form.useForm();
  let history = useHistory();
  const [display, setDisplay] = useState<string>('none');
  const [disable, setDisable] = useState<boolean>(false)
  const [unitData, setUnitData] = useState<UnitcodeDto[]>([])
  const [vehicleData, setVehicleData] = useState<VehicleDto[]>([])
  const [pickup, setPickup] = useState<PickUpDto[]>([])
  const routeService = new RouteService();
  const unitService = new UnitcodeService();
  const vehicleService = new VehicleService();
  const pickUpService = new PickUpService();

  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }

  const createRoute = (routeData: RouteDto) => {
    setDisable(true)
    routeService
      .createRoute(routeData)
      .then((res) => {
        setDisable(false)
        console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Route Created Successfully');
          history.push('/route-view');
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            console.log(res.internalMessage)
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  const saveData = (values: RouteDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateRoute(values);
    } else {
      setDisable(false)
      console.log(values);
      createRoute(values);
    }
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  useEffect(() => {
    getAllUnits();
    getAllVehicles();
    getAllPickUp();
  }, []);

  const getAllUnits = () => {
    unitService.getAllActiveUnitcode().then(res => {
      if (res.status) {
        setUnitData(res.data);
      } else {
        if (res.intlCode) {
          setUnitData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setUnitData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getAllVehicles = () => {
    vehicleService.getAllActiveVehicles().then(res => {
      if (res.status) {
        setVehicleData(res.data);
      } else {
        if (res.intlCode) {
          setVehicleData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setVehicleData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllPickUp = () => {
    pickUpService.getAllPickUp().then(res => {
      if (res.status) {
        setPickup(res.data);
      } else {
        if (res.intlCode) {
          setPickup([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPickup([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  return (
    <Card
      title={<span style={{ color: 'white' }}>Route</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={
        props.isUpdate == true ? (
          ''
        ) : (
          <Link to="/route-view">
            <Button className="panel_button">View </Button>
          </Link>
        )
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={props.routeData}
        name="control-hooks"
        onFinish={saveData}
      >
        <Form.Item name="routeId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="routeNumber"
              label="Route Number"
              rules={[
                {
                  required: true,
                  message: 'Route Number is required',
                  max: 10
                },
                {
                  pattern: /^[a-zA-Z0-9_.-]*$/,
                  message: `Should contain only alphabets and Numbers.`
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="vehicleNumber"
              label="Vehicle Model"
              rules={[
                { required: true, message: 'Vehicle Number is required' },
              ]}
            >
              <Select
                placeholder="Select Vehicle Number"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {vehicleData.map(dropData => {
                  return <Option value={dropData.vehicleName}>{dropData.vehicleName}</Option>;
                })}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="distance"
              label="Distance"
              rules={[
                { required: true, message: 'Distance is required', max: 10 },
                {
                  pattern: /^[a-zA-Z0-9_.-]*$/,
                  message: `Should contain only alphabets and Numbers.`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="plant"
              label="Plant"
              rules={[
                { required: true, message: 'Plant is required' },
              ]}
            >
              <Select
                placeholder="Select Plant"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {unitData.map(dropData => {
                  return <Option value={dropData.unitcodeName}>{dropData.unitcodeName}</Option>;
                })}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="pickUp"
              label="Pickup Point"
              rules={[
                { required: true, message: 'Pickup Point is required' },
              ]}
            >
              <Select
                mode='multiple'
                placeholder="Select Pickup Point"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {pickup.map(dropData => {
                  return <Option value={dropData.pickUp}>{dropData.pickUp}</Option>;
                })}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit">
            Submit
          </Button>
          {props.isUpdate === false && (
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
          )}
        </Col>
      </Form>
    </Card>
  );
}
export default RouteForm;