import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Input, Button, Row, Col, Form, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import { DesignationService, EmployeeLeavesService, TypesOfLeavesService } from '@gtpl/shared-services/hrms';
import { Excel } from 'antd-table-saveas-excel';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './leave-management-report.css';
import { DesignationDto, EmpLeaveDTO, EmployeeLeavesCreateDTO, GetEmpLeaves, TypesOfLeavesDto } from '@gtpl/shared-models/hrms';
import UndoOutlined from '@ant-design/icons/lib/icons/UndoOutlined';
import { ColumnProps } from 'antd/lib/table';

/* eslint-disable-next-line */
export interface LeaveManagementReportProps { }

let i = 1;
const excelColumns: any[] = [
  { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
  { title: 'Employee Name', dataIndex: 'employeeName' },
  { title: 'Employee Code', dataIndex: 'employeeCode' },
  { title: 'Designation', dataIndex: 'designation', },
];

export function LeaveManagementReport(
  props: LeaveManagementReportProps
) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [employeeData, setEmployeeData] = useState<EmpLeaveDTO[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<EmpLeaveDTO[]>([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<EmpLeaveDTO[]>([]);
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const designationService = new DesignationService();
  const { Option } = Select;
  const [form] = Form.useForm();
  const service = new HrmsEmpDetService();
  const employeeLeavesService = new EmployeeLeavesService();
  const typesOFLeavesService = new TypesOfLeavesService();


  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      console.log('-----------------------');
      console.log(value);
      console.log(record[dataIndex]);
      console.log(record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()));
      // return false;
      return record[dataIndex] ? record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()) : false;
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) { 
        setTimeout(() => searchInput.current.select()); 
      }
    },
    render: text =>
      text ?(
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    console.log(selectedKeys)
    console.log(confirm)
    console.log(dataIndex)

    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const commonColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      //  responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      // responsive: ['sm'],
      ...getColumnSearchProps('employeeCode'),
      sorter: (a, b) => a.employeeCode - b.employeeCode,
      width: '150px',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Employee Name',
      // dataIndex: 'employeeName',
      width: '350px',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName'),
      render(record){
      const emaname : any =(record?.employeeName)+' '+(record?.employeeLastName)
      return emaname;
      }
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: '150px',
      // responsive: ['sm'],
      sorter: (a, b) => a.designation?.localeCompare(b.designation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('designation')

    },
  ];
  // const exportedData = [];
  // console.log(exportedData)
  const excelData = employeeData

  useEffect(() => {
    getFiltersDropdownData(filtersData);
  }, [filtersData])

  useEffect(() => {
    getAllActiveEmployeesForLeaves();
    getTypesOfLeaves();
    getAllDesignation();
  }, []);
  const [columns, setColumns] = useState(commonColumns);
  const addLeavesToEmp = () => {
    const createdUser = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    const reqObj = new EmployeeLeavesCreateDTO(createdUser, new Date(), selectedRows);
    employeeLeavesService.createEmployeeLeaves(reqObj).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setVisible(false);
        getAllActiveEmployeesForLeaves();
        getTypesOfLeaves();
      }
      else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }
  const searchData = () => {
    const employeeName = form.getFieldValue('employeeName');
    const employeeCode = form.getFieldValue('employeeCode');
    const designation = form.getFieldValue('designation');

    let selectedData = filtersData;
    if (employeeName) {
      selectedData = filtersData.filter(item => item.employeeName === employeeName)
    }
    if (employeeCode) {
      selectedData = filtersData.filter(item => item.employeeCode === employeeCode)
    }
    if (designation) {
      selectedData = selectedData.filter(item => item.designation === designation)
    }
    setEmployeeData(selectedData);
  }


  const getFiltersDropdownData = (filtersData) => {
    let etdData: any[] = [];
    let employeesData: any[] = [];
    filtersData.forEach(element => {
      if (element.employeeName) {
        employeesData.push(element.employeeName)
      }
      if (element.employeeCode) {
        employeesData.push(element.employeeCode)
      }
      if (element.designation) {
        employeesData.push(element.designation)
      }
    });
    ///setEmployeeDetails(employeesData);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeysData(selectedRowKeys);
      setSelectedRows(selectedRows);
      setVisible(true);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeysData
  };

  const getAllActiveEmployeesForLeaves = () => {
    employeeLeavesService.getAllEmployeeLeaves(new GetEmpLeaves(new Date().getFullYear().toString())).then(res => {
      if (res.status) {
        setEmployeeData(res.data);
        setFiltersData(res.data);
      } else {
        setEmployeeData([]);
        setFiltersData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setEmployeeData([]);
      setFiltersData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const leavesChangeHandler = (employeData, leaveTypeId: number, leavesAlloted: number) => {
    const defaultVlaues = employeData.leaves.find(rec => rec.typeOfLeave === leaveTypeId);
    defaultVlaues.leavesAlloted = leavesAlloted;
    const newEmpDataState = employeeData.map(empData => {
      if (employeData.employeeId === empData.employeeId) {
        return employeData
      } else {
        empData
      }
    });
  }

  const getTypesOfLeaves = () => {
    const columns = []; // [...commonColumns];
    typesOFLeavesService.getAllActiveTypesOfLeaves().then(res => {
      if (res.status) {

        res.data.forEach(element => {
          const dynCol = {
            title: element.typeOfLeave + 's',
            dataIndex: element.typeOfLeave.replace(/ /g, ""),
            width: '120px',
            render: (text, record, index) => {
              const defaultVlaues = record.leaves.find(rec => rec.typeOfLeave === element.typeOfLeaveId)
              return <span style={{ textAlign: 'center' }}><span>{'Alloted : '}<b>{defaultVlaues.leavesAlloted}</b></span><br /><span>{'Used : '}<b>{defaultVlaues.leavesUsed}</b></span><br /><span>{'Available : '}<b>{defaultVlaues.leavesAvailable}</b></span></span>
            }
          }
          excelColumns.push(
            {
              title: element.typeOfLeave + 's',
              dataIndex: element.typeOfLeave.replace(/ /g, ""),
              render: (text, record, index) => {
                const defaultVlaues = record.leaves.find(rec => rec.typeOfLeave === element.typeOfLeaveId)
                return defaultVlaues.leavesAvailable
              }
            }
          );
          columns.push(dynCol);
        });
        setColumns(columns);
        setKeyUpdate(keyUpdate + 1);
      }
    }
    )
  }

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('id')
      .addColumns(excelColumns)
      .addDataSource(employeeData, { str2num: true })
      .saveAs('leave-mangement-Report.xlsx');
  }

  const getAllDesignation = () => {
    designationService.getAllDesignation().then(res => {
      if (res.status) {
        setDesignationData(res.data);
      } else {
        if (res.intlCode) {
          setDesignationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  
  const onReset = () => {
    // form.resetFields();
    form.resetFields();
    setSearchText('');
    setEmployeeData(filtersData);
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }

  const handleEmployeeName = () => {
    const employeeId = form.getFieldValue('employeeName')
    const request = new HrmsEmployeeRequest(employeeId);
    service.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        console.log(res.data.employeeCode);
        form.setFieldsValue({
          employeeCode: res.data.employeeCode,
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const handleEmployeeCode = (value) => {
    //setEmployeesData(value)
  }
  const handleDesignation = (value) => {
    // setEmployeesData(value)
  }

  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <><Card title={<span style={{ color: 'white' }}>Employee Balance Report</span>} extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <br></br>
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
          <Input hidden />
        </Form.Item>
        <Row gutter={[24, 24]}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
          <Form.Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "name is required"
                },

              ]}
            >

              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Name"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={handleEmployeeName}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {employeeData.map((employeeData) => {
                 return <Option key={employeeData.employeeId} value={employeeData.employeeId}>{`${employeeData.employeeName} ${employeeData.employeeLastName}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "code is required"
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: false, message: 'Select Designation',
                },
              ]}
            >
              <Select
                placeholder="Select Designation"
                onChange={handleDesignation}
                allowClear
                style={{ width: '100%' }}
                showSearch
              >
                {designationData.filter(emp => emp.designation != '').map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ marginTop: 30 }}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block onClick={() => searchData()}
              style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>
            {/* <Button type='primary' block onClick={()=>searchData()}>Get</Button> */}
            <Button
              icon={<UndoOutlined />}
              onClick={() => onReset()}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
      {/* <Row>
        {visible && selectedRowKeysData.length ? <Button type='primary' style={{ alignContent: 'left' }} onClick={addLeavesToEmp}>Submit</Button> : ""}
      </Row> */}
      <br></br>

      <Table
        key={Date.now()}
        // rowSelection={{
        //   ...rowSelection
        // }}
        //rowKey={record => Date.now() + '-' + keyUpdate}
        // columns={columns}
        columns={[...commonColumns,...columns]}
        dataSource={employeeData}
        pagination={{ onChange(current) { setPage(current) } }}
        onChange={onChange}
        bordered />



    </Card></>
  );
}

export default LeaveManagementReport;
