import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import {EndCustomersService} from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CurrencyDto, EndCustomerDto, EndCustomerRequest} from '@gtpl/shared-models/masters'
import './end-customer-grid.css';
import { Link } from 'react-router-dom';
import { CurrencyService } from '@gtpl/shared-services/masters';

import {EndCustomersForm} from '@gtpl/pages/master/master-components/end-customer-form';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface EndCustomerGridProps {

}

export function EndCustomerGrid(props: EndCustomerGridProps) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [hrmsData, setHrmsData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const endCustomersService = new EndCustomersService();
  const [selectedEndCustomersData, setSelectedEndCustomersData] = useState<EndCustomerDto>();
  const [endCustomersData, setEndCustomersData] = useState<EndCustomerDto[]>([]);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const currencyService = new CurrencyService();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const username = localStorage.getItem('username')

  const openFormWithData=(viewData: EndCustomerDto)=>{
    console.log(viewData);
    setDrawerVisible(true);
    setSelectedEndCustomersData(viewData);
  }
  useEffect(() => {
    getAll();
    getCurrenciesData();
  }, []);
  useEffect(() => {
    // getAll();
    console.log(endCustomersData)
  }, [endCustomersData]);
  const getAll= () => {
    endCustomersService.getAllEndCustomers(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
console.log(res);
      if (res.status) {

        setEndCustomersData(res.data);
      } else {
        if (res.intlCode) {

          setEndCustomersData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEndCustomersData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }


  const getCurrenciesData = () => {
    currencyService.getAllActiveCurrencys().then((res) => {
      if (res.status) {
        setCurrencies(res.data);
        let filtersArray:{text:string,value:string}[] = []
        res.data.map(item => {
          if(!(filtersArray.includes({text:item.currencyName,value:item.currencyName})))
          filtersArray.push({text:item.currencyName,value:item.currencyName})
        })
        setFiltersData(filtersArray)
        console.log(res.data)
      } else {
        setCurrencies([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCurrencies([]);
      });
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const deleteProfile = (endCustomersData:EndCustomerRequest) => {
    endCustomersData.isActive=endCustomersData.isActive?false:true;
    endCustomersService.ActivateorDeactivateEndCustomers(endCustomersData).then(res => { console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
       render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'End Customer Code',
      dataIndex:'endCustomerCode',
      sorter: (a, b) => a.endCustomerCode.localeCompare(b.endCustomerCode),
      sortDirections: ['descend', 'ascend'],
     ...getColumnSearchProps('endCustomerCode')
    },
    {
      title: 'End Customer Name',
      dataIndex:'endCustomerName',
      sorter: (a, b) => a.endCustomerName.localeCompare(b.endCustomerName),
        sortDirections: ['descend', 'ascend'],
     ...getColumnSearchProps('endCustomerName')
    },
    {
      title: 'Consignee Name',
      dataIndex:'consignee',
      sorter: (a, b) => a.consignee.localeCompare(b.consignee),
        sortDirections: ['descend', 'ascend'],
     ...getColumnSearchProps('consignee')
    },
    {
      title: 'Currency',
      dataIndex: ["currenciesInfo","currencyName"],
      // render: (text,rowData) => {return rowData.currenciesInfo.currencyName},
      responsive: ['sm'],
    //  sorter: (a, b) => a.currenciesInfo.currencyName.localeCompare(b.currenciesInfo.currencyName),
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('currencyId')
    render:(text,data) => {
      console.log(data)
      return text
    },
    filters: filtersData
     ,
    filterMultiple: false,
    onFilter: (value, record) => 
    {
      console.log(value)
      return record.currenciesInfo.currencyName === value;
    },
    },
    
    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        rowData.endCustomerCode.trim().toLowerCase()=="sandhya aqua"?<span></span>:
        <span>         
          { username == '"ramakrishna"' ?
          <>
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                console.log(rowData);

                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated EndCustomer');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            
            </>:''
            }
              <Popconfirm onConfirm={e =>{deleteProfile(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate endCustomers ?'
                :  'Are you sure to Activate endCustomers?'
            }
          >  
             <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>  
        </span>
      )
    }
  ];
  const updateCustomer = (customerDto: EndCustomerDto) => {
    
    endCustomersService.updateEndCustomers(customerDto).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
     }
  
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }


  const updateEndCustomer = (Data: EndCustomerDto) => {
    console.log('updateCustomerData');
    endCustomersService.updateEndCustomers(Data)
    .then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
console.log(endCustomersData.indexOf[0]);
// console.log(selectedEndCustomersData);
        return (
          
          <Card title={<span style={{color:'white'}}> End Customer </span>}
          style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={ <Link to='/EndCustomerform' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
          <br></br>
            <Row gutter={40}>
            <Col>
                <Card title={'Total End Customers : ' + endCustomersData.length} style={{textAlign: 'left', width: 240, height: 41,backgroundColor:'#bfbfbf'}}></Card>
                </Col>
                <Col>
                <Card title={'Active : ' + endCustomersData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
                </Col>
                <Col>
                <Card title={'In-Active :' + endCustomersData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
                </Col>
                </Row> 
                <br></br>
                <Table
                rowKey={record => record.endCustomerId}
                columns={columnsSkelton}
                dataSource={endCustomersData}
                scroll = {{x:true}}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                bordered />
              <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
                  onClose={closeDrawer} visible={drawerVisible} closable={true}>
                  <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                    <EndCustomersForm key={Date.now()}
                      endCustomersData={selectedEndCustomersData}
                      updateDetails={updateEndCustomer}
                      isUpdate={true}
                      
                      closeForm={closeDrawer} />
                  </Card> 
                </Drawer>
          </Card>
        );


}
export default EndCustomerGrid;
