import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';
import { useLocation } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

/* eslint-disable-next-line */
export interface FreigstInvoiceProps {
  saleOrderId:number;
}

export function FreigstInvoice(
    props: FreigstInvoiceProps
) {
    let location = useLocation();
    const salOrderService = new SaleOrderService();
    const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
    const soId = location.state;

    const getData = (saleOrderId) => {
        salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
            if (res.status) {
                console.log(res.data);
                setPlantDetails(res.data);
            } else {
                if (res.intlCode) {
                    setPlantDetails(undefined);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setPlantDetails(undefined);
            });
    };


    useEffect(() => {
        if (soId) {
            getData(soId);
        }
        // window.print()
    }, [soId])

    return (
        <div>
            {/* <Card title={<span style={{color:'white'}}>FREIGHT INVOICE</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Button>Print</Button>}> */}

            <html>
                <body id = "printme">
                    <h1 style={{ textAlign: 'center', fontSize: '32px' }}>FREIGHT INVOICE 
                    {/* <Button onClick={() => window.print()} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}>Print</span></Button> */}
                    </h1>
                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }}>

                        <tr>
                            <td className={'ta-b'}>

                            </td>
                            <td className={'ta-b'}>
                                <h2 style={{ textAlign: 'center' }}>TAX INVOICE<br /> </h2>
                            </td>
                            <td className={'ta-b'}>(logo)
                            </td>
                        </tr>
                    </table>

                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }}>
                        <tr>
                            <td style={{ borderRight: '1px solid black' }}>
                                Payment Info<hr />

                Bill of Lading:   - <br />
                Customer:{plantDetails?.endCustomerName} <br />
                Cust. Ref: - {plantDetails?.saleOrderItems[0].customeraddress}<br />
                            </td>
                            <td>
                                <td style={{ width: '100%' }}>
                                    <h3>EXPORT INVOICE</h3>
                INEAP039576<br />
                Date: {plantDetails?.invoiceDate}<br />
                                </td>
                                <td>
                                    <h3>ORIGINAL* </h3>

                                </td>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ borderRight: '1px solid black' }}>
                                <tr>
                                    <td>
                                        Payable to:
                </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        POSP Place Of<br />
                    Service Provider:<br />
                                    </td>
                                    <td>
                                        GSTIN:
                    </td>
                                </tr>
                                <tr>
                                    Contact Info <br />
                Invoiced By:
                </tr>
                            </td>
                            <td>
                                <tr>
                                    <td>Invoice To:</td>
                                    <td>SANDHYA AQUA EXPORTS PVT LIMITED<br />
                        MIG 25A LAWSONS BAY COLONY<br />
                        VISAKHAPATNAM/530003<br />
                        INDIA</td>
                                </tr>
                                <tr>
                                    GSTIN: 37AAJCS2286C1ZA
                </tr>
                                <tr>
                                    <td>(qr code)</td>
                                    <td>
                                        <h3>IRN:</h3>
                        fe2fba56dde62f303327b6ce6a705f533d3 <br />
                        be497b17c99d77c1f6d8fb3582ff2<br />
                        Ack. No.: 112110831398530<br />
                        Ack. Date: 14-APR-2021 11:19:00
                    </td>
                                </tr>
                            </td>
                        </tr>
                    </table>
                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed', borderTop: '0px solid' }}>
                        <tr>
                            <td>Voyage:25714E</td>
                            <td>Local Voyage Ref: -</td>
                            <td>Vessel: CAPE MAYOR</td>
                            <td>Call Date: 06 APR 2021</td>
                        </tr>
                        <tr style={{ borderTop: '1px solid black' }}>
                            <td colSpan={2}>
                                <tr>
                                    Place of Receipt: -
                </tr>
                                <tr>Load Port: </tr>
                            </td>
                            <td colSpan={2}>
                                <tr>Discharge Port: </tr>
                                <tr>Place of Delivery: </tr>
                            </td>
                        </tr>
                        <tr>
                            <td>Commodity Code</td>
                            <td>Description</td>
                            <td>Package</td>
                            <td>Qty</td>
                        </tr>
                        <tr>
                            <td>160529</td>
                            <td>Shrimps and prawns : Other</td>
                            <td>40RH</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Container Number(s):APRU6106090</td>
                            <td colSpan={3}></td>
                        </tr>
                    </table>
                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed', borderTop: '0px solid' }}>
                        <tr style={{ borderBottom: '1px solid' }}>
                            <td>Size/Type</td>
                            <td>Charge Description</td>
                            <td>Tax</td>
                            <td>Based on</td>
                            <td>Rate</td>
                            <td>Currency</td>
                            <td>Amount</td>
                            <td style={{ borderLeft: '1px solid' }}>Amount in USD</td>
                        </tr>
                        <tr>
                            <td>40RH  C</td>
                            <td>BASIC FREIGHT</td>
                            <td>IN</td>
                            <td>1UNI</td>
                            <td>3,750.00</td>
                            <td>USD</td>
                            <td> 3,750.00</td>
                            <td style={{ borderLeft: '1px solid' }}> 3,750.00</td>
                        </tr>
                        <tr>
                            <td>40RH  C</td>
                            <td>Bunker surcharge NOS</td>
                            <td>IN</td>
                            <td>1UNI</td>
                            <td>967.00</td>
                            <td>USD</td>
                            <td>967.00</td>
                            <td style={{ borderLeft: '1px solid' }}>967.00</td>
                        </tr>
                        <tr>
                            <td>40RH  C</td>
                            <td>Peak Season Adjustment Factor</td>
                            <td>IN</td>
                            <td>1UNI</td>
                            <td>300.00</td>
                            <td>USD</td>
                            <td>300.00</td>
                            <td style={{ borderLeft: '1px solid' }}>300.00</td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={3}>Rate of Exchange</td>
                            <td colSpan={4}>Currency Charge Totals</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={3}>1 USD = 74.688177 INR</td>
                            <td colSpan={4}>USD      5,017.00</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={3}>GST Tax applied as indicated on charges</td>
                            <td colSpan={4}>Total Excluding Tax</td>
                            <td style={{ borderLeft: '1px solid' }}> 5,017.00</td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={2}>Service Description</td>
                            <td>SAC</td>
                            <td>POS State</td>
                            <td>Tax</td>
                            <td>Taxable Amt.</td>
                            <td>Tax Amount</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={8}>Freight Charges</td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={2}>AP IGST @ 0% BASIC FREIGHT</td>
                            <td>996521</td>
                            <td>OTHER TERRITORY</td>
                            <td>IN</td>
                            <td> 3,750.00</td>
                            <td> 0.00</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>AP IGST @ 0% BAF NOS</td>
                            <td>996521</td>
                            <td>OTHER TERRITORY</td>
                            <td>IN</td>
                            <td>  967.00</td>
                            <td> 0.00</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>AP IGST @ 0% PEAK SEASON CH</td>
                            <td>996521</td>
                            <td>OTHER TERRITORY</td>
                            <td>IN</td>
                            <td>  300.00</td>
                            <td> 0.00</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={2}>Total IGST</td>
                            <td></td>
                            <td>OTHER TERRITORY</td>
                            <td></td>
                            <td>0.00USD</td>
                            <td>0.00 INR</td>
                            <td style={{ borderLeft: '1px solid' }}></td>
                        </tr>
                        <tr style={{ borderTop: '1px solid' }}>
                            <td colSpan={6}></td>
                            <td>Total GST TAX</td>
                            <td style={{ borderLeft: '1px solid' }}>0.00</td>
                        </tr>
                    </table>
                    <br />
                    {/* <table className={'ta-b'} style={{width:'100%',tableLayout: 'fixed'}}>
            <tr>
                <td>
                    <td>
                        (C)For and on behalf of CMA - CGM<br/>
                        4 QUAI D'ARENC<br/>
                        13002 MARSEILLE-FRANCE<br/>
                        FR72562024422 <br/>
                    </td>
                    <td>Carrier No. INCMA6661617</td>
                    <td rowSpan={2} style={{borderLeft: '1px solid'}} >
                        <h2>Total Amount: 5,017.00 USD</h2><br/>
                        Payable by 14-APR-2021<br/>
                        Payment before delivery of Bill Of Lading (Export) or containers (Import). <br/>
                        Our invoices are payable in full to CMA CGM by the indicated due date, without any<br/>
                        deduction, including for payments made in advance. All overdue payments shall bear<br/>
                        interest @ 18% per annum. Subject to Mumbai Jurisdiction. E & O.E.<br/>
                    </td>
                </td>
            </tr>
            <tr>
                <td colSpan={2} style={{borderRight:'1px solid'}}>
                    SWIFT Code: HSBCINBB<br/>
                    The Hongkong and Shanghai Banking Corporation Limited<br/>
                    THE HSBC LTD<br/>
                    FORT BRANCH 52 60 MG ROAD<br/>
                    MUMBAI<br/>
                    400 001 INDIA MH<br/>
                    Account Number.006-099311-861 <br/>
                </td>
            </tr>
        </table> */}
                    <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }}>
                        <tr >
                            <td style={{ borderRight: '1px solid' }}>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td>
                                        (C)For and on behalf of CMA - CGM<br />
                        4 QUAI D'ARENC<br />
                        13002 MARSEILLE-FRANCE<br />
                        FR72562024422 <br />
                                    </td>
                                    <td >Carrier No. INCMA6661617</td>
                                </tr>
                                <tr style={{ borderTop: '1px solid' }}>
                                    <td colSpan={1}>
                                        SWIFT Code: HSBCINBB<br />
                            The Hongkong and Shanghai Banking Corporation Limited<br />
                            THE HSBC LTD<br />
                            FORT BRANCH 52 60 MG ROAD<br />
                            MUMBAI<br />
                            400 001 INDIA MH<br />
                            Account Number.006-099311-861 <br />
                                    </td>
                                </tr>
                            </td>
                            <td>
                                <h2>Total Amount: 5,017.00 USD</h2><br />
                    Payable by 14-APR-2021<br />
                    Payment before delivery of Bill Of Lading (Export) or containers (Import). <br />
                    Our invoices are payable in full to CMA CGM by the indicated due date, without any<br />
                    deduction, including for payments made in advance. All overdue payments shall bear<br />
                    interest @ 18% per annum. Subject to Mumbai Jurisdiction. E & O.E.<br />

                            </td>
                        </tr>
                    </table>

                    <p style={{ textAlign: 'left' }}>
                        Agent: CMA CGM Agencies (India) Private Limited (CCAI), Regd. Office Address: 219, 2nd Floor, Metro House, Mangaldas Road, Pune – 411001.<br />
        CIN No – U63012PN2008PTC133890 Tel: +91 20 3045 8390/ Email: mby.genmbox@cma-cgm.com / Website: www.cma-cgm.com<br />
        CMA CGM SA, PAN No: AABCC9048G. Invoice issued by CCAI for and on behalf of CMA CGM SA.<br />
                    </p>
                </body>
            </html>



            {/* </Card> */}
        </div>
    )
}

export default FreigstInvoice;