import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber } from 'antd';
import { PackingMethodDto, PalletCapacityDto, PlantsDropDown, UnitcodeDto } from '@gtpl/shared-models/masters';
import { PackingMethodService, PalletCapacityService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";

import { UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';


const { TextArea } = Input;
const { Option } = Select;

import './pallet-capacity-form.css';
import { SizeCategoryEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface PalletCapacityFormProps {
  palletCapacityData: PalletCapacityDto;
  updatePalletCapacity: (palletCapacity: PalletCapacityDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function PalletCapacityForm(
  props: PalletCapacityFormProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
 const [packMethodData,setPackMethodData] = useState<PackingMethodDto[]>()
 const [plantData,setPlantData] =useState<PlantsDropDown[]>()
 const palletCapacityService = new PalletCapacityService();
 const packMethodService = new PackingMethodService();
 const plantsService = new UnitcodeService();

  // useEffect(() => {
  //   if (props?.palletCapacityData?.UnitId) {
  //     setSelectedUnit(props.palletCapacityData.UnitId)
  //   }
  // }, [])
  useEffect(() => {
    getPackMethods();
    getPlantsData();
  },[])

  const createPalletCapacity = (palletCapacityData: PalletCapacityDto) => {
    console.log(palletCapacityData)
    palletCapacityService.createPalletCapacity(palletCapacityData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Pallet Capacity Created Successfully');
        history.push("/pallet-capacity-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset = () => {
    form.resetFields();
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onBlur = () => {
    console.log('blur');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const saveData = (values: PalletCapacityDto) => {
    if (props.isUpdate) {
     
      props.updatePalletCapacity(values);
    } else {
      
      createPalletCapacity(values);
    }
  };

  const getPackMethods = () => {
    packMethodService.getAllActivePackingMethods().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Umos Retrived successfully');
        setPackMethodData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getPlantsData = () => {
    plantsService.getAllMainPlants().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Umos Retrived successfully');
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  
  

  return (


    <Card title={<span style={{ color: 'white' }}>Pallet Capacity</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/pallet-capacity-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} initialValues={props.palletCapacityData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="palletCapacityId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="unitId"
              label="Unit"
              rules={[
                {
                  required: true,
                  message: 'Unit name is required'
                }
                
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Unit"
              
               optionFilterProp="children"
               
                onFocus={onFocus}

                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Unit'S Name</Option>
                {plantData?.map((value) => {
                  return <Option key={value.plantId} value={value.plantId}>{value.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="packingMethodId"
              label="Pack Style"
              rules={[
                {
                  required: true,
                  message: 'Pack Style is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the Pack Style`
                }
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Pack Style"
              
               optionFilterProp="children"
               
                onFocus={onFocus}

                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Pack Style</Option>
                {packMethodData?.map((value) => {
                  return <Option key={value.packingMethodId} value={value.packingMethodId}>{value.packingMethodName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="sizeCategory"
              label="Size Category"
              rules={[
                {
                  required: true,
                  message: 'Size Category is required'
                }
                
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Size Category"
              
               optionFilterProp="children"
               
                onFocus={onFocus}

                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Size Category</Option>
                {Object.values(SizeCategoryEnum)?.map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="palletCapacity"
              label="Pallet Capacity(in cases)"
             
              rules={[
                {
                  required:true,
                  message: "Enter valid Pallet Capacity ."
                },
               
              ]}>

              <InputNumber style={{width:'100%'}}/>
            </Form.Item>
          </Col>
        
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  )

}

export default PalletCapacityForm;
