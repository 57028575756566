import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, ConfigProvider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import moment from 'moment';
import { ExitActionDto } from '@gtpl/shared-models/hrms';
import { ExitActionService } from '@gtpl/shared-services/hrms';
import ExitActionForm from '../../../exit-action-form/src/lib/exit-action-form';
import { ColumnProps } from 'antd/lib/table';
export interface ExitActionGridProps {}

export function ExitActionGrid(props: ExitActionGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  
  const [exitActionData, setExitActionData] = useState<ExitActionDto[]>([]);
  const [selectedExitActionsData, setSelectedExitActionData] = useState<any>(undefined);
  const [allData, setAllData] = useState<ExitActionDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const exitActionService = new ExitActionService();
  useEffect(() => {
    getAllExitActions();
  }, []);

  /**
   * 
   */
  const getAllExitActions = () => {
    exitActionService.getAllExitActions().then(res => {
      if (res.status) {
        setExitActionData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setExitActionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setExitActionData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })


  }

  /**
   * 
   * @param exitActionData 
   */
  const deleteExitAction = (exitActionData: ExitActionDto) => {
    exitActionData.isActive = exitActionData.isActive ? false : true;
    exitActionService.activateOrDeactivateExitAction(exitActionData).then(res => {
      console.log(res);
      if (res.status) {
        getAllExitActions();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
  const updateExitAction = (Data: ExitActionDto) => {
    exitActionService.updateExitAction(Data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllExitActions();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
        )
        : null

  });


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const columnsSkelton: ColumnProps<any>[] = [

    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Exit Date',
      key: 'exitDate',
      sorter: (a, b) => moment(a.exitDate).unix() - moment(b.exitDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.exitDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      responsive: ['sm'],
       sorter: (a, b) => String(a.employeeCode)?.localeCompare(b.employeeCode),
       sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeCode'),   
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    
    {
      title: 'Exit Reason',
      dataIndex: 'exitReason',
      sorter: (a, b) => a.exitReason.localeCompare(b.exitReason),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('exitReason') 
    },
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   responsive: ['sm'],
    //   // responsive: ['lg']...getColumnSearchProps('remarks')
    // },
    {
      title: 'Approved By',
      dataIndex: 'approvedBy',
      sorter: (a, b) => String(a.approvedByName)?.localeCompare(b.approvedByName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('approvedByName') ,
      render: (text, data, index) => {
        return <span>{data.approvedByName}</span>
      }
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'Active',
    //       value: true,
    //     },
    //     {
    //       text: 'InActive',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
        
    //     return record.isActive === value;
    //   },
      
    // },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span> 
          <Tooltip placement="top" title='Edit'>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Exit Action');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
           </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteExitAction(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate exit-action ?'
                :  'Are you sure to Activate exit-action ?'
            }
          >
            
          </Popconfirm>
       </span>
      )
    }
  ];

  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  const openFormWithData = (viewData: ExitActionDto) => {
    setDrawerVisible(true);
    setSelectedExitActionData(viewData); 
  }

  return (
    <>
   <Card title={<span style={{ color: 'white' }}>Exit Action</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/exit-action-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        <Table
          rowKey={record => record.ExitActionsId}
          columns={columnsSkelton}
          dataSource={exitActionData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <ExitActionForm key={Date.now()}
              exitActionData={selectedExitActionsData}
              updateExitAction={updateExitAction}
              isUpdate={true}
              closeForm={closeDrawer}/>
          </Card>
        </Drawer>
      </Card>
    </>
  );
}


