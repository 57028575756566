import { Card, Col, Row } from "antd";
import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface ITotalData {
    vendor: any;
}

const TotalData = (props: ITotalData) => {
    const { vendor } = props;

    const downTime = vendor?.currentDowntime?.map((dt) => dt) || [];

    const unassignedAssets = vendor?.unassignedAssets;

    const config = {
        colors: ["#6B7A8F", "#F7882F"],
        chart: {
            type: "column",
            backgroundColor: "white",
        },
        title: {
            text: "Un Assigned Assets 30 60 90",
            style: {
                color: "black",
                fontSize: "20px",
                lineHeight: "1.4",
                marginBottom: "0",
                overflow: "hidden",
                paddingTop: "calc(2px*var(--scale-factor, 1))",
                position: "relative",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                zIndex: "5",
                fontFamily: "Arial, sans-serif"
            },
        },
        xAxis: {
            categories: ["30 Days", "60 Days", "90 Days"],
            labels: {
                style: {
                    color: "black",
                },
            },
        },
        yAxis: [{
            title: {
                text: 'Asset Count',
                style: {
                    color: "black",
                },
            },
            labels: {
                style: {
                    color: "black",
                },
            },
        }, {
            title: {
                text: 'Asset Value',
                style: {
                    color: "black",
                },
            },
            opposite: true,
            labels: {
                style: {
                    color: "black",
                },
            },
        }],
        tooltip: {
            shared: true,
            formatter: function () {
                let tooltip = `<b>${this.x}</b><br />`;
                this.points.forEach((point: any) => {
                    tooltip += `${point.series.name}: ${point.y}<br/>`;
                });
                return tooltip;
            }
        },
        series: [
            {
                name: "Unassigned Assets",
                data: [
                    parseInt(unassignedAssets?.UnassignedFor30) || 0,
                    parseInt(unassignedAssets?.UnassignedFor60) || 0,
                    parseInt(unassignedAssets?.UnassignedFor90) || 0
                ],
                yAxis: 0,
            },
            {
                name: "Asset Cost",
                data: [
                    unassignedAssets?.assetsCostTo30 || 0,
                    unassignedAssets?.assetsCostTo60 || 0,
                    unassignedAssets?.assetsCostTo90 || 0
                ],
                yAxis: 1,
            }
        ]
    };

    return (
        <Row gutter={16} style={{ borderStyle: 'solid', borderWidth: '0px', backgroundColor: '#0d092b', borderRadius: '8px' }}>
            <Col span={16} style={{ paddingTop: '15px' }}>
                <HighchartsReact highcharts={Highcharts} options={config} />
            </Col>
            <Col span={4}>
                <Row>
                    <Col span={5} offset={1}>
                        <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '220px', backgroundColor: '#0b96b5', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px' }}>
                            <a style={{ color: "white", fontSize: '16px', fontWeight: 600 }}>Total Vendors</a>
                        </div>
                        <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '220px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '12px' }}>
                            <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }}>{vendor?.vendorsCount}</a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={5} offset={1}>
                        <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '220px', backgroundColor: '#0b96b5', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px' }}>
                            <a style={{ color: "white", fontSize: '16px', fontWeight: 600 }} href="#">Services</a>
                        </div>
                        <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '220px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '12px' }}>
                            <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }} href="#">{vendor?.servicesCount}</a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={5} offset={1}>
                        <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '220px', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px' }}>
                            <a style={{ color: "white", fontSize: '16px', fontWeight: 600 }}>Current DownTime</a>
                        </div>
                        <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '220px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '12px' }}>
                            {/* <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }}>{downTime || 0}<span className="divider"></span></a> */}
                            <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }}>
                                {downTime.length > 0 ? downTime.join(", ") : 0}
                                <span className="divider"></span>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default TotalData;
