import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from "@gtpl/shared-models/common-models";
import { HrmsEmpDetService } from "@gtpl/shared-services/masters";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Col, Row } from "antd"
import CardComponent from "libs/pages/dashboard/dashboard-components/factory-head-dashboard-grid/src/lib/dashboard-components/card-component"
import React, { useEffect, useState } from "react";


export const EmployeeDashboard = () => {
  const [employeeUnitWise, setEmployeeUnitWise] = useState<any[]>([]);
  const service = new HrmsEmpDetService()
//empoloyee count
const cardBodyForSoLifeCycleStatusCount: CardBodyDetails[] = [];
let LifeCycleStatusCount = 0;
let kakinadaCount = 0;
let unitOneCount = 0;
let unitTwoCount = 0;

useEffect(() => {
  getEmployeeUnitData();
}, [])

const getEmployeeUnitData = () => {

  service.getEmployeeUnitData().then((res) => {
      if (res.status) {
          setEmployeeUnitWise(res.data);
      } else {
          setEmployeeUnitWise([]);
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeUnitWise([]);
  })
}

for (const productInfo of employeeUnitWise) {
    cardBodyForSoLifeCycleStatusCount.push(new CardBodyDetails((productInfo.employee_type), Number(productInfo.kakinada).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit1).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit2).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), String(Number(productInfo.kakinada) + Number(productInfo.unit1) + Number(productInfo.unit2))));
    kakinadaCount = kakinadaCount + Number(productInfo.kakinada)
    unitOneCount = unitOneCount + Number(productInfo.unit1);
    unitTwoCount = unitTwoCount + Number(productInfo.unit2);

}
LifeCycleStatusCount = Number(kakinadaCount + unitOneCount + unitTwoCount);

const soLifeCycleStatusCountBodyStatus = new CardHeaderBody('Employee Count');
const soLifeCycleStatusCountBodyCount = new CardHeaderBody('Total Count', Number(LifeCycleStatusCount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
const soLifeCycleStatusCountBodyCardHeader = new CardHeader('Employee Total Count', [soLifeCycleStatusCountBodyStatus, soLifeCycleStatusCountBodyCount],'Sales');

const soLifeCycleStatusCountSoCard = new CardDetail(soLifeCycleStatusCountBodyCardHeader, cardBodyForSoLifeCycleStatusCount,
   ['Employee Type', 'Kakinada', 'Unit1', 'Unit2', 'Total']);



  return (
    <div style={{ backgroundColor: 'white', fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro' }}>
      <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
        <Col className="cardComp" xs={24} sm={24} md={8} xl={12} style={{ height: '33%' }}>
          <CardComponent cardData={soLifeCycleStatusCountSoCard} />
        </Col>
      </Row>
    </div>
  )

}

export default EmployeeDashboard;