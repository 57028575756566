import { UpdateServiceRemarksDto } from '@gtpl/shared-models/asset-management'
import { AssetService } from '@gtpl/shared-services/asset-management'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages'
import { Button, Card, Col, Form, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

type Props = {
   values: {assetServiceId?:number,remarks?:string}
   closeModal : () => void
}

export default function AddRemarks({values,closeModal}: Props) {
    const assetService = new AssetService()
    const [remarks,setRemarks] = React.useState<string>(values.remarks)
    const addRemarks = () => {
        const req = new UpdateServiceRemarksDto()
        req.assetServiceId = values.assetServiceId
        req.remarks = remarks
        assetService.updateServiceRemarks(req).then((res) => {
            if(res.status){
                AlertMessages.getSuccessMessage('Remarks updated')
            }
            closeModal()
        })
    }
    console.log(remarks,values)
    return (
        <Card>
            <Form >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item name='remarks' label='Note' >
                            <TextArea defaultValue={remarks} value={remarks} onChange={(e) => setRemarks(e.target.value)}  rows={5} maxLength={1000} showCount />
                        </Form.Item>
                    </Col>

                </Row>
                <Row justify='center'>
                    <Button size='small' type='primary' onClick={addRemarks}>Update</Button>
                </Row>
            </Form>
        </Card>
    )
}
