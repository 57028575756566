import { AssetsCode } from "@gtpl/shared-models/common-models";

export class AssetLicenseDto {
    licenseId?: number;
    licenseName: number;
    referenceNumber: number;
    company: string;
    licenseNumber: number;
    responsibility:string;
    expiryDate:any;
    isActive: boolean;
    createdUser : string;
    updatedUser: string;
    versionFlag?: number;
    renewalPersonName?:string;
    renewalStartDate?:any;
    renewalExpiryDate?:any;
    plantId?:number;
   


    /**
     * 
     * @param licenseId
     * @param licenseName
     * @param referenceNumber
     * @param company
     * @param licenseNumber
     * @param responsibility
     * @param expiryDate
     * @param isActive
     * @param createdUser
     * @param updatedUser
     * @param versionFlag
     * @param renewalPersonName
     * @param renewalStartDate
     * @param renewalExpiryDate
     * @param plantId
     */

    constructor(licenseId: number, licenseName: number, referenceNumber: number, company: string, licenseNumber: number, responsibility: string,expiryDate:any, isActive:boolean,createdUser : string,updatedUser:string, versionFlag?: number,renewalPersonName?:string,renewalStartDate?:Date, renewalExpiryDate?:Date,plantId?:number) {
        this.licenseId = licenseId;
        this.licenseName = licenseName;
        this.referenceNumber = referenceNumber;
        this.company= company;
        this.licenseNumber = licenseNumber;
        this.responsibility = responsibility;
        this.expiryDate = expiryDate;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.renewalPersonName = renewalPersonName;
        this.renewalStartDate = renewalStartDate;
        this.renewalExpiryDate = renewalExpiryDate
        this.plantId=plantId
    }
}