import React, { useEffect, useReducer, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { ItemsDto, ItemCategoriesDto, ItemSubCategoryDropDownDto, ItemSubCategoriesDto, SizesDto, VariantDto, SkuDto, EndCustomerDto, BrandsResponseModel, BrandsRequest, Brands, ItemCategoryReq, ItemCategoryRequest, ItemCategoryDropDownDto, UomsDto, MasterBrandsDropDownDto, SubCatReqForCategoryId, CategoryItemReq, SizesDropDownDto, MasterBrandDto, FoodTypesDto, Type1Dto, TypesOfValueAddition2Dto, ValueAdditionThreeDto, TypeOfValueAddition4Dto, ValueAdditionFiveDto, ProductDto, PackingMethodDto, PackingMethodCapacityRequest, TypesDropDownDto, ItemSubCategoryRequest } from '@gtpl/shared-models/masters';
import { ItemsService, ItemCategoryService, ItemSubCategoryService, SizesService, VariantService, SkuService, EndCustomersService, UomsService, MasterBrandService, FoodTypeService, ProductService, Type1Service, TypesOfValueAddition2Service, Type3Service, TypeofValueAddition4Service, TypeofValueAddition5Service, PackingMethodService, TypesService } from '@gtpl/shared-services/masters';
import './items-form.css';
import TextArea from 'antd/lib/input/TextArea';
import { PackingCategoryEnum } from '@gtpl/shared-models/common-models';
import {AssetTypeEnum} from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';


/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};


export interface ItemsFormProps {
  itemData: ItemsDto;
  updateItem: (item: ItemsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
const defaultVal = {
  packingCatSelected: false,
  brandSelected: false,
  sizeIdSelected: false,
  gradeRequired: false,
  bapRequired: false,
  foodTypeRequired: false,
  productRequired: false,
  v1Required: false,
  v2Required: false,
  v3Required: false,
  v4Required: false,
  v5Required: false
}





export function ItemsForm(props: ItemsFormProps) {
  const [form] = Form.useForm();



  useEffect(() => {
    getAllItemCategories();
    // getAllFoods();
    // getAllTypes1();
    // getAllTypes2();
    // getAllTypes3();
    // getAllTypes4();
    // getAllTypes5();
    // getAllProducts();
    // getSkuCodes();
    // getBrandData();
    // getUomsdata();
    // getPackingMethods();


  }, [])
  useEffect(() => {
    if (props.itemData) {
      if(props.itemData.isAsset !== 'NO') {
        setAssetNoData(true)
      }
      console.log(props.itemData)
    }
  }, [props.itemData])



  // based on Brand and Category requires fields start
  const [foodData, setFoodData] = useState<FoodTypesDto[]>([])
  const [type1Data, setType1Data] = useState<Type1Dto[]>([])
  const [type2Data, setType2Data] = useState<TypesOfValueAddition2Dto[]>([])
  const [type3Data, setType3Data] = useState<ValueAdditionThreeDto[]>([])
  const [type4Data, setType4Data] = useState<TypeOfValueAddition4Dto[]>([])
  const [type5Data, setType5Data] = useState<ValueAdditionFiveDto[]>([])
  const [variantRequired, setVariantRequired] = useState<boolean>(false);
  const [isItemNameHidden, setIsItemNameHidden] = useState<boolean>(true);
  const [assetHidden, setAssetHidden] = useState<boolean>(false);
  isItemNameHidden
  const [itemNameRequired, setItemNameRequired] = useState<boolean>(false);
  const [isSizeRequired, setIsSizeRequired] = useState<boolean>(false);
  const [isPackStyleRequired, setIsPackStyleRequired] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<ProductDto[]>([]);
    const [assetData,setAssetData] = useState<boolean>(true);
  const [assetNoData,setAssetNoData] = useState<boolean>(undefined);
  const [typesData, setTypesData] = useState<any[]>([])
  // based on Brand and Category requires fields end
  const [disable, setDisable] = useState<boolean>(false)
  const productService = new ProductService();
  const foodTypeService = new FoodTypeService();
  const type1Service = new Type1Service();
  const type2Service = new TypesOfValueAddition2Service();
  const type3Service = new Type3Service();
  const type4Service = new TypeofValueAddition4Service();
  const type5Service = new TypeofValueAddition5Service();
  const typesService = new TypesService();



  // get food Type Data
  const getAllFoods = () => {
    foodTypeService.getAllActiveFoodTypes().then(res => {
      if (res.status) {
        setFoodData(res.data);
      } else {
        if (res.intlCode) {
          setFoodData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setFoodData([]);
    })
  }
  const play = (value) => {
    if (value == 'NO') {
      setAssetData(true);
      setAssetNoData(false)
      setColumnsvisible(false)
    } 
      else  {
        setAssetData(false);
        setAssetNoData(true);
        setColumnsvisible(true)
      }
  };

  const getTypesDropdown = () => {
    const req = new ItemSubCategoryRequest(form.getFieldValue('itemSubCategoryId'))
    typesService.getTypesForItemSubcategoryId(req).then((res) => {
      if (res.status) {
        setTypesData(res.data)
      } else {
        if (res.intlCode) {
          setTypesData([]);
          // AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setTypesData([]);
    })

  }

  const getAllProducts = () => {
    productService.getAllActiveProducts().then(res => {
      if (res.status) {
        console.log(res.data)
        setProductsData(res.data);
      } else {
        if (res.intlCode) {
          setProductsData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setFoodData([]);
    })
  }


  const getAllTypes1 = () => {
    if (!props.isUpdate) {
      type1Service.getAllActiveValAddOneTypes().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    } else {
      type1Service.getAllTypes1().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    }
  }
  const getAllTypes2 = () => {
    if (!props.isUpdate) {
      type2Service.getAllActiveValAddTwoTypes().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    } else {
      type2Service.getAllTypesOfValueAddition2s().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    }
  }
  const getAllTypes3 = () => {
    if (!props.isUpdate) {
      type3Service.getAllAvtiveValAddThreeTypes().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    } else {
      type3Service.getAllTypesOfValueAddition3s().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    }
  }
  const getAllTypes4 = () => {
    if (!props.isUpdate) {
      type4Service.getAllActiveTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    } else {
      type4Service.getAllTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    }
  }
  const getAllTypes5 = () => {
    if (!props.isUpdate) {
      type5Service.getAllActiveTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    } else {
      type5Service.getAllTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    }
  }


  const [selectedItemCategory, setSelectedItemCategory] = useState<number>(null);
  const [itemCategoryData, setItemCategoryData] = useState<ItemCategoriesDto[]>([]);
  const [selectedSize, setSelectedSize] = useState<number>(null);
  const [sizesData, setSizesData] = useState<SizesDropDownDto[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<number>(null);
  const [variantData, setVariantData] = useState<VariantDto[]>([]);
  const [display, setDisplay] = useState<string>('none');
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [brandId, setBrandId] = useState<BrandsRequest>({ brandId: 1 });
  const [brandData, setBrandData] = useState<MasterBrandDto>();

  useEffect(() => {
    if (brandData) {
      categoryChange();
    }
  }, [brandData])

  const reducerAction = (state, action) => {
    console.log(brandData)
    switch (action.type) {
      case PackingCategoryEnum.POUCH:
        const dynamicFields1 = {
          packingCatSelected: true,
          brandSelected: true,
          sizeIdSelected: false,
          gradeRequired: brandData?.isPouchGradeConsider,
          bapRequired: brandData?.isPouchBapNoConsider,
          foodTypeRequired: brandData?.isPouchFoodTypeConsider,
          productRequired: brandData?.isPouchProductConsider,
          v1Required: brandData?.isPouchValAddOneConsider,
          v2Required: brandData?.isPouchValAddTwoConsider,
          v3Required: brandData?.isPouchValAddThreeConsider,
          v4Required: brandData?.isPouchValAddFourConsider,
          v5Required: brandData?.isPouchValAddFiveConsider
        }
        return dynamicFields1;
      case PackingCategoryEnum.CARTON:
        const dynamicFields2 = {
          packingCatSelected: true,
          brandSelected: true,
          sizeIdSelected: true,
          gradeRequired: brandData?.isCartonGradeConsider,
          bapRequired: brandData?.isCartonBapNoConsider,
          foodTypeRequired: brandData?.isCartonFoodTypeConsider,
          productRequired: brandData?.isCartonProductConsider,
          v1Required: brandData?.isCartonValAddOneConsider,
          v2Required: brandData?.isCartonValAddTwoConsider,
          v3Required: brandData?.isCartonValAddThreeConsider,
          v4Required: brandData?.isCartonValAddFourConsider,
          v5Required: brandData?.isCartonValAddFiveConsider
        }
        return dynamicFields2;

      case 'none':
        const dynamicFields3 = {
          packingCatSelected: false,
          sizeIdSelected: true,
          brandSelected: false,
          gradeRequired: false,
          bapRequired: false,
          foodTypeRequired: false,
          productRequired: false,
          v1Required: false,
          v2Required: false,
          v3Required: false,
          v4Required: false,
          v5Required: false
        }
        return dynamicFields3;

      case 'brand':
        const dynamicFields4 = {
          packingCatSelected: false,
          sizeIdSelected: false,
          brandSelected: true,
          gradeRequired: false,
          bapRequired: false,
          foodTypeRequired: false,
          productRequired: false,
          v1Required: false,
          v2Required: false,
          v3Required: false,
          v4Required: false,
          v5Required: false
        }
        return dynamicFields4;
      case 'pack':
        const dynamicFields5 = {
          packingCatSelected: true,
          sizeIdSelected: false,
          brandSelected: true,
          gradeRequired: false,
          bapRequired: false,
          foodTypeRequired: false,
          productRequired: false,
          v1Required: false,
          v2Required: false,
          v3Required: false,
          v4Required: false,
          v5Required: false
        }
        return dynamicFields5;
      case PackingCategoryEnum.SKEWER:
        const dynamicFields6 = {
          packingCatSelected: false,
          sizeIdSelected: true,
          brandSelected: false,
          gradeRequired: false,
          bapRequired: false,
          foodTypeRequired: false,
          productRequired: false,
          v1Required: false,
          v2Required: false,
          v3Required: false,
          v4Required: false,
          v5Required: false
        }
        return dynamicFields6;

    }
    return defaultVal;
  }

  const itemSubcategoryParameters = {
    bapNo: false,
    type: false,
    size: false,
    grade: false,
    packStyle: false,
    approvalNo: false,
    netWeight: false
  }

  const reducerActionTwo = (state, action) => {
    console.log(action, 'reducer action')

    switch (action.type) {
      case 'BAP LABEL': {
        const bapLabelFields = {
          bapNo: true,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return bapLabelFields;
      }
      case 'SAUCE CUPS': {
        const sauceCupsFields = {
          bapNo: false,
          type: false,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return sauceCupsFields;
      }
      case 'SOAKER PADS': {
        const soakerPadsFields = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return soakerPadsFields
      }
      case 'DUMMY LABEL': {
        const dummyLabelFields = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return dummyLabelFields
      }
      case 'EAS LABEL': {
        const easLabelFields = {
          bapNo: false,
          type: false,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return easLabelFields
      }
      case 'GRADE LABEL': {
        const gradeLabelFields = {
          bapNo: false,
          type: false,
          size: true,
          grade: true,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return gradeLabelFields
      }
      case 'Header Cards': {
        const headerCard = {
          bapNo: false,
          type: false,
          size: true,
          grade: false,
          packStyle: true,
          approvalNo: false,
          netWeight: false


        }
        return headerCard
      }
      case 'OTR POUCH': {
        const otrPouch = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false


        }
        return otrPouch;
      }
      case 'Plain Label': {
        const plainLabel = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: true,
          netWeight: false

        }
        return plainLabel;
      }
      case 'Q MARK LABEL': {
        const qMarkLabel = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: true,
          netWeight: false

        }
        return qMarkLabel;
      }
      case 'SANS LABEL': {
        const sansLabel = {
          bapNo: false,
          type: false,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false

        }
        return sansLabel;
      }
      case 'SHRINK WRAPPING POUCH': {
        const shrinkWrappouch = {
          bapNo: false,
          type: false,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false

        }
        return shrinkWrappouch;
      }
      case 'Plain Carton': {
        const plainCarton = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: false
        }
        return plainCarton;
      }

      case 'PLAIN POUCH': {
        const plainPouch = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: true
        }
        return plainPouch;
      }
      case 'Polythene cover': {
        const plainPouch = {
          bapNo: false,
          type: true,
          size: true,
          grade: false,
          packStyle: false,
          approvalNo: false,
          netWeight: true
        }
        return plainPouch;
      }


    }
    return itemSubcategoryParameters

  }




  const [dynamicRequired, dispatchDynamicFields] = useReducer(reducerAction, defaultVal);
  const [requiredField, dispatchRequiredFields] = useReducer(reducerActionTwo, itemSubcategoryParameters);


  const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  useEffect(() => {
    if (dynamicRequired) {
      console.log(dynamicRequired)
    }
  }, [dynamicRequired])


  const [brandArrayData, setBrandArrayData] = useState<MasterBrandsDropDownDto[]>([]);
  const [itemCategoryReq, setItemCategoryReq] = useState<ItemCategoryRequest>()
  const [itemCategoryForIdData, setItemCategoryForIdData] = useState<string>();
  const [uomDetails, setUomDetails] = useState<UomsDto[]>([]);
  const [packMaterialRequiredFields, setPackMaterialRequiredFields] = useState<boolean>(false);

  const brandsService = new MasterBrandService()
  const itemCategoryService = new ItemCategoryService();
  const service = new ItemsService;
  const skuService = new SkuService();
  const sizesService = new SizesService;
  const variantService = new VariantService;
  const endCustomersService = new EndCustomersService();
  const uomsService = new UomsService();

  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const subCategoryService = new ItemSubCategoryService();
  const [selectedsubCategory, setSelectedSubCategory] = useState<number>(null);
  const [packingMethods, setPackingMethods] = useState<PackingMethodDto[]>([]);
  const [columnsvisible,setColumnsvisible] = useState<boolean>(false);
  const packingMethodsService = new PackingMethodService()

  useEffect(() => {
    console.log(isPackStyleRequired)
  }, [isPackStyleRequired])


const assetonchange = (value) =>{
  if(value == 'Yes'){
    setColumnsvisible(true)
  }else{
    setColumnsvisible(false)
  }

}

  useEffect(() => {
    if (props.isUpdate) {
      checkItemCategory();
      getBrandInfoById();
      getAllItemSubCategories();
      handleSubCategory();
      // getPackingMethods();

    }
  }, [itemCategoryData.length])
  useEffect(() => {
    if (props.isUpdate) {
      console.log(packingMethods);
      if (packingMethods.length > 0) {
        let packMethodId = packingMethods.filter(item => (Number(item.noOfPouches) == Number(props.itemData.pouches) && Number(item.pouchesPerRow) == Number(props.itemData.pouchWeight) && Number(item.uomId) == Number(props.itemData.uomId)))[0]?.packingMethodId
        if (props.itemData.itemSubCategoryName == PackingCategoryEnum.POUCH) {
          packMethodId = packingMethods.filter(item => (Number(item.pouchesPerRow) == Number(props.itemData.pouchWeight) && Number(item.uomId) == Number(props.itemData.uomId)))[0]?.packingMethodId

        }
        console.log(packMethodId);
        form.setFieldsValue({ packStyleId: packMethodId })
      }
    }
  }, [packingMethods])

  let history = useHistory();



  const checkItemCategory = () => {
    const category = form.getFieldValue('itemCategoryId');
    {
      itemCategoryData.map((itemCategory) => {
        if (itemCategory.itemCategoryId == category) {
          if (itemCategory.itemCategory == 'Packing Material') {
            setDisplay('revert');
            // setPackMaterialRequiredFields(true);
          }
        }
      })
    }
  }
  const getUomsdata = () => {
    uomsService.getAllUoms().then((res) => {
      if (res.status) {
        setUomDetails(res.data);
      } else {
        setUomDetails([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setUomDetails([]);
    });
  }

  //To get categories
  const getAllItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Item categories retrived Successfully');
        //form.setFieldsValue({...res.data});
        setItemCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })



    // //To get sizes
    // sizesService.getAll().then(res => {
    //   if (res.status) {
    //     setSizesData(res.data);
    //   } else {
    //     if (res.intlCode) {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     } else {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     }
    //   }
    // }).catch(err => {
    //   AlertMessages.getErrorMessage(err.message);
    // })

    //To get variants(varieties)
    variantService.getAllVariants().then(res => {
      if (res.status) {
        setVariantData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }


  const getPackingMethods = () => {
    packingMethodsService.getAllActivePackingMethods().then((res) => {
      if (res.status) {
        setPackingMethods(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllItemSubCategories = () => {
    const itemCategoryId = form.getFieldValue('itemCategoryId');
    console.log(itemCategoryId, "&&&&&&")
    const category = itemCategoryData?.find(item => item.itemCategoryId == itemCategoryId)?.itemCategory
    console.log(category, "category")
    if (category != 'Packing Material') {
      // form.setFieldsValue({itemName:null})
      // form.setFieldsValue({productCode:null})
      setIsItemNameHidden(false);
    } else {
      // form.setFieldsValue({itemName:null})
      // form.setFieldsValue({productCode:null})
      setIsItemNameHidden(false);
    }
    // if (category != 'General Items') {
    //   // form.setFieldsValue({itemName:null})
    //   // form.setFieldsValue({productCode:null})
    //   setAssetHidden(false);
    // } else {
      // form.setFieldsValue({itemName:null})
      // form.setFieldsValue({productCode:null})
      setAssetHidden(true);
    // }
    // if(category == 'Packing Material'){
    //   dispatchDynamicFields({ type: 'none' });
    // }
    const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getItemCategoryData = (itemCategoryReq) => {
    //   //itemCategoryService.getItemCategoryForId(itemCategoryReq).then(res + {
    //   itemCategoryService.getItemCategoryById(itemCategoryReq).then(res => {
    //           // if (res.status) {
    //           //   setItemCategoryForIdData(res.data);
    //           // } else {
    //           //   if (res.intlCode) {
    //           //        AlertMessages.getErrorMessage(res.internalMessage);
    //           //   } else {
    //           //    AlertMessages.getErrorMessage(res.internalMessage);
    //           //   }
    //           // }
    //                     // if(res.itemCategoryId){
    //                     //   setItemCategoryForIdData(res.itemCategory);
    //                     //   console.log(itemCategoryForIdData);
    //                     // }
    //         }).catch(err => {
    //            AlertMessages.getErrorMessage(err.message);
    //         })
  }


  //Get sku codes
  const getSkuCodes = () => {
    if (props.isUpdate) {
      skuService.getAllSku().then((res) => {
        if (res.status) {
          setSkucode(res.data);
        }
        else {
          setSkucode([]);
        }
      })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSkucode([]);
        });
    } else {
      skuService.getActiveSkuCodes().then((res) => {
        if (res.status) {
          setSkucode(res.data);
        }
        else {
          setSkucode([]);
        }
      })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSkucode([]);
        });
    }

  }






  //get brand details
  // const endCustomerDetails = () => {
  //   endCustomersService.getAllEndCustomers().then(res => {
  //     console.log(res);
  //           if (res.status) {
  //             // res.data.map(value=>{
  //             //   // setBrandId({brandId : value.endCustomerId}) 
  //             //   const data = value.endCustomerId;
  //             //   //setBrandId(data);
  //             //   getBrandData(data);
  //             // })
  //             for(let i=0;i<res.data.length;i++){
  //               getBrandData({brandId:res.data[i].endCustomerId});
  //             }
  //             //setEndCustomersData(res.data);
  //           } else {
  //             if (res.intlCode) {

  //               setEndCustomersData([]);
  //                  AlertMessages.getErrorMessage(res.internalMessage);
  //             } else {
  //              AlertMessages.getErrorMessage(res.internalMessage);
  //             }
  //           }
  //         }).catch(err => {
  //           setEndCustomersData([]);
  //            AlertMessages.getErrorMessage(err.message);
  //         })


  // }

  const getBrandData = () => {
    brandsService.getAllMasterBrandsDropDown().then(res => {
      console.log(res);
      if (res.status) {
        setBrandArrayData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const createItem = (itemData) => {
    setDisable(true)
    console.log(itemData, 'itemData')
    // itemData.itemCategoryName=itemData.itemCategoryId;
    const packStyleId = form.getFieldValue('packStyleId');
    if (packStyleId > 0) {
      console.log(packStyleId, 'packStyleId')
      const product = packingMethods.find(i => i.packingMethodId == packStyleId)
      console.log(product, 'product')
      itemData.uomId = product.uomId;
      itemData.pouches = product.noOfPouches;
      itemData.pouchWeight = product.pouchesPerRow
    } else {
      itemData.uomId = null
      itemData.pouches = null
      itemData.pouchWeight = null
    }
    service.createItem(itemData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Item Created Successfully');
        history.push("/items-view");
        onReset();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  //If change occurs in Item category
  const handleItemCategory = (value, item) => {
    setItemCategoryReq(value);
    getItemCategoryData(value);

    setSelectedItemCategory(value);
    if (item.children == "Packing Material") {
      setDisplay('revert');
    } else {
      setDisplay('none');
    }
    form.setFieldsValue({ itemSubCategoryId: '' });
  }
  const handleSize = value => setSelectedSize(value);
  const handleVariant = value => setSelectedVariant(value);

  const handleSubCategory = (value?, item?) => {

    if (item) {
      form.setFieldsValue({ productId: null, foodTypeId: null, valueAdditionOneId: null, valueAdditionTwoId: null, valueAdditionThreeId: null, valueAdditionFourId: null, valueAdditionFiveId: null, bapNo: null, minGrade: null, maxGrade: null })
    }
    setSelectedSubCategory(item?.children);
    console.log(item);
    dispatchRequiredFields({ type: (subCategoryData.find(i => i.itemSubCategoryId == form.getFieldValue('itemSubCategoryId'))?.itemSubCategory) })

    form.setFieldsValue({ packingCategory: item ? item.children : props.itemData.itemSubCategoryName })


    // get sizes against item Category and item sub category
    getTypesDropdown();
    const itemCategoryId = form.getFieldValue('itemCategoryId');
    const itemSubCategoryId = form.getFieldValue('itemSubCategoryId');
    if (itemCategoryId && itemSubCategoryId) {
      (!props.isUpdate) ? form.setFieldsValue({ sizeId: null }) : "";
      const req = new CategoryItemReq();
      req.categoryId = itemCategoryId;
      req.itemSubCategoryId = itemSubCategoryId;
      sizesService.getAllActiveSizesDropDownForCategory(req).then((res) => {
        if (res.status) {
          setSizesData(res.data);
        } else {
          setSizesData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSizesData([]);
      });
    }
    categoryChange();
    // console.log(item.children);
    if ((item ? item.children : props.itemData.itemSubCategoryName) === PackingCategoryEnum.SKEWER || (item ? item.children : props.itemData.itemSubCategoryName) === PackingCategoryEnum.RING || (item ? item.children : props.itemData.itemSubCategoryName) === PackingCategoryEnum.PLATTER
    ) {
      console.log('latter')
      setIsPackStyleRequired(false);
      setItemNameRequired(false)
      setIsSizeRequired(true)
    } else if ((item ? (item.children) : props.itemData.itemSubCategoryName) === PackingCategoryEnum.POUCH
      || (item ? item.children : props.itemData.itemSubCategoryName) === PackingCategoryEnum.CARTON) {
      console.log('cartonssss')

      setIsPackStyleRequired(true);
      setItemNameRequired(false)
      setIsSizeRequired(false)
    } else {
      console.log('else')

      setIsPackStyleRequired(false);
      setItemNameRequired(true)
      setIsSizeRequired(false)
    }


  }

  const onReset = () => {
    console.log('');
    form.resetFields();

  }
  const onFocus = () => {
    //console.log('focus');
  }

  const onSearch = (val) => {
    //console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }

  const saveData = (values: ItemsDto) => {
    setDisable(false)
    // const selpackingCategoryId = form.getFieldValue('itemSubCategoryId')
    // const selpackingCategory = subCategoryData?.find(item => item.itemSubCategoryId == selpackingCategoryId).itemSubCategory
    // values.packingCategory = selpackingCategory
    console.log(values, 'values')
    itemCategoryData.map((category) => {
      if (category.itemCategoryId === values.itemCategoryId) {
        values.itemCategoryName = category.itemCategory;
        if (props.isUpdate) {
          console.log(values)
          if (form.getFieldValue('packStyleId') > 0) {
            const packStyleId = form.getFieldValue('packStyleId')
            console.log(packStyleId, 'packStyleId')
            const product = packingMethods.find(i => i.packingMethodId == packStyleId)
            console.log(product, 'product')
            values.uomId = product.uomId;
            values.pouches = product.noOfPouches;
            values.pouchWeight = product.pouchesPerRow
          } else {
            values.uomId = null
            values.pouches = null
            values.pouchWeight = null
          }
          props.updateItem(values);
        } else {
          setDisable(false)
          console.log(values)
          createItem(values);
        }
      }
    })
  };
  //This Code is used to render screen only when Item Category is selected to Packing Material
  const style = {
    display: display
  }

  const getBrandInfoById = () => {
    console.log(form.getFieldsValue())
    if (!props.isUpdate) {
      form.setFieldsValue({ productId: null, foodTypeId: null, valueAdditionOneId: null, valueAdditionTwoId: null, valueAdditionThreeId: null, valueAdditionFourId: null, valueAdditionFiveId: null, bapNo: null, minGrade: null, maxGrade: null })
    }
    const brandId = form.getFieldValue('brandId');
    if (brandId) {
      const req = new BrandsRequest(brandId);
      brandsService.getBrandInfoById(req).then((res) => {
        if (res.status) {
          setBrandData(res.data);
          dispatchDynamicFields({ type: 'brand' });
          // categoryChange();
        } else {
          setBrandData(undefined);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setBrandData(undefined);
      });
    }
  }

  const categoryChange = () => {
    let packingCategoryId = form.getFieldValue('itemSubCategoryId')
    let selBrandId = form.getFieldValue('brandId')
    console.log(props.itemData)
    console.log(selBrandId)
    // if(props.isUpdate){
    //   packingCategoryId = props.itemData.itemSubCategoryId
    //   selBrandId = props.itemData.brandId
    // }
    let packingCategory = subCategoryData?.find(item => item.itemSubCategoryId == packingCategoryId)?.itemSubCategory
    if (props.isUpdate) {
      packingCategory = props.itemData.itemSubCategoryName
    }
    console.log(packingCategory)
    console.log(packingCategoryId)
    console.log(form.getFieldValue('brandId'))
    if ((packingCategory === PackingCategoryEnum.POUCH || packingCategory === PackingCategoryEnum.CARTON)) {
      dispatchDynamicFields({ type: 'brand' });
    }
    if (packingCategory) {
      if ((packingCategory === PackingCategoryEnum.POUCH) && selBrandId) {
        dispatchDynamicFields({ type: PackingCategoryEnum.POUCH });
      } else if ((packingCategory === PackingCategoryEnum.CARTON) && selBrandId) {

        dispatchDynamicFields({ type: PackingCategoryEnum.CARTON });
      } else if (packingCategory === PackingCategoryEnum.SKEWER || packingCategory === PackingCategoryEnum.RING || packingCategory === PackingCategoryEnum.PLATTER) {
        dispatchDynamicFields({ type: PackingCategoryEnum.SKEWER });
      }
      else {
        dispatchDynamicFields({ type: 'none' });
      }
    }
  }


  return (
    <Card size="small" title={<span style={{ color: 'white' }}>Asset Form </span>} style={{ textAlign: 'center' }} extra={<Link to='/items-view' ><span style={{ color: 'white' }} >{(props.isUpdate === false) && <span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>} </span></Link>} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout={'vertical'} onFinish={saveData} initialValues={props.itemData} >
        <Row gutter={24}>
          <Form.Item
            name="itemId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemCategoryId"
              label="Asset Category"
              rules={[
                {
                  required: true,
                  message: 'Asset Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Item Category `,
                },
              ]}

            >
             
              <Select

                // style={{ width: 260 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onSelect={handleItemCategory}
                onChange={getAllItemSubCategories}
                // onChange={generalDisplay}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Item Category</Option> */}
                {itemCategoryData.map((itemCategory) => {
                  console.log(itemCategory, "************")
                  return <Option key={itemCategory.itemCategoryId} value={itemCategory.itemCategoryId}>{itemCategory.itemCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {assetHidden &&  <Col
                  xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                  <Form.Item
                    name="isAsset"
                    label="Is Asset"
                  >
                    {<Select
                      showSearch
                      defaultValue={'NO'}
                     onChange={play}
              
                    >
                      <Option key={1} value={'YES'}>Yes</Option>
                      <Option key={2} value={'NO'}>No</Option>
                    </Select>}
                  </Form.Item>
                </Col> }
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemSubCategoryId"
              label="Asset Sub Category"
              rules={[
                {
                  required: true,
                  message: 'Asset Sub Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Asset Sub Category `,
                },
              ]}

            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onSelect={handleSubCategory}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Sub Category</Option> */}
                {subCategoryData.map((subCategory) => {
                  return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {!isItemNameHidden ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="itemName"
                label="Asset Name"
                hidden={isItemNameHidden}
                rules={[
                  {
                    required: itemNameRequired,
                    message: 'Asset Name is required',
                  },

                ]}>
                <Input />
              </Form.Item>
            </Col>
            : <></>}
            {/* {columnsvisible ?
             <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
              <Form.Item
                name="assetSla"
                label="Asset Sla"
                rules={[
                  {
                    required: columnsvisible,
                    message: 'Asset Name is required',
                  },

                ]}>
                <Input />
              </Form.Item>
            </Col>:''} */}
            {columnsvisible ? 
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item initialValue={'HIGH'}
                name="assetPriority"
                label="Asset Priority"
                rules={[
                  {
                    required: columnsvisible,
                    message: 'Asset Name is required',
                  },
              
                ]}>
                   {<Select
                      showSearch
                      defaultValue={'HIGH'}
                    >
                      <Option key={1} value={'HIGH'}>HIGH</Option>
                      <Option key={2} value={'MEDIUM'}>MEDIUM</Option>
                      <Option key={2} value={'LOW'}>LOW</Option>

                    </Select>}
              </Form.Item>
            </Col>:''}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: requiredField.type ? 'revert' : 'none' }}>
            <Form.Item name="type"
              label="Type"
              rules={[{ required: (requiredField.type) ? true : false, message: 'MissingType.' }]}
            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select type"
                optionFilterProp="children"
                onChange={handleSize}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type</Option>

                {typesData.map((type) => {
                  return <Option key={type.typeId} value={type.typeId}>{type.type}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: requiredField.netWeight ? 'revert' : 'none' }}>
              <Form.Item name="netWeight"
                label="Net Weight (Kgs)"
                rules={[{ required: (requiredField.netWeight)?true:false, message: 'Missing Net Weight.' }]}
              >
              <Input /> 
              </Form.Item>
            </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: (requiredField.approvalNo) ? 'revert' : 'none' }}>
            <Form.Item name="approvalNo"
              label="Approval No"
              rules={[{ required: (requiredField.approvalNo) ? true : false, message: 'Missing Approval No.' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          {!isItemNameHidden ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="productCode"
                label="Product Code"


                rules={[
                  {
                    required: false,
                    message: 'Product Code is required',
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Enter valid Product Code`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            : <></>}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: ((dynamicRequired.sizeIdSelected) || requiredField.size) ? 'revert' : 'none' }}
          >
            <Form.Item
              name="sizeId"
              label="Size"
              rules={[{ required: (isSizeRequired) || requiredField.size ? true : false, message: 'Missing Size name' }]}

            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Size"
                optionFilterProp="children"
                onChange={handleSize}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Size</Option> */}

                {sizesData.map((sizeCategory) => {
                  return <Option key={sizeCategory.sizeId} value={sizeCategory.sizeId}>{sizeCategory.sizeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
       {assetData ?(<>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
              <Form.Item label="Pack Style" name='packStyleId' rules={[{ required: isPackStyleRequired || requiredField.netWeight, message: 'Missing pack style' }]}
              >
                <Select
                  placeholder="Select Pack Style"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {packingMethods?.map(uomDropDown => {
                    return <Option label={'packStyle'} key={uomDropDown.packingMethodId} value={uomDropDown.packingMethodId}>{(((props.isUpdate) ? (props.itemData.itemSubCategoryName) : (selectedsubCategory)) === PackingCategoryEnum.POUCH) ? uomDropDown.packingMethodName.split('X')[1] : uomDropDown.packingMethodName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="remarks"
              label="Remarks"
              >
              <TextArea rows={1} />
            </Form.Item>

          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>

            <Form.Item
              name="itemCode" style={{ display: "none" }} >
              <Input hidden />
            </Form.Item>
          </Col> */}

          {/* </Row> */}
          {/* <div style={style}>
          <Row gutter={24}> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
          >
            <Form.Item label="Brand"
              name={'brandId'}
              rules={[{ required: (isPackStyleRequired) ? true : false, message: 'Missing Brand name' }]}
              >
              <Select
                onSelect={getBrandInfoById}
                placeholder="Select Brand"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                <Option key={0} value={null}>Select Brand</Option>
                {brandArrayData.map(branddropData => {
                  return <Option label={'brandId'} key={branddropData.masterBrandId} value={branddropData.masterBrandId}>{branddropData.masterBrandName}</Option>
                })}
                |
              </Select>
            </Form.Item>
          </Col>
          </>):
          (<>
          </>)}
          {
            assetNoData? (<>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
          >
            <Form.Item label="Asset Type"
              name={'assetType'}
              rules={[{ required: true }]}
              initialValue={'MOVABLE'}
              >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'MOVABLE'}
                >
                  {Object.values(AssetTypeEnum).map((i) => {
                    return (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    );
                  })}               
                
              </Select>
            </Form.Item>
          </Col>
            </>) :(<></>)
          }

          <Form.Item
            name="packingCategory"
            
            >
            <Input hidden />
          </Form.Item>

          {/* </Row>
        </div>
        <div style={{ display: dynamicRequired.packingCatSelected ? 'revert' : 'none' }}>
          <Row gutter={24}> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.productRequired ? 'revert' : 'none' }}>

            <Form.Item label="Product"
              name={'productId'}
              rules={[{ required: (dynamicRequired.productRequired) ? true : false, message: 'Missing Product name' }]}
              >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                key={Date.now()}
                placeholder="Select Product"
                allowClear
              >
                {productsData?.map(dropData => {
                  return <Option key={dropData.productId} label='itemId' value={dropData.productId}>{dropData.productName}</Option>
                })}
              </Select>
            </Form.Item>


          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: ((dynamicRequired.gradeRequired) || (requiredField.grade)) ? 'revert' : 'none' }}>
            <Form.Item label='Grade' className='ItemsFormGrade' >
              <Input.Group compact>
                <Form.Item
                  name={'minGrade'}
                  rules={[{ required: (dynamicRequired.gradeRequired) || requiredField.grade ? true : false, message: 'Missing Min Grade' }]}
                  noStyle

                >
                  <InputNumber style={{ width: "50%" }} placeholder="Min" min={0} />
                </Form.Item>


                <Form.Item
                  name={"maxGrade"}
                  rules={[{ required: (dynamicRequired.gradeRequired) ? true : false, message: 'Missing Max Grade' }]}
                  noStyle

                >
                  <InputNumber style={{ width: "50%" }} placeholder="Max" min={0} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: (dynamicRequired.foodTypeRequired) ? 'revert' : 'none' }}>
            <Form.Item
              name="foodTypeId"
              label="Food Type:"
              rules={[
                {
                  required: (dynamicRequired.foodTypeRequired) ? true : false,
                  message: "Food Type is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Food Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Food Type</Option>
                {foodData.map((food) => {
                  return <Option value={food.foodTypeId}>{`${food.foodType} (${food.foodTypeCode})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.v1Required ? 'revert' : 'none' }}>
            <Form.Item
              name="valueAdditionOneId"
              label="Value Addition One:"
              rules={[
                {
                  required: (dynamicRequired.v1Required) ? true : false,
                  message: "Value Addition One is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type1"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type1</Option>
                {type1Data.map((type1) => {
                  return <Option value={type1.valueAdditionOneId}>{`${type1.name}(${type1.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.v2Required ? 'revert' : 'none' }}>
            <Form.Item
              name="valueAdditionTwoId"
              label="Value Addition Two:"
              rules={[
                {
                  required: (dynamicRequired.v2Required) ? true : false,
                  message: "Value Addition Two is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type2"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type2</Option>
                {type2Data.map((type2) => {
                  return <Option value={type2.valueAdditionTwoId}>{`${type2.name} (${type2.code})`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.v3Required ? 'revert' : 'none' }}>
            <Form.Item
              name="valueAdditionThreeId"
              label="Value Addition Three:"
              rules={[
                {
                  required: (dynamicRequired.v3Required) ? true : false,
                  message: "Value Addition Three is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type3"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type3</Option>
                {type3Data.map((type3) => {
                  return <Option value={type3.valueAdditionThreeId}>{`${type3.name} (${type3.code})`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.v4Required ? 'revert' : 'none' }}>
            <Form.Item
              name="valueAdditionFourId"
              label="Value Addition Four:"
              rules={[
                {
                  required: (dynamicRequired.v4Required) ? true : false,
                  message: "Value Addition Four is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type4"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type4</Option>
                {type4Data.map((type4) => {
                  return <Option value={type4.valueAdditionFourId}>{`${type4.name} (${type4.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: dynamicRequired.v5Required ? 'revert' : 'none' }}>
            <Form.Item
              name="valueAdditionFiveId"
              label="Value Addition Five:"
              rules={[
                {
                  required: (dynamicRequired.v5Required) ? true : false,
                  message: "Value Addition Five is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type5"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type5</Option>
                {type5Data.map((type5) => {
                  return <Option value={type5.valueAdditionFiveId}>{`${type5.name} (${type5.code})`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* 
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: (variantRequired) ? 'revert' : 'none' }}>
              <Form.Item label="Variant Code"
                name={'varientId'}
                rules={[{ required: variantRequired, message: 'Missing Varient Code' }]}
              >
                <Select
                  placeholder="Select Variant Code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {skucodes.map(skudropData => {
                    return <Option label={'varientId'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ display: ((dynamicRequired.bapRequired) || (requiredField.bapNo)) ? 'revert' : 'none' }}>
            <Form.Item name="bapNo"
              label="BAP No."
              rules={[{ required: (dynamicRequired.bapRequired || requiredField.bapNo) ? true : false, message: 'Missing BAP No.' }]}
            >
              <Input />
            </Form.Item>
          </Col>




          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{display:'none'}}>
              <Form.Item label="UOM"
                name={'uomId'}
                rules={[{ required: packMaterialReqpackuiredFields, message: 'Missing UOM' }]}
              >
                <Select
                  placeholder="Select UOM"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {uomDetails.map(uomDropDown => {
                    return <Option label={'uomId'} key={uomDropDown.uomId} value={uomDropDown.uomId}>{uomDropDown.uomName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> */}


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              name="minimumQuantity"
              label="Minimum Quantity"
            // rules={[
            //   {
            //     required: true,
            //     message:'Minimum Quantity is required',
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>

        <Row>

          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>

            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}





export default ItemsForm;
