import { Card } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
interface IServiceOwners {
  aboutToExpireDetails: any[];
}

const ServiceOwners = (props: IServiceOwners) => {
  const { aboutToExpireDetails } = props;

  // Extracting data for Highcharts
  const categories = aboutToExpireDetails.map(owner => owner.ownerName);
  const assetCountData = aboutToExpireDetails.map(owner => ({
    ownerName: owner.ownerName,
    totalNoOfSkippedServices: owner.servicesList.reduce((acc, service) => acc + parseInt(service.totalNoOfSkippedServices, 10), 0),
    noOfServicesDueIn30Days: owner.servicesList.reduce((acc, service) => acc + parseInt(service.noOfServicesDueIn30Days, 10), 0),
    noOfServicesDueIn60Days: owner.servicesList.reduce((acc, service) => acc + parseInt(service.noOfServicesDueIn60Days, 10), 0),
    noOfServicesDueIn90Days: owner.servicesList.reduce((acc, service) => acc + parseInt(service.noOfServicesDueIn90Days, 10), 0)
  }));

  const config = {
    colors: ["#3E8BB3", "#2A5677", "#FFA500", "#161B40"],
    chart: {
      type: "column",
      backgroundColor: "white"
    },
    title: {
      text: "Service Person VS Services Due Count",
      style: {
        color: "#000000",
        fontSize: "20px",
        lineHeight: "1.4",
        marginBottom: "0",
        overflow: "hidden",
        paddingTop: "calc(2px*var(--scale-factor, 1))",
        position: "relative",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        zIndex: "5",
        fontFamily: "Arial, sans-serif"
      }
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: "black"
        }
      }
    },
    yAxis: [{
      title: {
        text: 'Count',
        style: {
          color: "black"
        }
      },
      labels: {
        style: {
          color: "black"
        }
      }
    }],
    tooltip: {
      shared: true,
      formatter: function () {
        let tooltip = `<b>${this.x}</b><br/>`;
        this.points.forEach(point => {
          tooltip += `${point.series.name}: ${point.y}<br/>`;
        });
        return tooltip;
      }
    },
    series: [
      // {
      //   name: "Skipped Services",
      //   data: assetCountData.map(data => data.totalNoOfSkippedServices)
      // },
      {
        name: "Skipped Services in 30 Days",
        data: assetCountData.map(data => data.noOfServicesDueIn30Days)
      },
      {
        name: "Skipped Services in 60 Days",
        data: assetCountData.map(data => data.noOfServicesDueIn60Days)
      },
      {
        name: "Skipped Services in 90 Days",
        data: assetCountData.map(data => data.noOfServicesDueIn90Days)
      }
    ]
  };

  return (
    <div>
      <Card style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px', height: '460px' }}>
        <HighchartsReact highcharts={Highcharts} options={config} />
      </Card>
    </div>
  );
};

export default ServiceOwners;
