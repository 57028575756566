import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, UploadProps, Upload, Table, Form, Select } from 'antd';
import './documents.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UploadOutlined } from '@ant-design/icons'
import { read } from 'fs';
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
import { InsuranceDto, SODto, SoNumbersModel } from '@gtpl/shared-models/sale-management'
import { DownloadOutlined } from '@ant-design/icons';
import { BrowserRouter as Router1, HashRouter as Router, Route, Link, Redirect, useHistory } from "react-router-dom";
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
/* eslint-disable-next-line */
export interface InsuranceUploadProps { }
export function InsuranceUpload(
  props: InsuranceUploadProps
) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [SaleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [filelist, setfilelist] = useState([]);
  const [insUploadInfo, setInsUploadInfo] = useState<InsuranceDto[]>([]);
  const saleOrderService = new SaleOrderService();

  useEffect(() => {
    getAll()
    getAllUploadedFileInfo();
  }, [])

  const getAllUploadedFileInfo = () => {
    saleOrderService.getAllFiles().then((res) => {
      if (res.status) {
        setInsUploadInfo(res.data)
      } else {
        setInsUploadInfo([])
      }
    })
  }

  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setfilelist([]);
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(csv|xlsx|xls|pdf)$/)) {
        AlertMessages.getErrorMessage("Only xlsx,csv,xls,pdf files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
          return true;
        } else {
          setfilelist([...filelist, file]);
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist
  };
  const columns = [
    {
      title: 'FileName',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Sale Order',
      dataIndex: 'saleOrderName',
      key: 'saleOrderName',
    },
    {
      title: 'Download',
      dataIndex: 'filePath',
      render: (value, rowData: InsuranceDto, record) => {
        return <Button type={'primary'} icon={<DownloadOutlined />}><Link to={'http://206.189.138.212/erpx/'+`${rowData.filePath}`} target="_blank" style={{color:'white'}}>Download Insurance</Link></Button>
      }
    },
  ];

  // const download = () => {
  //   console.log(`${props.SaleOrderDetails.filePath}`)
  //   const fileName = `${props.SaleOrderDetails.filePath}`;
  //   if(fileName != 'null'){
  //     setTimeout(() => {
  //       const response = {
  //         file: 'http://206.189.138.212/erpx/'+`${props.SaleOrderDetails.filePath}`,
  //       };
        
  //       window.open(response.file);
  //       // you could also do:
  //       // window.location.href = response.file;
  //     }, 100);
  //   }
  //   else{
  //     AlertMessages.getErrorMessage("Please upload file. ");
  
  //   }
  // }

  const uploadInsuranceDetails = () => {
    form.validateFields().then(formDetails=>{
        console.log(formDetails);
        if (filelist.length > 0) {
          const formData = new FormData();
          filelist.forEach((file: any) => {
            formData.append('file', file);
          });
          formData.append('saleOrderId',formDetails.saleOrderId)
          saleOrderService.Insuranceupload(formData).then(res => {
            if(res.status){
              getAllUploadedFileInfo();
              form.resetFields();
              setfilelist([]);
            }
          })
        }

    })
  }


  let history = useHistory();

  const getAll = () => {
    saleOrderService.getSoNumbers().then(res => {
      console.log(res.data);
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <Card title={<span style={{ color: 'white' }}>INSURANCE UPLOAD</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>


      <Form form={form} layout="vertical">
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrderId" label="Sale Order" rules={[{ required: true, message: 'Sale Order is Required' },]}>
                <Select
                  showSearch
                  placeholder="SelectSaleOrder"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }

                >

                  <Option key={0} value={null}>SelectSaleOrder</Option>
                  {SaleOrderData.map((SaleOrderData) => {
                    return <Option key={SaleOrderData.saleOrderId} value={SaleOrderData.saleOrderId}>{SaleOrderData.saleOrderNumber}</Option>
                  })}
                </Select>
            </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="file" label="Upload" rules={[{ required: true, message: 'Please Upload File' },]}>
                <Upload {...uploadFieldProps} style={{  width:'100%' }}>
                  <Button style={{ color: "white", backgroundColor: "#806767", width:'100%' }} icon={<UploadOutlined />}>Upload Insurance</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item style={{ paddingTop: 30 }}>
                  <Button type="primary" onClick={() => uploadInsuranceDetails()}>save</Button>
              </Form.Item>
            </Col>

        </Row>
        
      </Form>

        <Table columns={columns} dataSource={insUploadInfo} />

    </Card>
  );
}
export default InsuranceUpload;
