import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber } from 'antd';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SkuDto, TypeOfValueAddition4Dto, TypesOfValueAddition2Dto, Type1Dto, ValueAdditionThreeDto, ValueAdditionFiveDto, ProductDto, FoodTypesDto, FoodTypesDropDownData, GradesDropDownData, GradesDto, HlPercentageDto, HlPercentageRangeRequest, RackPostionRequest } from '@gtpl/shared-models/masters';
import { SkuService, Type3Service, TypeofValueAddition4Service, TypesOfValueAddition2Service, Type1Service, ProductService, FoodTypeService, TypeofValueAddition5Service, GradeServices, HLpercentagesService } from '@gtpl/shared-services/masters';

import { Link, useHistory } from 'react-router-dom';
import './sku-form.css';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

/* eslint-disable-next-line */
export interface SkuFormProps {
  skuData: SkuDto;
  updateSku: (skuData: SkuDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function SkuForm(props: SkuFormProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  const [value, setValue] = useState("");
  const service = new SkuService();
  const productService = new ProductService();
  const foodTypeService = new FoodTypeService();
  const type1Service = new Type1Service();
  const type2Service = new TypesOfValueAddition2Service();
  const type3Service = new Type3Service();
  const type4Service = new TypeofValueAddition4Service();
  const type5Service = new TypeofValueAddition5Service();
  const gradesService = new GradeServices();
  const hlPercentageService = new HLpercentagesService()
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const [skuData, setSkuData] = useState<SkuDto[]>([])
  const [productsData, setProductsData] = useState<ProductDto[]>([])
  const [foodData, setFoodData] = useState<FoodTypesDto[]>([])
  const [type1Data, setType1Data] = useState<Type1Dto[]>([])
  const [type2Data, setType2Data] = useState<TypesOfValueAddition2Dto[]>([])
  const [type3Data, setType3Data] = useState<ValueAdditionThreeDto[]>([])
  const [type4Data, setType4Data] = useState<TypeOfValueAddition4Dto[]>([])
  const [type5Data, setType5Data] = useState<ValueAdditionFiveDto[]>([])
  const [gradesData, setGradesData] = useState<GradesDto[]>([])
  const [selectedProduct, setSelectedProduct] = useState<string>(null)
  const [selectedFood, setSelectedFood] = useState<string>(null)
  const [selectedType1, setSelectedType1] = useState<string>(null)
  const [selectedType2, setSelectedType2] = useState<string>(null)
  const [selectedType3, setSelectedType3] = useState<string>(null)
  const [selectedType4, setSelectedType4] = useState<string>(null);
  const [selectedType5, setSelectedType5] = useState<string>(null);
  const [selectedGrade, setSelectedGrade] = useState<string>(null)
  const [hlPercentData, setHlPercentData] = useState<any>();
  const [selectedLossOrGain, setSelectedLossOrGain] = useState<string>(null);
  const [selectedSoakingStyle, setSelectedSoakingStyle] = useState<string>(null);
  const [selectedMinMaxGlaze, setSelectedMinMaxGlaze] = useState<string>(null);
  const [selectedSoakingTime, setSelectedSoakingTime] = useState<string>(null);
  let hlPercent;
  let maxGrade;
  const [selectedMaxGrade, setSelectedMaxGrade] = useState<number>(null);
  const [selectedMinGrade, setSelectedMinGrade] = useState<number>(null);
  const [code, setCode] = useState('');

  // if( props.isUpdate ){
  //   form.setFieldsValue({variantCode:props.skuData.variantCode})
  // }

  useEffect(() => {
    if (hlPercentData) {
      calculation();
    }
  }
  ), [hlPercentData]

  useEffect(() => {
    getAllFoods(); getAllTypes1(); getAllTypes2(); getAllTypes3(); getAllTypes4(); getAllTypes5(); getAllProducts(); getAllGrades();

    if (props.isUpdate) {
      console.log(props.skuData.requiredCount)
      form.setFieldsValue({ requiredCount: Number(props.skuData.requiredCount) });
      // form.setFieldsValue({
      //   minCount:props.skuData.minCount,
      //   maxCount:props.skuData.maxCount,
      //   requiredCount:props.skuData.requiredCount
      // })
      // handleProduct(props.skuData?.productId);
      // handleGrade(props.skuData?.gradeId,0);
      // handleFood(props.skuData?.foodTypeId,0);
      // handleType1(props.skuData?.valueAdditionOneId);
      // handleType2(props.skuData?.valueAdditionTwoId);
      // handleType3(props.skuData?.valueAdditionThreeId);
      // handleType4(props.skuData?.valueAdditionFourId);
      // handleType5(props.skuData?.valueAdditionFiveId);
      // handleLossOrGain();
      // handleSoakingStyle(props.skuData?.soakingStyle);
      // handleMinMaxGlaze();
      // handleSoakingTime();
      // getHlPercentage()
      // calculation()
      // console.log(selectedGrade)
      // console.log(selectedProduct)
      // console.log(selectedFood)
      // console.log(selectedType1)
      // console.log(selectedType2)
      // console.log(selectedType3)
      // console.log(selectedType4)
      // console.log(selectedType5)
      // console.log(selectedSoakingStyle)
      // console.log(selectedLossOrGain)
      // console.log(selectedMinMaxGlaze)
      // console.log(selectedSoakingTime)
      // getVarcodeDefaultValue(`${props.skuData.variantCode}`);
    }

    if (!props.isUpdate) {
      handleSoakingTime();
    }

  }, [])




  const saveSku = (skuData: SkuDto) => {
    setDisable(true)
    skuData.isActive = true;
    service.createSku(skuData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Sku Code Created Successfully');
        history.push("/sku-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllProducts = () => {
    productService.getAllActiveProducts().then(res => {
      if (res.status) {
        setProductsData(res.data);
      } else {
        if (res.intlCode) {
          setProductsData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProductsData([]);
    })
  }
  const getAllGrades = () => {
    gradesService.getAllGrades(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setGradesData(res.data);
      } else {
        if (res.intlCode) {
          setGradesData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setGradesData([]);
    })
  }

  const getAllFoods = () => {
    foodTypeService.getAllActiveFoodTypes().then(res => {
      if (res.status) {
        setFoodData(res.data);
      } else {
        if (res.intlCode) {
          setFoodData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setFoodData([]);
    })
  }
  const getAllTypes1 = () => {
    if (!props.isUpdate) {
      type1Service.getAllActiveValAddOneTypes().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    } else {
      type1Service.getAllTypes1().then(res => {
        if (res.status) {
          setType1Data(res.data);
        } else {
          if (res.intlCode) {
            setType1Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType1Data([]);
      })
    }
  }
  const getAllTypes2 = () => {
    if (!props.isUpdate) {
      type2Service.getAllActiveValAddTwoTypes().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    } else {
      type2Service.getAllTypesOfValueAddition2s().then(res => {
        if (res.status) {
          setType2Data(res.data);
        } else {
          if (res.intlCode) {
            setType2Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType2Data([]);
      })
    }
  }
  const getAllTypes3 = () => {
    if (!props.isUpdate) {
      type3Service.getAllAvtiveValAddThreeTypes().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    } else {
      type3Service.getAllTypesOfValueAddition3s().then(res => {
        if (res.status) {
          setType3Data(res.data);
        } else {
          if (res.intlCode) {
            setType3Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType3Data([]);
      })
    }
  }
  const getAllTypes4 = () => {
    if (!props.isUpdate) {
      type4Service.getAllActiveTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    } else {
      type4Service.getAllTypeOfValueAddition4().then(res => {
        if (res.status) {
          setType4Data(res.data);
        } else {
          if (res.intlCode) {
            setType4Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType4Data([]);
      })
    }
  }
  const getAllTypes5 = () => {
    if (!props.isUpdate) {
      type5Service.getAllActiveTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    } else {
      type5Service.getAllTypeOfValueAddition5().then(res => {
        if (res.status) {
          setType5Data(res.data);
        } else {
          if (res.intlCode) {
            setType5Data([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setType5Data([]);
      })
    }
  }
  const getHlPercentage = () => {
    //  console.log(form.getFieldValue("gradeId"));
    //  console.log(form.getFieldValue("gradeId"));
    const selectedGrades = gradesData.find(grade => grade.gradeId.toString() == form.getFieldValue("gradeId")?.toString());
    console.log(selectedGrades);
    const req = new HlPercentageRangeRequest(selectedGrades?.gradeMaxCount);
    hlPercentageService.getHlPercentageByRange(req).then(res => {
      if (res.status) {
        setHlPercentData(res.data?.hlPercentage);
      } else {
        if (res.intlCode) {
          setHlPercentData(res.data.hlPercentage);
          AlertMessages.getErrorMessage('No Hl Percent for the given grade');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getVarcodeDefaultValue = (defaultCode: string) => {
    console.log(defaultCode);
    // if(code==''){
    form.setFieldsValue({
      variantCode: defaultCode,
    });
    // }
  }

  //calculation for min count max count and average Count
  const calculation = () => {
    // console.log(form.getFieldValue('gradeId'));
    // console.log(form.getFieldValue('lossOrGainPercentage'));
    if (form.getFieldValue('gradeId') && form.getFieldValue('lossOrGainPercentage')) {
      const productPercentage = form.getFieldValue('lossOrGainPercentage');
      // let ratio ;
      // let maxCount;
      // let minCount;
      // let requireCount;
      // console.log('hlPercent',hlPercentData)
      const ratio = (1 / ((hlPercentData / 100) * (productPercentage / 100)));
      // console.log('ratio',ratio)
      const maxCount = Math.round(((selectedMaxGrade / (ratio)) * 2.2))
      const minCount = Math.round(((selectedMinGrade / (ratio)) * 2.2))
      const requireCount = Math.ceil((minCount + maxCount) / 2)
      form.setFieldsValue({
        minCount: minCount,
        maxCount: maxCount,
        // requiredCount:requireCount
        requiredCount: maxCount
      })
    }
  }

  const generateVarientCode = () => {
    console.log(form.getFieldValue("productId"));
    const selectedProducts = productsData.find(product => product.productId == form.getFieldValue("productId"));
    let selectedProduct = form.getFieldValue("productId") ? selectedProducts?.productCode : '';
    setSelectedProduct(form.getFieldValue("productId") ? selectedProducts?.productCode : '');
    const selectedGrades = gradesData.find(grade => grade.gradeId.toString() == form.getFieldValue("gradeId")?.toString());

    setSelectedMaxGrade(selectedGrades?.gradeMaxCount);
    setSelectedMinGrade(selectedGrades?.gradeMinCount);
    let selectedGrade = form.getFieldValue("gradeId") ? (selectedGrades?.gradeMinCount + '-' + selectedGrades?.gradeMaxCount + '/') : '';
    let selectedMaxGrades = selectedGrades?.gradeMaxCount;
    // console.log(selectedMaxGrades+selectedGrade);
    setSelectedGrade(form.getFieldValue("gradeId") ? (selectedGrades?.gradeMinCount + '-' + selectedGrades?.gradeMaxCount + '/') : '')
    setSelectedLossOrGain(form.getFieldValue('lossOrGainPercentage') ? ('(' + form.getFieldValue('lossOrGainPercentage') + ')' + '%') : '')


    let shortCut;
    //  console.log(form.getFieldValue("soakingStyle"),'ss')
    if (form.getFieldValue("soakingStyle") == "STPP 2% + SALT 2%") { shortCut = '/S2S2' } else if (form.getFieldValue("soakingStyle") == "Only SALT 2%") { shortCut = '/OS2' } else if (form.getFieldValue("soakingStyle") == "NP 2% + SALT 2%") { shortCut = '/NP2S2' } else if (form.getFieldValue("soakingStyle") == "NT") { shortCut = '/NT' };
    const selectedSoakingStyle = form.getFieldValue("soakingStyle") ? shortCut : ''
    // console.log('ss',selectedSoakingStyle)
    setSelectedSoakingStyle(form.getFieldValue("soakingStyle") ? selectedSoakingStyle : '');


    const selectedMinGlaze = form.getFieldValue('minglaze');
    // console.log('selectedMinGlaze',selectedMinGlaze)
    const selectedMaxGlaze = form.getFieldValue('maxglaze');
    // console.log('selectedMaxGlaze',selectedMaxGlaze)
    // const selectedMinMaxGlaze = ((selectedMinGlaze?('/' + selectedMinGlaze):'')+ selectedMinGlaze?'-':"" + (selectedMaxGlaze?selectedMaxGlaze:''))
    console.log(selectedMinGlaze)
    console.log(selectedMaxGlaze)
    const selectedMinMaxGlaze = (selectedMinGlaze != undefined && selectedMaxGlaze != undefined) ? ('/' + selectedMinGlaze + '-' + selectedMaxGlaze) : "";
    setSelectedMinMaxGlaze((selectedMinGlaze ? ('/' + selectedMinGlaze) : '') + '-' + (selectedMaxGlaze ? selectedMaxGlaze : ''))


    const soakingTime = form.getFieldValue('soakingTime');
    let selectedSoakingTime = form.getFieldValue('soakingTime') + 'Min';
    setSelectedSoakingTime(selectedSoakingTime ? ('/' + soakingTime + 'Min') : '')


    const selectedFoods = foodData.find(food => food.foodTypeId.toString() == form.getFieldValue("foodTypeId")?.toString());
    let selectedFood = form.getFieldValue("foodTypeId") ? selectedFoods?.foodTypeCode : ''
    setSelectedFood(form.getFieldValue("foodTypeId") ? selectedFoods?.foodTypeCode : '');

    const selectedVal1 = type1Data.find(val1 => val1.valueAdditionOneId.toString() == form.getFieldValue("valueAdditionOneId")?.toString())
    let selectedType1 = form.getFieldValue("valueAdditionOneId") ? selectedVal1?.code : '';
    setSelectedType1(form.getFieldValue("valueAdditionOneId") ? selectedVal1?.code : '');

    const selectedVal2 = type2Data.find(val1 => val1.valueAdditionTwoId.toString() == form.getFieldValue("valueAdditionTwoId")?.toString())
    let selectedType2 = form.getFieldValue("valueAdditionTwoId") ? selectedVal2?.code : '';
    setSelectedType2(form.getFieldValue("valueAdditionTwoId") ? selectedVal2?.code : '');

    const selectedVal3 = type3Data.find(val1 => val1.valueAdditionThreeId.toString() == form.getFieldValue("valueAdditionThreeId")?.toString())
    let selectedType3 = form.getFieldValue("valueAdditionThreeId") ? selectedVal3?.code : '';
    setSelectedType3(form.getFieldValue("valueAdditionThreeId") ? selectedVal3?.code : '');

    const selectedVal4 = type4Data.find(val1 => val1.valueAdditionFourId.toString() == form.getFieldValue("valueAdditionFourId")?.toString())
    let selectedType4 = form.getFieldValue("valueAdditionFourId") ? selectedVal4?.code : '';
    setSelectedType4(form.getFieldValue("valueAdditionFourId") ? selectedVal4?.code : '');

    const selectedVal5 = type5Data.find(val1 => val1.valueAdditionFiveId.toString() == form.getFieldValue("valueAdditionFiveId")?.toString())
    let selectedType5 = form.getFieldValue("valueAdditionFiveId") ? selectedVal5?.code : '';
    setSelectedType5(form.getFieldValue("valueAdditionFiveId") ? selectedVal5?.code : '');

    // console.log("selectedMaxGrades"+selectedMaxGrades)
    // selectedMaxGrades!=undefined?
    // getHlPercentage()
    // :""; 

    // calculation();

    // console.log(selectedGrade)
    // console.log(selectedProduct)
    // console.log(selectedFood)
    // console.log(selectedType1)
    // console.log(selectedType2)
    // console.log(selectedType3)
    // console.log(selectedType4)
    // console.log(selectedType5)
    // console.log(selectedSoakingStyle)
    // console.log(selectedLossOrGain)
    console.log(selectedMinMaxGlaze)
    // console.log(selectedSoakingTime)
    getVarcodeDefaultValue(`${selectedGrade ? selectedGrade : ''}${selectedProduct ? selectedProduct : ''}${selectedFood ? selectedFood : ''}${selectedType1 ? selectedType1 : ''}${selectedType2 ? selectedType2 : ''}${selectedType3 ? selectedType3 : ''}${selectedType4 ? selectedType4 : ''}${selectedType5 ? selectedType5 : ''}${selectedSoakingStyle ? selectedSoakingStyle : ''}${selectedMinMaxGlaze ? selectedMinMaxGlaze + '/' : ''}${selectedSoakingTime ? selectedSoakingTime : ''}`);
  }
  const handleProduct = (value) => {
    generateVarientCode()
  }
  const handleGrade = (value, row) => {
    if (form.getFieldValue('gradeId') && form.getFieldValue('lossOrGainPercentage')) {
      getHlPercentage();
    }
    generateVarientCode()
  }
  const handleLossOrGain = () => {
    if (form.getFieldValue('gradeId') && form.getFieldValue('lossOrGainPercentage')) {
      getHlPercentage();
    }
    // calculation();
    generateVarientCode()
  }
  const handleSoakingStyle = (value) => {
    generateVarientCode()
  }
  const handleMinMaxGlaze = () => {
    generateVarientCode()
  }
  const handleSoakingTime = () => {
    generateVarientCode()
  }
  const handleFood = (value, row) => {

    generateVarientCode()
  }
  const handleType1 = (value) => {

    generateVarientCode()
  }
  const handleType2 = (value) => {
    generateVarientCode()
  }
  const handleType3 = (value) => {
    generateVarientCode()
  }
  const handleType4 = (value) => {
    generateVarientCode()
  }
  const handleType5 = async (value) => {
    generateVarientCode()
  }
  const handleSkuCode = (value) => {
    setCode(value);
  }
  const getSkucodeDefaultValue = (defaultCode: string) => {
    // if(code==''){
    form.setFieldsValue({
      skuCode: defaultCode,
    });
    // }
  }
  const handleVarCode = (value) => {
    setCode(value);
  }


  const saveData = (values: SkuDto) => {
    setDisable(false)
    console.log(values);
    if (props.isUpdate) {
      props.updateSku(values);
    } else {
      setDisable(false)
      saveSku(values);
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFocus = () => {
    // console.log('focus');
  }

  const onSearch = (val) => {
    // console.log('search:', val);
  }
  const onBlur = () => {
    // console.log('blur');
  }



  return (
    <Card
      title={<span style={{ color: 'white' }}>Product SKUS's</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={props.isUpdate == true ? "" : <Link to='/sku-view' ><span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>  </Link>}>
      <Form
        layout="vertical"
        form={form}
        initialValues={props.skuData}
        name="control-hooks"
        onFinish={saveData}
        autoComplete={"off"}
      >
        <Form.Item name="skuCodeId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>

        <Form.Item style={{ display: 'none' }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={30}>

          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="gradeId"
              label="Grade:"
              rules={[
                {
                  required: true,
                  message: "Grade is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Grade"
                optionFilterProp="children"
                onChange={handleGrade}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Grade</Option>
                {gradesData.map((grade) => {
                  return <Option value={grade.gradeId}>{grade.gradeMinCount + '-' + grade.gradeMaxCount}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="productId"
              label="Product:"
              rules={[
                {
                  required: true,
                  message: "Product is required"
                },
              ]}

            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Product"
                optionFilterProp="children"
                onChange={handleProduct}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Product</Option>
                {productsData.map((product) => {
                  return <Option value={product.productId}>{product.productName}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="lossOrGainPercentage"
              label="HL to FG %:"
              rules={[
                {
                  required: true,
                  message: 'Loss or Gain % is required'
                },
                // {
                //   pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //   message: `Don't Allow Spaces`
                // }
              ]}

            >
              <Input onChange={handleLossOrGain} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="requiredCount"
              label="Count:"
              rules={[
                {
                  required: true,
                  message: 'Count is required'
                },
                // {
                //   pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //   message: `Don't Allow Spaces`
                // }
              ]}
              initialValue={props.skuData?.requiredCount}
            >
              <Input min={0} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="minCount"
              label="Min Count:"
              rules={[
                {
                  required: true,
                  message: 'Count is required'
                },
                // {
                //   pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //   message: `Don't Allow Spaces`
                // }
              ]}
            >
              <Input disabled min={0} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="maxCount"
              label=" Max Count:"

              rules={[
                {
                  required: true,
                  message: 'Max COunt is required'
                },
                //   {
                //     pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //     message: `Don't Allow Spaces`
                // }
              ]}
            >
              <Input disabled min={0} />
            </Form.Item>
          </Col>





          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="foodTypeId"
              label="Food Type:"
              rules={[
                {
                  required: true,
                  message: "Food Type is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Food Type"
                optionFilterProp="children"
                onChange={handleFood}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Food Type</Option>
                {foodData.map((food) => {
                  return <Option value={food.foodTypeId}>{`${food.foodType} (${food.foodTypeCode})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="valueAdditionOneId"
              label="Value Addn. One:"
              rules={[
                {
                  required: true,
                  message: "Value Addition One is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type1"
                optionFilterProp="children"
                onChange={handleType1}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type1</Option>
                {type1Data.map((type1) => {
                  return <Option value={type1.valueAdditionOneId}>{`${type1.name}(${type1.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="valueAdditionTwoId"
              label="Value Addn. Two:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Two is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type2"
                optionFilterProp="children"
                onChange={handleType2}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type2</Option>
                {type2Data.map((type2) => {
                  return <Option value={type2.valueAdditionTwoId}>{`${type2.name} (${type2.code})`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="valueAdditionThreeId"
              label="Value Addn. Three:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Three is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type3"
                optionFilterProp="children"
                onChange={handleType3}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Type3</Option>
                {type3Data.map((type3) => {
                  return <Option value={type3.valueAdditionThreeId}>{`${type3.name} (${type3.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="valueAdditionFourId"
              label="Value Addn. Four:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Four is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type4"
                optionFilterProp="children"
                onChange={handleType4}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type4</Option>
                {type4Data.map((type4) => {
                  return <Option value={type4.valueAdditionFourId}>{`${type4.name} (${type4.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="valueAdditionFiveId"
              label="Value Addn. Five:"
              rules={[
                {
                  required: true,
                  message: "Value Addition Five is required"
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Type5"
                optionFilterProp="children"
                onChange={handleType5}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Type5</Option>
                {type5Data.map((type5) => {
                  return <Option value={type5.valueAdditionFiveId}>{`${type5.name} (${type5.code})`}</Option>
                })}
              </Select>
            </Form.Item></Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="soakingStyle" label="Soaking Style" rules={[{ required: true, message: 'Missing Soaking Style' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleSoakingStyle}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Soaking Style"
                allowClear

              >
                <Option label={'soakingStyle'} key={"STPP 2% + SALT 2%"} value="STPP 2% + SALT 2%">STPP 2% + SALT 2%</Option>
                <Option label={'soakingStyle'} key={"Only SALT 2%"} value="Only SALT 2%">Only SALT 2%</Option>
                <Option label={'soakingStyle'} key={"NP 2% + SALT 2%"} value="NP 2% + SALT 2%">NP 2% + SALT 2%</Option>
                {/* <Option label={'soakingStyle'}  key={"CP + SALT"} value="CP + SALT">CP + SALT</Option> */}
                <Option label={'soakingStyle'} key={"NT"} value="NT">NT</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item label='Glazing' rules={[{ required: true, message: 'Missed glazing/Enter a correct value/Min GLaze should be less than max glaze' }]}>
              <Input.Group compact>
                <Form.Item
                  label='Glazing'
                  name={'minglaze'}
                  noStyle
                  rules={[{
                    required: true, message: 'Min Glaze is required',
                    pattern: /^[0-9]*$/,
                    validator: (_, value) => {
                      const maxGlaze = form.getFieldValue('maxglaze');
                      if (maxGlaze) {
                        return value <= maxGlaze ? Promise.resolve() : Promise.reject(new Error('Min Glaze should be < Max Glaze'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}
                //  initialValue={getVarcodeDefaultValue}

                >
                  <InputNumber onChange={handleMinMaxGlaze} style={{ width: "35%" }} placeholder="Min" min={1} />
                </Form.Item>

                <Form.Item name={'middle'} noStyle>
                  <Input style={{ width: "30%" }}
                    placeholder="~"
                    disabled

                  />
                </Form.Item>
                <Form.Item
                  label='Max Glaze'
                  name={'maxglaze'}
                  noStyle
                  rules={[{
                    required: true, message: 'Missed max glaze/Max glaze greater than Min glaze/Enter a correct value', pattern: /^[0-9]*$/,
                    validator: (_, value) => {
                      const minGlaze = form.getFieldValue('minglaze');
                      if (minGlaze) {
                        return value >= minGlaze ? Promise.resolve() : Promise.reject(new Error('Max Glaze should be > Min Glaze'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}

                >
                  <InputNumber style={{ width: "35%" }} placeholder="Max" min={form.getFieldValue('minglaze')} onChange={handleMinMaxGlaze} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="soakingTime" label="Soaking Time" rules={[{ required: true }]} initialValue={120}>
              <InputNumber style={{ width: '100%' }} min={1} onChange={handleSoakingTime} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item

              name="variantCode"
              label="Variant Code:"

              rules={[
                {
                  required: true,
                  message: 'Variant code is required'
                },

                // {
                //   pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //   message: `Don't Allow Spaces`
                // }
              ]}
            // initialValue={props.isUpdate?props.skuData.variantCode:''}

            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="unitPrice"
              label=" Unit Price(per LB):"

              rules={[
                {
                  required: true,
                  message: 'Unit Price is required'
                },
                //   {
                //     pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
                //     message: `Don't Allow Spaces`
                // }
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="sttpSalt"
              label="STTP + Salt:"
              rules={[
                {
                  // required: true,message:'STTP + Salt is required'
                },
                {
                  pattern: /((\d+)((\.\d{1,2})?))$/,
                  message: `Don't Allow letters and Spaces`
                }]}
              hidden
              initialValue='0'
            ><Input /></Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="npSalt"
              label="NP + Salt:"
              rules={[
                {
                  // required: true,message:'NP + Salt is required'
                },
                {
                  pattern: /((\d+)((\.\d{1,2})?))$/,
                  message: `Don't Allow letters and Spaces`
                }]}
              initialValue='0'
              hidden
            ><Input /></Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="cpSalt"
              label="CP + Salt:"
              rules={[
                {
                  // required: true,message:'CP + Salt is required'
                },
                {
                  pattern: /((\d+)((\.\d{1,2})?))$/,
                  message: `Don't Allow letters and Spaces`
                }]}
              initialValue='0'
              hidden
            ><Input /></Form.Item>
          </Col>
          {/* </Row> */}
          {/* <Row gutter={20}> */}
          {/* <Col span={5}>
      <Form.Item
        name="skuCode"
        label="SKU Code"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input onChange={handleSkuCode}/>
      </Form.Item></Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="onlySalt"
              label="Only Salt:"
              rules={[
                {
                  // required: true,message:'Only Salt is required'
                },
                {
                  pattern: /((\d+)((\.\d{1,2})?))$/,
                  message: `Don't Allow letters and Spaces`
                }]}

              initialValue='0'
              hidden
            ><Input /></Form.Item>
          </Col>
        </Row>
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
          <Form.Item
            name="shortCode"
            label="Short Code"
            rules={[
              {
                required: true,
                message: 'Short Code is required'
              },
              // {
              //   pattern: /^[^-\s][a-zA-Z0-9_\s-*]*$/,
              //   message: `Don't Allow Spaces`
              // }
            ]}

          >
            <Input />
          </Form.Item>
        </Col>

        <Row>
          <Col style={{ textAlign: 'right' }} span={24}>
            <Button type="primary" disabled={disable} htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default SkuForm;
