import React, { useEffect, useState } from 'react';
import { LevelDto, RmRackPositionModel, RmRackDetails, RmRackPositionTypeEnum } from '@gtpl/shared-models/masters';
import { OccupancyEnum, RackPositionEnum } from "@gtpl/shared-models/masters";
import './rm-rack-position-form.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { RmRackService, ZoneService, LevelService, RmRackPositionService, RmColumnService } from '@gtpl/shared-services/masters'
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export interface RmRackPositionFormProps {
  rackData: RmRackPositionModel;
  updateRack: (rack: RmRackPositionModel) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function RmRackPositionForm(
  props: RmRackPositionFormProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const rackService = new RmRackPositionService();
  const rackMasterService = new RmRackService();
  const rackcoumnservice = new RmColumnService();
  const levelMasterService = new LevelService();
  const [rackData, setRackData] = useState<any[]>([]);
  const [levelData, setLevelData] = useState<LevelDto[]>([]);
  const [selectedRackName, setSelectedRackName] = useState<RmRackDetails[]>([]);
  const [selectedRackLevel, setSelectedRackLevel] = useState<LevelDto[]>([]);
  const [zonesDrop , setZonesDrop] = useState<any[]>([]);
  const [coulmndata,setColumndata] = useState<any[]>([]);

  useEffect(() => {
    getRacksMaster()
    getLevelMaster()
    getRmZones();
    getColumnMaster();
  }, [])


  const getRacksMaster = () => {
    rackMasterService.getAllRmRacks().then(res => {
      if (res.status) {
        setRackData(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getLevelMaster = () => {
    levelMasterService.getAllLevels().then(res => {
      if (res.status) {
        setLevelData(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }



  const createRack = (rackDto: RmRackPositionModel) => {
    rackDto.plantId = Number(localStorage.getItem('unit_id'));
    console.log(rackDto.plantId)
    rackService.createRmRackPosition(rackDto).then(res => {
      console.log(rackDto)
      if (res.status) {
        AlertMessages.getSuccessMessage('Rack Created Successfully');
        history.push("/rm-rack-position");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getColumnMaster = () => {
    rackcoumnservice.getAllColumnDropDown().then(res => {
      if (res.status) {
        setColumndata(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const getRmZones=()=>{
    rackService.getRmZones({plantId:Number(localStorage.getItem('unit_id'))}).then((res)=>{
        if(res.data){
          setZonesDrop(res.data)
        }else{
         // AlertMessages.getErrorMessage(res.internalMessage);
        }
    });
  };

  const handleRackName = (value) => {
    setSelectedRackName(value);
  }
  const handleRackLevel = (value) => {
    setSelectedRackLevel(value);
  }
  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: RmRackPositionModel) => {
    if (props.isUpdate) {
      props.updateRack(values);
    } else {
      createRack(values);
    }

  };
  return (<Card title={<span style={{ color: 'white' }}>Rack Position</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate ? '' : <Link to='/rm-rack-position' ><Button className='panel_button' >View </Button></Link>}
  >
    <Form layout="vertical" form={form} initialValues={props.rackData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="rmRackPositionId" style={{ display: "none" }} >
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>


      <Row gutter={24}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="zoneId"
            label="Zones"
            rules={[
              {
                required: true,
                message: 'Zones Required',
              },
            ]}>
            <Select
              showSearch
              placeholder="Select zones"
              optionFilterProp="children"
              //onSelect={handleRackLevel}
              // style={{width:'200px'}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {zonesDrop.map((drop) => {
                return <Option key={drop.zoneId} value={drop.zoneId}>{drop.rmZoneName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rmRackMasterId"
            label="Rack Name"
            rules={[
              {
                required: true,
                message: 'Rack Name is required'
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }

            ]}>
            <Select
              showSearch
              placeholder="Select Rack"
              optionFilterProp="children"
              onSelect={handleRackName}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Rack</Option>
              {rackData.map((rack) => {
                return <Option key={rack.rmRackMasterId} value={rack.rmRackMasterId}>{rack.rackName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rmRackPositionCode"
            label="Rack Position Code"
            rules={[
              {
                required: true,
                message: 'Rack Position Code Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }


            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
          <Form.Item
            name="rmRackPositionType"
            label="Rack Position Type"

            rules={[
              {
                required: true,
                message: 'Rack Position Type is  Required',

              },
            ]}
            initialValue = {RmRackPositionTypeEnum.FRONT}
            >
            <Select
              showSearch
              placeholder="Select Rack Position Type"
              allowClear={true}

            >
              {Object.keys(RmRackPositionTypeEnum).map((type) => {
                return <Option value={RmRackPositionTypeEnum[type]}>{RmRackPositionTypeEnum[type]}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
          <Form.Item
            name="rmRackPosition"
            label="Rack Position"

            rules={[
              {
                required: true,
                message: 'Rack Position is  Required',

              },
            ]}
            initialValue ={RackPositionEnum.FRONT_CENTER}
            >
            <Select
              showSearch
              placeholder="Select Rack Position"
              allowClear={true}
            >
              {Object.keys(RackPositionEnum).map((type) => {
                return <Option value={RackPositionEnum[type]}>{RackPositionEnum[type]}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rmRackLevel"
            label="Rack Level"
            rules={[
              {
                required: true,
                message: 'Rack Level Required',
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }


            ]}>
            <Select
              showSearch
              placeholder="Select Rack Level"
              optionFilterProp="children"
              onSelect={handleRackLevel}
              // style={{width:'200px'}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Rack Level</Option>
              {levelData.map((level) => {
                return <Option key={level.levelId} value={level.levelId}>{level.levelName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rmRackColumn"
            label="Rack Colum"
            rules={[
              {
                required: true,
                message: 'Rack Column Required',
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }


            ]}>
            <Select
              showSearch
              placeholder="Select Rack Column"
              optionFilterProp="children"
              onSelect={handleRackLevel}
              // style={{width:'200px'}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Rack Level</Option>
              {coulmndata.map((column) => {
                return <Option key={column.columnsId} value={column.columnsId}>{column.columnsNo}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
          <Form.Item
            name="rmRackBoundaries"
            label="Rack Boundaries"
            rules={[
              {
                required: true,
                message: 'Rack Boundaries Are Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }


            ]} 
            initialValue={1}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="assocRfId"
            label="Associated RF ID"
            rules={[
              {
                required: true,
                message: 'RFID is Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }


            ]}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
          <Form.Item
            name="occupancy"
            label="Occupancy"
          >
            <Select
              showSearch
              defaultValue={OccupancyEnum.NOT_OCCUPIED}
              allowClear={true}
            >
              {Object.keys(OccupancyEnum).map((type) => {
                return <Option value={OccupancyEnum[type]}>{OccupancyEnum[type]}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>
      </Row>
    </Form>
  </Card>
  );
}


export default RmRackPositionForm;