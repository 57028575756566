import React from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import './vendor-price-list-grid.css';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface VendorPriceListGridProps {}

export function VendorPriceListGrid(
  props: VendorPriceListGridProps
) {
  return (
    <Card title={<span style={{color:'white'}}>Vendors Price List</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/vendorsPriceListForm' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}  >
 
    </Card>
  );
}

export default VendorPriceListGrid;
