import React, { useEffect, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { AttendanceService } from '@gtpl/shared-services/hrms';
import { ShiftAttendanceRequest, ShiftWiseCountModel } from '@gtpl/shared-models/hrms';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';

export interface AttendanceGraphProps { }

export function AttendanceGraph(props: AttendanceGraphProps) {

    const [attendanceData, setAttendanceData] = useState<ShiftWiseCountModel[]>([])
    const service = new AttendanceService();

    useEffect(() => {
        const currDate = new Date();
        const fromDate = currDate.getFullYear() + '-' + Number(currDate.getMonth() + 1) + '-' + currDate.getDate();
        const toDate = currDate.getFullYear() + '-' + Number(currDate.getMonth()) + '-' + Number(currDate.getDate() + 1);
        getAttendanceData(fromDate, toDate);
    }, [])

    const getAttendanceData = (fromDate: string, toDate: string) => {
        const req = new ShiftAttendanceRequest(fromDate, toDate)
        service.getAttendanceCountShiftWise(req).then((res) => {
            if (res.status) {
                setAttendanceData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }
    const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf("day").subtract(idx, "days").format('DD-MMMM'));

    const graph = {
        title: {
            text: 'Attendance Trend of Last One Month'
        },

        yAxis: {
            title: {
                text: 'Number of Employees'
            }
        },

        xAxis: {
            categories: lastThirtyDays,
            accessibility: {
                rangeDescription: ''
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                //pointStart: 2010
            }
        },


        series: [{
            name: 'Total',
            data: [150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150]
        }, {
            name: 'Present',
            data: [111, 140, 138, 128, 130, 125, 135, 137, 130, 145, 142, 111, 140, 138, 128, 130, 125, 135, 137, 130, 145, 142, 128, 130, 125, 135, 137, 130, 145, 142]
        }, {
            name: 'Absent',
            data: [39, 10, 12, 22, 20, 25, 15, 13, 20, 5, 8, 39, 10, 12, 22, 20, 25, 15, 13, 20, 5, 8, 22, 20, 25, 15, 13, 20, 5, 8]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={graph} />
        </>
    )
}

export default AttendanceGraph;