import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { AssetAssignmentService, AssetService } from '@gtpl/shared-services/asset-management';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto";
import { AssetsId } from "@gtpl/shared-models/asset-management";
import { Button, Card, Descriptions, Modal, Popover, Row } from "antd";
import { Link, Redirect, useHistory } from 'react-router-dom';
import Text from "antd/lib/typography/Text";
import { ServiceStatusEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import AddRemarks from "libs/pages/asset-management/asset-management-components/asset-service/src/lib/add-remarks"

const localizer = momentLocalizer(moment);
moment.locale('en-GB');

export function AssetServiceCalender() {

  const [calenderData, setCalenderData] = useState<any[]>([])
  const [datesData, setDatesData] = useState<any[]>([])
  const history = useHistory()
  const service = new AssetAssignmentService()
  const assetsService = new AssetService();
  const [showModal, setShowModal] = useState<boolean>(true)
  const [handleRemarks, showHandleRemarks] = useState<{ modalVisible?: boolean, remarks?: string, assetServiceId?: number }>({ modalVisible: false, remarks: '', assetServiceId: 0 });
  const [events, setEvents] = useState([]);


  useEffect(() => {
    getDataForServiceCalender();
    getWarrantyAndInsuranceDate();
  }, [])

  function content(index) {
    return (
      <>
        <Descriptions column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item label="Service Name"  >
            {calenderData[index]?.serviceName}
          </Descriptions.Item>
          <Descriptions.Item label="Asset Name"  >
            {calenderData[index]?.assetName}
          </Descriptions.Item>
          <Descriptions.Item label="Asset Code"  >
            {calenderData[index]?.assetCode}
          </Descriptions.Item>
          <Descriptions.Item label="Model"  >
            {calenderData[index]?.model}
          </Descriptions.Item>
          <Descriptions.Item label="Location"  >
            {calenderData[index]?.locationName}
          </Descriptions.Item>
          <Descriptions.Item label="Service Date"  >
            {moment(calenderData[index]?.assetServiceDate).format("DD-MM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Service Provide"  >
            {calenderData[index]?.serviceProvider}
          </Descriptions.Item>
          <Descriptions.Item label="Service Owner"  >
            {calenderData[index]?.serviceOwner}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number"  >
            {calenderData[index]?.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Service Note"  >
            {calenderData[index]?.remarks}
          </Descriptions.Item>
        </Descriptions>
      </>
    )
  }

  const updateAssetsStatus = (values: any) => {
    const status = values.serviceStatus == ServiceStatusEnum.OPEN ? ServiceStatusEnum.INPROGRESS : ServiceStatusEnum.COMPLETE
    const req = new AssetByCode(values.assetId, values.assetServiceId, status)
    assetsService.updateAssetStatus(req).then((res) => {
      if (res.status) {
        getDataForServiceCalender()
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  function newcontent(index: any) {
    return (
      <div >
        <>Asset Name     :{calenderData[index].assetName}</><br />
        <>Asset Category :{calenderData[index].assetCategory}</> <br />
        <>Service Date   :{calenderData[index]?.actualServiceDate ? moment(calenderData[index].actualServiceDate).format('YYYY-MM-DD') : moment(calenderData[index].assetServiceDate).format('YYYY-MM-DD')}</><br />
        <>Expire Date    :{calenderData[index].expiredDate ? moment(calenderData[index].expiredDate).format('YYYY-MM-DD') : '-'}</><br />
        <>Asset Owner    :{calenderData[index].employeeName}</><br />
        <>Service Provider:{calenderData[index]?.serviceProvider ? calenderData[index]?.serviceProvider : '-'}</><br />
        <>Plant          :{calenderData[index].plantName}</><br />
        <>Location       :{calenderData[index].locationName}</><br />
        <>Department     :{calenderData[index].deptName}</><br /><br />
        {calenderData[index].serviceStatus == ServiceStatusEnum.OPEN ? <> <span><Button onClick={() => history.push(`/asset-service-form/${calenderData[index].assetId}/${calenderData[index].serviceStatus}/${calenderData[index].assetServiceId}/${calenderData[index].assetServiceDate}`)} type='primary' size='small'>Assign for service</Button></span><br /></> : <br />}
      </div>
    );
  }

  function renderPopoverTitle(values) {
    let title = (values.serviceStatus == ServiceStatusEnum.OPEN) ? 'Start' : 'Complete';
    const buttons = values.serviceStatus == ServiceStatusEnum.OPEN ? (<Button onClick={() => updateAssetsStatus(values)} size='small' type='primary' shape="round">{title}</Button>) : (<><Button onClick={() => handleAddRemarks(values)} size='small' type='primary' shape="round">Service note</Button>&nbsp;<Button onClick={() => updateAssetsStatus(values)} size='small' type='primary' shape="round">{title}</Button></>)
    return <Row justify="space-between"><span>Service Details</span><span>{buttons}</span></Row>

  }

  function handleAddRemarks(rowData) {
    setShowModal(false)
    showHandleRemarks({ modalVisible: true, remarks: rowData.remarks, assetServiceId: rowData.assetServiceId })
  }

  const handleOpenChange = (newOpen: boolean) => {
    setShowModal(newOpen);
  };


  // Get the first Monday in the month
  const NoCurrentTimeLine = () => null;
  const eventsData = calenderData?.map((i, index) => {

    return {
        title: i.serviceStatus != ServiceStatusEnum.COMPLETE ? <Popover
        title={renderPopoverTitle(i)} content={() => content(index)} overlayStyle={{ width: '600px' }} ><span style={{ color: 'white' }}><h1 style={{fontSize:'11px'}}>{i.serviceName ? i.serviceName : '-'}<br/>{i.serviceStatus}</h1>{i.assetName}</span></Popover> : <Popover title="Service Details" content={() => content(index)} overlayStyle={{ width: '600px' }} ><span style={{ color: 'white' }}><h1 style={{fontSize:'11px'}}>{i.serviceName ? i.serviceName : '-'}<br/>{i.serviceStatus}</h1>{i.assetName}</span>
      </Popover>,
      start: new Date(i.assetServiceDate),
      end: new Date(i.assetServiceDate),
      status: i.serviceStatus
    }
  })

  const eventDates = datesData?.map((i) => { 
    return {
      title : <span style={{background :'gray'}}><h1>{moment(i.insuranceDate).format('YYYY-MM-DD')}</h1>{ i.assetName}</span> ,
      start : new Date(i.insuranceDate),
      end : new Date(i.insuranceDate),
    }
   })
   const mergeArray = [...eventsData]


   console.log(events)
  const formats = {
    // dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd M/D', culture),
    // weekDayFormat: (date, culture, localizer) => localizer.format(date, 'ddd M/D', culture),
    eventTimeRangeFormat: () => null,
    // agendaTimeFormat: () => null,
    // timeGutterFormat: () => '',

  };



  const getDataForServiceCalender = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    service.getDataForServiceCalender(req).then((res) => {
      if (res.status) {
        setCalenderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  console.log(calenderData)

  const getWarrantyAndInsuranceDate = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    service.getWarrantyAndInsuranceDate(req).then((res) => {
      if (res.status) {
        setDatesData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const eventStyleGetter = (event) => {
    let color = '#536eef';
    const currDate = new Date();
    const serviceDate = new Date(event.start)
    if (event.status == ServiceStatusEnum.COMPLETE) {
      color = '#1be158'
    } else if (event.status === ServiceStatusEnum.INPROGRESS) {
      color = '#e7e24f'
    } else if ( serviceDate > currDate && event.status != ServiceStatusEnum.INPROGRESS){
      color = '#dd5959'
    }
    else {
      color = '#536eef'
    }
    return {
      style: {
        backgroundColor: color
      },
    }
  };
  
  return (
    <>
      {/* <h1 >🔵 Open  🔴 Skipped date  🟢 Service Completed  🟡 In Progress</h1> */}
      <div className="App">
        <Row justify="end">
          <Link to="/asset-service-form">  <h4>Click here to schedule a service</h4></Link>
        </Row>
        <br />
        <div style={{ height: 500 }}>
          <Calendar
            events={eventsData}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
            defaultView="month"
            color='white'
            formats={formats}
            eventPropGetter={eventStyleGetter}
            components={{ today: NoCurrentTimeLine }}
            popup={false}
            onShowMore={(events, date) => setShowModal(true)}

          />
        </div>
      </div>
      <Modal key={handleRemarks.assetServiceId} visible={handleRemarks.modalVisible} footer={false} width='60%' closable={true} onCancel={() => showHandleRemarks({ modalVisible: false })}>
        <AddRemarks values={{ ...handleRemarks }} closeModal={() => { showHandleRemarks({ modalVisible: false }); getDataForServiceCalender() }} />
      </Modal>
    </>
  )
}
export default AssetServiceCalender;