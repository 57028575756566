import { SoStatusEnum } from '@gtpl/shared-models/common-models';
import { InvoiceDto, InvoiceItemsInfo } from '@gtpl/shared-models/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Row, Col, Form, Button } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InvoiceDetailsForm from './invoice-details';
import { useHistory, useLocation } from 'react-router-dom'



import './invoice.css';
import { PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';

/* eslint-disable-next-line */
export interface InvoiceProps {
  isUpdate: boolean
}

export function Invoice(
  props: InvoiceProps
) {
  const [invoiceForm] = Form.useForm();
  const [invFormUpdateKey, setInvFormUpdateKey] = useState<number>(1);
  const [plantInvoiceData, setPlantInvoiceData] = useState<PlantInvoiceDetailsModel>(null);
  const [invoiceItemsData, setInvoiceItemsData] = useState<any[]>([]);
  const [invoiceDto, setInvoiceDtoData] = useState<InvoiceDto>(null);
  const [revisedUnitPrice, setRevisedUnitPrice] = useState<number>(0);
  const [invoiceAmnt, setInvoiceAmnt] = useState<number>(0);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  let history = useHistory();

  const saleService = new SaleOrderService()

  let location = useLocation();
  // console.log(location.state);
  const invoiceId = location.state;

  useEffect(() => {
    if (invoiceId) {
      props.isUpdate = true;
      getInvoiceDetails(invoiceId);
      // getGrnData(grnId)
    }
  }, [invoiceId])

  const getInvoiceDetails = (invoiceId) => {
    saleService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(invoiceId)).then((res) => {

      if (res.status) {
        setPlantInvoiceData(res.data)
        setInvFormUpdateKey(prevState => prevState + 1)
      } else {

        setPlantInvoiceData(null);
      }
    }).catch((err) => {
      // AlertMessages.getErrorMessage(err.message);
      setPlantInvoiceData(null);
    });

  }

  const onReset = () => {
    invoiceForm.resetFields();
  }
// console.log(invoiceItemsData)

  const saveInvoice = () => {
    invoiceForm.validateFields().then(invRes => {
      // console.log(invRes);
      const invoiceDetails = invoiceForm.getFieldsValue();
      const itemDetailsSaveData: InvoiceItemsInfo[] = [];
      for (const soItem of invoiceItemsData) {
        const invItemsInfo: InvoiceItemsInfo = new InvoiceItemsInfo(soItem.saleOrderItemId, soItem.invoicedQty, soItem.unitPrice, soItem.invoiceAmnt, soItem.invoicedQty, soItem.taxId,soItem.invoicedCases,soItem.grossWeight)
        itemDetailsSaveData.push(invItemsInfo);
         console.log(itemDetailsSaveData)
         console.log(invItemsInfo)
      }
      const invDate = moment(invoiceDetails.invoiceDate).format('YYYY-MM-DD');
      const orderDate = moment(invoiceDetails.orderDate).format('YYYY-MM-DD');
      const shipmentDate = moment(invoiceDetails.etaDate).format('YYYY-MM-DD');
      const licenseDate = moment(invoiceDetails.epcgLicenseDate).format('YYYY-MM-DD');
      //  console.log(invDate);

      // invoiceDto.invoiceItemsInfo = itemDetailsSaveData;
      // invoiceDto.customerId = invoiceDetails.customerId;
      let invId = undefined
      let updatedUser = undefined
      // let createdUser = JSON.parse(localStorage.getItem('username'))
      if (props.isUpdate) {
        invId = plantInvoiceData.invoiceId;
        updatedUser = JSON.parse(localStorage.getItem('username'));
        // createdUser = undefined;
      }

      const invoiceDtoDetails: InvoiceDto = new InvoiceDto(invId, invoiceDetails.saleOrderId, invoiceDetails.invoiceNumber, invDate, invoiceDetails.exporterId, invoiceDetails.unitId, invoiceDetails.customerId, invoiceDetails.endCustomerId, invoiceDetails.portOfLoading, invoiceDetails.portOfDischarge, invoiceDetails.flightNumber, SoStatusEnum.OPEN, invoiceDetails.freightCharges, invoiceDetails.exchangeRate, invoiceDetails.totalAmount, invoiceDetails.totalInrAmount, itemDetailsSaveData, undefined, JSON.parse(localStorage.getItem('username')), undefined, updatedUser, undefined, invoiceDetails.isTaxApplicable,invoiceDetails.saleCommission,invoiceDetails.insurance,invoiceDetails.finNumber,invoiceDetails.payterms,invoiceDetails.destination,Number(invoiceDetails.notifyPartyOne?invoiceDetails.notifyPartyOne:null),Number(invoiceDetails.notifyPartyTwo?invoiceDetails.notifyPartyTwo:null),Number(invoiceDetails.notifyPartyThree?invoiceDetails.notifyPartyThree:null),orderDate,Number(invoiceDetails.notifyPartyFour?invoiceDetails.notifyPartyFour:null),invoiceDetails.placeOfReceipt?invoiceDetails.placeOfReceipt:null,shipmentDate,invoiceDetails.invoiceCategory,invoiceDetails.epcgLicenseNumber,licenseDate,Number(invoiceDetails.epcgFobValue),Number(invoiceDetails.exportObligation),invoiceDetails.remarks)
       console.log(invoiceDtoDetails)

      if (props.isUpdate) {
        saleService.updatePlantInvoice(invoiceDtoDetails).then((res) => {
          if (res.status) {
             console.log(res.data)
            AlertMessages.getSuccessMessage('Invoice updated successfully');
            // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
            history.push("/invoice-grid");
            // onReset();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
      } else {
        saleService.createPlantInvoice(invoiceDtoDetails).then((res) => {
          if (res.status) {

            AlertMessages.getSuccessMessage('Invoice created successfully');
            // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
            history.push("/invoice-grid");
            // onReset();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
      }

    })

  }

  const invoiceItemsdata = (invoiceItemsTableData) => {
    // setInvFormUpdateKey(prevState => prevState+1);
    setInvoiceItemsData(invoiceItemsTableData);
    console.log(invoiceItemsTableData)
  }

  const invoiceAmount = (invAmount) => {
    setInvoiceAmnt(invAmount)
  }

  // const getRevisedPrice = (revisedPrice) => {
  //   setRevisedUnitPrice(revisedPrice)
  // }
  return (
    <Card title={props.isUpdate ? "UPDATE INVOICE" : "CREATE INVOICE"} size="small">
      <InvoiceDetailsForm key={invFormUpdateKey} form={invoiceForm} invoiceItemsdata={invoiceItemsdata} invoiceAmount={invoiceAmount} isUpdate={props.isUpdate} plantInvoiceData={props.isUpdate ? plantInvoiceData : undefined} />
      <br />
      <Row justify="end">
        <Col>
          <Form.Item >
            <br />
            <Button type="primary" htmlType="submit" onClick={saveInvoice}>
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Form.Item>
        </Col>
      </Row>
    </Card>

  );
}

export default Invoice;
