import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import { HrmsEmployeeDetailsForm } from '@gtpl/pages/master/master-components/hrms-employee-details-form';
import { Excel } from 'antd-table-saveas-excel';
import './hrms-employee-details-grid.css';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';

/* eslint-disable-next-line */
export interface HrmsEmployeeDetailsGridProps {

}

export function HrmsEmployeeDetailsGrid(
  props: HrmsEmployeeDetailsGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [hrmsData, setHrmsData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const hrmsEmpDetService = new HrmsEmpDetService();
  const rolesService = new RolesService();
  const [selectedEmployeeData, setSelectedEmployeeData] = useState<any>(undefined);
  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [rolesData, setRolesData] = useState<RolesDto[]>([]);
  // const [reportData, setReportData] = useState<DtoOfRecruitmentDashboard[]>([])


  const openFormWithData = (viewData: HrmsEmployeeDetailsDto) => {
    setDrawerVisible(true);
    console.log(viewData);
    // viewData.familyDetails[0].employeeFamilyId= undefined;
    // viewData.familyDetails[0].familyMemName= undefined;
    // viewData.familyDetails[0].relation= undefined;
    viewData.dateOfBirth ? viewData.dateOfBirth = moment(viewData.dateOfBirth, 'YYYY-MM-DD') : null;
    viewData.dateOfJoin ? viewData.dateOfJoin = moment(viewData.dateOfJoin, 'YYYY-MM-DD') : null;
    viewData.dateOfEntry ? viewData.dateOfEntry = moment(viewData.dateOfEntry, 'YYYY-MM-DD') : null;
    viewData.dateOfleave ? viewData.dateOfleave = moment(viewData.dateOfleave, 'YYYY-MM-DD') : null;
    setSelectedEmployeeData(viewData);
  }

  useEffect(() => {
    getAll();
    // getAllRoles();
  }, []);

  const getAll = () => {
    hrmsEmpDetService.getAll().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllRoles = () => {
    rolesService.getAllRoles().then(res => {
      if (res.status) {
        setRolesData(res.data);
      } else {
        if (res.intlCode) {
          setRolesData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRolesData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const deleteProfile = (hrmsData: HrmsEmployeeDetailsDto) => {
    hrmsData.isActive = hrmsData.isActive ? false : true;
    hrmsEmpDetService.activatedeActivate(hrmsData).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        // AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Employee Type',
      dataIndex: 'employeeType',
      responsive: ['sm'],
      sorter: (a, b) => a.employeeType?.localeCompare(b.employeeType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeType'),
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      responsive: ['sm'],
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeCode'),

    },
    {
      title: 'Employee First Name',
      dataIndex: 'employeeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName'),

    },
    {
      title: 'Employee Last Name',
      dataIndex: 'employeeLastName',
      responsive: ['lg'],
      sorter: (a, b) => a.employeeLastName?.localeCompare(b.employeeLastName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeLastName'),

    },
    {
      title: 'Reporting Manager',
      dataIndex: 'reportingManager',
      responsive: ['lg'],
      sorter: (a, b) => a.reportingManager?.localeCompare(b.reportingManager),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('reportingManager'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      responsive: ['lg'],
      sorter: (a, b) => a.gender?.localeCompare(b.gender),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('gender'),

    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      // responsive: ['lg'],
      sorter: (a, b) => a.designation?.localeCompare(b.designation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('designation'),

    },

    {
      title: 'Department',
      dataIndex: 'department',
      // responsive: ['lg'],
      sorter: (a, b) => a.department?.localeCompare(b.department),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('department'),

    },

    // {
    //   title: 'Unit',
    //   dataIndex: 'unitId',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.unitId.localeCompare(b.unitId),
    //    sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('unitId'),

    // },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNumber',
      responsive: ['sm'],
      sorter: (a, b) => a.mobileNumber?.localeCompare(b.mobileNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mobileNumber')
    },
    // {
    //   title: 'Role',
    //   dataIndex:'employeeRole',
    //   // responsive: ['sm'],
    //   sorter: (a, b) => a.employeeRole.localeCompare(b.employeeRole),
    //    sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('employeeRole')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'InActive',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Employee');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteProfile(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Employee ?'
                : 'Are you sure to Activate Employee ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  const exportedData = [];
  console.log(exportedData)
  // const excelData = reportData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    // {title: 'Plant',dataIndex: 'Plant',},
    { title: 'Employee Type', dataIndex: 'employeeType' },
    { title: 'Employee Id', dataIndex: 'employeeId' },
    { title: 'Employee Code', dataIndex: 'employeeCode' },
    { title: 'Employee Name', dataIndex: 'employeeName', render: (value, record) => { return record.employeeName + ' ' + record.employeeLastName; } },
    { title: 'Reporting Manager', dataIndex: 'reportingManager' },
    { title: 'Gender', dataIndex: 'gender' },

    { title: 'Contractor Name', dataIndex: 'contractorName' },
    { title: 'Designation', dataIndex: 'designation' },
    { title: 'Department', dataIndex: 'department' },
    { title: 'Mobile Number', dataIndex: 'mobileNumber', render: (text, record) => { return record.mobileNumber ? record.mobileNumber : '-' } },
    // { title: 'Aadhar Number', dataIndex: 'aadhaarNumber', render: (text, record) => { return record.aadhaarNumber ? JSON.parse(record.aadhaarNumber) : '-' } },
    { title: 'Aadhaar Number', dataIndex: 'aadhaarNumber', render: (value, record) => { return record.aadhaarNumber ? Number(record.aadhaarNumber).toLocaleString() : '-' } },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('sheet1')
      .addColumns(data)
      .addDataSource(employeeData, { str2num: true })
      .saveAs('employeeDetails.xlsx');
  }

  const updateProduct = (hrmsData: HrmsEmployeeDetailsDto) => {
    hrmsEmpDetService.update(hrmsData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Employee Details</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to="/HRMS-employee_details-form"  ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}>
      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total Employees: ' + employeeData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + employeeData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active: ' + employeeData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
        <Col>
          {<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.productId}
        columns={columnsSkelton}
        dataSource={employeeData}
        scroll={{ x: true }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <HrmsEmployeeDetailsForm key={Date.now()}
            updateDetails={updateProduct}
            isUpdate={true}
            hrmsData={selectedEmployeeData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default HrmsEmployeeDetailsGrid;
