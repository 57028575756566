import React, { useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col} from 'antd';
import { EducationDto, AllEducationResponseModel, EducationResponseModel } from '@gtpl/shared-models/hrms';
import { EducationService } from '@gtpl/shared-services/hrms';
import { Link, useHistory } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

import './education.css';

/* eslint-disable-next-line */
export interface EducationProps {
  educationData: EducationDto;
  updateData: (educationData: EducationDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function Education(
  props: EducationProps
) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const service = new EducationService;
  let history = useHistory()
  let createdUser = "";
  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }
  
  const create = (data: EducationDto) =>{
    setDisable(true)
    service.createEducation(data).then(res => {
      setDisable(false)
      if(res.status){
        AlertMessages.getSuccessMessage('Education Created Successfully');
        history.push('/educations-view')
        onReset();
      }else{
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  };

  const saveData = (values: EducationDto) => {
    setDisable(false)
    if(props.isUpdate){
      props.updateData(values);
    }else{
      setDisable(false)
      create(values);
    }
  };

  return (
    <Card title={<span style={{color:'white'}}>Education</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/educations-view' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.educationData} >
        <Form.Item 
        name ="educationId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="education"
                label="Education"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Education."
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
  </Card>
  );
}

export default Education;
