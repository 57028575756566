import { AnalyticsUnitReq } from '@gtpl/shared-models/analytics-data';
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useState, useEffect, PureComponent } from 'react';
import './warehouse-dashboard.css';

export interface RecentStockInProps { }

export function RecentStockIn(props: RecentStockInProps) {
    const [page, setPage] = React.useState(1);
    const [stockInData, setStockInData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getrecentFiveStockIns();
    }, [])

    const getrecentFiveStockIns = () => {
        service.getrecentFiveStockIns({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockInData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const dateTOAMORPM = (currentDateTime) => {
        const hrs = currentDateTime.getHours();
        const mnts = currentDateTime.getMinutes();
        const AMPM = hrs >= 12 ? 'PM' : 'AM';
        currentDateTime.hrs = hrs % 12;
        currentDateTime.hrs = hrs ? hrs : 12;
        currentDateTime.mnts = mnts < 10 ? '0' + mnts : mnts;
        const result = hrs + ':' + mnts + ' ' + AMPM;
        return result;
    }
    console.log(dateTOAMORPM(new Date()));

    // const hours = new Date().getHours();
    // const ampm = (hours >= 12) ? "PM" : "AM"
    // console.log(ampm, 'AM OR PM')


    const now = new Date();
    const hours = now.getHours() % 24 || 24
    console.log(hours,'am or pm ')

    const columns: ColumnProps<any>[] = [
        {
            title: 'Unit',
            dataIndex: 'unitCode',
            width: '70px'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: "100px",
            align: 'center',
            render: (value, record) => {
                return moment(record.date).format('YYYY-MM-DD HH:MM');
            }
        },
        {
            title: 'Sale Order',
            dataIndex: 'poNumber',
        },
        {
            title: 'Product',
            dataIndex: 'variantCode',
        },
        {
            title: 'Cartons',
            dataIndex: 'reportedCases',
        },
        {
            title: 'Rack Position',
            dataIndex: 'rackPositionCode',
        },
    ]
    return (
        <div>
            <Card title={<span style={{ color: 'black' }}>Recent 5 Stock In</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: 'white', border: 0 }}  >
                <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={stockInData}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
            </Card>
        </div>
    )
}
export default RecentStockIn;