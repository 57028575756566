import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin, Input } from 'antd';
import './documents.css';
import { ContainerLoadingStatus, GlobalStatus, InvoiceCategoriesEnum, TaxCategoriesEnum, UomEnum } from '@gtpl/shared-models/common-models';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest, FactoriesInput, UnitsOfWeightInput, CrrencySymbols } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons'
let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { ToWords } from 'to-words';
import { SaleOrderDetails } from '@gtpl/pages/sale-management/sale-components/sale-order-detail-view-grid';
import stampImage from "./stamp.png";
import signature from "./signature.png"

const toWords = new ToWords({
  localeCode: 'en-US',
});

/* eslint-disable-next-line */
export interface CusInvoiceProps {
  saleOrderId: number;
}

export function CusInvoice(props: CusInvoiceProps) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;
  const [remarksData, setRemarksData] = useState<string>(undefined);
  const { TextArea } = Input;
  const defaultDeclaration = 'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct'


  let totalCases = 0;
  let totalLbWeight = 0;
  let totalGrossWeight = 0;
  let netWeightInKgs = 0;
  let totalAmount = 0;
  let itemsTotalAmount = 0;
  let FOBInRs = 0;
  let FOBInUSD = 0;
  let cgst = 0;
  let sgst = 0;
  let igst = 0;
  let cgstPer = 0;
  let sgstPer = 0;
  let igstPer = 0;
  let lbPouchWeight
  let kgPouchWeight
  let declarationNote

  console.log(igst)
  const getData = (saleOrderId) => {
    const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(saleOrderId)
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  console.log(plantDetails)
  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print()
  }, [props.saleOrderId])
  let exporterDetails = ExporterDataInput;
  let exporterId = 1;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
  );
  const handleRemarks = (value) => {
    console.log(value);
    console.log(value.target.defaultValue)
    setRemarksData(value);
  }
  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };
  // return 'test';
  return (
    <div>
      {/* <span style={{alignContent:'left', backgroundColor:'red'}}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="CustomsInvoice"
              sheet="CustomsInvoice"
              buttonText="Get Excel" />
            </span> */}
      <br />
      {plantDetails ?
        <html>
          <body id="printme">
            <br></br>
            <table className={'ta-b'} style={{ width: '100%' }}>
              <tr><td className={'ta-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '10px', lineHeight: '12px', paddingTop: '8px' }}><h1>INVOICE CUM PACKING LIST</h1>
                {plantDetails?.isTaxApplicable === "YES" ? (<><br /><h2>(SUPPLY MEANT FOR EXPORT WITH PAYMENT OF IGST)</h2></>) : ''}
              </td></tr>
              <tr>
                <td className={'ta-b'} colSpan={2} rowSpan={1}>
                  Exporter: <br />
                  <h4 style={{ display: 'inline' }}><b>{(exporterData?.name) ? exporterData?.name : ''}</b></h4><br />
                  {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                  <br />
                  {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                  <br />
                  {exporterData?.state + ', ' + exporterData?.country}
                  <br />GSTIN:
                  {exporterData?.GSTNumber}
                  <br />
                </td>
                <td className={'padding'} colSpan={1}>
                  Invoice No.
                  <b>{plantDetails?.invoiceNumber}</b><br />
                  Date:
                  {<b>{moment(plantDetails?.invoiceDate).format('DD-MMM-YYYY')}</b>}
                </td>

                <td className={'padding'} colSpan={1}>
                  Buyer's Order No.
                  PO NO. <b>{plantDetails.custPoNo}</b>
                  <h4>Date: <b>{plantDetails?.actualEdl ? (moment(plantDetails?.actualEdl).format('DD-MMM-YYYY')) : null}</b>
                  </h4>
                </td>

                <td colSpan={4} className={'padding'}>
                  Exporter's Ref<br />
                  IE Code  <b>{exporterData?.ieCode}</b>
                </td>
              </tr>

              {/* <tr>
                <td colSpan={2} className={'padding'}>
                  Buyer's Order No.
                  PO NO. <b>{plantDetails.custPoNo}</b>
                  <h4>Date: <b>{plantDetails?.actualEdl ? (moment(plantDetails?.actualEdl).format('DD-MMM-YYYY')) : null}</b>
                  </h4>
                </td>
              </tr> */}

              <tr>
                <td className={'ta-b'} colSpan={3} rowSpan={plantDetails?.notifyPartyThree ? 2 : 1} >
                  PROCESSED & PACKED AT <br />
                  <h4 style={{ display: 'inline' }}><b>{(exporterData?.name) ? exporterData?.name : ''}</b></h4><br />
                  {plantDetails?.invoicedPlant?.villageName + ", " + plantDetails?.invoicedPlant?.mandalName}<br />
                  {plantDetails?.invoicedPlant?.postalCode + ", " + plantDetails?.invoicedPlant?.distirctName + ", " + plantDetails?.invoicedPlant?.stateName + ", " + plantDetails?.invoicedPlant?.countryName}<br />
                  FDA REGISTRATION NUMBER :{plantDetails?.invoicedPlant?.fdaRegNumber}
                  <br />
                  {plantDetails?.invoicedPlant?.feiNumber ?
                    <>FEI NUMBER:{plantDetails?.invoicedPlant?.feiNumber}</> : <></>}
                </td>
                {
                  (plantDetails.notifyPartyOne) ?
                <td colSpan={plantDetails?.notifyPartyOne ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start' }}>
                  <u>
                    Notify party 1</u>
                  <br />
                  <pre>
                    <b>{plantDetails?.notifyPartyOne?.split("\n")[0]}</b><br />
                    {plantDetails?.notifyPartyOne?.split("\n")[1]}<br />
                    {plantDetails?.notifyPartyOne?.split("\n")[2]}<br />
                    {plantDetails?.notifyPartyOne?.split("\n")[3]}</pre><br />
                  {/* {(plantDetails?.notifyPartyOne) ? plantDetails?.notifyPartyOne : ''} */}

                </td> : <></>
                
                } 
                {
                  (plantDetails.notifyPartyTwo) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start', width: '50%' }}>
                      <u>
                        Notify party 2</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyTwo?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyTwo?.split("\n")[3]}</pre><br /><br />

                    </td> : <></>
                }
              </tr>
              <tr >
                {
                  (plantDetails.notifyPartyThree) ?
                    <td colSpan={plantDetails.notifyPartyFour ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start' }}>
                      <u>
                        Notify party 3</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyThree?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyThree?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyThree?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }
                {
                  (plantDetails.notifyPartyFour) ?
                    <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start' }}>
                      <u>
                        Notify party 4</u>
                      <br />
                      <pre>
                        <b>{plantDetails?.notifyPartyFour?.split("\n")[0]}</b><br />
                        {plantDetails?.notifyPartyFour?.split("\n")[1]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[2]}<br />
                        {plantDetails?.notifyPartyFour?.split("\n")[3]}</pre><br /><br />
                    </td>
                    : <></>
                }

              </tr>

              <tr >
                <td colSpan={3} rowSpan={2} className={'ta-b'}>
                  Consignee:<br />
                  <b>AZ GEMS INC:</b><br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[3]}<br />
                  FDA REGISTRATION NO.{unitsData?.consigneefdaRegNumber}
                </td>

                <td colSpan={6} className={'ta-b'} >
                  Importer on record:
                  <br />
                  <b>AZ GEMS INC</b><br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
                  <br />FDA REGISTRATION NO.{unitsData?.consigneefdaRegNumber}
                </td>
              </tr>
              <tr>
                <td colSpan={3} className={'ta-b'}>
                  Country of Origin of Goods:<br />
                  <b>INDIA</b>
                </td>
                <td colSpan={5} className={'ta-b'}>
                  Country of Final Destination:<br />
                  <b>{plantDetails?.country}</b>{' '}

                </td>
              </tr>


              <tr>
                <td className={'ta-b'} colSpan={2}>
                  Pre Carriage By: Road
                </td>
                <td className={'ta-b'} colSpan={1}>
                  Place of Receipt of Pre-carrier: {(plantDetails?.placeOfReceipt) ? plantDetails?.placeOfReceipt : ' '}
                </td>
                <td colSpan={5} className={'ta-b'}>
                  Terms of Delivery and Payment: {plantDetails?.saleOrderItems[0]?.paymentTerms}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={2}>
                  Vessel / Flight No.
                  {/* {plantDetails?.vesselName} */}
                </td>
                <td className={'ta-b'} colSpan={1}>
                  Port of Loading: <br />
                  <b>{plantDetails?.portofloading ? (plantDetails?.portofloading)?.toUpperCase() + ', INDIA' : ''}</b>
                </td>

                <td colSpan={5} rowSpan={2} className={'ta-b'}>
                  Container No.&nbsp;
                  {/* {plantDetails?.saleOrderItems[0]?.containerNo} */}
                  <br />
                  Liner Seal No.&nbsp;
                  {/* {plantDetails?.saleOrderItems[0]?.linearSelaNo} */}
                  <br />
                  Bill of Lading No.&nbsp;
                  {/* {plantDetails?.saleOrderItems[0]?.billOfLadingno}<br/><br/><br/> */}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={2}>
                  Port of Discharge:
                  <b>{(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() + ", " : '') + (plantDetails?.country ? plantDetails?.country : '')}</b>
                </td>
                <td className={'ta-b'} colSpan={1}>
                  Final Destination:
                  <b>{(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails)?.toUpperCase() + ", " : '') + (plantDetails?.country ? plantDetails?.country : '')}</b>
                </td>
              </tr>

              <tr>
                <td colSpan={1} style={{ textAlign: 'center', fontSize: 10 }}>
                  Marks & Nos / No & Kind of Pkgs
                </td>
                <td colSpan={1} style={{ textAlign: 'center' }}>

                </td>
                <td style={{ textAlign: 'left', paddingLeft: '55px' }} colSpan={2}>
                  Description of Goods
                </td>

                <td style={{ textAlign: 'center' }}>
                </td>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Quantity <br />
                  IN KGS <br /> NET WEIGHT
                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Rate
                  PER KG
                  ({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  <br />{plantDetails?.shipmentTerms}
                  <br />{plantDetails?.saleOrderItems[0]?.destinationDetails}
                </th>
                <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                  Amount
                  <br />

                  ({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  <br />{plantDetails?.shipmentTerms}
                  <br />{plantDetails?.saleOrderItems[0]?.destinationDetails}
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                </th>
                <th colSpan={1} style={{ textAlign: 'center' }}>
                  HSN Code
                </th>
                <th style={{ textAlign: 'left', width: '54%' }} colSpan={2}>
                  Size
                </th>
                <th style={{ textAlign: 'left', width: '30%' }}>
                  No Of Cartons
                </th>
              </tr>
              {plantDetails?.saleOrderItems?.map(item => {
                const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                let finaItemslNetWeight;
                let itemUnitPrice;
                if (plantDetails.isInvoiced == GlobalStatus.YES) {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    finaItemslNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                    itemUnitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                  } else {
                    finaItemslNetWeight = Number(item.invoicedQty).toFixed(3);
                    itemUnitPrice = Number(item.revisedUnitPrice).toFixed(5);
                  }
                }
                itemsTotalAmount += Number(itemUnitPrice) * Number(finaItemslNetWeight);

              })}

              {
                plantDetails?.saleOrderItems?.map(item => {
                  console.log(itemsTotalAmount)



                  netWeightInKgs += Number(item.netWeight) * 0.454;
                  let freightCharges = plantDetails?.freightCharges === undefined ? 0 : plantDetails?.freightCharges;
                  let exchangeRate = plantDetails?.exchangeRate === undefined ? 0 : plantDetails?.exchangeRate;

                  console.log("freightCharges" + freightCharges);
                  totalCases += item.invoicedCases
                    ;
                  const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                  let finalNetWeight;
                  let unitPrice;
                  let invUnitPrice;


                  if (plantDetails.isInvoiced == GlobalStatus.YES) {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      finalNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                      unitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                      console.log(finalNetWeight)
                    } else {
                      finalNetWeight = Number(item.invoicedQty).toFixed(3);
                      unitPrice = Number(item.revisedUnitPrice).toFixed(5);
                      console.log(finalNetWeight)
                    }   
                    if (plantDetails.totalAmount > Number(itemsTotalAmount)) {

                      let totalAm = Number(((unitPrice) * (finalNetWeight)).toFixed(2))
                      let deciamlValue = (Number(totalAm.toLocaleString().slice(Number(totalAm.toLocaleString().length) - 2)))
                      // if (plantDetails.saleOrderItems.length > 1) {
                      
                      //   do {
                      //     unitPrice = Number(Number(unitPrice) + Number(0.00001)).toFixed(5)
                      //   }
                      //   while ((plantDetails.totalAmount) <= Number(itemsTotalAmount));


                      // } else {
                      //   unitPrice = Number(Number(unitPrice) + Number(0.00001)).toFixed(5)
                      // }
                    }


                  }
                  else {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      finalNetWeight = (Number(item.netWeight) * 0.454).toFixed(3);
                      unitPrice = (Number(item.unitPrice) / 0.454).toFixed(5);
                    } else {
                      finalNetWeight = Number(item.netWeight).toFixed(3);
                      unitPrice = Number(item.unitPrice).toFixed(5);
                    }
                  }
                  invUnitPrice = Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice)?.toFixed(5)
                  if (plantDetails?.country === 'CANADA') {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      invUnitPrice * 0.454
                    } else {
                      invUnitPrice
                    }
                  } else if (plantDetails?.country === 'USA') {
                    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      invUnitPrice * 0.454
                    } else {
                      invUnitPrice
                    }
                  }

                  totalLbWeight += Number(finalNetWeight);
                  totalAmount += (Number(invUnitPrice) * Number(finalNetWeight));
                  let insurance = 0;
                  if (plantDetails?.isInvoiced == GlobalStatus.YES) {
                    if (plantDetails?.insurance) {
                      insurance += plantDetails?.insurance;
                    }
                  }
                  // FOBInRs = totalAmount - freightCharges - insurance;
                  // FOBInUSD = (totalAmount - freightCharges - insurance) * exchangeRate;
                  FOBInRs = (totalAmount - freightCharges) * exchangeRate;
                  FOBInUSD = (totalAmount - freightCharges);
                  totalGrossWeight += (Number(item.grossWeightPerKg) * Number(item.invoicedCases));
                  lbPouchWeight = Number(item.pouchWeight)
                  kgPouchWeight = Number(item.pouchWeight)
                  if (uom.name == UomEnum.OZ) {
                    lbPouchWeight = lbPouchWeight * 0.0625
                  }
                  if (uom.name == UomEnum.Gms) {
                    kgPouchWeight = kgPouchWeight * 0.001
                  }
                  if (plantDetails.isTaxApplicable === "YES") {
                    if (item.taxCategory === TaxCategoriesEnum.State) {
                      cgst += (Number(((unitPrice) * (finalNetWeight)) * plantDetails?.exchangeRate) * Number(item.taxPer) / 2) / 100;
                      sgst += (Number(((unitPrice) * (finalNetWeight)) * plantDetails?.exchangeRate) * Number(item.taxPer) / 2) / 100;
                      igst += 0;
                      cgstPer = Number(item.taxPer) / 2;
                      sgstPer = Number(item.taxPer) / 2;
                      igstPer = 0;
                      declarationNote = true;

                    } else if (item.taxCategory === TaxCategoriesEnum.Central) {
                      cgst += 0;
                      sgst += 0;
                      igst += (Number(((unitPrice) * (finalNetWeight)) * plantDetails?.exchangeRate) * Number(item.taxPer)) / 100;
                      cgstPer = 0;
                      sgstPer = 0;
                      igstPer = Number(item.taxPer);
                      declarationNote = false;
                    } else {
                      cgst += 0;
                      sgst += 0;
                      igst += 0
                      cgstPer = 0;
                      sgstPer = 0;
                      igstPer = 0;
                    }
                  }
                  else {
                    cgst = 0;
                    sgst = 0;
                    igst = 0;
                    cgstPer = 0;
                    sgstPer = 0;
                    igstPer = 0;
                    declarationNote = true;
                  }
                  let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" ? "PENAEUS MONODON" : "";
                  // let shrimp = item.itemName.toUpperCase() === "VANNAME"?"SHRIMP":"";
                  let shrimp = "SHRIMPS";
                  let hasNumber = /\d/;
                  let normalOrder = hasNumber.test(plantDetails?.buyerPoNo);
                  let lotRefString = '';
                  let lotNo = '';
                  let RefNo = '';
                  if (normalOrder) {
                    if (plantDetails?.lotCode) {
                      lotNo = 'LOT NO. ' + plantDetails?.lotCode + ', '
                    }
                    if (plantDetails?.buyerPoNo) {
                      RefNo = 'REF NO. ' + plantDetails?.buyerPoNo
                    }
                    lotRefString = lotNo + RefNo
                  }
                  console.log(item.foodTypeName.toUpperCase() + ' ' + item.valueAdditionTwoIdName.toUpperCase());
                  // let foodType = item.foodTypeName?item.foodTypeName.toUpperCase():" ";
                  return (
                    <><tr style={{ border: '0px' }}>
                      <td style={{ textAlign: 'center' }}>{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}<br />
                        <span style={{ textAlign: 'center' }}>{Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</span><br /><span style={{ textAlign: 'center' }}>{(uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? <>{(Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'}</> : <></>}</span></td>
                      <td style={{ textAlign: 'center' }}>{item.hsnCode}</td>
                      <td colSpan={2} style={{ textAlign: 'left', alignContent: 'center' }}>
                        {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                          (!(([
                            'HLSO', 'HOSO', 'EZPeel', 'N/A'
                          ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                        ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')},{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ', ' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')} ,
                        {'LATIN NAME : ' + latinName.toLocaleUpperCase('en-US') + ' '} ,<br />
                        {' BRAND : ' + item.displayBrandName?.toLocaleUpperCase('en-US')} ,
                        {lotRefString}
                        {plantDetails?.finNumber ? ('FPO#/FIN# ' + (plantDetails?.finNumber)) : ''},
                        {item.minGrade + '/' + item.maxGrade} .
                        
                        {/* {' BRAND : ' + item.brandName.toUpperCase()} */}
                      </td>
                      <td style={{ textAlign: 'right' }}>{item.invoicedCases}</td>
                      <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(finalNetWeight) ? Number(finalNetWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
                      <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(invUnitPrice)}</td>
                      <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{((invUnitPrice) * (finalNetWeight)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'center' }} ></td>
                      {/* <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                      <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td> */}
                      {/* <td className={'ta-b'} style={{textAlign:'center', borderTop:'0px', borderBottom:'0px'}}></td> */}
                    </tr>
                      {/* <tr>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'left' }} colSpan={2}>
                          {item.minGrade + '/' + item.maxGrade}
                          </td>
                        <td style={{ textAlign: 'right' }}>{item.invoicedCases}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(finalNetWeight) ? Number(finalNetWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(unitPrice)}</td>
                        <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{((unitPrice) * (finalNetWeight)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr> */}
                    </>
                  )
                }
                )
              }
              <></>
              <tr>
                <td className={'ta-b'} colSpan={4} style={{ textAlign: 'center' }}>
                  <b> Total</b>
                </td>
                <td colSpan={1} className={'ta-b'} style={{ textAlign: 'right' }}><b>
                  {totalCases}</b>
                </td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}><b>
                  {(totalLbWeight) ? (Number(totalLbWeight)).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</b>
                </td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
                <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              </tr>

              <tr>
                <td colSpan={5} style={{ borderBottom: '1px' }} >
                  <b>Amount chargeable </b><br /> (in words):  <b> U.S {(totalAmount) ? (toWords.convert(totalAmount, { currency: true }).toUpperCase()) : ""}</b>
                  {/* <b>Amount chargeable </b><br /> (in words):  <b> U.S. DOLLARS {(totalAmount) ? (toWords.convert(totalAmount, { currency: true })).replace("Rupees", "").replace("Paise", "Cents").toUpperCase() : ""}</b> */}
                </td>
                <td colSpan={2} style={{ textAlign: 'left', borderBottom: '0px' }}>
                  <b>Total USD</b>
                </td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}><b>{Number(totalAmount).toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b></td>
              </tr>

              {/* <tr>
                <td colSpan={6}>
                  <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                    <td className={'ta-b'} colSpan={2}>
                      <h4><b>EPCG Licence No : {(plantDetails?.epcgLicenseNumber) ? plantDetails?.epcgLicenseNumber : ''}</b></h4>
                    </td>
                    <td className={'ta-b'} colSpan={2}>
                      <h4><b>Licence Date : {moment(plantDetails?.epcgLicenseDate).format('DD-MMM-YYYY')}</b></h4>
                    </td>
                    <td className={'ta-b'} colSpan={4}>
                      <h4><b>FOB VALUE IN USD : {(plantDetails?.epcgFobValue) ? plantDetails?.epcgFobValue : ''}</b></h4>
                    </td>
                    <td className={'ta-b'} colSpan={4}>
                      <h4><b>Export Obligation in USD : {(plantDetails?.exportObligation) ? plantDetails?.exportObligation : ''}</b></h4>
                    </td>
                  </table>
                </td>
              </tr>

              <br></br>
              <br></br>
              <br></br> */}
              <tr>
                <td></td>
                <td colSpan={6}>
                  <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                    {
                      plantDetails?.saleCommission ?
                        <tr>
                          <td className={'ta-b'} colSpan={2}>
                            <h4><b>SALE COMMISION IN USD {plantDetails?.saleCommission}</b></h4>
                          </td>
                        </tr> : ''
                    }
                    {
                      plantDetails?.insurance ?
                        <tr>
                          <td className={'ta-b'} colSpan={2}>
                            <h4><b>Insurance IN USD {plantDetails?.insurance}</b></h4>
                          </td>
                        </tr> : ''
                    }
                    <tr>
                      {plantDetails?.shipmentTerms == 'FOB' ? '' :
                        <td className={'ta-b'} colSpan={2}>

                          <h4><b>FREIGHT IN USD {plantDetails?.saleOrderItems[0]?.currencyDetails + " : " + plantDetails?.freightCharges === undefined ? 0 : plantDetails?.freightCharges}</b></h4>
                        </td>
                      }
                      <td className={'ta-b'} colSpan={2}>
                        <h4><b>FOB VALUE IN RS. {FOBInRs.toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b></h4>
                      </td>
                      <td className={'ta-b'} colSpan={4}>
                        <h4><b>FOB VALUE IN {plantDetails?.saleOrderItems[0]?.currencyDetails + " : "}
                          {FOBInUSD.toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b></h4>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8} className={'ta-b'}>
                        <h4>
                          <b> EXCHANGE RATE: 1.00 (USD) =  {plantDetails?.exchangeRate === undefined ? "" : plantDetails?.exchangeRate + " (INR)"}</b>
                        </h4>
                      </td>
                    </tr>
                  </table>
                  <br />
                </td>
                <td className={'ta-b'} style={{ borderTop: '0px', borderBottom: '0px', borderLeft: '0px' }}></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={7}>
                  <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                    <tr>
                      {
                        (declarationNote == true) ?
                          <td rowSpan={2} className={'ta-b'} style={{ borderBottom: '0px' }} colSpan={4}>
                            <b><u>Declaration</u>:</b><br />
                            <span><b>Supply Meant for Export Under Bond or Letter of Undertaking without Payment of Integrated Tax (IGST)</b></span>
                          </td> : ''
                      }
                      <td className={'ta-b'} style={{ textAlign: 'left', width: '25%' }}>
                        &nbsp;
                        <h4><b>Total Amount Before Tax :</b></h4>
                        CGST @ {cgstPer}% :<br />
                        SGST @ {sgstPer}% :<br />
                        IGST @ {igstPer}%:<br />
                        <h4><b>Total GST Amount :</b></h4>
                      </td>
                      <td style={{ textAlign: 'right', width: '20%' }} className={'ta-b'} colSpan={3}>
                        <b style={{ textAlign: 'right' }}>Currency ({(plantDetails?.isTaxApplicable == 'YES') ? 'INR' : plantDetails?.saleOrderItems[0]?.currencyDetails})</b>
                        <h4><b>{(plantDetails?.isTaxApplicable == 'YES') ? (Number(totalAmount.toFixed(2)) * (plantDetails?.exchangeRate)).toFixed(2) : totalAmount.toFixed(2)}</b></h4>
                        {Number(cgst).toLocaleString(plantDetails?.isTaxApplicable == 'YES' ? 'en-IN' : CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                        {Number(sgst).toLocaleString(plantDetails?.isTaxApplicable == 'YES' ? 'en-IN' : CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                        {Number(igst).toLocaleString(plantDetails?.isTaxApplicable == 'YES' ? 'en-IN' : CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                        {(Number(cgst.toFixed(2)) + Number(sgst.toFixed(2)) + Number(igst.toFixed(2))).toLocaleString(plantDetails?.isTaxApplicable == 'YES' ? 'en-IN' : CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td className={'ta-b'}>
                        <h4><b>Total Amount After Tax :</b></h4>
                      </td>

                      <td style={{ textAlign: 'right' }} className={'ta-b'} colSpan={3}>
                        <b>{((plantDetails?.isTaxApplicable == 'YES' ? (Number(totalAmount.toFixed(2)) * plantDetails?.exchangeRate) : Number(totalAmount.toFixed(2))) + Number(cgst.toFixed(2)) + Number(sgst.toFixed(2)) + Number(igst.toFixed(2))).toLocaleString(plantDetails?.isTaxApplicable == 'YES' ? 'en-IN' : CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails)?.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} colSpan={8} style={{ borderLeft: '0px', borderBottom: '0px', borderTop: '0px' }} >
                  {plantDetails.invoiceCategory == InvoiceCategoriesEnum.EPCG ?
                    <>"I/We, hereby, declare that I/We shall claim the benefits under Customs Notification No.07 / 2020 - Customs (N.T)
                      Dated 28-01-2020, as well as Chapter 3 of FTP as applicable."
                      FISH, CRUSTACEANS, MOLLUSCES, AQUATIC INVERTEBRATES
                      In frozen form other than AFD
                      <b>This Shipment is made under DUTY DRAW BACK SCHEME AS WELL AS UNDER MEIS SCHEME.</b>
                      THE GOODS ARE NOT PRODUCED FROM A 100% E.O.U. UNIT
                      ITC HS CODE : 0306 / 1605, DRAW BACK SCHEME CODE : 19, SL NO. OF EXPORT PRODUCT FOR RAW SHRIMPS: 0306 / 1605,
                      RATE OF ENTITLEMENT : 3.00% ON FOB OR Rs.23.60 / KG CAP
                    </> : <><b>This Shipment is made under DUTY DRAW BACK SCHEME AS WELL AS UNDER RoDTEP SCHEME.</b><br />
                      I/We, in regard to my/our claim under RoDTEP scheme made in this Shipping Bill or Bill of Export, hereby declare that:<br />
                      1. I/ We undertake to abide by the provisions, including conditions, restrictions, exclusions and time-limits as provided under RoDTEP scheme, and relevant notifications,
                      regulations, etc., as amended from time to time.<br />
                      2. Any claim made in this shipping bill or bill of export is not with respect to any duties or taxes or levies which are exempted or
                      credited under any other mechanism outside RoDTEP.<br />
                      3. I/We undertake to preserve and make available relevant documents relating to the exported goods for the purposes of audit in the manner and for the time period prescribed in the Customs Audit Regulations, 2018.<br /></>}
                </td>
              </tr>
              {/* <tr>
                <td style={{ width: '20%' }} className={'ta-b'}></td>
                <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  <h4>IN LBS</h4>
                </td>
                <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  <h4>IN KGS</h4>
                </td>
                <td style={{ width: '30%' }}></td>
                <td className={'ta-b'} colSpan={4} style={{ width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}></td>
              </tr> */}
              <tr>
                <td style={{ width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  {' '}
                  <b>TOTAL NET WEIGHT:{' '}</b>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                  {/* <h4>{(totalLbWeight / 0.454).toFixed(3)}</h4> */}
                  <h4>IN LBS : <br/>
                  {Math.round(totalLbWeight / 0.454).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                  {/* <h4>{(netWeightInKgs).toFixed(3)}</h4> */}
                  <h4>IN KGS : <br/>
                  {(totalLbWeight).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                  {/* <h4>{(netWeightInKgs).toFixed(3)}</h4> */}
                </td>
                <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                  <b>TOTAL NO. OF MASTER CARTONS:</b>
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}>
                  <h4>{totalCases}</h4>
                </td>
              </tr>
              <tr>
                <td style={{ width: '20%', borderTop: '0px' }} className={'ta-b'}>
                  {' '}
                  <b> TOTAL GROSS WEIGHT:</b>
                </td>
                <td style={{ textAlign: 'right', width: "20%", borderTop: '0px' }} className={'ta-b'}>
                  {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                  <h4>{Math.round((totalGrossWeight / 0.454) ? (totalGrossWeight / 0.454) : 0 ).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td style={{ textAlign: 'right', width: '20%', borderTop: '0px' }} className={'ta-b'}>
                  <h4>{(Math.round(totalGrossWeight ? totalGrossWeight : 0 )).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                </td>
                <td colSpan={3} style={{ width: '20%', textAlign: 'center' }}>
                  <b>TOTAL AMOUNT (IN {plantDetails?.saleOrderItems[0]?.currencyDetails}):</b>
                </td>
                <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderBottom: '0px', borderLeft: '0px' }}  >
                  <h4>{Number(totalAmount).toLocaleString(CrrencySymbols.find(item => item.name == plantDetails?.saleOrderItems[0]?.currencyDetails).locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                </td>
              </tr>
              {/* </table>
                      <table style={{width:'100%'}} id="table-to-xls"> */}
              <tr>
                <td colSpan={4} id='inputText'><b>Declaration :</b>
                  <TextArea style={{ outline: 'none', border: 'none' }} rows={3} contentEditable={true} defaultValue={defaultDeclaration}
                    onChange={(evt) => handleRemarks(evt)}
                  ></TextArea>
                  {plantDetails?.remarks?.length > 0 ? <>
                    <h4><b>REMARKS : {(plantDetails?.remarks) ? plantDetails?.remarks : ''}</b></h4>
                  </> : ''}
                </td>
                {/* <td colSpan={4}>
                  <h4><b>REMARKS : {(plantDetails?.remarks) ? plantDetails?.remarks : ''}</b></h4>
                </td> */}
                <td colSpan={4} id='stamp' className={'ta-b'} style={{ textAlign: 'left' }}>
                  <div className='stamp-and-print'>
                    <b>Signature & Date For:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h4>{exporterData?.name}</h4><br />
                    <h4>AUTHORISED SIGNATORY</h4>
                  </div>
                </td>
              </tr>
            </table>
          </body>
        </html>


        : <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height: '100' }}>CUSTOMS INVOICE</span>}
          // extra={<> <Button href="/#/document-register" style={{color:'black'}}>Back</Button></>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}><span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span></Card>}

    </div>
  );
}


export default CusInvoice;
