import React, { useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {RejectionReasonDto}  from '@gtpl/shared-models/masters';
import {GrnRejectionReasonService} from '@gtpl/shared-services/masters';

import './grn-rejection-reasons-form.css';

const { TextArea } = Input;
/* eslint-disable-next-line */
export interface GrnRejectionReasonsFormProps {

  rejectionreasonData: RejectionReasonDto;
  updateReason:(rejectionreason:RejectionReasonDto)=>void;
 isUpdate:boolean;
closeForm: () => void;
}

export function GrnRejectionReasonsForm(
  props: GrnRejectionReasonsFormProps
) {
  const [disable, setDisable] = useState<boolean>(false)
  const [form] = Form.useForm();
  const service = new GrnRejectionReasonService;
  let history = useHistory();

  const save = (Data: RejectionReasonDto) => {
    Data.createdUser = localStorage.getItem('username')
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
     AlertMessages.getSuccessMessage('Created Successfully');
         
     history.push("/grn-rejection-reason-view")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   AlertMessages.getErrorMessage(err.message);
 })
}

const saveData = (values: RejectionReasonDto) => {
  setDisable(false)
  // console.log(values);
  if(props.isUpdate){
    props.updateReason(values);
  }else{
    setDisable(false)
    save(values);
  }

};
const onReset = () => {
  form.resetFields();
};

  return (
    <Card title={<span style={{color:'white'}}>GRN Rejection Reason</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/grn-rejection-reason-view' ><span style={{color:'white'}} >  {(props.isUpdate===false) &&
          <Button className='panel_button' >View </Button>
           }  </span></Link>} >
    
    
          <Form form={form} initialValues={props.rejectionreasonData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="rejectionReasonId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.rejectionreasonData}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:6,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="rejectionReasonCode"
                      label="Rejection Reason Code"
                      rules={[
                        {
                          required: true,
                          
                          message: 'Rejection Reason Code required'

                        },
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                          message: `Invalid Rejection Reason Code`
                        }
                      ]}
                      >
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="rejectionReason"
                    label="Rejection Reason"
                    rules={[
                      {
                        required: true,
                       
                        message: 'Rejection Reason required'

                      },
                      {
                        pattern:  /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                        message: `Invalid Rejection Reason`
                      }
                    ]}>
                      <Input/>
                    
                  </Form.Item>
            </Col>
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="remarks"
                    label="Remarks"
                    >

                      
                      <TextArea rows={1} />
                    
                  </Form.Item>
            </Col>
          
            
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
  );
}

export default GrnRejectionReasonsForm;
