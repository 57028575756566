import React, { Fragment, useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  CrrencySymbols,
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  SaleOrderDetailViewInfoDTO,
  UnitsOfWeightInput,
} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv';
import moment from 'moment';

/* eslint-disable-next-line */
export interface ShipmentDetailsProps {
  saleOrderId: number;
  soData: SaleOrderDetailViewInfoDTO;
}

export function ShipmentDetails(props: ShipmentDetailsProps) {
  let location = useLocation();

  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  let address: string[];
  address = (plantDetails?.saleOrderItems.length) ? plantDetails?.saleOrderItems[0].customeraddress.split(",") : [];
  let addr: string[];
  addr = (plantDetails?.saleOrderItems.length) ? plantDetails?.saleOrderItems[0].customeraddress.split(",") : [];

  const soId = location.state;

  let totalCases = 0;
  let totalLbWeight = 0;
  let totalAmount = 0;


  const getData = (saleOrderId) => {
    const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(plantDetails)
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };
  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print()
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };


  return (
    <>
      <span style={{ alignContent: 'left', backgroundColor: 'red' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="ShipmentDetails"
          sheet="ShipmentDetails"
          buttonText="Get Excel" />
      </span>
      <br />
      <div id="printme">
        <br />
        <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
          <tr><td className={'ta-b'} colSpan={11}><h1 style={{ textAlign: 'center', fontSize: '30px' }}>
            SHIPMENT DATA SHEET
          </h1></td> </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>PO Number</b></td>
            <td className={'ta-b'} colSpan={2}>{(props.soData) ? props.soData.poNo : '-'}</td>
            <td className={'ta-b'} colSpan={3}><b>Supplier Name</b></td>
            <td className={'ta-b'} colSpan={3}><h5>SANDHYA AQUA EXPORTS PVT LTD</h5></td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Invoice Number</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.invoiceNumber}</td>
            <td className={'ta-b'} colSpan={3}><b>Buyer Name</b></td>
            <td className={'ta-b'} colSpan={3}>{
                    plantDetails?.country === "CANADA" ?
                    <>
                    AZ GEMS CANADA INC:
                    </> : <>AZ GEMS INC</>
                  }</td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Invoice Date</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.invoiceDate) ? moment(plantDetails?.invoiceDate).format('Do MMM, YY') : " "}</td>
            <td className={'ta-b'} colSpan={3}><b>Payment Terms</b></td>
            <td className={'ta-b'} colSpan={3}>{plantDetails?.saleOrderItems[0]?.paymentTerms}</td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Total Invoice Amount (US$)</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.totalAmount ? Number(plantDetails?.totalAmount).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
            <td className={'ta-b'} colSpan={3}><b>Shipment Terms</b></td>
            <td className={'ta-b'} colSpan={3}>{(plantDetails?.shipmentTerms ? plantDetails?.shipmentTerms : " ") + "-" + (plantDetails?.saleOrderItems[0]?.destinationDetails ? plantDetails?.saleOrderItems[0]?.destinationDetails : '') + ',' + (plantDetails?.country ? plantDetails?.country : '')}</td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Total Pounds Shipped</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.saleOrderItems[0].netWeight ? Number(plantDetails?.saleOrderItems[0].netWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
            <td className={'ta-b'} colSpan={3}><b>Country of Origin</b></td>
            <td className={'ta-b'} colSpan={3}>INDIA</td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>BL Number</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.trackingNo}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Container Number</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.saleOrderItems[0]?.containerNo}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Seal Number</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.saleOrderItems[0]?.linearSelaNo}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>SCAC Code</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.saleOrderItems[0]?.scas}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>BAP NUMBER</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.invoicedPlant.bapNo ? ('P' +plantDetails?.invoicedPlant.bapNo) : ''}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Port Of Loading</b></td>
            <td className={'ta-b'} colSpan={2}> {plantDetails?.portofloading ? (plantDetails?.portofloading)?.toUpperCase() + ', INDIA' : ''}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Shipped Onboard Date</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.shippedOnboardDate) ? moment(plantDetails?.shippedOnboardDate).format("DD-MMM-YYYY") : " "}</td>
            <td className={'ta-b'} colSpan={6}> </td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Originating Vessel Name</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.vesselName}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Port Of Transhipment</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.portOfTranshipment}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Date Of Transhipment</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.dateOfTranshipment) ? moment(plantDetails?.dateOfTranshipment).format("DD-MMM-YYYY") : " "}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Final Importing Vessel Name</b></td>
            <td className={'ta-b'} colSpan={2}>{plantDetails?.importingVesselName}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>Port Of Discharge</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.portOfDischarge ? (plantDetails?.portOfDischarge)?.toUpperCase() : '') + "," + (plantDetails?.country ? plantDetails?.country : '')}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>ETA Port Of Discharge Date</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.dischargeDate) ? moment(plantDetails?.dischargeDate).format("DD-MMM-YYYY") : " "}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}><b>Final Destination</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.saleOrderItems[0]?.destinationDetails ? plantDetails?.saleOrderItems[0]?.destinationDetails : '') + ',' + (plantDetails?.country ? plantDetails?.country : '')}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>

          <tr>
            <td className={'ta-b'} colSpan={3}><b>ETA Final Destination Date</b></td>
            <td className={'ta-b'} colSpan={2}>{(plantDetails?.destinationDate) ? moment(plantDetails?.destinationDate).format("DD-MMM-YYYY") : " "}</td>
            <td className={'ta-b'} colSpan={6}></td>
          </tr>
          <tr><td className={'ta-b'} colSpan={11}><b>Notes</b></td></tr>
          <tr>
            <th className={'ta-b'}>Item Size</th>
            <th className={'ta-b'} colSpan={3}>Item Description</th>
            <th className={'ta-b'}>Package</th>
            <th className={'ta-b'}>Quantity (Cases)</th>
            <th className={'ta-b'} colSpan={2}>Price Per LB</th>
            <th className={'ta-b'} colSpan={3}>Brand Name</th>
          </tr>
          {plantDetails?.saleOrderItems?.length ?
            plantDetails?.saleOrderItems.map((item) => {
              const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
              let shrimp = "SHRIMPS";
              return <tr><td className={'ta-b'}>{item.minGrade + '/' + item.maxGrade}</td>
                <td className={'ta-b'} colSpan={3}>{((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                  (!(([
                    'HLSO', 'HOSO', 'EZPeel', 'N/A'
                  ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')}<br />{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}</td>
                <td className={'ta-b'}>{item.pouches + "*" + Number(item.pouchWeight) + ' ' + uom.name.toUpperCase()}</td>
                <td style={{ textAlign: 'right' }} className={'ta-b'}>{item.cases}</td><td style={{ textAlign: 'right' }} className={'ta-b'} colSpan={2}>{(CrrencySymbols.find(ele => ele.id === item.currencyId).symbol) + '' + Number(item.revisedUnitPrice)}</td>
                <td className={'ta-b'} colSpan={3}>{item.displayBrandName === "Great American Seafood GAS" ? "Great American Seafood " : item.displayBrandName}</td></tr>;
            })
            : ''}

        </table>
      </div>
    </>
  );
}

export default ShipmentDetails;
