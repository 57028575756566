import React, { useCallback, useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select, DatePicker, InputNumber, Typography } from 'antd';

import './grade-suggestion-from.css';
import { GradeSuggestionService, ProductService, SkuService, UnitcodeService } from '@gtpl/shared-services/masters';
import { GradeSequenceModel, GradingSuggestionDto, PlantsDropDown, ProductDto, SkuDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CountRequest, GradePatternRequest, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import {CSVLink} from 'react-csv';
import Text from 'antd/lib/typography/Text';
const type = 'DragableBodyRow';
  const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex }:any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item:any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

/* eslint-disable-next-line */
export interface GradeSuggestionFromProps {
  isUpdate:boolean
}

export function GradeSuggestionFrom(
  props: GradeSuggestionFromProps
) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const productService = new SkuService();
  const [productsData, setProductsData] = useState<SkuDto[]>([])
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [saleOrderNumbers, setSaleOrderNumbers] = useState<any[]>([]);
  const unitsService = new UnitcodeService()
  const [data, setData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const service = new GradeSuggestionService();
  const saleService = new SaleOrderService();
  const [page, setPage] = React.useState(1);
  const [filtersData, setFiltersData] = useState<GradingSuggestionDto[]>([]);
  const [productDropdown,setProductDropdown] = useState<any>()
  const [priority,setPriority] = useState<Number>();
  const [plannedQty,setPlannedQty] = useState<Number>();
  const [updatedData,setUpdatedData] = useState<any>();
  const date = form.getFieldValue('date') ? moment(form.getFieldValue('date')).format("YYYY-MM-DD"):null;
  console.log(date,'---1--')
   
  
  useEffect(() => {
    getAllPlants();
   getData();
    setData(data)
   
  },[])
  
 
  const searchData = () => {
    console.log(form.getFieldValue('date'));
    console.log('on search');
    const date = form.getFieldValue('date') ? moment(form.getFieldValue('date')).format("YYYY-MM-DD"):null;
    let selectedData = filtersData;
   if (date) {
      selectedData = selectedData.filter(item => moment(item.date).format("YYYY-MM-DD") === date)
    }
    setFiltersData(selectedData);
    setData(selectedData);
}

  const getAllPlants = () => {

    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);

      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  
 const getAllSaleOrders = () => {
  const plantId = form.getFieldValue('plantId')
  const minCount = form.getFieldValue('minGrade')
  const maxCount = form.getFieldValue('maxGrade');
  const count = (minCount + maxCount)/2
  if(minCount && maxCount && plantId){
   saleService.getSaleOrdersForGradePattern(new GradePatternRequest(count,maxCount,plantId)).then((res) => {
      if (res.status) {
        setSaleOrderNumbers(res.data);
       
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNumbers([]);
    });
  };
}

 
  

  const create = (dto:GradingSuggestionDto) => {
    dto.date = moment(dto.date).format('YYYY-MM-DD')
  service.createGradeSuggestion(dto).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Grade suggestion Sequence created successfully');
        getData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
    
  }

  const getData = () => {
    service.getAllGradeSuggestions().then((res) => {
      if(res.status){
        setData(res.data)
        setFiltersData(res.data)
        AlertMessages.getSuccessMessage('Grade suggestion Sequence retrived successfully');
      }
      else {
        if (res.intlCode) {
          setData(res.data)
          AlertMessages.getErrorMessage(res.internalMessage);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
    
  }

  const getSaleOrderItemIds = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    if(saleOrderId){
      saleService.getProductForSoId(new saleOrder(saleOrderId)).then((res) => {
        if (res.status) {
          setProductDropdown(res.data);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSaleOrderNumbers([]);
      });
    }
  };
  const saveData = (values: GradingSuggestionDto) => {
    console.log(values);
    if(props.isUpdate){
      // props.updateItem(values);
      return null
    }else{
      create(values);
    }
  
  };

 
  const onReset = () => {
    form.resetFields();
  };
  const onResetFilter = () => {
    form.resetFields();
    getData();
  };
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }
  const deleteData = (index) => {
    data.splice(index, 1);
    setData([...data]);
    if (data.length == 0) {
      setShowTable(false)
    }
  }

  const edit = (rowData,index) => {
    
    }
  
  const addData = (index) => {
    data.push(index, 1);
    setData([...data]);
    if (data.length == 0) {
      setShowTable(false)
    }
  }
  const priorityOnChange = (value,index,rowData)=>{
    console.log(value,index,'onChange')
    const newdata = [...data]
    console.log(newdata,'newdata')
    newdata[index].priority = value
    setData(newdata)
  }

  const plannedQtyOnChange = (value,index,rowData) => {
    const newdata = [...data]
    newdata[index].plannedQuantity = value
    setData(newdata)
    setPlannedQty(value)
  }

 
 const updateSequence = () => {
    let seqModel:GradeSequenceModel[] = [];
    data?.forEach(eachRecord => {
      const seqDto = new GradeSequenceModel(eachRecord.sequence,eachRecord.priority,eachRecord.plannedQuantity,eachRecord.gradeSuggestionId)
      seqModel.push(seqDto);
    })
   service.updateGradeSuggestion(seqModel).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  
  }

    

  const columns = [
    
    {
      title: 'Sequence',
      key: 'sequence',
      dataIndex:'sequence',
      render: (text, object, index) =>{
        object.sequence = index+1
        return index+1
      } ,
      hide:true
    },
  //   {
  //     title: 'Date',
  //     key: 'date',
  //     dataIndex:'date',
  //     render:(value) => 
  //       (moment(value).format("DD-MM-YY"))
      
     
  // },
    {
      title: ' H/L Grade',
      key: 'minGrade',
      dataIndex:'minGrade',
      render:(value,record:any) => {
       return <span> {record.minGrade + '-' + record.maxGrade} </span>
      }
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex:'product'
    },
    {
      title: 'PO Number',
      key: 'poNumber',
      dataIndex:'poNumber'
    },
    {
      title: 'Priority',
      key: 'priority',
      dataIndex:'priority',
      render: (text, rowData, index) => {
        return (
          <>
            <Form.Item
              name={"priority" + index}
              rules={[
                {
                  required: true, message: 'Missing gross weight'
                },
               
              ]}

              initialValue={text}
              style={{ margin: 0 }}
            >
              {/* {rowData.grossWeight > 0 ?  */}
              <InputNumber defaultValue={text} min={0} key={rowData.priority} onChange={e => priorityOnChange(e,index,data)} />
             
            </Form.Item></>
        )
      }
    },
    {
      title: 'Planned Quantity(in Tons)',
      key: 'plannedQuantity',
      dataIndex:'plannedQuantity',
      render: (text, rowData, index) => {
        return (
          <>
            <Form.Item
              name={"plannedQuantity" + index}
              rules={[
                {
                  required: true, message: 'Missing gross weight'
                },
               
              ]}

              initialValue={text}
              style={{ margin: 0 }}
            >
              {/* {rowData.grossWeight > 0 ?  */}
              <InputNumber defaultValue={text} min={0} key={rowData.plannedQuantity} onChange={e => plannedQtyOnChange(e,index,data)} />
              {/* :"-"
            } */}
            </Form.Item></>
        )
      }
    },
   {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          {/* <Tooltip placement="top" title='dlete'>
            <MinusCircleOutlined onClick={() => {
              if (rowData) {
                deleteData(index)
                // openFormWithData(rowData);
              }
            }}
              style={{ color: '#1890ff', fontSize: '14px' }} />
          </Tooltip> */}
           <Popconfirm title="Sure to delete?" onConfirm={() => {if (rowData) {
                deleteData(index)
               }
            }}>
              <a>Delete</a>
            </Popconfirm>
            {/* <Divider type='vertical' />
            <Popconfirm title="Sure to update the value?" onConfirm={() => {if (rowData) {
                edit(rowData,index)
               }
            }}>
              <a>Update</a>
            </Popconfirm> */}
       </span>)
    }
    
   
  ];

 

 
  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex:any, hoverIndex:any) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [data],
  );
  return (
    <>
    <DndProvider backend={HTML5Backend}>
    <Card title={<span style={{color:'white'}}>Grading Pattern</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} >
       <Form form={form}  name="control-hooks" onFinish={saveData}  layout="vertical"  >
    <Form.Item name="gradeSuggestionId" style={{display:"none"}} >
      <Input hidden/>
    </Form.Item>
    <Form.Item name="sequence" style={{display:"none"}} >
      <Input defaultValue={data.length + 1} hidden/>
    </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser" >
      <Input hidden/>
    </Form.Item>
    <Row gutter={30}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item
                name="date"
                label="Date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: 'Date is Required'
                  },
                ]}
              >
                <DatePicker showToday={true} style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item
                name="plantId"
                label="Plant"
                rules={[
                  {
                    required: true, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Unit"
                  style={{ width: '100%' }}
                  
                >
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                  })}
                </Select>
               </Form.Item>
            </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
       <Form.Item label='H/L Grade'   rules={[{ required: true, message: 'Missed grade/Enter a correct value'}]}>
        <Input.Group  compact>
          <Form.Item
            label='H/L Grade'
            name={'minGrade'}
            noStyle
            rules={[{ required: true, message: 'Min Grade is required',
             pattern: /^[0-9]*$/ ,
             validator: (_, value) => {
              const maxGrade = form.getFieldValue('maxGrade');
              if (maxGrade) {
                return value < maxGrade ? Promise.resolve() : Promise.reject(new Error('Min Grade should be < Max Grade'));
              } else {
                return Promise.resolve();
              }

            }
           }]}
           
          >
            <InputNumber  style={{ width: "35%" }} placeholder="Min" min={1} onChange={getAllSaleOrders}   />
          </Form.Item>

          <Form.Item name={'middle'} noStyle >
            <Input style={{ width: "30%" }}
              placeholder="~"
              disabled

            />
          </Form.Item>
          <Form.Item
            label='Max Grade'
            name={'maxGrade'}
            noStyle
            rules={[{ required: true, message: 'Missed max Grade/Max Grade greater than Min Grade/Enter a correct value', pattern: /^[0-9]*$/,
            validator: (_, value) => {
              const minGrade = form.getFieldValue('minGrade');
              if (minGrade) {
                return value > minGrade ? Promise.resolve() : Promise.reject(new Error('Max Grade should be > Min Grade'));
              } else {
                return Promise.resolve();
              }

            }
          }]}
          >
            <InputNumber  style={{ width: "35%" }} placeholder="Max" min={form.getFieldValue('minGrade')}  onChange={getAllSaleOrders} />
          </Form.Item>
        </Input.Group>
        </Form.Item>
     


      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
      <Form.Item
        name="saleOrderId"
        label="PO Number:"
        rules={[
          {
            required: true,
            message: "PO is required"
          },
        ]}
      >
        <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select PO Number"
                optionFilterProp="children"
                onChange={getSaleOrderItemIds}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select PO Number</Option>
                {saleOrderNumbers?.map((product)=>{
                  return <Option value={product.saleOrderId}>{product.poNumber}</Option>
                })}
              </Select>
      </Form.Item></Col >
    <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}}>
      <Form.Item
        name="productId"
        label="Product:"
        rules={[
          {
            required: true,
            message: "Product is required"
          },
        ]}
      >
        <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Product"
                optionFilterProp="children"
                // onChange={handleProduct}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Product</Option>
                {productDropdown?.map((product)=>{
                  return <Option value={product.variantId}>{product.variantCode}</Option>
                })}
              </Select>
      </Form.Item></Col >
      
      
      <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item
                name="priority"
                label="Priority"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: 'Priority is Required'
                  },
                ]}
              >
                <InputNumber defaultValue={1} style={{ width: '100%' }}  />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item
                name="plannedQuantity"
                label="Planned Quantity(in Tons)"
                rules={[
                  {
                    required: true,
                    message: 'Planned Quantity is Required'
                  },
                ]}
              >
                <InputNumber  style={{ width: '100%' }}  />
              </Form.Item>
            </Col>
            
        <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}} style={{ textAlign: 'right',paddingTop:'30px' }}>
        
          <Button type="primary" htmlType="submit" >
            Add
          </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
              
          </Col>
      </Row>
  </Form>
     </Card>
     <Card title={<span style={{color:'white'}}>Grading Pattern </span>}
    style={{textAlign:'center'}}  headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={ <span style={{color:'white'}} >  <Button ><CSVLink filename={"GradeSuggestion.csv"} data={data} >Get Excel </CSVLink></Button> </span>} >

         <Row gutter={30}>

            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item
                name="date"
                label="Date"
                initialValue={moment()}
                rules={[
                  {
                    required: false, message: 'Select Date',
                  },
                ]}
              >
               <DatePicker showToday defaultValue={moment()}/>
               </Form.Item>
            </Col>


    
   
           <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Button type="primary" onClick={searchData} >
                Search
              </Button>
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onResetFilter}>
                Reset
              </Button>
          </Col>
        </Row>
    
    <br/>
    <Table pagination={false}
      columns={columns}
      dataSource={data.filter(item => moment(item.date).format("YYYY-MM-DD") === date)}
      components={components}
      // onRow={(record:any,index:any) => ({
      //   index,
      //   moveRow,
      // })}
      
      />
    <br/><br/><br/>
    <Row justify='end'>
    <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Button type="primary" onClick={updateSequence} >
                Update 
              </Button>
    </Col>
    </Row>

   
    
    </Card>
    </DndProvider>
     </>
  );
}

export default GradeSuggestionFrom;
