//import { AttendanceModel, DeptWiseDataModel, EmployeeAttendanceRequest } from "@gtpl/shared-models/hrms";
import { Input, Button, Space, Tag, Card, Col, Form, Row, Select } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { useState, useRef, useEffect } from "react";
import { CaretRightOutlined, SearchOutlined, FilterOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
//import { CSVLink, CSVDownload } from "react-csv";
//import { AttendanceService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { Excel } from 'antd-table-saveas-excel';
import { InterviewService } from '@gtpl/shared-services/hrms';
import { DtoOfRecruitmentDashboard, InterviewReportDto, InterviewReportResponse } from "@gtpl/shared-models/hrms";
import { PlantsDropDown } from "@gtpl/shared-models/masters";
import {  UnitRequest } from '@gtpl/shared-models/common-models';
import { UnitcodeService } from "@gtpl/shared-services/masters";
/* eslint-disable-next-line */
export interface RecruitmentReportGridProps {

  // RecruitmentReportData: RecruitmentModel[];
  // viewRecruitmentReport: (RecruitmentRepor: RecruitmentModel, viewOnly: boolean) => void;

}

export const RecruitmentReportGrid = (props: RecruitmentReportGridProps) => {
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [reportData, setReportData] = useState<DtoOfRecruitmentDashboard[]>([])
  const [selectedUnit,setSelectedUnit]=useState<string>(undefined);
  const service = new InterviewService();
  const [selectedunit, setUnit] = useState<number>(0)
  const { Option } = Select;
  const unitsService = new UnitcodeService();

  const onReset=()=>{
    form.resetFields();
    setUnit(0);
    setSearchText('');
    setReportData([]);
    getRecruitmentData();
    
  }
  const changeUnit =(val)=>{
    setSelectedUnit(val)
  }
  const getRecRep=()=>{
    service.getRecruitmentDataForUnit({plant_id:Number(selectedUnit)}).then((res) => {

      if (res.status) {
        setReportData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setReportData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={() => handleReset(clearFilters)}> Reset </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null
    });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => { 
    getAllPlants();
    getRecruitmentData(); }, [])

  const getAllPlants = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const getRecruitmentData = () => {
    service.getRecruitmentData().then((res) => {

      if (res.status) {
        setReportData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setReportData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }





  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    // {
    //   title: 'Plant',
    //   dataIndex: 'Plant',
    //     sorter: (a, b) => a.Plant.localeCompare(b.Plant),
    //     sortDirections: ['descend', 'ascend'],
    //     ...getColumnSearchProps('Plant')
    //   },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      sorter: (a, b) => a.jobDescription?.localeCompare(b.jobDescription),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobDescription')
    },
    {
      title: 'Total profile Assigned',
      dataIndex: 'totalProfileAssigned',
      sorter: (a, b) => a.totalProfileAssigned.localeCompare(b.totalProfileAssigned),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('totalProfileAssigned')
    },
    {
      title: 'Total Rejected',
      dataIndex: 'Rejected',
      sorter: (a, b) => a.Rejected.localeCompare(b.Rejected),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('Rejected')
    },
    {
      title: 'To Be Interviewed',
      dataIndex: 'toBeInterviewed',
      sorter: (a, b) => a.toBeInterviewed.localeCompare(b.toBeInterviewed),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('toBeInterviewed')
    },
    {
      title: 'Total Select',
      dataIndex: 'Selected',
      sorter: (a, b) => a.Selected.localeCompare(b.Selected),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('Selected')
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('Status')
    },
    
  ];
  const exportedData = [];
  console.log(exportedData)
  const excelData = reportData
  let i=1;
  const data = [
    { title: 'S No', dataIndex: 'sNo',render: (text, object, index) => { return i++; }},
    // {title: 'Plant',dataIndex: 'Plant',},
    { title: 'Job Description', dataIndex: 'jobDescription' }, 
    {title: 'Total profile Assigned', dataIndex: 'totalProfileAssigned',},
    { title: 'Total Rejected', dataIndex: 'Rejected', },
    { title: 'To Be Interviewed',  dataIndex: 'toBeInterviewed' },
    { title: 'Total Select', dataIndex: 'Selected' },
    {title: 'Status', dataIndex: 'Status',},
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('id')
      .addColumns(data)
      .addDataSource(reportData, { str2num: true })
      .saveAs('Recruitment-Report.xlsx');
  }

  return (
 
     <Card
      title={<span style={{ color: 'white' }}>Recruitment Report </span>}extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
        <Form layout={"vertical"} form={form} >
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
              name="unitId"
              label=" Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                //disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                style={{ width: '100%' }}
                onChange={changeUnit}
              >
                {plantData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={getRecRep} >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          </Row>
          </Form>
      <Table
        rowKey={record => record.Id}
        columns={sampleTypeColumns}
        dataSource={reportData}
        pagination={{ onChange(current) { setPage(current); } }}
        scroll={{ y: 500 }}
        size="small"
        bordered

      >
      </Table>
  
   </Card>
  );
}

export default RecruitmentReportGrid;
