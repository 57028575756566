import React, { useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {ReasonsDto}  from '@gtpl/shared-models/masters';
import {ReasonsService} from '@gtpl/shared-services/masters';

import './reasons-form.css';

const { TextArea } = Input;
/* eslint-disable-next-line */
export interface ReasonsFormProps {

  reasonsData: ReasonsDto;
  updateReasons:(reasons:ReasonsDto)=>void;
 isUpdate:boolean;
closeForm: () => void;
}

export function ReasonsForm(
  props: ReasonsFormProps
) {

  const [form] = Form.useForm();
  const service = new ReasonsService;
  const [disable,setDisable]=useState<boolean>(false)
  let history = useHistory();
  // let createdUser = "";
  // if(!props.isUpdate){
  //   createdUser = localStorage.getItem("createdReason");
  // }

  const create = (Data: ReasonsDto) => {
    setDisable(true)
    
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
     AlertMessages.getSuccessMessage('Created Successfully');
         
     history.push("/reasonsView")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   AlertMessages.getErrorMessage(err.message);
 })
}

const saveData = (values: ReasonsDto) => {
  setDisable(false)
  // console.log(values);
  if(props.isUpdate){
    props.updateReasons(values);
  }else{
    setDisable(false)
    create(values);
    // console.log('test')
  }

};
const onReset = () => {
  form.resetFields();
};

  return (
    <Card title={<span style={{color:'white'}}>AMC Reasons</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/reasonsView' ><span style={{color:'white'}} >  {(props.isUpdate===false) &&
          <Button className='panel_button' >View </Button>
           }  </span></Link>} >
    
    
          <Form form={form} initialValues={props.reasonsData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="reasonId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.reasonsData}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:6,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="reasonCode"
                      label="Reason Code"
                      rules={[
                        {
                          required: true,
                          
                          message: 'Reason Code required'

                        },
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                          message: `Invalid Reason Code`
                        }
                      ]}
                      >
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="reasonName"
                    label="Reason Name"
                    rules={[
                      {
                        required: true,
                       
                        message: 'Reason Name required'

                      },
                      {
                        pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                        message: `Should contain only alphabets.`
                      }
                    ]}>
                      <Input/>
                    
                  </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary"disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate!==true) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
  );
}

export default ReasonsForm;
