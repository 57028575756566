import React, { useState, useEffect } from 'react';
import './documents.css';
import moment from 'moment';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'
import { GrnVehicleInfo } from '@gtpl/shared-models/raw-material-procurement';
import { RmVehicleInspectionService } from '@gtpl/shared-services/raw-material-procurement';
import { GrnRequest, GrnData } from '@gtpl/shared-models/raw-material-procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GrnInformationDto } from '@gtpl/shared-models/production-management';
export interface RMGrnPrintProps{
    grnItemId: number;
}

export function RMGrnPrint(props: RMGrnPrintProps){
    
    const [inspectionDetails, setInspectionDetails] = useState<GrnVehicleInfo>();
    const service = new RmVehicleInspectionService();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const getCssFromComponent = (fromDoc, toDoc) => {
        Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
          if (styleSheet.cssRules) { // true for inline styles
            const newStyleElement = toDoc.createElement('style');
            Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
              newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
            });
            toDoc.head.appendChild(newStyleElement);
          }
        });
      }
    
      const printOrder = () => {
        const divContents = document.getElementById('printme').innerHTML;
        const element = window.open('', '', 'height=700, width=1024');
        element.document.write(divContents);
        getCssFromComponent(document, element.document);
        element.document.close();
        element.print();
        element.close(); // to close window when click on cancel/Save
        setIsModalVisible(true); // model should be open
      };

    const getData = (grnItemId) => {
        service.getVehicleInspectionInfo({grnItemId:grnItemId}).then(res =>{
            if(res.status){
                console.log(res.data);
                setInspectionDetails(res.data);
            }else {
                if(res.intlCode){
                    setInspectionDetails(undefined);
                    AlertMessages.getErrorMessage(res.internalMessage);
                }else{
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            setInspectionDetails(undefined);
            AlertMessages.getErrorMessage(err.message);
        })
    }

    useEffect( () => {
        if(props.grnItemId){
          getData(props.grnItemId);
        }
      },[props.grnItemId])

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    return(
        <html>            
            <body id="printme">                
                <Button onClick={printOrder} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                <br/><br/>
                <table className={'ta-b'} style={{width: '100%'}} >
                    <tr style={{borderBottom: '0px'}}>
                        <td colSpan={5} style={{fontSize: '17px', lineHeight:'12px', paddingTop:'20px', textAlign: 'center' }}>
                            <h2 >SANDHYA AQUA EXPORTS PVT. LIMITED, PAMARRU</h2> 
                            <h4 style={{marginBottom: '15px'}}>Kurumaddali village, Pamarru Mandal, Krishna District, AP.</h4>
                            {/* <h3 style={{textAlign: 'right'}}>FMT/SSO/P/01A</h3> */}
                            <h3 ><span style={{backgroundColor: '#57595A', color: '#D4E1E7', margin: '4px'}}>RAW MATERIAL VEHICLE INSPECTION REPORT</span></h3>
                        </td>
                    </tr>
                    <tr><td colSpan={5} style={{fontSize: '17px', lineHeight:'12px', paddingTop:'20px', textAlign: 'center' }}>                            
                    
                    </td></tr>
                    <tr></tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>                        
                        <td colSpan={5}><h3 style={{display: 'inline'}}>&ensp;Date: </h3>2021/05/14<br/><br/></td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;1. &ensp;Vehicle Number </h3></td>
                        <td colSpan={4} >: &ensp;{inspectionDetails?.vehicleNumber}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;2. &ensp;Vehicle in Time </h3></td>
                        <td colSpan={4}>: &ensp; {moment(new Date()).format('hh:mm A')}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;3. &ensp;Variety of Material </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.varietyOfMaterial}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;4. &ensp;Name of Supplier / Address </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.supplierAddress}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;5. &ensp;Supplier ID & Pond No. </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.supplierCode}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;6. &ensp;Seal Number </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.sealNumber}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;7. &ensp;Icing condition of material </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.icingConditionOfMaterial}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;8. &ensp;Crates condition (damage if any) </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.cratesCondition}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;9. &ensp;Cleanliness of crates  </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.cleanlinessOfCrates}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;10. &ensp;Vehicle cleanliness </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.vehicleCleanliness}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;11. &ensp;Any sign of infestation </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.anySignOfInfestation}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;12. &ensp;Any Sign of contamination (with dust / filth) </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.contaminationDustOrFilth}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} ><h3>&ensp;13. &ensp;Any damage of the insulation inside the container </h3></td>
                        <td colSpan={4}>: &ensp;{inspectionDetails?.insulationDamage}</td>
                    </tr>
                    <tr>
                        <td colSpan={5}><br/><br/></td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={5} ><h3 style={{display: 'inline'}}>&ensp;REMARKS: </h3> {inspectionDetails?.remarks}</td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}> <td colSpan={5}><br/><br/><br/></td></tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={5} ><h3 style={{display: 'inline'}}>&ensp;CORRECTIVE ACTION TAKEN: </h3></td>
                    </tr>
                    <tr style={{borderTop: '0px', borderBottom: '0px'}}>
                        <td colSpan={1} style={{textAlign: 'center'}}><br/><br/><br/><br/>Inspected by</td>
                        <td colSpan={4} style={{textAlign: 'center'}}><br/><br/><br/><br/>Verified by</td>
                    </tr>
                    <tr style={{borderTop: '0px'}}>
                        <td colSpan={5}> <br/><br/></td>
                    </tr>
                </table>
            </body>
        </html>
    );
}
export default RMGrnPrint;
