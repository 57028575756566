import React, { useEffect, useState, useRef } from 'react';
import { ColumnProps } from "antd/lib/table";
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal, Form, Select, Typography, DatePicker, Tabs } from 'antd';
import { CheckCircleOutlined, PrinterOutlined, CloseCircleOutlined, RightSquareOutlined, BarcodeOutlined, EyeOutlined, EditOutlined, SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetService } from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { Link, Redirect } from "react-router-dom";
import Barcode from 'react-barcode';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { AssetDto, purchasedatefilterreq } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetsCode } from '@gtpl/shared-models/common-models';
import { AssetsDetailedView } from './Detailed-view';
import { AssetForm } from '../../../aasets-view/src/lib/pages-asset-management-asset-management-components-aasets-view';
import moment from 'moment';
import BarcodePrint from './barcode-print';
import { BarcodeCoulmnsDto } from '@gtpl/shared-models/asset-management';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { Excel } from 'antd-table-saveas-excel';
import Highlighter from 'react-highlight-words';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import Scanner from '../../../asset-classification-form/src/lib/Scanner';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto, damageReasonDtoDefault } from '@gtpl/shared-models/masters';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

/* eslint-disable-next-line */
export interface AssetGridProps {
  updateState?: (Key: number) => void;
  status: AssetStatusEnum;
}

export function AssetGrid(
  props: AssetGridProps
) {
  const [assetsData, setAssetsData] = useState<AssetDto[]>([]);
  const [instockAssetsData, setInstockAssetsData] = useState<AssetDto[]>([]);
  const [inuseAssetsData, setInuseAssetsData] = useState<AssetDto[]>([]);
  const [maintenanceAssetsData, setMaintenanceAssetsData] = useState<AssetDto[]>([]);
  const [damagedAssetsData, setDamagedAssetsData] = useState<AssetDto[]>([]);
  const [retairedAssetsData, setRetairedAssetsData] = useState<AssetDto[]>([]);
  const [servicedAssetsData, setServicedAssetsData] = useState<AssetDto[]>([]);
  const [intransitAssetsData, setIntransitAssetsData] = useState<AssetDto[]>([]);
  const [soldAssetsData, setSoldAssetsData] = useState<AssetDto[]>([]);
  var instockData = [];
  var inuseData = [];
  var maintenanceData = [];
  var damagedData = [];
  var retairedData = [];
  var serviceData = [];
  var intransitData = [];
  var soldData = [];
  const [showBarcode, setShowBarcode] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<AssetDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const [code, setCode] = useState(0);
  const [selectedAssetsData, setSelectedAssetsData] = useState<any>(undefined);
  const service = new AssetService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const assetsService = new AssetService();
  const [assetCodeData, setAssetCodeData] = useState<AssetDto[]>([]);
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [updateKey, setUpdateKey] = useState<number>(1);
  const employeeService = new EmployeeService();
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [activeKeyVal, setActiveKeyVal] = useState<string>('8');
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const role = JSON.parse(localStorage.getItem('role'))
  const [pageSize, setPageSize] = useState<number>(null);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<AssetDto[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  // const [actiondisable,setActiondisable] = useState<boolean>(true);

  let diff = 0;
  let date;
  let todate;
  const { Text } = Typography;
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear, currentMonth, 1)
  const dateformat = 'YYYY-MM-DD';


  useEffect(() => {
    if (props.status !== undefined) {
      form.setFieldsValue({ status: props.status })
      setTabKey(props.status)
    } else {
      form.setFieldsValue(undefined)
    }
  }, [props.status])

  useEffect(() => {
    // getAllAssets();

    getAllPlants();
    onStatusSearch();
    getItemCategory();
    getDepartments();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations();
    getAssetCode();
    employeeDetails();
    getAllPlants()
    //   form.setFieldsValue({
    //     date: [(moment(moment().startOf('month').format("YYYY-MM-DD"))), moment(moment().format("YYYY-MM-DD"))]
    // })
    //   setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1, 'days')))
    //   setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))

  }, [])

  useEffect(() => {
    // if (barcode.le) {
    console.log(barcode)
    // setUpdateKey(prevVal => prevVal + 1)
    // }
  }, [barcode])


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeysData(selectedRowKeys);
      setSelectedRows(selectedRows);
      setVisible(true);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeysData
  };


  const EstimatedETDDate = (value) => {
    console.log(value, 'value')
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
    }
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  // const getAllAssets = () => {
  //   // const req = new  purchasedatefilterreq();
  //   // // if(form.getFieldValue('date') !== undefined){
  //   //   req.purchaseFromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM_DD')
  //   // // }
  //   // // if(form.getFieldValue('date') !== undefined){
  //   //   req.purchaseToDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM_DD')
  //   // // }
  //   service.getByStatus().then(res => {
  //     if (res.status) {
  //       setAssetsData(res.data)
  //       AlertMessages.getSuccessMessage('Assets retrieved successfully')
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }


  const AssetsDetailedView = (AssetsCode: number) => {
    setCode(AssetsCode)
  }

  const generateBarcode = (assetsId: AssetDto[]) => {

    const barCodes = [];
    console.log(assetsId);

    for (const assetIdrec of assetsId) {
      barCodes.push(assetIdrec)
      console.log(barCodes);

    }
    console.log(barCodes);
    setBarcode(barCodes);
    setShowBarcode(true);
    setSelectedRows(selectedRows);
  }

  const updateAsset = (assetData: AssetDto) => {
    assetData.month = assetData.month ? String(moment(assetData.month).month() + 1) : '0'
    service.createAssets(assetData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        onStatusSearch();
        setDrawerVisible(false);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onStatusSearch = () => {
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (form.getFieldValue('status') !== undefined) {
      req.status = form.getFieldValue('status')
    }
    if (form.getFieldValue('amcAvailable') !== undefined) {
      req.amcAvailable = form.getFieldValue('amcAvailable')
    }
    if (form.getFieldValue('warrentyAvailable') !== undefined) {
      req.warrentyAvailable = form.getFieldValue('warrentyAvailable')
    }
    if (form.getFieldValue('insuranceAvailable') !== undefined) {
      req.insuranceAvailable = form.getFieldValue('insuranceAvailable')
    }
    if (form.getFieldValue('date') !== undefined) {
      req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('date') !== undefined) {
      req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM_DD')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeId') !== undefined) {
      req.employeeId = form.getFieldValue('employeeId')
    }
    if (form.getFieldValue('warrantyDate') !== undefined) {
      req.warrantyStartDate = moment(form.getFieldValue('warrantyDate')[0]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('warrantyDate') !== undefined) {
      req.warrantyEndDate = moment(form.getFieldValue('warrantyDate')[1]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('insuranceDate') !== undefined) {
      req.insuranceStartDate = moment(form.getFieldValue('insuranceDate')[0]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('insuranceDate') !== undefined) {
      req.insuranceEndDate = moment(form.getFieldValue('insuranceDate')[1]).format('YYYY-MM_DD')
    }
    if (form.getFieldValue('assetCost') != undefined) {
      req.assetCost = form.getFieldValue('assetCost')
    }
    if (form.getFieldValue('assetAge') != undefined) {
      req.aging = form.getFieldValue('assetAge')
    }
    if (form.getFieldValue('assetMode') != undefined) {
      req.assetMode = form.getFieldValue('assetMode')
    }
    service.getByStatus(req).then(res => {
      if (res.status) {
        setAssetsData(res.data)
        res.data.map((e) => {
          if (e.status == AssetStatusEnum.INSTOCK) {
            instockData = [...instockData, e]
          } else if (e.status == AssetStatusEnum.INUSE) {
            inuseData = [...inuseData, e]
          } else if (e.status == AssetStatusEnum.UNDERMAINTENANCE) {
            maintenanceData = [...maintenanceData, e]
          } else if (e.status == AssetStatusEnum.DAMAGED) {
            damagedData = [...damagedData, e]
          } else if (e.status == AssetStatusEnum.RETIRED) {
            retairedData = [...retairedData, e]
          } else if (e.status == AssetStatusEnum.SERVICE) {
            serviceData = [...serviceData, e]
          } else if (e.status == AssetStatusEnum.INTRANSIT) {
            intransitData = [...intransitData, e]
          } else if(e.status == AssetStatusEnum.SOLD) {
            soldData = [...soldData,e]
          }
        })
        setInstockAssetsData(instockData)
        setInuseAssetsData(inuseData)
        setMaintenanceAssetsData(maintenanceData)
        setDamagedAssetsData(damagedData)
        setRetairedAssetsData(retairedData)
        setServicedAssetsData(serviceData)
        setIntransitAssetsData(intransitData)
        setSoldAssetsData(soldData)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  // const deleteProfile = (data) => {
  //   data.isActive = data.isActive ? false : true;
  //   service.activateOrDeactivate(data).then((res) => {
  //     if(res.status) {
  //       AlertMessages.getSuccessMessage(res.internalMessage)
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }
  const openFormWithData = (viewData: AssetDto) => {
    console.log(viewData, '---------viewdata')
    setDrawerVisible(true);
    viewData.dateOfPurchased ? viewData.dateOfPurchased = moment(viewData.dateOfPurchased, 'YYYY-MM-DD') : null;
    viewData.insuranceDate ? viewData.insuranceDate = moment(viewData.insuranceDate, 'YYYY-MM-DD') : null;
    viewData.expiredDate ? viewData.expiredDate = moment(viewData.expiredDate, 'YYYY-MM-DD') : null;
    viewData.warrentyExpiredDate ? viewData.warrentyExpiredDate = moment(viewData.warrentyExpiredDate, 'YYYY-MM-DD') : null;
    viewData.month ? viewData.month = moment(viewData.month, 'MM') : null;
    viewData.year ? viewData.year = moment(viewData.year, 'YYYY') : null;
    setSelectedAssetsData(viewData);

  }
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const closeWindow = () => {
    setBarcode([]);
    window.close();
  };

  const onReset = () => {
    form.resetFields();
    onStatusSearch();
    setActiveKeyVal('8')

  }
  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })

  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const onSearch = (val) => {
    console.log('search:', val);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getallAssets(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const ontabchange = (val) => {
    setActiveKeyVal(val)
  }

  // const isActionDisabled = (record) => {
  //   console.log(record,'-----------------------record--------------------')
  //   if(record.status == 'RETIRED' || record.status == 'DAMAGED'){
  //     return true
  //   }
  // }


  const [page, setPage] = useState<number>(1);

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      fixed: "left",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    }, {
      title: "Plant",
      dataIndex: "plantName",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
      render: (text, record) => {
        return (
          <>
            {record.plantName ? record.plantName : '-'}
          </>
        )
      },
    },

    {
      title: "Location",
      dataIndex: "currentLocation",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('currentLocation'),
      render: (text, record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Mode",
      dataIndex: "assetMode",
      align: "left",
      fixed: "left",
      width: 110,
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetMode'),
      render: (text, record) => {
        return (
          <>
            {record.assetMode ? record.assetMode : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetType'),
      render: (text, record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Category",
      dataIndex: "itemSubCategoryName",
      align: "left",
      // width:120,
      // fixed: "left",
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName')
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      align: "left",
      // fixed: "left",
      width: 120,
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: "left",
      width: 120,
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return <>
          <Button type='link' onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
        </>
      }
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice",
      align: "left",
      // width:120,
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoice'),
      render: (text, record) => {
        return (
          <>
            {record.invoice ? record.invoice : '-'}
          </>
        )
      },
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align: "left",
      // width:120,
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetSerialNo'),
      render: (text, record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },
    },

    {
      title: "Purchase Date",
      dataIndex: "dateOfPurchased",
      align: "right",
      sorter: (a, b) => a.dateOfPurchased?.localeCompare(b.dateOfPurchased),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('dateOfPurchased'),
      render: (text, record) => {
        return (
          <>
            {record.dateOfPurchased ? moment(record.dateOfPurchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "AMC",
      dataIndex: "amc",
      align: "left",
      // width:120,
      sorter: (a, b) => a.amc?.localeCompare(b.amc),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('amc'),
      render: (text, record) => {
        return (
          <>
            {record.amc ? record.amc : 'No'}
          </>
        )
      },

    },
    {
      title: "Provider",
      dataIndex: "amcname",
      align: "left",
      // width:120,
      sorter: (a, b) => a.amcname?.localeCompare(b.amcname),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('amcname'),
      render: (text, record) => {
        return (
          <>
            {record.amcname ? record.amcname : '-'}
          </>
        )
      },

    },
    {
      title: "Warranty Date",
      dataIndex: "warrentyExpiredDate",
      align: "right",
      //  width:80,
      // sorter: {
      //   compare:(a, b) =>{
      //     a= a.created_date.toLowerCase()
      //     b = b.created_date.toLowerCase()
      //     return a > b ? -1 : b > a ? 1:0
      //   },
      // },
      sorter: (a, b) => a.warrentyExpiredDate?.localeCompare(b.warrentyExpiredDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('warrentyExpiredDate'),
      render: (text, record) => {
        return (
          <>
            {record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "Insurance Date",
      dataIndex: "insuranceDate",
      align: "right",
      //  width:70,
      sorter: (a, b) => a.insuranceDate?.localeCompare(b.insuranceDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('insuranceDate'),
      render: (text, record) => {
        return (
          <>
            {record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      },

    },
    {
      title: "Expiry Date",
      dataIndex: "expiredDate",
      align: "right",
      // width:120,
      sorter: (a, b) => a.expiredDate?.localeCompare(b.expiredDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('expiredDate'),
      render: (text, record) => {
        return (
          <>
            {record.expiredDate ? moment(record.expiredDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },

    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')

    },
    {
      title: "Assigned To",
      dataIndex: "assignedName",
      align: "right",
      sorter: (a, b) => a.assignedName?.localeCompare(b.assignedName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedName'),
      render: (text, record) => {
        return (
          <>
            {record.status == `${AssetStatusEnum.INUSE}` ? record.assignedName : '-'}
          </>
        )
      },

    },
    {
      title: "Department",
      dataIndex: "departmentName",
      align: "right",
      sorter: (a, b) => a.departmentName?.localeCompare(b.departmentName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('departmentName'),
      render: (text, record) => {
        return (
          <>
            {record.status == `${AssetStatusEnum.INUSE}` ? record.departmentName : '-'}
          </>
        )
      },
    },
    // {
    //   title: "Aging",
    //   dataIndex: "age",
    //   render:(text,record,index) => {        
    //       todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
    //       date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
    //       diff = (todate - date) / 864e5;
    //       if(diff < 0) {
    //         diff = -diff;
    //       }
    //     return(
    //       <>
    //       {record.transactionDate ?  diff : '-'}
    //       </>
    //     )

    //   }
    // },
    // {
    //   title: "Asset Owned By",
    //   dataIndex: "ownerOfAsset",
    //   align: "right",
    //   // sorter: (a, b) => a.ownerOfAsset?.localeCompare(b.ownerOfAsset),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('ownerOfAsset'),
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.ownerOfAsset? record.ownerOfAsset: '-'}
    //       </>
    //     )
    //   },

    // },
    {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //   date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD'));
      //   diff = (todate - date) / 864e5;
      //   return (
      //     <>
      //       {record.dateOfPurchased ? diff : null}
      //     </>
      //   )
      // },  render: (text, record) => {
      title: ' Asset Age',
      dataIndex: 'assetAge',
      align: "right",
      // sorter: (a, b) => moment(a.assetAge).year() - moment(b.assetAge).year(),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetAge'),
      render: (text, record) => {

        return (
          <>
            {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
      // filters: [
      //   // {
      //   //   text: 'Below 1 Year',
      //   //   value:'Below 1 Year'
      //   // },
      //   // {
      //   //   text: 'Below 2 Year',
      //   //   value:'Below 2 Year'
      //   // },
      //   {
      //     text: 'Below 5 Years',
      //     value:'Below 5 Years'
      //   },
      //   {
      //     text: 'Above 5 Years',
      //     value:'Above 5 Years'
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value,record) => {
      //   const assetAge = record.assetAge;
      //   if(value === 'Below 5 Years'){
      //     return assetAge <= 1825;
      //   } else if(value === 'Above 5 Years') {
      //     return assetAge > 1825;
      //   } 
      //   return false;
      // }
    },
    {
      title: "Cost of Asset",
      dataIndex: "assetCost",
      align: "right",
      // width:120,
      // sorter: (a, b) => a.assetCost?.localeCompare(b.assetCost),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCost'),
      render: (text, record) => {
        return (
          <>
            {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      align: "left",
      width: 100,
      fixed: "right",
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <Button disabled={rowData.status == AssetStatusEnum.DAMAGED || rowData.status == AssetStatusEnum.RETIRED || rowData.status == AssetStatusEnum.SOLD ? true : false}>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                openFormWithData(rowData);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
              // disabled={rowData.status == AssetStatusEnum.DAMAGED || rowData.status == AssetStatusEnum.RETIRED ? true : false}
              
            />
            </Button>
          </Tooltip>
          {/* <Divider type="vertical" />
           <Popconfirm onConfirm={e => { deleteP
            rofile(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate asset ?'
                : 'Are you sure to Activate asset ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm> */}
          {/* <Divider type='vertical' />
          <Button type='primary' shape='round' onClick={() => generateBarcode(rowData.assetsCode)}>
            <PrinterOutlined />
          </Button> */}
        </span>

      )
    }
  ];

  const columns1: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      fixed: "left",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    }, {
      title: "Plant",
      dataIndex: "plantName",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
      render: (text, record) => {
        return (
          <>
            {record.plantName ? record.plantName : '-'}
          </>
        )
      },
    },

    {
      title: "Location",
      dataIndex: "currentLocation",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('currentLocation'),
      render: (text, record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Mode",
      dataIndex: "assetMode",
      align: "left",
      fixed: "left",
      width: 110,
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetMode'),
      render: (text, record) => {
        return (
          <>
            {record.assetMode ? record.assetMode : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      align: "left",
      fixed: "left",
      // width:120,
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetType'),
      render: (text, record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Category",
      dataIndex: "itemSubCategoryName",
      align: "left",
      // width:120,
      // fixed: "left",
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName')
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      align: "left",
      // fixed: "left",
      width: 120,
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: "left",
      width: 120,
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return <>
          <Button type='link' onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
        </>
      }
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice",
      align: "left",
      // width:120,
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoice'),
      render: (text, record) => {
        return (
          <>
            {record.invoice ? record.invoice : '-'}
          </>
        )
      },
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align: "left",
      // width:120,
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetSerialNo'),
      render: (text, record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },
    },

    {
      title: "Purchase Date",
      dataIndex: "dateOfPurchased",
      align: "right",
      sorter: (a, b) => a.dateOfPurchased?.localeCompare(b.dateOfPurchased),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dateOfPurchased'),
      render: (text, record) => {
        return (
          <>
            {record.dateOfPurchased ? moment(record.dateOfPurchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "AMC",
      dataIndex: "amcname",
      align: "left",
      // width:120,
      sorter: (a, b) => a.amcname?.localeCompare(b.amcname),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('amcname'),
      render: (text, record) => {
        return (
          <>
            {record.amcname ? record.amcname : 'No'}
          </>
        )
      },

    },
    {
      title: "Warranty",
      dataIndex: "warrentyExpiredDate",
      align: "right",
      //  width:80,
      // sorter: {
      //   compare:(a, b) =>{
      //     a= a.created_date.toLowerCase()
      //     b = b.created_date.toLowerCase()
      //     return a > b ? -1 : b > a ? 1:0
      //   },
      // },
      sorter: (a, b) => a.warrentyExpiredDate?.localeCompare(b.warrentyExpiredDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('warrentyExpiredDate'),
      render: (text, record) => {
        return (
          <>
            {record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "Insurance",
      dataIndex: "insuranceDate",
      align: "right",
      //  width:70,
      sorter: (a, b) => a.insuranceDate?.localeCompare(b.insuranceDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('insuranceDate'),
      render: (text, record) => {
        return (
          <>
            {record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      },

    },
    {
      title: "Expiry Date",
      dataIndex: "expiredDate",
      align: "right",
      // width:120,
      sorter: (a, b) => a.expiredDate?.localeCompare(b.expiredDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('expiredDate'),
      render: (text, record) => {
        return (
          <>
            {record.expiredDate ? moment(record.expiredDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },

    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')

    },
    {
      title: "Assigned To",
      dataIndex: "assignedName",
      align: "right",
      sorter: (a, b) => a.assignedName?.localeCompare(b.assignedName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedName'),
      render: (text, record) => {
        return (
          <>
            {record.status == `${AssetStatusEnum.INUSE}` ? record.assignedName : '-'}
          </>
        )
      },

    },
    {
      title: "Department",
      dataIndex: "departmentName",
      align: "right",
      sorter: (a, b) => a.departmentName?.localeCompare(b.departmentName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('departmentName'),
      render: (text, record) => {
        return (
          <>
            {record.status == `${AssetStatusEnum.INUSE}` ? record.departmentName : '-'}
          </>
        )
      },
    },
    // {
    //   title: "Aging",
    //   dataIndex: "age",
    //   render:(text,record,index) => {        
    //       todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
    //       date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
    //       diff = (todate - date) / 864e5;
    //       if(diff < 0) {
    //         diff = -diff;
    //       }
    //     return(
    //       <>
    //       {record.transactionDate ?  diff : '-'}
    //       </>
    //     )

    //   }
    // },
    {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //   date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD'));
      //   diff = (todate - date) / 864e5;
      //   return (
      //     <>
      //       {record.dateOfPurchased ? diff : null}
      //     </>
      //   )
      // },  render: (text, record) => {
      title: ' Asset Age',
      dataIndex: 'assetAge',
      align: "right",
      // sorter: (a, b) => moment(a.assetAge).year() - moment(b.assetAge).year(),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetAge'),
      render: (text, record) => {

        return (
          <>
            {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
      // filters: [
      //   // {
      //   //   text: 'Below 1 Year',
      //   //   value:'Below 1 Year'
      //   // },
      //   // {
      //   //   text: 'Below 2 Year',
      //   //   value:'Below 2 Year'
      //   // },
      //   {
      //     text: 'Below 5 Years',
      //     value:'Below 5 Years'
      //   },
      //   {
      //     text: 'Above 5 Years',
      //     value:'Above 5 Years'
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value,record) => {
      //   const assetAge = record.assetAge;
      //   if(value === 'Below 5 Years'){
      //     return assetAge <= 1825;
      //   } else if(value === 'Above 5 Years') {
      //     return assetAge > 1825;
      //   } 
      //   return false;
      // }
    },
    {
      title: "Cost of Asset",
      dataIndex: "assetCost",
      align: "right",
      // width:120,
      // sorter: (a, b) => a.assetCost?.localeCompare(b.assetCost),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCost'),
      render: (text, record) => {
        return (
          <>
            {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },

    },
  ];




  const barcodeWithColumns: BarcodeCoulmnsDto[] = [
    //{ lineNumber: 0, title: 'AssetId',dataIndex: 'assetId', span: 2, showLabel: true, showBarcode: false },
    { lineNumber: 0, title: 'Asset Code', dataIndex: 'assetsCode', span: 4, showLabel: true, showBarcode: false },
    { lineNumber: 1, title: 'Serial No', dataIndex: 'assetSerialNo', span: 4, showLabel: true, showBarcode: false },
    // { lineNumber: 1, title: 'Asset Name', dataIndex: 'itemName', span: 2, showLabel: true, showBarcode: false },
    // { lineNumber: 1, title: 'GRN ID',dataIndex: 'grnId', span: 2, showLabel: true, showBarcode: false },
    // { lineNumber: 2, title: 'Owner of Asset',dataIndex: 'ownerOfAsset', span: 2, showLabel: true, showBarcode: false },
    // { lineNumber: 2, title: 'Model', dataIndex: 'model', span: 2, showLabel: true, showBarcode: false },
    // { lineNumber: 3, title: 'Expired Date',dataIndex: 'expiredDate', span: 3, showLabel: true, showBarcode: false },
    // { lineNumber: 3, title: 'Invoice', dataIndex: 'invoice', span: 3, showLabel: true, showBarcode: false },
    { lineNumber: 4, title: '', dataIndex: 'assetsCode', span: 3, showLabel: false, showBarcode: true },

  ]

  const exportedData = [];
  const excelData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Asset Category', dataIndex: 'itemSubCategoryName' },
    { title: 'Asset Name', dataIndex: 'itemName' },
    { title: 'Asset Serial Number', dataIndex: 'assetSerialNo', render: (text, record) => { return record.assetSerialNo ? record.assetSerialNo : '-' } },
    { title: 'Asset Code', dataIndex: 'assetsCode' },
    { title: 'Invoice Number', dataIndex: 'invoice', render: (text, record) => { return record.invoice ? record.invoice : '-' } },
    { title: 'Model', dataIndex: 'model', render: (text, record) => { return record.model ? record.model : '-' } },
    { title: 'Asset Cost', dataIndex: 'assetCost', render: (text, record) => { return record.assetCost ? record.assetCost : '-' } },
    { title: 'Purchase Date', dataIndex: 'dateOfPurchased', render: (text, record) => { return record.dateOfPurchased ? moment(record.daetOfPurchased).format('YYYY-MM-DD') : '-' } },
    { title: 'Asset Supplier', dataIndex: 'assetSupplier', render: (text, record) => { return record.assetSupplier ? record.assetSupplier : '-' } },
    { title: 'Asset Owner', dataIndex: 'employeeName' },
    { title: 'Asset Condition', dataIndex: 'assetcondition' },
    { title: 'Curretn Location', dataIndex: 'currentLocation' },
    { title: 'AMC Availability', dataIndex: 'amc' },
    { title: 'Manufacturer/Vendor Name', dataIndex: 'amcname', render: (text, record) => { return record.amcname ? record.amcname : '-' } },
    // { title: 'Manufacturer/Vendor Number', dataIndex: 'amcno',render: (text, record) => { return record.amcno ? record.amcno: '-'}  },
    { title: 'Warranty Availability', dataIndex: 'warrenty' },
    { title: 'Warranty Valid Upto', dataIndex: 'warrentyExpiredDate', render: (text, record) => { return record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD') : '-' } },
    { title: 'Insurance Availability', dataIndex: 'insurance' },
    { title: 'Insurance Valid upto', dataIndex: 'insuranceDate', render: (text, record) => { return record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : '-' } },
    { title: 'Asset Status', dataIndex: 'status' },
    {
      title: 'Asset Age (In Days)', dataIndex: 'assetAge', render: (text, record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {record.dateOfPurchased ? diff : null}
          </>
        )
      }
    },
    { title: 'Asset Cost', dataIndex: 'assetCost', render: (text, record) => { return record.assetCost ? record.assetCost : '-' } },

  ];

  const setTabKey = (val) => {
    console.log(val, '---------val')
    if (val == 'INSTOCK') {
      console.log('yes if')
      setActiveKeyVal('1')
    } else if (val == 'INUSE') {
      setActiveKeyVal('2')
    } else if (val == 'UNDERMAINTENANCE') {
      setActiveKeyVal('3')
    } else if (val == 'SERVICE') {
      setActiveKeyVal('4')
    } else if (val == 'INTRANSIT') {
      setActiveKeyVal('5')
    } else if (val == 'DAMAGED') {
      setActiveKeyVal('6')
    } else if (val == 'RETIRED') {
      setActiveKeyVal('7')
    } else if(val == 'SOLD') {
      setActiveKeyVal('9')
    } 
    else {
      setActiveKeyVal('8')
    }
  }


  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Assets-report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('AssetsReport.xlsx');
  }



  return (
    <>
      {(code) ? <Redirect to={{ pathname: `/assetdetailedview/${code}`, state: { id: code, loc: '/assets-grid' } }} /> : null}
      <Card
        title={<span style={{ color: "white" }}> Asset Details</span>}
        style={{ textAlign: "center" }}
        headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
        extra={<>
          {/* <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button> */}
          <Row>
            <Col>
              <Link to={'/assets-form'}>
                <span style={{ color: "white" }}>

                  <Button className="panel_button">Create </Button>{" "}
                </span>
              </Link>
            </Col>
            {/* <Col>
              <Link to={'/items-view'}>
                <span style={{ color: "white" }}>
                  <Button className="panel_button">Asset Update</Button>{" "}
                </span>
              </Link>
            </Col> */}
          </Row>
        </>
        }
      >
        <Form form={form}
          layout='vertical'>
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 3 }} >
              <Form.Item label='Asset Mode' name='assetMode'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                //onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='OwnedAsset' value='OwnedAsset'>OwnedAsset </Option>
                  <Option key='RentedAsset' value='RentedAsset'>RentedAsset</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label='Asset Category' name='assetCategory' >
                <Select
                  defaultValue={'All'}
                  showSearch
                  //onChange={getItems}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {itemCategory.map((items) => {
                    return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >

              <Form.Item name="assetCode"
                label="Asset Code">
                <Select
                  suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} />}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetCodeData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                  })

                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Plant' name='plantId' initialValue={Number(localStorage.getItem('unit_id'))}>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                  showSearch
                  allowClear >
                  {/* <Option key={'all'} value={''}>All</Option> */}
                  {plantName.map((plant) => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Location' name='location'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={'All'}
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {locationData.map((location) => {
                    return (
                      <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Department' name='department'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={'All'}
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {depart.map((e) => {
                    return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Employee Name - Code' name='employeeId' >
                <Select
                  showSearch
                  optionFilterProp="children"
                  //  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  //  onChange={onSearch}
                  defaultValue={'All'}
                  allowClear>
                  <Option key={'all'} value={''}>All</Option>
                  {employeeData?.map((employee) => {
                    return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label='Asset Type' name='assetType'>
                <Select
                  placeholder='assetType'
                  showSearch
                  defaultValue={'All'}
                  //onChange={onStatusSearch}
                  allowClear
                >
                  <Option key='all' value=''>All</Option>
                  {/* <Option key='movable' value='movable'>Movable</Option>
                  <Option key='immovable' value='immovable'>Immovable </Option> */}
                  {Object.values(AssetTypeEnum).map((i) => {
                    return (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>

              <Form.Item label='Status' name='status'>

                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                  onChange={setTabKey}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='INSTOCK' value='INSTOCK'>INSTOCK </Option>
                  <Option key='INUSE' value='INUSE'>INUSE</Option>
                  <Option key='UNDERMAINTENANCE' value='UNDERMAINTENANCE'>UNDER MAINTENANCE</Option>
                  <Option key='SERVICE' value={'SERVICE'}>SERVICE</Option>
                  <Option key='INTRANSIT' value={'INTRANSIT'}>INTRANSIT</Option>
                  <Option key='DAMAGED' value='DAMAGED'>DAMAGED</Option>
                  <Option key='RETIRED' value='RETIRED'>RETIRED</Option>
                  <Option key = 'SOLD' value='SOLD'>SOLD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>

              <Form.Item label='AMC Available' name='amcAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                // onChange={onStatusSearch}
                >
                  <Option key='All' value=''>All</Option>
                  <Option key='N/A' value='N/A'>N/A</Option>
                  <Option key='YES' value='YES'>YES</Option>
                  <Option key='NO' value='NO'>NO</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} >

              <Form.Item label='Warranty Available' name='warrentyAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                // onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='yes' value='yes'>Yes</Option>
                  <Option key='no' value='no'>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} >

              <Form.Item label='Insurance Available' name='insuranceAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                //onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='yes' value='yes'>Yes</Option>
                  <Option key='no' value='no'>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Asset Cost' name='assetCost'>
                <Select
                  showSearch
                  defaultValue={'All'}
                // onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='0-25,000' value='0-25,000'>0-25,000</Option>
                  <Option key='25,000-50,000' value='25,000-50,000'>25,000-50,000</Option>
                  <Option key='50,000-1 lak' value='50,000-1 lak'>50,000-1 lakh</Option>
                  <Option key='above 1 lak' value='above 1 lak'>above 1 lakh</Option>

                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={5}>
              <Form.Item label='Purchase Date' name='date'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                // onChange={EstimatedETDDate}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Warranty' name='warrantyDate'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label='Insurance' name='insuranceDate'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Asset Age' name='assetAge'>
                <Select defaultValue={'All'}>
                  <Option key='All' value=''>All</Option>
                  <Option key='0-50' value='0-50'>Below 50 days</Option>
                  <Option key='0-90' value='0-90'>Below 3 months</Option>
                  <Option key='0-180' value='0-180'>Below 6 months</Option>
                  <Option key='0-365' value='0-365'>Below 1 year</Option>
                  <Option key='0-1095' value='0-1095'>Below 3 years</Option>
                  <Option key='0-1825' value='0-1825'>Below 5 years</Option>
                  <Option key='above 1825' value='above 1825'>Above 5 years</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ marginTop: "2.3%" }} >
              <Button type='primary' onClick={onStatusSearch}>Submit</Button>
            </Col>
            <Col style={{ marginTop: "2.3%" }}>
              <Button onClick={onReset}>Reset</Button>
            </Col>
            <Col style={{ marginTop: "2.3%" }}>
              <Button type='primary' shape='round' onClick={() => generateBarcode(selectedRows)}>
                <PrinterOutlined />
              </Button>
            </Col>
            {/* <Col>
              <Link to='/items-view' ><span><Button className='panel_button'>Asset Update</Button></span></Link>
            </Col> */}
          </Row>
        </Form>
        <br />
        {/* <Row gutter={24}>

          <Col span={3}>
            <Card title={'IN-STOCK: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.INSTOCK).length} style={{ textAlign: 'left', width: 170, height: 41, backgroundColor: 'green' }} />
          </Col>
          <Col span={3}>
            <Card title={'IN-USE: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.INUSE).length} style={{ textAlign: 'left', width: 150, height: 41, backgroundColor: 'orange' }} />
          </Col>
          <Col span={5}>
            <Card title={'UNDER-MAINTENANCE: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.UNDERMAINTENANCE).length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: 'yellow' }} />
          </Col>
          <Col span={4}>
            <Card title={'DAMAGED: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.DAMAGED).length} style={{ textAlign: 'left', width: 170, height: 41, backgroundColor: 'red' }} />
          </Col>
          <Col span={5} >
            <Card title={'DECOMMISSIONED: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.DECOMMISSIONED).length} style={{ textAlign: 'left', width: 230, height: 41, backgroundColor: 'red' }} />
          </Col>
          <Col span={3} >
            <Card title={'TOTAL: ' + assetsData.length} style={{ textAlign: 'left', width: 140, height: 41, backgroundColor: 'green' }} />
          </Col>
        </Row> */}
        <br></br>
        <Tabs activeKey={activeKeyVal} onChange={ontabchange}>
          <TabPane key='1' tab={<span ><b>{`IN-STOCK : ${instockAssetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={instockAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </TabPane>
          <TabPane key='2' tab={<span><b>{`IN-USE : ${inuseAssetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={inuseAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='3' tab={<span><b>{`UNDER-MAINTENANCE : ${maintenanceAssetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={maintenanceAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='4' tab={<span><b>{`SERVICE : ${servicedAssetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={servicedAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='5' tab={<span><b>{`INTRANSIT : ${intransitAssetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={intransitAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='6' tab={<span><b>{`DAMAGED : ${damagedAssetsData.length}`}</b></span>}>
            <Table columns={columns1} dataSource={damagedAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='7' tab={<span><b>{`RETIRED : ${retairedAssetsData.length}`}</b></span>}>
            <Table columns={columns1} dataSource={retairedAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              scroll={{ x: 2500, y: 500 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
          <TabPane key='9' tab={<span ><b>{`SOLD : ${soldAssetsData.length}`}</b></span>}>
            <Table columns={columns1} dataSource={soldAssetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              scroll={{ x: 2500, y: 500 }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </TabPane>
          <TabPane key='8' tab={<span><b>{`TOTAL : ${assetsData.length}`}</b></span>}>
            <Table columns={columns} dataSource={assetsData}
              rowSelection={{
                ...rowSelection
              }}
              rowKey={(record) => record.assetCode + '$' + record.assetId}
              scroll={{ x: 2500, y: 500 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalCost = 0;

                pageData.forEach(({ assetCost }) => {
                  if (Number(assetCost)) {
                    totalCost += Number(assetCost)
                  }
                })

                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={10} colSpan={18}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>₹ {Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}

            />
          </TabPane>
        </Tabs>
        {barcode.length > 0 ? <BarcodePrint key={Date.now() + barcode[0]?.assetId} printBarcodes={closeWindow} closeBarcodePopUp={closeWindow}
          columns={barcodeWithColumns} newWindow={false} barcodeInfo={barcode} /> : ''}
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <AssetForm key={Date.now()}
              updateAsset={updateAsset}
              isUpdate={true}
              // saveItem={saveVariant}
              assetData={selectedAssetsData}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            modal == 'assetId' ? <Scanner handleScan={handleAssetIdScan} /> : null
            //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal>
      </Card>
    </>
  );
}

export default AssetGrid;

