import React, { useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { SaleOrderTypeService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

import { SaleOrderTypeDto } from '@gtpl/shared-models/masters';
import { Link, useHistory } from "react-router-dom";

import './sale-order-types-form.css';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

/* eslint-disable-next-line */
export interface SaleOrderTypesFormProps {
  SaleOrderTypeData: SaleOrderTypeDto;
  updateItem: (SaleOrderTypeData: SaleOrderTypeDto) => void;
  isUpdate: boolean;
  // saveItem:(varirantData:VariantDto) => void;
  closeForm: () => void;
}



export function SaleOrderTypesForm(
  props: SaleOrderTypesFormProps
)  {
  const [form] = Form.useForm();
  // const getCopiedData = (): SaleOrderTypeDto => {
  //   return JSON.parse(JSON.stringify(props.SaleOrderTypeData));
  // };
  // const [saleTypeData, setSaleTypeData] = useState<SaleOrderTypeDto>(getCopiedData);
  const service = new SaleOrderTypeService();
  let history = useHistory();

  const saveSaleOrderType = (SaleOrderTypeData: SaleOrderTypeDto) => {
    // SaleOrderTypeData.saleorderTypeId = 0;
    // currencyData.isActive = true;
    service
      .createSaleOrderType(SaleOrderTypeData)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Sale-Order-Type Created Successfully');
          history.push("/saleordertypes-view");
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };
  /**
   *
   * @param values //Dto values
   */
  const saveData = (values: SaleOrderTypeDto) => {
    
    
    if (props.isUpdate) {
      props.updateItem(values);
    } else {
     console.log(values);
      console.log('11111111111111111111')
      saveSaleOrderType(values);
    }
  };

  /**
   * To reset form fields
   */
  const onReset = () => {
    console.log('state onreset');
    form.resetFields();
  };
  React.useEffect(() => {
    // console.log(props.SaleOrderTypeData);
   form.setFieldsValue({
      // saleOrderTypeName:saleTypeData.saleOrderTypeName, 
    });
  }, []);
  return (
    <Card
      title={<span style={{ color: 'white' }}>Sale Order Type</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 } } extra={props.isUpdate==true?"":<Link to='/saleordertypes-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} 
    >
      <Form
        {...layout}
        form={form}
        initialValues={props.SaleOrderTypeData}
        name="control-hooks"
        onFinish={saveData}
      >
        <Form.Item name="saleorderTypeId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        {/* <Form.Item style={{ display: 'none' }} name="createdSaleOrderType">
          <Input hidden />
        </Form.Item> */}
        <Form.Item
          name="saleOrderTypeName"
          label="Sale Order Type Name"
          rules={[
            {
              required: true,
            },
            {
              pattern:/^(?!\s*$).+/,
              message: `Enter Sale Order Type Name`
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SaleOrderTypesForm;
