import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Relations } from '@gtpl/pages/hrms/hrms-components/relations';
import { Link, Redirect } from 'react-router-dom';
import {ContainerRegisterService} from '@gtpl/shared-services/logistics';
import moment from 'moment';

import './log-book-grid.css';
import { LoadingReportModel } from '@gtpl/shared-models/logistics';
import { SoStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/so-status-enum';
import { ContainerStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/container-status.enum';
import { PlantsEnum } from 'libs/shared-models/common-models/src/lib/enum/plant.enum';

/* eslint-disable-next-line */
export interface LogBookGridProps {}

export function LogBookGrid(
  props: LogBookGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [logBookData, setLogBookData] = useState<LoadingReportModel[]>([]);
  const [selectedLogBookData, setSelectedLogBookData] = useState<any>(undefined);

  const conRegService = new ContainerRegisterService;

  useEffect(() =>{
    getLoadingReportDetails();

  },[]
  );


  const getLoadingReportDetails = () => {
    conRegService.getLoadingReportDetails().then(res => {
      console.log(res.data);
     if (res.status) {
        setLogBookData(res.data);
     } else {
        if (res.intlCode) {
          setLogBookData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setLogBookData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    //TO open the form for updation
  


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    // {
    //   title: 'So Number.',
    //   dataIndex: 'saleOrderNo',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.saleOrderNo.localeCompare(b.saleOrderNo),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrderNo')
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo')
    },
    {
      title: 'Consignee',
      dataIndex: 'consignee',
      // responsive: ['lg'],
      sorter: (a, b) => a.consignee.localeCompare(b.consignee),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('consignee')
    },
    {
      title: 'Load Port',
      dataIndex: 'loadPort',
      // responsive: ['lg'],
      sorter: (a, b) => a.loadPort.localeCompare(b.loadPort),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('loadPort')
    },
    {
      title: 'Final Destination',
      dataIndex: 'finalDestination',
      // responsive: ['lg'],
      sorter: (a, b) => a.finalDestination.localeCompare(b.finalDestination),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('finalDestination')
    },
    {
      title: 'Shipping Bill No & Date',
      dataIndex: 'shippingBillNo',
      // responsive: ['lg'],
      ...getColumnSearchProps('shippingBillNo'),
      render: (text, record) => (
        <span>{record.shippingBillNo} {record.etd!=null?record.etd:"-"} </span>
      ),
      sorter: (a, b) => a.shippingBillNo?.localeCompare(b.shippingBillNo),
      sortDirections: ['descend', 'ascend'],
      
    },
    {
      title: 'Container No.',
      dataIndex: 'containerNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.containerNo?.localeCompare(b.containerNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('containerNo')
    },
    
    {
      title: 'Seal No',
      dataIndex: 'sealNo',
      // responsive: ['lg'],
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('sealNo'),
      sorter: (a, b) => {
        a = a.sealNo || '';
        b = b.sealNo || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      // responsive: ['lg'],
      sorter: (a, b) => a.vesselName.localeCompare(b.vesselName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('vesselName')
    },
    {
      title: 'B/L No.',
      dataIndex: 'billOfLadingNo',
      // responsive: ['lg'],
      ...getColumnSearchProps('billOfLadingNo'),
      sorter: (a, b) => a.billOfLadingNo?.localeCompare(b.billOfLadingNo),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'BL Date',
      dataIndex: 'SOBDate',
      // responsive: ['lg'],
      ...getColumnSearchProps('sobDate'),
      render: (text, record) => (
        <span> {record.SOBDate != null ? moment(record.SOBDate).format("YYYY-MM-DD"):"-"} </span>
      ),
      sorter: (a, b) => a.sobDate?.localeCompare(b.sobDate),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'is B/L Ok?',
      dataIndex: 'blStatus',
      // responsive: ['lg'],
      sorter: (a, b) => a.blStatus.localeCompare(b.blStatus),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('blStatus')
    },
    {
      title: 'Freight Invoice Received?',
      dataIndex: 'freightInvoiceReceived',
      render: (freightInvoiceReceived, rowData) => (
        <>
          {freightInvoiceReceived === "YES" ?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: "YES",
        },
        {
          text: 'NO',
          value: "NO",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.freightInvoiceReceived === value;
      },
    },
    {
      title: 'Freight Invoice Paid?',
      dataIndex: 'fiPaidStatus',
      // responsive: ['lg'],
      ...getColumnSearchProps('fiPaidStatus'),
      sorter: (a, b) => a.fiPaidStatus?.localeCompare(b.fiPaidStatus),
      sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Orginal B/L Received?',
      dataIndex: 'obStatus',
      render: (obStatus, rowData) => (
        <>
          {obStatus === "YES" ?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: "YES",
        },
        {
          text: 'NO',
          value: "NO",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.obStatus === value;
      },
    },
    {
      title: 'Certificate Of Origin Received?',
      dataIndex: 'coReceived',
      render: (coReceived, rowData) => (
        <>
          {coReceived?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.coReceived === value;
      },
    },
    {
      title: 'Vert Cert/Health Cert Received?',
      dataIndex: 'hoCertificateStatus',
      render: (hoCertificateStatus, rowData) => (
        <>
          {hoCertificateStatus?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.hoCertificateStatus === value;
      },
    },
    {
      title: 'Signed DS received from plant?',
      dataIndex: 'signedDsStatus',
      render: (signedDsStatus, rowData) => (
        <>
          {signedDsStatus?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.signedDsStatus === value;
      },
    },
    // {
    //   title: 'PO Number',
    //   dataIndex: 'poNo',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.poN0.length - b.poN0.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    {
      title: 'Remarks-1/PO Number',
      dataIndex: 'poNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo')
    },
    {
      title: 'Document Dispatch Status',
      dataIndex: 'docDispatchStatus',
      render: (docDispatchStatus, rowData) => (
        <>
          {docDispatchStatus?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.docDispatchStatus === value;
      },
    },
    {
      title: 'ISF Filling',
      dataIndex: 'isfWorksheetUploaded',
      render: (isfWorksheetUploaded, rowData) => (
        <>
          {isfWorksheetUploaded === "YES" ?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isfWorksheetUploaded === value;
      },
    },
    {
      title: 'Remarks-2/Booking Number',
      dataIndex: 'refNo',
      sorter: (a, b) => a.refNo?.localeCompare(b.refNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('refNo')
    },
    // {
    //   title: 'VC NO.',
    //   dataIndex: '',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.remarks.length - b.remarks.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    
    {
      title: 'Shipping Copy Bill received?',
      dataIndex: 'shippingBillReceivedStatus',
      render: (shippingBillReceivedStatus, rowData) => (
        <>
          {shippingBillReceivedStatus === "YES" ?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: "YES",
        },
        {
          text: 'NO',
          value: "NO",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.shippingBillReceivedStatus === value;
      },
    },
    {
      title: 'Loading Survey Report Received?/Telex Release',
      dataIndex: 'loadingSurveyStatus',
      render: (loadingSurveyStatus, rowData) => (
        <>
          {loadingSurveyStatus === "YES" ?"YES":"NO"}
          
        </>
      ),
      filters: [
        {
          text: 'YES',
          value: "YES",
        },
        {
          text: 'NO',
          value: "NO",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.loadingSurveyStatus === value;
      },
    },
    {
      title: 'Insurance Status',
      dataIndex: '',
      // responsive: ['lg'],
      // sorter: (a, b) => a.remarks?.length - b.remarks.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('productName')
    },
    {
      title: 'Payment Terms',
      dataIndex: 'paymentTerms',
      sorter: (a, b) => a.paymentTerms?.localeCompare(b.paymentTerms),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('paymentTerms')
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width:'200px',
      sorter: (a, b) => a.remarks?.localeCompare(b.remarks),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('remarks')
    },
    {
      title: 'Invoice Value in USD',
      dataIndex: 'invoiceAmount',
      // responsive: ['lg'],
      sorter: (a, b) => a.invoiceAmount?.length - b.invoiceAmount.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoiceAmount')
    },
    {
      title: 'FOB Value in USD',
      dataIndex: 'fobInUsd',
      // responsive: ['lg'],
      sorter: (a, b) => a.fobInUsd?.length - b.fobInUsd?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fobInUsd')
    },
    
    {
      title: 'FOB Value in Rupees',
      dataIndex: 'fobInInr',
      // responsive: ['lg'],
      // sorter: (a, b) => a.fobInInr?.localeCompare(b.fobInInr),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fobInInr')
    },
    {
      title: 'IGST Value in Rupees',
      dataIndex: 'taxAmntInInr',
      responsive: ['lg'],
      sorter: (a, b) => a.taxAmntInInr?.localeCompare(b.taxAmntInInr),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('taxAmntInInr')
    },
    {
      title: 'Is Filling Done?',
      dataIndex: '',
      // responsive: ['lg'],
      sorter: (a, b) => a.processingPlant?.localeCompare(b.processingPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('productName')
    },
    {
      title: 'BAP Unit',
      dataIndex: 'manufacturingPlant',
      // responsive: ['lg'],
      sorter: (a, b) => a.manufacturingPlant?.length - b.manufacturingPlant?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('manufacturingPlant')
    },
    {
      title: 'Prod Unit',
      dataIndex: 'processingPlant',
      // responsive: ['lg'],
      sorter: (a, b) =>a.processingPlant?.localeCompare(b.processingPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('processingPlant')
    },
    {
      title: 'End Customer',
      dataIndex: 'endCustomer',
      // responsive: ['lg'],
      sorter: (a, b) => a.endCustomer?.localeCompare(b.endCustomer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomer')
    },

    {
      title: 'Plant',
      dataIndex: 'plant',
      filters:[

        {
          text:PlantsEnum.UNIT_ONE,
          value:PlantsEnum.UNIT_ONE,
        },
        {
          text:PlantsEnum.UNIT_TWO,
          value:PlantsEnum.UNIT_TWO,
        },
        {
          text:PlantsEnum.KAKINADA,
          value:PlantsEnum.KAKINADA,
        },
      ],
        filterMultiple: true,
        onFilter: (value, record) =>{
           return record.SoStatusEnum === value;
        }
    },

    {
      title: 'SO Status',
      dataIndex: 'soStatus',
      
      filters:[

        {
          text:SoStatusEnum.OPEN,
          value:SoStatusEnum.OPEN,
        },
        {
          text:SoStatusEnum.IN_PROGRESS,
          value:SoStatusEnum.IN_PROGRESS,
        },
        {
          text:SoStatusEnum.CLOSED,
          value:SoStatusEnum.CLOSED,
        },
      ],
        filterMultiple: true,
        onFilter: (value, record) =>{
           return record.SoStatusEnum === value;
        } 
    },

    {
      title: 'Container Status',
      dataIndex: 'containerStatus',

      filters:[

        {
          text:ContainerStatusEnum.NOT_READY,
          value:ContainerStatusEnum.NOT_READY,
        },
        {
          text:ContainerStatusEnum.READY_TO_BOOK,
          value:ContainerStatusEnum.READY_TO_BOOK,
        },
        {
          text:ContainerStatusEnum.BOOKED,
          value:ContainerStatusEnum.BOOKED,
        },
        {
          text:ContainerStatusEnum.DISPATCHED_TO_PLANT,
          value:ContainerStatusEnum.DISPATCHED_TO_PLANT,
        },
        {
          text:ContainerStatusEnum.LOADING,
          value:ContainerStatusEnum.LOADING,
        },
        {
          text:ContainerStatusEnum.LOADED_AND_SEALED,
          value:ContainerStatusEnum.LOADED_AND_SEALED,
        },
        {
          text:ContainerStatusEnum.SHIPPED,
          value:ContainerStatusEnum.SHIPPED,
        },
        {
          text:ContainerStatusEnum.OPEN,
          value:ContainerStatusEnum.OPEN,
        },
      ],
    },



      {
        title: ' Status',
        dataIndex: 'status',
        // sorter: (a, b) => a.status?.localeCompare(b.status),
        // sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('status')
        render: (Status, rowData) => (
          <>
            {Status}
            
          </>
        ),
        filters: [
          {
            text: 'OPEN',
            value: "OPEN",
          },
          {
            text: 'CLOSE',
            value: "CLOSE",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.status === value;
        },
      },
      
  
  ]

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   


  }
  return (
    <Card title={<span style={{color:'white'}}>Log Book</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}
    >

      
     <br></br>
     
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          scroll={{x:true}}
          dataSource={logBookData}
         
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
           />
      
     </Card>
  );
}

export default LogBookGrid;
