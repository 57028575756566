import React, { useEffect, useState } from 'react';
import {Button, Card, Switch,Popconfirm,Divider,Table, Drawer,Form,Input, Row, Col} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import './pages-asset-management-asset-management-components-asset-po-grid.css';
import { ColumnsType } from 'antd/lib/table';
import { AssetCatagoryService } from '../../../../../../shared-services/asset-management/src/lib/asset-category';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { AssetsCatagoryDto, AssetsCatagoryResponse } from 'libs/shared-models/asset-management/src/lib/assets-catagory';

/* eslint-disable-next-line */
export interface AssetCategoryProps {}

export function AssetCategory(
  props: AssetCategoryProps
) {
  const [selectedCatagoryData, setSelectedCatagoryData] = useState<any>(undefined);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [page,setPage] = useState<number>(1);
  const [assetsData,setAssetsData] = useState<any>([])
  const service = new AssetCatagoryService();
  const [form] = Form.useForm();

  useEffect(()=>{
    getAllAssetsCatagory();
  },[])

  const getAllAssetsCatagory = () => {
    service.getAllAssetsCatagory().
    then(res => {
        if(res.status) {
          setAssetsData(res.data)
          AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
  }
  const activateDeactivate = (data: AssetsCatagoryResponse) => {
    service.activateorDeactivateAssets(data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const openFormWithData = (viewData: AssetsCatagoryDto) => {
    setDrawerVisible(true);
    setSelectedCatagoryData(viewData);
  }

  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  const updateCategory = (Data) => {
  
  }


  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      key: "sno",
      responsive: ["sm"],
      width:130,
      render: (text,object,index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Id',
      dataIndex: 'assetsCatagoryId',
      width:130,
    },
    {
      title: 'Asset-Category',
      dataIndex: 'assetsCatagoryName',
      width:130,
    },
    {
      title: 'Action',
      dataIndex:'action',
      width:130,
      render:(text,rowData) => (
        <span>
        <EditOutlined className={'editSamplTypeIcon'} type="edit"
          onClick={() => {
            if (rowData.isActive) {
              openFormWithData(rowData);
            } else {
              AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item');
            }
          }}
          style={{ color: '#1890ff', fontSize: '14px' }}
        />

        <Divider type="vertical" />
        <Popconfirm onConfirm={e => { activateDeactivate(rowData); }}
          title={
            rowData.isActive
              ? 'Are you sure to Deactivate Item ?'
              : 'Are you sure to Activate Item ?'
          }
        >
          <Switch size="default"
            className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
            checkedChildren={<RightSquareOutlined type="check" />}
            unCheckedChildren={<RightSquareOutlined type="close" />}
            checked={rowData.isActive}
          />

        </Popconfirm>
      </span>
      )
    },
    
  ];
  
  return (
    <Card title={<span style={{color:'white'}}>Assets-Category</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-category-form' ><Button className='panel_button' >Create </Button></Link>}>  <br></br>
   <Table columns={columns} dataSource={assetsData} />

   <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
    onClose={closeDrawer} visible={drawerVisible} closable={true}>
  <Card>
    <Form initialValues={selectedCatagoryData} form={form} onFinish={updateCategory}>
      <Row>
      <Col>
      <Form.Item label='Category Id' name='assetsCatagoryId'
        rules={[{
          required:true,
        }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item label='Category Name' name='assetsCatagoryName'
        rules={[{
          required:true,
        }]}
      >
        <Input/>
      </Form.Item>
      </Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign:'right'}}>
        <Form.Item>
          <Button type='primary' htmlType='submit'>Submit</Button>
        </Form.Item>
        {[] && <Form.Item>
          <Button>Reset</Button>
        </Form.Item>}
        </Col>
      </Row>
    </Form>
  </Card>
 </Drawer>
    </Card>
  );
}

export default AssetCategory;
