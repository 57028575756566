import React,{useState, useEffect} from 'react';
import {Form, Input, Button, Card, Row, Col, DatePicker, Select} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import {TypesOfLeavesDto} from '@gtpl/shared-models/hrms';
import { TypesOfLeavesService } from '@gtpl/shared-services/hrms';

import './types-of-leaves.css';


/* eslint-disable-next-line */
export interface TypesOfLeavesProps {
  typesOfLeavesData: TypesOfLeavesDto;
  updateTypesOfLeaves: (typesOfLeaves: TypesOfLeavesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function TypesOfLeaves(
  props: TypesOfLeavesProps
) {

  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  let history = useHistory();
  const typesOfLeavesService = new TypesOfLeavesService()

  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }

  const createTypesOfLeaves = (Data: TypesOfLeavesDto) => {
    setDisable(true)
    typesOfLeavesService.createTypesOfLeaves(Data).then(res => {
      setDisable(false)
      if(res.status) {
        AlertMessages.getSuccessMessage('Type Of Leave created successfully');
        history.push('/types-of-leaves-grid');
        onReset();
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    console.log('');
    form.resetFields();
    
  }

  const saveData = (values: TypesOfLeavesDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values);
      props.updateTypesOfLeaves(values);
    }else {
      setDisable(false)
      console.log(values);
      createTypesOfLeaves(values);
    }
  };
  return (
    <Card title={<span style={{color:'white'}}>Type of Leaves</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/types-of-leaves-grid' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.typesOfLeavesData} >
        <Form.Item 
        name ="typeOfLeaveId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="typeOfLeave"
                label="Type of Leave"
                rules={[
                  {
                    required: true,
                    message:"Type of Leave is required",
                    max:18
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=~{}:";<>,.?|][a-zA-Z'. ]*$/,
                    message: `Should contain only alphabets`
                  }
                ]}>


                <Input/>
          </Form.Item>

        </Col>

        

        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="leaveCode"
                label="Leave Code"
                rules={[
                  {
                    required: true,
                    message:"Leave Code is required",
                    max:12
                  },
                   {
                     pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9 ]*$/,
                     message: `Should contain only alphabets and numbers.`
                   }
                ]}>
                <Input/>
          </Form.Item>
          </Col>

          <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="defaultLeaves"
                label="Defualt Leaves"
                rules={[
                  {
                    required: true,
                    message:"Valid Default Leaves required",max:2
                  },
                  {
                    pattern: /^[0-9]*$/ ,
                    message: `Should contain only  numbers.`
                  }
                ]}>
                <Input/>
          </Form.Item>
          </Col>
       
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
  </Card>
  );
}

export default TypesOfLeaves;
