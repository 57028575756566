import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, Descriptions, Tag } from 'antd';
import './asset-transfers-form.css';
import TextArea from 'antd/lib/input/TextArea';
import { DepartmentService, EmployeeService, ItemSubCategoryService, ItemsService, UnitcodeService } from "@gtpl/shared-services/masters";
import { AssetService, AssetTransfersService } from "@gtpl/shared-services/asset-management";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import { AssetsDropDown, PlantsDropDown } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import { AssetsCatagoryByIdReq } from 'libs/shared-models/asset-management/src/lib/assets-catagory/assets-catagory-id';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
const { Option } = Select;
import{AssetsDto}  from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';

/* eslint-disable-next-line */
export interface AssetTransfersFormProps {}

export function AssetTransfersForm(
  props: AssetTransfersFormProps
) {

  let history = useHistory();
  const [form] = Form.useForm();
  const [assetsData, setAssetsData] = useState<any[]>([]);
  const [isDateDisabled, setIsDateDisabled] = useState<boolean>(true);
  const [plantInfo, setPlantInfo] = useState<PlantsDropDown[]>([]);
  const [assetsList, setAssetsList] = useState<AssetsDropDown[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [hiddenIndent, setHiddenIndent] = useState<boolean>(false);
  const [hiddenToPlant, setHiddenToPlant] = useState<boolean>(false);
  const [showAssetDetails, setShowAssetDetails] = useState<boolean>(false);
  const [itemSubcatData, setItemSubcatData] = useState<any[]>([]);
  const dropDrownInfo = (key, value) => <Option key={key} value={key}>{value}</Option>
  const [previewdata, setPreviewData] = useState<AssetsDto>(undefined);
  const [itemdata, setItemData] = useState<any>(undefined);
  const [depart,setDepart] = useState<any[]>([]);
  const [employees,setEmployees] = useState<any[]>([]);

  
  const unitService = new UnitcodeService();
  const assetService = new AssetService();
  const itemsService = new ItemsService();
  const assetTransfersService = new AssetTransfersService();
  const itemSubcatService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const employeeService = new EmployeeService();


  const getEmployees = () => {
    employeeService.getAllActiveEmployees().then(res => {
      if(res.status){
        setEmployees(res.data)
      } else {
        setEmployees([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setEmployees([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getItemSubCategory = () => {
    itemSubcatService.getGeneralItemSubCategories().then((res) => {
      if (res.status) {
        setItemSubcatData(res.data);
      } else {
        setItemSubcatData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setItemSubcatData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantInfo(res.data);
        form.setFieldsValue({fromPlantName:Number(localStorage.getItem("unit_id"))})
      } else {
        setPlantInfo([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantInfo([]);
    });
  }

  const getAssetsByStatus = () => {
    itemsService.getAssetDropdown().then((res) => {
      if (res.status) {
        setAssetsList(res.data);
      } else {
        setAssetsList([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setAssetsList([]);
    });
  }

  
  const getAssetCode = () => {
    const req = new AssetsCatagoryByIdReq()
    req.assetsCatagoryId = form.getFieldValue('assetCategoryId')
    req.locationId = form.getFieldValue('location')
    req.unitId = Number(localStorage.getItem("unit_id"));
    assetService.getAsssetForCategory(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        setAssetsData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setAssetsData([]);
    })
  }

 

  const getDetailedView = (assetId) => {
    const req = new AssetByCode(assetsData.find((rec) => rec.assetId === assetId).assetCode);
    assetService.getByAssetCode(req).then(res => {
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        setShowAssetDetails(true);
      } else {
        setPreviewData(undefined);
        setItemData(undefined)
        setShowAssetDetails(false);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      setPreviewData(undefined);
      setItemData(undefined)
      setShowAssetDetails(false);
      AlertMessages.getErrorMessage(err.message)

    })
  }
  const transferStock = (values: any) => {
    console.log(values);
    assetTransfersService.createAssetTransfers(values).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.message);
        history.push('./view-asset-transfers');
      } else {
        AlertMessages.getErrorMessage(res.message);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const checkTransferType = (val) => {
    console.log(form.getFieldValue("transferType"));
    if(form.getFieldValue("transferType") === "INDENT"){
      setHiddenIndent(true);
      setHiddenToPlant(false);
    }
    else if(form.getFieldValue("transferType") === "EXTERNAL"){
      setHiddenToPlant(true);
      setHiddenIndent(false);
    }
    else{
      setHiddenIndent(false);
      setHiddenToPlant(false);
    }
  }
  const onReset = () => {
    form.resetFields();
    setBtnDisable(false);
  };
  

  useEffect(() => {
    checkTransferType("EXTERNAL")
    getAllPlants();
    getAssetsByStatus();
    getItemSubCategory();
    getEmployees();
  }, []);

  return (
    <Card title={<span style={{ color: 'white' }}> Asset Transfer</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<><Tag><span style={{backgroundColor:'white', color:'black','fontSize':'10px'}}>FROM PLANT : {plantInfo?.find((rec) => rec.plantId === Number(localStorage.getItem("unit_id")))?.plantName}</span></Tag><Link to='/view-asset-transfers'><span style={{ color: 'white' }}><Button className='panel_button'>View</Button> </span></Link></>}
  >
    <Form layout="vertical" form={form} name="control-hooks"  onFinish={transferStock}>
      <Form.Item name="assetTransferId" style={{ display: 'none' }}>
        <Input hidden />
      </Form.Item>
      <Form.Item name="createdUser" style={{ display: 'none' }} initialValue={localStorage.getItem("username")}>
        <Input hidden />
      </Form.Item>
      <Form.Item name="transferedQuantity" style={{ display: 'none' }} label="Required Quantity" rules={[
              {
                required: true,
                message: 'Required Quantity is required',
              },
            ]}
            initialValue={0}
          >
        <InputNumber min={0}  style={{ width: '100%' }} />
      </Form.Item>
      <Row gutter={24} >
        <Col span={3}>
          <Form.Item
            name="transferredDate"
            label="Date"
            rules={[
              {
                required: true,
                message: 'Transfer Date is required',
              },
            ]}
            initialValue={moment()}
          >
            <DatePicker disabled={isDateDisabled} showTime style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        
          <Form.Item style={{display:'none'}}
            name="fromPlant"
            label="from Plant"
            rules={[
              {
                required: true,
                message: 'From Plant is required',
              },
            ]}
            initialValue={Number(localStorage.getItem("unit_id"))}
          >
            <Select
            placeholder="Select From Plant"
            allowClear
            showSearch
            style={{ width: '100%' }}
            disabled={true}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option key={'plant'} value={''}>Select From Plant</Option>
            {
              plantInfo?.map(dropData => {
                return dropDrownInfo(dropData.plantId, dropData.plantName)
              })
            }
          </Select>
          </Form.Item>
        <Col span={3}>
          <Form.Item
            name="transferType"
            label="Transfer Type"
            rules={[
              {
                required: true,
                message: 'Transfer Type is required',
              },
            ]}
            initialValue={"EXTERNAL"}
          >
            <Select
              placeholder="Select Transfer Type"
              allowClear
              showSearch
              style={{ width: '100%' }}
              onChange = {checkTransferType}
              disabled={true}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                <Option label="transferType" value="">select Transfer Type</Option>
                <Option label="transferType" value="INTERNAL">Internal</Option>
                <Option label="transferType" value="EXTERNAL">Plant To Plant</Option>
                <Option label="transferType" value="INDENT">Indent</Option>

            </Select>
          </Form.Item>
        </Col>
        {
          hiddenIndent?

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="indentId"
              label="Indent"
              rules={[
                {
                  required: true,
                  message: 'Indent is required',
                },
              ]}
            >
            <Select
              placeholder="Select Indent"
              allowClear
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option key={'asset'} value={''}>Select Asset</Option>
              {/* {assetsData.map((assets) => {
                    return <Option key={assets.assetId} value={assets.assetId}>{assets.name}</Option>
                  })
              } */}
            </Select>
            </Form.Item>
          </Col>
          :""
        }
        {
          hiddenToPlant?
          <Col span={3}>
            <Form.Item
              name="toPlant"
              label="To Plant"
              rules={[
                {
                  required: true,
                  message: 'To Plant is required',
                },
              ]}
            >
            <Select
              placeholder="Select To Plant"
              allowClear
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {/* <Option key={'plant'} value={''}>Select To Plant</Option> */}
              {
                plantInfo?.map(dropData => {
                  return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? dropDrownInfo(dropData.plantId, dropData.plantName) : ''
                })
              }
            </Select>
            </Form.Item>
          </Col>
          :""  
        }
        <Col span={3}>
          <Form.Item name="transferedBy" label="Transfered By" rules={[
              {
                required: true,
                message: 'Transfered By is required',
              },
            ]} >
            <Select
            placeholder="Select Transfered By"
            allowClear
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
            {/* <Option key={'plant'} value={''}>Select Transfered By</Option> */}
            {
              employees?.map(dropData => {
                return dropDrownInfo(dropData.employeeId, dropData.employeeName)
              })
            }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item label={'Asset Category'} name="assetCategoryId">
              <Select
                placeholder='Select Asset Category'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={getAssetCode} >
                {itemSubcatData.map((itemSubcat) => {
                  return <Option key={itemSubcat.itemSubCategoryId} value={itemSubcat.itemSubCategoryId}>{`${itemSubcat.itemSubCategory}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="assetsId"
              label="Asset"
              rules={[
                {
                  required: true,
                  message: 'Asset is required',
                },
              ]}
            >
            <Select
              placeholder="Select Assets"
              allowClear
              showSearch
              style={{ width: '100%' }}
              onChange={getDetailedView}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option key={'asset'} value={''}>Select Asset</Option>
              {assetsData.map((assets) => {
                    return <Option key={assets.assetId} value={assets.assetId}>{assets.name}</Option>
                  })
              }
            </Select>
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={24} >
            {
              showAssetDetails?
                <Descriptions>
                <Descriptions.Item label="Asset Category" style={{paddingLeft:'10px'}}> {itemdata?.assetCategory}</Descriptions.Item>
                <Descriptions.Item label="Asset Name"> {itemdata?.assetname}</Descriptions.Item>
                <Descriptions.Item label="Asset Serial Number"> {previewdata?.assetSerialNo}</Descriptions.Item>
                <Descriptions.Item label="Asset Code" style={{paddingLeft:'10px'}}> {previewdata?.assetsCode} </Descriptions.Item>
                <Descriptions.Item label="Modal"> {previewdata?.model} </Descriptions.Item>
                <Descriptions.Item label="Date Of Purchased" > {moment(previewdata?.dateOfPurchased).format('YYYY-MM-DD HH:mm:ss')} </Descriptions.Item>
                <Descriptions.Item label="Asset Cost" style={{paddingLeft:'10px'}}> {previewdata?.assetCost} </Descriptions.Item>
                <Descriptions.Item label="Asset Supplier"> {previewdata?.assetSupplier} </Descriptions.Item>
                <Descriptions.Item label="AMC Availability" > {previewdata?.amc} </Descriptions.Item>
                <Descriptions.Item label="Warranty  Availability" style={{paddingLeft:'10px'}}> {previewdata?.warrenty} </Descriptions.Item>
                <Descriptions.Item label="Warranty Valid Upto">{previewdata?.warrentyExpiredDate ? moment(previewdata?.warrentyExpiredDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
                <Descriptions.Item label="Insurance" > {previewdata?.insurance} </Descriptions.Item>
                <Descriptions.Item label="Insurance Valid Upto" style={{paddingLeft:'10px'}}>{previewdata?.insuranceDate ? moment(previewdata?.insuranceDate).format('YYYY-MM-DD'):'-'}  </Descriptions.Item>
                <Descriptions.Item label="Current Location"> {itemdata?.location_name} </Descriptions.Item>
                </Descriptions>
              :""
            }
          </Row>
        <Row gutter={24}>
        {/* <Col span={3}>
          <Form.Item label='Department' name='departmentId'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'All'}
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All</Option>
            {depart.map((e) => {
              return<Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col> */}
          
          
          <Col span={6}>
            <Form.Item name="note" label="Note">
              <TextArea showCount />
            </Form.Item>
          </Col>
      </Row>
      <Row gutter={24}>
        
        <Col style={{paddingTop:'33px'}}>
          <Button type="primary" htmlType="submit" block disabled={btnDisable}>
            Submit
          </Button>
        </Col>
        <Col style={{paddingTop:'33px'}}>
          <Button
            htmlType="button"
            style={{ margin: '0 14px' }}
            onClick={onReset}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
    </Card>
  );
}

export default AssetTransfersForm;
