
import { Button, Card, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import { saleOrder, SaleOrderService } from "@gtpl/shared-services/sale-management";
import { UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';

export interface SoItemInProgressProps {
    saleOrderId: number;
    currencyId: number;
 }

export function SoItemInProgress(props: SoItemInProgressProps) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [soInProgressData, setSoInProgressData] = useState<any[]>([]);
    const searchInput = useRef(null);
    const service = new SaleOrderService;
    const [page, setPage] = React.useState(1);

    useEffect(()=>{
        getSoItemDetailsForInProgress()
    },[])

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };

    const getSoItemDetailsForInProgress = () => {
        console.log(props.saleOrderId)
        service.getSoItemDetailsForInProgress(new saleOrder(props.saleOrderId)).then(res => {
    
          if (res.status) {
            // for (const saleDetail of res.data){
            //   const uomData = UnitsOfWeightInput.find(uom => uom.value == Number(saleDetail.uomId));
            //   const itemDescription = `${saleDetail.varientId} ${saleDetail.pouches} * ${Number(saleDetail.pouchWeight)} ${uomData.name}`;
            //   saleDetail.varientId=itemDescription
            // }
            setSoInProgressData(res.data);
          } else {
            if (res.intlCode) {
              setSoInProgressData([]);
            } else {
              setSoInProgressData([]);
            }
          }
        }).catch(err => {
        })
      }

    const Columns: ColumnsType<any> = [
        {
            title: 'Brand',
            dataIndex: 'brand',
            width: 150,
            fixed: 'left',
            sorter: (a, b) => a.brand.localeCompare(b.brand),
            ...getColumnSearchProps('brand'),
        },
        {
            title: 'Grade',
            dataIndex: 'minGrade',
            //key: 'minGrade',
            sorter: (a, b) => a.minGrade - b.minGrade,
            ...getColumnSearchProps('minGrade'),
            render: (text, data, index) => {
                console.log(text, data, index)
                return <span>{`${text}-${data.maxGrade} `}</span>
            }
        },
        {
            title: 'Item Description',
            dataIndex: 'varientId',
            sorter: (a, b) => a.varientId - b.varientId,
            ...getColumnSearchProps('varientId'),
            render: (text, data, index) => {
                console.log(text, data, index)
                const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
                return <span>{text}</span>
              }
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            sorter: (a, b) => a.product - b.product,
            ...getColumnSearchProps('product'),
        },
        {
            title: 'Net Weight',
            dataIndex: 'netWeight',
            key: 'netWeight',
            sorter: (a, b) => a.netWeight - b.netWeight,
            ...getColumnSearchProps('netWeight'),
        },
        {
            title: 'Max Count',
            dataIndex: 'maxCount',
            key: 'maxCount',
            sorter: (a, b) => a.maxCount - b.maxCount,
            ...getColumnSearchProps('maxCount'),
        },
        {
            title: 'Min Count',
            dataIndex: 'minCount',
            key: 'minCount',
            sorter: (a, b) => a.minCount - b.minCount,
            ...getColumnSearchProps('minCount'),
        },
        {
            title: 'Lot No',
            dataIndex: 'lotNo',
            key: 'lotNo',
            sorter: (a, b) => a.lotNo - b.lotNo,
            ...getColumnSearchProps('lotNo'),
        },
        {
            title: 'Actual Count',
            dataIndex: 'actualCount',
            key: 'actualCount',
            sorter: (a, b) => a.actualCount - b.actualCount,
            ...getColumnSearchProps('actualCount'),
        },
        {
            title: 'Actual Qty Kgs ',
            dataIndex: 'actualQty',
            key: 'actualQty',
            sorter: (a, b) => a.actualQty - b.actualQty,
            ...getColumnSearchProps('actualQty'),
        },

    ]
    return (
        <>
            <Card
                key={props.saleOrderId + props.currencyId}
                style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>SALE ORDER ITEMS </span>}></Card>
            <Table
                columns={Columns}
                dataSource={soInProgressData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                // onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered
            ></Table>
        </>
    )
}
export default SoItemInProgress;