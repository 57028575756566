import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select } from "antd";
import { AssetDto, AssetServiceChecklistDTO, AssetServiceDto, AssetsId } from "libs/shared-models/asset-management/src/lib/open-assets";
import { AssetService, AssetLocationService } from "@gtpl/shared-services/asset-management";
import { ItemSubCategoryService } from "@gtpl/shared-services/masters"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AssetsCatagoryByIdReq } from "libs/shared-models/asset-management/src/lib/assets-catagory/assets-catagory-id";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import FormItem from "antd/lib/form/FormItem";
import AssetServiceCheckList from "./asset-service-checklist";
import { PlusSquareOutlined } from '@ant-design/icons'
import { Link, useHistory, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto";
import { ServiceStatusEnum } from "@gtpl/shared-models/common-models";

const { MonthPicker } = DatePicker

export enum FilterWith {
  Location = 'Location',
  AssetCategory = 'Asset Category',
  Both = 'Both'
}

export enum ServiceFrequency {
  Weekly = 'Weekly',
  FortWeekly = 'Fort Nightly',
  Monthly = 'Monthly',
  QuarterYearly = 'Quarterly',
  HalfYearly = 'Half yearly',
  Yearly = 'Yearly',
  Weekend = 'Weekend',
  Daily = 'Daily'
}
export interface AssetServiceFormProps {
  serviceData: any,
  updateService: (createAssetService: AssetServiceDto) => void;
  isUpdate: boolean,
  closeForm: () => void;

}
export function AssetServiceForm(props: AssetServiceFormProps) {
  const [form] = Form.useForm();
  const [assetsData, setAssetsData] = useState<any[]>([]);
  const [itemSubcatData, setItemSubcatData] = useState<any[]>([]);
  const [locationData, setLocationData] = useState<any[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const [updateState, setUpdateState] = useState<number>(1);
  const [filterWith, setFilterWith] = useState(FilterWith.Location);
  const [showChecklistModal, setShowChecklistModal] = useState<boolean>(false)
  const [frequencyOfService, setFrequencyOfService] = useState<ServiceFrequency>(ServiceFrequency.Weekly)
  const [checklistData, setChecklistData] = useState<AssetServiceChecklistDTO[]>([])
  const [serviceType, setServiceType] = useState<any>('unPlanned')
  const options = [
    { label: FilterWith.Location, value: FilterWith.Location },
    { label: FilterWith.AssetCategory, value: FilterWith.AssetCategory },
    { label: FilterWith.Both, value: FilterWith.Both },
  ];
  const weekdaysArr = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday','3rdSunday','4thSunday']


  const assetService = new AssetService();
  const itemSubcatService = new ItemSubCategoryService();
  const locationService = new AssetLocationService()
  const { Option } = Select;
  const plantId = Number(localStorage.getItem('unit_id'));
  const { id, status, assetServiceId, startDate, serviceProvider, serviceName, remarks } = useParams<any>()
  const history = useHistory()


  useEffect(() => {
    getAssetCode();
    getItemSubCategory();
    getLocation();
  }, [])

  useEffect(() => {
    if (id) {
      form.setFieldsValue(
        {
          assetId: atob(id),
          status: atob(status) == ServiceStatusEnum.OPEN ? ServiceStatusEnum.INPROGRESS : atob(status) == ServiceStatusEnum.INPROGRESS ? ServiceStatusEnum.COMPLETE : ServiceStatusEnum.OPEN,
          serviceProvider: atob(serviceProvider),
          serviceName: atob(serviceName),
          assetServiceDate: moment(atob(startDate)),
          remarks: atob(remarks)
        })
      getAllAssetsDataById()

    } else {
      form.setFieldsValue({ status: ServiceStatusEnum.OPEN })
    }
  }, [assetsData])

  const getAssetCode = () => {
    setDisable(false)
    const req = new AssetsCatagoryByIdReq()
    req.assetsCatagoryId = form.getFieldValue('assetCategoryId')
    req.locationId = form.getFieldValue('location')
    req.unitId = plantId
    assetService.getAsssetForCategory(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        setAssetsData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setAssetsData([]);
    })
  }

  const getLocation = () => {
    const req = new LocationByPlant(plantId)
    locationService.getLocationByPlant(req).then((res) => {
      if (res.status) {
        setLocationData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getItemSubCategory = () => {
    itemSubcatService.getGeneralItemSubCategories().then((res) => {
      if (res.status) {
        setItemSubcatData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getAllAssetsDataById = () => {
    setDisable(false)
    const req = new AssetsId()
    for(let i=0 ;i<req.assetId;i++){
    req.assetId = id ? atob(id) : form.getFieldValue('assetId')
    }
    assetService.getAllAssetsDataById(req).then((res) => {
      if (res.status) {
        // setAssetsData(res.data);
        form.setFieldsValue({ assetName: res.data[0].item_id, assetId: res.data[0].assetId, itemName: res.data[0].assetName, dateOfPurchased: moment(res.data[0].dateOfPurchased), locationName: res.data[0].location_name })
        if (id) {
          form.setFieldsValue({ assetCategoryId: res.data[0].assetCategoryId })
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onReset = () => {
    form.resetFields();

  };


  const createService = (createDto: AssetServiceDto) => {
    console.log(createDto)
    setDisable(true)
    createDto.assetServiceChecklist = checklistData
    createDto.month = moment(createDto.month).format('MMMM')
    createDto.StartDate=moment(createDto.StartDate).format('YYYY-MM-DD')
    createDto.endDate=moment(createDto.endDate).format('YYYY-MM-DD')
    createDto.completedDate=moment(createDto.endDate).format('YYYY-MM-DD')


    assetService.createAssetService(createDto)
      .then((res) => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage('Service Created Successfully');
          setServiceType('unPlanned')
          if (id) {
            history.push('/asset-service-calender')
          }
          onReset();
          form.setFieldsValue({ status: ServiceStatusEnum.OPEN })
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const updateStatus = () => {
    console.log('update')
    const req = new AssetByCode(form.getFieldValue('assetId'), Number(atob(assetServiceId)), form.getFieldValue('status'), form.getFieldValue('remarks'))
    assetService.updateAssetStatus(req).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Sucessfully')
        history.push('/asset-service-grid')
      } else {
        AlertMessages.getErrorMessage('Something went wrong')
      }
    })
  }

  function saveAsset(values) {
    if (status && form.getFieldValue('status') != ServiceStatusEnum.OPEN) {
      updateStatus()
    } else
      createService(values)
  }

  const disableDate = (current) => {
    return current < moment().subtract(1,'day').endOf('day')
  }

  const dissableEndDate = (current) => {
    return current < moment(form.getFieldValue('startDate')).endOf('day')
  }


  return (
    <Card title={<span style={{ color: "white" }}>Schedule service</span>} style={{ textAlign: "center" }} headStyle={{ backgroundColor: "#69c0ff", border: 0 }} extra={<><Link to='/asset-service-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link><Link to='/asset-service-calender' ><span style={{ color: 'white' }} ><Button className='panel_button' >Calendar </Button> </span></Link></>}
    >
      <Form form={form} onFinish={saveAsset} initialValues={[props.serviceData]} name="control-hooks" layout="vertical" >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} hidden>
            <Form.Item label={'Location'} name="location">
              <Select
                placeholder='Select Location'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={getAssetCode}
              >
                {locationData.map((location) => {
                  return <Option key={location.locationId} value={location.locationId}>{`${location.locationName}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item label={'Asset Category'} name="assetCategoryId">
              <Select
                placeholder='Select Asset Category'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={getAssetCode} >
                {itemSubcatData.map((itemSubcat) => {
                  return <Option key={itemSubcat.itemSubCategoryId} value={itemSubcat.itemSubCategoryId}>{`${itemSubcat.itemSubCategory}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="assetId"
              label='Asset Code'
              rules={[
                {
                  required: true,
                  message: "Asset Code is required"
                },
              ]}
            >
              {/* <Input  placeholder="asset code"/> */}
              <Select
                mode='multiple'
                disabled={id ? true : false}
                placeholder='Enter or Scan Asset Code'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={getAllAssetsDataById}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetsData.map((assets) => {
                  return <Option key={assets.assetId} value={assets.assetId}>{assets.name}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} hidden>
            <Form.Item name="assetName">
              <Input hidden />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} >
            <Form.Item name="locationName" label='Location'>
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} hidden>
            <Form.Item name="itemName"
              label="Asset Name">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="serviceTenure" label="Service Tenure">
              <Input disabled />
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="dateOfPurchased" label="Date Of Purchased">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} >
            <Form.Item name="serviceType" initialValue={serviceType} label="Service Type" rules={[
              {
                required: true,
                message: 'Service Type is required',
              },
            ]}
            >
              <Select defaultValue={'unPlanned'} value={serviceType} onChange={setServiceType} allowClear placeholder="Select Service Type" >
                <Option key={1} value={'planned'}>
                  {' '}
                  Planned
                </Option>
                <Option key={2} value={'unPlanned'}>
                  UnPlanned
                </Option>
              </Select>
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} style={{ paddingTop: '30px' }}>
            <Button icon={<PlusSquareOutlined />} onClick={() => { setShowChecklistModal(true); console.log(showChecklistModal) }}>Add Checklist</Button>
          </Col> */}
          {serviceType != 'planned' && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="assetServiceDate" label="Service Date" rules={[
              {
                required: true,
                message: "Input is required"
              },
            ]} >
              <DatePicker disabledDate={disableDate} style={{ width: "100%" }} format="YYYY-MM-DD" disabled={status && (atob(status) == ServiceStatusEnum.OPEN || atob(status) == ServiceStatusEnum.INPROGRESS)} />
            </Form.Item>
          </Col>}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>

            <Form.Item
              name="status"
              label="Service status"
              rules={[
                {
                  required: true,
                  message: "Input is required"
                }
              ]}
            >
              {<Select disabled
                allowClear >
                {Object.values(ServiceStatusEnum).map(e => <Option key={e} value={e}>{e}</Option>)}
              </Select>}
            </Form.Item>
          </Col>
          {<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="serviceName" label="Service Name" rules={[
              {
                required: true,
                message: "Input is required"
              },
            ]} >
              <Input disabled={id != undefined} />
            </Form.Item>
          </Col>
          }
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="serviceProvider" label="Service Provider" rules={[
              {
                required: true,
                message: "Input is required"
              },
            ]} >
              <Input disabled={id != undefined} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="serviceOwner" label="Service Owner" rules={[
              {
                required: true,
                message: "Input is required"
              },
            ]} >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="phoneNumber" label="Phone Number" rules={[
              {
                required: true,
                message: "Input is required"
              },

            ]} >
              <Input placeholder="Enter 10 digit ph no" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="remarks" label="Service note" >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} style={{ paddingTop: '30px' }}>
            <Button icon={<PlusSquareOutlined />} onClick={() => { setShowChecklistModal(true); console.log(showChecklistModal) }}>Add Checklist</Button>
          </Col>
        </Row>
        <Row gutter={24}>
          {serviceType == 'planned' && <> <Divider type='horizontal' />
          <Col span={24}>
          <h3>Frequency Details</h3>
          </Col> <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item name="frequencyOfService" label="Frequency of Service" initialValue={ServiceFrequency.Weekly} rules={[
              {
                required: true,
                message: "Input is required"
              },
            ]} >
              <Select
                placeholder='Enter or Service Interval'
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={(value: any) => { setFrequencyOfService(value) }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.values(ServiceFrequency).map((i) => {
                  return <Option key={i} value={i}>{i}</Option>
                })
                }
              </Select>
            </Form.Item>
          </Col>
            {(frequencyOfService == ServiceFrequency.Weekly || frequencyOfService == ServiceFrequency.FortWeekly) && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item name="day" label="Day" rules={[
                {
                  required: true,
                  message: "Input is required"
                },
              ]} >
                <Select
                  placeholder='Select Day'
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {weekdaysArr.map((e,index) => {
                    return <Option key={e} value={index + 1}>{e}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>}
            {(frequencyOfService != ServiceFrequency.Weekly && frequencyOfService != ServiceFrequency.FortWeekly && frequencyOfService != ServiceFrequency.Monthly && frequencyOfService != ServiceFrequency.Weekend && frequencyOfService != ServiceFrequency.Daily) && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} >
              <Form.Item name="month" label="Month" initialValue={moment()} rules={[
                {
                  required: true,
                  message: "Input is required"
                },
              ]} >
                <MonthPicker format='MM-YYYY' style={{ width: '100%' }} />
              </Form.Item>
            </Col>}
            {(frequencyOfService != ServiceFrequency.Weekly && frequencyOfService != ServiceFrequency.FortWeekly  && frequencyOfService != ServiceFrequency.Weekend && frequencyOfService != ServiceFrequency.Daily) && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item name="day" label="Day" rules={[
                {
                  required: true,
                  message: "Input is required"
                },
              ]} >
                <Select
                  placeholder='Select Day'
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[...Array(moment().daysInMonth()).keys()].map((i) => {
                    return <Option key={(i + 1)} value={(i + 1)}>{(i + 1)}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item name="startDate" label="Start Date" rules={[
                {
                  required: true,
                  message: "Input is required"
                },
              ]} >
                <DatePicker disabledDate={disableDate} style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item name="endDate" label="End Date" rules={[
                {
                  required: true,
                  message: "Input is required"
                },
              ]} >
                <DatePicker disabledDate={dissableEndDate} style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </>
          }


        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(!props.isUpdate) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
      <Modal key={'checklist'} closable width={'50%'} visible={showChecklistModal} onCancel={() => { setShowChecklistModal(false); setChecklistData([]) }} onOk={() => { setShowChecklistModal(false) }} >
        <AssetServiceCheckList setServiceChecklist={(value) => { setChecklistData(prev => [...prev, value]) }} initialValues={checklistData} />
      </Modal>
    </Card>
  );
}
export default AssetServiceForm;