
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, Upload, UploadProps } from 'antd';
import { Brands, CountryDto, CurrencyDto, CustomerACcountTypes, EndCustomerDto, MasterBrandsDropDownDto, PaymentmodesDto } from '@gtpl/shared-models/masters';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './end-customer-form.css';
import { CountryService, CurrencyService, EndCustomersService, MasterBrandService } from '@gtpl/shared-services/masters';
import { PaymentmodesService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea';
import appSettings from 'apps/services/config';
import { BrandsInfo } from 'libs/shared-models/masters/src/lib/endCuctomer/brands-model';
import { DocumentsListRequest } from '@gtpl/shared-models/logistics';
const countryService = new CountryService();
const currencyService = new CurrencyService();

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};



export interface EndCustomersFormProps {
  endCustomersData: EndCustomerDto;
  updateDetails: (endCustomers: EndCustomerDto,filelist:any) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function EndCustomersForm(props: EndCustomersFormProps) {
  let accountTypes = CustomerACcountTypes;
  let history = useHistory();

  const [form] = Form.useForm();
  const service = new EndCustomersService;
  const paymentModeService = new PaymentmodesService;
  const [selectedpaymentmode, setSelectedPaymentModes] = useState<number>(null);
  const [paymentModeData, setPaymentModeData] = useState<PaymentmodesDto[]>([]);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(null);
  const [brandsDropDown, setBrandsDropDown] = useState<MasterBrandsDropDownDto[]>([]);
  const [brandsInfo, setBrandsInfo] = useState<Brands[]>([]);
  const [brandsData, setBrandsData] = useState<BrandsInfo[]>([]);
  const [keyValues, setKeyValues] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState('');
  const [disable, setDisable] = useState<boolean>(false)
  const brandsService = new MasterBrandService()
  const [fieldsData, setFieldsData] = useState<DocumentsListRequest>(undefined);


  const [filelist, setfilelist] = useState<any>(props.isUpdate ? [{
    name: props.endCustomersData.fileName,
    status: 'done',
    url: appSettings.brand_file_path + props.endCustomersData.filePath,
  }] : []);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload Logo</div>
    </div>
  );

  useEffect(() => {
    getBrandData();
    getCurrenciesData()
    console.log(props.endCustomersData);

  }, [])

  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setfilelist([]);
      setImageUrl('');
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(png|jpeg|PNG|jpg|JPG)$/)) {
        AlertMessages.getErrorMessage("Only png,jpeg,jpg files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
          return true;
        } else {
          setfilelist([file]);
          getBase64(file, imageUrl =>
            setImageUrl(imageUrl)
          );
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist,
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const getBrandData = () => {
    brandsService.getAllMasterBrandsDropDown().then(res => {
      if (res.status) {
        setBrandsDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getCurrenciesData = () => {
    currencyService.getAllActiveCurrencys().then((res) => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setCurrencies([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCurrencies([]);
      });
  }

  // const uploadLogoDetails = () => {
  //   form.validateFields().then(formDetails => {
  //     console.log(formDetails);
  //     if (filelist.length > 0) {
  //       const formData = new FormData();
  //       filelist.forEach((file: any) => {
  //         formData.append('file', file);
  //       });
  //        formData.append('endCustomerId',(formDetails.endCustomerId)?formDetails.endCustomerId:"")
  //         formData.append('endCustomerCode',formDetails.endCustomerCode)
  //         formData.append('endCustomerName',formDetails.endCustomerName)
  //         formData.append('currencyId',formDetails.currencyId)
  //         formData.append('isActive',formDetails.isActive)
  //         formData.append('addressInfo',formDetails.addressInfo)
  //         formData.append('brandsInfo',formDetails.brandsInfo)
  //         formData.append('currenciesInfo',formDetails.currenciesInfo)
  //       formData.append('filePath', formDetails.filePath)
  //       formData.append('fileName', formDetails.fileName)
  //       // formData.append('createdUser',localStorage.getItem("createdUser"))
  //       service.logoUpload(formData).then(res => {
  //         if (res.status) {
  //           form.resetFields();
  //           setfilelist([]);
  //           if (!props.isUpdate) {
  //             AlertMessages.getSuccessMessage('Created Successfully');
  //             history.push('/EndCustomerview');
  //           } else {
  //             props.closeForm();
  //             window.location.reload();
  //             AlertMessages.getSuccessMessage('Updated Successfully');

  //           }
  //         }
  //       })
  //     }

  //   })
  // }

  const saveEndCustomer = (endCustomersData: EndCustomerDto) => {
    setDisable(true)

    const bransDetails = form.getFieldValue('brandsInfo') ? form.getFieldValue('brandsInfo') : [];
    // console.log(bransDetails),
    endCustomersData.brandsInfo = null;
    for (const brand of bransDetails) {
      let added = false;
      for (const brandInfo of brandsInfo) {
        if (!added) {
          if (brand.masterBrandId == brandInfo.brandId) {
            added = true;
            const brands: BrandsInfo = {
              brandId: null,
              brandName: brandInfo.brandName,
              masterBrandId: brandInfo.brandId
            }
            console.log(brands);
            brandsData.push(brands);
            setBrandsData(brandsData);
          }
        }
      }
    }
    endCustomersData.brandsInfo = brandsData;
    
      form.validateFields().then(req=>{
        const formData = new FormData();
        console.log(req);
        req.fileName = req.file?.fileList[0]?.name;
        formData.append('file', req.file?.fileList[0]);
        console.log(formData);
        console.log(req);
        service.createEndCustomers(endCustomersData).then(res => {
          if (res.status) {
            if (filelist.length > 0) {
              const formData = new FormData();
              filelist.forEach((file: any) => {
                formData.append('file', file);
              });
              formData.append('endCustomerId', `${res.data.endCustomerId}`)
              service.logoUpload(formData).then(fileres => {
                res.data.filePath = fileres.data;
                history.push("/EndCustomerview");
                onReset();
              })
            }
            history.push("/EndCustomerview");
                onReset();
          }else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        })
        setBrandsData([]);
       })
  }

  

  const saveData = (values: EndCustomerDto) => {
    setDisable(false)
    // form.validateFields().then(formDetails => {
    //   console.log(formDetails);
    //   if (filelist.length > 0) {
    //     const formData = new FormData();
    //     filelist.forEach((file: any) => {
    //       formData.append('file', file);
    //     });
        // Object.keys(formDetails).forEach(key => {
        //   formData.append(key, (formDetails[key]) ? formDetails[key] : "");
        //   if (key == 'createdUser') {
        //     formData.append(key, localStorage.getItem("createdUser"))
        //   }
        // })
        // service.logoUpload(formData).then(res => {
        //   if (res.status) {
        //     form.resetFields();
        //     setfilelist([]);
        //     if (!props.isUpdate) {
        //       AlertMessages.getSuccessMessage('Created Successfully');
        //       history.push('/EndCustomerview');
        //     } else {
        //       props.closeForm();
        //       window.location.reload();
        //       AlertMessages.getSuccessMessage('Updated Successfully');
        //     }
        //   }
        // })
    //   }

    // })
    if (props.isUpdate) {
    //s  setfilelist([]);
      //props.updateDetails(values,filelist);
      const bransDetails = form.getFieldValue('brandsInfo');
      values.brandsInfo = [];
      for (const brand of bransDetails) {
        if (!brand.brandId) {
          let added = false;
          for (const brandInfo of brandsInfo) {
            if (!added) {
              if (brand.masterBrandId == brandInfo.brandId) {
                added = true;
                const brands: BrandsInfo = {
                  brandId: null,
                  brandName: brandInfo.brandName,
                  masterBrandId: brandInfo.brandId
                }
                values.brandsInfo.push(brands);
              }
            }
          }
        } else {
          values.brandsInfo.push(brand);
        }
      }
      props.updateDetails(values,filelist);
    } else {
      setDisable(false)
      saveEndCustomer(values);
    }

  };
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset = () => {
    form.resetFields();
  };
  const handleCurrency = (value) => {
    setSelectedCurrency(value);
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const setBrandName = (values, brandData) => {
    const brands = new Brands(values, brandData.children);
    brandsInfo.push(brands);
    setBrandsInfo(brandsInfo);
  }

  const appendInputData = (val, label) => {
    console.log(val);
    console.log(label);
    setFieldsData({ ...fieldsData, [label]: val });
  }



  return (
    <>
      <Card size="small" title={<span style={{ color: 'white' }}>End Customer</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to="/EndCustomerview"  ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
        <Form form={form} onFinish={saveData} initialValues={props.endCustomersData} layout="vertical" >
          <Form.Item name="endCustomerId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="endCustomerCode"
                label="End Customer Code"
                rules={[
                  {
                    required: true, message: 'Missing End Customer Code'
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z&\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                    message: `Invalid End Customer Code`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="endCustomerName"
                label="End Customer Name"
                rules={[
                  {
                    required: true, message: 'Missing End Customer name'
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=~{}:";<>,.?|][a-zA-Z&'. ]*$/,
                    message: `Should contain only alphabets`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="consignee"
                label="Consignee Name"
                rules={[
                  {
                    required: true, message: 'Missing Consignee name'
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=~{}:";<>,.?|][a-zA-Z&'. ]*$/,
                    message: `Should contain only alphabets`
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={4}>
              <Form.Item
                name="currencyId"
                label="Currency"
                rules={[
                  {
                    required: true, message: 'Missing currency',
                  },
                ]}
              >
                <Select
                  placeholder="Select Currency"
                  onChange={handleCurrency}
                  allowClear
                >
                  {currencies.map(curencyDropData => {
                    return <Option value={curencyDropData.currencyId}>{curencyDropData.currencyName}</Option>
                  })}
                </Select>
                {/* <Input /> */}
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>

            <Form.List name="addressInfo">
              {(fields, { add, remove }) => (
                <>
                  <Card>
                    <Form.Item>
                      {/* <br /> */}
                      {/* <br/> */}
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Address
                      </Button>
                    </Form.Item>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        {/* <Row gutter={24}> */}

                        {/* </Row> */}
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="AddressLine1"
                              name={[field.name, 'addLineOne']}
                              fieldKey={[field.fieldKey, 'addressLineOne']}
                              rules={[{ required: true, message: 'Missing Apartment name' }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="AddressLine2"
                              name={[field.name, 'addLineTwo']}
                              fieldKey={[field.fieldKey, 'addressLineTwo']}
                              rules={[{ required: true, message: 'Missing street name' }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="State"
                              name={[field.name, 'addLineThree']}
                              fieldKey={[field.fieldKey, 'addressLineThree']}
                              rules={[{ required: true, message: 'Missing state name' },
                              {
                                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                                message: `Should contain only alphabets.`
                              }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...field}
                              label="Postal Code"
                              name={[field.name, 'addLineFour']}
                              fieldKey={[field.fieldKey, 'addressLineFour']}
                              rules={[{ required: true, message: 'Missing Postal Code' }]}
                            >
                              <Input />

                            </Form.Item>
                          </Col>
                        </Row>
                        {
                          props.isUpdate ? <></> :
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                        }
                      </Space>
                    ))}
                  </Card>

                </>
              )}
            </Form.List>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
              <Form.Item name="file" rules={[{ required: false, message: 'Please Upload File' },]}>
                <Upload {...uploadFieldProps} style={{ width: '100%' }} listType="picture-card" onChange={e => appendInputData(e.file.name, 'file')}>
                  {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Form.Item>
            </Col>          </Row>

          <Row gutter={24}>

            <Form.List name="brandsInfo">
              {(fields, { add, remove }) => (
                <>
                  <Card>
                    <Form.Item>

                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Brand
                      </Button>
                    </Form.Item>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>

                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item
                              {...field}
                              label="Brand Name"
                              name={[field.name, 'masterBrandId']}
                              // fieldKey={[field.fieldKey, 'brandId']}
                              rules={[{ required: true, message: 'Missing brand name' }, { pattern: /^(?!\s*$).+/, message: `Enter Brand Name` }]}
                            >
                              <Select
                                showSearch
                                placeholder="Please Select Brand"
                                optionFilterProp="children"
                                onChange={setBrandName}
                                onSearch={onSearch}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: 250 }}
                              >
                                <Option key={0} value={null}>Please select brand</Option>
                                {brandsDropDown.map(branddropData => {
                                  return <Option label={'brandId'} key={branddropData.masterBrandId} value={branddropData.masterBrandId}>{branddropData.masterBrandName}</Option>
                                })}
                                |
                              </Select>
                            </Form.Item>
                          </Col>

                        </Row>
                        {
                          props.isUpdate ? <></> :
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                        }
                      </Space>
                    ))}
                  </Card>

                </>
              )}
            </Form.List>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" disabled={disable} htmlType="submit" >
                Submit
              </Button>
              {(props.isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );

}



export default EndCustomersForm;

