import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker } from 'antd';
import './container-register.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';
import Link from 'antd/lib/typography/Link';
import { MinDate, minDate } from 'class-validator';

/* eslint-disable-next-line */
export interface BookContainerProps { }
const { Option } = Select;
export function BookContainer(
    props: BookContainerProps
) {
    let location = useLocation();
    const containerStatusInfo: ContainerStatusInfo | any = location.state;
    console.log(containerStatusInfo);
    const history = useHistory();
    const [containerRegisterForm] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [afterSubmitDisable, setAfterSubmitDisable] = useState<boolean>(false);

    // const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
    const [saleOrderData, setSaleOrderData] = useState<any[]>([]);

    const [tab, setTab] = useState<string>(undefined);

    const SoService = new SaleOrderService();
    const containerRService = new ContainerRegisterService();

    useEffect(() => {
        getSaleOrderData();
        if (containerStatusInfo) {
            containerRegisterForm.setFieldsValue({
                saleOrderId: containerStatusInfo.saleOrderId,
                containerStatus: ContainerStatusEnum.BOOKED,
                containerId:containerStatusInfo.containerId,
                referenceNumber:containerStatusInfo.referenceNumber,
                scac:containerStatusInfo.scac
            });
        } else {
            containerRegisterForm.setFieldsValue({
                containerStatus: ContainerStatusEnum.BOOKED
            });
        }
    }, [containerStatusInfo]);

    // get sale order drop down details
    const getSaleOrderData = () => {
        SoService.getSoPoBrandDropDownList().then((res) => {
            if (res.status) {
                setSaleOrderData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

    const onReset = () => {
        containerRegisterForm.resetFields();
        containerRegisterForm.setFieldsValue({
            saleOrderId: containerStatusInfo.saleOrderId,
        });
        containerRegisterForm.setFieldsValue({
            containerStatus: ContainerStatusEnum.BOOKED
        });
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
      };

    const saveData = (values) => {
        values.createdUser=localStorage.getItem('createdUser');
        values.updatedUser=localStorage.getItem('createdUser');
        values.unitId=localStorage.getItem("unit_id");
        
        setAfterSubmitDisable(true);
        containerRService.bookContainer(values).then(res => {
            if (!res.status) {
                AlertMessages.getErrorMessage(res.message);
            }else{
                // history.push('/container-register-grid');
                goToContainerGrid();
              AlertMessages.getSuccessMessage(res.internalMessage);
            }
            setAfterSubmitDisable(false);
        }).catch(err => {
            setAfterSubmitDisable(false);
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const goToContainerGrid = () => {
        setTab("Ready to Book");
      }

    return (
        <>
        {tab ? <Redirect to={{pathname:"/container-register-grid", state:tab}}/>:null}

        <Card title={<span style={{ color: 'white' }}>Slot Booking</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link onClick={e => goToContainerGrid()} ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

            <Form layout="vertical" form={containerRegisterForm} name="control-hooks" onFinish={saveData} >
                <Row gutter={24}>
                    <Form.Item name="containerId">
                        <Input hidden />
                    </Form.Item>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
                        <Form.Item name="saleOrderId" label="Sale Order Number"  rules={[{ required: true, message: 'Missing SaleOrder' }]}>
                            <Select
                                placeholder="Select SaleOrderNumber"
                                allowClear
                                disabled={true}
                            >
                                <Option value={''}>Please Select</Option>
                                {saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{`${dropData.poNumber}-${dropData.brandName}-${dropData.variantCode}`}</Option>
                                    // return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

                        <Form.Item label="Liner Booking No" name="referenceNumber" rules={[{ required: false, message: 'Missing Reference Number' }]} >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                            <Form.Item
                            name="scac"
                            label="SCAC"
                           >
                                <Input />
                            </Form.Item>
                    </Col>

                  

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  >
                        <Form.Item
                            name="expectedDeliveryDate"
                            label="Container ETA (to Plant)"
                            rules={[{ required: true, message: 'Missing Expected Delivery Date' },
                            ]} initialValue={moment()}>

                            <DatePicker 
                            format={'DD-MMMM-YYYY'}
                            style={{ width: '100%' }} 
                            disabledDate={disabledDate}
                    
                            />

                        </Form.Item>
                    </Col>

                   
                    

                </Row>
                <Row gutter={24}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>
                        <Form.Item
                            name="containerStatus"
                            label="Container Status"
                            rules={[
                                {
                                    // required: true, message: 'Missed Container Status'
                                },
                            ]}>
                            <Select
                                placeholder="Select Container Status"
                                defaultValue={ContainerStatusEnum.BOOKED}
                                disabled={true}
                            >
                                <Option key={0} value={null}>Select Container Status</Option>
                                {ContainerStatusInput.map((container) => {
                                    return <Option key={container.status} value={container.status}>{container.status}</Option>
                                })}
                            </Select>
                        </Form.Item>
                </Col>
                <Col style={{paddingTop:'30px'}}>
                        <Button type="primary" htmlType="submit" disabled={afterSubmitDisable} >
                            Submit
                        </Button>
                        {(isUpdate === false) &&
                            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                                Reset
                        </Button>
                        }
                    </Col>
                </Row>
            </Form>
        </Card>
        </>
    );
}

export default BookContainer;
