import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ActivitiesForm} from '@gtpl/pages/hrms/hrms-components/activities-form';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import './activities-grid.css';
import { RecruitmentActivitiesService } from '@gtpl/shared-services/hrms';
import { RecruitmentActivitiesDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';

export interface ActivitiesGridProps {}

export function ActivitiesGrid(props: ActivitiesGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [recuritActvitiyData, setRecruitActivityData] = useState<RecruitmentActivitiesDto[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<any>(undefined);

  const service = new RecruitmentActivitiesService();

     
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }

    const openFormWithData=(viewData: RecruitmentActivitiesDto)=>{
      setDrawerVisible(true);
      setSelectedActivity(viewData);
    }


    useEffect(() => {
      getAllRecruitmentActivities();
    },[])

    const getAllRecruitmentActivities = () => {
      service.getAllRecruitmentActivities().then(res => {
        if(res.status){
          setRecruitActivityData(res.data);
        }else {
          if(res.intlCode){
            setRecruitActivityData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setRecruitActivityData([]);
        AlertMessages.getErrorMessage(err.message);
      })
    } 
  
    /**
     * 
     * @param recuritActvitiyData 
     */
     const deleteActivity = (recuritActvitiyData:RecruitmentActivitiesDto) => {
      recuritActvitiyData.isActive=recuritActvitiyData.isActive?false:true;
      service.activateOrDeactivateRecruitmentActivity(recuritActvitiyData).then(res => { console.log(res);
        if (res.status) {
          getAllRecruitmentActivities();
          AlertMessages.getSuccessMessage('Success');
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
    /**
       * 
       * @param variantData 
       */
     const updateRecruitmentActivity = (data: RecruitmentActivitiesDto) => {
      service.updateRecruitmentActivity(data).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllRecruitmentActivities();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
     
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      // hideInSearch: true,
      // hideInForm: true,
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
	{
      title: 'Date',
      dataIndex: 'activityDate',
      width: '170px',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.activityDate.localeCompare(b.activityDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('Date'),
      render : (value,record:RecruitmentActivitiesDto) => {
      return moment(record.activityDate).format('DD-MM-YYYY')
      }
    },
     
    {
      title: 'Candidate Name',
      dataIndex: 'candidateName',
      width: '170px',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.candidateName?.localeCompare(b.CandidateName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('candidateName')
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      // hideInSearch: true,
      // hideInForm: true,
      width: '170px',
      sorter: (a, b) => a.activity?.localeCompare(b.activity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('activity')
    },

    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // hideInSearch: true,
    //    width: '170px',
    //   // hideInForm: true,
    //   responsive: ['sm'],
    //   // sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    //   // sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('remarks')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: '170px',
      align:'center',
      // hideInSearch:true,
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      width: '170px',
      // hideInSearch: true,
      // hideInForm: true,
      render: (text, rowData) => (
        <span> 
            <Tooltip placement="top" title='Edit'>      
             <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated activity');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>  
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteActivity(rowData);}}
              title={
              rowData.isActive
                ? 'Are you sure to Deactivate Activities?'
                :  'Are you sure to Activate Activities  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Activities</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/activities-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
     <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
          <Card title={'Total Recruitments: ' + recuritActvitiyData.length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
          <Card title={'Active: ' + recuritActvitiyData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
          <Card title={'In-Active: ' + recuritActvitiyData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
          <br></br>
          <Table
          rowKey={record => record.activityId}
          columns={columnsSkelton}
          dataSource={recuritActvitiyData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <ActivitiesForm key={Date.now()}
                isUpdate={true}
                closeForm={closeDrawer} 
                updateRecruitmentActivity={updateRecruitmentActivity}
                data={selectedActivity}/>
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default ActivitiesGrid;
