import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, Table } from 'antd';
import { AttendanceService, DesignationService, TeamsService } from '@gtpl/shared-services/hrms';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { DepartmentService, EmployeeService, HrmsEmpDetService } from '@gtpl/shared-services/masters';
import UndoOutlined from '@ant-design/icons/lib/icons/UndoOutlined';

import './employee-shift-mapping.css';
import { DesignationDto, EmpCodeShiftRequest, EmployeeDataModel, TeamDto } from '@gtpl/shared-models/hrms';
import { DepartmentDto, EmployeeDto, HrmsEmployeeDetailsDto, HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';

/* eslint-disable-next-line */
export interface EmployeesShiftMappingProps { }

export function EmployeesShiftMapping(
  props: EmployeesShiftMappingProps
) {

  const [columns, setColumns] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [employeeData, setEmployeeData] = useState<EmployeeDataModel[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<EmployeeDataModel[]>([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<EmployeeDataModel[]>([]);
  const [empNameData, setSelectedNameData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const [departmentData, setDepartmentData] = useState<DepartmentDto[]>([]);
  const [shiftGroupData, setShiftGroupData] = useState<TeamDto[]>([]);
  const [pageSize, setPageSize] = useState<number>(null);
  const designationService = new DesignationService();
  const { Option } = Select;
  const [form] = Form.useForm();
  const service = new HrmsEmpDetService();
  const attendanceService = new AttendanceService();
  const departmentService = new DepartmentService();
  const shiftGroupService = new TeamsService();

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeCode'),
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      width: '350px',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      // responsive: ['sm'],
      sorter: (a, b) => a.department?.localeCompare(b.department),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      // responsive: ['sm'],
      sorter: (a, b) => a.designation?.localeCompare(b.designation),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Shift Group',
      dataIndex: 'shiftGroup',
      // responsive: ['sm'],
      sorter: (a, b) => a.shiftGroup?.localeCompare(b.shiftGroup),
      sortDirections: ['descend', 'ascend']
    }
  ]


  useEffect(() => {
    getFiltersDropdownData(filtersData);
  }, [filtersData])

  useEffect(() => {
    getAllActiveEmployeesData();
    getAllActiveEmployees();
    getAllDesignation();
    getAllDepartments();
    getAllShiftGroups();
  }, []);

  const getAllActiveEmployeesData = () => {
    attendanceService.getDataForEmpShiftMapping().then(res => {
      if (res.status) {
        setFiltersData(res.data);
      } else {
        setEmployeeData([]);
        setFiltersData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setEmployeeData([]);
      setFiltersData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const searchData = () => {

    form.setFieldsValue({
      fromDate: moment(moment().format("YYYY-MM-DD")),
      toDate: moment(moment().format("YYYY-MM-DD"))
    })
    const employeeName = form.getFieldValue('employeeName');
    const department = form.getFieldValue('department');
    const designation = form.getFieldValue('designation');
    const shiftGroup = form.getFieldValue('shiftGroup');

    let selectedData = filtersData;
    if (employeeName) {
      selectedData = filtersData.filter(item => item.employeeName === employeeName)
    }
    if (department) {
      selectedData = selectedData.filter(item => item.department === department)
    }
    if (designation) {
      selectedData = selectedData.filter(item => item.designation === designation)
    }
    if (shiftGroup) {
      selectedData = selectedData.filter(item => item.shiftGroup === shiftGroup)
    }
    setEmployeeData(selectedData);
  }

  const getFiltersDropdownData = (filtersData) => {
    let employeesData: any[] = [];
    filtersData.forEach(element => {
      if (element.designation) {
        employeesData.push(element.designation)
      }
      if (element.department) {
        employeesData.push(element.department)
      }
      if (element.shiftGroup) {
        employeesData.push(element.shiftGroup)
      }
    });
    //setEmployeeDetails(employeesData);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeysData(selectedRowKeys);
      setSelectedRows(selectedRows);
      setVisible(true);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeysData
  };

  const getAllActiveEmployees = () => {
    service.getAllActiveEmployees().then(res => {
      if (res.status) {
        setSelectedNameData(res.data);
      } else {
        if (res.intlCode) {
          setSelectedNameData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSelectedNameData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllDesignation = () => {
    designationService.getAllDesignation().then(res => {
      if (res.status) {
        setDesignationData(res.data);
      } else {
        if (res.intlCode) {
          setDesignationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllShiftGroups = () => {
    shiftGroupService.getAllActiveTeams().then(res => {
      if (res.status) {
        setShiftGroupData(res.data);
      } else {
        if (res.intlCode) {
          setShiftGroupData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setShiftGroupData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
    setSearchText('');
    setEmployeeData([]);
  }

  const onReset1 = () => {
    setSelectedRowKeysData([]);
    setSelectedRows([]);
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onBlur = () => {
    console.log('blur');
  }

  const updateLogs = () => {
    const shiftCode = form.getFieldValue('newShiftGroup');
    const employeeCodes = selectedRowKeysData;
    const fromDate = form.getFieldValue('fromDate');
    const toDate = form.getFieldValue('toDate');
    const request = new EmpCodeShiftRequest(employeeCodes, shiftCode, fromDate, toDate);
    attendanceService.updateEmpLogsByShift(request).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Shift updated Successfully');
        getAllActiveEmployeesData();
        searchData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <><Card title={<span style={{ color: 'white' }}>Employee Shift Mapping</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <br></br>
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
          <Input hidden />
        </Form.Item>
        <Row gutter={[24, 24]}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: false,
                  message: "Enter Your Name"
                }
              ]}>
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Name"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {empNameData.map((empNameData) => {
                  return <Option key={empNameData.employeeId} value={empNameData.employeeName + ' ' + empNameData.employeeLastName}>{empNameData.employeeName} {empNameData.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: false,
                  message: "Select Designation"
                }
              ]}>
              <Select
                placeholder="Select Designation"
                allowClear
                style={{ width: '100%' }}
                showSearch
              >
                {designationData.filter(emp => emp.designation != '').map(dropData => {
                  return <Option value={dropData.designation}>{dropData.designation}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="department"
              label="Department"
              rules={[
                {
                  required: false,
                  message: "Enter Your Department"
                }
              ]}>
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Department"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {departmentData.map((departmentData) => {
                  return <Option key={departmentData.deptId} value={departmentData.deptName}>{departmentData.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="shiftGroup"
              label="Shift Group"
              rules={[
                {
                  required: false,
                  message: "Select Shift Group"
                }
              ]}>

              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Shift Group"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {shiftGroupData.map((shiftGroupData) => {
                  return <Option key={shiftGroupData.id} value={shiftGroupData.shiftCode}>{shiftGroupData.shiftCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ marginTop: 30 }}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block onClick={() => searchData()}
              style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>

            <Button
              icon={<UndoOutlined />}
              onClick={() => onReset()}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
            <Form.Item
              name="newShiftGroup"
              label="New Shift Group"
              rules={[
                {
                  required: true,
                  message: "Select New Shift Group"
                }
              ]}>

              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select New Shift Group"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {shiftGroupData.map((shiftGroupData) => {
                  return <Option key={shiftGroupData.id} value={shiftGroupData.shiftCode}>{shiftGroupData.shiftCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="fromDate" label="From Date" >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                defaultValue={moment(moment().format("YYYY-MM-DD"))}
                showToday={true}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="toDate" label="To Date" >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                defaultValue={moment(moment().format("YYYY-MM-DD"))}
                showToday={true}
              />
            </Form.Item>
          </Col>

          <Col span={24} style={{ textAlign: 'right' }}>
            {visible && selectedRowKeysData.length ? <>
              <Button type="primary" htmlType="submit" onClick={updateLogs}>
                Submit
              </Button>

              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset1}>
                Reset
              </Button>
            </> : ""}
          </Col>
        </Row>
      </Form>

      <br></br>

      <Table
        key={Date.now()}
        rowSelection={{
          ...rowSelection
        }}
        rowKey={record => record.employeeCode}
        columns={columnsSkelton}
        dataSource={employeeData}
        pagination={{
          onChange(current, pageSize) {
            setPage(current);
            setPageSize(pageSize);
          }
        }}
        bordered />

    </Card></>
  );
}

export default EmployeesShiftMapping;
