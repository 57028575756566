import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  UnitsOfWeightInput,

} from '@gtpl/shared-models/sale-management';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { GlobalStatus, UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PrinterOutlined } from '@ant-design/icons'
let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import Item from 'antd/lib/list/Item';
import moment from 'moment';
import logo from './azgems-logo.png';
import stampImage from "./stamp.png";
import signature from "./signature.png"
// import React from 'react';

export interface CustomPoPrintProps {
  saleOrderId: number;
}

export function CustomPoPrint(
  props: CustomPoPrintProps
) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;

  console.log(location.state);
  let totalCases = 0;
  let totalLbWeight = 0;
  let totalAmount = 0;
  let itemsTotalAmount = 0;
  let lbPouchWeight
  let kgPouchWeight
  let dateObject;
  let dateMomentObject;
  let orderDateNEw;
  let orderDateAgain

  let totalAmnt = 0;
  let newUnitPrice;
  let newTotalAmount;


  // console.log('order date',plantDetails.orderDate)

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    // document.body.innerHTML = oldPage
    window.print();

  }

  const getData = (saleOrderId) => {
    // const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(props.saleOrderId)).then((res) => {
      console.log(res.data);
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print()
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  let exporterId = 1;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
  );

  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };
  {
    if (plantDetails?.invoiceDate)
      dateMomentObject = moment(plantDetails?.invoiceDate, "DD-MMM-YYYY").toDate(); // 1st argument - string, 2nd argument - format
    // dateObject = moment(dateMomentObject).toDate()
    orderDateNEw = moment(dateMomentObject).add(45, 'days').toDate();


  }
  return (
    <div>
      <span style={{ alignContent: 'left', backgroundColor: 'red' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="customsPoPrint"
          sheet="customsPoPrint"
          buttonText="Get Excel" />
      </span>
      <br />
      <html>
        <head></head>
        <br />
        <body id="printme">


          <table style={{ width: '99%' }} id='table-to-xls'>

            <tr>
              <td></td>
              <td colSpan={4}>
                {
                  plantDetails?.clientName === 'AZ Gems' ? <>
                    <img style={{ marginLeft: '-20px', width: '25%' }} src={logo} alt='azgems-logo' />
                  </> : <b>'AZ GEMS CANADA INC'</b>
                }
                <br />
                {/* <b>405, MISSOURI CT.<br />
                  REDLANDS,<br />
                  CA 92373<br />
                  USA<br /> */}
                <b>{plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","} <br />
                  {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","} <br />{plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}</b>
                {/* </b> */}
              </td>
              <td colSpan={3} style={{ textAlign: 'center' }}>
                <b >Date: </b> {plantDetails?.actualEdl ? (moment(plantDetails?.actualEdl).format("DD-MMM-YYYY")) : null}

              </td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td colSpan={4}>
                <h1 className={'ta-b'} style={{ textAlign: 'center', fontSize: '20px' }}><u>PURCHASE ORDER</u></h1>
                <h1 style={{ textAlign: 'center', fontSize: '15px' }}><u>{plantDetails?.custPoNo}</u></h1>
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={8}>
                <b>TO</b><br />
                <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />

                {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                <br />
                {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                <br />
                {exporterData?.state + ', ' + exporterData?.country}
                <br />
              </td>
            </tr>
            <br />

            <tr style={{ borderBottom: '0px' }}>
              <td colSpan={8}>
                Dear Sirs, <br />
              </td>
            </tr>
            <tr style={{ borderBottom: '0px' }}>
              <td colSpan={8}>
                <p style={{ paddingLeft: '7em' }}>
                  We, <b>M/S. {
                    plantDetails?.country === "CANADA" ?
                      <>
                        <b>AZ GEMS CANADA INC:</b>
                      </> : <b>AZ GEMS INC:</b>
                  }</b>, USA are pleased to confirm as follows:<br />
                </p>
              </td>
            </tr>
            {/* </table> */}
            {/* <table style={{width: '98%'}} id='table-to-xls'> */}
            <><tr>
              <td></td>
              <td colSpan={2}>
                <b>PRODUCT  </b>
              </td>
              <td colSpan={4}>
                {
                  plantDetails?.saleOrderItems?.filter((ele, ind) => ind === plantDetails?.saleOrderItems?.findIndex(elem => elem.itemName === ele.itemName && elem.foodTypeName === ele.foodTypeName && elem.valueAdditionTwoIdName === ele.valueAdditionTwoIdName && elem.valueAdditionThreeIdName === ele.valueAdditionThreeIdName && elem.valueAdditionFiveIdName === ele.valueAdditionFiveIdName && elem.valueAdditionFourIdName === ele.valueAdditionFourIdName)).map(item => {
                    // totalCases += item.cases;
                    const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                    let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS" : item.itemName === "Black Tiger" ? "Black Tiger" : "";
                    let shrimp = "SHRIMPS";
                    let foodTypeName = item.foodTypeName.toUpperCase();
                    let valueAdditionTwoIdName = item.valueAdditionTwoIdName.toUpperCase();
                    let itemName = item.itemName.toUpperCase();
                    let valueAdditionThreeIdName = item.valueAdditionThreeIdName.toUpperCase();
                    let valueAdditionFiveIdName = item.valueAdditionFiveIdName.toUpperCase();
                    let valueAdditionFourIdName = item.valueAdditionFourIdName.toUpperCase();
                    console.log(item.saleOrderId);
                    return <p>: &ensp;{((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + (item.valueAdditionOneIdCode ? item.valueAdditionOneIdName.toLocaleUpperCase('en-US') : '') + ' ' + (
                      (!(([
                        'HLSO', 'HOSO', 'EZPeel', 'N/A'
                      ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                    ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + (item.valueAdditionFourIdCode ? item.valueAdditionFourIdName.toLocaleUpperCase('en-US') : ' ')}</p>
                  })
                }
              </td>
              <td colSpan={2}></td>
            </tr>
              <tr></tr><br />
              <tr>
                <td></td>
                <td colSpan={2}>
                  <b>QUANTITY  </b>
                </td>
                {plantDetails?.saleOrderItems?.map(item => {
                  totalCases += item.invoicedCases
                }
                )}
                <td colSpan={4}>
                  : &ensp;About {totalCases} Cartons
                </td>
                <td colSpan={2}></td>
              </tr>
              <tr>&nbsp;</tr>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <b>PACKING  </b>
                </td>
                <td colSpan={4}>
                  {
                    plantDetails?.saleOrderItems?.filter((ele, ind) => ind === plantDetails?.saleOrderItems?.findIndex(elem => elem.pouches === ele.pouches && elem.pouchWeight === ele.pouchWeight))
                      .map(item => {
                        const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                        lbPouchWeight = Number(item.pouchWeight)
                        kgPouchWeight = Number(item.pouchWeight)
                        if (uom.name == UomEnum.OZ) {
                          lbPouchWeight = lbPouchWeight * 0.0625
                        }
                        if (uom.name == UomEnum.Gms) {
                          kgPouchWeight = kgPouchWeight * 0.001
                        }

                        return <p>: {plantDetails.country === 'USA' ? <> &ensp;{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG') + ' = ' + Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG') + ' / ' + (Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'} </> : <>&ensp;{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG') + ' = ' + Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</>}</p>

                      })
                  }
                </td>
                <td colSpan={2}></td>
              </tr>
              <tr></tr>

            </>
            <tr>
              <th colSpan={1} className={'ta-b'} style={{ textAlign: 'center' }}>
                Pack style
              </th>

              <th className={'ta-b'} style={{ textAlign: 'center' }} colSpan={2}>
                Description of Goods
              </th>
              <th className={'ta-b'} style={{ textAlign: 'center' }}>
                Size
              </th>
              <th className={'ta-b'} style={{ textAlign: 'center' }}>
                No. Of Cartons
              </th>
              <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center' }}>
                Quantity
                <br />
                IN KGS <br /> NET WEIGHT
              </th>
              <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center' }}>
                Rate
                <br />
                PER KG
                <br />({plantDetails?.saleOrderItems[0]?.currencyDetails})
                <br />{plantDetails?.shipmentTerms}
                <br />{plantDetails?.saleOrderItems[0]?.destinationDetails}
              </th>
              <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center' }}>
                Amount
                <br />

                ({plantDetails?.saleOrderItems[0]?.currencyDetails})
                <br />{plantDetails?.shipmentTerms}
                <br />{plantDetails?.saleOrderItems[0]?.destinationDetails}
              </th>
            </tr>


            {plantDetails?.saleOrderItems?.map(item => {
              const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
              let finaItemslNetWeight;
              let itemUnitPrice;
              if (plantDetails.isInvoiced == GlobalStatus.YES) {
                if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                  finaItemslNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                  itemUnitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                } else {
                  finaItemslNetWeight = Number(item.invoicedQty).toFixed(3);
                  itemUnitPrice = Number(item.revisedUnitPrice).toFixed(5);
                }
              }
              itemsTotalAmount += Number(itemUnitPrice) * Number(finaItemslNetWeight);

            })}

            {
              plantDetails?.saleOrderItems?.map(item => {
                // totalCases += item.cases;
                const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                let finalNetWeight
                let unitPrice
                let invUnitPrice

                if (plantDetails.isInvoiced == GlobalStatus.YES) {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    finalNetWeight = (Number(item.invoicedQty) * 0.454).toFixed(3);
                    unitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(5);
                  } else {
                    finalNetWeight = Number(item.invoicedQty).toFixed(3);
                    unitPrice = Number(item.unitPrice).toFixed(5);
                  }
                  if (plantDetails.totalAmount > Number(itemsTotalAmount)) {

                    let totalAm = Number(((unitPrice) * (finalNetWeight)).toFixed(2))
                    let deciamlValue = (Number(totalAm.toLocaleString().slice(Number(totalAm.toLocaleString().length) - 2)))
                    if (plantDetails.saleOrderItems.length > 1) {

                      do {
                        unitPrice = Number(Number(unitPrice) + Number(0.00001)).toFixed(5)
                      }
                      while ((plantDetails.totalAmount) <= Number(itemsTotalAmount));


                    } else {
                      unitPrice = Number(Number(unitPrice) + Number(0.00001)).toFixed(5)
                    }
                  }

                }
                else {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    finalNetWeight = (Number(item.netWeight) * 0.454).toFixed(3);
                    unitPrice = (Number(item.unitPrice) / 0.454).toFixed(5);
                  } else {
                    finalNetWeight = Number(item.netWeight).toFixed(3);
                    unitPrice = Number(item.unitPrice).toFixed(5);
                  }
                }
                if (plantDetails?.country === 'CANADA') {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    invUnitPrice = ((Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice)) * 0.454).toFixed(5)
                  } else {
                    invUnitPrice = (Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice))
                  }
                } else if (plantDetails?.country === 'USA') {
                  if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                    invUnitPrice = ((Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice)) / 0.454).toFixed(5)
                  } else {
                    invUnitPrice = (Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice))
                  }
                }
                totalLbWeight += Number(finalNetWeight);
                // totalAmount += Number((Number(unitPrice) * Number(finalNetWeight)).toFixed(2));
                // totalAmount += (Number(plantDetails?.totalAmount))
                totalAmount = item.invoiceAmount;
                newUnitPrice = Number(totalAmount / finalNetWeight).toFixed(5)
                newTotalAmount = Number(Number(finalNetWeight) * Number(newUnitPrice))
                totalAmnt += (Number((invUnitPrice) * (finalNetWeight)));
                // totalAmnt += (Number(item.invoiceAmount) ? Number(item.invoiceAmount) : Number(finalNetWeight * Number(item.revisedUnitPrice ? item.revisedUnitPrice : item.unitPrice)));
                // console.log(totalAmount);
                let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" || "Sea Tiger" ? "PENAEUS MONODON" : "";
                // let shrimp = item.itemName  === "Vanname"?"SHRIMP":"";
                let shrimp = "SHRIMPS";
                let hasNumber = /\d/;
                let normalOrder = hasNumber.test(plantDetails?.buyerPoNo);
                let lotRefString = '';
                let lotNo = '';
                let RefNo = '';
                if (normalOrder) {
                  if (plantDetails?.lotCode) {
                    lotNo = 'LOT NO. ' + plantDetails?.lotCode + ','
                  }
                  if (plantDetails?.buyerPoNo) {
                    RefNo = 'REF NO. ' + plantDetails?.buyerPoNo
                  }
                  lotRefString = lotNo + RefNo
                }
                console.log("-----------")
                console.log('shrimp');
                return (

                  <tr style={{ border: '0px' }}>

                    <td className={'ta-b'} style={{ borderBottom: '0px', borderTop: '0px' }}>{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}
                      <span>{" = " + Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</span><br /><span>{" / " + (Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'}</span></td>
                    {/* <td colSpan={2} className={'ta-b'} style={{ textAlign: 'center', borderBottom:'0px', borderTop:'0px' }}>{item.foodTypeName===null?"":item.foodTypeName.toUpperCase() + ' ' + item.valueAdditionTwoIdName===null?"":item.valueAdditionTwoIdName.toUpperCase()}<br /> </td> */}

                    {/* <td className={'ta-b'} style={{ textAlign: 'center', borderBottom:'0px', borderTop:'0px' }}>{item.pouches + ' * ' + Number(item.pouchWeight) + " " + uom.name.toUpperCase()}<br /> */}
                    {/* <span style={{ textAlign: 'center' }}>{item.caseWeight + " " + uom.name.toUpperCase()}</span><br /><span style={{ textAlign: 'center' }}>{Number(item.caseWeight) * 0.454 + ' KGS'}</span></td> */}
                    <td colSpan={2} style={{ textAlign: 'left', alignContent: 'center' }}>
                      {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                        (!(([
                          'HLSO', 'HOSO', 'EZPeel', 'N/A'
                        ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                      ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')}<br />{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}<br />
                      {'LATIN NAME : ' + latinName.toUpperCase() + ' '}<br />
                      {' BRAND : ' + item.displayBrandName?.toUpperCase()}<br />

                      {/* {' BRAND : ' + item.brandName.toUpperCase()} */}
                      <span style={{ fontWeight: 'bold' }}>  {lotRefString} </span><br />
                      {plantDetails?.finNumber ? ('FPO#/FIN# ' + (plantDetails?.finNumber)) : ''}
                    </td>
                    <td className={'ta-b'} style={{ textAlign: 'left', borderBottom: '0px', borderTop: '0px' }}>{item.minGrade + '/' + item.maxGrade}</td>
                    <td style={{ textAlign: 'right' }}>{item.invoicedCases}</td>
                    <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>
                      {Number(finalNetWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}
                    </td>
                    <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(Number(invUnitPrice))}</td>
                    <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>
                      {(Number((invUnitPrice) * (finalNetWeight)))?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      {/* {(unitPrice * finalNetWeight).toFixed(2)} */}
                    </td>


                  </tr>
                )
              }
              )
            }
            <tr>
              <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              <td colSpan={2} className={'ta-b'} style={{ textAlign: 'center' }}>
                <b> Total</b>
              </td>
              <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              <td colSpan={1} className={'ta-b'} style={{ textAlign: 'right' }}>
                {totalCases}
              </td>
              <td style={{ textAlign: 'right' }} className={'ta-b'}>
                {totalLbWeight ? Number(totalLbWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}
              </td>
              <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
              <td style={{ textAlign: 'right' }} className={'ta-b'}>
                {totalAmnt ? (totalAmnt).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
              </td>
            </tr>


            {/* <tr>
                           <td></td>
                           <td rowSpan={2} colSpan={2}>
                             <b>Destination  </b>
                           </td>
                           <td rowSpan={2} colSpan={4} >
                           {plantDetails?.saleOrderItems[0].destinationDetails}
                          </td>
                           <td rowSpan={2} colSpan={2}></td>
                         </tr>
                         <tr id="hiderow"><span>&nbsp;</span></tr><br/>
                         <tr>
                             <td rowSpan={2}></td>
                             <td rowSpan={2} colSpan={2}>
                               <b>PAYMENT  </b>
                             </td>
                             <td rowSpan={2} colSpan={4}>
                               : {plantDetails?.saleOrderItems[0].paymentTerms}
                             </td>
                             <td rowSpan={2} colSpan={2}></td>
                           </tr>
                         <tr>&nbsp;</tr>
                         <tr><span></span></tr><br/>
                           <tr>
                             <td rowSpan={2}></td>
                             <td rowSpan={2} colSpan={2}>
                               <b>SHIPMENT DATE</b>
                             </td>
                             <td rowSpan={2} colSpan={4}>
                                {plantDetails?.shipmentTerms}
                            </td>
                             <td rowSpan={2} colSpan={2}></td>
                           </tr>
                         <tr></tr> */}
            {/* 
                      <tr>
                        <td></td>
                        <td colSpan={4}>
                        <table style={{width: "100%"}}>
                          <tr>
                            <td style={{width: "30%"}}>
                              <b>DESTINATION  </b>
                            </td>
                            <td style={{width: "50%"}}>
                            : &ensp;{plantDetails?.saleOrderItems[0].destinationDetails}
                            </td>
                            <td style = {{width: "20%"}}></td>
                          </tr>
                          <br/>
                          <tr>
                            <td style={{width: "30%"}}>
                              <b>PAYMENT  </b>
                            </td>
                            <td style={{width: "50%"}}>
                            : &ensp;{plantDetails?.saleOrderItems[0].paymentTerms}
                            </td>
                            <td style = {{width: "20%"}}></td>
                          </tr>
                          <br/>
                          <tr>
                            <td style={{width: "30%"}}>
                              <b>SHIPMENT DATE  </b>
                            </td>
                            <td style={{width: "50%"}}>
                            : &ensp;{plantDetails?.shipmentTerms}
                            </td>
                            <td style = {{width: "20%"}}></td>
                          </tr>
                          <br/>
                        </table>
                        </td>                        
                      </tr> */}
            <tr style={{ borderBottom: '0px' }}>
              <td></td>
              <td colSpan={3}>
                <p style={{ textAlign: 'left' }}>
                  <br /><b>Destination </b>
                </p>
              </td>
              <td>: {(plantDetails?.saleOrderItems[0]?.destinationDetails ? plantDetails?.saleOrderItems[0]?.destinationDetails : '') + ',' + (plantDetails?.country ? plantDetails?.country : '')}</td>
            </tr>
            <tr style={{ borderBottom: '0px' }}>
              <td></td>
              <td colSpan={3}>
                <p style={{ textAlign: 'left' }}>
                  <br /><b>PAYMENT </b>
                </p>
              </td>
              <td>: {plantDetails?.saleOrderItems[0].paymentTerms}</td>
            </tr>
            <tr style={{ borderBottom: '0px' }}>
              <td></td>
              <td colSpan={3}>
                <p style={{ textAlign: 'left' }}>
                  <br /><b>SHIPMENT DATE </b>
                </p>
              </td>
              <td>:  {plantDetails?.shipmentDate ? moment(plantDetails.shipmentDate).format('DD-MMM-YYYY') : ' '}</td>
              {/* <td>: {plantDetails?.orderDate?plantDetails.orderDate:'' }</td> */}
            </tr>
            <tr style={{ borderBottom: '0px' }}>
              <td></td>
              <td colSpan={4}>
                <p style={{ textAlign: 'left' }}>
                  <br /><b>FOR {
                    plantDetails?.country === "CANADA" ?
                      <>
                        <b>AZ GEMS CANADA INC:</b>
                      </> : <b>AZ GEMS INC:</b>
                  } </b>
                </p>
              </td>
            </tr>
            <tr style={{ borderBottom: '0px' }}>
              <td ></td>
              <td colSpan={8} >
                <table style={{ width: "100%" }} >
                  <tr>
                    <td style={{ width: "5%" }}>
                      <b style={{ textAlign: 'left', color: '#4EA7C6' }}><u>NOTE: </u></b>
                    </td>
                    <td  >
                      <b style={{ textAlign: 'left', color: '#4EA7C6' }}>This is system generated PO, hence Signature not required</b>
                    </td>
                  </tr>
                </table>
              </td>

            </tr>
            <tr>
              <td></td>
              <td>
                <div style={{ marginTop: '30px' }} ><Row><Col>
                  <img style={{ width: '200px' }} src={signature} />
                </Col><Col>
                    <img
                      style={{ width: '100px', marginLeft: '-83px', marginTop: '-33px' }}
                      src={stampImage} />
                  </Col></Row></div>
              </td>


              {/* <div className='stamp-and-print' style={{ width: '200px', height: '130px' }}></div> */}
              {/* </td> */}
            </tr>

            {/* <tr>UMAAAAA</tr> */}

            {/* <tr>
                      <td style={{textAlign:'center'}} colSpan={7}>
                        <h5><b>Destination : </b>{plantDetails?.saleOrderItems[0].destinationDetails}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{textAlign:'center'}} colSpan={7}>
                        <h5><b>Payment : </b>{plantDetails?.saleOrderItems[0].paymentTerms}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{textAlign:'center'}} colSpan={7}>
                        <h5><b>Shippment Date : </b>{plantDetails?.shipmentTerms}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{textAlign:'center'}} colSpan={7}>
                        <h5><b>For AZ GEMS INC </b></h5>
                      </td>
                    </tr>
                    <tr>
                      <td style={{textAlign:'center'}} colSpan={7}>
                        <h5><b><u>NOTE</u>: This is system generated PO, hence Signature not required</b></h5>
                      </td>
                    </tr> */}
          </table>
        </body>
      </html>
    </div>
  )



}

export default CustomPoPrint;