import React, { useEffect, useRef, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';


import './code-list-grid.css';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { CodeListService } from '@gtpl/shared-services/planning';
import { CodeListDto, CodeListStatus } from '@gtpl/shared-models/planning';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CodeListForm } from '@gtpl/pages/planning/planning-components/code-list-form';
import CodeListPrint from './code-list-print';

/* eslint-disable-next-line */
export interface CodeListGridProps { }

export function CodeListGrid(
  props: CodeListGridProps
) {
  const [codeListData, setCodeListData] = useState<CodeListDto[]>([]);
  const [selectedCodeListData, setSelectedCodeListData] = useState<any>(undefined);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const codeListService = new CodeListService()
  const [compName, setCompName] = useState<string>(null);
  const [selectedInformation, setSelectedInformation] = useState<CodeListDto>();
  useEffect(() => {
    getCodeListsData();
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  const getCodeListsData = () => {
    codeListService.getAllCodeList().then(res => {
      if (res.status) {
        console.log(res)
        setCodeListData(res.data);
      } else {
        if (res.intlCode) {
          setCodeListData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCodeListData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const openFormWithData = (viewData: CodeListDto) => {
    setDrawerVisible(true);
    setSelectedCodeListData(viewData);
  }

  /**
  * 
  * @param productData 
  */
  const deleteProduct = (codeListData: CodeListDto) => {
    codeListData.isActive = codeListData.isActive ? false : true;
    codeListService.activateOrDeactivateProduct(codeListData).then(res => {
      console.log(res);
      if (res.status) {
        getCodeListsData();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
    * 
    * @param variantData 
    */
  const updateCodeList = (codeListData: CodeListDto) => {
    codeListService.updateCodeList(codeListData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getCodeListsData();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const displayModel = (data: CodeListDto,) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Traceability Code',
      dataIndex: 'traceabilityCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.traceabilityCode.localeCompare(b.traceabilityCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('traceabilityCode')
    },

    {
      title: 'Code List Status',
      dataIndex: 'CodeListStatus',
      sorter: (a, b) => a.CodeListStatus.localeCompare(b.CodeListStatus),
      sortDirections: ['descend', 'ascend'],
      responsive: ['sm'],
      filters: [
        {
          text: 'Assigned',
          value: 'Assigned',
        },
        {
          text: 'Available',
          value: 'Available',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.CodeListStatus === value;
      },

    },

    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Product');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteProduct(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Product ?'
                : 'Are you sure to Activate Product ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];
  return (
    <Card title={<span style={{ color: 'white' }}>Code Lists</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/code-list-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >


      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total Products: ' + codeListData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + codeListData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + codeListData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <td className={'ta-b'} style={{ textAlign: 'center' }}>
        <Button style={{ backgroundColor: '#fafafa' }} ><span style={{ color: 'black' }}  ><PrinterOutlined /> Code List</span></Button>
      </td>
      
          <Modal
            className='print-docket-modal'
            key={'modal' + Date.now()}
            width={'80%'}
            visible={isModalVisible}
            title={<React.Fragment>
            </React.Fragment>}
            onCancel={handleCancel}
          >
            <CodeListPrint key={Date.now()}
            // codeListData = {setSelectedInformation}
            //    isUpdate={true}
            //    closeForm={handleCancel}
               />
          </Modal>
        
      <Table
        rowKey={record => record.codeListId}
        columns={columnsSkelton}
        dataSource={codeListData}
        // scroll = {{x:true}}
        // pagination={{
        //   onChange(current) {
        //     setPage(current);
        //   }
        // }}
        // onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <CodeListForm key={Date.now()}
            isUpdate={true}
            codeListData={selectedCodeListData}
            updateCodeList={updateCodeList}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default CodeListGrid;
