import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { SizesDto, ItemCategoriesDto, ItemSubCategoriesDto, ItemsDropDownDto, ItemsReqForCategoryId, ItemRequest, ItemsReqForSubCategoryId, SubCatReqForCategoryId, ItemSubCategoryDropDownDto } from '@gtpl/shared-models/masters';
import { SizesService, ItemsService, ItemCategoryService, ItemSubCategoryService } from '@gtpl/shared-services/masters';

const { Option } = Select;
const { TextArea } = Input;
import './sizes-form.css';


export interface SizesFormProps {

  sizesData: SizesDto;
  updateSize: (size: SizesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function SizesForm(
  props: SizesFormProps
) {


  const [form] = Form.useForm();
  const service = new SizesService;
  let history = useHistory();

  const [itemCategoryData, setItemCategoryData] = useState<ItemCategoriesDto[]>([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState<number>(null);
  const itemCategoryService = new ItemCategoryService();
  const [disable, setDisable] = useState<boolean>(false)
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(null);
  const itemService = new ItemsService();

  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const subCategoryService = new ItemSubCategoryService();
  const [selectedsubCategory, setSelectedSubCategory] = useState<number>(null);

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    getAllItemCategories();
    getItemSubCategories();
  }, [])

  const getAllItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Item categories retrived Successfully');
        //form.setFieldsValue({...res.data});
        setItemCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getItemSubCategories = () => {

    const itemCategoryId = form.getFieldValue('itemCategoryId');
    const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const getItems = (val) => {
  //   const categoryId = form.getFieldValue('itemCategoryId');
  //   const itemsRequest = new ItemsReqForCategoryId(categoryId, val);
  //   itemService.getItemForCategoryAndSubCat(itemsRequest).then((res) => {

  //     if (res.status) {
  //       // console.log(res.data);
  //       setItems(res.data);
  //     } else {

  //       setItems([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setItems([]);
  //   });
  // }


  const save = (Data: SizesDto) => {
   setDisable(true) 
    service.create(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');
        history.push("/sizes-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: SizesDto) => {
    setDisable(false)
    // console.log(values);
    if (props.isUpdate) {
      props.updateSize(values);
    } else {
      setDisable(false)
      save(values);
    }

  };

  const handleItemCategory = (value, item) => {
    setSelectedItemCategory(value);
    form.setFieldsValue({ itemId: '', itemSubCategoryId: '' });
  }

  const handleSubCategory = (value, item) => {
    setSelectedSubCategory(value);
    form.setFieldsValue({ itemId: '' });
  }

  const handleItem = (value, item) => {
    setSelectedItem(value);
  }

  const onReset = () => {
    form.resetFields();
  };

  const onFocus = () => {
    //console.log('focus');
  }

  const onSearch = (val) => {
    //console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>SIZES</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/sizes-view' ><span style={{ color: 'white' }} >  {(props.isUpdate === false) &&
        <Button className='panel_button' >View </Button>
      }  </span></Link>} >


      <Form form={form} initialValues={props.sizesData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        
        <Row gutter={24}>
        <Form.Item name="sizeId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name={'itemCategoryId'} label="Item category" rules={[{ required: true, message: 'Missing Item Category' }]}>
              <Select
                optionFilterProp="children"
                placeholder="Select Item Category"
                onChange={getItemSubCategories}
                onSelect={handleItemCategory}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              //allowClear
              >
                {itemCategoryData?.map(dropData => {
                  return <Option key={dropData.itemCategoryId} value={dropData.itemCategoryId}>{dropData.itemCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name={'itemSubCategoryId'} label="Item Sub Category" rules={[{ required: true, message: 'Missing Item Category' }]}>
              <Select
                placeholder="Select Item Sub Category"
                // onChange={getItems}
                onSelect={handleSubCategory}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {subCategoryData?.map(dropData => {
                  return <Option key={dropData.itemSubCategoryId} value={dropData.itemSubCategoryId}>{dropData.itemSubCategory
                  }</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="itemId" label="Item" rules={[{ required: true, message: 'Missing Item' }]}>
              <Select
                placeholder="Select Item"
                onSelect={handleItem}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {items?.map(dropData => {
                  return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  >
            <Form.Item
              name="sizeName"
              label="Size"
              rules={[
                {
                  required: true,
                  message: "Size is required",
                }, 
                // {
                //   pattern:/^\w[A-Z@#0-9.]/,
                //   message: `Invalid Size`
                // }
              ]}>
              <Input />

            </Form.Item>
          </Col>


        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default SizesForm;
