import { DesignationDto, TeamCalenderDto } from "@gtpl/shared-models/hrms";
import { TeamCalenderService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Input, Button, Tag, Tooltip, Divider, Popconfirm, Switch, Card, Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Table, { ColumnProps } from "antd/lib/table";
import { TeamCalender } from "../../../team-calender-form/src";
import { Link } from "react-router-dom";
import moment from "moment";

export interface TeamCalenderGridProps {}

export function TeamCalenderGrid(
  props: TeamCalenderGridProps
) {
  
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [teamcalenderData, setTeamCalenderData] = useState<TeamCalenderDto[]>([]);
  const [selectedTeamCalender, setSelectedTeamCalender] = useState<any>(undefined);

  const teamcalenderService = new TeamCalenderService();

  useEffect(() => {
    getAllTeamCalender();
  },[]);

  const getAllTeamCalender = () => {
    teamcalenderService.getAllTeamCalender().then(res => {
      if(res.status){
        setTeamCalenderData(res.data);
      }else {
        if(res.intlCode){
          setTeamCalenderData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTeamCalenderData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param TeamCalenderData 
   */
   const deleteTeamCalender = (teamcalenderData:TeamCalenderDto) => {
    teamcalenderData.isActive=teamcalenderData.isActive?false:true;
    teamcalenderService.activateOrDeactivateTeamCalender(teamcalenderData).then(res => { console.log(res);
      if (res.status) {
        getAllTeamCalender();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
   const updateTeamCalender = (teamcalenderData: TeamCalenderDto) => {
    teamcalenderService.updateTeamCalender(teamcalenderData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllTeamCalender();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(viewData: TeamCalenderDto)=>{
    setDrawerVisible(true);
    viewData.fromDate=moment(viewData.fromDate).format('YYYY-MM-DD');
    viewData.toDate=moment(viewData.toDate).format('YYYY-MM-DD');
    setSelectedTeamCalender(viewData);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Shift Group Code',
      dataIndex: 'shiftCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.shiftCode?.length - b.shiftCode?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shiftCode')
    },
    {
      title: 'From Date',
      dataIndex: 'fromDate',
      // responsive: ['lg'],
      sorter: (a, b) => a.fromDate?.localeCompare(b.fromDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.fromDate ? moment(record.fromDate).format('YYYY-MM-DD') : '-'}
        </span>
      },
    },
    {
      title: 'To Date',
      dataIndex: 'toDate',
      // responsive: ['lg'],
      sorter: (a, b) => a.toDate?.localeCompare(b.toDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.toDate ? moment(record.toDate).format('YYYY-MM-DD') : '-'}
        </span>
      },
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      // responsive: ['lg'],
      sorter: (a, b) => a.shift?.length - b.shift?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shift')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>  
             <Tooltip placement="top" title='Edit'>     
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Team Calender');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteTeamCalender(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Team Calender ?'
                :  'Are you sure to Activate Team Calender ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   
  }

  return (
    <Card title={<span style={{color:'white'}}>Team Calendar</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/teamcalender' ><Button className='panel_button' >Create </Button></Link>}>
          <Table
          rowKey={record => record.id}
          columns={columnsSkelton}
          dataSource={teamcalenderData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <TeamCalender key={Date.now()}
                updateTeamCalender={updateTeamCalender}
                isUpdate={true}
                teamcalenderData={selectedTeamCalender}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}
export default TeamCalenderGrid; 