import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col, InputNumber, Modal, Descriptions, Popover, Tag } from 'antd';
import {  Link, useHistory, Redirect } from "react-router-dom";
import { PrinterOutlined, ArrowUpOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons'
import {saleOrder, SaleOrderService} from '@gtpl/shared-services/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto} from '@gtpl/shared-models/sale-management';
import { CommonInvoice } from './common-invoice';
import { BillOfLading, CommercialInvoice, CommercialPackingList, CusInvoice, CustomPoPrint, PostPlantPackingList,DocumentListGrid, Eform13, FreigstInvoice, IsfWorksheet, PlantInvoice, PlantPackingList, ShipmentDetails, ShippingInstructions ,PostCommercialInvoice, CustomsInvoice} from '..';
import { PoPrint } from '@gtpl/pages/procurement-masters/procurement-master-pages/po-detail-view-pages';
import { UploadedFile } from '@nestjs/common';
import DocumentUploadForm from './post-sailing-documents-upload';
import { DocumentsListService } from '@gtpl/shared-services/logistics';
import { DocumentsList } from '@gtpl/shared-models/logistics';
import { DocumentType, EmployeeRolesEnum } from '@gtpl/shared-models/common-models';

const { Option } = Select;

export interface DocumentRegisterProps {}

export function DocumentRegister(props: DocumentRegisterProps){
    const [form] = Form.useForm();
    let history = useHistory();
    
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const [soData, setSoData] = useState<SaleOrderDetailViewInfoDTO>(undefined);
  const [postSailingData, setPostSailingData] = useState<DocumentsList[]>(undefined);
  const [preLoadingData, setPreLoadingData] = useState<DocumentsList[]>(undefined);
  const [display, setDisplay] = useState<string>('none');
  const [selectedSaleOrder,setSelectedSaleOrder] = useState<number>(null);
  const [docName,setDocName] = useState<string>(null);
  const [docType,setDocType] = useState<DocumentType>(null);
  const [soId1, setSoIdState1] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [compName, setCompName] = useState<string>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const service = new SaleOrderService;
  const documentsListService = new DocumentsListService();

    useEffect(() => {
        getAllSaleOrderItems();
      }, []);

      const getAllSaleOrderItems= () => {
        service.getSaleOrdersDropDownList().then(res => {
          if (res.status) {
            console.log("-----------------");
            console.log(res.data);
            setSoItemData(res.data);
          } else {
            if (res.intlCode) {
              setSoItemData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setSoItemData([]);
        })
      }
      const getPostSailingDocuments= (saleorderId) => {
        documentsListService.getDocumentsDetailsBySoId({saleOrderId:saleorderId, documentCategory:null}).then((res) => {
          if(res.status){
            setPostSailingData(res.data.filter(rec => rec.documentType === DocumentType.POSTSAILING));
            setPreLoadingData(res.data.filter(rec => rec.documentType === DocumentType.PRELOADING));
            // AlertMessages.getSuccessMessage(res.internalMessage);
          }else {
            if (res.intlCode) {
              setPostSailingData(undefined);
                // AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setPostSailingData(undefined);
        })
      }
      const getSaleOrderDetails= (saleorderId) => {
        service.getSaleOrderInformationById({saleOrderId:saleorderId}).then(res => {
          if (res.status) {
            console.log("-----------------");
            console.log(res.data);
            setSoData(res.data);
            getPostSailingDocuments(res.data.saleOrderId);
          } else {
            if (res.intlCode) {
              setSoData(undefined);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setSoData(undefined);
        })
      }
      
      const handleSaleOrder=(value, item)=>{
        console.log(value);
        setSelectedSaleOrder(value);
        setDisplay('block');
        // let data : any = soItemData.find(rec => rec.saleOrderId === value);
        // console.log(data);
        // setSoData(data);
        getSaleOrderDetails(value);
      }
      const handleCancel = () => {
        setIsModalVisible(false);
      };
      const setSoId1 = (saleOrderId:number,compName:string, docName?: string, documentType?: DocumentType) => {
        setDocName(docName);  
        setDocType(documentType);  
        setSoIdState1(saleOrderId);  
        setIsModalVisible(true);  
        setCompName(compName);  
      }
      
    /**
     * get form data 
     * @param fromDoc 
     * @param toDoc 
     */
    const getCssFromComponent = (fromDoc, toDoc) => {
      Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
        console.log(styleSheet.cssRules);
        if (styleSheet.cssRules) { // true for inline styles
          const newStyleElement = toDoc.createElement('style');
          Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
            newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
          });
          toDoc.head.appendChild(newStyleElement);
        }
      });
    }
    /**
     * print modal
     */
    const printOrder = () => {
      const divContents = document.getElementById('printme').innerHTML;
      const element = window.open('', '', 'height=700, width=1024');
      element.document.write(divContents);
      getCssFromComponent(document, element.document);
      element.document.close();
      setTimeout(() => {
        element.print();
        element.close(); // to close window when click on cancel/Save
        setIsModalVisible(true); // model should be open
      }, 1000);
    };
  
    function AsDownload() {
      //window.open(pathString, target);
      window.open("/#/common-invoice-detail-view", "_blank");
    };
    const closeDrawer=()=>{
      setDrawerVisible(true);
    }

    

    const dynamicRows = (doc) =>{
      return <tr>
        <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}></span></td>
        <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}></span></td>
        <td className={'ta-b'} style={{textAlign:'center'}}>
          <Button style={{backgroundColor:'#fafafa'}} 
          // onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments",doc.documentCategory, DocumentType.POSTSAILING);}}
          ><PrinterOutlined />{doc.documentCategory}</Button>&nbsp;&nbsp;
          {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
          <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", doc.documentCategory,DocumentType.POSTSAILING);}}/> }&nbsp;
          {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD))  &&
          <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",doc.documentCategory,DocumentType.POSTSAILING);}}/> }
        </td>
      </tr>
    }
    return (
      <>
        <Card title={<span style={{color:'white'}}>Document Register</span>} style={{textAlign:'center'}}
        // extra={<Link to='/items-view' ><span style={{color:'white'}} >{(props.isUpdate===false) && <span style={{color:'white'}} ><Button className='panel_button' > View </Button> </span> } </span></Link>}
         headStyle={{backgroundColor: '#69c0ff', border: 0 }}
        >
        <Form form={form} layout={'vertical'} >
          <Row gutter={24}>
            
    
            <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
              <Form.Item
                name="saleOrder"
                label="Customer PO"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Sale Order is required'
                //   },
                // ]}
    
              >
                <Select
                      showSearch
                      // style={{ width: 260 }}
                      placeholder="Select PO Number"
                      optionFilterProp="children"
                      onSelect={handleSaleOrder}
                    //   onFocus={onFocus}
                    //   onBlur={onBlur}
                    //   onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option key={0} value={null}>Select Sale Order</Option>
                      {soItemData.map((data)=>{
                        return <Option key={data.saleOrderId} value={data.saleOrderId}>{data.poNumber}</Option>
                      })}
                    </Select>
                </Form.Item>
            </Col>
           
            </Row>

           </Form>
           {soData != undefined      ?       
           <><Descriptions column={4}>
              <Descriptions.Item label="Customer">
                {soData.customer}
              </Descriptions.Item>
              <Descriptions.Item label="End Customer">
                {soData.endCustomer}
              </Descriptions.Item>
              <Descriptions.Item label="PO Date">
                  {soData.invDate}
                </Descriptions.Item>
              <Descriptions.Item label="PO Number" >
                {soData.poNo}
              </Descriptions.Item>
            </Descriptions>

              <Descriptions column={4}>
              <Descriptions.Item label="ETD">
                {soData.etd}
              </Descriptions.Item>
                <Descriptions.Item label="Is Invoiced?">
                  {soData.isInvoiced}
                </Descriptions.Item>
              </Descriptions></>
          : null}
           {
            selectedSaleOrder&&
           <Card bodyStyle={{backgroundColor:'gray'}}>
           <html style={{display:display}}>
           <head></head>
           <body style={{backgroundColor:'lightgray'}}>
           <table className={'ta-b'} style={{ width: '100%', color:'white' }}>
              <tr>
                <th className={'ta-b'} style={{color:'black', textAlign:'center'}}>Pre Sailing</th>
                <th className={'ta-bb'} style={{color:'black', textAlign:'center'}}>Pre Loading</th>
                <th className={'ta-b'} style={{color:'black', textAlign:'center'}}>Post Sailing</th>
                {/* <th className={'ta-b'} style={{color:'black', textAlign:'center'}}>Country Based</th> */}
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"CommonInvoice","Commercial Invoice",DocumentType.PRESAILING);}}><span style={{color:'black'}}><PrinterOutlined /> Commercial Invoice</span></Button>
                </td>
                {/* <td className={'ta-b'} style={{textAlign:'center'}}>
                  {soData !=undefined && soData.isInvoiced === 'YES' ? 
                  <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"ShippingInstructions","Shipping Instructions",DocumentType.PRELOADING);}}><PrinterOutlined />Shipping Instructions</Button>:<span style={{color:'black'}}>-</span>}
                </td> */}
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {soData !=undefined && soData.isInvoiced === 'YES' ? 
                    <>
                      <Button style={{backgroundColor:'#fafafa'}} 
                      // onClick={() => {setSoId1(selectedSaleOrder,"BlStatusGrid");}}
                      ><PrinterOutlined /> BL Draft</Button>&nbsp;&nbsp;
                       {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                      <span><UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", "Bill Of Lading",DocumentType.PRELOADING);}}/> </span>}&nbsp;&nbsp; 
                      {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                      <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments","Bill Of Lading",DocumentType.PRELOADING);}}/> }
                    </>:<span style={{color:'black'}}>-</span>
                    }
                </td>

                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {soData !=undefined && soData.isInvoiced === 'YES' ? 
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"CommercialInvoice", "Commercial Invoice", DocumentType.POSTSAILING);}}> <PrinterOutlined /> Commercial Invoice</Button>:<span style={{color:'black'}}>-</span>
                  }
                </td>
                
               
                {/* <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}>-</span> */}
                {/* <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"FreigstInvoice");}}> <PrinterOutlined /> Freight Invoice</Button> */}
                {/* </td> */}
                {/* <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}>-</span> */}
                {/* <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"");}}> <PrinterOutlined /> Invoice details data excel export</Button> */}
                {/* </td> */}
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"CustomsInvoice","CustomsInvoice",DocumentType.PRESAILING);}}><PrinterOutlined />Customs Invoice</Button></td>
                <td></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {soData !=undefined && soData.isInvoiced === 'YES' ? 
                  <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"PostPlantPackingList","PostPlantPackingList",DocumentType.POSTSAILING);}}><PrinterOutlined /> Commercial Packing List</Button>:<span style={{color:'black'}}>-</span>}
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"PlantInvoice","Plant Invoice",DocumentType.PRESAILING);}}><PrinterOutlined /> Plant Invoice</Button></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>0?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} 
                    // onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[0]?.documentCategory,preLoadingData[0]?.documentCategory,DocumentType.PRESAILING);}}
                    >
                    <PrinterOutlined /> {preLoadingData[0]?.documentCategory}</Button> &nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    
                    (preLoadingData[0]?.documentCategory!='Fill Seal')?<UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[0]?.documentCategory,DocumentType.PRELOADING);}}/>:''
                    }&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[0]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>:""
                  }
                </td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"IsfWorksheet", "ISF WorkSheet", DocumentType.POSTSAILING);}}><PrinterOutlined /> ISF WorkSheet</Button></td>
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"PlantPackingList","Plant Packing List",DocumentType.PRESAILING);}}><PrinterOutlined /> Plant Packing List</Button></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>1?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[1]?.documentCategory,preLoadingData[1]?.documentCategory,DocumentType.PRESAILING);}}><PrinterOutlined /> {preLoadingData[1]?.documentCategory}</Button>&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[1]?.documentCategory,DocumentType.PRELOADING);}}/>}&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[1]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>
                    :""
                  }
                </td>
                {/* <td className={'ta-b'} style={{textAlign:'center'}}>
                {soData !=undefined && soData.isInvoiced === 'YES' ?
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"E-Form13","E-Form13",DocumentType.PRELOADING);}}><PrinterOutlined /> E Form-13</Button>
                :<span style={{color:'black'}}>-</span>}
                </td> */}
                <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"shipmentDetails","Shipment Details",DocumentType.POSTSAILING);}}><PrinterOutlined /> Shipment Details</Button></td>
                
                {/* <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}>-</span> */}
                {/* <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"");}}><PrinterOutlined /> Simp</Button> */}
                {/* </td> */}
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}><Button style={{backgroundColor:'#fafafa'}} onClick={()   => {setSoId1(selectedSaleOrder,"CustomsPO","Customs PO",DocumentType.PRESAILING);}}><PrinterOutlined />Customs PO</Button></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>2?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[2]?.documentCategory,preLoadingData[2]?.documentCategory,DocumentType.PRESAILING);}}><PrinterOutlined /> {preLoadingData[2]?.documentCategory}</Button> &nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[2]?.documentCategory,DocumentType.PRELOADING);}}/>}&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[2]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>
                    :"-"
                  }  
                </td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} 
                  // onClick={() => {setSoId1(selectedSaleOrder,"IsfWorksheet","SIMP",DocumentType.POSTSAILING);}}
                 ><PrinterOutlined />SIMP</Button>&nbsp;&nbsp;
                 {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                  <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", "SIMP",DocumentType.POSTSAILING);}}/> }&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                  <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments", "SIMP",DocumentType.POSTSAILING);}}/>
                }
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}></span></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>3?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[3]?.documentCategory,preLoadingData[3]?.documentCategory,DocumentType.PRESAILING);}}><PrinterOutlined /> {preLoadingData[3]?.documentCategory}</Button> &nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[3]?.documentCategory,DocumentType.PRELOADING);}}/> }&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[3]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>
                    :""
                  } 
                </td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} 
                  // onClick={() => {setSoId1(selectedSaleOrder,"CO","CO",DocumentType.POSTSAILING);}}
                  ><PrinterOutlined />CO</Button>&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                  <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments","CO",DocumentType.POSTSAILING);}}/> }&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                  <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments","CO",DocumentType.POSTSAILING);}}/> }
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}></span></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>4?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[4]?.documentCategory,preLoadingData[4]?.documentCategory,DocumentType.PRESAILING);}}><PrinterOutlined /> {preLoadingData[4]?.documentCategory}</Button> &nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[4]?.documentCategory,DocumentType.PRELOADING);}}/> }&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[4]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>
                    :""
                  } 
                </td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} 
                  // onClick={() => {setSoId1(selectedSaleOrder,"DS","",DocumentType.POSTSAILING);}}
                  ><PrinterOutlined />DS</Button>&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                  <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments","DS",DocumentType.POSTSAILING);}} /> }&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                  <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments","DS",DocumentType.POSTSAILING);}}/> }
                </td>
              </tr>
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}><span style={{color:'black'}}></span></td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  {
                    preLoadingData?.length>5?
                    <>
                    <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,preLoadingData[5]?.documentCategory,preLoadingData[5]?.documentCategory,DocumentType.PRESAILING);}}><PrinterOutlined /> {preLoadingData[5]?.documentCategory}</Button> &nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                    <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", preLoadingData[5]?.documentCategory,DocumentType.PRELOADING);}}/> }&nbsp;&nbsp;
                    {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                    <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments",preLoadingData[5]?.documentCategory,DocumentType.PRELOADING);}}/> }
                    </>
                    :""
                  } 
                </td>
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#fafafa'}} 
                  // onClick={() => {setSoId1(selectedSaleOrder,"OBL","",DocumentType.POSTSAILING);}}
                  ><PrinterOutlined />OBL</Button>&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE))  &&
                  <UploadOutlined  title='upload' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", "obl",DocumentType.POSTSAILING);}}/> }&nbsp;&nbsp;
                  {((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_MANAGER) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.DOCUMENTATION_EXECUTIVE) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_HEAD) ||(JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE))  &&
                  <DownloadOutlined  title='download' style={{ color: 'black' }} onClick={() => {setSoId1(selectedSaleOrder,"downloadDocuments", "obl",DocumentType.POSTSAILING);}}/> }
                </td>
              </tr>
              {postSailingData?.map(doc => {
                return dynamicRows(doc);
                // getSaleOrderDetails(selectedSaleOrder)
              })
              }
              <tr>
                <td className={'ta-b'} style={{textAlign:'center'}}></td>
                {((JSON.parse(localStorage.getItem('role'))!=EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE)) ?
                <td className={'ta-b'} style={{textAlign:'center'}}><Button style={{backgroundColor:'#5360fa',}} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", null, DocumentType.PRELOADING);}}><span style={{color:"black"}}><b>Add More</b></span></Button></td>:<td className={'ta-b'} style={{textAlign:'center'}}></td> }
                {((JSON.parse(localStorage.getItem('role'))!=EmployeeRolesEnum.LOGISTICS_PLANT_EXECUTIVE)) ?
                <td className={'ta-b'} style={{textAlign:'center'}}>
                  <Button style={{backgroundColor:'#5360fa',}} onClick={() => {setSoId1(selectedSaleOrder,"uploadDocuments", null, DocumentType.POSTSAILING);}}><span style={{color:"black"}}><b>Add More</b></span></Button></td>
                  :<td className={'ta-b'} style={{textAlign:'center'}}></td> }
              </tr>
              {/* <tr> */}
              {/* <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td> */}
              {/* <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"");}}><PrinterOutlined /> Species code-shrimp excel download</Button>
              </td> */}
              {/* </tr>
              <tr>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td> */}
              {/* <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"");}}><PrinterOutlined /> SIMP instructions Excel download</Button></td> */}
              {/* </tr> */}
              {/* <tr>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}><Link to='/' ></Link></td>
              <td className={'ta-b'} style={{textAlign:'center'}}>
                <Button style={{backgroundColor:'#fafafa'}} onClick={() => {setSoId1(selectedSaleOrder,"PoPrint");}}><PrinterOutlined /> PO print</Button></td>
              </tr> */}
           </table>
           </body>
           </html>

            {isModalVisible ? 
            <>
                  <Modal
                    className='print-docket-modal'
                    key={'modal' + Date.now()}
                    width={'80%'}
                    style={{ top: 30, alignContent: 'right' }}
                    visible={isModalVisible}
                    title={<React.Fragment>
                    </React.Fragment>}
                    onCancel={handleCancel}
                    footer={[
                      
                    ]}
                    >
                    {compName === 'uploadDocuments'?null:compName === 'downloadDocuments'?null:
                      <Button onClick={printOrder} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                    }
                    {compName === 'CommonInvoice' ?
                      <CommonInvoice saleOrderId={soId1} />
                      : compName === 'CommercialInvoice' ?
                        <PostCommercialInvoice saleOrderId={soId1} />
                        : compName === 'FreigstInvoice' ?
                          <FreigstInvoice saleOrderId={soId1} />
                        : compName === 'E-Form13' ?
                          <Eform13 saleOrderId={soId1} />
                          : compName === 'CustomsInvoice' ?
                            <CustomsInvoice saleOrderId={soId1} />
                            : compName === 'PostPlantPackingList' ?
                              <PostPlantPackingList saleOrderId={soId1} />
                              : compName === 'PlantInvoice' ?
                                <PlantInvoice saleOrderId={soId1} />
                                : compName === 'ShippingInstructions' ?
                                  <ShippingInstructions saleOrderId={soId1} />
                                  : compName === 'IsfWorksheet' ?
                                    <IsfWorksheet saleOrderId={soId1} soData={soData} />
                                    : compName === 'PlantPackingList' ?
                                      <PlantPackingList saleOrderId={soId1} />
                                      : compName === 'shipmentDetails' ?
                                      <ShipmentDetails saleOrderId={soId1} soData={soData} />
                                      :  compName === 'BlStatusGrid' ?
                                      <BillOfLading saleOrderId={soId1} />
                                      :  compName === 'CustomsPO' ?
                                      <CustomPoPrint saleOrderId={soId1} />
                                      :compName === 'uploadDocuments'? <DocumentUploadForm key={Date.now()} saleOrderId={selectedSaleOrder} documentCategory={docName} documentType={docType}
                                      isUpdate={false}
                                      closeForm={closeDrawer}
                                       />:compName === 'downloadDocuments'? <DocumentListGrid key={Date.now()} saleOrderId={selectedSaleOrder} documentCategory={docName} documentType={docType}
                                       closeForm={closeDrawer}
                                        />: ""}
                  </Modal></>
      :""}
           </Card>}
        </Card>
        </>
      );       
}
export default DocumentRegister;