import { SkuService } from "@gtpl/shared-services/masters";
import {SkuDetails} from  '@gtpl/shared-models/masters';
import { Button, Card, Col, Form, Row, Select } from "antd";
import React, { useState, useEffect, useRef } from 'react';
const {Option} = Select
import { Link,useHistory, useLocation } from 'react-router-dom';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';



export interface SkuDetailViewProps{
    code : string;
}
export function SkuDetailView (){
    const [product,setProduct] = useState<any>()
    const productService = new SkuService()
    const [form] = Form.useForm();
    let history = useHistory();
    let location = useLocation();
    const skuId = location.state
    console.log(skuId)

    useEffect(()=>{
        getProducts();
        // if(props.code){
        //   console.log(props.code)
        // }
    },[])
 
    const getProducts = () => {
        productService.getActiveSkuCodes().then(res => {
            if(res.status){
                setProduct(res.data)
            }

        }).catch(err => {
            return err

         })
        }
      
        const createSkuDetails = (skuData:SkuDetails) => {
          skuData.skuCodeId = Number(skuId)
       
          productService.createSkuDetails(skuData).then(res => {
            if (res.status) {
              AlertMessages.getSuccessMessage('Sku Code Created Successfully');
              history.push("/sku-view");
              onReset();
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            // AlertMessages.getErrorMessage(err.message);
          })
        }

    

    // const onReset = () => {
    //     form.resetFields();
    //   };
      const onFocus=() =>{
        console.log('focus');
      }
      
      const onSearch=(val)=> {
        console.log('search:', val);
      }
      const onBlur=() =>{
        console.log('blur');
      }
      const onReset = () => {
        form.resetFields();
      };


return(
    <>
    
    <Card title={<span style={{ color: 'white' }}>Sku Detail View</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  extra={<Link to='/sku-view' ><span style={{color:'white'}} ><Button className='panel_button' >View</Button> </span></Link>} >
            <Form layout={'vertical'} onFinish={createSkuDetails}>
                

            <Row gutter={30}>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
      <Form.Item
        name="multipleVarientCode"
        label="multiple_Varient_Code:"
        rules={[
          {
            required: true,
            message: "Product is required"
          },
        ]}
      >
        <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Product"
                optionFilterProp="children"
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                mode = 'multiple'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Product</Option>
                {product?.filter(item => item.skuCodeId != skuId).map((product)=>{
                  return <Option value={product.skuCodeId}> {product.variantCode}  </Option>
                })}
              </Select>
      </Form.Item></Col >
      </Row>
        <Col style={{textAlign:'right'}} span={24}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          </Col>
            </Form>

            </Card>

    </>
)

}

export default SkuDetailView;


