
import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CurrenciesForm} from '@gtpl/pages/master/master-components/currencies-form';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { CurrencyDto } from '@gtpl/shared-models/masters';
import { FormattedMessage } from 'react-intl';
import {CurrencyService} from '@gtpl/shared-services/masters';
import GetCumulatives, { CumulativeModel,CumulativeTypes } from './get-cumulatives';
import './currencies-grid.css';
import { Link } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


// const data:ItemVariant[] = [
  //   {
    //     variantId: '1',
    //     variantName: 'Shrimp',
    //     isActive:true,    
    //   },
    //   {
//     variantId: '2',
//     variantName: 'General',
//     isActive:true,

//   },
//   {
  //     variantId: '3',
  //     variantName: 'Fish',
  //     isActive:false,
//   }
// ];


/* eslint-disable-next-line */
export interface CurrenciesGridProps {}

export const CurrenciesGrid =(props: CurrenciesGridProps) =>  {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [variantData, setVariantData] = useState<CurrencyDto[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(undefined);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new CurrencyService();
  
  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
      record[dataIndex]
    ? record[dataIndex]
    .toString()
    .toLowerCase()
    .includes(value.toLowerCase())
    : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
        searchedColumn === dataIndex ? (
          <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
          />
          ) :text
          )
          : null
          
  });
  
  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Currency Name',
      dataIndex: 'currencyName',
      // responsive: ['lg'],
      sorter: (a, b) => a.currencyName.localeCompare(b.currencyName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('currencyName')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Currencies');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
              />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteVariant(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate this Currency ?'
                :  'Are you sure to Activate this Currency ?'
              }
              >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {getAllCurrencys();}, [])
  
  const getAllCurrencys= () => {
    service.getAllCurrencies(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {setVariantData(res.data);
      } else {
        if (res.intlCode) {
          setVariantData([]);
          // console.log(res);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setVariantData([]);
    })
  }
  
  
  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }
  
  //TO open the form for updation
  const openFormWithData=(CurrencyViewData: CurrencyDto)=>{
    setDrawerVisible(true);
    setSelectedVariant(CurrencyViewData);
  }
  
  
  const saveVariant = (variantData: CurrencyDto) => {
    variantData.currencyId=0;
    // variantData.isActive=true;
    service.createCurrency(variantData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Currency Created Successfully');
          getAllCurrencys();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    
    /**
     * 
     * @param variantData 
     */
    const updateCurrency = (currencyData: CurrencyDto) => {
      currencyData.updatedUser= JSON.parse(localStorage.getItem('username'))
      service.updateCurrency(currencyData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllCurrencys();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    /**
     * 
     * @param CurrencyViewData 
     */
    const deleteVariant = (CurrencyViewData:CurrencyDto) => {
      CurrencyViewData.isActive=CurrencyViewData.isActive?false:true;
      service.ActivatedeActivateCurrency(CurrencyViewData).then(res => { console.log(res);
        if (res.status) {
          getAllCurrencys();
          AlertMessages.getSuccessMessage('Success'); 
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

    const cumulativeSkelton:CumulativeModel[]=[
      {
        dataIndex:'currencyId',
        lableName:'Currency',
        lablecolor:'#eb2f96',
    cumulativeType:CumulativeTypes.OCCURENCE
    },
    // {
      //   dataIndex:'variantName',
    //   lableName:'Variant Name',
    //   lablecolor:'#eb2f96',
    //   cumulativeType:CumulativeTypes.VALUE
    //   }
      
  ]
  return (

      
      <Card title={<span style={{color:'white'}}>Currencies</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/Currencies-form' ><Button className='panel_button' >Create </Button></Link>}>


     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Currencies: ' + variantData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + variantData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + variantData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
      <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
          rowKey={record => record.variantId}
          columns={columnsSkelton}
          dataSource={variantData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{x:true}}
          onChange={onChange}
          bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <CurrenciesForm key={Date.now()}
                updateItem={updateCurrency}
                isUpdate={true}
                // saveItem={saveVariant}
                currencyData={selectedVariant}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
        </Card>
  );
}


export default CurrenciesGrid;