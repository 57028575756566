import React, { useEffect, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, Space, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './suppliers-form.css';
import { Link, useHistory } from 'react-router-dom';
import { SupplierTypeEnum, SupplierTypeInput } from '@gtpl/shared-models/common-models'
const { Option } = Select;
import { CertificationsService, SuppliersService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CertificatesDto, FarmersDropDownDto, SupplierDTO } from '@gtpl/shared-models/masters';
import moment from 'moment';
/* eslint-disable-next-line */
export interface SuppliersFormProps {
  supplierData: SupplierDTO;
  updateSupplier: (supplier: SupplierDTO) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function SuppliersForm(
  props: SuppliersFormProps
) {
  const [form] = Form.useForm();
  const [isBroker, setIsBroker] = useState<boolean>(false);
  const [isCommissionReq, setIsCommissionReq] = useState<boolean>(false);
  const [supplierName , setSupplierName] = useState<any>('supplier Name');
  const [supplierCode , setSupplierCode] = useState<any>('supplier Code');
  const [isFarmer, setIsFarmer] = useState<boolean>(false);
  const [farmersList, setFarmersList] = useState<FarmersDropDownDto[]>([]);
  const [certificationData, setCertificationData] = useState<CertificatesDto[]>([]);
  const [disable, setDisable] = useState<boolean>(false)

  let history = useHistory();

  const service = new SuppliersService;
  const certificateService = new CertificationsService();


  useEffect(() => {
    getAllFarmers();
    getCertifications();
  }, [])
  
  useEffect(() => {
    checkIsBroker()
  }, [props.supplierData])
  const getAllFarmers = () => {
    service.getAllFarmers().then((res) => {
      if (res.status) {
        setFarmersList(res.data);
      } else {
        setFarmersList([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFarmersList([]);
    });
  }

  const getCertifications = () => {
    certificateService.getAllActiveCertifications().then((res) => {
      if (res.status) {
        setCertificationData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setCertificationData([]);
      }
    })
  }

  const checkIsBroker = () => {
    const category = form.getFieldValue('supplierCategory');
    if(category == SupplierTypeEnum.AGENT){
      setIsCommissionReq(true)
      setSupplierName('Agent Name')
      setSupplierCode('Agent Code')
    }else{
      setIsCommissionReq(false)
    }
    if (category == SupplierTypeEnum.BROKER) {
      setIsBroker(true);
      setIsFarmer(false);
      setSupplierName('Broker Name')
      setSupplierCode('Broker Code')
    }
    else if(category == SupplierTypeEnum.FARMER){
      setSupplierName('Farmer Name')
      setSupplierCode('Farmer Code')
      setIsFarmer(true);
    }
     else if ((category == SupplierTypeEnum.FARMER) || (category == SupplierTypeEnum.AGENT)) {
      setIsFarmer(true);
      setIsBroker(false);
    }
    else {
      setIsBroker(false);
    }
  }

  const onReset = () => {
    form.resetFields();
  };

  const save = (supplierInfo) => {
    setDisable(true)
    service.createSupplier(supplierInfo).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');
        history.push("/suppliers-view");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (supplierInfo) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateSupplier(supplierInfo);
    } else {
      setDisable(false)
      save(supplierInfo);
    }
  }

  const getsupplierCat = (e) => {
    console.log(e)
    console.log(e.target.value)
  }

  console.log(form.getFieldValue('supplierCategory'))

  return (
    <Card title={<span style={{ color: 'white' }}>Farmers</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate ? "" : <Link to="/suppliers-view"  ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form layout="vertical" form={form} onFinish={saveData} initialValues={props.supplierData}>
        <Row gutter={24}>
          <Form.Item
            name="supplierId"
          >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="supplierCategory"
              label="Category"
              rules={[
                {
                  required: true, message: 'Missed Category'
                },
              ]}>
              <Select
                placeholder="Select Category"
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                onChange={checkIsBroker}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {/* <Option key={0} value={null}>Select Category</Option> */}
                {SupplierTypeInput.map((type) => {
                  return <Option key={type.key} value={type.key}>{type.value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="supplierCode"
              label= {supplierCode}
              rules={[
                {
                  required: false, message: 'Missed Code'
                },
              ]}>
              <Input disabled/>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="supplierName"
              label = {supplierName}
              rules={[
                {
                  required: true, message: 'Missed Name'
                },
                {
          
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="gstNumber"
              label="GST Number"
              rules={[
                {
                  required: false, message: 'Missed GST Number'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="contactPerson"
              label="Contact Person"
              rules={[
                {
                  required: false, message: 'Missed Contact Person'
                },
                {
          
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="street"
              label="Street"
              rules={[
                {
                  required: false, message: 'Missed Street'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="apartment"
              label="Apartment"
              rules={[
                {
                  required: false, message: 'Missed Apartment'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: false, message: 'Missed City'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: false, message: 'Missed State'
                },
                {
          
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="district"
              label="District"
              rules={[
                {
                  required: false, message: 'Missed District'
                },
                {
          
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="postalCode"
              label="Postal Code"
              rules={[
                {
                  required: false, message: 'Missed Postal Code'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="commission"
              label="Commission"
              rules={[
                { 
                  // required:false
                  required: isCommissionReq
                },
              ]}>
              <Input/>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="bankAccNo"
              label="Bank Account No."
              rules={[
                {
                  required: false, message: 'Missed Bank Account No.'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="bankIfsc"
              label="Bank IFSC"
              rules={[
                {
                  required: false, message: 'Missed Bank IFSC'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="bankName"
              label="Bank Name"
              rules={[
                {
                  required: false, message: 'Missed Bank Name'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="bankBranch"
              label="Bank Branch"
              rules={[
                {
                  required: false, message: 'Missed Bank Branch'
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="contactNumber"
              label="Contact Number"
              rules={[
                {
                  required: false, message: 'Missed Contact Number'
                },
                {
                  pattern: /^-?\d*(\.\d*)?$/, message: 'Only Numbers are allowed',
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>


        </Row>

        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="emailId"
              label="E-mail"
              rules={[
                {
                  required: false, message: 'Missed E-mail'
                },
                {
                  pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, message: 'Please Enter Valid Email',
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="paymentPeriod"
              label="Credit Payment Period"
              rules={[
                {
                  required: false, message: 'Missed Credit Payment Period'
                },
                {
                  pattern: /^-?\d*(\.\d*)?$/, message: 'Only Numbers are allowed',
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col style={{ display: (isBroker) ? 'block' : 'none' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="Farmers"
              label="Farmers"
              rules={[
                {
                  required: false, message: 'Missed Farmers'
                },
              ]}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                {farmersList.map((farmer) => {
                  return <Option key={farmer.supplierId} value={farmer.supplierId}>{farmer.supplierName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24} style={{ display: (isFarmer) ? 'block' : 'none' }}>

          <Form.List name="farmInfo" >
            {(fields, { add, remove }) => (
              <>
                <Card>
                  <Form.Item>
                    {/* <br /> */}
                    {/* <br/> */}
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Farm
                    </Button>

                  </Form.Item>
                  {fields.map(field => (
                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                      <Row gutter={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'farmId']}
                          fieldKey={[field.fieldKey, 'farmId']}
                        >
                          <Input hidden />
                        </Form.Item>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Farm Code"
                            name={[field.name, 'farmCode']}
                            fieldKey={[field.fieldKey, 'farmCode']}
                            rules={[{ required: true, message: 'Missing Form Code' }, {
                              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                              message: `Don't Allow Spaces`
                            }]}
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Farm Name"
                            name={[field.name, 'farmName']}
                            fieldKey={[field.fieldKey, 'farmName']}
                            rules={[{ required: true, message: 'Missing Form Name' },
                             {
                              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                              message: `Don't Allow Spaces`
                            },
                          ]}
                            
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Address"
                            name={[field.name, 'address']}
                            fieldKey={[field.fieldKey, 'address']}
                            rules={[{ required: true, message: 'Missing Address' }, {
                              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                              message: `Don't Allow Spaces`
                            }]}
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Number of Acres"
                            name={[field.name, 'NumberOfAcres']}
                            fieldKey={[field.fieldKey, 'NumberOfAcres']}
                            rules={[{ required: true, message: 'Missing Number of Acres' }, {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/, message: 'Only Numbers are allowed',
                            }]}
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Number Of Ponds"
                            name={[field.name, 'numberOfPonds']}
                            fieldKey={[field.fieldKey, 'numberOfPonds']}
                            rules={[{ required: true, message: 'Missing Number Of Ponds' }, {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/, message: 'Only Numbers are allowed',
                            }]}
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Trace Code"
                            name={[field.name, 'traceCode']}
                            fieldKey={[field.fieldKey, 'traceCode']}
                            rules={[{ required: true, message: 'Missing Trace Code' }, {
                              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                              message: `Don't Allow Spaces`
                            }]}
                          >
                            <Input />

                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Expiry Date"
                            name={[field.name, 'expiryDate']}
                            fieldKey={[field.fieldKey, 'expiryDate']}
                            rules={[
                              {
                                required: true, message: 'Missed Expiry Date'
                              },
                            ]}>
                            <DatePicker onChange={(date) => {
                              // console.log(date);
                              // console.log(moment());
                              if(date < moment() ){
                                 AlertMessages.getErrorMessage('Expiry Date must be greater than today');}
                               }} 
                             style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label="Mobile Number"
                            name={[field.name, 'mobileNumber']}
                            fieldKey={[field.fieldKey, 'mobileNumber']}
                            rules={[
                              {
                                required: false, message: 'Missed Mobile Number'
                              },
                              {
                                pattern: /^-?\d*(\.\d*)?$/, message: 'Only Numbers are allowed',
                              }
                            ]}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item 
                          {...field}
                            label="Certifications"
                            name={[field.name, 'certificationId']}
                            fieldKey={[field.fieldKey, 'certificationId']} 
                            rules={[{ required: true, message: 'Missing Certification' }]}>
                              <Select
                                placeholder="certification"
                                allowClear
                            >
                              {certificationData.map(dropData => {
                                return <Option key={dropData.certificateId} value={dropData.certificateId}>{dropData.certificateName}</Option>
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      {
                        props.isUpdate ? <></> :
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                      }
                    </Space>
                  ))}
                </Card>

              </>
            )}
          </Form.List>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
              </Button>
              {props.isUpdate?'':<Button htmlType="button" onClick={onReset}>
              Reset
            </Button>}
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default SuppliersForm;
