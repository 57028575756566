import { Button, Card, Col, Row, Tooltip, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import LotCodeDetailsTable from './lotCode-table';
import LotCodeTree from './lot-code-tree';
import form from 'antd/lib/form';



export interface LotCodeDashboardProps { }

export function LotCodeDashboard(
    props: LotCodeDashboardProps) {



    return (

        <div className='dashboard' >

            <Row gutter={24} >

                <Col xs={24} sm={24} md={12} xl={12} style={{ marginTop: '20px' }} >
                    <Card >
                        <Card title={<span style={{ color: 'black' }} >Operation Wise Lots Info</span>}
                            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#white', border: 0 }}  >
                        </Card>
                        <LotCodeDetailsTable />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} style={{ marginTop: '20px' }} >
                    <LotCodeTree />
                </Col>
            </Row>

        </div >

    );
}

export default LotCodeDashboard;
