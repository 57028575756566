import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Switch, Input, Button, Tag, Row, Col, Drawer, Tooltip} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';

import { HolidaysCalendarDto} from '@gtpl/shared-models/hrms';
import { HolidaysCalendarService,} from '@gtpl/shared-services/hrms';
import {HolidaysCalender} from '@gtpl/pages/hrms/hrms-components/holidays-calender'

import './holidays-calender-grid.css';
import moment from 'moment';

/* eslint-disable-next-line */
export interface HolidaysCalenderGridProps {}

export function HolidaysCalenderGrid(
  props: HolidaysCalenderGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [holidaysCalendarData, setHolidaysCalendarData] = useState<HolidaysCalendarDto[]>([]);
  const [selectedHoliday, setSelectedHoliday] = useState<any>(undefined);

  const holidaysCalendarService = new HolidaysCalendarService();

  useEffect(() => {
    getAllHolidays();
  },[]);

  /**
   * 
   */
  const getAllHolidays = () => {
    holidaysCalendarService.getAllHolidays().then(res => {
      if(res.status){
        setHolidaysCalendarData(res.data);
      }else {
        if(res.intlCode){
          setHolidaysCalendarData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setHolidaysCalendarData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  } 

  /**
   * 
   * @param holidaysCalendarData 
   */
   const deleteHoliday = (holidaysCalendarData:HolidaysCalendarDto) => {
    holidaysCalendarData.isActive=holidaysCalendarData.isActive?false:true;
    holidaysCalendarService.activateOrDeactivateHoliday(holidaysCalendarData).then(res => { console.log(res);
      if (res.status) {
        getAllHolidays();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
   const updateHoliday = (holidaysCalendarData: HolidaysCalendarDto) => {
    holidaysCalendarService.updateHoliday(holidaysCalendarData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllHolidays();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
   * used for column filter
   * @param dataIndex column data index
   */

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(viewData: HolidaysCalendarDto)=>{
    setDrawerVisible(true);
    console.log(viewData)
    viewData.holidayDate=moment(viewData.holidayDate, 'YYYY-MM-DD')
    //viewData.holidayYear=moment(viewData.holidayYear, 'YYYY-MM-DD')
    setSelectedHoliday(viewData);
    console.log(viewData)
    console.log('selectedHoliday')
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Holiday Name',
      dataIndex: 'holidayName',
      // responsive: ['lg'],
      sorter: (a, b) => a.holidayName.length - b.holidayName.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('holidayName')
    },
    {
      title: 'Holiday Date',
      dataIndex: 'holidayDate',
      // responsive: ['lg'],
      sorter: (a, b) => moment(a.holidayDate).unix() - moment(b.holidayDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record?.holidayDate).format('YYYY-MM-DD') },
    },

    // {
    //   title: 'Holiday Year',
    //   dataIndex: 'holidayYear',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => moment(a.holidayYear).unix() - moment(b.holidayYear).unix(),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => { return moment(record.holidayYear).format('YYYY') },
    // },
    
    
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span> 
            <Tooltip placement="top" title='Edit'>       
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Holiday');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteHoliday(rowData);}}
            title={
              rowData.isActive
              
                ? 'Are you sure to Deactivate Holiday ?'
                :  'Are you sure to Activate Holiday ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
      /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
    const OpenFormTocreateRecord = () => {
      console.log('redirect here');
      
     }
  return (
    <Card title={<span style={{color:'white'}}>Holidays Calendar</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/holidays-calendar-form' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Holidays: ' + holidaysCalendarData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + holidaysCalendarData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + holidaysCalendarData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.holidayId}
          columns={columnsSkelton}
          dataSource={holidaysCalendarData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <HolidaysCalender
                updateHoliday={updateHoliday}
                isUpdate={true}
                holidaysCalendarData={selectedHoliday}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  
  );
}

export default HolidaysCalenderGrid;
