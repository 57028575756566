import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col, UploadProps, Upload, Switch, FormInstance, Space } from 'antd';
import { MasterBrandDto, TrimsDropDownDto } from '@gtpl/shared-models/masters';
import {MasterBrandService, TrimsService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UploadOutlined } from '@ant-design/icons'
import { DownloadOutlined, LoadingOutlined, PlusOutlined ,MinusCircleOutlined} from '@ant-design/icons';
import { BrowserRouter as Router1, HashRouter as Router, Route, Link, Redirect, useHistory } from "react-router-dom";
import './master-brands-form.css';
import appSettings from 'apps/services/config';
import { TrimsInfo } from 'libs/shared-models/masters/src/lib/trims/trims.model';
import {TrimsDto} from '@gtpl/shared-models/masters';

const { Option } = Select;
/* eslint-disable-next-line */
export interface MasterBrandsFormProps {
  masterBrandData:MasterBrandDto;
  updateMasterBrand:(masterBrand:MasterBrandDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
 }

export function MasterBrandsForm(
  props: MasterBrandsFormProps
  
) {
  
  const [form] = Form.useForm();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState('');
  const [toggle,setToggle] = useState(false);
  const [pouchGrade,setPouchGrade] = useState(undefined);
  const [pouchBapNo,setPouchBapNo] = useState(undefined);
  const [pouchTypeOne,setPouchTypeOne] = useState(undefined);
  const [pouchTypeTwo,setPouchTypeTwo] = useState(undefined);
  const [pouchTypeThree,setPouchTypeThree] = useState(undefined);
  const [pouchTypeFour,setPouchTypeFour] = useState(undefined);
  const [pouchTypeFive,setPouchTypeFive] = useState(undefined);
  const [pouchFoodType,setPouchFoodType] = useState(undefined);
  const [pouchProduct,setPouchProduct] = useState(undefined);
  const [cartonGrade,setCartonGrade] = useState(undefined);
  const [cartonBapNo,setCartonBapNo] = useState(undefined);
  const [cartonTypeOne,setCartonTypeOne] = useState(undefined);
  const [cartonTypeTwo,setCartonTypeTwo] = useState(undefined);
  const [cartonTypeThree,setCartonTypeThree] = useState(undefined);
  const [cartonTypeFour,setCartonTypeFour] = useState(undefined);
  const [cartonTypeFive,setCartonTypeFive] = useState(undefined);
  const [cartonFoodType,setCartonFoodType] = useState(undefined);
  const [cartonProduct,setCartonProduct] = useState(undefined);
  const [disable, setDisable] = useState<boolean>(false)
  const [trimsDropDown,setTrimsDropDown] = useState<TrimsDropDownDto[]>([]);
  const [trimsInfo, setTrimsInfo] = useState<TrimsInfo[]>([]);
  const [trimsData, setTrimsData] = useState<TrimsDto[]>([]);
  const trimsService = new TrimsService();



  const [filelist, setfilelist] = useState<any>(props.isUpdate?[{
    name: props.masterBrandData.fileName,
    status: 'done',
    url: appSettings.brand_file_path+props.masterBrandData.filePath,
  }]:[]);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload Image</div>
    </div>
  );
  let history = useHistory();

   
  let createdUser="";

  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  const getAllTrimsDropDown = ()=>{
    trimsService.getAllTrimsDropDown().then(res => {
            if (res.status) {
              setTrimsDropDown(res.data);
              console.log(res.data)
            } else {
              if (res.intlCode) {
                   AlertMessages.getErrorMessage(res.internalMessage);
              } else {
               AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
             AlertMessages.getErrorMessage(err.message);
          })
  }
  
  

  useEffect(() => {
  
    if(props.isUpdate == true){
      if(props?.masterBrandData?.isPouchGradeConsider == 'true'){
      setPouchGrade(true)
      }
      if(props?.masterBrandData?.isPouchBapNoConsider == 'true'){
      setPouchBapNo(true)
      }
      if(props?.masterBrandData?.isPouchValAddOneConsider == 'true'){
      setPouchTypeOne(true)
      }
      if(props?.masterBrandData?.isPouchValAddTwoConsider == 'true'){
      setPouchTypeTwo(true)
      }
      if(props?.masterBrandData?.isPouchValAddThreeConsider == 'true'){
      setPouchTypeThree(true)
      }
      if(props?.masterBrandData?.isPouchValAddFourConsider == 'true'){
      setPouchTypeFour(true)
      }
      if(props?.masterBrandData?.isPouchValAddFiveConsider == 'true'){
      setPouchTypeFive(true)
      }
      if(props?.masterBrandData?.isPouchFoodTypeConsider == 'true'){
      setPouchFoodType(true)
      }
      if(props?.masterBrandData?.isPouchProductConsider == 'true'){
      setPouchProduct(true)
      }
      if(props?.masterBrandData?.isCartonGradeConsider == 'true'){
        setCartonGrade(true)
        }
        if(props?.masterBrandData?.isCartonBapNoConsider == 'true'){
          setCartonBapNo(true)
          }
        if(props?.masterBrandData?.isCartonValAddOneConsider == 'true'){
          setCartonTypeOne(true)
        }
        if(props?.masterBrandData?.isCartonValAddTwoConsider == 'true'){
        setCartonTypeTwo(true)
        }
        if(props?.masterBrandData?.isCartonValAddThreeConsider == 'true'){
        setCartonTypeThree(true)
        }
        if(props?.masterBrandData?.isCartonValAddFourConsider == 'true'){
        setCartonTypeFour(true)
        }
        if(props?.masterBrandData?.isCartonValAddFiveConsider == 'true'){
        setCartonTypeFive(true)
        }
        if(props?.masterBrandData?.isCartonFoodTypeConsider == 'true'){
        setCartonFoodType(true)
        }
        if(props?.masterBrandData?.isCartonProductConsider == 'true'){
        setCartonProduct(true)
        }

       
  }
  // getAllTrimsDropDown();
 
 },[props.updateMasterBrand])
      
  const masterBrandService=new MasterBrandService()

  const createMasterBrand=(masterBrandData:MasterBrandDto)=>{
    setDisable(true)
    masterBrandService.createMasterBrand(masterBrandData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Master Brand Created Successfully');
        history.push("/master-brands-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const setTrims = (values, trimsData) => {
    const trims = new TrimsInfo(values, trimsData.children);
    trimsInfo.push(trims);
    setTrimsInfo(trimsInfo);
  }




  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadFieldProps: UploadProps = {
    // alert();
    multiple: false,
    onRemove: file => {
      setfilelist([]);
      setImageUrl('');
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(png|jpeg|PNG|jpg|JPG)$/)) {
        AlertMessages.getErrorMessage("Only png,jpeg,jpg files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
          return true;
        } else {
          setfilelist([file]);
          getBase64(file, imageUrl =>
            setImageUrl(imageUrl)
          );
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist,
  };

  const onReset=()=>{
    form.resetFields();
    
  }

  const saveData = (values: MasterBrandDto) => {
    if(props.isUpdate){
      console.log(values)
      props.updateMasterBrand(values);
    }else{

      console.log(values)
      createMasterBrand(values);
    }
  
  };
  const uploadBrandDetails = () => {
    form.validateFields().then(formDetails=>{
        console.log(formDetails);
        if (filelist.length > 0) {
          const formData = new FormData();
          filelist.forEach((file: any) => {
            formData.append('file', file);
          });

          formData.append('masterBrandId',(formDetails.masterBrandId)?formDetails.masterBrandId:"")
          formData.append('masterBrandName',formDetails.masterBrandName)
          formData.append('isPouchVariantConsider',formDetails.isPouchVariantConsider)
          formData.append('isPouchBapNoConsider',formDetails.isPouchBapNoConsider)
          formData.append('isPouchGradeConsider',formDetails.isPouchGradeConsider)
          formData.append('isPouchValAddOneConsider',formDetails.isPouchValAddOneConsider)
          formData.append('isPouchValAddTwoConsider',formDetails.isPouchValAddTwoConsider)
          formData.append('isPouchValAddThreeConsider',formDetails.isPouchValAddThreeConsider)
          formData.append('isPouchValAddFourConsider',formDetails.isPouchValAddFourConsider)
          formData.append('isPouchValAddFiveConsider',formDetails.isPouchValAddFiveConsider)
          formData.append('isPouchFoodTypeConsider',formDetails.isPouchFoodTypeConsider)
          formData.append('isPouchProductConsider',formDetails.isPouchProductConsider)
          formData.append('isCartonGradeConsider',formDetails.isCartonGradeConsider)
          formData.append('isCartonVariantConsider',formDetails.isCartonVariantConsider)
          formData.append('isCartonBapNoConsider',formDetails.isCartonBapNoConsider)
          formData.append('isCartonValAddOneConsider',formDetails.isCartonValAddOneConsider)
          formData.append('isCartonValAddTwoConsider',formDetails.isCartonValAddTwoConsider)
          formData.append('isCartonValAddThreeConsider',formDetails.isCartonValAddThreeConsider)
          formData.append('isCartonValAddFourConsider',formDetails.isCartonValAddFourConsider)
          formData.append('isCartonValAddFiveConsider',formDetails.isCartonValAddFiveConsider)
          formData.append('isCartonFoodTypeConsider',formDetails.isCartonFoodTypeConsider)
          formData.append('isCartonProductConsider',formDetails.isCartonProductConsider)
          formData.append('createdUser',localStorage.getItem("createdUser"))
          formData.append('updatedUser',localStorage.getItem("createdUser"))
          formData.append('displayBrandName',formDetails.displayBrandName)
          formData.append('trimsInfo',formDetails.trimsInfo)
          masterBrandService.Brandupload(formData).then(res => {
            if(res.status){
              form.resetFields();
              setfilelist([]);

              setDisable(false)
              if(!props.isUpdate){
                AlertMessages.getSuccessMessage('Created Successfully');
                history.push('/master-brands-grid');
              }else{
                setDisable(false)
                props.closeForm();
                AlertMessages.getSuccessMessage('Updated Successfully');
                window.location.reload();
                // history.push('/master-brands-grid');
                // setDrawerVisible(false);
              }
            }
          })
        }

    })
  }



  return (
    <Card size="small" title={<span style={{color:'white'}}>Brands</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/master-brands-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.masterBrandData} name="control-hooks" onFinish={uploadBrandDetails}>
      <Form.Item name="masterBrandId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row gutter={24}>
        
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="masterBrandName"
                label="Brand Name"
                rules={[
                  {
                    required:true,
                    message:'Brand Name Required'
                  }
                ]}
               >
                <Input/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
            <Form.Item name="displayBrandName"
                label="Display Brand Name"
                rules={[
                  {
                    required:true,
                    message:'Display Brand Name Required'
                  }
                ]}
               >
                <Input/> 
            </Form.Item>
        </Col>
              <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
                <Form.Item
                  
                  label="Trim"
                  name='trimsId'
                 
                  rules={[{ required: false, message: 'Missing Trims' }, { pattern: /^(?!\s*$).+/, message: `Enter Trims` }]}
                >
                  <Select
                    placeholder="Select Trim"
                    allowClear
                     onChange={setTrims}
                     mode= 'multiple'
                  >
                    
               <Option key={0} value={null}>Select Trim</Option>
                                {trimsDropDown.map(trimdropData => {
                                  return <Option label={'trimsId'} key={trimdropData.trimsId} value={trimdropData.trimsId}>{trimdropData.trimName}</Option>
                                })}
                    </Select>
                </Form.Item>
              </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
              <Form.Item name="file" rules={[{ required: !props.isUpdate, message: 'Please Upload File' },]}>
              <Upload {...uploadFieldProps} style={{  width:'100%' }} listType="picture-card">
                {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Form.Item>
            </Col>

           
      </Row>

    <>
       
</>


     

      
           


      <Card size="small" title={<span style={{color:'white'}}>Pouches</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
      <Row gutter={24}>
        
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchGradeConsider"
                label="Grade"
                
               >
                
                <Switch checked={pouchGrade} onChange={() =>  {props.isUpdate? setPouchGrade(!pouchGrade) : null }} />
              </Form.Item>
        </Col>

        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}  hidden >
            <Form.Item
                name="isPouchVariantConsider"
                label="Variant"
               >
                <Switch  />
              
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchBapNoConsider"
                label="BAP No"
               
               >
                
              <Switch checked={pouchBapNo} onChange={() =>  {props.isUpdate? setPouchBapNo(!pouchBapNo) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchValAddOneConsider"
                label="Value Addition One"
               >
                <Switch checked={pouchTypeOne} onChange={() =>  {props.isUpdate? setPouchTypeOne(!pouchTypeOne) : null }} />
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchValAddTwoConsider"
                label="Value Addition Two"
               >
                <Switch  checked={pouchTypeTwo} onChange={() =>  {props.isUpdate? setPouchTypeTwo(!pouchTypeTwo) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchValAddThreeConsider"
                label="Value Addition Three"
               >
                <Switch checked={pouchTypeThree} onChange={() =>  {props.isUpdate? setPouchTypeThree(!pouchTypeThree) : null }} />
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchValAddFourConsider"
                label="Value Addition Four"
               >
                <Switch checked={pouchTypeFour} onChange={() =>  {props.isUpdate? setPouchTypeFour(!pouchTypeFour) : null }} />
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchValAddFiveConsider"
                label="Value Addition Five"
               >
                <Switch checked={pouchTypeFive} onChange={() =>  {props.isUpdate? setPouchTypeFive(!pouchTypeFive) : null }}/>
              </Form.Item>
        </Col>
       
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchFoodTypeConsider"
                label="Food Type"
               >
                <Switch checked={pouchFoodType} onChange={() =>  {props.isUpdate? setPouchFoodType(!pouchFoodType) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isPouchProductConsider"
                label="Product"
               >
                <Switch checked={pouchProduct} onChange={() =>  {props.isUpdate? setPouchProduct(!pouchProduct) : null }}/>
              </Form.Item>
        </Col>
        </Row>

    </Card>

    <Card size="small" title={<span style={{color:'white'}}>Carton</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}
    >
      <Row gutter={48}>
        
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonGradeConsider"
                label="Grade"
               >
                <Switch checked={cartonGrade} onChange={() =>  {props.isUpdate? setCartonGrade(!cartonGrade) : null }}/>
              </Form.Item>
        </Col>

        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} hidden>
            <Form.Item
                name="isCartonVariantConsider"
                label="Variant"
               >
                 <Switch  />
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonBapNoConsider"
                label="BAP No"
               >
                 <Switch checked={cartonBapNo} onChange={() =>  {props.isUpdate? setCartonBapNo(!cartonBapNo) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonValAddOneConsider"
                label="Value Addition One"
               >
                <Switch checked={cartonTypeOne} onChange={() =>  {props.isUpdate? setCartonTypeOne(!cartonTypeOne) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonValAddTwoConsider"
                label="Value Addition Two"
               >
                <Switch checked={cartonTypeTwo} onChange={() =>  {props.isUpdate? setCartonTypeTwo(!cartonTypeTwo) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonValAddThreeConsider"
                label="Value Addition Three"
               >
                <Switch checked={cartonTypeThree} onChange={() =>  {props.isUpdate? setCartonTypeThree(!cartonTypeThree) : null }} />
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonValAddFourConsider"
                label="Value Addition Four"
               >
                <Switch checked={cartonTypeFour} onChange={() =>  {props.isUpdate? setCartonTypeFour(!cartonTypeFour) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonValAddFiveConsider"
                label="Value Addition Five"
               >
                <Switch checked={cartonTypeFive} onChange={() =>  {props.isUpdate? setCartonTypeFive(!cartonTypeFive) : null }}/>
              </Form.Item>
        </Col>
       
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonFoodTypeConsider"
                label="Food Type"
               >
                <Switch checked={cartonFoodType} onChange={() =>  {props.isUpdate? setCartonFoodType(!cartonFoodType) : null }}/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
            <Form.Item
                name="isCartonProductConsider"
                label="Product"
               >
                <Switch checked={cartonProduct} onChange={() =>  {props.isUpdate? setCartonProduct(!cartonProduct) : null }}/>
              </Form.Item>
        </Col>
        </Row>

    </Card>

     
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      
            
        </Col>
      </Form>
    </Card>
  );
}

export default MasterBrandsForm;




