import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import { HighColumn } from "@gtpl/shared-models/common-models";


export interface Highcolomnprops {
  columnData: HighColumn,

}



const Highcolumn = (props: Highcolomnprops) => {
  const { columnData } = props
  const title = columnData.title
  const category = columnData.categories
  const series = columnData.seriesDetails.map((seriesObj) => {
    return {
      name: seriesObj.seriesDetails.map(ser => ser.title)[0],
      data: seriesObj.seriesDetails.map(ser => Number(ser.value))
    }
  });


  const config = {
    colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
      type: 'column',
      backgroundColor: '#272953'
    },
    title: {
      text: title,
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.75rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      }
    },
    xAxis: {
      categories: category,
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }

    },
    yAxis: {
      min: 0,
      title: {
        text: columnData.text
      },
      style: {
        color: 'var(--text-color,#eee)',
        fontSize: '1.15rem',
        lineHeight: '1.4',
        marginBottom: '0',
        overflow: 'hidden',
        // paddingTop: '2px',
        paddingTop: 'calc(2px*var(--scale-factor, 1))',
        position: 'relative',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
        zIndex: '5',
        fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
      },
      labels: {
        style: {
          color: 'var(--text-color,#eee)'
        }
      }
    },
    dataLabels: {
      enabled: true,
    },

    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: series
  }


  return (

    <HighchartsReact highcharts={Highcharts} options={config} />


  )
}

export default Highcolumn;
