import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, DatePicker, Space, Tabs, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, SearchOutlined, EyeOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { BomTrackerPackingItemsDto } from '@gtpl/shared-models/sale-management';
import { Table } from "ant-table-extensions";
import { Redirect } from 'react-router-dom';
import './sale-orders-grid.css';
import { FoodTypesDto, PlantsDropDown, ProductDto } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import moment from 'moment';
import { EmployeeRolesEnum, PmStatusEnum, SaleOrderStatusEnum, SoStatusEnum, StatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import BomItemsGrid from './bom-items-grid';


/* eslint-disable-next-line */
export interface SaleOrdersGridProps { }

export function SaleOrdersGrid(props: SaleOrdersGridProps) {
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [saleId, setSaleId] = useState(0);
  const [unitId, setUnitId] = useState(0);
  const [poForm, setPoForm] = useState(0);
  const [saleOrderData, setSaleOrderData] = useState<AllSaleOrdersDto[]>([]);
  const [newSaleOrderData, setNewSaleOrderData] = useState<AllSaleOrdersDto[]>([])
  const [closedSaleOrderData, setClosedSaleOrderData] = useState<AllSaleOrdersDto[]>([]);
  const [bomSoData, setBomSoData] = useState<BomTrackerPackingItemsDto[]>([]);
  const [renderToPoForm, setRenderToPoForm] = useState<boolean>(false);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService()
  const [filtersData, setFiltersData] = useState<AllSaleOrdersDto[]>([]);
  const [pmStatus, setPmStatus] = useState<AllSaleOrdersDto[]>([]);
  const [transfferedSos, setTransferredSos] = useState<any[]>([]);
  const [newClosedSoData, setNewClosedSoData] = useState<any[]>([])
  const [selectedunit, setUnit] = useState<number>(0)

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new SaleOrderService();
  const [form] = Form.useForm();
  const [closedForm] = Form.useForm();
  const { Option } = Select;
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    getAllBomTrackerInfo();
    getAllPlants();
    getAllClosedSaleOrdersDetails();
    getTransfferdSos();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })
      closedForm.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })

    }
  },
    [])
  const closedSOData = saleOrderData.concat(closedSaleOrderData)

  const getTransfferdSos = () => {
    service.getTransferredSaleOrders().then((res) => {
      if (res.status) {
        setTransferredSos(res.data)
      } else {
        setTransferredSos([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const getAllBomTrackerInfo = () => {

    // console.log(localStorage.getItem('role'));
    // console.log(EmployeeRolesEnum[localStorage.getItem('role')]);
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getAllBomTrackerInfo(req).then(res => {
      if (res.status) {
        setSaleOrderData(res.data);
        setFiltersData(res.data);
      } else {
        if (res.intlCode) {
          setSaleOrderData([]);
          setFiltersData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
      setFiltersData([]);
    })

  }
console.log(saleOrderData)
  const getAllClosedSaleOrdersDetails = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getAllClosedSaleOrdersDetails(req).then(res => {
      if (res.status) {
        setClosedSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          setClosedSaleOrderData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setClosedSaleOrderData([]);
    })

  }

  const getAllPlants = () => {

    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);

      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const handleUnit = (value) => {
    setUnit(value)
  }

  const searchData = () => {

    console.log(form.getFieldValue('plantData'));
    const plantData = form.getFieldValue('plantData');
    const pmStatus = form.getFieldValue('pmStatus');

    console.log(form.getFieldValue('pmStatus'));
    let selectedData = filtersData;
    if (pmStatus) {
      if (pmStatus == PmStatusEnum.IN_PROGRESS) {
        selectedData = selectedData.filter(el => ((Number(el.totalBomQty) <= Number(el.totalPoQty)) && el.pmStatus === PmStatusEnum.IN_PROGRESS))
      }
      if (pmStatus == PmStatusEnum.PARTIAL_PO) {
        selectedData = selectedData.filter(el => ((Number(el.totalBomQty) > Number(el.totalPoQty)) && el.pmStatus == PmStatusEnum.IN_PROGRESS))
      } else {
        selectedData = selectedData.filter(item => item.pmStatus === pmStatus)
      }
    }
    if (form.getFieldValue('etdDateRange')) {

      const selectedEtdFromDate = moment(form.getFieldValue('etdDateRange')[0]).format("YYYY-MM-DD");
      const selectedEtdToDate = moment(form.getFieldValue('etdDateRange')[1]).format("YYYY-MM_DD");
      if (selectedEtdFromDate && selectedEtdToDate) {
        selectedData = selectedData.filter(item => moment(item.etd).format("YYYY-MM-DD") >= selectedEtdFromDate && moment(item.etd).format("YYYY-MM-DD") <= selectedEtdToDate)
      }

    }

    if (plantData) {
      selectedData = selectedData.filter(item => item.unitId === plantData)
    }
    setSaleOrderData(selectedData);
  }


  const onReset = () => {
    form.resetFields();
    setSearchText('');
    setSaleOrderData(filtersData);
  };


  const setUpdatevalue = (rowdata) => {
    console.log(rowdata.saleOrderId)
    setUnitId(rowdata.unitId);
    setPoForm(rowdata.saleOrderId);
    setObject({ saleOrderId: rowdata.saleOrderId, unitId: rowdata.unitId });

  }


  const expandedRowRender = (record: AllSaleOrdersDto, index, indent, expanded) => {
    return <BomItemsGrid saleOrderId={record.saleOrderId} />
  }



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: 70,
      fixed: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: ' Customer PO',
      dataIndex: 'poNo',
      width: 250,
      fixed: 'left',
      align: 'left',
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo'),
      render: (text, record) => record.isTransferred ?
        <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.poNo} <span style={{ color: 'black' }}>(Transferred) </span></Link> :
        <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.poNo}</Link>
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrder',
    //   fixed: 'left',
    //   width: 230,
    //   align: 'left',
    //   ...getColumnSearchProps('saleOrder'),
    //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</a>,
    //   sorter: (a, b) => a.saleOrderId - b.saleOrderId,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</Link>,
    // },
    {
      title: ' Prod Unit',
      dataIndex: 'unitCode',
      width: 60,
      align: 'left',
      // sorter: (a, b) => a.unitCode.localeCompare(b.unitCode),
      // sortDirections: ['descend', 'ascend'],
      // // ...getColumnSearchProps('unitCode'),
      render: (text, record) => <Tooltip title={record.unit}>{record.unitCode}</Tooltip>,
      // filters: [
      //   {
      //     text: PlantsDropDown.plantName ,
      //     value:PlantsDropDown.plantName,
      //   },
      //   {
      //     text: ,
      //     value: ,
      //   },
      // ],
      // filterMultiple: true,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.unitCode == value;
      // },
    },
    {
      title: 'End Customer Name',
      dataIndex: 'endCustomer',
      width: 160,
      align: 'left',
      sorter: (a, b) => a.endCustomer.localeCompare(b.endCustomer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomer')
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: 120,
      align: 'left',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      render: (value, record) => {
        return record.etd ? record.etd : '-'
      }

    },
    {
      title: 'Brand Design Type',
      dataIndex: 'brandDesignType',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.brandDesignType?.localeCompare(b.brandDesignType),
      sortDirections: ['descend', 'ascend'],
      // sortDirections: ['descend', 'ascend'],
      // sorter: (a, b) => a.brandDesignType.localeCompare(b.brandDesignType),
      // ...getColumnSearchProps('brandDesignType')
      filters: [
        {
          text: 'Old',
          value: 'Old',
        },
        {
          text: 'New',
          value: 'New',
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.brandDesignType === value;
      },

    },
    {
      title: 'Is Brand Approved?',
      dataIndex: 'isBrandApproved',
      width: 100,
      align: 'left',
      // sortDirections: ['descend', 'ascend'],
      // sorter: (a, b) =>  a.isBrandApproved.localeCompare(b.isBrandApproved),
      // ...getColumnSearchProps('isBrandApproved')
      filters: [
        {
          text: 'YES',
          value: 'YES',
        },
        {
          text: 'NO',
          value: 'NO',
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isBrandApproved === value;
      },
    },

    // {
    //   title: 'Additional Note',
    //   dataIndex: 'additionalNote',
    //   width: 120,
    //   align: 'left',
    //   sortDirections: ['descend', 'ascend'],
    //   sorter: (a, b) => moment(a.additionalNote).unix() - moment(b.additionalNote).unix(),
    //   ...getColumnSearchProps('additionalNote')
    // },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   width: 120,
    //   align: 'left',

    //   sortDirections: ['descend', 'ascend'],
    //   sorter: (a, b) => a.category.localeCompare(b.category),
    //   // ...getColumnSearchProps('category')


    // },

    {

      title: 'Aging',
      key: 'etd',
      width: 80,
      align: 'left',
      fixed: 'right',
      sorter: (a, b) => (Math.floor((new Date(moment(a.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {

        const obj: any = {
          children: (record.status != "CLOSED" ? <div style={{ textAlign: 'left' }}>{Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div> : <div>{"-"}</div>),
          props: {
            style: {
              background: record.status != "CLOSED" ? Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c' : "-",
              color: Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "Black" : 'white'
            }
          },

        };
        return record.etd ? obj : '-'



      }

    },
    {
      title: 'Packing Status',
      dataIndex: 'pmStatus',
      width: 110,
      align: 'left',

      // responsive: ['lg'],
      render: (status, rowData) => (
        <>
          {status == PmStatusEnum.OPEN ? <a><Tag onClick={() => renderToPo(rowData)} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{PmStatusEnum.OPEN}</Tag> </a> : status == PmStatusEnum.IN_PROGRESS ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == PmStatusEnum.CLOSE ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}
        </>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      // filters: [
      //   {
      //     text: PmStatusEnum.OPEN,
      //     value: PmStatusEnum.OPEN,
      //   },

      //   {
      //     text: PmStatusEnum.IN_PROGRESS,
      //     value: 'INPROGRESS',
      //   },
      //   {
      //     text: PmStatusEnum.CLOSE,
      //     value: PmStatusEnum.CLOSE,
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.status === value;
      // },
      // ...getColumnSearchProps('status')
    },

    {
      title: `Action`,
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
      render: (text, rowData) => (
        <span>
          {(object) ? <Redirect
            to={{
              pathname: "/saleorder-bom",
              state: object
            }}
          /> : null}

          {rowData.status != PmStatusEnum.CLOSE ?
            <Tooltip placement="top" title='Assign Stock'>
              <EditOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  setUpdatevalue(rowData);
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              /></Tooltip> : null

          }
          {/* {rowData.status != PmStatusEnum.CLOSE ?
            <EyeOutlined type="view"
              onClick={() => {

                renderToPo(rowData.status);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> : null
          } */}
          <Divider type="vertical" />

          {rowData.status == PmStatusEnum.OPEN ? <a><Tooltip placement="top" title='Raise PO'><ArrowRightOutlined style={{ color: 'red' }} onClick={() => renderToPo(rowData)} /></Tooltip> </a> : ""}

        </span>
      )
    }

  ];

  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: 65,
      fixed: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: ' Customer PO',
      dataIndex: 'poNo',
      width: 250,
      fixed: 'left',
      align: 'left',
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo'),
      render: (text, record) => <>
        {
          record.isTransferred ?
            <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.poNo} <span style={{ color: 'black' }}>(Transferred) </span></Link> :
            <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.poNo}</Link>
        }
      </>
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrder',
    //   fixed: 'left',
    //   width: 230,
    //   align: 'left',
    //   ...getColumnSearchProps('saleOrder'),
    //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</a>,
    //   sorter: (a, b) => a.saleOrderId - b.saleOrderId,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</Link>,
    // },
    {
      title: 'Prod Unit',
      dataIndex: 'unitCode',
      width: 70,
      align: 'left',
      sorter: (a, b) => a.unitCode.localeCompare(b.unitCode),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('unitCode'),
      render: (text, record) => <Tooltip title={record.unit}>{record.unitCode}</Tooltip>,
      // filters: [
      //   {
      //     text: PlantsDropDown.plantName ,
      //     value:PlantsDropDown.plantName,
      //   },
      //   {
      //     text: ,
      //     value: ,
      //   },
      // ],
      // filterMultiple: true,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.unitCode == value;
      // },
    },
    {
      title: 'End Customer Name',
      dataIndex: 'endCustomer',
      width: 160,
      align: 'left',
      sorter: (a, b) => a.endCustomer.localeCompare(b.endCustomer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomer')
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: 120,
      align: 'left',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      render: (value, record) => {
        return record.etd ? record.etd : '-'
      }

    },
    {
      title: 'Brand Design Type',
      dataIndex: 'brandDesignType',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.brandDesignType.localeCompare(b.brandDesignType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('brandDesignType')
      filters: [
        {
          text: 'Old',
          value: 'Old',
        },
        {
          text: 'New',
          value: 'New',
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.brandDesignType === value;

      },
    },
    {
      title: 'Brand Approved?',
      dataIndex: 'isBrandApproved',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.isBrandApproved.localeCompare(b.isBrandApproved),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('isBrandApproved')
      filters: [
        {
          text: 'YES',
          value: 'YES',
        },
        {
          text: 'NO',
          value: 'NO',
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isBrandApproved === value;
      },
    },
    // {
    //   title: 'Additional Note',
    //   dataIndex: 'additionalNote',
    //   width: 120,
    //   align: 'left',
    //   sortDirections: ['descend', 'ascend'],
    //   sorter: (a, b) => moment(a.additionalNote).unix() - moment(b.additionalNote).unix(),
    //   ...getColumnSearchProps('additionalNote')
    // },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   width: 120,
    //   align: 'left',

    //   sortDirections: ['descend', 'ascend'],
    //   sorter: (a, b) => moment(a.category).unix() - moment(b.category).unix(),
    //   // ...getColumnSearchProps('category')


    // },

    // {

    //   title: 'Aging',
    //   key: 'etd',
    //   width: 80,
    //   align: 'left',
    //   fixed: 'right',
    //   sorter: (a, b) => (Math.floor((new Date(moment(a.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('aging'),
    //   render(text, record) {

    //     const obj: any = {
    //       children: (record.status != "CLOSED" ? <div style={{ textAlign: 'left' }}>{Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div> : <div>{"-"}</div>),
    //       props: {
    //         style: {
    //           background: record.status != "CLOSED" ? Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c' : "-",
    //           color: Math.floor((new Date(moment(record.etd).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "Black" : 'white'
    //         }
    //       },

    //     };
    //     return record.etd ? obj : '-'

    // }

    // },



    {
      title: 'Status',
      dataIndex: 'pmStatus',
      width: 110,
      align: 'left',

      // responsive: ['lg'],
      render: (status, rowData) => (
        <>
          {status == PmStatusEnum.OPEN ? <a><Tag onClick={() => renderToPo(rowData)} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{PmStatusEnum.OPEN}</Tag> </a> : status == PmStatusEnum.IN_PROGRESS ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == PmStatusEnum.CLOSE ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}
        </>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      // filters: [
      //   {
      //     text: PmStatusEnum.OPEN,
      //     value: PmStatusEnum.OPEN,
      //   },

      //   {
      //     text: PmStatusEnum.IN_PROGRESS,
      //     value: 'INPROGRESS',
      //   },
      //   {
      //     text: PmStatusEnum.CLOSE,
      //     value: PmStatusEnum.CLOSE,
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.status === value;
      // },
      // ...getColumnSearchProps('status')
    },

    {
      title: `Action`,
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
      render: (text, rowData) => (
        <span>
          {(object) ? <Redirect
            to={{
              pathname: "/saleorder-bom",
              state: object
            }}
          /> : null}

          {rowData.soStatus != SaleOrderStatusEnum.CLOSED ?
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                setUpdatevalue(rowData);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> : null

          }
          {/* {rowData.soStatus != SaleOrderStatusEnum.CLOSED ?
            <EyeOutlined type="view"
              onClick={() => {

                renderToPo(rowData.status);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> : null
          } */}

          {/* {rowData.status == PmStatusEnum.OPEN ? <a><ArrowRightOutlined style={{ color: 'red' }} onClick={() => renderToPo(rowData)} /> </a> : ""} */}

        </span>
      )
    }

  ];


  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
  }
  const renderToPo = (record) => {
    setRenderToPoForm(true);
    setUnitId(record.unitId);
    setPoForm(record.saleOrderId);
  }

  const handlePlant = (value) => {
    console.log(value)
    setPlantData(value)
  }

  const setTransferData = () => {
    setNewSaleOrderData(saleOrderData?.map(element => {
      transfferedSos.find(rec => {
        rec.poNumber == element.poNo;
        if (rec.poNumber == element.poNo) {
          element.isTransferred = true
        }
      });
      return element;
    }))
  }

  const setTransferDataForClosedData = () => {
    setNewClosedSoData(closedSOData?.map(element => {
      transfferedSos.find(rec => {
        rec.poNumber == element.poNo;
        if (rec.poNumber == element.poNo) {
          element.isTransferred = true
        }
      });
      return element;
    }))
  }

  console.log(newSaleOrderData.filter(rec => rec.isTransferred == true), 'filtered value');

  useEffect(() => {
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })
    }
    setTransferData();
    setTransferDataForClosedData()

  }, [saleOrderData, transfferedSos])
  return (
    <>
      {(saleId) ? <Redirect to={`/saleorder-detail-view/${saleId}`} /> : null}
      {(poForm) ? <Redirect to={{ pathname: "/purchase-order-form", state: { id: poForm, type: 'BOM', unitId: unitId } }} /> : null}

      <Card title={<span style={{ color: 'white' }}>BOM Tracker</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
        <Tabs type='card'>
          <TabPane
            key="1"
            tab={<span style={{ color: "'#69c0ff'" }}>{"Orders: " + (saleOrderData.filter(item => item.soStatus != SoStatusEnum.CANCELLED).length)}</span>}
          >
            <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
              <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name="plantData"
                    label=" Unit"
                    rules={[
                      {
                        required: false, message: 'Select Unit',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Select Unit"
                      // onChange={handlePlant}
                      allowClear
                      style={{ width: '100%' }}
                      onChange={handleUnit}
                      disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                    >
                      {plantData.map(dropData => {
                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                      })}
                    </Select>

                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name="pmStatus"
                    label="Status"
                    rules={[
                      {
                        required: false,
                        message: 'Select Order Status'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Order Status"
                    >
                      {
                        Object.entries(PmStatusEnum).map((value) => {
                          if ((value[1] != PmStatusEnum.CLOSE)) {
                            return <Option value={value[1]} >{value[1].replace('_', ' ')} </Option>
                          }
                        })
                      }

                      {/* <Option key={0} value={null}>Select Order Status</Option>
                                <Option key={2} value={'OPEN'}>OPEN</Option>
                                <Option key={3} value={'PARTIAL OPEN'}>PARTIAL OPEN</Option>
                                <Option key={4} value={'IN PROGRESS'}>IN PROGRESS</Option>
                                <Option key={5} value={'CLOSED SALE ORDERS'}>CLOSED SALE ORDERS</Option>
                                 */}
                    </Select>

                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="etdDateRange"
                    label="EDD Date"
                    rules={[
                      {
                        required: false,
                        message: " EDD Date"
                      },
                    ]}>
                    <RangePicker />
                  </Form.Item>
                </Col>
                <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    // size="small"
                    block onClick={() => searchData()}
                    style={{ marginRight: 2, width: 100 }}
                  >
                    Search
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              key={'orders'}
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton}
              expandedRowRender={expandedRowRender}
              dataSource={newSaleOrderData.filter(item => item.soStatus != SoStatusEnum.CANCELLED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 700 }}
              size="large"
              bordered />
          </TabPane>

          <TabPane
            key="2"
            tab={<span style={{ color: "green" }}>{"Closed Orders: " + (closedSaleOrderData.filter(el => el.soStatus != SoStatusEnum.CANCELLED).length)}</span>}
          >
            {/* <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
              <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name="plantData"
                    label=" Unit"
                    rules={[
                      {
                        required: false, message: 'Select Unit',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Select Unit"
                      // onChange={handlePlant}
                      allowClear
                      style={{ width: '100%' }}
                      onChange={handleUnit}
                      disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

                    >
                      {plantData.map(dropData => {
                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                      })}
                    </Select>

                  </Form.Item>
                </Col> */}
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name="pmStatus"
                    label="Status"
                    rules={[
                      {
                        required: false,
                        message: 'Select Order Status'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Order Status"
                    >
                      {
                        Object.entries(PmStatusEnum).map((value) => {
                          return <Option value={value[1]} >{value[1].replace('_', ' ')} </Option>
                        })
                      }

                      <Option key={0} value={null}>Select Order Status</Option>
                                <Option key={2} value={'OPEN'}>OPEN</Option>
                                <Option key={3} value={'PARTIAL OPEN'}>PARTIAL OPEN</Option>
                                <Option key={4} value={'IN PROGRESS'}>IN PROGRESS</Option>
                                <Option key={5} value={'CLOSED SALE ORDERS'}>CLOSED SALE ORDERS</Option>
                                
                    </Select>

                  </Form.Item>
                </Col> */}
                {/* <Col>
                  <Form.Item name="etdDateRange"
                    label="EDD Date"
                    rules={[
                      {
                        required: false,
                        message: " EDD Date"
                      },
                    ]}>
                    <RangePicker />
                  </Form.Item>
                </Col> */}
                {/* <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    // size="small"
                    block onClick={() => searchData()}
                    style={{ marginRight: 2, width: 100 }}
                  >
                    Search
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form> */}
            <Table
              key={'sos'}
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton1}
              expandedRowRender={expandedRowRender}
              dataSource={closedSaleOrderData.filter(el => el.soStatus != SoStatusEnum.CANCELLED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 700 }}
              size="large"
              bordered />
          </TabPane>
          <TabPane
            key="3"
            tab={<span style={{ color: "red" }}>{"Cancelled" + ": " + (closedSOData.filter(el => el.soStatus == SoStatusEnum.CANCELLED).length)}</span>}
          >
            <Table
              key={'Cancelled orders'}
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton}
              expandedRowRender={expandedRowRender}
              dataSource={closedSOData.filter(el => el.soStatus == SoStatusEnum.CANCELLED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 700 }}
              size="large"
              bordered />
          </TabPane>
        </Tabs>
        {/* </TabPane> */}
        {/* <TabPane
            key="2"
            tab={<span style={{ color: "blue" }}>{'PARTIAL PO: ' + (saleOrderData.filter(el => ((Number(el.totalBomQty) > Number(el.totalPoQty)) && el.pmStatus == PmStatusEnum.IN_PROGRESS)).length)}</span>} */}
        {/* > */}
        {/* <TabPane> */}
        {/* <Table
          key={'sos'}
          rowKey={record => record.saleOrderId}
          columns={columnsSkelton}
          expandedRowRender={expandedRowRender}
          dataSource={saleOrderData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          scroll={{ x: 700 }}
          size="large"
          bordered /> */}
        {/* </TabPane> */}
        {/* <TabPane
            key="3"
            tab={<span style={{ color: "orange" }}>{'IN PROGRESS: ' + (saleOrderData.filter(el => ((Number(el.totalBomQty) <= Number(el.totalPoQty)) && el.pmStatus === PmStatusEnum.IN_PROGRESS)).length)}</span>}
          > */}
        {/* <TabPane>
            <Table
              key={'InprogressOrderes'}
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton}
              expandedRowRender={expandedRowRender}
              dataSource={saleOrderData.filter(el => ((Number(el.totalBomQty) <= Number(el.totalPoQty)) && el.pmStatus === PmStatusEnum.IN_PROGRESS))}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 700 }}
              size="large"
              bordered />
              </TabPane> */}
        {/* </TabPane>
          <TabPane
            key="4"
            tab={<span style={{ color: "#52c41a" }}>{'Closed Sale Orders: ' + (saleOrderData.filter(el => el.pmStatus == PmStatusEnum.CLOSE).length)}</span>}
          > */}
        {/* <TabPane>
            <Table
              key={'ClosedOrders'}
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton}
              expandedRowRender={expandedRowRender}
              dataSource={saleOrderData.filter(el => el.pmStatus == PmStatusEnum.CLOSE)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 700 }}
              size="large"
              bordered />
          </TabPane>
        </Tabs> */}
      </Card>
    </>
  );
}

export default SaleOrdersGrid;
