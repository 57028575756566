import React from 'react';
import {Form, Input, Button, Select, Card, Row, Col} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from "react-router-dom";
import {RmColumnDto} from '@gtpl/shared-models/masters';
import {RmColumnService} from '@gtpl/shared-services/masters';


import './rm-columns-form.css';

/* eslint-disable-next-line */
export interface RmColumnsFormProps {
  columnData: RmColumnDto;
  updateColumn: (column:RmColumnDto) => void;
  isUpdate: boolean;
  closeForm: ()=>void;
}

export function RmColumnsForm(
  props: RmColumnsFormProps
) {
  const [form] = Form.useForm();
  let history =  useHistory();
  
  let createdUser = "";
  if(!props.isUpdate)
  {
    createdUser = localStorage.getItem('createdUser');
  }
  const columnService = new RmColumnService();
  const createProduct = (columnData: RmColumnDto)=>{
    columnService.createColumn(columnData).then(res => {
      if(res.status) {
        AlertMessages.getSuccessMessage("Column Created Successfully");
        history.push("/rm-column-grid");
        onReset();
      }else {
        if(res.intlCode)
        {
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
  const onReset = ()=>{
    form.resetFields();
  }

  const saveData = (values: RmColumnDto) => {
    if(props.isUpdate){
      props.updateColumn(values);
    }else {
      createProduct(values);
    }
  };
  return (
    <Card title={<span style={{color:'white'}}>Rm Columns</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra = { props.isUpdate == true ? "":<Link to='/rm-columns-grid'><Button className='panel_button' > View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.columnData} name="control-hooks" onFinish={saveData}>
        <Form.Item name="columnsId" style={{display:'none'}} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
      </Form.Item>
        <Row>
          <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="columnsCode"
                  label="Column Code"
                  rules={[
                    {
                      required: true,message:'Column Code is required'
                      
                    },
                    {
                      pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="columnsNo"
                  label="Column Name"
                  rules={[
                    {
                      required: true,message:'Column No is required'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>
        </Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
      </Form>
    </Card>
  );
}

export default RmColumnsForm;
