import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin, Input } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  UnitsOfWeightInput,
} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { GlobalStatus, UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

let converter = require('number-to-words');
import { numberToWords } from 'amount-to-words'
import moment from 'moment';
import stampImage from "./stamp.png";
import signature from "./signature.png"



/* eslint-disable-next-line */
export interface CommercialPackingListProps {
  saleOrderId: number;
}


export function CommercialPackingList(props: CommercialPackingListProps) {

  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;
  const [remarksData, setRemarksData] = useState<string>(undefined);
  const { TextArea } = Input;
  const defaultDeclaration = 'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct'

  let totalCases = 0;
  let totalLbWeight = 0;
  let totalGrossWeight = 0;
  let totalAmount = 0;
  let lbPouchWeight
  let kgPouchWeight


  const getData = (saleOrderId) => {
    const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(saleOrderId)
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };
  console.log(plantDetails)
  const handleRemarks = (value) => {
    console.log(value);
    console.log(value.target.defaultValue);

    setRemarksData(value);
  }
  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
    // window.print();
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };

  return (
    <div>
      <>
        {/* <span style={{alignContent:'left', backgroundColor:'red'}}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="CustomsInvoice"
              sheet="CustomsInvoice"
              buttonText="Get Excel" />
          </span> */}
        <br />
        {plantDetails ?

          <html>
            <head></head>

            <body id="printme">
              {/* <img className='stamp-image' src={stampImage} />
            <img className='rts-packinglist-signature' src={signature} /> */}


              <br></br>
              <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                <tr><td className={'ta-b'} colSpan={8} style={{ textAlign: 'center', fontSize: '16px', lineHeight: '10px', paddingTop: '20px' }}><h1>PACKING LIST</h1></td></tr>
                <tr>
                  <td className={'ta-b'} colSpan={3} rowSpan={2}>
                    <b>Exporter:</b> <br />
                    <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                    {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                    <br />
                    {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                    <br />
                    {exporterData?.state + ', ' + exporterData?.country}
                    <br />

                  </td>
                  <td className={'ta-b'} colSpan={3}>
                    <b>Invoice No. </b>
                    {plantDetails?.invoiceNumber}<br />
                    <b>Date:</b>
                    {moment(plantDetails?.invoiceDate).format('DD-MMM-YYYY')}
                  </td>

                  <td className={'ta-b'} colSpan={2}>
                    <b>Exporter's Ref<br />
                      IE Code </b> {exporterData?.ieCode}
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className={'ta-b'}>
                    <b>Buyer's Order No. </b>
                    PO NO. {plantDetails.custPoNo}
                    {/* <h4><b>Date:</b>{moment(plantDetails?.actualEdl).format('Do MMMM, YYYY')}
                    </h4> */}
                  </td>
                </tr>

                <tr>
                  <td className={'ta-b'} colSpan={3} rowSpan={plantDetails?.notifyPartyThree ? 2 : 1} style={{ textAlign: 'start' }}>
                    <b>PROCESSED & PACKED AT </b><br />
                    <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                    {plantDetails?.invoicedPlant?.villageName + ", " + plantDetails?.invoicedPlant?.mandalName}<br />
                    {plantDetails?.invoicedPlant?.postalCode + ", " + plantDetails?.invoicedPlant?.distirctName + ", " + plantDetails?.invoicedPlant?.stateName + ", " + plantDetails?.invoicedPlant?.countryName}<br />
                    <b>FDA REGISTRATION NUMBER :</b>{plantDetails?.invoicedPlant?.fdaRegNumber}
                    <br />
                    {plantDetails?.invoicedPlant?.feiNumber ?
                      <><b>FEI NUMBER:</b>{plantDetails?.invoicedPlant?.feiNumber}</> : <></>}
                  </td>
                  <td colSpan={plantDetails?.notifyPartyOne ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start', width: '50%' }}>
                    <b ><u>
                      Notify party 1</u>
                    </b><br />
                    <pre>{(plantDetails?.notifyPartyOne) ? plantDetails?.notifyPartyOne : ''}</pre><br />

                  </td>
                  {
                    (plantDetails.notifyPartyTwo) ?
                      <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start', width: '50%' }}>
                        <b ><u>
                          Notify party 2</u>
                        </b><br />

                        <pre>{(plantDetails?.notifyPartyTwo) ? (plantDetails?.notifyPartyTwo) : " "}</pre><br />

                      </td> : <></>
                  }
                </tr>
                <tr >
                  {
                    (plantDetails.notifyPartyThree) ?
                      <td colSpan={plantDetails.notifyPartyFour ? 3 : 5} className={'ta-b'} style={{ textAlign: 'start' }}>
                        <b ><u>
                          Notify party 3</u>
                        </b><br />

                        <pre>{(plantDetails?.notifyPartyThree) ? plantDetails?.notifyPartyThree : " "}</pre>
                      </td>
                      : <></>
                  }
                  {
                    (plantDetails.notifyPartyFour) ?
                      <td colSpan={2} className={'ta-b'} style={{ textAlign: 'start' }}>
                        <b ><u>
                          Notify party 4</u>
                        </b><br />

                        <pre>{(plantDetails?.notifyPartyFour) ? plantDetails?.notifyPartyFour : " "}</pre>
                      </td>
                      : <></>
                  }

                </tr>

                <tr >
                  <td colSpan={3} rowSpan={2} className={'ta-b'}>
                    <b>Consignee:</b><br />
                    <b>AZ GEMS INC:</b><br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","}<br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","}<br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[3]}<br />
                    <b>FDA REGISTRATION NO. </b>{unitsData?.consigneefdaRegNumber}
                  </td>

                  <td colSpan={5} className={'ta-b'} >
                    <b>
                      Importer on record:
                    </b><br />
                    <b>AZ GEMS INC</b><br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
                    <br /><b>FDA REGISTRATION NO.</b>{unitsData?.consigneefdaRegNumber}
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className={'ta-b'}>
                    <b > Country of Origin of Goods:</b><br />
                    INDIA
                  </td>
                  <td colSpan={5} className={'ta-b'}>
                    <b> Country of Final Destination:</b><br />
                    {plantDetails?.country}{' '}

                  </td>

                </tr>


                <tr>
                  <td className={'ta-b'} colSpan={2}>
                    <b>Pre Carriage By:</b>
                    <h4>Road</h4>
                  </td>
                  <td className={'ta-b'} colSpan={1}>
                    <b>Place of Receipt of Pre-carrier:</b>
                    {(plantDetails?.placeOfReceipt) ? plantDetails?.placeOfReceipt : ' '}
                  </td>
                  <td colSpan={5} className={'ta-b'}>
                    <b>Terms of Delivery and Payment:</b><br />

                    {plantDetails?.saleOrderItems[0]?.paymentTerms}

                  </td>
                </tr>

                <tr>

                  <td className={'ta-b'} colSpan={2}>
                    <b>Vessel / Flight No. </b>

                    {(plantDetails?.vesselName) ? (plantDetails?.vesselName).toUpperCase() : ""}

                  </td>
                  <td className={'ta-b'} colSpan={1}>
                    <b> Port of Loading: </b><br />
                    {plantDetails?.portofloading ? (plantDetails?.portofloading)?.toUpperCase() + ', INDIA' : ''}

                  </td>

                  <td colSpan={5} rowSpan={2} className={'ta-b'}>
                    <b> Container No.&nbsp;</b>
                    {plantDetails?.saleOrderItems[0]?.containerNo}
                    <br />
                    <b> Liner Seal No.&nbsp;</b>
                    {plantDetails?.saleOrderItems[0]?.linearSelaNo}
                    <br />
                    <b> Bill of Lading No.&nbsp;</b>
                    {plantDetails?.trackingNo}<br />
                  </td>
                </tr>
                <tr>
                  <td className={'ta-b'} colSpan={2}>
                    <b>Port of Discharge:</b><br />
                    {(plantDetails?.portOfDischarge ? (plantDetails?.portOfDischarge)?.toUpperCase() : '') + "," + (plantDetails?.country ? plantDetails?.country : '')}
                  </td>
                  <td className={'ta-b'} colSpan={1}><b>
                    Final Destination:</b><br />
                    {(plantDetails?.saleOrderItems[0]?.destinationDetails ? plantDetails?.saleOrderItems[0]?.destinationDetails : '') + ',' + (plantDetails?.country ? plantDetails?.country : '')}
                    
                  </td>
                </tr>

                <tr>
                  <td colSpan={1} style={{ textAlign: 'center' }}>
                    Marks & Nos /
                  </td>
                  <td colSpan={1} style={{ textAlign: 'center' }}>
                    No & Kind of Pkgs
                  </td>
                  <td style={{ textAlign: 'center' }} colSpan={2}>
                    Description of Goods
                  </td>

                  <td style={{ textAlign: 'center' }}>
                  </td>
                  <th className={'ta-b'} colSpan={3} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                    Quantity
                    <br />
                    IN LBS <br /> NET WEIGHT
                  </th>
                  {/* <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                    Rate
                    <br />
                    PER KG
                    <br />({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  </th>
                  <th className={'ta-b'} colSpan={1} style={{ textAlign: 'center', borderBottom: '0px' }} rowSpan={2}>
                    Amount
                    <br />

                    ({plantDetails?.saleOrderItems[0]?.currencyDetails})
                  </th> */}
                </tr>
                <tr>
                  <th colSpan={1} style={{ textAlign: 'center' }}>
                  </th>
                  <th colSpan={1} style={{ textAlign: 'center' }}>
                    HSN Code
                  </th>
                  <th style={{ textAlign: 'left' }} colSpan={2}>
                    Size
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    No. Of Cartons
                  </th>
                </tr>

                {
                  plantDetails?.saleOrderItems?.map(item => {
                    totalCases += item.cases;
                    const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                    let finalNetWeight;
                    let unitPrice;
                    lbPouchWeight = Number(item.pouchWeight)
                    kgPouchWeight = Number(item.pouchWeight)
                    if (uom.name == UomEnum.OZ) {
                      lbPouchWeight = lbPouchWeight * 0.0625
                    }
                    if (uom.name == UomEnum.Gms) {
                      kgPouchWeight = kgPouchWeight * 0.001
                    }
                    if (plantDetails.isInvoiced == GlobalStatus.YES) {
                      if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                        // finalNetWeight = Number(item.dispatchedQty) * 0.454;
                        finalNetWeight = Number(item.dispatchedQty);
                        unitPrice = (Number(item.revisedUnitPrice) / 0.454).toFixed(4);
                      } else {
                        // finalNetWeight = Number(item.dispatchedQty);.
                        finalNetWeight = Number(item.dispatchedQty) * 2.20462;
                        unitPrice = Number(item.revisedUnitPrice).toFixed(4);
                      }
                    }
                    else {
                      if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                        finalNetWeight = Number(item.netWeight);
                        // finalNetWeight = Number(item.netWeight) * 0.454;
                        unitPrice = (Number(item.unitPrice) / 0.454).toFixed(4);
                      } else {
                        finalNetWeight = Number(item.netWeight) * 2.20462;
                        // finalNetWeight = Number(item.netWeight);
                        unitPrice = Number(item.unitPrice).toFixed(4);
                      }
                    }
                    totalLbWeight += Number(finalNetWeight);
                    totalGrossWeight += (Number(item.grossWeightPerKg) * Number(item.invoicedCases));
                    totalAmount += Number(unitPrice) * Number(finalNetWeight);
                    let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" ? "PENAEUS MONODON" : "";
                    // let shrimp = item.itemName === "Vanname"?"SHRIMP":"";
                    let shrimp = "SHRIMPS";
                    let hasNumber = /\d/;
                    let normalOrder = hasNumber.test(plantDetails?.buyerPoNo);
                    let lotRefString = '';
                    let lotNo = '';
                    let RefNo = '';
                    if (normalOrder) {
                      if (plantDetails?.lotCode) {
                        lotNo = 'LOT NO. ' + plantDetails?.lotCode + ','
                      }
                      if (plantDetails?.buyerPoNo) {
                        RefNo = 'REF NO. ' + plantDetails?.buyerPoNo
                      }
                      lotRefString = lotNo + RefNo
                    }
                    return (
                      <><tr style={{ border: '0px' }}>
                        <td style={{ textAlign: 'center' }}>{item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}<br />
                          <span style={{ textAlign: 'center' }}>{Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')}</span><br /><span style={{ textAlign: 'center' }}>{(uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? <>{(Number(item.caseWeight) * 0.454).toFixed(3) + ' KGS'}</> : <></>}</span></td>
                        <td style={{ textAlign: 'center' }}>{item.hsnCode}</td>
                        <td colSpan={2} style={{ textAlign: 'left', alignContent: 'center' }}>
                          {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US')) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                            (!(([
                              'HLSO', 'HOSO', 'EZPeel', 'N/A'
                            ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                          ) + (item.valueAdditionTwoIdCode ? item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') : '')}<br />{(item.valueAdditionThreeIdCode ? item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') : '') + ' ' + (item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}<br />
                          {'LATIN NAME : ' + latinName.toUpperCase() + ' '}<br />
                          {lotRefString}<br />
                          {' BRAND : ' + item.displayBrandName?.toUpperCase()}<br />
                          {plantDetails?.finNumber ? ('FPO#/FIN# ' + (plantDetails?.finNumber)) : ''}{plantDetails?.finNumber ? <br /> : ''}

                        </td>
                        <td style={{ textAlign: 'center' }} ></td>
                        <td colSpan={3} className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                        {/* <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td>
                        <td className={'ta-b'} style={{ textAlign: 'center', borderTop: '0px', borderBottom: '0px' }}></td> */}
                        {/* <td className={'ta-b'} style={{textAlign:'center', borderTop:'0px', borderBottom:'0px'}}></td> */}
                      </tr><tr>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'left' }} colSpan={2}>{item.minGrade + '/' + item.maxGrade}</td>
                          <td style={{ textAlign: 'right' }}>{item.cases}</td>
                          <td colSpan={3} className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px' }}>{(finalNetWeight) ? (Number(finalNetWeight)).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</td>
                          {/* <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(unitPrice)}</td>
                          <td className={'ta-b'} style={{ textAlign: 'right', borderTop: '0px', borderBottom: '0px' }}>{(unitPrice * finalNetWeight).toFixed(2)}</td> */}
                        </tr></>
                    )
                  }
                  )
                }
                <tr>
                  <td className={'ta-b'} colSpan={4} style={{ textAlign: 'center' }}>
                    <b> Total</b>
                  </td>
                  <td colSpan={1} className={'ta-b'} style={{ textAlign: 'right' }}><b>
                    {totalCases}</b>
                  </td>
                  <td colSpan={3} style={{ textAlign: 'right' }} className={'ta-b'}><b>
                    {(totalLbWeight) ? Number(totalLbWeight).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : 0}</b>
                  </td>
                  {/* <td className={'ta-b'} style={{ borderTop: '0px' }}></td>
                  <td className={'ta-b'} style={{ borderTop: '0px' }}></td> */}
                </tr>

                {/* <tr>
              <td colSpan={5} >
                <b>Amount chargeable </b><br/> (in words):  <b> U.S. DOLLARS {(totalAmount)?(toWords.convert(totalAmount, { currency: true })).replace("Rupees","").replace("Paise", "Cents").toUpperCase():""}</b>
              </td>
              <td colSpan={2} style={{ textAlign: 'left', borderBottom:'0px' }}>
                <b>Total USD</b>
              </td>
              <td style={{ textAlign: 'right' }} className={'ta-b'}><b>{Number(totalAmount).toFixed(2)}</b></td>
            </tr>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            
            <tr>
              <td></td>
              <td colSpan={6}>
                <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                  <tr>
                    <td className={'ta-b'} colSpan={2}>
                    <h4><b>FREIGHT IN USD {plantDetails?.saleOrderItems[0]?.currencyDetails}	:</b></h4>
                    </td>
                    <td className={'ta-b'} colSpan={2}>
                      <h4><b>FOB VALUE IN RS. :</b></h4>
                    </td>
                    <td className={'ta-b'} colSpan={4}>
                      <h4><b>FOB VALUE IN {plantDetails?.saleOrderItems[0]?.currencyDetails}:</b> </h4>
                    </td>

                  </tr>
                  <tr>
                    <td colSpan={8} className={'ta-b'}>
                      <h4>
                        <b> EXCHANGE RATE: 1.00 ({plantDetails?.saleOrderItems[0]?.currencyDetails}) =</b>
                      </h4>
                    </td>
                  </tr>
                </table>
              </td>
              <td className={'ta-b'} style={{ borderTop:'0px',borderBottom:'0px',borderLeft:'0px' }}></td>
            </tr>
            <br/>
            <br/>
            <tr>
              <td></td>
              <td colSpan={7}>
                <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
                  <tr>
                    <td rowSpan={2} className={'ta-b'} style={{borderBottom:'0px'}} colSpan={4}>
                    <b><u>Declaration</u>:</b><br />
                    <span><b>Supply Meant for Export Under Bond or Letter of Undertaking without Payment of Integrated Tax (IGST)</b></span>
                    </td>
                    <td className={'ta-b'} style={{ textAlign: 'left' }}>
                      <h4><b>Total Amount Before Tax :</b></h4>
                      <b>  CGST @ 0% :<br />
                      SGST @ 0% :<br />
                      IGST @ 0%:<br /></b>
                      <h4><b>Total GST Amount :</b></h4>
                    </td>
                    <td style={{ textAlign: 'right' }} className={'ta-b'} colSpan={3}>
                      <h4 style={{ textAlign: 'right' }}><b>Currency ({plantDetails?.saleOrderItems[0]?.currencyDetails})</b></h4>
                        {Number(totalAmount.toFixed(2))}<br />
                      0.00<br />
                      0.00<br />
                      0.00<br />
                      0.00
                    </td>
                  </tr>
                  <tr>
                    <td className={'ta-b'}>
                      <h4><b>Total Amount After Tax :</b></h4>
                    </td>
                    <td style={{ textAlign: 'right' }} className={'ta-b'} colSpan={3}>
                      <h4>{Number(totalAmount).toFixed(2)}</h4>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <br></br>
            <br></br>
            <tr>
              <td className={'ta-b'} colSpan={8} style={{borderLeft:'0px',borderBottom:'0px', borderTop:'0px'}} ><b>
                This Shipment is made under DUTY DRAW BACK SCHEME AS WELL AS UNDER RoDTEP SCHEME.</b><br />
                I/We, in regard to my/our claim under RoDTEP scheme made in this Shipping Bill or Bill of Export, hereby declare that:<br />
                1. I/ We undertake to abide by the provisions, including conditions, restrictions, exclusions and time-limits as provided under RoDTEP scheme, and relevant notifications,
                regulations, etc., as amended from time to time.<br/>
                2. Any claim made in this shipping bill or bill of export is not with respect to any duties or taxes or levies which are exempted or 
                credited under any other mechanism outside RoDTEP.<br />
                3. I/We undertake to preserve and make available relevant documents relating to the exported goods for the purposes of audit in the manner and for the time period prescribed in the Customs Audit Regulations, 2018.<br />
              </td>
            </tr> */}
                <tr>&nbsp;</tr>
                <tr>&nbsp;</tr>
                <tr>&nbsp;</tr>
                <tr>
                  <td style={{ width: '20%' }} className={'ta-b'}></td>
                  <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                    <h4>IN LBS</h4>
                  </td>
                  <td style={{ textAlign: 'center', width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                    <h4>IN KGS</h4>
                  </td>
                  <td style={{ width: '30%' }}></td>
                  <td className={'ta-b'} colSpan={4} style={{ width: '10%', borderTop: '0px', borderLeft: '0px', borderBottom: '0px' }}></td>
                </tr>
                <tr>
                  <td style={{ width: '20%', borderBottom: '0px' }} className={'ta-b'}>
                    {' '}
                    <b>TOTAL NET WEIGHT:{' '}</b>
                  </td>
                  <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                    <h4>{(Math.trunc(totalLbWeight)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                    {/* <h4>{(Math.trunc(totalLbWeight / 0.454)).toFixed(3)}</h4> */}
                  </td>
                  <td style={{ textAlign: 'right', width: '20%', borderTop: '0px', borderBottom: '0px' }} className={'ta-b'}>
                    {/* <h4>{(totalLbWeight).toFixed(3)}</h4> */}
                    <h4>{(totalLbWeight * 0.454).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                  </td>
                  <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                    <b>TOTAL NO. OF MASTER CARTONS:</b>
                  </td>
                  <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderBottom: '0px', borderLeft: '0px' }}>
                    <h4>{totalCases}</h4>
                  </td>

                </tr>
                <tr>
                  <td style={{ width: '20%', borderTop: '0px' }} className={'ta-b'}>
                    {' '}
                    <b> TOTAL GROSS WEIGHT:</b>
                  </td>
                  <td style={{ textAlign: 'right', width: "20%", borderTop: '0px' }} className={'ta-b'}>
                    {/* <h4>{(totalLbWeight)?Number(Number(totalLbWeight).toFixed(3)):0}</h4> */}
                    <h4>{Math.round((totalGrossWeight / 0.454) ? (totalGrossWeight / 0.454) : 0).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                  </td>
                  <td style={{ textAlign: 'right', width: '20%', borderTop: '0px' }} className={'ta-b'}>
                    <h4>{(Math.round(totalGrossWeight ? totalGrossWeight : 0)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</h4>
                  </td>
                  <td colSpan={5}></td>
                  {/* <td colSpan={3} style={{ width: '30%', textAlign: 'center' }}>
                    <b>TOTAL AMOUNT (IN {plantDetails?.saleOrderItems[0]?.currencyDetails}):</b>
                  </td>
                  <td className={'ta-b'} colSpan={2} style={{ textAlign: 'right', width: '10%', borderTop: '0px', borderBottom: '0px', borderLeft: '0px' }}  >
                    <h4>{Number(totalAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                  </td> */}
                </tr>
                {/* </table>
                <table style={{width:'100%'}} id="table-to-xls"> */}

                <tr>
                  <td colSpan={4} id='inputText'><b>Declaration :</b>
                    <TextArea style={{ outline: 'none', border: 'none' }} rows={3} contentEditable={true} defaultValue={defaultDeclaration}
                      onChange={(evt) => handleRemarks(evt)}
                    ></TextArea>
                  </td>
                  <td colSpan={4} className={'ta-b'} style={{ textAlign: 'left' }}>
                    <div >
                      <b>Signature & Date For:</b>
                      <h4>{exporterData?.name}</h4><br />
                      <h4>AUTHORISED SIGNATORY</h4>
                    </div>
                  </td>
                </tr>
              </table>
            </body>
          </html>


          : <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height: '100' }}>CUSTOMS INVOICE</span>}
            // extra={<> <Button href="/#/document-register" style={{color:'black'}}>Back</Button></>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}><span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span></Card>}
      </>
    </div>
  );
}

export default CommercialPackingList;
