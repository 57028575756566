import React,{useState,useEffect} from 'react';
import{Form,Input,Card,Button,Select,Row,Col} from 'antd';
import{DownTimeReasonDto,DowntimeReasonDefault}from '@gtpl/shared-models/masters';
import {  Link, useHistory } from "react-router-dom";
import{DowntimeReasonService}  from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './downtime-reason-form.css';
import TextArea from 'antd/lib/input/TextArea';

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  }, 
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

/* eslint-disable-next-line */
export interface DowntimeReasonFormProps {
  downtimereasonData:DownTimeReasonDto;
  updateItem:(downtimereasonData:DownTimeReasonDto)=>void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function DowntimeReasonForm( props: DowntimeReasonFormProps)
 {
  const [form] = Form.useForm();
  const service = new DowntimeReasonService;
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

let history =  useHistory()
const saveDowntimeReason = (downtimereasonData: DownTimeReasonDto) => {
  setDisable(true)
  downtimereasonData.downtimereasonId=0;
  downtimereasonData.isActive=true;
  service.createDownTimeReason(downtimereasonData).then(res => {
    setDisable(false)
    if (res.status) {
      AlertMessages.getSuccessMessage('downtimereason Created Successfully');
   history.push("/DownTimeReason-view")
      onReset();
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    setDisable(false)
    AlertMessages.getErrorMessage(err.message);
  })
}
const saveData = (values: DownTimeReasonDto) => {
  // console.log(values);
  setDisable(false)
  if(props.isUpdate){
    props.updateItem(values);
  }else{
    setDisable(false)
    saveDowntimeReason(values);

  }

};

/**
 * To reset form fields
 */

 const onReset = () => {
  console.log('jjjjjjjj');
  // props.countryData = new CountryDto[""];  
  form.resetFields();
};

return (
  

  <Card title={<span style={{color:'white'}}>DownTime Reasons</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/DownTimeReason-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
  
  
        <Form form={form} initialValues={props.downtimereasonData} name="control-hooks" onFinish={saveData}
        layout="vertical"
        >
  
        <Form.Item name="downtimereasonId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
        <Input hidden/>
      </Form.Item>
      <Row>
          <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{margin:'1%'}}>
                <Form.Item
                    name="downtimeReason"
                    label="DownTime Reason"
                    rules={[
                      {
                        required: true,
                        message:"Enter DowntimeReason Name"
                      },
                      {
                        pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                        message: `Should contain only alphabets.`
                      },
                    ]}>
                    <Input/>
                  </Form.Item>
          </Col >
          {/* <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{margin:'1%'}}>
          <Form.Item  name="downtimestatus" label="DownTimeReasonStatus"
          rules={[
          {
            pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
            message: `Should contain only alphabets.`
          }, 

        ]}>
              <TextArea rows={1} />
            </Form.Item>
          </Col> */}
          <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{margin:'1%'}} hidden>
                <Form.Item
                    name="sla"
                    label="SLA"
                    rules={[
                      {
                        required: true,
                        message:"Enter Expected Time In minuts"
                      },
                      {
                        pattern: /^[0-9\b]+$/,
                        message: `Should contain only numbers`
                      },
                    ]}
                    initialValue={120}
                    >
                    <Input/>
                  </Form.Item>
          </Col >
          </Row>
          <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            
              <Button type="primary" disabled={disable} htmlType="submit" >
                Submit
              </Button>
              {(props.isUpdate===false) &&
       <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
          Reset
        </Button>
        }
              </Col>
            </Row>
        </Form>
      </Card>
    );










}

export default DowntimeReasonForm;
