import React, { useEffect, useState } from 'react';
import { AttendanceService } from '@gtpl/shared-services/hrms';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Col, Form, Row, Select } from 'antd';
import { EmployeeAttendanceRequest, ShiftWiseDataModel } from '@gtpl/shared-models/hrms';
import AttendanceCardComponet from './attendance-card-component';
import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import AttendanceGraph from './attendance-graph-dashboard';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
const { Option } = Select;


export interface AttendanceDashboardProps { }

export function AttendanceDashboard(props: AttendanceDashboardProps) {

  const [attendanceData, setAttendanceData] = useState<ShiftWiseDataModel[]>([]);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [selectedunit, setUnit] = useState<number>(0);
  const service = new AttendanceService();
  const unitsService = new UnitcodeService();

  //Shift_A
  const attendanceBodyCardA: CardBodyDetails[] = [];

  let tTotalCountA = 0;
  let tPresentCountA = 0;
  let tAbsentA = 0;
  if (attendanceData.length > 0) {
    for (const dataInfo of attendanceData[0].deptDetailes) {
      const absent = Number(dataInfo.totalCount) - Number(dataInfo.presentCount);
      attendanceBodyCardA.push(new CardBodyDetails(dataInfo.departmentName, Number(dataInfo.presentCount).toString(), Number(absent).toString(), Number(dataInfo.totalCount).toString()));
      tTotalCountA += Number(dataInfo.totalCount);
      tPresentCountA += Number(dataInfo.presentCount);
      tAbsentA = Number(tTotalCountA) - Number(tPresentCountA);
    }
  }
  const totalEmpCountBodyA = new CardHeaderBody('Total', tTotalCountA.toString());
  const totalPresentEmpBodyTwoA = new CardHeaderBody('Present', tPresentCountA.toString());
  const totalPresentEmpBodyThA = new CardHeaderBody('Absent', tAbsentA.toString());
  const attnCardHeaderA = new CardHeader('Today Attendace for Shift G', [totalPresentEmpBodyTwoA, totalPresentEmpBodyThA, totalEmpCountBodyA]);

  const cardForShiftA = new CardDetail(attnCardHeaderA, attendanceBodyCardA, ['Department', 'Present', 'Absent', 'Total']);

  //Shift_B
  // const attendanceBodyCardB: CardBodyDetails[] = [];

  // let tTotalCountB = 0;
  // let tPresentCountB = 0;
  // let tAbsentB = 0;
  // if (attendanceData.length > 0) {
  //   for (const dataInfo of attendanceData[1].deptDetailes) {
  //     const absent = Number(dataInfo.totalCount) - Number(dataInfo.presentCount);
  //     attendanceBodyCardB.push(new CardBodyDetails(dataInfo.departmentName, Number(dataInfo.presentCount).toString(), Number(absent).toString(), Number(dataInfo.totalCount).toString()));
  //     tTotalCountB += Number(dataInfo.totalCount);
  //     tPresentCountB += Number(dataInfo.presentCount);
  //     tAbsentB = Number(tTotalCountB) - Number(tPresentCountB);
  //   }
  // }
  // const totalEmpCountBodyB = new CardHeaderBody('Total', tTotalCountB.toString());
  // const totalPresentEmpBodyTwoB = new CardHeaderBody('Present', tPresentCountB.toString());
  // const totalPresentEmpBodyThB = new CardHeaderBody('Absent', tAbsentB.toString());
  // const attnCardHeaderB = new CardHeader('Today Attendace for Shift B', [totalPresentEmpBodyTwoB, totalPresentEmpBodyThB, totalEmpCountBodyB]);

  // const cardForShiftB = new CardDetail(attnCardHeaderB, attendanceBodyCardB, ['Department', 'Present', 'Absent', 'Total']);

  // //Shift_C
  // const attendanceBodyCardC: CardBodyDetails[] = [];

  // let tTotalCountC = 0;
  // let tPresentCountC = 0;
  // let tAbsentC = 0;
  // if (attendanceData.length > 0) {
  //   for (const dataInfo of attendanceData[2].deptDetailes) {
  //     const absent = Number(dataInfo.totalCount) - Number(dataInfo.presentCount);
  //     attendanceBodyCardC.push(new CardBodyDetails(dataInfo.departmentName, Number(dataInfo.presentCount).toString(), Number(absent).toString(), Number(dataInfo.totalCount).toString()));
  //     tTotalCountC += Number(dataInfo.totalCount);
  //     tPresentCountC += Number(dataInfo.presentCount);
  //     tAbsentC = Number(tTotalCountC) - Number(tPresentCountC);
  //   }
  // }
  // const totalEmpCountBodyC = new CardHeaderBody('Total', tTotalCountC.toString());
  // const totalPresentEmpBodyTwoC = new CardHeaderBody('Present', tPresentCountC.toString());
  // const totalPresentEmpBodyThC = new CardHeaderBody('Absent', tAbsentC.toString());
  // const attnCardHeaderC = new CardHeader('Today Attendace for Shift G', [totalPresentEmpBodyTwoC, totalPresentEmpBodyThC, totalEmpCountBodyC]);

  // const cardForShiftC = new CardDetail(attnCardHeaderC, attendanceBodyCardC, ['Department', 'Present', 'Absent', 'Total']);


  useEffect(() => {
    getAllPlants();
    getAttendanceData();
  }, [])

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const handleUnit = (value) => {
    setUnit(value)
  }

  const getAttendanceData = () => {
    const currDate = new Date();
    // let unitId = 0;
    const date = currDate.getFullYear() + '-' + (Number(currDate.getMonth()) + 1) + '-' + currDate.getDate();
    // if (Number(localStorage.getItem('unit_id')) != 5) {
    //   unitId = Number(localStorage.getItem('unit_id'));
    // } else {
    //   unitId = selectedunit;
    // }
    const req = new EmployeeAttendanceRequest(date);
    service.getAttendanceDataShiftWise(req).then((res) => {
      if (res.status) {
        setAttendanceData(res.data)
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }


  return (

    <div className='dashboard' >
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <Form.Item
            name="unitId"
            label=" Unit"
            rules={[
              {
                required: false, message: 'Select Unit',
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Select Unit"
              allowClear
              disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              style={{ width: '100%' }}
              onChange={handleUnit}
            >
              {plantData.map(dropData => {
                return <Option value={dropData.plantId}>{dropData.plantName}</Option>
              })}
            </Select>
          </Form.Item>

        </Col>
      </Row>
      <Row gutter={24} justify='space-around'>

        <Col className="cardComp" xs={24} sm={24} md={12} xl={8}>
          <AttendanceCardComponet cardData={cardForShiftA} />
        </Col>
        {/* 
        <Col className="cardComp" xs={24} sm={24} md={12} xl={8}>
          <AttendanceCardComponet cardData={cardForShiftB} />
        </Col>

        <Col className="cardComp" xs={24} sm={24} md={12} xl={8}>
          <AttendanceCardComponet cardData={cardForShiftC} />
        </Col> */}
      </Row>
      <br />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} xl={24}>
          <AttendanceGraph />
        </Col>
      </Row>
    </div >

  );
}

export default AttendanceDashboard;