import { ShiftDto, TeamCalenderDto, TeamDto } from "@gtpl/shared-models/hrms";
import { ShiftService, TeamCalenderService, TeamsService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;


export interface TeamCalenderProps {
  teamcalenderData: TeamCalenderDto;
  updateTeamCalender: (teamcalender: TeamCalenderDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export function TeamCalender(
  props: TeamCalenderProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const teamCalenderService = new TeamCalenderService()
  const [disable, setDisable] = useState<boolean>(false)
  const dateFormat = "YYYY-MM-DD";
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [teamData, setTeamData] = useState<TeamDto[]>([]);
  const [shiftData, setShiftData] = useState<ShiftDto[]>([]);
  const teamService = new TeamsService();
  const shiftService = new ShiftService();
  let createdUser = "";

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  let id = null;
  if (props.isUpdate) {
    id = props.teamcalenderData.id;
  }


  const createTeamCalender = (Data: TeamCalenderDto) => {
    setDisable(true)
    teamCalenderService.createTeamCalender(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Team Calender created successfully');
        history.push('/teamcalender-grid');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('');
    form.resetFields();
  }

  const saveData = (values: TeamCalenderDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateTeamCalender(values);
    } else {
      setDisable(false)
      console.log(values);
      createTeamCalender(values);
    }
  };


  useEffect(() => {
    getAllTeams();
    getAllShiftActions();
  },[]);

  const getAllTeams = () => {
    teamService.getAllTeams().then(res => {
      if (res.status) {
        setTeamData(res.data);
      } else {
        if (res.intlCode) {
          setTeamData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTeamData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllShiftActions = () => {
    shiftService.getAllShiftActions().then(res => {
      if (res.status) {
        setShiftData(res.data);
      } else {
        if (res.intlCode) {
          setShiftData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setShiftData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current;
  // }

  function diableToDate(current){
    const val = form.getFieldValue('fromDate');
    return current && current < val;
  }

  const getNoOfDays = (dateType: string) => {
    const fromDate = form.getFieldValue('fromDate');
    const toDate = form.getFieldValue('toDate');
    if (moment(fromDate).format('dddd') === 'Sunday' || moment(toDate).format('dddd') === 'Sunday') {
      AlertMessages.getErrorMessage('Selected day should not be a sunday');
      if (dateType == 'fromDate') {
        setFromDate(moment());
        form.setFieldsValue({
          fromDate: moment()
        })
      }
      if (dateType == 'toDate') {
        form.setFieldsValue({
          toDate: moment()
        })
      }
    }
    const d1 = moment(form.getFieldValue('fromDate'), 'YYYY-MM-DD');
    const d2 = moment(form.getFieldValue('toDate'), 'YYYY-MM-DD');
    // Calc all days used including last day ( the +1 )
    const days = d2.diff(d1, 'days') + 1;

    console.log('Days:', days);

    // how many full weekends occured in this time span
    const weekends = Math.floor(days / 7);

    console.log('Full Weekends:', weekends);

    // Subtract all the weekend days
    let businessDays = days - (weekends * 1);

    // Special case for weeks less than 7
    if (weekends === 0) {
      const cur = d1.clone();
      for (let i = 0; i < days; i++) {
        if (cur.day() === 0) {
          businessDays--;
        }
        cur.add(1, 'days')
      }
    } else {
      // If the first day is a sunday we need to account for it
      if (d1.day() === 0) {
        console.log('Extra weekend day (Sunday)');
        businessDays--;
      }
    }
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Team Calendar</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/teamcalender-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} onFinish={saveData} >
        <Form.Item
          name="id" style={{ display: "none" }} initialValue={id} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>

        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="shiftCode"
              label="Shift Group Code"
              initialValue={props.teamcalenderData ? props.teamcalenderData.shiftCode : undefined}
              rules={[
                {
                  required: true,
                  message: "Select valid Shift Code"
                },
                // {

                //   pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                //   message: `Should contain only alphabets.`
                // }
              ]}>
              <Select
                placeholder="Select Shift Group Code"
                // onChange={}
                disabled={props.isUpdate ? true : false }
                allowClear
                style={{ width: '100%' }}
              >
                {teamData.map(dropData => {
                  return <Option key={dropData.id} value={dropData.shiftCode}>{dropData.shiftCode}</Option>;
                })}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="fromDate" label="From Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={(selected) => { setFromDate(selected); getNoOfDays('date') }}
                defaultValue={moment(props.teamcalenderData ? props.teamcalenderData.fromDate : undefined, dateFormat)}
                showToday={true} 
                //  disabledDate={disabledDate}
                />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="toDate" label="To Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={(selected) => { setFromDate(selected); getNoOfDays('date') }}
                defaultValue={moment(props.teamcalenderData ? props.teamcalenderData.toDate : undefined, dateFormat)}
                showToday={true}
              disabledDate= {diableToDate}
                 />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="shift"
              label="Shift "
              initialValue={props.teamcalenderData ? props.teamcalenderData.shift : undefined}
              rules={[
                {
                  required: true,
                  message: "Select valid Shift"
                },
                // {

                //   pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                //   message: `Should contain only alphabets.`
                // }
              ]}>
              <Select
                placeholder="Select Shift"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {shiftData.map(dropData => {
                  return <Option key={dropData.id} value={dropData.shift}>{dropData.shift}</Option>;
                })}</Select>
            </Form.Item>
          </Col>

        </Row>
        
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate !== true) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>

      </Form>
    </Card>
  );
}
export default TeamCalender;