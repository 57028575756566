import React, { useState, useEffect, useRef } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined,ArrowDownOutlined } from '@ant-design/icons';
import { SpecSheetForm, SpecSheetFormProps } from '@gtpl/pages/master/master-components/spec-sheet-form';
import './spec-sheet-grid.css';
import { SpecSheetDetailsDefualt, SpecSheetDto } from '@gtpl/shared-models/masters';
import { SpecSheetService } from '@gtpl/shared-services/masters';
import appSettings from 'apps/services/config';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


/* eslint-disable-next-line */
export interface SpecSheetGridProps { }

export function SpecSheetGrid(
  props: SpecSheetGridProps) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const [specSheetDetailsData, setspecSheetDetailsData] = useState<SpecSheetDto[]>([]);
  const [selectedSpecSheet, setselectedSpecSheet] = useState<SpecSheetDto>(undefined);
  const specsheetservice= new SpecSheetService;
  const [filelist, setFilelist] = useState([]);

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
    * 
    * @param selectedKeys 
    * @param confirm 
    * @param dataIndex 
    */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const openFormWithData=(SpecSheetViewData: SpecSheetDto)=>{
    console.log(SpecSheetViewData);
    setDrawerVisible(true);
    setselectedSpecSheet(SpecSheetViewData);
  }


  const getAllSpecSheetDetails= () => {
    specsheetservice.getAllSpecSheetDetails(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {setspecSheetDetailsData(res.data);
      } else {
        if (res.intlCode) {
          setspecSheetDetailsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setspecSheetDetailsData([]);
    })
  }
  
  /**
      * 
      * @param variantData 
      */
   const updateSpecSheetDetails = (specsheetDetailsData: SpecSheetDto, filelist:any) => {
    console.log(specsheetDetailsData)
    specsheetDetailsData.updatedUser= JSON.parse(localStorage.getItem('username'))
    specsheetservice.updateSpecSheet(specsheetDetailsData).then(res => {
      // console.log(res);
      if (res.status) {
        console.log(filelist);
        if (filelist.length > 0) {
          const formData = new FormData();
          filelist.forEach((file: any) => {
            formData.append('file', file);
          });
          formData.append('specSheetId', `${res.data.specSheetId}`)
          console.log(formData)
          specsheetservice.specSheetFileUpload(formData).then(fileres => {
            res.data.filePath = fileres.data;
            // history.push("/specsheet-view");
            getAllSpecSheetDetails();
          })
        }
        AlertMessages.getSuccessMessage('Updated Successfully');
        // getAllSpecSheetDetails();
        setDrawerVisible(false);

      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const deleteSpecSheetData = (specSheetViewData:SpecSheetDto) => {
    specSheetViewData.isActive=specSheetViewData.isActive?false:true;
    specsheetservice.activateOrDeactivateSpecSheetDetails(specSheetViewData).then(res => { console.log(res);
      if (res.status) {
        getAllSpecSheetDetails();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const download = (filePath) => {
    console.log(filePath);
    // : FilenameDto[]
    
    if (filePath) {
      filePath = filePath.split(",");
      for (const res of filePath) {
        if(res){
          console.log(res);
          setTimeout(() => {
            const response = {
              file: appSettings.file_upload_path+'masters' +'/'+ `${res}`,
            };
  
            window.open(response.file);
  
          }, 100);
        }
      }
    }
    else {
      AlertMessages.getErrorMessage("Please upload file. ");

    }
  }

  useEffect(() => {getAllSpecSheetDetails();}, [])

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Customer',
      dataIndex: 'clientname',
      sorter: (a, b) => a.clientname.trim().localeCompare(b.clientname.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('clientname')
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      // responsive: ['lg'],
      sorter: (a, b) => a.brand.trim().localeCompare(b.brand.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('brand')
    },
    {
      title: 'Varient',
      dataIndex: 'variantname',
      // responsive: ['lg'],
      sorter: (a, b) => a.variantname.trim().localeCompare(b.variantname.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantname')
    },
    {
      title: 'File',
      dataIndex: 'fileName',
      render: (value,rowData) => (
        rowData.fileName!=null?
        <>
       <Tag icon={<ArrowDownOutlined onClick={()=>download(rowData.filePath)}/>} >{value}</Tag>
        </>:''
      ),
    },
   
    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
    
    ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Details');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteSpecSheetData(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate SpecsheetDetails ?'
                :  'Are you sure to Activate SpecsheetDetails ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }

 
  ];
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Spec Sheet</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/spec-sheet-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
     
      <Card >
        {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}

        <Table
          rowKey={record => record.specSheetId}
          columns={columnsSkelton}
          dataSource={specSheetDetailsData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x: true }}
          onChange={onChange}
          size='small'
          bordered />
      </Card>
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '80%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <SpecSheetForm 
              key={Date.now()}
              updateItem={updateSpecSheetDetails}
              isUpdate={true}
              specsheetData={selectedSpecSheet}
              closeForm={closeDrawer} 
             />
        </Card>
      </Drawer>
    </Card>
  
  </>
   
  );
}

export default SpecSheetGrid;
