import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';

export interface CurrentDayStockOutPosProps { }

export function CurrentDayStockOutPos(props: CurrentDayStockOutPosProps) {
    const [page, setPage] = React.useState(1);
    const [stockOutPos, setStockOutPos] = useState<any>(undefined);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getTransferdSOsStock();
    }, [])

    const getTransferdSOsStock = () => {
        service.getTransferdSOsStock({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockOutPos(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                 setStockOutPos(undefined)
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

   
    return (
        <div>
            {console.log(stockOutPos)}
            <Card style={{ padding: '23px', textAlign: 'left', width: 234, height: 63, backgroundColor: '#2e445a', marginBottom: '-18px',color:'white',marginLeft:'-49px',borderRadius:'10px'  }}><b style={{marginLeft:'-18px'}}>Today Transfered SO :{" "+ stockOutPos?.totalCartons!=undefined ? stockOutPos?.totalCartons : '0'}</b></Card>
        </div>
    )
}
export default CurrentDayStockOutPosProps;