import React, { useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {RmToleranceService} from '@gtpl/shared-services/masters';
import './rm-tolerance-form.css';
import { RmToleranceDto } from '@gtpl/shared-models/raw-material-procurement';
import moment from 'moment';

/* eslint-disable-next-line */
const { TextArea } = Input;

export interface RmToleranceFormProps {
  rmToleranceData: RmToleranceDto;
updateRmTolerance:(rmTolerance:RmToleranceDto)=> void;
isUpdate:boolean;
closeForm: () => void;
}

export function RmToleranceForm(
  props: RmToleranceFormProps
) {


  const [form] = Form.useForm();
  let history = useHistory();
  const service = new RmToleranceService;
  const [disable, setDisable] = useState<boolean>(false)
  
  const create = (Data: RmToleranceDto) => {
    Data.createdUser=localStorage.getItem('username')
    setDisable(true)
    Data.date = moment(Data.date).format('YYYY-MM-DD')
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
     AlertMessages.getSuccessMessage('Created Successfully');
     history.push("/rm-tolerance-view")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   AlertMessages.getErrorMessage(err.message);
 })
}

  const saveData = (values: RmToleranceDto) => {
    setDisable(false)
    // console.log(values);
    if(props.isUpdate){
      props.updateRmTolerance(values);
    }else{
      setDisable(false)
      create(values);
      // console.log('test')
    }
  
  };

  const onReset = () => {
    form.resetFields();
  };


  return (
    <Card title={<span style={{color:'white'}}>Rm Tolerance</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/rm-tolerance-view' ><span style={{color:'white'}} >  {(props.isUpdate===false) &&
          <Button className='panel_button' >View </Button>
           }  </span></Link>} >
    
    
          <Form form={form} initialValues={props.rmToleranceData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="rmToleranceId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.rmToleranceData}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:6,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="date"
                      label="Date"
                      initialValue={moment()}
                      rules={[{ required: true }]} >
                      <DatePicker showToday style={{width:'100%'}}  format="YYYY-MM-DD"/> 
                     
                    </Form.Item>
            </Col>
           
          
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="looseShell"
                    label="Loose Shell(%)"
                    rules={[
                      {
                        required: true,
                       
                        message: 'Loose Shell(%) is mandatory, enter a correct value'

                      },
                      {
                        pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                        message: `Invalid Loose Shell`
                      }
                    ]}>
                      <Input/>
                    
                  </Form.Item>
                  </Col>
               
                  
           
                  <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="softShell"
                    label="Soft Shell(%)"
                    rules={[
                      {
                        required: true,
                       
                        message: 'Soft Shell(%) is mandatory, enter a correct value'

                      },
                      {
                        pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                        message: `Invalid Soft Shell`
                      }
                    ]}>
                       <Input/>
                  </Form.Item>
                  </Col>
                  
            
                  <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="fungus"
                    label="Fungus(%)"
                    rules={[
                      {
                        required: true,
                       
                        message: 'Fungus(%) is mandatory, enter a correct value'

                      },
                      {
                        pattern:/^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                        message: `Invalid Fungus`
                      }
                    ]}>
                      <Input/>
                    
                  </Form.Item>

            </Col>
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate!==true) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
  );
}

export default RmToleranceForm;
