import { Button, Card, Col, Form, Input, message, Row, Tooltip, Upload, UploadProps } from 'antd';
import { FormInstance, FormLayout } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import { DeleteFilled, InboxOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import appSettings from 'apps/services/config';

const { Dragger } = Upload;
export interface IFileUploadFormProps {
    maxCount: number;
    layoutType: FormLayout;
    formRef: FormInstance<any>;
    initialValues: any[];
    filesAndAllowedTypes?: Map<string, string[]>;
    children?: React.ReactNode;
    isButtonType?: boolean;
    disableDescription?: boolean;
}


export const FileUploadForm = (props: IFileUploadFormProps) => {
    const { formatMessage: fm } = useIntl();
    const { maxCount, isButtonType, disableDescription, layoutType, formRef, initialValues } = props;
    const [fileList, setFileList] = useState<any[]>([]);
    const fileUploadPath = window[appSettings.assets_url];
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const validateFileType = ({ type, name }: UploadFile, allowedTypes: string[] = ['image/jpeg']) => {
        return true;//allowedTypes.includes(type!);
    };
    const getUploadProps = (indexOfThatFile: number) => {
        const uploadProps = {
            multiple: false,
            beforeUpload: (file: UploadFile) => {
                const isAllowedType = validateFileType(file);
                if (!isAllowedType) {
                    message.error(`${file.name} is not JPEG file`);
                    return false;
                }
                setFileList((prev) => {
                    if (prev.length < maxCount) {
                        const clonedData = [...prev];
                        clonedData[indexOfThatFile] = file;
                        return clonedData;
                    }
                    return prev;
                });
                return false;
            },
            onRemove: (file: UploadFile) => {
                setFileList((prev) => {
                    const clonedData = [...prev];
                    clonedData[indexOfThatFile] = [];
                    return clonedData;
                });
            },
            fileList: fileList[indexOfThatFile] ? [fileList[indexOfThatFile]] : []
        } as UploadProps;
        return uploadProps;
    };

    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </div>
    );


    return (
        <Card title={<span style={{ color: "white" }}> Add Documents</span>}
              style={{ textAlign: "center" }}
              headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
            >
            <>
                {
                    initialValues.map((imageData: any, index) => {
                        if (!imageData || typeof imageData !== 'object')
                            return <></>
                        let path = imageData.base64Url ? imageData.base64Url : fileUploadPath + '/' + imageData.filePath.slice(7);
                        if (index % 3 === 0) {
                            return (
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    {/* {imageData.type.includes('image') ? <Image
                                        width={500}
                                        height={375}
                                        title={imageData.fileDescription}
                                        src={path}
                                    /> :
                                        <Card
                                            title={imageData.fileDescription}
                                            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }}
                                            bodyStyle={{ overflow: 'auto' }}
                                        ><embed src={path} width="500" height="375"
                                            type={imageData.type} /></Card>} */}
                                    <a href={`${path}`} target="_blank">{imageData.fileDescription}</a>
                                </Col>
                            );
                        } else {
                            return (
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                                    {/* {imageData.type.includes('image') ? <Image
                                        width={500}
                                        height={375}
                                        title={imageData.fileDescription}
                                        src={path}
                                    /> :
                                        <Card
                                            title={imageData.fileDescription}
                                            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }}
                                            bodyStyle={{ overflow: 'auto' }}
                                        ><embed src={path} width="500" height="375"
                                            type={imageData.type} /></Card>}
                                             */}
                                    <a href={`${path}`} target="_blank">{imageData.fileDescription}</a>
                                </Col>
                            );
                        }
                    }).reduce((r, element, index2) => {
                        index2 % 3 === 0 && r.push([]);
                        r[r.length - 1].push(element);
                        return r;
                    }, []).map((rowContent) => {
                        return <Row>{rowContent}</Row>;
                    })
                }
            </>
            <Form layout={layoutType} form={formRef} initialValues={{ filesData: initialValues.length !== 0 ? undefined : [''] }}>
                <Form.List name="filesData">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row key={field.key}>
                                    <Col
                                        xs={{ span: 22 }}
                                        sm={{ span: 22 }}
                                        md={{ span: 22 }}
                                        lg={{ span: 22 }}
                                        xl={{ span: 22 }}
                                    >
                                        <Row>
                                            {!disableDescription && <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }}
                                            >
                                                <Form.Item
                                                    label={fm({ id: "common.fileUploadForm.fileDescription", defaultMessage: 'File Description' })}
                                                    {...field}
                                                    name={[field.name, 'fileDescription']}
                                                    rules={[
                                                        {
                                                            message: fm({ id: "common.fileUploadForm.reqFileDescription", defaultMessage: 'Please Enter File Description' })
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder={fm({ id: `common.fileUploadForm.fileDescription`, defaultMessage: `File Description` })} autoComplete='off' />
                                                </Form.Item>
                                            </Col>}
                                            <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} xl={{ span: 11, offset: 2 }}
                                            >
                                                <Form.Item
                                                    label={fm({ id: "common.fileUploadForm.reqFile", defaultMessage: 'Select file to upload' })}
                                                    {...field}
                                                    name={[field.name, 'file']}
                                                    getValueFromEvent={normFile}
                                                >
                                                    {!isButtonType ? <Dragger {...getUploadProps(index)}>
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                        <p className="ant-upload-hint">
                                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                                            band files
                                                        </p>
                                                    </Dragger> : <Upload
                                                        style={{ width: 130, }}
                                                        multiple={false}
                                                        listType="text"
                                                        showUploadList
                                                        {...getUploadProps(index)}

                                                    >{uploadButton}</Upload>}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col xs={{ span: 2 }}
                                        sm={{ span: 2 }}
                                        md={{ span: 2 }}
                                        lg={{ span: 2 }}
                                        xl={{ span: 2 }}>

                                        {(fields.length - 1 === index && fields.length < 5) && <span style={{
                                            position: "absolute",
                                            marginTop: "33px",
                                            marginLeft: "15px"
                                        }}>

                                            <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                {/* <PlusCircleOutlined onClick={() => add()} /> */}
                                            </Tooltip> </span>}

                                        {/* {(index != 0 || initialValues.length != 0) && */}
                                        <span style={{
                                            position: "absolute",
                                            marginTop: "33px",
                                            marginLeft: "35px",
                                        }}>
                                            <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                            </Tooltip>

                                        </span>
                                        {/* } */}
                                    </Col>
                                </Row>
                            ))}

                            <Row>
                                <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    Add Documents <span>
                                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                            <PlusCircleOutlined onClick={() => {
                                                if (maxCount > fields.length) {
                                                    add()
                                                } else {
                                                    AlertMessages.getErrorMessage(`You can't add more than ${maxCount} fields`)
                                                }
                                            }} />
                                        </Tooltip>
                                    </span>

                                    {/* <Button onClick={() => {
                                        if (maxCount > fields.length) {
                                            add()
                                        } else {
                                            AlertMessages.getErrorMessage(`You can't add more than ${maxCount} fields`)
                                        }
                                    }} block>
                                        {fm({ id: "common.fileUploadForm.addButton", defaultMessage: 'Add New File' })}
                                    </Button> */}
                                </Col>
                                <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                            </Row>
                        </>)}
                </Form.List>
                {/* {typeof remarks !== 'undefined' && <Form.Item label="Remarks" name="remarks">
                    <TextArea rows={5} />
                </Form.Item>} */}
            </Form>
            {props?.children}
        </Card>
    )
}

export default FileUploadForm;