import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { IdProofsTypeDto } from '@gtpl/shared-models/masters';
import {IdproofsTypeService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";


import './id-proofs.css';

/* eslint-disable-next-line */
export interface IdProofsProps {
  idProofTypeData:IdProofsTypeDto;
  updateIdProofType:(idProofType:IdProofsTypeDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function IdProofs(props: IdProofsProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  const idproofsTypeService = new IdproofsTypeService()

  const createIdProof=(idProofTypeData:IdProofsTypeDto)=>{
    setDisable(true)
    idproofsTypeService.createIdProof(idProofTypeData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Id Proof Type Created Successfully');
        history.push("/id-proofs-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    form.resetFields();
    
  }

  const saveData = (values: IdProofsTypeDto) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values)
      props.updateIdProofType(values);
    }else{
      setDisable(false)
      console.log(values)
      createIdProof(values);
    }
  
  };

  return (
    <Card title={<span style={{color:'white'}}> Id Proofs</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/id-proofs-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.idProofTypeData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="idProofTypeId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row>
        
        <Col xs={{span:24}} sm={{span:24}} md={{ span: 8 }} lg={{ span: 8}} xl={{ span: 5}}>
            <Form.Item
                name="idProofType"
                label="Id Proof"
                rules={[
                  {
                    required: true,message:'Id Proof is required'
                    
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
      </Row>
     
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary"disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(!props.isUpdate) &&
         <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
         Reset
       </Button>
          }  
        </Col>
      </Form>
    </Card>
  );
}

export default IdProofs;