import React , {useState}from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export interface ScannerProps { handleScan: (value: any) => void}

export function Scanner(
    props: ScannerProps
) {
  const [data, setData] = useState<string>("Not Found");
  const [stopStream, setStopStream] = useState<boolean>(false);
  //const [scanResultWebCam, setScanResultWebCam] = useState('');

//   const handleScanWebCam = (result) => {
//     if (result) {
//         setScanResultWebCam(result);
//         props.handleScan(result)
//     }
// }

  return (
    <>
      <BarcodeScannerComponent
        width={500}
        height={500}
        stopStream={stopStream}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.getText());
            props.handleScan(result.getText())
            setStopStream(true);
          } else {
            setData("Not Found");
          }
        }}
      />
      <p>{data}</p>
    </>
  );
}

export default Scanner;