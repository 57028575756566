import { ContainerLogBookDTO } from "@gtpl/shared-models/logistics";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { useState } from "react";
import { useEffect } from "react";
import { LogBookItemsService } from '@gtpl/shared-services/logistics'
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";

/* eslint-disable-next-line */
export interface LogbookItemsGridProps {
    containerLogBookId:number;
}

export function LogbookItemsGrid(
  props: LogbookItemsGridProps
) {
    const [page, setPage] = React.useState(1);
    const [logBookItemsData, setLogBookItemsData] = useState<any[]>([])
    const service = new LogBookItemsService();
    useEffect(() => {
        getContainerLogBookItemDetails();
      }, []);
      const getContainerLogBookItemDetails= () => {
        service.getLogBookItemDetails({containerLogBookId: props.containerLogBookId}).then(res => {
          if (res.status) {
            setLogBookItemsData(res.data);
          } else {
            if (res.intlCode) {
                setLogBookItemsData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setLogBookItemsData([]);
          AlertMessages.getErrorMessage(err.message);
        })
      }
      const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        {
          title: 'Row',
          dataIndex: 'row',
          sorter: (a, b) => a.row.localeCompare(b.row),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Product SKU',
          dataIndex: 'variant',
          width:'120',
          sorter: (a, b) => a.variant.localeCompare(b.variant),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Brand',
          dataIndex: 'brandName',
          sorter: (a, b) => a.brandName.localeCompare(b.brandName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Cartons',
          dataIndex: 'cartons',
          sorter: (a, b) => a.cartons.localeCompare(b.cartons),
          sortDirections: ['descend', 'ascend'],
        },
        
      ];
      const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
    return (
        <>
             <Table
              rowKey={record => record.deptId}
              columns={columnsSkelton}
              scroll={{x:true}}
              dataSource={logBookItemsData}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
        </>
    )
}
export default LogbookItemsGrid;
