import { Button, Card,Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {EditOutlined,ClockCircleOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ContainerLogBookService} from '@gtpl/shared-services/logistics'
import { ColumnProps } from 'antd/lib/table';
import { ContainerLogBookDTO } from '@gtpl/shared-models/logistics';
import moment from 'moment';
import LogbookItemsGrid from './log-book-items-grid';
/* eslint-disable-next-line */
export interface ContainerLogBookGridProps {}

export function ContainerLogBookGrid(
  props: ContainerLogBookGridProps
) {
    const [page, setPage] = React.useState(1);
    const [containerLogBook, setContainerLogBook] = useState<ContainerLogBookDTO[]>([]);
    const [selectedContainerLog, setSelectedContainerLog] = useState<ContainerLogBookDTO>(undefined);
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [expandedIndex, setExpandedIndex] = useState([]);

    const service=new ContainerLogBookService();


    useEffect(() => {
        getContainerLogBookDetails();
      }, []);
    
      /**
       * 
       */
      const getContainerLogBookDetails= () => {
        service.getContainerLogDetails().then(res => {
          if (res.status) {
            setContainerLogBook(res.data);
          } else {
            if (res.intlCode) {
                setContainerLogBook([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setContainerLogBook([]);
          AlertMessages.getErrorMessage(err.message);
        })
      }
    const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
    const openFormWithData=(viewData: ContainerLogBookDTO)=>{
        console.log(viewData)
        setSelectedContainerLog(viewData);
        setFormVisible(true);
      }
    const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        {
          title: 'PO No',
          dataIndex: 'poNumber',
          sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Container No',
          dataIndex: 'containerNo',
          sorter: (a, b) => a.containerNo.localeCompare(b.containerNo),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'vehicle No',
          dataIndex: 'vehicleNo',
          sorter: (a, b) => a.vehicleNo.localeCompare(b.vehicleNo),
          sortDirections: ['descend', 'ascend'],
          render: (status, rowData) => (
            <>
              {rowData.vehicleNo? rowData.vehicleNo : '-'}

            </>
          ),
        },
        {
          title: 'Loading Date',
          dataIndex: 'loadingDate',
          sorter: (a, b) => a.loadingDate.localeCompare(b.loadingDate),
          sortDirections: ['descend', 'ascend'],
          render: (status, rowData) => (
            <>
              {rowData.loadingDate?moment(rowData.loadingDate).format("YYYY-MM-DD") : null}

            </>
          ),
        },
        {
          title: 'Start Time',
          dataIndex: 'loadingStartTime',
          sorter: (a, b) => a.loadingStartTime.localeCompare(b.loadingStartTime),
          sortDirections: ['descend', 'ascend'],
          render: (status, rowData) => (
            <>
              <ClockCircleOutlined />{" "+rowData.loadingStartTime}

            </>
          ),
        },
        {
          title: 'End Time',
          dataIndex: 'loadingEndTime',
          sorter: (a, b) => a.loadingEndTime.localeCompare(b.loadingEndTime),
          sortDirections: ['descend', 'ascend'],
          render: (status, rowData) => (
            <>
              <ClockCircleOutlined />{" "+ rowData.loadingEndTime}

            </>
          ),
        },
        {
          title:`Action`,
          dataIndex: 'action',
          render: (text, rowData) => (
            <span>         
                <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                  onClick={() => {
                    if (rowData.isActive) {
                      openFormWithData(rowData);
                    } else {
                      AlertMessages.getErrorMessage('You Cannot Edit Deactivated Departments');
                    }
                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                />
            </span>
          )
        }
      ];
      const renderItems = (record: ContainerLogBookDTO, index, indent, expanded) => {
        console.log(record);
        return <LogbookItemsGrid containerLogBookId={record.containerLogBookId} />
      }
      const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
          expandedRows.push(record.containerLogBookId);
          setExpandedIndex(expandedRows);
        } else {
          setExpandedIndex(expandedRows);
        }
      }
    
    return (
      <>
        {formVisible ? <Redirect to={{ pathname: "/container-log-book", state: selectedContainerLog }} /> : null}
        
        <Card title={<span style={{color:'white'}}>Container Log Book</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/container-log-book' ><Button className='panel_button' >Create </Button></Link>}
        
        >
         <br></br>
         {/* <Row gutter={40} >
          <Col>
              <Card title={'Total Departments: ' + departmentsData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
              </Col>
              <Col>
               <Card title={'Active: ' + departmentsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
              </Col>
              <Col>
               <Card title={'In-Active: ' + departmentsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
              </Col>
              </Row>
              <br></br> */}
              <Table
              rowKey={record => record.containerLogBookId}
              columns={columnsSkelton}
              scroll={{x:true}}
              dataSource={containerLogBook}
              expandedRowRender={renderItems}
              expandedRowKeys={expandedIndex}
              onExpand={setIndex}
              expandIconColumnIndex={6}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
         </Card>
         </>
      );
}
export default ContainerLogBookGrid;
