import React, { useEffect, useRef, useState } from 'react';

import './pages-asset-management-asset-management-components-dc-grid.css';
import { Button, Card, Switch, Popconfirm, Divider, Table, Drawer, Form, Input, Col, Row, Tag, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UndoOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { RightSquareOutlined, EditOutlined, CloseCircleOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { AssetLocationService } from "../../../../../../shared-services/asset-management/src/lib/asset-location";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLocationDtoDefault, AssetsLocationDto } from "../../../../../../shared-models/asset-management/src/lib/asset-location/asset-location.dto";
import { AssetLocationResponse } from "../../../../../../shared-models/asset-management/src/lib/asset-location/asset-location-active-inactive";
import { locationForm } from '../../../dc-form/src/lib/pages-asset-management-asset-management-components-dc-form';
import { LocationDto } from '@gtpl/shared-models/masters';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';

// import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { from } from 'rxjs';
import { UnitcodeService } from '@gtpl/shared-services/masters';
/* eslint-disable-next-line */
export interface AssetLocationProps { }

export function AssetLocation(
  props: AssetLocationProps
) {

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [page, setPage] = useState<number>(1);
  const { Option } = Select;
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [locationData, setLocationData] = useState<AssetsLocationDto[]>([])
  const [selectedLocationData, setSelectedLocationData] = useState<any>([])
  const [form] = Form.useForm()
  const plantsService = new UnitcodeService();
  const [unitData, setunitData] = useState<any[]>([]);
  const service = new AssetLocationService();
  const [selectedunit, setUnit] = useState<number>(0)
  const [plantName, setPlantName] = useState<any>([]);
  const role = JSON.parse(localStorage.getItem('role'))


  useEffect(() => {
    getAllPlants();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plant: Number(localStorage.getItem('unit_id')) })
    }

    getAllAssetLocation();
  }, [])

  const getAllAssetLocation = () => {
    const req = new LocationByPlant();
    // if(form.getFieldValue('unitId') !== undefined){
    //   req.plant = form.getFieldValue('unitId')
    // }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plant = Number(localStorage.getItem('unit_id'));
    } else {
      req.plant = form.getFieldValue('plant')
    }
    service.getAllAssets(req).
      then(res => {
        if (res.status) {
          setLocationData(res.data)
          AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
  }

  const actionHandler = (data) => {
    if (data.isActive == true) {
      data.isActive = false
    } else {
      data.isActive = true
    }
    console.log(data, '---------data')
    activateDeactivateLocation(data);
  }

  const activateDeactivateLocation = (data: AssetLocationResponse) => {
    service.activateOrDeactivate(data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
        getAllAssetLocation();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateLocation = (Data) => {
    service.update(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully')
        getAllAssetLocation();
        setDrawerVisible(false);
        form.resetFields();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const openFormWithData = (viewData: AssetsLocationDto) => {
    setDrawerVisible(true);
    // setSelectedLocationData(viewData);
    form.setFieldsValue({
      locationId: viewData.locationId
    })
    form.setFieldsValue({
      locationName: viewData.locationName
    })
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
    form.resetFields();
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }



  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      key: "sno",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      sorter: (a, b) => String(a.plant)?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),

    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('locationName'),
    },
    {
      title: 'Location Code',
      dataIndex: 'locationId',
      // sorter: (a, b) => a.locationId?.localeCompare(b.locationId),
      sorter: (a, b) => a.locationId - b.locationId,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('locationId'),
    },


    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'InActive',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        console.log(value, '-------value')
        console.log(record.isActive, '----isactive')
        // === is not work
        return record.isActive === value;
      },

    },

    {
      title: 'Action',
      dataIndex: 'action',

      render: (text, rowData) => (

        <span>

          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Location');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => {
            actionHandler(rowData)
          }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Item ?'
                : 'Are you sure to Activate Item ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }

  ];


  const handleUnit = (value) => {
    setUnit(value);
    getAllAssetLocation()
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Asset-Location</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/location-form' ><Button className='panel_button' >Create </Button></Link>}>  <br></br>
      <Form form={form}>
        <Row gutter={40}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="plant"
              label="Plant"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
              initialValue={Number(localStorage.getItem("unit_id"))}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}


              >
                {plantName.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col  style={{marginTop:'2%'}}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block
              onClick={() => searchHandler()}
              // style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>
          </Col> */}
          {/* <Col  style={{marginTop:'2%'}}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}
            >
              Reset
            </Button>
          </Col> */}
        </Row>
      </Form>

      <br />
      <Row gutter={40}>
        <Col>
          <Card title={'Total Locations: ' + locationData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + locationData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active: ' + locationData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>


      <br></br>< br />


      <Table columns={columns} dataSource={locationData} />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card>
          <Form form={form} onFinish={updateLocation}>
            <Row>
              <Col>
                <Form.Item label='Location Code' name='locationId'
                  rules={[{
                    required: true,
                  }]}
                  style={{ display: 'none' }}
                >
                  <Input hidden />
                </Form.Item>
                <Form.Item label='Location' name='locationName'
                  rules={[{
                    required: true,
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Form.Item>
                  <Button type='primary' htmlType='submit'>Submit</Button>
                </Form.Item>
                {/* {[] && <Form.Item>
          <Button>Reset</Button>
        </Form.Item>} */}
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </Card>
  );
}


export default AssetLocation;
