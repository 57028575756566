import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {FactoriesInput,PlantInvoiceDetailsModel,PlantInvoiceDetailsRequest,UnitsOfWeightInput,} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined} from '@ant-design/icons';

/* eslint-disable-next-line */
export interface Eform13Props { 
  saleOrderId:number;
}

export function Eform13(props: Eform13Props) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;


  let totalCases = 0;
  let totalLbWeight = 0;
  let totalAmount = 0;


  const getData = (saleOrderId) => {
    const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(saleOrderId)
        if (res.status) {
          console.log(res.data);
          setPlantDetails(res.data);
        } else {
          if (res.intlCode) {
            setPlantDetails(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  useEffect(() => {
    if(props.saleOrderId){
      getData(props.saleOrderId);
  }
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
    const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };
  // return 'test';

  return (
    <>
    {plantDetails?

      <html>
        <head></head>
        <body id="printme">
          <h1 style={{ textAlign: 'center', fontSize: '32px' }}>e-Form 13 
          {/* <Button onClick={() => window.print()} className='noprint' style={{float:'right', backgroundColor:'#69c0ff'}}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button> */}
          </h1>

          <table className={'ta-b'} style={{ width: '100%'}}>
            <tr className={'ta-b'}>
                <td className={'ta-b'}><b>TRANSACTION No.</b></td>
                <td className={'ta-b'} style={{textAlign:'right'}}>1031353071</td>
                <td className={'ta-b'}><b>DATE & TIME</b> </td>
                <td className={'ta-b'}>16-04-2021 17:50</td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>VIA. No</b></td>
                <td className={'ta-b'} style={{textAlign:'right'}}>21015</td>
                <td className={'ta-b'}><b>VSL NAME</b> </td>
                <td className={'ta-b'}>TRF KAYA</td>
            </tr>
          </table>
          <table className={'ta-b'} style={{ width: '100%' }}>
          <tr>
                <td className={'ta-b'}><b>CTR No</b></td>
                <td className={'ta-b'}>CGMU5570328</td>
                <td className={'ta-b'}><b>LINE</b> </td>
                <td className={'ta-b'}>CMA</td>
                <td className={'ta-b'}><b>ISO CODE</b> </td>
                <td className={'ta-b'} >4430</td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>CATEGORY</b></td>
                <td className={'ta-b'}>EXPORT</td>
                <td className={'ta-b'}><b>WEIGHT</b> </td>
                <td className={'ta-b'}>28485</td>
                <td className={'ta-b'}><b>STATUS</b> </td>
                <td className={'ta-b'}>FCL</td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>POD</b></td>
                <td className={'ta-b'}>SIN</td>
                <td className={'ta-b'}><b>GROUP</b> </td>
                <td className={'ta-b'}>FAC</td>
                <td className={'ta-b'}><b>COMMODITY</b> </td>
                <td className={'ta-b'}>FROZEN SHRIMPS</td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>SEAL No.</b></td>
                <td className={'ta-b'}></td>
                <td className={'ta-b'}><b>S.B.No</b> </td>
                <td className={'ta-b'}></td>
                <td className={'ta-b'}><b>BOOKING No.</b> </td>
                <td className={'ta-b'}></td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>TEMP REQ</b></td>
                <td className={'ta-b'}>-18</td>
                <td className={'ta-b'}><b>IMO CODE</b> </td>
                <td className={'ta-b'}></td>
                <td className={'ta-b'}><b>OOG DIM..</b> </td>
                <td className={'ta-b'}></td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>REMARKS</b></td>
                <td className={'ta-b'} colSpan={5}></td>
            </tr>
          </table>
          <table className={'ta-b'} style={{ width: '100%' }}>
            <tr className={'ta-b'}>
                <td className={'ta-b'}><b>Truck No.</b></td>
                <td className={'ta-b'} style={{textAlign:'right'}}></td>
                <td className={'ta-b'}><b>Driver Name</b> </td>
                <td className={'ta-b'}></td>
            </tr>
            <tr>
                <td className={'ta-b'}><b>VGM Weight(Kgs.)</b></td>
                <td className={'ta-b'} style={{textAlign:'right'}}>28485</td>
                <td className={'ta-b'}><b>VGM Verifier</b> </td>
                <td className={'ta-b'}>SHIPPER</td>
            </tr>
          </table>
          <b>This is system generated Form-13 which does not require sign, and stamp</b><br/>
          <b>We confirm that the container details mentioned above are having Shipping Bill with LEO / Annexure C</b><br/><br/><br/>
          <h2>CMA CGM AGENCIES INDIA PVT LTD</h2>
        </body>
      </html>
      :<Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height:'100' }}>e-Form 13</span>} 
      // extra={<> <Button href="/#/document-register" style={{color:'black'}}>Back</Button></>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}}><span style={{paddingTop:'10px' , textAlign:'center'}} >No data found</span></Card>}
</>
  );
}

export default Eform13Props;