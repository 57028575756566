import React, { useEffect, useRef, useState } from 'react';
import {Button, Card, Col, Divider, Form, Input, Modal, Popconfirm, Row, Switch} from 'antd';
import {SearchOutlined,CheckOutlined,CloseOutlined,RightSquareOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {Link, useHistory, useParams} from 'react-router-dom';
import './pages-asset-management-asset-management-components-location-audit-grid.css';
import Table, { ColumnProps } from 'antd/lib/table';
import TextArea from 'antd/lib/input/TextArea';
import {LocationAuditService} from 'libs/shared-services/asset-management/src/lib/location-audit';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LocationAuditDto } from 'libs/shared-models/asset-management/src/lib/open-assets/location-audit.dto';
import { LocationById } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-id.dto';


/* eslint-disable-next-line */
export interface LocationAuditGridProps {}

export function LocationAuditGrid(
  props: LocationAuditGridProps
) {

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const[btndisable,setBbtnDisable] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(null);
  const [page, setPage] = React.useState(1);
  const [openModal,setOpenModel] = useState<boolean>(false);
  const [assetsdata,setAssetsData] = useState<any[]>([]);
  const [btTwoDisable,setBtnTwoDisable] = useState<boolean>(true)
  let {id,auditId} = useParams<any>();
  const history = useHistory();



  const service = new LocationAuditService();

  let working = 0;
  let NotWorking = 0;

  useEffect(()=>{
    getAssetsByLocation(id)

  },[id])

  const getAssetsByLocation = (id) => {
    const req = new LocationById(id);
    service.getAssetsByLocation(req).then(res => {
      if(res.status){
        setAssetsData(res.data)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const update = (locationId) => {
    const req = new LocationAuditDto(null,locationId,null,null,null,null,null,null,null,null,working,NotWorking,'Completed',null,null,null);
    service.updateLocationAudit(req).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  
  const updateworkingStatus = () => {
    console.log(working,'------------working')
    console.log(NotWorking,'---------notworking')
    const req = new LocationAuditDto(auditId,id,null,null,null,null,null,null,null,null,working,NotWorking,'Completed',null,null,true);
    service.updateLocationAudit(req).then(res => {
      if(res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage)
        history.push('/location-audit-calender')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const notWorkingHandler = (rowData) => {
    NotWorking += 1
    if (NotWorking >= 1 || working >= 1){
      setBbtnDisable(true)
      setBtnTwoDisable(false)
      // setBtnTwoDisable(t)
    }
  }

  const workingHandler = (rowData) => {
    working += 1
    if (working >= 1 || NotWorking >= 1){
      setBbtnDisable(true)
      setBtnTwoDisable(false)
      console.log(working+'PPPPPPPPPPP')
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

function countAdd(rowData){
  
  if (working >= 1 || NotWorking >= 1){
    working -= 1
    NotWorking -= 1
    console.log(working)
    setBbtnDisable(false)
    setBtnTwoDisable(true)
  }

}
// function addWorking(rowData){
//     if(working==1 || NotWorking==1){
//       setBbtnDisable(true)
//     }
// }

// function addnotWorking(rowData){
//   if(working==1 || NotWorking==1){
//     setBbtnDisable(true)
//   }
// }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: '50px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      ...getColumnSearchProps('location_name'),
    },
    {
      title: 'Asset Mode',
      dataIndex: 'asset_mode',
      ...getColumnSearchProps('asset_mode'),

    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      ...getColumnSearchProps('asset_type'),

    },
    {
      title: 'Asset Code',
      dataIndex: 'assets_code',
      ...getColumnSearchProps('assets_code'),
    },

    {
      title: 'Asset Name',
      dataIndex: 'item_name',
      ...getColumnSearchProps('item_name'),
    },

    {
      title: 'Asset Category',
      dataIndex: 'item_sub_category',
      ...getColumnSearchProps('item_sub_category'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (text, rowData) => {
        return (
          <>
          <Popconfirm title={'Are you sure this Asset is Working?'} onConfirm={e => workingHandler(rowData)}>
            <button><CheckOutlined style={{color:'green'}} disabled={btndisable}/></button>
          
          </Popconfirm>
          <Divider type='vertical'/>
          <Popconfirm title={'Are you sure this Asset is Not Working?'} onConfirm={e => notWorkingHandler(rowData)}>
            <button><CloseOutlined style={{color:'red'}} disabled={btndisable}/></button>
          
          </Popconfirm>
          </>
        ) }
    },
    {
      title: 'Revert',
      dataIndex: 'revert',
      align: 'center',
      render: (text, rowData) => {
        return (
          <>
          <Popconfirm title={'Do you want to enable?'}>
          <button onClick={()=>countAdd(rowData)} >revert</button>

          </Popconfirm>
      
          </>
        ) }
    }

  ]

  return (
    <>
    <Card title={<span style={{ color: 'white' }}> Location Audit</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} 
        // extra={<Link to='/location-audit-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} 
        extra={<Button className='panel_button' onClick={() => updateworkingStatus()}>Submit </Button>} 

    >
       
    <Table columns={columns} dataSource={assetsdata}
    />
    <Modal
      className='qr-containerrrrr'
      key={'modalll' + Date.now()}
      width={'95%'}
      style={{ top: 30, alignContent: 'right' }}
      visible={openModal}
      onCancel={() => { setOpenModel(false) }}
      onOk={() => {}}
      title={<React.Fragment>
      </React.Fragment>}
    >
      <Form>
        <Row>
          <Col>
            <Form.Item label='Reason' name='reason'>
              <TextArea rows={2}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
    </Modal>

    </Card>
    
    </>
  );
}

export default LocationAuditGrid;
