import React, { useEffect, useState,useRef } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Select, Tabs , DatePicker, Alert,} from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {AssetAssignmentService} from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-code.dto";
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import moment from "moment";
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { Excel } from 'antd-table-saveas-excel';
import { AssetByDate } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-date.dto";
import {AssetRaciReport} from 'libs/shared-models/asset-management/src/lib/open-assets/raci-report.dto';
import {VendorsDto} from '@gtpl/shared-models/masters';
import {VendorsService} from '@gtpl/shared-services/masters';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import {EmployeeService} from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import {ItemSubCategoryService, UnitcodeService} from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetTypeEnum } from "libs/shared-models/common-models/src/lib/enum/asset-type.enum";

const {Option} = Select;
import Highlighter from 'react-highlight-words';


/* eslint-disable-next-line */
export interface AssetRACIReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const AssetRACIReport = (props: AssetRACIReportProps) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const onSearch = (value: string) => console.log(value);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [assetsData, setAssetsData] = useState<AssetDto[]>([])
  const [assetTransData,setAssetTransData] = useState<AssetDto[]>([]); 
 const {RangePicker} = DatePicker;
 const [vendorsData, setVendorsData] = useState<VendorsDto[]>([]);
 const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);

 const vendorsService = new VendorsService();
 const service = new AssetAssignmentService();
 const assetService = new AssetService();
 const employeeService = new EmployeeService();

 const locationService = new AssetLocationService();
 const [locationData,setLocationData] = useState<any[]>([]);
 const plantsService = new UnitcodeService();
 const [plantName,setPlantName]= useState<any>([]);
 const [itemCategory,setItemCategory] = useState([]);
 const itemSubCategoryService = new ItemSubCategoryService();
 const [length,setLength] = useState([]);
 const role = JSON.parse(localStorage.getItem('role'));
 const [searchText, setSearchText] = useState('');
 const [searchedColumn, setSearchedColumn] = useState('');
 const searchInput = useRef(null);

 let diff = 0;
 let date;
 let todate;

  useEffect(() => {
    getAllVendors();
    employeeDetails();
    getAssetCode();
    //getAllRaciInformation();
    getItemCategory();
    getAllPlants();
    searchHandler();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    } 
    getLocations() 
  },[])

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
    const getAllPlants = () => {
      plantsService.getAllPlants().then(res => {
        if(res.status){
          setPlantName(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
  
    const getLocations = () => {
      const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
      locationService.getAllAssets(req).then(res => {
        if(res.status){
          setLocationData(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }

  const getAssetCode = () => {
    const req = new AssetByStatus()
    req.plantId = Number(localStorage.getItem('unit_id'));
    assetService.getallAssets(req).then((res) => {
      if(res.status) {
        setAssetTransData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllVendors= () => {
    vendorsService.getAll().then((res) => {
      if (res.status) {
        setVendorsData(res.data);
      } else {
        if (res.intlCode) {
            setVendorsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setVendorsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res)=>{
      // console.log(props?.assetData)
      if(res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const resetHandler = () => {
    form.resetFields();
    setAssetsData([])
   // getAllRaciInformation()
   searchHandler()
  }

  const searchHandler = () => {
    console.log('-------------------------')
    // const assetId = form.getFieldValue('assetsCode') ? form.getFieldValue('assetsCode') : 'null';
    // const employeeId = form.getFieldValue('employeeId')? form.getFieldValue('employeeId') : 'null';
    // const ownerOfAsset = form.getFieldValue('ownerOfAsset') ? form.getFieldValue('ownerOfAsset') : 'null';
    // const assetSupplier  = form.getFieldValue('assetSupplier') ? form.getFieldValue('assetSupplier') : 'null';
    // const reportingManager = form.getFieldValue('reportingManager') ?form.getFieldValue('reportingManager') : 'null';
    const req = new AssetRaciReport()
    if(form.getFieldValue('assetsCode') !== undefined){
      req.assetId = form.getFieldValue('assetsCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plant = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plant = form.getFieldValue('plantId')
      }};
    if(form.getFieldValue('location') !== undefined){
      req.location = form.getFieldValue('location')
    }
    if(form.getFieldValue('assetCategory') !== undefined){
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if(form.getFieldValue('assetType') !== undefined){
      req.assetType = form.getFieldValue('assetType')
    }
    if(form.getFieldValue('employeeId') !== undefined){
      req.employeeId = form.getFieldValue('employeeId')
    }
    if(form.getFieldValue('ownerOfAsset') !== undefined){
      req.ownerOfAsset = form.getFieldValue('ownerOfAsset')
    }
    if(form.getFieldValue('assetSupplier') !== undefined){
      req.assetSupplier = form.getFieldValue('assetSupplier')
    }
    if(form.getFieldValue('status') !== undefined){
      req.status = form.getFieldValue('status')
    }
    assetService.getRaciReport(req).then(res => {
      if(res.status) {
        setAssetsData(res.data[0])
        setLength(res.data[0].length)
        if(res.data[0].length > 0){
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  // const getAllRaciInformation = () => {
  //   assetService.getAllRaciReport().then((res)=>{
  //     // console.log(props?.assetData)
  //     if(res.status) {
  //       console.log(res.data,'-------------dataa')
  //       setAssetsData(res.data[0])
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }

    const columns: ColumnProps<any>[] = [
      {
        title: "S.No",
        key: "sno",
      //  width: "30px",
        responsive: ["sm"],
        render: (text, object, index) => (page - 1) * pageSize + (index + 1)
      },
      {
        title: "Plant",
        dataIndex: "plant",
      //  width: 130,
        align:"left",
        ...getColumnSearchProps('plant'),
      },
      {
        title: "Location",
        dataIndex: "location_name",
       // width: 130,
         align:"left",
         ...getColumnSearchProps('location_name'),
        render:(text,record) => {
          return(
            <>
            {record.location_name ? record.location_name : '-'}
            </>
          )
        }
      },
      {
        title: "Asset Type",
        dataIndex: "asset_type",
        align:"left",
        width:120,
        render:(text,record) => {
          return (
            <>
              {record.asset_type ? record.asset_type : '-'}
            </>
          )
        },
        filters: [
          {
            text: 'MOVABLE',
            value: 'movable',
          },
  
          {
            text: 'FIXED',
            value: 'FIXED',
          },
          {
            text: 'SOFTASSETS',
            value: 'softassets',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.asset_type === value;
        },
  
        ...getColumnSearchProps('asset_type')
      },
      {
        title: "Asset Category",
        dataIndex: "asset_category",
        align:"left",
        width:120,
        // fixed:"left",
        sorter: (a, b) => a.asset_category?.localeCompare(b.asset_category),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('asset_category')
      },
      {
        title: "Asset Name",
        dataIndex: "assets_name",
       // width: 150,
         align:"left",
         sorter: (a, b) => a.assets_name?.localeCompare(b.assets_name),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assets_name')
      },
      {
        title: "Asset Code",
        dataIndex: "assets_code",
       // width: 130,
        align:"left",
        sorter: (a, b) => a.assets_code?.localeCompare(b.assets_code),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assets_code')
      },
      {
        title: "Invoice Number",
        dataIndex: "asset_invoice_no",
        align:"left",
        width:120,
        render:(text,record) => {
          return (
            <>
              {record.asset_invoice_no ? record.asset_invoice_no : '-'}
            </>
          )
        },
        // ...getColumnSearchProps('currentLocation')
      },
      {
        title: "Serial Number",
        dataIndex: "asset_serialno",
        align:"left",
        width:120,
        render:(text,record) => {
          return (
            <>
              {record.asset_serialno ? record.asset_serialno : '-'}
            </>
          )
        },
        // ...getColumnSearchProps('currentLocation')
      },
      
      {
        title:'Status',
        dataIndex:'status',
       // width:130,
        align:'left',
        sorter: (a, b) => a.status?.localeCompare(b.status),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('status'),
        render:(text,record) => {
          return(
            <>
            {record.status ? record.status : '-'}
            </>
          )
        }
      },
      {
        title: "Responsible",
        dataIndex: "responsible",
        //width: 130,
         align:"left",
         ...getColumnSearchProps('responsible'),
        render:(text,record) => {
          return(
            <>
            {record.responsible ? record.responsible : 'NA'}
            </>
          )
        }
      },
      {
        title: "Accountable",
        dataIndex: "accountable",
        //width: 130,
         align:"left",
         ...getColumnSearchProps('accountable'),
        render:(text,record) => {
          return(
            <>
            {record.accountable ? record.accountable : 'NA'}
            </>
          )
        }
      },
      {
        title: "Consulted",
        dataIndex: "consulted",
       // width: 130,
         align:"left",
         sorter: (a, b) => a.consulted?.localeCompare(b.consulted),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('consulted'),
        render:(text,record) => {
          return(
            <>
            {record.consulted ? record.consulted : 'NA'}
            </>
          )
        }
      },
      {
        title: "Informed",
        dataIndex: "informed",
       // width: 130,
       align:"left",
        render:(text,record) => {
          return(
            <>
            {record.informed ? record.informed : 'NA'}
            </>
          )
        }
      },
      
      {  title: "Asset Age",
      dataIndex: "assetAge",
     // width: 130,
       align:"right",
       sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
       sortDirections: ['descend', 'ascend'],
      render:(text,record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD')); 
        diff = (todate - date) / 864e5;
        return(
          <>
          {record.date_of_purchased ? diff : null}
          </>
        )
      }
    },
      
    ];
   
  
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const exportedData = [];
  const excelData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Plant', dataIndex: 'plant',render:(text,record) => {return record.plant ? record.plant : '-'} },
    { title: 'Location', dataIndex: 'location_name',render:(text,record) => {return record.plant ? record.plant : '-'} },
    { title: 'Asset Type', dataIndex: 'asset_type',render:(text,record) => {return record.asset_type ? record.asset_type : '-'} },
    { title: 'Asset Category', dataIndex: 'asset_category',render:(text,record) => {return record.asset_category ? record.asset_category : '-'} },
    { title: 'Asset Name', dataIndex: 'assets_name',render:(text,record) => {return record.assets_name ? record.assets_name : '-'} },
    { title: 'Asset Code', dataIndex: 'assets_code',render:(text,record) => {return record.assets_code ? record.assets_code : '-'} },
    { title: 'Invoice Number', dataIndex: 'asset_invoice_no',render:(text,record) => {return record.asset_invoice_no ? record.asset_invoice_no : '-'} },
    { title: 'Serial Number', dataIndex: 'asset_serialno',render:(text,record) => {return record.asset_serialno ? record.asset_serialno : '-'} },
    { title: 'Status', dataIndex: 'status',render:(text,record) => {return record.status ? record.status : '-'} },
    { title: 'Asset Age', dataIndex: 'assetAge',render:(text,record) => {
      todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      date = moment(moment(record.date_of_purchased).format('YYYY-MM-DD')); 
      diff = (todate - date) / 864e5;
      return(
        <>
        {record.date_of_purchased ? diff : null}
        </>
      )
    }},
    { title: 'Responsible', dataIndex: 'responsible',render:(text,record) => {return(<>{record.responsible ? record.responsible : '-'}</>)} },
    {title: 'Accountable', dataIndex: 'accountable',render:(text,record) => {return(<>{record.accountable ? record.accountable : '-'}</>)}},
    {title: 'Consulted', dataIndex: 'consulted',render:(text,record) => {return(<>{record.consulted ? record.consulted : '-'}</>)}},
    { title: 'Informed', dataIndex: 'informed',render:(text,record) => {return(<>{record.informed ? record.informed : '-'}</>)} },
   
  ];


  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('RACI Matrix Report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('AssetsRACIReport.xlsx');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>RACI Matrix Report</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} 
    extra={assetsData.length > 0 ? (
      <>
        <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
      </>
    ) : (<></>)}  >
      <br/><br/>
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={[24,24]}>
        <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5}}
            lg={{ span: 5}}
            xl={{ span: 6}} >
            <Form.Item name="assetsCode" label='Asset Code'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetTransData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetId}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
               defaultValue={'All'}
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
            <Col span={4}>
          <Form.Item label='Plant' name='plantId' >
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
            showSearch
            allowClear>
              {/* <Option key={'all'} value={''}>All</Option> */}
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'All'}
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col   span={5}>
            <Form.Item name="employeeId" label='Responsible'>
            <Select
                 placeholder='Responsible'
                 optionFilterProp="children"
                 filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                allowClear
                >
            {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
            })}
           </Select>
            </Form.Item>
          </Col>
          <Col  span={5}>
            <Form.Item name="ownerOfAsset" label='Accountable'>
            <Select 
            showSearch
            optionFilterProp="children"
            placeholder='Accountable'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
            })}
          </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="assetSupplier" label="Consulted">
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Consulted'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
           {vendorsData.map((vendors) => {
              return <Option key={vendors.vendorId} value={vendors.vendorName}>{vendors.vendorName}</Option>
            })}
          </Select>
            </Form.Item>
          </Col>
          <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5}}
            xl={{ span: 5}}>
          <Form.Item label='Status' name='status'>
           
            <Select 
              showSearch
              defaultValue={'All'}
              allowClear
              //onChange={onStatusSearch}
            >
              <Option key='all' value=''>All</Option>
              <Option key='INSTOCK' value='INSTOCK'>INSTOCK </Option>
              <Option key='INUSE' value='INUSE'>INUSE</Option>
              <Option key='UNDERMAINTENANCE' value='UNDERMAINTENANCE'>UNDER MAINTENANCE</Option>
              <Option key='SERVICE' value='SERVICE'>SERVICE</Option>
              <Option key='INTRANSIT' value='INTRANSIT'>INTRANSIT</Option>
              <Option key='DAMAGED' value='DAMAGED'>DAMAGED</Option>
              <Option key='RETIRED' value='RETIRED'>RETIRED</Option>
              <Option key='SOLD' value='SOLD'>SOLD</Option>
            </Select>
          </Form.Item>
          </Col>
          {/* <Col  
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}>
            <Form.Item name="reportingManager">
            <Select 
            showSearch
            optionFilterProp="children"
            placeholder='Informed'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</Option>
            })}
          </Select>
            </Form.Item>
          </Col> */}
          {/* </Row>
          <Row> */}
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType'>
          <Select 
              placeholder='assetType'
              showSearch
              defaultValue={'All'}
              //onChange={onStatusSearch}
              allowClear
            >
              <Option key='all' value=''>All</Option>
              {Object.values(AssetTypeEnum).map((i) => {
                return (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          </Col>
          <Col style={{marginTop:'2%'}}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block
              onClick={() => searchHandler()}
              style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>
            </Col>
            <Col style={{marginTop:'2%'}}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}
            >
              Reset
            </Button>
          </Col>
          </Row>
          <br></br>

        <Col span={3} style={{marginLeft:'83%',paddingBottom:'1px'}}>
            <Button >Total no of Records:{length}
            </Button>
            </Col>
      </Form>
    {/* <Form layout="horizontal" form ={form} onFinish={searchHandler}>
      <Row style={{ marginLeft: '1%' }}>
      <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%'}}>
          <Form.Item name="assetsCode" label="Asset Code">
          <Select 
            showSearch
            optionFilterProp="children"
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.assetsCode} -- ${assets.assetsName}`}</Option>
            })

            }
          </Select>
          </Form.Item>
        </Col>
        <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%',marginLeft:'2%'}}>
        
            <Space direction="vertical" size={12}>
           <RangePicker />
           </Space>
          
          </Col>
          <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%'}}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            style={{ marginRight: 2, width: 100 }}
          >
            Search
          </Button>

          <Button
          onClick={() => getAllData()}
            icon={<UndoOutlined />}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
   */}
   {assetsData.length > 0 ? (<>
    <Table
        columns={columns}
        scroll={{ x: 1600,y:500}}
        dataSource={assetsData}
        pagination={{
          onChange(current, pageSize) {
            setPage(current);
            setPageSize(pageSize)
          }
        }}
        onChange={onChange}
      /> </>):(
       
          <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
        )}  
     
     
  </Card>

  );
};
export default AssetRACIReport;

