import { ConsumptionReportRequest } from "@gtpl/shared-models/procurement-management";
import { AssetService } from "@gtpl/shared-services/asset-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Table, TableColumnProps } from "antd";
import { Excel } from "antd-table-saveas-excel";
import {  DownloadOutlined } from '@ant-design/icons';
import Column from "antd/lib/table/Column";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import React, { useEffect, useState } from "react";




export interface CategoriesWiseAmcInsCountProps { }


export function CategoriesWiseAmcInsCount(props: CategoriesWiseAmcInsCountProps) {

    const service = new AssetService();
    const [assetCount, setAssetCount] = useState<any>([])

    useEffect(() => {
        categoryWiseData()
    }, [])

    function categoryWiseData() {
        service.getCategoryWiseData().then(res => {
            if (res.status) {
                setAssetCount(res.data)
            } else {
                setAssetCount([])
            }
        })
    }
    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('assetCount')
            .addColumns(data)
            .addDataSource(assetCount, { str2num: true })
            .saveAs('asset-Category-wise-count.xlsx');
    }
    const data: any[] = [
        {
            title: 'S NO',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'CATEGORY ',
            dataIndex: 'itemSubCategory'
        },
        {
            title: 'AMC ',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'amcyesCount',
                    key: 'amcyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'amcNOCount',
                    key: 'amcNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'amcNACount',
                    key: 'amcNACount',
                },
            ]
        },
        {
            title: 'WARRANTY',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'warrentyYesCount',
                    key: 'warrentyYesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'warrentNOCount',
                    key: 'warrentNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'warrentyNACount',
                    key: 'warrentyNACount',
                },
            ]
        },
        {
            title: 'INSURANCE',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'insuranceyesCount',
                    key: 'insuranceyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'insuranceNOCount',
                    key: 'insuranceNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'insuranceNACount',
                    key: 'insuranceNACount',
                },
            ]
        },
        {
            title: 'SERVICE',
            children: [
                {
                    title: 'REQUIRED',
                    dataIndex: 'reqCount',
                    key: 'reqCount',
                },
                {
                    title: 'NOT REQUIRED',
                    dataIndex: 'notReqCount',
                    key: 'notReqCount',
                },
                {
                    title: 'MISSED',
                    dataIndex: 'misCount',
                    key: 'misCount',
                },
            ]
        },

    ];


    const columnsSkelton: any[] = [
        {
            title: 'S NO',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'CATEGORY ',
            dataIndex: 'itemSubCategory'
        },
        {
            title: 'AMC ',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'amcyesCount',
                    key: 'amcyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'amcNOCount',
                    key: 'amcNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'amcNACount',
                    key: 'amcNACount',
                },
            ]
        },
        {
            title: 'WARRANTY',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'warrentyYesCount',
                    key: 'warrentyYesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'warrentNOCount',
                    key: 'warrentNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'warrentyNACount',
                    key: 'warrentyNACount',
                },
            ]
        },
        {
            title: 'INSURANCE',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'insuranceyesCount',
                    key: 'insuranceyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'insuranceNOCount',
                    key: 'insuranceNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'insuranceNACount',
                    key: 'insuranceNACount',
                },
            ]
        },
        {
            title: (
                <div>
                    SERVICE <br></br>
                    <Button icon={<DownloadOutlined />} onClick={() => exportExcel()}>EXCEL</Button>
                </div>
            ),
            children: [
                {
                    title: 'REQUIRED',
                    dataIndex: 'reqCount',
                    key: 'reqCount',
                },
                {
                    title: 'NOT REQUIRED',
                    dataIndex: 'notReqCount',
                    key: 'notReqCount',
                },
                {
                    title: 'MISSED',
                    dataIndex: 'misCount',
                    key: 'misCount',
                },
            ]
        },

    ];

    return (
        <>
            <Table columns={columnsSkelton} dataSource={assetCount} pagination={false} bordered scroll={{ y: 400 }}> </Table>
        </>
    )


}
export default CategoriesWiseAmcInsCount;