import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { DamageReasonsDto, DestinationDto} from '@gtpl/shared-models/masters';
import {DestinationService} from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import {DestinationForm} from '@gtpl/pages/master/master-components/destination-form';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import tableExport from "antd-table-export";
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import './destination-grid.css';
import { Link } from 'react-router-dom';
import autoTable from 'jspdf-autotable';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface DestinationGridProps {
  
}

export function DestinationGrid(
  props: DestinationGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const destinationService = new DestinationService();
  const [selectedDestinationData, setSelectedDestinationData] = useState<any>(undefined);
  const [destinationData, setDestinationData] = useState<DestinationDto[]>([]);

  const openFormWithData=(viewData: DestinationDto)=>{
    setDrawerVisible(true);
    setSelectedDestinationData(viewData);
  }
  useEffect(() => {
    getAll();
  }, []);
  const getAll= () => {
    destinationService.getAllDestination(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setDestinationData(res.data);
      } else {
        if (res.intlCode) {
          setDestinationData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDestinationData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const deleteProfile = (destinationData:DestinationDto) => {
    destinationData.isActive=destinationData.isActive?false:true;
    destinationService.ActivateorDeactivateDestination(destinationData).then(res => { console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
       render: (text, object, index) => (page-1) * 10 +(index+1)
    },
   
    {
      title: 'Country Name',
      dataIndex:'countryName',
      render: (text,rowData) => {return rowData.countryName},
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('countryName')
    },
    {
      title: 'Destination Name',
      dataIndex:'destinationName',
      sorter: (a, b) => a.destinationName.trim().localeCompare(b.destinationName.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('destinationName')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        rowData.destinationName.trim().toLowerCase()=="vizag"?<span></span>:
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Destination');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteProfile(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate ?'
                :  'Are you sure to Activate ?'
            }
          >  
             <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>  
        </span>
      )
    }
  ];

  const updateDestination = (destinationData: DestinationDto) => {
destinationData.updatedUser = JSON.parse(localStorage.getItem('username'))
console.log(destinationData)
console.log('destinationData')
    destinationService.updateDestination(destinationData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
   }

  const closeDrawer=()=>{
    setDrawerVisible(false);
  }
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const data=[{
    title: 'Country',
    dataIndex: 'countryName',
  },
  {
    title: 'Destination',
    dataIndex: 'destinationName',
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    render: (isActive, rowData) => (
      <>
        {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
      </>
    ),
  },
];

  const exportExcel=()=>{
    const excel = new Excel();
          excel
            .addSheet('Destinations')
            .addColumns(data)
            .addDataSource(destinationData)
            .saveAs('destinations.xlsx');
  }
  const exportToPdf=()=>{
  //  var header = [
  //     {title: "Country", dataIndex: "countryName"},
  //     {title: "Destination", dataIndex: "destinationName"},
  // ];
  var columns = [
    {title: "Country", dataKey: "countryName"},
    {title: "Destination", dataKey: "destinationName"}, 
    // {title: "Status", dataKey: "isActive"}, 
];
// var rows = destinationData;
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, destinationData, {
      // styles: {fillColor: [100, 255, 255]},
      columnStyles: {
        id: {fillColor: 255}
      },
     
      margin: {top: 20},
      addPageContent: function(data) {
        doc.text("DESTINATIONS", 50, 15);
      }
    });
     // doc.autoTable({ html: '#my-table' })
    doc.save('destinations.pdf')
  }


  return (
    <Card title={<span style={{color:'white'}}> Destination </span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/destination-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <Row gutter={40}>
      <Col>
          <Card title={'Total Destinations : ' + destinationData.length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + destinationData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + destinationData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
         
          <Button style={{marginBottom: 20, }} onClick={() => {exportExcel();}} >
            Get Excel
          </Button>
          <Button style={{marginLeft: 20,marginBottom: 20, }} onClick={() => {exportToPdf();}}>
            Get PDF
          </Button>
      </Row> 
          <br></br>
          <Table
          rowKey={record => record.destinationId}
          columns={columnsSkelton}
          dataSource={destinationData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
       
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
             <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <DestinationForm key={Date.now()}
                updateDetails={updateDestination}
                isUpdate={true}
                destinatonData={selectedDestinationData}
                closeForm={closeDrawer} />
            </Card> 
          </Drawer>
     </Card>
  );
}



   

export default DestinationGrid;
