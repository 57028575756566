import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber } from 'antd';
import { NotificationDto } from '@gtpl/shared-models/hrms';
import { NotificationsService } from '@gtpl/shared-services/hrms';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { NotificationStatus } from '@gtpl/shared-models/common-models'
import { DepartmentService } from '@gtpl/shared-services/masters'
import { DepartmentDto, DeptRequest } from '@gtpl/shared-models/masters';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { RolesDto } from '@gtpl/shared-models/masters';
import { RolesService } from '@gtpl/shared-services/masters'
import { SkillsDto } from '@gtpl/shared-models/hrms';
import { SkillsService } from '@gtpl/shared-services/hrms';
import './notifications-form.css';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import moment from 'moment';
const { TextArea } = Input;
export interface NotificationsFormProps {

  updatenotificationType: (notificationType: NotificationDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
  data: NotificationDto
  // updateNotification : (data :NotificationDto) => void;
}


export function NotificationsForm(props: NotificationsFormProps) {
  const [form] = Form.useForm();
  const [departmentsData, setDepartmentsData] = useState<DepartmentDto[]>([]);
  const history = useHistory();
  const { Option } = Select;
  const [roleData, setroleData] = useState<RolesDto[]>([]);
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [skillsData, setSkillsData] = useState<SkillsDto[]>([]);
  const [selectedDept, setSelectedDept] = useState<any>(undefined);
  const departmentService = new DepartmentService();
  const unitsService = new UnitcodeService();
  const skillsService = new SkillsService();
  const service = new NotificationsService()
  const roleDataService = new RolesService()
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  useEffect(() => {
    getAllActiveDepartment();
    getAllPlants();
    getAllActiveRoles();
    getAllSkills();
  }, [])
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const notificationsService = new NotificationsService()
  const createNotification = (notificationTypeData: NotificationDto) => {
    notificationsService.createNotification(notificationTypeData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('sucessfully');
        history.push("notifications-view");
        onReset();
      }
      else {
        AlertMessages.getSuccessMessage('Already Exist');
        // console.log('----------------------------------------------------------');
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  const onReset = () => {
    form.resetFields();
  }
  const saveData = (values: NotificationDto) => {
    if (props.isUpdate) {
      console.log(values)
      props.updatenotificationType(values);
    }
    else {
      console.log(values)
      create(values);
    }
  };
  const getAllSkills = () => {
    skillsService.getAllSkills().then(res => {
      if (res.status) {
        setSkillsData(res.data);
      } else {
        if (res.intlCode) {
          setSkillsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSkillsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  const getAllActiveRoles = () => {
    roleDataService.getAllActiveRoles().then(res => {
      if (res.status) {
        setroleData(res.data);
      } else {
        if (res.intlCode) {
          setroleData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setroleData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveDepartment = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentsData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getDeptDataById = (value) => {
    const deptReq = new DeptRequest(value);
    departmentService.getDeptDataById(deptReq).then(res => {
      setSelectedDept(res.data.deptName);
    })
  }

  const getAllPlants = () => {
    unitsService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const create = (data: NotificationDto) => {
    console.log(data, 'data')
    data.plant = plantData.find(i => i.plantId == data.plantId).plantName
    data.skills = data.skills.toString()
    service.createNotification(data).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage("Notification Created Sucessfully")
        history.push('/notifications-view');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }

    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    })

  }
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > fromDate;
  }


  return (


    <Card title={<span style={{ color: 'white' }}>Notifications</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/notifications-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}

        name="control-hooks"
        initialValues={props.data}
        layout="vertical"
        onFinish={saveData}

      >

        <Form.Item name="notificationId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="plantId"
              label="Plant"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Plant "
                optionFilterProp="children"

                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {/* <Option value={undefined}>please select Plant</Option>; */}
                {plantData.map(dropData => {
                  return <Option key={dropData?.plantId} value={dropData?.plantId}>{dropData?.plantName}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="jobRole"
              label="Job Role"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Select
                placeholder="Select Job Role"
                // onChange={}
                allowClear
                style={{ width: '100%' }}
              >
                {roleData.map(dropData => {
                  return <Option value={dropData.roleName}>{dropData.roleName}</Option>;
                })}</Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="jobDescription"
              label="Job Description"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
                {
                  pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                  message: `Should contain only alphabets and first letter should be capital.`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="notificationDate"
              label="Notification Date"
              initialValue={moment(moment().format("YYYY-MM-DD"))}
              rules={[
                {
                  required: true,
                  message: "Select the date again"
                },
              ]}
            >
              < DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                defaultValue={moment(moment().format("YYYY-MM-DD"))}
                showToday={true}
                
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="resourceRequired"
              label="Resource Required "
              rules={[
                { required: true, message: ' Valid Number is required', max: 4 },
                {
                  pattern: /^[0-9]*$/,
                  message: `Don't Allow letters and Spaces`
                }
              ]}
            >
              {/* <InputNumber style={{width:'100%'}}/> */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="skills"
              label="Skills"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },

              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Skills "
                optionFilterProp="children"

                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {/* <Option value={undefined}>please select Plant</Option>; */}
                {skillsData.map(dropData => {
                  return <Option value={dropData.skills}>{dropData.skills}</Option>;
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="yearOfExperience"
              label="Year Of Experience"
              rules={[
                { required: true, message: ' Valid Number is required', max: 2 },
                {
                  pattern: /^[0-9]*$/,
                  message: `Don't Allow letters and Spaces`
                }
              ]}
            >
              {/* <InputNumber style={{width:'100%'}}/> */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="plannedClosingDate"
              label="Planned Closing Date"
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              < DatePicker
                style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="remarks" label="Remarks"
              rules={[
                {
                  required: false,
                  message: "It should not cross limit",
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  max: 100,
                }
              ]}>
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="status"
              label="Status"
              initialValue={NotificationStatus.Open}
              rules={[
                {
                  required: true,
                  message: "It should not be blank"
                },
              ]}
            >
              <Select
                showSearch

                placeholder="Select Status"
                defaultValue={NotificationStatus.Open}
                allowClear
              >
                <Option key={NotificationStatus.Open} value={NotificationStatus.Open} >{NotificationStatus.Open}</Option>
                {/* <Option key={NotificationStatus.Hold} value={NotificationStatus.Hold} >{NotificationStatus.Hold}</Option> */}
                {/* <Option key={NotificationStatus.Inprogress} value={NotificationStatus.Inprogress} >{NotificationStatus.Inprogress}</Option> */}
                <Option key={NotificationStatus.Closed} value={NotificationStatus.Closed} >{NotificationStatus.Closed}</Option>
                <Option key={NotificationStatus.Cancelled} value={NotificationStatus.Cancelled} >{NotificationStatus.Cancelled}</Option>

              </Select>

            </Form.Item>
          </Col>

          


        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default NotificationsForm;
