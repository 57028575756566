
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CertificationsService, EmployeeService, HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from "react-router-dom";
// import {AssetLicenseDto}  from '@gtpl/shared-models/masters';
import { AssetLicenseDto } from 'libs/shared-models/asset-management/src/lib/asset-license/asset-license.dto';

import { AssetLicenseService } from '@gtpl/shared-services/asset-management';
import moment from 'moment';
import { CertificatesDto, EmployeeCodeRequest, HrmsEmployeeDetailsDto, HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { ColumnProps } from 'antd/lib/table';


const { Option } = Select;
const { TextArea } = Input;
/* eslint-disable-next-line */
export interface AssetLicenseFormProps {
  assetLicenseData: AssetLicenseDto;
  updateReason: (licensereason: AssetLicenseDto) => void;
  isUpdate: boolean;
  closeForm: () => void;

}

export function AssetLicenseForm(
  props: AssetLicenseFormProps
) {
  const [disable, setDisable] = useState<boolean>(false)
  const [form] = Form.useForm();
  const [AssetHistoryData, setAssetHistoryData] = useState<any>();
  const [certificationdata, setcertificationData] = useState<CertificatesDto[]>([]);
  const [empNameData, setSelectedNameData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [current, setCurrent] = useState<any>();
  const service = new AssetLicenseService;
  const newService = new HrmsEmpDetService();
  const certficateService = new CertificationsService();
  const empService = new EmployeeService();
  let history = useHistory();
  const [page, setPage] = React.useState(1);


  useEffect(() => {
    getAllActiveEmployees();
    getAll();
    getPoDetailsData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
  }
  if(props.isUpdate){
    console.log(props.assetLicenseData)
  }
  }, []);

//   useEffect(() =>{
// if(props.assetLicenseData){
//   form.setFieldsValue(props.assetLicenseData)
//   // form.setFieldsValue({renewalStartDate:moment(props.assetLicenseData.expiryDate,'YYYY-MM-DD')})
// }
//   },[props.assetLicenseData])


  const getAllActiveEmployees = () => {
    newService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setSelectedNameData(res.data);
      } else {
        if (res.intlCode) {
          setSelectedNameData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSelectedNameData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getPoDetailsData = () => {
    certficateService.getAllActiveCertifications().then((res) => {
      if (res.status) {
        setcertificationData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setcertificationData([]);
      }
    })
  }
  
  const getAll = () => {
    // req.plantId=Number(localStorage.getItem('unit_id'))
    service.getAllHistoryLicense().then(res => {
      if (res.status) {
        setAssetHistoryData(res.data);
      } else {
        if (res.intlCode) {
          setAssetHistoryData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAssetHistoryData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const save = (Data: AssetLicenseDto) => {
    Data.plantId=Number(localStorage.getItem('unit_id')) 
    setDisable(true)
    service.createAssetLicense(Data).then(res => {
      console.log(Data)
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');

        history.push("/asset-license-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: AssetLicenseDto) => {
//  const req= new AssetByStatus();
//     req.plantId=Number(localStorage.getItem('unit_id')) 
    setDisable(false)
    // console.log(values);
    if (props.isUpdate) {
      props.updateReason(values);
    } else {
      setDisable(false)
      save(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }
const getvalue = (value) =>{
console.log(value)
}
const historySkelton: ColumnProps<any>[] =[
  {
    title: 'S No',
    key: 'sno',
    width: '70px',
    responsive: ['sm'],
    render: (text, object, index) => (page - 1) * 10 + (index + 1)
  },
  {
    title: 'License Name',
    dataIndex: 'certificateName',
    //  responsive: ['lg'],
    sorter: (a, b) => a.certificateName.localeCompare(b.certificateName),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'License Number',
    dataIndex: 'licenseNumber',
    // responsive: ['lg'],
    sorter: (a, b) => String(a.licenseNumber).localeCompare(String(b.licenseNumber)),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNumber',
    //  responsive: ['lg'],
    sorter: (a, b) => a.referenceNumber - b.referenceNumber,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'License Issuer',
    dataIndex: 'company',
    // responsive: ['lg'],
    sorter: (a, b) => String(a.company).localeCompare(String(b.company)),
  },
  {
    title: 'Responsibility',
    dataIndex: 'responsibility',
    // responsive: ['lg'],
    sorter: (a, b) => String(a.responsibility).localeCompare(String(b.responsibility)),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Expiry Date',
    dataIndex: 'expiryDate',
    render: (text, record) => {
      return (
        <>
          {/* {record.expiryDate ? moment(record.expiryDate).format('YYYY-MM-DD') : '-'} */}
          {record.renewalExpiryDate ? moment(record.renewalExpiryDate).format('YYYY-MM-DD') : moment(record.expiryDate).format('YYYY-MM-DD')}
        </>
      )
    },
  },
  {
    title: 'Renewal Person Name',
    dataIndex: 'renewal_person_name',
    //  responsive: ['lg'],
    sorter: (a, b) => a.renewal_person_name.localeCompare(b.renewal_person_name),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Renewal Start Date',
    dataIndex: 'renewal_start_date',
    render: (text, record) => {
      return (
        <>
          {/* {record.expiryDate ? moment(record.expiryDate).format('YYYY-MM-DD') : '-'} */}
          {record.renewal_start_date ? moment(record.renewal_start_date).format('YYYY-MM-DD') : '-'}
        </>
      )
    },
  },
  {
    title: 'Renewal Expiry Date',
    dataIndex: 'renewal_expiry_date',
    render: (text, record) => {
      return (
        <>
          {/* {record.expiryDate ? moment(record.expiryDate).format('YYYY-MM-DD') : '-'} */}
          {record.renewal_expiry_date ? moment(record.renewal_expiry_date).format('YYYY-MM-DD') : '-'}
        </>
      )
    },
  },

]
  return (
    <Card title={<span style={{ color: 'white' }}>Asset License</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-license-view' ><span style={{ color: 'white' }} >
        <Button className='panel_button' >View </Button>
      </span></Link>} >
      <Form form={form}
        initialValues={props.assetLicenseData}
        name="control-hooks"
        onFinish={saveData}
        layout="vertical"
      >
         
          <Form.Item name="plantId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
            {/* const req= new AssetByStatus();
    req.plantId=Number(localStorage.getItem('unit_id')) */}
        <Form.Item name="licenseId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser"
          initialValue={props.assetLicenseData}
        >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="licenseName"
              label="License Name"
              rules={[
                {
                  required: true,
                  message: 'License Name required'

                },
                // {
                //   pattern:/^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                //   message: `Invalid License Name`
                // }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Name"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value =>getvalue(value)}
              >
                {certificationdata.map(dropData => {
                  return <Option key={dropData.certificateId}value={dropData.certificateId}>{dropData.certificateName}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="licenseNumber"
              label="License Number"
              rules={[
                {
                  required: true,
                  message: 'License Number required'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Invalid License Number`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="referenceNumber"
              label="Reference Number"
              rules={[
                {
                  required: true,
                  message: 'Reference Number required',
        
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Invalid Reference Number`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="company"
              label="License Issuer"
            // rules={[
            //   {
            //     required: true, 
            //     message: 'company required'

            //   },
            //   {
            //     pattern:  /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
            //     message: `Invalid Rejection Reason`
            //   }
            // ]}
            >
              <Input />

            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="responsibility"
              label="Responsibility"

              rules={[
                {
                  required: true,
                  message: "Responsibility is required"
                },
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Name"
                // optionFilterProp="children"
                allowClear
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
              >
                {empNameData.map((empNameData) => {
                  return <Option key={empNameData.employeeId} value={empNameData.employeeName}>{empNameData.employeeName} </Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="expiryDate"
              label="Expiry Date"
              initialValue={moment
              ()}
              rules={[
                {
                  required: props.isUpdate ? true : false,
                  message: 'Enter valid date'
                }
              ]}
            >
              <DatePicker style={{ width: '100%' }}
                showToday={true} />
            </Form.Item>
          </Col>

          {props.isUpdate?
     
          <Card title={<span style={{color:'white'}}>Asset Renewal</span>}
    style={{textAlign:'center',width:'934px'}} headStyle={{backgroundColor: '#69c0ff', border:0}}>
      <Row>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%'}}>
            <Form.Item
              name="renewalPersonName"
              label="Renewal Person Name"
              rules={[
                {
                  // required: props.isUpdate ? true : false,
                  required: false,
                  message: 'Renewal Person Name required'
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                  message: `Invalid Renewal Person Name`
                }
              ]}
            >
            <Input/>
            </Form.Item>
          </Col> */}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
             name="renewalPersonName"
             label="Renewal Person Name"
              rules={[
                {
                  required: false,
                  message: "Name required"
                },
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Name"
                // optionFilterProp="children"
                allowClear
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
              >
                {empNameData.map((empNameData) => {
                  return <Option key={empNameData.employeeId} value={empNameData.employeeName + ' ' + empNameData.employeeLastName}>{empNameData.employeeName} {empNameData.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="renewalStartDate"
              label="Renewal Start Date"
              rules={[
                {
                  // required: props.isUpdate ? true : false,
                  required: false,
                  message: 'Enter valid date'
                }
              ]}
              initialValue={props.assetLicenseData.expiryDate}
            >
              <DatePicker style={{ width: '100%' }}
                showToday={true} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="renewalExpiryDate"
              label="Renewal Expiry Date"
              rules={[
                {
                  required: false,
                  message: 'Enter valid date'
                }
              ]}
            >
              <DatePicker style={{ width: '100%' }}
                showToday={true} />
            </Form.Item>
          </Col>
      </Row>
      <Row>
        <Card title={<span style={{color:'white'}}>License History</span>}
    style={{textAlign:'center',width:'934px'}} headStyle={{backgroundColor: '#69c0ff', border:0}}>
       <Table
        rowKey={record => record.productId}
        columns={historySkelton}
        dataSource={AssetHistoryData}
        pagination={{
          onChange(current) {
            // setPage(current);
          }
        }}
        scroll={{ x: true }}
        bordered />

        </Card>
      </Row>
          </Card>


         : <></>}

     
          
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>

            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>

          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AssetLicenseForm;
