import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Row, Col, DatePicker, Select } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
const { Option } = Select;
import { SuggestionsDto } from '@gtpl/shared-models/hrms';
import { SuggestionsService } from '@gtpl/shared-services/hrms';
import { DepartmentService } from '@gtpl/shared-services/masters';
import { DepartmentDto, DeptRequest, HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { HrmsEmployeeDetailsDto, RolesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
import TextArea from 'rc-textarea';
import './suggestion.css';
import { FgStatusEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface SuggestionProps {
  suggestionsData: SuggestionsDto;
  updateSuggestion: (suggestion: SuggestionsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function Suggestion(
  props: SuggestionProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [filtersData, setFiltersData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const suggestionsService = new SuggestionsService()
  const [departmentsData, setDepartmentsData] = useState<DepartmentDto[]>([]);
  const [selectedDept, setSelectedDept] = useState<any>(undefined);
  const departmentService = new DepartmentService();
  const hrmsEmpDetService = new HrmsEmpDetService();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  let createdUser = "";

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    getAllActiveDepartment();
    getAllActiveEmployeesForLeaves();
  }, [])
  const getAllActiveEmployeesForLeaves = () => {
    hrmsEmpDetService.getAllActiveEmployeesForLeaves().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
        setFiltersData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData(res.data);
          setFiltersData(res.data);
          console.log(res.data)
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      setFiltersData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveDepartment = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentsData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getDeptDataById = (value) => {
    const deptReq = new DeptRequest(value);
    departmentService.getDeptDataById(deptReq).then(res => {
      setSelectedDept(res.data.deptName);
    })
  }


  const createSuggestion = (Data: SuggestionsDto) => {
    Data.departmentName = selectedDept;
    suggestionsService.createSuggestion(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Suggestion created successfully');
        history.push('/suggestion-view');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('');
    form.resetFields();

  }
  const getEmployeeDetails = () => {
    const employeeId = form.getFieldValue('employeeId')
    const request = new HrmsEmployeeRequest(employeeId);
    hrmsEmpDetService.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeName: res.data.employeeName+' '+res.data.employeeLastName
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const handleDepartments = (value, item) => {
  //   setSelectedDept(value);
  //   console.log(value);

  // }

  const saveData = (values: SuggestionsDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateSuggestion(values);
    } else {
      console.log(values);
      createSuggestion(values);
    }
  };
  return (
    <Card title={<span style={{ color: 'white' }}>Suggestions</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/suggestion-view' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} onFinish={saveData} initialValues={props.suggestionsData} >
        <Form.Item
          name="suggestionId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
      <Row gutter={24}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeId"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "code is required"
                },

              ]}
            >

              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Code"
                optionFilterProp="children"
                onChange={getEmployeeDetails}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Employee code</Option>
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeId}>{employeeData.employeeCode} </Option>
                  
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeName"   
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "name is required"
                },
              ]}>
              <Input disabled />
            </Form.Item>
          </Col> 

      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="departmentId"
              label="Department"
              rules={[
                {
                  required: true,
                  message: 'Department is required'
                },
              ]}>
              <Select
                placeholder="Select Department"
                onSelect={getDeptDataById}
              >
                {departmentsData?.map(dropData => {
                  return <Option key={dropData.deptId} value={dropData.deptId}>{dropData.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
       
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="suggestionType"
                label="Suggestion Type"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Suggestion Type."
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=~{}:";<>,.?|][a-zA-Z'. ]*$/,
                    message: `Should contain only alphabets`
                  },
                
                ]}>
                     {<Select
                        showSearch
                        placeholder=" Please Suggestion Type "
                      >
                        {/* <Option key={0} value={null}>Please Select</Option> */}
                        <Option key={1} value={'General'}>General</Option>
                        <Option key={2} value={'Technical'}>Technical</Option>
                        <Option key={2} value={'Communication'}>Communication</Option>
                        <Option key={2} value={'Infrastructure'}>Infrast</Option>
                      </Select>}
                
          </Form.Item>
        </Col>
       
         
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Missing Status' }]} >
              <Select
                placeholder="Select Status"
                allowClear
              >
                <Option key={0} value={null}>Select Status</Option>
                <Option key={1} selected value={FgStatusEnum.OPEN}>{FgStatusEnum.OPEN}</Option>
                <Option key={2} value={FgStatusEnum.IN_PROGRESS}>{FgStatusEnum.IN_PROGRESS}</Option>
                <Option key={3} value={FgStatusEnum.CLOSE}>{FgStatusEnum.CLOSE}</Option>
                </Select>
                </Form.Item>

            </Col>

            <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="suggestion"
                label="Suggestion"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Suggestion."
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't cross the limit`,
                    max:1000,
                  }
                ]}>
                <TextArea rows={2} style={{width:'100%'}}/>
          </Form.Item>
        </Col>
        {/* <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="remarks"
                label="Remarks "
                rules={[
                  {
                    required: true,
                    message:"Enter valid Remarks."
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/_@ ]*$/,
                    message: `Should contain only alphabets and numbers.`
                  }
                ]}>
                <TextArea rows={1} style={{width:'100%'}}/>
          </Form.Item>
        </Col>

          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="remarks"
              label="Remarks "
              rules={[
                {
                  required: true,
                  message: "Enter valid Remarks."
                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/_@ ]*$/,
                  message: `Should contain only alphabets and numbers.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col> */}

           {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: 'Employee Name  is required'
                },
              ]}>
              
               <Select
                placeholder="Select Employee Name"
                onChange={getEmployeeDetails}
              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeId}>{employeeData.employeeName}</Option>
                })}
              </Select> 
            
            </Form.Item>
          </Col>  */}
                  
        </Row>


        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate !== true) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>

      </Form>
    </Card>

  );
}

export default Suggestion;
