import { Card, Col, Row } from "antd";
import './warehouse-dashboard.css';
import React, { } from "react";
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface ExpectedStockCardComponentProps {
    cardData: CardDetail
}

export const ExpectedStockCardComponent = (props: ExpectedStockCardComponentProps) => {
    const { cardData } = props;

    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <tr style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em' }}>
                <td >{bodyProps.cardBodyTitle}</td>
                <td >{bodyProps.cardBodyParam1}</td>
                <td >{bodyProps.cardBodyParam2}</td>
                <td >{bodyProps.cardBodyParam3}</td>
            </tr>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    return (
        <>
            <Card className="whCard" title={<span style={{ marginBottom: '10px' }} className="title">{cardData.carHeader.cardHeaderTitle}</span>} bordered={false} style={{ color: 'white', height: '100%',width:'558%' }}>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '90%', margin: '1.5em', fontSize: '0.85em', border: '0' }}>
                    {constructTableHeader(cardData.tableHeaders)}
                    <tbody>
                        {constructLocationDetail(cardData.cardBody)}
                    </tbody>
                </table>
            </Card>
        </>
    );
}
export default ExpectedStockCardComponent;