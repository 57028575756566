import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

interface IExpiryDetailsForWarrenty {
  aboutToExpireDetails: any
}

const ExpiryDetailsForWarrenty = (props: IExpiryDetailsForWarrenty) => {
  const { aboutToExpireDetails } = props;

  const assetCountData = aboutToExpireDetails?.assetMaintenanceDetails.flatMap((record: any) => {
    return record?.aboutToExpireDetails.map((rec: any) => {
      return { expireWithInDays: rec.expireWithInDays, assetValue: rec.assetValue, assetCount: rec.assetCount }
    })
  });

  const categories = assetCountData?.map((data: any) => data.expireWithInDays);

  const config = {
    colors: [
      "#6B7A8F",
      "#F7882F"
    ],
    chart: {
      type: "column",
      backgroundColor: "white", 
    },
    title: {
      text: "Expiry Details For Warranty",
      style: {
        color: "black",
        fontSize: "20px",
        lineHeight: "1.4",
        marginBottom: "0",
        overflow: "hidden",
        paddingTop: "calc(2px*var(--scale-factor, 1))",
        position: "relative",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        zIndex: "5",
        fontFamily: "Arial, sans-serif" // Updated font family
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: "black",
        },
      },
    },
    yAxis: [{
      title: {
        text: 'Asset Count',
        style: {
          color: "black",
        },
      },
      labels: {
        style: {
          color: "black",
        },
      },
    }, {
      title: {
        text: 'Asset Value',
        style: {
          color: "black",
        },
      },
      opposite: true,
      labels: {
        style: {
          color: "black",
        },
      },
    }],
    tooltip: {
      shared: true,
      formatter: function () {
        let tooltip = `<b>${this.x}</b><br/>`;
        this.points.forEach((point: any) => {
          tooltip += `${point.series.name}: ${point.y}<br/>`;
        });
        return tooltip;
      }
    },
    series: [
      {
        name: "Asset Count",
        data: assetCountData?.map((data: any) => data?.assetCount),
        yAxis: 0,
      },
      {
        name: "Asset Value",
        data: assetCountData?.map((data: any) => data?.assetValue),
        yAxis: 1,
      }
    ]
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
}

export default ExpiryDetailsForWarrenty;
