import React, { useEffect, useState,useRef } from 'react';
import {Card, Table, Form, Input, Button, Col, Row, Modal,Select, Tabs, Tooltip} from 'antd';
import {BarcodeOutlined,SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import {AssetLocationMappingDto} from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import {AssetLocationMappingService} from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { Scanner} from '../../../asset-classification-form/src/lib/Scanner';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import {ItemSubCategoryService, UnitcodeService} from '@gtpl/shared-services/masters';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import {AssetTypeEnum} from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const {Option} = Select;
const {TabPane} = Tabs;

/* eslint-disable-next-line */
export interface AssetLocationMappingProps {}

export function AssetLocationMapping(
  props: AssetLocationMappingProps
) {

  const [page,setPage] =useState<number>(1)
  const [data,setData] = useState<AssetLocationMappingDto[]>([]);
  const [locationunmappedData,setLocationunmappedData] = useState<any>([]);
  const service = new AssetLocationMappingService();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [assetCodeData,setAssetCodeData] = useState<any[]>([]);
  const assetsService = new AssetService(); 
  const [qrModal, setQrModal] = useState('');
  const [form] = Form.useForm();
  const locationService = new AssetLocationService();
  const [locationData,setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName,setPlantName]= useState<any>([]);
  const [itemCategory,setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const searchInput = useRef(null);
  const role = JSON.parse(localStorage.getItem('role'))


  useEffect(()=>{
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getAssets();
    getAllPlants();
    getItemCategory();
    getAssetCode();
    getLocations()
  },[])

  const getAssets = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('assetCode') !== undefined){
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }};
      if(form.getFieldValue('location') !== undefined){
        req.location = form.getFieldValue('location')
      }
      if(form.getFieldValue('assetType') !== undefined){
        req.assetType = form.getFieldValue('assetType')
      }
      if(form.getFieldValue('assetCategory') !== undefined){
        req.assetCategory = form.getFieldValue('assetCategory')
      }
      if(form.getFieldValue('assetMode') !== undefined){
        req.assetMode = form.getFieldValue('assetMode')
      }
    service.getLocationMappedAssets(req).then((res) => {
      if(res.status) {
        setData(res.data);
        if(res.data.length >0){
        AlertMessages.getSuccessMessage('Loction Mapped Assets retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('Data not found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
    service.getLocationUnmappedAssets(req).then(res => {
      if(res.status) {
        setLocationunmappedData(res.data);
        if(res.data.length >0){
         // AlertMessages.getSuccessMessage('Loction Unmapped Assets retrieved successfully')
          } else {
          //  AlertMessages.getErrorMessage('Data not found')
          }
      } else {
       // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if(res.status){
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
    const getAllPlants = () => {
      plantsService.getAllPlants().then(res => {
        if(res.status){
          setPlantName(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getAssetCodesDropDown(req).then((res) => {
      if(res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onFinish = (values) => {
    const req = new AssetLocationMappingDto(null,values.asset_id,null,values.location_id,true,null,values.assets_code);
    service.create(req).then( res => {
        if(res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
          getAssets()
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    )
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })   
    getAssets()
 
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns : ColumnProps<any>[] =[
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Plant',
      dataIndex:'unit_name',
      width:130,
      align:'left',
      ...getColumnSearchProps('unit_name'),
      sorter:(a,b) => a.unit_name.localeCompare(b.unit_name),
      sortDirections:['descend','ascend'],
    }, 
    {
      title:'Location',
      dataIndex:'location_name',
      width:130,
      align:'left',
      ...getColumnSearchProps('location_name'),
      sorter:(a,b) => a.unit_name.localeCompare(b.unit_name),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Mode',
      dataIndex:'asset_mode',
      width:130,
      align:'left',
      ...getColumnSearchProps('asset_mode'),
      sorter:(a,b) => a.asset_mode.localeCompare(b.asset_mode),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Type',
      dataIndex:'asset_type',
      width:130,
      align:'left',
      ...getColumnSearchProps('asset_type'),
      sorter:(a,b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Category',
      dataIndex:'item_sub_category',
      width:130,
      align:'left',
      sorter:(a,b) => a.item_sub_category.localeCompare(b.item_sub_category),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('item_sub_category')
    },   
    {
      title:'Asset Name',
      dataIndex:'item_name',
      width:130,
      align:"left",
      ...getColumnSearchProps('item_name'),
      sorter:(a,b) => a.item_name.localeCompare(b.item_name),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Code',
      dataIndex:'assets_code',
      width:130,
      align:"left",
      ...getColumnSearchProps('assets_code'),
      sorter:(a,b) => a.assets_code.localeCompare(b.assets_code),
      sortDirections:['descend','ascend'],
    },
    {
      title: "Serial Number",
      dataIndex: "asset_serialno",
      align:"left",
      // width:120,
      ...getColumnSearchProps('asset_serialno'),
      // sorter:(a,b) => a.asset_serialno.localeCompare(b.asset_serialno),
      sortDirections:['descend','ascend']
      // render:(text,record) => {
      //   return (
      //     <>
      //       {record.asset_serialno ? record.asset_serialno : '-'}
      //     </>
      //   )
      // },
    },
    {
      title: "Purchase Date",
      dataIndex: "date_of_purchased",
      align: "right",
      ...getColumnSearchProps('date_of_purchased'),
      sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },
    },
    {
     title: ' Asset Age',
     dataIndex: 'assetAge',
     align: "right",
     ...getColumnSearchProps('assetAge'),
     sorter: (a, b) => a.assetAge - b.assetAge,
     sortDirections: ['descend', 'ascend'],
     render: (text, record) => {
    
       return (
         <>
           {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
         </>
       )
     },
    }
  ]

  const columns1 : ColumnProps<any>[] =[
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Plant',
      dataIndex:'unit_name',
      width:130,
      align:'left',
      ...getColumnSearchProps('unit_name'),
      sorter:(a,b) => a.unit_name.localeCompare(b.unit_name),
      sortDirections:['descend','ascend'],
    }, 
    {
      title:'Location',
      dataIndex:'location_name',
      width:130,
      align:'left',
      render: (text, record, index) => {
        return (
          <>
            {text ? (
              text
            ) : (
              <Select
              placeholder="Please Select"
              style={{ width: '130' }}
              showSearch
              allowClear
              onChange={(e) => (record.location_id = e)}
              >
                {locationData.map((locations) => {
                    return (
                      <Option
                        key={locations.locationId}
                        value={locations.locationId}
                      >
                        {locations.locationName}
                      </Option>
                    );
                  })}

              </Select>
            )}
          </>
        );
      },
    },
    {
      title:'Asset Mode',
      dataIndex:'asset_mode',
      width:130,
      align:'left',
      ...getColumnSearchProps('asset_mode'),
      sorter:(a,b) => a.asset_mode.localeCompare(b.asset_mode),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Type',
      dataIndex:'asset_type',
      width:130,
      align:'left',
      ...getColumnSearchProps('asset_type'),
      sorter:(a,b) => a.asset_type.localeCompare(b.asset_type),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Category',
      dataIndex:'item_sub_category',
      width:130,
      align:'left',
      sorter:(a,b) => a.item_sub_category.localeCompare(b.item_sub_category),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('item_sub_category')
    },   
    {
      title:'Asset Name',
      dataIndex:'item_name',
      width:130,
      align:"left",
      ...getColumnSearchProps('item_name'),
      sorter:(a,b) => a.item_name.localeCompare(b.item_name),
      sortDirections:['descend','ascend'],
    },
    {
      title:'Asset Code',
      dataIndex:'assets_code',
      width:130,
      align:"left",
      ...getColumnSearchProps('assets_code'),
      sorter:(a,b) => a.assets_code.localeCompare(b.assets_code),
      sortDirections:['descend','ascend'],
    },
    {
      title: "Serial Number",
      dataIndex: "asset_serialno",
      align:"left",
      // width:120,
      ...getColumnSearchProps('asset_serialno'),
      sorter:(a,b) => a.asset_serialno.localeCompare(b.asset_serialno),
      sortDirections:['descend','ascend'],
      render:(text,record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },
    },
    {
      title: "Purchase Date",
      dataIndex: "date_of_purchased",
      align: "right",
      ...getColumnSearchProps('date_of_purchased'),
      sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },
    },
    {
     title: ' Asset Age',
     dataIndex: 'assetAge',
     align: "right",
     ...getColumnSearchProps('assetAge'),
     sorter: (a, b) => a.assetAge - b.assetAge,
     sortDirections: ['descend', 'ascend'],
     render: (text, record) => {
    
       return (
         <>
           {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
         </>
       )
     },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      align: "left",
      // width:150,
      fixed: "right",
      render: (text, rowData) => (
        <span>
          <Button type='primary' shape='round' onClick={(e) => onFinish(rowData)}>
            Update
          </Button>
        </span>

      )
    }
  ]

  const onReset = () => {
    form.resetFields();
    getAssets();

  }
  

  
 
  return (
    <div>
      <Card title={<span style={{ color: 'white' }}> Asset Location Mapping</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-location-mapping'><span style={{ color: 'white' }} ><Button className='panel_button' > Mapping </Button> </span></Link>}>
      <Form form={form} layout="vertical">
        <Row gutter={[24,24]}>
            <Col span={4}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
               defaultValue={'All'}
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategoryId}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
                  <Col  xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5}}
            lg={{ span: 5}}
            xl={{ span: 5}}>
              <Form.Item name="assetCode" label="Asset Code">
              <Select 
            suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }}/>}
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetCodeData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.concateditemName}`}</Option>
            })

            }
          </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
          <Form.Item label='Plant' name='plantId'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
            showSearch
            allowClear>
              {/* <Option key={'all'} value={''}>All</Option> */}
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={'All'}
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType'>
          <Select 
              placeholder='assetType'
              showSearch
              defaultValue={'All'}
              //onChange={onStatusSearch}
              allowClear
            >
              <Option key='all' value=''>All</Option>
              {Object.values(AssetTypeEnum).map((i) => {
                return (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Mode' name='assetMode'>
          <Select 
              placeholder='assetMode'
              showSearch
              defaultValue={'All'}
              //onChange={onStatusSearch}
              allowClear
            >
              <Option key='all' value=''>All</Option>
              <Option key='OwnedAsset' value='OwnedAsset'>OwnedAsset</Option>
              <Option key='RentedAsset' value='RentedAsset'>RentedAsset </Option>
            </Select>
          </Form.Item>
          </Col>
          </Row>
          <Row>
          <Col style={{marginLeft:'80%' }} >
              <Button type='primary' onClick={getAssets}>Submit</Button>
            </Col>
            <Col style={{marginLeft:'1%'}}>
              <Button onClick={onReset}>Reset</Button>
            </Col>
        </Row>
        </Form>

        <Tabs>
        <TabPane key='1' tab={<span ><b>{`Location-Mapped : ${data.length}`}</b></span>}>
      <Table 
        columns={columns} 
        dataSource={data}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
      />
      </TabPane>
      {/* <TabPane key='2' tab={<span><b>{`Location-Unmapped : ${locationunmappedData.length}`}</b></span>}>

      <Table columns={columns1} dataSource={locationunmappedData} 
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
      />
      </TabPane> */}
  </Tabs>
      <Modal
          className='qr-containerrrrr'
          key={'modalll' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
             qrModal == 'assetCode' ? <Scanner handleScan={handleAssetIdScan} /> : null
             //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal>
    </Card>
  </div>
  )
}


export default AssetLocationMapping;
