import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { HrmsEmployeeDetailsDto, HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from "react-router-dom";
import { ApplyForLeavesDto, GetAllAvailableLeavesDto, GetEmpAvlLeaves, TypesOfLeavesDto } from '@gtpl/shared-models/hrms';
import { ApplyForLeavesService, EmployeeLeavesService, HolidaysCalendarService, TypesOfLeavesService } from '@gtpl/shared-services/hrms';
import moment from 'moment';

import './apply-for-leaves-form.css';
import { LeavesDTO } from 'libs/shared-models/hrms/src/lib/employee-leaves/leaves.dto';
import { create } from 'domain';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};



const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

/* eslint-disable-next-line */
export interface ApplyForLeavesFormProps {
  applyForLeavesData: ApplyForLeavesDto;
  updateDetails: (hrms: ApplyForLeavesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ApplyForLeavesForm(
  props: ApplyForLeavesFormProps
) {
  console.log(props)

  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [typesOfLeaveData, setTypesOfLeavesData] = useState<TypesOfLeavesDto[]>([]);
  const [selectedTypesOfLeaves, setSelectedTypesOfLeaves] = useState<TypesOfLeavesDto[]>([]);
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [toDate, setToDate] = useState<moment.Moment>(moment());
  const [availableLeaves, setAvailableLeaves] = useState<number>(0);
  const [totalLeaves, setTotalLeaves] = useState<number>(0);
  const [holidays, setHolidays] = useState<any>()

  const { TextArea } = Input;

  const service = new HrmsEmpDetService();
  const typesOfLeavesService = new TypesOfLeavesService();
  const applyforLeavesService = new ApplyForLeavesService();
  const employeeLeavesService = new EmployeeLeavesService();
  const holidaysService = new HolidaysCalendarService

  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const disable = () => {
    form.getFieldValue('employeeCode').disabled = true;
  }
  useEffect(() => {
    getAllActiveEmployeesForLeaves();
    getAllTypesOfLeaves();
    getHolidaysCalendar();
    // getAllAvailableLeaves(props.applyForLeavesData?.typeOfLeave);
    // if(props.isUpdate){
    //   form.setFieldsValue({'typeOfLeaveId':props.applyForLeavesData.typeOfLeaveId})
    // }

    if (props.isUpdate) {
      form.getFieldValue('employeeCode').disabled;
    }
  }, [])

  const getAllActiveEmployeesForLeaves = () => {
    service.getAllActiveEmployeesForLeaves().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData(res.data);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  // const getEmployeeDetails = () => {
  //   const employeeId = form.getFieldValue('employeeId')
  //   const request = new HrmsEmployeeRequest(employeeId);
  //   service.getActiveEmployeesById(request).then(res => {
  //     if (res.status) {
  //       console.log(res.data.employeeName);
  //       form.setFieldsValue({
  //         employeeName: res.data.employeeName,
  //       });
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const getAllTypesOfLeaves = () => {
    typesOfLeavesService.getAllActiveTypesOfLeaves().then(res => {
      if (res.status) {
        setTypesOfLeavesData(res.data);
      } else {
        if (res.intlCode) {
          setTypesOfLeavesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTypesOfLeavesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllAvailableLeaves = () => {
    const employeeId = form.getFieldValue('employeeName');
    const typeofLeave = form.getFieldValue('typeOfLeave')
    const req = new GetEmpAvlLeaves(`${new Date().getFullYear()}`, employeeId, typeofLeave)
    employeeLeavesService.getAllAvailableLeaves(req).then(res => {
      if (res.status) {
        setAvailableLeaves(res.data);
      } else {
        if (res.intlCode) {
          setAvailableLeaves(0);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAvailableLeaves(0);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const createLeave = (Data: ApplyForLeavesDto) => {
    applyforLeavesService.createLeave(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Leave Applied successfully');
        history.push('/apply-for-leaves-grid');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: ApplyForLeavesDto) => {
    const noofDays = form.getFieldValue('noOfDays');
    const noofLeaves = availableLeaves;
    if (noofDays > noofLeaves) {
      AlertMessages.getErrorMessage('No.of Days cannot be more than No.of Leaves');
    } else {
      if (props.isUpdate) {
        props.updateDetails(values);
      } else {
        createLeave(values);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }

  const handleEmployeeName = () => {
    const employeeId = form.getFieldValue('employeeName');
    const request = new HrmsEmployeeRequest(employeeId);
    service.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeCode: res.data.employeeCode,
          employeeId: res.data.employeeId
        });
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getEmployeeName = () => {
    const employeeId = form.getFieldValue('employeeCode')
    const request = new HrmsEmployeeRequest(employeeId);
    service.getActiveEmployeesById(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeName: res.data.employeeName + " " + res.data.employeeLastName,
        });
        // getAdjustmentData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getHolidaysCalendar = () => {
    holidaysService.getHolidaysForYear().then((res) => {
      if (res.status) {
        setHolidays(res.data)
      } else {
        setHolidays([])
      }
    })
  }


  const getNoOfDays = (dateType: string) => {
    const fromDate = form.getFieldValue('fromDate');
    const toDate = form.getFieldValue('toDate');
    const noOfDays = (toDate - fromDate);
    const firstDay = form.getFieldValue('leaveFromDay');
    const secondDay = form.getFieldValue('leaveToDay');

    if (moment(fromDate).format('dddd') === 'Sunday' || moment(toDate).format('dddd') === 'Sunday') {
      AlertMessages.getErrorMessage('Selected day should not be a sunday');

      if (dateType == 'fromDate') {
        setFromDate(moment());
        form.setFieldsValue({
          fromDate: moment()
        })
      }
      if (dateType == 'toDate') {
        form.setFieldsValue({
          toDate: moment()
        })
      }
      let noOfDays = 0;
      if (noOfDays == 1) {
        if ((fromDate == toDate) && (firstDay != 'Full Day' && secondDay != 'Full Day')) {// Half day
          // finalDays -= 0.5;
        }
      } else {
        if (firstDay == 'Second Half' && secondDay == 'First Half') {// Full day
          // finalDays -= 1;
        } else if ((fromDate == toDate) && firstDay != 'Full Day') {// Half day
          // finalDays -= 0.5;
        } else if (firstDay == 'Full Day' && secondDay == 'First Half') {
          // finalDays -= 0.5;
        }


      }
      // if (form.getFieldValue('leaveFromDay') == 'Second Half' || form.getFieldValue('leaveFromDay') == 'First Half') {
      //   noOfDays = 0.5;
      // } else {
      //   noOfDays = 1;
      // }
      // // form.setFieldsValue({
      // //   noOfDays: noOfDays
      // // })
      // if (form.getFieldValue('leaveToDay') == 'Second Half' || form.getFieldValue('leaveToDay') == 'First Half') {
      //   noOfDays = noOfDays + 0.5;
      // } else {
      //   noOfDays = noOfDays + 1;
      // }
      // if (form.getFieldValue('leaveFromDay'))
      //   form.setFieldsValue({
      //     noOfDays: noOfDays
      //   })
      return false;
    } else {
      if (dateType == 'fromDate') {
        setFromDate(moment(fromDate));
        form.setFieldsValue({
          toDate: moment(fromDate)
        })
      }
      if (dateType == 'toDate') {
        setToDate(moment(toDate));

      }

    }
    const d1 = moment(form.getFieldValue('fromDate'), 'YYYY-MM-DD');
    const d2 = moment(form.getFieldValue('toDate'), 'YYYY-MM-DD');
    // Calc all days used including last day ( the +1 )
    console.log(d1)
    console.log(d2)


    const days = d2.diff(d1, 'days') + 1;

    console.log('Days:', days);

    // how many full weekends occured in this time span
    const weekends = Math.floor(days / 7);

    console.log('Full Weekends:', weekends);

    // Subtract all the weekend days
    let businessDays = days - (weekends * 1);

    // Special case for weeks less than 7
    if (weekends === 0) {
      const cur = d1.clone();
      for (let i = 0; i < days; i++) {
        if (cur.day() === 0) {
          businessDays--;
        }
        cur.add(1, 'days')
      }
    } else {
      // If the first day is a sunday we need to account for it
      if (d1.day() === 0) {
        console.log('Extra weekend day (Sunday)');
        businessDays--;
      }
    }

    //find if selected days have holidays from holiday calendar

    var selectedDates = [];
    const holidayDates = holidays.map(i => { return moment(i.holidayDate).format("YYYY-MM-DD") });

    for (var current = d1; current <= d2; current.add(1, 'd')) {
      selectedDates.push(current.format("YYYY-MM-DD"))
    }
    const holidaysMatched = selectedDates.filter(element => holidayDates.includes(element));
    console.log('holidays matched:', holidaysMatched.length)
    businessDays = (businessDays - holidaysMatched.length)

    setTotalLeaves(businessDays);
    console.log(businessDays)
    let finalDays = businessDays;
    // const fromDate = form.getFieldValue('leaveToDay')  
    if (businessDays == 1) {
      if ((firstDay == secondDay) && (firstDay != 'Full Day')) {// Half day
        finalDays -= 0.5;
      }
    } else {
      if (firstDay == 'Second Half' && secondDay == 'First Half') {// Full day
        finalDays -= 1;
      } else if ((firstDay == secondDay) && firstDay != 'Full Day') {// Half day
        finalDays -= 0.5;
      } else if (firstDay == 'Full Day' && secondDay == 'First Half') {
        finalDays -= 0.5;
      } else if (firstDay == 'Second Half' && secondDay == 'Full Day') {
        finalDays -= 0.5;
      }
    }
    console.log(finalDays)
    form.setFieldsValue({ noOfDays: finalDays })
    // ---------------------------------------------------------------
    // if (form.getFieldValue('leaveToDay') == 'Full Day') {
    //   form.setFieldsValue({
    //     noOfDays: businessDays
    //   })
    // } else if
    //   (form.getFieldValue('leaveToDay') == 'Second Half' || form.getFieldValue('leaveToDay') == 'First Half') {
    //   const leaves = businessDays - 0.5;
    //   form.setFieldsValue({
    //     noOfDays: leaves
    //   })
    // }
    // else if (form.getFieldValue('leaveFromDay') == 'Second Half' || form.getFieldValue('leaveFromDay') == 'First Half') {
    //   const leaves = businessDays - 0.5;
    //   form.setFieldsValue({
    //     noOfDays: leaves
    //   })
    // }
    // // else if (form.getFieldValue('leaveFromDay') == 'Second Half' && form.getFieldValue('leaveToDay') == 'First Half') {
    // //   const leaves = businessDays - 1;
    // //   form.setFieldsValue({
    // //     noOfDays: leaves
    // //   })
    // // }
    // else {
    //   form.setFieldsValue({
    //     noOfDays: businessDays
    //   })
    // };

  }
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < fromDate;
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Leave Application</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/apply-for-leaves-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={saveData}
        initialValues={props.applyForLeavesData}
      >

        <Form.Item name="applyForLeavesId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>

        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>

        <Form.Item style={{ display: "none" }} name="employeeId" >
          <Input hidden />
        </Form.Item>

        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "name is required"
                },

              ]}
            >
              <Select
                showSearch
                disabled={props.isUpdate ? true : false}
                allowClear
                //style={{ width: 210 }}
                placeholder="Select Employee Name"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={handleEmployeeName}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }

              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeId + employeeData.employeeLastName}>{employeeData.employeeName} {employeeData.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "name is required"
                },
              ]}>
              <Select
                showSearch
                disabled={props.isUpdate ? true : false}
                allowClear
                //style={{ width: 210 }}
                placeholder="Select Employee Code"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={getEmployeeName}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }

              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeId}>{employeeData.employeeCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              key={Date.now()}
              name="typeOfLeave"
              label={<span>Type of Leave || Available Leaves : {(availableLeaves)}</span>}
              initialValue={props?.applyForLeavesData?.typeOfLeave}
              rules={[
                {
                  required: true,
                  message: "Leave is required"
                },

              ]} >

              <Select
                key={Date.now()}
                showSearch
                placeholder="Select Type of Leave"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={getAllAvailableLeaves}
                defaultValue={Number(props?.applyForLeavesData?.typeOfLeave) || null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option key={0} value={null}>Select Type of Leave</Option>
                {typesOfLeaveData.map((typesOfLeaveData) => {
                  return <Option key={typesOfLeaveData.typeOfLeaveId} value={typesOfLeaveData.typeOfLeaveId}>{typesOfLeaveData.typeOfLeave}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="appliedDate" label="Apply Date" initialValue={moment()}>
              <DatePicker style={{width:'100%'}} format="YYYY-MM-DD"
              showToday={true} defaultValue={moment('YYYY-MM-DD')} disabled />
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="fromDate" label="From Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={(selected) => {
                  setFromDate(selected);
                  getNoOfDays('fromDate')
                }}
                showToday={true} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="leaveFromDay"
              label="Leave From Day"
              rules={[
                {
                  required: true,
                  message: "Enter a valid Day",
                  max: 15
                }
              ]}>
              {<Select
                showSearch
                onChange={() => getNoOfDays('')}
                placeholder="Select Leave To Day"
              >
                <Option key={0} value={null}>Select Leave To Day</Option>
                <Option key={1} value={'First Half'}>First Half</Option>
                <Option key={2} value={'Second Half'}>Second Half</Option>
                <Option key={3} value={'Full Day'}>Full Day</Option>
              </Select>}
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="toDate" label="To Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={() => getNoOfDays('toDate')}
                showToday={true}
              // disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="leaveToDay"
              label="Leave To Day"
              rules={[
                {
                  required: true,
                  message: "Enter a valid Day",
                  max: 15
                }
              ]}>
              {<Select
                showSearch
                onChange={() => getNoOfDays('')}
                placeholder="Select Leave To Day"
              >
                <Option key={0} value={null}>Select Leave To Day</Option>
                <Option key={1} value={'First Half'}>First Half</Option>
                <Option key={2} value={'Second Half'}>Second Half</Option>
                <Option key={3} value={'Full Day'}>Full Day</Option>
              </Select>}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="noOfDays"
              label="No of Leave days"
              rules={[
                {
                  required: true
                },

              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="leaveReason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: " Reason is required",max:25
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="leaveAddress"
              label="Leave Address"
              rules={[
                {

                  required: false,
                  max: 400
                  //message: " Address is required",
                  // pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                },

              ]}>

              <TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {/* {(props.isUpdate === true) && */}

            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
            {/* } */}
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ApplyForLeavesForm;
