import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col, InputNumber  } from 'antd';
import { OperationService} from '@gtpl/shared-services/production'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import { PlantsDropDown} from '@gtpl/shared-models/masters';
import { UnitcodeService} from '@gtpl/shared-services/masters';
import { PlantOperationCapacityDto } from '@gtpl/shared-models/masters';
import { PlantOperationCapacityService} from '@gtpl/shared-services/masters';
import { OperationsDropDown} from '@gtpl/shared-models/production-management';
const { TextArea } = Input;
export interface PlantOperationCapacityFormProps {
plantoperationcapacityData:PlantOperationCapacityDto;
updateplantoperationcapacity:(plantoperationcapacity:PlantOperationCapacityDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;

}

export function PlantOperationCapacityForm(props: PlantOperationCapacityFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService();
  const operationService = new OperationService
  const { Option } = Select;
  const service= new  PlantOperationCapacityService;
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  useEffect(() => {
    getAllPlants();
    getAllOperations();
  }, []);
  
  const create = (Data: PlantOperationCapacityDto) => {
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
     AlertMessages.getSuccessMessage('Created Successfully');
         
     history.push("/Plant-operation-capacity-view")
     onReset();
   } else {
     if (res.intlCode) {
       console.log("hhh")
       AlertMessages.getWarningMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   console.log(err);
   AlertMessages.getErrorMessage(err.message);
 })
}
  const getAllOperations = () => {
    operationService.getAllOperationsDropDown().then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllPlants = () => {
    unitsService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const saveData = (values:  PlantOperationCapacityDto) => {
    setDisable(false)
    console.log(values);
    if(props.isUpdate){
      props.updateplantoperationcapacity(values);
    }else{
      setDisable(false)
      create(values);
      // console.log('test')
    }
  
  };

  const onReset=()=>{
    form.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  return (
  

    <Card title={<span style={{color:'white'}}>Plant Operation Capacity</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/plant-operation-capacity-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form}         
           name="control-hooks"           
          layout="vertical"
          initialValues={props.plantoperationcapacityData}
          onFinish={saveData}
          >
    
          <Form.Item name="PlantOperationCapacityId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row>
             <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>

            <Form.Item
                name="plantId"
                label="Plant "
                rules={[
                  {
                    required: true,message:'Plant  is required'
                    
                  },
                  
                ]}>
                   <Select
                  showSearch
                  placeholder="Select Plant "
                  optionFilterProp="children"

                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* <Option value={undefined}>please select Plant</Option>; */}
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                  })}
                </Select>
               
              </Form.Item>
        </Col>

        
          <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="operationId"
                  label="Operation"
                  rules={[
                    {
                      required: true,message:'Operation is required'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                     <Select
                showSearch
                placeholder="Select Operation"
                optionFilterProp="children"
                // onChange={handleOperation}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Operations</Option>
                {operations.map((workstationCategory) => {
                  return <Option key={workstationCategory.operationId} value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                })}
              </Select>
                  {/* <Input />  */}
            </Form.Item>
          </Col>


          <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="mincount"
                  label="count 1"
                  rules={[
                    {
                      required: true,message:'value should be <= 50'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    }
                  ]}>
                  <Input type='number'/> 
            </Form.Item>
          </Col>


          <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="maxcount"
                  label="count 2"
                  rules={[
                    {
                      required: true,message:'value should be > 50'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    }
                  ]}>
                  <Input type='number' /> 
            </Form.Item>
          </Col>
            </Row>

            
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary"disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate!==false) &&
       <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
          Reset
        </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
      );
}

export default PlantOperationCapacityForm;