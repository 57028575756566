import { Card, Col, Collapse, Form, Radio, Row, Select, Table } from "antd";
import { LocationSaleOrderStatus } from "./location-wise-sale-order";
import { TotalSaleOrders } from "./sale-order";
import './dashboard.css'
import React, { useEffect, useState } from "react";
import Donut from "./donut";
import Barchart from "./barchat";
import Line from "./line";
import Column from "./column";
import Highdonut from "./donut";
import Highline from "./line";
import Highbar from "./barchat";
import Highcolumn from "./column";
import CardComponent from "./dashboard-components/card-component";
import { DatePicker } from 'antd';
import { BarChartRequest, CardBodyDetails, CardDetail, CardHeader, CardHeaderBody, DohountRequest, Highlinereq, HighColumn, ChartSeries, HighChartReq, TitleValue, SoCountDetailResponse, SoQntDetailResponse, SoLongRunResponse, SoComparisonDetailsResponse, SoDeadStockDetailsResponse, SoYearWisePlanningDetailsResponse, SoYearWiseShippedDetailsResponse, SoProductionStatusDetailsResponse, SoProductCompleteNotShippedResponse, SoCountDetail, SoQntDetail, UserDateRequest, SoLongRunDetails, SoComparisonDetails, SoProductionStatusDetails, SoYearWisePlanningDetails, SoProductCompleteNotShipped, SoDeadStockDetails, SoYearWiseShippedDetails, SoTopFiveDetails, UnitRequest, EndCustomerReq, SoLifeCycleStatusEnum, } from "@gtpl/shared-models/common-models";
import { LineSeries } from "libs/shared-models/common-models/src/lib/dashboards/line-series";
import { ColumnRequest } from "@gtpl/shared-models/masters";
import Highchartbar from "./highchart";
import { SaleOrderService } from "@gtpl/shared-services/sale-management";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import { DestinationService } from "@gtpl/shared-services/masters";
import { DestinationDto } from "@gtpl/shared-models/masters";
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import type { RadioChangeEvent } from 'antd';
import { useHistory } from "react-router-dom";
import form from "antd/lib/form";






export const ModuleWiseDashboard = () => {
    const [saleOrderCountDetail, setSaleOrderCountDetail] = useState<SoCountDetail[]>([]);
    const [saleOrderQtyDetail, setSaleOrderQtyDetail] = useState<SoQntDetail[]>([]);
    const [longRunSaleOrdersDetail, setLongRunSaleOrdersDetail] = useState<SoLongRunDetails[]>([]);
    const [longRunSoCount, setLongRunSoCount] = useState<any[]>([]);
    const [saleOrderProductionInprogressDetail, setSaleOrderProductionInprogressDetail] = useState<SoProductionStatusDetails[]>([]);
    const [monthWiseSoCountDetail, setMonthWiseSoCountDetail] = useState<SoYearWisePlanningDetails[]>([]);
    const [soProductionCompleteNotShippedDetail, setSoProductionCompleteNotShippedDetail] = useState<SoProductCompleteNotShipped[]>([]);
    const [saleOrdersDeadStockDetail, setSaleOrdersDeadStockDetail] = useState<SoDeadStockDetails[]>([]);
    const [saleOrdersComparisonDetail, setSaleOrdersComparisonDetail] = useState<SoComparisonDetails[]>([]);
    const [saleOrderYearWiseShippedDetail, setSaleOrderYearWiseShippedDetail] = useState<SoYearWiseShippedDetails[]>([]);
    const [topFiveSaleOrdersDetail, setTopFiveSaleOrdersDetail] = useState<SoTopFiveDetails[]>([]);
    const [soLifeCycleStatusCount, setSoLifeCycleStatusCount] = useState<any[]>([]);
    const [endcustomerWiseMonthlySOCount, setendcustomerWiseMonthlySOCount] = useState<any[]>([]);
    const saleOrderService = new SaleOrderService()
    const { RangePicker } = DatePicker;
    const [selectedFromDate, setSelectedFromDate] = useState(undefined);
    const [selectedToDate, setSelectedToDate] = useState(undefined);
    const [endCustomers, setEndCustomers] = useState<any[]>([]);
    const [selectedEndCustomers, setSelectedEndCustomers] = useState<any>();
    const [size, setSize] = useState<SizeType>('middle');
    const [hasTimeElapsed, setHasTimeElapsed] = useState(false);
    const { Panel } = Collapse;
    const [selectedDate, setSelectedDate] = useState<any[]>([])
    const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
    const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);

    const [form] = Form.useForm();
    let history = useHistory()
    // const timeoutRef = setTimeout(() => {
    //     history.push('/down-time-dashboard')
    // }, 120000);
    // console.log(timeoutRef,'ref')

    useEffect(() => {
        if (endcustomerWiseMonthlySOCount) {
            console.log(endcustomerWiseMonthlySOCount)
        }

    }, [endcustomerWiseMonthlySOCount])

    const { Option } = Select;

    useEffect(() => {
        getSaleOrdersCountAndQtyDetail();
        getLongRunSaleOrdersDetail();
        getSaleOrderProductionStatusDetail();
        getSaleOrderYearWisePlannedDetail([(moment(moment().format("YYYY-MM-DD")).subtract(2, 'months')), moment(moment().format("YYYY-MM-DD"))]);
        getSoProductionCompleteNotShippedDetail();
        getSaleOrdersDeadStockDetail();
        getSaleOrdersComparisonDetail();
        getSaleOrderYearWiseShippedDetail();
        getTopFiveSaleOrdersDetail();
        getActiveEndCustomersDropDownData();
        getSoLifeCycleStatusCount();
        getendcustomerWiseMonthlySOCount();
        getLongRunSoCount();


        form.setFieldsValue({
            planVsShipRange: [(moment(moment().format("YYYY-MM-DD")).subtract(2, 'months')), moment(moment().format("YYYY-MM-DD"))]

        })
        setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(2, 'months')))
        setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
    }, [])

    const EstimatedETDDate = (value) => {
        if (value) {
            const fromDate = new Date(value[0].format('YYYY-MM-DD'));
            const toDate = new Date(value[1].format('YYYY-MM-DD'));
            setSelectedEstimatedFromDate(fromDate)
            setSelectedEstimatedToDate(toDate)
        }
    }

    const getSaleOrdersCountAndQtyDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getSaleOrdersCounDetail(reqObj).then((res) => {
            if (res.status) {
                setSaleOrderCountDetail(res.data);
                saleOrderService.getSaleOrdersQtyDetail(reqObj).then((res) => {
                    if (res.status) {
                        setSaleOrderQtyDetail(res.data);
                    } else {
                        setSaleOrderQtyDetail([]);
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message);
                    setSaleOrderQtyDetail([]);
                })
            } else {
                setSaleOrderCountDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderCountDetail([]);
        });
    }

    const getLongRunSaleOrdersDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getLongRunSaleOrdersDetail(reqObj).then((res) => {
            if (res.status) {
                setLongRunSaleOrdersDetail(res.data);
            } else {
                setLongRunSaleOrdersDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setLongRunSaleOrdersDetail([]);
        })
    }
    const getLongRunSoCount = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getLongRunSoCount(reqObj).then((res) => {
            if (res.status) {
                setLongRunSoCount(res.data);
            } else {
                setLongRunSoCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setLongRunSoCount([]);
        })
    }

    const getSaleOrdersComparisonDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getSaleOrdersComparisonDetail(reqObj).then((res) => {
            if (res.status) {
                setSaleOrdersComparisonDetail(res.data);
            } else {
                setSaleOrdersComparisonDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrdersComparisonDetail([]);
        })
    }
    const getSaleOrderProductionStatusDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = (selectedFromDate);
        reqObj.toDate = (selectedToDate);
        reqObj.userName = 'User';
        saleOrderService.getSaleOrderProductionStatusDetail(reqObj).then((res) => {
            if (res.status) {
                setSaleOrderProductionInprogressDetail(res.data);
            } else {
                setSaleOrderProductionInprogressDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderProductionInprogressDetail([]);
        })
    }

    const getSaleOrderYearWisePlannedDetail = (rangeDate ? : any) => {
        const reqObj = new UserDateRequest();
            reqObj.fromDate = rangeDate ? moment(rangeDate[0]).format('YYYY-MM-DD') : moment(form.getFieldValue('planVsShipRange')[0]).format('YYYY-MM-DD');
            reqObj.toDate = rangeDate ?  moment(rangeDate[1]).format('YYYY-MM-DD') : moment(form.getFieldValue('planVsShipRange')[1]).format('YYYY-MM-DD');

        reqObj.userName = 'User';
        saleOrderService.getSaleOrderYearWisePlannedDetail(reqObj).then((res) => {
            if (res.status) {
                setMonthWiseSoCountDetail(res.data);
            } else {
                setMonthWiseSoCountDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setMonthWiseSoCountDetail([]);
        })
    }
    const getSoProductionCompleteNotShippedDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = (selectedFromDate);
        reqObj.toDate = (selectedToDate);
        reqObj.userName = 'User';
        saleOrderService.getSoProductionCompleteNotShippedDetail(reqObj).then((res) => {
            if (res.status) {
                setSoProductionCompleteNotShippedDetail(res.data);
            } else {
                setSoProductionCompleteNotShippedDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSoProductionCompleteNotShippedDetail([]);
        })
    }
    const getSaleOrdersDeadStockDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getSaleOrdersDeadStockDetail(reqObj).then((res) => {
            if (res.status) {
                setSaleOrdersDeadStockDetail(res.data);
            } else {
                setSaleOrdersDeadStockDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrdersDeadStockDetail([]);
        })
    }
    const getSaleOrderYearWiseShippedDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';

        reqObj.userName = 'User';
        saleOrderService.getSaleOrderYearWiseShippedDetail(reqObj).then((res) => {
            if (res.status) {
                setSaleOrderYearWiseShippedDetail(res.data);
            } else {
                setSaleOrderYearWiseShippedDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderYearWiseShippedDetail([]);
        })
    }
    const getTopFiveSaleOrdersDetail = () => {
        const reqObj = new UserDateRequest();
        reqObj.fromDate = '2022-04-01';
        reqObj.toDate = '2023-03-31';
        reqObj.userName = 'User';
        saleOrderService.getTopFiveSaleOrdersDetail(reqObj).then((res) => {
            if (res.status) {
                setTopFiveSaleOrdersDetail(res.data);
            } else {
                setTopFiveSaleOrdersDetail([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setTopFiveSaleOrdersDetail([]);
        })
    }
    const getActiveEndCustomersDropDownData = () => {
        saleOrderService.getEndCustDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.status) {
                setEndCustomers(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setEndCustomers([]);
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setEndCustomers([]);
            });
    }
    const getSoLifeCycleStatusCount = () => {

        saleOrderService.getSoLifeCycleStatusCount({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.status) {
                setSoLifeCycleStatusCount(res.data);
            } else {
                setSoLifeCycleStatusCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSoLifeCycleStatusCount([]);
        })
    }

    const getendcustomerWiseMonthlySOCount = () => {
        const unitId = Number(localStorage.getItem('unit_id'));
        const fromDate = (selectedFromDate);
        const toDate = (selectedToDate);
        const req = new EndCustomerReq(selectedEndCustomers, fromDate, toDate, unitId == 5 ? null : unitId);

        saleOrderService.endcustomerWiseMonthlySOCount(req).then((res) => {
            if (res.status) {
                setendcustomerWiseMonthlySOCount(res.data);
            } else {
                setendcustomerWiseMonthlySOCount([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setendcustomerWiseMonthlySOCount([]);
        })
    }


    // real time apis calling
    const unitsArray = ['K', 'U-1', 'U-2'];
    // const longRunSaleOrdersDetail: SoLongRunResponse = null;
    // const saleOrdersComparisonDetail: SoComparisonDetailsResponse = null;
    // const saleOrdersDeadStockDetail: SoDeadStockDetailsResponse = null;
    const saleOrderYearWisePlannedDetail: SoYearWisePlanningDetailsResponse = null;
    // const monthWiseSoCountDetail = saleOrderYearWisePlannedDetail.data;
    // const saleOrderYearWiseShippedDetail: SoYearWiseShippedDetailsResponse = null;
    // const saleOrderProductionStatusDetail: SoProductionStatusDetailsResponse = null;
    // const soProductionCompleteNotShippedDetail: SoProductCompleteNotShippedResponse = null;

    const unitWiseOpenSoCount = new Map<string, number>();
    const unitWiseShippedSoCount = new Map<string, number>();
    const unitWiseCanceledSoCount = new Map<string, number>();
    const unitWiseTotalSoCount = new Map<string, number>();


    const unitWiseOpenSoQty = new Map<string, number>();
    const unitWiseShippedSoQty = new Map<string, number>();
    const unitWiseCanceledSoQty = new Map<string, number>();
    const unitWiseTotalSoQty = new Map<string, number>();
    for (const unit of unitsArray) {
        let totalCount = 0;
        let totalQty = 0;
        // constructing count details for each unit
        if (!unitWiseOpenSoCount.has(unit)) {
            unitWiseOpenSoCount.set(unit, 0);
        }
        if (!unitWiseShippedSoCount.has(unit)) {
            unitWiseShippedSoCount.set(unit, 0);
        }
        if (!unitWiseCanceledSoCount.has(unit)) {
            unitWiseCanceledSoCount.set(unit, 0);
        }
        if (!unitWiseTotalSoCount.has(unit)) {
            unitWiseTotalSoCount.set(unit, 0);
        }
        const unitSoDetail: SoCountDetail[] = saleOrderCountDetail.filter(sale => sale.plant_code == unit);
        for (const unitSo of unitSoDetail) {
            const preUnitWiseOpenSoCount = unitWiseOpenSoCount.get(unit);
            const preUnitWiseShippedSoCount = unitWiseShippedSoCount.get(unit);
            const preUnitWiseCancelledSoCount = unitWiseCanceledSoCount.get(unit);
            unitWiseOpenSoCount.set(unit, preUnitWiseOpenSoCount + Number(unitSo.openSos));
            unitWiseShippedSoCount.set(unit, preUnitWiseShippedSoCount + Number(unitSo.closesSos));
            unitWiseCanceledSoCount.set(unit, preUnitWiseCancelledSoCount + Number(unitSo.cancelledSos));
            totalCount += Number(unitSo.closesSos);
            totalCount += Number(unitSo.openSos);
            totalCount += Number(unitSo.cancelledSos);
        }

        // constructing Qty details for each unit
        if (!unitWiseOpenSoQty.has(unit)) {
            unitWiseOpenSoQty.set(unit, 0);
        }
        if (!unitWiseShippedSoQty.has(unit)) {
            unitWiseShippedSoQty.set(unit, 0);
        }
        if (!unitWiseCanceledSoQty.has(unit)) {
            unitWiseCanceledSoQty.set(unit, 0);
        }
        const unitSoQtyDetail: SoQntDetail[] = saleOrderQtyDetail.filter(sale => sale.plant_code == unit);
        for (const unitSo of unitSoQtyDetail) {
            const preUnitWiseOpenSoQty = unitWiseOpenSoQty.get(unit);
            const preUnitWiseShippedSoQty = unitWiseShippedSoQty.get(unit);
            const preUnitWiseCancelledSoQty = unitWiseCanceledSoQty.get(unit);
            unitWiseOpenSoQty.set(unit, preUnitWiseOpenSoQty + Number(unitSo.openSos));
            unitWiseShippedSoQty.set(unit, preUnitWiseShippedSoQty + Number(unitSo.closesSos));
            unitWiseCanceledSoQty.set(unit, preUnitWiseCancelledSoQty + Number(unitSo.cancelledSos));
            totalQty += Number(unitSo.closesSos);
            totalQty += Number(unitSo.openSos);
            totalQty += Number(unitSo.cancelledSos);
        }
        unitWiseTotalSoCount.set(unit, totalCount);
        unitWiseTotalSoQty.set(unit, totalQty);
    }
    // constructing Open Card
    const cardBodyDetailOpen: CardBodyDetails[] = [];
    const cardBodyDetailShipped: CardBodyDetails[] = [];
    const cardBodyDetailCancelled: CardBodyDetails[] = [];
    const cardBodyDetailTotal: CardBodyDetails[] = [];

    let openSoCountTotal = 0;
    let shippedSoCountTotal = 0;
    let cancelledCountTotal = 0;
    let totalCountTotal = 0;

    let openSoQtyTotal = 0;
    let shippedSoQtyTotal = 0;
    let cancelledQtyTotal = 0;
    let totalQtyTotal = 0;

    unitsArray.forEach((unit) => {
        const openSoCount = unitWiseOpenSoCount.get(unit);
        const openSoQty = unitWiseOpenSoQty.get(unit);
        openSoCountTotal += openSoCount;
        openSoQtyTotal += openSoQty;

        const shippedSoCount = unitWiseShippedSoCount.get(unit);
        const shippedSoQty = unitWiseShippedSoQty.get(unit);
        shippedSoCountTotal += shippedSoCount;
        shippedSoQtyTotal += shippedSoQty;


        const cancelledSoCount = unitWiseCanceledSoCount.get(unit);
        const cancelledSoQty = unitWiseCanceledSoQty.get(unit);
        cancelledCountTotal += cancelledSoCount;
        cancelledQtyTotal += cancelledSoQty;

        const totalSoCount = unitWiseTotalSoCount.get(unit);
        const totalSoQty = unitWiseTotalSoQty.get(unit);
        totalCountTotal += totalSoCount;
        totalQtyTotal += totalSoQty;

        const openSoCardBody = new CardBodyDetails(unit, openSoCount.toString(), openSoQty.toString());
        cardBodyDetailOpen.push(openSoCardBody);

        const shippedSoCardBody = new CardBodyDetails(unit, shippedSoCount.toString(), shippedSoQty.toString());
        cardBodyDetailShipped.push(shippedSoCardBody);

        const cancelledSoCardBody = new CardBodyDetails(unit, cancelledSoCount.toString(), cancelledSoQty.toString());
        cardBodyDetailCancelled.push(cancelledSoCardBody);

        const totalSoCardBody = new CardBodyDetails(unit, totalSoCount.toString(), totalSoQty.toString());
        cardBodyDetailTotal.push(totalSoCardBody);
    });

    const unitWiseDetail = saleOrderProductionInprogressDetail.filter(so => Number(so.completedPercentage) <= 30);
    let soBelow30Count = 0;
    for (const unitDetail of unitWiseDetail) {
        soBelow30Count += Number(unitDetail.orderCases);
    }
    const openSoCardBody1 = new CardHeaderBody('0-30 %', soBelow30Count.toString());

    const unitWiseDetailFor60To90 = saleOrderProductionInprogressDetail.filter((so) => {
        return Number(so.completedPercentage) > 30 && Number(so.completedPercentage) >= 60
    });
    let soBelow60Count = 0;
    for (const unitDetail of unitWiseDetailFor60To90) {
        soBelow60Count += Number(unitDetail.orderCases);
    }

    const openSoCardBody2 = new CardHeaderBody('30 - 60 %', soBelow60Count.toString());

    const unitWiseDetailFor99 = saleOrderProductionInprogressDetail.filter((so) => {
        return Number(so.completedPercentage) > 60 && Number(so.completedPercentage) >= 99
    });
    let soBelow99Count = 0;
    for (const unitDetail of unitWiseDetailFor99) {
        soBelow99Count += Number(unitDetail.orderCases);
    }

    const openSoCardBody3 = new CardHeaderBody('60 - 90%', soBelow99Count.toString());

    const doNutReq = new DohountRequest('InProgress S.O Details', 'S.O Count', [openSoCardBody1, openSoCardBody2, openSoCardBody3]);
    // high chart data preparation
    const finMonthTextMap = new Map<number, string>();
    finMonthTextMap.set(1, 'Jan');
    finMonthTextMap.set(2, 'Feb');
    finMonthTextMap.set(3, 'Mar');
    finMonthTextMap.set(4, 'Apr');
    finMonthTextMap.set(5, 'May');
    finMonthTextMap.set(6, 'Jun');
    finMonthTextMap.set(7, 'Jul');
    finMonthTextMap.set(8, 'Aug');
    finMonthTextMap.set(9, 'Sep');
    finMonthTextMap.set(10, 'Oct');
    finMonthTextMap.set(11, 'Nov');
    finMonthTextMap.set(12, 'Dec');
    const unitMonthWiseCountMap = new Map<string, number[]>();
    const monthsNamesArray = new Set<string>();
    const seriesDetails: TitleValue[] = [];
    const lineSeriesForPlanned: CardHeaderBody[] = [];
    const lineSeriesForShipped: CardHeaderBody[] = [];
    const currentYrForPlanned: CardHeaderBody[] = [];
    const preYrForPlanned: CardHeaderBody[] = [];
    const endCustomer: CardHeaderBody[] = [];
    const monthPlanned = new Map<number, number>();
    const endCustomerMap = new Map<number, number>();
    const monthShipped = new Map<number, number>();
    for (const unit of unitsArray) {
        if (!unitMonthWiseCountMap.has(unit)) {
            unitMonthWiseCountMap.set(unit, []);
        }
        const monthCountOpenArray = [];
        const monthCountRecArray = [];
        const monthCountShippedArray = [];
        for (const [monthNumber, month] of finMonthTextMap) {
            if (!monthPlanned.has(monthNumber)) {
                monthPlanned.set(monthNumber, 0);
            }
            if (!monthShipped.has(monthNumber)) {
                monthShipped.set(monthNumber, 0);
            }
            monthsNamesArray.add(month);
            const monthWiseUnitSoCountDetailForOpen = monthWiseSoCountDetail.filter((so) => {
                return so.plantCode == unit && Number(so.finMonth) == monthNumber
            });
            let monthCountOpen = 0;
            let monthCountRec = 0;
            let monthCountShipped = 0;
            if (monthWiseUnitSoCountDetailForOpen.length) {
                for (const monthSo of monthWiseUnitSoCountDetailForOpen) {
                    monthCountOpen += Number(monthSo.openSos);
                    monthCountShipped += Number(monthSo.closesSos);
                    monthCountRec += Number(monthSo.openSos) + Number(monthSo.closesSos);
                }
            }
            // if(endcustomerWiseMonthlySOCount.length > 0) {
            //     console.log(endcustomerWiseMonthlySOCount)
            //     for(const item of endcustomerWiseMonthlySOCount){
            //         if(!endCustomerMap.has(monthNumber)){
            //             // if(month == item.monthName){
            //                 endCustomerMap.set(monthNumber,Number(item.soCount))
            //             // }
            //         }

            //     }
            //     console.log(endCustomerMap)
            // }
            const preMonthPlanned = monthPlanned.get(monthNumber);
            const preMonthShipped = monthShipped.get(monthNumber);
            monthPlanned.set(monthNumber, preMonthPlanned + monthCountRec);
            monthShipped.set(monthNumber, preMonthShipped + monthCountShipped);
            monthCountOpenArray.push(monthCountOpen);
            monthCountRecArray.push(monthCountRec);
            monthCountShippedArray.push(monthCountShipped);
        }
        seriesDetails.push(new TitleValue(unit, monthCountRecArray, 'Received Count'));
        seriesDetails.push(new TitleValue(unit, monthCountShippedArray, 'Shipped Count'));
        seriesDetails.push(new TitleValue(unit, monthCountOpenArray, 'Planned Count'));
    }
    

    const highChartReqes = new HighChartReq('Trend of S.O Received, Planned and Shipped', Array.from(monthsNamesArray), 'NO. of Sale Orders', seriesDetails);

    for (const [montNum, count] of monthPlanned) {
        lineSeriesForPlanned.push(new CardHeaderBody('PLANNED QUANTITY', count.toString()));
        currentYrForPlanned.push(new CardHeaderBody('Current Year', count.toString()));
        const preCount = 0;
        const soCount = 0;
        preYrForPlanned.push(new CardHeaderBody('Previous Year', preCount.toString()));

    }
    for (const [montNum, count] of monthShipped) {
        lineSeriesForShipped.push(new CardHeaderBody('SHIPPED QUANTITY', count.toString()));
    }

    const lineSeries1 = new LineSeries(lineSeriesForPlanned);

    const lineSeries2 = new LineSeries(lineSeriesForShipped);

    const highlinerequest = new Highlinereq('Trend of Planned vs Shipped', '{value}', Array.from(monthsNamesArray), '', [lineSeries1, lineSeries2]);

    const lineSeriesYear1 = new LineSeries(currentYrForPlanned);
    const lineSeriesyear2 = new LineSeries(preYrForPlanned);
    console.log(monthsNamesArray);
    const highColumnreaqest = new HighColumn('Trend of S.Os Prev Yr Vs Current Year', Array.from(monthsNamesArray), 'Total Number Of Sale Order Count', [lineSeriesYear1, lineSeriesyear2])
    //end customer wise total So's
    console.log(endcustomerWiseMonthlySOCount);


    if (endcustomerWiseMonthlySOCount.length > 0) {
        for (const monthsName of monthsNamesArray) {
            console.log(monthsName)
            console.log(endcustomerWiseMonthlySOCount[0])
            console.log(endcustomerWiseMonthlySOCount[0].Nov)
            const soCountVal = endcustomerWiseMonthlySOCount[0][monthsName]
            endCustomer.push(new CardHeaderBody('So Count', soCountVal));
            console.log(soCountVal);
        }
    }
    // for (const soCount of endcustomerWiseMonthlySOCount) {
    //     const soCountVal = soCount.soCount
    //     console.log(soCount.soCount);
    //     endCustomer.push(new CardHeaderBody('So Count', soCountVal.toString()));
    // }
    console.log(endCustomer)
    const lineSeriesEndCusSo = new LineSeries(endCustomer);
    const highEndreaqest = new HighColumn('End Customer Wise Total So', Array.from(monthsNamesArray), 'Total Number Of Sale Orders', [lineSeriesEndCusSo])

    // completed but not shipped card

    const cardBodyForCompletedButNotShipped: CardBodyDetails[] = [];
    let completedButNotShippedCount = 0;
    let completedButNotShippedQty = 0;
    for (const productInfo of soProductionCompleteNotShippedDetail) {
        cardBodyForCompletedButNotShipped.push(new CardBodyDetails(productInfo.poNumbr, Number(productInfo.orderCases).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.fgCases).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })));
        completedButNotShippedCount += Number(productInfo.orderCases);
        completedButNotShippedQty += Number(productInfo.fgCases);
    }

    const completedButNotShippedBodyCount = new CardHeaderBody('Total Count', Number(completedButNotShippedCount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
    const completedButNotShippedBodyQty = new CardHeaderBody('Total Qty', Number(completedButNotShippedQty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
    const completedButNotShippedBodyCardHeader = new CardHeader('SO Completed But Not Shipped', [completedButNotShippedBodyCount, completedButNotShippedBodyQty]);

    const completedButNotShippedCard = new CardDetail(completedButNotShippedBodyCardHeader, cardBodyForCompletedButNotShipped, ['SO', 'Count', 'Qty']);


    // Top 5 lONG RUN SO (skipped Sale Orders)

    const cardBodyForlongRunSo: CardBodyDetails[] = [];
    let maxInvDate = '';
    let maxActEndDate = '';
    let soCount = 0;
    
    for (const productInfo of longRunSaleOrdersDetail) {
        const invDateFormatted = productInfo.invoiceDate ? moment(productInfo.invoiceDate).format('YYYY-MM-DD') : '';
        const actDateFormatted = productInfo.actEdlDate ? moment(productInfo.actEdlDate).format('YYYY-MM-DD') : '';
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(invDateFormatted);
        const secondDate = new Date(actDateFormatted);

        const diffDays = Math.round(Math.abs((secondDate.valueOf() - new Date().getTime())) / oneDay);
        cardBodyForlongRunSo.push(new CardBodyDetails(productInfo.poNumber, invDateFormatted, actDateFormatted, diffDays ? diffDays.toString() : ''));
        maxInvDate = productInfo.invoiceDate ? moment(productInfo.invoiceDate).format('MM-DD') : '';
        maxActEndDate = productInfo.actEdlDate ? moment(productInfo.actEdlDate).format('MM-DD') : '';
        console.log('hhhah',soCount); 
        soCount = soCount + 1
    }
    const longRunSoBodyCount = new CardHeaderBody(null);
    const longRunSoBodyQty = new CardHeaderBody(null);
    const longRunSoBodyCardHeader = new CardHeader('Skipped Sale Orders', [longRunSoBodyCount, longRunSoBodyQty],'skipped-saleorders',' Total Skipped So Count',Number(longRunSoCount[0]?.poNumber).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
    const longRunSoCard = new CardDetail(longRunSoBodyCardHeader, cardBodyForlongRunSo, ['SO', 'Po Date', ' ETD', 'Aging'],false);
    //top 5 selling So (Fast Moving Product)
    const cardBodyForTopFiveSo: CardBodyDetails[] = [];
    let maxiInvDate = '';
    let maxiActEndDate = '';
    for (const productInfo of topFiveSaleOrdersDetail) {
        cardBodyForTopFiveSo.push(new CardBodyDetails(productInfo.variantCode, productInfo.count,));
    }
    const topFiveSoBodyCount = new CardHeaderBody(null);
    const topFiveSoBodyQty = new CardHeaderBody(null);
    const topFiveSoBodyCardHeader = new CardHeader('Fast Moving Product', [topFiveSoBodyCount, topFiveSoBodyQty]);

    const FastMovingProd = new CardDetail(topFiveSoBodyCardHeader, cardBodyForTopFiveSo, ['Product', ' Product Count',],false);
    // SoLifeCycleStatusCount
    const cardBodyForSoLifeCycleStatusCount: CardBodyDetails[] = [];
    let LifeCycleStatusCount = 0;
    let kakinadaCount = 0;
    let unitOneCount = 0;
    let unitTwoCount = 0;
    const lifeCycleMeaning = [{ lifeCycle: SoLifeCycleStatusEnum.Risk1, meaning: '(SO ETD-Processing Days >PO EDD)' }, { lifeCycle: SoLifeCycleStatusEnum.PARTIALLY_COMPLETED, meaning: '(100% Quantity Not In W/H)' }, { lifeCycle: SoLifeCycleStatusEnum.READY_FOR_INSPECTION, meaning: '(70% Production Completed Inspection Not Triggered)' }, { lifeCycle: SoLifeCycleStatusEnum.PENDING_FOR_INSPECTION, meaning: '(70% Production Completed Inspection Not Done)' }, { lifeCycle: SoLifeCycleStatusEnum.RISK2, meaning: '(Lead Time 12 Days)' }, { lifeCycle: SoLifeCycleStatusEnum.EARLY_SHIPMENT, meaning: '(Shipped Before ETD)' }]
    for (const productInfo of soLifeCycleStatusCount) {
        const lifeCycleStatus =  (lifeCycleMeaning.find(i => i.lifeCycle == productInfo.life_cycle_status)?.meaning) != undefined  ? ( lifeCycleMeaning.find(i => i.lifeCycle == productInfo.life_cycle_status)?.meaning): ''
        cardBodyForSoLifeCycleStatusCount.push(new CardBodyDetails((productInfo.life_cycle_status + lifeCycleStatus), Number(productInfo.kakinada).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit1).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.unit2).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), String(Number(productInfo.kakinada) + Number(productInfo.unit1) + Number(productInfo.unit2))));
        kakinadaCount = kakinadaCount + Number(productInfo.kakinada)
        unitOneCount = unitOneCount + Number(productInfo.unit1);
        unitTwoCount = unitTwoCount + Number(productInfo.unit2);
    }
    LifeCycleStatusCount = Number(kakinadaCount + unitOneCount + unitTwoCount);

    const soLifeCycleStatusCountBodyStatus = new CardHeaderBody(null);
    const soLifeCycleStatusCountBodyCount = new CardHeaderBody(null);
    const soLifeCycleStatusCountBodyCardHeader = new CardHeader('So Life Cycle Status Count',[soLifeCycleStatusCountBodyStatus,soLifeCycleStatusCountBodyStatus],'Sales','Total Count',Number(LifeCycleStatusCount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));

    const soLifeCycleStatusCountSoCard = new CardDetail(soLifeCycleStatusCountBodyCardHeader, cardBodyForSoLifeCycleStatusCount, ['Status', 'KKD', 'U1', 'U2', 'Total'],false);
    // Top 5 Dead stock Products

    const cardBodyForDeadStockPro: CardBodyDetails[] = [];
    let deadStockCount = 0;
    let deadStockQty = 0;
    for (const productInfo of saleOrdersDeadStockDetail) {
        const invDateFormatted = productInfo.date ? moment(productInfo.date).format('YYYY-MM-DD') : '';
        cardBodyForDeadStockPro.push(new CardBodyDetails(productInfo.variantCode,invDateFormatted, Number(productInfo.days).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), Number(productInfo.weightInLbs).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })));
        deadStockCount += Number(productInfo.days);
        deadStockQty += Number(productInfo.weightInLbs);
    }
    const DeadStockProBodyCount = new CardHeaderBody(null);
    const DeadStockProBodyQty = new CardHeaderBody(null);
    const DeadStockProBodyCardHeader = new CardHeader('Dead Stock Products', [DeadStockProBodyCount, DeadStockProBodyQty],'dead-stock','Total weight In(LBs)',Number(deadStockQty).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));

    const DeadStockProCard = new CardDetail(DeadStockProBodyCardHeader, cardBodyForDeadStockPro, ['Product','Date', 'Age', 'Qty'],false);

    //integration for Open Cancel Inprogress cancel
    const openSoCardHeaderBody1 = new CardHeaderBody('Total Count', openSoCountTotal.toString());
    const openSoCardHeaderBody2 = new CardHeaderBody('Total Qty', openSoQtyTotal.toString());
    const openSoCardHeader = new CardHeader('Open SO Detail', [openSoCardHeaderBody1, openSoCardHeaderBody2]);

    const openSoDetails = new CardDetail(openSoCardHeader, cardBodyDetailOpen, ['Unit', 'Count', 'Qty']);

    const shippedSoCardHeaderBody1 = new CardHeaderBody('Total Count', shippedSoCountTotal.toString());
    const shippedSoCardHeaderBody2 = new CardHeaderBody('Total Qty', shippedSoQtyTotal.toString());
    const shippedSoCardHeader = new CardHeader('Shipped SO Detail', [shippedSoCardHeaderBody1, shippedSoCardHeaderBody2]);

    const shippedSoDetails = new CardDetail(shippedSoCardHeader, cardBodyDetailShipped, ['Unit', 'Count', 'Qty']);

    const cancelledSoCardHeaderBody1 = new CardHeaderBody('Total Count', cancelledCountTotal.toString());
    const cancelledSoCardHeaderBody2 = new CardHeaderBody('Total Qty', cancelledQtyTotal.toString());
    const cancelledSoCardHeader = new CardHeader('Cancelled SO Detail', [cancelledSoCardHeaderBody1, cancelledSoCardHeaderBody2]);

    const cancelledSoDetails = new CardDetail(cancelledSoCardHeader, cardBodyDetailCancelled, ['Unit', 'Count', 'Qty']);

    const totalSoCardHeaderBody1 = new CardHeaderBody('Total Count', totalCountTotal.toString());
    const totalSoCardHeaderBody2 = new CardHeaderBody('Total Qty', totalQtyTotal.toString());
    const totalSoCardHeader = new CardHeader('Total SO Detail', [totalSoCardHeaderBody1, totalSoCardHeaderBody2]);

    const totalSoDetails = new CardDetail(totalSoCardHeader, cardBodyDetailTotal, ['Unit', 'Count', 'Qty']);



    const EstimatedDate = (value) => {
        const fromDate = new Date(value[0].format('YYYY-MM-DD'));
        const toDate = new Date(value[1].format('YYYY-MM-DD'));
        setSelectedFromDate(fromDate);
        setSelectedToDate(toDate);
        console.log(value);
    }
    const handleEndCustomer = (value) => {
        console.log(value);
        setSelectedEndCustomers(value)
    }
    // const newData = JsonData.map((JsonData)=>{
    return (
        <>
            <div style={{ backgroundColor: 'white', fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro' }}>
                <Collapse defaultActiveKey={['1', '2', '4', '5']}  >
                    <Panel header={<span style={{ color: 'white', fontSize: '30px' }}>Sales</span>} key="1" style={{ backgroundColor: "orange" }}>
                        <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
                            {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={8}>
                                <CardComponent cardData={openSoDetails} />
                            </Col>
                            <Col className="cardComp" xs={24} sm={24} md={8} xl={8}>
                                <CardComponent cardData={shippedSoDetails} />
                            </Col>
                            <Col className="cardComp" xs={24} sm={24} md={6} xl={6}>
                                <CardComponent cardData={cancelledSoDetails} />
                            </Col>
                            <Col className="cardComp" xs={24} sm={24} md={6} xl={6}>
                                <CardComponent cardData={totalSoDetails} />
                            </Col> */}
                            <Col className="cardComp" xs={24} sm={24} md={8} xl={12} style={{ height: '33%' }}>
                                <CardComponent cardData={soLifeCycleStatusCountSoCard} />
                            </Col>

                            <Col className="cardComp" xs={24} sm={24} md={8} xl={12} style={{ height: '100%', marginTop: '-4px' }}>
                                <Row gutter={24}>
                                    <Col className="cardComp" xs={24} sm={24} md={8} xl={24} style={{ height: '33%' }}>
                                        <CardComponent cardData={DeadStockProCard} />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col className="cardComp" xs={24} sm={24} md={8} xl={24} style={{ height: '33%' }}>
                                        <CardComponent cardData={longRunSoCard} />
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col className="cardComp" xs={24} sm={24} md={8} xl={8} style={{ height: '100%' }}>
                                <CardComponent cardData={longRunSoCard} />
                            </Col> */}
                        </Row>
                        <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
                            <Col className="cardComp" xs={24} sm={24} md={8} xl={9} >
                                <Card className="totalsocard">
                                    <div></div>
                                    <Highdonut doNutData={doNutReq} />
                                    <br /><br />
                                </Card>
                            </Col>

                            <Col className="cardComp" xs={24} sm={24} md={16} xl={15}>


                                <Card className="totalsocard" title={<span className="title">
                                    <Form form={form}>
                                        <Form.Item name='planVsShipRange'>
                                            <RangePicker onChange={EstimatedETDDate} />
                                        </Form.Item>
                                    </Form>
                                    <Tooltip title="search">
                                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => getSaleOrderYearWisePlannedDetail()} />
                                    </Tooltip> </span>} >
                                    <Highline highlineData={highlinerequest} />
                                </Card>

                            </Col>

                        </Row>
                        <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>

                            <Col className="cardComp" xs={24} sm={24} md={8} xl={8} style={{ height: '100%' }}>
                                <CardComponent cardData={FastMovingProd} />
                            </Col>
                            <Col className="cardComp" xs={24} sm={24} md={8} xl={16}>
                                <Card className="totalsocard" title={<span className="title">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="End Customer"
                                        allowClear
                                        onSelect={handleEndCustomer}
                                        dropdownMatchSelectWidth={false}
                                    >
                                        <Option key={0} value={null}>Select End Customer</Option>
                                        {endCustomers ? endCustomers?.map(endCust => {
                                            return <Option key={endCust.endCustomerId} value={endCust.endCustomerId}>{endCust.endCustomerName}</Option>
                                        }) : ""}
                                    </Select> <Tooltip title="search">
                                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => getendcustomerWiseMonthlySOCount()} />
                                    </Tooltip></span>}>
                                    <Highcolumn columnData={highEndreaqest} />
                                </Card>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel header={<span style={{ color: 'white', fontSize: '30px' }}>Warehouse</span>} key="4" style={{ backgroundColor: "orange" }}>
                        {/* <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
                            <Col className="cardComp" xs={24} sm={24} md={8} xl={24} style={{ height: '100%' }}>
                                <CardComponent cardData={DeadStockProCard} />
                            </Col>
                        </Row> */}
                    </Panel>
                    <Panel header={<span style={{ color: 'white', fontSize: '30px' }}>Logistics</span>} key="5" style={{ backgroundColor: "orange" }}>
                        <Row gutter={24} style={{ height: '33%', backgroundColor: "#272953" }}>
                            {/* <Col className="cardComp" xs={24} sm={24} md={24} xl={24}>
                                <Card className="totalsocard" title={<span className="title"><RangePicker onChange={EstimatedDate} /> <Tooltip title="search">
                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => getSaleOrderYearWisePlannedDetail()} />
                                </Tooltip> </span>}>
                                    <Highline highlineData={highlinerequest} />
                                </Card>
                            </Col> */}
                        </Row>
                    </Panel>
                </Collapse>
            </div>
        </>
    );
}

export default ModuleWiseDashboard;