import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
// import { ExpensesDto } from '@gtpl/shared-models/masters';
// import {ExpensesService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { ExpensesCategoryService, ExpensesService } from '@gtpl/shared-services/hrms'
import { ExpensesCategoryDto, ExpensesDto } from '@gtpl/shared-models/hrms';
import { HrmsEmployeeDetailsDto, HrmsEmployeeRequest, PaymentmodesDto } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService, PaymentmodesService } from '@gtpl/shared-services/masters'
import { CorpusApprovalEnum } from '@gtpl/shared-models/common-models';
import { PayMode } from '@gtpl/shared-models/common-models';
import { request } from 'http';
const { Option } = Select
const { TextArea } = Input;

export interface ExpensesFormProps {
  expensesData: ExpensesDto;
  updateExpenses: (expenses: ExpensesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ExpensesForm(props: ExpensesFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const expCatservice = new ExpensesCategoryService()
  const service = new ExpensesService()
  const [data, setData] = useState<ExpensesCategoryDto[]>()
  const [paymentModes, setPaymentModes] = useState<PaymentmodesDto[]>()
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const empService = new HrmsEmpDetService();
  const [payData , setPayData] = useState<boolean>(false)
  const pmService = new PaymentmodesService()
  const hrmsEmpDetService = new HrmsEmpDetService();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    getAllActiveExpensesCategories();
    getPaymentModes();
  }, [])


  const onReset = () => {
    form.resetFields();
  }



  const getAllActiveExpensesCategories = () => {
    expCatservice.getAllActiveExpensesCategories().then((res) => {
      if (res.status) {
        setData(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage)
          setData([]);
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }

      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    }) 
  }

  const getPaymentModes = () => {
    pmService.getAllActivePaymentmodes().then((res) => {
      if (res.status) {
        setPaymentModes(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage)
          setPaymentModes(res.data)
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    })
  }


  const create = (data: ExpensesDto) => {
    service.createExpenses(data).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Expenses Created Sucessfully');
        history.push('expenses-view');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
        else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }

    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  useEffect(() => {
    getAllActiveEmployees();
 }, [])

  const payDataDisplay = (value) => {
    if(value == PayMode.Employee){
        setPayData(true)
    }else{
      setPayData(false)
    }
  }

  const getAllActiveEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSelectedEmployeeData = (req) => {}
  
  const saveData = (values: ExpensesDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateExpenses(values);
    } else {
      console.log(values);
      create(values);
    }
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  return (


    <Card title={<span style={{ color: 'white' }}>Expenses</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/expenses-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}
        initialValues={props.expensesData}
        name="control-hooks"
        onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="expensesId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="expensesAgainst"
              label="Expenses Against"
              rules={[
                {
                  required: true, message: 'Expenses Against Name is required'
                }
              ]}
            >
              <Select
                placeholder="Select Expenses Againse"
                allowClear
              >
                {/* <Option key={0} value={null}>Select Expenses Against</Option> */}
                <Option key={1} selected value='Corpus'>Corpus</Option>
                <Option key={2} value='Project'>Project</Option>
                <Option key={3} value='SO'>SO</Option>
                <Option key={4} value='Customer'>Customer</Option>
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="expensesCategoryId"
              label="Expenses Type"
              rules={[
                {
                  required: true, message: 'Expenses Type Name is required'

                },

              ]}>
              <Select
                showSearch
                style={{ width: '100%' }}

                placeholder="Select Expenses Type"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >

                <Option key={0} value={null}>Select Expenses Category</Option>
                {data?.map((item) => {
                  return <Option key={item.expensesCategoryId} value={item.expensesCategoryId}>{item.expensesCategory}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="paymentMode"
              label="Payment mode"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                // style={{ width: 100 }}
                placeholder="Select Payment Mode"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >

                <Option key={0} value={null}>Select Expenses Category</Option>
                {paymentModes?.map((item) => {
                  return <Option value={item.paymentMode}>{item.paymentMode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="refrenceNo"
              label="Ref-no"
              rules={[

                {
                  required: true,
                  max:8

                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Should contain only numbers.`
                }]}

            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="paidToEmployeeOrVendor"
              label="Paid To Employee Or Vendor"
              rules={[
                {
                  required: true
                }
              ]}
            >
                    {<Select
                          showSearch
                          placeholder="Please Select"
                          onChange={payDataDisplay}
                          >
                    <Option key={PayMode.Employee} value={PayMode.Employee}> {PayMode.Employee} </Option>
                    <Option key={PayMode.Vendor} value={PayMode.Vendor}> {PayMode.Vendor} </Option>
                    </Select>}
              </Form.Item>
          </Col>

          {payData ? 
              <>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: true
                }
              ]}
            >
                    {<Select
                          showSearch
                          placeholder="Please Select Employee Name"
                          onChange={getSelectedEmployeeData}
                          >
                      {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName +' '+employee.employeeLastName}</Option>
                })}
                    </Select>}
              </Form.Item>
          </Col>
          </> : <></>}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="paymentStatus"
              label="Payment status"
              rules={
                [
                  {
                    required: true
                  }
                ]
              }
            >
              <Select
                placeholder="Select Payment status"
                allowClear
              >
                <Option key={0} value={null}>Select Payment Status</Option>
                <Option key={1} selected value='Paid'>Paid</Option>
                <Option key={2} value='Not Paid'>Not Paid</Option>


              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[

                {
                  required: true,
                  max:8

                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Should contain only numbers.`
                }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="corpusApproval"
              label="Corpus Approval"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                placeholder="Select Corpus Approval"
                allowClear
              >
                <Option key={0} value={null}>Select Status</Option>
                <Option key={1} selected value={CorpusApprovalEnum.APPROVED}>{CorpusApprovalEnum.APPROVED}</Option>
                <Option key={2} value={CorpusApprovalEnum.REJECTED}>{CorpusApprovalEnum.REJECTED}</Option>
                <Option key={3} value={CorpusApprovalEnum.PENDING}>{CorpusApprovalEnum.PENDING}</Option>

              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="approvedBy"
              label="Approved By"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item name="remarks" label="Remarks"
              rules={[
                {

                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                },
                {
                  required: true,
                  message: 'remarks missing'


                }
              ]}>
              <TextArea rows={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ExpensesForm;
