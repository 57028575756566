import { AlertMessages } from '@gtpl/shared-utils/alert-messages'
import { Button, Card, Col, Form, Input, Row, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'
import { AssetServiceChecklistDTO } from '@gtpl/shared-models/asset-management'
import { ObjectUnsubscribedError } from 'rxjs'

type Props = {
    setServiceChecklist: (value: AssetServiceChecklistDTO) => void
    initialValues: any[]
}
const AssetServiceCheckList: React.FC<Props> = (props: Props) => {
    const [checklistForm] = Form.useForm()
    const [checklist, setChecklist] = React.useState<any[]>([])
    const [tableData, setTableData] = React.useState<any>(props.initialValues)

    const columns = [
        { title: 'S.no', render: (value,row, index) => index + 1 },
        { title: 'Checklist', dataIndex: 'checklist' },
        {
            title: 'Action', render: (value, rowData, index) => {
                return <Button onClick={() => deletechecklist(index)} type='ghost' size='small' icon={<DeleteOutlined />} />
            }
        }
    ]
    function addchecklist() {
        checklistForm.validateFields().then((res) => {
            if (tableData.filter((c) => c.checklist == res.checklist).length) return AlertMessages.getInfoMessage('Checklist already exists,Enter New Checklist')
            const obj = new AssetServiceChecklistDTO(res.checklist, 'OPEN', localStorage.getItem('createdUser'))
            setTableData((prev) => [...prev, obj])
            props.setServiceChecklist(obj)
            checklistForm.resetFields()
        })
    }

    function deletechecklist(index) {
        console.log(index)
        tableData.splice(index, 1)
        setTableData(prev => [...tableData])
        props.setServiceChecklist(tableData)
    }
    return (
        <Card>
            <Form form={checklistForm} layout='vertical'>
                <Row gutter={24} >
                    <Col span={8}>
                        <Form.Item label='Checklist' name='checklist'>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingTop: '30px' }}>
                        <Button type='primary' htmlType='submit' onClick={addchecklist}>
                            Add
                        </Button>
                    </Col>
                </Row>
                <Row gutter={24} justify='center'>
                    {tableData ? <Table  bordered size='small' scroll={{x:500}} columns={columns} dataSource={tableData} pagination={false} /> : <></>}
                </Row>
            </Form>
        </Card>
    )
}

export default AssetServiceCheckList;
