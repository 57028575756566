import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, ConfigProvider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantOperationCapacityForm } from '@gtpl/pages/master/master-components/plant-operation-capacity-form';
import { Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import './plant-operation-capacity-grid.css';
import { PlantOperationCapacityDto } from 'libs/shared-models/masters/src/lib/plan-operation-capacity/plant-operation-capacity-dto';
import { PlantOperationCapacityService } from '@gtpl/shared-services/masters';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
export interface PlantOperationCapacityGridProps { }

export function PlantOperationCapacityGrid(props: PlantOperationCapacityGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const plantOperationCapacityService = new PlantOperationCapacityService();
  const [plantOperationCapacityData, setPlantOperationCapacity] = useState<PlantOperationCapacityDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [slectedPlantOperationCapacityData, setSelectedPlantOperationCapacityData] = useState<any>(undefined);
  useEffect(() => {
    getAll();
  }, []);
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
  * 
  * @param plantOperationCapacityData 
  */

  const deletePlantOperationCapacity = (plantOperationCapacityData: PlantOperationCapacityDto) => {
    //  plantOperationCapacityData.isActive=plantOperationCapacityData.isActive?false:true;
    plantOperationCapacityService.activatedeActivate({ ...plantOperationCapacityData, isActive: plantOperationCapacityData.isActive ? false : true }).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
* 
* @param plantOperationCapacityData 
*/

  const update = (plantOperationCapacityData: PlantOperationCapacityDto) => {
    plantOperationCapacityService.update(plantOperationCapacityData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAll = () => {
    
    const req = new PlantIdRequest(Number(localStorage.getItem('unit_id')))
    plantOperationCapacityService.getAll(req).then(res => {
      if (res.status) {
        setPlantOperationCapacity(res.data);
      } else {
        if (res.intlCode) {
          setPlantOperationCapacity([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPlantOperationCapacity([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  //TO open the form for updation
  const openFormWithData = (viewData: PlantOperationCapacityDto) => {
    setDrawerVisible(true);
    setSelectedPlantOperationCapacityData(viewData);
    console.log(slectedPlantOperationCapacityData)
    console.log('slectedPlantOperationCapacityData')
  }


  const columnsSkelton: ProColumns[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Plant',
      dataIndex: 'plantName',
      // responsive: ['lg'],
      sorter: (a, b) => a.plantName.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName')
    },
    {
      title: 'Operation',
      dataIndex: 'operationName',
      // responsive: ['lg'],
      sorter: (a, b) => a.operationName.localeCompare(b.operationName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('operationName')
    },
    {
      title: 'count 1',
      dataIndex: 'mincount',
      // responsive: ['lg'],
      sorter: (a, b) => a.mincount - b.mincount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mincount')
    },
    {
      title: 'count 2',
      dataIndex: 'maxcount',
      // responsive: ['lg'],
      sorter: (a, b) => a.maxcount - b.maxcount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('maxcount')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'InActive',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.isActive === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated plant operation capacity');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deletePlantOperationCapacity(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate plant operation capacity  ?'
                : 'Are you sure to Activate plant operation capacity ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }

  ];
  /** 
  * @param pagination
  * @param filters
  * @param sorter
  * @param extra
  */

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text: string) => {
    switch (text) {
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Plant Operation Capacity</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/plant-operation-capacity-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        <Row gutter={40}>
        <Col>
          <Card title={'Total Plant Capacities: ' + plantOperationCapacityData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active : ' + plantOperationCapacityData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + plantOperationCapacityData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
        </Row>
        <br></br>
        <ConfigProvider locale={enUSIntl}>
          <ProTable
            columns={columnsSkelton}
            dataSource={plantOperationCapacityData}
            rowKey={record => record.PlantOperationCapacityId}
            request={(params, sorter, filter) => {
              return Promise.resolve({
                success: true,
              });
            }}
            scroll={{ x: true }}
            dateFormatter='string'
            search={false}
            pagination=
            {{
              onChange(current) { setPage(current); }
            }}

          />
        </ConfigProvider>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <PlantOperationCapacityForm key={Date.now()}
              plantoperationcapacityData={slectedPlantOperationCapacityData}
              isUpdate={true}
              updateplantoperationcapacity={update}
              closeForm={closeDrawer}
            />
          </Card>
        </Drawer>
      </Card>
    </>
  );
}

export default PlantOperationCapacityGrid;
