import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface IDownTimeCausesDonutChartProps {
    assetsData: any;
}

const DownTimeCausesDonutChart = (props: IDownTimeCausesDonutChartProps) => {
    const { assetsData } = props;

    // Extract and transform data
    const affectedAssetsData = assetsData
        .flatMap(asset => asset?.assetDownTimeDetails || [])
        .map(detail => ({
            name: detail.downTimeCause,
            y: parseInt(detail.affectedAssetsCount, 10)
        }))
        .filter(data => !isNaN(data.y)); // Filter out invalid data


    // Chart options
    const options: Highcharts.Options = {
        title: {
            text: 'Down Time Causes and Affected Assets Count'
        },
        chart: {
            type: 'pie'
        },
        plotOptions: {
            pie: {
                innerSize: '50%', // Creates the donut effect
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}'
                }
            }
        },
        series: [
            {
                type: 'pie',
                name: 'Affected Assets Count',
                data: affectedAssetsData
            }
        ]
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default DownTimeCausesDonutChart;
