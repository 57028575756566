import React, { useState, useEffect, useRef } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button, Row, Col, Drawer, Tag, Modal, } from 'antd';
import Highlighter from 'react-highlight-words';
import {SkuDetailView, SkuDetailViewProps, SkuForm} from '@gtpl/pages/master/master-components/sku-form';
import {SkuService} from '@gtpl/shared-services/masters'
import {  SkuDto } from '@gtpl/shared-models/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import './sku-grid.css';
import {ProductService} from '@gtpl/shared-services/masters';
import { ProductDto } from '@gtpl/shared-models/masters';

import { Link } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface SkuGridProps {
  
}

export function SkuGrid(props: SkuGridProps,
  skuDetailProps : SkuDetailViewProps) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const [skuData, setSkuData] = useState<SkuDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSkuData, setSelectedSkuData] = useState<any>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const service = new SkuService;
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const activeProducts =  new ProductService();
  const [productsData, setProductsData] = useState<ProductDto[]>([]);
  const username = localStorage.getItem('username')

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    getAllSku();
    getAllActiveProducts();
  }, []);

const goToSkuDetailView = (value) =>{
  setIsModalVisible(true);
  skuDetailProps.code = value
}

const getAllActiveProducts = () => {
  activeProducts.getAllActiveProducts().then((res) => {
    if (res.status) {
      setProductsData(res.data);
      let filtersArray:{text:string,value:string}[] = []
      res.data.map(item => {
        if(!(filtersArray.includes({text:item.productName,value:item.productName})))
        filtersArray.push({text:item.productName,value:item.productName})
      })
      setFiltersData(filtersArray)

      console.log(res.data)
      // console.log(getProductFilters())
    } else {
      setProductsData([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
  setProductsData([]);
  });
}
  const getAllSku= () => {
  service.getAllSku(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setSkuData(res.data);
        console.log(res);
      } else {
        if (res.intlCode) {
          setSkuData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSkuData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }
/**
   * 
   * @param SkuData 
   */
  const deleteSku = (skuDto:SkuDto) => {
    skuDto.isActive=skuDto.isActive?false:true;
    service.ActivateOrDeactivateSku(skuDto).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
        getAllSku();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
      /**
     * 
     * @param skuData 
     */
    const updateSku = (skuData: SkuDto) => {
      console.log(skuData,'update data')
      service.updateSku(skuData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllSku();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      responsive: ['sm'],
      width: '70px',
      // fixed:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    
    },
   
    {
      title: 'Variant Code',
      dataIndex: 'variantCode', 
      width: '250px',
      // responsive: ['lg'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),
      render: (value, record) => 
      <Link  to={{pathname:"/sku-detail-view",state:record.skuCodeId}}>{value}</Link>
    },
    // {
    //     title: 'Food Type',
    //     dataIndex: 'foodType', 
    //     // responsive: ['lg'],
    //     sorter: (a, b) => a.foodType.localeCompare(b.foodType),
    //   sortDirections: ['descend', 'ascend'],
    //     ...getColumnSearchProps('foodType'),
      
    //   },
      {
        title: 'Product',
        dataIndex: 'productName', 
        width: '100px',
        render:(text,data) => {
          console.log(data)
          return text
        },
        filters: filtersData
         ,
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          console.log(value)
          return record.productName === value;
        },
      },
      // {
      //   title: 'Grade',
      //   dataIndex: 'gradeName', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.gradeName?.localeCompare(b.gradeName),
      // sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('gradeName')
      // },
      {
        title: 'Loss Or Gain(%)',
        dataIndex: 'lossOrGainPercentage', 
        width: '80px',
        // responsive: ['lg'],
        sorter: (a, b) => a.lossOrGainPercentage.localeCompare(b.lossOrGainPercentage),
      sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('lossOrGainPercentage')
      },
      // {
      //   title: 'Min Count',
      //   dataIndex: 'minCount', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) =>a.minCount-b.minCount,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('minCount')
      // },
      // {
      //   title: 'Max Count',
      //   dataIndex: 'maxCount', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.maxCount-b.maxCount,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('maxCount')
      // },
      {
        title: 'Required Count',
        dataIndex: 'requiredCount', 
        // responsive: ['lg'],
        width: '100px',
        sorter: (a, b) => a.requiredCount-b.requiredCount,
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('requiredCount')
      },
      // {
      //   title: 'Glazing',
      //   dataIndex: 'minglaze', 
      //   // responsive: ['lg'],
      //   // sorter: (a, b) => a.minglaze.localeCompare(b.minglaze),
      //   // sortDirections: ['descend', 'ascend'],
      //   // ...getColumnSearchProps('minglaze'),
      //   render:(value,rowData) => {
      //       return (rowData.minglaze + '-' + rowData.maxglaze)
      //   }
      // },
      // {
      //   title: 'Max Glaze',
      //   dataIndex: 'maxglaze', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.maxglaze.localeCompare(b.maxglaze),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('maxglaze')
      // },
      // {
      //   title: 'Soaking Style',
      //   dataIndex: 'soakingStyle', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('soakingStyle')
      // },
      // {
      //   title: 'Soaking Time',
      //   dataIndex: 'soakingTime', 
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.soakingTime.localeCompare(b.soakingTime),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('soakingTime')
      // },
    //    {
    //   title: 'Value Addition one',
    //   dataIndex: 'valueAdd1Name', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => {
    //     a = a.valueAdd1Name || '';
    //     b = b.valueAdd1Name || '';
    //     return a.localeCompare(b);
    // },
    //   // sorter: (a, b) => a.valueAdd1Name.localeCompare(b.valueAdd1Name),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAdd1Name')
    // },
    // {
    //   title: 'Value Addition two',
    //   dataIndex: 'valueAdd2Name', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.valueAdd2Name.localeCompare(b.valueAdd2Name),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAdd2Name')
    // },
    // {
    //   title: 'Value Addition three',
    //   dataIndex: 'valueAdd3Name', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.valueAdd3Name.localeCompare(b.valueAdd3Name),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAdd3Name')
    // },
    // {
    //   title: 'Value Addition four',
    //   dataIndex: 'valueAdd4Name', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.valueAdd4Name.localeCompare(b.valueAdd4Name),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAdd4Name')
    // },
    // {
    //   title: 'Value Addition five',
    //   dataIndex: 'valueAdd5Name', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.valueAdd5Name.localeCompare(b.valueAdd5Name),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAdd5Name')
    // },
    // {
    //   title: 'Only Salt(%)',
    //   dataIndex: 'onlySalt', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.onlySalt.localeCompare(b.onlySalt),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('onlySalt')
    // },
    // {
    //   title: 'STPP+Salt(%)',
    //   dataIndex: 'sttpSalt', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.sttpSalt.localeCompare(b.sttpSalt),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('sttpSalt')
    // },
    
    // {
    //   title: 'NP+salt(%)',
    //   dataIndex: 'npSalt', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.npSalt.localeCompare(b.npSalt),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('npSalt')
    // },
    // {
    //   title: 'CP+ onlySalt(%)',
    //   dataIndex: 'cpSalt', 
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.cpSalt.localeCompare(b.cpSalt),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('cpSalt')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: '100px',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive == value;
      },
      
    },
    {
      title:`Action     `,
      dataIndex: 'action',
      width: '90px',
      render: (text, rowData) => (
        rowData.variantCode.trim()=='NA' || rowData.variantCode.trim()=='N/A' ? <span> </span>:
        <span>
          
            {/* <EyeOutlined   className={'viewSamplTypeIcon'} type="eye" 
              onClick={() => { props.viewState(rowData, true); }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> */}
         
          {/* <Divider type="vertical" /> */}
          {
            username == '"ramakrishna"' ?
            <>
          <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated SKU Code');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />

          
          <Divider type="vertical" />
            </> : ''
          }
            <Popconfirm 
            onConfirm={e => { deleteSku(rowData);  }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate ?'
                :  'Are you sure to Activate ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }
     //drawer related
     const closeDrawer=()=>{
      setDrawerVisible(false);
    }

    const openFormWithData=(viewData: SkuDto)=>{
      console.log('view',viewData)
      setDrawerVisible(true);
      setSelectedSkuData(viewData);
      
    };

  return (
    <>
    <Card title={<span style={{color:'white'}}>Product SKU's </span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/sku-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}>
    
    <Row gutter={40}>
      <Col>
        <Card title={'Total Skus: ' + skuData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
      </Col>
      <Col>
        <Card title={'Active: ' + skuData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
      </Col>
      <Col>
        <Card title={'In-Active :' + skuData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
      </Col>
    </Row><br></br>
     
        <Table
            rowKey={record => record.skuDataId}
            columns={sampleTypeColumns}
            dataSource={skuData}
            scroll={{  y: 1000 }}
              pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange} 
            bordered
            
          />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '85%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <SkuForm key={Date.now()}
                updateSku={updateSku}
                isUpdate={true}
                skuData={selectedSkuData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
      </Card>

     
        </>
  );

};

export default SkuGrid;
