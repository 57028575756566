import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Statistic, Form, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownTimeFiltersDto, DownTimeReasonDto, DownTimeTrackingDto } from '@gtpl/shared-models/masters';
import { DowntimeReasonService, DownTimeTrackingService } from '@gtpl/shared-services/masters';
import { DownTimeTrackingForm } from '@gtpl/pages/master/master-components/down-time-tracking-form'
import './down-time-tracking-grid.css';
import moment from 'moment';
import { DownTimeTrackingStatusEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface DownTimeReportProps {

}

export function DownTimeReport(
  props: DownTimeReportProps
) {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const columns = useState('');
  // const { formatMessage: fm } = useIntl();

  const downtimetrackingService = new DownTimeTrackingService();
  const [selectedDownTimeTrackingData, setSelectedDownTimeTrackingData] = useState<any>(undefined);
  const [downtimeTrackingData, setDownTimeTrackingData] = useState<DownTimeTrackingDto[]>([]);
  const [selectedDowntimeTracking, setSelectedDowntimetracking] = useState<DownTimeTrackingDto>();
  const [downTimeReasonData, setDownTimeReasonData] = useState<DownTimeReasonDto[]>();
  const downTimeReasonService = new DowntimeReasonService;



  /**
   * used for column filter
   * @param dataIndex column data index
   */

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getAllActiveDownTimeReason = () => {
    downTimeReasonService.getAllActiveDownTimeReason().then(res => {
      if (res.status) {
        setDownTimeReasonData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'DownTimeReason',
      dataIndex: 'reason',
      // responsive: ['lg'],
      //  sorter: (a, b) => a.downtimeReason.localeCompare(b.downtimeReason),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('reason')
      sorter: (a, b) => a.reason - b.reason,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('reason')
    },
    {
      title: 'Category',
      dataIndex: 'category',
      // responsive: ['lg'],
      sorter: (a, b) => a.category.trim().localeCompare(b.category.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('category')
    },
    {
      title: 'Impact',
      dataIndex: 'impact',
      // responsive: ['lg'],
      sorter: (a, b) => a.impact.trim().localeCompare(b.impact.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('impact')
    },

    {
      title: 'Start Time',
      dataIndex: 'startTime',
      sorter: (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix(),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('startTime'),
      render: (value, record) => {
        return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
      }
    },

    {
      title: 'Time Duration',
      // dataIndex: 'diffhours', 
      render: (value, record) => {
        // const date1 : any= new Date(record?.startTime);
        // const date2 : any = new Date();
        //  const diff : any =new Date(date2-date1);
        //   const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
        //   const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes   
        //   const totalmins = diffMins + (diffHrs * 60)
        //   const totalvalue =  diffHrs + ':'+ diffMins        
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{record.diffhours}</div>),
          props: {
            style: { background: record.duration <= record.sla ? "#38f438" : record.duration > record.sla && record.duration <= 180 ? "#FFFF00" : '#FF0000', color: record.sla <= record.sla ? "black" : 'black' }
          },
        };
        return obj;

      }
    },
    {
      title: 'No.of Resources',
      dataIndex: 'resources',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.resources - b.resources,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('resources')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // responsive: ['lg'],
      sorter: (a, b) => a.status.trim().localeCompare(b.status.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    }

  ];
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => { getAllDownTimeTrackings(); getAllActiveDownTimeReason()}, [])

  const getAllDownTimeTrackings = () => {
    const req = new DownTimeFiltersDto
    req.downtimeReasonId = form.getFieldValue('reasonId')
    req.status = form.getFieldValue('status')
    downtimetrackingService.getDownTimeTrackingReport(req).then(res => {
      if (res.status) {
        setDownTimeTrackingData(res.data);
      } else {
        if (res.intlCode) {
          setDownTimeTrackingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDownTimeTrackingData([]);
    })
  }

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

 


  /**
      * 
      * @param variantData 
      */
  

  
  
  
  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }


  return (
    <Card title={<span style={{ color: 'white' }}>DownTime Report</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <br></br>
      <Card >
        <Form form={form} layout={'vertical'}>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name="reasonId"
                label="DownTime Reason"
                rules={[
                  {
                    required: true,
                    message: 'DownTime Reason is required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Enter the DownTimeReason`
                  }
                ]}
              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  placeholder="Select DownTimeReason"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Reason</Option>
                  {downTimeReasonData?.map((downTimeReason) => {
                    return <Option key={downTimeReason.downtimereasonId} value={downTimeReason.downtimereasonId}>{downTimeReason.downtimeReason}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="status"
              label="Status"
              initialValue={DownTimeTrackingStatusEnum.OPEN}
              rules={[
                {
                  // required: true,
                  message: 'status Reason is required',

                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the status`
                }
              ]}

            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select statusReason"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={DownTimeTrackingStatusEnum.OPEN}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Status</Option>
                {Object.values(DownTimeTrackingStatusEnum).map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          </Row>
        </Form>
        <Row>
          <Col span={4}>
            <Statistic title="Open:" value={downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.OPEN).length}></Statistic>
          </Col>
          <Col span={4}>
            <Statistic title="In-Progress:" value={downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.WIP).length}></Statistic>
          </Col>
          <Col span={4}>
            <Statistic title="Pending:" value={downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.PENDING).length}></Statistic>
          </Col>
          <Col span={4}>
            <Statistic title="Close:" value={downtimeTrackingData.filter(el => el.status == DownTimeTrackingStatusEnum.CLOSE).length}></Statistic>
          </Col>
        </Row>

        <Table
          rowKey={record => record.downtimeTrackingId}
          columns={columnsSkelton}
          dataSource={downtimeTrackingData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x: true }}
          onChange={onChange}
          size='small'
          bordered />
      </Card>
    </Card>
  );

}

export default DownTimeReport;

