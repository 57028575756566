import React, { useRef, useState } from 'react';
import { Row, Col } from 'antd';

import QrReader from 'react-qr-reader';
/* eslint-disable-next-line */
export interface QrContainerProps {
    handleScan: (data: any) => void
}

export function QrContainer(
    props: QrContainerProps
) {

    const [scanResultWebCam, setScanResultWebCam] = useState('');
    const [scanResultFile, setScanResultFile] = useState([]);




    const handleErrorWebCam = (error) => {
        console.log(error);
    }
    const handleScanWebCam = (result) => {
        if (result) {
            setScanResultWebCam(result);
            props.handleScan(result)
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <h3>Qr Code Scan by Web Cam</h3><br /><br />
                    <QrReader
                        delay={300}
                        style={{ width: '30%', height: '30%' }}
                        onError={handleErrorWebCam}
                        onScan={handleScanWebCam}
                    />
                </Col>
            </Row>
            <Row>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <h3>Scanned By WebCam Code: </h3>
                    <br /><br />
                    <p>    {scanResultWebCam}</p>
                </Col>
            </Row>






        </>
    );
}

export default QrContainer;