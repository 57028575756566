import React, { useEffect, useState } from 'react';
import { AttendanceService } from '@gtpl/shared-services/hrms';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import { Card, Descriptions, } from 'antd';
import { DeptWiseDataModel, EmployeeAttendanceRequest } from '@gtpl/shared-models/hrms';


export interface AttendanceDeptDashboardProps { }

export function AttendanceDeptDashboard(props: AttendanceDeptDashboardProps) {
    const [page, setPage] = React.useState(1);
    const [attendanceData, setAttendanceData] = useState<DeptWiseDataModel[]>([])
    const service = new AttendanceService();

    useEffect(() => {
        const currDate = new Date();
        const deptId = 3;
        const date = currDate.getFullYear() + '-' + (Number(currDate.getMonth()) + 1) + '-' + currDate.getDate();
        getAttendanceData(date, deptId);
    }, [])

    const getAttendanceData = (date: string, deptId: number) => {
        const req = new EmployeeAttendanceRequest(date, deptId);
        service.getAttendanceData(req).then((res) => {
            if (res.status) {
                setAttendanceData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const columns: ColumnProps<any>[] = [

        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            align: 'center',
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'Department',
            dataIndex: 'departmentName',
            align: 'center'
        },
        {
            title: 'Total Employees',
            dataIndex: 'totalCount',
            align: 'center'
        },
        {
            title: 'Present Count',
            dataIndex: 'presentCount',
            align: 'center'
        },
        {
            title: 'Absent Count',
            dataIndex: 'abscentCount',
            align: 'center',
            render: (value, row: any, index) => {
                return <>{Number(row.totalCount) - Number(row.presentCount)}</>
            }
        },
        {
            title: 'Absent %',
            dataIndex: 'absent',
            align: 'center',
            render: (value, row: any, index) => {
                return <>{(((Number(row.totalCount) - Number(row.presentCount)) * 100) / Number(row.totalCount)).toFixed(2) + ' % '}</>
            }
        }
    ];

    const empColumns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            align: 'center',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Employee Code',
            key: 'employeeCode',
            dataIndex: 'employeeCode',
            align: 'center'
        },
        {
            title: 'Employee Name',
            key: 'employeeName',
            dataIndex: 'employeeName',
            align: 'center'
        },
        {
            title: 'Designation',
            key: 'designation',
            dataIndex: 'designation',
            align: 'center'
        }
    ]


    return (
        <>
            <Card title={<span style={{ color: 'black' }}>Today's Production Department Attendance</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#hite', border: 0 }} >
                <Descriptions column={4}>
                    <Descriptions.Item label={'Total Employees '}>
                        {attendanceData[0]?.totalCount}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Present Count '}>
                        {attendanceData[0]?.presentCount}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Absent Count '}>
                        {Number(attendanceData[0]?.totalCount) - Number(attendanceData[0]?.presentCount)}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Absent % '}>
                        {(((Number(attendanceData[0]?.totalCount) - Number(attendanceData[0]?.presentCount)) * 100) / Number(attendanceData[0]?.totalCount)).toFixed(2) + ' %'}
                    </Descriptions.Item>
                </Descriptions>
                <Table
                    rowKey={record => record.departmentId}
                    columns={empColumns}
                    dataSource={attendanceData[0]?.empDetailes}
                    pagination={{
                        onChange(current) {
                            setPage(current);
                        }
                    }}
                    size='small'
                    bordered
                />
            </Card>
        </>
    )
}

export default AttendanceDeptDashboard;