import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, InputNumber, DatePicker, Form, Select, Tabs, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { ApplyForLeavesDto, ApplyForLeavesReq, ApplyForLeavesRequest } from '@gtpl/shared-models/hrms';
import { ApplyForLeavesService } from '@gtpl/shared-services/hrms';
import { ApplyForLeavesForm } from '@gtpl/pages/hrms/hrms-components/apply-for-leaves-form'
import moment from 'moment';

import './leave-approval-grid.css';
import { LeaveApprovalStatusEnum } from '@gtpl/shared-models/common-models';
import { HrmsEmployeeRequest } from '@gtpl/shared-models/masters';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';

/* eslint-disable-next-line */
export interface LeaveApprovalGridProps { }

export function LeaveApprovalGrid(
  props: LeaveApprovalGridProps
) {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [data, setData] = useState<any>();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [applyForLeavesData, selectedApplyForLeavesData] = useState<ApplyForLeavesDto[]>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>(undefined);
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [leaveId, setLeaveId] = useState<number>(null);
  const [leavesId, setLeavesId] = useState<number>(null);
  const [showpopup, setShowpopup] = useState<boolean>(false);

  const { TabPane } = Tabs;
  const hrmsEmpDetService = new HrmsEmpDetService();

  const applyForLeavesService = new ApplyForLeavesService();

  useEffect(() => {
    getAllAppliedLeaves();
  }, []);

  /**
   * 
   */


  const getAllAppliedLeaves = () => {
    applyForLeavesService.getAllAppliedLeaves().then(res => {
      if (res.status) {
        res.data.forEach((LeaveApproval) => {
          const req = new HrmsEmployeeRequest(LeaveApproval.employeeId);
          hrmsEmpDetService.getActiveEmployeesById(req).then(res => {
            LeaveApproval.employeeName = res.data.employeeName;
          })
          console.log(LeaveApproval.employeeId);
        });
        selectedApplyForLeavesData(res.data);
      } else {
        if (res.intlCode) {
          selectedApplyForLeavesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      selectedApplyForLeavesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param applyForLeavesData 
   */
  const approveLeaveStatus = (applyForLeavesData: ApplyForLeavesDto) => {
    const req = new ApplyForLeavesRequest(applyForLeavesData.applyForLeavesId, localStorage.getItem("createdUser"), applyForLeavesData.versionFlag, applyForLeavesData.isActive, LeaveApprovalStatusEnum.APPROVED);
    applyForLeavesService.updateLeaveStatus(req).then(res => {
      if (res.status) {
        getAllAppliedLeaves();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  /**
     * 
     * @param variantData 
     */
  const rejectLeaveStatus = (appliedLeavesData: ApplyForLeavesDto) => {
    const req = new ApplyForLeavesRequest(leaveId, localStorage.getItem("createdUser"), appliedLeavesData.versionFlag, appliedLeavesData.isActive, LeaveApprovalStatusEnum.REJECTED, form.getFieldValue('rejectionReason'),);
    setShowpopup(true);
    console.log(req,'req')
    applyForLeavesService.updateLeaveStatus(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
        getAllAppliedLeaves();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const cancelLeaveStatus = (appliedLeavesData: ApplyForLeavesDto) => {
    const req = new ApplyForLeavesRequest(leavesId, localStorage.getItem("createdUser"), appliedLeavesData.versionFlag, appliedLeavesData.isActive, LeaveApprovalStatusEnum.CANCEL, form.getFieldValue('rejectionReason'),);
    setShowpopup(true);
    console.log(req,'req')
    applyForLeavesService.updateLeaveStatus(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
        getAllAppliedLeaves();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const handlesubmit = () => {
    // alert();
    // rejectLeaveStatus(data);
    //  setShowpopup(false);
     if(form.getFieldValue('rejectionReason') == null)
     {
      return AlertMessages.getInfoMessage("Rejection reason must be filled")
     }else{
      rejectLeaveStatus(data);
      cancelLeaveStatus(data);
      setShowpopup(false);
     }
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });



  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const openFormWithData = (viewData: ApplyForLeavesDto) => {
    setDrawerVisible(true);
    setSelectedLeave(viewData);
    console.log(selectedLeave)
    console.log('selectedLeave')
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const tableColumns = (key) => {
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
      {
        title: 'Employee Code',
        dataIndex: 'employeeCode',
        // responsive: ['lg'],
        sorter: (a, b) => a.employeeCode?.localeCompare(b.employeeCode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeCode')
      },

      {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('employeeName')
      },
      {
        title: 'Leave Applied Date',
        dataIndex: 'appliedDate',
        responsive: ['lg'],
        sorter: (a, b) => a.appliedDate.localeCompare(b.appliedDate),
        render: (value, record) => {
          return <span>{record.appliedDate ? moment(record.appliedDate).format('YYYY-MM-DD') : '-'}</span>
        },
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('appliedDate'),

      },
      {
        title: 'Type of leave',
        dataIndex: 'typeOfLeaveDesc',
        sorter: (a, b) => a.typeOfLeave.localeCompare(b.typeOfLeave),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('typeOfLeaveDesc')
      },
      {
        title: 'From Date',
        dataIndex: 'fromDate',
        render: (text, record) => { return moment(record.fromDate).format('YYYY-MM-DD') },
        sorter: (a, b) => a.fromDate.localeCompare(b.fromDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'To Date',
        dataIndex: 'toDate',
        render: (text, record) => { return moment(record.toDate).format('YYYY-MM-DD') },
        sorter: (a, b) => a.toDate.localeCompare(b.toDate),
        sortDirections: ['descend', 'ascend'],

      },
      {
        title: "Reason",
        dataIndex: "leaveReason"
      },

      {
        title: 'NO of Days',
        dataIndex: 'noOfDays',
        responsive: ['lg'],
        sorter: (a, b) => a.noOfDays.localeCompare(b.noOfDays),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('noOfDays'),

      },
      
    ]
    const actionColumns: ColumnProps<any>[] = [
      {
        title: 'Action',
        dataIndex: 'status',
        align: 'center',
        render: (status, rowData) => (
          <span>
            {rowData.status ?
              <span>
                <Button type="primary" shape="round" size="small" onClick={() => approveLeaveStatus(rowData)}>
                  Approve
                </Button>
                <Divider type="vertical" />
                <Button type="primary" shape="round" size="small" danger onClick={() => {
                  setData(rowData)
                  setShowpopup(true)
                  setLeaveId(rowData.applyForLeavesId)
                }}>
                  Reject
                </Button>
                <Divider type="vertical" />
                <Button type="primary" shape="round" size="small" danger onClick={() => {
                  setData(rowData)
                  setShowpopup(true)
                  setLeavesId(rowData.applyForLeavesId)
                }}>
                  Cancel
                </Button>
              </span> :
              <span> </span>
            }
          </span>
        ),

      },

    ];
    if (key === "1") {
      return [...columnsSkelton, ...actionColumns];
    } else {
      return [...columnsSkelton]
    }
  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  // const getreasonVal = (val) => {
  //   rejectionReason(val)
  // }

  return (
    <Card title={<span style={{ color: 'white' }}>Leave Approvals</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <br></br>
      <div className="card-container">
        <Tabs type={'card'} tabPosition={'top'}>
          <TabPane
            key="1"
            tab={<span style={{ color: "#f5222d" }}>{'Open: ' + (applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.OPEN).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("1")}
              dataSource={applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.OPEN)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>

          <TabPane
            key="2"
            tab={<span style={{ color: "#f5222d" }}>{'Approved: ' + (applyForLeavesData.filter(el => el.status == LeaveApprovalStatusEnum.APPROVED).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("2")}
              dataSource={applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.APPROVED)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>

          <TabPane
            key="3"
            tab={<span style={{ color: "#f5222d" }}>{'Rejected: ' + (applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.REJECTED).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("2")}
              dataSource={applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.REJECTED)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>
          <TabPane
            key="4"
            tab={<span style={{ color: "#f5222d" }}>{'Cancel: ' + (applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.CANCEL).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("3")}
              dataSource={applyForLeavesData.filter(rec => rec.status == LeaveApprovalStatusEnum.CANCEL)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        
        style={{ top: 30, alignContent: 'right' }}
        visible={showpopup}
        footer={[
          <Button type="primary" htmlType="submit" onClick={() => handlesubmit()}>
            Submit
          </Button>,
          <Button key="back" onClick={() => { setShowpopup(false) }}>
            Cancel
          </Button>,
        ]}
        title={<React.Fragment>
        </React.Fragment>}
      >
        <div>
          <Form form={form}
            name="control-hooks"
            layout="vertical"
            onFinish={() => {{ rejectLeaveStatus && cancelLeaveStatus }}}
          >
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 8 }}>
              <Form.Item
                name="rejectionReason"
                label="Reason"

                rules={[
                  {
                    required: true,
                    message: 'rejectionReason required'
                  },
                 
                ]}>

                <Input />
              </Form.Item>
            </Col>
          </Form>
        </div>
      </Modal>

    </Card>

  );
}

export default LeaveApprovalGrid;
