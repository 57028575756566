import { Card, Col, Progress, Row, Table, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import AssetCategoryWiseMaintainanceDetailsChart from './asset-cat-wise-maintainance-details-chart'
import ExpeiryDetailsForInsurance from './expeiry-details-for-insurance'
import ExpiryDetailsForWarrenty from './expiry-details-for-warrenty'
import { AssetAssignmentService, AssetService } from '@gtpl/shared-services/asset-management'
import AssetCategoryWiseDownTime from './asset-cat-wise-down-time'
import ServiceOwners from './service-owner-comp'
import AssetCountAndValueInfoByAssetType from './asset-count-and-value-info-by-asset-type'
import VendorsAbstract from './vendor-abstract-comp'
import DownTimeCausesDonutChart from './down-time-causes-pie-chart'
import TopFiveUpComingServicesComponent from './top-five-upcoming-services'
import TopFiveAssetWarrentyDateComponent from './top-five-asset-warrenty-date-comp'
import AssetsTopFiveInsurenceDatesComponent from './assets-top-five-insurence-comp'
import AgeWiseInstockAssets from './age-wise-in-stock-assets-comp'
import TopFiveDownTimeAssetsComponent from './top-five-down-time-assets-comp'
import TotalData from './total-data'
import PersonResponsibleAssets from './persons-responsible-assets-data-comp'
import ExpiryDetailsForAMCChart from './expiry-details-for-amc-chart'
import "./mainpage.css";
import TopFiveAssetsLicenseComponent from './Top-five-asset-license-date-comp'
import TimerComp from './timer-comp'
import AbstractReport from '../../../opening-aasets-form/src/lib/pages-asset-management-asset-management-components-opening-aasets-form'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import excel from '../../../assets-dashboard/src/lib/excel.png'
import KPIConsolidationReport from './downtime-kpi'


const MainPage = () => {

    const [assetsData, setAssetsData] = useState([]);
    const [assetsMiantainaceData, setAssetsMiantainaceData] = useState<any[]>([])
    const [vendor, setVendor] = useState<any[]>([])
    const [raci, setRaci] = useState<any[]>([])
    const [responsibleAssets, setResponsibleAssets] = useState([])
    const [unAssignedAssetsVendorsData, setUnAssignedVendorsData] = useState([]);
    const [tableData, setTableData] = useState<any[]>([])
    const assetService = new AssetService();
    const assetsService = new AssetAssignmentService();

    useEffect(() => {
        getAssetsData();
        getAssetsMaintainanceData();
        getVendorAbstract();
        getRaciMatrixData();
        getResponsibleAssets();
        getUnAssignedVendorsData();
        getAssetsAccountData();
    }, []);



    const getAssetsAccountData = () => {
        assetService.AssetStatusWiseAbstract().then((res) => {
            if (res.status) {
                setTableData(res.data)
            } else {
                setTableData([]);
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const getAssetsMaintainanceData = () => {
        assetService.AssetCategoryWiseMaintenance().then((res) => {
            if (res.status) {
                setAssetsMiantainaceData(res.data)
            } else {
                setAssetsMiantainaceData([]);
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const getAssetsData = () => {
        assetsService.getAssetsAccountDashBoardsData().then((res) => {
            if (res.status) {
                setAssetsData(res.data)
            } else {
                setAssetsData([]);
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }


    //VendorAbstract service
    const getVendorAbstract = () => {
        assetService.AssetVendorDetails().then((res) => {
            if (res.status) {
                setVendor(res.data)
            } else {
                setVendor([]);
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }


    //Raci service
    const getRaciMatrixData = () => {
        assetService.serviceOwnerServices().then((res) => {
            if (res.status) {
                setRaci(res.data)
            } else {
                setRaci([]);
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const getResponsibleAssets = () => {
        assetService.getResponsibleAssets().then((res) => {
            if (res.status) {
                setResponsibleAssets(res.data)
            } else {
                setResponsibleAssets([])
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const getUnAssignedVendorsData = () => {
        assetService.getUnassignedAssetsVendors().then((res) => {
            if (res.status) {
                setUnAssignedVendorsData(res.data)
            } else {
                setUnAssignedVendorsData([])
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    ///assets tables state value data
    const transformedData = tableData.map(asset => {
        const formatValue = (value) => {
            return value.toLocaleString('en-IN'); // 'en-IN' for Indian numbering system
        };

        return {
            key: asset.assetType,
            assetType: asset.assetType,
            Instock: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'INSTOCK')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'INSTOCK')?.totalAssetsValue || 0)}`,
            InUse: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'INUSE')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'INUSE')?.totalAssetsValue || 0)}`,
            UnderMaintenance: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'UnderMaintenance')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'UnderMaintenance')?.totalAssetsValue || 0)}`,
            Damaged: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'Damaged')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'Damaged')?.totalAssetsValue || 0)}`,
            Retire: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'Retire')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'Retire')?.totalAssetsValue || 0)}`,
            INTRANSIT: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'Intransit')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'Intransit')?.totalAssetsValue || 0)}`,
            Service: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'Service')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'Service')?.totalAssetsValue || 0)}`,
            Sold: `${(asset.assetTypeInfo.find(info => info.assetStatus === 'Sold')?.assetsCount || 0)} / ₹${formatValue(asset.assetTypeInfo.find(info => info.assetStatus === 'Sold')?.totalAssetsValue || 0)}`,
        };
    });


    const handleExport: React.MouseEventHandler<HTMLElement> = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        if (tableData.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .split("-")
            .join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { title: 'Asset Type', key: 'assetType', width: 20 },
            { title: 'Instock', key: 'INSTOCK', width: 20 },
            { title: 'Inuse', key: 'INUSE', width: 20 },
            { title: 'Under Maintenance', key: 'UnderMaintenance', width: 20 },
            // { title: 'Damaged', key: 'Damaged', width: 20 },
            { title: 'Retire', key: 'Retire', width: 20 },
            { title: 'In Transit', key: 'INTRANSIT', width: 20 },
            { title: 'Service', key: 'Service', width: 20 },
            { title: 'Sold', key: 'Sold', width: 20 }
        ];

        // Add header row with styling
        const headerRow = worksheet.addRow(columns.map(column => column.title));
        headerRow.font = { bold: true };
        headerRow.eachCell((cell, colNumber) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        transformedData.forEach(item => {
            const rowData = columns.map(column => item[column.key]);
            worksheet.addRow(rowData);
        });

        // Set column widths
        columns.forEach((column, index) => {
            worksheet.getColumn(index + 1).width = column.width;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Asset Count and Value Info by asset type Report-${currentDate}.xlsx`);
    };

    return (


        <Card

            title="Asset Management DashBoard"
            style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
                height: "100%",
                textAlign: 'center',
                width: '100%'

            }
            }
            headStyle={{ backgroundColor: '#114232', color: 'white', fontSize: '1.5em', fontWeight: 'bold', }}
            extra={<Tooltip
                title="Export As Excel"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: '5px',
                        height: '35px',
                        width: '35px'
                    }}
                >

                    <img src={excel} style={{ width: 40, height: 40 }} alt=""
                        onClick={handleExport}
                    />
                </div>
            </Tooltip>}
        >
            <AssetCountAndValueInfoByAssetType tableData={tableData} transformedData={transformedData} />
            <br />
            <Row gutter={24}>
                <Col span={24}>
                    <AssetCategoryWiseMaintainanceDetailsChart maintenanceData={assetsMiantainaceData} />
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={24}>
                    <PersonResponsibleAssets assetsData={responsibleAssets} />

                </Col>
            </Row>
            {/* <br />
            <Row gutter={24}>
                <Col span={24}>
                    <KPIConsolidationReport />

                </Col>
            </Row> */}
            <br />
            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                    >
                        <ExpiryDetailsForAMCChart
                            aboutToExpireDetails={assetsData[1]}
                        />
                    </Card>
                </Col>
                {/* <Col span={8} >
                    <Card
                        style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                    >
                        <ExpeiryDetailsForInsurance
                            aboutToExpireDetails={assetsData[2]}
                        />
                    </Card>
                </Col> */}
                <Col span={12} >
                    <Card
                        style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                    >
                        <ExpiryDetailsForWarrenty
                            aboutToExpireDetails={assetsData[3]}
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={12}>
                    <TopFiveUpComingServicesComponent />
                </Col>
                <Col span={12}>
                    <TopFiveAssetWarrentyDateComponent />
                </Col>
            </Row>
            <br />
            {/* <Row gutter={24}>
                </Row> */}
            <Row gutter={24}>
                <Col span={12}>
                    <AssetsTopFiveInsurenceDatesComponent />
                </Col>
                <Col span={12}>
                    <AbstractReport />
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <ServiceOwners aboutToExpireDetails={raci} />
                </Col>
                <Col span={16}>
                    {/* <RaciMatrix raci={raci} /> */}
                    <TotalData vendor={unAssignedAssetsVendorsData} />
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col xs={24} lg={16}>
                    <VendorsAbstract vendorAbstract={vendor} />
                </Col>
                <Col xs={24} lg={8}>
                    <AgeWiseInstockAssets />
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Card style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                        <AssetCategoryWiseDownTime assetsData={assetsMiantainaceData} />
                    </Card>
                </Col>
                <Col span={8}>
                    <TopFiveDownTimeAssetsComponent assetsData={assetsMiantainaceData} />
                </Col>
                <Col span={8}>
                    <Card
                        style={{ boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                    >
                        <DownTimeCausesDonutChart assetsData={assetsMiantainaceData} />
                    </Card>
                </Col>
            </Row>
        </Card>

    )
}

export default MainPage