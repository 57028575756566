import { AssetService } from '@gtpl/shared-services/asset-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Col, Empty, Row, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react'
import excel from '../../../assets-dashboard/src/lib/excel.png'
import moment from 'moment';
 import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const TopFiveAssetWarrentyDateComponent = () => {
    const [warrentyDetails, setWarrentyDetails] = useState<any>([]);
    const [hoveredCard, setHoveredCard] = useState(null);

    const service = new AssetService();

    useEffect(() => {
        assetsTopWarrenty();
    }, [])
    const assetsTopWarrenty = () => {
        service.assetsTopWarrenty().then((res) => {
            if (res.status) {
                setWarrentyDetails(res.data[0]);
            } else {
                setWarrentyDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setWarrentyDetails([]);
        })
    }


    const handleExport = async (e) => {
        e.preventDefault();

        if (warrentyDetails.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date().toISOString().slice(0, 10).split("-").join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { title: 'Unit', key: 'unit', width: 20 },
            { title: 'Asset Code', key: 'assets_code', width: 20 },
            { title: 'Asset Name', key: 'asset_name', width: 20 },
            { title: 'Valid Upto', key: 'warrenty_expired_date', width: 20 },
            { title: 'Aging', key: 'aging', width: 20 }
        ];

        // Add header row with styling
        const headerRow = worksheet.addRow(columns.map(column => column.title));
        headerRow.font = { bold: true };
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        warrentyDetails.forEach(item => {
            const rowData = columns.map(column => {
                if (column.key === 'warrenty_expired_date') {
                    return item[column.key] ? moment(item[column.key]).format('YYYY-MM-DD') : '--';
                }
                return item[column.key];
            });
            worksheet.addRow(rowData);
        });

        // Set column widths
        columns.forEach((column, index) => {
            worksheet.getColumn(index + 1).width = column.width;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Top Five Warrenty Assets Info-${currentDate}.xlsx`);
    };
    return (
        <div>
            <Card title="TOP 5 Asset Warranty Date" style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
                height: "450px",
                textAlign: 'center'
            }} headStyle={{ backgroundColor: '#114232', color: 'white' }} 
            extra={<Tooltip
                title="Export As Excel"
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    height: '35px',
                    width: '35px'
                }}>
                    {/* <FileExcelOutlined style={{ fontSize: '25px', color: '#1D6F42' }} /> */}
                    <img src={excel} style={{ width: 40, height: 40 }} alt=""
                    onClick={handleExport} 
                    />
                </div>
            </Tooltip>
        }
        >
                <Card style={{ backgroundColor: '#AFD198', height: '50px' }}>
                    <Row gutter={16}>
                        <Col span={4}>
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Unit</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>AssetCode</h1>
                        </Col>
                        <Col span={8}>
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>AssetName</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>ValidUpto</h1>
                        </Col>
                        <Col span={2}>
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Aging</h1>
                        </Col>
                    </Row>
                </Card>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {warrentyDetails.length > 0 ? (
                        warrentyDetails.map((rec, index) => (
                            <Card key={index} style={{
                                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                                height: '80px',
                                backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white',
                                marginTop: '10px'
                            }}
                                onMouseEnter={() => setHoveredCard(index)}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.unit}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.assets_code}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.asset_name}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.warrenty_expired_date ? moment(rec.warrenty_expired_date).format('YYYY-MM-DD') : '--'}</p>
                                    </Col>
                                    <Col span={2}>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.aging}</p>
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    ) : (
                        <Empty />
                    )}

                </div>
            </Card>
        </div>
    )
}

export default TopFiveAssetWarrentyDateComponent;