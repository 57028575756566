import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useState, useEffect, PureComponent } from 'react';

export interface RecentStockOutProps { }

export function RecentStockOut(props: RecentStockOutProps) {
    const [page, setPage] = React.useState(1);
    const [stockOutData, setStockOutData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getrecentFiveStockOuts();
    }, [])

    const getrecentFiveStockOuts = () => {
        service.getrecentFiveStockOuts({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockOutData(res.data)
               // AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
               // AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const columns: ColumnProps<any>[] = [
        // {
        //     title: '#',
        //     key: 'sno',
        //     width: '30px',
        //     responsive: ['sm'],
        //     render: (text, object, index) => (page - 1) * 10 + (index + 1)
        // },
        {
            title: 'Unit',
            dataIndex: 'unitCode',
            width:'70px'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            width: "150px",
            align: 'center',
            render: (value,  record) => {
                return moment(record.createdAt).format('YYYY-MM-DD HH:MM');
              }
        },
        {
            title: 'Sale Order',
            dataIndex: 'poNumber',
        },
        
        {
            title: 'Product',
            dataIndex: 'variantCode',
        },
        {
            title: 'Job purpose',
            dataIndex: 'jobPurpose',
        },
        {
            title: 'Current Position',
            dataIndex: 'currentPositionCode',
        },
        // {
        //     title: 'Product',
        //     dataIndex: 'variantCode',
        // },
        {
            title: 'Cartons',
            dataIndex: 'jobCartons',
        },
        
       
    ]
    return (
        <div>
            <Card title={<span style={{ color: 'black' }}>Recent 5 Stock Out</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: 'white', border: 0 }}  >
                <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={stockOutData}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
            </Card>
        </div>
    )
}
export default RecentStockOut;