import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
// import {CountryDto} from '@gtpl/shared-models/masters';
import {CountryDto,CountryDtoDefault} from '@gtpl/shared-models/masters';
import {  Link, useHistory } from "react-router-dom";

import {CountryService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
//import 'countries-form.css'

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  }, 
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

export interface CountryFormProps {
  countryData:CountryDto;
  updateItem: (countryData:CountryDto) => void;
  isUpdate: boolean;
  // saveItem:(varirantData:VariantDto) => void;
  closeForm: () => void;
}

export function CountriesForm(props: CountryFormProps) {
  
       const [form] = Form.useForm();
       const service = new CountryService;
       const [disable, setDisable] = useState<boolean>(false)
       
let history =  useHistory()
       const saveCountry = (countryData: CountryDto) => {
        setDisable(true)
        countryData.countryId=0;
        countryData.isActive=true;
        service.createCountry(countryData).then(res => {
          setDisable(false)
          if (res.status) {
            AlertMessages.getSuccessMessage('Country Created Successfully');
         history.push("/Countries-view")
            onReset();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        })
      }
/**
 * 
 * @param values //Dto values
 */
  const saveData = (values: CountryDto) => {
        // console.log(values);
        setDisable(false)
        if(props.isUpdate){
          props.updateItem(values);
        }else{
          setDisable(false)
          saveCountry(values);

        }
      
      };

      

      /**
       * To reset form fields
       */
  const onReset = () => {
    console.log('jjjjjjjj');
    // props.countryData = new CountryDto[""];  
    form.resetFields();
  };
  return (
    <Card title={<span style={{color:'white'}}>Country</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/Countries-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    <Form form={form} initialValues={props.countryData} name="control-hooks" onFinish={saveData}>
    <Form.Item name="countryId" style={{display:"none"}} >
      <Input hidden/>
    </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser" >
      <Input hidden/>
    </Form.Item>
    <Row>
      <Col >
      <Form.Item 
      name="countryName"
      label="Country Name"
      
      rules={[
        {
          required: true,
          message:"Enter valid Country Name"
        },
        {
          
          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
          message: `Should contain only alphabets.`
          }
        
      ]}
    >
      <Input/>
    </Form.Item>
    </Col></Row>
  
   <Row>
   <Col span={24} style={{ textAlign: 'right' }}>
    
      <Button type="primary" disabled={disable} htmlType="submit" >
        Submit
      </Button>
          {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      </Col>
    </Row>
  </Form>
  </Card>
  );
}

export default CountriesForm;
