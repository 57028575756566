import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker } from 'antd';
import './container-register.css';
import { Redirect,useLocation,useHistory } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';

import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';

/* eslint-disable-next-line */
export interface AssignSoProps { }
const { Option } = Select;
export function AssignSo(
  props: AssignSoProps
) {

  let location = useLocation();
  const containerStatusInfo: ContainerStatusInfo | any = location.state;
  const [containerRegisterForm] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(undefined);

  // const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<any[]>([]);


  const SoService = new SaleOrderService();
  const containerRService = new ContainerRegisterService();
  const history = useHistory();

  useEffect(() => {
    getSaleOrderData();
    if (containerStatusInfo) {
      containerRegisterForm.setFieldsValue({
          saleOrderId: containerStatusInfo.saleOrderId,
          containerStatus: ContainerStatusEnum.LOADING,
          containerId:containerStatusInfo.containerId,
          containerNo:containerStatusInfo.containerNo,
          oldSoNumber: containerStatusInfo.saleOrderId,
      });
  } else {
      containerRegisterForm.setFieldsValue({
          containerStatus: ContainerStatusEnum.LOADING
      });
  }
}, [containerStatusInfo]);

  // get sale order drop down details
  const getSaleOrderData = () => {
    SoService.getSoPoBrandDropDownList({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const onReset = () => {
    containerRegisterForm.resetFields();
    containerRegisterForm.setFieldsValue({
      saleOrderId: containerStatusInfo.saleOrderId,
      oldSoNumber: containerStatusInfo.saleOrderId,
      containerStatus: ContainerStatusEnum.LOADING,
      containerNo:containerStatusInfo.containerNo
  });

  containerRegisterForm.setFieldsValue({
      containerStatus: ContainerStatusEnum.LOADING,
      containerNo:containerStatusInfo.containerNo
  });
  }

  const saveData = (values) => {
    values.createdUser=localStorage.getItem('createdUser');
    values.updatedUser=localStorage.getItem('createdUser');
      containerRService.confirmOrAssignSo(values).then(res => {
        if (!res.status) {
          AlertMessages.getErrorMessage(res.message);
        }else{
          history.push({
            pathname: '/container-register-grid',
            // search: '?the=search',
            state: 'Loading'
          })
          AlertMessages.getSuccessMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
  }
  const goToContainerGrid = () => {
    setTab("Dispatched to Plant");
  }
  return (
    <>
        {tab ? <Redirect to={{pathname:"/container-register-grid", state:tab}}/>:null}

    <Card title={<span style={{ color: 'white' }}>Confirm/Assign SO</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link onClick={e => goToContainerGrid()} ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form layout="vertical" form={containerRegisterForm} name="control-hooks" onFinish={saveData} >
        <Row gutter={24}>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  >
            <Form.Item
              name="loadingDate"
              label="Loading Date"
              rules={[{ required: true, message: 'Missing Loading Date' },
              ]} initialValue={moment()}>

              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col> */}
            <Form.Item name="containerId">
                        <Input hidden />
            </Form.Item>

            <Form.Item name="oldSoNumber">
                        <Input hidden />
            </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
          
            <Form.Item label="Container Number" name="containerNo" rules={[{ required: true, message: 'Missing Container Number' }]} >
              <Input disabled/>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
            <Form.Item name="saleOrderId" label="SO Number" >
              <Select
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SaleOrderNumber"
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {saleOrderData?.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{`${dropData.poNumber}-${dropData.brandName}-${dropData.variantCode}`}</Option>

                  // return <Option value={dropData.saleOrderId}>{`${dropData.saleOrderNumber}-${dropData.poNumber}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>
                <Form.Item
                  name="containerStatus"
                  label="Container Status"
                  rules={[
                    {
                      // required: true,message: 'Missed Container Status'
                    },
                  ]}>
                    <Select
                    placeholder="Select Container Status"
                    defaultValue={ContainerStatusEnum.LOADING}
                    disabled={true}
                    >
                    <Option key={0} value={null}>Select Container Status</Option>
                    {ContainerStatusInput.map((container) => {
                      return <Option key={container.id} value={container.id}>{container.status}</Option>
                    })}
                    </Select>
                </Form.Item>
          </Col>
           <Col style={{ paddingTop: '30px' }}>

            <Button type="primary" htmlType="submit" >
              Submit
              </Button>
              {(isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }             
          </Col>
        </Row>
      </Form>
    </Card>
    </>
  );
}

export default AssignSo;
