import React, { useEffect, useRef, useState } from 'react';
import {Button, Card, Col, Form, Input, Row, Select, Table} from 'antd';
import './pages-asset-management-asset-management-components-asset-sale-grid.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import {SearchOutlined,UndoOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {AssetsSaleService} from 'libs/shared-services/asset-management/src/lib/asset-sale'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';


const { Option } = Select;


/* eslint-disable-next-line */
export interface AssetSaleGridProps {}

export function AssetSaleGrid(
  props: AssetSaleGridProps
) {

  const service = new AssetsSaleService;
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [saledAssetsData,setSaledAssetsData] = useState<any>([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const [itemCategory, setItemCategory] = useState([]);
  const [assetCodeData, setAssetCodeData] = useState<any[]>([]);
  const assetsService = new AssetService();
  const role = JSON.parse(localStorage.getItem('role'));
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const employeeService = new EmployeeService();


  useEffect(()=>{
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getSaledAssetDetails();
    getItemCategory();
    getDepartments();
    getAssetCode();
    getLocations();
    employeeDetails();
    getAllPlants();
  },[])

  const getSaledAssetDetails = () => {
    const req = new AssetByAssetCode();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetsCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeName') !== undefined) {
      req.employeeId = form.getFieldValue('employeeName')
    }
    service.getAssetSaleDetails(req).then(res => {
      if(res.status){
        setSaledAssetsData(res.data)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        setSaledAssetsData([])
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    })
  }

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('plantId') !== undefined){
      req.plantId = form.getFieldValue('plantId')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'))
    }
    service.assetCodeDropdownforAssetSaleGrid(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }



  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const onSearch = (val) => {
    console.log('search:', val);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const resetHandler = () => {
    form.resetFields();
    getSaledAssetDetails();
  }

  const finishHandler = (value) => {
    getSaledAssetDetails();

  }

  const [page, setPage] = useState<number>(1);

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    }, 
    {
      title: "Plant",
      dataIndex: "unit_name",
      align: "left",
      // width:120,
      sorter: (a, b) => a.unit_name?.localeCompare(b.unit_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unit_name'),
      render: (text, record) => {
        return (
          <>
            {record.unit_name ? record.unit_name : '-'}
          </>
        )
      },
    },

    {
      title: "Location",
      dataIndex: "location_name",
      align: "left",
      width:120,
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location_name'),
      render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Mode",
      dataIndex: "asset_mode",
      align: "left",
     // width: 110,
      sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_mode'),
      render: (text, record) => {
        return (
          <>
            {record.asset_mode ? record.asset_mode : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Type",
      dataIndex: "asset_type",
      align: "left",
      // width:120,
      sorter: (a, b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_type'),
      render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Category",
      dataIndex: "item_sub_category",
      align: "left",
       width:120,
      // fixed: "left",
      sorter: (a, b) => a.item_sub_category.localeCompare(b.item_sub_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item_sub_category')
    },
    {
      title: "Asset Name",
      dataIndex: "item_name",
      align: "left",
     // width: 120,
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item_name')
    },
    {
      title: "Asset Code",
      dataIndex: "assets_code",
      align: "left",
    //  width: 120,
      sorter: (a, b) => a.assets_code?.localeCompare(b.assets_code),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assets_code'),
    },
    {
      title: "Purchase Date",
      dataIndex: "date_of_purchased",
      align: "right",
      sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('date_of_purchased'),
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "Warranty Date",
      dataIndex: "warrenty_expired_date",
      align: "right",
      sorter: (a, b) => a.warrenty_expired_date?.localeCompare(b.warrenty_expired_date),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('warrenty_expired_date'),
      render: (text, record) => {
        return (
          <>
            {record.warrenty_expired_date ? moment(record.warrenty_expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "Insurance Date",
      dataIndex: "insurance_date",
      align: "right",
      //  width:70,
      sorter: (a, b) => a.insurance_date?.localeCompare(b.insurance_date),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('insurance_date'),
      render: (text, record) => {
        return (
          <>
            {record.insurance_date ? moment(record.insurance_date).format('YYYY-MM-DD') : '-'}
          </>
        )
      },

    },
    {
      title: "Expiry Date",
      dataIndex: "expired_date",
      align: "right",
      // width:120,
      sorter: (a, b) => a.expired_date?.localeCompare(b.expired_date),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('expired_date'),
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },

    },

    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')

    },
    {
      title: ' Asset Age',
      dataIndex: 'assetAge',
      align: "right",
      sorter: (a, b) => a.assetAge - b.assetAge,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetAge'),
      render: (text, record) => {

        return (
          <>
            {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
    },
    {
      title: "Asset Cost",
      dataIndex: "asset_cost",
      align: "right",
      sorter: (a, b) => a.asset_cost-b.asset_cost,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_cost'),
      render: (text, record) => {
        return (
          <>
            {record.asset_cost ? Number(Number(record.asset_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },

    },
    {
      title: "Sale Cost",
      dataIndex: "asset_sale_cost",
      align: "right",
      sorter: (a, b) => a.asset_sale_cost-b.asset_sale_cost,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_sale_cost'),
      render: (text, record) => {
        return (
          <>
            {record.asset_sale_cost ? Number(Number(record.asset_sale_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
    },
    {
      title: "Sale Date",
      dataIndex: "created_at",
      align: "right",
      sorter: (a, b) => a.created_at?.localeCompare(b.created_at),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('created_at'),
      render: (text, record) => {
        return (
          <>
            {record.created_at ?  moment(record.created_at).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },
    },
    {
      title: "Approved By",
      dataIndex: "employee_name",
      width:120,
      align: "right",
      sorter: (a, b) => a.employee_name?.localeCompare(b.employee_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employee_name'),
      render: (text, record) => {
        return (
          <>
            {record.employee_name ? record.employee_name : '-'}
          </>
        )
      },
    },
    {
      title: "Department",
      dataIndex: "dept_name",
      width:120,
      align: "right",
      sorter: (a, b) => a.dept_name?.localeCompare(b.dept_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dept_name'),
      render: (text, record) => {
        return (
          <>
            {record.dept_name ? record.dept_name : '-'}
          </>
        )
      },
    },

    
  ];


  return (
    <>
      <Card
        title={<span style={{ color: 'white' }}> Saled Asset Details</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        extra={
            <Link to="/asset-sale-form">
              <span style={{ color: 'white' }}>
                <Button>Create </Button>{' '}
              </span>
            </Link>
        }
      >
         <Form layout="vertical" form={form} onFinish={finishHandler}>
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                defaultValue={'All'}
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}>
            <Form.Item name="assetCode" label="Asset Code">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetCodeData.map((assets) => {
                  return <Option key={assets.assets_code} value={assets.assets_code}>{`${assets.item_name}--${assets.assets_code}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label='Plant' name='plantId'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                showSearch
                allowClear>
                {/* <Option key={'all'} value={''}>All</Option> */}
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Department' name='department'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[24, 24]}>
          <Col span={5}>
            <Form.Item label='Employee Name - Code' name='employeeName'>
              <Select
                optionFilterProp="children"
                //  filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ marginTop: '2%', marginLeft: '1%' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Col>
          <Col style={{ marginTop: '2%', marginLeft: '1%' }}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
        <Table 
          columns={columns} 
          dataSource={saledAssetsData} 
          scroll={{x:2000,y:400}}
          pagination = {{
            onChange(current) {
              setPage(current);
            }
          }}
        />


      </Card>
    </>
  );
}

export default AssetSaleGrid;
