import { TeamDto } from "@gtpl/shared-models/hrms";
import { TeamsService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

export interface TeamFormProps {
  teamData: TeamDto;
  updateTeam: (team: TeamDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function TeamForm(props: TeamFormProps) {

  const [form] = Form.useForm();
  let history = useHistory();
  const teamservice = new TeamsService;
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    if (props.teamData) {
      console.log(props.teamData)
    }
  }, [props.teamData])

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const createTeam = (team: TeamDto) => {
    teamservice.createTeam(team).then(res => {
      if (res.status) {
        history.push("/team-view");
        onReset();
        AlertMessages.getSuccessMessage('Team Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('')
    form.resetFields();
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const saveData = (values: TeamDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateTeam(values);
    } else {
      console.log(values);
      createTeam(values);
    }
  }

  const onFocus = () => {
    console.log('focus');
  }
  const onBlur = () => {
    console.log('blur');
  }

  //   const getNoOfDays = (dateType: string) => {
  //     const fromDate = form.getFieldValue('fromDate');
  //     const toDate = form.getFieldValue('toDate');
  //     if (moment(fromDate).format('dddd') === 'Sunday' || moment(toDate).format('dddd') === 'Sunday') {
  //       AlertMessages.getErrorMessage('Selected day should not be a sunday');
  //       if (dateType == 'fromDate') {
  //         setFromDate(moment());
  //         form.setFieldsValue({
  //           fromDate: moment()
  //         })
  //       }
  //       if (dateType == 'toDate') {
  //         form.setFieldsValue({
  //           toDate: moment()
  //         })
  //       }
  //     }
  //     const d1 = moment(form.getFieldValue('fromDate'), 'YYYY-MM-DD');
  //     const d2 = moment(form.getFieldValue('toDate'), 'YYYY-MM-DD');
  //     // Calc all days used including last day ( the +1 )
  //     const days = d2.diff(d1, 'days') + 1;

  //     console.log('Days:', days);

  //     // how many full weekends occured in this time span
  //     const weekends = Math.floor(days / 7);

  //     console.log('Full Weekends:', weekends);

  //     // Subtract all the weekend days
  //     let businessDays = days - (weekends * 1);

  //     // Special case for weeks less than 7
  //     if (weekends === 0) {
  //       const cur = d1.clone();
  //       for (let i = 0; i < days; i++) {
  //         if (cur.day() === 0) {
  //           businessDays--;
  //         }
  //         cur.add(1, 'days')
  //       }
  //     } else {
  //       // If the first day is a sunday we need to account for it
  //       if (d1.day() === 0) {
  //         console.log('Extra weekend day (Sunday)');
  //         businessDays--;
  //       }
  //     } 
  // } 
  // function disabledDate(current) {
  //     // Can not select days before today and today
  //     return current && current < fromDate;
  //   }

  return (
    <Card title={<span style={{ color: 'white' }}>Shift Group</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/team-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} name="control-hooks" layout="vertical" onFinish={saveData} initialValues={props.teamData}>

        <Form.Item name="id" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
        <Form.Item
          name="teamName"
          label="Shift Group"
          rules={[
            {
              required: true,
              message: "Shift Group is required"
            },

          ]}>
        <Input />
        </Form.Item>
      </Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="shiftCode"
              label="Shift Group"
              rules={[
                {
                  required: true,
                  message: "Shift Group is required"
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default TeamForm;