
import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Form,
  Input,
  Button,
  Col,
  Row,
  Select,
  DatePicker,
  Descriptions,
  Modal,
  Alert,
  Divider,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AssetService } from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AssetsLocationDto } from 'libs/shared-models/asset-management/src/lib/asset-location/asset-location.dto';
import TextArea from 'antd/lib/input/TextArea';
import { VendorsDto } from '@gtpl/shared-models/masters';
import { ItemSubCategoryService } from 'libs/shared-services/masters/src/lib/item-sub-category-service';
import { ItemBySubcategory } from "libs/shared-models/masters/src/lib/items/item-by-subcategory";
import moment from "moment";
import { ServiceStatusEnum } from "@gtpl/shared-models/common-models";
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import {AssetRequestService} from 'libs/shared-services/asset-management/src/lib/asset-request.service';

const { Option } = Select;
const Rangepicker = DatePicker;

/* eslint-disable-next-line */
export interface AssetRequisitionFormProps  {
  assetData: AssetDto;
  updateAsset: (assetData: AssetDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function AssetRequisitionForm(
  props: AssetRequisitionFormProps
  ) {
  const service = new AssetRequestService();
  const [disable, setDisable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [insuranceData, setInsuranceData] = useState<boolean>(false);
  const [AmcData, setAmcData] = useState<boolean>(false);
  const [Data, setData] = useState<boolean>(false);
  const [fos, setFos] = useState<boolean>(false);
  const [vendorsData, setVendorsData] = useState<VendorsDto[]>([]);
  const [amcnoData, setAmcNoData] = useState<boolean>(true);
  const [manufacturerData, setManufacturerData] = useState<boolean>(undefined);
  const [dealerData, setDealerData] = useState<boolean>(false);
  const [location, setLocation] = useState<AssetsLocationDto[]>([]);
  const [item, setItem] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [plantName, setPlantName] = useState<any>([]);
  const [rented, setRented] = useState<boolean>(false);
  const [owned, setOwned] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [update,setUpdate] = useState<boolean>(false);
  const [isdisabled,setIsdisabled] = useState<boolean>(false)
  const employeeService = new EmployeeService();
  const assetService = new AssetService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const assetRequestservice = new AssetRequestService();

  useEffect(() => {
    if(props?.isUpdate){
      setUpdate(true)
      setIsdisabled(true)
    }
    employeeDetails();
    getItemCategory();
  }, []);

/* eslint-disable-next-line */

const onFinish = (values) => {
  console.log(values,'-------------------------------')
  assetRequestservice.createAssetRequest(values).then(res => {
    if(res.status){
      AlertMessages.getSuccessMessage(res.internalMessage)
      form.resetFields()
    } else {
      AlertMessages.getErrorMessage(res.internalMessage)
    }
  })
};
const getItems = (id) => {
  const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'));
    req.assetCategory = id;
  assetService.getInstockAssets(req).then((res) => {
    if (res.status) {
      setItem(res.data);
    } else {
      AlertMessages.getErrorMessage(res.internalMessage);
    }
  });
};

const getItemCategory = () => {
  const req = new AssetByStatus();
  req.plantId = Number(localStorage.getItem('unit_id'));
  service.getAssetCategoryDropDown(req).then((res) => {
    if (res.status) {
      setItemCategory(res.data);
    } else {
      AlertMessages.getErrorMessage(res.internalMessage);
    }
  });
};
const onReset = () => {
  form.resetFields();
};

const employeeDetails = () => {
  employeeService.getAllActiveEmployees().then((res)=>{
    if(res.status) {
      setEmployeeData(res.data)
    } else {
      AlertMessages.getErrorMessage(res.internalMessage)
    }
  })
}
return(
  <Card
  title={<span style={{ color: 'white' }}> Asset Requisition Form</span>}
  style={{ textAlign: 'center' }}
  headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
  extra={
    props.isUpdate == true ? (
      ''
    ) : (
      <Link to="/asset-requistion-grid">
        <span style={{ color: 'white' }}>
          <Button>View </Button>{' '}
        </span>
      </Link>
    )
  }
>
  <Form
    form={form}
    initialValues={props.assetData}
    name="control-hooks"
    layout="vertical"
    onFinish={onFinish}
  >
        <Row gutter={24}>
        <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
              <Form.Item name="requestTime" label="Request Date" initialValue={moment(moment().format("YYYY-MM-DD"))}>
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" defaultValue={moment(moment().format("YYYY-MM-DD"))}/>
              </Form.Item>
            </Col>
        <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
              <Form.Item
                name="raisedBy"
                label="Raised By"
                rules={[
                  {
                    required: true,
                    message: 'Raised By is required',
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  allowClear
                  
                >
                  {employeeData.map((employee) => {
                    return (
                      <Option
                        key={employee.employeeId}
                        value={employee.employeeId}
                      >
                        {employee.employeeName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            style={{ margin: '1%' }}
          >
            <Form.Item
              name="assetCategory"
              label="Asset Category"
              rules={[
                {
                  required: true,
                  message: 'Asset Category is required',
                },
              ]}
            >
              <Select
                placeholder="asset category"
                showSearch
                onChange={(value) => getItems(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {itemCategory.map((items) => {
                  return (
                    <Option
                      key={items.item_sub_category_id}
                      value={items.item_sub_category_id}
                    >
                      {items.item_sub_category}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        
          <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
               <Form.Item name='reason' label='Purpose'
            rules={[{
              required:true,
              message: 'Purpose is required',
              max:500,
            }]}
          >
            <Select>
              <Option key={1}value= {'assigningToNewEmployee'}>Assigning To New Employee</Option>
              <Option key={2}value= {'assigningToNewProject'}>Assigning To New Project</Option>
              <Option key={3}value= {'businesspurpose'}>Business purpose</Option>
            </Select>
          </Form.Item>
            </Col>
          <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
              style={{ margin: '1%' }}
            >
              <Form.Item name="remark" label="Remark ">
                <TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
          <Col style={{marginLeft:"80%"}}>
            <Button type="primary" disabled={disable} htmlType="submit">
              Submit
            </Button>
            {/* </Col>
            <Col style={{marginLeft:"85%"}}> */}
            {/* {(props.isUpdate == false) && */}
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
            {/* } */}
          </Col>

        </Row>
          </Form>
          </Card>
    );

  }

export default AssetRequisitionForm;
