import { CodeListMainIdRequest } from '@gtpl/shared-models/planning'
import { CodeListMainService } from '@gtpl/shared-services/planning';
import { Card } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './code-list-main-grid.css';
export interface CodeListPrintProps {
    selectedData: any
}


export function CodeListPrint(props?: CodeListPrintProps) {
    const [printData, setPrintData] = useState<any[]>([]);
    const codeListMainService = new CodeListMainService()

    useEffect(() => {
        getCodeListPrintData()
    }, [])

    console.log(props.selectedData)

    const getCodeListPrintData = () => {
        const req = new CodeListMainIdRequest();
        req.poNumber = props.selectedData.poNumber
        req.saleOrderId = props.selectedData.saleOrderId
        codeListMainService.getCodeListPrintData(req).then((res) => {
            if (res.status) {
                setPrintData(res.data)
            } else {
                setPrintData([])
            }
        }).catch((err) => {
            setPrintData([])
        })
    }

    function print() {
        const divContents = document.getElementById('printme').innerHTML;
        const element = window.open('', '', 'height=700, width=1024');
        element.document.write(divContents);
        getCssFromComponent(document, element.document);
        element.document.close();
        element.print();
        element.close(); // to close window when click on cancel/Save
    }
    const getCssFromComponent = (fromDoc, toDoc) => {
        Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
            if (styleSheet.cssRules) { // true for inline styles
                const newStyleElement = toDoc.createElement('style');
                Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
                    newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
                });
                toDoc.head.appendChild(newStyleElement);
            }
        });
    }

    const getSpecMembers = () => {
        return <table style={{ fontSize: '11px' }}>
            <tr className='ta-spec'>
                <td colSpan={2}><b>{'SPEC(' + props.selectedData.brandName + ')'}</b></td>
            </tr>
            <tr className='ta-spec'>
                <td colSpan={1} className='ta-spec'>GLAZE</td>
                <td colSpan={1} style={{ width: '200px' }}></td>

            </tr>
            <tr className='ta-spec'>
                <td colSpan={1} className='ta-spec'>TREATMENT</td>
                <td colSpan={1} style={{ width: '200px' }}>{''}</td>
            </tr>
            <tr className='ta-spec'>
                <td colSpan={1} className='ta-spec'>CUTTING</td>
                <td colSpan={1} style={{ width: '200px' }}>-</td>
            </tr>
            <tr className='ta-spec'>
                <td colSpan={1} className='ta-spec'>BAP</td>
                <td colSpan={1} style={{ width: '200px' }}>{printData[0]?.rating + ' STAR'}</td>
            </tr>
            <tr className='ta-spec'>
                <td colSpan={1} className='ta-spec'>OTHER</td>
                <td colSpan={1} style={{ width: '200px' }}>{''}</td>
            </tr>
        </table>
    }
    return (
        <Card className='code-list-print'>
            {printData.length ? <html>
                <head>

                </head>
                <button onClick={print}>Print</button>
                <body id='printme'>
                    <table style={{ width: '100%' }}>
                        <tr >
                            <td colSpan={5} style={{ padding: '5px' }}>
                                <h1 style={{ textAlign: 'center' }}> {'SANDHYA AQUA EXPORT PVT.LTD' + (printData[0]?.approvalNo ? ` (${printData[0]?.approvalNo})` : '')}</h1>
                                <h2 style={{ textAlign: 'center' }}>CODE LIST</h2>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}><h4>{'COUNTRY   : ' + props.selectedData.countryName}</h4></td>
                            <td colSpan={2}>{'DATE : ' + moment(printData[0]?.createdDate).format('DD.MM.YYYY')}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}> <h4>{'BUYER    : ' + props.selectedData.endCustomerName}</h4></td>
                        </tr>
                        <tr>
                            <td colSpan={3}><h4>{'BRAND     : ' + props.selectedData.brandName}</h4></td>
                            {/* <td style={{ position: 'absolute',top:'170px',right:'11px' }}>{''}</td> */}
                        </tr>
                        {/* <tr>
                            <td colSpan={3}><h4>{'PL NO     : '} </h4></td>
                        </tr> */}
                        <tr>
                            <td colSpan={3}><h4>{'PO NUMBER : ' + props.selectedData.poNumber}</h4></td>
                        </tr>

                        {printData.map((item) => {
                            let totalCartons = 0

                            return (<>
                                <tr>
                                    <td >
                                        <h3>PACKING :{item.packStyle}</h3>
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        <h3 style={{ textTransform: 'uppercase' }}>VARIETY : {' ' + item.skuDetails.foodType + ' FROZEN ' + (item.skuDetails.valueAdd1Name == 'N/A' ? "" : item.skuDetails.valueAdd1Name) + ' ' + (item.skuDetails.valueAdd2Name == 'N/A' ? "" : item.skuDetails.valueAdd2Name) + ' ' + (item.skuDetails.valueAdd3Name == 'N/A' ? "" : item.skuDetails.valueAdd3Name) + ' ' + (item.skuDetails.valueAdd5Name == 'N/A' ? "" : item.skuDetails.valueAdd5Name) + ' ' + item.skuDetails.productName + ' ' + (item.skuDetails.valueAdd4Name == 'N/A' ? "" : item.skuDetails.valueAdd4Name)}</h3>
                                    </td>
                                </tr>
                                <tr className='ta-cl' style={{ justifyContent: 'center' }}>
                                    <td className='ta-cl' rowSpan={2} colSpan={1}><b>CODE</b></td>
                                    <td className='ta-cl' rowSpan={2} colSpan={1}><b>TRACEBILITY</b></td>
                                    <td className='ta-cl' colSpan={1}><b>PRODUCTION DATE</b></td>
                                    <td className='ta-cl' colSpan={1}><b>BEST BEFORE DATE</b></td>
    
                                    <td className='ta-cl' rowSpan={2} colSpan={1}><b>TOTAL</b></td>

                                </tr>
                                <tr className='ta-cl' style={{ justifyContent: 'center' }}>
                                    {/* <td className='ta-cl' colSpan={1}><b></b></td> */}
                                    {/* <td className='ta-cl' colSpan={1}><b></b></td> */}
                                    <td className='ta-cl' colSpan={1}><b>DD/MM/YYYY</b></td>
                                    <td className='ta-cl' colSpan={1}><b>DD/MM/YYYY</b></td>
                                    {/* <td className='ta-cl' colSpan={1}><b></b></td> */}
                                </tr>
                                {item.codeListDetails.map((element) => {
                                    totalCartons += Number(element.cartons)
                                    return (<>
                                        <tr className='ta-cl'>
                                            <td className='ta-cl' colSpan={1}>{element.code}</td>
                                            <td className='ta-cl' colSpan={1}>{element.tracebilityCode}</td>
                                            <td className='ta-cl' colSpan={1}>{moment(element.productionDate).format('DD.MM.YYYY')}</td>
                                            <td className='ta-cl' colSpan={1}>{moment(element.bestBeforeDate).format('DD.MM.YYYY')}</td>
                                            <td className='ta-cl' colSpan={1}>{element.cartons}</td>
                                        </tr>
                                    </>
                                    )
                                })}
                                <tr className='ta-cl'>
                                    <td className='ta-cl' colSpan={4}>TOTAL MASTER CARTONS</td>
                                    <td className='ta-cl' colSpan={1}>{totalCartons}</td>
                                </tr>
                            </>)
                        })}
                        <tr style={{ paddingTop: '15px' }}>
                            <td colSpan={1}>ISSUED BY:</td>
                            <td colSpan={2}><h3>TOTAL MC/s:</h3></td>
                            <td colSpan={2}>VERIFIED BY:</td>
                        </tr>
                    </table>
                </body>
            </html> : <></>}
        </Card >

    )
}

export default CodeListPrint