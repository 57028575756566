import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker } from 'antd';
import { EmployeeCodeRequest, HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeService, HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { ApplyForLeavesDto, AttendanceAdjustRequest, AttnAdjustLogReq } from '@gtpl/shared-models/hrms';
import { AttendanceService } from '@gtpl/shared-services/hrms';

import './attendance-adjustment.css';
import moment from 'moment';

const { Option } = Select;

/* eslint-disable-next-line */
export interface AttendanceAdjustmentProps {
  applyForLeavesData: ApplyForLeavesDto;
  updateDetails: (hrms: ApplyForLeavesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function AttendanceAdjustment(
  props: AttendanceAdjustmentProps
) {
  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const service = new HrmsEmpDetService();
  const empService = new EmployeeService();
  const attnService = new AttendanceService();
  const [presentStatusHidden, setPresentStatusHidden] = useState<boolean>(false);

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  useEffect(() => {
    getAllActiveEmployeesForLeaves();
  }, [])

  const getAllActiveEmployeesForLeaves = () => {
    service.getAllActiveEmployeesForLeaves().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData(res.data);
          console.log(res.data)
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAdjustmentData = () => {
    const employeeCode = form.getFieldValue('employeeCode');
    const currDate = new Date();
    const date = moment(form.getFieldValue('date')).format('YYYY-MM-DD') || moment(currDate).format('YYYY-MM-DD')
    const request = new AttendanceAdjustRequest(employeeCode, date);
    attnService.getAdjustmentData(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          inTime: res.data.inTime ? moment(res.data.inTime) : moment(),
          outTime: res.data.outTime ? moment(res.data.outTime) : moment(),
          presentStatus: res.data.presentStatus,
          shift: res.data.shift
        })
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const validateFutureDate = () => {
    const selectedDate = form.getFieldValue('date')
    if (moment(selectedDate).isAfter(moment())) {
      setPresentStatusHidden(true)
    } else {
      setPresentStatusHidden(false)
    }
  }

  const getEmployeeDetails = () => {
    const employeeCode = form.getFieldValue('employeeName')
    const request = new EmployeeCodeRequest(employeeCode);
    empService.getActiveEmployeesByEmpCode(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeCode: res.data.employeeCode,
        });
        getAdjustmentData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getEmployeeName = () => {
    const employeeCode = form.getFieldValue('employeeCode')
    const request = new EmployeeCodeRequest(employeeCode);
    empService.getActiveEmployeesByEmpCode(request).then(res => {
      if (res.status) {
        form.setFieldsValue({
          employeeName: res.data.employeeName,
        });
        getAdjustmentData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const setDatePicker = () => {
    form.setFieldsValue({
    })
  }

  const saveData = (req: AttnAdjustLogReq) => {
    req.date = moment(form.getFieldValue('date')).format('YYYY-MM-DD');
    req.user = localStorage.getItem("username");
    attnService.attendanceAdjustment(req).then(res => {
      if (res.status) {
        onReset();
        AlertMessages.getSuccessMessage('Attendance Adjustment Applied Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage("Error");
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current.valueOf() > Date.now();
  }

  const onReset = () => {
    form.resetFields();
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onBlur = () => {
    console.log('blur');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Attendance Adjustment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

      <Form form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={saveData}
      >

        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "code is required"
                },
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Name"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={getEmployeeDetails}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeCode}>{employeeData.employeeName + " " + employeeData.employeeLastName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item
              name="employeeCode"
              label="Employee Code"
              rules={[
                {
                  required: true,
                  message: "name is required"
                },
              ]}>
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Employee Code"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                onChange={getEmployeeName}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {employeeData.map((employeeData) => {
                  return <Option key={employeeData.employeeId} value={employeeData.employeeCode}>{employeeData.employeeCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="date" label="Date" >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                defaultValue={moment(moment().format("YYYY-MM-DD"))}
                showToday={true}
                onChange={() => { getAdjustmentData(); validateFutureDate(); setDatePicker(); }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="shift" label="Shift">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="presentStatus"
              label="Attendance Status"
              rules={[
                {
                  required: true,
                  message: " Status is required"
                }
              ]}
            >
              {<Select
                showSearch
                placeholder="Select Status"
              >
                <Option hidden={presentStatusHidden} key={1} value={'P'}>Present</Option>
                <Option key={2} value={'A'}>Absent</Option>
                <Option key={3} value={'OD'}>Out Door</Option>
                <Option key={4} value={'H'}>Holiday</Option>
                <Option key={5} value={'WFH'}>WFH</Option>
                <Option key={6} value={'L'}>Leave</Option>
                <Option key={7} value={'HD'}>Half Day</Option>
                {/* <Option key={6} value={'COFF'}>Comp OFF</Option>  */}
              </Select>}
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="inTime" label="In Time" >
              <DatePicker showTime={{ format: "HH:mm:ss" }} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                defaultValue={moment()}
                // disabledDate={disabledDate}
                showToday={true} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="outTime" label="Out Time" >
              <DatePicker showTime={{ format: "HH:mm:ss" }} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                defaultValue={moment()}
                // disabledDate={disabledDate}
                showToday={true} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: " Reason is required"
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}
            >
              <Select
                showSearch
                placeholder="Select Status"
              >
                <Option key={1} value={'Permission'}>Permission</Option>
                <Option key={2} value={'Not Registered'}>Not Registered</Option>
                <Option key={3} value={'OD'}>Out Door</Option>
                <Option key={4} value={'Others'}>Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
            <Form.Item name="remarks"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: " Remarks are required", max: 30,
                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>

            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AttendanceAdjustment;
