import React from 'react';
import { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Space } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  UnitsOfWeightInput
} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { isRgbColor } from 'class-validator';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import HtmlToDocx from 'html-docx';
/* eslint-disable-next-line */
export interface ShippingInstructionsProps {
  saleOrderId: number;
}

export function ShippingInstructions(props: ShippingInstructionsProps) {
  let location = useLocation();
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const soId = location.state;


  let totalCases = 0;
  let totalLbWeight = 0;
  let totalAmount = 0;
  let totalGrossWeight = 0;
  let totalCartons = 0;
  let finalWeight = 0;


  const getData = (saleOrderId) => {
    //const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails(new PlantInvoiceDetailsRequest(saleOrderId)).then((res) => {
      console.log(saleOrderId)
      if (res.status) {
        console.log(res.data);
        setPlantDetails(res.data);
      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  useEffect(() => {
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
  }, [props.saleOrderId])

  let exporterDetails = ExporterDataInput;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId
  );

  let unitInput = FactoriesInput;
  const unitsData = FactoriesInput.find(item => item.id == plantDetails?.unitId)
  const tableData = () => {
    const tableDataArray = [];
    for (const data of plantDetails?.saleOrderItems) {
      const tableDataArray = data;
    }
  };

  // const generatePDF = () => {
  //   // const input = document.getElementById("printme");
  //   // var doc = new jsPDF('p', 'mm', 'a1');
  //   // doc.html(input, { html2canvas: { scale: 0.57 } }).then(() => {
  //   //   doc.save("test.doc");
  //   // });
  //   HtmlToDocx({
  //     exportElement: '#printme', 
  //     exportFileName: 'ShippingInstructions.docx', 
  //     StringStyle: '', 
  //     // margins:{top: 1200,right: 1200,bottom: 1200,left: 1200,header: 720,footer: 720,gutter: 0} 
  //   })
  // }
  return (

    <>
      {plantDetails ?
        <>
          <span style={{ alignContent: 'left', backgroundColor: 'red' }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="ShippingInstructions"
              sheet="ShippingInstructions"
              buttonText="Get Excel" />
          </span>
          <br />
          <html>
            <head></head>
            {/* <Space style={{ marginBottom: 16 }}>
      <Button onClick={() => generatePDF()}>Export to DOC</Button>
    </Space> */}
            <body id="printme" className={"ShippingInstructions"}>
              <br />
              <table style={{ width: '100%' }} className={'ta-b'} id="table-to-xls">
                <tr><td colSpan={9} style={{ textAlign: 'center', fontSize: '18px' }}><h1>SHIPPING INSTRUCTIONS</h1></td></tr>
                <tr>
                  <td colSpan={4} className={'ta-b'}>
                    <b>SHIPPER:</b>
                    <br />
                    <h4 style={{ display: 'inline' }}>{(exporterData?.name) ? exporterData?.name : ''}</h4><br />
                    {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                    <br />
                    {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                    <br />
                    {exporterData?.state + ', ' + exporterData?.country}
                  </td>
                  <td colSpan={5} rowSpan={2} className={'ta-b'}>

                    <b>TYPE OF B/L:</b> ORIGINAL{' '}<br />

                    <b style={{ backgroundColor: '#E0E0E0', color: '#FF3333' }}>BOOKING NUMBER:</b><br />


                    <p style={{ backgroundColor: '#FFFF33', color: '#FF3333' }}>
                      <b> BL Remarks: Please arrange Freight Invoice in USD currency and all local charges in INR.</b>
                    </p>
                    <br /><br /><br /><br /><br />
                  </td>
                </tr>
                <tr>
                  <td className={'ta-b'} colSpan={4}>
                    <b>
                      Consignee:{' '}
                    </b><br />
                    <b>TO THE ORDER </b>
                    {/* <br />
    {plantDetails?.saleOrderItems[0]?.customeraddress?}<br/>
    <b>FDA Registration No.</b>16502951940 */}
                    {/* 
                    <br />

                    JP MORGAN CHASE & CO.
                    <br />
                    10 S. DEARBORN,
                    <br />
                    CHICAGO, IL 60603-2300
                    <br /> */}

                    <b>AZ GEMS INC</b><br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                    {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
                    <br /><b>FDA REGISTRATION NO.</b>{plantDetails?.unitData?.fdaRegNumber}


                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className={'ta-b'} style={{ textAlign: 'start' }}>
                    <b ><u>
                      Notify party</u>
                    </b><br />
                    {/* {plantDetails?.saleOrderItems[0]?.deliveryaddress}<br/><br/><br/> */}
                    <b>ROD INTERNATIONAL CHB SERVICES LLC</b>
                    <br />
                    10330 PIONEER BLVD, SUITE 200
                    <br />
                    SANTA FE SPRINGS, CA 90670, USA.
                    <br />
                    TEL: (562)622-0715, FAX: (562)622-0745
                  </td>
                  <td className={'ta-b'} colSpan={4}></td>
                </tr>
                <tr>
                  {
                    plantDetails?.saleOrderItems?.length ? plantDetails?.saleOrderItems.map(item => {
                      const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                      let totWeight = 0;
                      if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                        totWeight = item.netWeight;
                      } else {
                        totWeight = item.netWeight * 2.20462;
                      }
                      finalWeight = Number(finalWeight) + Number(totWeight);
                    }
                    )
                      : ''}
                  <td className={'ta-b'} colSpan={4}>

                    <b> Vessel and Voyage No.</b>

                    {plantDetails?.vesselName}



                    <b>{Number(Number(finalWeight) * 0.454).toFixed(3)} (IN KGS)</b>

                  </td>
                  <td colSpan={5} className={'ta-b'}>
                    <b>
                      Place of Receipt:{' '}
                    </b>

                    VISAKHAPATNAM, INDIA

                  </td>
                </tr>
                <tr>
                  <td className={'ta-b'} colSpan={2}>
                    <b>
                      Port of Loading:{' '}
                    </b>
                    <br />
                    VISAKHAPATNAM, INDIA
                    <br />
                  </td>
                  <td className={'ta-b'} colSpan={2}>
                    <b>
                      Port of Discharge:{' '}
                    </b>
                    <br />
                    {plantDetails?.saleOrderItems[0]?.destinationDetails}, {plantDetails?.country}
                    <br />
                  </td>
                  <td className={'ta-b'} colSpan={5}>
                    <b>
                      Final place of Delivery:{' '}
                    </b>
                    <br />
                    {plantDetails?.saleOrderItems[0]?.destinationDetails}, {plantDetails?.country}
                    {/* LOS ANGELES, USA */}

                  </td>
                </tr>
                {/* </table>
              <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed', }} id="table-to-xls"> */}
                <tr id="containerHeader">
                  <td className={'ta-b'} colSpan={2} style={{ width: '20%', textAlign: 'center', backgroundColor: '#C0C0C0' }}>
                    <b>
                      Container Nos. Marks<br /> & Numbers:
                    </b>
                    <br />
                  </td>
                  <td className={'ta-b'} colSpan={6} style={{ width: '60%', backgroundColor: '#C0C0C0' }}>
                    <h4 style={{ textAlign: 'center' }}>
                      <b>CARGO DETAILS</b>
                    </h4>
                  </td>
                  {
                    plantDetails?.saleOrderItems?.length ? plantDetails?.saleOrderItems.map(item => {
                      console.log("item.grossWeight" + item.grossWeight);
                      console.log("item.grossWeight" + item.cases);
                      totalCartons += item.cases;
                      // const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                      // let finalNetWeight;
                      // if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                      //   finalNetWeight = item.netWeight;
                      // } else {
                      //   finalNetWeight = item.netWeight * 2.20462;
                      // }
                      // totalLbWeight += Number(finalNetWeight);
                      // totalAmount += Number(item.revisedUnitPrice) * Number(finalNetWeight);
                      // totalGrossWeight += Number(item.grossWeight);

                      // console.log('totalGrossWeight' + totalGrossWeight);
                    }
                    )
                      : ''}
                  <td className={'ta-b'} colSpan={1} style={{ width: '20%', textAlign: 'center', backgroundColor: '#C0C0C0' }}>
                    <b>Gross Weight</b>
                  </td>
                </tr>
                <tr>
                  <td className={'ta-b'} colSpan={2} style={{ width: '20%', height: '70%' }}>
                    <b>CONTAINER NUMBER:</b> <br />
                    <h4>{plantDetails?.saleOrderItems[0]?.containerNo}</h4><br />
                    <b>LINER SEAL NO.</b><br />
                    <b>{plantDetails?.saleOrderItems[0]?.linearSelaNo}</b>
                  </td>
                  <td className={'ta-b'} colSpan={6} style={{ width: '80%', height: '70%' }}>
                    <p style={{ color: "#FF3333" }}>

                      <b>TOTAL {totalCartons} MASTER CARTONS :<br /></b>
                    </p>
                    {plantDetails?.saleOrderItems?.length ? plantDetails?.saleOrderItems.map(item => {
                      totalCases += item.cases;
                      const uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                      let finalNetWeight = 0;
                      if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                        finalNetWeight = item.netWeight;
                      } else {
                        finalNetWeight = item.netWeight * 2.20462;
                      }
                      totalLbWeight = Number(totalLbWeight) + Number(finalNetWeight);
                      let itemGrossWeight = 0;
                      if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
                        itemGrossWeight = item.grossWeight;
                      } else {
                        itemGrossWeight = item.grossWeight * 2.20462;
                      }
                      totalGrossWeight += Number(itemGrossWeight);

                      totalAmount += Number(item.revisedUnitPrice) * Number(finalNetWeight);
                      let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS" : item.itemName === "Black Tiger" ? "Black Tiger" : "";
                      let shrimp = item.itemName === "Vanname" ? "SHRIMP" : "";
                      return (
                        <p style={{ color: "#FF3333" }}>

                          <b>
                            {totalCases} MASTER CARTONS OF <br />{item.foodTypeName.toUpperCase() + '  ' + item.valueAdditionOneIdName.toUpperCase() + '  ' + item.valueAdditionTwoIdName.toUpperCase()}<br />
                            {item.valueAdditionThreeIdName.toUpperCase() + '  ' + item.itemName.toUpperCase() + ' ' + 'SHRIMPS ,' + item.valueAdditionFourIdName.toUpperCase() + ' ,'} <br />
                            LATIN NAME: {`${latinName.toUpperCase()} ${item.itemName.toUpperCase()}`}<br />
                            REF NO. SO-14485,<br />
                            PACKINGS: {item.pouches + ' * ' + Number(item.pouchWeight) + ' ' + uom.name.toUpperCase()} = {(item.pouches) * (item.pouchWeight) + ' ' + uom.name.toUpperCase()}<br />
                            PER MASTER CARTONS; <br /> <br />
                            <br />
                          </b>
                        </p>
                      );
                    }
                    )
                      : ''}  <p style={{ color: "#FF3333" }}>

                      <b>
                        TOTAL NET WEIGHT: <br />
                        {/* {plantDetails?.saleOrderItems?.map(item => {return(<p>{(item.netWeight)?Number(item.netWeight).toFixed(3):0} LBS/{Number(totalLbWeight*0.454).toFixed(3)} KGS</p>)})} */}
                        {(totalLbWeight) ? Number(Number(totalLbWeight).toFixed(3)) : 0} LBS/{Number(Number(totalLbWeight) * 0.454).toFixed(3)} KGS
                        <br />
                        TOTAL GROSS WEIGHT: <br />
                        {/* {(totalLbWeight) ? Number(Number(totalLbWeight).toFixed(3)) : 0} LBS/{(Number(totalLbWeight) * 0.454).toFixed(3)} KGS */}
                        {((Math.round((plantDetails?.saleOrderItems[0]?.grossWeightPerKg) * (plantDetails?.saleOrderItems[0]?.invoicedCases))) / 0.454).toFixed(3)} LBS/{(Math.round((plantDetails?.saleOrderItems[0]?.grossWeightPerKg) * (plantDetails?.saleOrderItems[0]?.invoicedCases))).toFixed(3)} KGS<br /><br />

                        INVOICE NO. {plantDetails?.invoiceNumber} <br />
                        DATED : {moment(plantDetails?.invoiceDate).format('DD-MMM-YYYY')}; <br />
                        PO NO. {plantDetails.custPoNo};<br /><br />
                        PROCESSED AND PACKED BY: <br />
                        {plantDetails?.invoicedPlant?.unitcodeName},
                        <br />
                        {plantDetails?.invoicedPlant?.villageName + ", " + plantDetails?.invoicedPlant?.mandalName}<br />
                        {plantDetails?.invoicedPlant?.postalCode + ", " + plantDetails?.invoicedPlant?.distirctName}<br />
                        {plantDetails?.invoicedPlant?.stateName + ", " + plantDetails?.invoicedPlant.countryName}
                        <br />
                        FDA Regestration Number:{plantDetails?.invoicedPlant?.fdaRegNumber};
                        <br />
                        FEI Number:{plantDetails?.invoicedPlant?.feiNumber};<br />
                        <br />
                        Notify party -2:
                        <br />
                        {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[0] + ","}<br />
                        {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[1] + ","}
                        {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[2] + ","}
                        {plantDetails?.saleOrderItems[0]?.customeraddress?.split(",")[3]}<br />
                        FDA REGISTRATION NO. 16502951940;
                        <br />
                        <br />
                        SHIPPING BILL NO. {plantDetails?.blNO}, <br />
                        DATED: {moment(plantDetails.actualEdl).format("DD-MMM-YYYY")};<br /><br />
                        FREIGHT PREPAID<br />
                        FULL CONTAINER LOAD<br />
                        CY / CY<br />
                        CONTAINER SET TEMPERATURE:
                        MINUS 18 DEGREES CELSIUS
                      </b>
                    </p>

                  </td>

                  <td className={'ta-b'} colSpan={1} style={{ width: '20%', textAlign: 'center' }}>

                    <b>{(Number(totalGrossWeight * 0.454)).toLocaleString('en-IN', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} (IN KGS)</b>


                  </td>


                </tr>
                {/* </table>

              <table style={{ width: '100%' }} id="table-to-xls"> */}

                <tr>


                  <td className={'ta-b'} colSpan={3} style={{ width: '33%' }}>
                    <b>
                      Total No.of Packages:</b><br />
                    {totalCases} MASTER CARTONS
                    <br /><br />

                  </td>
                  <td className={'ta-b'} colSpan={3} style={{ width: '33%' }}>
                    <b>Total Weight(KGS)</b><br />
                    <b>GROSS WT:</b>{Number(Number(totalGrossWeight) * 0.454).toFixed(3)} (KGS)
                    <br /><br />
                  </td>
                  <td className={'ta-b'} colSpan={3} style={{ width: '33%' }}>
                    <b> Freight:</b><br />
                    PREPAID <br />
                    <p style={{ backgroundColor: '#FFFF99', color: '#FF3333', fontWeight: 'bold' }}>
                      BL Remarks: Please arrange Freight Invoice in USD currency and all local charges in INR.
                    </p>
                  </td>



                </tr>
                {/* </table>
              <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls"> */}

                <tr className={'ta-b'} >
                  <td className={'ta-b'} style={{ width: '100%' }} colSpan={9}>

                    <b>INVOICING PARTY NAME AND ADDRESS:<br />

                      {exporterData?.name}<br />
                      {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
                      <br />
                      {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : ''}
                      <br />
                      {exporterData?.state + ', ' + exporterData?.country}<br />
                      GSTIN : 37AAJCS2286C1ZA<br />
                      <p style={{ backgroundColor: '#FFFF33', color: '#FF3333' }}>
                        <b> BL Remarks: Please arrange Freight Invoice in USD currency and all local charges in INR.</b>
                      </p>
                    </b>
                  </td>
                </tr>

              </table>


            </body>

          </html></>
        : <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial', height: '100' }}>SHIPPING INSTRUCTIONS</span>}
          // extra={<> <Button href="/#/document-register" style={{color:'black'}}>Back</Button></>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}><span style={{ paddingTop: '10px', textAlign: 'center' }} >No data found</span></Card>}
    </>
    // </Card>
  );
}

export default ShippingInstructions;
