import React, { useState } from 'react';

import './code-list-form.css';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import {CodeListDto, CodeListStatus} from '@gtpl/shared-models/planning'
const { Option } = Select;
import { Link, useHistory } from "react-router-dom";
import { CodeListService } from '@gtpl/shared-services/planning';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';


/* eslint-disable-next-line */
export interface CodeListFormProps {
  codeListData: CodeListDto;
  updateCodeList: (codeList: CodeListDto) => void;
  isUpdate:boolean;
  closeForm: () => void;


}

export function CodeListForm(
  props: CodeListFormProps,
  
) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false) 
  let history = useHistory();
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  let unitId = "";
  if (!props.isUpdate) {
    unitId = localStorage.getItem("unit_id");
  }

  const onSearch = () => {

  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: CodeListDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateCodeList(values);
    } else {
      setDisable(false)
      console.log('hello');

      createCodeList(values);
    }

  };
  const codeListService = new CodeListService()


  const createCodeList = (codeList: CodeListDto) => {
    setDisable(true)
    codeListService.createCodeList(codeList).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Code List Created Successfully');
        history.push("/code-list-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Code List</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/code-list-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}  name="control-hooks" initialValues={props.codeListData} onFinish={saveData}
        layout="vertical"
      >
         <Form.Item name="codeListId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="unitId" initialValue={unitId}>
          <Input hidden />
        </Form.Item>
       
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="traceabilityCode"
              label="Traceability Code"
              rules={[
                {
                  required: true,
                  message: 'Traceability Code Required',
                },
                {
                  pattern: /^[^-\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                      message: `Invalid Traceability Code`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="CodeListStatus"
              label="CodeList Status"
              rules={[
                {
                  required: true,
                  message: 'Code List Status Required',

                },
                
              ]}>
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Code List Status"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
              Object.keys(CodeListStatus).map(function (key, index) {
                return <Option key={CodeListStatus[key]} value={CodeListStatus[key]}>{CodeListStatus[key]}</Option>
              })
            }
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary"disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
          </Button>
            }

          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default CodeListForm;
