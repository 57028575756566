import React from 'react';

import './resource-operation-efficiency-grid.css';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import { Link } from 'react-router-dom';


/* eslint-disable-next-line */
export interface ResourceOperationEfficiencyGridProps {}

export function ResourceOperationEfficiencyGrid(
  props: ResourceOperationEfficiencyGridProps
) {
  return (
    <>
    <Card title={<span style={{color:'white'}}>Resource Operation Efficiency</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/resource-operation-efficiency-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} ></Card>
    <div>
      <h1>
       
        resource-operation-efficiency-grid!
      </h1>
    </div>
    </>
  );
}

export default ResourceOperationEfficiencyGrid;
