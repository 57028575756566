import { Card, Col, Row } from "antd";
import './warehouse-dashboard.css';
import React, { } from "react";
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface WhWayRackProps {
    cardData: CardDetail
}

export const WhWayRackCardComponent = (props: WhWayRackProps) => {
    const { cardData } = props;

    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <tr style={{ textAlign:'center',marginRight:'5px', lineHeight: ' 1.8em' }}>
                <td >{bodyProps.cardBodyTitle}</td>
            </tr>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    return (
        <>
            <Card className="whCard" title={<span style={{ marginBottom: '10px' }} className="title">{cardData.carHeader.cardHeaderTitle}</span>} bordered={false} style={{ color: 'white', height: '80%',width:'520%' }}>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '67%', margin: '1.5em', fontSize: '2em', border: '0' }}>
                    
                        {constructLocationDetail(cardData.cardBody)}
                </table>
            </Card>
        </>
    );
}
export default WhWayRackCardComponent;