import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip } from 'antd';
import { BarcodeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import './pages-asset-management-asset-management-components-mrn-grid.css';
import { ColumnProps } from 'antd/lib/table';
import { Scanner } from '../../../asset-classification-form/src/lib/Scanner';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLocationMappingService } from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import { AssetLocationMappingDto } from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import moment from 'moment';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import Highlighter from 'react-highlight-words';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { AssetsDto } from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { Redirect } from "react-router-dom";
import { AssetById } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-id.dto';
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { TabPane } = Tabs;
const { Option } = Select;

/* eslint-disable-next-line */
export interface AssetCheckInProps { }

export function AssetCheckIn(
  props: AssetCheckInProps
) {

  const [page, setPage] = useState<number>(1);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<AssetDto[]>([]);
  const [checkInData, setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [selectedData, setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService();
  const assignmentService = new AssetAssignmentService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState<any>([]);
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [itemdata, setItemData] = useState<any>(null);
  const [code, setCode] = useState(0)
  const [activeTab, setActiveTab] = useState('1');
  const [showpopup, setShowpopup] = useState<boolean>(false);
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))


  let diff = 0;
  let date;
  let todate;

  useEffect(() => {
    employeeDetails();
    //assetsDetails();
    // getCheckIns();

    getAllPlants();
    getItemCategory();
    getDepartments();
    getByAssetCode();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getAssetCode();
    getLocations();
  }, [])

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDetailedView = (assetId) => {
    const req = new AssetByCode(assetId)
    assetsService.getByAssetCode(req).then(res => {
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        setModal(true)
        // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)

    })
  }

  // const getAssetCode = () => {
  //   assetsService.getallAssets().then((res) => {
  //     if(res.status) {
  //       setAssetCodeData(res.data);
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }
  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getAssetCodesDropDown(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  // const assetsDetails = () => {
  //   assetsService.getAllAssetsEligileForCheckIn().then((res) => {
  //     if(res.status) {
  //       setAssetsData(res.data);
  //       AlertMessages.getSuccessMessage('Data retrieved successfully')
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }

  // const getCheckIns = () => {
  //   assignmentService.getAllCheckIn().then(res => {
  //     if(res.status) {
  //       setCheckInData(res.data);
  //       AlertMessages.getSuccessMessage(`CheckIn's retrieved successfully`)
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }


  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  // const showDrawer = () => {
  //   setOpenDrawer(true);
  // };

  const closeDrawer = () => {
    setDrawerVisible(false);
    form1.resetFields();
  }

  const Dclose = () => {
    setShowpopup(false);
    form.resetFields();
  }
  const onCheckIn = (rowData) => {
    setSelectedData(rowData)
    setDrawerVisible(true)
  }
  const onCheckout = (rowData) => {
    setData(rowData)
    setShowpopup(true)

  }


  const checkInHandler = (values) => {
    console.log(values, '--------------values')
    const request = new AssetAssignmentDto(null, null, null, values.employeeId, null, null, true, null, null, values.assetsCode)
    assignmentService.createCheckIn(request).then(res => {
      if (res.status) {
        form1.resetFields();
        AlertMessages.getSuccessMessage('Created Successfully');
        getByAssetCode()
        // getCheckIns();
        closeDrawer();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const checkOutHandler = (rowData) => {
    rowData.checkOut = new Date();
    rowData.checkIn = null;
    assignmentService.updateCheckOut(rowData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('checked out successfully');
        getByAssetCode();
        //  getCheckIns();
        Dclose();

      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }

  // const getByAssetCode =() => {
  //  // let count = 0;
  //   const assetId = form.getFieldValue('assetCode')
  //   const request = new AssetByCode(assetId)
  //   assetsService.assetCodeSearch(request).then(res => {
  //     if(res.status) {
  //       setAssetsData(res.data)
  //     } else {
  //      // AlertMessages.getErrorMessage(res.internalMessage)
  //      setAssetsData([])
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message)
  //   })
  //   assignmentService.getAssetByCode(request).then(res => {
  //     if(res.status) {
  //       setCheckInData(res.data)
  //      // count = 1;
  //     } else {
  //       // AlertMessages.getErrorMessage(res.internalMessage)
  //       setCheckInData([])
  //     }
  //   })

  // }


  const getByAssetCode = () => {
    const request = new AssetByAssetCode();
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
      request.assetsCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
      request.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
        request.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
      request.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
      request.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
      request.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('department') !== undefined) {
      req.department = form.getFieldValue('department')
      request.department = form.getFieldValue('department')
    }
    if (form.getFieldValue('employeeName') !== undefined) {
      request.employeeId = form.getFieldValue('employeeName')
    }
    assetsService.getAllAssetsEligileForCheckIn(req).then(res => {
      if (res.status) {
        console.log(res.data)
        setAssetsData(res.data)
        getAssignedDetails(request)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('InStock Details Retrieved Successfully')
        } else {
          AlertMessages.getErrorMessage("No Data Found")
        }
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
        setAssetsData([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })


  }

  const getAssignedDetails = (request) => {
    assignmentService.getAssetByCode(request).then(res => {
      if (res.status) {
        console.log(res.data)
        setCheckInData(res.data)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Assigned Details Retrieved Successfully')
        } else {
          AlertMessages.getErrorMessage("No Data Found")
        }
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
        setCheckInData([])
      }
    })
  }

  console.log(checkInData)

  const handleBack = () => {
    setModal(false)
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
    },
    {
      title: 'Location',
      dataIndex: 'currentLocation',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('currentLocation'),
    },
    {
      title: 'Asset Mode',
      dataIndex: 'assetMode',
      width: 130,
      align: 'left',
      ...getColumnSearchProps('assetMode'),
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      width: 130,
      align: 'left',
      ...getColumnSearchProps('assetType'),
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Asset Category',
      dataIndex: 'itemSubCategoryName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCategory')
    },
    {
      title: 'Asset Name',
      dataIndex: 'itemName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      width: '130',
      align: 'left',
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return (<>
          <Button type='link' onClick={() => getDetailedView(record.assetsCode)}>
            {record.assetsCode}</Button>
        </>)
      },
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice",
      align: "left",
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      // width:120,
      render: (text, record) => {
        return (
          <>
            {record.invoice ? record.invoice : '-'}
          </>
        )
      },
      ...getColumnSearchProps('invoice')
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align: "left",
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetSerialNo'),
      // width:120,
      render: (text, record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      }
    },
    {
      title: 'Insurance',
      dataIndex: 'insuranceDate',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.insuranceDate?.localeCompare(b.insuranceDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('insuranceDate'),
      render: (text, record) => {
        return (
          <>
            {record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },
    },

    {
      title: 'Warranty',
      dataIndex: 'warrentyExpiredDate',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.warrentyExpiredDate?.localeCompare(b.warrentyExpiredDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('warrentyExpiredDate'),
      render: (text, record) => {
        return (
          <>
            {record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      },


    },
    {
      title: 'Asset Condition',
      dataIndex: 'assetcondition',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetcondition?.localeCompare(b.assetcondition),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetcondition')
    },
    {
      title: 'Asset Age',
      dataIndex: 'assetAge',
      width: 100,
      align: 'right',
      sorter: (a, b) => a.assetAge - b.assetAge,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {record.dateOfPurchased ? diff : null}
          </>
        )
      },
      ...getColumnSearchProps('assetAge')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 130,
      align: 'left',
      render: (text, rowData) => (

        <span>

          <Tooltip placement="top" title="Edit">

            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={() => onCheckIn(rowData)}
            >
              Check In
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ]

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })
    getByAssetCode()

  }

  const resetHandler = () => {
    form.resetFields();
    getByAssetCode();
    // getCheckIns();
  }

  const finishHandler = (value) => {
    console.log(value, '------------value')
    getByAssetCode();

  }

  const columns1: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      // width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      // width: 130,
      align: 'left',
      ...getColumnSearchProps('location'),
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Asset Mode',
      dataIndex: 'assetMode',
      // width: 130,
      align: 'left',
      ...getColumnSearchProps('assetMode'),
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetType'),
    },
    {
      title: 'Asset Category',
      dataIndex: 'assetCategory',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.assetCategory.localeCompare(b.assetCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCategory')
    },
    {
      title: 'Asset Name',
      dataIndex: 'itemName',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetsCode',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.assetsCode.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode'),
      render: (text, record) => {
        return (<>
          <Button type='link' onClick={() => getDetailedView(record.assetsCode)}>
            {record.assetsCode}</Button>
        </>)
      }

    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      align: "left",
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoice'),
      // width:120,
      render: (text, record) => {
        return (
          <>
            {record.invoiceNo ? record.invoiceNo : '-'}
          </>
        )
      }
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align: "left",
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetSerialNo'),
      // width:120,
      render: (text, record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },

    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    {
      title: 'Emp ph Num',
      dataIndex: 'employeePhNum',
      // width: 130,
      align: 'right',
      sorter: (a, b) => a.employeePhNum?.localeCompare(b.employeePhNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeePhNum'),
      render: (text, record) => {
        return (
          <>
            {record.employeePhNum ? record.employeePhNum : '-'}
          </>
        )
      }
    },
    {
      title: 'Emp Department',
      dataIndex: 'department',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.employeePhNum?.localeCompare(b.employeePhNum),
      sortDirections: ['descend', 'ascend'],

      ...getColumnSearchProps('department')
    },
    {
      title: 'Check-In',
      dataIndex: 'checkIn',
      align: 'right',
      width: 130,
      sorter: (a, b) => a.checkIn?.localeCompare(b.checkIn),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('checkIn'),
      render: (text, record) => {
        return (
          <span>
            {record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD') : '-'}
          </span>
        )
      }
    },

    {
      title: 'Aging',
      dataIndex: 'aging',
      width: 80,
      sorter: (a, b) => a.aging - b.aging,
      sortDirections: ['descend', 'ascend'],
      align: 'right',
      //   render:(text,record) => {
      //     todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //     date = moment(moment(record.checkIn).format('YYYY-MM-DD')); 
      //     diff = (todate - date) / 864e5;
      //     return(
      //       <>
      //       {record.checkIn ? diff : null}
      //       </>
      //     )
      //  }
      // ...getColumnSearchProps('department')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 130,
      align: 'left',
      render: (text, rowData) => (
        <span>

          <Tooltip placement="top" title="Edit">
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={() => onCheckout(rowData)}
            >
              Check Out
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ]
  return (

    <Card title={<span style={{ color: 'white' }}> Asset Assignment </span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <Form layout="vertical" form={form} onFinish={finishHandler}>
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                defaultValue={'All'}
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}>
            <Form.Item name="assetCode" label="Asset Code">
              <Select
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }} />}
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetCodeData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.concateditemName}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label='Plant' name='plantId'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                showSearch
                allowClear>
                {/* <Option key={'all'} value={''}>All</Option> */}
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Department' name='department'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[24, 24]}>
          <Col span={5}>
            <Form.Item label='Employee Name - Code' name='employeeName'>
              <Select
                optionFilterProp="children"
                //  filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {employeeData.map((employee) => {
                  return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ marginTop: '2%', marginLeft: '1%' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Col>
          <Col style={{ marginTop: '2%', marginLeft: '1%' }}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
      {/* <Tabs  type={'card'} tabPosition={'top'} onChange={(key) => setActiveTab(key)} activeKey={activeTab}> */}
      <Tabs>
        <TabPane key='1' tab={'IN-STOCK: ' + assetsData.length}>
          <Table columns={columns} dataSource={assetsData} pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
            scroll={{ x: 1400 }} />
        </TabPane>
        <TabPane key='2' tab={'Assigned Details: ' + checkInData.length}>
          <Table columns={columns1} dataSource={checkInData} pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
            scroll={{ x: 1700 }} />
        </TabPane>
      </Tabs>
      <Drawer
        bodyStyle={{ paddingBottom: 80 }} title='Check-In ' width={window.innerWidth > 768 ? '65%' : '60%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}

      >
        <Descriptions>
          <DescriptionsItem label='Asset Name'>{selectedData.itemName}</DescriptionsItem>
          <DescriptionsItem label='Asset Code'>{selectedData.assetsCode}</DescriptionsItem>
        </Descriptions>

        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>

          <Form form={form1} onFinish={checkInHandler}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item name='assetsCode' label='Asset Code' style={{ display: 'none' }} initialValue={selectedData.assetsCode}
                >
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item name='assetsName' label='Asset Name' style={{ display: 'none' }} initialValue={selectedData.assetsName}
                >
                  <Input hidden />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
          <Form.Item name='locationId' label='Location'
            rules={[{
              required:true,
              message: 'Location is required',
            }]}
          >
            <Input/>
          </Form.Item>
          </Col> */}
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 8, offset: 1 }} style={{ marginTop: '1%',marginLeft: '1%'}}> */}
              <Col span={11}>
                <Form.Item name='employeeId' label='Employee Name'
                  rules={[{
                    required: true,
                    message: 'Employee Name is required'
                  }]}
                >
                  <Select

                    showSearch
                    optionFilterProp="children"

                    allowClear
                  >
                    {employeeData.map((employee) => {
                      return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 9, offset: 1 }} style={{ marginTop: '1%',marginLeft:'2%' }}> */}
              <Col span={8}>
                <Form.Item name='checkinReason' label='Purpose'
                  rules={[{
                    required: true,
                    message: 'Purpose is required',
                    max: 500,
                  }]}
                >
                  <Select>
                    <Option key={1} value={'assigningToNewEmployee'}>Assigning To New Employee</Option>
                    <Option key={2} value={'assigningToNewProject'}>Assigning To New Project</Option>
                    <Option key={3} value={'businesspurpose'}>Business purpose</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
          <Col span={20} style={{textAlign:'right'}}>
          <Form.Item>
            <Button type='primary' htmlType='submit'>Submit</Button>
          </Form.Item>
          {[] && <Form.Item>
            <Button type='primary' onClick={closeDrawer}>Cancel</Button>
          </Form.Item>}
          </Col>
          </Row> */}
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
                {[] &&
                  <Button htmlType="button" style={{ margin: '0 14px' }} onClick={closeDrawer}>
                    Cancel
                  </Button>
                }
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
      <Drawer
        bodyStyle={{ paddingBottom: 50 }} width={window.innerWidth > 768 ? '65%' : '60%'}
        onClose={Dclose} visible={showpopup} closable={true}
      >
        <Descriptions title='Check-Out'>
          <DescriptionsItem label='Asset Code'>{data.assetsCode}</DescriptionsItem>
        </Descriptions>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <Form form={form} onFinish={checkOutHandler}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item name='assetId' label='Asset Code' style={{ display: 'none' }} initialValue={data.assetId}
                >
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item name='assetsCode' label='Asset Code' style={{ display: 'none' }} initialValue={data.assetsCode}
                >
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item name='assetsName' label='Asset Name' style={{ display: 'none' }}
                >
                  <Input hidden />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 9, offset: 1 }} style={{ margin: '1%' }}> */}

              {/* <Form.Item name='assetsCode' label='Asset Code'
            rules={[{
              required:true,
              message: 'Asset Id is required',
            }]}  
            
          >
            <Input readOnly />
          </Form.Item>
          </Col> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} style={{ marginTop: '1%', marginLeft: '1%' }}>

                <Form.Item name='checkOutReason' label='Reason'
                  rules={[{
                    required: true,
                    message: 'Reason is required',
                    max: 500,
                  }]}
                >
                  <Select>
                    <Option key={1} value={'maintenance'}> Maintenance</Option>
                    <Option key={2} value={'generalservicing'}>General Servicing</Option>
                    <Option key={3} value={'assigningpurpose'}>Assigning Purpose</Option>
                    <Option key={3} value={'resign'}>Resigning</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={{ span: 24 }}
           sm={{ span: 24 }}
            md={{ span: 5, offset: 1 }}
             lg={{ span: 5, offset: 1 }} 
             xl={{ span: 7, offset: 1 }} 
              style={{ marginTop: '1%',marginLeft:'4%' }}>
            <Form.Item name='TentativeDate' label='Tentative Date'
                rules={[{
                  required:false,
                }]} >
             <DatePicker  style={{ width: '100%' }}
                      format="YYYY-MM-DD"/>
         </Form.Item>
          </Col> */}
            </Row>
            <Row>

              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
                {[] &&
                  <Button htmlType="button" style={{ margin: '0 14px' }} onClick={Dclose}>
                    Cancel
                  </Button>
                }
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
      <Modal
        className='model'
        key={'modal' + Date.now()}
        width={'95%'}
        onCancel={handleBack}
        style={{ top: 30, alignContent: 'right' }}
        visible={modal}
        footer={[
          //  <Button key="back" onClick={() =>  handleBack()}>
          //    Back
          //  </Button>,
        ]}
        title={<React.Fragment>
        </React.Fragment>}
      >
        <Card title={<span style={{ color: 'white' }}> Detailed View</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

          <Descriptions>
            {/* <Descriptions.Item label="Asset Category">{previewdata?.assetCategory} </Descriptions.Item> */}
            <Descriptions.Item label="Plant"> {itemdata?.plant} </Descriptions.Item>
            <Descriptions.Item label="Current Location"> {itemdata?.location_name} </Descriptions.Item>
            <Descriptions.Item label="Asset Mode"> {previewdata?.assetMode} </Descriptions.Item>
            <Descriptions.Item label="Asset Type"> {previewdata?.assetType} </Descriptions.Item>
            <Descriptions.Item label="Asset Category"> {itemdata?.assetCategory}</Descriptions.Item>
            <Descriptions.Item label="Asset Name"> {itemdata?.assetname}</Descriptions.Item>
            <Descriptions.Item label="Asset Serial Number"> {previewdata?.assetSerialNo}</Descriptions.Item>
            <Descriptions.Item label="Asset Code"> {previewdata?.assetsCode} </Descriptions.Item>
            <Descriptions.Item label="Invoice">{previewdata?.invoice}  </Descriptions.Item>
            <Descriptions.Item label="Model"> {previewdata?.model} </Descriptions.Item>
            <Descriptions.Item label="No.Of Units"> {previewdata?.noOfUnits} </Descriptions.Item>
            <Descriptions.Item label="Date Of Purchased"> {moment(previewdata?.dateOfPurchased).format('YYYY-MM-DD HH:mm:ss')} </Descriptions.Item>
            <Descriptions.Item label="Expiry Date"> {moment(previewdata?.expiredDate).format('YYYY-MM-DD HH:mm:ss')} </Descriptions.Item>
            <Descriptions.Item label="Asset Cost"> {previewdata?.assetCost} </Descriptions.Item>
            <Descriptions.Item label="Asset Supplier"> {previewdata?.assetSupplier} </Descriptions.Item>
            <Descriptions.Item label="AMC Availability"> {previewdata?.amc} </Descriptions.Item>
            <Descriptions.Item label="Warranty  Availability"> {previewdata?.warrenty} </Descriptions.Item>
            <Descriptions.Item label="Warranty Valid Upto">{previewdata?.warrentyExpiredDate ? moment(previewdata?.warrentyExpiredDate).format('YYYY-MM-DD') : '-'}  </Descriptions.Item>
            <Descriptions.Item label="Insurance"> {previewdata?.insurance} </Descriptions.Item>
            <Descriptions.Item label="Insurance Valid Upto">{previewdata?.insuranceDate ? moment(previewdata?.insuranceDate).format('YYYY-MM-DD') : '-'}  </Descriptions.Item>
            {/* <Descriptions.Item label="Manufacturer">{previewdata?.manufacturer} </Descriptions.Item> */}
            {/* <Descriptions.Item label="Year"> {previewdata?.year} </Descriptions.Item> */}
            {/* <Descriptions.Item label="Units Of Measurment"> {previewdata?.unitsOfMeasurements} </Descriptions.Item> */}
          </Descriptions>
        </Card>
      </Modal>
      <Modal
        className='qr-containerrrrr'
        key={'modalll' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          qrModal == 'assetCode' ? <Scanner handleScan={handleAssetIdScan} /> : null
          //<Scanner handleScan={handleAssetIdScan}/>
        }

      </Modal>
    </Card>
  );
}

export default AssetCheckIn;
