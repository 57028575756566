import { defaultRenderText } from '@ant-design/pro-table';
import { TemperatureLogService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Input } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export interface TemperatureLogGridProps { }

export function TemperatureLogGrid(props:TemperatureLogGridProps) {
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(''); 
    const [searchedColumn, setSearchedColumn] = useState('');
    const service = new TemperatureLogService();
    const [allTempData, setAllTempData] = useState<any[]>([]);

    useEffect(() => {
        getAllTempDetails()
    }, []);

    const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {    setTimeout(() => searchInput.current.select());   }
        },
        render: text =>
          text ?(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) :text
          )
          : null
         
      });
    
      /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };

    const getAllTempDetails = () => {
        service.getAllTempDetails().then((res) => {
            if (res.status) {
                setAllTempData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Department Name',
            dataIndex: 'deptName',
            ...getColumnSearchProps('deptName')
        },
        {
            title: 'Unit',
            dataIndex: 'plant'
        },
        {
            title: 'Cold Storage',
            dataIndex: 'module',
            ...getColumnSearchProps('module')
        },
        {
            title: 'Temperature',
            dataIndex: 'temperature'
        },
        {
            title: 'Date',
            dataIndex: 'tempDate',
            render: (value, record) => {
                return <span>
                  {record.tempDate ? moment(record.tempDate).format('YYYY-MM-DD') : '-'}
                </span>
              }
        }
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div>
            <Card title={<span style={{ color: 'white' }}>Temperature Log</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/temperature-log-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
                <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={allTempData}
                    pagination={{
                        onChange(current) {
                            setPage(current);
                        }
                    }}
                    onChange={onChange}
                    scroll={{ x: 1500, y: 500 }}
                    bordered
                />
            </Card>
        </div>
    )
}