import React, { useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import './defect-form.css';
import { DefectDto } from '@gtpl/shared-models/masters';
import { DefectService } from '@gtpl/shared-services/masters';
const { TextArea } = Input;

/* eslint-disable-next-line */
export interface DefectFormProps {
  defectData: DefectDto;
  isUpdate: boolean;
  updateDefect: (defects: DefectDto) => void;
  closeForm: () => void;
}

export function DefectForm(
  props: DefectFormProps
) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  let history = useHistory();
  const service = new DefectService;

  const create = (Data: DefectDto) => {
    Data.createdUser = localStorage.getItem('username')
    setDisable(true)

    service.createDefect(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');

        history.push("/defect-grid")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: DefectDto) => {
    setDisable(false)
    // console.log(values);
    if (props.isUpdate) {
      props.updateDefect(values);
    } else {
      setDisable(false)
      create(values);
      // console.log('test')
    }

  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <Card title={<span style={{ color: 'white' }}>Defects</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/defect-grid' ><span style={{ color: 'white' }} >  {(props.isUpdate === false) &&
          <Button className='panel_button' >View </Button>
        }  </span></Link>} >

        <Form form={form} initialValues={props.defectData} name="control-hooks" onFinish={saveData}
          layout="vertical"
        >

          <Form.Item name="defectId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={props.defectData}>
            <Input hidden />
          </Form.Item>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
              <Form.Item
                name="defectName"
                label="Defect Name"
                rules={[
                  {
                    required: true,
                    message: 'defect Name required'

                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input />

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>

              <Button type="primary" disabled={disable} htmlType="submit" >
                Submit
              </Button>
              {(props.isUpdate !== true) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

export default DefectForm;
