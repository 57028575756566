import React, { useEffect, useState } from 'react';
import {Button, Card, Col, Form, Input, Row,Select} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {ExpensesCategoryDto, ExpensesSubCategoryDto} from '@gtpl/shared-models/hrms';
import { ExpensesCategoryService, ExpensesSubCategoryService } from '@gtpl/shared-services/hrms';
const { Option } = Select;

import './expenses-sub-category.css';

/* eslint-disable-next-line */
export interface ExpensesSubCategoryProps {
  expensesSubCategoryData: ExpensesSubCategoryDto;
  updateExpensesSubCategory: (expensesSubCategory: ExpensesSubCategoryDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ExpensesSubCategory(
  props: ExpensesSubCategoryProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  const expensesSubCategoryService = new ExpensesSubCategoryService()
  const [selectedExpensesCategory, setSelectedExpensesCategory] = useState<ExpensesCategoryDto>();
  const [expensesCategoryData, setExpensesCategoryData] = useState<ExpensesCategoryDto[]>([]);
  const [display, setDisplay] = useState<string>('none');

  const expensesCategorySerice = new ExpensesCategoryService();
  
  useEffect(() => {
    getAllExpensesCategories();
  },[])

  const getAllExpensesCategories = () =>{
    expensesCategorySerice.getAllExpensesCategories().then(res => {
      if (res.status) {
        setExpensesCategoryData(res.data);
      } else {
        if (res.intlCode) {
            setExpensesCategoryData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setExpensesCategoryData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset=()=>{
    console.log('');
    form.resetFields();
     }

     const createExpensesSubCategory = (Data: ExpensesSubCategoryDto) => {
      setDisable(true)
      expensesSubCategoryService.createExpensesSubCategory(Data).then(res => {
        setDisable(false)
        if(res.status) {
          AlertMessages.getSuccessMessage('Expenses Sub Category created successfully');
          history.push('/expenses-sub-category-view');
          onReset();
        } else {
          if(res.intlCode){
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
   
    const saveData = (values: ExpensesSubCategoryDto) => {
      setDisable(false)
      if(props.isUpdate){
        console.log(values);
        props.updateExpensesSubCategory(values);
      }else {
        setDisable(false)
        console.log(values);
        createExpensesSubCategory(values);
      }
    };

    const handleExpensesCategory=(value)=>{
      setSelectedExpensesCategory(value);
      if(value == ''){
        setDisplay('block');
      } else {
        setDisplay('none');
      }
       
    }

    const onFocus=() =>{
      console.log('focus');
    }
    
    const onSearch=(val)=> {
      console.log('search:', val);
    }
    const onBlur=() =>{
      console.log('blur');
    }
  return (
    <Card title={<span style={{color:'white'}}>Expenses Sub Category</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/expenses-sub-category-view' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.expensesSubCategoryData} >
        <Form.Item 
        name ="expensesSubCategoryId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
      <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
          <Form.Item
            name="expensesCategoryId"
            label="Expenses Category"
            rules={[
              {
                required: true,
                message: 'Expenses Category required'
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }

            ]}>
            <Select
              showSearch
              //style={{ width: 210 }}
              placeholder="Select Expenses Category"
              optionFilterProp="children"
              onSelect={handleExpensesCategory}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Expenses Category</Option>
              {expensesCategoryData.map((expensesCategoryData) => {
                return <Option key={expensesCategoryData.expensesCategoryId} value={expensesCategoryData.expensesCategoryId}>{expensesCategoryData.expensesCategory}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="expensesSubCategory"
                label="Expenses Sub Category"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Expenses Sub Category name."
                  },
                  {
                    // pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z]*$/,
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
        
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
   </Card>
  );
}

export default ExpensesSubCategory;
