import React, { useState, useEffect } from 'react';
import { BackTop, Card, Col, Form, Select, Row } from 'antd';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ReportTypeENum } from '@gtpl/shared-models/analytics-data';
import moment from 'moment';
import { PoGrnData, PoGrnDataResDto, PoQtyGrnDtoReqDto } from '@gtpl/shared-models/common-models';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';

/* eslint-disable-next-line */
export interface PoGrnProps { }

export function PoGrnGraph(props: PoGrnProps) {

    const [form] = Form.useForm();
    const { Option } = Select;
    const [poGrnQtyData, setPoGrnQtyData] = useState<PoGrnDataResDto>();
    const service = new PurchaseOrderService;
    const [graphType, setGraphType] = useState(ReportTypeENum.DAYWISE)
    const [selectedMonth, setSelectedMonth] = useState<any>(moment().month() + 1);
    const [selectedYear, setSelectedYear] = useState<any>(moment().year());

    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });


    useEffect(() => {
        const currentMonth = moment().month()
        getPoQtyGrnQty(currentMonth);
        years(0);
    }, [selectedMonth, graphType, selectedYear])

    const getPoQtyGrnQty = (currentMonth?: number) => {
        const req = new PoQtyGrnDtoReqDto
        req.month = Number(selectedMonth)
        
        req.year = Number(selectedYear)
        req.unitId = localStorage.getItem("unit_id")
        req.reportType = graphType;
        service.getPoQtyGrnQty(req).then((res) => {
            if (res.status) {
                setPoGrnQtyData(res.data)
                console.log(res.data, 'data')
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    };

    const poGraphData = poGrnQtyData?.PoGrnData.poQty.map(i => { return { y: Number(i), color: '#11e0ee', name: '' } })
    const grnGraphData = poGrnQtyData?.PoGrnData.grnQty.map(i => { return { y: Number(i), color: '#2884ecb7', name: '' } })
    console.log(poGraphData, '----')
    console.log(grnGraphData, 'grndata.......')



    const options = {
        title: { text: "Po Qty & Grn Qty" },
        chart: {
            type: 'column',
            style: {
                fontFamily: "Source Sans Pro, sans-serif",
                color: 'var(--text-color,#eee)',
            },
            backgroundColor: '#272953'
        },
        series: [

            {
                data: poGraphData,
                name: " Po Qty",
                borderWidth: 0,
                point: {
                    events: {}
                },
                dataLabels: {
                    enabled: false,
                    overflow: "allow",
                    crop: false
                },
                legendIndex: 1
            },
            {
                data: grnGraphData,
                color: "#2884ecb7",
                name: "Grn Qty ",
                borderWidth: 0,
                point: {
                    events: {}
                },
                dataLabels: {
                    enabled: false,
                    overflow: "allow",
                    crop: false,
                },
                legendIndex: 2
            },


        ],
        xAxis: {
            title: {
                text: graphType,
                style: {
                    color: 'var(--text-color,#eee)'
                }
            },
            labels: {
                style: {
                    color: 'var(--text-color,#eee)'
                }
            },
            type: "category",
            categories: poGrnQtyData ? poGrnQtyData.names : [],
            reversed: false
        },
        yAxis: [
            {
                id: "default: 0",
                title: {
                    text: "Po Qty Vs Grn Qty",
                    style: {
                        color: 'var(--text-color,#eee)'
                    },
                },
                labels: {
                    style: {
                        color: 'var(--text-color,#eee)'
                    }
                },
            },

        ],

        plotOptions: {
            series: {
                cursor: "pointer",
                stickyTracking: false,

            },
            column: {
                groupPadding: 0.05,
                pointPadding: 0.05,
            }
        },
    };

    const months = [
        {
            month: 'January',
            monthId: 1
        }, {
            month: 'February',
            monthId: 2
        }, {
            month: 'March',
            monthId: 3
        }, {
            month: 'April',
            monthId: 4
        }
        , {
            month: 'May',
            monthId: 5
        }
        , {
            month: 'June',
            monthId: 6
        }
        , {
            month: 'July',
            monthId: 7
        }
        , {
            month: 'August',
            monthId: 8
        }
        , {
            month: 'September',
            monthId: 9
        }
        , {
            month: 'October',
            monthId: 10
        }
        , {
            month: 'November',
            monthId: 11
        }
        , {
            month: 'December',
            monthId: 12
        }
    ]

    const years = (back) => {
        const year = new Date().getFullYear();
        return Array.from({ length: back }, (v, i) => year - back + i + 1);
    }
    const lastTenYears = years(10)

    return (
        <>
            <Form layout="vertical" form={form} name="control-hooks">
                <Row gutter={24} justify='center'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 5 }}>
                        <Form.Item name="graphType" label="Graph" initialValue={ReportTypeENum.DAYWISE}
                            rules={[
                                {
                                    required: true, message: 'Missing Graph Type',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Graph Type"
                                allowClear
                                onChange={(value) => { setGraphType(value) }}
                                defaultValue={ReportTypeENum.DAYWISE}
                            >
                                <Option key={0} value={null}>Select Graph Type</Option>
                                {Object.values(ReportTypeENum).map((value) => {
                                    return <Option key={value} value={value}>{value}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 5 }} style={{ display: (graphType == ReportTypeENum.DAYWISE || graphType == ReportTypeENum.WEEKWISE) ? 'unset' : 'none' }}>
                        <Form.Item name="monthWise" label="Month"
                            rules={[
                                {
                                    required: false,
                                },
                            ]} >
                            <Select
                                placeholder="Select Month"
                                allowClear
                                onSelect={(value) => { setSelectedMonth(value) }}
                                defaultValue={(moment().format('MMMM'))}
                            >
                                {months.map((i) => {
                                    return <Option key={i.monthId} value={i.monthId}>{i.month}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: graphType == ReportTypeENum.QUARTERWISE ? 'unset' : 'none' }}>
                        <Form.Item name="quarterly" label="Years"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}>
                            <Select
                                placeholder="Select Year"
                                allowClear
                                onSelect={(value) => { setSelectedYear(value) }}
                                defaultValue={moment().format('YYYY')}
                            >
                                {lastTenYears.map((value) => {
                                    return <Option key={value} value={value}>{value}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br /><br />
            <div className="poGrnContainer">
                <HighchartsReact key={Date.now()} highcharts={Highcharts} options={options} />
            </div>
        </>
    )
}
export default PoGrnGraph;
