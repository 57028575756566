import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CodeListMainDto } from '@gtpl/shared-models/planning';
import { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, TimePicker, Drawer, AutoComplete, Collapse, Descriptions, Switch, Radio, Space, Table, Typography, Modal, Divider, FormInstance } from 'antd';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { CodeListMainIdRequest, CodeListMainItemsDto } from '@gtpl/shared-models/planning'
import { CodeListMainService, CodeListService } from '@gtpl/shared-services/planning';

const { Text } = Typography;

export interface CodeListDetailProps {
    selectedData: any;
    isUpdate: boolean;
    itemsForm: FormInstance<any>
    soItemDropdown: any
}
export function CodeListDetailView(
    props: CodeListDetailProps
) {
    const { itemsForm, soItemDropdown } = props
    const [tableData, setTableData] = useState<any[]>([]);
    const [tracebilityCode, setTracebilityCode] = useState<any>()
    const codeListService = new CodeListService();
    const codeListMainService = new CodeListMainService()

    let location = useLocation();
    const codeListMainId = location.state;

    useEffect(() => {
        getTracebilityCodes();
        
        if (props.isUpdate) {
            getCodeListItemsData();
        }
    }, []);
    
    const getCodeListItemsData = () => {
        const req = new CodeListMainIdRequest()
        req.saleOrderId = props.selectedData.saleOrderId
        codeListMainService.getCodeListsForId(req).then((res) => {
            if (res.status) {
                setTableData(res.data)
            } else {
                setTableData([])

            }
        }).catch((err) => {
            setTableData([])
        })
    }
    const getTracebilityCodes = () => {
        codeListService.getAllActiveCodeList().then((res) => {
            if (res.status) {
                setTracebilityCode(res.data);
            } else {
                setTracebilityCode([]);
            }
        }).catch((err) => {
            setTracebilityCode([]);
        });
    }



    const columns: ColumnProps<any>[] = [
        {
            title: 'Sno',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'SO Item',
            dataIndex: 'soItemId',
            render: (value) => {
                return soItemDropdown?.find(i => i.saleOrderItemId == value)?.soItemName
            }

        },
        {
            title: 'Tracebility Code',
            dataIndex: 'tracebilityCodeId',
            render: (value) => {
                return tracebilityCode?.find(i => i.codeListId == value)?.traceabilityCode
            }

        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'

        },
        {
            title: 'Prod Date',
            dataIndex: 'productionDate',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY')
            }

        },
        {
            title: 'Mfg Date',
            dataIndex: 'bestBeforeDate',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY')
            }

        },
        {
            title: 'Cartons',
            dataIndex: 'cartons',
            fixed: 'right'
        },

    ]

    return (
        <Card title={<span style={{ color: 'white' }}>Code List Detail View</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/code-list-main-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>  </Link>}>
                <Form>
            {tableData.length > 0 ?

                <Table
                    dataSource={tableData}
                    columns={columns}
                    scroll={{ x: true }}
                    pagination={false}
                    bordered
                    summary={(pageData) => {

                        let totalCaartons = 0;
                        pageData.forEach(({ cartons }) => {
                            totalCaartons += Number(cartons);

                        });

                        return (
                            <>
                                <Table.Summary.Row className='tableFooter'>
                                    <Table.Summary.Cell index={0} colSpan={6} ><Text >Total Cartons</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} ><Text >{totalCaartons}</Text></Table.Summary.Cell>
                                </Table.Summary.Row>

                            </>
                        );
                    }}
                /> : <></>
            }
            </Form>
        </Card>
    );
}
export default CodeListDetailView;