
import React, { useEffect, useRef, useState } from 'react';
import BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';



const localizer = momentLocalizer(moment);
moment.locale('en-GB');


export function DownTimeEventCalender(
) {
  const downtimetrackingService = new DownTimeTrackingService();
  const [eventdata,setEventdata]=useState<any[]>([]);
    const now = new Date();
    useEffect(() =>{
        getallevncalnderdetails();
    },[])

    const eventss = eventdata.map(i =>{
      return {title : i.title,start : moment(i.start) ,end:moment(i.end)}
    })
    console.log(eventss)
    const events =

    [
        // {
        //     id: 0,
        //     title: 'Meeting',
        //     start: moment({ hours: 8 }).toDate(),
        //     end: moment({ hours: 10.5 }).toDate(),
        //     desc:'oneplus'
        //   },
      
        {
            'title': 'all day event',
            'start': moment(),
            'end': moment(),
          },
          {
            'title': 'day ',
            'start': new Date(2022, 0, 3, 7, 0, 0),
            'end': new Date(2022, 0, 3, 8, 50)
          },
          {
            'title': 'Renuka',
            'start': new Date(2022, 12, 1, 12, 0, 0),
            'end': new Date(2022, 12, 1, 5, 0)
          },
          {
            'title': 'mahi ',
            'start': new Date(2022, 4, 3, 7, 0, 0),
            'end': new Date(2022, 4, 3, 8, 5,0)
          },
 
    ];
    const getallevncalnderdetails = () =>{
        downtimetrackingService.downtimeventcalnder().then(res => {
            if (res.status) {
                setEventdata(res.data);
            } else {
              if (res.intlCode) {
                setEventdata([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setEventdata([]);
          })

    }

    



    return (
        <div className="App">
            <div style={{ height: 700 }}>
                <Calendar
                    events={eventss}
                    startAccessor="start"
                    endAccessor="end"
                    defaultDate={moment().toDate()}
                    localizer={localizer}
                />
            </div>
        </div>
    )
}
export default DownTimeEventCalender;

