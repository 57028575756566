import { Card, Col, DatePicker, Form, FormInstance, Input, InputNumber, Rate, Row, Select } from 'antd';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CertificatesDto, CertificationsDropDownData } from '@gtpl/shared-models/masters';
import { CertificationsService } from '@gtpl/shared-services/masters';
import { SalePoDetailsInfo } from '@gtpl/shared-models/sale-management';
import { JobTypesEnum } from '@gtpl/shared-models/common-models';

const { Option } = Select;
export interface IPodetailsProps {
    form: FormInstance<any>
    initialData:SalePoDetailsInfo
    jobType:string
}
const PoDetailsForm = (props: IPodetailsProps) => {
    const form = props.form;
    const [certificationdata, setcertificationData] = useState<CertificatesDto[]>([]);
    const [certification, setCertification] = useState(0)
    const [subPoDisable, setsubPoDisable] = useState<boolean>(false);
    const certficateService = new CertificationsService();

    useEffect(()=>{
        if(props.jobType == JobTypesEnum.SUB_JOB){
          console.log(props.jobType)
          setsubPoDisable(true)
        }
      },[props.jobType])

    useEffect(() => {
        getPoDetailsData();
        if(props.initialData){
        setCertification(Number(props.initialData.certificationId))
            
            console.log(props.initialData)
            props.form.setFieldsValue(props.initialData)

        }
    }, []);
    useEffect(() => {
        console.log(certification);
    }, [certification]);

    const getPoDetailsData = () => {
        certficateService.getAllActiveCertifications().then((res) => {
            if (res.status) {
                setcertificationData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setcertificationData([]);
            }
        })
    }
    const poNWNCOnchange = (selectedValue: string) => {

    }
    const CertificationOnchange = (selectedValue: string) => {
        setCertification(Number(selectedValue))
        if(form.getFieldValue('rating')){
            form.setFieldsValue({rating:null})
        }
        if(form.getFieldValue('iscertification')){
            form.setFieldsValue({iscertification:null})
        }
    }
    const soakingStyleOnchange = (selectedValue: string) => {

    }

    return (

        <Card title={<span style={{ color: 'white' }}>Certifications</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Form layout={"vertical"} form={form}>
                
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="certificationId" label="Certifications" rules={[{ required: true,message: 'Missing Certification' }]}>
                            <Select
                                placeholder="Select Certification"
                                onChange={CertificationOnchange}
                                allowClear
                                disabled={subPoDisable}
                            >
                                {certificationdata.map(dropData => {
                                    return <Option value={dropData.certificateId}>{dropData.certificateName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} >
                        {(certification == 1) ? <Form.Item name="rating" label="Rating" rules={[{ required: true,message: 'Missing rating' }]}>
                            <Rate count={4}/>
                        </Form.Item> : (certification == 2) ? <Form.Item name="iscertification" label="Certification Approved?" rules={[{ required: true,message: 'Missing certification approved' }]}>
                            <Select
                                placeholder="Certification"
                                allowClear
                                disabled={subPoDisable}
                            >
                                <Option value="YES">YES</Option>
                                <Option value="NO">NO</Option>

                            </Select>
                        </Form.Item> : <></>}

                    </Col>
                </Row>
            </Form>
        </Card>)
}
export default PoDetailsForm