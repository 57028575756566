import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ProfilesForm} from '@gtpl/pages/hrms/hrms-components/profiles-form';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import './profiles-grid.css';
import { ProfilesService } from '@gtpl/shared-services/hrms';
import { ProfilesDto } from '@gtpl/shared-models/hrms';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';

export interface ProfilesGridProps {}

export function ProfilesGrid(props: ProfilesGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [profileData, setProfileData] = useState<ProfilesDto[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<any>(undefined);
  const service = new ProfilesService
  const [updateState, setUpdateState] = useState(false);
  const [object, setObject] = useState(null);
  useEffect(() => {getAllProfiles();}, [])
  const getAllProfiles= () => {
    service.getAllProfiles().then(res => {
      if (res.status) {setProfileData(res.data);
      } else {
        if (res.intlCode) {
          setProfileData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProfileData([]);
    })
  }


  const updateProfile = (profileData: ProfilesDto) => {
    service.updateProfile(profileData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllProfiles();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const deleteCountry = (profilesData:ProfilesDto) => {
    profilesData.isActive=profilesData.isActive?false:true;
    service.activateOrDeactivateProfile(profilesData).then(res => { console.log(res);
      if (res.status) {
        getAllProfiles();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

     
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.purchaseOrderId)

  }
    
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    const openFormWithData=(viewData: ProfilesDto)=>{
      setDrawerVisible(true);
      viewData.profileDate=moment(viewData.profileDate,'YYYY-MM-DD')
      setSelectedProfile(viewData);
      console.log('profdata',viewData);
    }


    const columnsSkelton: ColumnProps<any>[] = [
    
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        // hideInSearch: true,
        // hideInForm: true,
        responsive: ['sm'],
        render: (text, object, index) => (page-1) * 10 + (index + 1)
      },
    {
      title: 'Candidate Name',
      dataIndex: 'candidateName',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.candidateName.localeCompare(b.candidateName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('candidateName')
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.jobDescription?.localeCompare(b.jobDescription),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobDescription')
    },
    {
        title: 'Profile Date',
        dataIndex: 'profileDate',        
        sorter: (a, b) => moment(a.profileDate).unix() - moment(b.profileDate).unix(),
        sortDirections: ['descend', 'ascend'],
        // valueType: 'dateTime',
        render: (text, record) => {return moment(record.profileDate).format('YYYY-MM-DD')},
      }, 
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.mobileNumber.localeCompare(b.MobileNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mobileNumber')
    },
    {
      title: 'Qualification',
      dataIndex: 'qualification',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.qualification.localeCompare(b.qualification),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('qualification')
    },
    // {
    //   title: 'Experience',
    //   dataIndex: 'experience',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   // sorter: (a, b) => a.experience.localeCompare(b.Experience),
    //   // sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('experience')
    // sorter : (a,b) => a.experience - b.experience,
    // sortDirections:['descend','ascend'],
    // ...getColumnSearchProps('experience')
    // },
    // {
    //   title: 'Stack',
    //   dataIndex: 'Stack',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.user.localeCompare(b.Stack),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('Stack')
    // },
    // {
    //   title: 'vendor Name',
    //   dataIndex: 'vendor Name',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.user.localeCompare(b.vendorName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('vendor Name')
    // },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      // hideInSearch: true,
      // hideInForm: true,
      sorter: (a, b) => a.sourceType.localeCompare(b.sourceType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'Reference',
          value: 'Reference',
        },
        
        {
          text: 'Direct',
          value: 'Direct',
        },
      ],
      filterMultiple: false, 
      onFilter: (value, record) => {
        // === is not work
        return record.sourceType == value;
      },
    },
    // {
    //   title: 'Referred by',
    //   dataIndex: 'referredBy',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.referredBy?.localeCompare(b.referredBy),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('referredBy')
    // },
    {
      title: ' Employee Status',
      dataIndex: 'candidateType',
      align:'center',
      render: (candidateType, rowData) => (
        <>
          {candidateType == 'Fresher'?<Tag color="#87d068">Fresher</Tag>:<Tag icon={<CloseCircleOutlined/>} color="#f50"> Experience</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Fresher',
          value: 'Fresher',
        },
        {
          text: 'Experience',
          value: 'Experience',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) =>
      {
        return record.candidateType === value;
      },
    },
    
    // {
    //   title: 'Notice Peroid',
    //   dataIndex: 'noticePeriod',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   sorter: (a, b) => a.noticePeriod-b.noticePeriod,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('noticePeriod')
    // },
	  // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // hideInSearch: true,
    //   // hideInForm: true,
    //   responsive: ['sm'],
    //   // sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    //   // sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('remarks')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined/>} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined/>} color="#f50"> In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) =>
      {
        return record.isActive === value;
      },
    },
    {
      title:`Action`,
      dataIndex: 'action',
      // hideInSearch: true,
      // hideInForm: true,
      render: (text, rowData) => (
        <span> 
          <Tooltip placement="top" title='Edit'>       
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Certification');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>   
              <Divider type="vertical" />
            
            <Popconfirm onConfirm={e =>{deleteCountry(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Profiles ?'
                :  'Are you sure to Activate Profiles  ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Profiles</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/profiles-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
     
      <Col>
          <Card title={'Total Profiles: ' + profileData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + profileData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + profileData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          
      
          </Row>
          <br></br>
          <Table
          rowKey={record => record.profileId}
          columns={columnsSkelton}
          dataSource={profileData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        {/* <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <ProfilesForm key={Date.now()}
                updateItem = {updateProfile}
                profiles={selectedProfile}
                isUpdate={true}
                closeForm={closeDrawer} />
            </Card>
          </Drawer> */}
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
             
              <ProfilesForm key={Date.now()}
                updateItem = {updateProfile}
                profiles={selectedProfile}
                // data={slectedEmploymentStatusData}
                isUpdate={true}
                closeForm={closeDrawer}
             />
             
            </Card>
          </Drawer>
     </Card>
    </>
  );
}



