import { UpdateServiceRemarksDto } from '@gtpl/shared-models/asset-management';
import { AssetService } from '@gtpl/shared-services/asset-management';
import { Card, Descriptions } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

type Props = {
    assetServiceId: number
}

export default function ServiceDetailView(props: Props) {
    const assetsService = new AssetService();
    const { assetServiceId } = useParams<any>()
    const [serviceData, setServiceData] = useState<any>()
    useEffect(() => {
        getData()
    },[])
    console.log(assetServiceId,'assetServiceId')
    const getData = () => {
        const req = new UpdateServiceRemarksDto()
        req.assetServiceId = props.assetServiceId ? props.assetServiceId : assetServiceId
        assetsService.getServiceDataForServiceId(req).then((res) => {
            if (res.status) {
                setServiceData(res.data[0])
            } else {
                setServiceData(res.data[0])
            }
        })
    }
    return (
        <Card title={'Service Details'}>
            <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
                <Descriptions.Item label="Service Name"  >
                    {serviceData?.serviceName}
                </Descriptions.Item>
                <Descriptions.Item label="Asset Name"  >
                    {serviceData?.assetName}
                </Descriptions.Item>
                <Descriptions.Item label="Asset Code"  >
                    {serviceData?.assetCode}
                </Descriptions.Item>
                <Descriptions.Item label="Model"  >
                    {serviceData?.model}
                </Descriptions.Item>
                <Descriptions.Item label="Location"  >
                    {serviceData?.locationName}
                </Descriptions.Item>
                <Descriptions.Item label="Service Date"  >
                    {moment(serviceData?.serviceDate).format("DD-MM-YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label="Service Provide"  >
                    {serviceData?.serviceProvider}
                </Descriptions.Item>
                <Descriptions.Item label="Service Owner"  >
                    {serviceData?.serviceOwner}
                </Descriptions.Item>
                <Descriptions.Item label="Phone Number"  >
                    {serviceData?.phoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Service Note"  >
                   <pre> {serviceData?.remarks}</pre>
                </Descriptions.Item>
                
               
            </Descriptions>

        </Card>
    )
}
