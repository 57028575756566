import React from "react";
import Tree from 'react-d3-tree';
export interface LotCodeTreeProps { }
export function LotCodeTree(props: LotCodeTreeProps) {
  const orgChart = [{

    lot: {
      name: 'Lot Code',
      attributes: { Code: 'LOT01/23/8/0003' },
      children: [
        {
          name: 'De Heading',
          attributes: {
            IsUsed: '60kg',
            WIP: '20kg'
          },
          children: [
            {
              name: 'Grading',
              attributes: {
                IsUsed: '60kg',
                WIP: '10kg'
              },
              children: [
                {
                  name: 'Sub Lot',
                  attributes: {
                    subLotNo: 'S0001',
                  },
                  children: [
                    {
                      name: 'PDTON',
                      children: [
                        {
                          name: 'soaking',
                          attributes: {
                            IsUsed: '15kg',
                            WIP: '25kg'
                          },
                          children: [
                            { name: 'Freezing' }
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'Sub Lot',
                  attributes: {
                    subLotNo: 'S0002',
                  },
                  children: [
                    {
                      name: 'PDTON',
                      children: [
                        {
                          name: 'soaking',
                          attributes: {
                            IsUsed: '20kg',
                            WIP: '20kg'
                          },
                          children: [
                            { name: 'Freezing' }
                          ]
                        }
                      ]
                    },
                  ],
                },
              ],
            },

          ],
        },
      ],

    }
  },
  // {
  //   lot: {
  //     name: 'Lot Code',
  //     children: [
  //       {
  //         name: 'De Heading',
  //         attributes: {
  //           department: 'Production',
  //         },
  //         children: [
  //           {
  //             name: 'Grading',
  //             attributes: {
  //               department: 'Production',
  //             },
  //             children: [
  //               {
  //                 name: 'Packing',
  //               },
  //             ],
  //           },
  //           {
  //             name: 'Peel Deveining',
  //             attributes: {
  //               department: 'Assembly',
  //             },
  //             children: [
  //               {
  //                 name: 'Packing',
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],

  //   }
  // }
  ];
  return (
    <>
    
      {orgChart.map(i => {
        return <Tree data={i.lot} orientation={"vertical"} nodeSize={{x:150,y: 200}} translate={{x:50,y:60}}/>
      })

      }
    </>
  )
}
export default LotCodeTree;