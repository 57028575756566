import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import { QualificationsDto } from '@gtpl/shared-models/masters';
import { QualificationsService } from '@gtpl/shared-services/masters';

import './qualifications.css';

/* eslint-disable-next-line */
export interface QualificationsProps {
  qualificationData: QualificationsDto;
  updateQualification: (qualification: QualificationsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function QualificationsForm(
  props: QualificationsProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const qualificationsService = new QualificationsService()
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const createQualification = (Data: QualificationsDto) => {
    setDisable(true)
    qualificationsService.createQualification(Data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Qualification created successfully');
        history.push('/qualifications-view');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('');
    form.resetFields();

  }

  const saveData = (values: QualificationsDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values);
      props.updateQualification(values);
    } else {
      setDisable(false)
      console.log(values);
      createQualification(values);
    }
  };

  return (

    <Card title={<span style={{ color: 'white' }}>Qualifications</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/qualifications-view' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} onFinish={saveData} initialValues={props.qualificationData} >
        <Form.Item
          name="qualificationId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="qualificationName"
              label="Qualification Name"
              rules={[
                {
                  required: true,
                  message: "Enter valid Qualification name.",
                  max: 25
                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/_@ ]*$/,
                  message: `Should contain only alphabets and numbers.`
                },
                {

                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>


        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate !== true) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>

      </Form>
    </Card>

  );
}

export default QualificationsForm;
