import React, { useEffect, useState } from 'react';
import Table, { ColumnProps } from "antd/lib/table";
import {Button, Card, Modal, Typography} from 'antd';
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {useHistory } from "react-router-dom";
import moment from 'moment';
import { truncateSync } from 'fs';
import { AbstractDetails } from 'libs/shared-models/asset-management/src/lib/open-assets/abstract-details.dto';

/* eslint-disable-next-line */
export interface AbstractReportProps {}

export function AbstractReport(
  props: AbstractReportProps
  ) {
    
    const [page, setPage] = useState<number>(1);
    const [data,setData] = useState<any>([]);
    const { Text } = Typography;
    const service = new AssetService();
    const [modal,setModal] = useState<boolean>(false);
    const [assetData,setAssetData] = useState<any>([]);

    let diff = 0;
    let date;
    let todate;

    useEffect(() => {
      service.getAbstractReport().then(res => {
        if(res.status){
          setData(res.data);
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('Data not found')
        }
      })
    },[])


    const onClickHandler = (record,data) => {
      console.log(data)
      const req = new AbstractDetails(record.item_sub_category,data)
      if(data == null) {
        service.getTotalAbstract(req).then(res => {
          if(res.status){
            setAssetData(res.data)
          } else {
            AlertMessages.getErrorMessage('Data not found')
          }
        })
      } else {
      service.getDetailsByAbstract(req).then(res => {
        if(res.status){
          setAssetData(res.data)
        } else {
          AlertMessages.getErrorMessage('Data not found')
        }
      })
    }
      setModal(true)
      
    }

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: "40px",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: "Asset Category",
      dataIndex: "item_sub_category",
      align:"left",
      width:120,  
    }, 
    {
      title: "In Stock",
      dataIndex: "Instock",
      align:"right",
      width:90,
      render:(text,record) => {
        return (
          <>
            <Button type='link' onClick={() => onClickHandler(record,'INSTOCK')}>{record.Instock}</Button>
          </>
        )
      }
    },
    {
      title: "In Use",
      dataIndex: "checkin",
      align:"right",
      width:90,
      render:(text,record) => {
        return (
          <>
            <Button type='link' onClick={() => onClickHandler(record,'INUSE')}>{record.checkin}</Button>
          </>
        )
      }

    },
    {
      title: "Maintenance",
      dataIndex: "undermaintainance",
      align:"right",
      width:110,
      render:(text,record) => {
        return (
          <>
            <Button type='link' onClick={() => onClickHandler(record,'UNDERMAINTENANCE')}>{record.undermaintainance}</Button>
          </>
        )
      }
      },
      {
        title: "Service",
        dataIndex: "service",
        align:"right",
        width:90,
        render:(text,record) => {
          return (
            <>
              <Button type='link' onClick={() => onClickHandler(record,'SERVICE')}>{record.service}</Button>
            </>
          )
        }
        },
        {
          title: "Intransit",
          dataIndex: "intransit",
          align:"right",
          width:90,
          render:(text,record) => {
            return (
              <>
                <Button type='link' onClick={() => onClickHandler(record,'INTRANSIT')}>{record.intransit}</Button>
              </>
            )
          }
          },
      {
        title: "Damaged",
        dataIndex: "damaged",
        align:"right",
        width:90,
        render:(text,record) => {
          return (
            <>
              <Button type='link' onClick={() => onClickHandler(record,'DAMAGED')}>{record.damaged}</Button>
            </>
          )
        }
        },
        {
          title: "Retired",
          dataIndex: "decommissioned",
          align:"right",
          width:90,
          render:(text,record) => {
            return (
              <>
                <Button type='link' onClick={() => onClickHandler(record,'RETIRED')}>{record.decommissioned}</Button>
              </>
            )
          }
          },
          {
            title: "SOLD",
            dataIndex: "sold",
            align:"right",
            width:90,
            render:(text,record) => {
              return (
                <>
                  <Button type='link' onClick={() => onClickHandler(record,'SOLD')}>{record.sold}</Button>
                </>
              )
            }
            },
        {
        title: "Total",
        dataIndex: "total",
        align:"right",
        width:90,
        render:(text,record) => {
          return (
            <>
              <Button type='link' onClick={() =>onClickHandler(record,null)}>{record.total}</Button>
              
            </>
          )
        }
  
        }
    ]
  
  const columns1: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 70,
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: "Asset Category",
      dataIndex: "itemSubCategoryName",
      align:"left",
      width:120,
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('itemSubCategoryName')
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      align:"left",
      width:120,
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend']
    }, 
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align:"left",
      width:120,
      sorter:(a,b)=>a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections:['descend','ascend'],
      // render:(text,record)=>{
      //   return<>
      //   <Button type='link'onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
      //   </>
      // }
    },
    
    {
      title: "Location",
      dataIndex: "currentLocation",
      align:"left",
      width:120,
      render:(text,record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      },
     // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Purchase Date",
      dataIndex: "dateOfPurchased",
      align:"right",
      width:120,
      render:(text,record) => {
        return(
          <>
          {record.dateOfPurchased? moment(record.dateOfPurchased).format('YYYY-MM-DD'): 'N/A'}
          </>
        )
      }
    },
    {
      title: "AMC",
      dataIndex: "amcname",
      align:"left",
      width:120,
      render:(text,record) => {
        return(
          <>
          {record.amcname? record.amcname : 'No'}
          </>
        )
      }
    },
    {
      title: "Warranty",
      dataIndex: "warrentyExpiredDate",      
      align:"right",
      width:120,
      // sorter: {
      //   compare:(a, b) =>{
      //     a= a.created_date.toLowerCase()
      //     b = b.created_date.toLowerCase()
      //     return a > b ? -1 : b > a ? 1:0
      //   },
      // },
      render:(text,record) => {
        return(
          <>
          {record.warrentyExpiredDate? moment(record.warrentyExpiredDate).format('YYYY-MM-DD'): 'N/A'}
          </>
        )
      }
    },
    {
      title: "Insurance",
      dataIndex: "insuranceDate",
      align:"right",
      width:120,
      render:(text,record) => {
        return(
          <>
          {record.insuranceDate? moment(record.insuranceDate).format('YYYY-MM-DD'): 'N/A'}
          </>
        )
      }
    },
    {
      title: "Expiry Date",
      dataIndex: "expiredDate",
      align:"right",
      width:120,
      render:(text,record) => {
        return(
          <>
          {record.expiredDate? moment(record.expiredDate).format('YYYY-MM-DD'): 'N/A'}
          </>
        )
      }
    },
    
    {
      title: "Status",
      dataIndex: "status",
      align:"left",
      width:120,
    },
    // {
    //   title: "Aging",
    //   dataIndex: "age",
    //   render:(text,record,index) => {        
    //       todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
    //       date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
    //       diff = (todate - date) / 864e5;
    //       if(diff < 0) {
    //         diff = -diff;
    //       }
    //     return(
    //       <>
    //       {record.transactionDate ?  diff : '-'}
    //       </>
    //     )
        
    //   }
    // },
    {
      title: ' Asset Age',
      dataIndex: 'assetAge',
      align:"right",
      width:120,
      render:(text,record) => {
        todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD')); 
        diff = (todate - date) / 864e5;
        return(
          <>
          {record.dateOfPurchased ? diff : null}
          </>
        )
      }
    },{
      title: "Cost of Asset",
      dataIndex: "assetCost",
      align:"right",
      width:120,
      render:(text,record) => {
        return (
          <>
            {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      }
    }
  ];
    return(
      <Card title={<span style={{ color: 'white' }}> Abstract Report </span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <Table 
      columns={columns} 
      dataSource={data} 
      pagination={false}
      
      // pagination={{
      //   onChange(current) {
      //     setPage(current);
      //   }
      // }}
      scroll={{x:500, y: 520 }}
      bordered
        summary={(pageData) => {
          let totalCheckIn = 0;
          let totalStock = 0;
          let totalMaintenance = 0;
          let totalService = 0;
          let totalIntransit = 0;
          let totalDamaged = 0;
          let totalDecommissioned = 0;
          let totalSold = 0;
          let totalAssets = 0;

          pageData.forEach(({checkin}) => {
            if(Number(checkin)) {
              totalCheckIn += Number(checkin)
            }
          })

          pageData.forEach(({Instock}) => {
            if(Number(Instock)) {
              totalStock += Number(Instock)
            }
          })

          pageData.forEach(({undermaintainance}) => {
            if(Number(undermaintainance)) {
              totalMaintenance += Number(undermaintainance)
            }
          })

          pageData.forEach(({service}) => {
            if(Number(service)) {
              totalService += Number(service)
            }
          })

          pageData.forEach(({intransit}) => {
            if(Number(intransit)) {
              totalIntransit += Number(intransit)
            }
          })

          pageData.forEach(({damaged}) => {
            if(Number(damaged)) {
              totalDamaged += Number(damaged)
            }
          })

          pageData.forEach(({decommissioned}) => {
            if(Number(decommissioned)) {
              totalDecommissioned += Number(decommissioned)
            }
          })

          pageData.forEach(({sold}) => {
            if(Number(sold)) {
              totalSold += Number(sold)
            }
          })

          pageData.forEach(({total}) => {
            if(Number(total)) {
              totalAssets += Number(total)
            }
          })

          return(
            <>
            <Table.Summary.Row className="tableFooter" >
              <Table.Summary.Cell index={1} colSpan={2}><Text>Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={1}><Text style={{textAlign:'end'}}>{Number(totalStock).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1}><Text style={{textAlign:'end'}}>{Number(totalCheckIn).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalMaintenance).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalService).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalIntransit).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={8} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalDamaged).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={9} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalDecommissioned).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={10} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalSold).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={11} colSpan={1}><Text style={{textAlign:'end'}}>{Number (totalAssets).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

            </Table.Summary.Row>
            </>
          )
        }}
    />
  <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={modal}
          onCancel={() => { setModal(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
         <Table columns={columns1} dataSource={assetData} scroll={{ x: 400, y: 500 }}

          summary={(pageData) => {
            let totalCost = 0;  
         pageData.forEach(({assetCost}) => {
          if(Number(assetCost)) {
            totalCost += Number(assetCost)
          }
        })

        return(
          <>
          <Table.Summary.Row className="tableFooter">
            <Table.Summary.Cell index={1} colSpan={11}><Text>Total</Text></Table.Summary.Cell>
            <Table.Summary.Cell index={10} colSpan={1}><Text style={{textAlign:'end'}}></Text></Table.Summary.Cell>
            <Table.Summary.Cell index={11} colSpan={1}><Text style={{textAlign:'end'}}>{Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

          </Table.Summary.Row>
          </>
        )
          }}
        />

        </Modal>

    </Card>
    )}

export default AbstractReport;
