import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import { ExpectedStockInOutResponse } from '@gtpl/shared-models/warehouse-management';
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CurrentDayStockTransfer from './current-dat-transfers';
import CurrentDayStockOut from './current-day-stock-out';
import ExpectedStockCardComponent from './expected-stock-card';
import LongRunningDummyStock from './long-running-dummy';
import ProductionPercentage from './production-percentage';
import RepackingEfficiency from './repacking-efficiency';
import { CurrentDayStockOutPos } from './stockout-pos';
import TopTenDummyProducts from './top-ten-dummy';
import WayRackStock from './wayrack-cartons';

export interface ExpectedStockInAndOutProps { }

export function ExpectedStockInAndOut(props: ExpectedStockInAndOutProps) {
    const [expectedStock, setExpectedStock] = useState<any>();
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getExpectedStockInsAndStockOuts();
    }, [])

    const getExpectedStockInsAndStockOuts = () => {

        service.getExpectedStockInsAndStockOuts({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setExpectedStock(res)
            } else {
                
            }
        })
    }
    
    const whAvalilityBodyCard: CardBodyDetails[] = [];

    let totalCount = 0;

    for (const dataInfo of Array(expectedStock)) {
        whAvalilityBodyCard.push(new CardBodyDetails(Number(dataInfo?.expectedStockInCnt ? dataInfo?.expectedStockInCnt : "0").toLocaleString("en-IN"), Number(dataInfo?.expectedStockOutCnt ? dataInfo?.expectedStockOutCnt : "0").toLocaleString("en-IN"), Number(dataInfo?.expectedLocations ? dataInfo?.expectedLocations : "0").toLocaleString("en-IN"), Number(dataInfo?.expectedavailableLocations ? dataInfo?.expectedavailableLocations : "0" ).toLocaleString("en-IN")));
    }
    const totalCoundBody = new CardHeaderBody('Total Count', totalCount.toString());
    const totalCoundBodyTwo = new CardHeaderBody('', status);

    const whCardHeader = new CardHeader('Expected Stock In & Out', [totalCoundBody, totalCoundBodyTwo]);

    const cardDetails = new CardDetail(whCardHeader, whAvalilityBodyCard, ['Expected Cartons In', ' Expected Cartons Out', 'Locations Need', 'Expected Available Locations',]);


    return (
        <>
        <Row gutter={24}>

            <Col span={5}>
                <ExpectedStockCardComponent cardData={cardDetails} />
            </Col>
        </Row>
        </>
    )
}

export default ExpectedStockInAndOut;