import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { DatePicker, Space } from "antd";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";
import { AssetMaintenanceService } from 'libs/shared-services/asset-management/src/lib/asset-maintenance';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetById } from "libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-id.dto";
import moment from "moment";
import { Excel } from 'antd-table-saveas-excel';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { Option } = Select;
const { RangePicker } = DatePicker;
import Highlighter from 'react-highlight-words';


/* eslint-disable-next-line */
export interface AssetsmaintenanaceReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const AssetsmaintenanaceReport = (
  props: AssetsmaintenanaceReportProps
) => {
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const onSearch = (value: string) => console.log(value);
  const [transData, setTransData] = useState<any>([]);
  const { TabPane } = Tabs;
  const [assetsData, setAssetsData] = useState<AssetDto[]>([])
  const assetService = new AssetService();
  const { Text } = Typography;
  const [length, setLength] = useState('');
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const [pageSize, setPageSize] = useState<number>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);




  const service = new AssetMaintenanceService();
  const role = JSON.parse(localStorage.getItem('role'))

  let diff = 0;
  let date;
  let todate;
  var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear, currentMonth, 1)
  const dateformat = 'YYYY-MM-DD';

  useEffect(() => {
    //getAllData();
    getAssetCode();

    getAllPlants();
    getItemCategory();
    getTransById();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    } getLocations();
  }, [])

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  // const getAllData = () => {
  //   service.getTransById().then(res => {
  //     if(res.status) {
  //       setTransData(res.data)
  //       // AlertMessages.getSuccessMessage('Data retrieved successfully')
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }


  const getAssetCode = () => {
    const req = new AssetByCode();
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getAssetCodesDropDownformaintanancetrans(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getTransById = () => {
    const req = new AssetByCode();
    if (form.getFieldValue('assetsCode') !== undefined) {
      req.assetsCode = form.getFieldValue('assetsCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('date') !== undefined) {
      req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM-DD')
      req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM-DD')
    }
    service.getTransById(req).then(res => {
      if (res.status) {
        setTransData(res.data)
        setLength(res.data.length);
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('Data not found')
        }
      } else {
        setTransData([])
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  // const getDateReport = (req) => {
  //   service.getByDate(req).then(res => {
  //     if(res.status) {
  //       setTransData(res.data)
  //       AlertMessages.getSuccessMessage('Data retrieved successfully')
  //     } else{
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const searchHandler = () => {
    // if(form.getFieldValue('date') !== undefined) {
    //   const fromDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[0]).format('YYYY-MM-DD') : null;
    //   const toDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[1]).format('YYYY-MM-DD') : null;
    //   const selectedDate = { fromDate, toDate };
    //   getDateReport(selectedDate);
    // } else {
    //   getTransById()
    // }
    getTransById()

  }


  const resetHandler = () => {
    form.resetFields();
    setTransData([])
    getTransById()
  }

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      // width: "30px",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: "Plant",
      dataIndex: "plant",
      align: "left",
      //width:120,
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('plant')
    },

    {
      title: "Location",
      dataIndex: "currentLocation",
      align: "left",
      // width:120,
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['ascend', 'descend',],
      render: (text, record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Asset Mode",
      dataIndex: "assetMode",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      // width: 130,
      align: "left",
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend']
      // filters: [
      //   {
      //     text: 'MOVABLE',
      //     value: 'movable',
      //   },

      //   {
      //     text: 'FIXED',
      //     value: 'FIXED',
      //   },
      //   {
      //     text: 'SOFTASSETS',
      //     value: 'softassets'
      //   }
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.assetType === value;
      // },
    },
    {
      title: "Asset Category",
      dataIndex: "assetCategory",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.assetCategory?.localeCompare(b.assetCategory),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.assetsCode.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Owner",
      dataIndex: "employeeName",
      // width: 130,
      align: "left",
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName'),
    },
    // {
    //   title: "Maintenance Type",
    //   dataIndex: "maintainanceType",
    // width: 130,
    //   align: "center",
    //   render:(text,record) => {
    //     return(
    //       <span>
    //         {record.maintainanceType ? record.maintainanceType: '-'}
    //       </span>
    //     )
    //   }
    // },
    {
      title: "Repair Start Date",
      dataIndex: "createdAt",
      // width: 130,
      align: "right",
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <span>
            {record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-'}
          </span>
        )
      }
    },
    {
      title: "Completion Date",
      dataIndex: "updatedAt",
      // width: 130,
      align: "right",
      sorter: (a, b) => a.updatedAt?.localeCompare(b.updatedAt),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <span>
            {record.updatedAt ? moment(record.updatedAt).format('YYYY-MM-DD') : '-'}
          </span>
        )
      }
    },
    {
      title: "Total No of Days",
      dataIndex: "days",
      // width: 130,
      align: "right",
      sorter: (a, b) => a.days?.localeCompare(b.days),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        todate = moment(moment(record.createdAt).format('YYYY-MM-DD'));
        date = moment(moment(record.updatedAt).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {diff ? diff : 0}
          </>
        )
      }
    },
    {
      title: "Cost of Repair",
      dataIndex: "costOfRepair",
      // width: 130,
      align: "right",
      render: (text, record) => {
        return (
          <span>
            {record.costOfRepair ? record.costOfRepair : 'NA'}
          </span>
        )
      }
    },
    {
      title: "Damage Reason",
      dataIndex: "damageReason",
      // width: 130,
      align: "left",
      render: (text, record) => {
        return (
          <span>
            {record.damageReason ? record.damageReason : 'NA'}
          </span>
        )
      }
    }
  ];


  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const exportedData = [];
  const excelData = transData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Plant', dataIndex: 'plant', render: (text, record) => { return record.plant ? record.plant : '-' } },
    { title: 'Location', dataIndex: 'currentLocation', render: (text, record) => { return record.currentLocation ? record.currentLocation : '-' } },
    { title: 'Asset Mode', dataIndex: 'assetMode', render: (text, record) => { return record.assetMode ? record.assetMode : '-' } },
    { title: 'Asset Type', dataIndex: 'assetType', render: (text, record) => { return record.assetType ? record.assetType : '-' } },
    { title: 'Asset Category', dataIndex: 'assetCategory', render: (text, record) => { return record.assetCategory ? record.assetCategory : '-' } },
    { title: 'Asset Name', dataIndex: 'itemName', render: (text, record) => { return record.itemName ? record.itemName : '-' } },
    { title: 'Asset Code', dataIndex: 'assetsCode', render: (text, record) => { return record.assetsCode ? record.assetsCode : '-' } },
    { title: 'Owner', dataIndex: 'employeeName', render: (text, record) => { return record.employeeName ? record.employeeName : '-' } },
    { title: 'Repair Start Date', dataIndex: 'createdAt', render: (text, record) => { return record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-' } },
    { title: 'Completion Date', dataIndex: 'updatedAt', render: (text, record) => { return record.updatedAt ? moment(record.updatedAt).format('YYYY-MM-DD') : '-' } },
    {
      title: 'Total No of Days', dataIndex: 'days', render: (text, record) => {
        todate = moment(moment(record.createdAt).format('YYYY-MM-DD'));
        date = moment(moment(record.updatedAt).format('YYYY-MM-DD'));
        diff = (todate - date) / 864e5;
        return (
          <>
            {diff ? diff : 0}
          </>
        )
      }
    },

    { title: 'Cost of Repair', dataIndex: 'costOfRepair', render: (text, record) => { return record.costOfRepair ? record.costOfRepair : '-' } },
    { title: 'Damage Reason', dataIndex: 'damageReason', render: (text, record) => { return record.damageReason ? record.damageReason : '-' } },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Maintenance-report')
      .addColumns(data)
      .addDataSource(transData, { str2num: true })
      .saveAs('AssetMaintenanceReport.xlsx');
  }

  return (
    <Card
      title={<span style={{ color: "white" }}>Maintenance Report</span>}
      style={{ textAlign: "center" }}
      headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={transData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>

        </>
      ) : (<></>)}

    >
      <br /><br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="date" label='Search by Date'>
              <RangePicker
                defaultValue={[
                  moment(startDate, dateformat),
                  moment(new Date(), dateformat)
                ]}
              />
              {/* defaultValue={moment(moment().format("YYYY-MM-DD HH:MM:SS"))} */}
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetsData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                defaultValue={'All'}
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Plant' name='plantId' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                showSearch
                allowClear>
                {/* <Option key={'all'} value={''}>All</Option> */}
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={'All'}
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                //onChange={onStatusSearch}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3} style={{ marginTop: '2%' }}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block
              onClick={() => searchHandler()}
            //style={{ marginRight: 2, width: 100 ,margin:'1%',marginTop:'1%'}}
            >
              Search
            </Button>
          </Col>
          <Col style={{ marginTop: '2%' }}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}
            >
              Reset
            </Button>
            </Col>
            <Col span={3} style={{ marginTop: '2%' }}>
            <Button style={{ marginLeft: '470%' }}  >Total no of Records:{length}</Button>
          </Col>
        </Row>
      </Form>

      {/* <Form layout="horizontal" form={form} onFinish={onFinish}>
        <Row style={{ marginLeft: "1%" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }}
            style={{marginTop:'1%',marginLeft:'1'}}
          >
            <Form.Item name="assetId" label="Asset Code">
            <Select 
            showSearch
            optionFilterProp="children"
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.assetsCode} -- ${assets.assetsName}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          
          
        
             <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%',marginLeft:'2%'}}>
        
            <Space direction="vertical" size={12}>
           <RangePicker />
           </Space>
          
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }}
            style={{ marginTop:'1%' }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>

            <Button onClick = {getAllData} icon={<UndoOutlined />}>Reset</Button>
          </Col>
        </Row>
      </Form> */}
      {transData.length > 0 ? <>
        <Table
          columns={columns}
          scroll={{ x: 1400 }}
          dataSource={transData}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalCost = 0;

            pageData.forEach(({ costOfRepair }) => {
              if (Number(costOfRepair)) {
                totalCost += Number(costOfRepair)
              }
            })

            return (
              <>
                <Table.Summary.Row className="tableFooter">
                  <Table.Summary.Cell index={1} colSpan={11}><Text>Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={10} colSpan={1}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'end' }}>{Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                </Table.Summary.Row>
              </>
            )
          }}
        />
      </> : <Alert
        message="Search For Data"
        type="info"
        showIcon
      />
      }
    </Card>
  );
}

export default AssetsmaintenanaceReport;

