import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

export interface LongRunningDummyStockProps { }

export function LongRunningDummyStock(props: LongRunningDummyStockProps) {
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getLongRunningDummyStock();
    }, [])

    const getLongRunningDummyStock = () => {
        service.getLongRunningDummyStock({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setData(res.data)
                // AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Unit',
            dataIndex: 'unitCode',
        },
        {
            title: 'Sale Order',
            dataIndex: 'poNumber',
        },
        {
            title: 'Product',
            dataIndex: 'variantCode',
        },
        {
            title: 'Reported Cases',
            dataIndex: 'cases',
        },
        {
            title: 'Rack Position',
            dataIndex: 'rackPositionCode',
        },
        {
            title: 'Aging',
            key: 'aging',
            render(text, record) {
                const obj: any = {
                    children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date().getTime() - new Date(moment(record.reportingDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 }</div>),
                    // props: {
                    //     style: {
                    //         background: Math.floor((new Date(moment(record.reportingDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
                    //         color: Math.floor((new Date(moment(record.reportingDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
                    //     }
                    // },new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}
                };
                return obj;
            }
        }
    ]

    return (
        <>
            <Card title={<span style={{ color: 'black' }}>Dead Stock</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: 'white', border: 0 }}  >
                <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
            </Card>
        </>
    )
}
export default LongRunningDummyStock;