import React, { useEffect, useRef, useState } from 'react';

import './vendor-price-list-form.css';
import { ItemsService, TaxesService, VendorsPriceListService, VendorsService } from '@gtpl/shared-services/masters';
import { ItemsDropDownDto, TaxDropDownDto, TaxesDto, VendorDropDownDto, VendorIdRequest, VendorPriceListCreateDto, VendorPriceListDto, VendorPriceListInfo, VPLActivateOrDeActiveRequest } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tag, Switch, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { MinusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, PlusOutlined, DeleteOutlined, EditOutlined, RightSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
/* eslint-disable-next-line */
export interface VendorPriceListFormProps { }
const { Option } = Select;
export function VendorPriceListForm(
  props: VendorPriceListFormProps
) {

  let location = useLocation();
  let recordMap = new Map<number,number>()
  const vendorId = location.state;
  const [VendorPriceListForm] = Form.useForm();
  const [VendorPriceListItemsForm] = Form.useForm();

  useEffect(() => {
    getItems();
    getVendors();
    getTaxes();
    getVendorsPriceList(vendorId);
  }, [vendorId]);


  const searchInput = useRef(null);
  const [itemsData, setItemsData] = useState<ItemsDropDownDto[]>([]);
  const [vendorsData, setVendorsData] = useState<VendorDropDownDto[]>([]);
  const [taxData, setTaxData] = useState<TaxesDto[]>([]);
  const [vendorsPriceListData, setVendorsPriceListData] = useState<VendorPriceListDto[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [editData, setEditData] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const [selRecord, setSelRecord] = useState<any>();
  const [inActiveItems, setInActiveItems] = useState<number[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const itemService = new ItemsService();
  const vendorService = new VendorsService();
  const taxService = new TaxesService();
  const vendorPriceListService = new VendorsPriceListService();

  const [itemDetails, setItemDetails] = useState<ItemsDropDownDto>();
  const [tax, setTaxDetails] = useState<TaxDropDownDto>();
  let history = useHistory();
  const [tableData,setTableData] = useState<any>()
  const [indexItemMapData,setIndexItemMapData] = useState<any>()
   /**
   * get items data for drop down
   */
  const getItems = () => {
    itemService.getItemsDropDownData().then((res) => {
      if (res.status) {
        setItemsData(res.data);
      } else {
        setItemsData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  /**
   * get vendors data for drop down
   */
  const getVendors = () => {

    vendorService.getVendorsDropDownData().then((res) => {
      if (res.status) {
        setVendorsData(res.data);
      } else {
        setVendorsData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const getTaxes = () => {
    taxService.getAllActiveTaxes().then((res) => {
      if (res.status) {
        setTaxData(res.data);
      } else {
        setTaxData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const getVendorsPriceList = (vendorId) => {
    const vendorReq = new VendorIdRequest(vendorId);
    vendorPriceListService.GetVendorsPriceListForVendor(vendorReq).then(res => {
      if (!res.status) {
        setInActiveItems([]);
        setVendorsPriceListData([]);
      } else {
        res.data.map((selRecordItem,index) => {
          console.log(selRecordItem)
          console.log(index)
          //  recordMap = new Map<Number,Number>();
          if(!(recordMap.has(selRecordItem.item.itemId))){
            recordMap.set(selRecordItem.item.itemId,index)
          }

        })
        console.log(recordMap)
        setIndexItemMapData(recordMap)
        setVendorsPriceListData(res.data);
        setTableData(res.data.map( i => { return {...i,...{itemName:i.item.itemName}}}))
      }
    }).catch(err => {
      setInActiveItems([]);
      setVendorsPriceListData([]);
    })
  }
  console.log(tableData)

  const addItems = async () => {
    await VendorPriceListItemsForm.validateFields().then(bomRes => {
      const itemFormInfo = VendorPriceListItemsForm.getFieldsValue();
      const vendorData = VendorPriceListForm.getFieldsValue();
      const exists = vendorsPriceListData.find(item => item.item.itemId === itemDetails.itemId);
      if (exists && !(editData)) {
        AlertMessages.getErrorMessage('Item already in list');
      } else {
        const itemData: VendorPriceListDto = {
          vendorId: vendorData.vendorId,
          item: itemDetails,
          tax: tax,
          unitPrice: itemFormInfo.unitPrice,
          discountPercentage: itemFormInfo.discountPercentage,
          discountPrice: itemFormInfo.discountPrice,
          actualPrice: itemFormInfo.actualPrice,
          skuCode: itemFormInfo.skuCode,
          createdUser: localStorage.getItem('createdUser'),
          updatedUser: localStorage.getItem('createdUser'),
          isActive: true,
          vendorPriceListId: (itemFormInfo.vendorPriceListId) ? itemFormInfo.vendorPriceListId : null,
          versionFlag: itemFormInfo.versionFlag
        }
        if (editData) {
          vendorsPriceListData.splice(indexItemMapData.get(selRecord.item.itemId), 1, itemData)
        } else {
          vendorsPriceListData.push(itemData);
        }
        setTableData(vendorsPriceListData.map( i => { return {...i,...{itemName:i.item.itemName}}}))

        // setTableData(vendorsPriceListData)
        setEditData(false);
        setKeyUpdate(keyUpdate + 1);
        ResetForm();
      }

    })

  }

  const ResetForm = () => {
    VendorPriceListItemsForm.resetFields();
  }

  const EditItem = (record: VendorPriceListDto, index) => {
    setEditData(true);
    setIndex(index);
    setSelRecord(record);
    VendorPriceListItemsForm.setFieldsValue({
      item: record.item.itemId,
      tax: record.tax.taxId,
      unitPrice: record.unitPrice,
      vendorPriceListId: record.vendorPriceListId,
      versionFlag: record.versionFlag
    })
    setItemDetails(record.item);
    setTaxDetails(record.tax);

  }

  const setItem = (itemId, itemName) => {
    const item = new ItemsDropDownDto(itemId, itemName.children);
    setItemDetails(item);
  }

  const setTax = (taxId, taxName) => {
    const tax = new TaxDropDownDto(taxId, taxName.children);
    setTaxDetails(tax);
  }

  /**
* 
* @param selectedKeys 
* @param confirm 
* @param dataIndex 
*/
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  /**
* used for column filter
* @param dataIndex column data index
*/
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  
  
    const columns: any[] = [
      {
        title: 'Item',
        dataIndex: 'item.itemName',
        sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('itemName'),
        render: (value, record) => {
          return (record?.item?.itemName) ? record.item.itemName : '';
        },
      },
      {
        title: 'Tax Percentage %',
        dataIndex: 'tax.taxId',
        key: 'tax.taxId',
        render: (value, record: VendorPriceListDto, index) => {
          return (record.tax) ? record.tax.taxName : '';
        },
      },
      {
        title: 'Unit Price',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
      },
      {
        title: 'Discount (%)',
        dataIndex: 'discountPercentage',
        key: 'discountPercentage',
      },
      {
        title: 'Discount Price',
        dataIndex: 'discountPrice',
        key: 'discountPrice',
      },
      {
        title: 'Actual Price',
        dataIndex: 'actualPrice',
        key: 'actualPrice',
      },
      {
        title: 'SKU Code',
        dataIndex: 'skuCode',
        key: 'skuCode',
        ...getColumnSearchProps('skuCode')
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive, rowData) => (
          <>
            {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.isActive === value;
        },

      },
      {
        title: 'Action',
        key: 'action',
        render: (value, record: VendorPriceListDto, index) => (
          <Space size="middle">

            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (record.isActive) {
                  EditItem(record, index);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated item Price');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
            <Popconfirm
              onConfirm={e => { inActiveItemInfo(record, index) }}
              title={
                record.isActive
                  ? 'Are you sure to Deactivate Vendors price list ?'
                  : 'Are you sure to Activate Vendors price list?'
              }
            >
              <Switch size="default"
                className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={record.isActive}
              />

            </Popconfirm>
          </Space>
        ),
      },
    ];
    
  

  const inActiveItemInfo = (priceList: VendorPriceListDto, index) => {
    priceList.isActive = priceList.isActive ? false : true;
    const req = new VPLActivateOrDeActiveRequest(priceList.vendorPriceListId, priceList.isActive, priceList.versionFlag, 'updated user')
    vendorPriceListService.ActivateOrDeActivateVPL(req).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        getVendorsPriceList(vendorId);
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveVendorsPriceList = async () => {
    await VendorPriceListForm.validateFields().then(bomRes => {
      if (vendorsPriceListData.length > 0) {
        const vendorPriceDetails: VendorPriceListCreateDto[] = [];
        const vendorData = VendorPriceListForm.getFieldsValue();
        for (const itemsPrice of vendorsPriceListData) {
          const bomData: VendorPriceListCreateDto = {
            itemId: itemsPrice.item.itemId,
            taxId: itemsPrice.tax.taxId,
            unitPrice: itemsPrice.unitPrice,
            discountPercentage: itemsPrice.discountPercentage,
            discountPrice: itemsPrice.discountPrice,
            actualPrice: itemsPrice.actualPrice,
            skuCode: itemsPrice.skuCode,
            createdUser: localStorage.getItem('createdUser'),
            vendorId: vendorData.vendorId,
            isActive: itemsPrice.isActive,
            updatedUser: localStorage.getItem('createdUser'),
            vendorPriceListId: (itemsPrice.vendorPriceListId) ? itemsPrice.vendorPriceListId : null
          }
          vendorPriceDetails.push(bomData);
        }
        const priceLists = new VendorPriceListInfo(inActiveItems, vendorPriceDetails);
        saveData(priceLists);
        VendorPriceListForm.resetFields();
        VendorPriceListItemsForm.resetFields();
        setInActiveItems([]);
        setVendorsPriceListData([]);
      } else {
        AlertMessages.getErrorMessage('Please Fill Details');
      }
    });
  }

  const saveData = (priceLists: VendorPriceListInfo) => {
    vendorPriceListService.CreateVendorPriceList(priceLists).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      } else {
        history.push('/vendorsView')
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const calActualPrice = () => {
    const discount = (VendorPriceListItemsForm.getFieldValue('discountPercentage')) ? VendorPriceListItemsForm.getFieldValue('discountPercentage') : 0;
    const unitPrice = (VendorPriceListItemsForm.getFieldValue('unitPrice')) ? VendorPriceListItemsForm.getFieldValue('unitPrice') : 0;
    const discountAmount = Number(unitPrice * (discount / 100)).toFixed(3);
    const actualPrice = unitPrice-Number(discountAmount);
    VendorPriceListItemsForm.setFieldsValue({ discountPrice: discountAmount, actualPrice:actualPrice })
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Vendors Price List Form</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/vendorsView' >{<span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>}</Link>} >
      <Form layout="vertical" form={VendorPriceListForm} name="control-hooks" >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="vendorId" label="Vendor Name" initialValue={vendorId} rules={[{ required: true, message: 'Vendor is required' }]}>
              <Select
                placeholder="Select Vendor"
                onChange={(vendorId) => getVendorsPriceList(vendorId)}
                allowClear
                disabled={true}

              >
                <Option value={''}>Please Select</Option>
                {vendorsData.map(vendor => {
                  return <Option value={vendor.vendorId}>{vendor.vendorName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form layout="vertical" form={VendorPriceListItemsForm} name="control-hooks" >
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item label="Item" name='item' rules={[{ required: true, message: 'Missing item name' }]} >
              <Select
                disabled={editData}
                showSearch
                allowClear
                placeholder="Select Item"
                onChange={(itemId, value) => setItem(itemId, value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={''}>Please Select</Option>
                {itemsData.map(item => {
                  return <Option key={item.itemId} value={item.itemId}>{item.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Tax Percentage %" name='tax' rules={[{ required: true, message: 'Missing Tax' }]} >
              <Select
                showSearch
                placeholder="Select Tax Percentage %"
                allowClear
                onChange={(taxId, value) => setTax(taxId, value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={''}>Please Select</Option>
                {taxData.map(tax => {
                  return <Option value={tax.taxId}>{tax.taxName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Unit Price" name='unitPrice' rules={[{ required: true, message: 'Missing Unit Price' }]} >
              <InputNumber
                min={0}
                required={true}
                style={{ width: '100%' }}
                onChange={calActualPrice}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Discount(%)" name='discountPercentage' rules={[{ required: true, message: 'Missing Discount Percentage' }]} initialValue={0}>
              <InputNumber
                min={0}
                max={100}
                required={true}
                style={{ width: '100%' }}
                onChange={calActualPrice}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Discount Price" name='discountPrice' rules={[{ required: true, message: 'Missing Discount Price' }]} initialValue={0}>
              <InputNumber
                min={0}
                required={true}
                style={{ width: '100%' }}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Actual Price" name='actualPrice' rules={[{ required: true, message: 'Missing Actual Price' }]} initialValue={0}>
              <InputNumber
                min={0}
                required={true}
                style={{ width: '100%' }}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="SKU Code" name='skuCode' rules={[{ required: true, message: 'Missing SKU Code' }]} >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button onClick={addItems} style={{ marginTop: 31, backgroundColor: 'yellowgreen' }} className='panel_button' icon={<PlusOutlined />}>
              {editData ? 'Update' : 'Add'}
            </Button>
          </Col>
        </Row>
        <Form.Item name='vendorPriceListId'>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='versionFlag'>
          <Input type='hidden' />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Table key={keyUpdate + 1} rowKey={record => record.item.itemId} columns={columns} dataSource={tableData} pagination={false} />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" onClick={saveVendorsPriceList}  >
              {/* <Link to='/saleorder-bom-grid' > */}
              Submit
              {/* </Link> */}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default VendorPriceListForm;
