
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import React, { useState, useEffect } from 'react';
 
export interface CurrentDayStockOutProps { }

export function CurrentDayStockOut(props: CurrentDayStockOutProps) {
    const [stockOutData, setStockOutData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getTodaysStockOutSOs();
    }, [])

    const getTodaysStockOutSOs = () => {
        service.getTodaysStockOutSOs({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setStockOutData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }
    return (
        <div>
            <Card  style={{ padding: '23px', textAlign: 'left', width: 244, height: 63, backgroundColor: '#2e445a', marginBottom: '-3px', color: 'white',marginTop:'21px',borderRadius:'10px',marginLeft:'-49px'  }}><b style={{marginLeft:'-18px'}}>Today Stock Out Cartons :{" " + stockOutData[0]?.jobCartonsCnt?stockOutData[0]?.jobCartonsCnt:"0" }</b></Card>
        </div>
    )
}
export default CurrentDayStockOut;