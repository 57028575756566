import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Divider, Empty, Form, Input, Modal, Popconfirm, Row, Select, Tabs, Tag, Tooltip } from 'antd';
import './asset-transfers-grid.css';
import Table, { ColumnProps } from 'antd/lib/table';
import { EmployeeRolesEnum, TransferStatusEnum } from '@gtpl/shared-models/common-models';
import { CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, StockOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
import { AssetService, AssetTransfersService } from "@gtpl/shared-services/asset-management";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { networkInterfaces } from 'os';
import { DcView } from './dc-view';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import MrnView, { AssetTransferPrint } from './print-view';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';

/* eslint-disable-next-line */
export interface AssetTransfersGridProps {}

export function AssetTransfersGrid(
  props: AssetTransfersGridProps
) {  

  const searchInput = useRef(null);
  const { Option } = Select;
  const [selectedunit, setUnit] = useState<number>(0)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm()
  const [transferDCData, setTransferDCData] = useState<any[]>([]);
  const [transferMRNData, setTransferMRNData] = useState<any[]>([]);
  const [defaultTab, setDefaultTab] = useState<string>('0');
  const [disable, setDisable] = useState<boolean>(false);
  const [printType, setPrintType] = useState('');
  const [selectedLogId, setSelectedRowId] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const role = JSON.parse(localStorage.getItem('role'))
  const [plantName, setPlantName] = useState<any>([]);
  const plantsService = new UnitcodeService();


  const { TabPane } = Tabs;

  const assetTransfersService = new AssetTransfersService();


  useEffect(() => {
    getTransferDCData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plant: Number(localStorage.getItem('unit_id')) })
    }
    getAllPlants();
    getTransferMRNData();
  }, []);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const getTransferDCData = () => {
    const req = new LocationByPlant();
    
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plant = Number(localStorage.getItem('unit_id'));
    } else {
      req.plant = form.getFieldValue('plant')
    }
    console.log(req.plant)
    assetTransfersService.getDCTransfers(req).then(res => {
      if (res.status) {
        setTransferDCData(res.data);
      } else {
        setTransferDCData([]);
      }
    }).catch(err => {
      setTransferDCData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getTransferMRNData = () => {
    const req = new PlantIdRequest(Number(localStorage.getItem('unit_id')));
    assetTransfersService.getMRNTransfers(req).then(res => {
      if (res.status) {
        setTransferMRNData(res.data);
      } else {
        setTransferMRNData([]);
      }
    }).catch(err => {
      setTransferMRNData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const ReceiveStock = (rowData) => {
    setDisable(true);
    assetTransfersService.ReceiveAsset({assetTransferId:rowData.assetTransferId,updatedUser:localStorage.getItem("username"),receivedDate:new Date(),updatedUserId:Number(localStorage.getItem("userid"))}).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTransferMRNData([]);
        getTransferMRNData();
      } else {
        AlertMessages.getErrorMessage(res.message);
      }
      setDisable(false);
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


const tableColumns = (key) => {
  console.log(key);
  const series: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
  ]
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Transfer Date',
      dataIndex: 'transferedDate,',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferedDate,).unix() - moment(b.transferedDate,).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferedDate,).format('YYYY-MM-DD') },

      // ...getColumnSearchProps('transferredDate')
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferType',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferType.localeCompare(b.transferType),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return record.transferType === "INTERNAL"?"Plant to Plant":"" },
      ...getColumnSearchProps('transferType')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'Asset Category',
      dataIndex: 'itemSubCategory',
      sorter: (a, b) => a.itemSubCategory.localeCompare(b.itemSubCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategory')
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetCode',
      sorter: (a, b) => a.assetCode.localeCompare(b.assetCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCode')
    },
    {
      title: 'Asset',
      dataIndex: 'assetName',
      align:'left',
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetName')
    },
  ]
  const MRN: ColumnProps<any>[] = [
    {
      title: 'MRN Number',
      dataIndex: 'mrnNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.mrnNumber.localeCompare(b.mrnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mrnNumber')
    },
  ]
  const fromPlant: ColumnProps<any>[] = [
    {
      title: 'From Plant',
      dataIndex: 'fromPlant',
      align:'center',
      // filters: [
      //   {
      //     text: 'Unit 1',
      //     value: 'Unit 1',
      //   },
      //   {
      //     text: 'Unit 2',
      //     value: 'Unit 2',
      //   },
      //   {
      //     text: 'Kakinada',
      //     value: 'Kakinada',
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   return record.fromPlant == value;
      // },
    }
  ]
  const toPlant: ColumnProps<any>[] = [
    {
      title: 'Plant',
      dataIndex: 'plant',
      sorter: (a, b) => String(a.plant)?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),

    },
    {
      title: 'To Plant',
      dataIndex: 'toPlant',
      align:'center',
      ...getColumnSearchProps('toPlant')

      // filters: [
      //   {F
      //     text: 'Unit 1',
      //     value: 'Unit 1',
      //   },
      //   {
      //     text: 'Unit 2',
      //     value: 'Unit 2',
      //   },
      //   {
      //     text: 'Kakinada',
      //     value: 'Kakinada',
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   return record.toPlant == value;
      // },
    },   
  ]
  const status: ColumnProps<any>[] = [
    {
      title: 'Status',
      dataIndex: 'status',
      align:'center',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<StockOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },
  ]
  const MRNaction: ColumnProps<any>[] = [
    {
      title: `Action`,
      dataIndex: 'action',
      align:'center',
      render: (text, rowData) => (
        <span>
          {((rowData.status === TransferStatusEnum.IN_TRANSIT) ?
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
              ReceiveStock(rowData);
            }}>
              <Tooltip placement="top" title='Receive'><StockOutlined disabled={disable} className={'receive'} type="Receive" name="Receive"
                style={{ color: 'red', fontSize: '14px' }}
              /> </Tooltip>
            </Popconfirm>
            : <Tooltip placement="top" title='Received'><StockOutlined style={{ color: 'lightgreen', fontSize: '14px' }} type="Received" name="Received" /></Tooltip>)}
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.assetTransferId, 'MRN');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
        </span>
      )
    }
  ]
  const DCaction: ColumnProps<any>[] = [
    {
      title: `Action`,
      dataIndex: 'action',
      align:'center',
      render: (text, rowData) => (
        <span>
          {/* {((rowData.status === TransferStatusEnum.IN_TRANSIT) ?
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
              ReceiveStock(rowData);
            }}>
              <Tooltip placement="top" title='Receive'><StockOutlined disabled={disable} className={'receive'} type="Receive" name="Receive"
                style={{ color: 'red', fontSize: '14px' }}
              /> </Tooltip>
            </Popconfirm>
            : <Tooltip placement="top" title='Received'><StockOutlined style={{ color: 'lightgreen', fontSize: '14px' }} type="Received" name="Received" /></Tooltip>)}
          <Divider type="vertical" /> */}
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.assetTransferId, 'DC');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />

             <Divider type="vertical" />
            <Button type='primary' shape='round'  onClick={() => {
              openPrint(rowData.assetTransferId, 'Gate Pass');
            }}
          >
           Gate Pass</Button>
        </span>
      )
    }
  ]
  if (key === "1") {
    return [...series, ...toPlant,...columnsSkelton, ...status, ...DCaction];
  }
  else if (key === "2") {
    return [...series, ...fromPlant, ...columnsSkelton, ...MRN, ...status, ...MRNaction];
  }
}
  
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }
  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openPrint = (logId, printType) => {
    setSelectedRowId(logId)
    setIsModalVisible(true);
    setPrintType(printType);
  }

  const handleUnit = (value) => {
    setUnit(value);
    getTransferDCData();
    // getAllAssetLocation()
  }
  
  return (
    <Card
      title={<span style={{ color: 'white' }}>Asset Transfers</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={<Link to='/asset-transfers'><span style={{ color: 'white' }}><Button className='panel_button'>Transfer </Button> </span></Link>}
    >
        <Form form={form}>
        <Row gutter={40}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="plant"
              label="Plant"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
              // initialValue={Number(localStorage.getItem("unit_id"))}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}


              >
                {plantName.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
        <TabPane
          key="1"
          tab={<span style={{ color: "#f5222d" }}>DC: {transferDCData.length}</span>}>
          {transferDCData?.length ?
            <Table
              columns={tableColumns("1")}
              dataSource={transferDCData}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              size="middle"
              bordered /> : <Empty />}
        </TabPane>
        <TabPane key="2" tab={<span style={{ color: "#f5222d" }}>MRN: {transferMRNData.length}</span>} >
          {transferMRNData?.length ?
            <Table
              columns={tableColumns("2")}
              dataSource={transferMRNData}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              // scroll={{ y:500 }}
              size="middle"
              bordered /> : <Empty />}
        </TabPane>
      </Tabs>
      <Modal
          className='print-docket-modal'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          {<AssetTransferPrint assetTransferId={selectedLogId} printOrder={printOrder} printType={printType} />}
        </Modal>
    </Card>
  );
}

export default AssetTransfersGrid;
