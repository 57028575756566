import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col  } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
// import { EmployeeCode, EmployeeName } from '@gtpl/shared-models/hrms-employee-details-form'
import './job-notifications-form.css'; 
import {JobNotificationDto} from '@gtpl/shared-models/hrms';
import {jobNotificationResponse} from '@gtpl/shared-models/hrms';
import {jobNotificationStatsService} from '@gtpl/shared-services/hrms';

const { TextArea } = Input;

export interface JobNotificationsFormProps {
  jobNotificationData : JobNotificationDto;
  updatejobnotificationType: (  jobNotificationData : JobNotificationDto)=> void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function JobNotificationsForm(props: JobNotificationsFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  const jobnotificationStatsService = new jobNotificationStatsService()
  const createjobNotification = (jobNotificationData : JobNotificationDto)=> {
    jobnotificationStatsService.create(jobNotificationData).then(res=>{
      if(res.status){
        AlertMessages.getSuccessMessage('sucessfully');
        history.push("//job-notifications-view");
        onReset();
      }
      else{
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err=>{
      AlertMessages.getErrorMessage(err.message);
    })
    }
    const onReset = ()=>{
      form.resetFields();
    }
    const saveData =( values : JobNotificationDto) => {
      if (props.isUpdate){
        console.log(values)
        props.updatejobnotificationType(values);
      }
      else {
        console.log(values)
        createjobNotification(values);
      }
    };
  
  

  return (
  

    <Card title={<span style={{color:'white'}}>Job Notification </span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/job-notifications-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form}        
           name="control-hooks"          
          layout="vertical"
          onFinish={saveData}
          >
    
          <Form.Item name="JobNotificationsId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="Department"
                      label="Department"
                      rules={[
                        {
                          required: true,
                          message: `Missing Department`,
                        },
                      ]}
                      >
                        <Input/> 
                      
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="Job_Notification"
                      label="Job_Notification"
                      rules={[
                        {
                          required: true,
                          message: `Missing Job Notification`,
                        },
                      ]}
                      >
                        <Input/> 
                      
                    </Form.Item>
            </Col> 
                                  
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate !== true ) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
      );
}

export default JobNotificationsForm;
