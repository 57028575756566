import React from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import {Link} from 'react-router-dom';
import './pages-asset-management-asset-management-components-asset-po-form.css';
import {AssetCatagoryService} from '../../../../../../shared-services/asset-management/src/lib/asset-category';
import {AssetsCatagoryDto} from '../../../../../../shared-models/asset-management/src/lib/assets-catagory/assets-catagory-dto';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

/* eslint-disable-next-line */
export interface AssetCategoryFormProps {
  isUpdate: boolean;
}

export function AssetCategoryForm(
  props: AssetCategoryFormProps
) {
  let createdUser = "";
  const service = new AssetCatagoryService();

  const onFinish = (values) => {
    // const req = new AssetsCatagoryDto(values);
    service.createAssetsCatagory(values).then(
      res => {
        if(res.status) {
          AlertMessages.getSuccessMessage('Created Successfully');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    )
  }
  return (
    <Card
      title={<span style={{ color: "white" }}>Asset Category</span>}
      style={{ textAlign: "center" }}
      headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      extra={
        props.isUpdate == true ? (
          ""
        ) : (
           <Link to="/asset-category">
          <span style={{ color: "white" }}>
            <Button>View </Button>{" "}
          </span>
           </Link>
        )
      }
    >
      <Form onFinish={onFinish}>
        <Row gutter={20}>
          <Col >
            <Form.Item name="asset_category_id" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="created_user" initialValue={createdUser}>
              <Input hidden />
            </Form.Item>
            </Col>
            <Col>
            <Form.Item
              name="assetsCatagoryName"
              label="Category Name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button htmlType='submit' type="primary">Submit</Button>
            {[] && <Button>Reset</Button>}
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AssetCategoryForm;
