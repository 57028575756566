import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

interface IAssetCategoryWiseDownTimeProps {
    assetsData: any;
}

const AssetCategoryWiseDownTime = (props: IAssetCategoryWiseDownTimeProps) => {
    const { assetsData } = props;

    if (!Array.isArray(assetsData)) {
        console.error('assetsData is not an array:', assetsData);
        return null;
    }

    const categories = assetsData.map(asset => asset?.assetCategory || 'Unknown');
    const affectedAssetsData = assetsData.map(asset => asset?.assetDownTimeDetails?.affectedAssetsCount || 0);

    

    const config = {
        colors: ["#161B40", "#EFA500"],
        chart: {
            type: 'bar',
            backgroundColor: 'white'
        },
        title: {
            text: 'Asset Category Wise Downtime',
            style: {
                color: "#000000",
                fontSize: "20px",
                fontFamily: "Arial, sans-serif"
            }
        },
        xAxis: {
            categories: categories,
            labels: {
                style: {
                    color: 'black'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count',
                style: {
                    color: 'black'
                }
            },
            labels: {
                style: {
                    color: 'black'
                }
            }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let tooltip = `<b>${this.x}</b><br/>`;
                this.points.forEach(point => {
                    tooltip += `${point.series.name}: ${point.y}<br/>`;
                });
                return tooltip;
            }
        },
        series: [
            {
                name: 'Affected Assets Count',
                data: affectedAssetsData
            }
        ]
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
    );
};

export default AssetCategoryWiseDownTime;
