import React, { useEffect, useRef, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined, EditOutlined, RightSquareOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Table, Drawer, Popconfirm, Switch, Tag, Input, Button, Card } from 'antd';
import './rm-tolerance-grid.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { RmToleranceForm } from '@gtpl/pages/master/master-components/rm-tolerance-form';
import { RmToleranceDto } from '@gtpl/shared-models/raw-material-procurement';
import { RmToleranceService } from '@gtpl/shared-services/masters';
import moment from 'moment';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface RmToleranceGridProps { }

export function RmToleranceGrid(
  props: RmToleranceGridProps
) {
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRmToleranceData, setSelectedRmToleranceData] = useState<any>(undefined);
  const [rmToleranceData, setRmToleranceData] = useState<RmToleranceDto[]>([]);
  const rmToleranceService = new RmToleranceService();



  useEffect(() => {
    getAll();
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const getAll = () => {
    rmToleranceService.getAll(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setRmToleranceData(res.data);
      } else {
        if (res.intlCode) {
          setRmToleranceData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRmToleranceData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteRmTolerance = (Data: RmToleranceDto) => {
    Data.isActive = Data.isActive ? false : true;
    rmToleranceService.activatedeActivate(Data).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateRmTolerance = (Data: RmToleranceDto) => {
    Data.updatedUser=localStorage.getItem('username')
    rmToleranceService.update(Data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const openFormWithData = (viewData: RmToleranceDto) => {
    setDrawerVisible(true);
    viewData.date=moment(viewData.date, 'YYYY-MM-DD')
    setSelectedRmToleranceData(viewData);

  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Date',
      dataIndex: 'date',
      responsive: ['sm'],

      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],

      render: (value, record: RmToleranceDto) => {
        return moment(record.date).format('DD-MM-YYYY')
      }
    },
    {
      title: 'Loose Shell',
      dataIndex: 'looseShell',
      //  responsive: ['lg'],
      sorter: (a, b) => a.looseShell.localeCompare(b.looseShell),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('looseShell')
    },
    {
      title: 'Soft Shell',
      dataIndex: 'softShell',
      // responsive: ['lg'],
      sorter: (a, b) => a.softShell?.localeCompare(b.softShell),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('softShell')
    },

    {
      title: 'Fungus',
      dataIndex: 'fungus',
      // responsive: ['lg'],
      sorter: (a, b) => a.fungus.localeCompare(b.fungus),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fungus')
    },

    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Rm Tolerance');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteRmTolerance(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate  ?'
                : 'Are you sure to Activate  ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Rm Tolerance</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to="/rm-tolerance-form"  ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <br></br>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Card title={'Total Rm Tolerances: ' + rmToleranceData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + rmToleranceData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + rmToleranceData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.productId}
        columns={columnsSkelton}
        dataSource={rmToleranceData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        scroll={{ x: true }}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <RmToleranceForm key={Date.now()}
            updateRmTolerance={updateRmTolerance}
            isUpdate={true}
            rmToleranceData={selectedRmToleranceData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default RmToleranceGrid;
