import { AssetService } from '@gtpl/shared-services/asset-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Col, Form, Row } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react'

const AgeWiseInstockAssets = () => {
    useEffect(() => {
        getAgeWiseAssets();
    }, [])
    const [assetsData, setAssetsData] = useState<any[]>([]);
    
    const getAgeWiseAssets = () => {
        service.getAgeWiseAssets().then(res => {
            if (res.status) {
                setAssetsData(res.data);
            } else {
                if (res.intlCode) {
                    setAssetsData([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setAssetsData([]);
        })

    }
    const plantname = assetsData.map(i => { return i.plantName });
    const last3months = assetsData.map(i => { return Number(i.last3months) });
    const threeTo6months = assetsData.map(i => { return Number(i.threeTo6months) });
    const sixTo12months = assetsData.map(i => { return Number(i.sixTo12months) });
    const yearbefore = assetsData.map(i => { return Number(i.yearbefore) });
    const purchasedButNotAssigned = assetsData.map(i => { return Number(i.purchasedButNotAssigned) });

    const service = new AssetService();

    const config = {
        colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
        chart: {
            type: 'column',
            backgroundColor: 'white'
        },

        title: {
            text: 'Age Wise Instock Assets',
            style: {
                color: 'black',
                fontSize: '20px',
                lineHeight: '1.4',
                marginBottom: '0',
                overflow: 'hidden',
                // paddingTop: '2px',
                paddingTop: 'calc(2px*var(--scale-factor, 1))',
                position: 'relative',
                textOverFlow: 'ellipsis',
                whiteSpace: 'nowrap',
                zIndex: '5',
                fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
            }
        },

        xAxis: {
            categories: plantname,
            labels: {
                style: {
                    color: 'black'
                }
            }
        },

        yAxis: {
            allowDecimals: false,
            min: 0,
            // tickAmount: 16,
            // tickPixelInterval: 100,
            title: {
                text: 'Number of Instock Assets'
            },
            enabled: true,
            style: {
                color: 'black',
                fontSize: '1.15rem',
                lineHeight: '1.4',
                marginBottom: '0',
                overflow: 'hidden',
                // paddingTop: '2px',
                paddingTop: 'calc(2px*var(--scale-factor, 1))',
                position: 'relative',
                textOverFlow: 'ellipsis',
                whiteSpace: 'nowrap',
                zIndex: '5',
                fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
            },
            labels: {
                style: {
                    color: 'black'
                }
            }
        },

        tooltip: {
            formatter: function () {
                return '<b>' + this.x + '</b><br/>' + 'No.of Assets: ' + this.y + '<br/>'

            }
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },

        series: [
            // {
            //   name: 'Purchased But Not Assigned',
            //   data: purchasedButNotAssigned,
            //   // stack: 'male'
            // },
            {
                name: 'Last3 M',
                data: last3months,
                // stack: 'male'
            },
            {
                name: '3to6 M',
                data: threeTo6months,
                // stack: 'male'
            },
            {
                name: '6to12 M',
                data: sixTo12months,
                // stack: 'male'
            },
            {
                name: 'Above 1Yr',
                data: yearbefore,
                // stack: 'male'
            },
        ]
    }
    return (
        <div>
            <Card style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px'
            }}>
                <Form layout="vertical" name="control-hooks">
                    <Row gutter={24}>
                        <Col span={24}>
                            <div><HighchartsReact highcharts={Highcharts} options={config} /></div>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )
}

export default AgeWiseInstockAssets;