import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker } from 'antd';
import './container-register.css';
import { Link, Redirect,useLocation } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';

/* eslint-disable-next-line */
export interface ContainerRegisterProps { }
const { Option } = Select;
export function ContainerRegister(
  props: ContainerRegisterProps
) {

  let location = useLocation();
  const containerStatusInfo: ContainerStatusInfo | any = location.state;
  const [containerRegisterForm] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);

  const SoService = new SaleOrderService();
  const containerRService = new ContainerRegisterService();

  useEffect(() => {
    getSaleOrderData();
    if (containerStatusInfo) {
      containerRegisterForm.setFieldsValue({
          saleOrderId: containerStatusInfo.saleOrder.saleOrderId,
          containerStatus: ContainerStatusEnum.BOOKED,
          containerId:containerStatusInfo.containerId,
          containerNo:containerStatusInfo.containerNo
      });
  } else {
      containerRegisterForm.setFieldsValue({
          containerStatus: ContainerStatusEnum.BOOKED
      });
  }
}, [containerStatusInfo]);

  // get sale order drop down details
  const getSaleOrderData = () => {
    SoService.getSaleOrdersDropDownList().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const onReset = () => {
    containerRegisterForm.resetFields();
  }

  const saveData = () => {
      containerRService.createContainer().then(res => {
        // if (!res.status) {
        //   throw new Error(res.internalMessage);
        // }else{
        //   AlertMessages.getSuccessMessage(res.internalMessage);
        // }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Container Register</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/container-register-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form layout="vertical" form={containerRegisterForm} name="control-hooks" onFinish={saveData} >
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  >
            <Form.Item
              name="loadingDate"
              label="Loading Date"
              rules={[{ required: true, message: 'Missing Loading Date' },
              ]} initialValue={moment()}>

              <DatePicker style={{ width: '100%' }} />

            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Container Number" name="containerNo" rules={[{ required: true, message: 'Missing Container Number' }]} >
              <Input disabled/>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrderId" label="Sale Order" >
              <Select
                placeholder="Select SaleOrder"
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Line Seal" name="lineSeal">
              <Input />
            </Form.Item>

          </Col>



        </Row>

        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item label="E-Seal" name="eSeal">
              <Input />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Vehicle Number" name="vehicleNumber">
              <Input />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Driver Name" name="driverName">
              <Input />
            </Form.Item>

          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Driver Phone Number" name="driverPhoneNumber">
              <Input />
            </Form.Item>

          </Col>

        </Row>

        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item label="Assigned By" name="assignedBy">
              <Input />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="containerStatus"
                  label="Container Status"
                  rules={[
                    {
                      required: true,message: 'Missed Container Status'
                    },
                  ]}>
                    <Select
                    placeholder="Select Container Status"
                    >
                    <Option key={0} value={null}>Select Container Status</Option>
                    {ContainerStatusInput.map((container) => {
                      return <Option key={container.id} value={container.id}>{container.status}</Option>
                    })}
                    </Select>
                </Form.Item>
          </Col>
          <Col style={{ paddingTop: '30px' }}>

            <Button type="primary" htmlType="submit" >
              Submit
              </Button>
              {(isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ContainerRegister;
