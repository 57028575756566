import React, { useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Form, Input, Row} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {HolidaysCalendarDto} from '@gtpl/shared-models/hrms';
import { HolidaysCalendarService } from '@gtpl/shared-services/hrms';

import './holidays-calender.css';
import moment from 'moment';

/* eslint-disable-next-line */

export interface HolidaysCalenderProps {
  holidaysCalendarData: HolidaysCalendarDto;
  updateHoliday: (holidayName: HolidaysCalendarDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function HolidaysCalender(
  props: HolidaysCalenderProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const holidaysCalendarService = new HolidaysCalendarService()
  const dateFormat = "YYYY-MM-DD";
  const today = moment();
  const [disable, setDisable] = useState<boolean>(false)

  useEffect(() => {
    if(props.holidaysCalendarData){
      console.log(props.holidaysCalendarData)
    }
  }, [props.holidaysCalendarData])
  
  let createdUser = "";

  if(!props.isUpdate){
    createdUser = localStorage.getItem("createdUser");
  }

  const createHoliday = (Data: HolidaysCalendarDto) => {
    setDisable(true)
    holidaysCalendarService.createHoliday(Data).then(res => {
      setDisable(false)
      if(res.status) {
        AlertMessages.getSuccessMessage('Holiday created successfully');
        history.push('/holidays-calendar-view');
        onReset();
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset=()=>{
    console.log('');
    form.resetFields();
     }
    const saveData = (values: HolidaysCalendarDto) => {
      setDisable(false)
      if(props.isUpdate){
        console.log(values);
        props.updateHoliday(values);
      }else {
        setDisable(false)
        console.log(values);
        createHoliday(values);
      }
    };
  return (
   
      <Card title={<span style={{color:'white'}}>Holidays Calendar</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/holidays-calendar-view' ><Button className='panel_button' >View </Button></Link>}
    >
    <Form layout = "vertical" form={form} onFinish={saveData} initialValues={props.holidaysCalendarData}>
        <Form.Item 
        name ="holidayId" style={{display:"none"}} >
          <Input hidden/>
        </Form.Item>
        <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
      <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                name="holidayName"
                label="Holiday Name"
                rules={[
                  {
                    required: true,
                    message:"Enter valid Holiday Name."
                  },
                  {
                    pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                    message: `Should contain only alphabets.`
                  }
                ]}>
                <Input/>
          </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                  name="holidayDate" 
                  label="Holiday Date" rules={[{ required: true }]} >
                 <DatePicker onChange={(val) => { moment(val).format('YYYY-MM-DD') }} style={{ width: '100%' }} />
                 
          </Form.Item>
        </Col>
        {/* <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
          <Form.Item
                 name="holidayYear" label="Holiday Year" rules={[{ required: true }]} >
                <DatePicker onChange={(val) => { moment(val).format('YYYY') }} style={{ width: '100%' }} picker={'year'} />
                
          </Form.Item>
        </Col> */}
      </Row>
   
      
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>

    </Form>
   </Card>
  );
}
 


export default HolidaysCalender;
