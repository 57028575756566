import React,{ useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import{DownTimeReasonDto,DowntimeReasonDefault}from '@gtpl/shared-models/masters';
import{DowntimeReasonService}  from '@gtpl/shared-services/masters';
import { Link, Redirect } from 'react-router-dom';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import './downtime-reason-grid.css';
import { DowntimeReasonForm} from '@gtpl/pages/master/master-components/downtime-reason-form';


/* eslint-disable-next-line */
export interface DowntimeReasonGridProps {}

export function DowntimeReasonGrid(
  props: DowntimeReasonGridProps){

    const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [defaultData, setDefaultData] = useState<Countries>(new Countries(null,null,null));
  const [downtimereaasonData, setDownTimeReasonData] = useState<DownTimeReasonDto[]>([]);
  const [selectedDownTimeReason, setSelectedDownTimeReason] = useState<any>(DowntimeReasonDefault);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new DowntimeReasonService;

   /**
   * used for column filter
   * @param dataIndex column data index
   */
    const getColumnSearchProps = (dataIndex:string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={ searchInput }
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex]
         .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {    setTimeout(() => searchInput.current.select());   }
      },
      render: text =>
        text ?(
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) :text
        )
        : null
       
    });
  
    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'DownTime Reason',
        dataIndex: 'downtimeReason',
        // responsive: ['lg'],
        sorter: (a, b) => a.downtimeReason.localeCompare(b.downtimeReason),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('downtimeReason')
      },
      {
        title: 'SLA',
        dataIndex: 'sla',
        width:'90px',
        align:'right',
        sorter: (a, b) => a.sla - b.sla,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('sla')
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        align:'center',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.isActive === value;
        },
        
      },
      {
        title:`Action`,
        dataIndex: 'action',
        render: (text, rowData) => (
          <span>         
              <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                onClick={() => {
                  if (rowData.isActive) {
                    openFormWithData(rowData);
                  } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated DownTimeReason');
                  }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            
            <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteDownTimeReason(rowData);}}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate DownTimeReason ?'
                  :  'Are you sure to Activate DownTimeReason ?'
              }
            >
              <Switch  size="default"
                  className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                  checkedChildren={<RightSquareOutlined type="check" />}
                  unCheckedChildren={<RightSquareOutlined type="close" />}
                  checked={rowData.isActive}
                />
              
            </Popconfirm>
          </span>
        )
      }
    ];

/**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
 const onChange=(pagination, filters, sorter, extra)=> {
  console.log('params', pagination, filters, sorter, extra);
}
useEffect(() => {getAllDownTimeReason();}, [])
const getAllDownTimeReason= () => {
  service.getAllDownTimeReason().then(res => {
    if (res.status) {setDownTimeReasonData(res.data);
    } else {
      if (res.intlCode) {
        setDownTimeReasonData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
      } else {
       AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setDownTimeReasonData([]);
  })
}

//drawer related
const closeDrawer=()=>{
  setDrawerVisible(false);
}

//TO open the form for updation
const openFormWithData=(DownTimeReasonViewData: DownTimeReasonDto)=>{
  console.log(DownTimeReasonViewData);
  setDrawerVisible(true);
  setSelectedDownTimeReason(DownTimeReasonViewData);
}

const saveDownTimeReason = (downtimereasonData: DownTimeReasonDto) => {
  downtimereasonData.downtimereasonId=0;
  downtimereasonData.isActive=true;
  service.createDownTimeReason(downtimereasonData).then(res => {
    if (res.status) {
      AlertMessages.getSuccessMessage('DownTimeTracking Created Successfully');
      getAllDownTimeReason();
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}

 /**
     * 
     * @param variantData 
     */
  const updateDownTimeReason = (downtimereasonData: DownTimeReasonDto) => {
    service.updateDownTimeReason(downtimereasonData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllDownTimeReason();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
     * 
     * @param VariantViewData 
     */
    const deleteDownTimeReason = (downtimereasonViewData:DownTimeReasonDto) => {
      downtimereasonViewData.isActive=downtimereasonViewData.isActive?false:true;
      service.ActivatedeOrActivateDownTimeReason(downtimereasonViewData).then(res => { console.log(res);
        if (res.status) {
          getAllDownTimeReason();
          AlertMessages.getSuccessMessage('Success'); 
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

    return (
      <Card title={<span style={{color:'white'}}>DownTime Reasons</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/DowntimeReason-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
       <Row gutter={40}>
        <Col>
            <Card title={'Total Reasons: ' + downtimereaasonData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
            </Col>
            <Col>
             <Card title={'Active: ' + downtimereaasonData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
            </Col>
            <Col>
             <Card title={'In-Active: ' + downtimereaasonData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
            </Col>
            </Row><br></br>
          <Card >
        {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
        
          <Table
            rowKey={record => record.downtimereasonId}
            columns={columnsSkelton}
            dataSource={downtimereaasonData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll = {{x:true}}
            onChange={onChange}
            size='small'
            bordered />
          </Card>
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                <DowntimeReasonForm key={Date.now()}
                  updateItem={updateDownTimeReason}
                  isUpdate={true}
                  // saveItem={saveVariant}
                  downtimereasonData={selectedDownTimeReason}
                  closeForm={closeDrawer} />
              </Card>
            </Drawer>
          </Card>
    );

}

export default DowntimeReasonGrid;
