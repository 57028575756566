import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import { Highlinereq } from "@gtpl/shared-models/common-models";
import { request } from "http";



export interface Highlineprps{
  highlineData:Highlinereq;
}

const Highline=(props:Highlineprps)=>{
  const {highlineData} = props
  const title = highlineData.headerTitle
  const category = highlineData.categories
  console.log(category);
  const subTitle = highlineData.subTitles
  const format = highlineData.format
  const series = highlineData.seriesDetails.map((seriesObj) => {
    return {
      name: seriesObj.seriesDetails.map(ser => ser.title)[0],
      data: seriesObj.seriesDetails.map(ser => Number(ser.value))
    }
  });
  console.log(series);
  
    

    const config = {
      colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
        '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
        title: {
            text: title,
            style: {
              color: 'var(--text-color,#eee)',
              fontSize: '1.75rem',
              lineHeight: '1.9',
              marginBottom: '0',
              overflow: 'hidden',
              // paddingTop: '2px',
              paddingTop: 'calc(2px*var(--scale-factor, 1))',
              position: 'relative',
              textOverFlow: 'ellipsis',
              whiteSpace: 'nowrap',
              zIndex: '5',
              fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
            }
          },
        
          subtitle: {
            text: subTitle,
            style: {
              color: 'var(--text-color,#eee)',
              textTransform: 'uppercase'
            }
          },
          
          chart: {
            backgroundColor: '#272953'
          },
        
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
          },

          xAxis: {
            categories: category,
            labels: {
              style: {
                color: 'var(--text-color,#eee)'
              }
            }
          },
          yAxis: { 
            labels: {
                format: format,
                style: {
                  color: 'var(--text-color,#eee)'
                }
            }
        },
        
          plotOptions: {
            line: {
              label: {
                connectorAllowed: false,
                enabled: true
              },
              
            }
          },
        
          series: series,
        
          responsive: {
            rules: [{
              condition: {
                maxWidth: 2000,
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                }
              }
            }]
          }
        
        }

   return(
           
        <HighchartsReact highcharts={Highcharts} options={config} />
        
        
    )
}

export default Highline;