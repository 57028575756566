import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Redirect } from 'react-router-dom';
import './insurance-grid.css';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {InsuranceForm} from '@gtpl/pages/master/master-components/insurance-form'
import { InsuranceService } from '@gtpl/shared-services/masters';
import { InsuranceDto } from '@gtpl/shared-models/masters';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface InsuranceGridProps {}

export function InsuranceGrid(
  props: InsuranceGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [insuranceData, setInsuranceData] = useState<InsuranceDto[]>([]);
  const [selectedInsuranceData, setSelectedInsuranceData] = useState<any>(undefined);
  const insuranceService = new InsuranceService();

  useEffect(() => {
    getAllInsurances();
  }, []);

  /**
   * 
   */
  const getAllInsurances= () => {
    insuranceService.getAllInsurances(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        console.log(res.data);
        setInsuranceData(res.data);
      } else {
        if (res.intlCode) {
            setInsuranceData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setInsuranceData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const deleteInsurance = (insuranceDto:InsuranceDto) => {
    insuranceDto.isActive=insuranceDto.isActive?false:true;
      insuranceDto.updatedUser = localStorage.getItem("createdUser");
      insuranceService.activateOrDeactivateInsurance({insuranceId:insuranceDto.insuranceId,isActive:insuranceDto.isActive,updatedUser:insuranceDto.updatedUser,versionFlag:insuranceDto.versionFlag}).then(res => { console.log(res);
      if (res.status) {
        getAllInsurances();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    /**
     * 
     * @param insuranceDto 
     */
    const updateInsurance = (insuranceDto: InsuranceDto) => {
      // insuranceDto.updatedUser= JSON.parse(localStorage.getItem('username'))
      insuranceDto.updatedUser = localStorage.getItem("createdUser");
  console.log(insuranceDto.updatedUser)
      insuranceService.updateInsurance(insuranceDto).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllInsurances();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    const openFormWithData=(viewData: any)=>{
      setDrawerVisible(true);
      setSelectedInsuranceData(viewData);
      console.log(selectedInsuranceData)
      console.log('selectedInsuranceData')
    }
   
    /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        // responsive: ['lg'],
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('companyName')
      }
      ,
      {
        title: 'Email',
        dataIndex: 'emailId',
        // responsive: ['lg'],
        sorter: (a, b) => a.emailId.localeCompare(b.emailId),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('emailId')
      }
      ,
      {
        title: 'Mobile No',
        dataIndex: 'mobileNumber',
        // responsive: ['lg'],
        sorter: (a, b) => a.mobileNumber.length - b.mobileNumber.length,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('mobileNumber')
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
            
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.isActive === value;
        },
        
      },
      {
        title:`Action`,
        dataIndex: 'action',
        render: (text, rowData) => (
          <span>         
              <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
                onClick={() => {
                  if (rowData.isActive) {
                    openFormWithData(rowData);
                  } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated Insurance Details');
                  }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            
            <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteInsurance(rowData);}}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate Insurance Details ?'
                  :  'Are you sure to Activate Insurance Details ?'
              }
            >
              <Switch  size="default"
                  className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                  checkedChildren={<RightSquareOutlined type="check" />}
                  unCheckedChildren={<RightSquareOutlined type="close" />}
                  checked={rowData.isActive}
                />
              
            </Popconfirm>
          </span>
        )
      }
    ];
  
    /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
    const OpenFormTocreateRecord = () => {
     console.log('redirect here');
     
  
  
    }
  return (
    <Card title={<span style={{color:'white'}}>Insurance Details</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/insurance-form' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40} >
      
          <Card title={'Total Insurance Details: ' + insuranceData.length} style={{textAlign: 'left', width: 300, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          
           <Card title={'Active: ' + insuranceData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          
           <Card title={'In-Active: ' + insuranceData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          
          </Row>
          <br></br>
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          scroll={{x:true}}
          dataSource={insuranceData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <InsuranceForm key={Date.now()}
                updateInsurance={updateInsurance}
                isUpdate={true}
                insuranceData={selectedInsuranceData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default InsuranceGrid;
