import React, { useState, useEffect, useRef } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button, Row, Col, Drawer, Tag, } from 'antd';
import Highlighter from 'react-highlight-words';
import {HsnCodesForm} from '@gtpl/pages/master/master-components/hsn-codes-form';
import {HsnService} from '@gtpl/shared-services/masters'
import {  HsnDto } from '@gtpl/shared-models/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {ProductService} from '@gtpl/shared-services/masters';
import { ProductDto } from '@gtpl/shared-models/masters';

import './hsn-codes-grid.css';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface HsnCodesGridProps {}

export function HsnCodesGrid(
  props: HsnCodesGridProps
) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const [hsnData, setSkuData] = useState<HsnDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [selectedHsnData, setSelectedHsnData] = useState<any>(undefined);
  const activeProducts =  new ProductService();
  const service = new HsnService;
  const [productsData, setProductsData] = useState<ProductDto[]>([]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    getAllHsn();
    getAllActiveProducts();
  }, []);



  const getAllActiveProducts = () => {
    activeProducts.getAllActiveProducts().then((res) => {
      if (res.status) {
        setProductsData(res.data);
        let filtersArray:{text:string,value:string}[] = []
        res.data.map(item => {
          if(!(filtersArray.includes({text:item.productName,value:item.productName})))
          filtersArray.push({text:item.productName,value:item.productName})
        })
        setFiltersData(filtersArray)

        console.log(res.data)
        // console.log(getProductFilters())
      } else {
        setProductsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    setProductsData([]);
    });
  }

  const getAllHsn= () => {
  service.getAllHsn(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setSkuData(res.data);
        console.log(res);
      } else {
        if (res.intlCode) {
          setSkuData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSkuData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }
/**
   * 
   * @param SkuData 
   */
  const deleteHsn = (hsnDto:HsnDto) => {
    hsnDto.isActive=hsnDto.isActive?false:true;
    service.ActivateOrDeactivateHsn(hsnDto).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Success');
        getAllHsn();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
      /**
     * 
     * @param hsnData 
     */
    const updateHsn = (hsnData: HsnDto) => {
      hsnData.updatedUser= JSON.parse(localStorage.getItem('username'))
      service.updateHsn(hsnData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllHsn();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      responsive: ['sm'],
      width: '70px',
      // fixed:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    
    },
    // {
    //   title: 'Tax Id',
    //   dataIndex: 'taxId',
    //   // responsive: ['lg'],
    //   ...getColumnSearchProps('taxId')
    // },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode', 
      sorter: (a, b) => a.hsnCode.localeCompare(b.hsnCode),
      fixed:'left',
      responsive: ['lg'],
      ...getColumnSearchProps('hsnCode')
    },
    {
      title: 'Variant Code',
      dataIndex: 'variantCode', 
      // responsive: ['lg'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    },
    
    {
      title: 'Product Name',
      dataIndex: 'productName', 
      render:(text,data) => {
        console.log(data)
        return text
      },
      filters: filtersData
       ,
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        console.log(value)
        return record.productName === value;
      },
    },
    // {
    //   title: 'Food Type',
    //   dataIndex: 'foodTypeId', 
    //   // responsive: ['lg'],
    //   ...getColumnSearchProps('foodTypeId')
    // },
   
    {
        title: 'Food Type',
        dataIndex: 'foodType', 
        // responsive: ['lg'],
        sorter: (a, b) => a.foodType.localeCompare(b.foodType),
      sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('foodType')
      },
    {
      title: 'Value Addition one',
      dataIndex: 'valueAdd1Name', 
      // responsive: ['lg'],
      sorter: (a, b) => {
        a = a.valueAdd1Name || '';
        b = b.valueAdd1Name || '';
        return a.localeCompare(b);
    },
      // sorter: (a, b) => a.valueAdd1Name.localeCompare(b.valueAdd1Name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAdd1Name')
    },
    {
      title: 'Value Addition two',
      dataIndex: 'valueAdd2Name', 
      // responsive: ['lg'],
      sorter: (a, b) => a.valueAdd2Name.localeCompare(b.valueAdd2Name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAdd2Name')
    },
    {
      title: 'Value Addition three',
      dataIndex: 'valueAdd3Name', 
      // responsive: ['lg'],
      sorter: (a, b) => a.valueAdd3Name.localeCompare(b.valueAdd3Name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAdd3Name')
    },
    {
      title: 'Value Addition four',
      dataIndex: 'valueAdd4Name', 
      // responsive: ['lg'],
      sorter: (a, b) => a.valueAdd4Name.localeCompare(b.valueAdd4Name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAdd4Name')
    },
    {
      title: 'Value Addition five',
      dataIndex: 'valueAdd5Name', 
      // responsive: ['lg'],
      sorter: (a, b) => a.valueAdd5Name.localeCompare(b.valueAdd5Name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAdd5Name')
    },
    
    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'InActive',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action     `,
      dataIndex: 'action',
      render: (text, rowData) => (
      
        <span>
          
          
          <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                   AlertMessages.getErrorMessage('You Cannot Edit Deactivated HSN Code');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />

          
          <Divider type="vertical" />
            <Popconfirm 
            onConfirm={e => { deleteHsn(rowData);  }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate ?'
                :  'Are you sure to Activate ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }
     //drawer related
     const closeDrawer=()=>{
      setDrawerVisible(false);
    }

    const openFormWithData=(viewData: HsnDto)=>{
      setDrawerVisible(true);
      setSelectedHsnData(viewData);
    };
  return (
    <>
    <Card title={<span style={{color:'white'}}>HSN Code</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/hsn-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}>
    <br></br>
    <Row gutter={40}>
      <Col>
        <Card title={'Total HSN: ' + hsnData.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
      </Col>
      <Col>
        <Card title={'Active: ' + hsnData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
      </Col>
      <Col>
        <Card title={'In-Active :' + hsnData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
      </Col>
    </Row><br></br>
     
        <Table
            rowKey={record => record.hsnDataId}
            columns={sampleTypeColumns}
            dataSource={hsnData}
            scroll={{ x:true }}
              pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange} 
            bordered
          />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '55%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <HsnCodesForm key={Date.now()}
                updateHsn={updateHsn}
                isUpdate={true}
                hsnData={selectedHsnData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
      </Card>
        </>
  );
}

export default HsnCodesGrid;
