import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, Upload, UploadProps } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { CodeListMainIdRequest } from '@gtpl/shared-models/planning';
import { CodeListMainService, CodeListService } from '@gtpl/shared-services/planning';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

const { Option } = Select;

export interface codeListFormProps {
    soId:number
    soItemId:number
    codeListData: (traceCodesData: any[]) => void;
    operation:string
    subJobCases:number
}
export function CodeListAllocationForm(props: codeListFormProps) {
    useEffect(()=>{
        // form.setFieldsValue({saleOrderItemId:props.soItemId})
        getTracebilityCodes(props.soId);

    },[])
  const codeListMainService = new CodeListMainService()
  const [traceCodes, setTraceCodes] = useState<any[]>([]);
  const [traceCodeQty, setTraceCodeQty] = useState<number>();
  
  const onReset = () => {
    form.resetFields();
  };
  const saveData = (values) => {
    props.codeListData([])
    console.log(values)
    let totCartons = 0
    for(const record of values.codeListInfo){
        totCartons = Number(totCartons)+Number(record.codeListQuantity)
    }
    console.log(Number(totCartons))
    if(Number(totCartons) > Number(props.subJobCases)){
        AlertMessages.getErrorMessage('code list Quantity should not more than assined sub job cases')
        form.resetFields()
    }else if(Number(totCartons) < Number(props.subJobCases)){
        AlertMessages.getErrorMessage('code list shoud allocate for the total Sub job Quantity')
        form.resetFields()
    }else{
        props.codeListData(values)
        AlertMessages.getSuccessMessage('code list allocated for sub job')
        form.resetFields()
    }
  }

  const setSoItem = (val,rowObj) => {
    console.log(rowObj)
    setTraceCodeQty(rowObj.traceCodeQty)
    // form.setFieldsValue({saleOrderItemId:rowObj.soItemId})
    form.setFieldsValue({saleOrderItemId:props.soItemId})
    // form.setFieldsValue({traceCodeQty:rowObj.})
    form.setFieldsValue({operation:props.operation})
  } 

    const getTracebilityCodes = (soId) => {
        const soreq = new CodeListMainIdRequest();
        soreq.saleOrderId = soId
        console.log(soreq)
        codeListMainService.geTracebilityCodeForSoId(soreq).then((res) => {
          if (res.status) {
            console.log(res.data)
            setTraceCodes(res.data);
          } else {
            // if (res.intlCode) {
            //   AlertMessages.getErrorMessage(res.internalMessage);
            // } else {
            //   AlertMessages.getErrorMessage(res.internalMessage);
            // }
            setTraceCodes([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setTraceCodes([]);
        });
      } 
    const [form] = Form.useForm();

    const validateQty = (val,indexKey) => {
        console.log(Number(val))
        console.log(Number(indexKey))
        console.log(Number(traceCodeQty))
        console.log(Number(props.subJobCases))
        const selTraceQty = traceCodes.find(item => item.tracebilityCodeId == form.getFieldValue('codeListInfo')[indexKey].codeListId).cartons
        form.getFieldValue('codeListInfo')
        console.log(form.getFieldValue('codeListInfo')[indexKey].codeListId)
        console.log(selTraceQty)
        if(val > selTraceQty){
            AlertMessages.getErrorMessage('Quantity should less than trace Code Qty')
            form.setFields([
                {
                  name: ['codeListInfo', indexKey, 'codeListQuantity'],
                  value: null,
                },
                
              ]);
        }
        if(Number(val) > Number(props.subJobCases)){
            AlertMessages.getErrorMessage('Quantity should less than assigned sub job cases')
            form.setFields([
                {
                  name: ['codeListInfo', indexKey, 'codeListQuantity'],
                  value: null,
                },
                
              ]);
              
        }
    }

    return (
        <>
                <Form form={form} 
                onFinish={saveData}
                 layout="vertical" >
                <Form.Item name="saleOrderItemId" style={{ display: "none" }} >
                    <Input hidden />
                </Form.Item>
                <Form.Item name="traceCodeQty" style={{ display: "none" }} >
                    <Input hidden />
                </Form.Item>
                <Form.Item name="operation" style={{ display: "none" }} >
                    <Input hidden />
                </Form.Item>
                    

                    <Row gutter={24}>

                        <Form.List name="codeListInfo">
                            {(fields, { add, remove }) => (
                                <>
                                    <Card>
                                        <Form.Item>

                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Allocate
                                            </Button>
                                        </Form.Item>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>

                                                <Row gutter={24}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Trace Code"
                                                            name={[field.name, 'codeListId']}
                                                            // fieldKey={[field.fieldKey, 'brandId']}
                                                            rules={[{ required: true, message: 'Missing Code' }, { pattern: /^(?!\s*$).+/, message: `Enter Trace Code` }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                placeholder="Please Select Code"
                                                                optionFilterProp="children"
                                                                onChange={setSoItem}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                style={{ width: 250 }}
                                                            >
                                                                {traceCodes.map(dropData => {
                      return <Option traceCodeQty = {dropData.cartons} label="codeListId" soItemId = {dropData.saleOrderItemId} key={dropData.tracebilityCodeId} value={dropData.tracebilityCodeId}>{dropData.tracebilityCode}</Option>
                    })}
                                                                |
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                        {...field}
                                                        label="Quantity"
                                                        name={[field.name, 'codeListQuantity']}
                                                        fieldKey={[field.fieldKey, 'codeListQunatity']}
                                                        rules={[{ required: true, message: 'Missing Qty' }]}
                                                        >
                                                        <Input onChange={e => validateQty(e.target.value,field.fieldKey)}/>

                                                        </Form.Item>
                                                       
                                                    </Col>

                                                </Row>
                                                {
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                }
                                                
                                            </Space>
                                        ))}
                                    </Card>

                                </>
                            )}
                        </Form.List>
                    </Row>

                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary"  htmlType="submit" >
                                Allocate
                            </Button>
                           
                        </Col>
                    </Row>
                </Form>
        </>
    );

};
export default CodeListAllocationForm;
