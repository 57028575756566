import { EmployeeCodeRequest, HrmsEmployeeDetailsDto } from "@gtpl/shared-models/masters"
import { EmployeeService } from "@gtpl/shared-services/masters"
import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import React, { useEffect, useState } from "react"
import { Redirect, useLocation, useParams } from "react-router-dom"
import EmployeeDetailView from "./employee-detail-view"

export interface EmployeeDetailViewPagesProps { }

export function EmployeeDetailViewPages(
    props: EmployeeDetailViewPagesProps
) {
    const [selectedEmpDetails, setSelectedEmpDetails] = useState<HrmsEmployeeDetailsDto>(undefined)
    const [redirectView, setRedirectView] = useState<boolean>(false)
    const { empCode } = useParams<any>()

    console.log(empCode)
    let location = useLocation();
    console.log(location.state);
    const service = new EmployeeService;
    const getEmpDetailsByCode = (empCode) => {
        const req = new EmployeeCodeRequest(empCode)
        service.getActiveEmployeesByEmpCode(req).then(res => {
            if (res.status) {
                console.log(res.data);
                setSelectedEmpDetails(res.data);
            } else {
                if (res.intlCode) {
                    setSelectedEmpDetails(undefined);
                    // AlertMessages.getErrorMessage(res.internalMessage);
                    goToTracker();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSelectedEmpDetails(undefined);
        })
    }
    // const getTotal = ({selectedSaleOrder}) => {
    //   console.log({selectedSaleOrder});

    // }
    const goToTracker = () => {
        setRedirectView(true);
    }
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
        getEmpDetailsByCode(empCode);
        // getTotal({selectedSaleOrder})
    }, [empCode])

    return (
        <>
            {(redirectView) ? <Redirect key={Date.now()} to={{ pathname: "/HRMS-employee_details-view" }} /> : null}

            <EmployeeDetailView employeeDetails={selectedEmpDetails} />
        </>
    );
}

export default EmployeeDetailViewPages;
