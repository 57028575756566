export const whatsApp = {
    'UsageReport': '919246649070-1615282910@g.us',
    'attendanceReport': '917382052052-1547014792@g.us',
    'VERSION': 'v16.0',
    // 'OLD_PHONE_NUMBER_ID': '102194172756615',
    // 'OLD_ACCESS_TOKEN': 'EAAMPZAswUgD4BAOUwlRXgVhMgpFW4eXT3z2ZBcVlIPnTtixguAqc74xFS1ydBrTuCD55njvOS7B9ZB4ZAZB3pvFQ4ySYEMZChD1OkKv5RUQ6wZBwOZCfiOB1F3KZCbqL8CHcDReQSOcjGHAZAzX7oy7ylsmaNpTgacI1aUQ8LydnFXR4PMHMZBDH8ZAz',
    'PHONE_NUMBER_ID' : '107287678965552',
    'ACCESS_TOKEN' : 'EAABtPfqQEJ0BAGLwjcos2nyZAaptYdOdPAoZBig7xgkZC82x10w72Fzw7xna1TAJ7YCG86ZAg9Vf083i2eSpSRBtlp4cZBZB1bvrZBEEhBNbe4GoMc6YHiHZAlivhzOkZAIv8c8bjHZAszqdZBIIGuGM9smQ6MZCZAtvbUUNKGqrlK6GWMSqFyfH3iLA0ZAPHlMfxTGYJJb41bPkbmwAZDZD',
    // 'contacts': ['919030069351','917702621117','918096131316','919246649070'],
    'contacts': ['8790477753'],
    'server':'LIVE DATA'
}
export default whatsApp;