import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Form, Tooltip, DatePicker, Empty, Row, Col, Select } from "antd";
import form from "antd/lib/form";
import moment from "moment";
//import { ContainerService } ';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { ContainerBarChartDto, Containertrnd } from "@gtpl/shared-models/logistics";



export interface LogisticsBarchartProps {

}
export function LogisticsBarChart(props: LogisticsBarchartProps) {
  const service = new ContainerRegisterService()
  const Option = Select
  const [selectedYear, setSelectedYear] = useState<any>(moment().year());
  const [containerData, setContainerData] = useState<any[]>([]);
 

console.log(containerData)

  const getAllContainersMonthWise =()=>{
    const req= new Containertrnd(Number(selectedYear))
    service.getMonthWiseContainersData(req).then(res => {
      if (res.status) {
        setContainerData(res.data);
      } else {
          if (res.intlCode) {
            setContainerData([]);
              AlertMessages.getErrorMessage(res.internalMessage);
          } else {
              AlertMessages.getErrorMessage(res.internalMessage);
          }
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setContainerData([]);
  })

  }
 
  useEffect(()=>{
    getAllContainersMonthWise();
    years(0);
  },[selectedYear])

const allmonths = ['January','February','March','April','May','June','July','August','September','October','November','December'];

let result = [];

allmonths.forEach(month => {
  const item = containerData.find(item => item.MONTH == month);
  
  if (item) {
    result.push(item);
  } else {
    result.push({MONTH: month,containers:0,quantity:0});
  }
})
  
const graphDataMonths = result.map(i => {return i.MONTH});
const graphQuantityData = result.map(i => {return Number(i.quantity)});
const graphContainersData = result.map(i =>{ return Number(i.containers)});
// console.log(graphQuantityData)
  const config = {
    chart: {
      type: 'column'
    },

    title: {
      text: 'Total Containers shipped with quantity by month wise'
    },

    xAxis: {
      categories: graphDataMonths
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      // tickAmount: 16,
      // tickPixelInterval: 100,
      title: {
        text: 'Number of Containers shipped'
      }
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' +
          this.series.name + ': ' + this.y  + '<br/>'
          // 'quantity :'+graphQuantityData[0];

      }
    },

    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },

    series: [
      {
      name: 'Containers',
      data:graphContainersData,
      // stack: 'male'
    }, 
    // {
    //   name: 'Containers',
    //   data:graphContainersData,
    //   stack: 'male'
    // },
  ]
  }
 
  const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  }
  const lastTenYears = years(10)


  return (
    <Form layout="vertical" name="control-hooks">
      <Row gutter={24} justify='center'>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
          <Form.Item name="quarterly" label="Years"
            rules={[
              {
                required: false,
              },
            ]}>
            <Select
              placeholder="Select Year"
              allowClear
              onSelect={(value) => { setSelectedYear(value) }}
              defaultValue={moment().format('YYYY')}
            >
              {lastTenYears.map((value) => {
                return <Option key={value} value={value}>{value}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {containerData.length ?
      <div><HighchartsReact highcharts={Highcharts} options={config} /></div> : <Empty/>
      }
    </Form>

  );
}
export default LogisticsBarChart;