import { Card, Tooltip, Row, Col, message } from 'antd';
import React, { useState } from 'react';
import excel from '../../../assets-dashboard/src/lib/excel.png'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface IProps {
    assetsData: {
        employeeName: string | null,
        countOfAssets: string,
        serviceSkippedAssets: string,
        amcExpiryAssets: string,
        insuranceSkipped: string,
        warrentySkipped: string,
        unassignedAssets: string
    }[]
}

const PersonResponsibleAssets = (props: IProps) => {
    const { assetsData } = props;
    const [hoveredCard, setHoveredCard] = useState(null);


    const handleExport: React.MouseEventHandler<HTMLElement> = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        if (assetsData?.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date().toISOString().slice(0, 10).split("-").join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { header: 'Person Name', key: 'employeeName', width: 20 },
            { header: 'Count Of Responsible', key: 'countOfAssets', width: 20 },
            { header: 'Service Skipped Assets', key: 'serviceSkippedAssets', width: 20 },
            { header: 'AMC Expiry Assets', key: 'amcExpiryAssets', width: 25 },
            { header: 'Insurance Skipped', key: 'insuranceSkipped', width: 25 },
            { header: 'Warranty', key: 'warrentySkipped', width: 25 },
            { header: 'License', key: 'license', width: 25 },
            { header: 'Unassigned Assets', key: 'unassignedAssets', width: 25 }
        ];

        worksheet.columns = columns;

        // Add header row with styling
        worksheet.getRow(1).font = { bold: true };
        worksheet.getRow(1).eachCell((cell, colNumber) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        assetsData.forEach(asset => {
            worksheet.addRow(asset);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Persons Data Against To Responsible Assets Info-${currentDate}.xlsx`);
    };

    return (
        <div>
            <Card title="Persons Data Against To Responsible Assets Info" style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px',
                height: "450px",
                textAlign: 'center'
            }} headStyle={{ backgroundColor: '#114232', color: 'white' }} 
            extra={<Tooltip
                title="Export As Excel"
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    height: '35px',
                    width: '35px'
                }}>
                    <img src={excel} style={{ width: 40, height: 40 }} alt="" 
                     onClick={handleExport}
                     />
                </div>
            </Tooltip>}
            >

                <Card style={{ backgroundColor: '#AFD198', height: '60px' }}>
                    <Row>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Person Name</h1>
                        </Col>
                        <Col span={3}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Count Of</h1>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "10px" }}>Responsible</h1>
                        </Col>
                        <Col span={3}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Service</h1>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "10px" }}>Skipped Assets</h1>
                        </Col>
                        <Col span={3}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>AMC Expiry</h1>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "10px" }}>Assets</h1>
                        </Col>
                        <Col span={3}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Insurance</h1>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "10px" }}>Skipped</h1>
                        </Col>
                        <Col span={3}>
                            <div>
                                <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Warranty</h1>
                            </div>
                        </Col>
                        <Col span={2}>
                            {/* <div>
                                <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>License</h1>
                            </div> */}
                        </Col>
                        <Col span={3}>
                            <div>
                                <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Unassigned Assets</h1>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {assetsData.map((asset, index) => (
                        <Card style={{
                            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                            height: '80px',
                            backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white',
                        }}
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)} key={index}>
                            <Row>
                                <Col span={4}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.employeeName || 'N/A'}</h1>
                                </Col>
                                <Col span={3}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.countOfAssets}</h1>
                                </Col>
                                <Col span={3}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.serviceSkippedAssets}</h1>
                                </Col>
                                <Col span={3}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.amcExpiryAssets}</h1>
                                </Col>
                                <Col span={3}>
                                    <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.insuranceSkipped}</h1>
                                </Col>
                                <Col span={3}>
                                    <div>
                                        <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.warrentySkipped}</h1>
                                    </div>
                                </Col>
                                <Col span={2}>
                                    {/* <div>
                                        <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}></h1>
                                    </div> */}
                                </Col>
                                <Col span={3}>
                                    <div>
                                        <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>{asset.unassignedAssets}</h1>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </div>
            </Card>
        </div>
    );
}

export default PersonResponsibleAssets;
