import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Tabs, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, RedoOutlined, RotateRightOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import Link from 'antd/lib/typography/Link';
import { BomPackingItemsDto, BomSODto, SODto } from '@gtpl/shared-models/sale-management';
import { SaleOrderItemsInfo, SaleOrderItemsDto, SOItemsDto } from '@gtpl/shared-models/sale-management';
import { SaleOrderService, saleOrder } from '@gtpl/shared-services/sale-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { SoDesignForm } from '@gtpl/pages/master/master-components/so-design-form';

import { Table } from "ant-table-extensions";

import { FormattedMessage } from 'react-intl';

import { Redirect } from 'react-router-dom';
import { FoodTypesDto, ProductDto } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import moment from 'moment';


import './factory-head-dashboard-grid.css';
import { ReProcess } from './re-processing-rm-stock';
import { RePacking } from './repacking-rm-stock';
import { PmStatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import SaleOrderItemDetails from './so-item-details';
import { PoFilterDto } from '@gtpl/shared-models/procurement-management';
import SoItemInProgress from './so-item-in-progress';
import SoItemBackLog from './so-back-log';


const { TabPane } = Tabs;
/* eslint-disable-next-line */
export interface FactoryHeadDashboardGridProps { }

export function FactoryHeadDashboardGrid(props: FactoryHeadDashboardGridProps) {
  const [searchText, setSearchText] = useState('');
  const [searchText1, setSearchText1] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchedColumn1, setSearchedColumn1] = useState('');
  const [saleId, setSaleId] = useState(0);
  const [saleOrderData, setSaleOrderData] = useState<AllSaleOrdersDto[]>([]);
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReProcess, setIsReProcess] = useState<boolean>(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);

  const service = new SaleOrderService();

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  /**
  * 
  * @param selectedKeys 
  * @param confirm 
  * @param dataIndex 
  */
  function handleSearch1(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText1(selectedKeys[0]);
    setSearchedColumn1(dataIndex);
  };

  function handleReset1(clearFilters) {
    clearFilters();
    setSearchText1('');
  };

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    getAllSaleOrders();
    // getAllSaleOrderItems();
    // getAllFGStockData();
  }, [])

  const getAllSaleOrders = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getAllSaleOrdersFHDashbard(req).then(res => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          setSaleOrderData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    })
  }
  const expandedRowRender1 = (record: AllSaleOrdersDto, index, indent, expanded) => {
    return <SaleOrderItemDetails key={Date.now()} saleOrderId={record.saleOrderId} currencyId={record.currencyId} />
  }

  const expandedRowRender3 = (record: AllSaleOrdersDto, index, indent, expanded) => {
    return <SoItemInProgress key={Date.now()} saleOrderId={record.saleOrderId} currencyId={record.currencyId} />
  }
  const expandedRowRender5 = (record: AllSaleOrdersDto, index, indent, expanded) => {
    return <SoItemBackLog key={Date.now()} saleOrderId={record.saleOrderId} currencyId={record.currencyId} />
  }
  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrder',
      fixed: 'left',
      width: 150,
      align: 'center',
      render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</Link>,
      // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</a>,
      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder')
    },
    {
      title: 'PO Number',
      dataIndex: 'poNo',
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo')
    },
    {
      title: 'End Customer Name',
      dataIndex: 'endCustomer',
      sorter: (a, b) => a.endCustomer.localeCompare(b.endCustomer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomer')
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: 200,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      render: (value, rowData, index) => {
        return value ? moment(value).format('YYYY-MM-DD') : "-";
      },

      // ...getColumnSearchProps('etd')
    },
    {
      title: 'Aging',
      key: 'etd',
      width: 100,
      align: 'left',
      sorter: (a, b) => (Math.floor((new Date(a.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
          props: {
            style: {
              background: Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
            }
          },
        };
        return obj;
      }
    },
    {
      title: 'Packaging Order Status',
      dataIndex: 'pmStatus',
      fixed: 'right',
      // responsive: ['lg'],
      render: (status, rowData) => (
        <>
          {status == PmStatusEnum.IN_PROGRESS ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>ORDERED</Tag> : status == PmStatusEnum.CLOSE ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>INVENTORY</Tag> : status == PmStatusEnum.OPEN ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>NOT ORDERED</Tag> : ""}

        </>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: PmStatusEnum.IN_PROGRESS,
          value: 'ORDERED',
        },

        {
          text: PmStatusEnum.CLOSE,
          value: 'INVENTORY',
        },
        {
          text: PmStatusEnum.OPEN,
          value: 'NOTORDERED',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
      // ...getColumnSearchProps('status')
    },



  ];
  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrder',
      fixed: 'left',
      width: 150,
      align: 'center',
      render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</Link>,
      // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</a>,
      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder')
    },
    {
      title: 'Container Number',
      dataIndex: 'containerNumber',
      sorter: (a, b) => a.containerNumber?.localeCompare(b.containerNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('containerNumber')
    },
    {
      title: 'Container Status',
      dataIndex: 'containerStatus',
      sorter: (a, b) => a.containerStatus?.localeCompare(b.containerStatus),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('containerStatus')
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: 200,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      render : (value , record) =>{
        return <>{record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}</>
      }

      // ...getColumnSearchProps('etd')
    },
    // {
    //   title: 'Packaging Order Status',
    //   dataIndex: 'status',
    //   // responsive: ['lg'],
    //   render: (status, rowData) => (
    //     <>
    //       {status=="ORDERED"?<Tag  style={{backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>ORDERED</Tag> : status=="INVENTORY"?<Tag  style={{backgroundColor:'#f2a637',color:"black", fontWeight: 'bold' }}>INVENTORY</Tag>:status=="NOTORDERED"?<Tag  style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}>NOT ORDERED</Tag>:""}

    //     </>
    //   ),
    //   sorter: (a, b) => a.status.localeCompare(b.status),
    //   sortDirections: ['descend', 'ascend'],
    //   filters: [
    //     {
    //       text: 'ORDERED',
    //       value: 'ORDERED',
    //     },

    //     {
    //       text: 'INVENTORY',
    //       value: 'INVENTORY',
    //     },
    //     {
    //       text: 'NOTORDERED',
    //       value: 'NOTORDERED',
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     return record.status === value;
    //   },
    //   // ...getColumnSearchProps('status')
    // },



  ];
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log(saleOrderId);
  }

const date = new Date();
console.log(saleOrderData.filter(rec => new Date(rec.etd) > date && rec.status == 'OPEN'))

  return (
    <>
      {(saleId) ? <Redirect to={{ pathname: "/saleorder-detail-view", state: saleId }} /> : null}

      <Card title={<span style={{ color: 'white' }}>Factory Head Dashboard</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >


        <br></br>
        <Row gutter={40}>
          <br></br>

        </Row>
        <br></br>
        <div className="card-container">
          <Tabs type={'card'} tabPosition={'top'}>
            <TabPane
              // tab="Open Orders"
              key="1"
              tab={<span style={{ color: "black" }}>{'Open Orders: ' + (saleOrderData.filter(el => el.status == "OPEN").length)}</span>}
              style={{ color: "#363636" }}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={columnsSkelton1}
                expandedRowRender={expandedRowRender1}
                dataSource={saleOrderData.filter(rec => rec.status == 'OPEN')}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />
              {/* } */}
            </TabPane>
            <TabPane
              key="3"
              tab={<span style={{ color: "green" }}>{'Work in Progress: ' + (saleOrderData.filter(el => el.status == "INPROGRESS").length)}</span>}
              style={{ color: "#363636" }}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={columnsSkelton1}
                expandedRowRender={expandedRowRender3}
                dataSource={saleOrderData.filter(rec => rec.status == 'INPROGRESS')}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />
            </TabPane>
            <TabPane
              key="5"
              tab={<span style={{ color: "red" }}>Back Log Orders</span>}
              style={{ color: "#363636" }}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={columnsSkelton2}
                expandedRowRender={expandedRowRender5}
                dataSource={saleOrderData.filter(rec => rec.status == 'OPEN' && new Date(rec.etd) > date)}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />
            </TabPane>
          </Tabs>
        </div>
        
      </Card>
    </>
  )
}
export default FactoryHeadDashboardGrid;
