import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { ApplyForLeavesDto, EmployeeCodesRequest } from '@gtpl/shared-models/hrms';
import { AttendanceService } from '@gtpl/shared-services/hrms';

import './attendance-adjustment.css';
import moment from 'moment';

const { Option } = Select;

/* eslint-disable-next-line */
export interface CreateAttendanceLogsProps {
    applyForLeavesData: ApplyForLeavesDto;
    closeForm: () => void;
}

export function CreateAttendanceLogs(
    props: CreateAttendanceLogsProps
) {
    const [form] = Form.useForm();
    const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
    const service = new HrmsEmpDetService();
    const attnService = new AttendanceService();

    useEffect(() => {
        getAllActiveEmployeesForLeaves();
    }, [])

    const getAllActiveEmployeesForLeaves = () => {
        service.getAllActiveEmployeesForLeaves().then(res => {
            if (res.status) {
                setEmployeeData(res.data);
            } else {
                if (res.intlCode) {
                    setEmployeeData(res.data);
                    console.log(res.data)
                    // AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    //  AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            setEmployeeData([]);
            // AlertMessages.getErrorMessage(err.message);
        })
    }

    const saveData = (req: EmployeeCodesRequest) => {
        attnService.createEmpAttendanceLogs(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage('Logs Created Successfully');
                onReset();
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage("Error");
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    };

    const onReset = () => {
        form.resetFields();
    }

    const onFocus = () => {
        console.log('focus');
    }

    const onSearch = (val) => {
        console.log('search:', val);
    }

    const onBlur = () => {
        console.log('blur');
    }

    return (
        <Card title={<span style={{ color: 'white' }}>Create New Employee Attendance Logs</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

            <Form form={form}
                name="control-hooks"
                layout="vertical"
                onFinish={saveData}
            >

                <Row>
                    {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
                        <Form.Item
                            name="employeeName"
                            label="Employee Name"
                            rules={[
                                {
                                    required: true,
                                    message: "code is required"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                //style={{ width: 210 }}
                                placeholder="Select Employee Name"
                                optionFilterProp="children"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                onChange={getEmployeeDetails}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {employeeData.map((employeeData) => {
                                    return <Option key={employeeData.employeeId} value={employeeData.employeeCode}>{employeeData.employeeName + " " + employeeData.employeeLastName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col> */}

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 2 }} style={{ margin: '1%' }}>
                        <Form.Item
                            name="employeeCodes"
                            label="Employee Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Employee Code"
                                },
                            ]}>
                            <Select
                                showSearch
                                //style={{ width: 210 }}
                                placeholder="Select Employee Code"
                                optionFilterProp="children"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                mode="multiple"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {employeeData.map((employeeData) => {
                                    return <Option key={employeeData.employeeId} value={employeeData.employeeCode}>{employeeData.employeeCode}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>

                        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default CreateAttendanceLogs;
