import { Button, Card, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ContainerLoadingDetails from './container-loading-details';
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
import './container-log-book-form.css';
import { Link, Redirect, useHistory } from 'react-router-dom';
import LogBookItems from './log-book-items';
import { ItemsDetails, SaleOrderDropDownDto, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ContainerLogBookService} from '@gtpl/shared-services/logistics'
import {useLocation} from 'react-router-dom'
import { ContainerLogBookDTO } from '@gtpl/shared-models/logistics';
import moment from 'moment';
/* eslint-disable-next-line */
export interface ContainerLogBookFormProps {}

export function ContainerLogBookForm(
  props: ContainerLogBookFormProps
) {
  const [containerLogBookDetailsForm] = Form.useForm();
  const saleOrderService = new SaleOrderService();
  const containerLogBookService = new ContainerLogBookService();
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([])
  const [productSku, setProductSku] = useState<ItemsDetails[]>([])
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [filelist, setfilelist] = useState([]);
  let history = useHistory();

  let location = useLocation();
  console.log(location.state);
  const data:any = location.state;

  useEffect(() => {
    if(data){
      data.loadingDate = moment(data.loadingDate);
      getSoItems(data.saleOrderId);
    }
    getSaleOrders();
  }, []);

  const getSaleOrders = () => {
    saleOrderService.getSaleOrderDropDownInvoiceDone({unitcodeId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const getSoItems = (reqData) => {
    saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        setProductSku(res.data);
      } else {
        setProductSku([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProductSku([]);
    })
  }
  
  const uploadFileList = (uploadFiles) =>{
    console.log('test');
    console.log(uploadFiles);
    setfilelist(uploadFiles)
  }
  
  const saveData = () => {
    console.log(containerLogBookDetailsForm.getFieldsValue());
    containerLogBookDetailsForm.validateFields().then(req => 
    {
      const formData = new FormData();
      console.log(req);
      req.loadingStartTime = moment(req.loadingStartTime).format('HH:mm');
      req.loadingEndTime = moment(req.loadingEndTime).format('HH:mm');
      req.fileName = req.file?.fileList[0]?.name;
      formData.append('file', req.file?.fileList[0]);
      console.log(formData);
      console.log(req);
      containerLogBookService.getCreateContainerLogBook(req).then(res => 
      {
        if (res.status) {
          if (filelist.length > 0) {
            const formData = new FormData();
            filelist.forEach((file: any) => {
              formData.append('file', file);
            });
            formData.append('containerLogBookId', `${res.data.containerLogBookId}`)
            containerLogBookService.containerLoadingFileUpload(formData).then(fileres => {
              res.data.filePath = fileres.data;
            })
          }
          history.push("/container-log-book-grid");
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Container Loading Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/container-log-book-grid' ><Button className='panel_button' >View </Button></Link>}>
      <ContainerLoadingDetails form={containerLogBookDetailsForm} saleOrderData = {saleOrderData} saleOrderItem={getSoItems} saleOrderItemDetails = {productSku} formData = {data} isUpdate = {data != undefined? true : false} uploadFileList={uploadFileList} />
      <Row justify="end">
          <Col>
            <Form.Item >
              <br />
              <Button type='primary' onClick={saveData} block disabled={btnDisable}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      {/* <LogBookItems saleOrderItemDetails = {productSku} getBrandsData = {getBrandsForSoId} brandsData = {[]}/> */}
    </Card>
  ); 
}

export default ContainerLogBookForm;
