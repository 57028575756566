import { Card, Col, Row } from "antd";
import '../dashboard.css'
import React, { useState } from "react";
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
import { Link, Redirect } from "react-router-dom";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface cardProps {
    cardData: CardDetail
}

export const CardComponet = (props: cardProps) => {
    const [status, setStatus] = useState('')
    const [cardType, setCardType] = useState(undefined)
    const [cardDead, setCardDead] = useState(undefined)
    const { cardData } = props;

    const getStatus = (status) => {
        console.log(status)
        setStatus(status)
    }



    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <>
                <tr style={{ borderBottom: '1px solid #f0f8ff', lineHeight: ' 1.8em' }}>
                    {/* {cardData.carHeader.cardType=='Sales'?<td ><Link to='/sale-otder-report'></Link>:{bodyProps.cardBodyTitle}}</td>:<td >{cardData.carHeader.cardType=='Sales'?<Link to='/sale-otder-report'></Link>:{bodyProps.cardBodyTitle}}</td>} */}
                    <td style={{ paddingLeft: '8px', textAlign: 'left' }}>{(cardData.carHeader.cardType == 'Sales') ? <Link to='#' onClick={e => getStatus(bodyProps.cardBodyTitle)} >{bodyProps.cardBodyTitle}</Link> : <>{bodyProps.cardBodyTitle}</>}</td>
                    <td style={{ paddingLeft: '8px', textAlign: 'left' }}>{bodyProps.cardBodyParam1}</td>
                    <td style={{ paddingLeft: '8px', textAlign: 'left' }}>{bodyProps.cardBodyParam2}</td>
                    <td style={{ textAlign: 'right' }}>{bodyProps.cardBodyParam3}</td>
                    <td style={{ paddingRight: '5px', textAlign: 'right' }}>{bodyProps.cardBodyParam4}</td>
                </tr>

            </>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    //console.log(cardData.carHeader.cardType,'000000000')
    return (
        <>
            {(status) || (cardType == 'skipped-saleorders') ? <Redirect to={{ pathname: "/saleorder-abstract-report", state: { status: status, cardType: cardData.carHeader.cardType } }} /> : (cardType == 'dead-stock') ? <Redirect to={{ pathname: "/stock-report-warehouse", state: { status: 'Above 180 days', cardType: cardData.carHeader.cardType } }} /> : null}
            <Card className="totalsocard" title={<span className="title" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span style={{ marginBottom: '10px' }} >{cardData.carHeader.cardHeaderTitle}</span>
                <span style={{ marginBottom: '10px' }}>
                    <div>{cardData.carHeader.cardHeaderCountTitle}</div>
                    <div>{cardData.carHeader.cardHeaderCountValue}</div>
                </span>
            </span>} bordered={false} style={{ color: 'white', height: '100%', backgroundColor: '#272953' }}>
                <Row style={{ width: '100%', height: '20px' }} hidden={cardData.cardHeaderRequired == null ? false : true} >
                    <Col span={8}>
                        {cardData.carHeader.cardHederData[0].title}
                    </Col>
                    <Col span={14} offset={2}>
                        {cardData.carHeader.cardHederData[1].title}
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[0].value}
                    </Col>
                    <Col span={14} offset={2} style={{ fontSize: '30px' }}>
                        {cardData.carHeader.cardHederData[1].value}
                    </Col>
                </Row>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '90%', margin: '1.5em', fontSize: '0.85em', border: '0' }}>
                    {constructTableHeader(cardData.tableHeaders)}
                    <tbody>
                        {constructLocationDetail(cardData.cardBody)}
                        {(cardData.carHeader.cardType == 'skipped-saleorders') ?
                            <tr>
                                <td style={{ lineHeight: ' 2.8em' }}> <Link to='#' onClick={e => { setCardType(cardData.carHeader.cardType) }}  >{'View All Skipped Sale Orders >>'}</Link></td>
                            </tr> : <></>
                        }
                        {(cardData.carHeader.cardType == 'dead-stock') ?
                            <tr>
                                <td style={{ lineHeight: ' 2.8em' }}> <Link to='#' onClick={e => { setCardType(cardData.carHeader.cardType) }}  >{'View All dead Stock Products Orders >>'}</Link></td>
                            </tr> : <></>
                        }
                    </tbody>
                </table>
            </Card>
        </>
    );
}
export default CardComponet;