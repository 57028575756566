import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'antd'
import './trims-configuration-form.css';
import { MasterBrandService, TrimsMappingService } from '@gtpl/shared-services/masters';
import { MasterBrandsDropDownDto, TrimsConfigurationDTO, TrimsItemMappingDto, TrimsItemMappingRequest } from '@gtpl/shared-models/masters';
import TrimsDetailform from './trims-detail-form';
import AddTrimForm from './add-trim-form';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";



/* eslint-disable-next-line */
export interface TrimsConfigurationFormProps {
  isUpdate: boolean
  selectedData: any
  onSucess: (value: boolean) => void
}

export function TrimsConfigurationForm(
  props: TrimsConfigurationFormProps
) {

  const [addTrimForm] = Form.useForm()
  const [trimDetailForm] = Form.useForm()
  const [trimsData, setTrimsData] = useState<any>([])
  const [trimsUpdateData, setTrimsUpdateData] = useState<any[]>([])
  const [trimsItemsUpdateData, setTrimsItemsUpdateData] = useState<any[]>([])
  let history = useHistory()

  const { selectedData, isUpdate } = props
  const service = new TrimsMappingService()

  useEffect(() => {
    if (props.isUpdate) {
      getDataForUpdate()
    }
  }, [])






  const save = () => {
    trimDetailForm.validateFields().then((trimDetail) => {
      const trimDetailsArray = []
      trimsData.forEach((element) => {
        console.log(element)
        trimDetailsArray.push(new TrimsItemMappingDto(element?.trimItemId ? element.trimItemId : undefined, element.subcategoryId, element.itemId, trimDetail.trimId, element.againstTo, element.noOfTrims, true, undefined, trimDetail.createdUser, undefined, undefined, undefined))
      })
      const trimsConfigData = new TrimsConfigurationDTO(trimDetail?.trimId ? trimDetail.trimId : undefined, trimDetail.brandId, trimDetail.packStyleId, trimDetail.varientCode, true, undefined, trimDetail.createdUser, undefined, undefined, undefined, trimDetailsArray, trimDetail.endCustomerId)
      if (trimDetailsArray.length) {
        service.saveTrimsConfiguration(trimsConfigData).then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage)
            addTrimForm.resetFields()
            trimDetailForm.resetFields()
            if (isUpdate) {
              props.onSucess(false)
            }
            history.push('trims-configuration-grid')
          } else {
            AlertMessages.getInfoMessage(res.internalMessage)
          }
        }).catch(err => {
          AlertMessages.getErrorMessage('Error while creating')

        })
      } else {
        AlertMessages.getInfoMessage('Add atleast one item details ')
      }

    })
  }

  const getDataForUpdate = () => {
    const req = new TrimsItemMappingRequest(selectedData.trimId)
    service.getAllTrimsDataForTrimId(req).then((res) => {
      if (res.status) {
        const { trimItemsInfo, ...rest } = res.data
        setTrimsUpdateData(rest)
        setTrimsItemsUpdateData(trimItemsInfo)
        setTrimsData(trimItemsInfo)
        trimDetailForm.setFieldsValue({
          brandId: rest.brandId,
          varientCode: rest.variantId,
          packStyleId: rest.packStyleId,
          endCustomerId: rest.endCustomerId,
          trimId: rest.trimId
        })
      } else {
        setTrimsData([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setTrimsData([])
    })
  }



  console.log(trimsData)
  return (
    <Card title={<span style={{ color: 'white' }}>Trims Configuration</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={!props.isUpdate && <Link to='/trims-configuration-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
      <TrimsDetailform form={trimDetailForm} addTrimForm={addTrimForm} initialValues={trimsUpdateData} isUpdate={isUpdate} />
      <AddTrimForm form={addTrimForm} trimsDetailFormForm={trimDetailForm} setTrimsData={setTrimsData} initialValues={trimsItemsUpdateData} isUpdate={isUpdate} />
      <br /><br />
      {trimsData.length ? <Row justify='end'>
        <Col span={2}>
          <Button disabled={trimsData.length ? false : true} onClick={save} type='primary'>
            Submit
          </Button>
        </Col>
      </Row> : <></>}
    </Card>
  );
}

export default TrimsConfigurationForm;
