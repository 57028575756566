import React from 'react';
// import {SaleOrderProcessDto} from '@gtpl/shared-models/masters'
import {SaleOrderProcessDto} from './../../../../../../shared-models/masters/src/lib/saler_order_process/sale_order_process.dto'
import './sale-order-process-types-form.css';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import {SaleOrderService} from '@gtpl/shared-services/masters'
import TextArea from 'antd/lib/input/TextArea';
import { Link, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
/* eslint-disable-next-line */
export interface SaleOrderProcessTypesFormProps {

  saleorderData: SaleOrderProcessDto;
    updateDetails:(saleorder:SaleOrderProcessDto)=>void;
   isUpdate:boolean;
  closeForm: () => void;
}

export function SaleOrderProcessTypesForm(
  props: SaleOrderProcessTypesFormProps
) {
  const [form] = Form.useForm();
  let history= useHistory();
  const service = new SaleOrderService;

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  const save = (Data: SaleOrderProcessDto) => {
    service.create(Data).then(res => {
   if (res.status) {
     AlertMessages.getSuccessMessage('Sale order process type Created Successfully');
     history.push("/saleorderprocesstypes-view")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
   AlertMessages.getErrorMessage(err.message);
 })
}
  const saveData = (values: SaleOrderProcessDto) => {
    // console.log(values);
    if(props.isUpdate){
      props.updateDetails(values);
    }else{
      save(values);
    }
  
  };
  const onReset = () => {
    form.resetFields();
  };


  return (
    <Card title={<span style={{color:'white'}}>Sale order process from</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}  extra={props.isUpdate==true?"":<Link to='/saleorderprocesstypes-view' ><span style={{color:'white'}} >  {(props.isUpdate===false) &&
      <Button className='panel_button' >View </Button>
       }  </span></Link>}   >
 <Form  onFinish={saveData} form={form} layout={'vertical'} initialValues={props.saleorderData} >
 <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
 <Row  gutter={20}>
    
<Form.Item
      name="saleorderprocessTypeId"
     
    >
      <Input hidden />
    </Form.Item>
       
    <Col span={8} >
<Form.Item
      name="saleorderprocessTypeName"
      label="Sale Order Process Type"
      rules={[
        {
          required: true,
          pattern:/^(?!\s*$).+/,
          message:"Enter valid Sale Order Process Type name."
        },
      ]}
    >
      <Input/>
    </Form.Item>
    </Col>
    <Col span={8} >
<Form.Item
      name="saleorderprocessTypeCode"
      label="Sale Order Process Code"
      // rules={[
      //   {
      //     required: true,
      //   },
      // ]}
    >
      <Input/>
    </Form.Item>
    </Col>
    
    </Row>
    
    <Col span={8} >
<Form.Item
      name="remarks"
      label="Remarks"
      // rules={[
      //   {
      //     required: true,
      //   },
      // ]}
    >
      <TextArea rows={1} />
    </Form.Item>
    </Col>

    <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
    <Button type="primary" htmlType="submit" >
        Submit
      </Button>
      {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      </Col>
      </Row>
 </Form>


 </Card>



  );
}

export default SaleOrderProcessTypesForm;
