import { ContractorDto } from "@gtpl/shared-models/hrms";
import { ContractorService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Input, Select, Form, Button, Card, Col, DatePicker, Row } from "antd";
import moment from "moment";
import React from "react";
import  { useState } from "react";
import  { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;

export interface ContractorFormProps {
  contractorData: ContractorDto;
  updateContractor: (contractor: ContractorDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function ContractorForm(props:ContractorFormProps){

  const [form] = Form.useForm();
  let history = useHistory();
  const contractorservice = new ContractorService;
  const dateFormat = "YYYY-MM-DD";
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());

  

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  let contractorId=null;
  if(props.isUpdate){
    contractorId = props.contractorData.contractorId;
  }
  
  const createContractor = (contractor: ContractorDto) => {
    contractorservice.createContractor(contractor).then(res => {
      if (res.status) {
        history.push("/contractor-view");
        onReset();
        AlertMessages.getSuccessMessage('Contractor Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    console.log('')
    form.resetFields();
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const saveData = (values: ContractorDto) => {
    if (props.isUpdate) {
      console.log(values);
      props.updateContractor(values);
    } else {
      console.log(values);
      createContractor(values);
    }
  }
  const onFocus = () => {
    console.log('focus');
  }
  const onBlur = () => {
    console.log('blur');
  }

  
  const getNoOfDays = (dateType: string) => {
    const fromDate = form.getFieldValue('fromDate');
    const toDate = form.getFieldValue('toDate');
    if (moment(fromDate).format('dddd') === 'Sunday' || moment(toDate).format('dddd') === 'Sunday') {
      AlertMessages.getErrorMessage('Selected day should not be a sunday');
      if (dateType == 'fromDate') {
        setFromDate(moment());
        form.setFieldsValue({
          fromDate: moment()
        })
      }
      if (dateType == 'toDate') {
        form.setFieldsValue({
          toDate: moment()
        })
      }
    }
    const d1 = moment(form.getFieldValue('fromDate'), 'YYYY-MM-DD');
    const d2 = moment(form.getFieldValue('toDate'), 'YYYY-MM-DD');
    // Calc all days used including last day ( the +1 )
    const days = d2.diff(d1, 'days') + 1;

    console.log('Days:', days);

    // how many full weekends occured in this time span
    const weekends = Math.floor(days / 7);

    console.log('Full Weekends:', weekends);

    // Subtract all the weekend days
    let businessDays = days - (weekends * 1);

    // Special case for weeks less than 7
    if (weekends === 0) {
      const cur = d1.clone();
      for (let i = 0; i < days; i++) {
        if (cur.day() === 0) {
          businessDays--;
        }
        cur.add(1, 'days')
      }
    } else {
      // If the first day is a sunday we need to account for it
      if (d1.day() === 0) {
        console.log('Extra weekend day (Sunday)');
        businessDays--;
      }
    } 
} 
function disabledDate(current) {
    // Can not select days before today and today
    return current && current < fromDate;
  }


  return(
    <Card title={<span style={{ color: 'white' }}>Human Resource Agent</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/contractor-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


    <Form form={form} name="control-hooks" layout="vertical" onFinish={saveData}>

      <Form.Item name="contractorId" style={{ display: "none" }} initialValue={contractorId}  >
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="contractorName"
            label="Contractor Name"
            initialValue={props.contractorData? props.contractorData.contractorName: undefined}
            rules={[
              {
                required: true,
                message: "Contractor Name is required",max:25
              },
              {
                pattern: /^[A-Z][a-z]*(?: [A-Z][a-z]*)*$/,
                message: `Should contain only alphabets and first letter should be capital.`
              }

            ]}>
          <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="contractorType"
            label="Contract Type"
            initialValue={props.contractorData? props.contractorData.contractorType: undefined}
            rules={[
              {
                required: true,
                message: "Contract Type is required"
              },
            ]}>
            {<Select
                    showSearch
                    placeholder="Select Contract Type"
                  >
                    <Option key={0} value={null}>Select Contract Type</Option>
                    <Option key={1} value={'Project Based'}>Project Based</Option>
                    <Option key={2} value={'Monthly Based'}>Monthly Based</Option>
                    <Option key={2} value={'Renewal'}>Renewal</Option>
                  </Select>}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="noOfEmployees"
            label="Max Manpower"
            initialValue={props.contractorData? props.contractorData.noOfEmployees: undefined}
            rules={[
              {
                required: true,
                message: "Max Manpower is required",max:6
              },
              {
                pattern: /^[0-9]*$/,
                message: `Should contain only Numbers.`
              }

            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
        <Form.Item name="fromDate" label="From Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={(selected) => {setFromDate(selected);getNoOfDays('fromDate')}}
                defaultValue={moment(props.contractorData? props.contractorData.fromDate: undefined, dateFormat)}
                showToday={true} />
            </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
        <Form.Item name="toDate" label="To Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={() => getNoOfDays('toDate')}
                defaultValue={moment(props.contractorData? props.contractorData.toDate: undefined, dateFormat)}
                showToday={true}
                disabledDate={disabledDate}
              />
            </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="contractPeriod"
            label="Contract Period"
            initialValue={props.contractorData? props.contractorData.contractPeriod: undefined}
            rules={[
              {
                required: true,
                message: "Contract Period is required",max:12
              },
              {
                pattern: /^[0-9A-Za-z ]*$/,
                message: `Should contain only Numbers and Alphabets.`
              }

            ]}
          > 
         <Input />
          </Form.Item>
        </Col>
        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="contractorProId"
            label="Contract project Id"
            initialValue={props.contractorData? props.contractorData.contractorProId: undefined}
            rules={[
              {
                required: true,
                message: "Contractor Project Id is required",max:12
              },
              {
                pattern: /^[0-9A-Za-z]*$/,
                message: `Should contain only Numbers and Alphabets.`
              }


            ]}
          > 
         <Input />
          </Form.Item>
        </Col> */}
      </Row>
      <Row>
      <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate!==true) &&
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>
      </Row>
    </Form>
  </Card>
  )
}
export default ContractorForm;