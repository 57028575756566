import React, {useEffect, useState, useRef} from 'react';
import {Divider, Table, Popconfirm, Card, Switch, Input, Button, Tag, Col, Row, Drawer, Tooltip} from 'antd';
import Highlighter from 'react-highlight-words';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';

import {LoanCategoriesDto} from '@gtpl/shared-models/hrms';
import {LoanCategoriesService} from '@gtpl/shared-services/hrms';
import {LoanCategories} from '@gtpl/pages/hrms/hrms-components/loan-categories';

import './loan-categories-grid.css';

/* eslint-disable-next-line */
export interface LoanCategoriesGridProps {}

export function LoanCategoriesGrid(
  props: LoanCategoriesGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false); 

  const [categoriesData, setCategoriesData] = useState<LoanCategoriesDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(undefined);
  const service = new LoanCategoriesService();

  useEffect(() => {
    getAllLoanCategories();
  },[])

  const getAllLoanCategories = () => {
    service.getAllLoanCategories().then(res =>{
      if(res.status){
        setCategoriesData(res.data);
      }else{
        if(res.intlCode){
          setCategoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCategoriesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteLoanCategory = (data: LoanCategoriesDto) => {
    data.isActive = data.isActive? false: true;
    service.activateOrDeactive(data).then(res => {
      if(res.status){
        getAllLoanCategories();
        AlertMessages.getSuccessMessage('Success');
      }else{
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateLoanCategory = (data: LoanCategoriesDto) => {
    service.update(data).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllLoanCategories();
        setDrawerVisible(false);
      }else{
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData=(viewData: LoanCategoriesDto)=>{
    setDrawerVisible(true);
    setSelectedCategory(viewData);
    console.log(selectedCategory)
    console.log('selectedCategory')
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Loan Category',
      dataIndex: 'loanCategoryName',
      // responsive: ['lg'],
      sorter: (a, b) => a.loanCategoryName.length - b.loanCategoryName.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('loanCategoryName')
    },
    
   
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span> 
          <Tooltip placement="top" title='Edit'>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Loan Category');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteLoanCategory(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Loan Category ?'
                :  'Are you sure to Activate Loan Category ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   
  }

  return (
    <Card title={<span style={{color: 'white'}}>Loan Categories</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/loan-categories-form' ><Button className='panel_button' >Create </Button></Link>}
    >
      <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Loan Categories: ' + categoriesData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + categoriesData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + categoriesData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.loanCategoryId}
          columns={columnsSkelton}
          dataSource={categoriesData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <LoanCategories key={Date.now()}
                update={updateLoanCategory}
                isUpdate={true}
                data={selectedCategory}
                closeForm={closeDrawer} 
                />
            </Card>
          </Drawer>
    </Card>
  );
}

export default LoanCategoriesGrid;
