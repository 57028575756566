import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Switch, Input, Button, Tag, Row, Col, Drawer, Tooltip} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EditOutlined, SearchOutlined, AlertTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';

import {ExpensesCategoryDto} from '@gtpl/shared-models/hrms';
import {ExpensesCategoryService} from '@gtpl/shared-services/hrms';
import {ExpensesCategory} from '@gtpl/pages/hrms/hrms-components/expenses-category'

import './expenses-category-grid.css';

/* eslint-disable-next-line */
export interface ExpensesCategoryGridProps {}

export function 
ExpensesCategoryGrid(
  props: ExpensesCategoryGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [expensesCategoryData, setExpensesCategoryData] = useState<ExpensesCategoryDto[]>([]);
  const [selectedExpensesCategory, setSelectedExpensesCategory] = useState<any>(undefined);

  const expensesCategoryService = new ExpensesCategoryService();

  useEffect(() => {
    getAllExpensesCategories();
  },[]);

  /**
   * 
   */
  const getAllExpensesCategories = () => {
    expensesCategoryService.getAllExpensesCategories().then(res => {
      if(res.status){
        setExpensesCategoryData(res.data);
      }else {
        if(res.intlCode){
          setExpensesCategoryData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setExpensesCategoryData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  } 

  /**
   * 
   * @param expensesCategoryData 
   */
   const deleteExpensesCategory = (expensesCategoryData:ExpensesCategoryDto) => {
    expensesCategoryData.isActive=expensesCategoryData.isActive?false:true;
    expensesCategoryService.activateOrDeactivateExpensesCategory(expensesCategoryData).then(res => { console.log(res);
      if (res.status) {
        getAllExpensesCategories();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
     * 
     * @param variantData 
     */
   const updateExpensesCategory = (expensesCategoryData: ExpensesCategoryDto) => {
    expensesCategoryService.updateExpensesCategory(expensesCategoryData).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllExpensesCategories();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

   /**
   * used for column filter
   * @param dataIndex column data index
   */

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }

  //TO open the form for updation
  

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Expenses Category',
      dataIndex: 'expensesCategory',
      // responsive: ['lg'],
      sorter: (a, b) => a.expensesCategory.length - b.expensesCategory.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('expensesCategory')
    },
    
   
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span> 
          <Tooltip placement="top" title='Edit'>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Expenses Category');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteExpensesCategory(rowData);}}
            title={
              rowData.isActive
              
                ? 'Are you sure to Deactivate Expenses Category ?'
                :  'Are you sure to Activate Expenses Category ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }

    
  ];
 //TO open the form for updation
    const openFormWithData=(viewData: ExpensesCategoryDto)=>{
      setDrawerVisible(true);
      setSelectedExpensesCategory(viewData);
      console.log(selectedExpensesCategory)
      console.log('selectedExpensesCategory')
    }
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
  return (
    <Card title={<span style={{color:'white'}}>Expenses Categories</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/expenses-category-form' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40}>
      <Col>
          <Card title={'Total Expenses Categories: ' + expensesCategoryData.length} style={{textAlign: 'left', width: 300, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + expensesCategoryData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + expensesCategoryData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.expensesCategoryId}
          columns={columnsSkelton}
          dataSource={expensesCategoryData}
          scroll = {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <ExpensesCategory key={Date.now()}
                updateExpensesCategory={updateExpensesCategory}
                isUpdate={true}
                expensesCategoryData={selectedExpensesCategory}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default 
ExpensesCategoryGrid;
