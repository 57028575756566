
import { Button, Card, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Table, { ColumnProps, ColumnsType } from 'antd/lib/table';
import { saleOrder, SaleOrderService } from "@gtpl/shared-services/sale-management";
import { UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';

export interface SoItemBackLogProps {
    saleOrderId: number;
    currencyId: number;
 }

export function SoItemBackLog(props: SoItemBackLogProps) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [soBackLogData, setSoBackLogData] = useState<any[]>([]);
    const searchInput = useRef(null);
    const service = new SaleOrderService;
    const [page, setPage] = React.useState(1);

    useEffect(()=>{
        getSoItemDetailsForBackLog()
    },[])

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };

    const getSoItemDetailsForBackLog = () => {
        console.log(props.saleOrderId)
        service.getSoItemDetailsForBackLog(new saleOrder(props.saleOrderId)).then(res => {
    
          if (res.status) {
            // for (const saleDetail of res.data){
            //   const uomData = UnitsOfWeightInput.find(uom => uom.value == Number(saleDetail.uomId));
            //   const itemDescription = `${saleDetail.varientId} ${saleDetail.pouches} * ${Number(saleDetail.pouchWeight)} ${uomData.name}`;
            //   saleDetail.varientId=itemDescription
            // }
            setSoBackLogData(res.data);
          } else {
            if (res.intlCode) {
              setSoBackLogData([]);
            } else {
              setSoBackLogData([]);
            }
          }
        }).catch(err => {
        })
      }

      const Columns: ColumnProps<any>[] = [
        {
          title: 'Brand',
          dataIndex: 'brand',
          width: 150,
          sorter: (a, b) => a.brand.localeCompare(b.brand),
          ...getColumnSearchProps('brand'),
        },
        {
          title: 'Product',
          dataIndex: 'product',
          sorter: (a, b) => a.product.localeCompare(b.product),
          ...getColumnSearchProps('product'),
         
        },
        {
          title: 'Item Description',
          dataIndex: 'varientId',
          key: 'varientId',
          sorter: (a, b) => a.varientId - b.varientId,
          ...getColumnSearchProps('varientId'),
          render: (text, data, index) => {
            console.log(text, data, index)
            const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
            return <span>{text}</span>
          }
        },
        {
          title: 'Grade',
          dataIndex: 'minGrade',
          key: 'minGrade',
          sorter: (a, b) => a.minGrade - b.minGrade,
          ...getColumnSearchProps('minGrade'),
          render: (text, data, index) => {
            console.log(text, data, index)
            return <span>{`${text} - ${data.maxGrade} `}</span>
          }
        },
        {
          title: 'Cases',
          dataIndex: 'cases',
          key: 'cases',
          sorter: (a, b) => a.cases - b.cases,
          ...getColumnSearchProps('cases'),
        },
        {
          title: 'In FG Inventory cases',
           dataIndex: 'fgCases',
          
        },
        {
          title: '% completed',
          // dataIndex: 'minCount',
          key: 'completed',
          sorter: (a, b) => a.minCount - b.minCount,
          ...getColumnSearchProps('minCount'),
          render: (text, data, index) => {
            console.log(text, data, index)
            return <span>{(Number(data.fgCases) / Number(data.cases)) * 100}</span>
          }
        },
  
      ];
    return (
        <>
            <Card
                key={props.saleOrderId + props.currencyId}
                style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>SALE ORDER ITEMS </span>}></Card>
            <Table
                columns={Columns}
                dataSource={soBackLogData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                // onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered
            ></Table>
        </>
    )
}
export default SoItemBackLog;