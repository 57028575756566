import React, { useState, useEffect } from 'react';
import { BackTop, Card, Col, Form, Select, Row } from 'antd';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { ReportTypeENum, StockInResDto } from '@gtpl/shared-models/analytics-data';
import { StockInRqstDto } from '@gtpl/shared-models/warehouse-management';
import moment from 'moment';
import './warehouse-dashboard.css';

/* eslint-disable-next-line */
export interface WhTrendsGraphProps { }

export function WhTrendsGraph(props: WhTrendsGraphProps) {

  const [form] = Form.useForm();
  const { Option } = Select;
  const [stockdata, setStockData] = useState<StockInResDto>();
  const service = new WarehouseDashboardService;
  const [graphType, setGraphType] = useState(ReportTypeENum.DAYWISE)
  const [selectedMonth, setSelectedMonth] = useState<any>(moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState<any>(moment().year());

  Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

  useEffect(() => {
    getStockInfo();
    years(0);
  }, [selectedMonth, graphType, selectedYear])

  const getStockInfo = () => {
    const req = new StockInRqstDto
    req.month = Number(selectedMonth)
    req.year = Number(selectedYear)
    req.unitId = localStorage.getItem("unit_id")
    req.reportType = graphType;
    service.getStockInfo(req).then((res) => {
      if (res.status) {
        setStockData(res.data)
        //AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        //AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };

  const saleOrderGraphData = stockdata?.stockIn.saleorder.map(i => { return { y: i, color: '#11e0ee',name:''  } })

  // const superDummyGraphData = stockdata?.stockIn.superDummy.map(i => { return { y: i, color: '#e5e75a', name: 'Stock In' } })

  const dummyGraphData = stockdata?.stockIn.dummy.map(i => { return { y: i,color: '#2884ecb7',name:'' } })
  console.log(dummyGraphData,'--')

  const saleOrderOutGraphData = stockdata?.stockOut.saleorder.map(i => { return { y: i, color: '#18ff65',name:'' } })

  // const superDummyOutGraphData = stockdata?.stockOut.superDummy.map(i => { return { y: i, color: '#40a9ff', name: 'Stock Out' } })

  const dummyOutGraphData = stockdata?.stockOut.dummy.map(i => { return { y: i, color: '#1d639c',name:''  } })



  const options = {
    title: { text: "Stock In & Out Movement Trends (LB's)" },
    theme: "dark2",
    chart: {
      inverted: false,
      style: {
        fontFamily: "Source Sans Pro, sans-serif"
      },
      reflow: true,
      alignTicks: false,
      sortDirection: 1,
      events: {}
      
    },
    series: [
      
      // {
      //   id: "0bar45",
      //   type: "column",
      //   dataSorting: {
      //     enabled: true,
      //     matchByName: true,
      //     sortKey: "y"
      //   },
      //   data: superDummyGraphData,
      //   stack: "stacked-by-1",
      //   stacking: "normal",
      //   color: "#e5e75a",
      //   name: "Super Dummy",
      //   borderWidth: 0,
      //   dataLabels: {
      //     enabled: false,
      //     overflow: "allow",
      //     crop: false
      //   }
      // },
      {
        id: "0bar45",
        type: "column",
        data: dummyGraphData,
        stack: "stacked-by-1",
        stacking: "normal",
        color: "#2884ecb7",
        name: " Stock In Dummy",
        borderWidth: 0,
        point: {
          events: {}
        },
        dataLabels: {
          enabled: false,
          overflow: "allow",
          crop: false
        },
        yAxis: "default: 0",
        zIndex: 1,
        index: 1,
        legendIndex: 1
      },
      {
        id: "0bar45",
        type: "column",
        dataSorting: {
          enabled: false,
          matchByName: false,
          sortKey: "y"
        },
        data: saleOrderGraphData ,
        stack: "stacked-by-1",
        stacking: "normal",
        color: "#11e0ee",
        name: "Stock In Sale Order ",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          overflow: "allow",
          crop: false
        }
      },
      
      // {
      //   id: "4bar51",
      //   type: "column",
      //   data: superDummyOutGraphData,
      //   stack: "51",
      //   stacking: "normal",
      //   color: "#40a9ff",
      //   name: "Super Dummy",
      //   borderWidth: 0,
      //   dataLabels: {
      //     enabled: false,
      //     overflow: "allow",
      //     crop: false
      //   }
      // },
      {
        id: "4bar51",
        type: "column",
        data: dummyOutGraphData,
        stack: "51",
        stacking: "normal",
        name: "Stock Out Dummy",
        color: "#1d639c",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          overflow: "allow",
          crop: false
        }
      },
      {
        id: "4bar51",
        type: "column",
        data: saleOrderOutGraphData,
        stack: "51",
        stacking: "normal",
        color: "#18ff65",
        name: "Stock out Sale Order",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          overflow: "allow",
          crop: false
        }

      },
    ],
    // legend: {
    //   verticalAlign: "bottom",
    //   align: "center",
    //   enabled: true,
    //   layout: "horizontal",
    //   maxHeight: 100,
    //   margin: 16,
    //   itemStyle: {
    //     fontWeight: "normal",
    //     fontSize: "11px",
    //     color: "#222"
    //   },
    //   symbolHeight: 11,
    //   symbolWidth: 11
    // },
    xAxis: {
      title: {
        text: graphType
      },
      type: "category",
      categories: stockdata ? stockdata.names : [],
      reversed: false
    },
    yAxis: [

      {
        id: "default: 0",
        title: { text: "Stock In Vs Stock Out" }
      },
    ],
    plotOptions: {
      series: {
        cursor: "pointer",
        stickyTracking: false,

      },
      column: {
        groupPadding: 0.05,
        pointPadding: 0.05,
        stacking: 'normal',
      }
    },
    tooltip: {
      shayered: false,
      backgroundColor: "rgba(255,255,255,1)",
      borderColor: "#cccccc",
      distance: 16,
      borderRadius: 4
    },
    credits: {
      enabled: false
    },
    dataLabels: {
      enabled: false,
      rotation: -90,
      color: '#FFFFFF',
      align: 'right',
      format: '{point.y:.1f}', // one decimal
      y: 10, // 10 pixels down from the top
      style: {
        fontSize: '13px',
        fontFamily: 'Verdana, sans-serif'
      }
    }
  };

  const months = [
    {
      month: 'January',
      monthId: 1
    }, {
      month: 'Feburary',
      monthId: 2
    }, {
      month: 'March',
      monthId: 3
    }, {
      month: 'April',
      monthId: 4
    }
    , {
      month: 'May',
      monthId: 5
    }
    , {
      month: 'June',
      monthId: 6
    }
    , {
      month: 'July',
      monthId: 7
    }
    , {
      month: 'August',
      monthId: 8
    }
    , {
      month: 'September',
      monthId: 9
    }
    , {
      month: 'October',
      monthId: 10
    }
    , {
      month: 'November',
      monthId: 11
    }
    , {
      month: 'December',
      monthId: 12
    }
  ]

  const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  }
  const lastTenYears = years(10)

  return (
    <>
      <Form layout="vertical" form={form} name="control-hooks">
        <Row gutter={24} justify='center'>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 5 }}>
            <Form.Item name="graphType" label="Graph" initialValue={ReportTypeENum.DAYWISE}
              rules={[
                {
                  required: true, message: 'Missing Graph Type',
                },
              ]}
            >
              <Select
                placeholder="Select Graph Type"
                allowClear
                onChange={(value) => { setGraphType(value) }}
                defaultValue={ReportTypeENum.DAYWISE}
              >
                <Option key={0} value={null}>Select Graph Type</Option>
                {Object.values(ReportTypeENum).map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 5 }} style={{ display: (graphType == ReportTypeENum.DAYWISE || graphType == ReportTypeENum.WEEKWISE) ? 'unset' : 'none' }}>
            <Form.Item name="monthWise" label="Month"
              rules={[
                {
                  required: false,
                },
              ]} >
              <Select
                placeholder="Select Month"
                allowClear
                onSelect={(value) => { setSelectedMonth(value) }}
                defaultValue={(moment().format('MMMM'))}
              >
                {months.map((i) => {
                  return <Option key={i.monthId} value={i.monthId}>{i.month}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: graphType == ReportTypeENum.QUARTERWISE ? 'unset' : 'none' }}>
            <Form.Item name="quarterly" label="Years"
              rules={[
                {
                  required: false,
                },
              ]}>
              <Select
                placeholder="Select Year"
                allowClear
                onSelect={(value) => { setSelectedYear(value) }}
                defaultValue={moment().format('YYYY')}
              >
                {lastTenYears.map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br /><br />
      <div className="stockContainer">
        <HighchartsReact key={Date.now()} highcharts={Highcharts} options={options} />
      </div>
    </>
  )
}
export default WhTrendsGraph;
