import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Tabs, Form, DatePicker, Select, Spin, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SearchOutlined } from '@ant-design/icons';
import Table, { ColumnProps } from 'antd/lib/table';
import { ContainerReportModel } from '@gtpl/shared-models/logistics';
import {ContainerRegisterGrid} from '@gtpl/pages/logistics/logistics-components/container-register-grid';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';

const Option = Select;


/* eslint-disable-next-line */
export interface logisticsLifeCycleProps { }

export function LogisticLifeCycle(
  props: logisticsLifeCycleProps
) {
  const containerService = new ContainerRegisterService();
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const [containerData, setContainerData] = useState<any[]>([]);
  useEffect(() => {
    getAlllogisticslifecycledata();
  }, []);
const allstatus = ['NOT READY','READY TO BOOK','BOOKED','LOADING','LOADED AND SEALED','SHIPPED','OPEN','NOT RECEIVED','DRAFT RECEIVED','OBL RECEIVED','RECEIVED AND SHARED'];
  let result=[];
  allstatus.forEach(status =>{
    const item = containerData.find(item => item.STATUS == status);
    if (item) {
      result.push(item);
    }else{
    result.push({STATUS: status,kakinada:0,unitone:0,unittwo:0,total:0});

    }
  })

  const getAlllogisticslifecycledata= () => {
    containerService.getLogisticsLifeCycleData().then(res => {
      if (res.status) {setContainerData(res.data);
      } else {
        if (res.intlCode) {
          setContainerData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setContainerData([]);
    })
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
        title: '#',
        key: 'sno',
        width: '40px',
        responsive: ['sm'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
        title: 'Status',
        dataIndex: 'STATUS',
       width:'150px',
 
    },
    {
      title: 'Kakinada',
      dataIndex: 'kakinada',
      width: '90px',
      align:'right',

  },
  {
    title: 'Unitone',
    dataIndex: 'unitone',
    width: '90px',
    align:'right',

},
{
  title: 'Unittwo',
  dataIndex: 'unittwo',
  width: '90px',
  align:'right',

},
{
  title: 'Total',
  dataIndex: 'total',
  width: '90px',
  align:'right',
 render:(value,record) =>{
  const unit1 : number=Number(record?.unitone)
  const unit2 : number=Number(record.unittwo)
  const kkd : number =Number(record.kakinada)
  const total =unit1+unit2+kkd;
  return total;
 }
},
 {
  title: 'Total plant count',
  dataIndex: 'total',
  width: '120px',
  align:'right',

},
];
  return (
    <>
      <Card title={<span style={{ color: 'black' }}>Logistics Life cycle</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#white', border: 0 }}></Card>
  <Table
            // rowKey={record => record.downtimeTrackingId}
            columns={columnsSkelton}
            dataSource={result}
            size='small'
            pagination={false}
            scroll={{ y:400 }}
            bordered />
         </>

  );
}



export default LogisticLifeCycle;
