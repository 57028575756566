import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, Checkbox, Divider, Space, Steps, Affix, Modal, Upload, Typography, UploadProps } from 'antd';
import { DepartmentDto, HrmsEmployeeDetailsDto, PlantsDropDown } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './hrms-employee-details-form.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DepartmentService, HrmsEmpDetService, UnitcodeService } from '@gtpl/shared-services/masters';
import { QualificationsDto } from '@gtpl/shared-models/masters';
import { QualificationsService } from '@gtpl/shared-services/masters';
import { ContractorDto, DesignationDto, PickUpDto, RouteDto, SkillsDto, TeamDto } from '@gtpl/shared-models/hrms';
import { Link, useHistory } from "react-router-dom";
//  import TextArea from 'rc-textarea';
import moment from 'moment';
import { OperationService } from '@gtpl/shared-services/production'
import { ContractorService, DesignationService, PickUpService, RouteService, SkillsService, TeamsService } from '@gtpl/shared-services/hrms';
import { OperationsDropDown } from '@gtpl/shared-models/production-management';
import QrContainer from './qr-scanner';
import { LoadingOutlined } from '@ant-design/icons';
import { DocumentsListRequest } from '@gtpl/shared-models/logistics';
import { IndiaStatesEnum } from '@gtpl/shared-models/common-models';
import { TransportEnum } from '@gtpl/shared-models/common-models';
import { equals } from 'class-validator';
import TextArea from 'antd/lib/input/TextArea';


/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const alphaNumeric = /^([A-Z0-9]+)*$/
const numeric = /^[0-9]*$/

export interface HrmsEmployeeDetailsFormProps {
  hrmsData: HrmsEmployeeDetailsDto;
  updateDetails: (hrms: HrmsEmployeeDetailsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function HrmsEmployeeDetailsForm(props: HrmsEmployeeDetailsFormProps) {
  const [selectedId, setSelectedId] = useState<string>("");
  const [empform] = Form.useForm();
  const service = new HrmsEmpDetService;
  const [departmentsData, setDepartmentsData] = useState<DepartmentDto[]>([]);
  const [qualificationData, setQualificationData] = useState<QualificationsDto[]>([]);
  // const [idproofsData, setIdproofsData] = useState<IdProofsTypeDto[]>([]);
  const departmentService = new DepartmentService();
  const qualificationsService = new QualificationsService();
  // const idproofsTypeService=new IdproofsTypeService();
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [permanentAddress, setPermanentAddress] = useState<boolean>(false);
  const [permanentPinCode, setPermanentPinCode] = useState<boolean>(false);
  const [permanentState, setPermanentState] = useState<boolean>(false);
  const [shiftGroupData, setShiftGroupData] = useState<TeamDto[]>([]);
  const unitsService = new UnitcodeService();
  const [current, setCurrent] = useState<any>();
  const [autoRefresh, setautoRefresh] = useState(1);
  const { Step } = Steps;
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [salariedEmployeeVisible, setsalariedEmployeeVisible] = useState<boolean>(false);
  const [employeementType, setEmployeementType] = useState<boolean>(false);
  const [transferType, setTransferType] = useState<string>(undefined);
  const [laboursEmployeeVisible, setlaboursEmployeeVisible] = useState<boolean>(false);
  const [designationData, setDesignationData] = useState<DesignationDto[]>([]);
  const [skillSetData, setSkillSetData] = useState<SkillsDto[]>([]);
  const [contractorData, setContractorData] = useState<ContractorDto[]>([]);
  const contractorService = new ContractorService();
  const designationService = new DesignationService();
  const skillSetService = new SkillsService();
  const pickUpSetService = new PickUpService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const [expField, setExpField] = useState<number>(0);
  const [routeData, setRouteData] = useState<RouteDto[]>([]);
  const routeService = new RouteService();
  const [pickUpData, setPickUpData] = useState<PickUpDto[]>([]);
  const [fieldsData, setFieldsData] = useState<DocumentsListRequest>(undefined);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [transportationData, setTransportationData] = useState<boolean>(false)
  const employeeService = new HrmsEmpDetService();
  const shiftGroupService = new TeamsService();
  const initialValues = {
    familyDetails: [''],
    educationDetails: [''],
    idProofDetails: [''],
    experienceDetails: ['']
  }

  useEffect(() => {
    console.log(props.hrmsData);
    // if (!props.hrmsData) {
    //   props.hrmsData = new HrmsEmployeeDetailsDto(null,);
    // }
    if (props.hrmsData?.employeeId > 0 && props.isUpdate) {
      let value = props.hrmsData.employeeType;
      console.log(value);
      if (value === "Salaried Form") {
        setsalariedEmployeeVisible(true)
      }
      else {
        setsalariedEmployeeVisible(false)
      }
      if (value === "Labour Form") {
        setlaboursEmployeeVisible(true)
      }
      else {
        setlaboursEmployeeVisible(false)
      }
    }
    getAllDesignation();
    getAllActiveRoute();
    getAllActiveEmployees();

  }, []);

  /**
   * 
   */

  useEffect(() => {
    getAllShiftGroups();
  }, []);


  const getAllDesignation = () => {
    designationService.getAllDesignation().then(res => {
      if (res.status) {
        setDesignationData(res.data);
      } else {
        if (res.intlCode) {
          setDesignationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDesignationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveEmployees = () => {
    employeeService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllSkills = () => {
    skillSetService.getAllSkills().then(res => {
      if (res.status) {
        setSkillSetData(res.data);
      } else {
        if (res.intlCode) {
          setSkillSetData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSkillSetData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllPickUp = () => {
    pickUpSetService.getAllPickUp().then(res => {
      if (res.status) {
        setPickUpData(res.data);
      } else {
        if (res.intlCode) {
          setPickUpData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPickUpData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllShiftGroups = () => {
    shiftGroupService.getAllActiveTeams().then(res => {
      if (res.status) {
        setShiftGroupData(res.data);
      } else {
        if (res.intlCode) {
          setShiftGroupData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setShiftGroupData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllContractorActions = () => {
    contractorService.getAllContractorActions().then(res => {
      if (res.status) {
        setContractorData(res.data);
      } else {
        if (res.intlCode) {
          setContractorData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    }).catch(err => {
      setContractorData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, width: '120%' }}>Upload Image</div>
    </div>
  );

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  useEffect(() => {
    getAllActiveDepartment();
    getAllActiveQualification();
    // getAllActiveIdProof();
    getAllPlants();
    getAllSkills();
    getAllContractorActions();
    getAllActiveRoute();
    getAllPickUp();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }, []);

  const getAllActiveDepartment = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepartmentsData(res.data);
      } else {
        if (res.intlCode) {
          setDepartmentsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDepartmentsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveQualification = () => {
    qualificationsService.getAllActiveQualification().then(res => {
      if (res.status) {
        setQualificationData(res.data);
      } else {
        if (res.intlCode) {
          setQualificationData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setQualificationData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllPlants = () => {
    unitsService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const getAllActiveRoute = () => {
    routeService.getAllActiveRoute().then(res => {
      if (res.status) {
        setRouteData(res.data);
      } else {
        if (res.intlCode) {
          setRouteData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRouteData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const save = (hrmsData: HrmsEmployeeDetailsDto) => {
    service.create(hrmsData).then(res => {
      if (res.status) {
        history.push('/HRMS-employee_details-view')
        onReset();
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        console.log(res.internalMessage, "**********");
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let currentAdd;
  let currentPinCode;
  let currentState;
  const setAddress = (val) => {
    if (val.target.checked) {
      console.log(val);
      console.log(form.getFieldsValue());
      console.log(form.getFieldValue('currentAddress'));
      currentAdd = form.getFieldValue("currentAddress");
      currentPinCode = form.getFieldValue("pinCode");
      currentState = form.getFieldValue("state");
      permanentAddress == false ? setPermanentAddress(false) : setPermanentAddress(true);
      permanentPinCode == false ? setPermanentPinCode(false) : setPermanentPinCode(true);
      permanentState == false ? setPermanentState(false) : setPermanentState(true);
      if (permanentAddress == false) {
        console.log(permanentAddress)
        console.log('true---')
        form.setFieldsValue({
          permanentAddress: currentAdd
        })
      }
      if (permanentPinCode == false) {
        console.log(permanentPinCode)
        console.log('true---')
        form.setFieldsValue({
          permanentPinCode: currentPinCode
        })
      }
      if (permanentState == false) {
        console.log(permanentState)
        console.log('true---')
        form.setFieldsValue({
          permanentState: currentState
        })
      }
    } else {
      form.setFieldsValue({ permanentAddress: null }),
        form.setFieldsValue({ permanentPinCode: null }),
        form.setFieldsValue({ permanentState: null })
    }
  }

  const getAllOperations = () => {
    operationService.getAllOperationsDropDown().then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const [form] = Form.useForm();
  const operationService = new OperationService
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [filelist, setFilelist] = useState<any>()

  let history = useHistory();


  useEffect(() => {
    getAllOperations()
  }, [])

  const handleQrScan = (xmldata) => {
    setShowQrScan(false)
    console.log(xmldata);

    const data: any = convertXMLStringToObject(xmldata, "PrintLetterBarcodeData");
    console.log(data);
    let address = data.loc + ',' + data.house + ',' + data.street + ',' + data.lm + ',' + data.loc + ',' + data.dist + ',' + data.state + ',' + data.pc
    form.setFieldsValue({
      employeeName: data.name,
      gender: data.gender == "F" ? "Female" : "Male",
      aadhaarNumber: data.uid,
      currentAddress: address

    })

  }

  function convertXMLStringToObject(data: string, refTag: string) {
    const tagStartSymbol = '<';
    const tagEndSymbol = ['/>', '</' + refTag + '>'];
    const startsAt = data.indexOf("<" + refTag);

    let endsAt = 0;
    for (const endStr of tagEndSymbol) {
      const endsNo = data.indexOf(endStr, startsAt);
      if (endsNo) {
        endsAt = endsNo;
        break;
      }
    }
    const resultObj = {};
    const attributesStr = data.substring(startsAt + refTag.length + 1, endsAt);
    const strLen = attributesStr.length;
    const delimeter = '"';
    let start = false;
    let end = false;
    let charCount = 0;
    let from = 0;
    const fromTos: { from: number, to: number }[] = [];
    for (let i = 0; i < strLen; i++) {
      const ch = attributesStr[i];
      charCount++;
      if (ch == delimeter) {
        start = true;
      }
      if (ch == delimeter && start && from < i) {
        // start = false;
        end = true;
      }
      if (start && end) {
        fromTos.push({ from: from + 1, to: i });
        from = i;
        end = false;
        start = false;
      }
    }
    for (let j = 0; j < fromTos.length; j++) {
      if (j % 2 == 0) {
        const key = attributesStr.substring(fromTos[j].from, fromTos[j].to - 1).trim();
        ++j;
        const value = attributesStr.substring(fromTos[j].from, fromTos[j].to).trim();
        resultObj[key] = value;
      }
    }
    return resultObj
  }

  const saveData = (values: HrmsEmployeeDetailsDto) => {
    console.log('va', values);
    if (props.isUpdate) {
      props.updateDetails(values);
    } else {
      save(values);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  const onClose = () => {
    props.closeForm;
  }

  function onChange(value) {
    console.log(`selected ${value}`);

  }

  function onSearch(val) {
    console.log('search:', val);
  }

  const personalDetailsAffix = () => {
    setCurrent(0)
  }

  const jobDetailsAffix = () => {
    setCurrent(1)
  }

  const familyDetailsAffix = () => {
    setCurrent(2)
  }

  const educationDetailsAffix = () => {
    setCurrent(3)
  }

  const idProofDetailsAffix = () => {
    setCurrent(4)
  }

  const experienceDetailsAffix = () => {
    setCurrent(5)
  }

  const setId = (value) => {
    form.setFieldsValue({ idNumber: '' });
    if (equals(value, "Aadhaar Card")) {
      setSelectedId("Aadhaar Card");
    } else {
      setSelectedId("")
    }
  }

  const reflectExp = (value: any) => {
    console.log(value.target.value)
    setExpField(value.target.value)

  }

  const onFocus = () => {
    console.log('focus');
  }

  const onBlur = () => {
    console.log('blur');
  }

  const handelET = (value) => {
    if (value === "Contract") {
      setEmployeementType(true)
    } else {
      setEmployeementType(false)
    }
  }

  const formType = (value) => {
    setTransferType(value);
    if (value === "Salaried Form") {
      setsalariedEmployeeVisible(true)
    }
    else {
      setsalariedEmployeeVisible(false)
    }
    if (value === "Labour Form") {
      setlaboursEmployeeVisible(true)
    }
    else {
      setlaboursEmployeeVisible(false)
    }
  }
  // const HrmsGrid = new HrmsEmployeeDetailsGrid();

  const prefixSelector = (
    <Form.Item name="prefix" noStyle label="Please Select">
      <Select style={{ width: 70 }} placeholder="Mr/Mrs">

        <Option value="Mr">Mr</Option>
        <Option value="Mrs">Mrs</Option>
        <Option value="Miss">Miss</Option>
      </Select>
    </Form.Item>
  );

  const appendInputData = (val, label) => {
    console.log(val);
    console.log(label);
    setFieldsData({ ...fieldsData, [label]: val });
  }

  const uploadFieldProps: UploadProps = {
    multiple: false,
    onRemove: file => {
      setFilelist([]);
      setImageUrl('');
    },
    beforeUpload: (file: any) => {
      if (!file.name.match(/\.(png|jpeg|PNG|jpg|JPG)$/)) {
        AlertMessages.getErrorMessage("Only png,jpeg,jpg files are allowed!");
        return true;
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = data => {
        if (filelist.length == 1) {
          AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
          return true;
        } else {
          setFilelist([file]);
          getBase64(file, imageUrl =>
            setImageUrl(imageUrl)
          );
          return false;
        }
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: filelist,
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const transportationDisplay = (value) => {
    if (value == TransportEnum.Company) {
      setTransportationData(true)
    } else {
      setTransportationData(false)
    }
  }

  const calculateExperience = (key) => {
    console.log(key)
    const fields = form.getFieldsValue();
    console.log(fields)
    const { experienceDetails } = fields;
    const fromDate = experienceDetails[key].fromDate ? experienceDetails[key].fromDate : moment();
    const toDate = experienceDetails[key].toDate ? experienceDetails[key].toDate : moment()
    console.log('from  date', fromDate);
    console.log('to date', toDate)

    var monthDiff = toDate.diff(fromDate, 'months');
    var yearDiff = toDate.diff(fromDate, 'years');
    console.log(monthDiff, yearDiff)
    const experience = yearDiff + ((monthDiff - (12 * yearDiff)) / 100)
    console.log(experience.toFixed(2))
    form.setFields([{
      name: ['experienceDetails', key, 'experience'],
      value: experience.toFixed(2)
    }])

  }

  return (
    <Card title={<span style={{ color: 'white' }}>Employee Form</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/HRMS-employee_details-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

      <Form form={form}
        initialValues={props.hrmsData ? props.hrmsData : initialValues}
        name="control-hooks"
        onFinish={saveData}
        layout="vertical"
      >
        <br />
        {salariedEmployeeVisible ? <>
          <Affix>

            <Card style={{ width: '100%' }} headStyle={{ width: '100%' }} >
              <Steps size="small" current={current}>
                <Step title="Personal Details" />
                <Step title="Job Details" />
                <Step title="Family Details" />
                <Step title="Educational Details" />
                <Step title="Id Proof Details" />
                <Step title="Experience Details" />
                <Step title="Finish" status='finish' />
              </Steps>
            </Card>
          </Affix></> :

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >

            <Form.Item
              name="employeeType"
              label="Employee Type"
              rules={[
                {
                  required: true,

                }
              ]}>
              {<Select
                onChange={formType}
                showSearch
                placeholder="Select Employee Type"
              >

                <Option key={1} value={'Salaried Form'}>Executive Employee</Option>
                <Option key={2} value={'Labour Form'}>Worker Employee</Option>
              </Select>}
            </Form.Item>
          </Col>}

        {salariedEmployeeVisible ?
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
              <Form.Item
                name="employeeType"
                label="Employee Type"
                rules={[
                  {
                    required: true,
                  }
                ]}>
                {<Select
                  onChange={formType}
                  showSearch
                  placeholder="Select Employee Type"
                >
                  <Option key={1} value={'Salaried Form'}>Executive Employee</Option>
                  <Option key={2} value={'Labour Form'}>Worker Employee</Option>
                </Select>}
              </Form.Item>
            </Col>

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Personal Details</h1>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="aadhaarNumber"
                  label="Employee Aadhaar Number"
                  rules={[
                    {
                      required: true,
                      message: "Enter Valid Employee Aadhaar Number", max: 12, min: 12,
                    },
                    {
                      pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} placeholder='Employee Aadhaar Number' />
                </Form.Item>
              </Col>

              <Col>
                <br /><br />
                <Button htmlType='button' onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{ color: '#1890ff' }}>
                  Scan Aadhaar
                </Button>
                <Modal
                  className='qr-container'
                  key={'modal' + Date.now()}
                  width={'95%'}
                  style={{ top: 30, alignContent: 'right' }}
                  visible={showQrSacn}
                  onCancel={() => { setShowQrScan(false) }}
                  title={<React.Fragment>
                  </React.Fragment>}
                >
                  {
                    modal == 'QRScan' ? <QrContainer handleScan={handleQrScan} /> : null
                  }

                </Modal>
              </Col>
              <br />
              <br />
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 2 }} xl={{ span: 5, offset: 2 }} style={{ margin: '1%' }} >
                <Form.Item name="image"
                  rules={[
                    {
                      required: false,
                      message: 'Image is Required'
                    }
                  ]}
                >
                  <Upload {...uploadFieldProps} style={{ width: '100%' }} listType="picture-card" maxCount={1} onChange={e => appendInputData(e.file.name, 'file')}>
                    {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
                  </Upload> <Typography.Text>(Supported formats .jpeg, .png, .jpg)</Typography.Text>
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={24}>
              <Form.Item name="employeeId" style={{ display: "none" }}>
                <Input hidden />
              </Form.Item>
              <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
                <Input hidden />
              </Form.Item>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeCode"
                  label="Employee Code"
                  rules={[
                    {
                      required: false,
                      message: "Enter Employee Code",
                      max: 12
                    },
                    {
                      pattern: /^([A-Z0-9]+)*$/,
                      message: `Should contain alphabets and numbers.`
                    }
                  ]}>
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeName"
                  label="Employee First Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter Employee First Name"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    },
                    {
                      max: 50,
                      message: `you have reached a limit of 50.`
                    }
                  ]}
                >
                  <Input onFocus={personalDetailsAffix} addonBefore={prefixSelector} placeholder='First Name' />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeLastName"
                  label="Employee Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter Employee Last Name"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    },
                    {
                      max: 50,
                      message: `you have reached a limit of 50.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} placeholder='Last Name' />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      required: true,
                      message: "Select Designation "
                    },
                  ]}>

                  <Select
                    showSearch
                    placeholder="Select designation"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                    onFocus={personalDetailsAffix}
                  >
                    {designationData.map(dropData => {
                      return <Option value={dropData.designation}>{dropData.designation}</Option>;
                    })}</Select>

                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeRole"
                  label="Role"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                ><Select
                  showSearch
                  placeholder="Select Employee Role"
                >
                    <Option key={0} value={null}>Select Employee Role</Option>
                    {
                      Object.keys(EmployeeRolesEnum).map(function (key, index) {
                        return <Option key={EmployeeRolesEnum[key]} value={EmployeeRolesEnum[key]}>{EmployeeRolesEnum[key]}</Option>
                      })
                    }
                  </Select>

                </Form.Item> */}
              {/* </Col> */}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="dateOfBirth"
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a valid date'
                    }
                  ]}>
                  <DatePicker onFocus={personalDetailsAffix} style={{ width: '100%' }} format="YYYY-MM-DD" showToday={false} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Gender",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Gender"
                    onFocus={personalDetailsAffix}
                  >
                    <Option key={1} value={'Male'}>Male</Option>
                    <Option key={2} value={'Female'}>Female</Option>
                    <Option key={3} value={'Other'}>Other</Option>
                  </Select>}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="maritualStatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Marital Status  ",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Marital Status"
                    onFocus={personalDetailsAffix}
                  >
                    <Option key={1} value={'Married'}>Married</Option>
                    <Option key={2} value={'UnMarried'}>UnMarried </Option>
                    <Option key={3} value={'Others'}>Others </Option>
                  </Select>}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="mobileNumber"
                  label="Mobile Number"
                  rules={[
                    { required: true, message: ' Valid Mobile No is required', min: 10, max: 12 },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Don't Allow letters and Spaces`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="emergencyContactNo"
                  label="Emergency Contact Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid contact number",
                      max: 12
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
              {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
            <Form.Item
                name="altMobileNumber"
                label=" Alternative Contact Number"
                rules={[
                  {
                    required: false,
                    message: "Enter a valid Alternative contact number",
                    max:10
                  },
                  {
                    pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                    message: `Should contain only numbers.`
                  }
                ]}>
                <Input/>
              </Form.Item>
          </Col> */}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="emailID"
                  label="Email Id"
                  rules={[
                    {
                      required: false,
                      type: 'email',
                      message: "Please enter your email Id",
                      max: 25
                    },
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bloodGroup"
                  label="Blood Group"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Blood Group ",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Blood Group"
                    onFocus={personalDetailsAffix}
                  >
                    <Option key={1} value={'O+'}>O+</Option>
                    <Option key={2} value={'O-'}>O-</Option>
                    <Option key={3} value={'A+'}>A+</Option>
                    <Option key={4} value={'A-'}>A-</Option>
                    <Option key={5} value={'B+'}>B+</Option>
                    <Option key={6} value={'B-'}>B-</Option>
                    <Option key={7} value={'AB+'}>AB+</Option>
                    <Option key={8} value={'AB-'}>AB-</Option>

                  </Select>}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Nationality"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} defaultValue="Indian" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="departmentId"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: 'Department is required'
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    onFocus={personalDetailsAffix}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {departmentsData?.map(dropData => {
                      return <Option key={dropData.deptId} value={dropData.deptId}>{dropData.deptName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="unitId"
                  label="Unit"
                  rules={[
                    {
                      required: false, message: 'Select Unit',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Unit"
                    onFocus={personalDetailsAffix}
                    disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {plantData.map(dropData => {
                      return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="priorityOne"
                  label="Priority One"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Priority One"
                    },

                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Priortity One"
                    optionFilterProp="children"
                    // onChange={handleOperation}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {operations.map((workstationCategory) => {
                      return <Option value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                    })}
                  </Select>

                </Form.Item>
              </Col> */}
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="priorityType"
                  label="Priority Two"
                  rules={[
                    {
                      required: false,

                      message: "Please enter your Priority Type"
                    },

                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Priortity Two"
                    optionFilterProp="children"
                    // onChange={handleOperation}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {operations.map((workstationCategory) => {
                      return <Option key={workstationCategory.operationId} value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                    })}
                  </Select>

                </Form.Item>
              </Col> */}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="shiftGroup"
                  label="Shift Group"
                  rules={[
                    {
                      required: false,
                      message: "Select Shift Group",
                    }
                  ]}>
                  {<Select
                    showSearch
                    //style={{ width: 210 }}
                    placeholder="Select Shift Group"
                    optionFilterProp="children"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    allowClear
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {shiftGroupData.map((shiftGroupData) => {
                      return <Option key={shiftGroupData.id} value={shiftGroupData.shiftCode}>{shiftGroupData.shiftCode}</Option>
                    })}
                  </Select>
                  }
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="transportation"
                  label="Transportation"
                  rules={[
                    {
                      required: true,
                      message: "Select Transportation",
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Transportation"
                    onChange={transportationDisplay}
                  >
                    <Option key={TransportEnum.Personal} value={TransportEnum.Personal}> {TransportEnum.Personal} </Option>
                    <Option key={TransportEnum.Company} value={TransportEnum.Company}> {TransportEnum.Company} </Option>
                  </Select>}
                </Form.Item>
              </Col>

              {transportationData ?
                <>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                    <Form.Item
                      name="routeNumber"
                      label="Route Number"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Route Number",
                        },
                      ]}>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select Route Number"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {routeData?.map(dropData => {
                          return <Option key={dropData.routeId} value={dropData.routeNumber}>{dropData.routeNumber}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                    <Form.Item
                      name="pickUp"
                      label="Pickup point"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Pickup Point"
                        },
                        {
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                          message: `Should contain only alphabets.`
                        }
                      ]}>
                      <Select
                        showSearch
                        placeholder="Select Pickup Point"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                      >
                        {pickUpData?.map(dropData => {
                          return <Option key={dropData.pickUpId} value={dropData.pickUp}>{dropData.pickUp}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                </> : <></>}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="reportingManager"
                  label="Reporting Manager"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Reporting Manager",
                      max: 25
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Reporting Manager"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                    onFocus={personalDetailsAffix}
                  >
                    {designationData.map(dropData => {
                      return <Option value={dropData.designation}>{dropData.designation}</Option>;
                    })}</Select>

                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* <Col xs={{span:10}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}} style={{margin:'5px'}}> */}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="currentAddress"
                  label="Current Address"

                  rules={[
                    {
                      required: true,
                      //   pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      //   // message: `Don't Allow Spaces`
                      max: 500
                    }
                  ]}>
                  <TextArea onFocus={personalDetailsAffix} rows={4} style={{ width: '100%' }} />
                  {/* <TextArea onFocus={personalDetailsAffix} size='large' aria-multiline /> */}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,

                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    allowClear={true}
                  >
                    {Object.keys(IndiaStatesEnum).map((type) => {
                      return <Option value={IndiaStatesEnum[type]}>{IndiaStatesEnum[type]}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="pinCode"
                  label="PIN Code"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Pin code number",
                      max: 6
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
              <Row>
              </Row>
              <Form.Item name='sameAsCurAddress'>
                <Checkbox onChange={setAddress} style={{ paddingRight: '250px' }}>Same as Current Address</Checkbox>
              </Form.Item>
            </Row>
            <Row>
              {/* <Col xs={{span:10}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}  style={{margin:'1%'}}> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentAddress" label="Permanent address"
                  rules={[
                    {
                      required: true,
                      //   pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      //   // message: `Don't Allow Spaces`
                      max: 500
                    }
                  ]}>
                  <TextArea onFocus={personalDetailsAffix} rows={4} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentState"
                  label="State"
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    allowClear={true}
                  >
                    {Object.keys(IndiaStatesEnum).map((type) => {
                      return <Option value={IndiaStatesEnum[type]}>{IndiaStatesEnum[type]}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentPinCode"
                  label="PIN Code"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Pin code number",
                      max: 6
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Job Details</h1><br />

            <Row gutter={24}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeStatus"
                  label="Employment Type"
                  rules={[

                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Employment Type"
                    onFocus={jobDetailsAffix}
                    defaultValue="Direct"
                  >
                    <Option key={1} value={'Direct'}>Full Time</Option>
                    <Option key={2} value={'Refrence'}>Part Time</Option>
                    <Option key={3} value={'Transfer'}>Contract</Option>
                    <Option key={4} value={'Other'}>Intern</Option>
                  </Select>

                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>

                <Form.Item
                  name="yearsOfExperience"
                  label="Years of Experience"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Year of experience",
                      max: 10
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} key={Date.now()} defaultValue={expField} disabled />
                </Form.Item>
              </Col> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="companyEmail"
                  label="Company Email"
                  rules={[
                    {
                      required: false,
                      type: 'email',
                      message: "Please enter your Company email",
                      max: 25
                    },
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeMode"
                  label="Employee Mode"
                  rules={[
                    {
                      required: false,
                      message: "Enter valid employee mode"
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Employee Mode"
                    onFocus={jobDetailsAffix}
                  >
                    <Option key={1} value={'Direct'}>Direct</Option>
                    <Option key={2} value={'Refrence'}>Refrence</Option>
                    <Option key={3} value={'Transfer'}>Transfer</Option>
                    <Option key={4} value={'Other'}>Other</Option>
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="dateOfJoin"
                  label="Date of Joining"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Date of joining",
                    }
                  ]}>
                  <DatePicker onFocus={jobDetailsAffix} style={{ width: '100%' }} format="YYYY-MM-DD"
                    defaultValue={moment(moment().format("YYYY-MM-DD"))}
                    showToday={true} />
                </Form.Item>
              </Col>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                    <Form.Item
                      name="dateOfleave"
                      label="Date Of Leaving"
                      rules={[
                        {
                          required: false,
                          message: "Enter a valid Date of leaving",
                        }
                      ]}>
                      <DatePicker onFocus={jobDetailsAffix} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col> */}
              {(props.isUpdate === true) &&
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>

                  <Form.Item
                    name="workstationNumber"
                    label="Workstation Number"
                    rules={[
                      {
                        required: false,
                        message: "Enter a valid Workstation number",
                      }
                    ]}>
                    <Input onFocus={jobDetailsAffix} />
                  </Form.Item>
                </Col>}
              {(props.isUpdate === true) &&
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>

                  <Form.Item

                    name="healthCardNumber"
                    label="Health Card Number"
                    rules={[
                      {
                        required: false,
                        message: "Enter a valid Health card number",
                        max: 25
                      },
                      {
                        pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                        message: `Should contain only numbers.`
                      }
                    ]}>
                    <Input onFocus={jobDetailsAffix} />
                  </Form.Item>
                </Col>}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="uanNumber"
                  label="UAN Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid UAN number",
                      max: 14
                    },
                    {
                      pattern: /^[0-9 ]*$/,
                      message: `Should contain only numbers.`,
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="panNumber"
                  label="PAN Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid PAN Number",
                      max: 12
                    },
                    {
                      pattern: /^([A-Z0-9]+)*$/,
                      message: `Should contain only alphabets and Numbers.`
                    }
                  ]}>
                  <Input max={10} onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="esiNumber"
                  label="ESI Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid ESI number",
                      max: 20
                    },
                    {
                      pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="audit"
                  label="Is Audit ?"
                  rules={[
                    {
                      required: false,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Audit"
                    onFocus={jobDetailsAffix}
                    defaultValue={'Yes'}
                  >
                    <Option key={1} value={'Yes'}>Yes</Option>
                    <Option key={2} value={'No'}>No </Option>
                  </Select>}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="wageType"
                  label="Wage Type"
                  rules={[
                    {
                      required: false,
                      message: 'Please Select Wage Type'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Wage Type"
                    onFocus={jobDetailsAffix}
                    defaultValue={"Monthly Based"}
                  >
                    <Option value={"Day Based"}>Day Based</Option>
                    <Option value={"KG Based"}>KG Based</Option>
                    <Option value={"Monthly Based"}>Monthly Based</Option>
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bankAccountNumber"
                  label="Bank Account Number"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Bank account number",
                      max: 16
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bankName"
                  label="Bank Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Bank Name",
                      max: 25
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="ifscCode"
                  label="IFSC Code"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid IFSC Code",
                      max: 15
                    }
                  ]}>
                  <Input onFocus={jobDetailsAffix} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="dateOfEntry"
                  label="Date of Entry"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Date of entry",
                    }
                  ]}>
                  <DatePicker onFocus={jobDetailsAffix} style={{ width: '100%' }} format="YYYY-MM-DD"
                    defaultValue={moment(moment().format("YYYY-MM-DD"))}
                    showToday={true} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="isProbPeriodCompleted"
                  label="Is Probhitionary Period ?"
                  rules={[
                    {
                      required: false,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Probhitionary Period "
                    onFocus={jobDetailsAffix}
                  >
                    <Option key={1} value={'Yes'}>Yes</Option>
                    <Option key={2} value={'No'}>No </Option>
                  </Select>}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="skillSet"
                  label="Skill Set"
                  rules={[
                    {
                      required: false,
                      message: 'This is a mandatory field',
                    }
                  ]}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    placeholder="Select SkillSet"
                    onFocus={jobDetailsAffix}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {skillSetData.map(dropData => {
                      return <Option value={dropData.skills}>{dropData.skills}</Option>;
                    })}
                  </Select>
                  <br />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="accommodation"
                  label="Accommodation"
                  rules={[
                    {
                      required: true,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Accommodation"
                    onFocus={jobDetailsAffix}
                  >
                    <Option key={1} value={'inHouse'}>In House</Option>
                    <Option key={2} value={'outSide'}>Out Side </Option>
                  </Select>}
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Family Details</h1><br />

            <Row gutter={24}>
              <Form.List name="familyDetails">
                {(fields, { add, remove }) => (
                  <Card>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'employeeFamilyId']} fieldKey={[field.fieldKey, 'employeeFamilyId']}>
                          <Input hidden />
                        </Form.Item>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item {...field} label="Name" name={[field.name, 'familyMemName']} fieldKey={[field.fieldKey, 'familyMemName']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Enter valid Family Member Name',
                                  max: 20
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              <Input onFocus={familyDetailsAffix} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item {...field} label="Relation" name={[field.name, 'relation']} fieldKey={[field.fieldKey, 'relation']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing relation',
                                  max: 20
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              {<Select
                                showSearch
                                placeholder="Select  Relation"
                                onFocus={familyDetailsAffix}
                              >
                                <Option key={1} value={'Father'}>Father</Option>
                                <Option key={2} value={'Mother '}>Mother </Option>
                                <Option key={3} value={'Husband'}>Husband</Option>
                                <Option key={4} value={'Wife'}>Wife </Option>
                                <Option key={5} value={'Daughter'}>Daughter </Option>
                                <Option key={6} value={'Son'}>Son</Option>
                              </Select>}
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item label="Date Of Birth" name={[field.name, 'famDateOfBirth']} fieldKey={[field.fieldKey, 'famDateOfBirth']} rules={[{ required: false, message: 'Missing Date ' }]}>
                              <DatePicker onFocus={familyDetailsAffix} style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item {...field} label="Aadhaar Number" name={[field.name, 'famAadhaarNo']} fieldKey={[field.fieldKey, 'famAadhaarNo']}
                              rules={[{
                                required: false,
                                message: "Enter a valid Aadhaar card number",
                                max: 12
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: `Should contain only numbers.`
                              }]}
                            >
                              <Input onFocus={familyDetailsAffix} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item {...field} label="Contact No" name={[field.name, 'contactNo']} fieldKey={[field.fieldKey, 'contactNo']}
                              rules={[
                                {
                                  required: false,
                                  message: "Enter a valid mobile number",
                                  max: 12
                                },
                                {
                                  pattern: /^[0-9]*$/,
                                  message: `Should contain only numbers.`
                                }
                              ]}
                            >
                              <Input onFocus={familyDetailsAffix} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onFocus={familyDetailsAffix} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Family Details
                      </Button>
                    </Form.Item>
                  </Card>
                )}
              </Form.List>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Educational Details</h1><br />

            <Row gutter={24}>
              <Form.List name="educationDetails">
                {(fields, { add, remove }) => (
                  <Card>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'employeeEducationId']} fieldKey={[field.fieldKey, 'employeeEducationId']}>
                          <Input hidden />
                        </Form.Item>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item {...field} label="Qualification" name={[field.name, 'empQualification']} fieldKey={[field.fieldKey, 'empQualification']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing Qualification'
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              <Select
                                placeholder="Select qualification"
                                // onChange={}
                                allowClear
                                style={{ width: '100%' }}
                                onFocus={educationDetailsAffix}
                              >
                                {qualificationData.map(dropData => {
                                  return <Option value={dropData.qualificationName}>{dropData.qualificationName}</Option>;
                                })}</Select>

                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item {...field} label="Specialization" name={[field.name, 'Specialization']} fieldKey={[field.fieldKey, 'Specialization']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing Specialization',
                                  max: 20
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              <Input onFocus={educationDetailsAffix} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item label="Year Of Passout" name={[field.name, 'famDateOfPassout']} fieldKey={[field.fieldKey, 'famDateOfPassout']} rules={[{ required: false, message: 'Missing Date ' }]}>
                              <DatePicker style={{ width: '100%' }} picker="month" format="YYYY-MM" />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item {...field} label="Percentage/CGPA" name={[field.name, 'percentage']} fieldKey={[field.fieldKey, 'percentage']}
                              rules={[{
                                required: false,
                                message: "Enter a valid percentage/CGPA",
                                max: 12
                              },
                              ]}
                            >
                              <Input onFocus={educationDetailsAffix} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item >
                      <Button type="dashed" onFocus={educationDetailsAffix} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Educational Details
                      </Button>
                    </Form.Item>
                  </Card>
                )}
              </Form.List>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>ID Proof Details</h1><br />

            <Row gutter={24}>
              <Form.List name="idProofDetails">
                {(fields, { add, remove }) => (
                  <Card>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'employeeIdProofId']} fieldKey={[field.fieldKey, 'employeeIdProofId']}>
                          <Input hidden />
                        </Form.Item>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item {...field} label="ID Type" name={[field.name, 'idType']} fieldKey={[field.fieldKey, 'idType']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing ID Type'
                                }
                              ]}>
                              <Select showSearch
                                placeholder="Select ID Type"
                                onFocus={idProofDetailsAffix}
                                onSelect={setId}
                                onChange={setId}
                              >
                                <Option key={1} value={'Aadhaar Card'}>Aadhaar Card</Option>
                                <Option key={2} value={'Pan Card'}>Pan Card </Option>
                                <Option key={3} value={'Driving Licence'}>Driving Licence</Option>
                                <Option key={4} value={'Passport'}>Passport</Option>
                                <Option key={5} value={'Ration Card'}>Ration Card </Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item {...field} label="ID Number" name='idNumber' fieldKey={[field.fieldKey, 'idNumber']}
                              rules={[
                                { required: true, message: ' Valid id No is required', max: selectedId ? 12 : 16 },
                                {
                                  pattern: selectedId ? numeric : alphaNumeric,
                                  message: selectedId ? 'Only Numbers are accepted' : 'Only AlphaNumeric are accepted'
                                }
                              ]}
                            >
                              <Input onFocus={idProofDetailsAffix} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onFocus={idProofDetailsAffix} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add ID Proof Details
                      </Button>
                    </Form.Item>
                  </Card>
                )}
              </Form.List>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Experience Details</h1><br />

            <Row gutter={24}>
              <Form.List name="experienceDetails">
                {(fields, { add, remove }) => (
                  <Card>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'employeeExperienceId']} fieldKey={[field.fieldKey, 'employeeExperienceId']}>
                          <Input hidden />
                        </Form.Item>
                        <Row gutter={24}>
                          <Col span={5}>
                            <Form.Item {...field} label="Organization" name={[field.name, 'organisation']} fieldKey={[field.fieldKey, 'organisation']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing Organization'
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              <Input onFocus={experienceDetailsAffix} />
                            </Form.Item>
                          </Col>

                          <Col span={5}>
                            <Form.Item {...field} label="Designation" name={[field.name, 'designation']} fieldKey={[field.fieldKey, 'designation']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing Designation',
                                  max: 15
                                },
                                {
                                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                                  message: `Should contain only alphabets.`
                                }
                              ]}
                            >
                              <Input onFocus={experienceDetailsAffix} />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item {...field} label="From Date" name={[field.name, 'fromDate']} fieldKey={[field.fieldKey, 'fromDate']} rules={[{ required: true, message: 'Missing Date ' }]}>
                              <DatePicker
                                style={{ width: '100%' }}
                                onFocus={experienceDetailsAffix}
                                onChange={() => { setautoRefresh(prev => prev + 1); calculateExperience(field.key); }} />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item {...field} label="To Date" name={[field.name, 'toDate']} fieldKey={[field.fieldKey, 'toDate']} rules={[{ required: true, message: 'Missing Date ' }]}>
                              <DatePicker
                                style={{ width: '100%' }}
                                onFocus={experienceDetailsAffix}
                                onChange={() => { setautoRefresh(prev => prev + 1); calculateExperience(field.key) }} />
                            </Form.Item>
                          </Col>

                          <Col span={5}>
                            <Form.Item {...field} label="Experience(In Years.MM)" name={[field.name, 'experience']} fieldKey={[field.fieldKey, 'experience']}                            >
                              <Input onFocus={experienceDetailsAffix} onBlur={(selected) => reflectExp(selected)} disabled />
                            </Form.Item>
                          </Col>
                          {/* <Col span={6}>
                                <Form.Item {...field}
                                  initialValue={calculateExperience()}
                                  label="Experience" name={[field.name, 'experience']} fieldKey={[field.fieldKey, 'experience']}
                                >
                                  <Input />
                                </Form.Item>
                              </Col> */}
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onFocus={experienceDetailsAffix} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Experience Details
                      </Button>
                    </Form.Item>
                  </Card>
                )}
              </Form.List>
            </Row>
            <Divider />

            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                {(props.isUpdate === false) &&
                  <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                    Reset
                  </Button>}
              </Col>
            </Row>

          </> : <></>}

        {laboursEmployeeVisible ?
          <><h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Personal Details</h1>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="aadhaarNumber"
                  label="Employee Aadhaar Number"
                  rules={[
                    {
                      required: true,
                      message: "Enter Valid Employee Aadhaar Number", max: 12, min: 12
                    },
                    {
                      pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input placeholder='Employee Aadhaar Number' />
                </Form.Item>
              </Col>

              <Col>
                <br /><br />
                <Button htmlType='button' onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{ color: '#1890ff' }}>
                  Scan Aadhaar
                </Button>
                <Modal
                  className='qr-container'
                  key={'modal' + Date.now()}
                  width={'95%'}
                  style={{ top: 30, alignContent: 'right' }}
                  visible={showQrSacn}
                  onCancel={() => { setShowQrScan(false) }}
                  title={<React.Fragment>
                  </React.Fragment>}
                >
                  {
                    modal == 'QRScan' ? <QrContainer handleScan={handleQrScan} /> : null
                  }

                </Modal>
              </Col>
              <br />
              <br />
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item name="image"
                  rules={[
                    {
                      required: false,
                      message: 'Image is Required'
                    }
                  ]}
                >
                  <Upload {...uploadFieldProps} style={{ width: '100%' }} listType="picture-card" maxCount={1} onChange={e => appendInputData(e.file.name, 'file')}>
                    {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
                  </Upload> <Typography.Text>(Supported formats .jpeg, .png, .jpg)</Typography.Text>
                </Form.Item>

              </Col>
            </Row>

            <Row gutter={24}>
              <Form.Item name="employeeId" style={{ display: "none" }}>
                <Input hidden />
              </Form.Item>
              <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
                <Input hidden />
              </Form.Item>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeCode"
                  label="Employee Code"
                  rules={[
                    {
                      required: false,
                      message: "Enter Employee Code",
                      max: 12
                    },
                    {
                      pattern: /^([A-Z0-9]+)*$/,
                      message: `Should contain alphabets and numbers.`
                    }
                  ]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>

                <Form.Item
                  name="employeeName"
                  label="Employee First Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter Employee First Name"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}
                >
                  <Input addonBefore={prefixSelector} placeholder='First Name' />
                </Form.Item>
              </Col>


              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeLastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter Your  Last Name"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                      message: `Should contain only alphabets.`
                    },
                  ]}>

                  <Input placeholder='Employee Last Name' />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="employeeStatus"
                  label="Employeement Mode"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Employeement Mode'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Employeement Mode"
                    onFocus={jobDetailsAffix}
                    onChange={handelET}
                  >
                    <Option value={"Direct"}>Direct</Option>
                    <Option value={"Contract"}>Contract</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{
                margin: '1%', display: form.getFieldValue('employeeStatus') === 'Contract' ? 'unset' : 'none'
              }}>
                <Form.Item
                  name="contractorName"
                  label="Contractor Name"
                  rules={[
                    {
                      required: form.getFieldValue('employeeStatus') === 'Contract' ? true : false,
                      message: "Enter a Contractor Name",
                      max: 10
                    }
                  ]}>
                  <Select
                    placeholder="Select Contractor Name"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {contractorData.map(dropData => {
                      return <Option key={dropData.contractorId} value={dropData.contractorName}>{dropData.contractorName}</Option>;
                    })}</Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>

                <Form.Item
                  name="employeeCategory"
                  label="Employee Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Employee Category'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Employeement Mode"
                    onFocus={jobDetailsAffix}
                  >
                    <Option value={"In-House"}>In-House</Option>
                    <Option value={"Outside"}>Outside</Option>
                    <Option value={"Staff"}>Staff</Option>
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>

                <Form.Item
                  name="wageType"
                  label="Wage Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Wage Type'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Wage Type"
                    onFocus={jobDetailsAffix}
                  >
                    <Option value={"Day Based"}>Day Based</Option>
                    <Option value={"KG Based"}>KG Based</Option>
                    <Option value={"Monthly Based"}>Monthly Based</Option>
                  </Select>

                </Form.Item>
              </Col>

              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="famAadhaarNo"
                  label="Aadhaar Number"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Adhaar Number",
                      max: 12, min: 12
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="mobileNumber"
                  label="Mobile Phone"
                  rules={[
                    {
                      required: false,
                      min: 10,
                      max: 12
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="dateOfBirth"
                  label="Date Of Birth"
                  rules={[
                    {
                      required: false,
                    }
                  ]}>
                  <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" showToday={false} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Gender",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Gender"
                  >
                    <Option key={0} value={null}>Select Gender</Option>
                    <Option key={1} value={'Male'}>Male</Option>
                    <Option key={2} value={'Female'}>Female</Option>
                    <Option key={3} value={'Other'}>Other</Option>
                  </Select>}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="maritualStatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Marital Status  ",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Marital Status"
                  >
                    <Option key={1} value={'Married'}>Married</Option>
                    <Option key={2} value={'UnMarried'}>UnMarried </Option>
                  </Select>}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="emailID"
                  label="Email Id"
                  rules={[
                    {
                      required: false,
                      type: 'email',
                      message: "Please enter your email Id",
                      max: 45
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bloodGroup"
                  label="Blood Group"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Blood Group ",
                      max: 10
                    }
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Blood Group"
                  >
                    <Option key={1} value={'O+'}>O+</Option>
                    <Option key={2} value={'O-'}>O-</Option>
                    <Option key={3} value={'A+'}>A+</Option>
                    <Option key={4} value={'A-'}>A-</Option>
                    <Option key={5} value={'B+'}>B+</Option>
                    <Option key={6} value={'B-'}>B-</Option>
                    <Option key={7} value={'AB+'}>AB+</Option>
                    <Option key={8} value={'AB-'}>AB-</Option>

                  </Select>}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Nationality"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Input defaultValue="Indian" />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="departmentId"
                  label="Department"

                  rules={[
                    {
                      required: false,
                      message: 'Department is required'
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {departmentsData?.map(dropData => {
                      return <Option key={dropData.deptId} value={dropData.deptId}>{dropData.deptName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      required: false,
                      message: "Select Designation "
                    },
                  ]}>

                  <Select
                    showSearch
                    placeholder="Select designation"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                    onFocus={personalDetailsAffix}
                  >
                    {designationData.map(dropData => {
                      return <Option value={dropData.designation}>{dropData.designation}</Option>;
                    })}</Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="unitId"
                  label="Unit"
                  rules={[
                    {
                      required: false, message: 'Select Unit',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Unit"
                    disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                    // onChange={handlePlant}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {plantData.map(dropData => {
                      return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                    })}
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="shiftGroup"
                  label="Shift Group"
                  rules={[
                    {
                      required: false,
                      message: "Select Shift Group",
                    }
                  ]}>
                  {<Select
                    showSearch
                    //style={{ width: 210 }}
                    placeholder="Select Shift Group"
                    optionFilterProp="children"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    allowClear
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {shiftGroupData.map((shiftGroupData) => {
                      return <Option key={shiftGroupData.id} value={shiftGroupData.shiftCode}>{shiftGroupData.shiftCode}</Option>
                    })}
                  </Select>
                  }
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="priorityOne"
                  label="Priority One"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Priority One"
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Priority One"
                    optionFilterProp="children"
                    // onChange={handleOperation}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {operations.map((workstationCategory) => {
                      return <Option key={workstationCategory.operationId} value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                    })}
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="priorityType"
                  label="Priority Two"
                  rules={[
                    {
                      required: false,

                      message: "Please enter your Priority Type"
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Priority Two"
                    optionFilterProp="children"
                    // onChange={handleOperation}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {operations.map((workstationCategory) => {
                      return <Option key={workstationCategory.operationId} value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>


              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="routeNumber"
                  label="Route Number"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Route Number"
                    },

                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Route Number"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {routeData?.map(dropData => {
                      return <Option key={dropData.routeId} value={dropData.routeNumber}>{dropData.routeNumber}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="pickUp"
                  label="Pickup point"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Pickup Point"
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Pickup Point"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {pickUpData?.map(dropData => {
                      return <Option key={dropData.pickUpId} value={dropData.pickUp}>{dropData.pickUp}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="reportingManager"
                  label="Reporting Manager"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Reporting Manager",
                      max: 25
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder="Select Reporting Manager"
                    // onChange={}
                    allowClear
                    style={{ width: '100%' }}
                    onFocus={onFocus}
                  >
                    {designationData.map(dropData => {
                      return <Option value={dropData.designation}>{dropData.designation}</Option>;
                    })}</Select>

                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="audit"
                  label="Is Audit ?"
                  rules={[
                    {
                      required: false,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Audit Period"
                    onFocus={jobDetailsAffix}
                    defaultValue={'No'}
                  >
                    <Option key={1} value={'Yes'}>Yes</Option>
                    <Option key={2} value={'No'}>No </Option>
                  </Select>}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="currentAddress"
                  label="Current Address"
                  rules={[
                    {
                      required: true,
                      //   pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      //   message: `Don't Allow Spaces`
                      max: 500
                    }
                  ]}>
                  <TextArea onFocus={personalDetailsAffix} rows={4} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    allowClear={true}
                  >
                    {Object.keys(IndiaStatesEnum).map((type) => {
                      return <Option value={IndiaStatesEnum[type]}>{IndiaStatesEnum[type]}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="pinCode"
                  label="PIN Code"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Pin Code number",
                      max: 12
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item name='sameAsCurAddress'>
                <Checkbox onChange={setAddress} style={{ paddingRight: '250px' }}>Same as Current Address</Checkbox>
              </Form.Item>
            </Row>
            <Row>
              {/* <Col xs={{span:10}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}  style={{margin:'1%'}}> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentAddress" label="Permanent address"
                  rules={[
                    {
                      required: true,
                      //   pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      //   message: `Don't Allow Spaces`
                      max: 500
                    }
                  ]}>
                  <TextArea onFocus={personalDetailsAffix} rows={4} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentState"
                  label="State"
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    allowClear={true}
                  >
                    {Object.keys(IndiaStatesEnum).map((type) => {
                      return <Option value={IndiaStatesEnum[type]}>{IndiaStatesEnum[type]}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="permanentPinCode"
                  label="PIN Code"
                  rules={[
                    {
                      required: true,
                      message: "Enter a valid Pin code number",
                      max: 12
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input onFocus={personalDetailsAffix} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>Other Details</h1><br />

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>

                <Form.Item
                  name="uanNumber"
                  label="UAN Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid UAN number",
                      max: 14
                    },
                    {
                      pattern: /^[0-9 ]*$/,
                      message: `Should contain only numbers.`,
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="panNumber"
                  label="PAN Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid PAN Number",
                      max: 12
                    },
                    {
                      pattern: /^([A-Z0-9]+)*$/,
                      message: `Should contain only alphabets and Numbers.`
                    }
                  ]}>
                  <Input max={10} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="esiNumber"
                  label="ESI Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid ESI number",
                      max: 20
                    },
                    {
                      pattern: /^[^-\s\\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9_\s-]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bankAccountNumber"
                  label="Bank Account Number"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Bank account number",
                      max: 16
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: `Should contain only numbers.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="bankName"
                  label="Bank Name"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid Bank Name",
                      max: 25
                    },
                    {
                      pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                      message: `Should contain only alphabets.`
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="ifscCode"
                  label="IFSC Code"
                  rules={[
                    {
                      required: false,
                      message: "Enter a valid IFSC Code",
                      max: 15
                    }
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="skillSet"
                  label="Skill Set"
                  rules={[
                    {
                      required: false,
                      message: 'This is a mandatory field',
                    }
                  ]}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    placeholder="Select SkillSet"
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {skillSetData.map(dropData => {
                      return <Option value={dropData.skills}>{dropData.skills}</Option>;
                    })}
                  </Select>
                  <br />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item
                  name="accommodation"
                  label="Accommodation"
                  rules={[
                    {
                      required: false,
                      message: "This field is mandatory"
                    },
                  ]}>
                  {<Select
                    showSearch
                    placeholder="Select Accommodation"
                  >
                    <Option key={1} value={'inHouse'}>In House</Option>
                    <Option key={2} value={'outSide'}>Out Side </Option>
                  </Select>}
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <h1 style={{ color: 'grey', fontSize: '25px', textAlign: 'left' }}>ID Proof Details</h1><br />

            <Row gutter={24}>
              <Form.List name="idProofDetails">
                {(fields, { add, remove }) => (
                  <Card>
                    {fields.map(field => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item name={[field.name, 'employeeIdProofId']} fieldKey={[field.fieldKey, 'employeeIdProofId']}>
                          <Input hidden />
                        </Form.Item>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item {...field} label="ID Type" name={[field.name, 'idType']} fieldKey={[field.fieldKey, 'idType']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing ID Type'
                                }
                              ]}>
                              <Select showSearch
                                placeholder="Select ID Type"
                                onFocus={idProofDetailsAffix}
                                onSelect={setId}
                                onChange={setId}
                              >
                                <Option key={1} value={'Aadhaar Card'}>Aadhaar Card</Option>
                                <Option key={2} value={'Pan Card'}>Pan Card </Option>
                                <Option key={3} value={'Driving Licence'}>Driving Licence</Option>
                                <Option key={4} value={'Passport'}>Passport</Option>
                                <Option key={5} value={'Ration Card'}>Ration Card </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item {...field} label="ID Number" name='idNumber' fieldKey={[field.fieldKey, 'idNumber']}
                              rules={[
                                { required: true, message: ' Valid id No is required', max: selectedId ? 12 : 16 },
                                {
                                  pattern: selectedId ? numeric : alphaNumeric,
                                  message: selectedId ? 'Only Numbers are accepted' : 'Only AlphaNumeric are accepted'
                                }
                              ]}
                            >
                              <Input onFocus={idProofDetailsAffix} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onFocus={idProofDetailsAffix} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add ID Proof Details
                      </Button>
                    </Form.Item>
                  </Card>
                )}
              </Form.List>
            </Row>
            <Divider />

            <Row>
              <Col span={40} style={{ textAlign: 'right' }}>

                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                {(props.isUpdate !== true) &&
                  <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                    Reset
                  </Button>}
              </Col>
            </Row>

          </> : <></>}

      </Form>
    </Card>
  )
}

export default HrmsEmployeeDetailsForm;
