import { Button, Card, Col, Progress, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import "./mainpage.css";
import { AssetService } from '@gtpl/shared-services/asset-management';
import { Excel } from 'antd-table-saveas-excel';
import excel from '../../../assets-dashboard/src/lib/excel.png'

interface IProps {
    maintenanceData: any[]
}
const AssetCategoryWiseMaintainanceDetailsChart = (props: IProps) => {
    const { maintenanceData } = props
    const [categoryWiseData, setCategoryWiseData] = useState([]);
    const [hoveredCard, setHoveredCard] = useState(null);

    const assetCatWiseMaintainaceService = new AssetService();

    useEffect(() => {
        getAssetCategoryWiseData();
    }, []);

    const getAssetCategoryWiseData = () => {
        assetCatWiseMaintainaceService.getCategoryWiseData().then((res) => {
            if (res.status) {
                setCategoryWiseData(res.data);
            } else {
                setCategoryWiseData([]);
            }
        }).catch((error) => {
            console.log(error.message);
        });
    };

    const getApplicableAssetsTotal = (fieldName) => {
        if (categoryWiseData?.length) {
            const total = categoryWiseData?.reduce((a, c) => a + Number(c?.[fieldName]), 0)
            return total
        } else {
            return 0
        }
    }

    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('assetCount')
            .addColumns(data)
            .addDataSource(categoryWiseData, { str2num: true })
            .saveAs('asset-Category-wise-count.xlsx');
    }
    const data: any[] = [
        {
            title: 'S NO',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'CATEGORY ',
            dataIndex: 'itemSubCategory'
        },
        {
            title: 'AMC ',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'amcyesCount',
                    key: 'amcyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'amcNOCount',
                    key: 'amcNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'amcNACount',
                    key: 'amcNACount',
                },
            ]
        },
        {
            title: 'WARRANTY',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'warrentyYesCount',
                    key: 'warrentyYesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'warrentNOCount',
                    key: 'warrentNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'warrentyNACount',
                    key: 'warrentyNACount',
                },
            ]
        },
        {
            title: 'INSURANCE',
            children: [
                {
                    title: 'YES',
                    dataIndex: 'insuranceyesCount',
                    key: 'insuranceyesCount',
                },
                {
                    title: 'NO',
                    dataIndex: 'insuranceNOCount',
                    key: 'insuranceNOCount',
                },
                {
                    title: 'N/A',
                    dataIndex: 'insuranceNACount',
                    key: 'insuranceNACount',
                },
            ]
        },


    ];
    

    const amcApplicable = getApplicableAssetsTotal('amcyesCount') + getApplicableAssetsTotal('amcNOCount')
    const amcApplicableCost = getApplicableAssetsTotal('amcyesCost') + getApplicableAssetsTotal('amcNOCost')
    const warrantyApplicable = getApplicableAssetsTotal('warrentyYesCount') + getApplicableAssetsTotal('warrentNOCount')
    const warrantyApplicableCost = getApplicableAssetsTotal('warrentyYesCost') + getApplicableAssetsTotal('warrentyNOCost')
    const insuranceApplicable = getApplicableAssetsTotal('insuranceyesCount') + getApplicableAssetsTotal('insuranceNOCount')
    const insuranceApplicableCost = getApplicableAssetsTotal('insuranceyesCost') + getApplicableAssetsTotal('insuranceNOCost')
    const totalLicense = getApplicableAssetsTotal('amcyesCount') + getApplicableAssetsTotal('insuranceyesCount') + getApplicableAssetsTotal('warrentyYesCount')

    const deductions = (keys: string) => {

        if (maintenanceData?.length) {
            const assetMaintenanceDetails = maintenanceData?.map((rec) => { return rec?.assetMaintenanceDetails })
            console.log(assetMaintenanceDetails, "assetMaintenanceDetails")
            const filterData = assetMaintenanceDetails?.map((rec) => rec.filter((rec) => rec?.maintenanceType === keys))
            console.log(filterData, "filterData")

            const totalMaintenanceDue = filterData?.reduce((a, c) => a + Number(c[0]?.maintenanceDue), 0)
            console.log(totalMaintenanceDue, "totalMaintenanceDue")

            return totalMaintenanceDue
        } else {
            return 0
        }

    }

    return <>
        <Row gutter={24}>
            <Col span={24}>
                <Card style={{ backgroundColor: '#0d092b', borderRadius: '8px' }}>
                    <Row gutter={24} >
                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }}>AMC Uncovered</h3>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0' }}>Assets</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }}>{getApplicableAssetsTotal('amcNOCount')}<span className="divider">/ ₹</span>{getApplicableAssetsTotal('amcNOCost') ? getApplicableAssetsTotal('amcNOCost').toLocaleString('en-IN') : 0}</h3>
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }}  >Warranty Uncovered </h3>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0' }} >Assets</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }}>{getApplicableAssetsTotal('warrentNOCount')}<span className="divider">/ ₹</span>{getApplicableAssetsTotal('warrentyNOCost') ? getApplicableAssetsTotal('warrentyNOCost').toLocaleString('en-IN') : 0}</h3>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }} >Insurance Uncovered </h3>
                                <h3 style={{ color: "white", fontSize: '16px', fontWeight: 600, margin: '0' }} >Assets</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }}>{getApplicableAssetsTotal('insuranceNOCount')}<span className="divider">/ ₹</span>{getApplicableAssetsTotal('insuranceNOCost') ? getApplicableAssetsTotal('insuranceNOCost').toLocaleString('en-IN') : 0}</h3>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#F4DF4EFF', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }} >Total AMC Dues </h3>
                                <br />
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }} >This Month</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: "16px", fontWeight: 600, margin: '0' }} >
                                    {12}<span className="divider">/ ₹</span>{'3,42,567'}
                                </h3>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#F4DF4EFF', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }}>Total Insurance Dues</h3>
                                <br />
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }}>This Month</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: "16px", fontWeight: 600, margin: '0' }} >
                                    {8}<span className="divider">/ ₹</span>{'8,97,800'}
                                </h3>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50%', width: '100%', backgroundColor: '#F4DF4EFF', marginBottom: '0px', textAlign: 'center', marginTop: '0px', paddingTop: '20px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0', marginBottom: '15px' }} >Total Warranty Dues</h3>
                                <br />
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }}>This Month</h3>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px', marginBottom: '15px' }}>
                                <h3 style={{ color: "black", fontSize: '16px', fontWeight: 600, margin: '0' }} >
                                    {6}<span className="divider">/ ₹</span>{'5,67,560'}
                                </h3>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

        <br />
        <br />
        <Row gutter={24}>
      <Col xs={24} lg={18}>
        <Card
          title="Category Wise Maintenance Details"
          style={{
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            height: "500px",
            textAlign: 'center'
          }}
          headStyle={{ backgroundColor: '#114232', color: 'white' }}
          extra={
            <Tooltip title="Export As Excel">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '5px',
                  height: '35px',
                  width: '35px'
                }}
              >
                <img src={excel} style={{ width: 40, height: 40 }} alt=""     onClick={exportExcel } />
              </div>
            </Tooltip>
          }
        >
          <Card style={{ backgroundColor: '#AFD198', height: '80px' }}>
            <Row gutter={18}>
              <Col span={2}><h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>S No</h1></Col>
              <Col span={4}><h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>CATEGORY</h1></Col>
              <Col span={6}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>AMC</h1>
                </Row>
                <Row style={{ paddingTop: '8px' }}>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>YES</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>No</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>N/A</h1></Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>WARRANTY</h1>
                </Row>
                <Row style={{ paddingTop: '8px' }}>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>YES</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>No</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>N/A</h1></Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>INSURANCE</h1>
                </Row>
                <Row style={{ paddingTop: '8px' }}>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>YES</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>No</h1></Col>
                  <Col span={8}><h1 style={{ fontSize: '15px', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>N/A</h1></Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
            {categoryWiseData?.map((raci, index) => (
              <Card
                key={index}
                style={{
                  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                  height: '80px',
                  backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white'
                }}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <Row gutter={16} style={{ borderStyle: 'solid', color: 'black', borderWidth: '0px', marginBottom: '10px', borderRadius: '5px' }}>
                  <Col span={1}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold', wordWrap: 'break-word', wordBreak: 'break-all' }}>{index + 1}</h1>
                  </Col>
                  <Col span={5}>
                    <h1 style={{ fontSize: '14px', wordWrap: 'break-word', wordBreak: 'break-all',whiteSpace: 'nowrap' }}>{raci.itemSubCategory}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold',color:"#0B6623"}}>{raci.amcyesCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold', color:"red"}}>{raci.amcNOCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>{raci.amcNACount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold',color:"#0B6623" }}>{raci.warrentyYesCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold',color:"red" }}>{raci.warrentNOCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>{raci.warrentyNACount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold',color:"#0B6623" }}>{raci.insuranceyesCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold',color:"red" }}>{raci.insuranceNOCount}</h1>
                  </Col>
                  <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>{raci.insuranceNACount}</h1>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </Card>
      </Col>
      <Col xs={24} lg={6}>
        <Card style={{ backgroundColor: '#0d092b', borderRadius: '8px' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={24}>
              <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '100%', backgroundColor: '#114232', marginBottom: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "white", fontSize: '16px', fontWeight: 600 }}>AMC Applicable Assets</a>
              </div>
              <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }}>{amcApplicable}<span className="divider">/ ₹</span>{amcApplicableCost ? amcApplicableCost.toLocaleString('en-IN') : 0}</a>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={24}>
              <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '100%', backgroundColor: '#114232', marginBottom: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "white", fontSize: '16px', fontWeight: 500 }}>Warranty Applicable Assets</a>
              </div>
              <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "black", fontSize: '16px', fontWeight: 500 }}>{warrantyApplicable}<span className="divider">/ ₹</span>{warrantyApplicableCost ? warrantyApplicableCost.toLocaleString('en-IN') : 0}</a>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={24}>
              <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '50px', width: '100%', backgroundColor: '#114232', marginBottom: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "white", fontSize: '16px', fontWeight: 500 }}>Insurance Applicable Assets</a>
              </div>
              <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '70px', width: '100%', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '20px' }}>
                <a style={{ color: "black", fontSize: '16px', fontWeight: 600 }}>{insuranceApplicable}<span className="divider">/ ₹</span>{insuranceApplicableCost ? insuranceApplicableCost.toLocaleString('en-IN') : 0}</a>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    </>



}

export default AssetCategoryWiseMaintainanceDetailsChart;