
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { RelationDto } from '@gtpl/shared-models/masters';
import { RelationService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";

import './relations.css';

/* eslint-disable-next-line */
export interface RelationsProps {
  relationData: RelationDto;
  updateRelation: (relation: RelationDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function Relations(props: RelationsProps) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const relationService = new RelationService()

  const createRelation = (relationData: RelationDto) => {
    setDisable(true)
    relationService.createRelation(relationData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Relation Created Successfully');
        history.push("/relations-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();

  }

  const saveData = (values: RelationDto) => {
    setDisable(false)
    if (props.isUpdate) {
      console.log(values)
      props.updateRelation(values);
    } else {
      setDisable(false)
      console.log(values)
      createRelation(values);
    }

  };

  return (
    <Card title={<span style={{ color: 'white' }}> Relations</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/relations-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.relationData} name="control-hooks" onFinish={saveData}>
        <Form.Item name="relationId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 5 }}>
            <Form.Item
              name="relation"
              label="Relation Name"
              rules={[
                {
                  required: true, message: 'Relation Name is required',max:20

                },
                {
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" disabled={disable} htmlType="submit" >
            Submit
          </Button>
          <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>

        </Col>
      </Form>
    </Card>
  );
}

export default Relations;