import React, { useEffect, useState } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button } from 'antd';
import './documents.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import {
  FactoriesInput,
  PlantInvoiceDetailsModel,
  PlantInvoiceDetailsRequest,
  SaleOrderDetailViewInfoDTO,
  UnitsOfWeightInput,
} from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { UomEnum } from '@gtpl/shared-models/common-models';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

/* eslint-disable-next-line */
export interface IsfWorksheetProps {
  saleOrderId: number;
  soData: SaleOrderDetailViewInfoDTO;
}

export function IsfWorksheet(
  props: IsfWorksheetProps
) {
  const salOrderService = new SaleOrderService();
  const [plantDetails, setPlantDetails] = useState<PlantInvoiceDetailsModel>();
  const [singleproductData, setSingleProductData] = useState<any[]>([]);

  let totalCases = 0;
  let totalGrossWeight = 0;
  let totalLbWeight = 0;
  let totalAmount = 0;
  let lbPouchWeight
  let kgPouchWeight
  let packSize;

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    // document.body.innerHTML = oldPage
    window.print();

  }

  const getData = (saleOrderId) => {
    // const reqObj = new PlantInvoiceDetailsRequest(1);
    salOrderService.getPlantInvoiceDetails({ saleOrderId: saleOrderId }).then((res) => {
      console.log(res);
      if (res.status) {
        console.log(res);
        setPlantDetails(res.data);
        const output = [...new Map(res.data?.saleOrderItems?.map(o => [o.foodTypeName && o.itemName && o.valueAdditionOneIdName && o.valueAdditionTwoIdName && o.valueAdditionThreeIdName && o.valueAdditionFourIdName && o.valueAdditionFiveIdName, o])).values()]
        console.log("output");
        console.log(output);
        setSingleProductData(output);

      } else {
        if (res.intlCode) {
          setPlantDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPlantDetails(undefined);
      });
  };

  useEffect(() => {
    console.log('kkk');
    if (props.saleOrderId) {
      getData(props.saleOrderId);
    }
  }, [])


  let exporterDetails = ExporterDataInput;
  let exporterId = 1;
  const exporterData = exporterDetails.find(
    (item) => item.value == plantDetails?.exporterId ? plantDetails.exporterId : exporterId
  );


  let factoryDetails = FactoriesInput;
  let factoryId = 3;
  const unitsData = factoryDetails.find(
    (item) => item.id == plantDetails?.unitId ? plantDetails.unitId : factoryId
  );


  let uom;
  let outputWeight = '';
  plantDetails?.saleOrderItems?.map(item => {
    totalCases += item.cases;
    totalGrossWeight += Number(item.grossWeight);
    console.log(plantDetails)
    console.log(item.cases)
    console.log(item.grossWeight)
    console.log(totalGrossWeight)

    uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
    if (uom.name == UomEnum.LB || uom.name == UomEnum.OZ) {
      outputWeight = UomEnum.LB;
    } else if (uom.name == UomEnum.KG || uom.name == UomEnum.Gms) {
      outputWeight = UomEnum.KG;``
    }

    lbPouchWeight = Number(plantDetails?.saleOrderItems[0].pouchWeight)
    kgPouchWeight = Number(plantDetails?.saleOrderItems[0].pouchWeight)

    if (uom.name == UomEnum.OZ) {
      lbPouchWeight = lbPouchWeight * 0.0625
    }
    if (uom.name == UomEnum.Gms) {
      kgPouchWeight = kgPouchWeight * 0.001
    }

    // packSize = item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')+' = '+Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')+' PER CARTON'
    //  packSize =  (plantDetails?.saleOrderItems[0].pouches + " * " + (Number(plantDetails?.saleOrderItems[0].pouchWeight)).toFixed(2) + " " + outputWeight?.toLocaleUpperCase('en-US') + " = " + (Number(plantDetails?.saleOrderItems[0].pouchWeight) * Number(plantDetails?.saleOrderItems[0].pouches)).toFixed(2) +" " + outputWeight?.toLocaleUpperCase('en-US')+" PER CARTON")

    //  const grouped = groupBy(plantDetails.saleOrderItems,items => [items.pouches,items.pouchWeight])

  });


  const grouped = plantDetails?.saleOrderItems?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.pouches === value.pouches && t.pouchWeight === value.pouchWeight
    ))
  )



  const productGrouped = plantDetails?.saleOrderItems?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.itemName === value.itemName && t.varientCode === value.varientCode
    ))
  )



  return (
    // <Card title={<span style={{color:'white'}}>ISF Worksheet</span>}
    // style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Button>Print</Button>}>
    <>
      <span style={{ alignContent: 'left', backgroundColor: 'red' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="ISFWorksheet"
          sheet="ISFWorksheet"
          buttonText="Get Excel" />
      </span>
      <br />
    <html>
      <head>
      {/* <style type="text/css" media="print">
         {" @page { size: landscape; } "}
       </style> */}
      </head>
      <body id='printme' className='isf-worksheet-print'>

        <table className={'ta-b'} style={{ width: '100%', border: '1px black solid' }} id="table-to-xls" hidden>
          <tr><td className={'ta-b'} colSpan={12}></td></tr>
          <tr><td className={'ta-b'} colSpan={12}><h1 style={{ textAlign: 'center', fontSize: '32px' }}>10+2 ISP FILING / PRE SHIPMENT ADVISE</h1></td></tr>
          <tr><td className={'ta-b'} colSpan={12}></td></tr>
          <tr><td className={'ta-b'} colSpan={9}></td><td className={'ta-b'}>Date</td><td className={'ta-b'}></td><td className={'ta-b'}>{moment(Date()).format('DD-MMM-YYYY')}</td></tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>SUPPLIERS (SELLER)  NAME:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{(exporterData?.name) ? exporterData?.name : ''}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>PO NUMBER:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>{plantDetails?.custPoNo}</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>SUPPLIERS (SELLER) ADDRESS:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}></td>
            <td className={'ta-b'} colSpan={4}>{(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : '' + "," + exporterData?.country}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={2}>PURCHASE CONT. #:</td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={8}></td>
            <td className={'ta-b'} colSpan={2}>LC # (if applicable):</td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>MANUFACTURERS NAME:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{exporterData?.name}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>MANUFACTURERS ADDRESS:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.postalCode) ? plantDetails?.invoicedPlant?.postalCode : "") + "," + ((plantDetails?.invoicedPlant?.distirctName) ? plantDetails?.invoicedPlant?.distirctName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}></td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.stateName) ? plantDetails?.invoicedPlant?.stateName : "") + "," + ((plantDetails?.invoicedPlant?.countryName) ? plantDetails?.invoicedPlant?.countryName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>

          {
            productGrouped?.length ?

              productGrouped?.map((item, index) => {
                let latinName = item.itemName === "Vannamei" ? "LITOPENAEUS VANNAMEI" : item.itemName === "Black Tiger" || "Sea Tiger" ? "PENAEUS MONODON" : "";
                let shrimp = "SHRIMPS";

                console.log('item', item.itemName)
                return <tr className={'ta-b'}>
                  <td colSpan={2} className={'ta-b'}>{index === 0 ? "PRODUCT DESCRIPTION:" : ""}</td>
                  <td></td>
                  <td colSpan={4} className={'ta-b'} style={{ textAlign: 'left', alignContent: 'center' }}>

                    {[...new Map(productGrouped?.map(o => [o.foodTypeName && o.itemName && o.valueAdditionOneIdName && o.valueAdditionTwoIdName && o.valueAdditionThreeIdName && o.valueAdditionFourIdName && o.valueAdditionFiveIdName, o])).values()]?.map((i, index) => {
                      console.log(i, "1111111")
                      let data = ((i?.foodTypeCode.charAt(0) == "C") ? 'COOKED' : i?.foodTypeName) + ' FROZEN ' + ((i.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + i?.valueAdditionOneIdName + ' ' + (
                        (!(([
                          'HLSO', 'HOSO', 'EZPeel', 'N/A'
                        ].indexOf(i.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                      ) + i?.valueAdditionTwoIdName + '' + i?.valueAdditionThreeIdName + ' ' + (i?.valueAdditionFiveIdCode ? i?.valueAdditionFiveIdName : '') + ' ' + i?.itemName + ' ' + shrimp.toUpperCase() + ',' + i?.valueAdditionFourIdName
                      return data
                      // <br />
                      // <br />
                    })}
                  </td>
                  <td className={'ta-b'}></td>
                  <td className={'ta-b'} colSpan={2}>{index === 0 ? "QTY SHIPPED:" : ""}</td>
                  <td className={'ta-b'} colSpan={2}>{index === 0 ? (Math.round(totalGrossWeight)).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : ''}</td>
                </tr>;
              })
              : <tr></tr>}
          <tr className={'ta-b'}>
            <td className={'ta-b'} colSpan={2}>COMMODITY HTSUS NUMBER:</td>
            <td className={'ta-b'} colSpan={6}></td>
            <td className={'ta-b'}>PACK SIZE: </td>
            <td className={'ta-b'} colSpan={3}>{packSize}
              {/* {plantDetails?.saleOrderItems[0].pouches + " * " +  ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')+ " = " + Number(plantDetails?.saleOrderItems[0].caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG')+" PER CARTON"} */}
            </td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>COUNTRY OF ORIGIN:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>INDIA</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={8}></td>
            <td className={'ta-b'}>BRAND :<span style={{textAlign:'right'}}>{plantDetails?.saleOrderItems[0]?.displayBrandName?.toUpperCase()}</span> </td>
            <td className={'ta-b'} colSpan={3}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}>CONTAINER STUFFING LOCATION</td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={12}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}>CONSOLIDATOR NAME AND ADDRESS:</td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}></td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.postalCode) ? plantDetails?.invoicedPlant?.postalCode : "") + "," + ((plantDetails?.invoicedPlant?.distirctName) ? plantDetails?.invoicedPlant?.distirctName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={3}></td>
            <td className={'ta-b'} colSpan={4}>{((plantDetails?.invoicedPlant?.stateName) ? plantDetails?.invoicedPlant?.stateName : "") + "," + ((plantDetails?.invoicedPlant?.countryName) ? plantDetails?.invoicedPlant?.countryName : "")}</td>
            <td className={'ta-b'} colSpan={5}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>ESTIMATED DEPARTURE DATE:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{(plantDetails?.shippedOnboardDate) ? moment(plantDetails?.shippedOnboardDate).format('DD-MMM-YYYY') : ""}</td>
            <td className={'ta-b'} colSpan={2}>CONTAINER #:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>{plantDetails?.saleOrderItems[0].containerNo}</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>ACTUAL SHIPMENT DATE:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{(plantDetails?.shippedOnboardDate) ? moment(plantDetails?.shippedOnboardDate).format('DD-MMM-YYYY') : ""}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={2}>BOOKING #:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>{plantDetails?.saleOrderItems[0].blNo}</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>SHIPPING AGENT</td>
            <td className={'ta-b'} colSpan={6}></td>
            <td className={'ta-b'}>HOUSE BL #:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>-</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>VESSEL NAME:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={4}>{plantDetails?.vesselName}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={2}>MASTER BL #:</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'}>{plantDetails?.trackingNo}</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={4}>ETA PORT OF FIRST CALL</td>
            <td className={'ta-b'} colSpan={4}>: &ensp;{(plantDetails?.portOfDischarge ? (plantDetails.portOfDischarge + ',') : '') + (plantDetails?.country ? plantDetails?.country : '')}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={2}>SEAL #:</td>
            <td className={'ta-b'}>{plantDetails?.saleOrderItems[0].linearSelaNo}</td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={4}>ETA PORT OF FINAL DESTINATION </td>
            <td className={'ta-b'} colSpan={4}>: &ensp;{(plantDetails?.saleOrderItems[0]?.destinationDetails ? (plantDetails?.saleOrderItems[0]?.destinationDetails + ',') : '') + (plantDetails?.country ? plantDetails?.country : '')}</td>
            <td className={'ta-b'} colSpan={4}></td>
          </tr>
          <tr><td className={'ta-b'} colSpan={12}></td></tr>
          <tr><td className={'ta-b'} style={{ borderBottom: '2px black solid' }} colSpan={12}></td></tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>BUYER NAME AND ADDRESS:</td>
            <td className={'ta-b'} colSpan={5}>{
                    plantDetails?.country === "CANADA" ?
                    <>
                    AZ GEMS CANADA INC:
                    </> : <>AZ GEMS INC</>
                  }</td>
            <td className={'ta-b'} colSpan={2}>Importer of Record #: </td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}></td>
            <td className={'ta-b'} colSpan={5}>{plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[0] + ","}</td>
            <td className={'ta-b'}></td>
            <td className={'ta-b'} colSpan={2}>Importer of Record #:</td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}></td>
            <td className={'ta-b'} colSpan={4}>{plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
            </td>
            <td className={'ta-b'} colSpan={2}>Consignee Number:</td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={2}>SHIP TO NAME AND ADDRESS:</td>
            <td className={'ta-b'} colSpan={10}></td>
          </tr>
          <tr>
            <td className={'ta-b'} colSpan={8}></td>
            <td className={'ta-b'} colSpan={2}>PLEASE EMAIL TO:</td>
            <td className={'ta-b'} colSpan={2}></td>
          </tr>
        </table>

        -----------------------------------------------------------------------------------
        <br></br>
        <br></br>
        <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xlsx">
          <tr><td className={'ta-b'} colSpan={6}><h1 style={{ textAlign: 'center', fontSize: '32px' }}>10+2 ISP FILING / PRE SHIPMENT ADVISE</h1></td></tr>
          <tr>
            <td className={'ta-b'} rowSpan={2}>
              <h4>SUPPLIERS(SELLER)NAME:</h4>
              {(exporterData?.name) ? exporterData?.name : ''}<br /><br />
              <h4>SUPPLIERS(SELLER)ADDRESS:</h4>
              {(exporterData?.addressOne) ? exporterData?.addressOne + ',' : ''}
              <br />
              {(exporterData?.addressTwo) ? (exporterData?.addressTwo) + ' - ' + exporterData?.postalCode : '' + "," + exporterData?.country}<br />
            </td>
            <td colSpan={3} className={'ta-b'}>
              <b style={{ backgroundColor: '#585858', color: '#FFFFFF' }}>DATE :</b>&nbsp;&nbsp;&nbsp; {moment(Date()).format('DD-MMM-YYYY')}
            </td>

          </tr>
          <tr>
            <td colSpan={3} className={'ta-b'}>
              PO NUMBER: {plantDetails?.custPoNo}
            </td>
          </tr>

          <tr>
            <td className={'ta-b'}>
              <h4>MANUFACTURERS NAME:</h4>
              {exporterData?.name}<br /><br />
              <h4>MANUFACTURERS ADDRESS:</h4>
              {((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "")}<br />
              {((plantDetails?.invoicedPlant?.postalCode) ? plantDetails?.invoicedPlant?.postalCode : "") + "," + ((plantDetails?.invoicedPlant?.distirctName) ? plantDetails?.invoicedPlant?.distirctName : "")}<br />
              {((plantDetails?.invoicedPlant?.stateName) ? plantDetails?.invoicedPlant?.stateName : "") + "," + ((plantDetails?.invoicedPlant?.countryName) ? plantDetails?.invoicedPlant?.countryName : "")}
              <h4 style={{ display: 'inline' }}>FDA Regestration Number:</h4>&ensp;{plantDetails?.invoicedPlant?.fdaRegNumber}<br />
              {plantDetails?.invoicedPlant?.feiNumber ?
                <><h4 style={{ display: 'inline' }}>FEI Number:</h4>&ensp;{plantDetails?.invoicedPlant?.feiNumber}</> : <></>}
            </td>
            <td colSpan={3} className={'ta-b'}>
              PURCHASE CONT. #:<br />
              <b style={{ backgroundColor: '#585858', color: '#FFFFFF' }}>LC # (if applicable): </b><br />
            </td>
          </tr>
          <tr>
            <td className={'ta-b'}>
              <h4>PRODUCT DESCRIPTION:</h4>
              {
                productGrouped?.length ?
                  <tr>
                      <>
                        <td colSpan={2} style={{ textAlign: 'left', alignContent: 'center' }}>

                          {plantDetails?.saleOrderItems?.map((i, index) => {
                            console.log(i, "1111111")
                            let shrimp = "SHRIMPS";
                            let data = ((i?.foodTypeCode.charAt(0) == "C") ? 'COOKED' : i?.foodTypeName) + ' FROZEN ' + ((i.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + i?.valueAdditionOneIdName + ' ' + (
                              (!(([
                                'HLSO', 'HOSO', 'EZPeel', 'N/A'
                              ].indexOf(i.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                            ) + i?.valueAdditionTwoIdName + '' + i?.valueAdditionThreeIdName + ' ' + (i?.valueAdditionFiveIdCode ? i?.valueAdditionFiveIdName : '') + ' ' + i?.itemName + ' ' + shrimp.toUpperCase() + ',' + i?.valueAdditionFourIdName
                            return data
                            // <br />
                            // <br />
                          })}
                          {/* {((item.foodTypeCode.toLocaleUpperCase('en-US').charAt(0) == "C") ? 'COOKED' : item.foodTypeName.toLocaleUpperCase('en-US') ) + ' FROZEN ' + ((item.valueAdditionOneIdCode === 'EZPeel') ? ' HEADLESS ' : '') + item.valueAdditionOneIdName.toLocaleUpperCase('en-US') + ' ' + (
                          (!(([
                            'HLSO', 'HOSO', 'EZPeel', 'N/A'
                          ].indexOf(item.valueAdditionOneIdCode)) > -1) ? " AND " : '')
                        ) + item.valueAdditionTwoIdName.toLocaleUpperCase('en-US') + ' ' + item.valueAdditionThreeIdName.toLocaleUpperCase('en-US') + ' ' + ( item.valueAdditionFiveIdCode ? item.valueAdditionFiveIdName.toLocaleUpperCase('en-US') : '') + ' ' + item.itemName.toLocaleUpperCase('en-US') + ' ' + shrimp.toUpperCase() + ',' + item.valueAdditionFourIdName.toLocaleUpperCase('en-US')}<br />
                       <br />   */}
                          {/* {' BRAND : ' + item.brandName.toUpperCase()} */}
                        </td>
                      </>
                    </tr>
                  
                  : " "}
            </td>
            <td colSpan={3} className={'ta-b'}>
              <b style={{ backgroundColor: '#585858', color: '#FFFFFF' }}>QTY SHIPPED: </b> &nbsp;&nbsp;&nbsp;
              {(Math.round(totalGrossWeight )).toFixed(3)} KGS
              <br />
            </td>
          </tr>
          <tr>
            <td rowSpan={2} className={'ta-b'}>
              <h4>COMMODITY HTSUS NUMBER:</h4>
              <br />
              <h4>COUNTRY OF ORIGIN:</h4>
              INDIA<br />
            </td>


            <td colSpan={3} className={'ta-b'}>
              <h4>PACK SIZE:</h4>
              {
                grouped?.length ?
                  grouped.map((item => {
                    uom = UnitsOfWeightInput.find(uom => uom.value == item.uomId);
                    lbPouchWeight = Number(item.pouchWeight)
                    kgPouchWeight = Number(item.pouchWeight)
                    if (uom.name == UomEnum.OZ) {
                      lbPouchWeight = lbPouchWeight * 0.0625
                    }
                    if (uom.name == UomEnum.Gms) {
                      kgPouchWeight = kgPouchWeight * 0.001
                    }
                    return <tr>
                      <td> &nbsp;
                        {item.pouches + ' * ' + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG') + ' = ' + Number(item.caseWeight).toFixed(2) + " " + ((uom.name == UomEnum.LB || uom.name == UomEnum.OZ) ? 'LB' : 'KG') + ' PER CARTON'}
                      </td>
                    </tr>
                  })) : <td></td>
              }
            </td>


          </tr>
          <tr>
            <td colSpan={3} className={'ta-b'}>
              BRAND:
              <h4>{plantDetails?.saleOrderItems[0]?.displayBrandName?.toLocaleUpperCase('en-US')}</h4>
            </td>
          </tr>
          <tr>
            <td className={'ta-b'}>
              <b style={{ backgroundColor: '#585858', color: '#FFFFFF' }}>CONTAINER STUFFING LOCATION:</b><br />
              {((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "") + ",India"}<br />
            </td>
            <td colSpan={3} className={'ta-b'}>

            </td>

          </tr>

          <tr>
            <td className={'ta-b'}>
              {/* <b style={{backgroundColor: '#585858', color: '#FFFFFF'}}>CONTAINER STUFFING LOCATION:</b><br/>
        {((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName :"")}<br/> */}
              <b style={{ backgroundColor: '#585858', color: '#FFFFFF' }}>CONSOLIDATOR NAME AND ADDRESS:</b><br />
              {(exporterData?.name) ? exporterData?.name : ''}<br />
              {((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName : "")}<br />
              {((plantDetails?.invoicedPlant?.postalCode) ? plantDetails?.invoicedPlant?.postalCode : "") + "," + ((plantDetails?.invoicedPlant?.distirctName) ? plantDetails?.invoicedPlant?.distirctName : "")}<br />
              {((plantDetails?.invoicedPlant?.stateName) ? plantDetails?.invoicedPlant?.stateName : "") + "," + ((plantDetails?.invoicedPlant?.countryName) ? plantDetails?.invoicedPlant?.countryName : "")}<br />
            </td>
            <td colSpan={3} className={'ta-b'}>
              {/* <b style={{backgroundColor: '#585858', color: '#FFFFFF'}}>CONSOLIDATOR NAME AND ADDRESS:</b><br/>
        {((plantDetails?.invoicedPlant?.villageName) ? plantDetails?.invoicedPlant?.villageName : "") + "," + ((plantDetails?.invoicedPlant?.mandalName) ? plantDetails?.invoicedPlant?.mandalName :"")}<br/>
        {((plantDetails?.invoicedPlant?.postalCode) ? plantDetails?.invoicedPlant?.postalCode : "" ) + ","+((plantDetails?.invoicedPlant?.distirctName) ? plantDetails?.invoicedPlant?.distirctName : "")}<br/>
        {((plantDetails?.invoicedPlant?.stateName) ? plantDetails?.invoicedPlant?.stateName : "") + ","+((plantDetails?.invoicedPlant?.countryName) ? plantDetails?.invoicedPlant?.countryName : "")}<br/> */}
            </td>
          </tr>


          <tr>
            <td className={'ta-b'}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>ESTIMATED DEPARTURE DATE </h4>
                  </td>
                  <td>: &ensp;{(plantDetails?.shippedOnboardDate) ? moment(plantDetails?.shippedOnboardDate).format('DD-MMM-YYYY') : ""}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>ACTUAL SHIPMENT DATE </h4>
                  </td>
                  <td>: &ensp;{(plantDetails?.shippedOnboardDate) ? moment(plantDetails?.shippedOnboardDate).format('DD-MMM-YYYY') : ""}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>SHIPPING AGENT </h4>
                  </td>
                  <td> &ensp;</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>VESSEL NAME </h4>
                  </td>
                  <td>: &ensp;{plantDetails?.importingVesselName}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>ETA PORT OF FIRST CALL </h4>
                  </td>
                  <td>: &ensp;{(plantDetails?.portOfDischarge ? plantDetails?.portOfDischarge + ',' : '') + (plantDetails?.country ? plantDetails?.country : '')}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>
                    <h4 style={{ display: 'inline', backgroundColor: '#585858', color: '#FFFFFF' }}>ETA PORT OF FINAL DESTINATION</h4>
                  </td>
                  <td>: &ensp;{(plantDetails?.saleOrderItems[0]?.destinationDetails ? plantDetails?.saleOrderItems[0]?.destinationDetails : '') + ',' + (plantDetails?.country ? plantDetails?.country : '')}</td>
                </tr>
              </table>
              {/* <h4>ESTIMATED DEPARTURE DATE: </h4>{moment(plantDetails?.edl).format('Do MMMM, YYYY')}<br/><br/>
        <h4>ACTUAL SHIPMENT DATE: </h4> {moment(plantDetails?.actualEdl).format('Do MMMM, YYYY')}<br/><br/>
        <h4>SHIPPING AGENT: </h4>CMA CGM LYRA VOY-0TUGZE1MA<br/><br/>
        <h4>VESSEL NAME: </h4>
        <h4>ETA PORT OF FIRST CALL: </h4>LOS ANGELES, USA<br/><br/>
        <h4>ETA PORT OF FINAL DESTINATION: </h4>LOS ANGELES, USA<br/><br/> */}
            </td>
            <td colSpan={3} className={'ta-b'}>
              <table>
                <tr style={{ textAlign: 'right' }}>
                  <td>
                    <span style={{ width: '100px', clear: 'left', float: 'left', textAlign: 'right', paddingRight: '2px', backgroundColor: '#585858', color: '#FFFFFF' }}><h4 style={{ display: 'inline' }}>CONTAINER #: </h4></span>
                  </td>
                  <td> &ensp;{plantDetails?.saleOrderItems[0].containerNo}</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ width: '100px', clear: 'left', float: 'left', textAlign: 'right', paddingRight: '2px', backgroundColor: '#585858', color: '#FFFFFF' }}><h4 style={{ display: 'inline' }}>BOOKING #: </h4></span>
                  </td>
                  <td> &ensp;{plantDetails?.saleOrderItems[0].bookingNo}</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ width: '100px', clear: 'left', float: 'left', textAlign: 'right', paddingRight: '2px', backgroundColor: '#585858', color: '#FFFFFF' }}>
                      <h4 style={{ display: 'inline' }}>HOUSE BL #: </h4>
                    </span>
                  </td>
                  <td>  &ensp;-</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ width: '100px', clear: 'left', float: 'left', textAlign: 'right', paddingRight: '2px', backgroundColor: '#585858', color: '#FFFFFF' }}>
                      <h4 style={{ display: 'inline' }}>MASTER BL #: </h4>
                    </span>
                  </td>
                  <td> &ensp;{plantDetails?.trackingNo}</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ width: '100px', clear: 'left', float: 'left', textAlign: 'right', paddingRight: '2px', backgroundColor: '#585858', color: '#FFFFFF' }}>
                      <h4 style={{ display: 'inline' }}>SEAL #: </h4>
                    </span>
                  </td>
                  <td> &ensp;{plantDetails?.saleOrderItems[0].linearSelaNo}</td>
                </tr>
              </table>
              {/* <h4>CONTAINER #: </h4>{plantDetails?.saleOrderItems[0].containerNo}<br/><br/>
        <h4>BOOKING #:</h4>{plantDetails?.saleOrderItems[0].bookingNo}<br/><br/>
        <h4>HOUSE BL #</h4>-<br/><br/>
        <h4>MASTER BL #:</h4>IGC0118248<br/><br/>
        <h4>SEAL #:</h4>{plantDetails?.saleOrderItems[0].linearSelaNo}<br/><br/> */}
            </td>
          </tr>

          <tr>
            <td className={'ta-b'} rowSpan={3}>
              <h4>BUYER NAME AND ADDRESS:</h4>
              {
                    plantDetails?.country === "CANADA" ?
                    <>
                    <b>AZ GEMS CANADA INC:</b>
                    </> : <b>AZ GEMS INC:</b>
                  }<br />
              {plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[0] + ","}<br />
              {plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[1] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[2] + "," + plantDetails?.saleOrderItems[0]?.customeraddress.split(",")[3]}
              <br />FDA REGISTRATION NO. {unitsData?.consigneefdaRegNumber}
              <h4>SHIP TO NAME AND ADDRESS: </h4>
            </td>
            <td colSpan={3} className={'ta-b'}>
              Importer of Record #:
            </td>

          </tr>
          <tr>
            <td colSpan={3} className={'ta-b'}>
              Consignee Number:

            </td>
          </tr>
          <tr>
            <td colSpan={3} className={'ta-b'}>
              Please email to:

            </td>
          </tr>



        </table>
      </body>
    </html>
    </>
    // </Card>
  );
}

export default IsfWorksheet;
