import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {EmploymentStatusForm} from '@gtpl/pages/hrms/hrms-components/employment-status-form';
import {  Link } from "react-router-dom";
import { ColumnProps } from 'antd/lib/table';
import enUSIntl from 'antd/lib/locale/en_US';
import {EmploymentStatService} from '@gtpl/shared-services/hrms'
import './employment-status-grid.css';
import { EmploymentStatusDto } from '@gtpl/shared-models/hrms';
export interface EmploymentStatusGridProps {}

export function EmploymentStatusGrid(props: EmploymentStatusGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [data,setData] = useState<EmploymentStatusDto[]>([]);
  const [slectedEmploymentStatusData, setSelectedEmploymentStatusData] = useState<any>(undefined);
  const service = new EmploymentStatService()
  const [allData, setAllData] = useState<EmploymentStatusDto[]>([]);

  useEffect(() => {
    getAll();
  },[])
   
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase() 
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });


  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */

const getAll = () => {
  service.getAllEmploymentStats().then((res) => {
    if(res.data){
      setData(res.data);
      setAllData(res.data);
}else{
  setAllData([]);
  if(res.intlCode){
    AlertMessages.getErrorMessage(res.internalMessage)
    setData([]);
  }else{
    AlertMessages.getErrorMessage(res.internalMessage)
  }
}
  }).catch((err) => {
    setAllData([]);
    setData([]);
    AlertMessages.getErrorMessage(err.message)
  })
}


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const update = (Data: EmploymentStatusDto) => {
    service.update (Data).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll ();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteEmploymentStatus = (Data:EmploymentStatusDto) => {
    Data.isActive= Data.isActive?false:true;
    service.activateOrDeactivate(Data).then(res => { console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const openFormWithData=(Data: EmploymentStatusDto)=>{
    setDrawerVisible(true);
    setSelectedEmploymentStatusData(Data);
  }

   
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '60px',    
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
      
    },
	{
      title: 'Employment Status',
      dataIndex: 'employmentStatus',
      align:'center',
      width: '190px',  
      sorter: (a, b) => a.employmentStatus?.localeCompare(b.employmentStatus),
      
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employmentStatus')
    },    
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      width: '190px',  
      render: (isActive, rowData) => (
        <>
          {rowData.isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      align:'center',
      width: '190px',  
      render: (text, rowData) => (
        <span>      
             <Tooltip placement="top" title='Edit'> 
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Certification');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
            <Popconfirm onConfirm={e =>{deleteEmploymentStatus(rowData);}}
            
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate EmploymentStatus ?'
                :  'Are you sure to Activate EmploymentStatus ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
    const openFormWithData=(viewData:EmploymentStatusDto)=>{
      setDrawerVisible(true);
      setSelectedEmploymentStatusData(viewData);
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>Employment Status</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/employment-status-view' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
     <Col>
          <Card title={'Total Employment Status: ' +data.length} style={{textAlign: 'left', width: 300, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' +data.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' +data.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 230, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row><br></br>
          <Card >
            <Table
              columns={columnsSkelton}
              rowKey={record => record.EmploymentStatusId}
              dataSource={data}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              scroll = {{x:true}}
              onChange={onChange}
             
                          
            />
      </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <EmploymentStatusForm key={Date.now()}
                
               
                isUpdate={true}
                closeForm={closeDrawer}
                update ={update}
                EmpStatusData={slectedEmploymentStatusData}
            />
            </Card>
          </Drawer>
     </Card>
    </>
  );
}

export default EmploymentStatusGrid;
