import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { CustomerDto, CustomerACcountTypes} from '@gtpl/shared-models/masters';
import {CustomersService} from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import {CustomersForm} from '@gtpl/pages/master/master-components/customers-form';
import { Link } from 'react-router-dom';
import './customers-grid.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface CustomersGridProps {}

export function CustomersGrid(
  props: CustomersGridProps
) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [hrmsData, setHrmsData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const customersService = new CustomersService();
  const [selectedCustomersData, setSelectedCustomersData] = useState<any>(undefined);
  const [customersData, setCustomersData] = useState<CustomerDto[]>([]);

  const openFormWithData=(viewData: CustomerDto)=>{
    console.log(viewData);
    setDrawerVisible(true);
    setSelectedCustomersData(viewData);
  }
  useEffect(() => {
    getAll();
  }, []);

 

  useEffect(() => {
    // getAll();
    console.log(customersData)
  }, [customersData]);
  const getAll= () => {
    customersService.getAllCustomers(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setCustomersData(res.data);
      } else {
        if (res.intlCode) {
          setCustomersData([]);
            // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCustomersData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const deleteProfile = (customersData:CustomerDto) => {
    customersData.isActive=customersData.isActive?false:true;
    customersService.ActivateorDeactivateCustomers(customersData).then(res => { console.log(res);
      if (res.status) {
        getAll();
        // AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      // AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
       render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Customer Name',
      dataIndex:'clientName',
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      sortDirections: ['descend', 'ascend'],
     ...getColumnSearchProps('clientName')
    },
    // {
    //   title: 'GST No',
    //   dataIndex: 'gstNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.gstNumber.length - b.gstNumber.length,
    //  // sorter: (a, b) => a.remarks.length - b.remarks.length,
    //    sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('gstNumber')
    // },
    // {
    //   title: 'Website Name',
    //   dataIndex:'website',
    //   sorter: (a, b) => a.website.length - b.website.length,
    //   sortDirections: ['descend', 'ascend'],
    //  ...getColumnSearchProps('website')
    // },
    // {
    //   title: 'Client Code',
    //   dataIndex: 'clientCode',
    //   //  responsive: ['lg'],
    //    sorter: (a, b) => a.clientCode.length - b.clientCode.length,
    //    sortDirections: ['descend', 'ascend'],
    //     ...getColumnSearchProps('clientCode')
    // },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      // responsive: ['sm'],
      sorter: (a, b) => a.contactPerson?.localeCompare(b.contactPerson),
      sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('contactPerson')
    },
    {
      title: 'Mobile No',
      dataIndex: 'phoneNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.phoneNo.localeCompare(b.phoneNo),

       sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('phoneNo')
    },
    // {
    //   title: 'Email',
    //   dataIndex:'email',
    //   sorter: (a, b) => a.email.length - b.email.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('email')
    // },
    // {
    //   title: 'Street Name',
    //   dataIndex:'street',
    //   sorter: (a, b) => a.street.length - b.street.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('street')
    // },
    // {
    //   title: 'Apartment Name',
    //   dataIndex:'appartment',
    //   sorter: (a, b) => a.appartment.length - b.appartment.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('appartment')
    // },
    // {
    //   title: 'City Name',
    //   dataIndex:'city',
    //   sorter: (a, b) => a.city.length - b.city.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('city')
    // },
    // {
    //   title: 'State Name',
    //   dataIndex:'state',
    //   sorter: (a, b) => a.state.length - b.state.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('state')
    // },
    // {
    //   title: 'Postal Code',
    //   dataIndex:'postalCode',
    //   sorter: (a, b) => a.postalCode.length - b.postalCode.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('postalCode')
    // },
    
    // {
    //   title: 'Country',
    //   dataIndex:'countryId',
    //   sorter: (a, b) => a.countryId.length - b.countryId.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('countryId')
    // },
    // {
    //   title: 'Currency',
    //   dataIndex:'currency',
    //   sorter: (a, b) => a.currency.length - b.currency.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('currency')
    // },
    
    // {
    //   title: 'Payment Terms',
    //   dataIndex:'paymentTerms',
    //   sorter: (a, b) => a.paymentTerms.length - b.paymentTerms.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('paymentTerms')
    // },
    // {
    //   title: 'Rate Per Hour',
    //   dataIndex:'ratePerHour',
    //   sorter: (a, b) => a.ratePerHour.length - b.ratePerHour.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('ratePerHour')
    // },
    // {
    //   title: 'Public Note',
    //   dataIndex:'publicNote',
    //   sorter: (a, b) => a.publicNote.length - b.publicNote.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('publicNote')
    // },,
    
    // {
    //   title: 'Shipment Terms',
    //   dataIndex:'shipmentTerms',
    //   sorter: (a, b) => a.shipmentTerms.length - b.shipmentTerms.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('shipmentTerms')
    // },
    
    // {
    //   title: 'Private Note',
    //   dataIndex:'privateNote',
    //   sorter: (a, b) => a.privateNote.length - b.privateNote.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('privateNote')
    // },
    
    // {
    //   title: 'Payment Mode',
    //   dataIndex:'paymentModeId',
    //   sorter: (a, b) => a.paymentModeId.length - b.paymentModeId.length,
    //   sortDirections: ['descend', 'ascend'],
    //    ...getColumnSearchProps('paymentModeId')
    // },
    
    {
      title: 'Customer Account Type',
      dataIndex:'accountType',
      sorter: (a, b) => a.accountType.localeCompare(b.accountType),
      sortDirections: ['descend', 'ascend'],
      responsive: ['sm'],
      filters: [
        {
          text: 'AZ',
          value:'AZ',
        },
        {
          text: 'KEY_CUSTOMER',
          value:'KEY_CUSTOMER',
        },
        {
          text: 'AGENT',
          value:'AGENT',
        },
        {
          text: 'IMPORTER',
          value:'IMPORTER',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.accountType === value;
      },
      
    },

    {
      title: 'Status',
      dataIndex: 'isActive',
       render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
    
    ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        rowData.clientName.trim().toLowerCase()=="sandhya aqua"?<span></span>:
        <span>         
            <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                   openFormWithData(rowData);
                } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated Customer');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          
          <Divider type="vertical" />
              <Popconfirm onConfirm={e =>{deleteProfile(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate customers ?'
                :  'Are you sure to Activate customers?'
            }
          >  
             <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>  
        </span>
      )
    }
  ];
  const updateCustomer = (customerDto: CustomerDto) => {
    customerDto.updatedUser= JSON.parse(localStorage.getItem('username'))
    customersService.updateCustomers(customerDto).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
     }
  
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }
    

    return (
      <Card title={<span style={{color:'white'}}>Customers Details</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}extra={<Link to = "/Customers-form"  ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
       <br></br>
        <Row gutter={40}>
        <Col>
            <Card title={'Total Customers: ' + customersData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
            </Col>
            <Col>
             <Card title={'Active: ' + customersData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#52c41a'}}></Card>
            </Col>
            <Col>
             <Card title={'In-Active: ' + customersData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#f5222d'}}></Card>
            </Col>
            </Row> 
            <br/>
            <br/>
            <br/>
            <Table
            rowKey={record => record.productId}
            columns={columnsSkelton}
            dataSource={customersData}
            scroll={{x:50}}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '60%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
               <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                <CustomersForm key={Date.now()}
                  updateDetails={updateCustomer}
                  isUpdate={true}
                  customersData={selectedCustomersData}
                  closeForm={closeDrawer} />
              </Card> 
            </Drawer>
       </Card>
    );
  }
  

    
    

export default CustomersGrid;
