import React from 'react';
import CurrentTemp from './current-temp';
import DayWiseTemp from './day-wise-temp';

import './temperature-log.css';

/* eslint-disable-next-line */
export interface TemperatureLogProps { }

export function TemperatureLog(
  props: TemperatureLogProps
) {
  return (
    <div>
      {/* <CurrentTemp id={undefined} value={undefined} title={undefined} /><br /><br />
      <DayWiseTemp /> */}
    </div>
  );
}

export default TemperatureLog;
