import { WarehouseDashboardService } from '@gtpl/shared-services/analytics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';

export interface RepackingEfficiencyProps { }

export function RepackingEfficiency(props: RepackingEfficiencyProps) {
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);
    const service = new WarehouseDashboardService;

    useEffect(() => {
        getRepackingEfficiency();
    }, [])

    const getRepackingEfficiency = () => {
        service.getRepackingEfficiency({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const columns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Sale Order',
            dataIndex: 'poNumber',
        },
        {
            title: 'Issued Qty',
            dataIndex: 'issuedQuantity',
        },
        {
            title: 'Received Qty',
            dataIndex: 'receivedQuantity',
        },
        {
            title: 'Cartons',
            dataIndex: 'jobCartons',
        },
        {
            title: 'Efficiency',
            dataIndex:'efficiency'
        }
    ]
    return (
        <>
            <Card title={<span style={{ color: 'black' }}>Repacking Efficiency</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: 'white', border: 0 }}  >
                <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
            </Card>
        </>
    )
}
export default RepackingEfficiency;