import { CodeListMainIdRequest, CodeListMainItemsDto } from '@gtpl/shared-models/planning'
import { CodeListMainService, CodeListService } from '@gtpl/shared-services/planning'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages'
import { Button, Card, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Table, Typography } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
const { Option } = Select
const { Text } = Typography;


interface CodeListItemsProps {
    isUpdate: boolean;
    selectedData: any;
    itemsForm: FormInstance<any>
    soItemDropdown: any
    setTableData: (values) => void
    processingUnitId : number;
}
export default function CodeListItems(props: CodeListItemsProps) {
    const { itemsForm, soItemDropdown } = props
    const [tracebilityCode, setTracebilityCode] = useState<any>()
    const [tableData, setTableData] = useState<any[]>([]);
    const [editedRowIndex, setEditedRowIndex] = useState<number>()
    const [buttonDisable,setButtonDisable] = useState<boolean>(false)

    const codeListService = new CodeListService();
    const codeListMainService = new CodeListMainService()

    useEffect(() => {
        getTracebilityCodes();
        if (props.isUpdate) {
            getCodeListItemsData()
        }
    }, []);

    useEffect(() => {     
        if(props.soItemDropdown && (!props.isUpdate)){
            setTableData([])
            itemsForm.resetFields()
            setEditedRowIndex(undefined)
        }
    }, [props.soItemDropdown]);


    const validateCartons = (values) => {
        if ((values) > soItemDropdown.filter(i => i.saleOrderItemId == values.saleOrderItemId).jobCases) return AlertMessages.getInfoMessage('cases should not exceed than sale order cases')
        itemsForm.resetFields(['cartons']);
        setButtonDisable(true)
    }

    const validateForDuplicateItem = (values) => {
        let totCartons = 0
        if(!tableData.length ) {
            if ((values.cartons) > soItemDropdown.find(i => i.saleOrderItemId == values.saleOrderItemId).jobCases) return AlertMessages.getInfoMessage('cases should not exceed than sale order cases')
        }
       if(editedRowIndex == undefined){
           if (tableData.find((el) => el.soItemId == values.saleOrderItemId && el.code == values.code && el.tracebilityCodeId == values.tracebilityCodeId )) return AlertMessages.getInfoMessage('This combination already exists ')
       }
        let a: any = tableData.filter(i => { return i.soItemId == values.saleOrderItemId })
        a.forEach(el => totCartons += Number(el.cartons))
        if(props.isUpdate && editedRowIndex != undefined){
            console.log('updating')

            if (( values.cartons) > soItemDropdown.find(i => i.saleOrderItemId == values.saleOrderItemId).jobCases) { return AlertMessages.getInfoMessage('cases should not exceed than sale order cases') }
        }else{
            if ((totCartons + values.cartons) > soItemDropdown.find(i => i.saleOrderItemId == values.saleOrderItemId).jobCases) { return AlertMessages.getInfoMessage('cases should not exceed than sale order cases') }
        }
        return true
    }

 

    const addFormItemToTable = async () => {
        await itemsForm.validateFields().then((res) => {
            const validate =  validateForDuplicateItem(res)
            if(validate != true) return;
            console.log(res)
            const data = new CodeListMainItemsDto(res.saleOrderItemId, ((res.code)), ((res.tracebilityCodeId)), Number(res.cartons), res.productionDate, res.bestBeforeDate, res.createdAt, res.createdUser, res.updatedAt, res.updatedUser, 1,res.unitId,res.codeListMainItemId);
            const obj = { soItemId: res.saleOrderItemId, code: res.code, tracebilityCodeId: res.tracebilityCodeId, cartons: Number(res.cartons), productionDate: res.productionDate, bestBeforeDate: res.bestBeforeDate,codeListMainItemId : res.codeListMainItemId,unitId:res.unitId }
            if (props.isUpdate && editedRowIndex != undefined) {   
                tableData[editedRowIndex] = obj;
                setTableData(prevState => { tableData[editedRowIndex] = obj; return [...tableData] });
                props.setTableData(tableData)
                setEditedRowIndex(undefined)
                itemsForm.resetFields()
            } else {
                setTableData([...tableData, data])
                props.setTableData([...tableData, data])
                itemsForm.resetFields()
            }
        })

    }

    const getTracebilityCodes = () => {
        codeListService.getAllActiveCodeList().then((res) => {
            if (res.status) {
                setTracebilityCode(res.data);
            } else {
                setTracebilityCode([]);
            }
        }).catch((err) => {
            setTracebilityCode([]);
        });
    }

    const deleteItem = (index) => {
        tableData.splice(index, 1)
        setTableData([...tableData])
        props.setTableData([...tableData])
    }

    const getCodeListItemsData = () => {
        const req = new CodeListMainIdRequest()
        req.saleOrderId = props.selectedData.saleOrderId
        codeListMainService.getCodeListsForId(req).then((res) => {
            if (res.status) {
                setTableData(res.data)
            } else {
                setTableData([])

            }
        }).catch((err) => {
            setTableData([])
        })
    }

    const handleEdit = (rowData, index) => {
        setEditedRowIndex(index)
        const soCases = soItemDropdown.filter(i => { return i.saleOrderItemId == rowData.soItemId})
        itemsForm.setFieldsValue({
            saleOrderItemId: rowData.soItemId,
            codeListMainItemId: rowData.codeListMainItemId ? rowData.codeListMainItemId : undefined,
            soCases: soCases[0].jobCases,
            code: rowData.code,
            cartons: rowData.cartons,
            bestBeforeDate: moment(rowData.bestBeforeDate),
            productionDate: moment(rowData.productionDate),
            tracebilityCodeId: rowData.tracebilityCodeId,
            unitId : soCases[0].processingUnitId
        })
    }

    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'Sno',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (index + 1)
        },
        {
            title: 'SO Item',
            dataIndex: 'soItemId',
            render: (value) => {
                return soItemDropdown?.find(i => i.saleOrderItemId == value)?.soItemName
            }

        },
        {
            title: 'Tracebility Code',
            dataIndex: 'tracebilityCodeId',
            render: (value) => {
                return tracebilityCode?.find(i => i.codeListId == value)?.traceabilityCode
            }

        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'

        },
        {
            title: 'Prod Date',
            dataIndex: 'productionDate',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY')
            }

        },
        {
            title: 'Mfg Date',
            dataIndex: 'bestBeforeDate',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY')
            }

        },
        {
            title: 'Cartons',
            dataIndex: 'cartons',
            fixed: 'right'
        },
        {
            title: 'Action',
            render: (value, record, index) => {
                return props.isUpdate ? <EditOutlined className={'editSamplTypeIcon'} type="edit"
                    onClick={() => handleEdit(record, index)}
                    style={{ color: '#1890ff', fontSize: '14px' }}
                /> : <Button size='small' type='primary' shape='round' onClick={() => deleteItem(index)} >Delete</Button>
            }
        }
    ]

    const onReset = () => {
        itemsForm.resetFields();
    };

    const onSoItemSelect = (value) => {
        itemsForm.resetFields(['code', 'tracebilityCodeId', 'productionDate', 'bestBeforeDate', 'cartons'])
        const soCases = soItemDropdown.filter(i =>{return i.saleOrderItemId == value})
        console.log(soCases,soItemDropdown.filter(i =>{return i.saleOrderItemId == value}))
        itemsForm.setFieldsValue({ soCases: soCases[0].jobCases ,unitId: soCases[0].processingUnitId })
    }

    return (
        <Card >

            <Form form={itemsForm} name="control-hooks" layout="vertical">
                <Row gutter={24}  >
                    <Form.Item name='codeListMainItemId' hidden><Input hidden /></Form.Item>
                    {/* <Form.Item name='unitId' hidden initialValue={props.processingUnitId}><Input hidden /></Form.Item> */}

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="saleOrderItemId" label="SO Item" rules={[
                            {
                                required: true,
                                message: 'SO Item  is Required'
                            },
                        ]}>
                            <Select
                                showSearch
                                placeholder="Select SO Item"
                                optionFilterProp="children"
                                onClear={onReset}
                                onSelect={(value) => onSoItemSelect(value)}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option key={0} value={null}>Select Product SKU</Option>
                                {soItemDropdown.map((saleOrder) => {
                                    return <Option key={saleOrder.saleOrderItemId} value={saleOrder.saleOrderItemId}>{saleOrder.soItemName}</Option>
                                })}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="soCases" label="SO Cases" >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                        <Form.Item name="unitId" label="Processing Unit" hidden >
                            <Input disabled hidden />
                        </Form.Item>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="code" label="Code" rules={[
                            {
                                required: true,
                                message: 'Code  is Required'
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                        <Form.Item
                            label="Tracebility Code"
                            name='tracebilityCodeId'
                            rules={[{ required: true, message: 'Missing Tracebility code' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Tracebility Code"
                                optionFilterProp="children"

                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Select.Option key={0} value={null}>Select Tracebility Code</Select.Option>
                                {tracebilityCode?.map((item) => {
                                    return <Select.Option key={item.codeListId} value={item.codeListId}>{item.traceabilityCode}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            label="Production Date"
                            name='productionDate'
                            rules={[{ required: true, message: 'Missing Production Date' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            label="Best before Date"
                            name='bestBeforeDate'
                            rules={[{ required: true, message: 'Missing Best before Date' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            label="Cartons"
                            name='cartons'
                            rules={[{ required: true, message: 'Missing Cartons' }]}
                        >
                            <InputNumber style={{width:'100%'}} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ paddingTop: '28px' }}>
                        <Button type="primary" onClick={addFormItemToTable}>
                           { editedRowIndex != undefined ? 'Update' : 'Add'}
                        </Button>

                        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                            Reset
                        </Button>

                    </Col>
                </Row>


                {tableData.length > 0 ?

                    <Table
                        dataSource={tableData}
                        columns={columnsSkelton}
                        scroll={{ x: true }}
                        pagination={false}
                        bordered
                        summary={(pageData) => {

                            let totalCaartons = 0;
                            pageData.forEach(({ cartons }) => {
                                totalCaartons += Number(cartons);

                            });

                            return (
                                <>
                                    <Table.Summary.Row className='tableFooter'>
                                        <Table.Summary.Cell index={0} colSpan={6} ><Text >Total Cartons</Text></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6} ><Text >{totalCaartons}</Text></Table.Summary.Cell>
                                    </Table.Summary.Row>

                                </>
                            );
                        }}
                    /> : <></>
                }


            </Form>
        </Card >
    )
}
