import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { CertificatesDto, DepartmentDto, PlantsDropDown, PlantsIdRequest } from '@gtpl/shared-models/masters';
import { CertificationsService, DepartmentService, UnitcodeService, ZoneService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { StockInService, TemperatureLogService } from '@gtpl/shared-services/warehouse-management';
import { TemperatureLogDTO } from '@gtpl/shared-models/warehouse-management';
import moment from 'moment';
const { Option } = Select



// import './products-form.css';


const { TextArea } = Input;


/* eslint-disable-next-line */
export interface TemperatureLogFormProps {
  temperatureData:TemperatureLogDTO;
  closeForm: () => void;
  
}

export function TemperatureLogForm(props: TemperatureLogFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disable, setDisable] = useState<boolean>(false);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [deptDropData, setDeptDropData] = useState<DepartmentDto[]>([]);
  const [zoneDropDown,setZoneDropDown] = useState<any[]>([])
  const deptService = new DepartmentService()
  const unitsService = new UnitcodeService();
  const zoneService = new ZoneService();
  let unitId = Number(localStorage.getItem('unit_id'));


  let createdUser = "";
 
  const tempLogService = new TemperatureLogService();

  useEffect(() => {
    getAllPlants();
    getDeptDropData();
    getcoldStorageDropDown();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  },[])

  const createTemperatureLog = (data: TemperatureLogDTO) => {
    data.date = new Date();
    data.day =  Number(moment().format('DD'));
    data.hour = Number(moment().hours());
    data.minutes = Number(moment().minutes());
    data.month = moment().month();
    data.subModule = 'string'
    data.deptCode = 'W5636'
    setDisable(true)
    tempLogService.createTemperatureLog(data).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Temp Log Created Successfully');
        history.push("/temperature-log-view")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: TemperatureLogDTO) => {
    setDisable(false)
   
      setDisable(false)
      createTemperatureLog(values);
    

  };

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const getDeptDropData = () => {
    deptService.getAllActiveDepartment().then((res) => {
      if (res.status) {
        setDeptDropData(res.data)
      } else {
        setDeptDropData([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
      setDeptDropData([])
    })
  }

  const getcoldStorageDropDown = () => {
    const req = new PlantsIdRequest(unitId)
    zoneService.getPlantZones(req).then((res) => {
      if(res.status){
        setZoneDropDown(res.data)
      }else{
        setZoneDropDown([])
      }
    }).catch((res) => {
      setDeptDropData([])
    })
  }




  return (

    <Card title={<span style={{ color: 'white' }}>Temperature Log</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={ <Link to='/certificates-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form}  name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="tempLogId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
            <Form.Item
              name="unitId"
              label="Unit" 
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              >
                 {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                  })}
              </Select>
            </Form.Item>
          </Col >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
            <Form.Item name="deptName" label="Department"
              rules={[
                {

                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Don't Allow Spaces`
                }
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Department"
                allowClear
                style={{ width: '100%' }}

              >
                {deptDropData.map(dropData => {
                  return <Option value={dropData.deptName}>{dropData.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
            <Form.Item name="module" label="Cold Storage"
            >
              {/* <Input /> */}
              <Select
                placeholder="Select Cold Storage"
                allowClear
                optionFilterProp="children"
               
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {zoneDropDown?.map(zoneData => {
                  return <Option key={zoneData.zoneId} value={zoneData.zoneId}>{zoneData.zoneName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
            <Form.Item name="temperature" label="Temperature"
            >
              <Input />
             
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
            <Form.Item name="uom" label="UOM"
            >
              <Input />
             
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
          </Col>
        </Row>
      </Form>
    </Card>

  )
}

export default TemperatureLogForm;
