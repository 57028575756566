import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export interface StockAgingProps { }

export function StockAging(props: StockAgingProps) {
    const config = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Stock Aging'
        },
        xAxis: {
            title : {text:'stock'},
            categories:[1  ]
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            pointFormat: ''
          },
          series: [{
            name: '',
            data: [
              ['', 24.2],
              ['rahul', 20.8],
              ['Karachi', 14.9],
              ['Shenzhen', 13.7],
              ['Guangzhou', 13.1],
              ['Istanbul', 12.7],
              ['Mumbai', 12.4],
              ['Moscow', 12.2],
              ['São Paulo', 12.0],
              ['Delhi', 11.7],
              ['Kinshasa', 11.5],
              ['Tianjin', 11.2],
              ['Lahore', 11.1],
              ['Jakarta', 10.6],
              ['Dongguan', 10.6],
              ['Lagos', 10.6],
              ['Bengaluru', 10.3],
              ['Seoul', 9.8],
              ['Foshan', 9.3],
              ['Tokyo', 9.3]
            ],
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.y:.1f}', // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
              }
            }
          }]
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={config} />
    )
}