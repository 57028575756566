import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
//import { SupplierMappingDto } from '@gtpl/shared-models/masters';
import { EndCustomersService, SupplierMappingService, VendorsService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { SupplierMappingTypeEnum, SupplierTypeEnum } from '@gtpl/shared-models/common-models';
import { SupplierCreationDTO, VendorDropDownDto, VendorsDropDownResponseModel } from '@gtpl/shared-models/masters';
import { seriesInfo } from 'libs/shared-models/sale-management/src/lib/bom/series-info';


// import './products-form.css';


const { TextArea } = Input;
const { Option } = Select;


/* eslint-disable-next-line */
export interface SupplierMappingFormProps {
  // supplierMappingData:SupplierMappingDto;
  updateSupplierMapping: (data: SupplierCreationDTO) => void;
  isUpdate: boolean;
  isNewForm: boolean;
  closeForm: () => void;
}

export function SupplierMappingForm(props: SupplierMappingFormProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([])
  const [endCustomerData, setendCustomerData] = useState<any[]>([])
  const [supplierType, setSupplierType] = useState<any>()

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const supplierMappingDataService = new SupplierMappingService()
  const vendorService = new VendorsService()
  const endCustomerservice = new EndCustomersService()

  const createSupplierMapping = (dto: SupplierCreationDTO) => {
    setDisable(true)
    supplierMappingDataService.create(dto).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Supplier  Created Successfully');
        history.push("/supplier-creation-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getVendorsDropDownData = () => {
    setDisable(true)
    vendorService.getVendorsDropDownData().then(res => {
      setDisable(false)
      if (res.status) {
        setVendorData(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getEndCustomersData = () => {
    endCustomerservice.getActiveEndCustomersDropDownData().then((res) => {
      if (res.status) {
        setendCustomerData(res.data)
      } else {
        setendCustomerData([])
      }
    }).catch(error => {
      setendCustomerData([])
      throw error
    })
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: SupplierCreationDTO) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateSupplierMapping(values);
    } else {
      setDisable(false)
      createSupplierMapping(values);
    }
  };

  const supplierTypeHandler = (value: SupplierMappingTypeEnum) => {
    setSupplierType(value)
    if (value == SupplierMappingTypeEnum.PackingSupplier) {
      getVendorsDropDownData()
    } else if (value == SupplierMappingTypeEnum.EndCustomer) {
      getEndCustomersData()
    }
  }

  console.log(supplierType)

  return (


    <Card title={<span style={{ color: 'white' }}>Supplier Creation</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/supplier-creation-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


      <Form form={form} name="control-hooks"
        layout="vertical" onFinish={saveData}
      >

        <Form.Item name="id" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
            <Form.Item
              name="supplierType"
              label="User Type"
              rules={[
                {
                  required: true,
                  message: "Enter Supplier Type"
                },
                // {
                //   pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                //   message: `Should contain only alphabets.`
                // },
              ]}>
              {<Select
                showSearch
                placeholder="Select Supplier Type"
                onSelect={supplierTypeHandler}
              >
                {Object.values(SupplierMappingTypeEnum).map(i => {
                  return <Option value={i} key={i}>{i}</Option>

                })}
              </Select>}
            </Form.Item>
          </Col >

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
            <Form.Item
              name="supplierId"
              label="User"
              rules={[
                {
                  required: true,
                  message: "Enter Supplier"
                },

              ]}>
              {<Select
                showSearch
                placeholder="Select Supplier "

              >
                {supplierType == SupplierMappingTypeEnum.PackingSupplier ? vendorData.map(i => {
                  return <Option value={i.vendorId} key={i.vendorId}>{i.vendorName}</Option>

                }) : supplierType == SupplierMappingTypeEnum.EndCustomer ? endCustomerData.map((i) => {
                  return <Option value={i.endCustomerId}>{i.endCustomerName}</Option>
                }) : <></>
                }
              </Select>}
            </Form.Item>
          </Col >

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
            <Form.Item
              name="userName"
              label="User Name"
              rules={[
                {
                  required: true,
                  // pattern: /^[a-zA-Z ]*$/,
                  message: 'Name should be in alphabet only'
                },
              ]}>
              <Input />


            </Form.Item>
          </Col >

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                }
              ]}
              help={props.isNewForm ? '' : 'Note: Leave it blank, if you wish not to change password'}
            >
              <Input.Password />
            </Form.Item>

          </Col >
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} >
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default SupplierMappingForm;

