import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Upload, message, UploadProps } from 'antd';
import './so-design-form.css';
import CustomerDetailsForm from './customer-details-form';
import { SaleOrderTrackingReportPages } from '@gtpl/pages/sale-management/sale-pages/sale-order-tracking-report-pages';
import { UploadOutlined } from '@ant-design/icons'
import PoDetailsForm from './po-details-form';
import ProcessDetailsForm from './process-details-form';
import LabInspectionForm from './lab-inspection';
import ItemDetailsForm from './item-details-form';
import { PackTypeCategory, SaleOrderDto, SaleOrderItemsInfo, SoInvoiceDetailsInfo } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import moment from 'moment';
import { Link, Route, useHistory } from "react-router-dom";
import {useLocation} from 'react-router-dom'
import { JobTypesEnum, SoStatusEnum, StatusEnum } from '@gtpl/shared-models/common-models';
import { SubJobCodeListRequest } from '@gtpl/shared-models/planning';




// import ItemDetailsForm from './item-details-form';

/* eslint-disable-next-line */
export interface SoDesignFormProps {
  saleOrderData: SaleOrderDto;
  // updateSaleOrder:(saleOrder:SaleOrderDto)=>void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function SoDesignForm(props: SoDesignFormProps) {
  let history = useHistory();

  const [customerForm] = Form.useForm();
  // const [itemdetailsForm] = Form.useForm();
  const [processDetailsForm] = Form.useForm();
  const [poDetailsForm] = Form.useForm();
  const [labInspectionForm] = Form.useForm();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<number>(1);
  const [itemDetailsKey, setItemDetailsKey] = useState<number>(1);
  const [updateCurrency, setUpdateCurrency] = useState<string>('');
  const [jobType, setJobType] = useState<string>(JobTypesEnum.MAIN_JOB);
  const [itemsKey, setItemsKey] = useState<number>(10);
  const [itemDetailsGridData, setItemDetailsGridData] = useState([]);
  const [codeListDetails, setCodeListDetails] = useState<SubJobCodeListRequest[]>([]);
  const [subJobCodeListData, setSubJobCodeListData] = useState();
  

 
  const [custFormKeyUpdate, setCustFormKeyUpdate] = useState<number>(1);
  const [processFormKeyUpdate, setProcessFormKeyUpdate] = useState<number>(5);
  const [inspectionFormKeyUpdate, setInspectionFormKeyUpdate] = useState<number>(4);
  const [poFormKeyUpdate, setPoFormKeyUpdate] = useState<number>(2);
  const [itemFormKeyUpdate, setItemFormKeyUpdate] = useState<number>(3);
  const [saleOrderDetails, setSaleOrderDetails] = useState<SaleOrderDto>(null);
  const [packCategory, setPackCategory] = useState<string>('');
  const [masterBrand, setMasterBrand] = useState<number>(0);

  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [isSubPoCasesUpdate, setIsSubPoCasesUpdate] = useState<boolean>(false);
  const [loadInspValue, setLoadInspValue] = useState<boolean>(false);
  const [isCodeListAllocated, setIsCodeListAllocated] = useState<boolean>(false);setIsCodeListAllocated
  const [customerBrandsData, setCustomerBrandsData] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const saleService = new SaleOrderService();

  
  
  let location = useLocation();
  const saleOrderId = location.state;
  const jobtype = location.key;
  useEffect(()=>{
    if(saleOrderId){
      props.isUpdate = true;
    getsaleOrderData(saleOrderId);
    }
    if(jobtype){
      setJobType(jobtype)
      getsaleOrderData(saleOrderId);
    }

  },[])

  const getsaleOrderData = (saleOrderId) => {
    console.log('servie')
    saleService.getSaleorderDataById(new saleOrder(saleOrderId)).then((res) => {
      console.log(res)
      console.log(jobType)
      if (res.status) {
        console.log(res.data);
        setSaleOrderDetails(res.data)
        
        setCustFormKeyUpdate(preStateval=>preStateval+10);
        setItemsKey(preStateval=>preStateval+10);
        setPoFormKeyUpdate(preStateval=>preStateval+15);
        setProcessFormKeyUpdate(preStateval=>preStateval+20);
        setInspectionFormKeyUpdate(preStateval=>preStateval+25);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setSaleOrderDetails(null);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderDetails(null);
    });
  }


  const getItemDetailsData = () => {
    const itemDetailsSaveData: SaleOrderItemsInfo[] = []
    console.log(itemDetailsGridData);
    // console.log(itemDetailsGridData[0]['traceCodesData']['codeListInfo']);
    console.log(props.isUpdate);
    if(props.isUpdate){
      itemDetailsGridData.forEach(itemGridData => {
        itemDetailsSaveData.push(new SaleOrderItemsInfo(itemGridData['saleOrderItemId'], saleOrderDetails.saleOrderId, itemGridData['itemId'], itemGridData['minGrade'], itemGridData['maxGrade'], itemGridData['foodTypeId'], itemGridData['valueAdditionOneId'], itemGridData['valueAdditionTwoId'], itemGridData['valueAdditionThreeId'], itemGridData['valueAdditionFourId'],itemGridData['valueAdditionFiveId'], itemGridData['pouches'], itemGridData['cases'], itemGridData['caseWeight'], itemGridData['netWeight'], itemGridData['netAmount'], localStorage.getItem('createdUser'), itemGridData['pouchWeight'], 1, itemGridData['unitPrice'], itemGridData['brandId'], itemGridData['uomId'], itemGridData['minGlaze'], itemGridData['MaxGlaze'], itemGridData['soakingTime'], itemGridData['soakingStyle'], itemGridData['styleWeighment'], itemGridData['varientId'],itemGridData['innerBags'],itemGridData['shrimpWeight'],itemGridData['innerBagSizeId']?itemGridData['innerBagSizeId']:undefined,itemGridData['innerBagSizeId']?itemGridData['innerBagSizeId']:undefined,localStorage.getItem('createdUser'),masterBrand>0?masterBrand:itemGridData['masterBrandId'],itemGridData['lossOrGainPercentage']?itemGridData['lossOrGainPercentage']:itemGridData['finalPercentage'],itemGridData['category'],itemGridData['requiredCount'],itemGridData['packStyleId'],null,null,itemGridData['palletCapacity'],null,itemGridData['barcode']))
      })
    }else{

      itemDetailsGridData.forEach(itemGridData => {
        console.log(itemDetailsGridData)
        itemDetailsSaveData.push(new SaleOrderItemsInfo(undefined, undefined, itemGridData['itemId'], itemGridData['minGrade'], itemGridData['maxGrade'], itemGridData['foodTypeId'], itemGridData['valueAdditionOneId'], itemGridData['valueAdditionTwoId'], itemGridData['valueAdditionThreeId'], itemGridData['valueAdditionFourId'],itemGridData['valueAdditionFiveId'], itemGridData['pouches'], itemGridData['cases'], itemGridData['caseWeight'], itemGridData['netWeight'], itemGridData['netAmount'], localStorage.getItem('createdUser'), itemGridData['pouchWeight'], 1, itemGridData['unitPrice'], itemGridData['brandId']['key'], itemGridData['uomId']['key'], itemGridData['minGlaze'], itemGridData['MaxGlaze'], itemGridData['soakingTime'], itemGridData['soakingStyle'], itemGridData['styleWeighment']['children'], itemGridData['varientId']['key'],itemGridData['innerBags'],itemGridData['shrimpWeight'],itemGridData['innerBagSizeId']?itemGridData['innerBagSizeId']['key']:undefined,itemGridData['innerBagSizeId']?itemGridData['innerBagSizeId']['key']:undefined,undefined,itemGridData['brandId']['masterBrandId'],itemGridData['lossOrGainPercentage'],itemGridData['category']['children'],itemGridData['requiredCount'],itemGridData['uomId']['packMethodId'],null,null,itemGridData['uomId']['palletCapacity'],null,itemGridData['barcode']))
      })
    }
    console.log(itemDetailsSaveData)
    return itemDetailsSaveData;
  }

  const addDataToItemDetails = (itemDetalFormdata) => {
    console.log(itemDetalFormdata)
    setItemDetailsGridData(itemDetalFormdata)
  }

  const brandDetails = (brandDetails) => {
    // console.log(brandDetails)
    setCustomerBrandsData(brandDetails)
  }

  const categoryValue = (categoryValue)=> {
    setSelectedCategory(categoryValue);
    // if(categoryValue == PackTypeCategory.SKEWERS){
      // setItemDetailsKey(prevVal=>prevVal+1)
    // }
    setItemsKey(prevVal=>prevVal+1);
  }
  const onReset = () => {
    customerForm.resetFields();
    processDetailsForm.resetFields();
    poDetailsForm.resetFields();
    labInspectionForm.resetFields();
  };

  const loadInspection = (loadInspectionValue) => {
    console.log(loadInspectionValue)
    setLoadInspValue(loadInspectionValue)
  }

  const saveSaleOrder = (btnValue) => {
    
    customerForm.validateFields().then(customerRes => {
      processDetailsForm.validateFields().then(processRes => {
        poDetailsForm.validateFields().then(poRes => {
          labInspectionForm.validateFields().then(poRes => {
            if(itemDetailsGridData.length>0){
              setBtnDisable(true);
              if(jobType == JobTypesEnum.SUB_JOB && !isSubPoCasesUpdate){
                AlertMessages.getErrorMessage('cases should update while generating SUB PO/sub job Cases should less than main job')
                setBtnDisable(false);
              }else{

            
                        const customerData = customerForm.getFieldsValue();
                        console.log(customerData);
                        const processDetailsData = processDetailsForm.getFieldsValue();
                        const poDetailsData = poDetailsForm.getFieldsValue();
            
                        const labInspectionData = labInspectionForm.getFieldsValue();
                        console.log(labInspectionData);
            
            
                        const saleOrderDto = new SaleOrderDto();
                        let opeartion = undefined;
                        if(jobType == 'Clone'){
                          opeartion = 'Clone'
                        }
                        saleOrderDto.saleOrderItemsInfo = getItemDetailsData();
                        saleOrderDto.operation = opeartion;
            
                        saleOrderDto.soInvDetailsInfo = customerData;
                        if(props.isUpdate){
            
                          saleOrderDto.soInvDetailsInfo.invDetailsId = saleOrderDetails.soInvDetailsInfo.invDetailsId;
                        }
                        saleOrderDto.soPoDetailsInfo = poDetailsData;
                        if(props.isUpdate){
            
                          saleOrderDto.soPoDetailsInfo.SoPoId = saleOrderDetails.soPoDetailsInfo.SoPoId;
                        }
                        saleOrderDto.soPoDetailsInfo.additionalNote = processDetailsData.Addnote;
                        saleOrderDto.soPoDetailsInfo.isBrandApproved = processDetailsData.branddesign;
                        saleOrderDto.soPoDetailsInfo.brandDesignType = processDetailsData.branddesignType;
                        if(customerData.edaDate){
                          customerData.edaDate = customerData.edaDate != '' ? moment(customerData.edaDate).format('YYYY/MM/DD'):null;
                        }
                        // else{
                        //   customerData.edaDate = undefined
                        // }
                        if(customerData.edlDate){
                          customerData.edlDate = customerData.edaDate != '' ? moment(customerData.edlDate).format('YYYY/MM/DD'):null;
                        }
                        // else{
                        //   customerData.edlDate = undefined
                        // }
                        customerData.invoiceDate = moment(customerData.invoiceDate).format('YYYY/MM/DD');
                        saleOrderDto.snspectionId = labInspectionData;
                        saleOrderDto.snspectionId.qaQcInspection = loadInspValue;
                        saleOrderDto.soProcessType = customerData.soProcessType;
                        saleOrderDto.soType = customerData.soType;
                        console.log(customerData);
  
  
                        saleOrderDto.createdUser = JSON.parse(localStorage.getItem('username'));
                        saleOrderDto.status = SoStatusEnum.OPEN;
                        if(props.isUpdate){
                          saleOrderDto.saleOrderId = saleOrderDetails.saleOrderId;
                          saleOrderDto.status = saleOrderDetails.status;
            
            
                        }
                        // const serv = props.isUpdate ? saleService.updateSaleOrder : saleService.createSaleOrder
                        if(props.isUpdate){
                          if(jobType == JobTypesEnum.MAIN_JOB){
  
                            saleService.updateSaleOrder(saleOrderDto).then((res) => {
                              if (res.status) {
                                if (filelist.length > 0) {
                                  const formData = new FormData();
                                  filelist.forEach((file: any) => {
                                    formData.append('file', file);
                                  });
                                  formData.append('saleOrderId', `${res.data.saleOrderId}`)
                                  saleService.saleOrderFileUpload(formData).then(fileres => {
                                    res.data.filePath = fileres.data
                                  })
                                }
                                AlertMessages.getSuccessMessage('Sale Order updated successfully');
                                setBtnDisable(false);
              
                                // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
                                history.push("/saleorder-tracker");
                                // onReset();
                              } else {
                                if (res.intlCode) {
                                  AlertMessages.getErrorMessage(res.internalMessage);
                                setBtnDisable(false);
    
                                } else {
                                  AlertMessages.getErrorMessage(res.internalMessage);
                                setBtnDisable(false);
    
                                }
                              }
                            }).catch((err) => {
                              AlertMessages.getErrorMessage(err.message);
                            });
                          }else if(jobType == 'Clone'){
                            console.log(saleOrderDto);
                          saleService.createSaleOrder(saleOrderDto).then((res) => {
                            if (res.status) {
                              if (filelist.length > 0) {
                                const formData = new FormData();
                                filelist.forEach((file: any) => {
                                  formData.append('file', file);
                                });
                                formData.append('saleOrderId', `${res.data.saleOrderId}`)
                                saleService.saleOrderFileUpload(formData).then(fileres => {
                                  res.data.filePath = fileres.data
                                })
                              }
                              AlertMessages.getSuccessMessage('Cloned Sale Order created successfully');
                              if(btnValue == 'continue'){
                                setBtnDisable(false);
                              }else{
                                history.push("/saleorder-tracker");
                                // setBtnDisable(false);
                              }
            
                              // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
                              // onReset();
                            } else {
                              if (res.intlCode) {
                                AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
  
                              } else {
                                AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
  
                              }
                            }
                          }).catch((err) => {
                            AlertMessages.getErrorMessage(err.message);
                          });
                          }
                          else{
                            if(!(isCodeListAllocated)){
                              AlertMessages.getErrorMessage('Code list should allocate for subjob.Press allocate code list button to allocate trace codes')
                              setBtnDisable(false);
                            }else{
                              saleOrderDto.subJobCodeListData = codeListDetails
                              console.log(saleOrderDto)
                              console.log('sub so')
                              saleService.createSubSaleOrder(saleOrderDto).then((res) => {
                                if (res.status) {
                                  if (filelist.length > 0) {
                                    const formData = new FormData();
                                    filelist.forEach((file: any) => {
                                      formData.append('file', file);
                                    });
                                    formData.append('saleOrderId', `${res.data.saleOrderId}`)
                                    saleService.saleOrderFileUpload(formData).then(fileres => {
                                      res.data.filePath = fileres.data
                                    })
                                  }
                                  AlertMessages.getSuccessMessage('Sale Order updated successfully');
                                  setBtnDisable(false);
                
                                  // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
                                  history.push("/saleorder-tracker");
                                  // onReset();
                                } else {
                                  if (res.intlCode) {
                                    AlertMessages.getErrorMessage(res.message);
                                  setBtnDisable(false);
      
                                  } else {
                                    AlertMessages.getErrorMessage(res.message);
                                  setBtnDisable(false);
      
                                  }
                                }
                              }).catch((err) => {
                                console.log('catch')
  
                                AlertMessages.getErrorMessage(err.message);
                              });
                            }
                          }
            
                          console.log(saleOrderDto);
                        }else{
                          console.log(saleOrderDto);
                          saleService.createSaleOrder(saleOrderDto).then((res) => {
                            if (res.status) {
                              if (filelist.length > 0) {
                                const formData = new FormData();
                                filelist.forEach((file: any) => {
                                  formData.append('file', file);
                                });
                                formData.append('saleOrderId', `${res.data.saleOrderId}`)
                                saleService.saleOrderFileUpload(formData).then(fileres => {
                                  res.data.filePath = fileres.data
                                })
                              }
                              AlertMessages.getSuccessMessage('Sale Order created successfully');
                              if(btnValue == 'continue'){
                              customerForm.setFieldsValue({poNumber:null})
                              customerForm.setFieldsValue({buyerPoNumber:null})
                                setBtnDisable(false);
                              }else{
                                history.push("/saleorder-tracker");
                                // setBtnDisable(false);
                              }
            
                              // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
                              // onReset();
                            } else {
                              if (res.intlCode) {
                                AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
  
                              } else {
                                AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
  
                              }
                            }
                          }).catch((err) => {
                            AlertMessages.getErrorMessage(err.message);
                          });
                        }
              }

            }else{
              AlertMessages.getErrorMessage('missing product details');
            }
          })
        })
      })
    })
  }

  
  // const uploadFieldProps: UploadProps = {
  //   multiple: false,
  //   onRemove: file => {
  //     setfilelist([]);
  //   },
  //   beforeUpload: (file: any) => {
  //     if (!file.name.match(/\.(csv|xlsx|xls|pdf)$/)) {
  //       AlertMessages.getErrorMessage("Only xlsx,csv,xls,pdf files are allowed!");
  //       return true;
  //     }
  //     var reader = new FileReader();
  //     reader.readAsArrayBuffer(file);
  //     reader.onload = data => {
  //       if (filelist.length == 1) {
  //         AlertMessages.getErrorMessage("You Cannot Upload More Than One File At A Time");
  //         return true;
  //       } else {
  //         setfilelist([...filelist, file]);
  //         return false;
  //       }
  //     }
  //   },
  //   progress: {
  //     strokeColor: {
  //       '0%': '#108ee9',
  //       '100%': '#87d068',
  //     },
  //     strokeWidth: 3,
  //     format: percent => `${parseFloat(percent.toFixed(2))}%`,
  //   },
  //   fileList: filelist
  // };
  const mastesrBrandData = (masterBrand) => {
    console.log(masterBrand);
    setMasterBrand(masterBrand);
    

  }

  const uploadFileList = (uploadFiles) =>{
    console.log('test');
    console.log(uploadFiles);
    setfilelist(uploadFiles)
  }
  const currency = (currencyName) => {
    console.log(currencyName);
    setSelectedCurrency(currencyName);
    // setItemsKey(prevVal=>prevVal+1);

  }
  const codeListData = (codeListData) => {
    console.log(codeListData)
    setCodeListDetails([...codeListDetails,codeListData])
  }

  const isCodelistAllocated = (isCodeListAllocated) => {
    console.log(isCodelistAllocated)
    setIsCodeListAllocated(true)
  }

  const isCasesUpdate = (isCasesUpdate) =>{
    console.log(isCasesUpdate)
    setIsSubPoCasesUpdate(isCasesUpdate)
  }
  return (
    <Card title={props.isUpdate ? jobType == JobTypesEnum.MAIN_JOB ? "UPDATE SALE ORDER" : jobType == 'Clone' ? "CLONE SALE ORDER" : "SPLIT SALE ORDER" : "CREATE SALE ORDER"}  size="small">
      <CustomerDetailsForm key= {custFormKeyUpdate} intialData={saleOrderDetails?.soInvDetailsInfo} soStatus={saleOrderDetails?.status} form={customerForm} brandDetails={brandDetails} categoryValue={categoryValue} uploadFileList={uploadFileList} currency={currency} uploadedFile={saleOrderDetails?.filePath} soProcessType= {saleOrderDetails?.soProcessType} soType= {saleOrderDetails?.soType} jobType={jobType} isSubContract= {saleOrderDetails?.soInvDetailsInfo?.isSubContract}/>
      <ItemDetailsForm key={itemsKey}  addDataToItemDetails={addDataToItemDetails} mastesrBrandData  = {mastesrBrandData}brandsData={customerBrandsData} category={selectedCategory} initialData={saleOrderDetails?.saleOrderItemsInfo} soStatus={saleOrderDetails?.status} packCategory={saleOrderDetails?.soInvDetailsInfo?.category} currency={selectedCurrency} updateCurrency={saleOrderDetails?.soInvDetailsInfo?.currency} jobType={jobType} isCasesUpdate={isCasesUpdate} isCodelistAllocated = {isCodelistAllocated} codeListData = {codeListData}/>
      <PoDetailsForm key = {processFormKeyUpdate} form={poDetailsForm} initialData={saleOrderDetails?.soPoDetailsInfo} jobType={jobType}/>
      <ProcessDetailsForm key = {poFormKeyUpdate} itemDetailsData={itemDetailsGridData} form={processDetailsForm} initialData={saleOrderDetails?.soPoDetailsInfo} jobType={jobType}/>
      <LabInspectionForm form={labInspectionForm} key={inspectionFormKeyUpdate} initialData={saleOrderDetails?.soPoDetailsInfo} loadInspection={loadInspection} soStatus={saleOrderDetails?.status} jobType={jobType}/>
      <br />
      <Row gutter = {24} justify="end">
        <Col span={4}>
          <Form.Item >

            <br />
            {/* <Link to={`/saleorder-view`}> */}
            {((!(props.isUpdate) && (customerForm.getFieldValue('soProcessType') != 'MTS')) || (props.isUpdate && jobType == 'Clone'))?<Button  type='primary' onClick={e=>(saveSaleOrder('continue'))} block disabled={btnDisable}>
              Save & Continue..
                    </Button> : <></>}
            {/* </Link> */}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item >

            <br />
            {/* <Link to={`/saleorder-view`}> */}
            <Button type='primary' onClick={e=>(saveSaleOrder('close'))} block disabled={btnDisable}>
              Save & Close
                    </Button>
            {/* </Link> */}
          </Form.Item>
        </Col>
      </Row>
    </Card>

  );
}

export default SoDesignForm;
