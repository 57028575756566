import React,{useState,useEffect}  from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
/* eslint-disable-next-line */
import { UomsDto } from '@gtpl/shared-models/masters';

import {UomsService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import './uoms-form.css';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

export interface UomsFormProps {
  uomsData:UomsDto;
  updateItem: (uomsData:UomsDto) => void;
  isUpdate: boolean;
  // saveItem:(uomsData:uomsDto) => void;
  closeForm: () => void;
}

export function UomsForm(props: UomsFormProps) {

       const [form] = Form.useForm();
       const service = new UomsService();
       const [disable, setDisable] = useState<boolean>(false)
       let history = useHistory();

       
      let createdUser="";
      if(!props.isUpdate){
        createdUser= localStorage.getItem("createdUser");
      }

       const saveUoms = (uomsData: UomsDto) => {
        setDisable(true)
        uomsData.isActive=true;
        service.createUoms(uomsData).then(res => {
          setDisable(false)
          if (res.status) {
            AlertMessages.getSuccessMessage('Uom Created Successfully');
            history.push("/UOMs-view");
            onReset();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        })
      }
/**
 * 
 * @param values //Dto values
 */
  const saveData = (values: UomsDto) => {
    setDisable(false)
        if(props.isUpdate){
        console.log(values);

          props.updateItem(values);
        }else{
          setDisable(false)
          saveUoms(values);
        }
      
      };

      /**
       * To reset form fields
       */
  const onReset = () => {
    console.log('Uom Reset');
    form.resetFields();
  }; 

  return (
  

    <Card title={<span style={{color:'white'}}>UOMs</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/UOMs-view' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
    
    
          <Form form={form} initialValues={props.uomsData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="uomId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="uomName"
                      label="UOM NAME"
                      rules={[
                        {
                          required: true,
                          message:'UOM Name Is Required'
                        },
                        {
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                          message: `Should contain only alphabets.`
                        }
                      ]}>
                      <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="uomType"
                    label="UOM TYPE"
                    rules={[
                      {
                        required: true,
                        message:'UOM Type Is required'
                      },
                      {
                        pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                        message: `Should contain only alphabets.`
                      }
                    ]}>
                    <Input/>
                  </Form.Item>
            </Col>
          
            
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
      );
}
export default UomsForm;






