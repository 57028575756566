import { ContractorDto, TeamDto, TeamRequest } from "@gtpl/shared-models/hrms";
import { TeamsService } from "@gtpl/shared-services/hrms";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Input, Button, Divider, Popconfirm, Tooltip, Card, ConfigProvider, Drawer, Tag, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ProTable, { ProColumns } from "@ant-design/pro-table";
import enUSIntl from 'antd/lib/locale/en_US';
import { Link } from "react-router-dom";
import TeamForm from "../../../teams-form/src/lib/pages-hrms-hrms-components-teams-form";
import Table, { ColumnProps } from "antd/lib/table";
import { UserRequestDto } from "@gtpl/shared-models/common-models";


export interface TeamGridProps { }
export function TeamGrid(props: TeamGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [teamData, setTeamData] = useState<TeamDto[]>([]);
  const [selectedTeamData, setSelectedTeamData] = useState<any>(undefined);
  const [allData, setAllData] = useState<TeamDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const teamService = new TeamsService();

  useEffect(() => {
    getAllTeams();
  }, []);

  const getAllTeams = () => {
    teamService.getAllTeams(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setTeamData(res.data);
        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setTeamData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTeamData([]);
      setAllData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
  * 
  * @param teamData 
  */
  const deleteTeam = (teamData: TeamRequest) => {
    teamData.isActive = teamData.isActive ? false : true;
    teamService.activateOrDeactivateTeams(teamData).then(res => {
      console.log(res);
      if (res.status) {
        getAllTeams();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateTeam = (Data: TeamDto) => {
    Data.updatedUser= JSON.parse(localStorage.getItem('username'))
    teamService.updateTeam(Data).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllTeams();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  /**
* 
* @param pagination 
* @param filters 
* @param sorter 
* @param extra 
*/
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getBoolean = (text: string) => {
    switch (text) {
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  const openFormWithData = (viewData: TeamDto) => {
    setDrawerVisible(true);
    setSelectedTeamData(viewData);
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Shift Group',
      dataIndex: 'shiftCode',
      sorter: (a, b) => a.shiftCode?.length - b.shiftCode?.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shiftCode')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',

      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Team');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteTeam(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Shift Group ?'
                : 'Are you sure to Activate Shift Group ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />
          </Popconfirm>
        </span>
      )
    }

  ];


  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Shift Group</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/team-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <br></br>
        <Table
          rowKey={record => record.TeamId}
          columns={columnsSkelton}
          dataSource={teamData}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <TeamForm key={Date.now()}
              teamData={selectedTeamData}
              updateTeam={updateTeam}
              isUpdate={true}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
      </Card>
    </>
  )
}