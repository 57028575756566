import { Button, Card, Form, Input, Tabs } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LeaveApprovalStatusEnum } from '@gtpl/shared-models/common-models';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { OverTimeService } from '@gtpl/shared-services/hrms';
import { Excel } from 'antd-table-saveas-excel';

/* eslint-disable-next-line */
export interface OtReportProps { }

export function OtReport(
  props: OtReportProps
) {
  const [page, setPage] = React.useState(1);
  const [overTimeData, setOverTimeData] = useState<any[]>([]);
  const service = new OverTimeService();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const { TabPane } = Tabs;


  useEffect(() => {
    getOverTimeTableData();
  }, [])

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: (text: { toString: () => any; }) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getOverTimeTableData = () => {
    service.getOverTimeTableData().then((res) => {
      if (res.status) {
        setOverTimeData(res.data)
        setFiltersData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }

  const tableColumns = (key) => {
    const columns: ColumnProps<any>[] = [
      {
        title: '#',
        key: 'sno',
        width: '30px',
        responsive: ['sm'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
      {
        title: 'Employee Code',
        dataIndex: 'employeeCode',
        ...getColumnSearchProps('employeeCode')
      },
      {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        ...getColumnSearchProps('employeeName')
      },
      {
        title: 'Applied Date',
        dataIndex: 'date',
        width: 200,
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (value, record) => {
          return <>{record.date ? moment(record.date).format('YYYY-MM-DD') : '-'}</>
        }
      },
      {
        title: 'In Time',
        dataIndex: 'inTime',
        render: (value, record) => {
          return <>{record.inTime ? moment(record.inTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
        }
      },
      {
        title: 'Out Time',
        dataIndex: 'outTime',
        render: (value, record) => {
          return <>{record.outTime ? moment(record.outTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
        }
      },
      {
        title: 'Applied OT Hours',
        dataIndex: 'otHours'
      },
      {
        title: 'Working Hours',
        dataIndex: 'workingHours',
        render: (text, record) => {
          return <span>
            {record.outTime ? moment.utc(moment(record.outTime).diff(moment(record.inTime))).format("HH:mm") : '00:00'}
          </span>
        },
      }
    ];


    if (key === "1") {
      return [...columns];
    } else {
      return [...columns]
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const data = [
    { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
    { title: 'Employee Code', dataIndex: 'employeeCode' },
    { title: 'Employee Name', dataIndex: 'employeeName' },
    { title: "Applied Date", dataIndex: "date", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "In Time", dataIndex: "inTime",render: (value, record) => {
      return <>{record.inTime ? moment(record.inTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
    } },
    { title: 'Out Time', dataIndex: 'outTime', render: (value, record) => {
      return <>{record.inTime ? moment(record.inTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
    } },
    { title: "Applied OT Hours", dataIndex: "otHours" },
    { title: "Working Hours", dataIndex: "totalHours", render: (text, record) => {
      return <span>
        {record.outTime ? moment.utc(moment(record.outTime).diff(moment(record.inTime))).format("HH:mm") : '00:00'}
      </span>
    },},
    { title: 'Status' , dataIndex: "status"},
];
  const exportExcel = () => {
    const excel = new Excel();
    excel
        .addSheet('ot-report')
        .addColumns(data)
        .addDataSource(overTimeData, { str2num: true })
        .saveAs('ot-report.xlsx');
}

  return (
    <Card title={<span style={{ color: 'white' }}>Attendance Adjustment Approval</span>}extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
      <br></br>
      <div className="card-container">
        <Tabs type={'card'} tabPosition={'top'}>
          <TabPane
            key="1"
            tab={<span style={{ color: "#f5222d" }}>{'Open: ' + (overTimeData.filter(rec => rec.status == LeaveApprovalStatusEnum.OPEN).length)}</span>}
          >
            
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("1")}
              dataSource={overTimeData.filter(rec => rec.status == LeaveApprovalStatusEnum.OPEN)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>

          <TabPane
            key="2"
            tab={<span style={{ color: "#f5222d" }}>{'Approved: ' + (overTimeData.filter(el => el.status == LeaveApprovalStatusEnum.APPROVED).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("2")}
              dataSource={overTimeData.filter(rec => rec.status == LeaveApprovalStatusEnum.APPROVED)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>

          <TabPane
            key="3"
            tab={<span style={{ color: "#f5222d" }}>{'Rejected: ' + (overTimeData.filter(rec => rec.status == LeaveApprovalStatusEnum.REJECTED).length)}</span>}
          >
            <Table
              rowKey={record => record.applyForLeavesId}
              columns={tableColumns("2")}
              dataSource={overTimeData.filter(rec => rec.status == LeaveApprovalStatusEnum.REJECTED)}
              scroll={{ x: true }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered />
          </TabPane>
        </Tabs>
      </div>
    </Card>
  )
}

export default OtReport;