import React, { useState } from 'react';
import {Form, Input, Button, Card, Row, Col} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import {LoanCategoriesDto} from '@gtpl/shared-models/hrms';
import { LoanCategoriesService } from '@gtpl/shared-services/hrms';

import './loan-categories.css';

/* eslint-disable-next-line */
export interface LoanCategoriesProps {
  data: LoanCategoriesDto;
  update: (data: LoanCategoriesDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function LoanCategories(
  props: LoanCategoriesProps
) {
   const [form] = Form.useForm();
   let history = useHistory();
   const service = new LoanCategoriesService();
   const [disable, setDisable] = useState<boolean>(false)
   let createdUser = '';

   if(props.isUpdate){
      createdUser = localStorage.getItem('createdUser');
   }

   const createLoanCategory = (data: LoanCategoriesDto) => {
    setDisable(true)
     service.create(data).then(res => {
      setDisable(false)
       if(res.status){
         AlertMessages.getSuccessMessage('Loan Category created successfull');
         history.push('/loan-categories-grid');
         onReset();
       }else {
         if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
         }else{
          AlertMessages.getErrorMessage(res.internalMessage);
         }         
       }
     }).catch(err => {
      setDisable(false)
       AlertMessages.getErrorMessage(err.message);
     })
   }

   const onReset = () => {
     form.resetFields();
   }

   const saveData = (values: LoanCategoriesDto) => {
    setDisable(false)
     if(props.isUpdate){
       props.update(values);
     }else {
      setDisable(false)
       createLoanCategory(values);
     }
   };

  return (
    <Card title={<span style={{color: 'white'}}>Loan Categories</span>} style= {{textAlign: 'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra = {props.isUpdate==true? '' : <Link to='/loan-categories-grid'><Button className="panel_button">View</Button></Link>}>
      <Form layout = 'vertical' form = {form} onFinish = {saveData} initialValues={props.data}>
        <Form.Item
         name = "loanCategoryId" style={{display: 'none'}}
        >
          <Input hidden/>
        </Form.Item>
        <Form.Item style= {{display: 'none'}} name ="createdUser" initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{span:24}} sm={{span: 24}} md={{span:6}} lg={{span: 6}} xl={{span: 6}}>
            <Form.Item
             name="loanCategoryName"
             label="Loan Category"
             rules = {[
               {
                 required: true,
                 message: 'Enter valid Loan Category name'
               },
               {
                pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                message: `Should contain only alphabets.` 
               }
             ]}>
               <Input/>
             </Form.Item>
          </Col>
        </Row>
        <Col span={24} style={{textAlign: 'right'}}>
          <Button type="primary"disabled={disable} htmlType="submit">
            Submit
          </Button>
          {(props.isUpdate!==true) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
          }
        </Col>
      </Form>
    </Card>
  );
}

export default LoanCategories;
