import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber, Modal, Descriptions, Popover, Tag, Table } from 'antd';
import { GrossWeightRequest, ItemsDetails, SaleOrderDetailViewInfoDTO, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './capture-gross-weight-form.css';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { Link, useHistory } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { UomEnum } from '@gtpl/shared-models/common-models';

const { Option } = Select;

/* eslint-disable-next-line */
export interface CaptureGrossWeightFormProps {
  saleOrderId: number
  setModalVisible: (req: boolean) => void;
}

export function CaptureGrossWeightForm(
  props: CaptureGrossWeightFormProps
) {
  const [form] = Form.useForm();
  const [saleOrderItemsData, setSaleOrderItemsData] = useState<ItemsDetails[]>([])
  const [saleOrderDetails, setSaleOrderDetails] = useState<SaleOrderDetailViewInfoDTO>(undefined);
  const [fieldsData, setFieldsData] = useState<any>(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);

  const saleOrderService = new SaleOrderService();
  let history = useHistory();

  useEffect(() => {
    getsaleOrderData(props.saleOrderId);
  }, []);

  const saveData = (values) => {
    // console.log(values);
    console.log(tableData);
    let flag = true;
    tableData.forEach(element => {
      console.log(element.grossWeight);
      console.log(element.netWeight);
      // if (element.grossWeight === null || (element.grossWeight < Number(element.netWeight) && element.grossWeight > 0)) {
      if (element.grossWeight === null || element.grossWeight <= 0) {
        element.grossWeight = 0;
        AlertMessages.getErrorMessage("Gross Weight should be greater than 0");
        // AlertMessages.getErrorMessage("Gross Weight should be greater than Net Weight");
        flag = false
      }
      element.totalGrossWeight = Number(element.grossWeight) * Number(element.cases);
      // element.grossWeight = String(element.grossWeight);
    });
    console.log(tableData);
    if (flag) {
      saleOrderService.updateGrossWeight(tableData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          props.setModalVisible(false);
          history.push('/container-register-grid');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
      console.log(values);
    }
  }
  const addGrossWeight = (val, rowData, index) => {
    console.log("index", index);
    console.log(val, rowData);
    console.log(val, rowData.saleOrderItemId);
    rowData.saleOrderItemId = rowData.saleOrderItemId;
    rowData.grossWeight = val;
    // setTableData(tableData.splice(index, 1, rowData));
    // setIndexVal(indexVal+1);
    console.log(tableData);
    // let totalGrossWeight = Number(val)*Number(rowData.cases);
    // let name = "totalGrossWeight"  + index;
    // console.log("  "+name);
    // form.setFieldsValue({name:totalGrossWeight});
  }
  const getsaleOrderData = (saleOrderId) => {
    saleOrderService.getSaleOrderDetailsById({ saleOrderId: saleOrderId }).then((res) => {
      console.log(res.data);
      if (res.status) {
        setSaleOrderDetails(res.data)
        setTableData(res.data.itemData);
      } else {

        setSaleOrderDetails(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderDetails(undefined);
    });
  }

  const tableColumns = (key) => {

    const columns: ColumnProps<any>[] = [
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'Brand',

      },
      {
        title: 'Grade',
        dataIndex: 'minGrade',
        key: 'minGrade',
        render: (text, data, index) => {
          return <span>{`${text} / ${data.maxGrade} `}</span>
        }
      },
      {
        title: 'Product SKU',
        dataIndex: 'varient',
        key: 'varient',
        render: (text, data, index) => {
          return <span>{`${data.product} ${text} `}</span>
        }
      },

      {
        title: 'Pack Style',
        dataIndex: 'packingStyle',
        key: 'packingStyle',
        render: (record, rowData, index) => {
          let lbPouchWeight = Number(rowData.pouchWeight)
          let kgPouchWeight = Number(rowData.pouchWeight)
          if (rowData.name == UomEnum.OZ) {
            lbPouchWeight = lbPouchWeight * 0.0625
          }
          if (rowData.name == UomEnum.Gms) {
            kgPouchWeight = kgPouchWeight * 0.001
          }
          return <><span>{rowData.pouches + ' * ' + ((rowData.uom == UomEnum.LB || rowData.uom == UomEnum.OZ) ? lbPouchWeight.toFixed(2) : kgPouchWeight.toFixed(2)) + " " + ((rowData.uom == 'LB' || rowData.uom == 'OZ') ? 'LB' : 'KG')}</span><><br /><span style={{ textAlign: 'center' }}>{Number(rowData.caseWeight).toFixed(2) + " " + ((rowData.uom == 'LB' || rowData.uom == 'OZ') ? 'LB' : 'KG')}</span><br /><span style={{ textAlign: 'center' }}>{(Number(rowData.caseWeight) * 0.454).toFixed(3) + 'KGS'}</span></></>
        }
        // render: (text,record) => {return ` ${Number(record.pouches)}` + " * " + ` ${Number(record.pouchWeight)}` + ' ' + record.uom},
      },

      {
        title: 'Cases',
        dataIndex: 'cases',
        key: 'cases',
      },
      {
        title: 'Net Weight',
        dataIndex: 'netWeight',
        key: 'netWeight',
        render: (text, record) => {
          const selUom = UnitsOfWeightInput.find(item => item.value == record.uomId);

          let outputUom
          if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
            outputUom = UomEnum.LB;
          }
          if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
            outputUom = UomEnum.KG;
          }
          return ` ${Number(parseFloat(record.netWeight).toFixed(2))}` + ' ' + outputUom
        },



      },
      {
        title: 'Gross Weight(per case)',
        dataIndex: 'grossWeight',
        key: 'grossWeight',
        width: '11%',
        render: (text, rowData, index) => {
          return (
            <>
              <Form.Item
                name={"grossWeight" + index}
                rules={[
                  {
                    required: true, message: 'Missing gross weight'
                  },
                ]}

                initialValue={rowData.grossWeight === null ? 0 : Number(rowData.grossWeight)}
                style={{ margin: 0 }}
              >
                <InputNumber min={0} defaultValue={text} onChange={e => addGrossWeight(e, rowData, index)} /> &nbsp;&nbsp; {( 'per KG')}
              </Form.Item>
            </>
          )
        }
      },
      // {
      //   title: 'Total Gross Weight(In KGS)',
      //   dataIndex: 'totalGrossWeight',
      //   key: 'totalGrossWeight',
      //   width: '10%',
      //   render: (text, rowData, index) => {
      //     return (
      //       <>
      //         <Form.Item
      //           name={"totalGrossWeight" + index}
      //           rules={[
      //             {
      //               required: true, message: 'Missing Total gross weight'
      //             },
      //             {
      //               pattern: /^[0-9]*$/,
      //               message: 'Enter only positive numbers'
      //             },
      //             // {
      //             //   validator: (_, value) =>
      //             //     value >= rowData.netWeight ? Promise.resolve() : Promise.reject(new Error('Gross Weight should be <= Net Weight ' + rowData.netWeight)),
      //             // }
      //           ]}

      //           initialValue={rowData.totalGrossWeight === null ? 0 : Number(rowData.totalGrossWeight)}
      //           style={{ margin: 0 }}
      //         >
      //           {/* {rowData.grossWeight > 0 ?  */}
      //           <InputNumber min={0} name={"totalGrossWeight" + index}
      //             key={rowData.totalGrossWeight} readOnly
      //           />
      //           {/* :"-"
      //         } */}
      //         </Form.Item></>
      //     )
      //   }
      // },
    ]
    const SOItemcolumns: ColumnProps<any>[] = [
      {
        title: 'Sale Order Item Id',
        dataIndex: 'saleOrderItemId',
        key: 'saleOrderItemId',
        width: '10%',
        render: (text, rowData, index) => {
          return (
            <>
              <Form.Item
                name="saleOrderItemId"
                rules={[
                  {
                    required: true, message: 'Missing saleOrderItemId',
                  },
                ]}
                initialValue={rowData.saleOrderItemId}
                style={{ margin: 0 }}
              >
                <InputNumber
                  key={rowData.saleOrderItemId} defaultValue={rowData.saleOrderItemId}
                  disabled
                />
              </Form.Item></>
          )
        }
      },
    ]
    return [...columns];
  }


  return (
    <>
      <Card
        title={<span style={{ color: 'white' }}> Capture Gross Weight</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={saveData}
        >
          <Col span={24}>
            <Table
              dataSource={saleOrderDetails != undefined ? saleOrderDetails.itemData : null}
              columns={tableColumns("1")} pagination={false} />
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>

          </Col>
        </Form>
      </Card>
    </>
  );
}

export default CaptureGrossWeightForm;
