import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker, Descriptions, Table, InputNumber } from 'antd';
import { Link, Redirect,useLocation,useHistory } from 'react-router-dom';
import { ReProcessRequiredQty, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto, SaleOrderItemsDTO, SOItemsDto, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { ContainerStatusEnum, ContainerStatusInput, ProductionTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';
import { FGStockData, RepackingDtoRequest, ReProcessingDtoReq, ReprocessingInputRequest } from '@gtpl/shared-models/warehouse-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined, RedoOutlined, RotateRightOutlined } from '@ant-design/icons';
import { SoProductionDTO } from '@gtpl/shared-models/sale-management';

/* eslint-disable-next-line */
export interface RePackingProps {
  SoItemData:SOItemsDto
 }
const { Option } = Select;
export function RePacking(
  props: RePackingProps
) {

  let location = useLocation();
  const containerStatusInfo: ContainerStatusInfo | any = location.state;
  
  const history = useHistory();

  const [ReProcessForm] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
  const [saleOrderItemsData, setSaleOrderItemsData] = useState<SaleOrderItemsDTO[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [updateStateKey, setUpdateStateKey] = useState<number>(1);
  const [fgData, setFgData] = useState<FGStockData[]>([]);
  const [requiredBomWeight, setRequiredBomWeight] = useState<number>(0);
  
  const SoService = new SaleOrderService();
  const containerRService = new ContainerRegisterService();
  const saleService = new SaleOrderService();
  const fgService = new FGStockService();

  useEffect(() => {
      getSaleOrderData();
      getSaleOrderDetailsById({saleOrderId:props.SoItemData.saleOrderId})
      if (props.SoItemData) {
          ReProcessForm.setFieldsValue({
              saleOrderId: props.SoItemData.saleOrderId,
              saleOrderItemId:props.SoItemData.saleOrderItemId
          });
      } 
      getRepackingStock(); 
      getReProcessingRequiredQuantity();
  }, [props.SoItemData]);

  // get sale order drop down details
  const getSaleOrderData = () => {
      SoService.getSaleOrdersDropDownList().then((res) => {
          if (res.status) {
              setSaleOrderData(res.data);
          } else {
              if (res.intlCode) {
                  AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
              }
              setSaleOrderData([]);
          }
      }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSaleOrderData([]);
      });
  }

  const getSaleOrderDetailsById=(req) =>{
      SoService.getSaleOrderDetailsById(req).then((res) => {
          if (res.status) {
              console.log(res.data);
              setSaleOrderItemsData(res.data.itemData);
              ReProcessForm.setFieldsValue({saleOrderItemId:props.SoItemData.saleOrderItemId});
          } else {
              if (res.intlCode) {
                  AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
              }
              setSaleOrderItemsData([]);
          }
      }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSaleOrderItemsData([]);
      });
  }



  const getRepackingStock=() =>{
      console.log(props.SoItemData);
      const rePackReq = new RepackingDtoRequest();
      rePackReq.product = props.SoItemData.productId;
      rePackReq.varient = props.SoItemData.variant;
      rePackReq.brand = props.SoItemData.brandId;
      rePackReq.masterBrandId = props.SoItemData.masterBrandId;
      rePackReq.maxGrade = props.SoItemData.maxGrade;
      rePackReq.minGrade = props.SoItemData.minGrade;
      rePackReq.category = props.SoItemData.categoryId;
      rePackReq.soakingStyle = props.SoItemData.soakingStyle;
      rePackReq.soakingTime = props.SoItemData.soakingTime;
      rePackReq.requiredQty = props.SoItemData.netWeight;
      rePackReq.requiredCases = props.SoItemData.cases;
      rePackReq.uomId = props.SoItemData.uomId;
      rePackReq.unitId = Number(localStorage.getItem("unit_id"));
      console.log(rePackReq);
      fgService.getAllAssignableRePackingStock(rePackReq).then((res)=>{
        if (res.status) {
          setFgData(res.data);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setFgData([]);
        }
      }).catch((error)=>{
        AlertMessages.getErrorMessage(error.message);
        setFgData([]);
      })
    }
    const getReProcessingRequiredQuantity = () =>{
      const req = new ReProcessRequiredQty(props.SoItemData.productId, props.SoItemData.saleOrderId, props.SoItemData.saleOrderItemId)
      saleService.getReProcessingRequiredQuantity(req).then((res)=>{
        if (res) {
          setRequiredBomWeight(res);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setRequiredBomWeight(0);
        }
      }).catch((error)=>{
        AlertMessages.getErrorMessage(error.message);
          setRequiredBomWeight(0);
      })
    }

  const checkAssigningQty = (assigningQty, record, CaseWeight) =>{
      const qty =Number(record.totalCartons)*Number(record.pouches)*Number(record.shrimpWeight);
      const rem = Number(assigningQty)%Number(CaseWeight);
      console.log(qty);
      console.log("******");
      console.log(114%57);
      console.log(assigningQty);
      
          if(rem==0){
              record.assigningQty = assigningQty;
              const changeAssignQty = record.stockId;
              const formField = {[changeAssignQty]:assigningQty}
              ReProcessForm.setFieldsValue(formField)
          }else{
              const changeAssignQty = record.stockId;
              const formField = {[changeAssignQty]:0}
              ReProcessForm.setFieldsValue(formField)
              AlertMessages.getErrorMessage("Please enter valid quantity");
              record.assigningQty =0;
              
          }
          setUpdateStateKey(updateStateKey+1)
  }
  const getColumns = () =>{
      const columns = [
          {
            title: 'So No',
            dataIndex: 'soNo',
            key: 'soNo',
            render:(value, record, index)=>{
              return index+1
            }
          },
          {
              title: 'Soaking Style',
              dataIndex: 'soakingStyle',
              key: 'soakingStyle',
              render:(value)=>{
                  return value
              }
          },
          {
              title: 'Soaking Time',
              dataIndex: 'soakingTime',
              key: 'soakingTime',
              render:(value)=>{
                  return value
              }
          },
        //   {
        //       title: 'MFG Date',
        //       dataIndex: 'date',
        //       key: 'date',
        //       render:(value)=>{
        //           return moment(value).format('YYYY/MM/DD')
        //       }
        //   },
        //   {
        //       title: 'Pack Style',
        //       dataIndex: 'packStyle',
        //       key: 'packStyle',
        //       render:(value, record:FGStockData)=>{
        //           return record.pouches+"*"+record.pouchWeight +record.uom;
        //       }
        //   },
        //   {
        //       title: 'Cases',
        //       dataIndex: 'totalCartons',
        //       key: 'totalCartons',
        //       render:(value, record:FGStockData)=>{
        //           return <div style={{textAlign:'right'}}>{record.totalCartons}</div>
        //       }
        //   },
        //   {
        //       title: 'Re-Packing QTY',
        //       dataIndex: 'netWeight',
        //       key: 'netWeight',
        //       render:(value, record:FGStockData)=>{
        //           return <div style={{textAlign:'right'}}>{Number(record.totalCartons)*Number(record.pouches)*Number(record.shrimpWeight)}</div>
        //       }
        //   },
        {
            title: fgData.length > 0 ? 'Assignable FG Qty(In'+ fgData[fgData.length-1].uom+' s)': '',
            dataIndex: 'assignableRMWeight',
            key: 'netWeight',
            render:(text,data)=>{
                console.log(data)
                return <div style={{textAlign:'right'}}>{`${Number(text)} (${Number(data?.assignablecases)} Cartons)`}</div>
            }
        },
        //   {
        //       title: 'Assigned Quantity',
        //       dataIndex: 'assigningQty',
        //       key: 'assigningQty',
        //       render:(value, record:FGStockData)=>{
        //           if((record.totalCartons>0)){
        //               return <Form.Item name={record.stockId}><InputNumber min={0} max={Number(record.totalCartons)*Number(record.pouches)*Number(record.shrimpWeight)} onChange={(val)=>checkAssigningQty(val, record, Number(record.pouches)*Number(record.shrimpWeight))}/></Form.Item> 
        //           }else{
        //               return '' 
        //           }
                  
        //       }
        //   },
          {
              title: 'Action',
              dataIndex: 'assign',
              key: 'assign',
              render: (value, record:FGStockData, index) => (
              <Button type="primary" style={{float:'right'}} onClick={()=>AssignQty(record)}><RedoOutlined /> Assign</Button>)
          },
      ];
      return columns;
  }

  const AssignQty = (record:FGStockData)=>{
      console.log(record);
      const rmStock :FGStockData = record;
      let stockIds:number[]=[]
        fgData.forEach(element => {
            // element.saleOrderId = props.SoItemData.saleOrderId
            stockIds.push(element.stockId)
        });
      rmStock.assignedSoId = props.SoItemData.saleOrderId;
      rmStock.assignedSoItemId = props.SoItemData.saleOrderItemId;

      fgService.AssignReprocessQty(new ReprocessingInputRequest(props.SoItemData.saleOrderId,stockIds,props.SoItemData.saleOrderItemId,fgData[fgData.length-1].assignableRMWeight,'rePack',props.SoItemData.minGrade,props.SoItemData.maxGrade,props.SoItemData.hlPercentage,props.SoItemData.finalPercentage)).then((res)=>{
          if (res.status) {
              setFgData([]);
            //   getRepackingStock();
              AlertMessages.getSuccessMessage(res.internalMessage);
              history.push('/fh-dashboard-grid')
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setFgData([]);
          }
        }).catch((error)=>{
          AlertMessages.getErrorMessage(error.message);
          setFgData([]);
        })
        // saleService.updateReProcessQty(record).then((res)=>{
        //   getReProcessingRequiredQuantity();
        //   console.log(res);
        // })
        const req = new SoProductionDTO();
        req.soProductionId =0;
        req.type =ProductionTypeEnum.RE_PACKING;
        req.lotNumber =record.lotNumber;
        req.saleOrderId =record.assignedSoId;
        req.saleOrderItemId =record.assignedSoItemId;
        req.inputQuantity =record.assigningQty;
        req.outputQuantity =record.assigningQty;
        req.createdUser =localStorage.getItem('createdUser');
        req.updatedUser =localStorage.getItem('createdUser');
        saleService.createSoProduction(req).then((res)=>{
          getReProcessingRequiredQuantity();
          console.log(res);
        })
  }

  let record = 0;

  return (
    <Card title={<span style={{ color: 'white' }}>Re Packing</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
    <Form layout="vertical" form={ReProcessForm} name="control-hooks">
        <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item name="saleOrderId" label="Sale Order" >
                    <Select
                        placeholder="Select SaleOrder"
                        allowClear
                        disabled={true}
                    >
                        <Option value={''}>Please Select</Option>
                        {saleOrderData.map(dropData => {
                            return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item name="saleOrderItemId" label="Product" >
                    <Select
                        placeholder="Select Product"
                        allowClear
                        disabled={true}
                    >
                        <Option value={''}>Please Select</Option>
                        {saleOrderItemsData.map(dropData => {
                            return <Option value={dropData.saleOrderItemId}>{dropData.product}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
            <Col>
            <Descriptions column={3}>
                <Descriptions.Item label="Brand">
                    {props.SoItemData.brand}
                </Descriptions.Item>
                <Descriptions.Item label="Variant">
                    {props.SoItemData.varientId}
                </Descriptions.Item>
                <Descriptions.Item label="Grade">
                    {props.SoItemData.minGrade+" - "+props.SoItemData.maxGrade}
                </Descriptions.Item>
                <Descriptions.Item label="Soaking Style">
                    {props.SoItemData.soakingStyle}
                </Descriptions.Item>
                <Descriptions.Item label="Soaking Time">
                    {props.SoItemData.soakingTime}
                </Descriptions.Item>
                <Descriptions.Item label="Pack Style">
                    {props.SoItemData.pouches +' * '+Number(props.SoItemData.pouchWeight)+' '+(UnitsOfWeightInput.find(uom => uom.value == Number(props.SoItemData.uomId)).name)}
                </Descriptions.Item>
                <Descriptions.Item label="Required Cases">
                    {props.SoItemData.cases}
                </Descriptions.Item>
                <Descriptions.Item label="Net Case Weight">
                    {Number(props.SoItemData.caseWeight)}
                </Descriptions.Item>
                <Descriptions.Item label="Net Weight">
                    {Number(props.SoItemData.netWeight)}
                </Descriptions.Item>
                <Descriptions.Item label="Net Drained Weight">
                    {Number(props.SoItemData.netDrainedWeight)}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Quantity">
                    {Number(props.SoItemData.pouches)*Number(props.SoItemData.cases)*Number(props.SoItemData.shrimpWeight)}
                </Descriptions.Item> */}
                <Descriptions.Item label="Required Quantity">
                    {Math.round(requiredBomWeight)}
                </Descriptions.Item>
            </Descriptions>
            </Col>
            
        </Row>
        <Table 
            className ={'rmProcessing'}
            key={keyUpdate}
            // rowKey={(rowRecord: FGStockData) => rowRecord.stockId + record++} 
            columns={getColumns()} 
            dataSource={fgData?[fgData[fgData.length-1]]:[]}
            pagination={false} />
    </Form>
</Card>
  );
}

export default RePacking;
