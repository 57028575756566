import { SupplierMappingService } from '@gtpl/shared-services/masters'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import React from 'react'

type updatePasswordProps = {
    username: string
}

export default function PasswordUpdateForm(props: updatePasswordProps) {
    const [form] = Form.useForm()
    const service = new SupplierMappingService()

    const updatePassWord = (values) => {
        service.updatePassword(values).then((res) => {
            if(res.status){
                AlertMessages.getSuccessMessage('Password updated sucessfully')
            }else{
                AlertMessages.getErrorMessage('Error while updating password')
            }
        }).catch(error => {
           throw error
        })
    }
    return (
        <Card title={'Update Password'} >
            <Form form={form} onFinish={updatePassWord}>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                        <Form.Item
                            name="userName"
                            label="User Name"
                            initialValue={props.username}
                            rules={[
                                {
                                    required: true,
                                    // pattern: /^[a-zA-Z ]*$/,
                                    message: 'Name should be in alphabet only'
                                },
                            ]}>
                            <Input disabled />
                        </Form.Item>
                    </Col >
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ margin: '1%' }}>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                    </Col >

                    <Col span={24} style={{ textAlign: 'right' }}>

                        <Button type="primary"  htmlType="submit" >
                            Submit
                        </Button>
                       

                </Col>
            </Row>

        </Form>

        </Card >
    )
}
