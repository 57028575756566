import React, { useState } from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {ItemCategoriesDto}  from '@gtpl/shared-models/masters';
import './item-categories-form.css';
import {ItemCategoryService} from '@gtpl/shared-services/masters'

const { TextArea } = Input;

/* eslint-disable-next-line */
export interface ItemCategoriesFormProps {

  itemcategoryData: ItemCategoriesDto;
  updateDetails:(itemcategory:ItemCategoriesDto)=>void;
 isUpdate:boolean;
closeForm: () => void;

}

export function ItemCategoriesForm(
  props: ItemCategoriesFormProps
) {
      
  const [form] = Form.useForm();
  const service = new ItemCategoryService
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  const save = (Data: ItemCategoriesDto) => {
    Data.createdUser = localStorage.getItem('username')
    setDisable(true)
    service.create(Data).then(res => {
      setDisable(false)
   if (res.status) {
      AlertMessages.getSuccessMessage('Created Successfully');
         
     history.push("/item-category-view")
     onReset();
   } else {
     if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     } else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
 }).catch(err => {
  setDisable(false)
   AlertMessages.getErrorMessage(err.message);
 })
}

const saveData = (values: ItemCategoriesDto) => {
  setDisable(false)
  // console.log(values);
  if(props.isUpdate){
    props.updateDetails(values);
  }else{
    setDisable(false)
    save(values);
  }

};
const onReset = () => {
  form.resetFields();
};






  return (
   
    <Card title={<span style={{color:'white'}}>Item Categories</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/item-category-view' >{(props.isUpdate===false) && <span style={{color:'white'}} ><Button className='panel_button' > View </Button> </span> }</Link>} >
    
    
          <Form form={form} initialValues={props.itemcategoryData} name="control-hooks" onFinish={saveData}
          layout="vertical"
          > 
    
          <Form.Item name="itemCategoryId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={props.itemcategoryData}>
          <Input hidden/>
        </Form.Item>
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="itemCategory"
                      label="Asset Category"
                      rules={[
                        {
                          required: true,message: "Asset Category is required",
                          
                        },
                        {
                          pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                            message: `Invalid Asset Category` 
                        },
                        {
                          max:30,
                          message:'Asset Category cannot be greater than 30'
                        }
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="itemCategoryCode"
                      label="Asset Category Code"
                      rules={[
                        {
                          required: true,message: "Asset Category Code is required",
                         
                        },
                        {
                          pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/`~{}:";'<>,.?|\s-]*$/,
                          message: `Invalid Asset Category Code`
                        }
                      ]}>
                     <Input/>
                    </Form.Item>
            </Col>
           
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="remarks"
                    label="Remarks"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    >
                      <TextArea rows={1} />
                    
                  </Form.Item>
            </Col>
          
            
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" disabled={disable} htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
                </Col>
              </Row>
          </Form>
        </Card>
  );
}

export default ItemCategoriesForm;
