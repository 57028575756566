import React, { useEffect, useRef, useState } from 'react';
import { RmRackService } from '@gtpl/shared-services/masters'
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, DoubleLeftOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link, Redirect } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import './rm-racks-grid.css';
import { PlantIdRequest, RackDetails, RackDto, RmRackDetails, RmRackDto } from '@gtpl/shared-models/masters';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import RmRacksForm from '../../../rm-racks-form/src/lib/rm-racks-form';

/* eslint-disable-next-line */
export interface RmRacksGridProps { }

export function RmRacksGrid(
  props: RmRacksGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [racksData, setRacksData] = useState<RmRackDetails[]>([]);
  const [rackInfo, setRackInfo] = useState<any>(undefined);

  const [selectedRackData, setSelectedRackData] = useState<any>(undefined);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const unitsService = new UnitcodeService();
  const rackService = new RmRackService();
  const [plantId, setPlantId] = useState<any>()
  const username = localStorage.getItem('username')
 
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  useEffect(() => {
    getAllRacks();
  }, []);
  const getAllRacks = () => {
    rackService.getAllRmRacks().then(res => {
      if (res.status) {
        setRacksData(res.data);
        // setPlantId(res.data);
      } else {
        if (res.intlCode) {
          setRacksData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRacksData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteRack = (racksData: RmRackDto) => {
    //racksData.isActive=racksData.isActive?false:true;
    rackService.activateOrDeactivateRmRack({ ...racksData, isActive: racksData.isActive ? false : true }).then(res => {
      console.log(res);
      if (res.status) {
        getAllRacks();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param variantData 
   */
  const updateRack = (racksData: RmRackDto) => {
    rackService.updateRmRack(racksData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllRacks();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

 

  //TO open the form for updation
  const openFormWithData = (viewData: RmRackDto) => {
    setDrawerVisible(true);
    setSelectedRackData(viewData);
    console.log(selectedRackData)
    console.log('selectedRackData')
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plantName',
      // responsive: ['lg'],
      sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName')
    },
    {
      title: 'Zone Name',
      dataIndex: 'zoneName',
      // responsive: ['lg'],
      sorter: (a, b) => a.zoneName?.localeCompare(b.zoneName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('zoneName')
    },
    {
      title: 'Allocated Plant',
      dataIndex: 'allocatedPlantName',
      // responsive: ['lg'],
      sorter: (a, b) => a.allocatedPlantName?.localeCompare(b.allocatedPlantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('allocatedPlantName')
    },
    {
      title: 'Allocated Zone Name',
      dataIndex: 'allocatedZoneName',
      // responsive: ['lg'],
      sorter: (a, b) => a.allocatedZoneName?.localeCompare(b.allocatedZoneName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('allocatedZoneName')
    },
    {
      title: 'Rack Code',
      dataIndex: 'rackCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.rackCode.localeCompare(b.rackCode),
      ...getColumnSearchProps('rackCode')
    },
    {
      title: "Rack Name",
      dataIndex: 'rackName',
      // responsive: ['lg'],
      sorter: (a, b) => a.rackName.localeCompare(b.rackName),
      ...getColumnSearchProps('rackName')
    },



    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.remarks.length - b.remarks.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    {
      title: 'Rack Type',
      dataIndex: 'reservedForDummy',
      // sorter: (a, b) => a.reservedForDummy?.localeCompare(b.reservedForDummy),
      // ...getColumnSearchProps('reservedForDummy'),
      render: (reservedForDummy, rowData) => (
        <>
          {reservedForDummy === 1 ? <span>Dummy</span> : <span>Sale Order</span>}

        </>
      ),
      filters: [
        {
          text: 'Dummy',
          value: 1,
        },
        {
          text: 'SaleOrder',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.reservedForDummy === value;
      },

    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

        </>
      ),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'InActive',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title:'Action',
      dataIndex: 'action',
      render: (value, record) => {
        return Number(Number(record.rackCount) - Number(record.availCount)) === 0 && Number(record.unitId) != Number(localStorage.getItem("unit_id")) &&
         Number(record.allocatedPlantId) != Number(localStorage.getItem("unit_id")) ?
          <span>
            <Tooltip title='Transfer Rack'><DoubleLeftOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                setModalVisible(true);
                setRackInfo(record);
              } }
              style={{ color: '#1890ff', fontSize: '16px' }} /></Tooltip>
          </span> : null;
      }
    }
    // {
    //   title: `Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>
    //       {  username == '"ramakrishna"' ?
    //         <>
    //       <EditOutlined className={'editSamplTypeIcon'} type="edit"
    //         onClick={() => {
    //           if (rowData.isActive) {
    //             openFormWithData(rowData);
    //           } else {
    //             AlertMessages.getErrorMessage('You Cannot Edit Deactivated Rack');
    //           }
    //         }}
    //         style={{ color: '#1890ff', fontSize: '14px' }}
    //       />

    //       <Divider type="vertical" />
           
    //       <Popconfirm onConfirm={e => { deleteRack(rowData); }}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate Rack ?'
    //             : 'Are you sure to Activate Rack ?'
    //         }
    //       >
    //         <Switch size="default"
    //           className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
    //           checkedChildren={<RightSquareOutlined type="check" />}
    //           unCheckedChildren={<RightSquareOutlined type="close" />}
    //           checked={rowData.isActive}
    //         />

    //       </Popconfirm>
    //       </> : ''
    //       }
    //     </span>
    //   )
    // }
  ];
  /**
 * 
 * @param pagination 
 * @param filters 
 * @param sorter 
 * @param extra 
 */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const handleTransferCancel = () => {
    setModalVisible(false);
  }
  const closeModal = (closeModal) => {
    if(closeModal){
      setModalVisible(false);
      window.location.reload();
    }
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Racks</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  extra={  <Link to='/rm-rack-form' ><Button className='panel_button' >Create </Button></Link>}

    >
      <br></br>
      <Row gutter={24} >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

          <Card title={'Total Racks: ' + racksData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + racksData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active :' + racksData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.rackId}
        columns={columnsSkelton}
        dataSource={racksData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <RmRacksForm key={Date.now()}
            updateRack={updateRack}
            isUpdate={true}
            rackData={selectedRackData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default RmRacksGrid;

