import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

interface IExpiryDetailsForAMCChartProps {
    aboutToExpireDetails: any;
}

const ExpiryDetailsForAMCChart = (props: IExpiryDetailsForAMCChartProps) => {
    const { aboutToExpireDetails } = props;

    const assetCountData = aboutToExpireDetails?.assetMaintenanceDetails.map((record, index) => {
        return record?.aboutToExpireDetails.map((rec) => {
            return { expireWithInDays: rec.expireWithInDays, assetValue: rec.assetValue, assetCount: rec.assetCount }
        })
    }).flat(1);
    const categories = assetCountData?.map((data: any) => data.expireWithInDays);

    const config = {
        colors: [
            "#A43820",
            "#388087"
          ],
        chart: {
            type: "column",
            backgroundColor: "white",
        },
        title: {
            text: `Expiry Details For AMC`,
            align: 'center',
            style: {
                color: "black",
                fontSize: "20px",
            },
        },
        xAxis: {
            categories: categories,
            title: {
                text: "Days Left"
            },
            labels: {
                style: {
                    color: "black",
                },
            },
        },
        yAxis: [{
            title: {
                text: 'Asset Count',
                style: {
                    color: "black",
                },
            },
            labels: {
                style: {
                    color: "black",
                },
            },
        }, {
            title: {
                text: 'Asset Value',
                style: {
                    color: "black",
                },
            },
            opposite: true,
            labels: {
                style: {
                    color: "black",
                },
            },
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
                grouping: true
            }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let tooltip = `<b>${this.x}</b><br/>`;
                this.points.forEach(point => {
                    tooltip += `${point.series.name}: ${point.y}<br/>`;
                });
                return tooltip;
            }
        },
        series: [
            {
                name: "Asset Count",
                data: assetCountData?.map((data: any) => data?.assetCount),
                yAxis: 0,
                stack: 'assetCount'
            },
            {
                name: "Asset Value",
                data: assetCountData?.map((data: any) => data?.assetValue),
                yAxis: 1,
                stack: 'assetValue'
            }
        ]
    };

    
    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
    );
}

export default ExpiryDetailsForAMCChart;
